import * as React from 'react'
import * as _ from 'lodash'
import NavTabs from '@optum-wvie/dynamic-ui-framework/src/components/natives/NavTabs'
import '!style-loader!css-loader!../src/customStyles.css'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { config } from '../../../config'

interface HelpTab {
  id: string
  title: string
  leftIcon: string
  component: React.ReactNode
}

interface HelpPresentationProps {
  id: string
  subProps: Object
  navTabs: Array<HelpTab>
  onNavClick: (id: string) => void
  setNavDiv: (el: React.ReactNode) => void
  selectedRole: any
  openToken?: string
  uatUrl?: boolean
  userAccount: any
  errorMessage: Array<any>
  onDeleteClick: () => void
  userAccess: any
}

function loadAll(
  ActiveSubcomponent,
  activeTabIndex,
  id,
  subProps,
  openToken,
  uatUrl,
  userAccess
) {
  var comp
  if (activeTabIndex == 0) {
    return <ActiveSubcomponent key={id} {...subProps} {...userAccess} />
  } else if (activeTabIndex == 4) {
    if (uatUrl) {
      comp = (
        <div>
          <div className="headerpadding margin-top-10">
            Please click here to contact your <span />
            <a
              href="https://dhhr.wv.gov/Pages/default.aspx"
              style={{ textDecoration: 'underline' }}
              target="_blank"
            >
              local West Virginia Department of Human Services
              Office
            </a>
          </div>
          <div className="headerpadding margin-top-10">
            UAT environment users, please contact the Technical Call Center for
            further assistance at 1-844-451-3514.
          </div>
          <div className="headerpadding margin-top-10">
            For UAT Testers: To schedule training sessions in the PATH Learning
            Management System (LMS), click on the following link –
            <form
              action={`${config['lsmRedirectUrl']}?PARTNER=${config['lmsPartner']}&TARGET=${config['lmsTarget']}`}
              method="POST"
              target="_blank"
            >
              <input
                type="text"
                style={{ display: 'none' }}
                name="opentoken"
                value={openToken}
              />
              <div>
                <input
                  type="submit"
                  className="btnSubmit"
                  value="Click here to access training website"
                />
              </div>
            </form>
          </div>
        </div>
      )
    } else {
      comp = (
        <div className="headerpadding margin-top-10">
          Please click here to contact your <span />
          <a
            href="https://dhhr.wv.gov/Pages/default.aspx"
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            local West Virginia Department of Human Services
          </a>
        </div>
      )
    }
  } else if (activeTabIndex == 5) {
    comp = (
      <form
        action={`${config['lsmRedirectUrl']}?PARTNER=${config['lmsPartner']}&TARGET=${config['lmsTarget']}`}
        method="POST"
        target="_blank"
      >
        <input
          type="text"
          style={{ display: 'none' }}
          name="opentoken"
          value={openToken}
        />
        <div className="headerpadding margin-top-10">
          <input
            type="submit"
            className="btnSubmit"
            value="Click here to access training website"
          />
        </div>
      </form>
    )
  }
  return comp
}

export default function HelpPresentation(props: HelpPresentationProps) {
  const {
    id,
    subProps,
    navTabs,
    onNavClick,
    setNavDiv,
    openToken,
    errorMessage,
    onDeleteClick,
    userAccount,
    userAccess
  } = props
  let uatUrl = props.uatUrl || false
  let finalNavTabs = null
  finalNavTabs = navTabs || []
  let ActiveSubcomponent = null
  let activeTabIndex = _.findIndex(navTabs, { id })
  if (props.selectedRole.roleName === 'Client' || !userAccount) {
    _.remove(finalNavTabs, function(tab) {
      return tab.id === 'peTraining'
    })
  }

  if (finalNavTabs.length === 0) {
    ActiveSubcomponent = props => <div>There are no tabs for this role...</div>
  } else if (finalNavTabs.length <= activeTabIndex) {
    ActiveSubcomponent = navTabs[0].component
    onNavClick('0')
  } else {
    if (activeTabIndex > -1) {
      ActiveSubcomponent = navTabs[activeTabIndex].component
    } else {
      ActiveSubcomponent = props => <div>Invalid subcomponent ID</div>
    }
  }
  return (
    <div>
      <a href="#side-nav" className="skip-nav">
        Skip to side navigation
      </a>
      <div className="row">
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
      </div>
      <div className="container-fluid">
        <div className="row" style={{ display: 'block' }}>
          <div className="header-view">
            <p className="heading">
              <i className="myglyphicon-halflings myglyphicon-circle_question_mark myaccount-icon" />{' '}
              Help Center
            </p>
            <div className="header-sub" />
          </div>
          <div className="helpBelowHeader">
            <button
              className="printStyle print btn btn-tiny btn-white"
              onClick={function() {
                window.print()
              }}
              title="Print"
              aria-label="Print"
            >
              <span className="fa fa-print btn-icon-sm" />
              Print
            </button>
          </div>
        </div>
        <div className="row">
          <div
            id="side-nav"
            className="tabs-section col-md-4 col-lg-3 col-xl-2 no-padding"
            ref={el => setNavDiv(el)}
          >
            <NavTabs
              activeKey={id}
              onNavClick={onNavClick}
              tabs={finalNavTabs}
            />
          </div>
          <div className="form-section col-md-8 col-lg-9 col-xl-10">
            {loadAll(
              ActiveSubcomponent,
              activeTabIndex,
              id,
              subProps,
              openToken,
              uatUrl,
              userAccess
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
