import { findIndex, isArray, isObject, isString } from 'lodash' // extra functionality
import { combineReducers } from 'redux'

const _ = { findIndex, isArray, isObject, isString }

export default combineReducers({
  activeCaseId,
  myCaseInformation,
  updateMyCaseInformation,
  caseNumber,
  myOpenCasesInformation,
  csCases
})

function activeCaseId(state = 0, action) {
  switch (action.type) {
    case 'SET_ACTIVE_CASE_ID':
      return action.caseId
    case 'LOGOUT_SUCCESS':
      return 0
    default:
      return state
  }
}

function caseNumber(state = '', action) {
  switch (action.type) {
    case 'UPDATE_CASE':
      return action.caseNumber
    case 'LOGOUT_SUCCESS':
      return ''
    default:
      return state
  }
}

function updateMyCaseInformation(state = [], action) {
  switch (action.type) {
    case 'UPDATE_CASE_FORMDATA':
      return action.formData
    case 'LOGOUT_SUCCESS':
      return []
    default:
      return state
  }
}

function myCaseInformation(state = [], action) {
  switch (action.type) {
    case 'UPDATE_CASE_DOCUSIGN_FORMDATA':
      return action.formData
    case 'LOGOUT_SUCCESS':
      return []
    default:
      return state
  }
}

function myOpenCasesInformation(state = [], action) {
  switch (action.type) {
    case 'UPDATE_OPEN_CASES':
      return action.openCases
    default:
      return state
  }
}

function csCases(state = [], action) {
  switch (action.type) {
    case 'UPDATE_CS_CASES':
      return action.csCases
    default:
      return state
  }
}
