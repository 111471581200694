import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyProfileContainer = MultitenantLoadable(
  () => import('./MyProfileContainer'),
  () => import('../../' + tenantDirectoryName + '/MyProfile/MyProfileContainer')
)

const MyProfilePresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyProfilePresentation'),
    () =>
      import(
        '../../' + tenantDirectoryName + '/MyProfile/MyProfilePresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyProfile/formComponents')
)

export default function MyProfile(props) {
  return (
    <ErrorBoundary name="MyProfile">
      <MyProfileContainer
        {...props}
        presentation={presentationProps => (
          <MyProfilePresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyProfileContainer = MultitenantLoadable(
  () => import('./MyProfileContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyProfilePresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyProfilePresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyProfile(props) {
  return (
    <BaseErrorBoundary name="MyProfile">
      <BaseMyProfileContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyProfilePresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
