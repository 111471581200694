import * as React from 'react'

import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  tabs: Fields.TabsField,
  carousel: Fields.CarouselField,
  panel: Fields.PanelField,
  tableRow: Fields.TableRowField,
  mcr: Fields.CodeReferenceField,
  addressSuggestion: Fields.AddressSuggestionField
}

export const widgets = {
  radio: Widgets.CustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  checkboxes: Widgets.CustomCheckboxesWidget,
  'alt-date': Widgets.DatePickerWidget,
  dollar: Widgets.DollarWidget,
  span: Widgets.SpanWidget,
  validatedText: Widgets.ValidatedTextWidget,
  header: Widgets.HeaderWidget,
  checkbox: Widgets.CustomCheckboxWidget,
  phone: Widgets.PhoneWidget,
  ssn: Widgets.SSNWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
