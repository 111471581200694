import * as React from 'react'
import CommonQuestions from './CommonQuestions'
import { ENTITLEMENTS as ent } from '@optum-wvie/dynamic-ui-framework/src/entitlements'

export const HelpTabs = [
  {
    id: 'commonQuestions',
    title: 'Help.commonQuestionsTab',
    component: CommonQuestions
  },
  {
    id: 'applicationprocess',
    title: 'Help.applicationProcessTab'
  },
  {
    id: 'myAccount',
    title: 'Help.myAccountTab'
  },
  {
    id: 'lifeChanges',
    title: 'Help.lifeChangesTab'
  },
  {
    id: 'morehelp',
    title: 'Help.needMoreHelpTab'
  }
]
