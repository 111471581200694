import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'

export const widgets = {
  header: Widgets.HeaderWidget,
  'alt-date': Widgets.DatePickerWidget,
  select: Widgets.CustomSelectWidget,
  validatedText: Widgets.ValidatedTextWidget,
  span: Widgets.SpanWidget
}
export const fields = {
  tableRow: Fields.TableRowField
}

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate

export const FieldTemplate = Templates.CustomFieldTemplate
