import { includes } from 'lodash'
import * as queryString from 'query-string'

const assetBase = process.env.ASSET_BASE

const _ = { includes }

export function isImplemented() {
  ///TODO: In West Virginia's implementation fork, return true here.
  return true
}
const pE_app_processing_path = '/pea/v1.0/api'
const pE_search_path = '/pesearch/v1.0/api'
const pE_retrievePdf_path = '/services/muleesb/attachments'
const pE_createPdf_path = '/pepdf/v1.0'
const pE_verify_path = '/pev/v1.0/api'
export function getConfig(baseConfig: any) {
  let config: any = {
    tenant: {
      id: 1,
      code: 'wv',
      state: 'WV',
      name: 'West Virginia',
      styles: {
        theme: assetBase + '/css/blue-theme.css'
      },
      fetcher: {
        retries: 5,
        timeoutMs: 30000
      },
      externalAuth: {
        checkHasPin: true // was true
      },
      timeZone: 'America/New_York',
      useDocusign: true
    },
    //Security Parameter
    portalName: 'cp',
    bearer: 'Bearer ',
    preGoLiveTitlesVisibile: true,
    hideWVWORKS: false,
    childSupportIntegrated: true,
    //Endpoints
    submitSaveExitPEApplication: '/pea/v1.0/api/applications',
    updatePeApplication: pE_app_processing_path + '/applications/{applId}',
    getAppSummaryonUuid: pE_app_processing_path + '/applications',
    getAppSummaryonAppId: pE_app_processing_path + '/applications/{applId}',
    getPeAppDetails: pE_app_processing_path + '/applications/{applId}',
    pESearchApplication: pE_search_path + '/search',
    pECreatePdf: pE_createPdf_path + '/getPePDF/{applId}',
    pERetrievepdf:
      pE_retrievePdf_path + '/get?uniqueID={docId}&source_System=WV',
    forms_MyAppointments: '/cp/myAppointments',
    forms_CommunityPartnerSearch: '/cp/CommunityPartnerSearch',
    forms_AppIntakeSchema: '/cp/AppIntakeSchema',
    forms_MyNewPermission: '/cp/myNewPermission',
    forms_Reports: '/cp/Reports',
    forms_QuickReports: '/cp/myQuickReport',
    forms_CpRenewals: '/cp/CpRenewals',
    forms_ApplicationSearch: '/cp/ApplicationSearch',
    forms_userAccess: '/cp/userAccess',
    forms_organization: '/cp/organization',
    forms_IncidentForm: '/cp/IncidentForm',
    forms_MyTasks: '/cp/myTasks',
    saveApplication: '/WVIE/saveapplication/v2.1/saveApplication',
    saveCOCApplication: '/WVIE/saveapplication/v2.1/saveCOCApplication',
    saveCCAApplication: '/WVIE/saveapplication/v2.1/saveCCAApplication',
    submitApplication: '/WVIE/application/v2.1/submitApplication',
    submitInCompleteApplication:
      '/WVIE/application/v2.1/submitIncompleteSnapAppl',
    getCocCaseNumbers: '/WVIE/coc/v2.0/getCocCaseNumbers',
    getCaseNumbers:
      '/WVIE/coc/v2.0/getCaseNumbers={caseNumber}&reviewTypeIndicator={reviewTypeIndicator}',
    enrollment: '/WVIE/enrollement/v2.0/enrollment',
    getClientDetails: '/WVIE/case/v2.0/clientDetails',
    getVerificationCheckList:
      '/WVIE/individualVerificationChecklist/v1.0/getVerificationCheckList',
    validateUser: '/WVIE/client/v2.0/validateUser',
    authentication: '/WVIE/client/v2.0/authentication',
    provisioning: '/WVIE/client/v1.0/provisioning',
    accessControl: '/WVIE/client/v2.0/accessControl',
    UpdatedPermissionList: '/WVIE/client/v2.0/UpdatedPermissionList',
    getCaseBenefitDetails: '/WVIE/case/v2.0/getBenefitDetails',
    getReviewDueDateSummary: '/Reviews/v1.0/getReviewDueDateSummary',
    getClientNoticeList: '/WVIE/notice/v2.0/getClientNoticeList',
    getClientNoticeDetails:
      '/WVIE/notice/v2.0/getClientNoticeDetails?programCode={programCode}&requestTimeStamp={reqTimeStamp}&agSeqNumber={agSeqNumber}&subProgramCode={subProgramCode}&readUnreadFlag={readUnreadFlag}&caseNum={caseNum}',
    review: '/WVIE/client-review/v2.0/clientreview',
    benifitFinder: '/WVIE/benefitFinder/v1.0/benefitFinder',
    communityPartnerSearch:
      '/WVIE/communityPartnerSearch/v1.0/communityPartner/{cntyName}',
    getApplicationPDF: '/WVIE/application/pdf/v2.1/getApplicationPDF',
    getWorkerApplicationPDF:
      '/WVIE/application/pdf/v2.1/getApplicationPDF?appID={applId}',
    clientAppointments: '/WVIE/client/v2.0/clientAppointments',
    pEapplications: '/pea/v1.0/api/applications',
    pEapplicationsId: '/pea/v1.0/api/applications/{applId}',
    '': '/pea/v1.0',
    verify: '/pev/v1.0/api/verify',
    pEsearch: '/pesearch/v1.0/api/search',
    pEsearchAllByStatus: '/pesearch/v1.0/api/searchAllByStatus',
    pESubmitApplications: '/pea/v1.0/api/pesapplication',
    pEcreate: '/pepdf/v1.0/api/create/{applId}',
    pEgetAttachments:
      '/services/muleesb/attachments/get?uniqueID={docId}&source_System=WV',
    countiesStateUrl: '/WVIE/stcnty/v1.0/statecounty?state={state}',
    saveEndpoint: '/WVIE/application/v1.0/saveApplication',
    submitEndpoint: '/WVIE/application/v1.0/submitApplication',
    gettabs: '/WVIE/profiletab/v1.0/gettabs',
    updatedefaultorg: '/WVIE/profiletab/v1.0/updatedefault',
    peDashboardServices: '/pe/Dashboard/v1.0/getDashboardData',
    getUserResults: '/WVIE/admin/v1.0/getUserResults',
    cancelInvitation: '/WVIE/admin/v1.0/cancelInvitation',
    invitation: '/WVIE/admin/v1.0/invitation',
    updateAccess: '/WVIE/admin/v1.0/updateAccess',
    createupdateorg: '/WVIE/org/v1.0/createupdateorg',
    retrieveorgById: '/WVIE/org/v1.0/retrieveorgById?orgId=',
    retrieveorg: '/WVIE/org/v1.0/retrieveorg',
    dhhrWorkerApplicationSearch: '/WVIE/dhhr/v1.0/dhhrWorkerApplicationSearch',
    screenigReportSearch: '/WVIE/reports/v1.0/screenigReportSearch',
    applicationReportSearch: '/WVIE/reports/v1.0/applicationReportSearch',
    myQuickReportSearch: '/WVIE/reports/v1.0/CPAdminReportSearch',
    forms_MyIncome: '/cp/myIncome',
    savePEApplication: '/WVIE/saveapplication/v2.1/savePEApplication',
    submitPEApplication: '/WVIE/application/v2.1/submitPEApplication',
    submitPEDetermination: '/WVIE/saveapplication/v2.1/submitPEDetermination',
    getIncomeLimits: '/WVIE/client/v1.0/getIncomeLimits',
    docUpload: '/services/muleesb/attachments/upload',
    docView: '/services/muleesb/attachments/view/{docID}',
    docRemove: '/services/muleesb/attachments/remove/{docID}',
    deleteDrftEndPoint: '/WVIE/saveapplication/v2.1/deleteDraftApplication',
    validatePeMaidId:
      '/pe/Dashboard/v1.0/validatePeMaidId?temporaryPeId={MAID}',
    PEAdminWorkerApplicationSearch:
      '/WVIE/admin/v1.0/PEAdminWorkerApplicationSearch',
    PEAdminWorkerMedicaidApplicationSearch:
      '/WVIE/admin/v1.0/PEAdminWorkerMedicaidApplicationSearch',
    CPAdminWorkerApplicationSearch:
      '/WVIE/admin/v1.0/CPAdminWorkerApplicationSearch',
    peUpdateUserInfo: '/pe/Dashboard/v1.0/updateUserInfo',
    lmsAuthTokenUrl: '/WVIE/lmsToken/v1.0/getLmsOpenToken',
    getCpRenewals: '/pe/Dashboard/v1.0/getCpRenewals',
    gatewayBaseUrlSsWv: '/services/wv/self-service-portal/incident/create',
    retrieveOrgWithUserCount: '/WVIE/org/v1.0/retrieveOrgWithUserCount',
    logoutUrl: '/api/ie/usrmgmt/v1/authorize/{tenantCode}/token/revoke',
    lmsPartner:
      'https%3A%2F%2Flearning-nonprod.lms.wvpath.org%2Faccess%2Fsaml%2Fmetadata%2Fwest-virginia',
    lmsTarget:
      'https%3A%2F%2Flearning-nonprod.lms.wvpath.org%2Faccess%2Fsaml%2Flogin%2Fwest-virginia',
    lsmRedirectUrl: 'https://ofefederation-stg.optum.com/idp/startSSO.ping',
    forms_benH: '/cp/BenH',
    forms_benHRef: '/cp/BenHReferral',
    addOrUpdateEligApp: '/WVIE/benjaminH/v1.0/addOrUpdateEligibleApplicant',
    removeEligApp: '/WVIE/benjaminH/v1.0/removeEligibleApplicant',
    getEligAppData: '/WVIE/benjaminH/v1.0/getEligibleApplicantData',
    searchEligAppData:
      '/WVIE/benjaminH/v1.0/getEligibleApplicantDataSearchResult',
    getEligAppIdDetail: '/WVIE/benjaminH/v1.0/getEligibleApplicantDetail',
    getReferralData: '/WVIE/benjaminH/v1.0/getReferralList',
    searchReferralData: '/WVIE/benjaminH/v1.0/getReferralListSearchResult',
    getReferralIdDetail: '/WVIE/benjaminH/v1.0/getReferralDetail',
    saveReferral: '/WVIE/benjaminH/v1.0/updateReferralDetails',
    forms_banner: '/cp/Banner',
    saveBannerMessage: '/WVIE/benjaminH/v1.0/addOrUpdateBannerMessage',
    getAllBannerMessages: '/WVIE/benjaminH/v1.0/getAllBannerMessages',
    getBannerMessageDetail: '/WVIE/benjaminH/v1.0/getBannerMessageDetail',
    getBannerList: '/WVIE/benjaminH/v1.0/getBannerList',
    referralScreening: '/WVIE/benjaminH/v1.0/screeningProcess',
    referralSubmit: '/WVIE/benjaminH/v1.0/createReferralsPostScreening',
    paymentsSent: '/oscar-service/api/cs/v1/paymentProcessing/getAllocations',
    updateReadInd: '/oscar-service/api/cs/v1/public-portal-api/updateReadInd',
    paymentsReceived:
      '/oscar-service/api/cs/v1/paymentProcessing/getDisbursements',
    getBenefitApplicationsDetails:
      '/api/ie/applicationProcessing/v2/secure/benefitApplicationDetails/{tenantCode}',
    getPdfCSEndpoint:
      '/WVIE/application/pdf/v2.1/getChildSupportPDF?appID={applId}&CS=true'
  }

  //Retrieve environment-level config.
  switch (baseConfig.environment) {
    case 'LOCAL':
      config = {
        ...config,
        tenant: {
          id: 5,
          state: 'WV',
          code: 'wv',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 5,
        tCode: 'WVSE',
        preGoLiveTitlesVisibile: true,
        gatewayBaseUrl: 'https://ie-api-gw-test.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-test.optum.com/rf/api/ie/forms/wv/{version}',
        processingBaseUrl:
          'http://localhost:9990/api/application/processing/program/v1.0',
        gatewayBaseUrlPe:
          'http://dev-nonprod-pea-service.ocp-ctc-dmz-nonprod.optum.com',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Flocalhost%3A8443%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://localhost:8443/ie-portal-webapp/react/home',
        localhostUrl: 'http://localhost:8080',
        openId_localhostAccount: {
          uuid: '7da48872-3aed-43b7-9290-b0010ac7a1a7',
          userId: 'RichieRich',
          firstName: 'Richie',
          lastName: 'Rich',
          dob: '1980-01-01',
          email: 'RichieRich@getnada.com',
          defaultUserRoleId: null
        }
      }
      break
    // OFE ENVIRONMENT
    case 'OFEDEV':
      config = {
        ...config,
        tenant: {
          id: 1,
          state: 'WV',
          code: 'wv',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 1,
        tCode: 'WV',
        preGoLiveTitlesVisibile: true,
        gatewayBaseUrl: 'https://ie-api-gw-int1.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-dev.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-dev.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-dev.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-dev.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-int1.optum.com/api/ie/authorization/v1.0/{tenantId}',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw-dev.optum.com/WVIE/'
      }
      break
    case 'OFETST':
      config = {
        ...config,
        tenant: {
          id: 1,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 1,
        tCode: 'WV',
        preGoLiveTitlesVisibile: true,
        gatewayBaseUrl: 'https://ie-api-gw-test.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
        gatewayWvSharedUrl: 'https://wvie-api-gw-test.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-test.optum.com/api/ie/forms/wv/{version}',
        hideWVWORKS: false,
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-test.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-test.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-test.optum.com/api/ie/authorization/v1.0/{tenantId}',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw-test.optum.com/WVIE/',
        sendEmailInvitationUrl:
          'https://wvie-api-gw-test.optum.com/services/esb/mule/framework/v2.0/notification/email'
      }
      break
    case 'OFESIT':
      config = {
        ...config,
        tenant: {
          id: 1,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 1,
        tCode: 'WV',
        portalName: 'cp',
        hideWVWORKS: false,
        gatewayBaseUrl: 'https://ie-api-gw-sit.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-sit1.optum.com',
        gatewayWvSharedUrl: 'https://wvie-api-gw-sit1.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-sit1.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-sit1.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-sit1.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-sit.optum.com/api/ie/authorization/v1.0/WV',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw-sit1.optum.com/WVIE/'
      }
      break
    case 'OFETTSIT':
      config = {
        ...config,
        tenant: {
          id: 1,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 1,
        tCode: 'WV',
        gatewayBaseUrl: 'https://ie-api-gw-ttsit.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-ttsit.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-ttsit.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-ttsit.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-sit1.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-sit.optum.com/api/ie/authorization/v1.0/WV',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw-sit1.optum.com/WVIE/'
      }
      break
    case 'OFEUAT':
      config = {
        ...config,
        tenant: {
          id: 1,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 3,
        tCode: 'WVUT',
        portalName: 'cp',
        hideWVWORKS: false,
        gatewayBaseUrl: 'https://ie-api-gw-uat.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-uat.optum.com',
        gatewayWvSharedUrl: 'https://wvie-api-gw-uat.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-uat.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-uat.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-uat.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-uat.optum.com/api/ie/authorization/v1.0/WV',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw-uat.optum.com/WVIE/'
      }
      break
    case 'OFESTG':
      config = {
        ...config,
        tenant: {
          id: 7,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 7,
        tCode: 'WVST',
        gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-stage.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-stage.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-stage.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-stage.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-stage.optum.com/api/ie/authorization/v1.0/WVST',
        gatewayBaseUrlBenefitFinder:
          'https://wvie-api-gw-stage.optum.com/WVIE/',
        getBenefitApplicationsDetails:
          '/api/ie/applicationProcessing/v2/secure/benefitApplicationDetails/WVST'
      }
      break
    case 'OFEMPROD':
      config = {
        ...config,
        tenant: {
          id: 1,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 1,
        tCode: 'WV',
        portalName: 'cp',
        gatewayBaseUrl: 'https://ie-api-gw-mprod.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-mprod.optum.com',
        trainingUrl: 'https://wvie-api-gw-mprod.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-mprod.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-mprod.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-mprod.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-stage.optum.com/api/ie/authorization/v1.0/WV',
        gatewayBaseUrlBenefitFinder:
          'https://wvie-api-gw-stage.optum.com/WVIE/',
        getBenefitApplicationsDetails:
          '/api/ie/applicationProcessing/v2/secure/benefitApplicationDetails/WV'
      }
      break
    case 'OFEMNO':
      config = {
        ...config,
        tenant: {
          id: 10,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 10,
        tCode: 'WVMO',
        gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-mno-stg.optum.com',
        gatewayWvSharedUrl: 'https://wvie-api-gw-mno-stg.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-mno-stg.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-mno-stg.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-mno-stg.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-stage.optum.com/api/ie/authorization/v1.0/WVMO',
        gatewayBaseUrlBenefitFinder:
          'https://wvie-api-gw-mno-stg.optum.com/WVIE/'
      }
      break
    case 'OFEPRF':
      config = {
        ...config,
        tenant: {
          id: 8,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 8,
        tCode: 'WVPT',
        hideWVWORKS: false,
        gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-perf-stg.optum.com',
        gatewayWvSharedUrl: 'https://wvie-api-gw-stg.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        formsBaseUrl:
          'https://wvie-api-gw-perf-stg.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-perf-stg.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-perf-stg.optum.com/ie-portal-webapp/react/home',
        authorizationApiUrl:
          'https://ie-api-gw-stage.optum.com/api/ie/authorization/v1.0/WVPT',
        gatewayBaseUrlBenefitFinder:
          'https://wvie-api-gw-perf-stg.optum.com/WVIE/',
        getBenefitApplicationsDetails:
          '/api/ie/applicationProcessing/v2/secure/benefitApplicationDetails/WV'
      }
      break
    case 'OFETRN':
      config = {
        ...config,
        tenant: {
          id: 9,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 9,
        tCode: 'WVTR',
        gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-trn-stg.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/wv/{version}",
        formsBaseUrl:
          'https://wvie-api-gw-trn-stg.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-api-gw-trn-stg.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-api-gw-trn-stg.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-stage.optum.com/api/ie/authorization/v1.0/WVTR',
        gatewayBaseUrlBenefitFinder:
          'https://wvie-api-gw-trn-stg.optum.com/WVIE/'
      }
      break
    case 'OFEPROD':
      config = {
        ...config,
        tenant: {
          id: 1,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 1,
        tCode: 'WV',
        preGoLiveTitlesVisibile: true,
        gatewayBaseUrl: 'https://ie-api-gw.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw.optum.com',
        gatewayWvSharedUrl: 'https://wvie-api-gw2.optum.com',
        trainingUrl: 'https://wvie-api-gw.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/wv/{version}",
        formsBaseUrl: 'https://wvie-api-gw.optum.com/api/ie/forms/wv/{version}',
        lmsPartner:
          'https%3A%2F%2Flearning.lms.wvpath.org%2Faccess%2Fsaml%2Fmetadata%2Fwv-path',
        lmsTarget:
          'https%3A%2F%2Flearning.lms.wvpath.org%2Faccess%2Fsaml%2Flogin%2Fwv-path',
        lsmRedirectUrl: 'https://ofefederation.optum.com/idp/startSSO.ping',
        openId_loginUrl:
          'https://identity.optumgovid.com/oidc/authorize?client_id=0828cb0912dd22a01b60582f2c6b96b8a2628147a1f697cd&response_type=code&redirect_uri=https%3A%2F%2Fwww.wvpath.wv.gov%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl: 'https://identity.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://www.wvpath.wv.gov/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw.optum.com/api/ie/authorization/v1.0/WV',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw.optum.com/WV/'
      }
      break
    case 'DEV':
      config = {
        ...config,
        tCode: 'WV',
        gatewayWvUrl: 'https://kong-api-wvie-dev.ocp-ctc-dmz-nonprod.optum.com',
        submitSaveExitPEApplication: pE_app_processing_path + '/applications',
        updatePeApplication: pE_app_processing_path + '/applications/{applId}',
        getAppSummaryonUuid: pE_app_processing_path + '/applications',
        getAppSummaryonAppId: pE_app_processing_path + '/applications/{applId}',
        getPeAppDetails: pE_app_processing_path + '/applications/{applId}',
        pESearchApplication: pE_search_path + '/search',
        pECreatePdf: pE_createPdf_path + '/getPePDF/{applId}',
        pERetrievepdf:
          pE_retrievePdf_path + '/get?uniqueID={docId}&source_System=WV',
        pEVerify: pE_verify_path + '/verify',
        //"gatewayBaseUrl": "https://wv-ie-api-gw-tst.optum.com" //TODO
        forms_MyAppointments: '/cp/myAppointments',
        forms_CommunityPartnerSearch: '/cp/CommunityPartnerSearch',
        forms_AppIntakeSchema: '/cp/AppIntakeSchema',
        forms_MyNewPermission: '/cp/myNewPermission',
        getPermissionsList: 'accessControl',
        updatePermissionsList: 'UpdatedPermissionList',
        forms_MyTasks: '/cp/myTasks',
        //"gatewayBaseUrl": "https://wv-ie-api-gw-dev.optum.com" //TODO
        gatewayBaseUrl: 'https://ie-apigw-dev.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        gatewayBaseUrlPe:
          'https://dev-nonprod-pea-service.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlSubmitWv:
          'https://dev-nonprod-submit-application.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlMsgWv:
          'https://dev-nonprod-client-noticelist.ose-ctc-dmz.optum.com/WVIE/',
        gatewayBaseUrlNcWv:
          'https://dev-nonprod-coccasenumbers.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlCocWv:
          'https://dev-nonprod-coccasenumbers.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlRenewalWv:
          'https://dev-nonprod-client-review.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlClientchangesWv:
          'https://dev-nonprod-getclient-details.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlSubmitClientChanges:
          'https://dev-nonprod-getclient-details.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlSubmitRenewalChanges:
          'https://dev-nonprod-client-review.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlNuWv:
          'https://dev-nonprod-email-enrollment-mule.ocp-ctc-dmz-nonprod.optum.com/services/',
        gatewayBaseUrlAuWv:
          'https://dev-nonprod-validate-user.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlDocWv:
          'https://dev-nonprod-client-noticedetails.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlBnfWv:
          'https://dev-nonprod-benefitdetails.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlPermWv:
          'https://dev-nonprod-access-control.ocp-ctc-dmz-nonprod.optum.com/WVIE/client/v1.0/',
        gatewayBaseUrlCpsWv:
          'https://dev-nonprod-getcommunitypartner.ocp-ctc-dmz-nonprod.optum.com/WVIE/communityPartnerSearch/v1.0/communityPartner/{cntyName}',
        gatewayBaseUrlAppointmentWv:
          'https://dev-nonprod-client-appointments.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlpinEndpointWv:
          'https://dev-nonprod-validate-user.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        getVerificationCheckListWv:
          'https://dev-nonprod-verification-checklist.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlSubmitAppWv:
          'https://dev-nonprod-submit-application.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlPdfWv:
          'https://dev-nonprod-applicationpdfservice.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlPeVerify:
          'https://dev-nonprod-pe-verification.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlPeSearch:
          'https://dev-nonprod-pe-app-search.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlPeCreatePdf:
          'https://pe-pdf-adobe-service-wvie-dev.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlPeRetrievePdf:
          'https://dev-nonprod-federatedstorage-attachments-mule.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlPeSubmit:
          'https://dev-nonprod-pes-service.ocp-ctc-dmz-nonprod.optum.com/pes/v1.0/api/applications',
        gatewayBaseUrlBenefitFinder:
          'https://dev-nonprod-benefitfinder.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlCPS:
          'https://dev-nonprod-getcommuntypartner.ocp-ctc-dmz-nonprod.optum.com/WVIE/communityPartnerSearch/v1.0/communityPartner/{cntyName}',
        formsBaseUrl:
          'https://dev-nonprod-wvie-frontend-service.ocp-ctc-dmz-nonprod.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-cp-dev.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-cp-dev.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-apigw-dev.optum.com/api/ie/usrmgmt/v1/authorize/wv',
        //"gatewayBaseUrl": "https://wv-ie-api-gw-dev.optum.com" //TODO,
        countiesStateUrl: '/WVIE/stcnty/v1.0/statecounty?state={state}',
        gatewayBaseUrlIncomeWv:
          'https://coc-income-limits-wvie-dev.ocp-ctc-dmz-nonprod.optum.com/WVIE/client/v1.0/getIncomeLimits',
        gatewayBaseUrlSsWv:
          'https://selfservice-ticketing-mule-wvie-dev.ocp-ctc-dmz-nonprod.optum.com/services/wv/self-service-portal/incident/create'
      }
      break
    case 'CS-DEV':
      config = {
        ...config,
        tenant: {
          id: 1,
          state: 'WV',
          code: 'wv',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 1,
        tCode: 'WV',
        preGoLiveTitlesVisibile: true,
        gatewayBaseUrl: 'https://ie-api-gw-int1.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-dev.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/{tenantCode}/{version}",
        formsBaseUrl:
          'https://wvie-api-gw-dev.optum.com/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-cs-dev.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-cs-dev.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-int1.optum.com/api/ie/authorization/v1.0/{tenantId}',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw-dev.optum.com/WVIE/',
        validateUser: '/WVIE/client/v2.0/validateUser?CS=true',
        getCaseNumbers:
          '/WVIE/coc/v2.0/getCaseNumbers={caseNumber}&reviewTypeIndicator={reviewTypeIndicator}?CS=true'
      }
      break
    case 'CS-TEST':
      config = {
        ...config,
        tenant: {
          id: 1,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        portalName: 'cp-cs',
        tId: 1,
        tCode: 'WV',
        preGoLiveTitlesVisibile: true,
        gatewayBaseUrl: 'https://ie-api-gw-test.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/wv/{version}",
        formsBaseUrl:
          'https://wvie-api-gw-test.optum.com/rf/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-cs-test.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-cs-test.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-test.optum.com/api/ie/authorization/v1.0/{tenantId}',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw-test.optum.com/WVIE/',
        validateUser: '/WVIE/client/v2.0/validateUser?CS=true',
        getCaseNumbers:
          '/WVIE/coc/v2.0/getCaseNumbers={caseNumber}&reviewTypeIndicator={reviewTypeIndicator}?CS=true',
        getPdfCSEndpoint:
          '/WVIE/application/pdf/v2.1/getChildSupportPDF?appID={applId}&CS=true'
      }
      break
    case 'CS-SIT':
      config = {
        ...config,
        tenant: {
          id: 1,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        tId: 1,
        tCode: 'WV',
        portalName: 'cp-cs',
        gatewayBaseUrl: 'https://ie-api-gw-sit.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-sit1.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/wv/{version}",
        formsBaseUrl:
          'https://wvie-api-gw-sit1.optum.com/rf/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-cs-sit1.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-cs-sit1.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-sit.optum.com/api/ie/authorization/v1.0/WV',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw-sit1.optum.com/WVIE/',
        validateUser: '/WVIE/client/v2.0/validateUser?CS=true',
        getCaseNumbers:
          '/WVIE/coc/v2.0/getCaseNumbers={caseNumber}&reviewTypeIndicator={reviewTypeIndicator}?CS=true',
        getPdfCSEndpoint:
          '/WVIE/application/pdf/v2.1/getChildSupportPDF?appID={applId}&CS=true'
      }
      break
    case 'CS-UAT':
      config = {
        ...config,
        tenant: {
          id: 3,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        portalName: 'cp-cs',
        tId: 3,
        tCode: 'WVUT',
        gatewayBaseUrl: 'https://ie-api-gw-uat.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-uat.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/wv/{version}",
        formsBaseUrl:
          'https://wvie-api-gw-uat.optum.com/rf/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-cs-uat.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-cs-uat.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-uat.optum.com/api/ie/authorization/v1.0/WVUT',
        gatewayBaseUrlBenefitFinder: 'https://wvie-api-gw-uat.optum.com/WVIE/',
        validateUser: '/WVIE/client/v2.0/validateUser?CS=true',
        getCaseNumbers:
          '/WVIE/coc/v2.0/getCaseNumbers={caseNumber}&reviewTypeIndicator={reviewTypeIndicator}?CS=true',
        getBenefitApplicationsDetails:
          '/api/ie/applicationProcessing/v2/secure/benefitApplicationDetails/WV',
        getPdfCSEndpoint:
          '/WVIE/application/pdf/v2.1/getChildSupportPDF?appID={applId}&CS=true'
      }
      break
    case 'CS-STAGE':
      config = {
        ...config,
        tenant: {
          id: 7,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        portalName: 'cp-cs',
        tId: 7,
        tCode: 'WVST',
        gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-stage.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/wv/{version}",
        formsBaseUrl:
          'https://wvie-api-gw-stage.optum.com/rf/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-cs-stage.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-cs-stage.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-stage.optum.com/api/ie/authorization/v1.0/WVST',
        gatewayBaseUrlBenefitFinder:
          'https://wvie-api-gw-stage.optum.com/WVIE/',
        validateUser: '/WVIE/client/v2.0/validateUser?CS=true',
        getCaseNumbers:
          '/WVIE/coc/v2.0/getCaseNumbers={caseNumber}&reviewTypeIndicator={reviewTypeIndicator}?CS=true',
        getBenefitApplicationsDetails:
          '/api/ie/applicationProcessing/v2/secure/benefitApplicationDetails/WV',
        getPdfCSEndpoint:
          '/WVIE/application/pdf/v2.1/getChildSupportPDF?appID={applId}&CS=true'
      }
      break
    case 'CS-MPROD':
      config = {
        ...config,
        tenant: {
          id: 7,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        portalName: 'cp',
        tId: 7,
        tCode: 'WV',
        gatewayBaseUrl: 'https://ie-api-gw-mprod.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-mprod.optum.com',
        trainingUrl: 'https://wvie-api-gw-mprod.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/wv/{version}",
        formsBaseUrl:
          'https://wvie-api-gw-mprod.optum.com/rf/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-cs-mprod.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-cs-mprod.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-mprod.optum.com/api/ie/authorization/v1.0/WVST',
        gatewayBaseUrlBenefitFinder:
          'https://wvie-api-gw-mprod.optum.com/WVIE/',
        validateUser: '/WVIE/client/v2.0/validateUser?CS=true',
        getCaseNumbers:
          '/WVIE/coc/v2.0/getCaseNumbers={caseNumber}&reviewTypeIndicator={reviewTypeIndicator}?CS=true',
        getBenefitApplicationsDetails:
          '/api/ie/applicationProcessing/v2/secure/benefitApplicationDetails/WV',
        getPdfCSEndpoint:
          '/WVIE/application/pdf/v2.1/getChildSupportPDF?appID={applId}&CS=true'
      }
      break
    case 'CS-MNOSTG':
      config = {
        ...config,
        tenant: {
          id: 10,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        portalName: 'cp-cs',
        tId: 10,
        tCode: 'WVMO',
        gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-mno-stg.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/wv/{version}",
        formsBaseUrl:
          'https://wvie-api-gw-mno-stg.optum.com/rf/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-cs-stage.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-cs-stage.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-stage.optum.com/api/ie/authorization/v1.0/WVMO',
        gatewayBaseUrlBenefitFinder:
          'https://wvie-api-gw-mno-stg.optum.com/WVIE/',
        validateUser: '/WVIE/client/v2.0/validateUser?CS=true',
        getCaseNumbers:
          '/WVIE/coc/v2.0/getCaseNumbers={caseNumber}&reviewTypeIndicator={reviewTypeIndicator}?CS=true'
      }
      break
    case 'CS-PREFSTG':
      config = {
        ...config,
        tenant: {
          id: 8,
          code: 'wv',
          state: 'WV',
          name: 'West Virginia',
          styles: {
            theme: assetBase + '/css/blue-theme.css'
          },
          fetcher: {
            retries: 5,
            timeoutMs: 30000
          },
          externalAuth: {
            checkHasPin: true // was true
          },
          timeZone: 'America/New_York'
        },
        portalName: 'cp-cs',
        tId: 8,
        tCode: 'WVPT',
        gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
        gatewayWvUrl: 'https://wvie-api-gw-perf-stg.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        // "formsBaseUrl": "https://ie-api-gw-int1.optum.com/api/ie/forms/wv/{version}",
        formsBaseUrl:
          'https://wvie-api-gw-perf-stg.optum.com/rf/api/ie/forms/wv/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=IECPWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-client-cs-perf-stg.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl:
          'https://identity.nonprod.optumgovid.com/oidc/token',
        openId_redirectUrl:
          'https://wvie-client-cs-perf-stg.optum.com/ie-portal-webapp/react/home',
        authorizationApiUrl:
          'https://ie-api-gw-stage.optum.com/api/ie/authorization/v1.0/WVPT',
        gatewayBaseUrlBenefitFinder:
          'https://wvie-api-gw-perf-stg.optum.com/WVIE/',
        validateUser: '/WVIE/client/v2.0/validateUser?CS=true',
        getCaseNumbers:
          '/WVIE/coc/v2.0/getCaseNumbers={caseNumber}&reviewTypeIndicator={reviewTypeIndicator}?CS=true',
        getPdfCSEndpoint:
          '/WVIE/application/pdf/v2.1/getChildSupportPDF?appID={applId}&CS=true'
      }
      break
    case 'TEST':
      config = {
        ...config,
        gatewayWvUrl:
          'https://kong-api-nonprod-wviekong.ocp-ctc-dmz-nonprod.optum.com',
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html',
        submitSaveExitPEApplication: pE_app_processing_path + '/applications',
        updatePeApplication: pE_app_processing_path + '/applications/{applId}',
        getAppSummaryonUuid: pE_app_processing_path + '/applications',
        getAppSummaryonAppId: pE_app_processing_path + '/applications/{applId}',
        getPeAppDetails: pE_app_processing_path + '/applications/{applId}',
        pESearchApplication: pE_search_path + '/search',
        pECreatePdf: pE_createPdf_path + '/getPePDF/{applId}',
        pERetrievepdf:
          pE_retrievePdf_path + '/get?uniqueID={docId}&source_System=WV',
        pEVerify: pE_verify_path + '/verify',
        //"gatewayBaseUrl": "https://wv-ie-api-gw-tst.optum.com" //TODO
        forms_MyAppointments: '/cp/myAppointments',
        forms_CommunityPartnerSearch: '/cp/CommunityPartnerSearch',
        forms_AppIntakeSchema: '/cp/AppIntakeSchema',
        forms_MyNewPermission: '/cp/myNewPermission',
        getPermissionsList: 'accessControl',
        updatePermissionsList: 'UpdatedPermissionList',
        forms_MyTasks: '/cp/myTasks',
        forms_Reports: '/cp/Reports',
        forms_QuickReports: '/cp/myQuickReport',
        forms_CpRenewals: '/cp/CpRenewals',
        forms_ApplicationSearch: '/cp/ApplicationSearch',
        forms_userAccess: '/cp/userAccess',
        forms_organization: '/cp/organization',
        //"gatewayBaseUrl": "https://wv-ie-api-gw-tst.optum.com" //TODO
        environment: 'TEST',
        gatewayBaseUrl: 'https://ie-api-gw-stg.optum.com',
        gatewayBaseUrlPe:
          'https://dev-nonprod-pea-service.ocp-ctc-dmz-nonprod.optum.com',
        newBaseUrl:
          'https://dev-nonprod-pea-service.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlMsgWv:
          'https://dev-nonprod-client-noticelist.ose-ctc-dmz.optum.com/WVIE/',
        gatewayBaseUrlSubmitWv:
          'https://dev-nonprod-submit-application.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlNcWv:
          'https://dev-nonprod-coccasenumbers.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlNuWv:
          'https://dev-nonprod-email-enrollment-mule.ocp-ctc-dmz-nonprod.optum.com/services/',
        gatewayBaseUrlAuWv:
          'https://dev-nonprod-validate-user.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlDocWv:
          'https://dev-nonprod-client-noticedetails.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlBnfWv:
          'https://dev-nonprod-benefitdetails.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlRenewalWv:
          'https://dev-nonprod-client-review.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlClientchangesWv:
          'https://dev-nonprod-getclient-details.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlSubmitClientChanges:
          'https://dev-nonprod-getclient-details.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlSubmitRenewalChanges:
          'https://dev-nonprod-client-review.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlPermWv:
          'https://dev-nonprod-access-control.ocp-ctc-dmz-nonprod.optum.com/WVIE/client/v1.0/',
        gatewayBaseUrlCpsWv:
          'https://dev-nonprod-getcommunitypartner.ocp-ctc-dmz-nonprod.optum.com/WVIE/communityPartnerSearch/v1.0/communityPartner/{cntyName}',
        gatewayBaseUrlCocWv:
          'https://dev-nonprod-coccasenumbers.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlAppointmentWv:
          'https://dev-nonprod-client-appointments.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlpinEndpointWv:
          'https://dev-nonprod-validate-user.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        getVerificationCheckListWv:
          'https://dev-nonprod-verification-checklist.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlSubmitAppWv:
          'https://dev-nonprod-submit-application.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlPdfWv:
          'https://dev-nonprod-applicationpdfservice.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlgetClientDetails:
          'https://dev-nonprod-client-details.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlBenefitFinder:
          'https://dev-nonprod-benefitfinder.ocp-ctc-dmz-nonprod.optum.com/WVIE/',
        gatewayBaseUrlPeVerify:
          'https://dev-nonprod-pe-verification.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlPeSearch:
          'https://dev-nonprod-pe-app-search.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlPeCreatePdf:
          'https://pe-pdf-adobe-service-wvie-dev.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlPeRetrievePdf:
          'https://dev-nonprod-federatedstorage-attachments-mule.ocp-ctc-dmz-nonprod.optum.com',
        gatewayBaseUrlPeSubmit:
          'https://dev-nonprod-pes-service.ocp-ctc-dmz-nonprod.optum.com/pes/v1.0/api/applications',
        gatewayBaseUrlCPS:
          'https://dev-nonprod-getcommuntypartner.ocp-ctc-dmz-nonprod.optum.com/WVIE/communityPartnerSearch/v1.0/communityPartner/{cntyName}',
        formsBaseUrl:
          'https://test-nonprod-wvie-frontend-service.ocp-ctc-dmz-nonprod.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://stage-sso.uhc.com/ext/as/authorization.oauth2?pfidpadapterid=OptumIdOIDCStage&client_id=olc50019&response_type=code&redirect_uri=https%3A%2F%2Fwvie-cp-tst.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid%20profile%20email&state={state}&nonce=',
        openId_tokenServerUrl: 'https://stage-sso.uhc.com/ext/as/token.oauth2',
        openId_redirectUrl:
          'https://wvie-cp-tst.optum.com/ie-portal-webapp/react/home',
        openId_grantType: 'authorization_code',
        authorizationApiUrl:
          'https://ie-api-gw-stg.optum.com/api/ie/authorization/v1.0/{tenantId}',
        //"gatewayBaseUrl": "https://wv-ie-api-gw-tst.optum.com" //TODO
        countiesStateUrl:
          'https://state-county-wvie-dev.ocp-ctc-dmz-nonprod.optum.com/WVIE/stcnty/v1.0/statecounty?state={state}',
        gatewayBaseUrlIncomeWv:
          'https://coc-income-limits-wvie-dev.ocp-ctc-dmz-nonprod.optum.com/WVIE/client/v1.0/getIncomeLimits',
        gatewayBaseUrlSsWv:
          'https://selfservice-ticketing-mule-wvie-dev.ocp-ctc-dmz-nonprod.optum.com/services/wv/self-service-portal/incident/create'
      }
      break
    case 'STAGING':
      config = {
        ...config,
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html'
        //"gatewayBaseUrl": "https://wv-ie-api-gw-stg.optum.com" //TODO
      }
      break
    case 'DEMO':
      config = {
        ...config,
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html'
        //"gatewayBaseUrl": "https://wv-ie-api-gw-demo.optum.com" //TODO
      }
      break
    default:
      config = {
        ...config,
        isDefaultWVCase: true,
        trainingUrl: 'https://wvie-api-gw-dev.optum.com/story.html'
        //"gatewayBaseUrl": "https://wv-ie-api-gw-dev.optum.com" //TODO
      }
  }

  return config
}

export function determineEnvironment() {
  const url = window.location.href.toLowerCase()
  if (_.includes(url, 'localhost')) {
    return 'LOCAL'
  }
  // OFE Environments
  if (_.includes(url, 'wvie-client-perf')) {
    return 'OFEPRF'
  }
  if (_.includes(url, 'wvie-client-ttsit')) {
    return 'OFETTSIT'
  }
  if (_.includes(url, 'wvie-client-mprod')) {
    return 'OFEMPROD'
  }
  if (_.includes(url, 'wvie-client-sit1')) {
    return 'OFESIT'
  }
  if (_.includes(url, 'wvie-client-test')) {
    return 'OFETST'
  }
  if (_.includes(url, 'wvie-client-dev')) {
    return 'OFEDEV'
  }
  if (_.includes(url, 'wvie-client-test')) {
    return 'OFETST'
  }
  if (_.includes(url, 'wvie-client-sit1')) {
    return 'OFESIT'
  }
  if (_.includes(url, 'wvie-client-uat')) {
    return 'OFEUAT'
  }
  if (_.includes(url, 'wvie-client-stage')) {
    return 'OFESTG'
  }
  if (_.includes(url, 'wvie-client-mno-stg')) {
    return 'OFEMNO'
  }
  if (_.includes(url, 'wvie-client-perf-stg')) {
    return 'OFEPRF'
  }
  if (_.includes(url, 'wvie-client-trn-stg')) {
    return 'OFETRN'
  }
  if (_.includes(url, 'www.wvpath.org')) {
    return 'OFEPROD'
  }
  if (_.includes(url, 'wvpath.org')) {
    return 'OFEPROD'
  }
  if (_.includes(url, 'wvpath.wv.gov')) {
    return 'OFEPROD'
  }
  if (_.includes(url, 'www.wvpath.wv.gov')) {
    return 'OFEPROD'
  }
  if (_.includes(url, '-cs-dev')) return 'CS-DEV'
  if (_.includes(url, '-cs-test')) return 'CS-TEST'
  if (_.includes(url, '-cs-sit1')) return 'CS-SIT'
  if (_.includes(url, '-cs-ttsit')) return 'CS-TTSIT'
  if (_.includes(url, '-cs-uat')) return 'CS-UAT'
  if (_.includes(url, '-cs-stage')) return 'CS-STAGE'
  if (_.includes(url, '-cs-mprod')) return 'CS-MPROD'
  if (_.includes(url, '-cs-mno-stg')) return 'CS-MNOSTG'
  if (_.includes(url, '-cs-perf-stg')) return 'CS-PREFSTG'
  if (_.includes(url, 'cp-dev')) {
    return 'DEV'
  }
  if (_.includes(url, '-tst')) {
    return 'TEST'
  }
  if (_.includes(url, '-stg')) {
    return 'STAGING'
  }
  if (_.includes(url, '-demo')) {
    return 'DEMO'
  }
  if (_.includes(url, 'ie-testing')) {
    return 'DEV'
  }
  return null
}

export function determineTenant(environment) {
  const Env = determineEnvironment().toLocaleUpperCase()
  if (Env == 'LOCAL') {
    return 'WVSE'
  }

  if (Env == 'OFEDEV') {
    return 'WV'
  }

  if (Env == 'OFETST') {
    return 'WV'
  }

  if (Env == 'OFESIT') {
    return 'WV'
  }

  if (Env == 'OFETTSIT') {
    return 'WV'
  }

  if (Env == 'OFEUAT') {
    return 'WVUT'
  }

  if (Env == 'OFESTG') {
    return 'WVST'
  }

  if (Env == 'OFEMNO') {
    return 'WVMO'
  }
  if (Env == 'OFEPRF') {
    return 'WVPT'
  }
  if (Env == 'OFETRN') {
    return 'WVTR'
  }
  if (Env == 'OFEPROD') {
    return 'WV'
  }
  if (Env == 'DEV') {
    return 'WV'
  }
  if (Env == 'CS-DEV') {
    return 'WV'
  }
  if (Env == 'CS-TEST') {
    return 'WV'
  }
  if (Env == 'CS-SIT') {
    return 'WV'
  }
  if (Env == 'CS-UAT') {
    return 'WVUT'
  }
  if (Env == 'CS-MPROD') {
    return 'WV'
  }
  if (Env == 'CS-MNOSTG') {
    return 'WV'
  }
  if (Env == 'CS-PREFSTG') {
    return 'WVPT'
  }
  if (Env == 'OFEMPROD') {
    return 'WV'
  }
}
