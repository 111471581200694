import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import {
  isEqual,
  includes,
  get,
  isObject,
  isEmpty,
  mergeWith,
  set,
  isNil
} from 'lodash'
import { config } from '../../../config'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'

const loadEndpoint = config['fetchDocList']
const deleteEndpoint = config['deleteUploadedDoc']
const viewEndpoint = config['viewDoc']
const uploadEndpoint = config['uploadDoc']
const submitEndpoint = config['submitAllDocs']
const formsEndpoint = config['forms_MyDocumentsAccordion']

const _ = { isEqual, includes, get, isObject, isEmpty, mergeWith, set, isNil }

interface MyDocumentsAccordionContainerProps {
  presentation: any
  uuid: string
  appIntake: string
  applicants: Array<Object>
  appId: string
  minHeight: string
  defaultDocType: number
  onDocUpload: (uploadedDoc: any) => void
  onUploadDelete: (deletedDoc: any) => void
  locale: string
  authHeader: string
}

interface MyDocumentsAccordionContainerState {
  showModal: boolean
  formData: {}
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formContext: any
  modalData: {}
  loaded: boolean
  showDeleteModal: boolean
  deleteFieldId: Array<string>
  uploadedDocsData: Array<number>
}

class MyDocumentsAccordionContainer extends React.Component<
  MyDocumentsAccordionContainerProps,
  MyDocumentsAccordionContainerState
> {
  constructor(props: MyDocumentsAccordionContainerProps) {
    super(props)

    this.state = {
      showModal: false,
      formSchema: null,
      formData: null,
      formContext: null,
      modalData: null,
      loaded: false,
      showDeleteModal: false,
      deleteFieldId: null,
      uploadedDocsData: []
    }
  }

  componentDidMount() {
    this.fetchForms()
  }

  fetchForms = () => {
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        this.setState({ formSchema: formJson }, () => {
          if (this.props.appIntake) {
            this.parseApplicantData(this.props.applicants)
          } else {
            this._loadFormData(loadEndpoint)
          }
        })
      })
      .catch(error => {
        console.error('MyDocumentsAccordion form fetch failed due to ex', error)
        const code = CODES.MY_DOCUMENTS_ACCORDION_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.appIntake && this.state.formSchema) {
      if (!_.isEqual(this.props.applicants, prevProps.applicants)) {
        this.parseApplicantData(this.props.applicants)
      }
    }

    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      this.fetchForms()
    }
  }

  parseApplicantData = (applicants: Array<any>) => {
    let docUploadIds = new Array()
    let docUploadNames = new Array()
    const myDocuments = {
      myDocuments: [
        {
          category: 'Demographics',
          accordionTitle: I18n.t('MyDocuments.demographics'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        },
        {
          category: 'Income',
          accordionTitle: I18n.t('MyDocuments.income'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        },
        {
          category: 'Assets',
          accordionTitle: I18n.t('MyDocuments.assets'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        },
        {
          category: 'Expenses',
          accordionTitle: I18n.t('MyDocuments.expenses'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        },
        {
          category: 'Additional Information',
          accordionTitle: I18n.t('MyDocuments.addInfo'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        }
      ]
    }
    for (var accordionSection of myDocuments['myDocuments']) {
      for (var applicant of applicants) {
        let applicantName = applicant.clientFstNm + ' ' + applicant.clientLstNm
        if (
          applicant.clientDetails &&
          applicant.clientDetails.personalData &&
          applicant.clientDetails.personalData.length > 0
        ) {
          applicantName =
            applicant.clientDetails.personalData[0].clientFstNm +
            ' ' +
            applicant.clientDetails.personalData[0].clientLstNm
        }
        let prtcpObj = {
          applicantName
        }
        accordionSection.participantUpload.push(prtcpObj)
        if (applicant.myDocuments) {
          for (var docClassList of applicant.myDocuments) {
            if (!_.isNil(docClassList) && !_.isNil(docClassList.docList)) {
              for (var doc of docClassList.docList) {
                let uploadedObj = {
                  prtcpName: applicantName,
                  flNm: doc.fileName,
                  docVerfNm: I18n.t('General.documentTypes.' + doc.typeRefCode),
                  docUploadId: doc.docUploadId,
                  flFmtTyp: doc.flFmtTyp,
                  uploadDt: doc.uploadDt,
                  typeRefCode: doc.typeRefCode
                }
                if (
                  (!_.isNil(docClassList) &&
                    (docClassList.documentClssName ===
                      accordionSection.category ||
                      docClassList.documentClssName ===
                        accordionSection.accordionTitle)) ||
                  (!_.isNil(doc) &&
                    (doc.documentClssName === accordionSection.category ||
                      doc.documentClssName === accordionSection.accordionTitle))
                ) {
                  accordionSection.documentUpload.push(uploadedObj)
                }
                docUploadIds.push(doc.docUploadId)
                docUploadNames.push(applicantName + ' - ' + doc.fileName)
              }
            }
          }
        }
      }
    }

    let modalData = {
      docUploadIds: docUploadIds,
      docUploadNames: docUploadNames
    }
    this._loadMyDocumentAccordionComponent(
      this.state.formSchema,
      myDocuments,
      modalData
    )
  }

  _parseJson = (json: Array<any>) => {
    const myDocuments = {
      myDocuments: [
        {
          category: 'Demographics',
          accordionTitle: I18n.t('MyDocuments.demographics'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        },
        {
          category: 'Income',
          accordionTitle: I18n.t('MyDocuments.income'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        },
        {
          category: 'Assets',
          accordionTitle: I18n.t('MyDocuments.assets'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        },
        {
          category: 'Expenses',
          accordionTitle: I18n.t('MyDocuments.expenses'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        },
        {
          category: 'Additional Information',
          accordionTitle: I18n.t('MyDocuments.addInfo'),
          participantUpload: [],
          documentUpload: [],
          documentSubmit: []
        }
      ]
    }
    let returnJson = myDocuments['myDocuments']
    let docUploadIds = new Array()
    let docUploadNames = new Array()
    for (let j = 0; j < returnJson.length; j++) {
      returnJson[j].participantUpload = this.props.applicants
      for (let i = 0; i < json.length; i++) {
        let appDoc = {
          ...json[i],
          docVerfNm: I18n.t('General.documentTypes.' + json[i].typeRefCode)
        }
        if (
          appDoc['docClssNm'] == returnJson[j]['category'] ||
          appDoc['docClssNm'] == returnJson[j]['accordionTitle']
        ) {
          if (appDoc['status'] == 'SUBMITTED') {
            if (returnJson[j]['documentSubmit'] == null) {
              returnJson[j]['documentSubmit'] = new Array()
            }
            returnJson[j]['documentSubmit'].push(appDoc)
          } else {
            if (returnJson[j]['documentUpload'] == null) {
              returnJson[j]['documentUpload'] = new Array()
            }
            returnJson[j]['documentUpload'].push(appDoc)
            docUploadIds.push(appDoc.docUploadId)
            docUploadNames.push(appDoc.prtcpName + ' - ' + appDoc.flNm)
          }
        }
      }
    }
    let modalData = {
      docUploadIds: docUploadIds,
      docUploadNames: docUploadNames
    }
    this._loadMyDocumentAccordionComponent(
      this.state.formSchema,
      myDocuments,
      modalData
    )
  }

  _viewDocDetails = (docId: string) => {
    let res = docId.split('_myDocuments_')

    let isUpload = _.includes(res[1], '_documentUpload_')
    let id
    let document
    let index
    let panelIndex
    let fileName
    if (isUpload) {
      res = res[1].split('_documentUpload_')
      panelIndex = res[0]
      id = res[1].split('_')
      index = id[0]
      document = this.state.formData['myDocuments'][panelIndex][
        'documentUpload'
      ][index]
    } else {
      res = res[1].split('_documentSubmit_')
      panelIndex = res[0]
      id = res[1].split('_')
      index = id[0]
      document = this.state.formData['myDocuments'][panelIndex][
        'documentSubmit'
      ][index]
    }

    const fetchEndpoint = viewEndpoint.replace(
      '{storId}',
      document['docUploadId']
    )
    helper
      .fetchJson(fetchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        }
      })
      .then(json => {
        let fileContent = json.fileContent
        let fileFsURL = json.url
        let fileName = json.fileName
        if (!_.isNil(fileContent)) {
          var newBase = new Buffer(fileContent, 'base64')
          var file = new Blob([newBase], {
            type: document.flFmtTyp
          })

          //trick to download store a file having its URL
          var fileURL = ''
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file)
          } else {
            fileURL = URL.createObjectURL(file)
            window.open(fileURL)
            // auto download the file
            var a = window.document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = fileName
            window.document.body.appendChild(a)
            a.click()
          }
        } else if (!_.isNil(fileFsURL)) {
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.open(fileFsURL)
          } else {
            // auto download the file
            var a = window.document.createElement('a')
            a.href = fileFsURL
            a.target = '_blank'
            a.download = fileName
            window.document.body.appendChild(a)
            a.click()
          }
        } else {
          const code = CODES.MY_DOCUMENTS_ACCORDION_FETCH_DETAILS
          const error = {
            message: 'There is no content associated to the Document requested'
          }
          throw new ClientPortalException(error, code)
        }
      })
      .catch(error => {
        console.error(
          'MyDocumentsAccordion _viewDocDetails failed with ex',
          error
        )
        const code = CODES.MY_DOCUMENTS_ACCORDION_FETCH_DETAILS
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }
  _loadFormData = (endpoint: string) => {
    const fetchEndpoint = endpoint.replace('{applId}', this.props.appId)
    helper
      .fetchJson(fetchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        }
      })
      .then(json => {
        // load json into form
        this._parseJson(json)
      })
      .catch(error => {
        const code = CODES.MY_DOCUMENTS_ACCORDION_FETCH_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _loadMyDocumentAccordionComponent = (
    formSchemaJson: any,
    formDataJson: any,
    modalData: any
  ) => {
    let formData, formContext
    formContext = {
      refs: _.get(formSchemaJson, 'metaData.refs', {}),
      forms: formSchemaJson ? formSchemaJson['forms'] : null,
      modalData: modalData,
      config
    }

    //load the pre-filled form data
    let formDataLoad = formDataJson

    if (_.isObject(formDataLoad) && !_.isEmpty(formDataLoad)) {
      //Merge the pre-filled form data with an empty default object to ensure necessary objects are available for RJSF rendering.
      formData = _.mergeWith(
        helper.createObjectFromMasterSchema(null, formContext),
        formDataLoad,
        function(objValue, srcValue) {
          if (srcValue == null && objValue == undefined) {
            return {}
          }
          return undefined
        }
      )
    } else {
      //New application, initialize to empty object
      formData = helper.createObjectFromMasterSchema(null, formContext)
    }

    //default 0 path, first accordion, first client
    if (this.props.defaultDocType) {
      _.set(
        formData,
        'myDocuments[0].participantUpload[0].docType',
        this.props.defaultDocType
      )
    }

    //Augment the context with formData
    formContext = {
      ...formContext,
      formData: formData,
      upload: this._onUploadClick,
      delete: this._openDeleteModal,
      viewDocDetails: this._viewDocDetails
    }
    this.setState({
      formData: formData,
      formContext: formContext,
      formSchema: formSchemaJson,
      modalData: modalData,
      loaded: true
    })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData
        }
      }
    })
  }

  _onDeleteClick = () => {
    this.setState({ loaded: false, showDeleteModal: false })
    let { deleteFieldId } = this.state
    let panelIndex = deleteFieldId[0]
    let id = deleteFieldId[1].split('_')
    let index = id[0]
    let document = this.state.formData['myDocuments'][panelIndex][
      'documentUpload'
    ][index]
    const fetchEndpoint = deleteEndpoint
      .replace('{storId}', document['storId'])
      .replace('{docUploadId}', document['docUploadId'])
    helper
      .fetchJson(fetchEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        }
      })
      .then(json => {
        if (this.props.appIntake) {
          const deletedDoc = {
            clientName: document['prtcpName'],
            documentClssIndex: panelIndex,
            docIndex: index
          }
          this.props.onUploadDelete(deletedDoc)
        } else {
          this._parseJson(json)
        }
      })
      .catch(error => {
        console.error(
          'MyDocumentsAccordion _onDeleteClick failed with ex',
          error
        )
        const code = CODES.MY_DOCUMENTS_ACCORDION_DELETE_DOC
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _onUploadClick = (id: string) => {
    let res = id.split('_myDocuments_')
    res = res[1].split('_participantUpload_')
    let panelIndex = res[0]
    res = res[1].split('_')
    let index = res[0]

    let participantUpload = this.state.formData['myDocuments'][panelIndex][
      'participantUpload'
    ][index]

    let accordionTitle = this.state.formData['myDocuments'][panelIndex]
      .accordionTitle

    //TODO: More elegant error handling.
    //TODO: Extract and translate these messages if acceptable.
    if (
      !_.get(participantUpload, 'file') ||
      _.isEmpty(participantUpload['file'])
    ) {
      alert('Please upload a file.')
      return
    }
    if (!_.get(participantUpload, 'docType.key')) {
      alert('Please enter the document type.')
      return
    }

    let dataURI = participantUpload['file']
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString = dataURI.split(',')[1]
    // separate out the mime component
    let mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0]
    let filename = dataURI.split(';')[1].split('=')[1]

    let data = {
      fileContent: byteString,
      applicationId: this.props.appId,
      caseId: null,
      documentCategory: participantUpload['docType'].key,
      documentType: null,
      byPassFlag: 'N',
      fileName: filename,
      clientId: participantUpload['id'],
      mimeType: mimeString
    }

    this.setState({ loaded: false })
    helper
      .fetchJson(uploadEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        },
        body: JSON.stringify(data)
      })
      .then(json => {
        if (this.props.appIntake) {
          const uploadedDoc = {
            clientIndex: index,
            documentClssIndex: panelIndex,
            documentClssName: accordionTitle,
            doc: {
              fileType: participantUpload['docType'].value,
              typeRefCode: participantUpload['docType'].key,
              fileName: participantUpload['file']
                .split(';')[1]
                .split('name=')[1],
              flFmtTyp: participantUpload['file']
                .split(';')[0]
                .split('data:')[1],
              uploadDt: json.uploadDt,
              docUploadId: json.docUploadId,
              documentClssName: accordionTitle
            }
          }
          this.props.onDocUpload(uploadedDoc)
        } else {
          this._loadFormData(loadEndpoint)
        }
      })
      .catch(error => {
        console.error(
          'MyDocumentsAccordion _onUploadClick failed with ex',
          error
        )
        const code = CODES.MY_DOCUMENTS_ACCORDION_UPLOAD_DOC
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _onSubmit = ({ formData }) => {
    this.setState({ loaded: false })
    if (formData.uploadedDocs.length != 0) {
      const fetchEndpoint = submitEndpoint.replace('{applId}', this.props.appId)
      helper
        .fetchJson(fetchEndpoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: this.props.uuid,
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            Authorization: this.props.authHeader
          },
          body: JSON.stringify(formData['uploadedDocs'])
        })
        .then(json => {
          this._close()
          this._loadFormData(loadEndpoint)
        })
        .catch(error => {
          console.error('MyDocumentsAccordion _onSubmit failed with ex', error)
          const code = CODES.MY_DOCUMENTS_ACCORDION_SUBMIT
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }

    this._close()
    this._loadFormData(loadEndpoint)
  }

  _close = () => {
    this.setState({ showModal: false })
  }

  _open = () => {
    this.setState({
      showModal: true,
      uploadedDocsData: []
    })
  }

  _closeDeleteModal = () => {
    this.setState({ showDeleteModal: false, deleteFieldId: null })
  }

  _openDeleteModal = (id: string) => {
    let res = id.split('_myDocuments_')
    res = res[1].split('_documentUpload_')
    this.setState({ showDeleteModal: true, deleteFieldId: res })
  }

  render() {
    const { presentation, minHeight, appIntake, locale } = this.props
    const {
      showModal,
      formData,
      formSchema,
      formContext,
      loaded,
      showDeleteModal,
      uploadedDocsData
    } = this.state

    const presentationProps = {
      showModal,
      formData,
      formSchema,
      formContext,
      loaded,
      onSubmit: this._onSubmit,
      onFormDataChange: this._onFormDataChange,
      appIntake,
      open: this._open,
      showDeleteModal,
      closeDeleteModal: this._closeDeleteModal,
      close: this._close,
      onDeleteClick: this._onDeleteClick,
      minHeight,
      locale,
      uploadedDocsData,
      onUploadedDocsChange: ({ formData }) => {
        this.setState({ uploadedDocsData: formData })
      }
    }

    return presentation(presentationProps)
  }
}
function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}
export default connect(mapStateToProps)(MyDocumentsAccordionContainer)
