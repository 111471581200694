const jwt_decode = require('jwt-decode')
import { config } from './config'
import {
  get,
  pick,
  isNil,
  find,
  merge,
  throttle,
  isNull,
  forEach,
  cloneDeep
} from 'lodash'
import { loadTranslations } from 'react-redux-i18n'
import { fetchJson } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'

const _ = {
  get,
  pick,
  isNil,
  find,
  merge,
  throttle,
  isNull,
  forEach,
  cloneDeep
}

declare var Promise: any

const getUserRolesEndpoint = config['getUserRoles']
const getAgenciesEndpoint = config['gatewayWvUrl'] + config['gettabs']

const authorizationTokenUrl = config['authorizationTokenEndpoint']
const authorizationUserinfoUrl = config['authorizationUserinfoEndpoint']
const refreshTokenUrl = config['refreshTokenEndpoint']
const UpdateUserInfoEndPoint =
  config['gatewayWvUrl'] + config['peUpdateUserInfo']

const logOutUrl = config['gatewayBaseUrl'] + config['logoutUrl']
//Call refresh API no more than once per five minutes.
const REFRESH_AUTH_FREQUENCY_MS = 5 * 60000
let accessToken
export function loginUser(code, checkHasPin) {
  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(code))
    return fetchJson(
      authorizationTokenUrl.replace('{authorizationCode}', code),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          portalName: config.portalName,
          tenantCode: config.tenant.code
        }
      }
    )
      .then(json => {
        //Decode and parse the JWT
        const idToken = json.id_token
        accessToken = json.access_token
        const jwt = jwt_decode(idToken)
        let userAccount = {
          uuid: jwt['sub'] || null,
          userId: jwt['preferred_username'] || null,
          firstName: jwt['given_name'] || null,
          lastName: jwt['family_name'] || null,
          dob: jwt['birthdate'] || null,
          email: jwt['email'] || null,
          defaultUserRoleId: null
        }

        const timeout = json.expires_in * 1000
        const expiry = _moment(config).valueOf() + timeout

        // Calling Update User Account
        fetchJson(authorizationUserinfoUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: jwt['sub'],
            portalName: config.portalName,
            Authorization: 'Bearer ' + accessToken,
            tenantCode: config.tenant.code
          },
          mode: 'cors'
        })
          .then(json => {
            userAccount = json.userInformation
            dispatch(setDefaultUserRoleId(userAccount.defaultUserRoleId))
            dispatch(setupUserAccess(json.userRoles, userAccount, false))
            return true
          })
          .catch(ex => {
            console.error('loginUser update account action failed with ex', ex)
          })
        let body = {
          fName: userAccount.firstName,
          lName: userAccount.lastName,
          userId: userAccount.userId,
          uuid: userAccount.uuid
        }
        fetchJson(UpdateUserInfoEndPoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: userAccount.uuid,
            portalName: config.portalName,
            Authorization: config.bearer + accessToken,
            tenantCode: config.tCode
          },
          body: JSON.stringify(body)
        })
          .then(json => {
            return true
          })
          .catch(ex => {
            console.error('loginUser update account action failed with ex', ex)
          })
        // Dispatch the success action
        dispatch(
          receiveLogin(userAccount, idToken, accessToken, expiry, timeout)
        )

        if (checkHasPin) {
          let hasPin = false
          //TODO: Call an API to determine whether they have a valid PIN.
          dispatch(setHasPin(hasPin))
        }
        return true
      })
      .catch(err => {
        console.error('loginUser action failed with ex', err)
        dispatch(loginError(err.message))
        return Promise.reject(err.message)
      })
  }
}

export function requestLogin(code) {
  return {
    type: 'LOGIN_REQUEST',
    isFetching: true,
    isAuthenticated: false,
    code
  }
}

export function receiveLogin(
  userAccount,
  idToken,
  accessToken,
  expiry,
  timeout
) {
  return {
    type: 'LOGIN_SUCCESS',
    isFetching: false,
    isAuthenticated: true,
    userAccount,
    idToken,
    accessToken,
    expiry,
    timeout
  }
}

const refreshAuthService = _.throttle(
  (dispatch, uuid, accessToken, skipNextRefresh, isInitial) => {
    if (skipNextRefresh) {
      dispatch({
        type: 'CLEAR_SKIP_NEXT_REFRESH'
      })
    } else {
      if (isInitial) {
        dispatch({
          type: 'LOGIN_REFRESH_INITIAL'
        })
      }
      fetchJson(refreshTokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + accessToken,
          portalName: config.portalName,
          uuid,
          tenantCode: config.tenant.code
        },
        body: '{}'
      })
        .then(json => {
          console.log('refreshAuthService response:', json)
          const idToken = json.id_token
          const accessToken = json.access_token
          const timeout = json.expires_in * 1000
          const expiry = _moment(config).valueOf() + timeout
          dispatch({
            type: 'LOGIN_REFRESH',
            idToken,
            accessToken,
            expiry,
            timeout
          })
        })
        .catch(err => console.error('refreshAuthService failed with ex', err))
    }
  },
  REFRESH_AUTH_FREQUENCY_MS
)

const refreshLocalhost = _.throttle((dispatch, skipNextRefresh, isInitial) => {
  if (skipNextRefresh) {
    dispatch({
      type: 'CLEAR_SKIP_NEXT_REFRESH'
    })
  } else {
    if (isInitial) {
      dispatch({
        type: 'LOGIN_REFRESH_INITIAL'
      })
    }
    dispatch({
      type: 'LOGIN_REFRESH_LOCAL',
      expiry: _moment(config).valueOf() + config.defaultExpiryMs,
      timeout: config.defaultExpiryMs
    })
  }
}, REFRESH_AUTH_FREQUENCY_MS)

export function loginRefresh(uuid, accessToken, skipNextRefresh, isInitial) {
  return dispatch => {
    if (process.env.NODE_ENV === 'production') {
      refreshAuthService(
        dispatch,
        uuid,
        accessToken,
        skipNextRefresh,
        isInitial
      )
    } else {
      refreshLocalhost(dispatch, skipNextRefresh, isInitial)
    }
  }
}

export function loginError(message) {
  return {
    type: 'LOGIN_FAILURE',
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

export function requestLogout() {
  return {
    type: 'LOGOUT_REQUEST',
    isFetching: true,
    isAuthenticated: true
  }
}

export function clearAuth() {
  return {
    type: 'CLEAR_AUTH'
  }
}

export function receiveLogout() {
  return {
    type: 'LOGOUT_SUCCESS',
    token: null,
    isFetching: false,
    isAuthenticated: false,
    userAccount: null,
    expiry: null
  }
}

export function logoutUser(userAccount = { uuid: '' }, accessToken = '') {
  return dispatch => {
    if (!_.isNull(userAccount)) {
      fetchJson(logOutUrl, {
        method: 'POST',
        headers: {
          uuid: userAccount.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: accessToken ? 'Bearer ' + accessToken : ''
        }
      })
        .then(json => {
          dispatch(requestLogout())
          dispatch(receiveLogout())
        })
        .catch(error => {
          dispatch(requestLogout())
          dispatch(receiveLogout())
          console.error('Falied to logout', error, 'endpoint: ', logOutUrl)
        })
    } else {
      dispatch(requestLogout())
      dispatch(receiveLogout())
    }
  }
}

export function fakeLogin() {
  return {
    type: 'LOGIN_SUCCESS',
    isFetching: false,
    isAuthenticated: true,
    userAccount: config.openId_localhostAccount,
    expiry: _moment(config).valueOf() + config.defaultExpiryMs,
    timeout: config.defaultExpiryMs
  }
}

export function addMyFavorite(key) {
  return {
    type: 'ADD_MY_FAVORITE',
    key
  }
}

export function myMessagesError(message) {
  return {
    type: 'UPDATE_MYMESSAGES_Error',
    message
  }
}

export function myMessagesDeleteError() {
  return {
    type: 'DELETE_MYMESSAGES_Error'
  }
}

export function errorListUpdate(errorList) {
  return {
    type: 'UPDATE_ERROR_LIST',
    errorList
  }
}

export function removeMyFavorite(key) {
  return {
    type: 'REMOVE_MY_FAVORITE',
    key
  }
}

export function updateMyCocApplicationStep(step) {
  return {
    type: 'UPDATE_MY_COC_APPLICATION-STEP',
    step
  }
}

export function addMyApplication(applId, status, applicationData) {
  return {
    type: 'ADD_MY_APPLICATION',
    applId: applId ? applId : '0',
    status,
    applicationData
  }
}

export function updateMyApplication(applId, status, applicationData) {
  return {
    type: 'UPDATE_MY_APPLICATION',
    applId,
    status,
    applicationData
  }
}

export function assignApplicationId(applId) {
  return {
    type: 'ASSIGN_APPLICATION_ID',
    applId
  }
}
export function updatedFormData(formData) {
  return {
    type: 'UPDATE_COC_FORMDATA',
    formData
  }
}

export function removeMyApplication(applId) {
  return {
    type: 'REMOVE_MY_APPLICATION',
    applId
  }
}

export function setActiveApplicationId(applId) {
  return {
    type: 'SET_ACTIVE_APPLICATION_ID',
    applId
  }
}

export function setActiveCaseId(caseId) {
  return {
    type: 'SET_ACTIVE_CASE_ID',
    caseId
  }
}

export function updateMyCaseInformation(formData) {
  return {
    type: 'UPDATE_CASE_FORMDATA',
    formData
  }
}

export function updateMyOpenCases(openCases) {
  return {
    type: 'UPDATE_OPEN_CASES',
    openCases
  }
}

export function updateMyApplicationStep(applId, step) {
  return {
    type: 'UPDATE_MY_APPLICATION_STEP',
    applId,
    step
  }
}
//clietnupdatewizardcontainer storing data before API call
export function myCaseInformation(formData) {
  return {
    type: 'UPDATE_CASE_DOCUSIGN_FORMDATA',
    formData
  }
}

export function updateMyApplicationTabStatus(applId, tabStatus) {
  return {
    type: 'UPDATE_MY_APPLICATION_TAB_STATUS',
    applId,
    tabStatus
  }
}

export function addPEApplication(applId, status, applicationData) {
  return {
    type: 'ADD_PE_APPLICATION',
    applId: applId ? applId : '0',
    status,
    applicationData
  }
}

export function updatePEApplication(applId, status, applicationData) {
  return {
    type: 'UPDATE_PE_APPLICATION',
    applId,
    status,
    applicationData
  }
}

export function assignPEApplicationId(applId) {
  return {
    type: 'ASSIGN_PE_APPLICATION_ID',
    applId
  }
}

export function removePEApplication(applId) {
  return {
    type: 'REMOVE_PE_APPLICATION',
    applId
  }
}

export function saveMaidID(maid) {
  return {
    type: 'SET_MAID_NUMBER',
    maid
  }
}

export function setActivePEApplicationId(applId) {
  return {
    type: 'SET_ACTIVE_PE_APPLICATION_ID',
    applId
  }
}

export function updatePEApplicationStep(applId, step) {
  return {
    type: 'UPDATE_PE_APPLICATION_STEP',
    applId,
    step
  }
}

export function setHasPin(hasPin) {
  return {
    type: 'SET_HAS_PIN',
    hasPin
  }
}

export function setHASCCA(hasCCA) {
  return {
    type: 'SET_HAS_CCA',
    hasCCA
  }
}

export function setCaseTypes(caseTypes) {
  return {
    type: 'SET_CASE_TYPES',
    caseTypes
  }
}

export function updateUserRoles(userRoles) {
  return {
    type: 'UPDATE_ACCESS_ROLES',
    userRoles
  }
}

export function updateOrgList(orgList) {
  return {
    type: 'UPDATE_ORG_LIST',
    orgList
  }
}

export function setSelectedUserRole(selectedUserRole) {
  return {
    type: 'UPDATE_SELECTED_ROLE',
    selectedUserRole
  }
}

export function setSelectedOrg(selectedOrg) {
  return {
    type: 'UPDATE_SELECTED_ORG',
    selectedOrg
  }
}

export function setDefaultUserRoleId(userRoleId: number) {
  return {
    type: 'SET_DEFAULT_USER_ROLE_ID',
    userRoleId
  }
}

export function setDefaultOrg(selectedOrg) {
  return {
    type: 'SET_DEFAULT_ORG',
    selectedOrg
  }
}

export function setUserRoleIdOrgList(userRoleIdOrgIdList) {
  return {
    type: 'UPDATE_USERROLEID_ORGID_LIST',
    userRoleIdOrgIdList
  }
}

export function fetchUserRoles(userAccount, accessToken = undefined) {
  return dispatch => {
    const fetchEndpoint = getUserRolesEndpoint
    fetchJson(fetchEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        uuid: userAccount.uuid,
        tenantCode: config.tenant.code,
        portalName: config.portalName,
        Authorization: accessToken ? 'Bearer ' + accessToken : ''
      }
    })
      .then(json => {
        dispatch(setupUserAccess(json, userAccount, false))
      })
      .catch(error => {
        console.error(
          'actions fetchUserRoles failed due to ex',
          error,
          'endpoint: ',
          fetchEndpoint
        )
      })
  }
}

export function setupUserAccess(json, userAccount, isTesting) {
  return dispatch => {
    if (Array.isArray(json) && json.length > 0) {
      // setup UserRoles

      let userRoles = json.map(rawRole => {
        const entitlementPaths = []
        for (let i = 0; i < _.get(rawRole, 'entitlements', []).length; ++i) {
          entitlementPaths.push('entitlements[' + i + '].entitlementId')
          entitlementPaths.push('entitlements[' + i + '].entitlementName')
          entitlementPaths.push(
            'entitlements[' + i + '].entitlementDescription'
          )
        }
        return _.pick(
          rawRole,
          'userRoleId',
          'uuid',
          'role.roleId',
          'role.roleName',
          'role.multilingualDescription',
          'role.multilingualDisplayName',
          'role.status',
          'organization.orgId',
          'organization.orgNm',
          'organization.multilingualDisplayName',
          'organization.startDate',
          'organization.endDate',
          ...entitlementPaths
        )
      })
      let selectedIds = new Array()
      let selectedElements = new Array()
      let defaultUserRoles = _.cloneDeep(userRoles)
      const newArray =
        userRoles &&
        userRoles.map((ele, index) => {
          if (selectedIds.indexOf(ele.role.roleId) === -1) {
            selectedIds.push(ele.role.roleId)
            selectedElements.push(ele)
          }
        })
      userRoles = selectedElements
      const isOnlyClient =
        userRoles.length == 1 &&
        userRoles[0].role.roleName.toLowerCase().trim() == 'client'
      dispatch(
        fetchOrgList(
          userAccount,
          userRoles,
          isTesting,
          defaultUserRoles,
          isOnlyClient
        )
      )
    } else {
      // User has no roles.
      //TODO: We should always have a client role at least, right?  Do we want to call auth to add it if is not there?
      dispatch(updateUserRoles([]))
    }
  }
}

export function fetchOrgList(
  userAccount,
  userRoles,
  isTesting,
  defaultUserRoles,
  isOnlyClient
) {
  if (isTesting) {
    return dispatch => {
      dispatch(updateUserRoles(userRoles))
    }
  }
  return dispatch => {
    const fetchEndpoint = getAgenciesEndpoint
    fetchJson(fetchEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: userAccount.sub,
        portalName: config.portalName,
        Authorization: config.bearer + accessToken,
        tenantCode: config.tCode
      }
    })
      .then(json => {
        let defaultOrg = null
        if (isOnlyClient) {
          json = {
            ProfileTabList: []
          }
        }
        userRoles = userRoles.map(userRole => {
          let orgList = []
          json &&
            json.ProfileTabList.map(orgObj => {
              if (orgObj.roleName == userRole.role.roleName) {
                orgList.push(orgObj)
              }
              if (orgObj.dfltflg == 'Y') {
                defaultOrg = orgObj
              } else {
                if (
                  json.ProfileTabList.length == 1 &&
                  json.ProfileTabList[0].dfltflg == 'N'
                ) {
                  defaultOrg = orgObj
                }
              }
            })
          return {
            ...userRole,
            orgList: orgList
          }
        })

        let newUserRoles = []
        userRoles.map(roleObj => {
          if (
            roleObj.role.roleName !== 'Client' &&
            roleObj.role.roleName !== 'Worker Admin'
          ) {
            if (roleObj.orgList && roleObj.orgList.length > 0) {
              newUserRoles.push(roleObj)
            }
          }
          if (
            roleObj.role.roleName === 'Client' ||
            roleObj.role.roleName === 'Worker Admin'
          ) {
            newUserRoles.push(roleObj)
          }
        })
        let userRoleIdOrgIdList = []
        _.forEach(json.ProfileTabList, tabs => {
          const userRole = _.find(defaultUserRoles, userRole => {
            return (
              _.get(userRole, 'organization.orgId', '') == tabs.ieOrgId &&
              _.get(userRole, 'role.roleName', '') == tabs.roleName
            )
          })
          userRoleIdOrgIdList.push({
            userRoleId: _.get(userRole, 'userRoleId', ''),
            orgId: _.get(tabs, 'ieOrgId', ''),
            rolename: _.get(tabs, 'roleName', '')
          })
        })
        dispatch(updateUserRoles(newUserRoles))
        dispatch(setUserRoleIdOrgList(userRoleIdOrgIdList))
        let selectedUserRole
        // if more than one role, then check for defaultUserRoleId
        if (userRoles.length > 1 && !_.isNil(userAccount.defaultUserRoleId)) {
          selectedUserRole = _.find(userRoles, {
            userRoleId: userAccount.defaultUserRoleId
          })
        } else if (userRoles.length === 1) {
          selectedUserRole = userRoles[0]
        }

        // setup selected UserRoles
        dispatch(setSelectedUserRole(selectedUserRole))

        dispatch(updateOrgList(json.ProfileTabList))
        dispatch(setDefaultOrg(defaultOrg))
        dispatch(setSelectedOrg(defaultOrg))
      })
      .catch(error => {
        console.error(
          'actions fetchOrgList failed due to ex',
          error,
          'endpoint: ',
          fetchEndpoint
        )
      })
  }
}
export const fakeUserRoles = [
  {
    userRoleId: 48,
    startDate: '2018-05-04T19:13:57.860Z',
    endDate: null,
    createdDate: '2018-05-04T19:13:57.860Z',
    createdUser: null,
    lastUpdateDate: null,
    lastUpdateUser: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 1,
      roleName: 'Client',
      multilingualDescription: {
        en: 'This is the description for Client',
        es: 'This is the Spanish description for Client'
      },
      multilingualDisplayName: {
        en: 'My Case',
        es: 'Spanish My Case'
      },
      lastUpdateUser: null,
      tenantId: 2,
      status: 'Active',
      startDate: '2018-05-03T15:19:41.320Z',
      endDate: null,
      createdDate: null,
      lastUpdateDate: null
    },
    entitlements: [
      {
        entitlementId: 1,
        entitlementName: ENTITLEMENTS.CLIENT_PARTICIPATION,
        entitlementDescription: null
      },
      {
        entitlementId: 4,
        entitlementName: ENTITLEMENTS.CP_VIEW_PROFILE,
        entitlementDescription: null
      },
      {
        entitlementId: 5,
        entitlementName: ENTITLEMENTS.CP_VIEW_PERMISSIONS,
        entitlementDescription: null
      },
      {
        entitlementId: 6,
        entitlementName: ENTITLEMENTS.CP_VIEW_APPLICATIONS,
        entitlementDescription: null
      },
      {
        entitlementId: 7,
        entitlementName: ENTITLEMENTS.CP_VIEW_BENEFITS,
        entitlementDescription: null
      },
      {
        entitlementId: 8,
        entitlementName: ENTITLEMENTS.CP_VIEW_TASKS,
        entitlementDescription: null
      },
      {
        entitlementId: 9,
        entitlementName: ENTITLEMENTS.CP_VIEW_APPEALS,
        entitlementDescription: null
      },
      {
        entitlementId: 10,
        entitlementName: ENTITLEMENTS.CP_VIEW_MESSAGES,
        entitlementDescription: null
      },
      {
        entitlementId: 11,
        entitlementName: ENTITLEMENTS.CP_VIEW_PROVIDERS,
        entitlementDescription: null
      },
      {
        entitlementId: 12,
        entitlementName: ENTITLEMENTS.CP_VIEW_DOCUMENTS,
        entitlementDescription: null
      },
      {
        entitlementId: 13,
        entitlementName: ENTITLEMENTS.CP_VIEW_LIFEEVENTS,
        entitlementDescription: null
      }
    ]
  },
  {
    userRoleId: 46,
    startDate: '2018-05-04T19:13:57.850Z',
    endDate: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 13,
      roleName: 'Presumptive Eligibility Worker',
      multilingualDescription: {
        en: 'This is the description for Presumptive Eligibility Worker',
        es: 'This is the Spanish description for Presumptive Eligibility Worker'
      },
      multilingualDisplayName: {
        en: 'Presumptive Eligibility Worker',
        es: 'Spanish Presumptive Eligibility Worker'
      },
      tenantId: 2,
      status: 'Active'
    },
    orgList: [
      {
        dfltflg: 'N',
        orgId: 1160,
        orgName: 'WVRJA',
        roleId: 19,
        roleName: 'Presumptive Eligibility Worker',
        roleorgId: 7065
      }
    ],
    entitlements: [
      {
        entitlementId: 2,
        entitlementName: ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT,
        entitlementDescription: null
      },
      {
        entitlementId: 4,
        entitlementName: ENTITLEMENTS.CP_VIEW_PROFILE,
        entitlementDescription: null
      },
      {
        entitlementId: 5,
        entitlementName: ENTITLEMENTS.CP_VIEW_RENEWAL,
        entitlementDescription: null
      },
      {
        entitlementId: 6,
        entitlementName: ENTITLEMENTS.CP_VIEW_APPLICATIONS,
        entitlementDescription: null
      },
      {
        entitlementId: 7,
        entitlementName: ENTITLEMENTS.CP_VIEW_DOCUMENTS,
        entitlementDescription: null
      }
    ]
  },
  {
    userRoleId: 47,
    startDate: '2018-05-04T19:13:57.860Z',
    endDate: null,
    createdDate: '2018-05-04T19:13:57.860Z',
    createdUser: null,
    lastUpdateDate: null,
    lastUpdateUser: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 12,
      roleName: 'Community Partner',
      multilingualDescription: {
        en: 'This is the description for Community Partner',
        es: 'This is the Spanish description for Community Partner'
      },
      multilingualDisplayName: {
        en: 'Community Partner',
        es: 'Spanish Community Partner'
      },
      lastUpdateUser: null,
      tenantId: 2,
      status: 'Active',
      startDate: '2018-05-03T15:19:41.320Z',
      endDate: null,
      createdDate: null,
      lastUpdateDate: null
    },
    orgList: [
      {
        dfltflg: 'N',
        orgId: 1160,
        orgName: 'WVRJA',
        roleId: 20,
        roleName: 'Community Partner',
        roleorgId: 7066
      }
    ],
    entitlements: [
      {
        entitlementId: 3,
        entitlementName: ENTITLEMENTS.COMMUNITY_PARTNERSHIP,
        entitlementDescription: null
      },
      {
        entitlementId: 14,
        entitlementName: ENTITLEMENTS.CP_VIEW_PROFILE,
        entitlementDescription: null
      },
      {
        entitlementId: 15,
        entitlementName: ENTITLEMENTS.CP_VIEW_DASHBOARD,
        entitlementDescription: null
      },
      {
        entitlementId: 16,
        entitlementName: ENTITLEMENTS.CP_VIEW_APPLICATIONS,
        entitlementDescription: null
      },
      {
        entitlementId: 17,
        entitlementName: ENTITLEMENTS.CP_VIEW_REVIEWS,
        entitlementDescription: null
      },
      {
        entitlementId: 18,
        entitlementName: ENTITLEMENTS.CP_VIEW_DOCUMENTS,
        entitlementDescription: null
      },
      {
        entitlementId: 19,
        entitlementName: ENTITLEMENTS.CP_VIEW_QUICKREPORTS,
        entitlementDescription: null
      }
    ]
  },
  {
    userRoleId: 49,
    startDate: '2018-05-04T19:13:57.860Z',
    endDate: null,
    createdDate: '2018-05-04T19:13:57.860Z',
    createdUser: null,
    lastUpdateDate: null,
    lastUpdateUser: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 999,
      roleName: 'Some Other Role',
      multilingualDescription: {
        en: 'Some Other Role',
        es: 'Spanish Some Other Role'
      },
      multilingualDisplayName: {
        en: 'Some Other Role',
        es: 'Spanish Some Other Role'
      },
      lastUpdateUser: null,
      tenantId: 2,
      status: 'Active',
      startDate: '2018-05-03T15:19:41.320Z',
      endDate: null,
      createdDate: null,
      lastUpdateDate: null
    },
    entitlements: []
  },
  {
    userRoleId: 50,
    startDate: '2018-05-04T19:13:57.860Z',
    endDate: null,
    createdDate: '2018-05-04T19:13:57.860Z',
    createdUser: null,
    lastUpdateDate: null,
    lastUpdateUser: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 2,
      roleName: 'Client',
      multilingualDescription: {
        en: 'This is the description for Client',
        es: 'This is the Spanish description for Client'
      },
      multilingualDisplayName: {
        en: 'My Case',
        es: 'Spanish My Case'
      },
      lastUpdateUser: null,
      tenantId: 1,
      status: 'Active',
      startDate: '2018-05-03T15:19:41.320Z',
      endDate: null,
      createdDate: null,
      lastUpdateDate: null
    },
    entitlements: [
      {
        entitlementId: 1,
        entitlementName: ENTITLEMENTS.CLIENT_PARTICIPATION,
        entitlementDescription: null
      },
      {
        entitlementId: 4,
        entitlementName: ENTITLEMENTS.CP_VIEW_PROFILE,
        entitlementDescription: null
      },
      {
        entitlementId: 5,
        entitlementName: ENTITLEMENTS.CP_VIEW_PERMISSIONS,
        entitlementDescription: null
      },
      {
        entitlementId: 6,
        entitlementName: ENTITLEMENTS.CP_VIEW_APPLICATIONS,
        entitlementDescription: null
      },
      {
        entitlementId: 7,
        entitlementName: ENTITLEMENTS.CP_VIEW_BENEFITS,
        entitlementDescription: null
      },
      {
        entitlementId: 8,
        entitlementName: ENTITLEMENTS.CP_VIEW_TASKS,
        entitlementDescription: null
      },
      {
        entitlementId: 9,
        entitlementName: ENTITLEMENTS.CP_VIEW_APPEALS,
        entitlementDescription: null
      },
      {
        entitlementId: 10,
        entitlementName: ENTITLEMENTS.CP_VIEW_MESSAGES,
        entitlementDescription: null
      },
      {
        entitlementId: 11,
        entitlementName: ENTITLEMENTS.CP_VIEW_PROVIDERS,
        entitlementDescription: null
      },
      {
        entitlementId: 12,
        entitlementName: ENTITLEMENTS.CP_VIEW_DOCUMENTS,
        entitlementDescription: null
      },
      {
        entitlementId: 13,
        entitlementName: ENTITLEMENTS.CP_VIEW_LIFEEVENTS,
        entitlementDescription: null
      }
    ]
  },
  {
    userRoleId: 51,
    startDate: '2018-05-04T19:13:57.850Z',
    endDate: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 20,
      roleName: 'Presumptive Eligibility Worker',
      multilingualDescription: {
        en: 'This is the description for Presumptive Eligibility Worker',
        es: 'This is the Spanish description for Presumptive Eligibility Worker'
      },
      multilingualDisplayName: {
        en: 'Presumptive Eligibility Worker',
        es: 'Spanish Presumptive Eligibility Worker'
      },
      tenantId: 1,
      status: 'Active'
    },
    entitlements: [
      {
        entitlementId: 2,
        entitlementName: ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT,
        entitlementDescription: null
      },
      {
        entitlementId: 4,
        entitlementName: ENTITLEMENTS.CP_VIEW_PROFILE,
        entitlementDescription: null
      },
      {
        entitlementId: 5,
        entitlementName: ENTITLEMENTS.CP_VIEW_RENEWAL,
        entitlementDescription: null
      },
      {
        entitlementId: 6,
        entitlementName: ENTITLEMENTS.CP_VIEW_APPLICATIONS,
        entitlementDescription: null
      },
      {
        entitlementId: 7,
        entitlementName: ENTITLEMENTS.CP_VIEW_DOCUMENTS,
        entitlementDescription: null
      }
    ],
    organization: {
      orgId: 1,
      orgNm: 'Test Org',
      multilingualDisplayName: {
        en: 'Test Org',
        es: 'Spanish Test Org'
      },
      tenantId: 1,
      startDate: '2018-05-04T19:13:57.850Z',
      endDate: null
    }
  },
  {
    userRoleId: 52,
    startDate: '2018-05-04T19:13:57.860Z',
    endDate: null,
    createdDate: '2018-05-04T19:13:57.860Z',
    createdUser: null,
    lastUpdateDate: null,
    lastUpdateUser: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 21,
      roleName: 'Community Partner',
      multilingualDescription: {
        en: 'This is the description for Community Partner',
        es: 'This is the Spanish description for Community Partner'
      },
      multilingualDisplayName: {
        en: 'Community Partner',
        es: 'Spanish Community Partner'
      },
      lastUpdateUser: null,
      tenantId: 1,
      status: 'Active',
      startDate: '2018-05-03T15:19:41.320Z',
      endDate: null,
      createdDate: null,
      lastUpdateDate: null
    },
    entitlements: [
      {
        entitlementId: 3,
        entitlementName: ENTITLEMENTS.COMMUNITY_PARTNERSHIP,
        entitlementDescription: null
      },
      {
        entitlementId: 14,
        entitlementName: ENTITLEMENTS.CP_VIEW_PROFILE,
        entitlementDescription: null
      },
      {
        entitlementId: 15,
        entitlementName: ENTITLEMENTS.CP_VIEW_DASHBOARD,
        entitlementDescription: null
      },
      {
        entitlementId: 16,
        entitlementName: ENTITLEMENTS.CP_VIEW_APPLICATIONS,
        entitlementDescription: null
      },
      {
        entitlementId: 17,
        entitlementName: ENTITLEMENTS.CP_VIEW_REVIEWS,
        entitlementDescription: null
      },
      {
        entitlementId: 18,
        entitlementName: ENTITLEMENTS.CP_VIEW_DOCUMENTS,
        entitlementDescription: null
      },
      {
        entitlementId: 19,
        entitlementName: ENTITLEMENTS.CP_VIEW_QUICKREPORTS,
        entitlementDescription: null
      }
    ]
  },
  {
    userRoleId: 53,
    startDate: '2018-05-04T19:13:57.860Z',
    endDate: null,
    createdDate: '2018-05-04T19:13:57.860Z',
    createdUser: null,
    lastUpdateDate: null,
    lastUpdateUser: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 22,
      roleName: 'Some Other Role',
      multilingualDescription: {
        en: 'Some Other Role',
        es: 'Spanish Some Other Role'
      },
      multilingualDisplayName: {
        en: 'Some Other Role',
        es: 'Spanish Some Other Role'
      },
      lastUpdateUser: null,
      tenantId: 1,
      status: 'Active',
      startDate: '2018-05-03T15:19:41.320Z',
      endDate: null,
      createdDate: null,
      lastUpdateDate: null
    },
    entitlements: []
  },
  {
    userRoleId: 840,
    startDate: '2018-05-04T19:13:57.860Z',
    endDate: null,
    createdDate: '2018-05-04T19:13:57.860Z',
    createdUser: null,
    lastUpdateDate: null,
    lastUpdateUser: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 14,
      roleName: 'PE Admin',
      multilingualDescription: {
        en: 'PE Admin',
        es: 'TRANSLATE: PE Admin'
      },
      multilingualDisplayName: {
        en: 'PE Admin',
        es: 'TRANSLATE: PE Admin'
      },
      lastUpdateUser: null,
      tenantId: 1,
      status: 'Active',
      startDate: '2018-05-03T15:19:41.320Z',
      endDate: null,
      createdDate: null,
      lastUpdateDate: null
    },
    entitlements: [
      {
        entitlementDescription: 'Allows the role to community partnership tabs',
        entitlementId: 22,
        entitlementName: 'CP_USER_ACCESS'
      },
      {
        entitlementDescription:
          'Allows the role to view the Dashboard tab on the My Account page.',
        entitlementId: 15,
        entitlementName: 'CP_VIEW_DASHBOARD'
      },
      {
        entitlementDescription:
          'Allows the role to view the Applications tab on the My Account page.',
        entitlementId: 16,
        entitlementName: 'CP_VIEW_APPLICATIONS'
      },
      {
        entitlementDescription:
          'Allows the role to view the Profile tab on the My Account page.',
        entitlementId: 4,
        entitlementName: 'CP_VIEW_PROFILE'
      },
      {
        entitlementDescription:
          'Allows the role to submit presumptive eligibility applications on behalf of others.',
        entitlementId: 2,
        entitlementName: 'PRESUMPTIVE_ELIGIBILITY_MANAGEMENT'
      },
      {
        entitlementDescription:
          'Allows the role to submit presumptive eligibility applications on behalf of others',
        entitlementId: 75,
        entitlementName: 'PRESUMPTIVE_ELIGIBILITY_MANAGEMENT'
      },
      {
        entitlementDescription:
          'Allows the role to create user roles in client portal',
        entitlementId: 66,
        entitlementName: 'CP_USER_ACCESS'
      },
      {
        entitlementDescription:
          'Allows the role to view the Applications tab on the My Account page',
        entitlementId: 71,
        entitlementName: 'CP_VIEW_APPLICATIONS'
      },
      {
        entitlementDescription:
          'Allows the role to view the Profile tab on the My Account page',
        entitlementId: 72,
        entitlementName: 'CP_VIEW_PROFILE'
      },
      {
        entitlementDescription:
          'Allows the role to view the Dashboard tab on the My Account page',
        entitlementId: 70,
        entitlementName: 'CP_VIEW_DASHBOARD'
      },
      {
        entitlementDescription:
          'Allows the role to manage organization in client portal',
        entitlementId: 67,
        entitlementName: 'CP_MANAGE_ORGANIZATION'
      }
    ],
    orgList: [
      {
        dfltflg: 'N',
        orgId: 1160,
        orgName: 'WVRJA',
        roleId: 14,
        roleName: 'PE Admin',
        roleorgId: 7064
      }
    ]
  },
  {
    userRoleId: 1045,
    startDate: '2018-05-04T19:13:57.860Z',
    endDate: null,
    createdDate: '2018-05-04T19:13:57.860Z',
    createdUser: null,
    lastUpdateDate: null,
    lastUpdateUser: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 40,
      roleName: 'CP Admin',
      multilingualDescription: {
        en: 'CP Admin',
        es: 'TRANSLATE: CP Admin'
      },
      multilingualDisplayName: {
        en: 'CP Admin',
        es: 'TRANSLATE: CP Admin'
      },
      lastUpdateUser: null,
      tenantId: 1,
      status: 'Active',
      startDate: '2018-05-03T15:19:41.320Z',
      endDate: null,
      createdDate: null,
      lastUpdateDate: null
    },
    entitlements: [
      {
        entitlementDescription: 'Allows the role to community partnership tabs',
        entitlementId: 21,
        entitlementName: 'CP Admin'
      },
      {
        entitlementDescription: 'Allows the role to community partnership tabs',
        entitlementId: 22,
        entitlementName: 'CP_USER_ACCESS'
      },
      {
        entitlementDescription: 'Allows the role to community partnership tabs',
        entitlementId: 23,
        entitlementName: 'CP_MANAGE_ORGANIZATION'
      },
      {
        entitlementDescription: 'Allows the role to community partnership tabs',
        entitlementId: 54,
        entitlementName: 'CP Admin'
      },
      {
        entitlementDescription:
          'Allows the role to create user roles in client portal',
        entitlementId: 55,
        entitlementName: 'CP_USER_ACCESS'
      },
      {
        entitlementDescription:
          'Allows the role to manage organization in client portal',
        entitlementId: 56,
        entitlementName: 'CP_MANAGE_ORGANIZATION'
      },
      {
        entitlementDescription: 'Allows the role to do search on Applications',
        entitlementId: 57,
        entitlementName: 'CP_APPLICATION_SEARCH'
      },
      {
        entitlementDescription:
          'Allows the role to view the Quick Reports tab on the My Account page',
        entitlementId: 58,
        entitlementName: 'CP_VIEW_QUICKREPORTS'
      },
      {
        entitlementDescription:
          'Allows the role to view the Dashboard tab on the My Account page',
        entitlementId: 59,
        entitlementName: 'CP_VIEW_DASHBOARD'
      },
      {
        entitlementDescription:
          'Allows the role to view the Applications tab on the My Account page',
        entitlementId: 60,
        entitlementName: 'CP_VIEW_APPLICATIONS'
      },
      {
        entitlementDescription:
          'Allows the role to view the Profile tab on the My Account page',
        entitlementId: 61,
        entitlementName: 'CP_VIEW_PROFILE'
      },
      {
        entitlementDescription:
          'Allows the role to view the Renewal tab on the My Account page',
        entitlementId: 62,
        entitlementName: 'CP_VIEW_RENEWAL'
      },
      {
        entitlementDescription:
          'Allows the role to view the Profile tab on the My Account page.',
        entitlementId: 4,
        entitlementName: 'CP_VIEW_PROFILE'
      }
    ],
    orgList: [
      {
        dfltflg: 'N',
        orgId: 1160,
        orgName: 'WVRJA',
        roleId: 40,
        roleName: 'CP Admin',
        roleorgId: 7110
      }
    ]
  },
  {
    userRoleId: 1182,
    startDate: '2019-02-28T16:38:23.327Z',
    endDate: null,
    createdDate: '2019-02-28T16:38:23.327Z',
    createdUser: null,
    lastUpdateDate: '2019-05-10T20:30:37.953Z',
    lastUpdateUser: null,
    uuid: '1e7836ec-e0a2-409e-ba27-176c401b22fe',
    role: {
      roleId: 43,
      roleName: 'Worker Admin',
      roleDescription: 'WV Client',
      createdUser: 'SYSTEM',
      lastUpdateUser: null,
      tenantId: 3,
      status: 'Active',
      numUsers: null,
      displayName: 'My Case',
      startDate: '2018-12-06T21:29:42.213Z',
      endDate: null,
      createdDate: '2019-02-18T21:45:34.537Z',
      lastUpdateDate: '2018-12-18T16:35:08.743Z',
      multilingualDescription: {
        en: 'WV Client',
        es: 'TRANSLATE: WV Client'
      },
      multilingualDisplayName: {
        en: 'My Case',
        es: 'TRANSLATE: My Case'
      },
      isOrgRequired: true
    },
    entitlements: [
      {
        entitlementId: 74,
        entitlementName: 'CP_WORKER_ADMIN',
        entitlementDescription: 'Allows the role to worker admin tabs',
        createdUser: 'SYSTEM',
        lastUpdateUser: 'SYSTEM',
        tenantId: 3,
        category: 'Client Portal - My Accounts Tab',
        createdDate: '2019-03-19T17:55:51.817Z',
        lastUpdateDate: '2019-03-19T17:55:51.817Z'
      },
      {
        entitlementId: 66,
        entitlementName: 'CP_USER_ACCESS',
        entitlementDescription:
          'Allows the role to create user roles in client portal',
        createdUser: 'SYSTEM',
        lastUpdateUser: 'SYSTEM',
        tenantId: 3,
        category: 'Client Portal - My Accounts Tab',
        createdDate: '2019-03-19T17:55:51.817Z',
        lastUpdateDate: '2019-03-19T17:55:51.817Z'
      },
      {
        entitlementId: 67,
        entitlementName: 'CP_MANAGE_ORGANIZATION',
        entitlementDescription:
          'Allows the role to manage organization in client portal',
        createdUser: 'SYSTEM',
        lastUpdateUser: 'SYSTEM',
        tenantId: 3,
        category: 'Client Portal - My Accounts Tab',
        createdDate: '2019-03-19T17:55:51.817Z',
        lastUpdateDate: '2019-03-19T17:55:51.817Z'
      },
      {
        entitlementId: 1,
        entitlementName: 'CLIENT_PARTICIPATION',
        entitlementDescription:
          'Allows the role to submit applications, receive benefits they are eligible for, and manage their account.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: null,
        category: 'Primary',
        createdDate: '2018-11-16T15:21:42.797Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 4,
        entitlementName: 'CP_VIEW_PROFILE',
        entitlementDescription:
          'Allows the role to view the Profile tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: 5,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.797Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 5,
        entitlementName: 'CP_VIEW_PERMISSIONS',
        entitlementDescription:
          'Allows the role to view the Permissions tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: null,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.797Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 16,
        entitlementName: 'CP_VIEW_APPLICATIONS',
        entitlementDescription:
          'Allows the role to view the Applications tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: 5,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.810Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 7,
        entitlementName: 'CP_VIEW_BENEFITS',
        entitlementDescription:
          'Allows the role to view the Benefits tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: null,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.797Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 8,
        entitlementName: 'CP_VIEW_TASKS',
        entitlementDescription:
          'Allows the role to view the Tasks tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: null,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.810Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 9,
        entitlementName: 'CP_VIEW_APPEALS',
        entitlementDescription:
          'Allows the role to view the Appeals tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: null,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.810Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 10,
        entitlementName: 'CP_VIEW_MESSAGES',
        entitlementDescription:
          'Allows the role to view the Messages tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: null,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.810Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 11,
        entitlementName: 'CP_VIEW_PROVIDERS',
        entitlementDescription:
          'Allows the role to view the Providers tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: null,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.810Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 13,
        entitlementName: 'CP_VIEW_LIFEEVENTS',
        entitlementDescription:
          'Allows the role to view the Life Events tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: null,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.810Z',
        lastUpdateDate: null
      },
      {
        entitlementId: 18,
        entitlementName: 'CP_VIEW_DOCUMENTS',
        entitlementDescription:
          'Allows the role to view the Documents tab on the My Account page.',
        createdUser: 'SYSTEM',
        lastUpdateUser: null,
        tenantId: null,
        category: 'Client Portal - My Account Tabs',
        createdDate: '2018-11-16T15:21:42.810Z',
        lastUpdateDate: null
      }
    ],
    organization: null,
    userId: null
  },
  {
    userRoleId: 1045,
    startDate: '2018-05-04T19:13:57.860Z',
    endDate: null,
    createdDate: '2018-05-04T19:13:57.860Z',
    createdUser: null,
    lastUpdateDate: null,
    lastUpdateUser: null,
    uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
    role: {
      roleId: 40,
      roleName: 'CP Admin',
      multilingualDescription: {
        en: 'CP Admin',
        es: 'TRANSLATE: CP Admin'
      },
      multilingualDisplayName: {
        en: 'CP Admin',
        es: 'TRANSLATE: CP Admin'
      },
      lastUpdateUser: null,
      tenantId: 1,
      status: 'Active',
      startDate: '2018-05-03T15:19:41.320Z',
      endDate: null,
      createdDate: null,
      lastUpdateDate: null
    },
    entitlements: [
      {
        entitlementDescription: 'Allows the role to community partnership tabs',
        entitlementId: 21,
        entitlementName: 'CP Admin'
      },
      {
        entitlementDescription: 'Allows the role to community partnership tabs',
        entitlementId: 22,
        entitlementName: 'CP_USER_ACCESS'
      },
      {
        entitlementDescription: 'Allows the role to community partnership tabs',
        entitlementId: 23,
        entitlementName: 'CP_MANAGE_ORGANIZATION'
      },
      {
        entitlementDescription: 'Allows the role to community partnership tabs',
        entitlementId: 54,
        entitlementName: 'CP Admin'
      },
      {
        entitlementDescription:
          'Allows the role to create user roles in client portal',
        entitlementId: 55,
        entitlementName: 'CP_USER_ACCESS'
      },
      {
        entitlementDescription:
          'Allows the role to manage organization in client portal',
        entitlementId: 56,
        entitlementName: 'CP_MANAGE_ORGANIZATION'
      },
      {
        entitlementDescription: 'Allows the role to do search on Applications',
        entitlementId: 57,
        entitlementName: 'CP_APPLICATION_SEARCH'
      },
      {
        entitlementDescription:
          'Allows the role to view the Quick Reports tab on the My Account page',
        entitlementId: 58,
        entitlementName: 'CP_VIEW_QUICKREPORTS'
      },
      {
        entitlementDescription:
          'Allows the role to view the Dashboard tab on the My Account page',
        entitlementId: 59,
        entitlementName: 'CP_VIEW_DASHBOARD'
      },
      {
        entitlementDescription:
          'Allows the role to view the Applications tab on the My Account page',
        entitlementId: 60,
        entitlementName: 'CP_VIEW_APPLICATIONS'
      },
      {
        entitlementDescription:
          'Allows the role to view the Profile tab on the My Account page',
        entitlementId: 61,
        entitlementName: 'CP_VIEW_PROFILE'
      },
      {
        entitlementDescription:
          'Allows the role to view the Renewal tab on the My Account page',
        entitlementId: 62,
        entitlementName: 'CP_VIEW_RENEWAL'
      },
      {
        entitlementDescription:
          'Allows the role to view the Profile tab on the My Account page.',
        entitlementId: 4,
        entitlementName: 'CP_VIEW_PROFILE'
      }
    ],
    orgList: [
      {
        dfltflg: 'N',
        orgId: 1164,
        orgName: 'PEUSER Agency',
        roleId: 40,
        roleName: 'CP Admin',
        roleorgId: 7082
      }
    ]
  }
]

export const fakeOrgList = [
  {
    dfltflg: 'N',
    orgId: 1160,
    orgName: 'WVRJA',
    roleId: 14,
    roleName: 'PE Admin',
    roleorgId: 7064
  },
  {
    dfltflg: 'N',
    orgId: 1160,
    orgName: 'WVRJA',
    roleId: 19,
    roleName: 'Presumptive Eligibility Worker',
    roleorgId: 7065
  },
  {
    dfltflg: 'N',
    orgId: 1160,
    orgName: 'WVRJA',
    roleId: 20,
    roleName: 'Community Partner',
    roleorgId: 7066
  },
  {
    dfltflg: 'N',
    orgId: 1160,
    orgName: 'WVRJA',
    roleId: 23,
    roleName: 'Worker Admin',
    roleorgId: 7067
  },
  {
    dfltflg: 'N',
    orgId: 1160,
    orgName: 'WVRJA',
    roleId: 40,
    roleName: 'CP Admin',
    roleorgId: 7110
  },
  {
    dfltflg: 'N',
    orgId: 1164,
    orgName: 'PEUSER Agency',
    roleId: 40,
    roleName: 'CP Admin',
    roleorgId: 7082
  }
]

export function fakeUserAccessSetup(): any {
  return dispatch => {
    const userAccount = config.openId_localhostAccount

    dispatch(setupUserAccess(fakeUserRoles, userAccount, true))
    return true
  }
}

export function getStaticTranslations(locales): any {
  return dispatch => {
    const endpoints = [
      ...locales.map(locale => {
        return (config['SharedStaticTranslation'] + '.' + locale).replace(
          '{version}',
          '1.0'
        )
      }),
      ...locales.map(locale => {
        return (config['CPStaticTranslation'] + '.' + locale).replace(
          '{version}',
          '1.0'
        )
      })
    ]
    const apiRequests = endpoints.map(endpoint => {
      return fetchJson(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName
        }
      })
        .then(json => {
          return json
        })
        .catch(error => {
          console.error(
            'getTranslations failed at endpoint ' + endpoint + ' due to error:',
            error
          )
        })
    })
    Promise.all(apiRequests).then(results => {
      const translationsObject = {}
      for (let i = 0; i < locales.length; ++i) {
        translationsObject[locales[i]] = _.merge(
          {},
          results[i],
          results[locales.length + i]
        )
      }
      dispatch(loadTranslations(translationsObject))
    })
  }
}

export function viewPEApplications() {
  return {
    type: 'VIEW_APPLICATION'
  }
}

export function myBenefitsFormData(formData) {
  return {
    type: 'MY_BENIFITS_FORMDATA',
    formData
  }
}

export function clearApplications() {
  return {
    type: 'CLEAR_APPLICATIONS'
  }
}

export function clearApplicationsID() {
  return {
    type: 'CLEAR_APPLICATIONS_ID'
  }
}

export function updateCase(caseNumber) {
  return {
    type: 'UPDATE_CASE',
    caseNumber
  }
}

export function updatecsCases(csCases) {
  return {
    type: 'UPDATE_CS_CASES',
    csCases
  }
}

export function addReferralId(referralId) {
  return {
    type: 'ADD_REFERRAL',
    referralId
  }
}

export function removeReferralId() {
  return {
    type: 'REMOVE_REFERRAL'
  }
}

export function addEligibleId(eligibleId) {
  return {
    type: 'ADD_ELIGIBLE',
    eligibleId
  }
}

export function removeEligibleId() {
  return {
    type: 'REMOVE_ELIGIBLE'
  }
}

export function getStates(states) {
  return {
    type: 'GET_STATES',
    states
  }
}

export function getCounties(counties) {
  return {
    type: 'GET_COUNTIES',
    counties
  }
}
