import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form' // json schema form library
import { isEmpty } from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { I18n } from 'react-redux-i18n'
import { ErrorModal } from '../src/components/natives/ErrorModal'

const _ = { isEmpty }

interface MyAppDraftsPresentationProps {
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formData: any
  formContext: any
  sendToApplication: (appId: string) => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  locale: string
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function MyAppDraftsPresentation(
  props: MyAppDraftsPresentationProps
) {
  const {
    formSchema,
    formData,
    formContext,
    sendToApplication,
    formComponents,
    locale,
    errorMessage,
    onDeleteClick
  } = props

  if (formSchema != null && formData != null && !_.isEmpty(formData)) {
    return (
      <div id="mydrafts-content" tabIndex={-1}>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <table style={{ width: '90%' }}>
          <tbody>
            <tr>
              <td>
                <h3>
                  <span className="glyphicon glyphicon-folder-open" />
                  &nbsp;&nbsp; {I18n.t('MyAppDrafts.title')}
                </h3>
              </td>
            </tr>
            <tr>
              <td>
                <h5>
                  {I18n.t('MyAppDrafts.moreDrafts')}
                  <br />
                </h5>
              </td>
              <td>
                <div className="text-right">
                  {I18n.t('MyAppDrafts.startNewApplication')} &nbsp;&nbsp;
                  <button
                    className="btn btn-primary goto-focus"
                    onClick={() => sendToApplication(null)}
                  >
                    {I18n.t('General.go')}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Form
          schema={formSchema.schema}
          uiSchema={formSchema.uiSchema}
          formData={formData}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          formContext={formContext}
          locale={locale}
        >
          <button type="submit" className="sr-only">
            {I18n.t('General.submitButton')}
          </button>
        </Form>
      </div>
    )
  }
  return (
    <div>
      <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
      <ScreenPreLoader loaded={false} />
    </div>
  )
}
