import * as React from 'react'
import * as _ from 'lodash'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { getServerTime } from '../src/components/utils'
const moment = require('moment-mini-ts')
const moment_timezone = require('moment-timezone')
import { config } from '~/config'
import { I18n } from 'react-redux-i18n'

// Code changes for GPRB0359133 and US2558381
export function validBirthDateCheck(birthdate, currentDate) {
  let currESTDT = moment_timezone
    .tz(currentDate, 'America/New_York')
    .format('YYYY-MM-DD')
  if (birthdate < '1900-01-01' || birthdate > currESTDT) {
    return true
  }
  return false
}
// Code Changes GPRB0359078 and US2554056
export function validZipCodeCheck(zip) {
  if (String(zip).length > 0 && String(zip).length < 5) {
    return true
  }
}

// Code Changes GPRB0359223 and US2819645
export function validSSN(ssn) {
  let temp = ssn
  if (ssn.length > 0 && ssn.length < 9) {
    return false
  }
  if (
    temp.substring(0, 3) === '000' ||
    (temp.substring(0, 3) >= '900' && temp.substring(0, 3) <= '999') ||
    temp.substring(0, 3) === '666'
  ) {
    return false
  }
  if (temp.substring(3, 5) === '00' || temp.substring(5, 9) === '0000') {
    return false
  }
  return true
}

export const customValidationErrors = (
  formContext,
  errors,
  formData,
  id,
  trackerName,
  errorsJson
) => {
  const clientsArray = formContext.formData.application.clients.map(
    (client, index) => {
      const name =
        client.clientDetails.personalData[0].clientFstNm &&
        client.clientDetails.personalData[0].clientLstNm
          ? client.clientDetails.personalData[0].clientFstNm +
            ' ' +
            client.clientDetails.personalData[0].clientLstNm +
            '-'
          : 'Person ' + (index + 1) + '-'
      return name
    }
  )
  const applicantName = formContext.formData.application.clients.map(
    (client, index) => {
      const name =
        client.clientDetails.personalData[0].clientFstNm &&
        client.clientDetails.personalData[0].clientLstNm
          ? client.clientDetails.personalData[0].clientFstNm +
            ' ' +
            client.clientDetails.personalData[0].clientLstNm
          : ' '
      return name
    }
  )

  const errorId =
    id.match('(?:.*)clients_([0-9]+)_?') &&
    id.match('(?:.*)clients_([0-9]+)_?')[1]
  if (id.match('_clientAdditionalInformation_disability')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['disabilityBenefits'] === undefined ||
          _.trim(row['disabilityBenefits']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_disabilityBenefits'
        ) &&
        formContext.TANF
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].disabilityBenefits',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['disabilityBenefits']
        })
      }
      if (
        _.get(row, 'disabilityType.key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_disabilityType'
        ) &&
        formContext.TANF
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].disabilityType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['disabilityType']
        })
      }
      if (
        (row['disabilityStartDate'] === undefined ||
          _.trim(row['disabilityStartDate']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_disabilityStartDate'
        ) &&
        formContext.TANF
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].disabilityStartDate',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['disabilityStartDate']
        })
      }
    })
  }
  if (id.match('_clientAdditionalInformation_pregnancy')) {
    const errorId =
      id.match('(?:.*)clients_([0-9]+)_?') &&
      id.match('(?:.*)clients_([0-9]+)_?')[1]

    _.forEach(formData, (row, index) => {
      const clientPersonalDetails = _.get(
        formContext.formData,
        `application.clients[${errorId}].clientDetails.personalData[0]`,
        []
      )
      let genderCheck = clientPersonalDetails.gdrCd
      let femaleOverTen
      let birthdate = _moment(config, clientPersonalDetails.clientBthDt)
      let cur = _moment(config, formContext.currentDate)
      if (cur.diff(birthdate, 'years') >= 10) {
        femaleOverTen = true
      } else {
        femaleOverTen = false
      }

      if (
        (row['anticipatedDueDate'] === undefined ||
          _.trim(row['anticipatedDueDate']) === '') &&
        _.get(genderCheck, 'key') != 'GDGEMA83644' &&
        femaleOverTen &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_anticipatedDueDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].anticipatedDueDate',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['anticipatedDueDate']
        })
      }
      if (
        (row['numberOfBabies'] === undefined ||
          _.trim(row['numberOfBabies']) === '') &&
        _.get(genderCheck, 'key') != 'GDGEMA83644' &&
        femaleOverTen &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_numberOfBabies'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].numberOfBabies',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['numberOfBabies']
        })
      }
    })
  }
  if (id.match('clientAdditionalInformation_SPECIALNEEDS')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['specialNeedsDescription'] === undefined ||
          _.trim(row['specialNeedsDescription']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_specialNeedsDescription'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].specialNeedsDescription',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            errorsJson['SPECIALNEEDS_specialNeedsDescription']
        })
      }
    })
  }
  if (id.match('clientAdditionalInformation_education')) {
    _.forEach(formData, (row, index) => {
      if (
        _.get(row['levelOfEducation'], 'key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_levelOfEducation'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].levelOfEducation',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['levelOfEducation']
        })
      }
      if (
        _.get(row['enrolledRightNow'], 'key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_enrolledRightNow'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].enrolledRightNow',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['enrolledRightNow']
        })
      }

      if (
        _.get(row['enrolledRightNow'], 'key') !== 'ENSTNO72634' &&
        (row['nameOfTheSchool'] === undefined ||
          _.trim(row['nameOfTheSchool']) == '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_nameOfTheSchool'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].nameOfTheSchool',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['nameOfTheSchool']
        })
      }
      if (
        _.get(row['enrolledRightNow'], 'key') !== 'ENSTNO72634' &&
        _.get(row['typeOfSchool'], 'key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_typeOfSchool'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].typeOfSchool',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['typeOfSchool']
        })
      }

      if (
        _.get(row['enrolledRightNow'], 'key') !== 'ENSTNO72634' &&
        (_.get(row, 'exptGrdDt') === undefined ||
          _.trim(row, 'exptGrdDt') === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_exptGrdDt'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].exptGrdDt',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage1']
        })
      }
    })
  }
  if (id.match('clientAdditionalInformation_ChildCareDetails')) {
    _.forEach(formData, (row, index) => {
      if (
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_schoolSchedule_0_sunday'
        )
      ) {
        _.forEach(row.schoolSchedule, (school, innerIndex) => {
          if (
            !(
              school['monday'] ||
              school['tuesday'] ||
              school['wednesday'] ||
              school['thursday'] ||
              school['friday'] ||
              school['saturday'] ||
              school['sunday']
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].schoolSchedule[' +
                innerIndex +
                '].sunday',
              message: I18n.t('Validation.requiredFieldError'),
              stack: clientsArray[errorId] + errorsJson['errorMessage2']
            })
          }
        })
      }

      if (
        (!row['childCareReason'] ||
          (row['childCareReason'] && row['childCareReason'].length === 0)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_childCareReason'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].childCareReason',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage3']
        })
      }
      if (
        (row['currentOrFormerMilitary'] === undefined ||
          _.trim(row['currentOrFormerMilitary']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_currentOrFormerMilitary'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].currentOrFormerMilitary',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage4']
        })
      }
      if (
        (_.get(row['militaryBranchName'], 'key') == undefined ||
          !row['militaryBranchName']) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_militaryBranchName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].militaryBranchName',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage5']
        })
      }
      if (
        (row['militaryStartDate'] === undefined ||
          _.trim(row['militaryStartDate']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_militaryStartDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].militaryStartDate',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage6']
        })
      }
      if (
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_wvWorkSchedule_0_sunday'
        )
      ) {
        _.forEach(row.wvWorkSchedule, (school, innerIndex) => {
          if (
            !(
              school['monday'] ||
              school['tuesday'] ||
              school['wednesday'] ||
              school['thursday'] ||
              school['friday'] ||
              school['saturday'] ||
              school['sunday']
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].wvWorkSchedule[' +
                innerIndex +
                '].sunday',
              message: I18n.t('Validation.requiredFieldError'),
              stack: clientsArray[errorId] + errorsJson['errorMessage7']
            })
          }
        })
      }
    })
  }
  if (id.match('clientAdditionalInformation_healthCoverage')) {
    _.forEach(formData, (row, index) => {
      if (_.includes(_.get(row, 'nameOfThePerson', []), 'Other')) {
        if (_.get(row, 'nameOfOtherPerson') == undefined) {
          row['nameOfOtherPerson'] = [{}]
        }
        _.forEach(_.get(row, 'nameOfOtherPerson'), (o, index1) => {
          if (
            (o['nameOfOtherPersonNotApplying'] === undefined ||
              _.trim(o['nameOfOtherPersonNotApplying']) === '') &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_nameOfOtherPerson_' +
                index1 +
                '_nameOfOtherPersonNotApplying'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].nameOfOtherPerson.[' +
                index1 +
                '].nameOfOtherPersonNotApplying',
              message: I18n.t('Validation.requiredFieldError'),
              stack:
                clientsArray[errorId] + errorsJson['otherPersonNameRequired']
            })
          }
        })
      }
    })
  }
  if (id.match('clientAdditionalInformation_healthInsurance')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['insuranceCompany'] === undefined ||
          _.trim(row['insuranceCompany']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_insuranceCompany'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].insuranceCompany',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['insuranceCompany']
        })
      }
      if (
        (row['nameOfTheOtherPolicyHolder'] === undefined ||
          _.trim(row['nameOfTheOtherPolicyHolder']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_nameOfTheOtherPolicyHolder'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].nameOfTheOtherPolicyHolder',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage8']
        })
      }

      if (_.includes(_.get(row, 'nameOfThePerson', []), 'Other')) {
        if (_.get(row, 'nameOfOtherPerson') == undefined) {
          row['nameOfOtherPerson'] = [{}]
        }
        _.forEach(_.get(row, 'nameOfOtherPerson'), (o, index1) => {
          if (
            (o['nameOfOtherPersonNotApplying'] === undefined ||
              _.trim(o['nameOfOtherPersonNotApplying']) === '') &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_nameOfOtherPerson_' +
                index1 +
                '_nameOfOtherPersonNotApplying'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].nameOfOtherPerson.[' +
                index1 +
                '].nameOfOtherPersonNotApplying',
              message: I18n.t('Validation.requiredFieldError'),
              stack:
                clientsArray[errorId] + errorsJson['otherPersonNameRequired']
            })
          }
        })
      }

      if (
        (row['nameOfThePerson'] === undefined ||
          _.trim(row['nameOfThePerson']) === '' ||
          row['nameOfThePerson'].length < 1) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_nameOfThePerson'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].nameOfThePerson',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['nameOfThePerson']
        })
      }
      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Health Insurance Policy Address - Zip Code validation
      if (
        row['address'] &&
        row['address']['zip'] &&
        row['address']['zip'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].address.zip',
            message: errorsJson['zipMust5'],
            stack: clientsArray[errorId] + errorsJson['zipMustBe5']
          })
        }
      }
    })
  }

  if (id.match('clientDetails_personalData')) {
    let validMaritalStatus = false
    let currentProgram =
      formContext && formContext.prgNames && formContext.prgNames.split(',')
    if (currentProgram && currentProgram.length > 0) {
      if (
        _.includes(currentProgram, 'Medicare Premium Assistance') ||
        _.includes(currentProgram, 'SNAP') ||
        _.includes(currentProgram, 'QMB')
      ) {
        validMaritalStatus = true
      }
    }

    _.forEach(formData, (row, index) => {
      if (
        (row['clientFstNm'] === undefined ||
          _.trim(row['clientFstNm']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientFstNm'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientFstNm',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['clientFstNm']
        })
      }
      if (
        (row['clientLstNm'] === undefined ||
          _.trim(row['clientLstNm']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientLstNm'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientLstNm',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['clientLstNm']
        })
      }
      //US1801346 Story
      if (
        (row['gdrCd'] === undefined ||
          (_.includes(_.keys(row['gdrCd']), 'key') &&
            _.get(row['gdrCd'], 'key') === undefined)) &&
        document.getElementById(id + '_' + trackerName + '_' + index + '_gdrCd')
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].gdrCd',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['gdrCd']
        })
      }
      if (
        (row['clientBthDt'] === undefined ||
          _.trim(row['clientBthDt']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientBthDt'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientBthDt',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['clientBthDt']
        })
      }

      // Code changes for GPRB0359133 and US2558381
      if (
        row['clientBthDt'] &&
        row['clientBthDt'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientBthDt'
        )
      ) {
        if (
          validBirthDateCheck(
            _.trim(row['clientBthDt']),
            formContext.currentDate
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].clientBthDt',
            message: I18n.t('Validation.invalidDOBError'),
            stack: clientsArray[errorId] + errorsJson['clientBthDtFuture']
          })
        }
      }
      // Code changes GPRB0359223 and US2819645
      // Personal Personal Data SSN Validation
      if (
        row['clientSsn'] &&
        row['clientSsn'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientSsn'
        )
      ) {
        if (!validSSN(_.trim(row['clientSsn']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].clientSsn',
            message: I18n.t('Validation.inValidSSN'),
            stack: clientsArray[errorId] + errorsJson['clientSsn']
          })
        }
      }
      if (
        // (!formContext.isRenewal || validMaritalStatus) &&
        (row['mrtlStatusCd'] === undefined ||
          _.trim(row['mrtlStatusCd']) === '' ||
          (_.includes(_.keys(row['mrtlStatusCd']), 'key') &&
            _.get(row['mrtlStatusCd'], 'key') === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_mrtlStatusCd'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].mrtlStatusCd',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['mrtlStatusCd']
        })
      }
      //End
    })
  }

  if (id.match('clientDetails_residenceInformation')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['clientRemInStIntnFlg'] === undefined ||
          _.trim(row['clientRemInStIntnFlg']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientRemInStIntnFlg'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientRemInStIntnFlg',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['clientRemInStIntnFlg']
        })
      }
      if (
        (row['addressSpecificationCode'] === undefined ||
          _.trim(row['addressSpecificationCode']) === '' ||
          (_.includes(_.keys(row['addressSpecificationCode']), 'key') &&
            row['addressSpecificationCode'].key === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_addressSpecificationCode'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].addressSpecificationCode',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['addressSpecificationCode']
        })
      }
    })
  }
  //added by ravi
  if (id.match('clientDetails_citizenshipImmigration')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['usCtznOrNatInd'] === undefined ||
          _.trim(row['usCtznOrNatInd']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_usCtznOrNatInd'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].usCtznOrNatInd',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['usCtznOrNatInd']
        })
      }
      if (row['usCtznOrNatInd'] == 'N') {
        if (
          (row['countrymoved'] === undefined ||
            _.trim(row['countrymoved']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_countrymoved'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].countrymoved',
            message: I18n.t('Validation.requiredFieldError'),
            stack: clientsArray[errorId] + errorsJson['countrymoved']
          })
        }
      }

      if (row['usCtznOrNatInd'] == 'N') {
        if (
          (_.get(row['immgStatusCd'], 'key') === undefined ||
            _.trim(_.get(row['immgStatusCd'], 'key')) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_immgStatusCd'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].immgStatusCd',
            message: I18n.t('Validation.requiredFieldError'),
            stack: clientsArray[errorId] + errorsJson['errorMessage9']
          })
        }
      }

      if (row['usCtznOrNatInd'] == 'N') {
        if (
          (row['dtOfEntToUS'] === undefined ||
            _.trim(row['dtOfEntToUS']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_dtOfEntToUS'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].dtOfEntToUS',
            message: I18n.t('Validation.requiredFieldError'),
            stack: clientsArray[errorId] + errorsJson['dtOfEntToUS']
          })
        }
      }
    })
  }
  //END

  // Code changes for GPRB0359133 and US2558381
  if (id.match('clientDetails_taxInformation')) {
    _.forEach(formData, (row, index) => {
      if (
        row['otherPersonBthDt'] &&
        row['otherPersonBthDt'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherPersonBthDt'
        )
      ) {
        if (
          validBirthDateCheck(
            _.trim(row['otherPersonBthDt']),
            formContext.currentDate
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].otherPersonBthDt',
            message: I18n.t('Validation.invalidDOBError'),
            stack: clientsArray[errorId] + errorsJson['clientBthDtFuture']
          })
        }
      }
      // Code changes for GPRB0359223 and US2819645
      // Personal Tax Information SSN Validation
      if (
        row['otherPersonSsn'] &&
        row['otherPersonSsn'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherPersonSsn'
        )
      ) {
        if (!validSSN(_.trim(row['otherPersonSsn']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].otherPersonSsn',
            message: I18n.t('Validation.inValidSSN'),
            stack: clientsArray[errorId] + errorsJson['clientSsn']
          })
        }
      }
      if (
        row['zip'] !== undefined &&
        document.getElementById(id + '_' + trackerName + '_' + index + '_zip')
      ) {
        if (validZipCodeCheck(_.trim(row['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].zip',
            message: I18n.t('Validation.invalidZip'),
            stack: clientsArray[errorId] + errorsJson['employerAddressZip']
          })
        }
      }

      //added for user story - US3672780 start
      if (
        (row['otherPersonFstNm'] === undefined ||
          _.trim(row['otherPersonFstNm']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherPersonFstNm'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].otherPersonFstNm',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['otherPersonFstNm']
        })
      }
      if (
        (row['otherPersonLstNm'] === undefined ||
          _.trim(row['otherPersonLstNm']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherPersonLstNm'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].otherPersonLstNm',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['otherPersonLstNm']
        })
      }
      if (
        row['otherPersonHaveIncome'] === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherPersonHaveIncome'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].otherPersonHaveIncome',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['otherPersonHaveIncome']
        })
      }
      if (row['otherPersonHaveIncome'] === 'Y') {
        let income = _.get(row, 'otherPersonIncomeDetails.incomes', [])
        for (let x = 0; x < income.length; x++) {
          if (
            income[x].typeOfIncome['key'] === undefined &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_otherPersonIncomeDetails_incomes_' +
                x +
                '_typeOfIncome'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                ']' +
                '.otherPersonIncomeDetails.incomes[' +
                x +
                '].typeOfIncome',
              message: I18n.t('Validation.requiredFieldError'),
              stack: clientsArray[errorId] + errorsJson['typeOfIncome']
            })
          }
          if (
            income[x].averageAmountReceived === undefined &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_otherPersonIncomeDetails_incomes_' +
                x +
                '_averageAmountReceived'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                ']' +
                '.otherPersonIncomeDetails.incomes[' +
                x +
                '].averageAmountReceived',
              message: I18n.t('Validation.requiredFieldError'),
              stack: clientsArray[errorId] + errorsJson['averageAmountReceived']
            })
          }
        }
      }
      if (
        row['otherPersonHaveIncomeDeductions'] === undefined &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_otherPersonHaveIncomeDeductions'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].otherPersonHaveIncomeDeductions',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            errorsJson['otherPersonHaveIncomeDeductions']
        })
      }
      if (row['otherPersonHaveIncomeDeductions'] === 'Y') {
        let deduction = _.get(row, 'otherPersonDeductionDetails.deductions', [])
        for (let x = 0; x < deduction.length; x++) {
          if (
            deduction[x].typeOfDeduction['key'] === undefined &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_otherPersonDeductionDetails_deductions_' +
                x +
                '_typeOfDeduction'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                ']' +
                '.otherPersonDeductionDetails.deductions[' +
                x +
                '].typeOfDeduction',
              message: I18n.t('Validation.requiredFieldError'),
              stack: clientsArray[errorId] + errorsJson['typeOfDeduction']
            })
          }
          if (
            deduction[x].averageAmountPays === undefined &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_otherPersonDeductionDetails_deductions_' +
                x +
                '_averageAmountPays'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                ']' +
                '.otherPersonDeductionDetails.deductions[' +
                x +
                '].averageAmountPays',
              message: I18n.t('Validation.requiredFieldError'),
              stack: clientsArray[errorId] + errorsJson['averageAmountPays']
            })
          }
        }
      }
      //US3672780 End
    })
  }

  if (id.match('clientDetails_otherInformation')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['drugFelony'] === undefined || _.trim(row['drugFelony']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_drugFelony'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].drugFelony',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['drugFelony']
        })
      }
      if (
        (row['probation'] === undefined || _.trim(row['probation']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_probation'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].probation',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['probation']
        })
      }
      if (
        (row['jailFelony'] === undefined || _.trim(row['jailFelony']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_jailFelony'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].jailFelony',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['jailFelony']
        })
      }
      if (
        (row['domesticVoilence'] === undefined ||
          _.trim(row['domesticVoilence']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_domesticVoilence'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].domesticVoilence',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['domesticVoilence']
        })
      }
      if (
        (row['snapFromOtherState'] === undefined ||
          _.trim(row['snapFromOtherState']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapFromOtherState'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapFromOtherState',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['snapFromOtherState']
        })
      }
      if (
        (row['snapOffense'] === undefined ||
          _.trim(row['snapOffense']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapOffense'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapOffense',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['snapOffense']
        })
      }
      if (
        (row['snapTrafficking500'] === undefined ||
          _.trim(row['snapTrafficking500']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapTrafficking500'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapTrafficking500',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['snapTrafficking500']
        })
      }
      if (
        (row['snapForIllegal'] === undefined ||
          _.trim(row['snapForIllegal']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapForIllegal'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapForIllegal',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['snapForIllegal']
        })
      }
      if (
        (row['1996DrugFelony'] === undefined ||
          _.trim(row['1996DrugFelony']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_1996DrugFelony'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].1996DrugFelony',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['1996DrugFelony']
        })
      }
      if (
        (row['snapLying'] === undefined || _.trim(row['snapLying']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapLying'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapLying',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['snapLying']
        })
      }
      if (
        (row['isAdultFlny'] === undefined ||
          _.trim(row['isAdultFlny']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_isAdultFlny'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].isAdultFlny',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['isAdultFlny']
        })
      }
      if (row['isAdultFlny'] == 'Y') {
        if (
          (row['isAdultComplnce'] === undefined ||
            _.trim(row['isAdultComplnce']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_isAdultComplnce'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].isAdultComplnce',
            message: I18n.t('Validation.requiredFieldError'),
            stack: clientsArray[errorId] + errorsJson['isAdultComplnce']
          })
        }
      }
    })
  }
  if (id.match('clientIncomes_earnedIncomes')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['jobStartDate'] === undefined ||
          _.trim(row['jobStartDate']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_jobStartDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].jobStartDate',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage10']
        })
      }
      if (
        (_.get(row['frequency'], 'key') === undefined ||
          _.trim(_.get(row['frequency'], 'key')) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_frequency'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].frequency',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['frequencyEI']
        })
      }
      if (
        (row['hoursWorked'] === undefined ||
          _.trim(row['hoursWorked']) === '' ||
          isNaN(parseInt(row['hoursWorked']))) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_hoursWorked'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].hoursWorked',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['hoursWorkedEI']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['amountEI']
        })
      }
      if (
        row['incomeSource'] &&
        (row['incomeSource']['incomeSourceName'] === undefined ||
          _.trim(row['incomeSource']['incomeSourceName']) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_incomeSource_incomeSourceName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].incomeSource.incomeSourceName',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['incomeSourceEI']
        })
      }

      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Employer Address - Zip Code validation
      if (
        row['incomeSource'] &&
        row['incomeSource']['address'] &&
        row['incomeSource']['address']['zip'] &&
        row['incomeSource']['address']['zip'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_incomeSource_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['incomeSource']['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property:
              '.' + trackerName + '[' + index + '].incomeSource.address.zip',
            message: I18n.t('Validation.invalidZip'),
            stack: clientsArray[errorId] + errorsJson['employerAddressZip']
          })
        }
      }

      if (
        (row['workHoursReduction'] === undefined ||
          _.trim(row['workHoursReduction']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_workHoursReduction'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].workHoursReduction',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['workHoursReductionIE']
        })
      }
      if (
        (row['refuseEmployment'] === undefined ||
          _.trim(row['refuseEmployment']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_refuseEmployment'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].refuseEmployment',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['refuseEmploymentEI']
        })
      }
      if (
        (row['setSchedule'] === undefined ||
          _.trim(row['setSchedule']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_setSchedule'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].setSchedule',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage11']
        })
      }
      if (
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_workSchedule_0_sunday'
        )
      ) {
        _.forEach(row.workSchedule, (school, innerIndex) => {
          if (
            !(
              school['monday'] ||
              school['tuesday'] ||
              school['wednesday'] ||
              school['thursday'] ||
              school['friday'] ||
              school['saturday'] ||
              school['sunday']
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].workSchedule[' +
                innerIndex +
                '].sunday',
              message: I18n.t('Validation.requiredFieldError'),
              stack: clientsArray[errorId] + errorsJson['errorMessage12']
            })
          }
        })
      }
    })
  }
  if (id.match('clientIncomes_selfEmploymentIncomes')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['incomeStartDate'] === undefined ||
          _.trim(row['incomeStartDate']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_incomeStartDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].incomeStartDate',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage13']
        })
      }
      if (
        _.get(row, 'incomeType.key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_incomeType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].incomeType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['selfEmploymentIncomes']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            errorsJson['amountSEI1'] +
            applicantName[errorId] +
            errorsJson['amountSEI2'] +
            applicantName[errorId] +
            errorsJson['amountSEI3']
        })
      }
      if (
        (row['expense'] === undefined || _.trim(row['expense']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_expense'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].expense',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            errorsJson['expenseSEI1'] +
            applicantName[errorId] +
            errorsJson['expenseSEI2']
        })
      }
      if (
        (row['setSchedule'] === undefined ||
          _.trim(row['setSchedule']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_setSchedule'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].setSchedule',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage14']
        })
      }
      if (
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_workSchedule_0_sunday'
        )
      ) {
        _.forEach(row.schoolSchedule, (school, innerIndex) => {
          if (
            !(
              school['monday'] ||
              school['tuesday'] ||
              school['wednesday'] ||
              school['thursday'] ||
              school['friday'] ||
              school['saturday'] ||
              school['sunday']
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].workSchedule[' +
                innerIndex +
                '].sunday',
              message: I18n.t('Validation.requiredFieldError'),
              stack: clientsArray[errorId] + errorsJson['errorMessage15']
            })
          }
        })
      }
    })
  }
  if (id.match('clientIncomes_otherIncomes')) {
    _.forEach(formData, (row, index) => {
      if (
        _.get(row, 'incomeType.key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_incomeType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].incomeType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['unearnedIncomes']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['amountReqUI']
        })
      }
      if (
        (_.get(row['frequency'], 'key') === undefined ||
          _.trim(_.get(row['frequency'], 'key')) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_frequency'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].frequency',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['frequencyEI']
        })
      }
    })
  }
  if (id.match('clientIncomes_roomAndBoardExpenses')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['moneyRoomMeals'] === undefined ||
          _.trim(row['moneyRoomMeals']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_moneyRoomMeals'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].moneyRoomMeals',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['moneyRoomMealsUI']
        })
      }
    })
  }
  if (id.match('clientExpenses_heatingExpenses')) {
    _.forEach(formData, (row, index) => {
      if (
        _.get(row['primaryHeatingSource'], 'key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_primaryHeatingSource'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].primaryHeatingSource',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['primaryHeatingSource']
        })
      }
      if (
        (row['payingForThis'] === undefined ||
          _.trim(row['payingForThis']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_payingForThis'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].payingForThis',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['payingForThis']
        })
      }

      if (
        (row['other'] === undefined || _.trim(row['other']) === '') &&
        document.getElementById(id + '_' + trackerName + '_' + index + '_other')
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].other',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['otherPayerName']
        })
      }

      if (
        (_.get(row, 'mainHeatingFuelCompanyName') === undefined ||
          _.get(row, 'mainHeatingFuelCompanyName') === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_mainHeatingFuelCompanyName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].mainHeatingFuelCompanyName',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] + errorsJson['mainHeatingFuelCompanyName']
        })
      }

      if (
        (row['mainHeatingFuelCompanyNameForOthers'] === undefined ||
          _.trim(row['mainHeatingFuelCompanyNameForOthers']) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_mainHeatingFuelCompanyNameForOthers'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].mainHeatingFuelCompanyNameForOthers',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            errorsJson['mainHeatingFuelCompanyNameForOthers']
        })
      }

      if (
        (row['accountVendor'] === undefined ||
          _.trim(row['accountVendor']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountVendor'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountVendor',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['accountVendor']
        })
      }

      if (
        (row['accountNumber'] === undefined ||
          _.trim(row['accountNumber']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountNumber'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountNumber',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + 'Account Number is required'
        })
      }

      if (
        _.get(
          row,
          'statusOfHomeEnergyService.anyOfTheseSituationsApply.key'
        ) === undefined &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_anyOfTheseSituationsApply'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.anyOfTheseSituationsApply',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['anyOfTheseSituationsApply']
        })
      }
      if (
        (_.get(row, 'statusOfHomeEnergyService.lowOnFuel') === undefined ||
          _.trim(_.get(row, 'statusOfHomeEnergyService.lowOnFuel')) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_lowOnFuel'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.lowOnFuel',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['lowOnFuel']
        })
      }

      if (
        (_.get(row, 'statusOfHomeEnergyService.outOfFuel') === undefined ||
          _.trim(_.get(row, 'statusOfHomeEnergyService.outOfFuel')) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_outOfFuel'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.outOfFuel',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['outOfFuel']
        })
      }

      if (
        (_.get(row, 'statusOfHomeEnergyService.nonWorkingFurnaceOrBoiler') ===
          undefined ||
          _.trim(
            _.get(row, 'statusOfHomeEnergyService.nonWorkingFurnaceOrBoiler')
          ) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_nonWorkingFurnaceOrBoiler'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.nonWorkingFurnaceOrBoiler',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['nonWorkingFurnaceOrBoiler']
        })
      }

      if (
        (_.get(row, 'statusOfHomeEnergyService.electricAccount') ===
          undefined ||
          _.trim(_.get(row, 'statusOfHomeEnergyService.electricAccount')) ===
            '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_electricAccount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.electricAccount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['electricAccount']
        })
      }

      if (
        (_.get(row, 'statusOfHomeEnergyService.accountVendor') === undefined ||
          _.trim(_.get(row, 'statusOfHomeEnergyService.accountVendor')) ===
            '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_accountVendor'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.accountVendor',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['accountVendor']
        })
      }
      if (
        (_.get(row, 'statusOfHomeEnergyService.electricAccountVendor') ===
          undefined ||
          _.trim(
            _.get(row, 'statusOfHomeEnergyService.electricAccountVendor')
          ) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_electricAccountVendor'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.electricAccountVendor',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['electricAccountVendor']
        })
      }
      if (
        (_.get(row, 'statusOfHomeEnergyService.payingForThis') === undefined ||
          _.trim(_.get(row, 'statusOfHomeEnergyService.payingForThis')) ===
            '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_payingForThis'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.payingForThis',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['payingForThis']
        })
      }
      if (
        (_.get(row, 'statusOfHomeEnergyService.electicAccountPayingForThis') ===
          undefined ||
          _.trim(
            _.get(row, 'statusOfHomeEnergyService.electicAccountPayingForThis')
          ) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_electicAccountPayingForThis'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.electicAccountPayingForThis',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] + errorsJson['electicAccountPayingForThis']
        })
      }
      if (
        (_.get(row, 'statusOfHomeEnergyService.electricAccountNumber') ===
          undefined ||
          _.trim(
            _.get(row, 'statusOfHomeEnergyService.electricAccountNumber')
          ) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_electricAccountNumber'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.electricAccountNumber',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['electricAccountNumber']
        })
      }
      if (
        (_.get(row, 'statusOfHomeEnergyService.wavier') === undefined ||
          _.trim(_.get(row, 'statusOfHomeEnergyService.wavier')) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_statusOfHomeEnergyService_wavier'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].statusOfHomeEnergyService.wavier',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['wavier']
        })
      }
    })
  }
  if (id.match('clientExpenses_childSupport')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['paymentOrdered'] === undefined ||
          _.trim(row['paymentOrdered']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_paymentOrdered'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].paymentOrdered',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['paymentCourtOrdered']
        })
      }
      if (
        (row['paymentAmount'] === undefined ||
          _.trim(row['paymentAmount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_paymentAmount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].paymentAmount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['paymentReq']
        })
      }
    })
  }
  if (id.match('clientExpenses_dependentCareExpenses')) {
    _.forEach(formData, (row, index) => {
      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Dependent Care - Zip Code validation
      if (
        row['address'] &&
        row['address']['zip'] &&
        row['address']['zip'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].address.zip',
            message: errorsJson['zipMust5'],
            stack:
              clientsArray[errorId] +
              errorsJson['paymentReq'] +
              errorsJson['zipMustBe5']
          })
        }
      }
      if (
        row['dependent'] &&
        (row['dependent']['payForCare'] === undefined ||
          _.trim(row['dependent']['payForCare']) === '' ||
          row['dependent']['payForCare'].length < 1) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_dependent_payForCare'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].dependent.payForCare',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['payForCareDependentCare']
        })
      }
      if (
        row['dependent'] &&
        (row['dependent']['otherPerson'] === undefined ||
          _.trim(row['dependent']['otherPerson']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_dependent_otherPerson'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].dependent.otherPerson',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + "Other Person's Name is required."
        })
      }
      if (
        row['dependent'] &&
        ((row['dependent']['paymentFrequency'] &&
          row['dependent']['paymentFrequency'].key === undefined) ||
          _.trim(
            row['dependent']['paymentFrequency'] &&
              row['dependent']['paymentFrequency'].key
          ) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_dependent_paymentFrequency'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].dependent.paymentFrequency',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['dependentPaymentFrequency']
        })
      }
      if (
        row['dependent'] &&
        (row['dependent']['paymentAmount'] === undefined ||
          _.trim(row['dependent']['paymentAmount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_dependent_paymentAmount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].dependent.paymentAmount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['dependentPaymentAmount']
        })
      }
    })
  }
  if (id.match('clientExpenses_coolingExpenses')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['payAirCondition'] === undefined ||
          _.trim(row['payAirCondition']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_payAirCondition'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].payAirCondition',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['coolingPayAirCondition']
        })
      }
      if (
        (row['includeInRent'] === undefined ||
          _.trim(row['includeInRent']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_includeInRent'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].includeInRent',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['coolingIncludeInRent']
        })
      }
    })
  }
  if (id.match('clientExpenses_utilityExpenses')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['billPaidTo'] === undefined || _.trim(row['billPaidTo']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_billPaidTo'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].billPaidTo',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['utilityBillPaidTo']
        })
      }
      if (
        (row['utilityCost'] === undefined ||
          _.trim(row['utilityCost']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_utilityCost'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].utilityCost',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['utilityCost']
        })
      }
      if (
        _.get(row['utilityType'], 'key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_utilityType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].utilityType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['utilityType']
        })
      }

      if (
        (row['usedForHeating'] === undefined ||
          _.trim(row['usedForHeating']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_usedForHeating'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].usedForHeating',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage16']
        })
      }

      if (
        (row['usedForCooling'] === undefined ||
          _.trim(row['usedForCooling']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_usedForCooling'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].usedForCooling',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage17']
        })
      }

      if (
        (row['nameOntheBill'] === undefined ||
          _.trim(row['nameOntheBill']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_nameOntheBill'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].nameOntheBill',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage18']
        })
      }

      if (
        (row['otherutilityCompany'] === undefined ||
          _.trim(row['otherutilityCompany']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherutilityCompany'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].otherutilityCompany',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage19']
        })
      }

      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Utility Cost - Zip Code validation
      if (
        row['address'] &&
        row['address']['zip'] &&
        row['address']['zip'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].address.zip',
            message: errorsJson['zipMust5'],
            stack: clientsArray[errorId] + errorsJson['zipMustBe5']
          })
        }
      }
    })
  }
  if (id.match('clientExpenses_shelterExpenses')) {
    _.forEach(formData, (row, index) => {
      if (
        _.get(row, 'shelterType.key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_shelterType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].shelterType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['shelterType']
        })
      }
      if (
        (row['shelterCost'] === undefined ||
          _.trim(row['shelterCost']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_shelterType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].shelterCost',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['shelterCost']
        })
      }
      if (
        _.get(row, 'shelterSubsidyDoesYourHHReceive.key') === undefined &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_shelterSubsidyDoesYourHHReceive'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].shelterSubsidyDoesYourHHReceive',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage20']
        })
      }
      if (
        (row['billPaidTo'] === undefined || _.trim(row['billPaidTo']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_billPaidTo'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].billPaidTo',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['utilityBillPaidTo']
        })
      }
      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Shelter Cost - Zip Code validation
      if (
        row['address'] &&
        row['address']['zip'] &&
        row['address']['zip'] !== undefined &&
        _.trim(row['address']['zip']).length > 0 &&
        _.trim(row['address']['zip']).length < 5 &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].address.zip',
            message: errorsJson['zipMust5'],
            stack: clientsArray[errorId] + errorsJson['zipMustBe5']
          })
        }
      }
    })
  }
  if (id.match('clientExpenses_deduction')) {
    _.forEach(formData, (row, index) => {
      if (
        _.get(row, 'deductionType.key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_deductionType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].deductionType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['typeOfDeduction']
        })
      }
      if (
        _.get(row, 'frequency.key') === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_frequency'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].frequency',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            errorsJson['deductionFrequency1'] +
            applicantName[errorId] +
            errorsJson['deductionFrequency2']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            errorsJson['deductionAmount1'] +
            applicantName[errorId] +
            errorsJson['deductionAmount2']
        })
      }
    })
  }
  if (id.match('clientAssets_bankingAccounts')) {
    _.forEach(formData, (row, index) => {
      if (_.includes(_.get(row, 'nameOfThePerson', []), 'Other')) {
        if (_.get(row, 'nameOfOtherPerson') == undefined) {
          row['nameOfOtherPerson'] = [{}]
        }
        _.forEach(_.get(row, 'nameOfOtherPerson'), (o, index1) => {
          if (
            (o['nameOfOtherPersonNotApplying'] === undefined ||
              _.trim(o['nameOfOtherPersonNotApplying']) === '') &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_nameOfOtherPerson_' +
                index1 +
                '_nameOfOtherPersonNotApplying'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].nameOfOtherPerson.[' +
                index1 +
                '].nameOfOtherPersonNotApplying',
              message: I18n.t('Validation.requiredFieldError'),
              stack:
                clientsArray[errorId] + errorsJson['otherPersonNameRequired']
            })
          }
        })
      }

      if (
        (row['financialInstitutionName'] === undefined ||
          _.trim(row['financialInstitutionName']) == '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_financialInstitutionName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].financialInstitutionName',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['bfinancialInstitutionName']
        })
      }
      if (
        (row['accountType'] === undefined ||
          _.trim(row['accountType']) === '' ||
          (_.includes(_.keys(row['accountType']), 'key') &&
            _.get(row, 'accountType.key') === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['baccountType']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['bamount']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['baccountAccesible']
        })
      }
      if (
        (row['assetBurial'] === undefined ||
          _.trim(row['assetBurial']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_assetBurial'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].assetBurial',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['bassetBurial']
        })
      }
    })
  }
  if (id.match('clientAssets_cash')) {
    _.forEach(formData, (row, index) => {
      if (_.includes(_.get(row, 'nameOfThePerson', []), 'Other')) {
        if (_.get(row, 'nameOfOtherPerson') == undefined) {
          row['nameOfOtherPerson'] = [{}]
        }
        _.forEach(_.get(row, 'nameOfOtherPerson'), (o, index1) => {
          if (
            (o['nameOfOtherPersonNotApplying'] === undefined ||
              _.trim(o['nameOfOtherPersonNotApplying']) === '') &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_nameOfOtherPerson_' +
                index1 +
                '_nameOfOtherPersonNotApplying'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].nameOfOtherPerson.[' +
                index1 +
                '].nameOfOtherPersonNotApplying',
              message: I18n.t('Validation.requiredFieldError'),
              stack:
                clientsArray[errorId] + errorsJson['otherPersonNameRequired']
            })
          }
        })
      }

      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['camount']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['caccountAccesible']
        })
      }
      if (
        (row['assetBurial'] === undefined ||
          _.trim(row['assetBurial']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_assetBurial'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].assetBurial',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['cassetBurial']
        })
      }
    })
  }

  if (id.match('clientAssets_trusts')) {
    _.forEach(formData, (row, index) => {
      if (_.includes(_.get(row, 'nameOfThePerson', []), 'Other')) {
        if (_.get(row, 'nameOfOtherPerson') == undefined) {
          row['nameOfOtherPerson'] = [{}]
        }
        _.forEach(_.get(row, 'nameOfOtherPerson'), (o, index1) => {
          if (
            (o['nameOfOtherPersonNotApplying'] === undefined ||
              _.trim(o['nameOfOtherPersonNotApplying']) === '') &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_nameOfOtherPerson_' +
                index1 +
                '_nameOfOtherPersonNotApplying'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].nameOfOtherPerson.[' +
                index1 +
                '].nameOfOtherPersonNotApplying',
              message: I18n.t('Validation.requiredFieldError'),
              stack:
                clientsArray[errorId] + errorsJson['otherPersonNameRequired']
            })
          }
        })
      }

      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['tamount']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['taccountAccesible']
        })
      }

      if (
        (row['assetBurial'] === undefined ||
          _.trim(row['assetBurial']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_assetBurial'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].assetBurial',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['tassetBurial']
        })
      }
    })
  }
  if (id.match('clientAssets_investmentAccounts')) {
    _.forEach(formData, (row, index) => {
      if (_.includes(_.get(row, 'nameOfThePerson', []), 'Other')) {
        if (_.get(row, 'nameOfOtherPerson') == undefined) {
          row['nameOfOtherPerson'] = [{}]
        }
        _.forEach(_.get(row, 'nameOfOtherPerson'), (o, index1) => {
          if (
            (o['nameOfOtherPersonNotApplying'] === undefined ||
              _.trim(o['nameOfOtherPersonNotApplying']) === '') &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_nameOfOtherPerson_' +
                index1 +
                '_nameOfOtherPersonNotApplying'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].nameOfOtherPerson.[' +
                index1 +
                '].nameOfOtherPersonNotApplying',
              message: I18n.t('Validation.requiredFieldError'),
              stack:
                clientsArray[errorId] + errorsJson['otherPersonNameRequired']
            })
          }
        })
      }

      if (
        (row['accountType'] === undefined ||
          _.trim(row['accountType']) === '' ||
          (_.includes(_.keys(row['accountType']), 'key') &&
            _.get(row, 'accountType.key') === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['iaccountType']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['iamount']
        })
      }
      if (
        (row['financialInstitutionName'] === undefined ||
          _.trim(row['financialInstitutionName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_financialInstitutionName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].financialInstitutionName',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['ifinancialInstitutionName']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['iaccountAccesible']
        })
      }
      if (
        (row['assetBurial'] === undefined ||
          _.trim(row['assetBurial']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_assetBurial'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].assetBurial',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['iassetBurial']
        })
      }
    })
  }

  if (id.match('clientAssets_lumpsumPayment')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['accountType'] === undefined ||
          _.trim(row['accountType']) === '' ||
          (_.includes(_.keys(row['accountType']), 'key') &&
            _.get(row, 'accountType.key') === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['llumpsumType']
        })
      }
      if (
        (row['startDate'] === undefined || _.trim(row['startDate']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_startDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].startDate',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['lstartDate']
        })
      }
      if (
        (row['getAgain'] === undefined || _.trim(row['getAgain']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_getAgain'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].getAgain',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['lgetAgain']
        })
      }
    })
  }
  if (id.match('clientAssets_vehicles')) {
    _.forEach(formData, (row, index) => {
      if (_.includes(_.get(row, 'nameOfThePerson', []), 'Other')) {
        if (_.get(row, 'nameOfOtherPerson') == undefined) {
          row['nameOfOtherPerson'] = [{}]
        }
        _.forEach(_.get(row, 'nameOfOtherPerson'), (o, index1) => {
          if (
            (o['nameOfOtherPersonNotApplying'] === undefined ||
              _.trim(o['nameOfOtherPersonNotApplying']) === '') &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_nameOfOtherPerson_' +
                index1 +
                '_nameOfOtherPersonNotApplying'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].nameOfOtherPerson.[' +
                index1 +
                '].nameOfOtherPersonNotApplying',
              message: I18n.t('Validation.requiredFieldError'),
              stack:
                clientsArray[errorId] + errorsJson['otherPersonNameRequired']
            })
          }
        })
      }

      if (
        (row['accountType'] === undefined ||
          _.trim(row['accountType']) === '' ||
          (_.includes(_.keys(row['accountType']), 'key') &&
            _.get(row, 'accountType.key') === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['vtypeOfVehicle']
        })
      }
      if (
        (row['fairMarketValue'] === undefined ||
          _.trim(row['fairMarketValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_fairMarketValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].fairMarketValue',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['vfairMarketValue']
        })
      }
      if (
        (row['equityValue'] === undefined ||
          _.trim(row['equityValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_equityValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].equityValue',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['vequityValue']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['vaccessible']
        })
      }
      if (
        row['vehicleYear'] != undefined &&
        _.trim(row['vehicleYear']) != '' &&
        _.trim(row['vehicleYear']).length > 0 &&
        _.trim(row['vehicleYear']).length < 4 &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_vehicleYear'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].vehicleYear',
          message: errorsJson['fourDigits'],
          stack: clientsArray[errorId] + errorsJson['vvehicleYear']
        })
      }
    })
  }
  if (id.match('clientAssets_soldtransferAsset')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['accountType'] === undefined ||
          _.trim(row['accountType']) === '' ||
          (_.includes(_.keys(row['accountType']), 'key') &&
            _.get(row, 'accountType.key') === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['ssoldtransferType']
        })
      }

      if (
        (row['startDate'] === undefined || _.trim(row['startDate']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_startDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].startDate',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['sstartDate']
        })
      }
      if (
        (row['fairMarketValue'] === undefined ||
          _.trim(row['fairMarketValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_fairMarketValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].fairMarketValue',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['sfairMarketValue']
        })
      }
      if (
        (row['equityValue'] === undefined ||
          _.trim(row['equityValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_equityValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].equityValue',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['sequityValue']
        })
      }
    })
  }
  if (id.match('clientAssets_otherAssets')) {
    _.forEach(formData, (row, index) => {
      if (_.includes(_.get(row, 'nameOfThePerson', []), 'Other')) {
        if (_.get(row, 'nameOfOtherPerson') == undefined) {
          row['nameOfOtherPerson'] = [{}]
        }
        _.forEach(_.get(row, 'nameOfOtherPerson'), (o, index1) => {
          if (
            (o['nameOfOtherPersonNotApplying'] === undefined ||
              _.trim(o['nameOfOtherPersonNotApplying']) === '') &&
            document.getElementById(
              id +
                '_' +
                trackerName +
                '_' +
                index +
                '_nameOfOtherPerson_' +
                index1 +
                '_nameOfOtherPersonNotApplying'
            )
          ) {
            errors.push({
              name: 'dateOverlap',
              property:
                '.' +
                trackerName +
                '[' +
                index +
                '].nameOfOtherPerson.[' +
                index1 +
                '].nameOfOtherPersonNotApplying',
              message: I18n.t('Validation.requiredFieldError'),
              stack:
                clientsArray[errorId] + errorsJson['otherPersonNameRequired']
            })
          }
        })
      }

      if (
        (row['accountType'] === undefined ||
          _.trim(row['accountType']) === '' ||
          (_.includes(_.keys(row['accountType']), 'key') &&
            _.get(row, 'accountType.key') === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountType',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['ootherType']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) == '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['oamount']
        })
      }
      if (
        (row['fairMarketValue'] === undefined ||
          _.trim(row['fairMarketValue']) == '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_fairMarketValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].fairMarketValue',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['ofairMarketValue']
        })
      }
      if (
        (row['equityValue'] === undefined ||
          _.trim(row['equityValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_equityValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].equityValue',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['oequityValue']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['oaccessible']
        })
      }
    })
  }
  //story US1801346

  if (id.match('_clientDetails_relationShip')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['relClientName'] === undefined ||
          _.trim(row['relClientName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_relClientName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].relClientName',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['relClientName']
        })
      }
      if (
        (row['relationName'] === undefined ||
          _.trim(row['relationName']) === '' ||
          (_.includes(_.keys(row['relationName']), 'key') &&
            row['relationName'].key === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_relationName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].relationName',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['relationName']
        })
      }
      if (
        (row['foodMeals'] === undefined || _.trim(row['foodMeals']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_foodMeals'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].foodMeals',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['foodMeals']
        })
      }
    })
  }
  if (id.match('_clientDetails_language')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['spokenLanguage'] === undefined ||
          _.trim(row['spokenLanguage']) === '' ||
          (_.includes(_.keys(row['spokenLanguage']), 'key') &&
            row['spokenLanguage'].key === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_spokenLanguage'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].spokenLanguage',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['spokenLanguage']
        })
      }
      if (
        (row['writtenLanguage'] === undefined ||
          _.trim(row['writtenLanguage']) === '' ||
          (_.includes(_.keys(row['writtenLanguage']), 'key') &&
            row['writtenLanguage'].key === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_writtenLanguage'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].writtenLanguage',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['writtenLanguage']
        })
      }
    })
  }
  if (id.match('_clientDetails_ethnicityRace')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['ethnicOrigin'] === undefined ||
          _.trim(row['ethnicOrigin']) === '' ||
          (_.includes(_.keys(row['ethnicOrigin']), 'key') &&
            row['ethnicOrigin'].key === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_ethnicOrigin'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].ethnicOrigin',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage21']
        })
      }

      if (
        (row['raceCd'] === undefined || row['raceCd'].length === 0) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_raceCd'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].raceCd',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + errorsJson['errorMessage22']
        })
      }

      if (
        (row['descentOfAmericanIndianOrAlskanNative'] === undefined ||
          _.trim(row['descentOfAmericanIndianOrAlskanNative']) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_descentOfAmericanIndianOrAlskanNative'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].descentOfAmericanIndianOrAlskanNative',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            'Please select "Descent of American Indian/Alaskan Native"'
        })
      }
      if (
        (row['nameOfTheTribe'] === undefined ||
          _.trim(row['nameOfTheTribe']) === '' ||
          (_.includes(_.keys(row['nameOfTheTribe']), 'key') &&
            row['nameOfTheTribe'].key === undefined)) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_nameOfTheTribe'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].nameOfTheTribe',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + 'Please select "Name of The Tribe"'
        })
      }
      if (
        (row['otherTribeName'] === undefined ||
          _.trim(row['otherTribeName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherTribeName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].otherTribeName',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + 'Please answer "Other Tribe Name"'
        })
      }
      if (
        (row['stateOfTheTribe'] === undefined ||
          _.trim(row['stateOfTheTribe']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_stateOfTheTribe'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].stateOfTheTribe',
          message: I18n.t('Validation.requiredFieldError'),
          stack: clientsArray[errorId] + 'Please select "State of the Tribe"'
        })
      }
      if (
        (row['enrolledFederalOrVillageProgram'] === undefined ||
          _.trim(row['enrolledFederalOrVillageProgram']) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_enrolledFederalOrVillageProgram'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].enrolledFederalOrVillageProgram',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            'Please select "Enrolled in Federal or Village Program"'
        })
      }
      if (
        (row['memberOfFederallyRecognizedTribe'] === undefined ||
          _.trim(row['memberOfFederallyRecognizedTribe']) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_memberOfFederallyRecognizedTribe'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' +
            trackerName +
            '[' +
            index +
            '].memberOfFederallyRecognizedTribe',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            'Please select "Member of Federally Recognized Tribe"'
        })
      }
      if (
        (row['receivedServiceThroughIHS'] === undefined ||
          _.trim(row['receivedServiceThroughIHS']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_receivedServiceThroughIHS'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].receivedServiceThroughIHS',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            'Please select "Received a service from the Indian Health Service"'
        })
      }
      if (
        (row['eligibleForServiceThroughIHS'] === undefined ||
          _.trim(row['eligibleForServiceThroughIHS']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_eligibleForServiceThroughIHS'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].eligibleForServiceThroughIHS',
          message: I18n.t('Validation.requiredFieldError'),
          stack:
            clientsArray[errorId] +
            'Please select "Eligible to get services from the Indian Health Service"'
        })
      }
    })
  }
}
