import * as React from 'react'
import { Component } from 'react'
import { isEmpty } from 'lodash'
import BenefitsView from './BenefitsView'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'

const _ = { isEmpty }

interface MyFavoritesViewState {
  expanded: boolean
}

/* Favorites View Components */
class MyFavoritesView extends Component<any, any> {
  constructor(props) {
    super(props)

    /* Initial State is not expanded */
    this.state = {
      expanded: false
    }
  }

  render() {
    // store and state for myfavorites

    const myFavorites = this.props.myFavorites

    // no favorites exist
    if (_.isEmpty(myFavorites)) {
      return (
        <div>
          <h1>{I18n.t('MyFavorites.title')}</h1>
          <h2>{I18n.t('MyFavorites.noFavorites')}</h2>
        </div>
      )
      // favorites exist
    } else {
      // render outer layer with content
      return (
        <div>
          <div className="margin-regular bottom-margin-regular">
            <h1>{I18n.t('MyFavorites.title')}</h1>
            <p>{I18n.t('MyFavorites.description')}</p>
            <p>{I18n.t('MyFavorites.note')}</p>
          </div>
          <div className="my-favorites-content bottom-margin-regular">
            <BenefitsView benefits={myFavorites} expanded={false} />
          </div>
        </div>
      )
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    myFavorites: state.myFavorites,
    locale: state.i18n.locale
  }
}

export default connect(mapStateToProps)(MyFavoritesView)
