import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { isEqual, noop, get, set, forEach, cloneDeep, isNil } from 'lodash' // extra functionality
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
import * as queryString from 'query-string'
import { I18n } from 'react-redux-i18n'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'
const _ = { isEqual, noop, get, set, forEach, cloneDeep, isNil }

const loadEndpoint = config['getMsgByUuid']
const documentEndpont = config['getDocument']
const updateReadFlgEndpoint = config['updateMsgReadUnreadFlg']
const formsEndpoint = config['forms_MyMessages']

const Fragment = (React as any).Fragment

//API parameter functions:
const initialData = { Application: { myMessages: [] } }
const createEndpoint = props => {
  return loadEndpoint.replace('{uuid}', props.uuid)
}
const createRequest = props => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      uuid: props.uuid,
      userRoleId: props.userRoleId,
      tenantCode: config.tenant.code,
      portalName: config.portalName,
      Authorization: props.authHeader
    }
  }
}
const responseMapper = json => {
  return { Application: { myMessages: json || [] } }
}

const MESSAGE_TYPE_TRANSLATIONS = {
  en: {
    notice: 'Notice',
    alert: 'Alert',
    greeting: 'Greeting',
    document: 'Document'
  },
  es: {
    notice: 'Aviso',
    alert: 'Alerta',
    greeting: 'Acogida',
    document: 'Documento'
  }
}

const NOTICE_TYPE_TRANSLATIONS = {
  en: {
    approval: 'Approval',
    denial: 'Denial',
    rfi: 'RFI',
    qhpApprovalNotice: 'QHP Approval Notice',
    chipApprovalNotice: 'CHIP Approval Notice',
    noiPending: 'NOI Pending',
    medicaidApprovalNotice: 'Medicaid Approval Notice',
    tanfApprovalNotice: 'TANF Approval Notice',
    qhpDenialNotice: 'QHP Denial Notice',
    chipDenialNotice: 'CHIP Denial Notice',
    snapDenialNotice: 'SNAP Denial Notice',
    medicaidDenialNotice: 'Medicaid Denial Notice',
    tanfDenialNotice: 'TANF Denial Notice',
    noticeOfAction: 'Notice of Action',
    applicantUpload: 'Applicant Upload',
    documentUnsuccessfullyUploaded:
      'Your document has been unsuccessfully uploaded',
    deletedDocument: 'Deleted Document',
    renewal: 'Renewal',
    noticeOfActionRfi: 'Notice Of Action RFI',
    noticeOfActionRfiCoc: 'Notice Of Action RFI COC',
    shelterVerificationForm: 'Shelter Verification Form',
    employmentVerificationForm: 'Employment VerificationForm',
    noaRenewalInitial: 'NOA Renewal Initial',
    noaRenewal16th: 'NOA Renewal 16th',
    noaRenewalClosure: 'NOA Renewal Closure',
    nodApproval: 'NOD Approval',
    nodDenial: 'NOD Denial',
    rfiInitial: 'RFI Initial',
    rfiCOC: 'RFI COC'
  },
  es: {
    approval: 'Aprobación',
    denial: 'Negación',
    rfi: 'RFI',
    qhpApprovalNotice: 'Aviso de aprobación de QHP',
    chipApprovalNotice: 'Aviso de aprobación de CHIP',
    noiPending: 'NOI Pendiente',
    medicaidApprovalNotice: 'Aviso de aprobación de Medicaid',
    tanfApprovalNotice: 'Aviso de aprobación de TANF',
    qhpDenialNotice: 'Notificación de rechazo de QHP',
    chipDenialNotice: 'Aviso de negación de CHIP',
    snapDenialNotice: 'Notificación de rechazo de SNAP',
    medicaidDenialNotice: 'Notificación de negación de Medicaid',
    tanfDenialNotice: 'Notificación de negación de TANF',
    noticeOfAction: 'Notificación de Acción',
    applicantUpload: 'Upload de candidatos',
    documentUnsuccessfullyUploaded: 'Su documento ha sido cargado sin éxito',
    deletedDocument: 'Documento borrado',
    renewal: 'Renacimiento',
    noticeOfActionRfi: 'Aviso de Acción RFI',
    noticeOfActionRfiCoc: 'Aviso de Acción RFI COC',
    shelterVerificationForm: 'Formulario de Verificación del Refugio',
    employmentVerificationForm: 'Formulario de Verificación de Empleo',
    noaRenewalInitial: 'Inicial de Renovación de NOA',
    noaRenewal16th: 'Renovación de la NOA 16',
    noaRenewalClosure: 'Cierre de renovación de NOA',
    nodApproval: 'Aprobación NOD',
    nodDenial: 'Negación de NOD',
    rfiInitial: 'RFI Inicial',
    rfiCOC: 'RFI COC'
  }
}

interface MyMessagesContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  loadData: any
  minHeight: string
  uuid: string
  userRoleId: number
  locale: string
  authHeader: string
}

interface MyMessagesContainerState {
  formData: any
  application: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formContext: any
  messageDetailViewId: number
  messageId: number
  criteriaFormData: {
    startDate?: string
    endDate?: string
  }
  callingApi: boolean
}

class MyMessagesContainer extends React.Component<
  MyMessagesContainerProps,
  MyMessagesContainerState
> {
  constructor(props: MyMessagesContainerProps) {
    super(props)

    this.state = {
      formData: props.formData || {},
      application: {},
      forms: null,
      formContext: {},
      messageDetailViewId: null,
      messageId: null,
      criteriaFormData: {},
      callingApi: false
    }
  }

  componentDidMount() {
    this._loadForms()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.formData, this.props.formData)) {
      this.setState({
        formData: this._updateFormDataByLocale(
          'en',
          this.props.locale,
          this.props.formData
        )
      })
    }
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      this.setState({ callingApi: true })
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(json => {
          this.setState(prevState => {
            return {
              formData: this._updateFormDataByLocale(
                prevProps.locale,
                this.props.locale,
                this.props.formData
              ),
              formContext: {
                ...prevState.formContext,
                forms: json['app']['forms']
              },
              forms: json['app']['forms']
            }
          })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('MyMessages form re-fetch failed due to ex', error)
          const code = CODES.MY_MESSAGES_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _updateFormDataByLocale = (oldLocale, newLocale, formData) => {
    let newFormData = _.cloneDeep(formData)
    let myMessages = _.get(newFormData, 'Application.myMessages')
    _.forEach(myMessages, message => {
      switch (message.messageType) {
        case MESSAGE_TYPE_TRANSLATIONS[oldLocale].notice:
          message.messageType = MESSAGE_TYPE_TRANSLATIONS[newLocale].notice
          break
        case MESSAGE_TYPE_TRANSLATIONS[oldLocale].alert:
          message.messageType = MESSAGE_TYPE_TRANSLATIONS[newLocale].alert
          break
        case MESSAGE_TYPE_TRANSLATIONS[oldLocale].greeting:
          message.messageType = MESSAGE_TYPE_TRANSLATIONS[newLocale].greeting
          break
        case MESSAGE_TYPE_TRANSLATIONS[oldLocale].document:
          message.messageType = MESSAGE_TYPE_TRANSLATIONS[newLocale].document
          break
      }
      switch (message.noticeType) {
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].approval:
          message.noticeType = NOTICE_TYPE_TRANSLATIONS[newLocale].approval
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].denial:
          message.noticeType = NOTICE_TYPE_TRANSLATIONS[newLocale].denial
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].rfi:
          message.noticeType = NOTICE_TYPE_TRANSLATIONS[newLocale].rfi
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].qhpApprovalNotice:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].qhpApprovalNotice
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].chipApprovalNotice:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].chipApprovalNotice
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].noiPending:
          message.noticeType = NOTICE_TYPE_TRANSLATIONS[newLocale].noiPending
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].medicaidApprovalNotice:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].medicaidApprovalNotice
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].tanfApprovalNotice:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].tanfApprovalNotice
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].qhpDenialNotice:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].qhpDenialNotice
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].chipDenialNotice:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].chipDenialNotice
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].snapDenialNotice:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].snapDenialNotice
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].medicaidDenialNotice:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].medicaidDenialNotice
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].tanfDenialNotice:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].tanfDenialNotice
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].noticeOfAction:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].noticeOfAction
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].applicantUpload:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].applicantUpload
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].documentUnsuccessfullyUploaded:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].documentUnsuccessfullyUploaded
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].deletedDocument:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].deletedDocument
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].renewal:
          message.noticeType = NOTICE_TYPE_TRANSLATIONS[newLocale].renewal
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].noticeOfActionRfi:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].noticeOfActionRfi
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].noticeOfActionRfiCoc:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].noticeOfActionRfiCoc
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].shelterVerificationForm:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].shelterVerificationForm
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].employmentVerificationForm:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].employmentVerificationForm
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].noaRenewalInitial:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].noaRenewalInitial
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].noaRenewal16th:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].noaRenewal16th
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].noaRenewalClosure:
          message.noticeType =
            NOTICE_TYPE_TRANSLATIONS[newLocale].noaRenewalClosure
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].nodApproval:
          message.noticeType = NOTICE_TYPE_TRANSLATIONS[newLocale].nodApproval
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].nodDenial:
          message.noticeType = NOTICE_TYPE_TRANSLATIONS[newLocale].nodDenial
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].rfiInitial:
          message.noticeType = NOTICE_TYPE_TRANSLATIONS[newLocale].rfiInitial
          break
        case NOTICE_TYPE_TRANSLATIONS[oldLocale].rfiCOC:
          message.noticeType = NOTICE_TYPE_TRANSLATIONS[newLocale].rfiCOC
          break
      }
    })
    return newFormData
  }

  static getDerivedStateFromProps(
    props: MyMessagesContainerProps,
    state: MyMessagesContainerState
  ) {
    return {
      formContext: {
        ...state.formContext,
        formData: props.formData || {}
      }
    }
  }

  _generatePDF = (json: Object) => {
    let message = json['Application']

    const fetchEndpoint = documentEndpont.replace(
      '{msgId}',
      this.state.messageId
    )

    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(resp => {
        //trick to download store a file having its URL
        var fileURL = resp.document.url
        let fileContent = 'Notification.pdf'
        if (!_.isNil(fileURL)) {
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.open(fileURL)
          } else {
            // auto download the file
            var a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = fileContent
            document.body.appendChild(a)
            a.click()
          }
        } else {
          const code = CODES.MY_MESSAGES_VIEW_PDF
          const error = {
            message: 'There is no content associated to the Document requested'
          }
          throw new ClientPortalException(error, code)
        }
      })
      .catch(error => {
        console.error('MyMessages _generatePDF failed with ex', error)
        const code = CODES.MY_MESSAGES_VIEW_PDF
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _goBack = () => {
    this.setState({
      messageDetailViewId: null
    })
  }
  _updateReadUnreadFlag(messageId: string) {
    const fetchEndpoint = updateReadFlgEndpoint.replace(
      '{messageId}',
      messageId
    )
    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(response => {
        return true
      })
      .catch(error => {
        console.error('MyMessages _updateReadUnreadFlag failed with ex', error)
        const code = CODES.MY_MESSAGES_UPDATE_READ_FLAG
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _loadForms = () => {
    const endpoint = formsEndpoint.replace('{version}', '1.0')
    helper
      .fetchJson(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        let application, forms, formContext
        application = formJson['app']
        forms = application['forms']

        this.setState(prevState => {
          return {
            formData: this.props.formData,
            application,
            forms,
            formContext: {
              ...prevState.formContext,
              refs: application['metaData']['refs'],
              forms: forms,
              viewMessageDetails: (id, messageId) => {
                this.setState({ messageDetailViewId: id, messageId })
                this._updateReadUnreadFlag(messageId)
              },
              config
            }
          }
        })
      })
      .catch(error => {
        console.error('MyMessages form fetch failed due to ex', error)
        const code = CODES.MY_MESSAGES_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _onSearchData = () => {
    const { startDate, endDate } = this.state.criteriaFormData

    let endpoint = createEndpoint(this.props)
    if (startDate || endDate) {
      const queryParameters = {
        startDate: startDate
          ? helper._moment(config, startDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
          : _.noop(),
        endDate: endDate
          ? helper._moment(config, endDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
          : _.noop()
      }
      endpoint += '?' + queryString.stringify(queryParameters)
    }

    this.props.loadData({
      endpoint
    })
  }

  convertDataURIToBinary = (dataURI: string) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }

  _onCriteriaChange = ({ formData }) => {
    this.setState({
      criteriaFormData: formData
    })
  }

  _onCriteriaClear = ({ formData }) => {
    this.setState({
      criteriaFormData: {}
    })
  }

  render() {
    const { presentation, minHeight, isLoading, locale } = this.props

    const {
      formData,
      application,
      formContext,
      forms,
      messageDetailViewId,
      messageId,
      criteriaFormData
    } = this.state

    const presentationProps = {
      application,
      formData,
      formContext,
      forms,
      isLoading,
      messageDetailViewId,
      messageId,
      goBack: this._goBack,
      generatePDF: this._generatePDF,
      minHeight,
      criteriaFormData,
      onCriteriaChange: this._onCriteriaChange,
      onCriteriaClear: this._onCriteriaClear,
      onSearchData: this._onSearchData,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    userRoleId,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

export default connect(mapStateToProps)(
  helper.withFetcher(
    MyMessagesContainer,
    false,
    initialData,
    createEndpoint,
    createRequest,
    responseMapper,
    config.tenant,
    true,
    true
  )
)

export const MyMessagesDoubleFetcherContainer = connect(mapStateToProps)(
  helper.withFetcher(
    MyMessagesContainer,
    true,
    initialData,
    createEndpoint,
    createRequest,
    responseMapper,
    config.tenant,
    true,
    true
  )
)
