import * as React from 'react'
import { Route } from 'react-router-dom'

declare const process
const baseUrl = process.env.PUBLIC_URL
const Fragment = (React as any).Fragment

export default function Routes(props) {
  const { auth, openIdRedirect } = props
  return <Fragment>{null}</Fragment>
}
