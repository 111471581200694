import * as React from 'react'
import { isEmpty } from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

const _ = { isEmpty }

interface MyBenefitsPresentationProps {
  formData: any
  formContext: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  onFormDataChange: (formData: any) => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  locale: string
}

export default function MyBenefitsPresentation(
  props: MyBenefitsPresentationProps
) {
  const {
    formData,
    formContext,
    forms,
    onFormDataChange,
    formComponents,
    locale
  } = props

  let output = <ScreenPreLoader loaded={false} />

  if (!_.isEmpty(forms)) {
    output = (
      <div className="form-section">
        <h1>
          <i className="pe-7s-gift" /> {I18n.t('MyBenefits.title')}
        </h1>
        <Form
          schema={forms[0]['schema']}
          uiSchema={forms[0]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <input type="submit" style={{ display: 'none' }} />
        </Form>
      </div>
    )
  }
  return (
    <>
      <Helmet>
        <title>{I18n.t('MyBenefits.title')}</title>
      </Helmet>
      {output}
    </>
  )
}
