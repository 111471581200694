import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const BenHReferralContainer = MultitenantLoadable(
  () => import('./BenHReferralContainer'),
  () => import('../../' + tenantDirectoryName + '/BenH/BenHReferralContainer')
)

const BenHReferralPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./BenHReferralPresentation'),
    () => import(config)
  ),
  () => import('./formComponents'),
  () => import(config)
)

export default function BenHReferral(props) {
  return (
    <BenHReferralContainer
      {...props}
      presentation={presentationProps => (
        <BenHReferralPresentation {...presentationProps} />
      )}
    />
  )
}
