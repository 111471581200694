import * as _ from 'lodash'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { getServerTime } from '../src/components/utils'
import { config } from '../../../config'
const moment = require('moment-mini-ts')
const moment_timezone = require('moment-timezone')

const requiredFieldError = 'This field is required.'
const expectedValueError = 'Please enter only valid characters.'
const invalidEmailMessage = 'Please enter valid email'
const inValidSSNMessage = 'Not a valid SSN number'
const expectedDateError = 'Please do not enter future date.'
const invalidBirthDateError = 'Invalid Birth Date'
const invalidDOBError = 'Invalid Date of Birth'
const emptyErrorMessage = 'This selection is not required.'
const tooManySelectedMessage = 'Too Many values selected'
const vaildDate = 'Please enter Valid Date'
const invalidZipExt = 'Must be four digits.'
const invalidZip = 'Must be five digits.'
const inValidSSN = 'Invalid SSN'
const currentDate = getServerTime()
function isEmpty(str) {
  return str === undefined || str == null || str.length === 0
}

function validBirthDateCheck(birthdate, currentDt) {
  //let currDt = moment(getServerTime())
  let currESTDT = moment_timezone
    .tz(currentDt, 'America/New_York')
    .format('YYYY-MM-DD')
  if (birthdate < '1900-01-01' || birthdate > currESTDT) {
    return true
  }
  return false
}

// Code Changes GPRB0359223 and US2819645
function validSSN(ssn) {
  let temp = ssn
  if (ssn.length > 0 && ssn.length < 9) {
    return false
  }
  if (
    temp.substring(0, 3) === '000' ||
    (temp.substring(0, 3) >= '900' && temp.substring(0, 3) <= '999') ||
    temp.substring(0, 3) === '666'
  ) {
    return false
  }
  if (temp.substring(3, 5) === '00' || temp.substring(5, 9) === '0000') {
    return false
  }
  return true
}

export function createErrorJsonString(message, step, id, displayMessage) {
  const error = {
    message,
    step,
    id,
    displayMessage
  }
  return JSON.stringify(error)
}

export function requireField(
  id,
  errors,
  formData,
  step,
  displayMessage,
  requiredFieldErrorMsg = requiredFieldError
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (isEmpty(localFormData)) {
      localErrors.addError(
        createErrorJsonString(requiredFieldErrorMsg, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

export function requireMcrField(
  id,
  errors,
  formData,
  step,
  displayMessage,
  requiredFieldErrorMsg = requiredFieldError
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (isEmpty(localFormData) || isEmpty(localFormData.value)) {
      localErrors.addError(
        createErrorJsonString(requiredFieldErrorMsg, step, id, displayMessage)
      )
    }
  } catch (err) {}
}
export function errorMessage(
  id,
  errors,
  formData,
  step,
  displayMessage,
  requiredFieldErrorMsg = requiredFieldError
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    localErrors.addError(
      createErrorJsonString(requiredFieldErrorMsg, step, id, displayMessage)
    )
  } catch (err) {}
}

function requireChildCareCounty(
  id,
  errors,
  formData,
  step,
  displayMessage,
  requiredFieldErrorMsg = requiredFieldError
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (!_.isEqual(localFormData, 'WV')) {
      localErrors.addError(
        createErrorJsonString(requiredFieldErrorMsg, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

export function notRequiredField(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(emptyErrorMessage, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

// Code Changes GPRB0359078 and US2554056
function validZipCodeCheck(zip) {
  if (String(zip).length > 0 && String(zip).length < 5) {
    return true
  }
  return false
}

function validZipExtCheck(zipExt) {
  if (String(zipExt).length > 0 && String(zipExt).length < 4) {
    return true
  }
  return false
}

// Code Changes GPRB0359078 and US2554056
export function notRequiredZipExt(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(invalidZipExt, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

export function notRequiredZip(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(invalidZip, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

export function validEnteredValueForGrandParentes(grandParents) {
  if (
    (grandParents.clientFstNm && grandParents.clientFstNm !== undefined) ||
    (grandParents.clientMidlNm && grandParents.clientMidlNm !== undefined) ||
    (grandParents.clientLstNm && grandParents.clientLstNm !== undefined) ||
    (grandParents.suffixCode && grandParents.suffixCode !== undefined) ||
    (grandParents.addrLn1Txt && grandParents.addrLn1Txt !== undefined) ||
    (grandParents.addrLn2Txt && grandParents.addrLn2Txt !== undefined) ||
    (grandParents.adrLn3Txt && grandParents.adrLn3Txt !== undefined) ||
    (grandParents.adrLn4Txt && grandParents.adrLn4Txt !== undefined) ||
    (grandParents.aptNumber && grandParents.aptNumber !== undefined) ||
    (grandParents.cityNm && grandParents.cityNm !== undefined) ||
    (grandParents.ctyNm1 && grandParents.ctyNm1 !== undefined) ||
    (grandParents.county && grandParents.county !== undefined) ||
    (grandParents.zipcode && grandParents.zipcode !== undefined) ||
    (grandParents.zipExt && grandParents.zipExt !== undefined) ||
    (grandParents.primaryPhone && grandParents.primaryPhone !== undefined)
  ) {
    return true
  }
  return false
}

export function validFirstNameLastNameForGrandParentes(grandParents) {
  if (
    (grandParents.clientFstNm && grandParents.clientFstNm !== undefined) ||
    (grandParents.clientLstNm && grandParents.clientLstNm !== undefined)
  ) {
    return true
  }
  return false
}

function validGPRelationCheck(relationCode) {
  if (relationCode === undefined) {
    return true
  }
  return false
}

export function notRequiredRelationshipForGPs(
  id,
  errors,
  formData,
  step,
  displayMessage
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    localErrors.addError(
      createErrorJsonString(requiredFieldError, step, id, displayMessage)
    )
  } catch (err) {}
}

export function tooManySelected(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    localErrors.addError(
      createErrorJsonString(tooManySelectedMessage, step, id, displayMessage)
    )
  } catch (err) {}
}

function benfitesError(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    localErrors.addError(
      createErrorJsonString(requiredFieldError, step, id, displayMessage)
    )
  } catch (err) {}
}
function userrestriction(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    if (formData.Application.clients[0].exptMagiIncm > 18) {
      localErrors.addError(
        createErrorJsonString(
          'This field exceeds maximum value.',
          step,
          id,
          displayMessage
        )
      )
    }
  } catch (err) {}
}

function checkMAIDNUmber(
  id,
  errors,
  formData,
  step,
  displayMessage,
  formContext
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (formContext.isTempMaidValid && !formContext.isTempMaidValid.isValid) {
      localErrors.addError(
        createErrorJsonString(
          'Invalid MAID Number. Please re-enter.',
          step,
          id,
          displayMessage
        )
      )
    }
  } catch (err) {}
}

function checkForMAIDNumberLeadingZeros(
  id,
  errors,
  formData,
  step,
  displayMessage,
  formContext
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (formData.Application.clients[0].temporaryMaidNumber === undefined) {
      localErrors.addError(
        createErrorJsonString(expectedValueError, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

function requireSupportOrder(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    let courtOrderDocs
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (
      formData.Application.clientApplicant[schemaPath[2]].supportOrder === 'Y'
    ) {
      for (let i = 0; i < formData.Application.clients.length; i++) {
        if (formData.Application.clients[i].myDocuments) {
          courtOrderDocs =
            _.find(
              formData.Application.clients[i].myDocuments[0].docList,
              function(o) {
                return o.fileType === 'Court Order'
              }
            ) || courtOrderDocs
        }
      }
      if (!courtOrderDocs) {
        localErrors.addError(
          createErrorJsonString(requiredFieldError, step, id, displayMessage)
        )
      }
    }
  } catch (err) {}
}
function requireDocuments(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (formData.Application.clients[schemaPath[2]].copyOfBC === 'Y') {
      if (formData.Application.clients[schemaPath[2]].myDocuments) {
        let birthCertDocs = _.find(
          formData.Application.clients[schemaPath[2]].myDocuments[0].docList,
          function(o) {
            return o.fileType === 'Birth Certificate'
          }
        )
        if (birthCertDocs === undefined) {
          localErrors.addError(
            createErrorJsonString(requiredFieldError, step, id, displayMessage)
          )
        }
      } else {
        localErrors.addError(
          createErrorJsonString(requiredFieldError, step, id, displayMessage)
        )
      }
    }
  } catch (err) {}
}

export function requireZipCode(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
  } catch (err) {
    console.log(err)
  }
}
function requireFieldCHSP(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    const chspFlag = formData.Application.clients.find(client => {
      return client.optBenefitCHSP === true
    })
    if (chspFlag && chspFlag.optBenefitCHSP) {
      if (isEmpty(localFormData)) {
        localErrors.addError(
          createErrorJsonString(requiredFieldError, step, id, displayMessage)
        )
      }
    }
  } catch (err) {}
}

function requireDaysTime(i, formData) {
  try {
    let localFormData = formData

    let client = formData.Application.clients[i]

    var a = client.schoolSchedule[0]

    let keys = Object.keys(a)
    var superWeek = _.slice(keys, 0, 7)

    var selectedday = 0
    if (client.childCareSchool === 'Y' && client.webSchoolSchedule === 'N') {
      for (var j = 0; j < superWeek.length; j++) {
        if (a[superWeek[j]] === undefined || a[superWeek[j]] === false) {
          selectedday++
        }
      }
      if (selectedday === superWeek.length) {
        return true
      }

      if (a.timeFrom === undefined || a.timeTo === undefined) {
        return true
      }
    }

    return false
  } catch (err) {
    console.log(err)
  }
}

function compareCOCSSN(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (localFormData && isNaN(localFormData)) {
      localErrors.addError(
        createErrorJsonString(expectedValueError, step, id, 'SSN is invalid')
      )
    } else if (
      localFormData &&
      !isNaN(localFormData) &&
      localFormData.length != 9
    ) {
      localErrors.addError(
        createErrorJsonString(inValidSSNMessage, step, id, 'SSN is invalid')
      )
    }
  } catch (err) {}
}

function compareAppSSN(
  idSSN,
  idConfirSSN,
  errors,
  formData,
  step,
  displayMessage
) {
  try {
    let localErrors = errors
    let localSSNFormData = formData
    let localSSNConfirFormData = formData
    const schemaPathSSN = idSSN.split('_')
    const schemaPathSSNConfir = idConfirSSN.split('_')
    for (let i = 0; i < schemaPathSSN.length; ++i) {
      localErrors = localErrors[schemaPathSSN[i]]
      localSSNFormData = localSSNFormData[schemaPathSSN[i]]
    }
    for (let i = 0; i < schemaPathSSNConfir.length; ++i) {
      localSSNConfirFormData = localSSNConfirFormData[schemaPathSSNConfir[i]]
    }

    if (localSSNConfirFormData != localSSNFormData) {
      localErrors.addError(
        createErrorJsonString(
          'Please validate both SSN fields',
          step,
          idSSN,
          'SSN Fields do not match'
        )
      )
    }
  } catch (err) {}
}

export function validateUniqueSSN(
  id,
  errors,
  step,
  displayMessage,
  errorMsg,
  ssn,
  ssns
) {
  try {
    let length = 0
    let localErrors = errors
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
    }
    ssns.forEach(element => {
      if (element == ssn) {
        length++
      }
    })

    if (length > 1) {
      localErrors.addError(
        createErrorJsonString(errorMsg, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

function compareAppEmail(
  Email1,
  Email2,
  errors,
  formData,
  step,
  displayMessage
) {
  try {
    let localErrors = errors
    let localEmail1FormData = formData
    let localEmail2FormData = formData
    const schemaPathEmail1 = Email1.split('_')
    const schemaPathEmail2 = Email2.split('_')
    for (let i = 0; i < schemaPathEmail1.length; ++i) {
      localErrors = localErrors[schemaPathEmail1[i]]
      localEmail1FormData = localEmail1FormData[schemaPathEmail1[i]]
    }
    for (let i = 0; i < schemaPathEmail2.length; ++i) {
      localEmail2FormData = localEmail2FormData[schemaPathEmail2[i]]
    }

    if (localEmail2FormData != localEmail1FormData) {
      localErrors.addError(
        createErrorJsonString(
          'Please validate both Email fields',
          step,
          Email2,
          'Email Fields do not match'
        )
      )
    }
  } catch (err) {}
}

function expectAlphabets(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    if (!isEmpty(localFormData) && !/^[a-z \-']+$/i.test(localFormData)) {
      localErrors.addError(
        createErrorJsonString(expectedValueError, step, id, displayMessage)
      )
    }
  } catch (err) {}
}
function expectCorrectEmailId(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (
      !isEmpty(localFormData) &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(localFormData)
    ) {
      localErrors.addError(
        createErrorJsonString(invalidEmailMessage, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

function expectBthDtLessCurrDt(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    const currDt = _moment(config, currentDate).format('YYYY-MM-DD')
    if (id === 'Application_clientDt') {
      if (formData.Application.clientDt > currDt) {
        localErrors.addError(
          createErrorJsonString(expectedDateError, step, id, displayMessage)
        )
      }
    } else {
      if (formData.Application.clients[schemaPath[2]].clientBthDt > currDt) {
        localErrors.addError(
          createErrorJsonString(expectedDateError, step, id, displayMessage)
        )
      }
    }
  } catch (err) {}
}

// Code changes for GPRB0359133 and US2558381
function expectBthDtgreaterthanNinteenHundredandLessCurrDt(
  id,
  errors,
  formData,
  step,
  displayMessage
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(invalidBirthDateError, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

// Code Changes GPRB0359223 and US2819645
function ssnValidation(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(inValidSSN, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

// Code changes for GPRB0359133 and US2558381
function cocBthDtgreaterthanNinteenHundredandLessCurrDt(
  id,
  errors,
  formData,
  step,
  displayMessage
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(invalidDOBError, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

function expectCorrectDiagnosisDt(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    const currDt = _moment(config).format('YYYY-MM-DD')
    if (formData.Application.clients[schemaPath[2]].pregnant === 'Y') {
      if (
        !(
          formData.Application.clients[schemaPath[2]].diagnosisDt <
          formData.Application.clients[schemaPath[2]].dueDate
        )
      ) {
        localErrors.addError(
          createErrorJsonString(vaildDate, step, id, displayMessage)
        )
      }
    }
  } catch (err) {}
}

function requireChildSupport(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    let childrenArray = []
    let relationshipChildren = []
    let childrenCount = 0
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (formData.Application.benefitCategorieslist.indexOf('CHSP') > -1) {
      const clients = formData.Application.clients
      for (let i = 0; i < clients.length; i++) {
        if (i === 0) {
          continue
        }
        if (
          clients[i].relationship === '37' ||
          clients[i].relationship === '59'
        ) {
          if (clients[i].optBenefitCHSP) {
            childrenArray.push(clients[i].clientFstNm)
          }
          childrenCount = childrenCount + 1
        }
      }
      relationshipChildren = formData.Application.clients.find(client => {
        return client.relationship === '37' || client.relationship === '59'
      })
      if (
        formData.Application.clients.length > 1 &&
        childrenArray.length === 0 &&
        displayMessage !=
          'Please create a new application if you have more than 15 Children' &&
        displayMessage !=
          'Por favor, cree una nueva aplicacion si tiene mas de 15 hijos'
      ) {
        localErrors.addError(
          createErrorJsonString('', step, id, displayMessage)
        )
      } else if (
        ((formData.Application.clients.length > 1 &&
          !relationshipChildren &&
          formData.Application.benefitCategorieslist.indexOf('CHSP') > -1) ||
          (formData.Application.clients.length === 1 &&
            formData.Application.benefitCategorieslist.indexOf('CHSP') > -1)) &&
        displayMessage !=
          'Please create a new application if you have more than 15 Children' &&
        displayMessage !=
          'Por favor, cree una nueva aplicacion si tiene mas de 15 hijos'
      ) {
        localErrors.addError(
          createErrorJsonString('', step, id, displayMessage)
        )
      }

      if (
        childrenCount > 15 &&
        (displayMessage ===
          'Please create a new application if you have more than 15 Children' ||
          displayMessage ===
            'Por favor, cree una nueva aplicacion si tiene mas de 15 hijos')
      ) {
        localErrors.addError(
          createErrorJsonString('', step, id, displayMessage)
        )
      }
    }
  } catch (err) {}
}

function expectCorrectDueDt(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    const currDt = _moment(config, currentDate).format('YYYY-MM-DD')
    if (formData.Application.clients[schemaPath[2]].pregnant === 'Y') {
      if (
        formData.Application.clients[parseInt(schemaPath[2])].dueDate <= currDt
      ) {
        localErrors.addError(
          createErrorJsonString(vaildDate, step, id, displayMessage)
        )
      }
    }
  } catch (err) {}
}

function getNodeValue(id, formData) {
  let localFormData = formData
  const schemaPath = id.split('_')
  for (let i = 0; i < schemaPath.length; ++i) {
    localFormData = localFormData[schemaPath[i]]
  }

  return localFormData
}

// US2668722
// dont show when MPA and HEWL selected for same applicant
function determineNonApplicantForChildCare(index, formData) {
  const benefitCatList = _.get(
    formData,
    'Application.benefitCategorieslist',
    []
  )
  if (
    _.includes(benefitCatList, 'CHCR') &&
    formData.Application.clients[index].clientBthDt === undefined &&
    !formData.Application.clients[index].optBenefitMDPA &&
    !formData.Application.clients[index].optBenefitHEWL
  ) {
    return true
  }
  return false
}
//US2915041
function evalChildCareEligibilityOnDOBChange(index, formData) {
  const benefitCatList = _.get(
    formData,
    'Application.benefitCategorieslist',
    []
  )
  if (
    _.includes(benefitCatList, 'CHCR') &&
    formData.Application.clients[index].optBenefitCHCR &&
    formData.Application.clients[index].role !== undefined &&
    formData.Application.clients[index].role === 'child'
  ) {
    let clientBirthDate = _moment(
      config,
      formData.Application.clients[index].clientBthDt
    )
    let date = _moment(config, currentDate)
    let age = date.diff(clientBirthDate, 'years')
    if (clientBirthDate !== undefined && !_.inRange(age, 0, 18)) {
      return true
    }
  }
  return false
}

function addErrorToNode(id, errors, step, fieldError, displayMessage) {
  try {
    let localErrors = errors
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
    }

    if (localErrors) {
      localErrors.addError(
        createErrorJsonString(fieldError, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

export function validateNewUserForm(formData, errors, formContext) {
  if (isEmpty(formData.firstName)) {
    requireField('firstName', errors, formData, 0, 'Please enter First Name')
  }
  if (isEmpty(formData.lastName)) {
    requireField('lastName', errors, formData, 0, 'Please enter Last Name')
  }
  if (isEmpty(formData.emailAddress)) {
    requireField('emailAddress', errors, formData, 0, 'Please enter Email')
  }

  if (
    formContext.isPEAdmin ||
    formContext.isCPAdmin ||
    formContext.isWorkerAdmin
  ) {
    if (isEmpty(formData.selectedRole) && formContext.isPEAdmin) {
      requireField('selectedRole', errors, formData, 0, 'Please select Role')
    }
    if (
      (formData.selectedRole === 'PE Worker' &&
        isEmpty(formData.completionDate) &&
        formContext.isPEAdmin) ||
      formContext.isCPAdmin ||
      formContext.isWorkerAdmin
    ) {
      requireField(
        'completionDate',
        errors,
        formData,
        0,
        'Please enter Data Agreements Date'
      )
    }
    if (
      (formData.selectedRole === 'PE Worker' &&
        isEmpty(formData.trainingDate) &&
        formContext.isPEAdmin) ||
      formContext.isCPAdmin ||
      formContext.isWorkerAdmin
    ) {
      requireField(
        'trainingDate',
        errors,
        formData,
        0,
        'Please enter Training Confirmation Date'
      )
    }
    if (formContext.isWorkerAdmin) {
      if (isEmpty(formData.roles)) {
        requireField('roles', errors, formData, 0, 'Please enter Role(s)')
      }
      if (isEmpty(formData.orgs)) {
        requireField('orgs', errors, formData, 0, 'Please enter Org(s)')
      }
    }
  }

  return errors
}

export function validateContributorForm(formData, errors, formContext) {
  if (!formData[0].clientFstNm) {
    requireField(
      '0_clientFstNm',
      errors,
      formData,
      0,
      'Please enter First Name'
    )
  }
  if (!formData[0].clientLstNm) {
    requireField('0_clientLstNm', errors, formData, 0, 'Please enter Last Name')
  }
  if (validZipCodeCheck(formData[0].address.zip)) {
    errorMessage(
      '0_address_zip',
      errors,
      formData,
      0,
      'Zip Code must be five digits or may be left blank.',
      'Must be five digits.'
    )
  }
  if (validBirthDateCheck(formData[0].clientBthDt, currentDate)) {
    errorMessage(
      '0_clientBthDt',
      errors,
      formData,
      0,
      'Birth Date cannot be prior to 01/01/1900 and cannot be a future date.',
      'Invalid Birth Date'
    )
  }
  // Code Changes GPRB0359223 and US2819645
  // Add Non-Applicant SSN Validation
  if (formData[0].prtcpSsn && formData[0].prtcpSsn !== undefined) {
    if (!validSSN(formData[0].prtcpSsn))
      ssnValidation(
        '0_prtcpSsn',
        errors,
        formData,
        'Invalid SSN',
        'SSN: Invalid Social Security Number'
      )
  }
  return errors
}

export function validatePE_CPForm(formData, errors, step, errorMessages) {
  const validateAll = step == null
  let validationStep = null

  //testing access
  //Pre-Questionnaire validation
  if (step === 1 || validateAll) {
    validationStep = 1
    compareAppSSN(
      'Application_clients_0' + '_prtcpSsnReEntry',
      'Application_clients_0' + '_prtcpSsn',
      errors,
      formData,
      validationStep,
      errorMessages['SSNNotmatch']
    )
    // Code Changes GPRB0359223 and US2819645
    // PE Application SSN Validation
    if (
      formData.Application.clients[0].prtcpSsn &&
      formData.Application.clients[0].prtcpSsn !== undefined
    ) {
      if (!validSSN(formData.Application.clients[0].prtcpSsn))
        ssnValidation(
          'Application_clients_' + 0 + '_prtcpSsn',
          errors,
          formData,
          validationStep,
          errorMessages['InvalidSocialSecurityNumber']
        )
    }
    if (
      formData.Application.clients[0].prtcpSsnReEntry &&
      formData.Application.clients[0].prtcpSsnReEntry !== undefined
    ) {
      if (!validSSN(formData.Application.clients[0].prtcpSsnReEntry))
        ssnValidation(
          'Application_clients_' + 0 + '_prtcpSsnReEntry',
          errors,
          formData,
          validationStep,
          errorMessages['InvalidSocialSecurityNumber']
        )
    }
    requireField(
      'Application_clients_0' + '_clientFstNm',
      errors,
      formData,
      validationStep,
      errorMessages['FirstNamerequired']
    )
    requireField(
      'Application_clients_0' + '_clientLstNm',
      errors,
      formData,
      validationStep,
      errorMessages['LastNamerequired']
    )

    requireField(
      'Application_clients_0' + '_clientBthDt',
      errors,
      formData,
      validationStep,
      errorMessages['BirthDaterequired']
    )

    // Code changes for GPRB0359133 and US2558381
    /* if (formData.Application.clients[0].clientBthDt !== undefined) {
      expectBthDtLessCurrDt(
        'Application_clients_' + 0 + '_clientBthDt',
        errors,
        formData,
        validationStep,
        'Please do not enter future date.'
      )
    } */
    if (
      formData.Application.clients[0].clientBthDt &&
      formData.Application.clients[0].clientBthDt !== undefined
    ) {
      if (
        validBirthDateCheck(
          formData.Application.clients[0].clientBthDt,
          currentDate
        )
      )
        expectBthDtgreaterthanNinteenHundredandLessCurrDt(
          'Application_clients_' + 0 + '_clientBthDt',
          errors,
          formData,
          validationStep,
          errorMessages['BirthDatecannotbeprior']
        )
    }

    requireField(
      'Application_clients_0' + '_genderCode',
      errors,
      formData,
      validationStep,
      errorMessages['GenderRequired']
    )
    requireField(
      'Application_clients_0' + '_isCovered',
      errors,
      formData,
      validationStep,
      'Medicaid or Chip Coverage information is required. '
    )
    requireField(
      'Application_clients_0_address_0' + '_adrLn3Txt',
      errors,
      formData,
      validationStep,
      errorMessages['StreetName']
    )
    requireField(
      'Application_clients_0_address_0' + '_ctyNm',
      errors,
      formData,
      validationStep,
      errorMessages['Cityrequired']
    )
    requireField(
      'Application_clients_0_address_0' + '_county',
      errors,
      formData,
      validationStep,
      errorMessages['Countyrequired']
    )
    requireField(
      'Application_clients_0_address_0' + '_st',
      errors,
      formData,
      validationStep,
      errorMessages['Stateisrequired']
    )
    requireField(
      'Application_clients_0_address_0' + '_zip',
      errors,
      formData,
      validationStep,
      errorMessages['Ziprequired']
    )

    // Code Changes GPRB0359078 and US2554056
    // PE Application - Mailing Address Zip Code and Zip Ext validation
    let address = _.get(formData, 'Application.clients[0].address', [])
    for (let id = 0; id < address.length; id++) {
      if (address && address[id].zip && address[id].zip !== undefined) {
        if (validZipCodeCheck(address[id].zip)) {
          notRequiredZip(
            'Application_clients_0_address_' + id + '_zip',
            errors,
            formData,
            validationStep,
            errorMessages['ZipCodefivemustdigits']
          )
        }
      }
      if (address && address[id].zipExt && address[id].zipExt !== undefined) {
        if (validZipExtCheck(address[id].zipExt)) {
          notRequiredZipExt(
            'Application_clients_0_address_' + id + '_zipExt',
            errors,
            formData,
            validationStep,
            errorMessages['Zipmustbefourdigits']
          )
        }
      }
    }

    if (formData.Application.clients[0].mailingAddressCheck !== undefined) {
      if (formData.Application.clients[0].mailingAddressCheck.length > 0) {
        requireField(
          'Application_clients_0' + '_mailingAddressRadioBtn',
          errors,
          formData,
          validationStep,
          errorMessages['MailingTemporaryselectionrequired']
        )

        requireField(
          'Application_clients_0_secondaryAddress' + '_adrLn3Txt',
          errors,
          formData,
          validationStep,
          errorMessages['StreetName']
        )
        requireField(
          'Application_clients_0_secondaryAddress' + '_ctyNm',
          errors,
          formData,
          validationStep,
          'City Name is required. '
        )
        requireField(
          'Application_clients_0_secondaryAddress' + '_county',
          errors,
          formData,
          validationStep,
          errorMessages['Countyrequired']
        )
        requireField(
          'Application_clients_0_secondaryAddress' + '_st',
          errors,
          formData,
          validationStep,
          errorMessages['Stateisrequired']
        )
        requireField(
          'Application_clients_0_secondaryAddress' + '_zip',
          errors,
          formData,
          validationStep,
          errorMessages['Ziprequired']
        )

        // Code Changes GPRB0359078 and US2554056
        // PE Application - Secondary Address Zip Code and Zip Ext validation
        let secondaryAddress = _.get(
          formData,
          'Application.clients[0].secondaryAddress',
          {}
        )
        if (
          secondaryAddress &&
          secondaryAddress.zip &&
          secondaryAddress.zip !== undefined
        ) {
          if (validZipCodeCheck(secondaryAddress.zip)) {
            notRequiredZip(
              'Application_clients_0_secondaryAddress' + '_zip',
              errors,
              formData,
              validationStep,
              errorMessages['ZipCodefivemustdigits']
            )
          }
        }
        if (
          secondaryAddress &&
          secondaryAddress.zipExt &&
          secondaryAddress.zipExt !== undefined
        ) {
          if (validZipExtCheck(secondaryAddress.zipExt)) {
            notRequiredZipExt(
              'Application_clients_0_secondaryAddress' + '_zipExt',
              errors,
              formData,
              validationStep,
              errorMessages['ZipCodefivedigits']
            )
          }
        }
      }
    }
  }

  //CitizenShip validation
  if (step === 2 || validateAll) {
    validationStep = 2
    requireField(
      'Application_clients_0' + '_usCtznOrNatInd',
      errors,
      formData,
      validationStep,
      errorMessages['USCitizenrequired']
    )
  }

  //HouseHold & Income Validation
  if (step === 3 || validateAll) {
    validationStep = 3
    for (let i = 0; i < formData.Application.clients.length; ++i) {
      requireField(
        'Application_clients_0' + '_exptMagiIncm',
        errors,
        formData,
        validationStep,
        errorMessages['Numberindividualapplicant']
      )
      userrestriction(
        'Application_clients_0' + '_exptMagiIncm',
        errors,
        formData,
        validationStep,
        errorMessages['Maximumhouseholdsize']
      )
      requireField(
        'Application_clients_0' + '_exptIncmThsYr',
        errors,
        formData,
        validationStep,
        errorMessages['HouseholdestimatedIncome']
      )
      requireField(
        'Application_clients_0' + '_exptPeriod',
        errors,
        formData,
        validationStep,
        errorMessages['Incomeperiodrequired']
      )
    }
  }

  //Additional Question validation
  if (step === 5 || validateAll) {
    validationStep = 5
    if (formData.Application.clients[0].genderCode === '2') {
      requireField(
        'Application_benefitProgramInformations_0' + '_isPregnantNow',
        errors,
        formData,
        validationStep,
        errorMessages['CurrentPregnancyInformationrequired']
      )
    }
    requireField(
      'Application_benefitProgramInformations_0' + '_isApproved',
      errors,
      formData,
      validationStep,
      errorMessages['PresumptiveEligibilityApprovalRequire']
    )

    if (
      formData.Application.clients[0].genderCode === '2' &&
      formData.Application.benefitProgramInformations[0].isApproved === 'Y'
    ) {
      requireField(
        'Application_benefitProgramInformations_0' + '_isPregnantWhenApproved',
        errors,
        formData,
        validationStep,
        errorMessages['PregnancyInformationrequired']
      )

      if (
        formData.Application.benefitProgramInformations[0]
          .isPregnantWhenApproved === 'Y'
      ) {
        requireField(
          'Application_benefitProgramInformations_0' + '_isPregnancyEnded',
          errors,
          formData,
          validationStep,
          errorMessages['PregrnancyEndInformation']
        )
        if (
          formData.Application.benefitProgramInformations[0]
            .isPregnancyEnded === 'Y' &&
          !formData.Application.benefitProgramInformations[0].pregEndDate
        ) {
          requireField(
            'Application_benefitProgramInformations_0' + '_pregEndDt',
            errors,
            formData,
            validationStep,
            errorMessages['PregnancyEndDateisrequired']
          )
        }
      }
    }
    requireField(
      'Application_fosterclients_0' + '_fostercare',
      errors,
      formData,
      validationStep,
      errorMessages['Fostercareinformationrequired']
    )
    
    requireField(
      'Application_fosterclients_0' + '_fostercareNonWV',
      errors,
      formData,
      validationStep,
      'Foster care (any state) information is required. '
    )
    
    if (formData.Application.clients[0].genderCode === '2') {
      requireField(
        'Application_cancerclients_0' + '_breastAndCervicalCancerPatients',
        errors,
        formData,
        validationStep,
        errorMessages['BreastorCervicalcancerinformation']
      )
    }

    requireField(
      'Application_caretakerclients_0' + '_parentorcaretaker',
      errors,
      formData,
      validationStep,
      errorMessages['ParentCaretakerinformationRequire']
    )
  }

  //Review & Sign validation
  if (step === 6 || validateAll) {
    validationStep = 6
    requireField(
      'Application_clients_0' + '_presumEligStartDate',
      errors,
      formData,
      validationStep,
      errorMessages['PresumptiveEligibilityStartDate']
    )
    requireField(
      'Application_clients_0' + '_agreement',
      errors,
      formData,
      validationStep,
      errorMessages['CoveredMedicaidorChiprequired']
    )
  }
  return errors
}

export function validateBenefitFinder(
  formData,
  errors,
  step,
  checkInArr,
  errorMessages
) {
  if (step == 0) {
    if (
      formData['benefitList'] == undefined ||
      formData['benefitList'].length == 0
    ) {
      requireField('benefitList', errors, formData, step, errorMessages[0])
    }
  } else {
    if (
      (step == 1 &&
        (checkInArr[1].schema.title == 'General Questions ' ||
          checkInArr[1].schema.title == 'Cuestiones generales')) ||
      step == checkInArr.length - 1
    ) {
      if (formData['householdWV'] == 'Y' && formData['county'] == undefined) {
        requireField('county', errors, formData, step, errorMessages[1])
      }
      if (formData['householdWV'] == 'N' && formData['county'] !== undefined) {
        notRequiredField('county', errors, formData, 0, errorMessages[2])
      }

      if (
        !(
          formData['benefitList'].length == 1 &&
          formData['benefitList'][0] == 'LIEAP'
        ) &&
        (formData['oldPeople'] == undefined ||
          formData['oldPeople'].length == 0)
      ) {
        requireField('oldPeople', errors, formData, step, errorMessages[3])
      }
      if (formData['oldPeople'] !== undefined) {
        if (formData['peopleInHousehold'] < formData['oldPeople'].length) {
          tooManySelected('oldPeople', errors, formData, step, errorMessages[4])
        }
      }
    }
    if (
      (step == 2 &&
        (checkInArr[2].schema.title == 'Household Questions' ||
          checkInArr[2].schema.title == 'Preguntas sobre el hogar')) ||
      step == checkInArr.length - 1
    ) {
      if (
        formData['attendSchool'] == 'Y' &&
        (formData['typeSchool'] == undefined ||
          formData['typeSchool'].length == 0)
      ) {
        requireField('typeSchool', errors, formData, step, errorMessages[5])
      }
      if (
        formData['otherUtilities'] == 'Y' &&
        formData['howMany'] == undefined
      ) {
        requireField('howMany', errors, formData, step, errorMessages[6])
      }

      if (
        formData['attendSchool'] == 'N' &&
        formData['typeSchool'] !== undefined &&
        formData['typeSchool'].length > 0
      ) {
        notRequiredField('typeSchool', errors, formData, 0, errorMessages[7])
      }
    }
    if (
      (step == 3 &&
        (checkInArr[3].schema.title == 'Income & Resources Questions' ||
          checkInArr[3].schema.title == 'Preguntas sobre la salud')) ||
      (step == 4 &&
        (checkInArr[4].schema.title == 'Income & Resources Questions' ||
          checkInArr[3].schema.title == 'Preguntas sobre la salud'))
    ) {
      if (
        formData['householdEarn'] == undefined ||
        formData['householdEarn'] == ''
      ) {
        requireField('householdEarn', errors, formData, step, errorMessages[8])
      }
      if (
        formData['earningPerMonth'] == undefined ||
        formData['earningPerMonth'] == ''
      ) {
        requireField(
          'earningPerMonth',
          errors,
          formData,
          step,
          errorMessages[9]
        )
      }
      if (
        formData['childrenChildCare'] == undefined &&
        formData.benefitList &&
        formData.benefitList.length > 0 &&
        formData.benefitList.indexOf('Childcare') > -1
      ) {
        requireField(
          'childrenChildCare',
          errors,
          formData,
          step,
          errorMessages[10]
        )
      }
    }

    var checkAge = ['60 - 64 years', '65 years or older']
    if (step == 4 && formData.anyoneDisabled == 'N') {
      if (
        formData.benefitList &&
        formData.benefitList.length > 0 &&
        formData.benefitList.indexOf('SNAP') > -1 &&
        formData.individual60Over !== '' &&
        formData.individual60Over !== '0.00' &&
        formData.individual60Over !== '0'
      ) {
        let ageValue = false
        for (var i = 0; i < formData.oldPeople.length; i++) {
          ageValue = _.includes(checkAge, formData.oldPeople[i])
          if (ageValue) {
            break
          }
        }
        if (!ageValue) {
          notRequiredField(
            'individual60Over',
            errors,
            formData,
            '',
            errorMessages[11]
          )
        }
      }
    }
  }
}

export function validateIncidentForm(formData, errors) {
  requireField('incidentForm_name', errors, formData, 0, 'Name is required. ')
  requireField(
    'incidentForm_emailId',
    errors,
    formData,
    0,
    'Email is required. '
  )
  requireField(
    'incidentForm_contactNumber',
    errors,
    formData,
    0,
    'Preferred Contact Number is required. '
  )
  requireField(
    'incidentForm_issueDescription',
    errors,
    formData,
    0,
    'Issue Information is required. '
  )

  return errors
}

export function validateUserAccess(formData, errors) {
  if (
    formData.Application.usercompleteDetails[0].dataAgreementCompletionDate ==
    undefined
  ) {
    requireField(
      'Application_usercompleteDetails_0_trainingConfirmationDate',
      errors,
      formData,
      0,
      'Training Confirmation Date : This feild is required'
    )
  }
  if (
    formData.Application.usercompleteDetails[0].dataAgreementCompletionDate ==
    undefined
  ) {
    requireField(
      'Application_usercompleteDetails_0_dataAgreementCompletionDate',
      errors,
      formData,
      0,
      'Data Agreements Completion Date: This feild is required'
    )
  }
}

export function validateOrgForm(formData, errors, formContext) {
  if (formData && formData.Application && formData.Application.agencyList) {
    let agencyList = formData.Application.agencyList[0]
    if (
      agencyList.organizationName === undefined ||
      agencyList.organizationName.length === 0
    ) {
      requireField(
        'Application_agencyList_0_organizationName',
        errors,
        formData,
        0,
        'Organization Name is required.'
      )
    }
    if (
      agencyList.phoneNumber &&
      agencyList.phoneNumber.length > 0 &&
      agencyList.phoneNumber.length < 10
    ) {
      errorMessage(
        'Application_agencyList_0_phoneNumber',
        errors,
        formData,
        0,
        'Phone Number is required',
        'Phone Number must be 10 digits.'
      )
    }
    if (
      agencyList.zipCode &&
      agencyList.zipCode.length > 0 &&
      agencyList.zipCode.length < 5
    ) {
      errorMessage(
        'Application_agencyList_0_zipCode',
        errors,
        formData,
        0,
        'Invalid Zip/Postal Code',
        'Zip Code must be 5 digits.'
      )
    }
    if (
      agencyList.emailaddr &&
      !agencyList.emailaddr.match(
        '^[\\w-]+(?:\\.[\\w-]+)*@(?:[\\w-]+\\.)+[a-zA-Z]{2,7}$'
      )
    ) {
      errorMessage(
        'Application_agencyList_0_emailaddr',
        errors,
        formData,
        0,
        'Invalid Email Address',
        'Invalid Email Address format.'
      )
    }
  }
  return errors
}
export function validateCPForm(
  formData,
  errors,
  step,
  formContext,
  isIncomplete,
  currentDate,
  submitClicked,
  errorMessages
) {
  const validateAll = step == null
  let validationStep = null
  let showBenfitsMeg = false
  const benfitsSet = new Set()
  const clientsArray = formData.Application.clients.map((client, index) => {
    const name =
      client.clientFstNm && client.clientLstNm
        ? client.clientFstNm + ' ' + client.clientLstNm + '-'
        : 'Person ' + (index + 1) + '-'
    return name
  })
  let ssns = []
  formData.Application.clients.forEach(client => {
    if (
      client.optBenefitFDNT ||
      client.optBenefitSCAL ||
      client.optBenefitMDPA ||
      client.optBenefitHMEN ||
      client.optBenefitCHCR ||
      client.optBenefitCHSP ||
      _.get(formData, 'Application.benefitCategorieslist', []).indexOf('HEWL') >
        -1 ||
      _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
        'WVWORKS'
      ) > -1
    ) {
      ssns.push(client.prtcpSsn)
    }
  })
  if (
    _.get(formData, 'Application.clientApplicant[0].roleOfApplicant') === 'Y'
  ) {
    ssns.push(_.get(formData, 'Application.clientsObligor[0].clientSSN'))
  } else if (
    _.get(formData, 'Application.clientApplicant[0].roleOfApplicant') === 'N'
  ) {
    ssns.push(_.get(formData, 'Application.clientsObligee[0].clientSSN'))
  }
  _.remove(ssns, function(ssn) {
    return ssn == undefined
  })
  //testing access
  //Pre-Questionnaire validation
  if (step === 1 || validateAll) {
    validationStep = 1
    for (let i = 0; i < formData.Application.clients.length; i++) {
      if (
        formData.Application.clients[i].optBenefitMDPA ||
        formData.Application.clients[i].optBenefitHEWL
      ) {
        if (i != 0 && formData.Application.clients[i].livesWith === undefined) {
          requireField(
            'Application_clients_' + i + '_livesWith',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['Wholiveswithyou']
          )
        }
      }
      if (formData.Application.clients[i].optBenefitHEWL) {
        requireField(
          'Application_clients_' + i + '_disabledHousehold',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['clients_disabledHousehold']
        )
      }
      //unpaidBillsForMonths
      if (
        formData.Application.clients[i].optBenefitHEWL &&
        formData.Application.clients[i].unpaidMedicalBills !== 'N'
      ) {
        requireField(
          'Application_clients_' + i + '_unpaidBillsForMonths',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['clients_unpaidBillsForMonths']
        )
      }
      //unpaidBillsForMonths
      if (
        formData.Application.clients[i].optBenefitHEWL &&
        formData.Application.clients[i].unpaidBillsForMonths === '1'
      ) {
        requireField(
          'Application_clients_' + i + '_livesTogetherFirstMonth',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['Werethepeopleliving']
        )
      }
      if (
        formData.Application.clients[i].optBenefitHEWL &&
        formData.Application.clients[i].unpaidBillsForMonths === '2'
      ) {
        requireField(
          'Application_clients_' + i + '_livesTogetherFirstMonth',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['Werethepeopleliving']
        )
        requireField(
          'Application_clients_' + i + '_livesTogetherSecondMonth',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['Werethepeoplesecondliving']
        )
      }
      if (
        formData.Application.clients[i].optBenefitHEWL &&
        formData.Application.clients[i].unpaidBillsForMonths === '3'
      ) {
        requireField(
          'Application_clients_' + i + '_livesTogetherFirstMonth',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['Werethepeopleliving']
        )
        requireField(
          'Application_clients_' + i + '_livesTogetherSecondMonth',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['Werethepeoplesecondliving']
        )
        requireField(
          'Application_clients_' + i + '_livesTogetherThirdMonth',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['Werethepeopleliving']
        )
      }
      if (
        formData.Application.clients[i].optBenefitCHSP ||
        formData.Application.clients[i].optBenefitCHCR
      ) {
        requireField(
          'Application_clients_' + i + '_genderCode',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['GenderRequired']
        )
        i != 0 &&
          formData.Application.clients[i].optBenefitCHSP &&
          requireField(
            'Application_clients_' + i + '_relationship',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['RelationshipRequired']
          )
      }
      if (
        _.isArray(formData.Application.benefitCategorieslist) &&
        formData.Application.benefitCategorieslist.indexOf('CHCR') > -1
      ) {
        if (formData.Application.clients[i].clientBthDt === undefined) {
          requireField(
            'Application_clients_' + i + '_clientBthDt',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['BirthDaterequired']
          )
        }
        if (
          (formData.Application.clients[i].optBenefitCHCR === undefined ||
            !formData.Application.clients[i].optBenefitCHCR) &&
          formData.Application.clients[i].genderCode === undefined
        ) {
          requireField(
            'Application_clients_' + i + '_genderCode',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['GenderRequired']
          )
        }
      }

      requireField(
        'Application_clients_' + i + '_clientFstNm',
        errors,
        formData,
        validationStep,
        clientsArray[i] + errorMessages['FirstNamerequired']
      )
      requireField(
        'Application_clients_' + i + '_clientLstNm',
        errors,
        formData,
        validationStep,
        clientsArray[i] + errorMessages['LastNamerequired']
      )

      // Code changes for GPRB0359133 and US2558381
      /*if (formData.Application.clients[i].clientBthDt !== undefined) {
        expectBthDtLessCurrDt(
          'Application_clients_' + i + '_clientBthDt',
          errors,
          formData,
          validationStep,
          clientsArray[i] + ' Please do not enter future date.'
        )
      } */
      if (
        formData.Application.clients[i].clientBthDt &&
        formData.Application.clients[i].clientBthDt !== undefined
      ) {
        if (
          validBirthDateCheck(
            formData.Application.clients[i].clientBthDt,
            currentDate
          )
        ) {
          expectBthDtgreaterthanNinteenHundredandLessCurrDt(
            'Application_clients_' + i + '_clientBthDt',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['BirthDatecannotbeprior']
          )
        }
      }

      ;(formData.Application.clients[i].optBenefitHEWL ||
        formData.Application.clients[i].optBenefitFDNT) &&
        expectCorrectDueDt(
          'Application_clients_' + i + '_dueDate',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['ExpectedDueDateinvalid']
        )
      i != 0 &&
        !isIncomplete &&
        formData.Application.clients[i].optBenefitFDNT &&
        requireField(
          'Application_clients_' + i + '_shareGroceriesWith',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['yourhouseholdbuy']
        )
    }

    requireZipCode(
      'Application_assister_assisterAddress_zip',
      errors,
      formData,
      validationStep,
      errorMessages['ZipCoderequired']
    )

    // Code Changes GPRB0359078 and US2554056
    // Full Application - Assister Address Zip Code and Zip ext validation
    let assisterAddress = _.get(
      formData,
      'Application.assister.assisterAddress',
      {}
    )
    if (
      assisterAddress &&
      assisterAddress.zip &&
      assisterAddress.zip !== undefined
    ) {
      if (validZipCodeCheck(assisterAddress.zip)) {
        notRequiredZip(
          'Application_assister_assisterAddress_zip',
          errors,
          formData,
          validationStep,
          errorMessages['ZipCodefivedigits']
        )
      }
    }

    if (
      assisterAddress &&
      assisterAddress.zipExt &&
      assisterAddress.zipExt !== undefined
    ) {
      if (validZipExtCheck(assisterAddress.zipExt)) {
        notRequiredZipExt(
          'Application_assister_assisterAddress_zipExt',
          errors,
          formData,
          validationStep,
          errorMessages['Extmustbefourdigits']
        )
      }
    }
    if (formData.Application.someoneAsstYouFlg == undefined) {
      requireField(
        'Application_someoneAsstYouFlg',
        errors,
        formData,
        validationStep,
        errorMessages['Whocompletingapplication']
      )
    }
    if (formData.Application.assister.assisterRoleCd == undefined) {
      requireField(
        'Application_assister_assisterRoleCd',
        errors,
        formData,
        validationStep,
        errorMessages['Whatrole']
      )
    }
    if (formData.Application.benefitCategorieslist == undefined) {
      requireField(
        'Application_benefitCategorieslist',
        errors,
        formData,
        validationStep,
        errorMessages['Whathouseholdprogram']
      )
    }

    if (formData.Application.benefitCategorieslist !== undefined) {
      if (formData.Application.benefitCategorieslist.length == 0) {
        requireField(
          'Application_benefitCategorieslist',
          errors,
          formData,
          validationStep,
          errorMessages['Whathouseholdprogram']
        )
      }
    }
    if (
      formData.Application.clients[0].optBenefitFDNT &&
      formData.Application.clients[0].farmerJobEnd === 'Y'
    ) {
      requireField(
        'Application_clients_' + 0 + '_twentyFiveDollarQue',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['getmorethan25']
      )
    }

    requireChildSupport(
      'Application_benefitCategorieslist',
      errors,
      formData,
      validationStep,
      errorMessages['newapplication15Children']
    )

    let benefitesFlagHewl = true,
      benefitesFlagSCAL = true,
      benefitesFlagHMEN = true,
      benefitesFlagFDNT = true,
      benefitesFlagMDPA = true,
      benefitsFlagWVWORKS = true

    if (
      _.isArray(formData.Application.benefitCategorieslist) &&
      formData.Application.benefitCategorieslist.indexOf('HEWL') > -1
    ) {
      for (let i = 0; i < formData.Application.clients.length; i++) {
        if (formData.Application.clients[i].optBenefitHEWL) {
          benefitesFlagHewl = false
        }
      }
    } else {
      benefitesFlagHewl = false
    }
    if (
      _.isArray(formData.Application.benefitCategorieslist) &&
      formData.Application.benefitCategorieslist.indexOf('SCAL') > -1
    ) {
      for (let i = 0; i < formData.Application.clients.length; i++) {
        if (formData.Application.clients[i].optBenefitSCAL) {
          benefitesFlagSCAL = false
        }
      }
    } else {
      benefitesFlagSCAL = false
    }
    if (
      _.isArray(formData.Application.benefitCategorieslist) &&
      formData.Application.benefitCategorieslist.indexOf('HMEN') > -1
    ) {
      for (let i = 0; i < formData.Application.clients.length; i++) {
        if (formData.Application.clients[i].optBenefitHMEN) {
          benefitesFlagHMEN = false
        }
      }
    } else {
      benefitesFlagHMEN = false
    }
    if (
      _.isArray(formData.Application.benefitCategorieslist) &&
      formData.Application.benefitCategorieslist.indexOf('FDNT') > -1
    ) {
      for (let i = 0; i < formData.Application.clients.length; i++) {
        if (formData.Application.clients[i].optBenefitFDNT) {
          benefitesFlagFDNT = false
        }
      }
    } else {
      benefitesFlagFDNT = false
    }
    if (
      _.isArray(formData.Application.benefitCategorieslist) &&
      formData.Application.benefitCategorieslist.indexOf('MDPA') > -1
    ) {
      for (let i = 0; i < formData.Application.clients.length; i++) {
        if (formData.Application.clients[i].optBenefitMDPA) {
          benefitesFlagMDPA = false
        }
      }
    } else {
      benefitesFlagMDPA = false
    }
    if (
      _.isArray(formData.Application.benefitCategorieslist) &&
      formData.Application.benefitCategorieslist.indexOf('WVWORKS') > -1
    ) {
      for (let i = 0; i < formData.Application.clients.length; i++) {
        if (formData.Application.clients[i].optBenefitWVWORKS) {
          benefitsFlagWVWORKS = false
        }
      }
    } else {
      benefitsFlagWVWORKS = false
    }

    if (
      benefitesFlagHewl ||
      benefitesFlagSCAL ||
      benefitesFlagHMEN ||
      benefitesFlagFDNT ||
      benefitesFlagMDPA ||
      benefitsFlagWVWORKS
    ) {
      benfitesError(
        'Application_clients_' +
          0 +
          '_optBenefit' +
          formData.Application.benefitCategorieslist[0],
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['householdmemberperprogram']
      )
    }

    /**
     * Adding below code as the part of US1968957
     */
    if (
      _.isArray(formData.Application.benefitCategorieslist) &&
      formData.Application.benefitCategorieslist.indexOf('CHSP') > -1
    ) {
      let optBenefitChspCheck = []
      let relationshipCheck = []
      let childrenFlag = false
      for (let i = 0; i < formData.Application.clients.length; i++) {
        if (i == 0 && !formData.Application.clients[i].optBenefitCHSP) {
          benfitesError(
            'Application_clients_' +
              0 +
              '_optBenefit' +
              formData.Application.benefitCategorieslist[0],
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['householdmemberperprogram']
          )
        } else if (i > 0) {
          if (true) {
            let cur = _moment(config, currentDate)
            let testFlag =
              cur.diff(formData.Application.clients[i].clientBthDt, 'years') <
              18
            // User story #US2625192 to removed age validation so removing testFlag check
            if (formData.Application.clients[i].optBenefitCHSP) {
              childrenFlag = true
            }
          }
        }
      }

      if (!childrenFlag) {
        benfitesError(
          'Application_clients_' +
            0 +
            '_optBenefit' +
            formData.Application.benefitCategorieslist[0],
          errors,
          formData,
          validationStep,
          errorMessages['ChildSupportServices']
        )
      }
    }
    let roleArray = new Array()
    formData.Application.clients.forEach(client => {
      roleArray.push(client.role)
    })
    roleArray = _.uniq(roleArray)
    let benefitCatgList = _.get(
      formData,
      'Application.benefitCategorieslist',
      []
    )
    if (
      _.includes(benefitCatgList, 'CHCR') &&
      !(
        _.includes(roleArray, 'parentorcaregiver') &&
        _.includes(roleArray, 'child')
      )
    ) {
      errorMessage(
        'Application_clients_' + 0 + '_role',
        errors,
        formData,
        validationStep,
        errorMessages['onechildcaregiver']
      )
    }
    for (let i = 0; i < formData.Application.clients.length; i++) {
      if (formData.Application.clients[i].optBenefitCHCR) {
        requireField(
          'Application_clients_' + i + '_role',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['caregiveraschildinneedofservices']
        )
      }
    }
  }

  //Personal validation
  if (step === 3 || validateAll) {
    validationStep = 3
    //marital status check in case of MPA & health care
    for (let i = 0; i < formData.Application.clients.length; i++) {
      if (
        (formData.Application.clients[i].optBenefitMDPA ||
          _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
            'WVWORKS'
          ) > -1) &&
        (_moment(config, currentDate).diff(
          formData.Application.clients[i].clientBthDt,
          'years'
        ) > 14 ||
          !formData.Application.clients[i].clientBthDt ||
          formData.Application.clients[i].clientBthDt == '')
      ) {
        requireField(
          'Application_clients_' + i + '_mrtlStatusCd',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['applicantmaritalstatus']
        )
      }
    }

    for (let g = 0; g < formData.Application.clients.length; g++) {
      if (
        formData.Application.clients[g].optBenefitMDPA ||
        formData.Application.clients[g].optBenefitHEWL
      ) {
        if (formData.Application.clients[g].genderCode === undefined) {
          requireField(
            'Application_clients_' + g + '_genderCode',
            errors,
            formData,
            validationStep,
            clientsArray[g] + errorMessages['GenderRequired']
          )
        }
        if (formData.Application.clients[g].clientBthDt === undefined) {
          requireField(
            'Application_clients_' + g + '_clientBthDt',
            errors,
            formData,
            validationStep,
            clientsArray[g] + errorMessages['BirthDaterequired']
          )
        }
      }

      // US2668722
      if (determineNonApplicantForChildCare(g, formData)) {
        requireField(
          'Application_clients_' + g + '_clientBthDt',
          errors,
          formData,
          validationStep,
          clientsArray[g] + errorMessages['BirthDaterequired']
        )
      }

      if (
        _.isArray(formData.Application.benefitCategorieslist) &&
        formData.Application.benefitCategorieslist.indexOf('CHCR') > -1
      ) {
        if (formData.Application.clients[g].optBenefitCHCR) {
          if (formData.Application.clients[g].genderCode === undefined) {
            requireField(
              'Application_clients_' + g + '_genderCode',
              errors,
              formData,
              validationStep,
              clientsArray[g] + errorMessages['GenderRequired']
            )
          }
        }
        if (
          (formData.Application.clients[g].optBenefitCHCR === undefined ||
            !formData.Application.clients[g].optBenefitCHCR) &&
          formData.Application.clients[g].genderCode === undefined
        ) {
          requireField(
            'Application_clients_' + g + '_genderCode',
            errors,
            formData,
            validationStep,
            clientsArray[g] + errorMessages['GenderRequired']
          )
        }
      }

      // US2915041
      if (evalChildCareEligibilityOnDOBChange(g, formData)) {
        expectBthDtgreaterthanNinteenHundredandLessCurrDt(
          'Application_clients_' + g + '_clientBthDt',
          errors,
          formData,
          validationStep,
          clientsArray[g] + errorMessages['ChildCarecannotbeolder18']
        )
      }
    }

    for (let i = 0; i < formData.Application.clients.length; i++) {
      if (formData.Application.clients[i].alternativeName === 'Y') {
        let alernateInformationLength = _.get(
          formData,
          `Application.clients[${i}].altNameDetails`,
          []
        ).length
        for (let aI = 0; aI < alernateInformationLength; aI++) {
          requireField(
            'Application_clients_' +
              i +
              '_altNameDetails_' +
              aI +
              '_alternativeFstNm',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['AlternativeFirstName']
          )

          requireField(
            'Application_clients_' +
              i +
              '_altNameDetails_' +
              aI +
              '_alternativeLstNm',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['AlternativeLastName']
          )
        }
      }
      if (i === 0) {
        const benefitCatList = _.get(
          formData,
          'Application.benefitCategorieslist',
          []
        )
        //   if (
        //     !(
        //       _.size(benefitCatList) === 1 &&
        //       (_.includes(benefitCatList, 'CHCR') ||
        //         _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
        //           'WVWORKS'
        //         ) > -1) &&
        //       _.size(benefitCatList) > 0
        //     )
        //   ) {
        //     requireField(
        //       'Application_clients_' + 0 + '_wvCounty',
        //       errors,
        //       formData,
        //       validationStep,
        //       clientsArray[0] + ' West Virginia County Service is required'
        //     )
        //   }
      }
      if (
        (formData.Application.clients[i].optBenefitFDNT ||
          formData.Application.clients[i].optBenefitSCAL ||
          formData.Application.clients[i].optBenefitMDPA ||
          formData.Application.clients[i].optBenefitHEWL ||
          formData.Application.clients[i].optBenefitHMEN ||
          _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
            'WVWORKS'
          ) > -1 ||
          formData.Application.clients[i].optBenefitCHCR) &&
        !isIncomplete
      ) {
        requireField(
          'Application_clients_' + i + '_addressSpecificationCode',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['applicantlive']
        )
      }
      if (
        formData.Application.clients[i].addressSpecificationCode == '6' &&
        (formData.Application.clients[i].optBenefitMDPA ||
          formData.Application.clients[i].optBenefitHEWL)
      ) {
        requireField(
          'Application_clients_' + i + '_nursingFacilityName',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['NursingFacility']
        )
      }
      requireField(
        'Application_clients_' + i + '_clientLstNm',
        errors,
        formData,
        validationStep,
        clientsArray[i] + errorMessages['LastNamerequired']
      )
      requireField(
        'Application_clients_' + i + '_clientFstNm',
        errors,
        formData,
        validationStep,
        clientsArray[i] + errorMessages['FirstNamerequired']
      )
      if (
        _.includes(_.get(formData, `Application.clients[${i}].raceCd`, []), '1')
      ) {
        requireField(
          'Application_clients_' + i + '_dsctOfAmInAlNat',
          errors,
          formData,
          validationStep,
          clientsArray[i] +
            'Please Select "Descendent of American Indian/Alaskan Native"'
        )
        requireField(
          'Application_clients_' + i + '_nameOfTribe',
          errors,
          formData,
          validationStep,
          clientsArray[i] + 'Please Select "Name of the Tribe"'
        )
        requireField(
          'Application_clients_' + i + '_enrlInFdrOrVllgProg',
          errors,
          formData,
          validationStep,
          clientsArray[i] +
            'Please Select "Member of Federally Recognized Tribe"'
        )
        requireField(
          'Application_clients_' + i + '_mmbrOfFedRecogTribe',
          errors,
          formData,
          validationStep,
          clientsArray[i] +
            'Please Select "Enrolled in Federal or Village Program"'
        )
        requireField(
          'Application_clients_' + i + '_receivedServiceThroughIHS',
          errors,
          formData,
          validationStep,
          clientsArray[i] +
            'Please Select "Received a service from the Indian Health Service"'
        )
        requireField(
          'Application_clients_' + i + '_eligibleForServiceThroughIHS',
          errors,
          formData,
          validationStep,
          clientsArray[i] +
            'Please Select "Eligible to get services from the Indian Health Service"'
        )
      }
      if (formData.Application.clients[i].nameOfTribe === '40') {
        requireField(
          'Application_clients_' + i + '_otherTribeName',
          errors,
          formData,
          validationStep,
          clientsArray[i] + 'Please Select "Other Tribe Name"'
        )
        requireField(
          'Application_clients_' + i + '_stateOfTheTribe',
          errors,
          formData,
          validationStep,
          clientsArray[i] + 'Please Select "State of the Tribe"'
        )
      }
      if (i === 0) {
        for (
          let j = 0;
          j < formData.Application.clients[0].address.length;
          j++
        ) {
          const benefitCatList = _.get(
            formData,
            'Application.benefitCategorieslist',
            []
          )
          const state = _.get(
            formData,
            `Application.clients[${0}].address[${j}]st`
          )
          requireField(
            'Application_clients_' + 0 + '_address_' + j + '_adrLn3Txt',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['StreetName']
          )
          requireField(
            'Application_clients_' + 0 + '_address_' + j + '_ctyNm',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['Cityrequired']
          )
          requireField(
            'Application_clients_0_address_' + j + '_county',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['Countyrequired']
          )
          if (_.includes(benefitCatList, 'CHCR') && !_.isEqual(state, 'WV')) {
            requireChildCareCounty(
              'Application_clients_' + 0 + '_address_' + j + '_st',
              errors,
              formData,
              validationStep,
              clientsArray[0] + errorMessages['ChildCareapplicantsmustreside']
            )
          } else {
            requireField(
              'Application_clients_' + 0 + '_address_' + j + '_st',
              errors,
              formData,
              validationStep,
              clientsArray[0] + errorMessages['Stateisrequired']
            )
          }
          requireField(
            'Application_clients_' + 0 + '_address_' + j + '_zip',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['ZipCoderequired']
          )
          // Code Changes GPRB0359078 and US2554056
          // Full Application - Physical Address Zip Code and Zip Ext validation
          let clientAddress = _.get(
            formData,
            'Application.clients[0].address',
            {}
          )
          if (
            clientAddress &&
            clientAddress[0].zip &&
            clientAddress[0].zip !== undefined
          ) {
            if (validZipCodeCheck(clientAddress[0].zip)) {
              notRequiredZip(
                'Application_clients_' + 0 + '_address_' + j + '_zip',
                errors,
                formData,
                validationStep,
                clientsArray[0] + errorMessages['ZipCodefivemustdigits']
              )
            }
          }
          if (
            clientAddress &&
            clientAddress[0].zipExt &&
            clientAddress[0].zipExt !== undefined
          ) {
            if (validZipExtCheck(clientAddress[0].zipExt)) {
              notRequiredZipExt(
                'Application_clients_' + 0 + '_address_' + j + '_zipExt',
                errors,
                formData,
                validationStep,
                clientsArray[0] + errorMessages['Extmustbefourdigits']
              )
            }
          }
        }
      }

      if (
        formData.Application &&
        _.isArray(formData.Application.clients) &&
        formData.Application.clients[i].mailingAddressCheck &&
        formData.Application.clients[i].mailingAddressCheck === 'N'
      ) {
        requireField(
          'Application_clients_' + i + '_mailingAddress_adrLn3Txt',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['MailingAddressStreetName']
        )
        requireField(
          'Application_clients_' + i + '_mailingAddress_ctyNm',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['MailingAddressCity']
        )
        requireField(
          'Application_clients_' + i + '_mailingAddress_st',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['MailingAddressState']
        )
        requireField(
          'Application_clients_' + i + '_mailingAddress_zip',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['MailingAddressZipCode']
        )
        // Code Changes GPRB0359078 and US2554056
        // Full Application - Mailing Address Zip Code and Zip Ext validation
        let mailingAddress = _.get(
          formData,
          'Application.clients[0].mailingAddress',
          {}
        )
        if (
          mailingAddress &&
          mailingAddress.zip &&
          mailingAddress.zip !== undefined
        ) {
          if (validZipCodeCheck(mailingAddress.zip)) {
            notRequiredZip(
              'Application_clients_' + i + '_mailingAddress_zip',
              errors,
              formData,
              validationStep,
              clientsArray[i] + errorMessages['MailingAddressZipCodeFive']
            )
          }
        }
        if (
          mailingAddress &&
          mailingAddress.zipExt &&
          mailingAddress.zipExt !== undefined
        ) {
          if (validZipExtCheck(mailingAddress.zipExt)) {
            notRequiredZipExt(
              'Application_clients_' + i + '_mailingAddress_zipExt',
              errors,
              formData,
              validationStep,
              clientsArray[i] + errorMessages['MailingAddressExt']
            )
          }
        }
      }

      // Code changes for GPRB0359133 and US2558381
      /* if (formData.Application.clients[i].clientBthDt !== undefined) {
        expectBthDtLessCurrDt(
          'Application_clients_' + i + '_clientBthDt',
          errors,
          formData,
          validationStep,
          clientsArray[i] + ' Please do not enter future date.'
        )
      } */
      if (
        formData.Application.clients[i].clientBthDt &&
        formData.Application.clients[i].clientBthDt !== undefined
      ) {
        if (
          validBirthDateCheck(
            formData.Application.clients[i].clientBthDt,
            currentDate
          )
        ) {
          expectBthDtgreaterthanNinteenHundredandLessCurrDt(
            'Application_clients_' + i + '_clientBthDt',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['MailingAddressBirthDate']
          )
        }
      }

      if (
        formData.isPeApplication &&
        formData.Application.clients[i].presumptiveEligibilityCoverage === 'Y'
      ) {
        requireField(
          'Application_clients_' + i + '_temporaryMaidNumber',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['MAIDnumber']
        )
        checkMAIDNUmber(
          'Application_clients_' + i + '_temporaryMaidNumber',
          errors,
          formData,
          validationStep,
          clientsArray[i] + ' Invalid MAID number',
          formContext
        )
      }

      if (
        formData.Application.clients &&
        formData.Application.clients[i].presumptiveEligibilityCoverage &&
        formData.Application.clients[i].presumptiveEligibilityCoverage === 'Y'
      ) {
        checkForMAIDNumberLeadingZeros(
          'Application_clients_' + i + '_temporaryMaidNumber',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['temporaryMAIDnumbermustbe11'],
          formContext
        )
      }
      ;(formData.Application.clients[i].optBenefitFDNT ||
        formData.Application.clients[i].optBenefitHEWL ||
        formData.Application.clients[i].optBenefitMDPA ||
        formData.Application.clients[i].optBenefitSCAL ||
        _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
          'WVWORKS'
        ) > -1 ||
        formData.Application.clients[i].optBenefitHMEN) &&
        requireField(
          'Application_clients_' + i + '_clientRemInStIntnFlg',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['DoYouIntendResideWestVirginia']
        )
      if (
        formData.Application.clients[i].optBenefitCHCR ||
        formData.Application.clients[i].optBenefitFDNT ||
        formData.Application.clients[i].optBenefitHEWL ||
        formData.Application.clients[i].optBenefitMDPA ||
        formData.Application.clients[i].optBenefitSCAL ||
        _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
          'WVWORKS'
        ) > -1
      ) {
        if (formData.Application.clients[i].ethnicity == '') {
          requireField(
            'Application_clients_' + i + '_ethnicity',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['EthnicityRequired']
          )
        }

        if (
          formData.Application.clients[i].raceCd == undefined ||
          formData.Application.clients[i].raceCd.length == 0
        ) {
          requireField(
            'Application_clients_' + i + '_raceCd',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['RaceRequired']
          )
        }
      }
    }
    requireField(
      'Application_clients_' + 0 + '_mailingAddressCheck',
      errors,
      formData,
      validationStep,
      clientsArray[0] + errorMessages['MailingAddressRequired']
    )
  }

  //Citizenship And Residency
  if (step === 4 || validateAll) {
    validationStep = 4
    //ALM Defect 2096 fix
    for (let l = 0; l < formData.Application.clients.length; l++) {
      // Code Changes GPRB0359223 and US2819645
      // Citizenship And Residency SSN Validation
      if (
        formData.Application.clients[l].prtcpSsn &&
        formData.Application.clients[l].prtcpSsn !== undefined
      ) {
        if (!validSSN(formData.Application.clients[l].prtcpSsn))
          ssnValidation(
            'Application_clients_' + l + '_prtcpSsn',
            errors,
            formData,
            validationStep,
            clientsArray[l] + errorMessages['InvalidSocialSecurityNumber']
          )
      }
      if (!isIncomplete) {
        let dob = formData.Application.clients[l].clientBthDt
        let birthdate = _moment(config, dob)
        let cur = _moment(config, currentDate)
        if (
          formData.Application.clients[l].optBenefitHEWL ||
          formData.Application.clients[l].optBenefitMDPA ||
          _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
            'WVWORKS'
          ) > -1 ||
          (formData.Application.clients[l].optBenefitCHCR &&
            cur.diff(birthdate, 'years') < 18)
        ) {
          requireField(
            'Application_clients_' + l + '_usCtznOrNatInd',
            errors,
            formData,
            validationStep,
            clientsArray[l] + errorMessages['IsApplicantUScitizen']
          )
        }

        if (l == 0 && formData.Application.clients[l].optBenefitCHSP) {
          compareAppSSN(
            'Application_clients_' + l + '_prtcpSsn',
            'Application_clients_' + l + '_prtcpSsnConfir',
            errors,
            formData,
            validationStep,
            clientsArray[l] + errorMessages['SSNNotmatch']
          )
          requireField(
            'Application_clients_' + l + '_prtcpSsnConfir',
            errors,
            formData,
            validationStep,
            clientsArray[l] + errorMessages['confirmSSNNUMBER']
          )
        } else if (formData.Application.clients[l].optBenefitCHSP) {
          compareCOCSSN(
            'Application_clients_' + l + '_prtcpSsn',
            errors,
            formData,
            step,
            clientsArray[l] + errorMessages['SSNinvalid']
          )
          compareAppSSN(
            'Application_clients_' + l + '_prtcpSsn',
            'Application_clients_' + l + '_prtcpSsnConfir',
            errors,
            formData,
            validationStep,
            clientsArray[l] + errorMessages['SSNNotmatch']
          )
        }
        // Code Changes GPRB0359223 and US2819645
        // Citizenship And Residency SSN Validation
        if (
          formData.Application.clients[l].prtcpSsnConfir &&
          formData.Application.clients[l].prtcpSsnConfir !== undefined
        ) {
          if (!validSSN(formData.Application.clients[l].prtcpSsnConfir))
            ssnValidation(
              'Application_clients_' + l + '_prtcpSsnConfir',
              errors,
              formData,
              validationStep,
              clientsArray[l] + errorMessages['InvalidSocialSecurityNumber']
            )
        }
      }
      if (
        submitClicked &&
        formData.Application.clients[l].optBenefitCHSP &&
        (_.get(formData, `Application.clients[${l}].prtcpSsn`) != undefined ||
          _.get(formData, `Application.clients[${l}].prtcpSsn`) != ' ')
      ) {
        validateUniqueSSN(
          'Application_clients_' + l + '_prtcpSsn',
          errors,
          validationStep,
          clientsArray[l] +
            errorMessages['SocialSecurityNumberprovidedisthesame'],
          errorMessages['numberunique'],
          _.get(formData, `Application.clients[${l}].prtcpSsn`),
          ssns
        )
      }

      if (
        _.get(formData, `Application.clients[${l}].usCtznOrNatInd`) == 'N' &&
        _.get(formData, `Application.clients[${l}].arriveUsBeforeAug221996`) ==
          'N' &&
        _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
          'WVWORKS'
        ) > -1
      ) {
        requireField(
          'Application_clients_' + l + '_whenDidComeToUs',
          errors,
          formData,
          validationStep,
          clientsArray[l] + errorMessages['WhenDidApplicantcome']
        )
        requireField(
          'Application_clients_' + l + '_whichCountryOfApplicant',
          errors,
          formData,
          validationStep,
          clientsArray[l] + errorMessages['whichcountrydidapplicantcome']
        )
      }
    }
  }
  //health
  if ((step === 5 || validateAll) && !isIncomplete) {
    validationStep = 5
    for (let i = 0; i < formData.Application.clients.length; i++) {
      if (
        formData.Application.clients[i].optBenefitMDPA ||
        formData.Application.clients[i].optBenefitHEWL
      ) {
        if (
          formData.Application.clients[i].hlthInsEligFromAnySrc === undefined
        ) {
          requireField(
            'Application_clients_' + i + '_hlthInsEligFromAnySrc',
            errors,
            formData,
            validationStep,
            clientsArray[i] +
              errorMessages['applicanteligibleforHealthInsurance']
          )
        }
        if (formData.Application.clients[i].hlthInsEligFromAnySrc === 'Y') {
          requireField(
            'Application_clients_' + i + '_clientEnrlHlthInsPln',
            errors,
            formData,
            step,
            clientsArray[i] + errorMessages['applicantenrolled']
          )
        }
      }
    }
    for (let i = 0; i < formData.Application.clients.length; i++) {
      if (
        formData.Application.clients[i].optBenefitHEWL ||
        formData.Application.benefitCategorieslist.includes('HEWL')
      ) {
        requireField(
          'Application_clients_' + i + '_federalTax',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['applicantfileTax']
        )
        requireField(
          'Application_clients_' + i + '_taxFlStatusCd',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['applicantfiletaxesjointly']
        )
        requireField(
          'Application_clients_' + i + '_clmDepnFlg',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['applicantdependentstaxes']
        )
        if (
          formData.Application.clients[i].clmDepnFlg === 'Y' &&
          formData.Application.clients[i].dependents
        ) {
          requireField(
            'Application_clients_' + i + '_clmDepnFlg',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['applicantdependentstaxes']
          )
        }
        requireField(
          'Application_clients_' + i + '_claimedAsADependent',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['applicantclaimedsomeonetaxreturn']
        )

        if (
          formData.Application.clients[i].clmDepnFlg === 'Y' &&
          formData.Application.clients[i].dependents === undefined
        ) {
          requireField(
            'Application_clients_' + i + '_dependents',
            errors,
            formData,
            step,
            clientsArray[i] + errorMessages['WhoapplicantclaimingAsdependent']
          )
        }
        if (
          formData.Application.clients[i].claimedAsADependent === 'Y' &&
          formData.Application.clients[i].willClaimAsDependent === undefined
        ) {
          requireField(
            'Application_clients_' + i + '_willClaimAsDependent',
            errors,
            formData,
            validationStep,
            clientsArray[i] +
              errorMessages['WhoapplicantWillclaimingAsdependent']
          )
        }
      }
    }
  }
  //Family & Household
  if ((step === 6 || validateAll) && !isIncomplete) {
    validationStep = 6
    if (
      formData.Application.clients[0].optBenefitHMEN ||
      formData.Application.clients[0].optBenefitFDNT
    ) {
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_statusOfHomeEnergyService_wavier',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['Waiverrequired']
      )
      for (let i = 0; i < formData.Application.clients.length; ++i) {
        ;(formData.Application.clients[i].optBenefitHMEN ||
          formData.Application.clients[i].optBenefitFDNT) &&
          formData.Application.clients[i].shelterOrUtilityCostQuestions
            .housingOrRentAssistance === 'Y' &&
          requireField(
            'Application_clients_' +
              i +
              '_shelterOrUtilityCostQuestions_chargedWithUtilityExpense',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['UtilityCharged']
          )
        ;(formData.Application.clients[i].optBenefitHMEN ||
          formData.Application.clients[i].optBenefitFDNT) &&
          formData.Application.clients[i].shelterOrUtilityCostQuestions
            .shareWithOther === 'Y' &&
          requireField(
            'Application_clients_' +
              i +
              '_shelterOrUtilityCostQuestions_utilityContributorDetails_utilityCostContributorFistName',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['CostContributorFirstNamerequired']
          )
        ;(formData.Application.clients[i].optBenefitHMEN ||
          formData.Application.clients[i].optBenefitFDNT) &&
          formData.Application.clients[i].shelterOrUtilityCostQuestions
            .shareWithOther === 'Y' &&
          requireField(
            'Application_clients_' +
              i +
              '_shelterOrUtilityCostQuestions_utilityContributorDetails_utilityCostContributorLastName',
            errors,
            formData,
            validationStep,
            clientsArray[i] +
              errorMessages['HomeUtilityCostContributorrequired']
          )
      }
    }
    let optBenefitCheck = formData.Application.clients[0]
    if (optBenefitCheck.optBenefitFDNT || optBenefitCheck.optBenefitHMEN) {
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_payForOwnHeat',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['PayHeatrequired']
      )
      if (
        formData.Application.clients[0].shelterOrUtilityCostQuestions
          .payForOwnHeat === 'N'
      ) {
        requireField(
          'Application_clients_' +
            0 +
            '_shelterOrUtilityCostQuestions_monthlyContributorDetails_monthlyCostContributorFistName',
          errors,
          formData,
          validationStep,
          clientsArray[0] + errorMessages['WhopaysforyourheatContributor']
        )
        requireField(
          'Application_clients_' +
            0 +
            '_shelterOrUtilityCostQuestions_monthlyContributorDetails_monthlyCostContributorLastName',
          errors,
          formData,
          validationStep,
          clientsArray[0] +
            errorMessages['WhopaysforyourheatContributorLastName']
        )
      }
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_homeHeatingCosts',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['Homeheatingcostrequired']
      )
      // Code Changes GPRB0359078 and US2554056
      // Full Application - Household Expenses Payment to Landlord Address Zip Code validation
      let paymentToLandlordAddress = _.get(
        formData,
        'Application.clients[0].shelterOrUtilityCostQuestions.paymentToLandlord',
        {}
      )
      if (
        paymentToLandlordAddress &&
        paymentToLandlordAddress.zip &&
        paymentToLandlordAddress.zip !== undefined
      ) {
        if (validZipCodeCheck(paymentToLandlordAddress.zip)) {
          notRequiredZip(
            'Application_clients_' +
              0 +
              '_shelterOrUtilityCostQuestions_paymentToLandlord_zip',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['ZipCodefivedigits']
          )
        }
      }
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_airConditioningCosts',
        errors,
        formData,
        validationStep,
        clientsArray[0] + ' Air conditioning cost  is required.'
      )
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_homeCoolingCosts',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['Homecoolingcostrequired']
      )
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_billedSeparatelyForHeatingOrCooling',
        errors,
        formData,
        validationStep,
        clientsArray[0] + ' BilledSeparatelyForHeatingOrCooling  is required.'
      )
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_housingOrRentAssistance',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['housingOrRentAssistance']
      )
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_lowIncomeEnergyPayments',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['lowIncomeEnergyPayments']
      )
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_shareWithOther',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['shareWithOther']
      )
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_statusOfHomeEnergyService_anyOfTheseSituationsApply',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['anyOfTheseSituationsApply']
      )
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_statusOfHomeEnergyService_lowOnFuel',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['lowOnFuel']
      )
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_statusOfHomeEnergyService_outOfFuel',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['outOfFuel']
      )
      requireField(
        'Application_clients_' +
          0 +
          '_shelterOrUtilityCostQuestions_statusOfHomeEnergyService_nonWorkingFurnaceOrBoiler',
        errors,
        formData,
        validationStep,
        clientsArray[0] + errorMessages['nonWorkingFurnaceOrBoiler']
      )
      if (optBenefitCheck.optBenefitHMEN || optBenefitCheck.optBenefitFDNT) {
        requireField(
          'Application_clients_' +
            0 +
            '_shelterOrUtilityCostQuestions_primaryHeatingSource',
          errors,
          formData,
          validationStep,
          clientsArray[0] + errorMessages['primaryHeatingSource']
        )
        if (
          formData.Application.clients[0].shelterOrUtilityCostQuestions
            .primaryHeatingSource != undefined &&
          (formData.Application.clients[0].shelterOrUtilityCostQuestions
            .primaryHeatingSource == '2' ||
            formData.Application.clients[0].shelterOrUtilityCostQuestions
              .primaryHeatingSource == '5')
        ) {
          requireField(
            'Application_clients_' +
              0 +
              '_shelterOrUtilityCostQuestions_mainHeatingFuelCompanyName',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['mainHeatingFuelCompanyName']
          )
        }
        if (
          optBenefitCheck.optBenefitHMEN &&
          formData.Application.clients[0].shelterOrUtilityCostQuestions
            .primaryHeatingSource != undefined &&
          formData.Application.clients[0].shelterOrUtilityCostQuestions
            .primaryHeatingSource != '2'
        ) {
          requireField(
            'Application_clients_' +
              0 +
              '_shelterOrUtilityCostQuestions_electricAccount',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['electricAccount']
          )
        }
        if (
          optBenefitCheck.optBenefitHMEN &&
          formData.Application.clients[0].shelterOrUtilityCostQuestions
            .primaryHeatingSource != undefined &&
          formData.Application.clients[0].shelterOrUtilityCostQuestions
            .primaryHeatingSource != '2' &&
          formData.Application.clients[0].shelterOrUtilityCostQuestions
            .electricAccount === 'Y'
        ) {
          requireField(
            'Application_clients_' +
              0 +
              '_shelterOrUtilityCostQuestions_electicAccountVendor',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['electicAccountVendor']
          )
        }
        if (
          optBenefitCheck.optBenefitHMEN &&
          formData.Application.clients[0].shelterOrUtilityCostQuestions
            .primaryHeatingSource &&
          formData.Application.clients[0].shelterOrUtilityCostQuestions
            .primaryHeatingSource != '2' &&
          formData.Application.clients[0].shelterOrUtilityCostQuestions
            .primaryHeatingSource != '5'
        ) {
          requireField(
            'Application_clients_' +
              0 +
              '_shelterOrUtilityCostQuestions_mainHeatingFuelCompanyNameForOthers',
            errors,
            formData,
            validationStep,
            clientsArray[0] +
              errorMessages['mainHeatingFuelCompanyNameForOthers']
          )
          requireField(
            'Application_clients_' +
              0 +
              '_shelterOrUtilityCostQuestions_payingForThis',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['payingForThis']
          )
          requireField(
            'Application_clients_' +
              0 +
              '_shelterOrUtilityCostQuestions_accountNumber',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['accountNumber']
          )
        }
      }
      if (formData.Application.clients[0].shelterOrUtilityCosts === 'Y') {
        requireField(
          'Application_clients_' + 0 + '_applicantsPaying',
          errors,
          formData,
          validationStep,
          clientsArray[0] + errorMessages['applicantsPaying']
        )
      }
    }
    if (
      (optBenefitCheck.optBenefitFDNT || optBenefitCheck.optBenefitHMEN) &&
      optBenefitCheck.shelterOrUtilityCosts === 'Y'
    ) {
      for (
        var i = 0;
        i < formData.Application.clients[0].shelterPaymentDetail.length;
        i++
      ) {
        const existingShelterPayment =
          formData.Application.clients[0].shelterExpenses &&
          formData.Application.clients[0].shelterExpenses.find(
            shelterExpense => {
              return (
                shelterExpense ===
                formData.Application.clients[0].shelterPaymentDetail[i]
                  .shelterExpenseKey
              )
            }
          )
        existingShelterPayment &&
          requireField(
            'Application_clients_' +
              0 +
              '_shelterPaymentDetail_' +
              i +
              '_shelterExpense_' +
              0 +
              '_payingTo',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['payingTo']
          )
        existingShelterPayment &&
          requireField(
            'Application_clients_' +
              0 +
              '_shelterPaymentDetail_' +
              i +
              '_shelterExpense_' +
              0 +
              '_shelterAmountPaid',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['shelterAmountPaid']
          )
        // Code Changes GPRB0359078 and US2554056
        // Full Application - Shelter Expenses Payment Info address Zip Code validation
        if (
          formData.Application.clients[0].shelterPaymentDetail &&
          formData.Application.clients[0].shelterPaymentDetail[i]
            .shelterExpense &&
          formData.Application.clients[0].shelterPaymentDetail[i]
            .shelterExpense[0].paymentInfo &&
          formData.Application.clients[0].shelterPaymentDetail[i]
            .shelterExpense[0].paymentInfo.zipCode &&
          formData.Application.clients[0].shelterPaymentDetail[i]
            .shelterExpense[0].paymentInfo.zipCode !== undefined
        ) {
          if (
            validZipCodeCheck(
              formData.Application.clients[0].shelterPaymentDetail[i]
                .shelterExpense[0].paymentInfo.zipCode
            )
          ) {
            notRequiredZip(
              'Application_clients_' +
                0 +
                '_shelterPaymentDetail_' +
                i +
                '_shelterExpense_' +
                0 +
                '_paymentInfo_zipCode',
              errors,
              formData,
              validationStep,
              clientsArray[0] + errorMessages['ZipCodefivedigits']
            )
          }
        }
      }
    }
    const benefitCategorieslist = formData.Application.benefitCategorieslist
    if (formData.Application.clients[0].optBenefitFDNT) {
      for (
        let j = 0;
        j < formData.Application.clients[0].otherFamilyExpenseDetail.length;
        j++
      ) {
        const existingOtherExpensePayment =
          formData.Application.clients[0].otherFamilyExpense &&
          formData.Application.clients[0].otherFamilyExpense.find(
            otherFamilyExpense => {
              return (
                otherFamilyExpense ===
                formData.Application.clients[0].otherFamilyExpenseDetail[j]
                  .otherFamilyExpenseKey
              )
            }
          )
        if (existingOtherExpensePayment) {
          for (
            let k = 0;
            k <
            formData.Application.clients[0].otherFamilyExpenseDetail[j]
              .otherFamilyExpense.length;
            k++
          ) {
            const expenseQuestions =
              formData.Application.clients[0].otherFamilyExpenseDetail[j]
                .otherFamilyExpense[k]
            if (
              expenseQuestions.dependentCareArray &&
              expenseQuestions.dependentCareArray.length > 0
            ) {
              for (
                let d = 0;
                d < expenseQuestions.dependentCareArray.length;
                d++
              ) {
                requireField(
                  'Application_clients_' +
                    0 +
                    '_otherFamilyExpenseDetail_' +
                    j +
                    '_otherFamilyExpense_' +
                    k +
                    '_dependentCareArray_' +
                    d +
                    '_whoElseOwnThisAssets',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[0] + errorMessages['whoElseOwnThisAssets']
                )
                requireField(
                  'Application_clients_' +
                    0 +
                    '_otherFamilyExpenseDetail_' +
                    j +
                    '_otherFamilyExpense_' +
                    k +
                    '_dependentCareArray_' +
                    d +
                    '_shelterAmountPaid',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[0] +
                    errorMessages['dependentCareArrayshelterAmountPaid']
                )
                requireField(
                  'Application_clients_' +
                    0 +
                    '_otherFamilyExpenseDetail_' +
                    j +
                    '_otherFamilyExpense_' +
                    k +
                    '_dependentCareArray_' +
                    d +
                    '_howOftenPaid',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[0] + errorMessages['howOftenPaid']
                )
              }
            }
            if (expenseQuestions.paymentInfo) {
              requireField(
                'Application_clients_' +
                  0 +
                  '_otherFamilyExpenseDetail_' +
                  j +
                  '_otherFamilyExpense_' +
                  k +
                  '_paymentInfo_payingTo',
                errors,
                formData,
                validationStep,
                clientsArray[0] + errorMessages['paymentInfo_payingTo']
              )
            }
            // Code Changes GPRB0359078 and US2554056
            // Full Application - Other Family Expenses Payment Info address Zip Code validation
            if (
              expenseQuestions &&
              expenseQuestions.paymentInfo &&
              expenseQuestions.paymentInfo.zipCode &&
              expenseQuestions.paymentInfo.zipCode !== undefined
            ) {
              if (validZipCodeCheck(expenseQuestions.paymentInfo.zipCode)) {
                notRequiredZip(
                  'Application_clients_' +
                    0 +
                    '_otherFamilyExpenseDetail_' +
                    j +
                    '_otherFamilyExpense_' +
                    k +
                    '_paymentInfo_zipCode',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[0] + errorMessages['ZipCodefivedigits']
                )
              }
            }
          }
        }
      }
      for (let l = 0; l < formData.Application.clients.length; l++) {
        for (
          let j = 0;
          j < formData.Application.clients[l].otherFamilyExpenseDetail.length;
          j++
        ) {
          const existingOtherExpensePayment =
            formData.Application.clients[l].otherFamilyExpense &&
            formData.Application.clients[l].otherFamilyExpense.find(
              otherFamilyExpense => {
                return (
                  otherFamilyExpense ===
                  formData.Application.clients[l].otherFamilyExpenseDetail[j]
                    .otherFamilyExpenseKey
                )
              }
            )
          if (existingOtherExpensePayment) {
            for (
              let k = 0;
              k <
              formData.Application.clients[l].otherFamilyExpenseDetail[j]
                .otherFamilyExpense.length;
              k++
            ) {
              const expenseQuestions =
                formData.Application.clients[l].otherFamilyExpenseDetail[j]
                  .otherFamilyExpense[k]
              if (expenseQuestions.childSupportLegallyObligated === 'Y') {
                requireField(
                  'Application_clients_' +
                    l +
                    '_otherFamilyExpenseDetail_' +
                    j +
                    '_otherFamilyExpense_' +
                    k +
                    '_actualMonthlyAmount',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[l] + errorMessages['actualMonthlyAmount']
                )
              }
              existingOtherExpensePayment === '1' &&
                requireField(
                  'Application_clients_' +
                    l +
                    '_otherFamilyExpenseDetail_' +
                    j +
                    '_otherFamilyExpense_' +
                    k +
                    '_nameOfTheChild',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[l] + errorMessages['nameOfTheChild']
                )
              existingOtherExpensePayment === '1' &&
                requireField(
                  'Application_clients_' +
                    l +
                    '_otherFamilyExpenseDetail_' +
                    j +
                    '_otherFamilyExpense_' +
                    k +
                    '_childSupportLegallyObligated',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[l] +
                    errorMessages['childSupportLegallyObligated']
                )
            }
          }
        }
      }
    }
    for (let z = 0; z < formData.Application.clients.length; z++) {
      if (
        formData.Application.clients[z].optBenefitHMEN ||
        formData.Application.clients[z].optBenefitFDNT
      ) {
        for (
          let i = 0;
          i <
          formData.Application.clients[z].otherHouseholdUtilityExpenseDetail
            .length;
          i++
        ) {
          const existingOtherHouseholdUtilityPayment =
            formData.Application.clients[z].otherHouseholdUtilityExpenses &&
            formData.Application.clients[z].otherHouseholdUtilityExpenses.find(
              otherHouseholdUtilityExpense => {
                return (
                  otherHouseholdUtilityExpense ===
                  formData.Application.clients[z]
                    .otherHouseholdUtilityExpenseDetail[i]
                    .otherHouseholdUtilityKey
                )
              }
            )
          if (existingOtherHouseholdUtilityPayment) {
            const otherHouseholdUtility =
              formData.Application.clients[z]
                .otherHouseholdUtilityExpenseDetail[i].otherHouseholdUtility
            for (let j = 0; j < otherHouseholdUtility.length; j++) {
              if (otherHouseholdUtility) {
                if (
                  existingOtherHouseholdUtilityPayment == '16' ||
                  existingOtherHouseholdUtilityPayment == '18'
                ) {
                  requireField(
                    'Application_clients_' +
                      z +
                      '_otherHouseholdUtilityExpenseDetail_' +
                      i +
                      '_otherHouseholdUtility_' +
                      j +
                      '_payingToDropdown',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[z] + errorMessages['payingToDropdown']
                  )
                } else {
                  requireField(
                    'Application_clients_' +
                      z +
                      '_otherHouseholdUtilityExpenseDetail_' +
                      i +
                      '_otherHouseholdUtility_' +
                      j +
                      '_payingTo',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[z] + errorMessages['payingToDropdown']
                  )
                }
              }
              requireField(
                'Application_clients_' +
                  z +
                  '_otherHouseholdUtilityExpenseDetail_' +
                  i +
                  '_otherHouseholdUtility_' +
                  j +
                  '_shelterAmountPaid',
                errors,
                formData,
                validationStep,
                clientsArray[z] +
                  errorMessages['otherHouseholdUtility_shelterAmountPaid']
              )
              requireField(
                'Application_clients_' +
                  z +
                  '_otherHouseholdUtilityExpenseDetail_' +
                  i +
                  '_otherHouseholdUtility_',
                errors,
                formData,
                validationStep,
                clientsArray[z] +
                  errorMessages[
                    'otherHouseholdUtilityExpenseDetail_otherHouseholdUtility'
                  ]
              )
              // Code Changes GPRB0359078 and US2554056
              // Full Application - Utility Expenses Payment Info address Zip Code validation
              if (
                formData.Application.clients[z]
                  .otherHouseholdUtilityExpenseDetail &&
                formData.Application.clients[z]
                  .otherHouseholdUtilityExpenseDetail[i]
                  .otherHouseholdUtility &&
                formData.Application.clients[z]
                  .otherHouseholdUtilityExpenseDetail[i].otherHouseholdUtility[
                  j
                ].paymentInfo &&
                formData.Application.clients[z]
                  .otherHouseholdUtilityExpenseDetail[i].otherHouseholdUtility[
                  j
                ].paymentInfo.zipCode &&
                formData.Application.clients[z]
                  .otherHouseholdUtilityExpenseDetail[i].otherHouseholdUtility[
                  j
                ].paymentInfo.zipCode !== undefined
              ) {
                if (
                  validZipCodeCheck(
                    formData.Application.clients[z]
                      .otherHouseholdUtilityExpenseDetail[i]
                      .otherHouseholdUtility[j].paymentInfo.zipCode
                  )
                ) {
                  notRequiredZip(
                    'Application_clients_' +
                      z +
                      '_otherHouseholdUtilityExpenseDetail_' +
                      i +
                      '_otherHouseholdUtility_' +
                      j +
                      '_paymentInfo_zipCode',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[z] + errorMessages['ZipCodefivedigits']
                  )
                }
              }
            }
          }
        }
      }
    }

    //sprint 9 story
    for (let i = 0; i < formData.Application.clients.length; ++i) {
      // AppIntakeFamily_Application_clients_0_clientRelationships_0_clientRelationship
      //caringFor
      if (
        formData.Application.clients[i].optBenefitMDPA ||
        formData.Application.clients[i].optBenefitHEWL
      ) {
        for (let z = 0; z < formData.Application.clients.length - 1; ++z) {
          requireField(
            'Application_clients_' +
              i +
              '_clientRelationships_' +
              z +
              '_clientRelationship',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['RelationshipRequired']
          )
        }
      }

      if (
        formData.Application.clients[i].optBenefitHEWL ||
        _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
          'WVWORKS'
        ) > -1
      ) {
        for (let z = 0; z < formData.Application.clients.length - 1; ++z) {
          requireField(
            'Application_clients_' +
              i +
              '_clientRelationships_' +
              z +
              '_caringFor',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['caringFor']
          )
        }
      }
    }

    //Sprint 10
    // Code Changes for US3173420
    for (let j = 0; j < formData.Application.clients.length; ++j) {
      //if (formData.Application.clients[j].optBenefitCHCR) {
      // AppIntakeFamily_Application_clients_0_fullOrPartTimeStu
      let clientBirthDate = _moment(
        config,
        formContext.formData.Application.clients[j].clientBthDt
      )
      let cur = _moment(config, currentDate)
      let age = cur.diff(clientBirthDate, 'years')

      if (
        formData.Application.clients[j].optBenefitWVWORKS ||
        (formData.Application.clients[j].optBenefitCHCR &&
          clientBirthDate !== undefined &&
          !_.inRange(age, 5, 18)) ||
        (_.includes(formData.Application.benefitCategorieslist, 'SCAL') &&
          formData.Application.clients[j].optBenefitSCAL)
      ) {
        requireField(
          'Application_clients_' + j + '_fullOrPartTimeStu',
          errors,
          formData,
          validationStep,
          clientsArray[j] + errorMessages['fullOrPartTimeStu']
        )
      }
      // Code Changes for US3173420
      if (
        (formData.Application.clients[j].optBenefitCHCR ||
          (_.includes(formData.Application.benefitCategorieslist, 'SCAL') &&
            formData.Application.clients[j].optBenefitSCAL)) &&
        (formData.Application.clients[j].fullOrPartTimeStu === '1' ||
          formData.Application.clients[j].fullOrPartTimeStu === '2' ||
          formData.Application.clients[j].fullOrPartTimeStu === '3')
      ) {
        // AppIntakeFamily_Application_clients_0_schoolEnrollmentDetails_enrolledRightNow
        requireField(
          'Application_clients_' +
            j +
            '_schoolEnrollmentDetails_nameOfTheSchool',
          errors,
          formData,
          validationStep,
          clientsArray[j] +
            errorMessages['schoolEnrollmentDetails_nameOfTheSchool']
        )
        requireField(
          'Application_clients_' +
            j +
            '_schoolEnrollmentDetails_beganAttendSchool',
          errors,
          formData,
          validationStep,
          clientsArray[j] +
            errorMessages['schoolEnrollmentDetails_beganAttendSchool']
        )
      }
      //}
      // Code Changes for US3173420
      if (
        (formData.Application.clients[j].optBenefitCHCR ||
          (_.includes(formData.Application.benefitCategorieslist, 'SCAL') &&
            formData.Application.clients[j].optBenefitSCAL)) &&
        (formData.Application.clients[j].fullOrPartTimeStu === '1' ||
          formData.Application.clients[j].fullOrPartTimeStu === '2' ||
          formData.Application.clients[j].fullOrPartTimeStu === '3')
      ) {
        requireField(
          'Application_clients_' + j + '_schoolEnrollmentDetails_typeOfSchool',
          errors,
          formData,
          validationStep,
          clientsArray[j] +
            errorMessages['schoolEnrollmentDetails_typeOfSchool']
        )
        requireField(
          'Application_clients_' + j + '_schoolEnrollmentDetails_exptGrdDt',
          errors,
          formData,
          validationStep,
          clientsArray[j] + errorMessages['schoolEnrollmentDetails_exptGrdDt']
        )
      }
    }

    for (let k = 0; k < formData.Application.clients.length; ++k) {
      const optBenefitWVWORKS = _.get(
        formData,
        `Application.clients[${k}].optBenefitWVWORKS`,
        false
      )
      if (optBenefitWVWORKS) {
        const otherFamilyExpense = _.get(
          formData,
          `Application.clients[${k}].otherFamilyExpense`,
          []
        )
        if (otherFamilyExpense.length === 1 && otherFamilyExpense[0] === 10) {
          const otherFamilyExpenseDetailArray = _.get(
            formData,
            `Application.clients[${k}].otherFamilyExpenseDetail`,
            []
          )
          for (
            let otherFamilyExpenseDetailIndex = 0;
            otherFamilyExpenseDetailIndex <
            otherFamilyExpenseDetailArray.length;
            ++otherFamilyExpenseDetailIndex
          ) {
            const otherFamilyExpenseArray = _.get(
              formData,
              `Application.clients[${k}].otherFamilyExpenseDetail[${otherFamilyExpenseDetailIndex}].otherFamilyExpense`,
              []
            )
            for (
              let otherFamilyExpenseIndex = 0;
              otherFamilyExpenseIndex < otherFamilyExpenseArray.length;
              ++otherFamilyExpenseIndex
            ) {
              const dependentCareArray = _.get(
                formData,
                `Application.clients[${k}].otherFamilyExpenseDetail[${otherFamilyExpenseDetailIndex}].otherFamilyExpense[${otherFamilyExpenseIndex}].dependentCareArray`,
                []
              )
              for (
                let dependentCareIndex = 0;
                dependentCareIndex < dependentCareArray.length;
                ++dependentCareIndex
              ) {
                requireField(
                  'Application_clients_' +
                    k +
                    '_otherFamilyExpenseDetail_' +
                    otherFamilyExpenseDetailIndex +
                    '_otherFamilyExpense_' +
                    otherFamilyExpenseIndex +
                    '_dependentCareArray_' +
                    dependentCareIndex +
                    '_whoElseOwnThisAssets',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[0] + errorMessages['whoElseOwnThisAssets']
                )
                if (
                  dependentCareArray[dependentCareIndex]
                    .whoElseOwnThisAssets === 'Other'
                ) {
                  requireField(
                    'Application_clients_' +
                      k +
                      '_otherFamilyExpenseDetail_' +
                      otherFamilyExpenseDetailIndex +
                      '_otherFamilyExpense_' +
                      otherFamilyExpenseIndex +
                      '_dependentCareArray_' +
                      dependentCareIndex +
                      '_nameOfOtherPerson',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[0] +
                      'Please enter the Name of person not in the home for dependant care expense'
                  )
                }
                requireField(
                  'Application_clients_' +
                    k +
                    '_otherFamilyExpenseDetail_' +
                    otherFamilyExpenseDetailIndex +
                    '_otherFamilyExpense_' +
                    otherFamilyExpenseIndex +
                    '_dependentCareArray_' +
                    dependentCareIndex +
                    '_shelterAmountPaid',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[0] +
                    errorMessages['dependentCareArrayshelterAmountPaid']
                )
                requireField(
                  'Application_clients_' +
                    k +
                    '_otherFamilyExpenseDetail_' +
                    otherFamilyExpenseDetailIndex +
                    '_otherFamilyExpense_' +
                    otherFamilyExpenseIndex +
                    '_dependentCareArray_' +
                    dependentCareIndex +
                    '_howOftenPaid',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[0] + errorMessages['howOftenPaid']
                )
              }

              requireField(
                'Application_clients_' +
                  k +
                  '_otherFamilyExpenseDetail_' +
                  otherFamilyExpenseDetailIndex +
                  '_otherFamilyExpense_' +
                  otherFamilyExpenseIndex +
                  '_paymentInfo_payingTo',
                errors,
                formData,
                validationStep,
                clientsArray[0] + errorMessages['paymentInfo_payingTo']
              )
            }
          }
        }
      }
    }
  }

  //Child Care
  if ((step === 7 || validateAll) && !isIncomplete) {
    validationStep = 7
    for (let i = 0; i < formData.Application.clients.length; ++i) {
      if (
        formData.Application.clients[i].childCareProvider === 'Y' &&
        (formData.Application.clients[i].relationship === '37' ||
          formData.Application.clients[i].relationship === '59')
      ) {
        requireField(
          'Application_clients_' +
            i +
            '_providerInformation_' +
            0 +
            '_providerName',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['paymentInfo_payingTo']
        )
      }
      // Code Changes GPRB0359078 and US2554056
      // Full Application - Child Care Provider Accordion Zip Code validation
      if (
        formData.Application.clients[i].providerInformation &&
        formData.Application.clients[i].providerInformation[0]
          .providerAddress &&
        formData.Application.clients[i].providerInformation[0].providerAddress
          .zip &&
        formData.Application.clients[i].providerInformation[0].providerAddress
          .zip !== undefined
      ) {
        if (
          validZipCodeCheck(
            formData.Application.clients[i].providerInformation[0]
              .providerAddress.zip
          )
        ) {
          notRequiredZip(
            'Application_clients_' +
              i +
              '_providerInformation_' +
              0 +
              '_providerAddress_zip',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['ZipCodefivedigits']
          )
        }
      }
      let flag = false
      if (requireDaysTime(i, formData)) {
        flag = true
        errors.addError(
          createErrorJsonString(
            expectedValueError,
            validationStep,
            'Application_clients_' + i + '_schoolSchedule',
            clientsArray[i] + errorMessages['SelectDayTime']
          )
        )
      }
      if (flag) {
        break
      }
      if (
        formData.Application.clients[i].role == 'parentorcaregiver' &&
        formData.Application.clients[i].optBenefitCHCR
      ) {
        requireField(
          'Application_clients_' + i + '_childCareReason',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['childCareReason']
        )
        requireField(
          'Application_clients_' + i + '_currentOrFormerMilitary',
          errors,
          formData,
          validationStep,
          clientsArray[i] +
            `Please select "Are you a current or former member of the military"`
        )
        if (formData.Application.clients[i].currentOrFormerMilitary === 'Y') {
          requireField(
            'Application_clients_' + i + '_militaryBranchName',
            errors,
            formData,
            validationStep,
            clientsArray[i] + `Please select "Military Branch Name"`
          )
          requireField(
            'Application_clients_' + i + '_militaryStartDate',
            errors,
            formData,
            validationStep,
            clientsArray[i] + `Please select "Military Service From Date"`
          )
        }
      }
      if (i === 0 && formData.Application.clients[i].optBenefitCHCR) {
        const schoolSchedule = _.get(
          formData,
          'Application.clients[' + i + '].schoolSchedule',
          []
        )
        for (let schedule = 0; schedule < schoolSchedule.length; schedule++) {
          const scheduleObject = schoolSchedule[schedule]
          if (
            scheduleObject['monday'] ||
            scheduleObject['tuesday'] ||
            scheduleObject['wednesday'] ||
            scheduleObject['thursday'] ||
            scheduleObject['friday'] ||
            scheduleObject['saturday'] ||
            scheduleObject['sunday']
          ) {
            !scheduleObject.timeTo &&
              requireField(
                'Application_clients_' +
                  i +
                  '_schoolSchedule_' +
                  schedule +
                  '_timeTo',
                errors,
                formData,
                validationStep,
                clientsArray[i] + errorMessages['schoolSchedule_timeTo']
              )
            !scheduleObject.timeFrom &&
              requireField(
                'Application_clients_' +
                  i +
                  '_schoolSchedule_' +
                  schedule +
                  '_timeFrom',
                errors,
                formData,
                validationStep,
                clientsArray[i] + errorMessages['schoolSchedule_timeFrom']
              )
          }
        }
      }
    }
  }
  //INCOME_AND_RESOURCES
  if ((step === 9 || validateAll) && !isIncomplete) {
    validationStep = 9
    let flg = true
    let spouseFlag = false

    /*formData.Application.clients.forEach(client => {
      if (client.relationship === '61' && client.optBenefitMDPA) {
        spouseFlag = true
      }
    })*/
    for (let i = 0; i < formData.Application.clients.length; i++) {
      // Code Fix for US3447722
      spouseFlag = false
      if (
        (i === 0 &&
          _.includes(formData.Application.benefitCategorieslist, 'MDPA')) ||
        (formData.Application.clients[i].relationship !== undefined &&
          formData.Application.clients[i].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        spouseFlag = true
      }

      if (
        _.get(formData, `Application.clients[${i}].strkAffectIncome`) == 'Y' &&
        (_.get(formData, 'Application.benefitCategorieslist', []).indexOf(
          'WVWORKS'
        ) > -1 ||
          formData.Application.clients[i].optBenefitFDNT)
      ) {
        requireField(
          'Application_clients_' + i + '_strkSrtDate',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['strkSrtDate']
        )
      }

      if (
        (formData.Application.clients[i].reportedIncome === 'Y' &&
          (formData.Application.clients[i].optBenefitCHCR ||
            formData.Application.clients[i].optBenefitCHSP ||
            formData.Application.clients[i].optBenefitFDNT ||
            formData.Application.clients[i].optBenefitHMEN ||
            formData.Application.clients[i].optBenefitMDPA ||
            formData.Application.clients[i].optBenefitSCAL ||
            formData.Application.clients[i].optBenefitWVWORKS ||
            (formData.Application.clients[i].optBenefitMDPA &&
              formData.Application.clients[i].relationship === '61'))) ||
        (i === 0 &&
          spouseFlag &&
          formData.Application.clients[i].reportedIncome === 'Y') ||
        (formData.Application.clients[i].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA &&
          formData.Application.clients[i].reportedIncome === 'Y')
      ) {
        requireField(
          'Application_clients_' + [i] + '_exptMagiIncm',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['clients_exptMagiIncm']
        )
      }

      if (formData.Application.clients[i].exptMagiIncm == '') {
        flg = false
      }
      if (formData.Application.clients[i].reportedIncome === 'Y' && flg) {
        if (
          formData.Application.clients[i].incometypeDetails.earnedIncome
            .wagesCheckbox &&
          formData.Application.clients[i].incometypeDetails.earnedIncome
            .wagesCheckbox[0] > -1
        ) {
          requireField(
            'Application_clients_' +
              [i] +
              '_incometypeDetails_earnedIncome_wagesDetails_0' +
              '_nameofemployer',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['nameofemployer']
          )
          // Code Changes GPRB0359078 and US2554056
          // Full Application - Earned Income Zip Code and Zip Ext validation
          if (
            formData.Application.clients[i].incometypeDetails.earnedIncome &&
            formData.Application.clients[i].incometypeDetails.earnedIncome
              .wagesDetails
          ) {
            if (
              formData.Application.clients[i].incometypeDetails.earnedIncome
                .wagesDetails[0].employerZipCode &&
              formData.Application.clients[i].incometypeDetails.earnedIncome
                .wagesDetails[0].employerZipCode !== undefined
            ) {
              if (
                validZipCodeCheck(
                  formData.Application.clients[i].incometypeDetails.earnedIncome
                    .wagesDetails[0].employerZipCode
                )
              ) {
                notRequiredZip(
                  'Application_clients_' +
                    [i] +
                    '_incometypeDetails_earnedIncome_wagesDetails_0' +
                    '_employerZipCode',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[i] + errorMessages['ZipCodefivedigits']
                )
              }
            }
            if (
              formData.Application.clients[i].incometypeDetails.earnedIncome
                .wagesDetails[0].employerZipExt &&
              formData.Application.clients[i].incometypeDetails.earnedIncome
                .wagesDetails[0].employerZipExt !== undefined
            ) {
              if (
                validZipExtCheck(
                  formData.Application.clients[i].incometypeDetails.earnedIncome
                    .wagesDetails[0].employerZipExt
                )
              ) {
                notRequiredZipExt(
                  'Application_clients_' +
                    [i] +
                    '_incometypeDetails_earnedIncome_wagesDetails_0' +
                    '_employerZipExt',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[i] + errorMessages['Extmustbefourdigits']
                )
              }
            }
          }
          if (
            formData.Application.clients[i].optBenefitHEWL ||
            formData.Application.clients[i].optBenefitMDPA ||
            formData.Application.clients[i].optBenefitFDNT ||
            formData.Application.clients[i].optBenefitHMEN ||
            formData.Application.clients[i].optBenefitCHCR ||
            formData.Application.clients[i].optBenefitSCAL ||
            // below condition added to enable validation for non-appplying members
            formData.Application.benefitCategorieslist.indexOf('HEWL') > -1 ||
            formData.Application.benefitCategorieslist.indexOf('SCAL') > -1 ||
            formData.Application.benefitCategorieslist.indexOf('WVWORKS') >
              -1 ||
            (formData.Application.clients[i].optBenefitMDPA &&
              formData.Application.clients[i].relationship === '61') ||
            (i === 0 && spouseFlag) ||
            (formData.Application.clients[i].relationship === '61' &&
              formData.Application.clients[0].optBenefitMDPA)
          ) {
            // Employment start date is mandatory for child care. defect# 3108
            if (formData.Application.clients[i].optBenefitCHCR) {
              requireField(
                'Application_clients_' +
                  [i] +
                  '_incometypeDetails_earnedIncome_wagesDetails_0' +
                  '_jobStartDate',
                errors,
                formData,
                validationStep,
                clientsArray[i] + errorMessages['jobStartDate']
              )
            }

            requireField(
              'Application_clients_' +
                [i] +
                '_incometypeDetails_earnedIncome_wagesDetails_0' +
                '_incomestartDate',
              errors,
              formData,
              validationStep,
              clientsArray[i] + errorMessages['incomestartDate']
            )

            requireField(
              'Application_clients_' +
                [i] +
                '_incometypeDetails_earnedIncome_wagesDetails_0' +
                '_incomeFrequency',
              errors,
              formData,
              validationStep,
              clientsArray[i] + errorMessages['incomeFrequency']
            )
            requireField(
              'Application_clients_' +
                [i] +
                '_incometypeDetails_earnedIncome_wagesDetails_0' +
                '_hoursPerWeek',
              errors,
              formData,
              validationStep,
              clientsArray[i] + errorMessages['hoursPerWeek']
            )
          }
          if (
            formData.Application.clients[i].optBenefitHEWL ||
            formData.Application.clients[i].optBenefitMDPA ||
            formData.Application.clients[i].optBenefitCHCR ||
            (formData.Application.clients[i].optBenefitMDPA &&
              formData.Application.clients[i].relationship === '61') ||
            (i === 0 && spouseFlag) ||
            (formData.Application.clients[i].relationship === '61' &&
              formData.Application.clients[0].optBenefitMDPA)
          ) {
            // AppIntakeIncome_Application_clients_1_incometypeDetails_earnedIncome_wagesDetails_0_paidFromthisEmployer
            requireField(
              'Application_clients_' +
                [i] +
                '_incometypeDetails_earnedIncome_wagesDetails_0' +
                '_paidFromthisEmployer',
              errors,
              formData,
              validationStep,
              clientsArray[i] + errorMessages['paidFromthisEmployer']
            )
          }
        }
      }
    }
    for (
      let client = 0;
      client < formData.Application.clients.length;
      client++
    ) {
      const childCareSelected = formData.Application.clients[0].optBenefitCHCR
      if (childCareSelected) {
        if (client === 0) {
          requireField(
            'Application_clients_' + 0 + '_totalHouseholdAssets',
            errors,
            formData,
            validationStep,
            clientsArray[0] + errorMessages['totalHouseholdAssets']
          )
        }
        const childCareReason =
          formData.Application.clients[client].childCareReason &&
          formData.Application.clients[client].childCareReason.indexOf('2')
        const wagesCheckBox =
          formData.Application.clients[client].incometypeDetails.earnedIncome
            .wagesCheckbox &&
          formData.Application.clients[
            client
          ].incometypeDetails.earnedIncome.wagesCheckbox.indexOf('108')
        const wagesDetails =
          formData.Application.clients[client].incometypeDetails.earnedIncome
            .wagesDetails
        for (let wage = 0; wage < wagesDetails.length; wage++) {
          if (
            childCareReason > -1 &&
            wagesCheckBox > -1 &&
            wagesDetails[wage].setSchedule === 'Y'
          ) {
            const workdayTimings = wagesDetails[wage].workdayTimings
            if (workdayTimings) {
              for (
                let workday = 0;
                workday < workdayTimings.length;
                workday++
              ) {
                const workdayValue = workdayTimings[workday]

                if (
                  !(
                    workdayValue.sunday ||
                    workdayValue.monday ||
                    workdayValue.tuesday ||
                    workdayValue.wednesday ||
                    workdayValue.thursday ||
                    workdayValue.friday ||
                    workdayValue.saturday
                  )
                ) {
                  _.set(workdayValue, 'sunday', undefined)
                  requireField(
                    'Application_clients_' +
                      client +
                      '_incometypeDetails_earnedIncome_wagesDetails_' +
                      wage +
                      '_workdayTimings_' +
                      workday +
                      '_sunday',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[client] + errorMessages['workdayTimings'],
                    clientsArray[client] + errorMessages['SelectOneday']
                  )
                } else {
                  requireField(
                    'Application_clients_' +
                      client +
                      '_incometypeDetails_earnedIncome_wagesDetails_' +
                      wage +
                      '_workdayTimings_' +
                      workday +
                      '_timeFrom',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[client] +
                      errorMessages['workdayTimings_timeFrom']
                  )
                  requireField(
                    'Application_clients_' +
                      client +
                      '_incometypeDetails_earnedIncome_wagesDetails_' +
                      wage +
                      '_workdayTimings_' +
                      workday +
                      '_timeTo',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[client] +
                      errorMessages['workdayTimings_timeTo']
                  )
                }
              }
            }
          }
        }
        const selfEmploymentIncomeDetails =
          formData.Application.clients[client].incometypeDetails
            .selfemploymentIncome.selfEmploymentIncomeDetails
        for (
          let seIncome = 0;
          seIncome < selfEmploymentIncomeDetails.length;
          seIncome++
        ) {
          const seIncomeObject = selfEmploymentIncomeDetails[seIncome]
          if (seIncomeObject.hasIncome) {
            const incomeArray = seIncomeObject.income
            for (let income = 0; income < incomeArray.length; income++) {
              const incomeObject = incomeArray[income]
              if (incomeObject.setSchedule === 'Y' && childCareReason > -1) {
                const workdayTimings = incomeObject.workdayTimings
                for (
                  let workday = 0;
                  workday < workdayTimings.length;
                  workday++
                ) {
                  const workdayValue = workdayTimings[workday]

                  if (
                    !(
                      workdayValue.sunday ||
                      workdayValue.monday ||
                      workdayValue.tuesday ||
                      workdayValue.wednesday ||
                      workdayValue.thursday ||
                      workdayValue.friday ||
                      workdayValue.saturday
                    )
                  ) {
                    _.set(workdayValue, 'sunday', undefined)
                    requireField(
                      'Application_clients_' +
                        client +
                        '_incometypeDetails_selfemploymentIncome_selfEmploymentIncomeDetails_' +
                        seIncome +
                        '_income_' +
                        income +
                        '_workdayTimings_' +
                        workday +
                        '_sunday',
                      errors,
                      formData,
                      validationStep,
                      clientsArray[client] + errorMessages['workdayTimings'],
                      clientsArray[client] + errorMessages['SelectOneday']
                    )
                  } else {
                    requireField(
                      'Application_clients_' +
                        client +
                        '_incometypeDetails_selfemploymentIncome_selfEmploymentIncomeDetails_' +
                        seIncome +
                        '_income_' +
                        income +
                        '_workdayTimings_' +
                        workday +
                        '_timeFrom',
                      errors,
                      formData,
                      validationStep,
                      clientsArray[client] +
                        errorMessages['workdayTimings_timeFrom']
                    )
                    requireField(
                      'Application_clients_' +
                        client +
                        '_incometypeDetails_selfemploymentIncome_selfEmploymentIncomeDetails_' +
                        seIncome +
                        '_income_' +
                        income +
                        '_workdayTimings_' +
                        workday +
                        '_timeTo',
                      errors,
                      formData,
                      validationStep,
                      clientsArray[client] +
                        errorMessages['workdayTimings_timeTo']
                    )
                  }
                }
              }
            }
          }
        }
      }
    }

    //sprint 9 changes - Start
    //have any vehicles validation fixed
    for (let a = 0; a < formData.Application.clients.length; a++) {
      // Code Fix for US3447722
      spouseFlag = false
      if (
        (a === 0 &&
          _.includes(formData.Application.benefitCategorieslist, 'MDPA')) ||
        (formData.Application.clients[a].relationship !== undefined &&
          formData.Application.clients[a].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        spouseFlag = true
      }
      if (
        _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
          'WVWORKS'
        ) > -1 ||
        formData.Application.clients[a].optBenefitMDPA ||
        (formData.Application.benefitCategorieslist.indexOf('MDPA') > -1 &&
          formData.Application.clients[a].relationship === '61') ||
        spouseFlag ||
        (formData.Application.clients[a].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        if (formData.Application.clients[a].anyVechicle === undefined) {
          requireField(
            'Application_clients_' + [a] + '_anyVechicle',
            errors,
            formData,
            validationStep,
            clientsArray[a] + errorMessages['anyVechicle']
          )
        }
        if (formData.Application.clients[a].anyVechicle === 'Y') {
          requireField(
            'Application_clients_' + [a] + '_vehicleAssets',
            errors,
            formData,
            validationStep,
            clientsArray[a] + errorMessages['vehicleAssets']
          )
        }

        let vehicleAssetDetailsArray =
          formData.Application.clients[0].vehicleAssetDetails

        for (let x = 0; x < vehicleAssetDetailsArray.length; x++) {
          if (
            formData.Application.clients[a].vehicleAssets &&
            formData.Application.clients[a].vehicleAssets.length > 0
          ) {
            for (let selectedItem in formData.Application.clients[a]
              .vehicleAssets) {
              if (
                formData.Application.clients[a].vehicleAssets[selectedItem] ===
                vehicleAssetDetailsArray[x].vehicleAssetKey
              ) {
                requireField(
                  'Application_clients_' +
                    [a] +
                    '_vehicleAssetDetails_' +
                    [x] +
                    '_vehicleAssetType_0_worthOfThisVehicle',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[a] + errorMessages['worthOfThisVehicle']
                )
                requireField(
                  'Application_clients_' +
                    [a] +
                    '_vehicleAssetDetails_' +
                    [x] +
                    '_vehicleAssetType_0_oweToThisVehicle',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[a] + errorMessages['oweToThisVehicle']
                )
                requireField(
                  'Application_clients_' +
                    [a] +
                    '_vehicleAssetDetails_' +
                    [x] +
                    '_vehicleAssetType_0_accessibleBy',
                  errors,
                  formData,
                  validationStep,
                  clientsArray[a] + errorMessages['accessibleBy']
                )
              }
            }
          }
        }
      }

      //GPRB0359161	- US2677198
      if (formData.Application.clients[a].anyVechicle === 'Y') {
        let vehicleAssetDetailsArray =
          formData.Application.clients[a].vehicleAssetDetails

        for (let x = 0; x < vehicleAssetDetailsArray.length; x++) {
          if (
            formData.Application.clients[a].vehicleAssets &&
            formData.Application.clients[a].vehicleAssets.length > 0
          ) {
            for (let selectedItem in formData.Application.clients[a]
              .vehicleAssets) {
              if (
                formData.Application.clients[a].vehicleAssets[selectedItem] ===
                vehicleAssetDetailsArray[x].vehicleAssetKey
              ) {
                // remove IF and new FOR loop to validate all vehicles
                if (
                  vehicleAssetDetailsArray[x].vehicleAssetType[0].vehicleYear &&
                  vehicleAssetDetailsArray[x].vehicleAssetType[0].vehicleYear
                    .length < 4
                )
                  errorMessage(
                    'Application_clients_' +
                      [a] +
                      '_vehicleAssetDetails_' +
                      [x] +
                      '_vehicleAssetType_0_vehicleYear',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[a] + errorMessages['vehicleYear'],
                    errorMessages['vehicleAssetType']
                  )
              }
            }
          }
        }
      }
    }
    //have any any other assets fixed
    for (let b = 0; b < formData.Application.clients.length; b++) {
      // Code Fix for US3447722
      spouseFlag = false
      if (
        (b === 0 &&
          _.includes(formData.Application.benefitCategorieslist, 'MDPA')) ||
        (formData.Application.clients[b].relationship !== undefined &&
          formData.Application.clients[b].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        spouseFlag = true
      }
      if (
        _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
          'WVWORKS'
        ) > -1 ||
        formData.Application.clients[b].optBenefitMDPA ||
        (formData.Application.benefitCategorieslist.indexOf('MDPA') > -1 &&
          formData.Application.clients[b].relationship === '61') ||
        spouseFlag ||
        (formData.Application.clients[b].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        if (
          formData.Application.clients[b].anyOtherLiquidAssets === undefined
        ) {
          requireField(
            'Application_clients_' + [b] + '_anyOtherLiquidAssets',
            errors,
            formData,
            validationStep,
            clientsArray[b] + errorMessages['anyOtherLiquidAssets']
          )
        }
        if (formData.Application.clients[b].anyOtherLiquidAssets === 'Y') {
          requireField(
            'Application_clients_' + [b] + '_otherAssets',
            errors,
            formData,
            validationStep,
            clientsArray[b] + errorMessages['otherAssets']
          )
        }
        var otherAssetDetailsArray =
          formData.Application.clients[b].otherAssetDetails
        for (let x = 0; x < otherAssetDetailsArray.length; x++) {
          if (
            formData.Application.clients[b].otherAssets &&
            formData.Application.clients[b].otherAssets.length > 0
          ) {
            for (let selectedItem in formData.Application.clients[b]
              .otherAssets) {
              if (
                formData.Application.clients[b].otherAssets[selectedItem] ===
                otherAssetDetailsArray[x].otherAssetKey
              ) {
                if (
                  otherAssetDetailsArray[x].otherAssetType[0].worthOfAsset ===
                    undefined ||
                  otherAssetDetailsArray[x].otherAssetType[0].worthOfAsset ===
                    ''
                ) {
                  requireField(
                    'Application_clients_' +
                      [b] +
                      '_otherAssetDetails_' +
                      [x] +
                      '_otherAssetType_0_worthOfAsset',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[b] + errorMessages['worthOfAsset']
                  )
                }
                if (
                  otherAssetDetailsArray[x].otherAssetType[0]
                    .accessibleByAplicant === undefined
                ) {
                  requireField(
                    'Application_clients_' +
                      [b] +
                      '_otherAssetDetails_' +
                      [x] +
                      '_otherAssetType_0_accessibleByAplicant',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[b] + errorMessages['accessibleByAplicant']
                  )
                }
              }
            }
          }
        }
      }
    }
    //have any liquid assets fixed
    for (let c = 0; c < formData.Application.clients.length; c++) {
      // Code Fix for US3447722
      spouseFlag = false
      if (
        (c === 0 &&
          _.includes(formData.Application.benefitCategorieslist, 'MDPA')) ||
        (formData.Application.clients[c].relationship !== undefined &&
          formData.Application.clients[c].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        spouseFlag = true
      }
      if (
        _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
          'WVWORKS'
        ) > -1 ||
        formData.Application.clients[c].optBenefitMDPA ||
        (formData.Application.benefitCategorieslist.indexOf('MDPA') > -1 &&
          formData.Application.clients[c].relationship === '61') ||
        spouseFlag ||
        (formData.Application.clients[c].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        if (formData.Application.clients[c].anyLiquidAssets === undefined) {
          requireField(
            'Application_clients_' + [c] + '_anyLiquidAssets',
            errors,
            formData,
            validationStep,
            clientsArray[c] + errorMessages['anyLiquidAssets']
          )
        }
        if (formData.Application.clients[c].anyLiquidAssets === 'Y') {
          requireField(
            'Application_clients_' + [c] + '_liquidAssets',
            errors,
            formData,
            validationStep,
            clientsArray[c] + errorMessages['liquidAssets']
          )
        }
        var liquidAssetDetailsArray =
          formData.Application.clients[c].liquidAssetDetails

        for (let x = 0; x < liquidAssetDetailsArray.length; x++) {
          if (
            formData.Application.clients[c].liquidAssets &&
            formData.Application.clients[c].liquidAssets.length > 0
          ) {
            for (let selectedItem in formData.Application.clients[c]
              .liquidAssets) {
              if (
                formData.Application.clients[c].liquidAssets[selectedItem] ===
                liquidAssetDetailsArray[x].liquidAssetKey
              ) {
                if (
                  liquidAssetDetailsArray[x].liquidAssetType[0].valueOfAsset ===
                    undefined ||
                  liquidAssetDetailsArray[x].liquidAssetType[0].valueOfAsset ===
                    ''
                ) {
                  requireField(
                    'Application_clients_' +
                      [c] +
                      '_liquidAssetDetails_' +
                      [x] +
                      '_liquidAssetType_0_valueOfAsset',
                    errors,
                    formData,
                    validationStep,
                    clientsArray[c] + errorMessages['worthOfAsset']
                  )
                }
              }
            }
          }
        }
      }
    }
    //incometypeDetails selfemploymentIncome validation
    // Code Fix for US3447722
    for (let d = 0; d < formData.Application.clients.length; d++) {
      spouseFlag = false
      if (
        (d === 0 &&
          _.includes(formData.Application.benefitCategorieslist, 'MDPA')) ||
        (formData.Application.clients[d].relationship !== undefined &&
          formData.Application.clients[d].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        spouseFlag = true
      }
      if (
        _.get(formData, 'Application.benefitCategorieslist', []).indexOf(
          'WVWORKS'
        ) > -1 ||
        _.includes(formData.Application.benefitCategorieslist, 'HEWL') ||
        formData.Application.clients[d].optBenefitMDPA ||
        (formData.Application.benefitCategorieslist.indexOf('MDPA') > -1 &&
          formData.Application.clients[d].relationship === '61') ||
        spouseFlag ||
        (formData.Application.clients[d].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        if (formData.Application.clients[d].reportedIncome === undefined) {
          requireField(
            'Application_clients_' + d + '_reportedIncome',
            errors,
            formData,
            validationStep,
            clientsArray[d] + errorMessages['reportedIncome']
          )
        }
        let selfEmploymentIncomeDetailsArray =
          formData.Application.clients[d].incometypeDetails.selfemploymentIncome
            .selfEmploymentIncomeDetails
        let unearnedIncomeDetailsArray =
          formData.Application.clients[d].incometypeDetails.unearnedIncome
            .unearnedIncomeDetails

        if (formData.Application.clients[d].reportedIncome === 'Y') {
          for (let k = 0; k < selfEmploymentIncomeDetailsArray.length; k++) {
            if (selfEmploymentIncomeDetailsArray[k].hasIncome) {
              requireField(
                'Application_clients_' +
                  [d] +
                  '_incometypeDetails_selfemploymentIncome_selfEmploymentIncomeDetails_' +
                  [k] +
                  '_income_0' +
                  '_amount',
                errors,
                formData,
                validationStep,
                clientsArray[d] +
                  errorMessages['selfEmploymentIncomeDetails_income_amount']
              )
              requireField(
                'Application_clients_' +
                  [d] +
                  '_incometypeDetails_selfemploymentIncome_selfEmploymentIncomeDetails_' +
                  [k] +
                  '_income_0' +
                  '_businessExpenses',
                errors,
                formData,
                validationStep,
                clientsArray[d] + errorMessages['businessExpenses']
              )
            }
          }
        }

        if (formData.Application.clients[d].reportedIncome === 'Y') {
          for (let j = 0; j < unearnedIncomeDetailsArray.length; j++) {
            if (unearnedIncomeDetailsArray[j].hasIncome) {
              requireField(
                'Application_clients_' +
                  [d] +
                  '_incometypeDetails_unearnedIncome_unearnedIncomeDetails_' +
                  [j] +
                  '_income_0' +
                  '_amount',
                errors,
                formData,
                validationStep,
                clientsArray[d] + errorMessages['unearnedIncome']
              )
              requireField(
                'Application_clients_' +
                  [d] +
                  '_incometypeDetails_unearnedIncome_unearnedIncomeDetails_' +
                  [j] +
                  '_income_0' +
                  '_incomeFrequency',
                errors,
                formData,
                validationStep,
                clientsArray[d] +
                  errorMessages['unearnedIncomeDetails_incomeFrequency']
              )
            }
          }
        }
      }
    }
    //deductionsIncomeReported
    for (let z = 0; z < formData.Application.clients.length; z++) {
      if (_.includes(formData.Application.benefitCategorieslist, 'HEWL')) {
        let deductionDetailsArray =
          formData.Application.clients[z].deductionDetails

        if (
          formData.Application.clients[z].deductionsIncomeReported === undefined
        ) {
          requireField(
            'Application_clients_' + [z] + '_deductionsIncomeReported',
            errors,
            formData,
            validationStep,
            clientsArray[z] + errorMessages['deductionsIncomeReported']
          )
        }

        if (formData.Application.clients[z].deductionsIncomeReported === 'Y') {
          requireField(
            'Application_clients_' + [z] + '_deductions',
            errors,
            formData,
            validationStep,
            clientsArray[z] + errorMessages['clients_deductions']
          )

          for (let x = 0; x < deductionDetailsArray.length; x++) {
            if (
              formData.Application.clients[z].deductions &&
              formData.Application.clients[z].deductions.length > 0
            ) {
              // AppIntakeIncome_Application_clients_0_deductionDetails_2_paysForDeduction
              for (let selectedItem in formData.Application.clients[z]
                .deductions) {
                if (
                  formData.Application.clients[z].deductions[selectedItem] ===
                  deductionDetailsArray[x].paysForDeductionKey
                ) {
                  if (
                    deductionDetailsArray[x].paysForDeduction === undefined ||
                    deductionDetailsArray[x].paysForDeduction === ''
                  ) {
                    requireField(
                      'Application_clients_' +
                        [z] +
                        '_deductionDetails_' +
                        [x] +
                        '_paysForDeduction',
                      errors,
                      formData,
                      validationStep,
                      clientsArray[z] + errorMessages['paysForDeduction']
                    )
                  }
                  if (
                    deductionDetailsArray[x].howOftenPaidDeduction === undefined
                  ) {
                    requireField(
                      'Application_clients_' +
                        [z] +
                        '_deductionDetails_' +
                        [x] +
                        '_howOftenPaidDeduction',
                      errors,
                      formData,
                      validationStep,
                      clientsArray[z] + errorMessages['howOftenPaidDeduction']
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    //disability validation for health care and MPA
    for (let e = 0; e < formData.Application.clients.length; e++) {
      if (
        _.includes(formData.Application.benefitCategorieslist, 'HEWL') &&
        formData.Application.clients[e].disabledHousehold
      ) {
        if (
          formData.Application.clients[e].getDisabilityBenefit === undefined
        ) {
          requireField(
            'Application_clients_' + [e] + '_getDisabilityBenefit',
            errors,
            formData,
            validationStep,
            clientsArray[e] + errorMessages['clients_getDisabilityBenefit']
          )
        }

        if (
          formData.Application.clients[e].getDisabilityBenefit === 'Y' &&
          (formData.Application.clients[e].disabilityDate === undefined ||
            formData.Application.clients[e].disabilityDate === '')
        ) {
          // AppIntakeIncome_Application_clients_0_disabilityDate
          requireField(
            'Application_clients_' + [e] + '_disabilityDate',
            errors,
            formData,
            validationStep,
            clientsArray[e] + errorMessages['disabilityDate']
          )
        }

        if (formData.Application.clients[e].disabilityDate !== undefined) {
          expectBthDtLessCurrDt(
            'Application_clients_' + [e] + '_disabilityDate',
            errors,
            formData,
            validationStep,
            errorMessages['clients_disabilityDate_futuredate']
          )
        }
      }
    }
    //sprint 9 changes - End
    for (let k = 0; k < formData.Application.clients.length; k++) {
      let incomesFlag = true
      let data = formData.Application.clients[k].incometypeDetails
      if (
        _.includes(formData.Application.benefitCategorieslist, 'HEWL') ||
        formData.Application.clients[k].optBenefitMDPA ||
        (formData.Application.benefitCategorieslist.indexOf('MDPA') > -1 &&
          formData.Application.clients[k].relationship === '61') ||
        (k === 0 && spouseFlag) ||
        (formData.Application.clients[k].relationship === '61' &&
          formData.Application.clients[0].optBenefitMDPA)
      ) {
        if (formData.Application.clients[k].reportedIncome === 'Y') {
          if (
            data.earnedIncome.wagesCheckbox &&
            data.earnedIncome.wagesCheckbox.length > 0
          ) {
            incomesFlag = false
          }
          if (incomesFlag) {
            for (
              let l = 0;
              l < data.unearnedIncome.unearnedIncomeDetails.length;
              l++
            ) {
              if (data.unearnedIncome.unearnedIncomeDetails[l].hasIncome) {
                incomesFlag = false
                break
              }
            }
          }
          if (incomesFlag) {
            for (
              let m = 0;
              m < data.selfemploymentIncome.selfEmploymentIncomeDetails.length;
              m++
            ) {
              if (
                data.selfemploymentIncome.selfEmploymentIncomeDetails[m]
                  .hasIncome
              ) {
                incomesFlag = false
                break
              }
            }
          }
          if (incomesFlag) {
            const localData = _.cloneDeep(formData)
            localData.Application.clients[k].incometypeDetails = undefined
            requireField(
              'Application_clients_' + k + '_incometypeDetails',
              errors,
              localData,
              validationStep,
              clientsArray[k] + errorMessages['clients_incometypeDetails']
            )
          }
        }
      }
    }
  }

  // Additional Questions Validation
  if ((step === 11 || validateAll) && !isIncomplete) {
    validationStep = 11
    for (let i = 0; i < formData.Application.clients.length; i++) {
      let dob = formData.Application.clients[i].clientBthDt
      let birthdate = _moment(config, dob)
      let cur = _moment(config, currentDate)

      if (
        formData.Application.clients[i].optBenefitHEWL ||
        formData.Application.clients[i].optBenefitMDPA ||
        formData.Application.clients[i].optBenefitFDNT
      ) {
        if (
          formData.Application.clients[i].stateOtherThanWv &&
          formData.Application.clients[i].stateOtherThanWv === 'Y'
        ) {
          requireField(
            'Application_clients_' + i + '_benefits',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['clients_benefits']
          )
        }
        if (
          formData.Application.clients[i].benefits &&
          formData.Application.clients[i].benefits.length > 0
        ) {
          for (
            let z = 0;
            z < formData.Application.clients[i].benefits.length;
            z++
          ) {
            let benefitDetails = formData.Application.clients[i].benefits[z]
            let benefitSelected = benefitDetails - 1
            requireField(
              'Application_clients_' +
                i +
                '_benefitDetails_' +
                [benefitSelected] +
                '_whichStatePrograRece',
              errors,
              formData,
              validationStep,
              clientsArray[i] + errorMessages['whichStatePrograRece']
            )
          }
        }
      }

      if (formData.Application.clients[i].optBenefitFDNT) {
        // Code changes for US3059258
        requireField(
          'Application_clients_' + i + '_stateOtherThanWv',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['stateOtherThanWv']
        )
        if (cur.diff(birthdate, 'years') >= 16) {
          requireField(
            'Application_clients_' + i + '_traffickingOfBenefits',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['traffickingOfBenefits']
          )
        }
        if (cur.diff(birthdate, 'years') >= 18) {
          requireField(
            'Application_clients_' + i + '_exchSnapBeneForIllegal',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['exchSnapBeneForIllegal']
          )
          requireField(
            'Application_clients_' + i + '_lieMisrepIdentity',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['clients_lieMisrepIdentity']
          )
        }
      }

      if (
        formData.Application.clients[i].optBenefitFDNT ||
        _.includes(formData.Application.benefitCategorieslist, 'WVWORKS')
      ) {
        requireField(
          'Application_clients_' + i + '_victimOfAHmnTraffickingFl',
          errors,
          formData,
          validationStep,
          clientsArray[i] + errorMessages['clients_victimOfAHmnTraffickingFl']
        )
        if (cur.diff(birthdate, 'years') >= 18) {
          requireField(
            'Application_clients_' + i + '_tryToAvoidProsecJail',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['clients__tryToAvoidProsecJail']
          )
          requireField(
            'Application_clients_' + i + '_isViolatCondiOfProbation',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['clients_isViolatCondiOfProbation']
          )
          requireField(
            'Application_clients_' + i + '_isAdultFlny',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['clients_isAdultFlny']
          )
          if (formData.Application.clients[i].isAdultFlny === 'Y') {
            requireField(
              'Application_clients_' + i + '_isAdultComplnce',
              errors,
              formData,
              validationStep,
              clientsArray[i] + errorMessages['clients_isAdultComplnce']
            )
          }
        }
        if (cur.diff(birthdate, 'years') >= 16) {
          requireField(
            'Application_clients_' + i + '_convOfIndicOff',
            errors,
            formData,
            validationStep,
            clientsArray[i] + errorMessages['clients_convOfIndicOff']
          )
        }
      }
    }
    requireField(
      'Application_clients_' + 0 + '_householdRequestForADA',
      errors,
      formData,
      validationStep,
      clientsArray[0] + errorMessages['clients_householdRequestForADA']
    )
  }
  //Document Upload Validation
  if ((step === 12 || validateAll) && !isIncomplete) {
    validationStep = 12
  }

  //Review & Sign validation
  if (step === 13 || validateAll) {
    validationStep = 13
    if (!formData.Application.agreement) {
      formData.Application.agreement = undefined
    }
    requireField(
      'Application_agreement',
      errors,
      formData,
      validationStep,
      errorMessages['Application_agreement']
    )
    requireField(
      'Application_signatureReceived',
      errors,
      formData,
      validationStep,
      errorMessages['Application_signatureReceived']
    )
    if (!isIncomplete) {
      requireField(
        'Application_voterRegistration',
        errors,
        formData,
        validationStep,
        errorMessages['Application_voterRegistration']
      )
      requireField(
        'Application_clientDt',
        errors,
        formData,
        validationStep,
        'Date of Birth is required. '
      )
      /*if (formData.Application.clientDt !== undefined) {
        expectBthDtLessCurrDt(
          'Application_clientDt',
          errors,
          formData,
          validationStep,
          'Date of Birth is invalid.'
        )
      } */
      if (
        formData.Application.clientDt &&
        formData.Application.clientDt !== undefined
      ) {
        if (validBirthDateCheck(formData.Application.clientDt, currentDate)) {
          cocBthDtgreaterthanNinteenHundredandLessCurrDt(
            'Application_clientDt',
            errors,
            formData,
            validationStep,
            errorMessages['Application_clientDtfuturedate']
          )
        }
      }
    }
  }

  //Child Support
  if ((step === 8 || validateAll) && !isIncomplete) {
    validationStep = 8

    for (let i = 0; i < formData.Application.clientApplicant.length; ++i) {
      requireFieldCHSP(
        'Application_clientApplicant_' + i + '_roleOfApplicant',
        errors,
        formData,
        validationStep,
        errorMessages['clientApplicant_roleOfApplicant']
      )
      requireFieldCHSP(
        'Application_clientApplicant_' + i + '_childrenLiveWithYou',
        errors,
        formData,
        validationStep,
        errorMessages['clientApplicant_childrenLiveWithYou']
      )
      let clwyCheck =
        formData.Application.clientApplicant[0].childrenLiveWithYou
      if (clwyCheck === 'S') {
        requireFieldCHSP(
          'Application_clientApplicant_' + i + '_daysOfWeek',
          errors,
          formData,
          validationStep,
          errorMessages['clientApplicant_daysOfWeek']
        )
      }
      requireFieldCHSP(
        'Application_clientApplicant_' + i + '_recvngTMS',
        errors,
        formData,
        validationStep,
        errorMessages['clientApplicant_recvngTMS']
      )
      requireFieldCHSP(
        'Application_clientApplicant_' + i + '_supportOrder',
        errors,
        formData,
        validationStep,
        errorMessages['clientApplicant_supportOrder']
      )
      requireFieldCHSP(
        'Application_clientApplicant_' + i + '_courtHearing',
        errors,
        formData,
        validationStep,
        errorMessages['clientApplicant_courtHearing']
      )
      requireFieldCHSP(
        'Application_clientApplicant_' + i + '_safetyConcerns',
        errors,
        formData,
        validationStep,
        errorMessages['clientApplicant_safetyConcerns']
      )
      requireFieldCHSP(
        'Application_clientApplicant_' + i + '_caseReview',
        errors,
        formData,
        validationStep,
        errorMessages['clientApplicant_caseReview']
      )
      requireFieldCHSP(
        'Application_clientApplicant_' + i + '_relationship',
        errors,
        formData,
        validationStep,
        errorMessages['RelationshipRequired']
      )
    }
    for (let i = 0; i < formData.Application.clientsObligor.length; ++i) {
      if (formData.Application.clientApplicant[0].roleOfApplicant === 'Y') {
        requireFieldCHSP(
          'Application_clientsObligor_' + i + '_clientFrstNm',
          errors,
          formData,
          validationStep,
          errorMessages['clientsObligor_clientFirstNm']
        )
        requireFieldCHSP(
          'Application_clientsObligor_' + i + '_clientLastNm',
          errors,
          formData,
          validationStep,
          errorMessages['clientsObligor_clientLastNm']
        )

        // Code changes for GPRB0359133 and US2558381
        if (
          formData.Application.clientsObligor[i].clientDOB &&
          formData.Application.clientsObligor[i].clientDOB !== undefined
        ) {
          if (
            validBirthDateCheck(
              formData.Application.clientsObligor[i].clientDOB,
              currentDate
            )
          ) {
            expectBthDtgreaterthanNinteenHundredandLessCurrDt(
              'Application_clientsObligor_' + i + '_clientDOB',
              errors,
              formData,
              validationStep,
              errorMessages['BirthDatecannotbeprior']
            )
          }
        }

        compareAppSSN(
          'Application_clientsObligor_' + i + '_clientSSNConfir',
          'Application_clientsObligor_' + i + '_clientSSN',
          errors,
          formData,
          validationStep,
          errorMessages['SSNNotmatch']
        )
        // Code Changes GPRB0359223 and US2819645
        // Obligor SSN Validation
        if (
          formData.Application.clientsObligor[i].clientSSN &&
          formData.Application.clientsObligor[i].clientSSN !== undefined
        ) {
          if (!validSSN(formData.Application.clientsObligor[i].clientSSN))
            ssnValidation(
              'Application_clientsObligor_' + i + '_clientSSN',
              errors,
              formData,
              validationStep,
              errorMessages['NonCustodialclientsObligor__clientSSN']
            )
        }
        if (
          formData.Application.clientsObligor[i].clientSSNConfir &&
          formData.Application.clientsObligor[i].clientSSNConfir !== undefined
        ) {
          if (!validSSN(formData.Application.clientsObligor[i].clientSSNConfir))
            ssnValidation(
              'Application_clientsObligor_' + i + '_clientSSNConfir',
              errors,
              formData,
              validationStep,
              errorMessages['NonCustodialclientsObligor__clientSSN']
            )
        }

        if (
          submitClicked &&
          ((formData.Application.clients[0].optBenefitCHSP &&
            _.get(formData, `Application.clientsObligor[${i}].clientSSN`) !=
              undefined) ||
            _.get(formData, `Application.clientsObligor[${i}].clientSSN`) !=
              ' ')
        ) {
          validateUniqueSSN(
            'Application_clientsObligor_' + i + '_clientSSN',
            errors,
            validationStep,
            errorMessages['NonCustodialParentSocialSecurityNumberMsg'],
            errorMessages['numberunique'],
            _.get(formData, `Application.clientsObligor[${i}].clientSSN`),
            ssns
          )
        }

        compareAppEmail(
          'Application_clientsObligor_' + i + '_contactInfo_emails2',
          'Application_clientsObligor_' + i + '_contactInfo_emails1',
          errors,
          formData,
          validationStep,
          errorMessages['contactInfo_emails1notMatch']
        )
        // Code Changes GPRB0359078 and US2554056
        // Full Application - Child Support Obligor Address and Employer Address Zip Code and Zip ext validation
        if (formData.Application.clientsObligor[i].Address) {
          if (
            formData.Application.clientsObligor[i].Address.zip &&
            formData.Application.clientsObligor[i].Address.zip !== undefined
          ) {
            if (
              validZipCodeCheck(
                formData.Application.clientsObligor[i].Address.zip
              )
            ) {
              notRequiredZip(
                'Application_clientsObligor_' + i + '_Address_zip',
                errors,
                formData,
                validationStep,
                errorMessages['ZipCodefivedigits']
              )
            }
          }
          if (
            formData.Application.clientsObligor[i].Address.zipExt &&
            formData.Application.clientsObligor[i].Address.zipExt !== undefined
          ) {
            if (
              validZipExtCheck(
                formData.Application.clientsObligor[i].Address.zipExt
              )
            ) {
              notRequiredZipExt(
                'Application_clientsObligor_' + i + '_Address_zipExt',
                errors,
                formData,
                validationStep,
                errorMessages['Address_zipExtfourdigits']
              )
            }
          }
        }
        if (
          formData.Application.clientsObligor[i].obligorEmployed &&
          formData.Application.clientsObligor[i].obligorEmployed.Address
        ) {
          if (
            formData.Application.clientsObligor[i].obligorEmployed.Address
              .zip &&
            formData.Application.clientsObligor[i].obligorEmployed.Address
              .zip !== undefined
          ) {
            if (
              validZipCodeCheck(
                formData.Application.clientsObligor[i].obligorEmployed.Address
                  .zip
              )
            ) {
              notRequiredZip(
                'Application_clientsObligor_' +
                  i +
                  '_obligorEmployed_Address_zip',
                errors,
                formData,
                validationStep,
                errorMessages['ZipCodefivedigits']
              )
            }
          }
          if (
            formData.Application.clientsObligor[i].obligorEmployed.Address
              .zipExt &&
            formData.Application.clientsObligor[i].obligorEmployed.Address
              .zipExt !== undefined
          ) {
            if (
              validZipExtCheck(
                formData.Application.clientsObligor[i].obligorEmployed.Address
                  .zipExt
              )
            ) {
              notRequiredZipExt(
                'Application_clientsObligor_' +
                  i +
                  '_obligorEmployed_Address_zipExt',
                errors,
                formData,
                validationStep,
                errorMessages['Address_zipExtfourdigits']
              )
            }
          }
        }
      }
      if (
        formData.Application.clientsObligor[i].obligorLicenseOrID
          .licenseOrID === 'Y'
      ) {
        requireFieldCHSP(
          'Application_clientsObligor_' + i + '_obligorLicenseOrID_stat',
          errors,
          formData,
          validationStep,
          errorMessages['clientsObligor_obligorLicenseOrID_stat']
        )
      }
      if (formData.Application.clientsObligor[i].obligorIncome.income === 'Y') {
        requireFieldCHSP(
          'Application_clientsObligor_' +
            i +
            '_obligorIncome_obligorDescription',
          errors,
          formData,
          validationStep,
          errorMessages['obligorIncome_obligorDescription']
        )
      }
      if (
        formData.Application.clientsObligor[i].obligorIncarcerated
          .obligorIncarcerate === 'Y'
      ) {
        requireFieldCHSP(
          'Application_clientsObligor_' +
            i +
            '_obligorIncarcerated_obligorIncarcerateType',
          errors,
          formData,
          validationStep,
          errorMessages['obligorIncarcerated_obligorIncarcerateType']
        )
      }
    }
    for (let i = 0; i < formData.Application.clientsObligor.length; ++i) {
      if (formData.Application.clientApplicant[0].roleOfApplicant === 'N') {
        requireFieldCHSP(
          'Application_clientsObligee_' + i + '_clientFirstNm',
          errors,
          formData,
          validationStep,
          errorMessages['clientsObligee_clientFirstNm']
        )
        requireFieldCHSP(
          'Application_clientsObligee_' + i + '_clientLastNm',
          errors,
          formData,
          validationStep,
          errorMessages['clientsObligee_clientLastNm']
        )
        // Code changes for GPRB0359133 and US2558381
        if (
          formData.Application.clientsObligee[i].clientDOB &&
          formData.Application.clientsObligee[i].clientDOB !== undefined
        ) {
          if (
            validBirthDateCheck(
              formData.Application.clientsObligee[i].clientDOB,
              currentDate
            )
          ) {
            expectBthDtgreaterthanNinteenHundredandLessCurrDt(
              'Application_clientsObligee_' + i + '_clientDOB',
              errors,
              formData,
              validationStep,
              errorMessages['BirthDatecannotbeprior']
            )
          }
        }
        compareAppSSN(
          'Application_clientsObligee_' + i + '_clientSSNConfir',
          'Application_clientsObligee_' + i + '_clientSSN',
          errors,
          formData,
          validationStep,
          errorMessages['SSNNotmatch']
        )
        requireFieldCHSP(
          'Application_clientsObligee_' + i + '_address_st',
          errors,
          formData,
          validationStep,
          errorMessages['clientsObligee_address_st']
        )
        // Code Changes GPRB0359223 and US2819645
        // Obligee SSN Validation
        if (
          formData.Application.clientsObligee[i].clientSSN &&
          formData.Application.clientsObligee[i].clientSSN !== undefined
        ) {
          if (!validSSN(formData.Application.clientsObligee[i].clientSSN))
            ssnValidation(
              'Application_clientsObligee_' + i + '_clientSSN',
              errors,
              formData,
              validationStep,
              errorMessages['clientsObligee_clientSSN']
            )
        }
        if (
          formData.Application.clientsObligee[i].clientSSNConfir &&
          formData.Application.clientsObligee[i].clientSSNConfir !== undefined
        ) {
          if (!validSSN(formData.Application.clientsObligee[i].clientSSNConfir))
            ssnValidation(
              'Application_clientsObligee_' + i + '_clientSSNConfir',
              errors,
              formData,
              validationStep,
              errorMessages['clientsObligee_clientSSN']
            )
        }

        if (
          submitClicked &&
          ((formData.Application.clients[0].optBenefitCHSP &&
            _.get(formData, `Application.clientsObligee[${i}].clientSSN`) !=
              undefined) ||
            _.get(formData, `Application.clientsObligee[${i}].clientSSN`) !=
              ' ')
        ) {
          validateUniqueSSN(
            'Application_clientsObligee_' + i + '_clientSSN',
            errors,
            validationStep,
            errorMessages['clientsObligee_CustodialCaretaker'],
            errorMessages['numberunique'],
            _.get(formData, `Application.clientsObligee[${i}].clientSSN`),
            ssns
          )
        }
        // Code Changes GPRB0359078 and US2554056
        // Full Application - Child Support Obligee Address and Employer Address Zip Code and Zip ext validation
        if (formData.Application.clientsObligee[i].Address) {
          if (
            formData.Application.clientsObligee[i].Address.zip &&
            formData.Application.clientsObligee[i].Address.zip !== undefined
          ) {
            if (
              validZipCodeCheck(
                formData.Application.clientsObligee[i].Address.zip
              )
            ) {
              notRequiredZip(
                'Application_clientsObligee_' + i + '_Address_zip',
                errors,
                formData,
                validationStep,
                errorMessages['ZipCodefivedigits']
              )
            }
          }
          if (
            formData.Application.clientsObligee[i].Address.zipExt &&
            formData.Application.clientsObligee[i].Address.zipExt !== undefined
          ) {
            if (
              validZipExtCheck(
                formData.Application.clientsObligee[i].Address.zipExt
              )
            ) {
              notRequiredZipExt(
                'Application_clientsObligee_' + i + '_Address_zipExt',
                errors,
                formData,
                validationStep,
                errorMessages['Address_zipExtfourdigits']
              )
            }
          }
        }
        if (
          formData.Application.clientsObligee[i].obligeeEmployed &&
          formData.Application.clientsObligee[i].obligeeEmployed.Address
        ) {
          if (
            formData.Application.clientsObligee[i].obligeeEmployed.Address
              .zip &&
            formData.Application.clientsObligee[i].obligeeEmployed.Address
              .zip !== undefined
          ) {
            if (
              validZipCodeCheck(
                formData.Application.clientsObligee[i].obligeeEmployed.Address
                  .zip
              )
            ) {
              notRequiredZip(
                'Application_clientsObligee_' +
                  i +
                  '_obligeeEmployed_Address_zip',
                errors,
                formData,
                validationStep,
                errorMessages['ZipCodefivedigits']
              )
            }
          }
          if (
            formData.Application.clientsObligee[i].obligeeEmployed.Address
              .zipExt &&
            formData.Application.clientsObligee[i].obligeeEmployed.Address
              .zipExt !== undefined
          ) {
            if (
              validZipExtCheck(
                formData.Application.clientsObligee[i].obligeeEmployed.Address
                  .zipExt
              )
            ) {
              notRequiredZipExt(
                'Application_clientsObligee_' +
                  i +
                  '_obligeeEmployed_Address_zipExt',
                errors,
                formData,
                validationStep,
                errorMessages['Address_zipExtfourdigits']
              )
            }
          }
        }
      }
    }

    for (let i = 1; i < formData.Application.clients.length; i++) {
      let dob = formData.Application.clients[i].clientBthDt
      let birthdate = _moment(config, dob)
      let cur = _moment(config, currentDate)
      // TO_DO remove since there is no age check - GPRB0359584 - US3185134
      if (
        formData.Application.clients[i].optBenefitCHSP
        //&& cur.diff(birthdate, 'years') < 18
      ) {
        if (
          formData.Application.clients[i].obligeeRelationship == undefined ||
          formData.Application.clients[i].obligeeRelationship == ''
        ) {
          requireFieldCHSP(
            'Application_clients_' + i + '_obligeeRelationship',
            errors,
            formData,
            validationStep,
            errorMessages['Obligeerelationship']
          )
        }
        if (
          formData.Application.clients[i].obligorRelationship == undefined ||
          formData.Application.clients[i].obligorRelationship == ''
        ) {
          requireFieldCHSP(
            'Application_clients_' + i + '_obligorRelationship',
            errors,
            formData,
            validationStep,
            errorMessages['Obligeerelationship']
          )
        }

        if (
          formData.Application.clients[i].receiveSSI == undefined ||
          formData.Application.clients[i].receiveSSI == ''
        ) {
          requireFieldCHSP(
            'Application_clients_' + i + '_receiveSSI',
            errors,
            formData,
            validationStep,
            errorMessages['clients_receiveSSI']
          )
        }
        if (
          formData.Application.clients[i].paternityEstablished == undefined ||
          formData.Application.clients[i].paternityEstablished == ''
        ) {
          requireFieldCHSP(
            'Application_clients_' + i + '_paternityEstablished',
            errors,
            formData,
            validationStep,
            errorMessages['clients_paternityEstablished']
          )
        }
        if (
          formData.Application.clients[i].parentsMarried == undefined ||
          formData.Application.clients[i].parentsMarried == ''
        ) {
          requireFieldCHSP(
            'Application_clients_' + i + '_parentsMarried',
            errors,
            formData,
            validationStep,
            errorMessages['clients_parentsMarried']
          )
        }
        if (
          formData.Application.clients[i].parentsName == undefined ||
          formData.Application.clients[i].parentsName == ''
        ) {
          requireFieldCHSP(
            'Application_clients_' + i + '_parentsName',
            errors,
            formData,
            validationStep,
            errorMessages['clients_parentsName']
          )
        }
        if (
          formData.Application.clients[i].copyOfBC == undefined ||
          formData.Application.clients[i].copyOfBC == ''
        ) {
          requireFieldCHSP(
            'Application_clients_' + i + '_copyOfBC',
            errors,
            formData,
            validationStep,
            errorMessages['Application_clients__copyOfBC']
          )
        }
      }
    }
    // Code Changes GPRB0359078 and US2554056
    // Full Application - Child Support Grandparents Obligor and Obligee address Zip Code and Zip Ext validation
    for (let i = 0; i < formData.Application.clientsGrandParents.length; ++i) {
      const applicantName =
        formData.Application.clients[0].clientFstNm +
        ' ' +
        formData.Application.clients[0].clientLstNm
      if (formData.Application.clientsGrandParents[i].obligeeParents) {
        for (
          let j = 0;
          j < formData.Application.clientsGrandParents[i].obligeeParents.length;
          j++
        ) {
          if (
            formData.Application.clientsGrandParents[i].obligeeParents[j]
              .zipcode &&
            formData.Application.clientsGrandParents[i].obligeeParents[j]
              .zipcode !== undefined
          ) {
            if (
              validZipCodeCheck(
                formData.Application.clientsGrandParents[i].obligeeParents[j]
                  .zipcode
              )
            ) {
              notRequiredZip(
                'Application_clientsGrandParents_' +
                  i +
                  '_obligeeParents_' +
                  j +
                  '_zipcode',
                errors,
                formData,
                validationStep,
                errorMessages['ZipCodefivedigits']
              )
            }
          }
          if (
            formData.Application.clientsGrandParents[i].obligeeParents[j]
              .zipExt &&
            formData.Application.clientsGrandParents[i].obligeeParents[j]
              .zipExt !== undefined
          ) {
            if (
              validZipExtCheck(
                formData.Application.clientsGrandParents[i].obligeeParents[j]
                  .zipExt
              )
            ) {
              notRequiredZipExt(
                'Application_clientsGrandParents_' +
                  i +
                  '_obligeeParents_' +
                  j +
                  '_zipExt',
                errors,
                formData,
                validationStep,
                errorMessages['Address_zipExtfourdigits']
              )
            }
          }
          const obligeeParents =
            formData.Application.clientsGrandParents[i].obligeeParents[j]
          if (
            validFirstNameLastNameForGrandParentes(obligeeParents) &&
            validGPRelationCheck(obligeeParents.grandParentsRelation)
          ) {
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligeeParents_' +
                j +
                '_grandParentsRelation',
              errors,
              formData,
              validationStep,
              applicantName +
                errorMessages['obligeeParents_grandParentsRelation']
            )
          } else if (
            !validFirstNameLastNameForGrandParentes(obligeeParents) &&
            !validGPRelationCheck(obligeeParents.grandParentsRelation)
          ) {
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligeeParents_' +
                j +
                '_clientFstNm',
              errors,
              formData,
              validationStep,
              applicantName + errorMessages['obligeeParents_clientFstNm']
            )
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligeeParents_' +
                j +
                '_clientLstNm',
              errors,
              formData,
              validationStep,
              applicantName + errorMessages['obligeeParents_clientFstNm']
            )
          } else if (
            validEnteredValueForGrandParentes(obligeeParents) &&
            !validFirstNameLastNameForGrandParentes(obligeeParents) &&
            validGPRelationCheck(obligeeParents.grandParentsRelation)
          ) {
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligeeParents_' +
                j +
                '_clientFstNm',
              errors,
              formData,
              validationStep,
              applicantName + errorMessages['obligeeParents_clientFstNm']
            )
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligeeParents_' +
                j +
                '_clientLstNm',
              errors,
              formData,
              validationStep,
              applicantName + errorMessages['obligeeParents_clientFstNm']
            )
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligeeParents_' +
                j +
                '_grandParentsRelation',
              errors,
              formData,
              validationStep,
              applicantName +
                errorMessages['obligeeParents_grandParentsRelation']
            )
          } else if (
            validEnteredValueForGrandParentes(obligeeParents) &&
            !validFirstNameLastNameForGrandParentes(obligeeParents) &&
            !validGPRelationCheck(obligeeParents.grandParentsRelation)
          ) {
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligeeParents_' +
                j +
                '_clientFstNm',
              errors,
              formData,
              validationStep,
              applicantName +
                errorMessages['obligeeParents_clientFstNmANDLastName']
            )
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligeeParents_' +
                j +
                '_clientLstNm',
              errors,
              formData,
              validationStep,
              applicantName +
                errorMessages['obligeeParents_clientFstNmANDLastName']
            )
          }
        }
      }
      if (formData.Application.clientsGrandParents[i].obligorParents) {
        for (
          let j = 0;
          j < formData.Application.clientsGrandParents[i].obligorParents.length;
          j++
        ) {
          if (
            formData.Application.clientsGrandParents[i].obligorParents[j]
              .zipcode &&
            formData.Application.clientsGrandParents[i].obligorParents[j]
              .zipcode !== undefined
          ) {
            if (
              validZipCodeCheck(
                formData.Application.clientsGrandParents[i].obligorParents[j]
                  .zipcode
              )
            ) {
              notRequiredZip(
                'Application_clientsGrandParents_' +
                  i +
                  '_obligorParents_' +
                  j +
                  '_zipcode',
                errors,
                formData,
                validationStep,
                errorMessages['ZipCodefivedigits']
              )
            }
          }
          if (
            formData.Application.clientsGrandParents[i].obligorParents[j]
              .zipExt &&
            formData.Application.clientsGrandParents[i].obligorParents[j]
              .zipExt !== undefined
          ) {
            if (
              validZipExtCheck(
                formData.Application.clientsGrandParents[i].obligorParents[j]
                  .zipExt
              )
            ) {
              notRequiredZipExt(
                'Application_clientsGrandParents_' +
                  i +
                  '_obligorParents_' +
                  j +
                  '_zipExt',
                errors,
                formData,
                validationStep,
                errorMessages['Address_zipExtfourdigits']
              )
            }
          }
          const obligorParents =
            formData.Application.clientsGrandParents[i].obligorParents[j]
          if (
            validFirstNameLastNameForGrandParentes(obligorParents) &&
            validGPRelationCheck(obligorParents.grandParentsRelation)
          ) {
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligorParents_' +
                j +
                '_grandParentsRelation',
              errors,
              formData,
              validationStep,
              applicantName +
                errorMessages['obligeeParents_grandParentsRelation']
            )
          } else if (
            !validFirstNameLastNameForGrandParentes(obligorParents) &&
            !validGPRelationCheck(obligorParents.grandParentsRelation)
          ) {
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligorParents_' +
                j +
                '_clientFstNm',
              errors,
              formData,
              validationStep,
              applicantName + errorMessages['obligeeParents_clientFstNm']
            )
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligorParents_' +
                j +
                '_clientLstNm',
              errors,
              formData,
              validationStep,
              applicantName + errorMessages['obligeeParents_clientFstNm']
            )
          } else if (
            validEnteredValueForGrandParentes(obligorParents) &&
            !validFirstNameLastNameForGrandParentes(obligorParents) &&
            validGPRelationCheck(obligorParents.grandParentsRelation)
          ) {
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligorParents_' +
                j +
                '_clientFstNm',
              errors,
              formData,
              validationStep,
              applicantName + errorMessages['obligeeParents_clientFstNm']
            )
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligorParents_' +
                j +
                '_clientLstNm',
              errors,
              formData,
              validationStep,
              applicantName + errorMessages['obligeeParents_clientFstNm']
            )
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligorParents_' +
                j +
                '_grandParentsRelation',
              errors,
              formData,
              validationStep,
              applicantName +
                errorMessages['obligeeParents_grandParentsRelation']
            )
          } else if (
            validEnteredValueForGrandParentes(obligorParents) &&
            !validFirstNameLastNameForGrandParentes(obligorParents) &&
            !validGPRelationCheck(obligorParents.grandParentsRelation)
          ) {
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligorParents_' +
                j +
                '_clientFstNm',
              errors,
              formData,
              validationStep,
              applicantName +
                errorMessages['obligeeParents_clientFstNmANDLastName']
            )
            notRequiredRelationshipForGPs(
              'Application_clientsGrandParents_' +
                i +
                '_obligorParents_' +
                j +
                '_clientLstNm',
              errors,
              formData,
              validationStep,
              applicantName +
                errorMessages['obligeeParents_clientFstNmANDLastName']
            )
          }
        }
      }
    }
    if (
      _.includes(formData.Application.benefitCategorieslist, 'CHSP') &&
      formData.Application.childSupportEnforcement[0]
        .csEnforcementServicesApplication === undefined
    ) {
      requireField(
        'Application_childSupportEnforcement_0_csEnforcementServicesApplication',
        errors,
        formData,
        validationStep,
        errorMessages['csEnforcementServicesApplication']
      )
    }
  }
  return errors
}

export function validateAPForm(formData, errors, step) {
  const validateAll = step == null
  let validationStep = null

  // Program Request
  if (step === 2 || validateAll) {
    validationStep = 2
    validateAPProgramRequestInfo(formData, errors, validationStep)
  }

  // Program Request
  if (step === 8 || validateAll) {
    validationStep = 8
    validateAPReviewAndSubmit(formData, errors, validationStep)
  }

  return errors
}

function validateAPProgramRequestInfo(formData, errors, validationStep) {
  // check if requestDate is filled-in for the Healthcare program if client is selected.
  const programDetails =
    formData.application.programRequestDetails.programDetails
  const programDetailsPath = 'application_programRequestDetails_programDetails_'
  for (let i = 0; i < programDetails.healthcare.length; ++i) {
    const cbr = programDetails.healthcare[i]
    if (cbr.isClientSelected) {
      requireField(
        programDetailsPath + 'healthcare_' + i + '_requestDate',
        errors,
        formData,
        validationStep,
        'Request Date is required as ' +
          cbr.clientName +
          ' has opted for Healthcare program.'
      )
    }
  }

  // check if requestDate is filled-in for the LIHEAP program if client is selected.
  // check if group number is filled-in for the LIHEAP program if client is selected.
  const selGroupNumbers = []
  for (let i = 0; i < programDetails.liheap.length; ++i) {
    const cbr = programDetails.liheap[i]
    if (cbr.isClientSelected) {
      requireField(
        programDetailsPath + 'liheap_' + i + '_clientBenefitGroupNumber',
        errors,
        formData,
        validationStep,
        'Expense Sharing Group is required as ' +
          cbr.clientName +
          ' has opted for LIHEAP program.'
      )
      requireField(
        programDetailsPath + 'liheap_' + i + '_requestDate',
        errors,
        formData,
        validationStep,
        'Request Date is required as ' +
          cbr.clientName +
          ' has opted for LIHEAP program.'
      )
      const index = i
      const groupNumber = getNodeValue(
        programDetailsPath + 'liheap_' + i + '_clientBenefitGroupNumber',
        formData
      )
      const clientName = cbr.clientName
      selGroupNumbers.push({ index, groupNumber, clientName })
    }
  }

  // check if select group number is valid
  for (const selGroupNumber of selGroupNumbers) {
    if (selGroupNumber && selGroupNumber.groupNumber > selGroupNumbers.length) {
      addErrorToNode(
        programDetailsPath +
          'liheap_' +
          selGroupNumber.index +
          '_clientBenefitGroupNumber',
        errors,
        validationStep,
        'Select expense sharing group no more than ' + selGroupNumbers.length,
        'Please reselect appropriate Expense Group Number from the list for ' +
          selGroupNumber.clientName +
          '. It cannot be more than number of applicants opted for LIHEAP.'
      )
    }
  }

  // check if requestDate is filled-in for the SNAP program if client is selected.
  for (let i = 0; i < programDetails.snap.length; ++i) {
    const cbr = programDetails.snap[i]
    if (cbr.isClientSelected) {
      requireField(
        programDetailsPath + 'snap_' + i + '_requestDate',
        errors,
        formData,
        validationStep,
        'Request Date is required as ' +
          cbr.clientName +
          ' has opted for SNAP program.'
      )
    }
  }

  // check if requestDate is filled-in for the TANF program if client is selected.
  for (let i = 0; i < programDetails.tanf.length; ++i) {
    const cbr = programDetails.tanf[i]
    if (cbr.isClientSelected) {
      requireField(
        programDetailsPath + 'tanf_' + i + '_requestDate',
        errors,
        formData,
        validationStep,
        'Request Date is required as ' +
          cbr.clientName +
          ' has opted for TANF program.'
      )
    }
  }

  // check if relationship details are filled-in
  const clientRelationships =
    formData.application.programRequestDetails.relationshipDetails
      .clientRelationships
  const clientRelationshipsPath =
    'application_programRequestDetails_relationshipDetails_'
  for (let i = 0; i < clientRelationships.length; ++i) {
    const clientRelationship = clientRelationships[i]
    requireField(
      clientRelationshipsPath +
        'clientRelationships_' +
        i +
        '_relationshipCode',
      errors,
      formData,
      validationStep,
      'Relationship Details has to be filled-in for ' +
        clientRelationship.clientName +
        ' and ' +
        clientRelationship.clientRelationName
    )
    // TODO: check if we can avoid reporting same error again, if there are multiple occurences.
  }

  return errors
}

function validateAPReviewAndSubmit(formData, errors, validationStep) {
  // Check if required personal info is entered for each Authorized Representative
  const reviewAndSubmitDetails = formData.application.reviewAndSubmitDetails
  const reviewAndSubmitDetailsPath = 'application_reviewAndSubmitDetails_'
  if (reviewAndSubmitDetails.authorizedRepresentative) {
    for (
      let i = 0;
      i < reviewAndSubmitDetails.authorizedRepresentative.length;
      ++i
    ) {
      const rep = reviewAndSubmitDetails.authorizedRepresentative[i]
      requireField(
        reviewAndSubmitDetailsPath +
          'authorizedRepresentative_' +
          i +
          '_firstName',
        errors,
        formData,
        validationStep,
        'Must provide First Name for Authorized Representative ' + (i + 1)
      )
      requireField(
        reviewAndSubmitDetailsPath +
          'authorizedRepresentative_' +
          i +
          '_lastName',
        errors,
        formData,
        validationStep,
        'Must provide Last Name for Authorized Representative ' + (i + 1)
      )
      if (rep.hasAddress === 'Yes') {
        requireField(
          reviewAndSubmitDetailsPath +
            'authorizedRepresentative_' +
            i +
            '_address_addressLine1',
          errors,
          formData,
          validationStep,
          'Must provide Address for Authorized Representative ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath +
            'authorizedRepresentative_' +
            i +
            '_address_city',
          errors,
          formData,
          validationStep,
          'Must provide City for Authorized Representative ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath +
            'authorizedRepresentative_' +
            i +
            '_address_state',
          errors,
          formData,
          validationStep,
          'Must provide State for Authorized Representative ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath +
            'authorizedRepresentative_' +
            i +
            '_address_zip',
          errors,
          formData,
          validationStep,
          'Must provide Zip/Postal Code for Authorized Representative ' +
            (i + 1)
        )
      }
    }
  }

  // Check if required personal info is entered for each Assistor
  if (reviewAndSubmitDetails.assistor) {
    for (let i = 0; i < reviewAndSubmitDetails.assistor.length; ++i) {
      const rep = reviewAndSubmitDetails.assistor[i]
      requireField(
        reviewAndSubmitDetailsPath + 'assistor_' + i + '_firstName',
        errors,
        formData,
        validationStep,
        'Must provide First Name for Assistor ' + (i + 1)
      )
      requireField(
        reviewAndSubmitDetailsPath + 'assistor_' + i + '_lastName',
        errors,
        formData,
        validationStep,
        'Must provide Last Name for Assistor ' + (i + 1)
      )
      if (rep.hasAddress === 'Yes') {
        requireField(
          reviewAndSubmitDetailsPath +
            'assistor_' +
            i +
            '_address_addressLine1',
          errors,
          formData,
          validationStep,
          'Must provide Address for Assistor ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath + 'assistor_' + i + '_address_city',
          errors,
          formData,
          validationStep,
          'Must provide City for Assistor ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath + 'assistor_' + i + '_address_state',
          errors,
          formData,
          validationStep,
          'Must provide State for Assistor ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath + 'assistor_' + i + '_address_zip',
          errors,
          formData,
          validationStep,
          'Must provide Zip/Postal Code for Assistor ' + (i + 1)
        )
      }
    }
  }

  // Check if required personal info is entered for each Payor
  if (reviewAndSubmitDetails.payor) {
    for (let i = 0; i < reviewAndSubmitDetails.payor.length; ++i) {
      const rep = reviewAndSubmitDetails.payor[i]
      requireField(
        reviewAndSubmitDetailsPath + 'payor_' + i + '_firstName',
        errors,
        formData,
        validationStep,
        'Must provide First Name for Payor ' + (i + 1)
      )
      requireField(
        reviewAndSubmitDetailsPath + 'payor_' + i + '_lastName',
        errors,
        formData,
        validationStep,
        'Must provide Last Name for Payor ' + (i + 1)
      )
      if (rep.hasAddress === 'Yes') {
        requireField(
          reviewAndSubmitDetailsPath + 'payor_' + i + '_address_addressLine1',
          errors,
          formData,
          validationStep,
          'Must provide Address for Payor ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath + 'payor_' + i + '_address_city',
          errors,
          formData,
          validationStep,
          'Must provide City for Payor ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath + 'payor_' + i + '_address_state',
          errors,
          formData,
          validationStep,
          'Must provide State for Payor ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath + 'payor_' + i + '_address_zip',
          errors,
          formData,
          validationStep,
          'Must provide Zip/Postal Code for Payor ' + (i + 1)
        )
      }
    }
  }

  // Check if required personal info is entered for each Social Worker
  if (reviewAndSubmitDetails.socialWorker) {
    for (let i = 0; i < reviewAndSubmitDetails.socialWorker.length; ++i) {
      const rep = reviewAndSubmitDetails.socialWorker[i]
      requireField(
        reviewAndSubmitDetailsPath + 'socialWorker_' + i + '_firstName',
        errors,
        formData,
        validationStep,
        'Must provide First Name for Social Worker ' + (i + 1)
      )
      requireField(
        reviewAndSubmitDetailsPath + 'socialWorker_' + i + '_lastName',
        errors,
        formData,
        validationStep,
        'Must provide Last Name for Social Worker ' + (i + 1)
      )
      if (rep.hasAddress === 'Yes') {
        requireField(
          reviewAndSubmitDetailsPath +
            'socialWorker_' +
            i +
            '_address_addressLine1',
          errors,
          formData,
          validationStep,
          'Must provide Address for Social Worker ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath + 'socialWorker_' + i + '_address_city',
          errors,
          formData,
          validationStep,
          'Must provide City for Social Worker ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath + 'socialWorker_' + i + '_address_state',
          errors,
          formData,
          validationStep,
          'Must provide State for Social Worker ' + (i + 1)
        )
        requireField(
          reviewAndSubmitDetailsPath + 'socialWorker_' + i + '_address_zip',
          errors,
          formData,
          validationStep,
          'Must provide Zip/Postal Code for Social Worker ' + (i + 1)
        )
      }
    }
  }

  // Check required info for application confirmation section
  if (reviewAndSubmitDetails.reviewAndSign) {
    for (let i = 0; i < reviewAndSubmitDetails.reviewAndSign.length; ++i) {
      const applicant = reviewAndSubmitDetails.reviewAndSign[i]
      if (
        applicant.isPrimaryClient ||
        (applicant.signatureReceived && applicant.signatureReceived === 'Yes')
      ) {
        requireField(
          reviewAndSubmitDetailsPath +
            'reviewAndSign_' +
            i +
            '_dateOfSignature',
          errors,
          formData,
          validationStep,
          'Must provide Date of Signature for ' + applicant.clientName
        )
      }
    }
  }
}

export function validate_renewal(
  formData,
  errors,
  step,
  allPrograms,
  prgNames,
  isRenewal,
  currentDate,
  errorMessage
) {
  let applyingFrom = _.get(formData, 'application.APPLYFROM.applyingFrom', {})
  if (applyingFrom === '10') {
    requireField(
      'application_APPLYFROM_specifyOthers',
      errors,
      formData,
      step,
      errorMessage['APPLYFROM_specifyOthers']
    )
  }
  let someoneAsstYouFlg = _.get(formData, 'application.someoneAsstYouFlg', '')
  if (someoneAsstYouFlg === 'N') {
    requireField(
      'application_authorizedRepresentative_assisterFstNm',
      errors,
      formData,
      step,
      errorMessage['authorizedRepresentative_assisterFstNm']
    )
    requireField(
      'application_authorizedRepresentative_assisterLstNm',
      errors,
      formData,
      step,
      errorMessage['authorizedRepresentative_assisterLstNm']
    )
    requireField(
      'application_authorizedRepresentative_assisterAddress_adrLn3Txt',
      errors,
      formData,
      step,
      errorMessage['authorizedRepresentative_assisterAddress_adrLn1Txt']
    )
    requireField(
      'application_authorizedRepresentative_assisterAddress_ctyNm',
      errors,
      formData,
      step,
      errorMessage['authorizedRepresentative_assisterAddress_ctyNm']
    )
    requireField(
      'application_authorizedRepresentative_assisterAddress_st',
      errors,
      formData,
      step,
      errorMessage['authorizedRepresentative_assisterAddress_st']
    )
    requireField(
      'application_authorizedRepresentative_assisterAddress_zip',
      errors,
      formData,
      step,
      errorMessage['authorizedRepresentative_assisterAddress_zip']
    )
    if (
      _.get(
        formData,
        'application.authorizedRepresentative.assisterRoleCd',
        {}
      ) === '3'
    ) {
      requireField(
        'application_authorizedRepresentative_noticeQuestionForRepresentativeOrLegalGuardian',
        errors,
        formData,
        step,
        errorMessage[
          'authorizedRepresentative_noticeQuestionForRepresentativeOrLegalGuardian'
        ]
      )
    }
    if (
      _.get(
        formData,
        'application.authorizedRepresentative.assisterRoleCd',
        {}
      ) === '7'
    )
      requireField(
        'application_authorizedRepresentative_doYouLiveInHousehold',
        errors,
        formData,
        step,
        errorMessage['authorizedRepresentative_doYouLiveInHousehold']
      )
    // Code Changes GPRB0359078 and US2554056
    // COC and Renewal - Assister Address Zip Code validation
    let assisterAddress = _.get(
      formData,
      'application.authorizedRepresentative.assisterAddress',
      {}
    )

    if (assisterAddress) {
      if (assisterAddress.zip && assisterAddress.zip !== undefined)
        if (validZipCodeCheck(assisterAddress.zip)) {
          notRequiredZip(
            'application_authorizedRepresentative_assisterAddress_zip',
            errors,
            formData,
            step,
            errorMessage[
              'authorizedRepresentative_assisterAddress_zipFiveDigit'
            ]
          )
        }
    }
  }

  if (
    formData.application.EBT.ebtQue == undefined ||
    _.trim(formData.application.EBT.ebtQue) == ''
  ) {
    requireField(
      'application_EBT_ebtQue',
      errors,
      formData,
      step,
      errorMessage['EBT_ebtQue']
    )
  }

  if (formData.application.EBT.ebtQue == 'Y') {
    if (
      formData.application.EBT.ebtCardHolder.ebtCardFstNm == undefined ||
      _.trim(formData.application.EBT.ebtCardHolder.ebtCardFstNm) == ''
    ) {
      requireField(
        'application_EBT_ebtCardHolder_ebtCardFstNm',
        errors,
        formData,
        step,
        errorMessage['EBT_ebtCardHolder_ebtCardFstNm']
      )
    }
    if (
      formData.application.EBT.ebtCardHolder.ebtCardLstNm == undefined ||
      _.trim(formData.application.EBT.ebtCardHolder.ebtCardLstNm) == ''
    ) {
      requireField(
        'application_EBT_ebtCardHolder_ebtCardLstNm',
        errors,
        formData,
        step,
        errorMessage['EBT_ebtCardHolder_ebtCardLstNm']
      )
    }
  }

  let validProgram = false
  for (let i = 0; i < allPrograms.length; i++) {
    let currentProgram = allPrograms[i]
    if (
      isRenewal &&
      prgNames &&
      (currentProgram == 'food & nutrition (snap)' ||
        currentProgram == 'SNAP' ||
        currentProgram == 'FDNT' ||
        prgNames.search('SNAP') > 0 ||
        currentProgram == 'Medicare Premium Assistance' ||
        currentProgram == 'SLMB' ||
        currentProgram == 'QMB' ||
        prgNames.search('QMB') > 0 ||
        prgNames.search('Medicare Premium Assistance') > 0 ||
        currentProgram === 'Health Care Benefits' ||
        prgNames.search('Health Care Benefits') > 0 ||
        currentProgram == 'child care services' ||
        currentProgram == 'CHCR' ||
        prgNames.search('CHCR') > 0 ||
        prgNames.search('child care services') > 0 ||
        currentProgram == 'child support services' ||
        currentProgram == 'CHSP' ||
        prgNames.search('CHSP') > 0 ||
        prgNames.search('child support services') > 0 ||
        currentProgram == 'SLER' ||
        currentProgram == 'LIEAP' ||
        prgNames.search('LIEAP') > 0 ||
        prgNames.search('SLER') > 0 ||
        prgNames.search('Other-LIEAP') > 0)
    ) {
      validProgram = true
      break
    }
  }

  let hasSpecialNeeds = false
  for (let i = 0; i < allPrograms.length; i++) {
    let currentProgram = allPrograms[i]
    if (
      isRenewal &&
      prgNames &&
      (currentProgram == 'food & nutrition (snap)' ||
        currentProgram == 'SNAP' ||
        currentProgram == 'FDNT' ||
        prgNames.search('SNAP') > 0 ||
        currentProgram == 'Medicare Premium Assistance' ||
        currentProgram == 'SLMB' ||
        currentProgram == 'QMB' ||
        prgNames.search('QMB') > 0 ||
        prgNames.search('Medicare Premium Assistance') > 0 ||
        currentProgram === 'Health Care Benefits' ||
        prgNames.search('Health Care Benefits') > 0 ||
        currentProgram == 'child support services' ||
        currentProgram == 'CHSP' ||
        prgNames.search('CHSP') > 0 ||
        prgNames.search('child support services') > 0 ||
        currentProgram == 'child care services' ||
        currentProgram == 'CHCR' ||
        prgNames.search('CHCR') > 0 ||
        prgNames.search('child care services') > 0)
    ) {
      hasSpecialNeeds = true
    }
  }

  // if (hasSpecialNeeds || !isRenewal) {
  //   if (
  //     formData.application.SPECIALNEEDS.specialNeeds == undefined ||
  //     _.trim(formData.application.SPECIALNEEDS.specialNeeds) == ''
  //   ) {
  //     requireField(
  //       'application_SPECIALNEEDS_specialNeeds',
  //       errors,
  //       formData,
  //       step,
  //       'Special Needs: Please select "If we have to contact the applicant or if an interview is required, does the household have any special needs?"'
  //     )
  //   }
  // }

  requireField(
    'application_householdRequestForADA_householdRequestForADA',
    errors,
    formData,
    step,
    errorMessage['householdRequestForADA_householdRequestForADA']
  )

  requireField(
    'application_householdInfo_householdAddress_cntyLive',
    errors,
    formData,
    step,
    errorMessage['householdInfo_householdAddress_cntyLive']
  )
  if (formData.application.changes === 'Y') {
    requireField(
      'application_householdInfo_0_householdAddress_dtBirth',
      errors,
      formData,
      step,
      errorMessage['householdInfo_0_householdAddress_dtBirth']
    )
    requireField(
      'application_householdInfo_0_householdAddress_gdrCd',
      errors,
      formData,
      step,
      errorMessage['householdInfo_0_householdAddress_gdrCd']
    )
  }
  let householdAddress = _.get(
    formData,
    'application.householdInfo.householdAddress',
    {}
  )
  if (householdAddress) {
    if (
      householdAddress.poBox == undefined ||
      _.trim(householdAddress.poBox) == ''
    ) {
      householdAddress.poBox = undefined
      requireField(
        'application_householdInfo_householdAddress_poBox',
        errors,
        formData,
        step,
        errorMessage['householdInfo_householdAddress_poBox']
      )
    }
    if (
      householdAddress.ctyNm1 == undefined ||
      _.trim(householdAddress.ctyNm1) == ''
    ) {
      householdAddress.ctyNm1 = undefined
      requireField(
        'application_householdInfo_householdAddress_ctyNm1',
        errors,
        formData,
        step,
        errorMessage['householdInfo_householdAddress_ctyNm1']
      )
    }
    if (householdAddress.st == undefined || _.trim(householdAddress.st) == '') {
      householdAddress.st = undefined
      requireField(
        'application_householdInfo_householdAddress_st',
        errors,
        formData,
        step,
        errorMessage['householdInfo_householdAddress_st']
      )
    }
    if (
      householdAddress.zip1 == undefined ||
      _.trim(householdAddress.zip1) == ''
    ) {
      householdAddress.zip1 === undefined
      requireField(
        'application_householdInfo_householdAddress_zip1',
        errors,
        formData,
        step,
        errorMessage['householdInfo_householdAddress_zip1']
      )
    }
    // Code Changes GPRB0359078 and US2554056
    // COC and Renewal - Household Address Zip Code and Zip Ext validation
    if (householdAddress.zip && householdAddress.zip !== undefined) {
      if (validZipCodeCheck(householdAddress.zip)) {
        notRequiredZip(
          'application_householdInfo_householdAddress_zip',
          errors,
          formData,
          step,
          errorMessage['householdInfo_householdAddress_zipfivedigits']
        )
      }
    }
    if (householdAddress.zipExt && householdAddress.zipExt !== undefined) {
      if (validZipExtCheck(householdAddress.zipExt)) {
        notRequiredZipExt(
          'application_householdInfo_householdAddress_zipExt',
          errors,
          formData,
          step,
          errorMessage['householdInfo_householdAddress_zipExt']
        )
      }
    }
  }
  // Code Changes GPRB0359078 and US2554056
  // COC and Renewal - Mailing Address Zip Code validation
  let mailingAddress = _.get(
    formData,
    'application.householdInfo.mailingAddress',
    {}
  )

  if (mailingAddress) {
    if (mailingAddress.zip && mailingAddress.zip !== undefined) {
      if (validZipCodeCheck(mailingAddress.zip)) {
        notRequiredZip(
          'application_householdInfo_mailingAddress_zip',
          errors,
          formData,
          step,
          errorMessage['householdInfo_mailingAddress_zip']
        )
      }
    }
  }

  let validProgramRentAssistance = false
  for (let i = 0; i < allPrograms.length; i++) {
    let currentProgram = allPrograms[i]
    if (
      isRenewal &&
      prgNames &&
      (currentProgram == 'food & nutrition (snap)' ||
        currentProgram == 'SNAP' ||
        currentProgram == 'FDNT' ||
        currentProgram == 'SLER' ||
        currentProgram == 'LIEAP' ||
        prgNames.search('LIEAP') > 0 ||
        prgNames.search('SLER') > 0 ||
        prgNames.search('Other-LIEAP') > 0 ||
        prgNames.search('SNAP') > 0)
    ) {
      validProgramRentAssistance = true
    }
  }
  if (!isRenewal || validProgramRentAssistance) {
    requireField(
      'application_householdInfo_housingAssistance_rentAssistance',
      errors,
      formData,
      step,
      errorMessage['householdInfo_housingAssistance_rentAssistance']
    )
  }

  let rentAssistance = _.get(
    formData,
    'application.householdInfo.housingAssistance.rentAssistance',
    {}
  )
  if (rentAssistance === '1') {
    requireField(
      'application_householdInfo_housingAssistance_publicHousingAssistance',
      errors,
      formData,
      step,
      errorMessage['householdInfo_housingAssistance_publicHousingAssistance']
    )
  }

  for (let i = 0; i < formData.application.clients.length; i++) {
    requireField(
      'application_clients_' + i + '_clientDetails_personalData_0_clientFstNm',
      errors,
      formData,
      step,
      errorMessage['application_clientDetails_personalData_0_clientFstNm']
    )
    requireField(
      'application_clients_' + i + '_clientDetails_personalData_0_clientLstNm',
      errors,
      formData,
      step,
      errorMessage['application_clientDetails_personalData_0_clientLstNm']
    )

    requireField(
      'application_clients_' + i + '_clientDetails_personalData_0_clientBthDt',
      errors,
      formData,
      step,
      errorMessage['application_clientDetails_personalData_0_clientBthDt']
    )

    // Code changes for GPRB0359133 and US2558381
    if (
      formData.application.clients[i].clientDetails.personalData &&
      formData.application.clients[i].clientDetails.personalData[0]
        .clientBthDt &&
      formData.application.clients[i].clientDetails.personalData[0]
        .clientBthDt !== undefined
    ) {
      if (
        validBirthDateCheck(
          formData.application.clients[i].clientDetails.personalData[0]
            .clientBthDt,
          currentDate
        )
      ) {
        cocBthDtgreaterthanNinteenHundredandLessCurrDt(
          'application_clients_' +
            i +
            '_clientDetails_personalData_0_clientBthDt',
          errors,
          formData,
          step,
          errorMessage[
            'application_clientDetails_personalData_0_clientBthDtprior'
          ]
        )
      }
    }
    // Code Changes GPRB0359223 and US2819645
    // Household Information SSN Validation
    if (
      formData.application.clients[i].clientDetails.personalData[0].clientSsn &&
      formData.application.clients[i].clientDetails.personalData[0]
        .clientSsn !== undefined
    ) {
      if (
        !validSSN(
          formData.application.clients[i].clientDetails.personalData[0]
            .clientSsn
        )
      )
        ssnValidation(
          'application_clients_' +
            i +
            '_clientDetails_personalData_0_clientSsn',
          errors,
          formData,
          step,
          errorMessage['application_clientDetails_personalData_0_clientSsn']
        )
    }
    requireField(
      'application_clients_' + i + '_clientDetails_personalData_0_gdrCd',
      errors,
      formData,
      step,
      errorMessage['application_clientDetails_personalData_0_gdrCd']
    )
  }
  expectCorrectEmailId(
    'application_householdInfo_contactInfo_email',
    errors,
    formData,
    step,
    errorMessage['application_householdInfo_contactInfo_email']
  )
  for (let i = 0; i < formData.application.clients.length; i++) {
    if (
      formData.application.clients[
        i
      ].clientDetails.personalData[0].hasOwnProperty('alterativeInformation') &&
      formData.application.clients[i].clientDetails.personalData[0]
        .alterativeInformation != undefined &&
      formData.application.clients[i].clientDetails.personalData[0]
        .alterativeInformation.isKnownByAnotherName == 'Y'
    ) {
      if (
        formData.application.clients[i].clientDetails.personalData[0]
          .alterativeInformation.alternativeFstName == undefined ||
        _.trim(
          formData.application.clients[i].clientDetails.personalData[0]
            .alterativeInformation.alternativeFstName
        ) == ''
      ) {
        formData.application.clients[
          i
        ].clientDetails.personalData[0].alterativeInformation.alternativeFstName = undefined

        requireField(
          'application_clients_' +
            i +
            '_clientDetails_personalData_0_alterativeInformation_alternativeFstName',
          errors,
          formData,
          step,
          errorMessage[
            'clientDetails_personalData_0_alterativeInformation_alternativeFstName'
          ]
        )
      }
      if (
        formData.application.clients[i].clientDetails.personalData[0]
          .alterativeInformation.alternativeLstName == undefined
      ) {
        formData.application.clients[
          i
        ].clientDetails.personalData[0].alterativeInformation.alternativeLstName = undefined
        requireField(
          'application_clients_' +
            i +
            '_clientDetails_personalData_0_alterativeInformation_alternativeLstName',
          errors,
          formData,
          step,
          errorMessage[
            'clientDetails_personalData_0_alterativeInformation_alternativeLstName'
          ]
        )
      }
    }
  }
  let reviewandsignDOB = _.get(formData, 'application.clientDt', '')

  if (reviewandsignDOB && reviewandsignDOB !== undefined) {
    if (validBirthDateCheck(reviewandsignDOB, currentDate)) {
      cocBthDtgreaterthanNinteenHundredandLessCurrDt(
        'application_clientDt',
        errors,
        formData,
        step,
        errorMessage['application_clientDt']
      )
    }
  }
  return errors
}

export function validateReferralForm(formData, errors) {
  if (formData.Application) {
    if (
      formData.Application.eligibilityOutcome == undefined ||
      formData.Application.eligibilityOutcome.outcome == undefined ||
      formData.Application.eligibilityOutcome.outcome.key == undefined
    ) {
      requireField(
        'Application_eligibilityOutcome_outcome',
        errors,
        formData,
        0,
        ' Referral Comments Eligibility Outcome is required'
      )
    }
  }
  if (formData.referralSearch) {
    if (
      formData.referralSearch.referralSubmitDateFrom != undefined &&
      formData.referralSearch.referralSubmitDateTo == undefined
    ) {
      requireField(
        'referralSearch_referralSubmitDateTo',
        errors,
        formData,
        0,
        'Please enter both Referral Submit Date From and Referral Submit Date To for referral submit date range search.'
      )
    }
    if (
      formData.referralSearch.referralSubmitDateTo != undefined &&
      formData.referralSearch.referralSubmitDateFrom == undefined
    ) {
      requireField(
        'referralSearch_referralSubmitDateFrom',
        errors,
        formData,
        0,
        'Please enter both Referral Submit Date From and Referral Submit Date To for referral submit date range search.'
      )
    }
  }
  return errors
}

export function validateEligAppForm(
  formData,
  errors,
  view,
  showAddEligibleAppView
) {
  // View = 0 ==> Eligible Applicant Search
  // View = 1 ==> Eligible Applicant Add
  if (view == 0 && !showAddEligibleAppView) {
    if (formData.eligibleApplicantSearch) {
      if (
        formData.eligibleApplicantSearch.ageFrom != undefined &&
        formData.eligibleApplicantSearch.ageTo == undefined
      ) {
        requireField(
          'eligibleApplicantSearch_ageTo',
          errors,
          formData,
          0,
          'Please enter both Age From and Age To for age range search.'
        )
      }
      if (
        formData.eligibleApplicantSearch.ageTo != undefined &&
        formData.eligibleApplicantSearch.ageFrom == undefined
      ) {
        requireField(
          'eligibleApplicantSearch_ageFrom',
          errors,
          formData,
          0,
          'Please enter both Age From and Age To for age range search.'
        )
      }
      if (
        formData.eligibleApplicantSearch.individualAddDateFrom != undefined &&
        formData.eligibleApplicantSearch.individualAddDateTo == undefined
      ) {
        requireField(
          'eligibleApplicantSearch_individualAddDateTo',
          errors,
          formData,
          0,
          'Please enter both Add Date From and Add Date To for add date range search.'
        )
      }
      if (
        formData.eligibleApplicantSearch.individualAddDateTo != undefined &&
        formData.eligibleApplicantSearch.individualAddDateFrom == undefined
      ) {
        requireField(
          'eligibleApplicantSearch_individualAddDateFrom',
          errors,
          formData,
          0,
          'Please enter both Add Date From and Add Date To for add date range search.'
        )
      }
      if (
        formData.eligibleApplicantSearch.medicalEligibilityDateFrom !=
          undefined &&
        formData.eligibleApplicantSearch.medicalEligibilityDateTo == undefined
      ) {
        requireField(
          'eligibleApplicantSearch_medicalEligibilityDateTo',
          errors,
          formData,
          0,
          'Please enter both Medical Eligibility Date From and Medical Eligibility Date To for medical eligibility date range search.'
        )
      }
      if (
        formData.eligibleApplicantSearch.medicalEligibilityDateTo !=
          undefined &&
        formData.eligibleApplicantSearch.medicalEligibilityDateFrom == undefined
      ) {
        requireField(
          'eligibleApplicantSearch_medicalEligibilityDateFrom',
          errors,
          formData,
          0,
          'Please enter both Medical Eligibility Date From and Medical Eligibility Date To for medical eligibility date range search.'
        )
      }
    }
  } else if (view == 3 && showAddEligibleAppView) {
    if (
      formData.Application.remove[0].removeReason == undefined ||
      formData.Application.remove[0].removeReason.value == undefined
    ) {
      requireMcrField(
        'Application_remove_0_removeReason',
        errors,
        formData,
        0,
        ' Remove Reason (mandatory to remove an individual) is required.'
      )
    }
  } else if (view == 1 && showAddEligibleAppView) {
    if (formData.Application && formData.Application.clients) {
      if (formData.Application.clients[0].clientFstNm == undefined) {
        requireField(
          'Application_clients_0_clientFstNm',
          errors,
          formData,
          0,
          ' Personal Data First Name is required.'
        )
      }
      if (formData.Application.clients[0].clientLstNm == undefined) {
        requireField(
          'Application_clients_0_clientLstNm',
          errors,
          formData,
          0,
          ' Personal Data Last Name is required.'
        )
      }
      if (formData.Application.clients[0].clientBthDt == undefined) {
        requireField(
          'Application_clients_0_clientBthDt',
          errors,
          formData,
          0,
          ' Personal Data Birth Date is required.'
        )
      } else {
        if (
          validBirthDateCheck(
            formData.Application.clients[0].clientBthDt,
            currentDate
          )
        ) {
          expectBthDtgreaterthanNinteenHundredandLessCurrDt(
            'Application_clients_0_clientBthDt',
            errors,
            formData,
            0,
            ' Personal Data Birth Date cannot be prior to 01/01/1900 and cannot be a future date.'
          )
        }
      }
      if (
        formData.Application.clients[0].genderCode == undefined ||
        formData.Application.clients[0].genderCode.value == undefined
      ) {
        requireMcrField(
          'Application_clients_0_genderCode',
          errors,
          formData,
          0,
          ' Personal Data Gender is required.'
        )
      }
      if (
        formData.Application.clients[0].prtcpSsn == undefined ||
        _.trim(formData.Application.clients[0].prtcpSsn).length == 0
      ) {
        requireField(
          'Application_clients_0_prtcpSsn',
          errors,
          formData,
          0,
          ' Personal Data Social Security Number is required.'
        )
      } else {
        if (formData.Application.clients[0].prtcpSsn != undefined) {
          if (!validSSN(formData.Application.clients[0].prtcpSsn)) {
            ssnValidation(
              'Application_clients_0_prtcpSsn',
              errors,
              formData,
              0,
              ' Personal Data Social Security Number is required.'
            )
          }
        }
      }
      if (
        formData.Application.clients[0].citizenshipStatus == undefined ||
        formData.Application.clients[0].citizenshipStatus.value == undefined
      ) {
        requireMcrField(
          'Application_clients_0_citizenshipStatus',
          errors,
          formData,
          0,
          ' Personal Data Citizenship Status is required.'
        )
      }
      if (formData.Application.clients[0].medicalEligibilityDate == undefined) {
        requireField(
          'Application_clients_0_medicalEligibilityDate',
          errors,
          formData,
          0,
          ' Personal Data Medical Eligibility Date is required.'
        )
      }

      if (formData.Application.clients[0].clientBthDt != undefined) {
        let birthdate = _moment(config, currentDate).diff(
          formData.Application.clients[0].clientBthDt,
          'years',
          false
        )
        if (birthdate < 18) {
          for (
            let i = 0;
            i < formData.Application.legalGuardians[0].length;
            i++
          ) {
            if (
              formData.Application.legalGuardians[0][i].clientFstNm == undefined
            ) {
              formData.Application.legalGuardians[0][i].clientFstNm = undefined
              errorMessage(
                'Application_legalGuardians_0_' + i + '_clientFstNm',
                errors,
                formData,
                0,
                ' Parent or Legal Guardian First Name is required.'
              )
            }
            if (
              formData.Application.legalGuardians[0][i].clientLstNm == undefined
            ) {
              formData.Application.legalGuardians[0][i].clientLstNm = undefined
              errorMessage(
                'Application_legalGuardians_0_' + i + '_clientLstNm',
                errors,
                formData,
                0,
                ' Parent or Legal Guardian Last Name is required.'
              )
            }
          }
        }
      }
    }
    if (formData.Application.physicalAddress[0].adrLn1Txt == undefined) {
      requireField(
        'Application_physicalAddress_0_adrLn1Txt',
        errors,
        formData,
        0,
        ' Physical Address Address Line 1 is required.'
      )
    }
    if (formData.Application.physicalAddress[0].ctyNm == undefined) {
      requireField(
        'Application_physicalAddress_0_ctyNm',
        errors,
        formData,
        0,
        ' Physical Address City is required.'
      )
    }
    if (formData.Application.physicalAddress[0].st == undefined) {
      requireField(
        'Application_physicalAddress_0_st',
        errors,
        formData,
        0,
        ' Physical Address State is required.'
      )
    }
    if (formData.Application.physicalAddress[0].zip == undefined) {
      requireField(
        'Application_physicalAddress_0_zip',
        errors,
        formData,
        0,
        ' Physical Address Zip is required.'
      )
    }
    if (formData.Application.physicalAddress[0].cnty == undefined) {
      requireField(
        'Application_physicalAddress_0_cnty',
        errors,
        formData,
        0,
        ' Physical Address County is required.'
      )
    }
    for (let i = 0; i < formData.Application.phones[0].length; i++) {
      if (
        formData.Application.phones[0][i].telNumber1 == undefined ||
        formData.Application.phones[0][i].telNumber1 == ''
      ) {
        requireField(
          'Application_phones_0_' + i + '_telNumber1',
          errors,
          formData,
          0,
          ' Contact Information at least one Phone Number is required.'
        )
      }
      if (
        formData.Application.phones[0][i].phoneType == undefined ||
        (formData.Application.phones[0][i].phoneType != undefined &&
          formData.Application.phones[0][i].phoneType.value == undefined)
      ) {
        requireMcrField(
          'Application_phones_0_' + i + '_phoneType',
          errors,
          formData,
          0,
          ' Contact Information at least one Phone Type is required.'
        )
      }
    }
  }

  return errors
}

export function validateReferralScreen(formData, errors, step, checkInArr) {
  if (step == 0) {
    if (
      formData['programList'] == undefined ||
      formData['programList'].length == 0
    ) {
      requireField(
        'programList',
        errors,
        formData,
        step,
        'Please choose a value for "Please select the programs for which you would like to screen."'
      )
    }
  } else {
    if (
      step == 1 &&
      checkInArr[1].schema.title == 'Income & Expense Questions'
    ) {
      const incomeQuestions = formData['incomeQuestions']
      if (
        incomeQuestions['earnedIncome'] == '' ||
        incomeQuestions['earnedIncome'] == undefined
      ) {
        requireField(
          'incomeQuestions_earnedIncome',
          errors,
          formData,
          step,
          'Earned Income Amount is required.'
        )
      }
      if (
        incomeQuestions['unearnedIncome'] == '' ||
        incomeQuestions['unearnedIncome'] == undefined
      ) {
        requireField(
          'incomeQuestions_unearnedIncome',
          errors,
          formData,
          step,
          'Unearned Income Amount is required.'
        )
      }
      if (
        incomeQuestions['otherIncomeFromChildSupport'] == '' ||
        incomeQuestions['otherIncomeFromChildSupport'] == undefined
      ) {
        requireField(
          'incomeQuestions_otherIncomeFromChildSupport',
          errors,
          formData,
          step,
          'Child Support Amount is required.'
        )
      }
      if (
        parseFloat(incomeQuestions['otherIncomeFromChildSupport']) >
          parseFloat(incomeQuestions['unearnedIncome']) &&
        parseFloat(incomeQuestions['unearnedIncome']) > 0
      ) {
        errorMessage(
          'incomeQuestions_otherIncomeFromChildSupport',
          errors,
          formData,
          step,
          'Child Support Amount cannot be greater than the Unearned Income Amount.',
          'Child Support Amount cannot be greater than the Unearned Income Amount.'
        )
      }
      if (
        incomeQuestions['dependentCareAmnt'] == '' ||
        incomeQuestions['dependentCareAmnt'] == undefined
      ) {
        requireField(
          'incomeQuestions_dependentCareAmnt',
          errors,
          formData,
          step,
          'Dependent Care Amount is required.'
        )
      }
    }
  }
}

export function validateMessage(formData, errors) {
  if (formData.Banner.fromDate == undefined) {
    requireField(
      'Banner_fromDate',
      errors,
      formData,
      0,
      'From Date is required.'
    )
  }
  if (formData.Banner.toDate == undefined) {
    requireField('Banner_toDate', errors, formData, 0, 'To Date is required.')
  }
  if (formData.fromTime == undefined) {
    requireField(
      'Banner_fromTime',
      errors,
      formData,
      0,
      'From Time is required.'
    )
  }
  if (formData.Banner.toTime == undefined) {
    requireField('Banner_toTime', errors, formData, 0, 'To Time is required.')
  }
  if (formData.Banner.message == undefined) {
    requireField('Banner_message', errors, formData, 0, 'Message is required.')
  }
  if (
    formData.Banner.fromDate != undefined &&
    formData.Banner.toDate != undefined
  ) {
    if (
      _moment(config, formData.Banner.fromDate, 'YYYY-MM-DD').isAfter(
        _moment(config, formData.Banner.toDate, 'YYYY-MM-DD')
      )
    ) {
      errorMessage(
        'Banner_toDate',
        errors,
        formData,
        0,
        '"To Date" must be greater than or equal to "From Date."',
        'Invalid From Date'
      )
    }
  }
  return errors
}

export function validate_renewalPRC2(formData, errors, step) {
  if (step === 1) {
    let someoneAsstYouFlg = _.get(formData, 'application.someoneAsstYouFlg', '')
    if (someoneAsstYouFlg === 'N') {
      requireField(
        'application_authorizedRepresentative_assisterFstNm',
        errors,
        formData,
        step,
        'Household Information: First Name is required.'
      )
      requireField(
        'application_authorizedRepresentative_assisterLstNm',
        errors,
        formData,
        step,
        'Household Information: Last Name is required.'
      )
      requireField(
        'application_authorizedRepresentative_assisterAddress_adrLn1Txt',
        errors,
        formData,
        step,
        'Address: Address Line 1 is required.'
      )
      requireField(
        'application_authorizedRepresentative_assisterAddress_ctyNm',
        errors,
        formData,
        step,
        'Address: City is required.'
      )
      requireField(
        'application_authorizedRepresentative_assisterAddress_st',
        errors,
        formData,
        step,
        'Address: Please lesect "state".'
      )
      requireField(
        'application_authorizedRepresentative_assisterAddress_zip',
        errors,
        formData,
        step,
        'Address: Zip code is required.'
      )
      if (
        _.get(
          formData,
          'application.authorizedRepresentative.assisterRoleCd',
          {}
        ) === '3'
      ) {
        requireField(
          'application_authorizedRepresentative_noticeQuestionForRepresentativeOrLegalGuardian',
          errors,
          formData,
          step,
          'Address: Please select "Should the individual receive a copy of all the notices sent to you?"'
        )
      }

      let assisterAddress = _.get(
        formData,
        'application.authorizedRepresentative.assisterAddress',
        {}
      )

      if (assisterAddress) {
        if (assisterAddress.zip && assisterAddress.zip !== undefined)
          if (validZipCodeCheck(assisterAddress.zip)) {
            notRequiredZip(
              'application_authorizedRepresentative_assisterAddress_zip',
              errors,
              formData,
              step,
              'Address: Zip Code must be five digits.'
            )
          }
      }
    }
    requireField(
      'application_householdRequestForADA_householdRequestForADA',
      errors,
      formData,
      step,
      'ADA: Please select "Does your household request an accommodation under the Americans with Disabilities Act?"'
    )
  }
  if (step === 2) {
    requireField(
      'application_householdInfo_changeInAddress',
      errors,
      formData,
      step,
      'Household Address: Please select "Has your Address changed?"'
    )
    let changeInAddress = _.get(
      formData,
      'application.householdInfo.changeInAddress',
      ''
    )
    if (changeInAddress === 'Y') {
      let householdAddress = _.get(
        formData,
        'application.householdInfo.householdAddress',
        {}
      )
      requireField(
        'application_householdInfo_householdAddress_cntyLive',
        errors,
        formData,
        step,
        'Household Address: Please select "In what county do you live?"'
      )
      if (householdAddress) {
        if (
          householdAddress.poBox == undefined ||
          _.trim(householdAddress.poBox) == ''
        ) {
          householdAddress.poBox = undefined
          requireField(
            'application_householdInfo_householdAddress_poBox',
            errors,
            formData,
            step,
            'Household Address: Street Name or PO Box is required.'
          )
        }
        if (
          householdAddress.ctyNm1 == undefined ||
          _.trim(householdAddress.ctyNm1) == ''
        ) {
          householdAddress.ctyNm1 = undefined
          requireField(
            'application_householdInfo_householdAddress_ctyNm1',
            errors,
            formData,
            step,
            'Household Address: City is required.'
          )
        }
        if (
          householdAddress.st == undefined ||
          _.trim(householdAddress.st) == ''
        ) {
          householdAddress.st = undefined
          requireField(
            'application_householdInfo_householdAddress_st',
            errors,
            formData,
            step,
            'Household Address: Please select "State"'
          )
        }
        if (
          householdAddress.zip1 == undefined ||
          _.trim(householdAddress.zip1) == ''
        ) {
          householdAddress.zip1 === undefined
          requireField(
            'application_householdInfo_householdAddress_zip1',
            errors,
            formData,
            step,
            'Household Address: Zip Code is required'
          )
        }
        // Code Changes GPRB0359078 and US2554056
        // COC and Renewal - Household Address Zip Code and Zip Ext validation
        if (householdAddress.zip && householdAddress.zip !== undefined) {
          if (validZipCodeCheck(householdAddress.zip)) {
            notRequiredZip(
              'application_householdInfo_householdAddress_zip',
              errors,
              formData,
              step,
              'Household Address: Zip Code must be five digits.'
            )
          }
        }
        if (householdAddress.zipExt && householdAddress.zipExt !== undefined) {
          if (validZipExtCheck(householdAddress.zipExt)) {
            notRequiredZipExt(
              'application_householdInfo_householdAddress_zipExt',
              errors,
              formData,
              step,
              'Household Address: Ext must be four digits or may be left blank.'
            )
          }
        }
      }
      let mailingAddress = _.get(
        formData,
        'application.householdInfo.mailingAddress',
        {}
      )

      if (mailingAddress) {
        if (mailingAddress.zip && mailingAddress.zip !== undefined) {
          if (validZipCodeCheck(mailingAddress.zip)) {
            notRequiredZip(
              'application_householdInfo_mailingAddress_zip',
              errors,
              formData,
              step,
              'Mailing Address: Zip must be five digits or may be left blank.'
            )
          }
        }
      }
    }
  }
  if (step === 3) {
    requireField(
      'application_liveWithYou',
      errors,
      formData,
      step,
      'Please select "Is this list of people living in your household correct?* If not, you will need to add or remove individuals below."'
    )
  }
  if (step === 4) {
    requireField(
      'application_wonMoreThan4250',
      errors,
      formData,
      step,
      'Please select "Since your last application for SNAP, has anyone in your household won more than $4,250 through a single bet, game of chance, or lottery"'
    )
  }
  if (step === 5) {
    requireField(
      'application_householdIncomeChange',
      errors,
      formData,
      step,
      'Please select "Has your household\'s gross earned income (including earnings from self-employment) increased or decreased by more than $125 from the amount above"'
    )
    requireField(
      'application_individualIncomeChange',
      errors,
      formData,
      step,
      'Please select "Has anyone had a change in earnings (including earnings from self-employment) because they changed, started or stopped a job?"'
    )
  }
  if (step === 6) {
    requireField(
      'application_householdUnearnedIncomeChange',
      errors,
      formData,
      step,
      'Please select "Has the source of your household\'s gross unearned income changed or has the amount increased or decreased by more than $125 since you last reported a change"'
    )
  }
  if (step === 7) {
    requireField(
      'application_changeInLegalObligationtoPay',
      errors,
      formData,
      step,
      'Please select "Has any household members had a change in his/her legal obligation to pay child support"'
    )
  }
  if (step === 8) {
    requireField(
      'application_changeReason',
      errors,
      formData,
      step,
      'Please select "Tell us how your household expenses have changed?*"'
    )
  }
  if (step == 9) {
    requireField(
      'application_underAgeEighteen',
      errors,
      formData,
      step,
      'Please select "Do you have a person under age 18 in your SNAP assistance group (the people you receive SNAP with)?*'
    )
    const underAgeEighteen = _.get(
      formData,
      'application.underAgeEighteen',
      undefined
    )
    const allAdultsMeetExemptions = _.get(
      formData,
      'application.allAdultsMeetExemptions',
      undefined
    )
    console.log('formData', formData)
    if (
      underAgeEighteen !== undefined &&
      underAgeEighteen == 'N' &&
      allAdultsMeetExemptions == undefined
    ) {
      requireField(
        'application_allAdultsMeetExemptions',
        errors,
        formData,
        step,
        'Please select "Do all adults living in your household meet one of the exemptions listed above*?'
      )
    }
  }

  return errors
}
