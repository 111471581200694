import * as React from 'react'
import { Provider, connect } from 'react-redux'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { config } from '../../../config'
import * as actions from '../../../actions'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { Modal } from 'react-bootstrap'
import Layout from '../Layout'
import SelectRole from '../SelectRole'
import IdleTimer from 'react-idle-timer'
import { EVENTS } from 'react-idle-timer'
import { isNil, get, round, isNull } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { ErrorBoundary } from '../Errors'
import { Helmet } from 'react-helmet'

const _ = { isNil, get, round, isNull }

declare const process
const baseUrl = process.env.PUBLIC_URL

const Root = ({ store, routes }) => {
  return (
    <Provider store={store}>
      <ErrorBoundary name="Root">
        <ConnectedApp routes={routes} />
      </ErrorBoundary>
    </Provider>
  )
}

//Events that will refresh the user session.
const ACTIVITY_EVENTS: EVENTS[] = [
  'mousemove',
  'keydown',
  'wheel',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove'
]

class App extends React.Component<any, any> {
  private idleTimer
  constructor(props: any) {
    super(props)
    const { auth, loginRefresh, logoutUser } = props
    const { userAccount, accessToken, skipNextRefresh } = auth
    if (auth.isAuthenticated || auth.userAccount || auth.expiry) {
      if (helper.isLoggedIn(auth, config)) {
        loginRefresh(userAccount.uuid, accessToken, skipNextRefresh, true)
      } else {
        logoutUser(props.auth.userAccount, props.auth.accessToken)
      }
    }

    document.addEventListener('keydown', this.onFocus)
    document.addEventListener('click', this.onFocus)

    this.idleTimer = null

    this.state = {
      tabFocus: false,
      showCountdownModal: false,
      countdownIntervalId: null,
      remainingSeconds: null
    }
  }

  onFocus = e => {
    if (e.which === 9 && !this.state.tabFocus) {
      this.setState({ tabFocus: true })
    } else if (e.which === 1 && this.state.tabFocus) {
      this.setState({ tabFocus: false })
    }
  }

  onAction = e => {
    const { auth, loginRefresh } = this.props
    const { showCountdownModal } = this.state
    if (helper.isLoggedIn(auth, config) && !showCountdownModal) {
      const { userAccount, accessToken, skipNextRefresh } = auth
      loginRefresh(userAccount.uuid, accessToken, skipNextRefresh, false)
    }
  }

  onActive = e => {}

  onIdle = e => {
    const timeLeft = 5 * 60000
    if (timeLeft > 0) {
      const countdownIntervalId = setInterval(this.onCountdownTick, 1000)
      this.setState({
        remainingSeconds: _.round(timeLeft / 1000),
        showCountdownModal: true,
        countdownIntervalId
      })
    } else {
      this.props.logoutUser(
        this.props.auth.userAccount,
        this.props.auth.accessToken
      )
      this.setState({
        remainingSeconds: null,
        showCountdownModal: false,
        countdownIntervalId: null
      })
    }
  }

  onCountdownTick = () => {
    const remainingSeconds = this.state.remainingSeconds - 1
    if (remainingSeconds <= 0) {
      this.onCountdownLogout()
    } else {
      this.setState({ remainingSeconds })
    }
  }

  onCountdownContinue = () => {
    clearInterval(this.state.countdownIntervalId)
    const { userAccount, accessToken, skipNextRefresh } = this.props.auth
    this.props.loginRefresh(
      (userAccount || {}).uuid,
      accessToken,
      skipNextRefresh,
      false
    )
    this.setState({
      remainingSeconds: null,
      showCountdownModal: false,
      countdownIntervalId: null
    })
  }

  onCountdownLogout = () => {
    clearInterval(this.state.countdownIntervalId)
    this.props.logoutUser(
      this.props.auth.userAccount,
      this.props.auth.accessToken
    )
    this.setState({
      remainingSeconds: null,
      showCountdownModal: false,
      countdownIntervalId: null
    })
  }

  render() {
    const { auth, userAccess, routes, isFetching, serviceCalling } = this.props
    const { tabFocus, showCountdownModal, remainingSeconds } = this.state
    const isLoggedIn = helper.isLoggedIn(auth, config)
    //If they are logged in, have multiple roles, and have not selected one, render the role select screen.
    const forceSelectRole =
      isLoggedIn &&
      (userAccess.userRoles || []).length > 1 &&
      _.isNil(_.get(userAccess, 'selectedUserRole'))

    const idleTimeout =
      (auth.timeout || config.defaultExpiryMs) - config.countdownTimerMs

    return (
      <div className={tabFocus ? 'tabbing' : null}>
        <Helmet>
          <html lang={I18n._localeKey} />
          <title>Home - IE Client Portal</title>
        </Helmet>
        {isLoggedIn && !showCountdownModal && (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref
            }}
            events={ACTIVITY_EVENTS}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={500}
            timeout={idleTimeout}
            stopOnIdle={true}
          />
        )}
        <BrowserRouter>
          <Layout>
            {isFetching ? (
              <ScreenPreLoader />
            ) : forceSelectRole ? (
              <SelectRole />
            ) : (
              <Switch>{routes && routes(auth)}</Switch>
            )}
          </Layout>
        </BrowserRouter>
        {showCountdownModal && (
          <Modal
            show={true}
            onHide={this.onCountdownContinue}
            aria-labelledby="contained-modal-title"
            size={'sm'}
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>
                <i className="fa fa-exclamation-triangle" />
                {' ' + I18n.t('Root.countdown.title')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {I18n.t('Root.countdown.message', {
                remainingSeconds: remainingSeconds.toString()
              })}
            </Modal.Body>
            <Modal.Footer>
              <div className="col-lg-12">
                <button
                  id="Root_countdownContinueBtn"
                  type="button"
                  className="btn btn-primary float-left"
                  onClick={this.onCountdownContinue}
                >
                  {I18n.t('Root.countdown.continueBtn')}
                </button>
                <button
                  id="Root_countdownLogoutBtn"
                  type="button"
                  className="btn btn-warning float-right"
                  onClick={this.onCountdownLogout}
                >
                  {I18n.t('Root.countdown.logoutBtn')}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        <Modal
          show={!_.isNull(auth.userAccount) && isLoggedIn && serviceCalling}
          backdrop="static"
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userAccess: state.userAccess,
    isFetching: state.auth.isFetching || state.userAccess.isFetching,
    serviceCalling: _.get(
      state,
      'myMessagesError.myMessagesError.serviceCalling',
      false
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loginRefresh: (uuid, accessToken, skipNextRefresh, isInitial) => {
      dispatch(
        actions.loginRefresh(uuid, accessToken, skipNextRefresh, isInitial)
      )
    },
    logoutUser: (userAccount, accessToken) => {
      dispatch(actions.logoutUser(userAccount, accessToken))
    }
  }
}

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)

export default Root
