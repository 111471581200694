import * as React from 'react'
import { Redirect } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { isEqual, cloneDeep, noop, get, includes } from 'lodash'
import { connect } from 'react-redux'
import { config } from '../../../config'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'
const moment = require('moment-timezone')
const _ = { isEqual, cloneDeep, noop, get, includes }

declare const process
const baseUrl = process.env.PUBLIC_URL

const formsEndpoint = config['forms_MyPESearchApplication']
const noticeGateEndpoint = config['getNoticeByDocId']
const peOverrideEndpoint = config['peOverrideEndpoint']
const agreeAutoAcceptEndPoint = config['agreeAutoAcceptEndPoint']
const deleteDraftEndPoint = config['deleteDraftEndPoint']
const submitOverrideUrl = config['submitOverride']
const getPEApplicationsForPEUser = config['getPEApplicationsForPEUser']

interface SearchPEApplicationsContainerProps {
  presentation: any
  uuid: string
  userRoleId: number
  orgId: string
  locale: string
  authHeader: string
}

interface SearchPEApplicationsContainerState {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  showUploadModal: boolean
  showSuccessPopup: boolean
  formContext: any
  loadScreen: boolean
  redirect: string
}

/**
 * PEApplications provides:
 *  1) Application full (CP APPINTAKE) Navigation
 *  2) PE application  (CP PEAPPINTAKE) Navigation
 *  3) view Submitted Application details
 *
 * @class MyPESearchApplication
 * @extends {React.Component<SearchPEApplicationsContainerProps, SearchPEApplicationsContainerState>}
 */
class SearchPEApplicationsContainer extends React.Component<
  SearchPEApplicationsContainerProps,
  SearchPEApplicationsContainerState
> {
  constructor(props: SearchPEApplicationsContainerProps) {
    super(props)
    this.state = {
      forms: null,
      formData: null,
      showUploadModal: false,
      showSuccessPopup: false,
      formContext: { elibilityFormEdit: false },
      loadScreen: null,
      redirect: null
    }
  }

  _deleteDraftApplication = (props: any) => {
    let applicationId = this.getAppId(props)
    const myApplicationsDraftIndex = props.id.match(
      '(?:.*)myApplicationsDraft_clients_([0-9]+)_?'
    )
    const myAgencyApplicationDraftIndex = props.id.match(
      '(?:.*)myAgencyApplicationDraft_clients_([0-9]+)_?'
    )
    let formData = helper.deepClone(this.state.formData)

    const fetchEndpoint = deleteDraftEndPoint.replace('{applId}', applicationId)
    helper
      .fetchJson(fetchEndpoint, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(jsonData => {
        if (
          myApplicationsDraftIndex &&
          props.formContext.formData.Application.myApplicationsDraft.clients
            .length
        ) {
          formData.Application.myApplicationsDraft.clients.splice(
            myApplicationsDraftIndex[1] - 1,
            1
          )
        } else if (
          myAgencyApplicationDraftIndex &&
          props.formContext.formData.Application.myAgencyApplicationDraft
            .clients.length
        ) {
          formData.Application.myAgencyApplicationDraft.clients.splice(
            myAgencyApplicationDraftIndex[1] - 1,
            1
          )
        }
        this.setState({ formData })
      })
      .catch(error => {
        console.error('SearchPEApplications _deleteDraft failed with ex', error)
        const code = CODES.SEARCH_PE_APPLICATIONS_DELETE_DRAFT
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  getAppId = (props: any) => {
    const myApplicationsDraftIndex = props.id.match(
      '(?:.*)myApplicationsDraft_clients_([0-9]+)_?'
    )
    const myAgencyApplicationDraftIndex = props.id.match(
      '(?:.*)myAgencyApplicationDraft_clients_([0-9]+)_?'
    )
    let applicationId
    if (
      myApplicationsDraftIndex &&
      props.formContext.formData.Application.myApplicationsDraft.clients.length
    ) {
      applicationId =
        props.formContext.formData.Application.myApplicationsDraft.clients[
          myApplicationsDraftIndex[1]
        ].fullApplications[0].fullApplication[0].appId
    }
    if (
      myAgencyApplicationDraftIndex &&
      props.formContext.formData.Application.myAgencyApplicationDraft.clients
        .length
    ) {
      applicationId =
        props.formContext.formData.Application.myAgencyApplicationDraft.clients[
          myAgencyApplicationDraftIndex[1]
        ].fullApplications[0].fullApplication[0].appId
    }
    return applicationId
  }

  isFullApplicationRadioSelected = (props: any) => {
    let fieldId = props.id
    let flag = false
    if (_.includes(fieldId, 'myApplicationSub')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationSub_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myApplicationSub.clients[
          clientIndex
        ].fullApplications[0].fullApplication.forEach(fullApp => {
          if (fullApp.select) {
            flag = true
          }
        })
      }
    }
    /*else if (_.includes(fieldId, "myAgencyApplicationSubmitted")) {
            let clientIndex = fieldId.match('(?:.*)myAgencyApplicationSubmitted_clients_([0-9]+)_?')[1];
            this.state.formData.Application.myAgencyApplicationSubmitted.clients[clientIndex].fullApplications[0].fullApplication.forEach((fullApp) => {
                if (fullApp.select) {
                    flag = true;
                }
            });
        }*/
    return flag
  }

  isPEApplicationRadioSelected = (props: any) => {
    let fieldId = props.id
    let flag = false

    if (_.includes(fieldId, 'myApplicationSub')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationSub_clients_([0-9]+)_?'
      )[1]
      if (this.state.formData.Application) {
        this.state.formData.Application.myApplicationSub.clients[
          clientIndex
        ].peApplications[0].peApplication.forEach(peApp => {
          if (peApp.select) {
            flag = true
          }
        })
      }
    }

    return flag
  }

  _editDraftApplication = (props: any) => {
    let applicationId = this.getAppId(props)
    this._setRedirect(applicationId)
  }

  componentDidMount() {
    this._loadApp()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      helper
        .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(json => {
          const forms = json['app']['forms']
          this.setState(prevState => {
            return {
              forms,
              formContext: {
                ...prevState.formContext,
                forms
              }
            }
          })
        })
        .catch(error => {
          console.error(
            'SearchPEApplications form re-fetch failed due to ex',
            error
          )
          const code = CODES.SEARCH_PE_APPLICATIONS_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  getIndividualResult = (index: string) => {
    let appMgmtParams = {}
    appMgmtParams['searchApplications'] = {
      ...this.state.formData.searchApplications
    }
    appMgmtParams['pagination'] = { recordsPerPage: 20, pageIndex: 1 }
    return helper
      .fetchJson(getPEApplicationsForPEUser, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          orgId: this.props.orgId,
          userRoleId: this.props.userRoleId.toString(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        },
        body: JSON.stringify(appMgmtParams)
      })
      .then(json => {
        return json.clientInfos
      })
      .catch(error => {
        const code = CODES.SEARCH_PE_APPLICATIONS_SEARCH_PE_INFO
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        } else {
          return error
        }
      })
  }

  _loadApp = () => {
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        this._initializeForm(formJson)
      })
      .catch(error => {
        console.error('SearchPEApplications form fetch failed due to ex', error)
        const code = CODES.SEARCH_PE_APPLICATIONS_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
    // this._initializeForm(Json);
  }

  _initializeForm = (formsJson: any) => {
    let forms, application, formData, formContext, refs
    application = formsJson['app']
    forms = application['forms']
    refs = application['metaData']['refs']
    //load the pre-filled form data
    formData = application['formData']
    let loadScreen = true
    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      refs: refs,
      forms: forms,
      formData: formData,
      component: this,
      viewPENotice: this._viewEligibilityNotice,
      confirmEligibility: (elgReqId, appId) =>
        this._confirmEligibility(elgReqId, appId),
      notAgreeEligibilty: this._notAgreeEligibilty,
      elibilityFormEdit: false,
      agreeEligibilty: this._agreeEligibilty,
      setRedirect: this._setRedirect,
      _deleteDraftApplication: this._deleteDraftApplication,
      overrideSubmit: formData => this._overrideSubmit(),
      config
    }

    this.setState({ forms, formContext, formData, loadScreen })
  }

  _overrideSubmit = () => {
    let formData = helper.deepClone(this.state.formData)
    let disposition = []
    let requestData
    if (formData && formData.overridePeData.PEGroupTest1) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'PEGroup',
        possibleDisposition: formData.overridePeData.PEGroupTest1,
        ruleID: ''
      })
    }

    if (formData && formData.overridePeData.StateResidency1) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'StateResident',
        possibleDisposition: formData.overridePeData.StateResidency1,
        ruleID: ''
      })
    }

    if (formData && formData.overridePeData.CitizenshipImmigration1) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'CitizenshipOrImmigration',
        possibleDisposition: formData.overridePeData.CitizenshipImmigration1,
        ruleID: ''
      })
    }

    if (formData && formData.overridePeData.householdIncomeTest1) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'FPL',
        possibleDisposition: formData.overridePeData.householdIncomeTest1,
        ruleID: ''
      })
    }

    if (formData && formData.overridePeData.PELast) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'PEInLastTwelveMonth',
        possibleDisposition: formData.overridePeData.PELast,
        ruleID: ''
      })
    }

    requestData = {
      disposition,
      eventId: 0,
      appId: formData.overridePeData.appId,
      eligReqId: formData.overridePeData.eligReqId
    }

    helper
      .fetchJson(submitOverrideUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        },
        mode: 'cors',
        body: JSON.stringify(requestData)
      })
      .then(results => {
        this._closeUploadModal()
        this._loadApp()
      })
      .catch(error => {
        console.error(
          'SearchPEApplications override submit failed with ex',
          error
        )
        const code = CODES.SEARCH_PE_APPLICATIONS_SUBMIT_OVERRIDE
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _openUploadModal = () => {
    this.setState({ showUploadModal: true, showSuccessPopup: false })
  }

  _onFormDataChangeModal = ({ formData }) => {
    this.state.formData.overridePeData = formData
    this._refreshFormContext()
    //this.setState({ formData.overridePeData: formData}, () => this._refreshFormContext());
  }
  _agreeEligibilty = () => {
    let formData = helper.deepClone(this.state.formData)

    const fetchEndpoint = agreeAutoAcceptEndPoint.replace(
      '{applicationId}',
      formData.overridePeData.appId
    )
    helper
      .fetchJson(fetchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
        //mode : 'cors',
        //body: JSON.stringify(params)
      })
      .then(json => {
        this._closeUploadModal()
        this._loadApp()
      })
      .catch(error => {
        console.error('SearchPEApplications auto accept failed with ex', error)
        const code = CODES.SEARCH_PE_APPLICATIONS_AGREE_AUTO_ACCEPT
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }
  _closeUploadModal = () => {
    this.setState({ showUploadModal: false, showSuccessPopup: false })
  }
  _confirmEligibility = (elgReqId: string, appId: string) => {
    //const {  formData } = this.state;

    let formData = helper.deepClone(this.state.formData)
    formData.overridePeData = {}
    var fetchEndpoint = peOverrideEndpoint.replace('{elgReqId}', elgReqId)
    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        formData.overridePeData.benefitStartDate1 = formJson.startDate
        formData.overridePeData.benefitEndDate1 = formJson.endDate
        formData.overridePeData.programName1 = formJson.programName
        formData.overridePeData.ApplicationName1 = formJson.applicantName
        formData.overridePeData.incomeFrequency = formJson.incomeFrequency
        formData.overridePeData.citizenshipTest1 =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[0].status
        formData.overridePeData.PEGroupTest =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[1].status
        formData.overridePeData.lastPe =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[2].status
        formData.overridePeData.stateResidencyTest1 =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[3].status
        formData.overridePeData.eligibilityResult1 =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].eligibility
        formData.overridePeData.citizenshipstatus1 =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].citizenshipOrImmigrationStatus
        formData.overridePeData.householdSize = formJson.houseHoldSize
        formData.overridePeData.PEEligibleGroup =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].eligibleGroups
        formData.overridePeData.householdIncome1 = formJson.houseHoldIncome
        formData.overridePeData.householdTest =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[4].status

        formData.overridePeData.PELast =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[1].status
        formData.overridePeData.PEGroupTest1 =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[0].status
        formData.overridePeData.CitizenshipImmigration1 =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[3].status
        formData.overridePeData.StateResidency1 =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[2].status
        formData.overridePeData.householdIncomeTest1 =
          formJson.rulesResponse.programEligibility.benefitDetails[0].assistanceUnits[0].clients[0].tests[4].status
        formData.overridePeData.appId = appId
        formData.overridePeData.eligReqId = elgReqId

        this.setState({ formData: formData })
        this._openUploadModal()
      })
      .catch(error => {
        console.error(
          'SearchPEApplications PE override failed due to ex',
          error
        )
        const code = CODES.SEARCH_PE_APPLICATIONS_PE_OVERRIDE
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }
  _notAgreeEligibilty = () => {
    let formContext = this.state.formContext
    formContext.elibilityFormEdit = true
    this.setState({ formContext }, () => this._refreshFormContext())
  }

  _setRedirect = (path: string) => {
    this.setState({ redirect: path })
  }

  _viewEligibilityNotice = (applicationId: string) => {
    var fetchEndpoint =
      noticeGateEndpoint.replace('{docUploadId}', '0') +
      '?appId=' +
      applicationId +
      '&pe=pe'

    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(resp => {
        var content
        var contentType
        var pdfAsDataUri
        content = resp.notices[0].notice.document.fileContent
        contentType = 'application/pdf'
        pdfAsDataUri = 'data:application/pdf;base64,' + content // shortened

        var bytearray = this.convertDataURIToBinary(pdfAsDataUri)

        var file = new Blob([bytearray], {
          type: contentType
        })
        //trick to download store a file having its URL
        var fileURL = ''
        // for IE 10/11 save doc pop up will appear
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file)
        } else {
          fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          // auto download the file
          var a = document.createElement('a')
          a.href = fileURL
          a.target = '_blank'
          a.download = 'Notification.pdf'
          document.body.appendChild(a)
          a.click()
        }
      })
      .catch(error => {
        console.error('SearchPEApplications _generatePDF failed with ex', error)
        const code = CODES.SEARCH_PE_APPLICATIONS_VIEW_PDF
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  convertDataURIToBinary = (dataURI: string) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({ formData }, () => this._refreshFormContext())
  }

  // refresh formcontext since it works with formdata
  _refreshFormContext = () => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        formData: helper.deepClone(this.state.formData)
      }
    })
  }

  _clearForm = () => {
    let formData = _.cloneDeep(this.state.formData)
    formData.searchApplications.applicationType = _.noop()
    formData.searchApplications.applicationId = _.noop()
    formData.searchApplications.source = _.noop()
    formData.searchApplications.clientFstName = _.noop()
    formData.searchApplications.clientLstName = _.noop()
    formData.searchApplications.dob = _.noop()
    formData.searchApplications.clientSSN = _.noop()
    formData.searchApplications.appStatus = _.noop()
    formData.searchApplications.fromDate = _.noop()
    formData.searchApplications.toDate = _.noop()

    this.setState({
      formData,
      formContext: {
        ...this.state.formContext,
        formData: formData
      }
    })
  }

  _searchPEApplications = () => {
    let accordionIndex = '1'
    this.getIndividualResult(accordionIndex).then(response => {
      response.map(value => {
        value.fullApplications = []
        value.peApplications = []
        value.accordionTitle =
          (value.firstName ? value.firstName : '') +
          ' ' +
          (value.lastName ? value.lastName : '') +
          (accordionIndex == '1'
            ? value.dob
              ? '  DOB: ' + moment.utc(value.dob).format('MM/DD/YYYY')
              : ''
            : '')
        value.fullApplications.push({ fullApplication: value.fullApplication })
        delete value.fullApplication
        value.peApplications.push({ peApplication: value.peApplication })
        delete value.peApplication
      })

      let newFormData = helper.deepClone(this.state.formData)
      newFormData.Application.myApplicationSub.clients = response
      this.setState({ formData: newFormData }, () => this._refreshFormContext())
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={baseUrl + '/application'} />
    }
    const { presentation, locale } = this.props
    const {
      forms,
      formData,
      formContext,
      loadScreen,
      showUploadModal
    } = this.state

    const presentationProps = {
      forms,
      formData,
      formContext,
      loadScreen,
      onFormDataChange: this._onFormDataChange,
      searchPEApplications: this._searchPEApplications,
      clearForm: this._clearForm,
      showUploadModal,
      closeUploadModal: this._closeUploadModal,
      onFormDataChangeModal: this._onFormDataChangeModal,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let org = _.get(state.userAccess, 'selectedUserRole.organization')
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    orgId: org ? org.orgId : null,
    userRoleId,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

export default connect(mapStateToProps)(SearchPEApplicationsContainer)
