import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
import { isEqual, get, isNil } from 'lodash'
import * as Modal from 'react-bootstrap/Modal'
import * as actions from '../../../actions'
import { I18n } from 'react-redux-i18n'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'

const _ = { isEqual, get, isNil }

const loadEndpoint = config['getUserAccountDetails']
const formsEndpoint = config['forms_MyProfile']
const pinEndpoint = config['pinEndpoint']
const updateDefaultUserRoleEndpoint = config['updateDefaultUserRole']

interface Entitlement {
  entitlementId: number
  entitlementName: string
  entitlementDescription: string
}

interface UserRole {
  userRoleId: number
  uuid: string
  role: {
    roleId: number
    roleName: string
    multilingualDisplayName: {
      en: string
      es: string
    }
    multilingualDescription: {
      en: string
      es: string
    }
    tenantId: number
    status: string
    startDate: string
    endDate: string
  }
  entitlements: Array<Entitlement>
}

interface MyProfileContainerProps {
  presentation: any
  userAccount: {
    uuid: string
    defaultUserRoleId: number
    isDefaultUserRole: boolean
  }
  minHeight: string
  userAccess: {
    userRoles: Array<UserRole>
    selectedUserRole: UserRole
  }
  externalAuth?: {
    hasPin: boolean
  }
  setHasPin: (hasPin: boolean) => void
  setDefaultUserRoleId: (userRoleId: number) => void
  locale: string
  authHeader: string
}

interface MyProfileContainerState {
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formData: any
  showPinErrorModal: boolean
  formContext: any
  userRoles: Array<UserRole>
  defaultUserRoleId: number
  callingApi: boolean
}

class MyProfileContainer extends React.Component<
  MyProfileContainerProps,
  MyProfileContainerState
> {
  constructor(props: MyProfileContainerProps) {
    super(props)
    this.state = {
      formSchema: null,
      formData: null,
      showPinErrorModal: false,
      formContext: {
        config
      },
      userRoles: null,
      defaultUserRoleId: null,
      callingApi: false
    }
  }

  componentDidMount() {
    const endpoint = formsEndpoint.replace('{version}', '1.0')
    helper
      .fetchJson(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.userAccount.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        this._loadFormSchema(formJson)
        this._getFormData(loadEndpoint)
      })
      .catch(error => {
        console.error('MyProfile componentDidMount failed due to ex', error)
        const code = CODES.MY_PROFILE_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })

    this.setState(prevState => {
      if (_.get(config.tenant, 'externalAuth.checkHasPin', false)) {
        return {
          formContext: {
            ...prevState.formContext,
            addPin: this._pinValidation,
            checkHasPin: true
          }
        }
      } else {
        return {
          formContext: {
            ...prevState.formContext,
            checkHasPin: false
          }
        }
      }
    })
    this._loadUserRoles(this.props.userAccess)
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.userAccount.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(json => {
          this._loadFormSchema(json)
        })
        .catch(error => {
          console.error('MyProfile form re-fetch failed due to ex', error)
          const code = CODES.MY_PROFILE_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _loadUserRoles(json) {
    let userRoles = json.userRoles.map(userRole => {
      return {
        ...userRole
      }
    })
    let defaultUserRoleId = !_.isNil(this.props.userAccount.defaultUserRoleId)
      ? this.props.userAccount.defaultUserRoleId
      : 0
    this.setState({
      userRoles: userRoles,
      defaultUserRoleId: defaultUserRoleId
    })
  }

  static getDerivedStateFromProps(
    props: MyProfileContainerProps,
    state: MyProfileContainerState
  ) {
    if (_.get(config.tenant, 'externalAuth.checkHasPin', false)) {
      return {
        formContext: {
          ...state.formContext,
          hasPin: props.externalAuth.hasPin
        }
      }
    } else {
      return null
    }
  }

  _onFormDataChange = ({ formData }) => {
    let defaultRoleId = this.state.defaultUserRoleId
    for (let userRole of formData.roleSetting.userRoles) {
      if (userRole.defaultRole === 'true') {
        if (this.state.defaultUserRoleId != userRole.userRoleId) {
          defaultRoleId = userRole.userRoleId
        }
      }
    }
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData
        },
        defaultUserRoleId: defaultRoleId
      }
    })
  }

  _getFormData = endpoint => {
    helper
      .fetchJson(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.userAccount.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        }
      })
      .then(json => {
        this.props.setHasPin(json['authorized'])

        // JSON for Role Settings table
        let roleSetting = { userRoles: this.state.userRoles }
        json['roleSetting'] = roleSetting
        for (let userRole of roleSetting['userRoles']) {
          userRole['defaultRole'] =
            userRole.userRoleId === this.state.defaultUserRoleId
              ? 'true'
              : 'false'
        }
        this._loadFormData(json)
      })
      .catch(error => {
        console.error(
          'MyProfile _getFormData failed due to ex',
          error,
          'endpoint: ' + endpoint
        )
        const code = CODES.MY_PROFILE_FETCH_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _pinValidation = (userPin: any) => {
    const params = {
      uuid: this.props.userAccount.uuid,
      pin: userPin.pin,
      ssn: userPin.ssnEntry
    }

    helper
      .fetchJson(pinEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.userAccount.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        },
        body: JSON.stringify(params)
      })
      .then(json => {
        var hasPin = null
        if (json && json instanceof Object) {
          hasPin = json && json['data']
        } else {
          hasPin = json
        }

        //const hasPin = true;
        if (!hasPin) {
          // alert("Pin could not be validated! Double-check the input");
          this._openPinErrorModal()
        }
        this.props.setHasPin(hasPin)
      })
      .catch(error => {
        console.error('MyProfile _pinValidation failed due to ex', error)
        const code = CODES.MY_PROFILE_VALIDATE_PIN
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _handleRoleChange = roleId => {
    this.setState({
      defaultUserRoleId: roleId
    })
  }

  _updateDefaultUserRoleId = () => {
    let defaultUserRoleId = this.state.defaultUserRoleId
    if (defaultUserRoleId <= 0) {
      return
    }

    let userRoles: Array<UserRole> = this.state.userRoles
    let selectedUserRole: UserRole = null
    for (let userRole of userRoles) {
      if (userRole.userRoleId === defaultUserRoleId) {
        selectedUserRole = userRole
      }
    }
    if (!_.isNil(selectedUserRole)) {
      const updateEndpoint = updateDefaultUserRoleEndpoint
        .replace('{userRoleId}', defaultUserRoleId.toString())
        .replace('{isDefaultUserRole}', 'Y')
      helper
        .fetchJson(updateEndpoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.userAccount.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(response => {
          //TODO: Handle response?
          this.props.setDefaultUserRoleId(defaultUserRoleId)
        })
        .catch(error => {
          console.error(
            'MyProfile _updateUserRoleSelection failed due to ex',
            error,
            'endpoint: ',
            updateEndpoint
          )
          const code = CODES.MY_PROFILE_UPDATE_DEFAULT_ROLE
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
      this._getFormData(loadEndpoint)
    }
  }

  _loadFormData = (json: Object) => {
    this.setState({ formData: json })
  }

  _loadFormSchema = (json: any) => {
    this.setState({ formSchema: json['myProfile']['forms'] })
  }

  _closePinErrorModal = () => {
    this.setState({ showPinErrorModal: false })
  }

  _openPinErrorModal = () => {
    this.setState({ showPinErrorModal: true })
  }

  render() {
    const { presentation, minHeight, locale } = this.props
    const {
      formSchema,
      formData,
      formContext,
      showPinErrorModal,
      userRoles,
      defaultUserRoleId
    } = this.state

    const presentationProps = {
      formSchema,
      formData,
      formContext,
      onFormDataChange: this._onFormDataChange,
      closePinErrorModal: this._closePinErrorModal,
      showPinErrorModal,
      minHeight,
      userRoles,
      defaultUserRoleId,
      updateDefaultUserRoleId: this._updateDefaultUserRoleId,
      handleRoleChange: this._handleRoleChange,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    externalAuth: state.externalAuth,
    userAccount: state.auth.userAccount,
    userAccess: state.userAccess,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setHasPin: hasPin => {
      dispatch(actions.setHasPin(hasPin))
    },
    setDefaultUserRoleId: userRoleId => {
      dispatch(actions.setDefaultUserRoleId(userRoleId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileContainer)
