import * as React from 'react'
import { findIndex } from 'lodash'
import NavTabs from '@optum-wvie/dynamic-ui-framework/src/components/natives/NavTabs'
import { I18n } from 'react-redux-i18n'

const _ = { findIndex }

interface HelpTab {
  id: string
  title: string
  leftIcon: string
  component: React.ReactNode
}

interface HelpPresentationProps {
  id: string
  subProps: Object
  navTabs: Array<HelpTab>
  onNavClick: (id: string) => void
  setNavDiv: (el: React.ReactNode) => void
  locale: string
}

function loadAll(ActiveSubcomponent, activeTabIndex, id, subProps) {
  var comp
  if (activeTabIndex == 0) {
    return <ActiveSubcomponent key={id} {...subProps} />
  } else if (activeTabIndex == 4) {
    comp = (
      <div className="headerpadding margin-top-10">
        <div className="paragraph-label headerpadding">
          {I18n.t('Help.needMorHlp')}
        </div>
        <div className="headerpadding margin-top-10">
          {I18n.t('Help.cnctAgency')}
        </div>
      </div>
    )
  }
  return comp
}

export default function HelpPresentation(props: HelpPresentationProps) {
  const { id, subProps, navTabs, onNavClick, setNavDiv, locale } = props
  let finalNavTabs = (navTabs || []).map(tab => {
    return {
      ...tab,
      title: I18n.t(tab.title)
    }
  })
  let ActiveSubcomponent = null
  const activeTabIndex = _.findIndex(navTabs, { id })
  if (finalNavTabs.length === 0) {
    ActiveSubcomponent = props => <div>{I18n.t('Help.noTabs')}</div>
  } else {
    if (activeTabIndex > -1) {
      ActiveSubcomponent = navTabs[activeTabIndex].component
    } else {
      ActiveSubcomponent = props => <div>{I18n.t('Help.invldSubComp')}</div>
    }
  }
  return (
    <div>
      <div className="header-view">
        <p className="heading">
          <i className="myglyphicon-halflings myglyphicon-circle_question_mark myaccount-icon" />{' '}
          {I18n.t('Help.hlpCntr')}
        </p>
        <div className="header-sub" />
      </div>
      <div className="below-header-bar" />
      <div className="row my-account">
        <div
          id="side-nav"
          className="tabs-section nav col-md-2 col-sm-3 no-padding"
          ref={el => setNavDiv(el)}
        >
          <NavTabs activeKey={id} onNavClick={onNavClick} tabs={finalNavTabs} />
        </div>
        <div
          id="content"
          className="form-section col-md-10 col-sm-9"
          tabIndex={-1}
        >
          {loadAll(ActiveSubcomponent, activeTabIndex, id, subProps)}
        </div>
      </div>
    </div>
  )
}
