import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyLifeEventsContainer = MultitenantLoadable(
  () => import('./MyLifeEventsContainer'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/MyLifeEvents/MyLifeEventsContainer'
    )
)

const MyLifeEventsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyLifeEventsPresentation'),
    () =>
      import(
        '../../' +
          tenantDirectoryName +
          '/MyLifeEvents/MyLifeEventsPresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyLifeEvents/formComponents')
)

export default function MyLifeEvents(props) {
  return (
    <ErrorBoundary name="MyLifeEvents">
      <MyLifeEventsContainer
        {...props}
        presentation={presentationProps => (
          <MyLifeEventsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

const MyLifeEventsDoubleFetcherContainer = MultitenantLoadable(
  () => import('./MyLifeEventsContainer'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/MyLifeEvents/MyLifeEventsContainer'
    ),
  'MyLifeEventsDoubleFetcherContainer'
)

export function MyLifeEventsDoubleFetcher(props) {
  return (
    <ErrorBoundary name="MyLifeEventsDoubleFetcher">
      <MyLifeEventsDoubleFetcherContainer
        {...props}
        presentation={presentationProps => (
          <MyLifeEventsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyLifeEventsContainer = MultitenantLoadable(
  () => import('./MyLifeEventsContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyLifeEventsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyLifeEventsPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyLifeEvents(props) {
  return (
    <BaseErrorBoundary name="MyLifeEvents">
      <BaseMyLifeEventsContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyLifeEventsPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}

const BaseMyLifeEventsDoubleFetcherContainer = MultitenantLoadable(
  () => import('./MyLifeEventsContainer'),
  () => Promise.resolve().then(() => null),
  'MyLifeEventsDoubleFetcherContainer'
)

export function BaseMyLifeEventsDoubleFetcher(props) {
  return (
    <BaseErrorBoundary name="MyLifeEventsDoubleFetcher">
      <BaseMyLifeEventsDoubleFetcherContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyLifeEventsPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
