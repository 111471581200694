import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'
import {
  AppIntakeContainerProps,
  AppIntakePresentationProps
} from './AppIntake'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const AppIntakeContainer = MultitenantLoadable(
  () => import('./AppIntakeContainer'),
  () => import('../../' + tenantDirectoryName + '/AppIntake/AppIntakeContainer')
)

const AppIntakePresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./AppIntakePresentation'),
    () =>
      import(
        '../../' + tenantDirectoryName + '/AppIntake/AppIntakePresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/AppIntake/formComponents')
)

export default function AppIntake(props: AppIntakeContainerProps) {
  return (
    <ErrorBoundary name="AppIntake">
      <AppIntakeContainer
        {...props}
        presentation={(presentationProps: AppIntakePresentationProps) => (
          <AppIntakePresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseAppIntakeContainer = MultitenantLoadable(
  () => import('./AppIntakeContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseAppIntakePresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./AppIntakePresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseAppIntake(props) {
  return (
    <BaseErrorBoundary name="AppIntake">
      <BaseAppIntakeContainer
        {...props}
        presentation={presentationProps => (
          <BaseAppIntakePresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
