import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as serviceHelper from '../src/components/utils'
import { config } from '../../../config'
import * as actions from '../../../actions'
import { UnauthorizedError } from '../src/components/utils'

const gatewayWvUrl = config['gatewayWvUrl']
const loadEndpoint = gatewayWvUrl + config['getCaseBenefitDetails']
const myBenefitsSchemaEndpoint = config['forms_MyBenefits']

interface MyBenefitsContainerProps {
  presentation: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  userRoleId: number
  externalAuth?: {
    hasPin: boolean
  }
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  oldFormData: any
  myBenefitsFormData: (formData: any) => void
  myRenewalFormData: (formData: any) => void
  caseNumber: any
  roleId: any
  orgId: any
  locale: string
  logoutUser
  hasCCA
  history: {
    push: (url: string) => void
  }
}

interface MyBenefitsContainerState {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  pinCheck: boolean
  selectedBenefits: any
}
const baseUrl = process.env.PUBLIC_URL
class MyBenefitsContainer extends React.Component<
  MyBenefitsContainerProps,
  MyBenefitsContainerState
> {
  private radioId
  constructor(props: MyBenefitsContainerProps) {
    super(props)

    this.state = {
      forms: null,
      formData: null,
      formContext: null,
      pinCheck: false,
      selectedBenefits: null
    }
  }

  componentDidMount() {
    document.title = 'My Benefits - My Account'
    this._initializeForms(true)
    this._checkForPin()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      this._initializeForms(false)
    }
  }

  _checkForPin = () => {
    if (this.props.externalAuth.hasPin) {
      this.setState(prevState => {
        return {
          ...prevState,
          pinCheck: this.props.externalAuth.hasPin
        }
      })
    }
  }

  _initializeForms = (loadFormData = true) => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    serviceHelper
      .fetchJson(
        myBenefitsSchemaEndpoint.replace('{version}', '1.0'),
        request,
        this.props.showErrorMessage
      )
      .then(json => {
        const myBenefitsSchema = json
        const forms = myBenefitsSchema['forms']

        helper.cleanNullValues(forms)

        this.setState(
          {
            forms,
            formContext: {
              forms,
              formData: null,
              refs: {
                '{case summary}': ({ fieldId, formContext }) => {
                  try {
                    const index = fieldId.match('^LifeEvents_([0-9]+)_?')[1]
                    return 'Case ID ' + formContext.formData[index].caseNumber
                  } catch (err) {
                    return 'Case ID UNKNOWN'
                  }
                },
                '{benefit details}': ({ fieldId, formContext }) => {
                  try {
                    const index = fieldId.match('^myBenefits_([0-9]+)_?')[1]
                    return formContext.formData[0][index].benefitProgramName
                  } catch (err) {
                    return 'Case ID UNKNOWN'
                  }
                }
              },
              locale: this.props.locale,
              radioCheck: this._radioCheck
            }
          },
          () => {
            if (loadFormData) {
              this._getMyBenefitsJSON()
            }
          }
        )
      })
      .catch(error => {
        console.error('MyBenefits _initializeForms failed due to ex', error)
      })
  }

  _getMyBenefitsJSON = () => {
    serviceHelper
      .fetchJson(
        loadEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            userRoleId: this.props.userRoleId.toString(),
            caseNumber: this.props.caseNumber,
            roleId: this.props.roleId,
            orgId: this.props.orgId,
            programType: this.props.hasCCA ? 'CCA' : 'FA'
          }
        },
        this.props.showErrorMessage,
        0,
        120000
      )
      .then(json => {
        if (json) {
          let formData = json
          helper.cleanNullValues(formData)
          // update react state with fetched data
          this.props.myBenefitsFormData(formData)
          this._onFormDataChange({ formData })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
        }
        console.error('MyBenefits _getMyBenefitsJSON failed due to ex', error)
      })
  }
  _radioCheck = (id, prgmCode) => {
    if (id.includes('benefitsSecond')) {
      this.radioId = prgmCode
    }
  }

  _onFormDataChange = ({ formData }) => {
    const snapDetails =
      formData.length > 0
        ? formData[0].filter(bnfObj => {
            return bnfObj !== null && bnfObj.benefitProgramName === 'SNAP'
          })
        : []
    let selectedBenefits
    if (formData.benefits) {
      let newFormData = _.cloneDeep(this.state.formData)

      for (let k in newFormData[0][0]) {
        _.set(newFormData, '[' + 0 + ']' + '[' + 0 + ']' + '[' + k + ']', {
          ...newFormData[0][0][k],
          action: 'true'
        })
      }
      if (
        _.isArray(newFormData) &&
        _.isArray(newFormData[0]) &&
        _.isArray(newFormData[0][1])
      ) {
        for (let k in newFormData[0][1]) {
          _.set(newFormData, '[' + 0 + ']' + '[' + 1 + ']' + '[' + k + ']', {
            ...newFormData[0][1][k],
            action: undefined
          })
        }
      }
      if (
        _.isArray(newFormData) &&
        _.isArray(newFormData[0]) &&
        _.isArray(newFormData[0][2])
      ) {
        for (let k in newFormData[0][2]) {
          _.set(newFormData, '[' + 0 + ']' + '[' + 2 + ']' + '[' + k + ']', {
            ...newFormData[0][2][k],
            action: undefined
          })
        }
      }

      if (
        _.isArray(newFormData) &&
        _.isArray(newFormData[0]) &&
        _.isArray(newFormData[0][3])
      ) {
        for (let k in newFormData[0][3]) {
          _.set(newFormData, '[' + 0 + ']' + '[' + 3 + ']' + '[' + k + ']', {
            ...newFormData[0][3][k],
            action: undefined
          })
        }
      }
      this.setState({
        formData: newFormData,
        formContext: {
          ...this.state.formContext,
          formData: newFormData
        }
      })
    } else if (formData.benefitsSecond) {
      let newFormData = _.cloneDeep(this.state.formData)
      if (
        _.isArray(newFormData) &&
        _.isArray(newFormData[0]) &&
        _.isArray(newFormData[0][0])
      ) {
        for (let k in newFormData[0][0]) {
          _.set(newFormData, '[' + 0 + ']' + '[' + 0 + ']' + '[' + k + ']', {
            ...newFormData[0][0][k],
            action: undefined
          })
        }
      }
      if (
        _.isArray(newFormData) &&
        _.isArray(newFormData[0]) &&
        _.isArray(newFormData[0][1])
      ) {
        for (let k in newFormData[0][1]) {
          if (newFormData[0][1][k].benefitProgramCode == this.radioId) {
            _.set(newFormData, '[' + 0 + ']' + '[' + 1 + ']' + '[' + k + ']', {
              ...newFormData[0][1][k],
              action: 'true'
            })
          } else {
            _.set(newFormData, '[' + 0 + ']' + '[' + 1 + ']' + '[' + k + ']', {
              ...newFormData[0][1][k],
              action: undefined
            })
          }
        }
      }
      if (
        _.isArray(newFormData) &&
        _.isArray(newFormData[0]) &&
        _.isArray(newFormData[0][2])
      ) {
        for (let k in newFormData[0][2]) {
          _.set(newFormData, '[' + 0 + ']' + '[' + 2 + ']' + '[' + k + ']', {
            ...newFormData[0][2][k],
            action: undefined
          })
        }
      }
      this.setState({
        formData: newFormData,
        formContext: {
          ...this.state.formContext,
          formData: newFormData
        }
      })
    } else if (formData.benefitsThird) {
      let newFormDataTemp = _.cloneDeep(this.state.formData)

      if (
        _.isArray(newFormDataTemp) &&
        _.isArray(newFormDataTemp[0]) &&
        _.isArray(newFormDataTemp[0][0])
      ) {
        for (let k in newFormDataTemp[0][0]) {
          _.set(
            newFormDataTemp,
            '[' + 0 + ']' + '[' + 0 + ']' + '[' + k + ']',
            {
              ...newFormDataTemp[0][0][k],
              action: undefined
            }
          )
        }
      }
      if (
        _.isArray(newFormDataTemp) &&
        _.isArray(newFormDataTemp[0]) &&
        _.isArray(newFormDataTemp[0][1])
      ) {
        for (let k in newFormDataTemp[0][1]) {
          _.set(
            newFormDataTemp,
            '[' + 0 + ']' + '[' + 1 + ']' + '[' + k + ']',
            {
              ...newFormDataTemp[0][1][k],
              action: undefined
            }
          )
        }
      }
      if (
        _.isArray(newFormDataTemp) &&
        _.isArray(newFormDataTemp[0]) &&
        _.isArray(newFormDataTemp[0][2])
      ) {
        for (let k in newFormDataTemp[0][2]) {
          _.set(
            newFormDataTemp,
            '[' + 0 + ']' + '[' + 2 + ']' + '[' + k + ']',
            {
              ...newFormDataTemp[0][2][k],
              action: 'true'
            }
          )
        }
      }

      this.setState({
        formData: newFormDataTemp,
        formContext: {
          ...this.state.formContext,
          formData: newFormDataTemp
        }
      })
    } else if (formData.benefitsFourth) {
      let newFormDataTemp = _.cloneDeep(this.state.formData)

      if (
        _.isArray(newFormDataTemp) &&
        _.isArray(newFormDataTemp[0]) &&
        _.isArray(newFormDataTemp[0][0])
      ) {
        for (let k in newFormDataTemp[0][0]) {
          _.set(
            newFormDataTemp,
            '[' + 0 + ']' + '[' + 0 + ']' + '[' + k + ']',
            {
              ...newFormDataTemp[0][0][k],
              action: undefined
            }
          )
        }
      }
      if (
        _.isArray(newFormDataTemp) &&
        _.isArray(newFormDataTemp[0]) &&
        _.isArray(newFormDataTemp[0][1])
      ) {
        for (let k in newFormDataTemp[0][1]) {
          _.set(
            newFormDataTemp,
            '[' + 0 + ']' + '[' + 1 + ']' + '[' + k + ']',
            {
              ...newFormDataTemp[0][1][k],
              action: undefined
            }
          )
        }
      }
      if (
        _.isArray(newFormDataTemp) &&
        _.isArray(newFormDataTemp[0]) &&
        _.isArray(newFormDataTemp[0][3])
      ) {
        for (let k in newFormDataTemp[0][3]) {
          _.set(
            newFormDataTemp,
            '[' + 0 + ']' + '[' + 3 + ']' + '[' + k + ']',
            {
              ...newFormDataTemp[0][3][k],
              action: 'true'
            }
          )
        }
      }

      this.setState({
        formData: newFormDataTemp,
        formContext: {
          ...this.state.formContext,
          formData: newFormDataTemp
        }
      })
    }
    const healthCareDetails =
      formData.length > 0
        ? formData[0].filter(bnfObj => {
            return (
              bnfObj.benefitProgramName === 'Health Care Benefits' ||
              bnfObj.benefitProgramName === 'Medicare Premium Assistance'
            )
          })
        : []
    const wvWorksDetails =
      formData.length > 0
        ? formData[1].filter(bnfObj => {
            return bnfObj.benefitProgramName === 'WV WORKS'
          })
        : []
    const CCADetails =
      formData.length > 0
        ? formData[2].filter(bnfObj => {
            return bnfObj.benefitProgramName === 'Child Care'
          })
        : []
    const otherDetails =
      formData.length > 1
        ? formData[1].filter(bnfObj => {
            return bnfObj.benefitProgramName === 'Other'
          })
        : []

    let newFormData = []
    if (_.isArray(formData)) {
      for (let i = 0; i < formData.length; i++) {
        formData[i].length > 0
          ? formData[i].map(bnfObj => {
              newFormData.push({
                ...bnfObj,
                programCodeDescSummary:
                  bnfObj.benefitProgramCode &&
                  bnfObj.benefitProgramCode === 'FS P'
                    ? 'SNAP - FOOD STAMPS'
                    : bnfObj.benfitTypeSummary +
                      ' - ' +
                      (bnfObj.programCodeDescSummary
                        ? bnfObj.programCodeDescSummary
                        : bnfObj.benefitProgramCode),
                reviewStatusSummary: bnfObj.reviewDueDateSummary
              })
            })
          : []
      }
    }

    let bnfDetails = []
    if (snapDetails && snapDetails.length > 0)
      bnfDetails.push({ snap: snapDetails })
    if (healthCareDetails && healthCareDetails.length > 0)
      bnfDetails.push({ healthcare: healthCareDetails })
    if (wvWorksDetails && wvWorksDetails.length > 0)
      bnfDetails.push({ wvWorks: wvWorksDetails })
    if (otherDetails && otherDetails.length > 0)
      bnfDetails.push({ otherBenefits: otherDetails })
    if (CCADetails && CCADetails.length > 0)
      bnfDetails.push({ CCA: CCADetails })

    let benefitHistoryObjects = []
    if (_.isArray(formData)) {
      for (let i = 0; i < formData.length; i++) {
        formData[i].length > 0 &&
          formData[i].map(historyObj => {
            let extractedObject = {
              caseNumber: historyObj.caseNumber,
              benefitType: historyObj.benefitProgramName,
              historyArray: historyObj.benefitHistory
            }
            benefitHistoryObjects.push(extractedObject)
          })
      }
    }
    const presentationObjects =
      benefitHistoryObjects.length > 0
        ? benefitHistoryObjects.map(extractObj => {
            const historyObjects = extractObj.historyArray
              ? extractObj.historyArray.length > 0 &&
                extractObj.historyArray.map(bnf => {
                  return {
                    caseNumber: extractObj.caseNumber,
                    benefitType: extractObj.benefitType,
                    ...bnf
                  }
                })
              : []
            return historyObjects
          })
        : []
    const mergedArrays =
      presentationObjects.length > 0 && [].concat.apply([], presentationObjects)
    if (
      !formData.benefits &&
      !formData.benefitsSecond &&
      !formData.benefitsThird &&
      !formData.benefitsFourth
    ) {
      this.setState(prevState => {
        const { formData: stateFormData } = prevState
        if (_.get(stateFormData, ['benefits'], [].length > 0)) {
          _.map(stateFormData.benefits, (benefit, key) => {
            if (benefit.action) {
              delete formData.benefits[key].action
            }
          })
        }
        return {
          formData: [formData, mergedArrays, bnfDetails, newFormData],
          formContext: {
            ...prevState.formContext,
            formData: [formData, mergedArrays, bnfDetails, newFormData]
          }
        }
      })
      this.props.myBenefitsFormData(this.state.formData)
    }
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    const { presentation, errorMessage, oldFormData } = this.props
    const {
      formData,
      formContext,
      forms,
      pinCheck,
      selectedBenefits
    } = this.state

    const presentationProps = {
      formData,
      formContext,
      forms,
      onFormDataChange: this._onFormDataChange,
      pinCheck,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      oldFormData,
      selectedBenefits
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let oldFormData = state.mybenifitsapplication.mybenifitsFormData || null
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''

  return {
    auth: state.auth,
    userRoleId,
    externalAuth: state.externalAuth,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || [],
    oldFormData,
    caseNumber: _.get(state, 'myCases.caseNumber'),
    roleId,
    orgId,
    hasCCA: _.get(state, 'hasCCA.hasCCA'),
    locale: state.i18n.locale
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loginUser: (code, checkHasPin) => {
      dispatch(actions.loginUser(code, checkHasPin))
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    errorListUpdate: errorList => {
      dispatch(actions.errorListUpdate(errorList))
    },
    myBenefitsFormData: formData => {
      dispatch(actions.myBenefitsFormData(formData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBenefitsContainer)
