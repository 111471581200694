import * as React from 'react'
import * as _ from 'lodash'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { getServerTime } from '../src/components/utils'
const moment = require('moment-mini-ts')
const moment_timezone = require('moment-timezone')
import { config } from '../../../config'

// Code changes for GPRB0359133 and US2558381
function validBirthDateCheck(birthdate, currentDate) {
  let currESTDT = moment_timezone
    .tz(currentDate, 'America/New_York')
    .format('YYYY-MM-DD')
  if (birthdate < '1900-01-01' || birthdate > currESTDT) {
    return true
  }
  return false
}

// Code Changes GPRB0359078 and US2554056
function validZipCodeCheck(zip) {
  if (String(zip).length > 0 && String(zip).length < 5) {
    return true
  }
}

// Code Changes GPRB0359223 and US2819645
function validSSN(ssn) {
  let temp = ssn
  if (ssn.length > 0 && ssn.length < 9) {
    return false
  }
  if (
    temp.substring(0, 3) === '000' ||
    (temp.substring(0, 3) >= '900' && temp.substring(0, 3) <= '999') ||
    temp.substring(0, 3) === '666'
  ) {
    return false
  }
  if (temp.substring(3, 5) === '00' || temp.substring(5, 9) === '0000') {
    return false
  }
  return true
}

export const customValidationErrors = (
  formContext,
  errors,
  formData,
  id,
  trackerName,
  errorMessage
) => {
  const clientsArray = formContext.formData.application.clients.map(
    (client, index) => {
      const name =
        client.clientDetails.personalData[0].clientFstNm &&
        client.clientDetails.personalData[0].clientLstNm
          ? client.clientDetails.personalData[0].clientFstNm +
            ' ' +
            client.clientDetails.personalData[0].clientLstNm +
            '-'
          : 'Person ' + (index + 1) + '-'
      return name
    }
  )
  const applicantName = formContext.formData.application.clients.map(
    (client, index) => {
      const name =
        client.clientDetails.personalData[0].clientFstNm &&
        client.clientDetails.personalData[0].clientLstNm
          ? client.clientDetails.personalData[0].clientFstNm +
            ' ' +
            client.clientDetails.personalData[0].clientLstNm
          : ' '
      return name
    }
  )

  const errorId =
    id.match('(?:.*)clients_([0-9]+)_?') &&
    id.match('(?:.*)clients_([0-9]+)_?')[1]
  if (id.match('_clientAdditionalInformation_disability')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['disabilityBenefits'] === undefined ||
          _.trim(row['disabilityBenefits']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_disabilityBenefits'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].disabilityBenefits',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrddisabilityBenefits']
        })
      }
      if (
        (row['disabilityType'] === undefined ||
          _.trim(row['disabilityType']) === '') &&
        row['isNew'] &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_disabilityType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].disabilityType',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrddisabilityType']
        })
      }
      if (
        (row['disabilityStartDate'] === undefined ||
          _.trim(row['disabilityStartDate']) === '') &&
        row['isNew'] &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_disabilityStartDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].disabilityStartDate',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrddisabilityStartDate']
        })
      }
    })
  }
  if (id.match('_clientAdditionalInformation_pregnancy')) {
    const errorId =
      id.match('(?:.*)clients_([0-9]+)_?') &&
      id.match('(?:.*)clients_([0-9]+)_?')[1]

    _.forEach(formData, (row, index) => {
      const clientPersonalDetails = _.get(
        formContext.formData,
        `application.clients[${errorId}].clientDetails.personalData[0]`,
        []
      )
      let genderCheck = clientPersonalDetails.gdrCd
      let femaleOverTen
      let birthdate = _moment(config, clientPersonalDetails.clientBthDt)
      let cur = _moment(config, formContext.currentDate)
      if (cur.diff(birthdate, 'years') >= 10) {
        femaleOverTen = true
      } else {
        femaleOverTen = false
      }

      if (
        (row['anticipatedDueDate'] === undefined ||
          _.trim(row['anticipatedDueDate']) === '') &&
        genderCheck != '1' &&
        femaleOverTen &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_anticipatedDueDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].anticipatedDueDate',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdanticipatedDueDate']
        })
      }
      if (
        (row['numberOfBabies'] === undefined ||
          _.trim(row['numberOfBabies']) === '') &&
        genderCheck != '1' &&
        femaleOverTen &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_numberOfBabies'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].numberOfBabies',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClientUpdateWizardHowmanybabiesexpected']
        })
      }
    })
  }
  if (id.match('clientAdditionalInformation_education')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['levelOfEducation'] === undefined ||
          _.trim(row['levelOfEducation']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_levelOfEducation'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].levelOfEducation',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdHighestlevelofeducation']
        })
      }
      if (
        (row['enrolledRightNow'] === undefined ||
          _.trim(row['enrolledRightNow']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_enrolledRightNow'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].enrolledRightNow',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdyouinschoolrightnow']
        })
      }
      if (
        row['enrolledRightNow'] !== '4' &&
        row['nameOfTheSchool'] === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_nameOfTheSchool'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].nameOfTheSchool',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdNametheschoolattend']
        })
      }
      if (
        row['enrolledRightNow'] !== '4' &&
        row['typeOfSchool'] === undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_typeOfSchool'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].typeOfSchool',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdTypeofSchool']
        })
      }
    })
  }
  if (id.match('clientAdditionalInformation_healthInsurance')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['insuranceCompany'] === undefined ||
          _.trim(row['insuranceCompany']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_insuranceCompany'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].insuranceCompany',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdInsuranceCompanyName']
        })
      }
      if (
        (row['nameOfThePerson'] === undefined ||
          _.trim(row['nameOfThePerson']) === '' ||
          row['nameOfThePerson'].length < 1) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_nameOfThePerson'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].nameOfThePerson',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdWhoelseiscovered']
        })
      }
      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Health Insurance Policy Address - Zip Code validation
      if (
        row['address'] &&
        row['address']['zip'] &&
        row['address']['zip'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].address.zip',
            message: 'Must be five digits.',
            stack: clientsArray[errorId] + errorMessage['ZipCodefivedigits']
          })
        }
      }
    })
  }

  if (id.match('clientDetails_personalData')) {
    let validMaritalStatus = false
    let currentProgram =
      formContext && formContext.prgNames && formContext.prgNames.split(',')
    if (currentProgram && currentProgram.length > 0) {
      if (
        _.includes(currentProgram, 'Medicare Premium Assistance') ||
        _.includes(currentProgram, 'SNAP') ||
        _.includes(currentProgram, 'QMB')
      ) {
        validMaritalStatus = true
      }
    }

    _.forEach(formData, (row, index) => {
      if (
        (row['clientFstNm'] === undefined ||
          _.trim(row['clientFstNm']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientFstNm'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientFstNm',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['FirstNamerequired']
        })
      }
      if (
        (row['clientLstNm'] === undefined ||
          _.trim(row['clientLstNm']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientLstNm'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientLstNm',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['LastNamerequired']
        })
      }
      //US1801346 Story
      if (
        row['gdrCd'] === undefined &&
        document.getElementById(id + '_' + trackerName + '_' + index + '_gdrCd')
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].gdrCd',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdSelectGender']
        })
      }
      if (
        (row['clientBthDt'] === undefined ||
          _.trim(row['clientBthDt']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientBthDt'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientBthDt',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['Application_clientDt']
        })
      }

      // Code changes for GPRB0359133 and US2558381
      if (
        row['clientBthDt'] &&
        row['clientBthDt'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientBthDt'
        )
      ) {
        if (
          validBirthDateCheck(
            _.trim(row['clientBthDt']),
            formContext.currentDate
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].clientBthDt',
            message: 'Invalid Date of Birth',
            stack:
              clientsArray[errorId] +
              errorMessage['Application_clientDtfuturedate']
          })
        }
      }

      // Code changes GPRB0359223 and US2819645
      // Personal Personal Data SSN Validation
      if (
        row['clientSsn'] &&
        row['clientSsn'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientSsn'
        )
      ) {
        if (!validSSN(_.trim(row['clientSsn']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].clientSsn',
            message: 'Invalid SSN',
            stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdInvalidSSN']
          })
        }
      }

      if (
        // (!formContext.isRenewal || validMaritalStatus) &&
        (row['mrtlStatusCd'] === undefined ||
          _.trim(row['mrtlStatusCd']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_mrtlStatusCd'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].mrtlStatusCd',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdpersonmaritalstatus']
        })
      }
      //End
    })
  }

  if (id.match('clientDetails_residenceInformation')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['clientRemInStIntnFlg'] === undefined ||
          _.trim(row['clientRemInStIntnFlg']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientRemInStIntnFlg'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientRemInStIntnFlg',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdintendtoresideinWV']
        })
      }
      if (
        (row['addressSpecificationCode'] === undefined ||
          _.trim(row['addressSpecificationCode']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_addressSpecificationCode'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].addressSpecificationCode',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdWhereYouLive']
        })
      }
    })
  }
  //added by ravi
  if (id.match('clientDetails_citizenshipImmigration')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['usCtznOrNatInd'] === undefined ||
          _.trim(row['usCtznOrNatInd']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_usCtznOrNatInd'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].usCtznOrNatInd',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdIsThisPersonUScitizen']
        })
      }
      if (row['usCtznOrNatInd'] == 'N') {
        if (
          (row['countrymoved'] === undefined ||
            _.trim(row['countrymoved']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_countrymoved'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].countrymoved',
            message: errorMessage['Thisfieldisrequired'],
            stack:
              clientsArray[errorId] +
              errorMessage['ClntUpdWzrdWhichcountrythispersonfrom']
          })
        }
      }
      if (row['usCtznOrNatInd'] == 'N') {
        if (
          (row['dtOfEntToUS'] === undefined ||
            _.trim(row['dtOfEntToUS']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_dtOfEntToUS'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].dtOfEntToUS',
            message: errorMessage['Thisfieldisrequired'],
            stack:
              clientsArray[errorId] +
              errorMessage['ClntUpdWzrdWhenthispersonComeLive']
          })
        }
      }
    })
  }
  //END

  // Code changes for GPRB0359133 and US2558381
  if (id.match('clientDetails_taxInformation')) {
    _.forEach(formData, (row, index) => {
      if (
        row['otherPersonBthDt'] &&
        row['otherPersonBthDt'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherPersonBthDt'
        )
      ) {
        if (
          validBirthDateCheck(
            _.trim(row['otherPersonBthDt']),
            formContext.currentDate
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].otherPersonBthDt',
            message: 'Invalid Date of Birth',
            stack:
              clientsArray[errorId] + errorMessage['BirthDatecannotbeprior']
          })
        }
      }
      // Code changes for GPRB0359223 and US2819645
      // Personal Tax Information SSN Validation
      if (
        row['otherPersonSsn'] &&
        row['otherPersonSsn'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherPersonSsn'
        )
      ) {
        if (!validSSN(_.trim(row['otherPersonSsn']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].otherPersonSsn',
            message: 'Invalid SSN',
            stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdInvalidSSN']
          })
        }
      }
    })
  }

  if (id.match('clientDetails_otherInformation')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['drugFelony'] === undefined || _.trim(row['drugFelony']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_drugFelony'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].drugFelony',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdfelondrug']
        })
      }
      if (
        (row['probation'] === undefined || _.trim(row['probation']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_probation'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].probation',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdviolatedconditionsparole']
        })
      }
      if (
        (row['jailFelony'] === undefined || _.trim(row['jailFelony']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_jailFelony'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].jailFelony',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdtryingtoavoidprosecution']
        })
      }

      if (
        (row['domesticVoilence'] === undefined ||
          _.trim(row['domesticVoilence']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_domesticVoilence'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].domesticVoilence',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdvictimofdomesticviolence']
        })
      }
      if (
        (row['snapFromOtherState'] === undefined ||
          _.trim(row['snapFromOtherState']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapFromOtherState'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapFromOtherState',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdreceivedyoucurrentlyreceivingSNAP']
        })
      }
      if (
        (row['snapOffense'] === undefined ||
          _.trim(row['snapOffense']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapOffense'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapOffense',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdconvictedSNAPoffense']
        })
      }

      if (
        (row['snapTrafficking500'] === undefined ||
          _.trim(row['snapTrafficking500']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapTrafficking500'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapTrafficking500',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdhouseholdconvicted']
        })
      }
      if (
        (row['snapForIllegal'] === undefined ||
          _.trim(row['snapForIllegal']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapForIllegal'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapForIllegal',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdhouseholdconvictedexplosive']
        })
      }
      if (
        (row['1996DrugFelony'] === undefined ||
          _.trim(row['1996DrugFelony']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_1996DrugFelony'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].1996DrugFelony',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdfelonydrugconviction']
        })
      }
      if (
        (row['snapLying'] === undefined || _.trim(row['snapLying']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_snapLying'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].snapLying',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdsnapLying']
        })
      }
      if (
        (row['isAdultFlny'] === undefined ||
          _.trim(row['isAdultFlny']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_isAdultFlny'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].isAdultFlny',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdisAdultFlny']
        })
      }

      if (row['isAdultFlny'] == 'Y') {
        if (
          (row['isAdultComplnce'] === undefined ||
            _.trim(row['isAdultComplnce']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_isAdultComplnce'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].isAdultComplnce',
            message: errorMessage['Thisfieldisrequired'],
            stack:
              clientsArray[errorId] + errorMessage['ClntUpdWzrdisAdultComplnce']
          })
        }
      }
    })
  }
  if (id.match('clientIncomes_earnedIncomes')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['frequency'] === undefined || _.trim(row['frequency']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_frequency'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].frequency',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdfrequency']
        })
      }
      if (
        (row['hoursWorked'] === undefined ||
          _.trim(row['hoursWorked']) === '' ||
          isNaN(parseInt(row['hoursWorked']))) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_hoursWorked'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].hoursWorked',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdhoursWorked']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdamount']
        })
      }
      if (
        row['incomeSource'] &&
        (row['incomeSource']['incomeSourceName'] === undefined ||
          _.trim(row['incomeSource']['incomeSourceName']) === '') &&
        document.getElementById(
          id +
            '_' +
            trackerName +
            '_' +
            index +
            '_incomeSource_incomeSourceName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].incomeSource.incomeSourceName',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdincomeSourceName']
        })
      }

      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Employer Address - Zip Code validation
      if (
        row['incomeSource'] &&
        row['incomeSource']['address'] &&
        row['incomeSource']['address']['zip'] &&
        row['incomeSource']['address']['zip'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_incomeSource_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['incomeSource']['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property:
              '.' + trackerName + '[' + index + '].incomeSource.address.zip',
            message: 'Must be five digits.',
            stack:
              clientsArray[errorId] +
              errorMessage['ClntUpdWzrdincomeSourceaddresszip']
          })
        }
      }

      if (
        (row['workHoursReduction'] === undefined ||
          _.trim(row['workHoursReduction']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_workHoursReduction'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].workHoursReduction',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdworkHoursReduction']
        })
      }
      if (
        (row['refuseEmployment'] === undefined ||
          _.trim(row['refuseEmployment']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_refuseEmployment'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].refuseEmployment',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdrefuseEmployment']
        })
      }
    })
  }
  if (id.match('clientIncomes_selfEmploymentIncomes')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['incomeType'] === undefined || _.trim(row['incomeType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_incomeType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].incomeType',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdincomeType']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdamount1'] +
            applicantName[errorId] +
            errorMessage['ClntUpdWzrdamount2'] +
            applicantName[errorId] +
            errorMessage['ClntUpdWzrdamount3']
        })
      }
      if (
        (row['expense'] === undefined || _.trim(row['expense']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_expense'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].expense',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdexpense1'] +
            applicantName[errorId] +
            errorMessage['ClntUpdWzrdexpense2']
        })
      }
    })
  }
  if (id.match('clientIncomes_unearnedIncomes')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['incomeType'] === undefined || _.trim(row['incomeType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_incomeType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].incomeType',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdUnearnedincomeType']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdincomereceived']
        })
      }
      if (
        (row['unearnedfrequency'] === undefined ||
          _.trim(row['unearnedfrequency']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_unearnedfrequency'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].unearnedfrequency',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdfrequency']
        })
      }
      if (
        (row['frequency'] === undefined || _.trim(row['frequency']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_frequency'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].frequency',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdfrequency']
        })
      }
      if (
        (row['moneyRoomMeals'] === undefined ||
          _.trim(row['moneyRoomMeals']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_moneyRoomMeals'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].moneyRoomMeals',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdmoneyRoomMeals']
        })
      }
    })
  }
  if (id.match('clientExpenses_heatingExpenses')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['payOwn'] === undefined || _.trim(row['payOwn']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_payOwn'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].payOwn',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdpayOwn']
        })
      }
      if (
        (row['payOwnFstName'] === undefined ||
          _.trim(row['payOwnFstName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_payOwnFstName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].payOwnFstName',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['FirstNamerequired']
        })
      }
      if (
        (row['payOwnLstName'] === undefined ||
          _.trim(row['payOwnLstName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_payOwnLstName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].payOwnLstName',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['LastNamerequired']
        })
      }
      if (
        (row['includedInRent'] === undefined ||
          _.trim(row['includedInRent']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_includedInRent'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].includedInRent',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdincludedInRent']
        })
      }
      if (
        (row['fuelType'] === undefined || _.trim(row['fuelType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_fuelType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].fuelType',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdfuelType']
        })
      }
      if (
        (row['shareThisExpense'] === undefined ||
          _.trim(row['shareThisExpense']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_shareThisExpense'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].shareThisExpense',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdshareThisExpense']
        })
      }
      if (
        (row['assistance'] === undefined || _.trim(row['assistance']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_assistance'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].assistance',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdassistance']
        })
      }
      if (
        (row['when'] === undefined || _.trim(row['when']) === '') &&
        document.getElementById(id + '_' + trackerName + '_' + index + '_when')
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].when',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdwhen']
        })
      }
    })
  }
  if (id.match('clientExpenses_childSupport')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['paymentOrdered'] === undefined ||
          _.trim(row['paymentOrdered']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_paymentOrdered'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].paymentOrdered',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdpaymentOrdered']
        })
      }
      if (
        (row['paymentAmount'] === undefined ||
          _.trim(row['paymentAmount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_paymentAmount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].paymentAmount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdpaymentAmount']
        })
      }
    })
  }
  if (id.match('clientExpenses_dependentCareExpenses')) {
    _.forEach(formData, (row, index) => {
      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Dependent Care - Zip Code validation
      if (
        row['address'] &&
        row['address']['zip'] &&
        row['address']['zip'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].address.zip',
            message: 'Must be five digits.',
            stack: clientsArray[errorId] + errorMessage['ZipCodefivedigits']
          })
        }
      }
      if (
        row['dependent'] &&
        (row['dependent']['payForCare'] === undefined ||
          _.trim(row['dependent']['payForCare']) === '' ||
          row['dependent']['payForCare'].length < 1) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_dependent_payForCare'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].dependent.payForCare',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrddependentpayForCare']
        })
      }
      if (
        row['dependent'] &&
        (row['dependent']['paymentFrequency'] === undefined ||
          _.trim(row['dependent']['paymentFrequency']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_dependent_paymentFrequency'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].dependent.paymentFrequency',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrddependentpaymentFrequency']
        })
      }
      if (
        row['dependent'] &&
        (row['dependent']['paymentAmount'] === undefined ||
          _.trim(row['dependent']['paymentAmount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_dependent_paymentAmount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].dependent.paymentAmount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrddependentpaymentAmount']
        })
      }
    })
  }
  if (id.match('clientExpenses_coolingExpenses')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['payAirCondition'] === undefined ||
          _.trim(row['payAirCondition']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_payAirCondition'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].payAirCondition',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdpayAirCondition']
        })
      }
      if (
        (row['includeInRent'] === undefined ||
          _.trim(row['includeInRent']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_includeInRent'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].includeInRent',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdincludeInRent']
        })
      }
    })
  }
  if (id.match('clientExpenses_utilityExpenses')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['billPaidTo'] === undefined || _.trim(row['billPaidTo']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_billPaidTo'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].billPaidTo',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdbillPaidTo']
        })
      }
      if (
        (row['utilityCost'] === undefined ||
          _.trim(row['utilityCost']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_utilityCost'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].utilityCost',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdutilityCost']
        })
      }
      if (
        (row['utilityType'] === undefined ||
          _.trim(row['utilityType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_utilityType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].utilityType',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdutilityType']
        })
      }

      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Utility Cost - Zip Code validation
      if (
        row['address'] &&
        row['address']['zip'] &&
        row['address']['zip'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].address.zip',
            message: 'Must be five digits.',
            stack: clientsArray[errorId] + errorMessage['ZipCodefivedigits']
          })
        }
      }
    })
  }
  if (id.match('clientExpenses_shelterExpenses')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['shelterType'] === undefined ||
          _.trim(row['shelterType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_shelterType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].shelterType',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdshelterType']
        })
      }
      if (
        (row['shelterCost'] === undefined ||
          _.trim(row['shelterCost']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_shelterType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].shelterCost',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdshelterCost']
        })
      }
      if (
        (row['billPaidTo'] === undefined || _.trim(row['billPaidTo']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_billPaidTo'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].billPaidTo',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdbillPaidTo']
        })
      }
      // Code Changes GPRB0359078 and US2554056
      // COC and Renewals - Shelter Cost - Zip Code validation
      if (
        row['address'] &&
        row['address']['zip'] &&
        row['address']['zip'] !== undefined &&
        _.trim(row['address']['zip']).length > 0 &&
        _.trim(row['address']['zip']).length < 5 &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_address_zip'
        )
      ) {
        if (validZipCodeCheck(_.trim(row['address']['zip']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].address.zip',
            message: 'Must be five digits.',
            stack: clientsArray[errorId] + errorMessage['ZipCodefivedigits']
          })
        }
      }
    })
  }
  if (id.match('clientExpenses_deduction')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['deductionType'] === undefined ||
          _.trim(row['deductionType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_deductionType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].deductionType',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrddeductionType']
        })
      }
      if (
        (row['frequency'] === undefined || _.trim(row['frequency']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_frequency'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].frequency',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdfrequency2'] +
            applicantName[errorId] +
            errorMessage['ClntUpdWzrdfrequency3']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdamount5'] +
            applicantName[errorId] +
            errorMessage['ClntUpdWzrdamount6']
        })
      }
    })
  }
  if (id.match('clientAssets_bankingAccounts')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['financialInstitutionName'] === undefined ||
          _.trim(row['financialInstitutionName']) == '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_financialInstitutionName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].financialInstitutionName',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdfinancialInstitutionName']
        })
      }
      if (
        (row['accountType'] === undefined ||
          _.trim(row['accountType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountType',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdaccountType']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdvalueofyouraccount']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdaccountAccesible']
        })
      }
      if (
        (row['assetBurial'] === undefined ||
          _.trim(row['assetBurial']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_assetBurial'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].assetBurial',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdassetBurial']
        })
      }
    })
  }
  if (id.match('clientAssets_cash')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdWhatvalueofCash']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdISCashAccesibleForYou']
        })
      }
      if (
        (row['assetBurial'] === undefined ||
          _.trim(row['assetBurial']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_assetBurial'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].assetBurial',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdassetBurial']
        })
      }
    })
  }

  if (id.match('clientAssets_trusts')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdtrustfundAmount']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdaccountAccesible2']
        })
      }
      if (
        (row['assetBurial'] === undefined ||
          _.trim(row['assetBurial']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_assetBurial'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].assetBurial',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdassetBurial']
        })
      }
    })
  }
  if (id.match('clientAssets_investmentAccounts')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['accountType'] === undefined ||
          _.trim(row['accountType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountType',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdaccountType']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdvalueofyouraccount']
        })
      }
      if (
        (row['financialInstitutionName'] === undefined ||
          _.trim(row['financialInstitutionName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_financialInstitutionName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].financialInstitutionName',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdfinancialInstitutionName']
        })
      }
      if (
        (row['accountAccesible'] === undefined ||
          _.trim(row['accountAccesible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accountAccesible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accountAccesible',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdaccountAccesible']
        })
      }
      if (
        (row['assetBurial'] === undefined ||
          _.trim(row['assetBurial']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_assetBurial'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].assetBurial',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdassetBurial']
        })
      }
    })
  }
  if (id.match('clientAssets_lumpsumPayment')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['lumpsumType'] === undefined ||
          _.trim(row['lumpsumType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_lumpsumType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].lumpsumType',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdlumpsumType']
        })
      }
      if (
        (row['startDate'] === undefined || _.trim(row['startDate']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_startDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].startDate',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdreceivestartDate']
        })
      }
      if (
        (row['getAgain'] === undefined || _.trim(row['getAgain']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_getAgain'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].getAgain',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdgetAgain']
        })
      }
    })
  }
  if (id.match('clientAssets_vehicles')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['typeOfVehicle'] === undefined ||
          _.trim(row['typeOfVehicle']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_typeOfVehicle'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].typeOfVehicle',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdtypeOfVehicle']
        })
      }
      if (
        (row['fairMarketValue'] === undefined ||
          _.trim(row['fairMarketValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_fairMarketValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].fairMarketValue',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdfairMarketValue']
        })
      }
      if (
        (row['equityValue'] === undefined ||
          _.trim(row['equityValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_equityValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].equityValue',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdequityValue']
        })
      }
      if (
        (row['accessible'] === undefined || _.trim(row['accessible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accessible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accessible',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdaccessible']
        })
      }
      if (
        row['vehicleYear'] != undefined &&
        _.trim(row['vehicleYear']) != '' &&
        _.trim(row['vehicleYear']).length > 0 &&
        _.trim(row['vehicleYear']).length < 4 &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_vehicleYear'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].vehicleYear',
          message: 'Must be four digits.',
          stack: clientsArray[errorId] + errorMessage['vehicleYear']
        })
      }
    })
  }
  if (id.match('clientAssets_soldtransferAsset')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['soldtransferType'] === undefined ||
          _.trim(row['soldtransferType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_soldtransferType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].soldtransferType',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdsoldtransferType']
        })
      }
      if (
        (row['startDate'] === undefined || _.trim(row['startDate']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_startDate'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].startDate',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdTransferDate']
        })
      }
      if (
        (row['fairMarketValue'] === undefined ||
          _.trim(row['fairMarketValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_fairMarketValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].fairMarketValue',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdValueofAsset']
        })
      }
      if (
        (row['equityValue'] === undefined ||
          _.trim(row['equityValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_equityValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].equityValue',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            errorMessage['ClntUpdWzrdAmountreceivedasset']
        })
      }
    })
  }
  if (id.match('clientAssets_otherAssets')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['otherType'] === undefined || _.trim(row['otherType']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherType'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].otherType',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdotherType']
        })
      }
      if (
        (row['amount'] === undefined || _.trim(row['amount']) == '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_amount'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].amount',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdfairMarketValue']
        })
      }
      if (
        (row['fairMarketValue'] === undefined ||
          _.trim(row['fairMarketValue']) == '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_fairMarketValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].fairMarketValue',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdfairMarketValue']
        })
      }
      if (
        (row['equityValue'] === undefined ||
          _.trim(row['equityValue']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_equityValue'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].equityValue',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdequityValue']
        })
      }
      if (
        (row['accessible'] === undefined || _.trim(row['accessible']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_accessible'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].accessible',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdaccessible']
        })
      }
    })
  }
  //story US1801346
  if (id.match('_clientDetails_relationShip')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['relClientName'] === undefined ||
          _.trim(row['relClientName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_relClientName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].relClientName',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdrelClientName']
        })
      }
      if (
        (row['relationName'] === undefined ||
          _.trim(row['relationName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_relationName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].relationName',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdrelClientName']
        })
      }
      if (
        (row['foodMeals'] === undefined || _.trim(row['foodMeals']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_foodMeals'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].foodMeals',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + errorMessage['ClntUpdWzrdfoodMeals']
        })
      }
    })
  }
  if (id.match('_clientDetails_language')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['spokenLanguage'] === undefined ||
          _.trim(row['spokenLanguage']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_spokenLanguage'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].spokenLanguage',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] + errorMessage['ClntUpdWzrdspokenLanguage']
        })
      }
    })
  }
  if (id.match('_clientDetails_ethnicityRace')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['ethnicOrigin'] === undefined ||
          _.trim(row['ethnicOrigin']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_ethnicOrigin'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].ethnicOrigin',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            `Please select \"Is this person Hispanic or Latino\""`
        })
      }

      if (
        (row['raceCd'] === undefined || row['raceCd'].length === 0) &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_raceCd'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].raceCd',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            `Please select \"What is this person's Race\""`
        })
      }

      if (
        (row['dsctOfAmInAlNat'] === undefined ||
          _.trim(row['dsctOfAmInAlNat']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_dsctOfAmInAlNat'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].dsctOfAmInAlNat',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            'Please select "Descent of American Indian/Alaskan Native"'
        })
      }
      if (
        (row['nameOfTribe'] === undefined ||
          _.trim(row['nameOfTribe']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_nameOfTribe'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].nameOfTribe',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + 'Please select "Name of The Tribe"'
        })
      }
      if (
        (row['otherTribeName'] === undefined ||
          _.trim(row['otherTribeName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_otherTribeName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].otherTribeName',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + 'Please answer "Other Tribe Name"'
        })
      }
      if (
        (row['stateOfTheTribe'] === undefined ||
          _.trim(row['stateOfTheTribe']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_stateOfTheTribe'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].stateOfTheTribe',
          message: errorMessage['Thisfieldisrequired'],
          stack: clientsArray[errorId] + 'Please select "State of the Tribe"'
        })
      }
      if (
        (row['enrlInFdrOrVllgProg'] === undefined ||
          _.trim(row['enrlInFdrOrVllgProg']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_enrlInFdrOrVllgProg'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].enrlInFdrOrVllgProg',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            'Please select "Enrolled in Federal or Village Program"'
        })
      }
      if (
        (row['mmbrOfFedRecogTribe'] === undefined ||
          _.trim(row['mmbrOfFedRecogTribe']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_mmbrOfFedRecogTribe'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].mmbrOfFedRecogTribe',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            'Please select "Member of Federally Recognized Tribe"'
        })
      }
      if (
        (row['receivedServiceThroughIHS'] === undefined ||
          _.trim(row['receivedServiceThroughIHS']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_receivedServiceThroughIHS'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].receivedServiceThroughIHS',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            'Please select "Received a service from the Indian Health Service"'
        })
      }
      if (
        (row['eligibleForServiceThroughIHS'] === undefined ||
          _.trim(row['eligibleForServiceThroughIHS']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_eligibleForServiceThroughIHS'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property:
            '.' + trackerName + '[' + index + '].eligibleForServiceThroughIHS',
          message: errorMessage['Thisfieldisrequired'],
          stack:
            clientsArray[errorId] +
            'Please select "Eligible to get services from the Indian Health Service"'
        })
      }
    })
  }
}
