import { I18n } from 'react-redux-i18n'
import { isEmpty, get, isNil } from 'lodash'
const _ = {
  isEmpty,
  get,
  isNil
}
export default function translateMyDocumentData(formData) {
  const newFormData = {
    ...formData,
    Application: {
      ...formData.Application,
      clients: (_.get(formData, 'Application.clients') || []).map(client => {
        return {
          ...client,
          myDocuments: (client.myDocuments || []).map(myDocument => {
            if (!_.isNil(myDocument)) {
              let docClssNm
              switch (myDocument.docClssNm) {
                case 'Demographics':
                  docClssNm = I18n.t('MyDocuments.demographics')
                  break
                case 'Income':
                  docClssNm = I18n.t('MyDocuments.income')
                  break
                case 'Assets':
                  docClssNm = I18n.t('MyDocuments.assets')
                  break
                case 'Expenses':
                  docClssNm = I18n.t('MyDocuments.expenses')
                  break
                case 'Additional Information':
                  docClssNm = I18n.t('MyDocuments.addInfo')
                  break
                default:
                  docClssNm = myDocument.docClssNm
              }
              return {
                ...myDocument,
                docClssNm,
                docList: (myDocument.docList || []).map(doc => {
                  return {
                    ...doc,
                    fileType: I18n.t('General.documentTypes.' + doc.typeRefCode)
                  }
                })
              }
            }
          })
        }
      })
    }
  }
  return newFormData
}
