export default function myBenH(
  state = { referralId: null, eligibleId: null },
  action
) {
  switch (action.type) {
    case 'ADD_REFERRAL':
      return {
        ...state,
        referralId: action.referralId
      }

    case 'REMOVE_REFERRAL':
      return {
        ...state,
        referralId: null
      }
    case 'ADD_ELIGIBLE':
      return {
        ...state,
        eligibleId: action.eligibleId
      }

    case 'REMOVE_ELIGIBLE':
      return {
        ...state,
        eligibleId: null
      }
    default:
      return state
  }
}
