import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyBenefitsContainer = MultitenantLoadable(
  () => import('./MyBenefitsContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/MyBenefits/MyBenefitsContainer')
)

const MyBenefitsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyBenefitsPresentation'),
    () =>
      import(
        '../../' + tenantDirectoryName + '/MyBenefits/MyBenefitsPresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyBenefits/formComponents')
)

export default function MyBenefits(props) {
  return (
    <ErrorBoundary name="MyBenefits">
      <MyBenefitsContainer
        {...props}
        presentation={presentationProps => (
          <MyBenefitsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyBenefitsContainer = MultitenantLoadable(
  () => import('./MyBenefitsContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyBenefitsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyBenefitsPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyBenefits(props) {
  return (
    <BaseErrorBoundary name="MyBenefits">
      <BaseMyBenefitsContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyBenefitsPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
