import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyDashboardContainer = MultitenantLoadable(
  () => import('./MyDashboardContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/MyDashboard/MyDashboardContainer')
)

const MyDashboardPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyDashboardPresentation'),
    () =>
      import(
        '../../' + tenantDirectoryName + '/MyDashboard/MyDashboardPresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyDashboard/formComponents')
)

export default function MyDashboard(props) {
  return (
    <ErrorBoundary name="MyDashboard">
      <MyDashboardContainer
        {...props}
        presentation={presentationProps => (
          <MyDashboardPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyDashboardContainer = MultitenantLoadable(
  () => import('./MyDashboardContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyDashboardPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyDashboardPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyDashboard(props) {
  return (
    <BaseErrorBoundary name="MyDashboard">
      <BaseMyDashboardContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyDashboardPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
