import * as React from 'react'
import { I18n } from 'react-redux-i18n'

function OnNextButton(props) {
  let id = `ClientUpdateWizard_nextBtn_${props.index + 1}`
  let id_submit =
    props.index === 6
      ? 'ClientUpdateWizard_submitBtn'
      : `ClientUpdateWizard_submitBtn_${props.index + 1}`
  let submitButton
  if (props.step === props.forms.length) {
    submitButton = (
      <button
        id={id_submit}
        type="submit"
        className="btn btn-default float-right sp-md"
        disabled={props.callingApi}
      >
        {I18n.t('General.submitButton')}
      </button>
    )
  } else if (props.step !== 1 || (props.showNext && props.step == 1)) {
    if (props.showNext && props.step == 1) {
      submitButton = props.hasError ? (
        <button
          id={id}
          type="submit"
          className="btn btn-default float-right sp-md nextButton btn-danger"
          disabled={props.callingApi}
          aria-label="There are errors on this page"
        >
          {I18n.t('General.labelErrors')}
        </button>
      ) : (
        <button
          id={id}
          type="submit"
          className="btn btn-default float-right sp-md nextButton"
          disabled={props.callingApi}
        >
          {I18n.t('General.nextButton')}
        </button>
      )
    } else {
      submitButton =
        props.hasError && !(props.slideIndex < props.slides.length - 1) ? (
          <button
            id={id}
            type="submit"
            className="btn btn-default float-right sp-md btn-danger"
            disabled={props.callingApi}
            aria-label="There are errors on this page"
          >
            {I18n.t('General.labelErrors')}
          </button>
        ) : (
          <button
            id={id}
            type="submit"
            className="btn btn-default float-right sp-md"
            disabled={props.callingApi}
          >
            {I18n.t('General.nextButton')}
          </button>
        )
    }
  } else {
    submitButton = null
  }

  return submitButton
}

export default OnNextButton
