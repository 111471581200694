import * as React from 'react'
import { I18n } from 'react-redux-i18n'

interface MyAppealPresentationProps {}

export default function MyAppealPresentation(props: MyAppealPresentationProps) {
  document.title = 'My Appeals - My Account'
  return (
    <div className="row">
      <div id="content_appeal" className="col-12">
        <h1 className="title-header">
          <span className="pe-7s-speaker" /> {I18n.t('MyAppeal.title')}
        </h1>
      </div>
      <div className="col-12">
        <p>
          {I18n.t('MyAppeal.appealMessage.m1')}{' '}
          <a href=" https://dhhr.wv.gov/Pages/default.aspx" target="_blank">
            {I18n.t('MyAppeal.appealMessage.m2')}
          </a>
          .
        </p>
      </div>
    </div>
  )
}
