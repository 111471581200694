import * as React from 'react'
import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const BenefitsCatalogContainer = MultitenantLoadable(
  () => import('./BenefitsCatalogContainer'),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/BenefitsCatalog/BenefitsCatalogContainer'
    )
)

const BenefitsCatalogPresentation = MultitenantLoadable(
  () => import('./BenefitsCatalogPresentation'),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/BenefitsCatalog/BenefitsCatalogPresentation'
    )
)

export default function BenefitsCatalog(props: BenefitsCatalogContainerProps) {
  return (
    <ErrorBoundary name="BenefitsCatalog">
      <BenefitsCatalogContainer
        {...props}
        presentation={presentationProps => (
          <BenefitsCatalogPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.
const BaseBenefitsCatalogContainer = MultitenantLoadable(
  () => import('./BenefitsCatalogContainer'),
  () =>
    Promise.resolve().then(() => {
      null
    })
)

const BaseBenefitsCatalogPresentation = MultitenantLoadable(
  () => import('./BenefitsCatalogPresentation'),
  () =>
    Promise.resolve().then(() => {
      null
    })
)

export function BaseBenefitsCatalog(props: BenefitsCatalogContainerProps) {
  return (
    <BaseErrorBoundary name="BenefitsCatalog">
      <BaseBenefitsCatalogContainer
        {...props}
        presentation={presentationProps => (
          <BaseBenefitsCatalogPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
