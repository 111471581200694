import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import '!style-loader!css-loader!./reports.css'
import { Pagination } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Pagination'
import { ErrorModal } from '../src/components/natives/ErrorModal'

import DatePicker from 'react-datepicker'
// import moment from 'moment/src/moment'

import 'react-datepicker/dist/react-datepicker.css'

interface ReportsPresentationProps {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  application: any
  currentPage: number
  formContext: any
  formData: any
  onFormDataChange: (formData: any) => void
  isLoading: boolean
  minHeight: string
  formComponents
  mainContainer: boolean
  navigateApplicationReports: () => void
  reportType: string
  search: () => void
  screeningSearch: () => void
  showBackResultsTable: () => void
  searchResults: any
  reset: () => void
  screeningReset: () => void
  showResultsTable: boolean
  showScreeningTable: boolean
  pageChangeDetected: (pageNumber: number) => void
  reviewApplication: boolean
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function ReportsPresentation(props: ReportsPresentationProps) {
  const {
    currentPage,
    pageChangeDetected,
    form,
    application,
    formContext,
    onFormDataChange,
    navigateApplicationReports,
    isLoading,
    minHeight,
    formComponents,
    search,
    screeningSearch,
    mainContainer,
    searchResults,
    showResultsTable,
    showScreeningTable,
    showBackResultsTable,
    reset,
    screeningReset,
    reportType,
    errorMessage,
    onDeleteClick,
    reviewApplication
  } = props

  const benefitsScreenedFor =
    form &&
    form.app.forms[1].schema['properties'].screeningReports.properties
      .benefitsScreenedFor
  let applicationTypeHeader, scrHeader
  const resultChunks = _.isNil(searchResults) ? [] : _.chunk(searchResults, 20)

  const header = isLoading ? <h2 /> : <div />
  let formStatus = formContext.formData
    ? formContext.formData.applicationReportCriteria
    : undefined

  if (showScreeningTable) {
    return (
      <div>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <div className="col-lg-12">
          <h2>Screening Report Results</h2>
        </div>
        <table className="table table-striped table-bordered table-condensed table-hover">
          <thead>
            <tr>
              {searchResults.county && <th>County</th>}
              {searchResults.programSelected && <th>Program Selected</th>}
              <th>Number of Applications</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {searchResults.county && <td>{searchResults.county}</td>}
              {searchResults.programSelected && (
                <td>
                  {
                    benefitsScreenedFor.enumNames[
                      benefitsScreenedFor.enum.indexOf(
                        searchResults.programSelected
                      )
                    ]
                  }
                </td>
              )}
              <td>{searchResults.numberOfApplications}</td>
            </tr>
          </tbody>
        </table>
        <div className="col-lg-12">
          <div className="col-lg-9" />
          <div className="col-lg-3">
            <button
              type="button"
              className="btn btn-default pull-right userListButton1"
              onClick={showBackResultsTable}
            >
              Previous
            </button>
          </div>
        </div>
      </div>
    )
  }
  if (showResultsTable) {
    if (form && formStatus && formStatus.applicationType) {
      applicationTypeHeader = (
        <th>
          <span>Review Application</span>
        </th>
      )
    }
    if (form && formStatus && formStatus.geographical) {
      let scr = formStatus.geographical
      scrHeader = (
        <th>
          <span>
            {scr === 'State' ? 'State' : scr === 'County' ? 'County' : 'Region'}
          </span>
        </th>
      )
    }

    return (
      <div>
        <div>
          <div className="col-lg-12">
            <h2>Application Report Results</h2>
          </div>
          <table className="table table-striped table-bordered table-condensed table-hover">
            <thead>
              <tr>
                <th>
                  <span>Number of Applications</span>
                </th>
                <th>
                  <span>Application Status</span>
                </th>
                {applicationTypeHeader}
                {scrHeader}
              </tr>
            </thead>
            <tbody>
              {resultChunks[currentPage - 1] ? (
                resultChunks[currentPage - 1].map((result, i) => {
                  return (
                    <tr key={i}>
                      <td>{searchResults[i].numberOfApplications}</td>
                      <td>{searchResults[i].applicationStatus}</td>
                      {applicationTypeHeader ? (
                        <td>{result.reviewApplication}</td>
                      ) : null}
                      {scrHeader ? <td>{result.geographicalValue}</td> : null}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={2}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="flex-box">
            <div className="col-lg-6">
              <button
                type="button"
                className="btn btn-default float-left reset-margin userListButton1"
                onClick={showBackResultsTable}
              >
                Previous
              </button>
            </div>
            <div className="col-lg-6">
              <Pagination
                prev={'‹'}
                next={'›'}
                first={'«'}
                last={'»'}
                ellipsis={false}
                boundaryLinks={true}
                items={searchResults.length}
                maxButtons={1}
                activePage={currentPage}
                onSelect={pageChangeDetected}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (mainContainer) {
    return (
      <div className="col-12">
        <fieldset className="search">
          <legend className="searchField">List of Reports</legend>
          <div className="bg-white row vert-padding">
            <div className="col-12 formItemsMargin">
              <a
                onClick={navigateApplicationReports}
                onKeyPress={navigateApplicationReports}
                tabIndex={0}
              >
                Application reports
              </a>
            </div>
            <div className="col-12 formItemsMargin">
              <a
                onClick={navigateApplicationReports}
                tabIndex={0}
                onKeyPress={navigateApplicationReports}
              >
                Screening reports
              </a>
            </div>
          </div>
        </fieldset>
      </div>
    )
  }

  if (form) {
    if (reportType === 'Screening reports') {
      return (
        <div>
          <ErrorModal
            errorMessage={errorMessage}
            onDeleteClick={onDeleteClick}
          />
          <Form
            schema={form.app.forms[1].schema}
            uiSchema={form.app.forms[1].uiSchema}
            formData={formContext.formData}
            onChange={onFormDataChange}
            formContext={formContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          />
          <div className="col-lg-12 px-0">
            <button
              type="button"
              className="btn btn-default pull-left reset-margin userListButton1 goto-focus ml-0"
              onClick={navigateApplicationReports}
            >
              Previous
            </button>
            <div className="float-right">
              <button
                type="button"
                id="search_Clear"
                className="btn btn-default reset-margin userListButton1 goto-focus"
                onClick={screeningReset}
              >
                Clear
              </button>
              <button
                type="submit"
                className="btn btn-default userListButton2 goto-focus"
                onClick={screeningSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        {header}
        <Form
          schema={form.app.forms[0].schema}
          uiSchema={form.app.forms[0].uiSchema}
          formData={formContext.formData}
          onChange={onFormDataChange}
          formContext={formContext}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
        />
        <div className="col-lg-12 clearfix-alignment  mb-4">
          <button
            type="button"
            className="btn btn-default float-left reset-margin userListButton1 goto-focus"
            onClick={navigateApplicationReports}
          >
            Previous
          </button>
          <div className="app-btn-placement">
            <button
              type="button"
              id="Application_Clear"
              className="btn btn-default reset-margin userListButton1 goto-focus"
              onClick={reset}
            >
              Clear
            </button>
            <button
              type="submit"
              className="btn btn-default userListButton2 goto-focus"
              onClick={search}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    )
  }
  return <ScreenPreLoader minHeight={minHeight} />
}
