import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyPermissionContainer = MultitenantLoadable(
  () => import('./MyPermissionContainer'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/MyPermission/MyPermissionContainer'
    )
)

const MyPermissionPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyPermissionPresentation'),
    () =>
      import(
        '../../' +
          tenantDirectoryName +
          '/MyPermission/MyPermissionPresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyPermission/formComponents')
)

export default function MyPermission(props) {
  return (
    <ErrorBoundary name="MyPermission">
      <MyPermissionContainer
        {...props}
        presentation={presentationProps => (
          <MyPermissionPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyPermissionContainer = MultitenantLoadable(
  () => import('./MyPermissionContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyPermissionPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyPermissionPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyPermission(props) {
  return (
    <BaseErrorBoundary name="MyPermission">
      <BaseMyPermissionContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyPermissionPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
