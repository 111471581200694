import * as React from 'react'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { isEqual, get, some, findIndex, merge, forEach } from 'lodash'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'

const _ = { isEqual, get, some, findIndex, merge, forEach }

declare var Promise: any

const loadEndpoint = config['casesForLifeEvents']
const renewalInfoEndpoint = config['renewalInfoEndpoint']
const formsEndpoint = config['forms_MyLifeEvents']

interface MyLifeEventsContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  uuid: string
  locale: string
  authHeader: string
}

interface MyLifeEventsContainerState {
  form: {
    schema: Object
    uiSchema: Object
  }
  formContext: any
  callingApi: boolean
}

class MyLifeEventsContainer extends React.Component<
  MyLifeEventsContainerProps,
  MyLifeEventsContainerState
> {
  constructor(props: MyLifeEventsContainerProps) {
    super(props)

    this.state = {
      form: null,
      formContext: {},
      callingApi: false
    }
  }

  componentDidMount() {
    this._initializeForm()
  }

  componentDidUpdate(
    prevProps: MyLifeEventsContainerProps,
    prevState: MyLifeEventsContainerState
  ) {
    if (
      prevProps.isLoading &&
      !this.props.isLoading &&
      this.props.formData &&
      Array.isArray(this.props.formData)
    ) {
      //We have received all data from withFetcher, so now start calls to determine case renewal statuses.
      this._retrieveRenewalInfo()
    }
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      this.setState({ callingApi: true })
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(formsJson => {
          this.setState({ callingApi: false })
          const form = formsJson
          const formContext = {
            ...this.state.formContext,
            form
          }
          this.setState({ form, formContext })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('MyLifeEvents form re-fetch failed due to ex', error)
          const code = CODES.MY_LIFE_EVENTS_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  static getDerivedStateFromProps(
    props: MyLifeEventsContainerProps,
    state: MyLifeEventsContainerState
  ) {
    return {
      formContext: {
        ...state.formContext,
        formData: props.formData
      }
    }
  }

  _initializeForm = () => {
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(form => {
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            formContext: {
              ...prevState.formContext,
              forms: [{ ...form }],
              refs: {
                '{case summary}': ({ fieldId, formContext }) => {
                  try {
                    const index = fieldId.match('^LifeEvents_([0-9]+)_?')[1]
                    return (
                      I18n.t('MyLifeEvents.caseID') +
                      formContext.formData[index].caseId
                    )
                  } catch (err) {
                    return I18n.t('MyLifeEvents.caseIDUnknown')
                  }
                },
                '{has only qhp}': ({ fieldId, formContext }) => {
                  try {
                    const index = fieldId.match('^LifeEvents_([0-9]+)_?')[1]
                    const clients = _.get(
                      formContext,
                      'formData.' + index + '.clients'
                    )
                    return (
                      _.some(clients, value => {
                        return value['hasBenefitQHP'] === true
                      }) &&
                      !_.some(clients, value => {
                        return (
                          value['hasBenefitHEAP'] === true ||
                          value['hasBenefitMEDICAID'] === true ||
                          value['hasBenefitSNAP'] === true ||
                          value['hasBenefitTANF'] === true ||
                          value['hasBenefitCCA'] === true
                        )
                      })
                    )
                  } catch (err) {
                    return false
                  }
                }
              },
              config
            }
          }
        })
      })
      .catch(error => {
        console.error('MyLifeEvents _initializeForm failed due to ex', error)
        const code = CODES.MY_LIFE_EVENTS_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _retrieveRenewalInfo = () => {
    Promise.all(
      this.props.formData.map(element => {
        const fetchEndpoint = renewalInfoEndpoint.replace(
          '{caseId}',
          element.caseId
        )

        const request = {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          },
          method: 'GET'
        }
        return helper
          .fetchJson(fetchEndpoint, request)
          .then(json => {
            if (
              json &&
              json['programRenewals'] &&
              _.some(json['programRenewals'], programRenewal => {
                //Adding Renewal status null check
                if (programRenewal['renStatusCd']) {
                  return programRenewal['renStatusCd'].key === 'RERENO96730'
                }
              })
            ) {
              return element.caseId
            } else {
              return null
            }
          })
          .catch(error => {
            console.error(
              'MyLifeEvents _retrieveRenewalInfo failed at endpoint ' +
                fetchEndpoint +
                ' with error:',
              error
            )
            const code = CODES.MY_LIFE_EVENTS_FETCH_RENEWAL_INFO
            if (shouldThrow(code)) {
              this.setState(() => {
                if (error instanceof helper.IEServiceError) {
                  throw error
                } else {
                  throw new ClientPortalException(error, code)
                }
              })
            }
          })
      })
    ).then(responses => {
      this.setState({
        formContext: {
          ...this.state.formContext,
          eligiblePendingRenewals: (responses || []).filter(e => {
            return e !== null
          })
        }
      })
    })
  }

  _onFormDataChange = ({ formData }) => {
    console.error(
      'MyLifeEvents _onFormDataChange was called but the form should be read-only!'
    )
  }

  render() {
    const { presentation, formData, isLoading, minHeight, locale } = this.props
    const { form, formContext } = this.state

    const presentationProps = {
      form,
      formContext,
      formData,
      onFormDataChange: this._onFormDataChange,
      isLoading,
      minHeight,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

const initialData = null
const createEndpoint = props => {
  return loadEndpoint
}
const createRequest = props => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      uuid: props.uuid,
      tenantCode: config.tenant.code,
      portalName: config.portalName,
      Authorization: props.authHeader
    }
  }
}
const responseMapper = json => {
  if (Array.isArray(json) && json.length > 0) {
    let combinedJson = []
    _.forEach(json, (value, index) => {
      const caseIndex = _.findIndex(combinedJson, element => {
        return element.caseId === value.caseId
      })
      if (caseIndex === -1) {
        combinedJson.push(value)
      } else {
        _.merge(combinedJson[caseIndex], value)
      }
    })

    let newFormData = combinedJson.map(element => {
      let clients = []
      _.forEach(element.programClientMap, (value, program) => {
        if (program === 'Healthcare') {
          _.forEach(value, healthCareValue => {
            const clientIndex = _.findIndex(clients, client => {
              return client.clientName === healthCareValue.clientName
            })
            const activeQHP =
              healthCareValue.benefitProgramCode === 'QHP' &&
              healthCareValue.clientProgramStatusValue === 'Active'
            if (clientIndex === -1) {
              clients.push({
                clientName: healthCareValue.clientName,
                hasBenefitHEAP: false,
                hasBenefitMEDICAID: false,
                hasBenefitQHP: activeQHP,
                hasBenefitSNAP: false,
                hasBenefitTANF: false,
                hasBenefitCCA: false
              })
            } else if (activeQHP) {
              clients[clientIndex].hasBenefitQHP = true
            }
          })
        } else {
          _.forEach(value, clientName => {
            const clientIndex = _.findIndex(clients, client => {
              return client.clientName === clientName
            })

            if (clientIndex === -1) {
              clients.push({
                clientName,
                hasBenefitHEAP: program === 'HEAP',
                hasBenefitMEDICAID: program === 'MEDICAID',
                hasBenefitQHP: program === 'QHP',
                hasBenefitSNAP: program === 'SNAP',
                hasBenefitTANF: program === 'TANF',
                hasBenefitCCA: program === 'CCA'
              })
            } else {
              switch (program) {
                case 'HEAP':
                  clients[clientIndex].hasBenefitHEAP = true
                  break
                case 'MEDICAID':
                  clients[clientIndex].hasBenefitMEDICAID = true
                  break
                case 'QHP':
                  clients[clientIndex].hasBenefitQHP = true
                  break
                case 'SNAP':
                  clients[clientIndex].hasBenefitSNAP = true
                  break
                case 'TANF':
                  clients[clientIndex].hasBenefitTANF = true
                  break
                case 'CCA': {
                  clients[clientIndex].hasBenefitCCA = true
                  break
                }
              }
            }
          })
        }
      })
      return {
        caseId: element.caseId,
        clients
      }
    })
    helper.cleanNullValues(newFormData)
    return newFormData
  } else {
    return []
  }
}

export default connect(mapStateToProps)(
  helper.withFetcher(
    MyLifeEventsContainer,
    false,
    initialData,
    createEndpoint,
    createRequest,
    responseMapper,
    config.tenant,
    true,
    true
  )
)

export const MyLifeEventsDoubleFetcherContainer = connect(mapStateToProps)(
  helper.withFetcher(
    MyLifeEventsContainer,
    true,
    initialData,
    createEndpoint,
    createRequest,
    responseMapper,
    config.tenant,
    true,
    true
  )
)
