import * as React from 'react'
import { config } from '../../../config'
import * as actions from '../../../actions'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { v4 } from 'uuid'

declare const process
const baseUrl = process.env.PUBLIC_URL

export default connect(
  null,
  mapDispatchToProps
)(
  class BenhEligibleRedirect extends React.Component<any, any> {
    shouldRedirect: boolean
    constructor(props) {
      super(props)
      this.shouldRedirect = false
    }

    // This class does the same thing as /login - referralId is added to state in redirect url

    componentDidMount() {
      const matchUrl = this.props.match.url
      const eligibleId = matchUrl.split('/')[2]
      const loginUrl = config.openId_loginUrl.replace('{state}', v4());
      const url = loginUrl;
      const splitStr = url.split('&nonce')
      const newUrl = splitStr[0] + '%3BeligibleId%3D' + eligibleId + '&nonce='

      let isDefaultCase = config.isDefaultBaseCase && config.isDefaultWVCase
      let isProductionNodeEnv = process.env.NODE_ENV === 'production'
      let isLocalConfigEnv = config.environment === 'LOCAL'

      if (isDefaultCase || (isProductionNodeEnv && isLocalConfigEnv)) {
        let messageList = [
          {
            errorCode: 'IE-APPP-BEN100',
            errorDescription:
              'This functionality is currently not available. Please try again later or contact the Technical Call Center at 1-844-451-3515 for immediate assistance.'
          }
        ]
        this.props.showErrorMessage(messageList)
        this.shouldRedirect = true
      } else {
        window.location.href = newUrl
      }
    }

    render() {
      //return <Redirect to={baseUrl + '/login'} />
      if (this.shouldRedirect) {
        return <Redirect to={baseUrl + '/home'} />
      }
      return null
    }
  }
)

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    }
  }
}
