import * as React from 'react'
import { Modal, Button } from 'react-bootstrap'

interface ModalProps {
  showModal: boolean
  hideModal?: () => void
  size?: any
  message?: string
  ques?: boolean
  stay?: () => void
  yesNo?: boolean
}

class ModalContainer extends React.Component<ModalProps, any> {
  constructor(props: any) {
    super(props)
  }

  yesNo = (
    <Modal.Footer>
      <Button variant="primary" onClick={() => this.props.hideModal()}>
        Yes
      </Button>
      <Button variant="primary" onClick={() => this.props.stay()}>
        No
      </Button>
    </Modal.Footer>
  )

  okCancel = (
    <Modal.Footer>
      <Button variant="primary" onClick={() => this.props.hideModal()}>
        Ok
      </Button>
      <Button variant="primary" onClick={() => this.props.stay()}>
        Cancel
      </Button>
    </Modal.Footer>
  )

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.hideModal}
        size={this.props.size}
        role="dialog"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title">
            <h4 className="modal-container-header">Save Changes</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="headertxt">{this.props.message}</h5>
        </Modal.Body>
        {this.props.ques ? (
          this.props.yesNo ? (
            this.yesNo
          ) : (
            this.okCancel
          )
        ) : (
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.props.hideModal()}>
              Close
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    )
  }
}

export default ModalContainer
