import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyProvidersContainer = MultitenantLoadable(
  () => import('./MyProvidersContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/MyProviders/MyProvidersContainer')
)

const MyProvidersPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyProvidersPresentation'),
    () =>
      import(
        '../../' + tenantDirectoryName + '/MyProviders/MyProvidersPresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyProviders/formComponents')
)

export default function MyProviders(props) {
  return (
    <ErrorBoundary name="MyProviders">
      <MyProvidersContainer
        {...props}
        presentation={presentationProps => (
          <MyProvidersPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyProvidersContainer = MultitenantLoadable(
  () => import('./MyProvidersContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyProvidersPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyProvidersPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyProviders(props) {
  return (
    <BaseErrorBoundary name="MyProviders">
      <BaseMyProvidersContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyProvidersPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
