import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import {
  isEqual,
  set,
  get,
  isObject,
  isEmpty,
  mergeWith,
  trim,
  trimStart,
  cloneDeep
} from 'lodash'
import { connect } from 'react-redux'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'

const _ = {
  isEqual,
  set,
  get,
  isObject,
  isEmpty,
  mergeWith,
  trim,
  trimStart,
  cloneDeep
}

const loadData = true
const initialLoadEndpoint = config['myProviderInitialLoad']
const selectedLoadEndpoint = config['myProviderSelectedLoad']
const submitEnrollmentEndpoint = config['submitEnrollments']
const formsEndpoint = config['forms_MyProvidersNew']
const openEnrollmentEndPoing = config['openenrollment']
const providerProfileEndPoint = config['getProviderProfile']

//request forms
let MyEnrollment = {
  caseId: null,
  clientId: null,
  providerType: null,
  providerName: null,
  providerId: null,
  typeCode: null,
  providerPhone: {
    providerTelId: null,
    provId: null,
    telId: null,
    phone: {
      telephoneId: null,
      telTypCd: null,
      telNumber1: null,
      cntryCd: null,
      telExt: null
    }
  },
  providerAddress: {
    providerAddressId: null,
    adrId: null,
    provId: null,
    address: {
      addressId: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: '',
      addressLine4: '',
      city: null,
      state: null,
      zipcode: null,
      county: null
    }
  },
  mangHealthCareOrg: null,
  enrollmentSourceCd: null,
  programName: null,

  recordEndDate: null,

  referralDate: null,
  tenantId: null
}

export class RatingField extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      formContext: props.formContext,
      showRating: false
    }
  }
  render() {
    let providerName = this.props.providerName
    let providerRating = this.props.rating
    return <div className="form-control">{providerName}</div>
  }
}

interface MyProvidersNewContainerProps {
  presentation: any
  uuid: string
  locale: string
  authHeader: string
}

interface MyProvidersNewContainerState {
  forms: Array<{
    schema: any
    uiSchema: any
  }>
  formData: any
  formContext: any
  mapProps: {
    member: any
    crfProvider: any
    features: any
  }
  noSelectedBenefit: boolean
  showMap: boolean
  showMapAddress: boolean
  providerMapping: any
  initialDataLoad: boolean
  benfProgCd: string
  isChange: boolean
  oldForms: Array<{
    schema: any
    uiSchema: any
  }>
  showAddrFlag: boolean
  showCRFMap: boolean
  isSearchEnabled: boolean
  isAddressChanged: boolean
  isAdditionalSearchEnabled: boolean
  isAddNewProvider: boolean
  isModalOpen: boolean
  previousProviderMapping: any
  previousMapProps: {
    member: any
    crfProvider: any
    features: any
  }
  callingApi: boolean
  providerError: any
  isProviderExistsError: boolean
}

class MyProvidersNewContainer extends React.Component<
  MyProvidersNewContainerProps,
  MyProvidersNewContainerState
> {
  private container: HTMLDivElement

  constructor(props: MyProvidersNewContainerProps) {
    super(props)

    this.state = {
      forms: null,
      formData: null,
      formContext: null,
      mapProps: null,
      noSelectedBenefit: false,
      showMap: false,
      showMapAddress: false,
      providerMapping: null,
      initialDataLoad: false,
      benfProgCd: null,
      isChange: false,
      oldForms: null,
      showAddrFlag: false,
      showCRFMap: false,
      isSearchEnabled: false,
      isAddressChanged: false,
      isAdditionalSearchEnabled: false,
      isAddNewProvider: false,
      isModalOpen: false,
      previousProviderMapping: null,
      previousMapProps: null,
      callingApi: false,
      providerError: null,
      isProviderExistsError: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      this.setState({ callingApi: true })
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(formsJson => {
          this.setState({ callingApi: false })
          const application = formsJson['app']
          const forms = application['forms']
          const formContext = {
            ...this.state.formContext,
            forms
          }
          this.setState({ forms, formContext })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('MyProvidersNew form re-fetch failed due to ex', error)
          const code = CODES.MY_PROVIDERS_NEW_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _getInitialLoadDataJSON = (tempJson: Object) => {
    helper
      .fetchJson(initialLoadEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(results => {
        // load json into form
        let newJson = helper.deepClone(tempJson)
        let enumValue = []
        let enumNamesValue = []
        let enumNameCd = []
        let jsonArray = JSON.parse(JSON.stringify(results))

        for (var k = 0; k < jsonArray.length; k++) {
          enumValue.push(jsonArray[k].appId + k)
          enumNamesValue.push(jsonArray[k].benefitLongName)
          enumNameCd.push(jsonArray[k].benefitCd)
        }
        //Modify newJson
        _.set(
          newJson,
          'app.forms[0].schema.definitions.myProviders.properties.benefitsDdl.properties.selectedBenefit.enum',
          enumValue
        )
        _.set(
          newJson,
          'app.forms[0].schema.definitions.myProviders.properties.benefitsDdl.properties.selectedBenefit.enumNames',
          enumNamesValue
        )
        _.set(
          newJson,
          'app.forms[0].schema.definitions.myProviders.properties.benefitsDdl.properties.selectedBenefit.enumNamesCd',
          enumNameCd
        )

        // this._fillDummyData(newJson);
        this._loadForm(newJson)
      })
      .catch(error => {
        console.error(
          'MyProvidersNew _getInitialLoadDataJSON failed with ex',
          error
        )
        //TODO: fix the CORS error.  Also we are just using pre-fiilled data anyway?
        const code = CODES.MY_PROVIDERS_NEW_INITIAL_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        } else {
          this._loadForm(tempJson)
        }
      })
  }

  /**
   * Populate child care
   */
  _fillData = (newForms: Array<any>, applicantData: Array<any>) => {
    let enumValueForApplicant = []
    let enumNamesValueForApplicant = []
    let enumNameCdForApplicant = []
    for (var k = 0; k < applicantData.length; k++) {
      if (
        enumValueForApplicant.length === 0 ||
        enumValueForApplicant.indexOf(
          applicantData[k].memberData.needs[0].clientId
        ) === -1
      ) {
        enumValueForApplicant.push(
          applicantData[k].memberData.needs[0].clientId
        )
        enumNamesValueForApplicant.push(applicantData[k].applicantName)
        enumNameCdForApplicant.push(this.state.benfProgCd)
      }
    }

    //Modify newJson
    _.set(
      newForms,
      '[0].schema.definitions.myProviders.properties.providerSearch.properties.applicant.enum',
      enumValueForApplicant
    )
    _.set(
      newForms,
      '[0].schema.definitions.myProviders.properties.providerSearch.properties.applicant.enumNames',
      enumNamesValueForApplicant
    )
    _.set(
      newForms,
      '[0].schema.definitions.myProviders.properties.providerSearch.properties.applicant.enumNamesCd',
      enumNameCdForApplicant
    )

    return newForms
  }

  _loadForm = (json: Object) => {
    let forms, application, formData, formContext, initialDataLoad, enumArray
    application = json['app']
    forms = application['forms']
    enumArray = _.get(
      forms[0].schema,
      'definitions.myProviders.properties.benefitsDdl.properties.selectedBenefit.enum'
    )

    if (enumArray && enumArray[0] != null) {
      initialDataLoad = true
    }

    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      refs: application['metaData']['refs'],
      forms: forms,
      searchProvider: this._setCRFEnable,
      isAdditionalSearchEnabled: this.state.isAdditionalSearchEnabled,
      addNewProvider: this._enableSearchComponant,
      isAddNewProvider: this.state.isAddNewProvider,
      saveEnrollment: this._saveEnrollment,
      config
    }

    //load the pre-filled form data
    let formDataLoad = application['formData']

    if (loadData && _.isObject(formDataLoad) && !_.isEmpty(formDataLoad)) {
      //Merge the pre-filled form data with an empty default object to ensure necessary objects are available for RJSF rendering.
      formData = _.mergeWith(
        helper.createObjectFromMasterSchema(null, formContext),
        formDataLoad,
        function(objValue, srcValue) {
          if (srcValue == null && objValue == undefined) {
            return {}
          }
          return undefined
        }
      )
    } else {
      //New application, initialize to empty object
      formData = helper.createObjectFromMasterSchema(null, formContext)
    }

    helper.cleanNullValues(formData)

    formContext = { ...formContext, selectProvider: this._selectProvider }
    formContext = { ...formContext, showMap: this._showMap }

    formContext = {
      ...formContext,
      formData: formData,
      isChange: this.state.isChange,
      showAddrFlag: this.state.showAddrFlag,
      component: this
    }

    if (initialDataLoad != undefined) {
      this.setState({ forms, formData, formContext, initialDataLoad })
    } else {
      this.setState({ forms, formData, formContext })
    }
  }

  // called from ApplicantProviderTable
  initModelWindow = (
    providerInfo: any,
    caseId: string,
    clientId: string,
    benefitCd: string
  ) => {
    let { formData } = this.state
    formData = helper.deepClone(formData)
    this.getProviderProfile(providerInfo, caseId, clientId, benefitCd)
  }

  getProviderProfile = (providerInfo, caseId, clientId, benefitCd) => {
    const fetchEndpoint = providerProfileEndPoint.replace(
      '{providerId}',
      providerInfo.id
    )

    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        }
      })
      .then(results => {
        if (results) {
          let jsonDataArray = JSON.parse(JSON.stringify(results))

          let providerBasicInfo = jsonDataArray.providerBasicInfo
          let providerDetailedInfo = jsonDataArray.providerDetailedInfo
          this.updateFormData(
            providerInfo,
            providerBasicInfo,
            providerDetailedInfo
          )
          let { formContext } = this.state
          formContext = {
            ...formContext,
            providerInfo: providerInfo,
            caseId: caseId,
            clientId: clientId,
            benefitCd: benefitCd
          }
          this.setState({ formContext, isModalOpen: true }, () =>
            this._refreshFormContext()
          )
        }
      })
      .catch(error => {
        console.error('MyProvidersNew getProviderProfile failed with ex', error)
        const code = CODES.MY_PROVIDERS_NEW_PROVIDER_PROFILE
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  updateFormData(providerInfo, providerBasicInfo, providerDetailedInfo) {
    let formData = helper.deepClone(this.state.formData)

    let serviceType = providerInfo.serviceType
      ? providerInfo.serviceType
      : I18n.t('MyProvidersCCA.childCareCenter')

    //provider header info
    _.set(
      formData,
      'providerPopupModal.providerHeaderInfo.providerIntroduction.providerDetails.providerName',
      _.get(providerBasicInfo, 'providerIntroduction.providerName')
    )
    _.set(
      formData,
      'providerPopupModal.providerHeaderInfo.providerIntroduction.providerType',
      serviceType
    )
    _.set(
      formData,
      'providerPopupModal.providerHeaderInfo.providerIntroduction.providerAddress',
      _.get(providerBasicInfo, 'providerAddress.address')
    )

    //provider footer info
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerDescription.providerCertification',
      _.get(
        providerDetailedInfo,
        'noticeSection.childCareTable[6].childTableValue'
      )
    )
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerDescription.providerPhone',
      _.get(providerBasicInfo, 'providerAddress.phone')
    )
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerDescription.providerEmail',
      _.get(providerBasicInfo, 'providerAddress.email')
    )
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerDescription.providerWebSite',
      _.get(providerBasicInfo, 'providerAddress.website')
    )
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerDescription.providerPaymentOptions',
      _.get(
        providerDetailedInfo,
        'noticeSection.childCareTable[5].childTableValue'
      )
    )

    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerCapacityCol.providerMacCapacity',
      _.get(
        providerDetailedInfo,
        'noticeSection.childCareTable[0].childTableValue'
      )
    )
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerCapacityCol.providerOpenings',
      _.get(
        providerDetailedInfo,
        'noticeSection.childCareTable[1].childTableValue'
      )
    )
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerCapacityCol.providerMeals',
      _.get(
        providerDetailedInfo,
        'noticeSection.childCareTable[2].childTableValue'
      )
    )
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerCapacityCol.providerTransportation',
      _.get(
        providerDetailedInfo,
        'noticeSection.childCareTable[3].childTableValue'
      )
    )
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.providerCapacityCol.providerServices',
      _.get(
        providerDetailedInfo,
        'noticeSection.childCareTable[4].childTableValue'
      )
    )

    //hours table load
    _.set(
      formData,
      'providerPopupModal.providerFooterInfo.hoursTable',
      _.get(providerDetailedInfo, 'aboutProvider.hoursTable')
    )
    this.setState({ formData: formData }, () => this._refreshFormContext())
  }

  _refreshFormContext = () => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        formData: helper.deepClone(this.state.formData)
      }
    })
  }

  _selectProvider = (providerMapping: any) => {
    const { showCRFMap, previousProviderMapping, isAddressChanged } = this.state
    const mapProps = {
      member: providerMapping,
      crfProvider: null,
      features: {
        intialview: 'map',
        travelRadius: true,
        addressChange: true,
        countyServed: false,
        flyoutmenu: false,
        tenant: 'IE-CP',
        selectProvider: true
      }
    }

    this.setState(prevState => {
      return { mapProps, previousMapProps: this.state.mapProps }
    })
  }
  componentDidMount() {
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        this._getInitialLoadDataJSON(formJson)
      })
      .catch(error => {
        console.error('MyProvidersNew form fetch failed due to ex', error)
        const code = CODES.MY_PROVIDERS_NEW_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })

    if (this.state.showMap) {
      this.setState(prevState => {
        return {
          formContext: {
            ...prevState.formContext,
            showAddrFlag: false
          }
        }
      })
    }
  }
  _onFormDataChange = ({ formData }) => {
    let newForms = helper.deepClone(this.state.forms)
    var firstFlag = false
    this.setState(prevState => {
      return {
        formContext: {
          ...prevState.formContext,
          showCRFMap: false
        }
      }
    })
    if (
      this.state.formData.Application.myProviders.benefitsDdl
        .selectedBenefit !==
      formData.Application.myProviders.benefitsDdl.selectedBenefit
    ) {
      if (
        !this.state.formData.Application.myProviders.benefitsDdl.selectedBenefit
      ) {
        this.setState({ oldForms: this.state.forms })
      }
      this.state.formData.Application.myProviders.benefitsDdl.selectedBenefit =
        formData.Application.myProviders.benefitsDdl.selectedBenefit
      var benfProgCd = this._getBenfProgCd()
      this._disableSearchComponant()
      //Update the medicade
      if (this.isButtonActive(benfProgCd)) {
        this._updateMessage(benfProgCd)
        formData.Application.myProviders.medicadeChipActive = true
      } else {
        //is open enrollment active
        formData.Application.myProviders.isOpenEnrollment = true
        //for change provider for medicade or chip
        formData.Application.myProviders.medicadeChipActive = false
        //open enrollment message
        formData.Application.myProviders.isOpenEnrollmentMsg = false
      }
      this.setState({ benfProgCd }, () => this._fetchDataLoad())
    }
    this._isValid(formData)
    this._isErrorMessageVisible(formData)
    if (formData.Application.myProviders.providerSearch.address) {
      let address = formData.Application.myProviders.providerSearch.address
      this._updateProviderMapping(address)
      this.setState(prevState => {
        return {
          formData,
          formContext: {
            ...prevState.formContext,
            formData
          }
        }
      })
      this._selectProvider(this.state.providerMapping)
      this.setState({ showMapAddress: false, showCRFMap: false })
    } else {
      this.setState(prevState => {
        return {
          formData,
          formContext: {
            ...prevState.formContext,
            formData
          }
        }
      })
    }
  }

  _updateProviderMapping = (address: string) => {
    if (
      this.state.formData.Application.myProviders.providerMapping[0] &&
      this.state.formData.Application.myProviders.providerMapping[0]
        .memberData &&
      this.state.formData.Application.myProviders.providerMapping[0].memberData
        .needs[0]
    ) {
      let providerMap = this.state.formData.Application.myProviders
        .providerMapping[0].memberData.needs[0]
      let programName = 'Medical'
      let map = {
        firstName: providerMap.firstName,
        lastName: providerMap.lastName,
        clientId: providerMap.clientId,
        address: '',
        ADR_LN_1_TXT: '',
        ADR_LN_2_TXT: '',
        CTY_NM: '',
        ST: '',
        ZIP: '',
        providerType: programName || 'Medical'
      }
      map.address = address
      if (
        address &&
        address.lastIndexOf(',') > -1 &&
        address.split(',').length > 2
      ) {
        let addressArray = address.split(',')
        map.ADR_LN_1_TXT = _.trim(addressArray[0])
        map.CTY_NM = _.trim(addressArray[1])
        let stateZip = _.trimStart(addressArray[2])
        map.ST = _.trim(stateZip.split(' ')[0])
        map.ZIP = _.trim(stateZip.split(' ')[1])
      } else {
        map.ADR_LN_1_TXT = address
      }
    }

    this.setState(prevState => {
      let showCRFMap = this.state.showCRFMap
      let isAddressChanged = false
      if (
        !_.isEqual(prevState.formData.Application.myProviders.address, address)
      ) {
        showCRFMap = false
        isAddressChanged = true
      }
      return {
        providerMapping: { ...prevState.providerMapping },
        showCRFMap,
        isAddressChanged
      }
    })
  }

  _getBenfProgCd = () => {
    var enumArray = this.state.forms[0].schema.definitions.myProviders
      .properties.benefitsDdl.properties.selectedBenefit.enum
    var benfProgCd = null
    for (var k = 0; k < enumArray.length; k++) {
      if (
        this.state.formData.Application.myProviders.benefitsDdl
          .selectedBenefit == enumArray[k]
      ) {
        benfProgCd = this.state.forms[0].schema.definitions.myProviders
          .properties.benefitsDdl.properties.selectedBenefit.enumNamesCd[k]
      }
    }
    return benfProgCd
  }

  _isValid = (formData: any) => {
    let flag = true
    if (
      formData &&
      formData.Application &&
      formData.Application.myProviders &&
      formData.Application.myProviders.providerSearch &&
      formData.Application.myProviders.providerSearch.applicant &&
      formData.Application.myProviders.providerSearch.address
    ) {
      var applicationProviderMapping =
        formData.Application.myProviders.benefitsDdl.applicationProviderMapping
      for (var i = 0; i < applicationProviderMapping.length; i++) {
        if (
          formData.Application.myProviders.providerSearch.address &&
          applicationProviderMapping[i].applicantId ===
            formData.Application.myProviders.providerSearch.applicant &&
          applicationProviderMapping[i].providers.length >= 3
        ) {
          flag = false
        }
      }
    } else {
      flag = false
    }
    return flag
  }

  _isErrorMessageVisible = (formData: any) => {
    let flag = false
    if (
      formData &&
      formData.Application &&
      formData.Application.myProviders &&
      formData.Application.myProviders.providerSearch &&
      formData.Application.myProviders.providerSearch.applicant
    ) {
      var applicationProviderMapping =
        formData.Application.myProviders.benefitsDdl.applicationProviderMapping
      for (var i = 0; i < applicationProviderMapping.length; i++) {
        if (
          formData.Application.myProviders.providerSearch.applicant &&
          applicationProviderMapping[i].applicantId ===
            formData.Application.myProviders.providerSearch.applicant &&
          applicationProviderMapping[i].providers.length >= 3
        ) {
          flag = true
        }
      }
    } else {
      flag = false
    }
    this.setState(prevState => {
      return {
        formContext: {
          ...prevState.formContext,
          _isErrorMessageVisible: flag,
          showCRFMap: !flag
        }
      }
    })

    return flag
  }

  _openEnrollment = (benfProgCd: string) => {
    const fetchEndpoint = openEnrollmentEndPoing.replace(
      '{benfProgCd}',
      benfProgCd.toUpperCase()
    )
    return helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .catch(error => {
        const code = CODES.MY_PROVIDERS_NEW_OPEN_ENROLLMENT
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  //TO update the program code
  _updateMessage = (benfProgCd: string) => {
    this._openEnrollment(benfProgCd).then(resp => {
      let formData = _.cloneDeep(this.state.formData)
      if (!_.isEmpty(resp.data)) {
        formData.Application.myProviders.isOpenEnrollment = false
      } else {
        formData.Application.myProviders.isOpenEnrollment = true
      }
      this.setState(prevState => {
        return {
          formData,
          formContext: {
            ...prevState.formContext,
            formData
          }
        }
      })
    })
  }

  _fetchDataLoad = () => {
    var applId = this.state.formData.Application.myProviders.benefitsDdl
      .selectedBenefit
    if (applId != 'undefined') applId = applId.slice(0, -1)

    const fetchEndpoint = selectedLoadEndpoint
      .replace('{applId}', applId)
      .replace('{benfProgCd}', this.state.benfProgCd)

    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(results => {
        if (results) {
          let jsonDataArray = JSON.parse(JSON.stringify(results))
          this._getApplicantProviderMapping(jsonDataArray)
          let providerMapping = []
          let add = null
          let openEnrollementFlag = this.state.formData.Application.myProviders
            .isOpenEnrollment
          let medicadeChipActive = this.state.formData.Application.myProviders
            .medicadeChipActive
          let count = 0
          for (var k = 0; k < jsonDataArray.length; k++) {
            if (jsonDataArray[k].adrLn1Txt) {
              add =
                jsonDataArray[k].adrLn1Txt +
                ' ' +
                jsonDataArray[k].adrLn2Txt +
                ',' +
                jsonDataArray[k].ctyNm +
                ',' +
                jsonDataArray[k].st +
                ',' +
                jsonDataArray[k].zip
            }
            let addresses = []
            let details = []
            let needs = []
            let detail = {
              detailId: '000009',
              program: 'Medical',
              filter: 'Medical',
              subsidized: '100%',
              providerId: jsonDataArray[k].providerExists
                ? jsonDataArray[k].provider.id
                : null,
              allowUpdate: true
            }
            details.push(detail)
            let address = {
              addressId: k,
              name: 'Home',
              address: add ? add : '',
              ADR_LN_1_TXT: jsonDataArray[k].adrLn1Txt
                ? jsonDataArray[k].adrLn1Txt
                : '',
              ADR_LN_2_TXT: jsonDataArray[k].adrLn2Txt
                ? jsonDataArray[k].adrLn2Txt
                : '',
              CTY_NM: jsonDataArray[k].ctyNm ? jsonDataArray[k].ctyNm : '',
              ST: jsonDataArray[k].st ? jsonDataArray[k].st : '',
              ZIP: jsonDataArray[k].zip ? jsonDataArray[k].zip : '',
              ZIP_EXT: null,
              addressLine1: null,
              addressLine2: null,
              website: null,
              phone: null,
              hours: null
            }

            if (jsonDataArray[k].provider != null) {
              ;(address.addressLine1 = jsonDataArray[k].provider.adr_LN_1_TXT
                ? jsonDataArray[k].provider.adr_LN_1_TXT
                : ' ' + ' ' + jsonDataArray[k].provider.adr_LN_2_TXT
                ? jsonDataArray[k].provider.adr_LN_2_TXT
                : ' '),
                (address.addressLine2 = jsonDataArray[k].provider.cty_NM
                  ? jsonDataArray[k].provider.cty_NM
                  : '' + ',' + jsonDataArray[k].provider.st
                  ? jsonDataArray[k].provider.st
                  : '' + ',' + jsonDataArray[k].provider.zip
                  ? jsonDataArray[k].provider.zip
                  : ''),
                (address.website = jsonDataArray[k].provider.website
                  ? jsonDataArray[k].provider.website
                  : ' '),
                (address.phone = jsonDataArray[k].provider.phone
                  ? jsonDataArray[k].provider.phone
                  : ' '),
                (address.hours = jsonDataArray[k].provider.hours
                  ? jsonDataArray[k].provider.hours
                  : ' ')
            }

            addresses.push(address)
            let need = {
              clientId: jsonDataArray[k].prtcpId,
              firstName: jsonDataArray[k].prtcpFirstName,
              lastName: jsonDataArray[k].prtcpLastName,
              addresses: addresses,
              details: details,
              caseId: jsonDataArray[k].caseId || '3410'
            }
            needs.push(need)
            let memberData = {
              transactionId: applId,
              needs: needs
            }
            let providermap = {
              applicantName:
                jsonDataArray[k].prtcpFirstName +
                ' ' +
                jsonDataArray[k].prtcpLastName,
              providerName: jsonDataArray[k].providerExists
                ? jsonDataArray[k].provider.name
                : null,
              appliedBenefit: applId,
              memberData: memberData,
              readMoreFlag: 'true',
              change: false
            }
            //openenrollment not active and medicade or chip and atleast client is not submitted enrollment
            if (
              jsonDataArray[k].providerExists &&
              openEnrollementFlag &&
              medicadeChipActive
            ) {
              count = count + 1
            }
            providerMapping.push(providermap)
            //all clients enrolled then display message.
            if (openEnrollementFlag && medicadeChipActive) {
              this.state.formData.Application.myProviders.isOpenEnrollmentMsg =
                count == jsonDataArray.length
            }
          }
          this._getSelectedDataJSON(providerMapping)
        }
      })
      .catch(error => {
        console.error('MyProvidersNew _fetchDataLoad failed with ex', error)
        const code = CODES.MY_PROVIDERS_NEW_SELECTED_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _getApplicantProviderMapping = (responseData: Array<any>) => {
    let applicantProviderMaps = []

    var unique = []

    let mapping = []

    var localId = 0
    for (var j = 0; j < responseData.length; j++) {
      var clientId = responseData[j].prtcpId

      mapping.push(clientId)
    }
    unique = mapping.filter(function(item, i, ar) {
      return ar.indexOf(item) === i
    })

    for (var i = 0; i < unique.length; i++) {
      let applicantProviderMap = {
        applicantId: '',
        applicantName: '',
        providers: [],
        caseId: '',
        benefitCd: ''
      }
      for (var j = 0; j < responseData.length; j++) {
        var clientId = responseData[j].prtcpId ? responseData[j].prtcpId : ''
        var provider = responseData[j].provider ? responseData[j].provider : ''
        helper.cleanNullValues(provider)
        var clientName =
          (responseData[j].prtcpFirstName
            ? responseData[j].prtcpFirstName
            : '') +
          ' ' +
          (responseData[j].prtcpLastName ? responseData[j].prtcpLastName : '')
        if (unique[i] === clientId) {
          applicantProviderMap.applicantId = clientId
          applicantProviderMap.applicantName = clientName
          applicantProviderMap.providers.push(provider)
          applicantProviderMap.caseId = responseData[j].caseId
            ? responseData[j].caseId
            : null
          applicantProviderMap.benefitCd = responseData[j].benefitCd
            ? responseData[j].benefitCd
            : null
        }
      }
      applicantProviderMaps.push(applicantProviderMap)
    }

    let newFormData = helper.deepClone(this.state.formData)
    _.set(
      newFormData,
      'Application.myProviders.benefitsDdl.applicationProviderMapping',
      applicantProviderMaps
    )
    this.setState(prevState => {
      return {
        formData: newFormData,
        formContext: {
          ...prevState.formContext,
          formData: newFormData
        }
      }
    })
  }

  _getSelectedDataJSON = (providerMapping: Array<any>) => {
    let newForms = helper.deepClone(this.state.forms)
    let newFormData = helper.deepClone(this.state.formData)
    newForms = this._fillData(newForms, providerMapping)
    _.set(
      newFormData,
      'Application.myProviders.providerMapping',
      providerMapping
    )
    _.set(
      newFormData,
      'Application.myProviders.benefitsDdl.selectedBenefit',
      this.state.formData.Application.myProviders.benefitsDdl.selectedBenefit
    )
    helper.cleanNullValues(newFormData)
    this.setState(prevState => {
      return {
        forms: newForms,
        formData: newFormData,
        showMap: true,
        providerMapping: providerMapping[0],
        formContext: {
          ...prevState.formContext,
          formData: newFormData
        }
      }
    })
  }

  isButtonActive = (programCode: string) => {
    return programCode == 'CHIP' || programCode == 'Medicaid'
  }

  _setCRFEnable = () => {
    let formData = helper.deepClone(this.state.formData)
    let applicantId = formData.Application.myProviders.providerSearch.applicant
    let providerMappings = formData.Application.myProviders.providerMapping.filter(
      providerMapping =>
        providerMapping.memberData.needs[0].clientId == applicantId
    )
    let providerMapping =
      providerMappings.length > 0 ? providerMappings[0] : null
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData,
          isAdditionalSearchEnabled: true,
          showCRFMap: true,
          isSearchEnabled: true,
          providerMapping
        }
      }
    })
  }

  _enableSearchComponant = () => {
    this.setState(prevState => {
      return {
        formContext: {
          ...prevState.formContext,
          isAddNewProvider: true
        }
      }
    })
  }

  _disableSearchComponant = () => {
    this.setState(prevState => {
      return {
        formContext: {
          ...prevState.formContext,
          isAddNewProvider: false,
          isAdditionalSearchEnabled: false
        }
      }
    })
  }

  //Save enrollment
  _saveEnrollment = (selectedProvider: any) => {
    let formDataValue = this.state.formData
    let newForms = helper.deepClone(this.state.forms)

    let myEnrollments = []

    //find the enrollment
    var providermap =
      formDataValue['Application']['myProviders']['providerMapping']
    var caseId
    if (
      providermap &&
      providermap[0] &&
      providermap[0].memberData &&
      providermap[0].memberData.needs &&
      providermap[0].memberData.needs[0]
    ) {
      caseId = providermap[0].memberData.needs[0].caseId
    }
    let providerMapflag = true

    if (
      selectedProvider &&
      selectedProvider.needs &&
      selectedProvider.needs[0] &&
      selectedProvider.needs[0].details &&
      selectedProvider.needs[0].details[0] &&
      selectedProvider.needs[0].details[0].provider
    ) {
      let providerDetails = selectedProvider.needs[0].details[0].provider

      let myenrollment = _.cloneDeep(MyEnrollment)
      myenrollment.caseId = caseId
      myenrollment.clientId = selectedProvider.needs[0].clientId
      myenrollment.providerName = providerDetails.name
      myenrollment.srcProviderId = providerDetails.id
      myenrollment.providerTypeTxt = providerDetails.serviceType
      //Address
      myenrollment.providerAddress.address.addressLine1 =
        providerDetails.ADR_LN_1_TXT
      myenrollment.providerAddress.address.addressLine2 =
        providerDetails.ADR_LN_2_TXT
      myenrollment.providerAddress.address.city = providerDetails.CTY_NM
      myenrollment.providerAddress.address.state = providerDetails.ST
      myenrollment.providerAddress.address.zipcode = providerDetails.ZIP
      myenrollment.providerAddress.address.county = providerDetails.CNTY
      //Phone
      myenrollment.providerPhone.phone.telNumber1 = providerDetails.phone
      myenrollment.tenantId = config.tenant.id
      myenrollment.programName = this.state.benfProgCd
      myEnrollments.push(myenrollment)
    }

    //if changes found call save enrollment
    if (!_.isEmpty(myEnrollments)) {
      const fetchEndpoint = submitEnrollmentEndpoint.replace('{source}', 'CP')
      helper
        .fetchJson(fetchEndpoint, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: this.props.uuid,
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            Authorization: this.props.authHeader
          },
          method: 'POST',
          body: JSON.stringify(myEnrollments)
        })
        .then(res => {
          formDataValue.isChange = false
          //message should display openenrollment active and all client are enrolled and (Medicaid or CHIP)
          if (
            formDataValue.Application.myProviders.medicadeChipActive &&
            providerMapflag &&
            formDataValue.Application.myProviders.isOpenEnrollment
          ) {
            formDataValue.Application.myProviders.isOpenEnrollmentMsg = true
          }
          formDataValue.Application.myProviders.providerSearch = {}
          this.setState(prevState => {
            return {
              formData: formDataValue,
              formContext: {
                ...prevState.formContext,
                formData: formDataValue,
                isAdditionalSearchEnabled: false,
                showCRFMap: false
              }
            }
          })
          this._fetchDataLoad()
        })
        .catch(error => {
          console.error(
            'MyProvidersNew submit enrollment failed due to ex',
            error
          )
          if (
            error &&
            error.errorList &&
            error.errorList[0] &&
            error.errorList[0].errorCode === 'IE-ENRO-00G162'
          ) {
            let providerError = error.errorList[0]
            this.setState({ providerError, isProviderExistsError: true })
            return
          }
          const code = CODES.MY_PROVIDERS_NEW_SUBMIT
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _showMap = (providerMap: any) => {
    this.setState({ showMap: true, showAddrFlag: true })
    this.setState(prevState => {
      return {
        formContext: {
          ...prevState.formContext,
          showAddrFlag: true
        }
      }
    })
    this.setState({ providerMapping: providerMap })
  }

  _cancel = () => {
    this.setState({
      isModalOpen: false
    })
  }

  _closeCRFError = () => {
    this.setState({
      isProviderExistsError: false,
      providerError: null
    })
  }

  render() {
    const { presentation, locale } = this.props
    const {
      forms,
      formData,
      formContext,
      mapProps,
      showMap,
      showMapAddress,
      initialDataLoad,
      showCRFMap,
      previousMapProps,
      isSearchEnabled,
      isModalOpen,
      providerError,
      isProviderExistsError
    } = this.state

    const presentationProps = {
      forms,
      formData,
      formContext,
      mapProps,
      showMap,
      showMapAddress,
      initialDataLoad,
      showCRFMap,
      cancel: this._cancel,
      previousMapProps,
      isModalOpen,
      onFormDataChange: this._onFormDataChange,
      locale,
      closeCRFError: this._closeCRFError,
      providerError,
      isProviderExistsError
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

export default connect(mapStateToProps)(MyProvidersNewContainer)
