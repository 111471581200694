import * as React from 'react'
import { uniqBy } from 'lodash'
import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const _ = { uniqBy }

const tenantDirectoryName = config.tenant.code.toLowerCase()

const withMyAccountTabs = (
  WrappedComponent,
  basePromiseThunk,
  tenantPromiseThunk
) => {
  return class extends React.Component<any, any> {
    constructor(props) {
      super(props)
      this.state = {
        myAccountTabs: undefined
      }
    }

    componentDidMount() {
      Promise.all([tenantPromiseThunk(), basePromiseThunk()])
        .then(result => {
          const tenantTabs = result[0].MyAccountTabs
          const baseTabs = result[1].MyAccountTabs
          this.setState({
            myAccountTabs: _.uniqBy(
              [
                ...tenantTabs,
                ...baseTabs
                //Applying tenantTabs before baseTabs so that it will take precedence in the uniqBy duplicate removal.
              ],
              'id'
            )
          })
        })
        .catch(() => {
          basePromiseThunk().then(baseTabs => {
            this.setState({
              myAccountTabs: baseTabs.MyAccountTabs
            })
          })
        })
    }

    render() {
      return (
        <WrappedComponent
          myAccountTabs={this.state.myAccountTabs}
          {...this.props}
        />
      )
    }
  }
}

const MyAccountContainer = withMyAccountTabs(
  MultitenantLoadable(
    () => import('./MyAccountContainer'),
    () =>
      import('../../' + tenantDirectoryName + '/MyAccount/MyAccountContainer')
  ),
  () => import('./tabs'),
  () => import('../../' + tenantDirectoryName + '/MyAccount/tabs')
)

const MyAccountPresentation = MultitenantLoadable(
  () => import('./MyAccountPresentation'),
  () =>
    import('../../' + tenantDirectoryName + '/MyAccount/MyAccountPresentation')
)

export default function MyAccount(props) {
  return (
    <ErrorBoundary name="MyAccount">
      <MyAccountContainer
        {...props}
        presentation={presentationProps => (
          <MyAccountPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyAccountContainer = withMyAccountTabs(
  MultitenantLoadable(
    () => import('./MyAccountContainer'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./tabs'),
  () => Promise.resolve().then(() => null)
)

const BaseMyAccountPresentation = MultitenantLoadable(
  () => import('./MyAccountPresentation'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyAccount(props) {
  return (
    <BaseErrorBoundary name="MyAccount">
      <BaseMyAccountContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyAccountPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
