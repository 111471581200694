import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config' // endpoints
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import '!style-loader!css-loader!../src/customStyles.css'
import { connect } from 'react-redux'
import * as actions from '../../../actions'
import { UnauthorizedError } from '../src/components/utils'
import { Redirect } from 'react-router-dom'
import * as serviceHelper from '../src/components/utils'
import { I18n } from 'react-redux-i18n'
const baseUrl = process.env.PUBLIC_URL

const formsBaseUrl = config['formsBaseUrl']

const IncomeEndpoint = config['gatewayWvUrl'] + config['getIncomeLimits']
const formsEndpoint = formsBaseUrl + config['forms_MyIncome']

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  dataTable: Fields.DataTableField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  span: Widgets.SpanWidget,
  routeLink: Widgets.LinkWidget,
  iconWidget: Widgets.ConditionalIconWidget
}

interface incomeState {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  tableData: any
  tableData2: any
  application: any
  formContext: any
  redirect: any
}

export class CriWidget extends React.Component<any, incomeState> {
  constructor(props) {
    super(props)

    this.state = {
      application: {},
      forms: null,
      formContext: props.formContext,
      tableData: null,
      tableData2: null,
      redirect: null
    }
  }

  componentDidMount() {
    this.loadForms()
    this._getIncome()
  }

  loadForms() {
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName
        }
      })
      .then(formJson => {
        let application, forms, formContext
        application = formJson['app']
        forms = application['forms']

        this.setState(prevState => {
          return {
            ...prevState,
            application: application,
            forms: forms,
            formContext: {
              ...prevState.formContext,
              refs: application['metaData']['refs'],
              forms: forms
            }
          }
        })
      })
      .catch(error => {
        console.error('MyIncomes form fetch failed due to ex', error)
      })
  }

  _getIncome = () => {
    const houserHoldSize = this.state.formContext.formData.application.clients
      .length
    const fetchEndpoint = IncomeEndpoint
    let body = {
      houseHoldSize: 11,
      programName: 'CoCAll'
    }

    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          method: 'POST',
          body: JSON.stringify(body)
        },
        this.props.showErrorMessage
      )
      .then(json => {
        let arr = json
        arr.map(function(currentObj, index, arr) {
          currentObj.incomeLimit = '$' + currentObj.incomeLimit
        })
        const size = arr.filter(household => {
          return (
            household.houserHoldSize ===
            this.state.formContext.formData.application.clients.length
          )
        })
        const formData2 = { Application: { myIncomes: size || [] } }
        const formData1 = { Application: { myIncomes: arr || [] } }
        this.setState(prevState => {
          return {
            ...prevState,
            tableData: formData1,
            tableData2: formData2
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
      })
  }

  _table() {
    let tableId1 = document.getElementById('table1').className
    let tableId2 = document.getElementById('table2').className
    if (tableId1 == 'tableStyleShow') {
      document.getElementById('table1').className = 'tableShowNone'
      document.getElementById('table2').className = 'tableStyleShow'
    } else {
      document.getElementById('table1').className = 'tableStyleShow'
      document.getElementById('table2').className = 'tableShowNone'
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state
    }
  }

  render() {
    const { formContext, tableData, tableData2, forms, redirect } = this.state

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }

    return (
      <div>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          {I18n.t('ClientUpdateWizard.CriWidget1')}
        </h3>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          {I18n.t('ClientUpdateWizard.CriWidget2')}
        </h3>
        <ul>
          <li>{I18n.t('ClientUpdateWizard.CriWidget3')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget4')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget5')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget6')}</li>
        </ul>
        <p className="cri-p-space">{I18n.t('ClientUpdateWizard.CriWidget7')}</p>
        <div>
          <p className="cri-p-space">
            {I18n.t('ClientUpdateWizard.CriWidget23')}
          </p>
          <button
            style={{ backgroundColor: 'none' }}
            className="coc-link-style btn btn-default"
            onClick={this._table}
            type="button"
          >
            {I18n.t('ClientUpdateWizard.CriWidget24')}
          </button>
          {forms && forms.length > 0 ? (
            <div id="table1" className="tableStyleShow">
              <Form
                schema={forms[0]['schema']}
                uiSchema={forms[0]['uiSchema']}
                formData={tableData2}
                formContext={formContext}
                fields={fields}
                widgets={widgets}
                ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
                FieldTemplate={Templates.CustomFieldTemplate}
              >
                <input type="submit" style={{ display: 'none' }} />
              </Form>
            </div>
          ) : (
            ''
          )}
          {forms && forms.length > 0 ? (
            <div id="table2" className="tableShowNone">
              <Form
                schema={forms[1]['schema']}
                uiSchema={forms[1]['uiSchema']}
                formData={tableData}
                formContext={formContext}
                fields={fields}
                widgets={widgets}
                ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
                FieldTemplate={Templates.CustomFieldTemplate}
              >
                <input type="submit" style={{ display: 'none' }} />
              </Form>
            </div>
          ) : (
            ''
          )}
        </div>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          {I18n.t('ClientUpdateWizard.CriWidget8')}
        </h3>
        <p>{I18n.t('ClientUpdateWizard.CriWidget9')}</p>
        <ul>
          <li>{I18n.t('ClientUpdateWizard.CriWidget10')}</li>
          <li> {I18n.t('ClientUpdateWizard.CriWidget11')}</li>
          <li> {I18n.t('ClientUpdateWizard.CriWidget12')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget13')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget14')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget15')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget16')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget17')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget18')}</li>
          <li>{I18n.t('ClientUpdateWizard.CriWidget19')}</li>
        </ul>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          {I18n.t('ClientUpdateWizard.CriWidget20')}
        </h3>
        <ul>
          <li>{I18n.t('ClientUpdateWizard.CriWidget21')}</li>
        </ul>
        <p>{I18n.t('ClientUpdateWizard.CriWidget22')}</p>
        <br />
      </div>
    )
  }
}
function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CriWidget)
