import * as React from 'react'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { isEmpty, get } from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form' // json schema form library
import ConfirmModal from './ConfirmModal'
import { I18n } from 'react-redux-i18n'
import { Pagination } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Pagination'
import translateMyDocumentData from '../AppIntake/translateMyDocumentData'
import { Helmet } from 'react-helmet'

const _ = { isEmpty, get }

interface MyApplicationsPresentationProps {
  application: any
  forms: Array<{
    schema: any
    uiSchema: any
  }>
  formData: any
  formContext: any
  onFormDataChange: (formData: any) => void
  applicationWithdrawn: boolean
  retroActiveRequest: boolean
  modalWasClosed: () => void
  reviewFormContext: any
  goBack: () => void
  changePage: (eventKey: number) => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  minHeight: string
  locale: string
  pagination: any
}

function translateApplicationStatus(status: string) {
  switch ((status || '').toLowerCase()) {
    case 'draft':
      return I18n.t('General.draft')
    case 'submitted':
      return I18n.t('General.submitted')
    default:
      return status
  }
}

export default function MyApplicationsPresentation(
  props: MyApplicationsPresentationProps
) {
  const {
    application,
    forms,
    formData,
    formContext,
    onFormDataChange,
    applicationWithdrawn,
    retroActiveRequest,
    modalWasClosed,
    reviewFormContext,
    goBack,
    formComponents,
    locale,
    changePage,
    pagination
  } = props

  let withdrawnModal = applicationWithdrawn ? (
    <ConfirmModal
      open={applicationWithdrawn}
      modalWasClosed={modalWasClosed}
      retro={false}
    />
  ) : null
  let retroActiveRequestModal = retroActiveRequest ? (
    <ConfirmModal
      open={retroActiveRequest}
      modalWasClosed={modalWasClosed}
      retro={true}
    />
  ) : null

  if (reviewFormContext && reviewFormContext.reviewFormData) {
    const newReviewFormContext = {
      ...reviewFormContext,
      reviewFormData: translateMyDocumentData(reviewFormContext.reviewFormData)
    }
    return (
      <Form
        schema={forms[1]['schema']}
        uiSchema={forms[1]['uiSchema']}
        formData={{}}
        formContext={newReviewFormContext}
        fields={formComponents.fields}
        widgets={formComponents.widgets}
        ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
        FieldTemplate={formComponents.FieldTemplate}
        locale={locale}
      >
        <button type="submit" style={{ display: 'none' }} />
        <div>
          <button
            type="button"
            className="btn btn-default float-right"
            onClick={goBack}
          >
            {I18n.t('General.backButton')}
          </button>
        </div>
      </Form>
    )
  } else {
    if (!_.isEmpty(application)) {
      const newFormData = {
        applicationSummaries: ((formData || {}).applicationSummaries || []).map(
          record => {
            return {
              ...record,
              status: translateApplicationStatus(record.status)
            }
          }
        )
      }
      const newFormContext = {
        ...formContext,
        formData: newFormData
      }
      const pageCount = Math.ceil(
        pagination.recordCount / pagination.recordsPerPage
      )
      const activePage = pagination.pageIndex
      const start =
        pagination.recordsPerPage * activePage - pagination.recordsPerPage + 1
      const end =
        activePage == pageCount
          ? pagination.recordCount
          : pagination.recordsPerPage * activePage

      return (
        <div>
          <Helmet>
            <title>{I18n.t('MyApplications.title')}</title>
          </Helmet>
          {withdrawnModal}
          {retroActiveRequestModal}
          <div className="form-section">
            <h1>
              <i className="pe-7s-note2" /> {I18n.t('MyApplications.title')}
            </h1>
            <Form
              schema={forms[0]['schema']}
              uiSchema={forms[0]['uiSchema']}
              formData={newFormData}
              onChange={onFormDataChange}
              formContext={newFormContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              locale={locale}
            >
              <button type="submit" style={{ display: 'none' }} />
            </Form>
            <div className="float-left messageRegularLine">
              <span>
                {I18n.t('General.displaying')} {start} - {end}
              </span>
            </div>
            <Pagination
              prev={'‹'}
              next={'›'}
              first={'«'}
              last={'»'}
              ellipsis={true}
              boundaryLinks={true}
              items={pageCount}
              maxButtons={3}
              activePage={pagination.pageIndex}
              onSelect={changePage}
            />
          </div>
          <script>
            ($('.panel-heading:even').css("backgroundColor", "#4190C1"));
          </script>
        </div>
      )
    }
  }
  return (
    <div>
      <ScreenPreLoader loaded={false} minHeight={props.minHeight} />
    </div>
  )
}
