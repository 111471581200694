export default function externalAuth(state = { hasPin: null }, action) {
  switch (action.type) {
    case 'SET_HAS_PIN':
      return {
        ...state,
        hasPin: action.hasPin
      }
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        hasPin: false
      }
    default:
      return state
  }
}
