import * as React from 'react'
import { isEmpty } from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { I18n } from 'react-redux-i18n'

const _ = { isEmpty }

interface MyLifeEventsPresentationProps {
  form: {
    schema: Object
    uiSchema: Object
  }
  formContext: any
  formData: any
  onFormDataChange: (formData: any) => void
  isLoading: boolean
  minHeight: string
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  callingApi: boolean
  locale: string
}

document.title = I18n.t('MyLifeEvents.title')

export default function MyLifeEventsPresentation(
  props: MyLifeEventsPresentationProps
) {
  const {
    form,
    formContext,
    formData,
    onFormDataChange,
    isLoading,
    minHeight,
    formComponents,
    callingApi,
    locale
  } = props

  const header = isLoading ? (
    <h3>
      <div id="content" style={{ display: 'table' }} tabIndex={-1}>
        <h1 style={{ display: 'table-cell' }}>
          <i className="fa fa-child" />
          {I18n.t('MyLifeEvents.title')}
        </h1>
        <i
          className="fa fa-spinner fa-spin float-right"
          style={{ fontSize: '24px', display: 'table-cell' }}
        />
      </div>
    </h3>
  ) : (
    <h1>
      <i className="pe-7s-compass" />
      {I18n.t('MyLifeEvents.title')}
    </h1>
  )

  if (form && !_.isEmpty(form) && formData) {
    return (
      <div id="content" className="form-section" tabIndex={-1}>
        {header}
        {formData.length > 0 ? (
          <Form
            schema={form['schema']}
            uiSchema={form['uiSchema']}
            formData={formData}
            onChange={onFormDataChange}
            formContext={formContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
            locale={locale}
          >
            <button
              type="submit"
              style={{ display: 'none' }}
              disabled={callingApi}
            />
          </Form>
        ) : (
          <p>{I18n.t('MyLifeEvents.noActiveProgs')}</p>
        )}
      </div>
    )
  }
  return <ScreenPreLoader minHeight={minHeight} />
}
