import * as React from 'react'
import { findIndex, isEmpty } from 'lodash'
import Select from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/widgets/SelectWidget'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { I18n } from 'react-redux-i18n'

const _ = { findIndex, isEmpty }

export default class ProviderSelectWidget extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      value: props.value,
      formContext: props.formContext,
      enumOptions: props.options.enumOptions
    }
  }

  _handleChange(value) {
    if (value === '{new provider}') {
      const index = this.props.id.match('(?:.*)providerMapping_(.+?)_?')[1]
      const providerMapping = this.props.formContext.formData.Application
        .myProviders.providerMapping[index]
      this.props.formContext.selectProvider(providerMapping)
      this.props.formContext.showMap(providerMapping)
    } else {
      let name = value
      const selectedIndex = this.props.id.match(
        '(?:.*)providerMapping_(.+?)_?'
      )[1]
      let appliedBenefit = this.props.formContext.formData.Application
        .myProviders.providerMapping[selectedIndex].appliedBenefit
      const index = _.findIndex(
        this.props.formContext.formData.Application.myProviders.providerMapping,
        function(element) {
          return (
            element['providerName'] === name &&
            element['appliedBenefit'] === appliedBenefit
          )
        }
      )

      this.props.formContext.sameasProvider(selectedIndex, index)
    }
  }

  _handleDelete() {
    const removeIndex = this.props.id.match('(?:.*)providerMapping_(.+?)_?')[1]
    this.setState({ value: null }, () => {
      this.props.formContext.deleteProvider(this.props.id, removeIndex)
    })
  }

  _handleLinkTextToggle() {
    const index = this.props.id.match('(?:.*)providerMapping_(.+?)_?')[1]
    this.setState({ value: null }, () => {
      this.props.formContext.toggleShowText(index)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    })
  }
  //botton functionality verification
  button = index => {
    if (
      this.props.formContext.formData.Application.myProviders
        .medicadeChipActive &&
      this.props.formContext.formData.Application.myProviders
        .isOpenEnrollment &&
      !this.props.formContext.formData.Application.myProviders.providerMapping[
        index
      ].change
    ) {
      return <div />
    } else {
      return (
        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={this._handleDelete.bind(this)}
          >
            {I18n.t('MyProviders.providerSelectWidget.changeProvider')}
          </button>
        </div>
      )
    }
  }

  render() {
    if (
      typeof this.props.value === 'string' ||
      this.props.value instanceof String
    ) {
      const index =
        this.props.id.match('(?:.*)providerMapping_(.+?)_?') &&
        this.props.id.match('(?:.*)providerMapping_(.+?)_?')[1]
      if (
        !_.isEmpty(
          this.props.formContext.formData.Application.myProviders
            .providerMapping
        )
      ) {
        let addressLine1 = this.props.formContext.formData.Application
          .myProviders.providerMapping[index].memberData.needs[0].addresses[0]
          .addressLine1
        let addressLine2 = this.props.formContext.formData.Application
          .myProviders.providerMapping[index].memberData.needs[0].addresses[0]
          .addressLine2
        let phone = this.props.formContext.formData.Application.myProviders
          .providerMapping[index].memberData.needs[0].addresses[0].phone
        let website = this.props.formContext.formData.Application.myProviders
          .providerMapping[index].memberData.needs[0].addresses[0].website
        let hours = this.props.formContext.formData.Application.myProviders
          .providerMapping[index].memberData.needs[0].addresses[0].hours

        if (
          this.props.formContext.formData.Application.myProviders
            .providerMapping[index].readMoreFlag === false
        ) {
          let ShowText = I18n.t('MyProviders.providerSelectWidget.showLess')
          return (
            <div>
              <div id={this.props.id} className="bold">
                {this.props.value}
              </div>
              <div>{!_.isEmpty(addressLine1) ? addressLine1 : ' '}</div>
              <div>{!_.isEmpty(addressLine2) ? addressLine2 : ' '}</div>
              <div>
                {I18n.t('MyProviders.providerSelectWidget.phone')} :{' '}
                {!_.isEmpty(phone) ? phone : ' '}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-link btn-link-dark myProvider_btn"
                  onClick={this._handleLinkTextToggle.bind(this)}
                >
                  {ShowText}
                </button>
              </div>
              <div>
                {I18n.t('MyProviders.providerSelectWidget.website')}:{' '}
                {!_.isEmpty(website) ? website : ' '}{' '}
              </div>
              <div>
                {I18n.t('MyProviders.providerSelectWidget.hours')}:{' '}
                {!_.isEmpty(hours) ? hours : ' '}
              </div>
              <div className="myProvider_btn">
                {I18n.t('MyProviders.providerSelectWidget.viewMap')}
              </div>
              {this.button(index)}
            </div>
          )
        } else {
          let ShowText = I18n.t('MyProviders.providerSelectWidget.readMore')
          return (
            <div>
              <div id={this.props.id} className="bold">
                {this.props.value}
              </div>
              <div>{!_.isEmpty(addressLine1) ? addressLine1 : ' '}</div>
              <div>{!_.isEmpty(addressLine2) ? addressLine2 : ' '}</div>
              <div>
                {I18n.t('MyProviders.providerSelectWidget.phone')} :{' '}
                {!_.isEmpty(phone) ? phone : ' '}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-link btn-link-dark myProvider_btn"
                  onClick={this._handleLinkTextToggle.bind(this)}
                >
                  {ShowText}
                </button>
              </div>
              {this.button(index)}
            </div>
          )
        }
      }
    }

    if (
      _.isEmpty(this.props.value) &&
      this.props.options.enumOptions === false
    ) {
      let newProps = this.props
      const newEnums = []
      newEnums.push({ label: 'New Provider', value: '{new provider}' })
      let mappings = this.props.formContext.formData.Application.myProviders
        .providerMapping
      if (mappings && mappings.length > 0) {
        for (let j = 0; j < mappings.length; ++j) {
          let pName = this.props.formContext.formData.Application.myProviders
            .providerMapping[j].providerName

          if (pName && !_.isEmpty(pName)) {
            newEnums.push({
              label: this.props.formContext.formData.Application.myProviders
                .providerMapping[j].providerName,
              value: this.props.formContext.formData.Application.myProviders
                .providerMapping[j].providerName
            })
          }
        }
      }
      newProps['options']['enumOptions'] = newEnums
      return (
        <Select
          {...newProps}
          onChange={this._handleChange.bind(this)}
          placeholder={I18n.t(
            'MyProviders.providerSelectWidget.selectProvider'
          )}
        />
      )
    }

    let newProps = this.props

    if (typeof this.props.options.enumOptions !== 'undefined') {
      newProps['options']['enumOptions'] = helper.resolveEnumRefs(
        this.props.options.enumOptions,
        this.props.formContext,
        this.props.id,
        this.props.locale
      )
    }
    return (
      <Select
        {...newProps}
        onChange={this._handleChange.bind(this)}
        placeholder={I18n.t('MyProviders.providerSelectWidget.selectProvider')}
      />
    )
  }
}
