import * as React from 'react'
import { isEmpty } from 'lodash'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config'
import { Modal } from 'react-bootstrap'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { I18n } from 'react-redux-i18n'

const _ = { isEmpty }

const removeProviderEndPoint = config['removeProviderInfo']

export default class ProviderInformation extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      forms: this.props.form,
      formData: this.props.formData,
      formContext: this.props.formContext,
      done: false,
      message: ''
    }
    this._onCancel = this._onCancel.bind(this)
    this._message = this._message.bind(this)
  }

  UNSAFE_componentWillMount() {
    let formContext = helper.deepClone(this.state.formContext)
    formContext = {
      ...formContext,
      component: this,
      removeProvider: this._removeProvider,
      _message: this._message,
      _onExit: this._onExit,
      config
    }
    this.setState({
      formContext
    })
  }

  loadData = () => {
    let formData = helper.deepClone(this.state.formData)
    this.setState({ formData }, () => this._refreshFormContext())
  }

  /**
   * Displaying message in the popup
   */
  _message = message => {
    this.setState({ done: true, message: message })
  }

  _refreshFormContext = () => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        formData: helper.deepClone(this.state.formData)
      }
    })
  }

  /**
   * Remove Provider of client for the given providerId and clientId.
   */
  _removeProvider = () => {
    let { providerInfo, clientId, caseId, benefitCd } = helper.deepClone(
      this.props.formContext
    )

    const removeClientProvider = removeProviderEndPoint
      .replace('{providerId}', providerInfo.id)
      .replace('{clientId}', clientId)
      .replace('{caseId}', caseId)
      .replace('{programName}', benefitCd.toUpperCase())

    return helper
      .fetchJson(removeClientProvider, {
        method: 'DELETE',
        headers: {}
      })
      .then(json => {
        if (
          _.isEmpty(json.status) &&
          json['messageElements']['messageStatus'] == 'SUCCESS'
        ) {
          let msg = I18n.t('MyProvidersCCA.successfullyUnenrolled')
          let errorList = json['messageElements']['errorList']

          if (
            errorList != undefined &&
            errorList.length > 0 &&
            errorList[0]['errorCode'] == '6000'
          ) {
            msg = errorList[0]['errorDescription']
          }
          //Displaying message "Success" and Popup
          this._message(msg)
          this._refreshFormContext()
        } else {
          //Displaying message "Fail" and Popup
          this._message(I18n.t('MyProvidersCCA.statusFailed'))
        }
      })
      .catch(ex => {
        console.error(ex)
        this._message(I18n.t('MyProvidersCCA.statusFailed'))
      })
  }
  _onCancel = () => {
    this.props.cancel()
  }

  /**
   * Closing popup
   */
  _onExit = () => {
    this.setState({ done: false })
    this.props.cancel()
  }

  render() {
    const { formComponents, formData, locale } = this.props
    const {
      forms,
      application,
      formContext,
      mapProps,
      registry,
      isModalOpen,
      done,
      message
    } = this.state

    return (
      <div>
        <Modal
          show={this.props.isModalOpen}
          onHide={this._onCancel}
          aria-labelledby="contained-modal-title"
          bsSize="large"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {"I18n.t('MyProvidersCCA.providerInformation')"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              schema={forms[1]['schema']}
              uiSchema={forms[1]['uiSchema']}
              formData={formData}
              formContext={formContext}
              widgets={formComponents.widgets}
              fields={formComponents.fields}
              FieldTemplate={formComponents.FieldTemplate}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              locale={locale}
            >
              <button type="submit" style={{ display: 'none' }} />
            </Form>
          </Modal.Body>
        </Modal>
        <ConfirmModal show={done} message={message} onClose={this._onExit} />
      </div>
    )
  }
}

function ConfirmModal(props) {
  const { show, message, onClose } = props
  return (
    <div className="static-modal">
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="glyphicon myglyphicon-check" />
            {I18n.t('MyProvidersCCA.providerInformation.removeProvider')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">{message}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-default" onClick={onClose}>
            {I18n.t('General.ok')}OK
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
