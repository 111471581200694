import * as React from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { ENTITLEMENTS_wv as ENTITLEMENTS } from '../src/entitlements_wv'
import { config } from '../../../config'
import 'airbnb-js-shims'

declare var process
const baseUrl = process.env.PUBLIC_URL

interface MyAccountTab {
  id: string
  clientTitle: {
    en: string
    es: string
  }
  otherTitle: {
    en: string
    es: string
  }
  title: string
  leftIcon: string
  component: React.ReactNode
  entitlements: Array<string>
}

interface MyAccountContainerProps {
  presentation: any
  location: {
    search: string
    pathname: string
  }
  history: {
    push: (url: string) => void
  }
  userAccess
  myAccountTabs: Array<MyAccountTab>
  selectedEntitlements: Array<string>
  externalAuth?: {
    hasPin: boolean
  }
  caseTypes
  locale: string
  userRoleId: number
  userRoles: Array<any>
}

interface MyAccountContainerState {
  id: string
  minHeight: number
  navTabs: Array<MyAccountTab>
  prevProps: any
}

const caseCheck = (tab, caseTypes) => {
  if (tab.hasOwnProperty('caseCheck')) {
    if (_.includes(tab.caseCheck, 'cs')) {
      return caseTypes.cs
    }
    if (_.includes(tab.caseCheck, 'fa') || _.includes(tab.caseCheck, 'cca')) {
      return caseTypes.cca || caseTypes.fa
    }
    return true
  }
  return true
}

const navigationTabs = (
  myAccountTabs,
  entitlements,
  externalAuth,
  caseTypes
) => {
  let tabs = []
  //Filter tabs by entitlement.
  _.forEach(myAccountTabs, tab => {
    if (
      _.isEmpty(tab.entitlements) ||
      _.some(tab.entitlements, tabEntitlement => {
        return (
          _.includes(entitlements, tabEntitlement) && caseCheck(tab, caseTypes)
        )
      })
    ) {
      tabs.push(tab)
    }
  })

  //Filter tabs if we need them to enter a PIN.
  if (entitlements.includes(ENTITLEMENTS.CLIENT_PARTICIPATION)) {
    if (
      _.get(config.tenant, 'externalAuth.checkHasPin', false) &&
      !externalAuth.hasPin
    ) {
      tabs = tabs.filter(tab => {
        switch (tab.id) {
          case 'profile':
          case 'applications':
          case 'documents':
          case 'permissions':
          case 'dashboard':
            return true
          default:
            return false
        }
      })
    }
    return sortTabs(tabs, entitlements)
  }
  return sortTabs(tabs, entitlements)
}

const sortTabs = (tabs, entitlements) => {
  //Not sure if this should be moved to presentation.

  let sortedTabs = [...tabs]
  sortedTabs = sortTabsForClient(sortedTabs)
  _.each(entitlements, entitlement => {
    switch (entitlement) {
      case ENTITLEMENTS.CLIENT_PARTICIPATION:
        sortedTabs = sortTabsForClient(sortedTabs)
        return false
      case ENTITLEMENTS.COMMUNITY_PARTNERSHIP:
        sortedTabs = sortTabsForCommunityPartner(sortedTabs)
        return false
      case ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT:
        sortedTabs = sortTabsForPEWorker(sortedTabs)
        return false
      case ENTITLEMENTS.CP_Admin:
        sortedTabs = sortTabsForCPAdmin(sortedTabs)
        return false
      // case ENTITLEMENTS.CP_REPORTS:
      // 	sortedTabs = sortedTabsForWorkerAdmin(sortedTabs);
      // 	return false;
      case ENTITLEMENTS.BH_ELIGIBLE_APPLICANT_VIEW:
        sortedTabs = sortTabsForBenH(sortedTabs)
        return false
      case ENTITLEMENTS.BH_REFERRAL:
        sortedTabs = sortTabsForBenHRef(sortedTabs)
        return false
    }
  })

  return sortedTabs
}

const sortTabsForCommunityPartner = tabs => {
  tabs = tabs.filter(
    tabs =>
      tabs.id != 'documents' && tabs.id != 'reports' && tabs.id != 'search'
  )
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'dashboard':
          return 1
        case 'applications':
          return 2
        case 'renewal':
          return 3
        case 'profile':
          return 4
        case 'quickreports':
          return 5
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

const sortTabsForClient = tabs => {
  tabs = tabs.filter(
    tabs =>
      tabs.id != 'tasks' && tabs.id != 'documents' && tabs.id != 'providers'
  )
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'profile':
          return 1
        case 'permissions':
          return 2
        case 'applications':
          return 3
        case 'benefits':
          return 4
        case 'tasks':
          return 5
        case 'appeals':
          return 6
        case 'messages':
          return 7
        case 'childsupportpayments':
          return 8
        case 'providers':
          return 9
        case 'providersNew':
          return 10
        case 'documents':
          return 11
        case 'lifeevents':
          return 12
        case 'appointments':
          return 13
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

const sortTabsForPEWorker = tabs => {
  tabs = tabs.filter(
    tabs =>
      tabs.id != 'quickreports' &&
      tabs.id != 'documents' &&
      tabs.id != 'reports' &&
      tabs.id != 'search'
  )
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'dashboard':
          return 1
        case 'applications':
          return 2
        case 'profile':
          return 3
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

const sortTabsForCPAdmin = tabs => {
  tabs = tabs.filter(tabs => tabs.id != 'reports' && tabs.id != 'search')
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'dashboard':
          return 1
        case 'applications':
          return 2
        case 'renewal':
          return 3
        case 'quickreports':
          return 4
        case 'profile':
          return 5
        case 'userAccess':
          return 6
        case 'organization':
          return 7
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

const sortTabsForBenH = tabs => {
  tabs = tabs.filter(
    tabs =>
      tabs.id != 'reports' &&
      tabs.id != 'search' &&
      tabs.id != 'tasks' &&
      tabs.id != 'documents' &&
      tabs.id != 'providers'
  )
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'eligibleApplicants':
          return 1
        case 'referrals':
          return 2
        case 'profile':
          return 3
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

const sortTabsForBenHRef = tabs => {
  tabs = tabs.filter(
    tabs =>
      tabs.id != 'reports' &&
      tabs.id != 'search' &&
      tabs.id != 'tasks' &&
      tabs.id != 'documents' &&
      tabs.id != 'providers'
  )
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'referrals':
          return 1
        case 'profile':
          return 2
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

class MyAccountContainer extends React.Component<
  MyAccountContainerProps,
  MyAccountContainerState
> {
  private navDiv: HTMLDivElement

  constructor(props: MyAccountContainerProps) {
    super(props)
    this.navDiv = (React as any).createRef()

    this.state = {
      id: '',
      minHeight: 0,
      navTabs: null,
      prevProps: null
    }
  }

  static getDerivedStateFromProps(
    props: MyAccountContainerProps,
    state: MyAccountContainerState
  ) {
    if (
      !state.prevProps ||
      props.myAccountTabs != state.prevProps.myAccountTabs ||
      props.selectedEntitlements != state.prevProps.selectedEntitlements ||
      props.externalAuth != state.prevProps.externalAuth ||
      props.caseTypes != state.prevProps.caseTypes
    ) {
      if (!props.myAccountTabs || props.myAccountTabs.length === 0) {
        return null
      }
      const navTabs = navigationTabs(
        props.myAccountTabs,
        props.selectedEntitlements,
        props.externalAuth,
        props.caseTypes
      )

      let newId = state.id

      if (navTabs && navTabs.length > 0) {
        if (!newId || newId === '') {
          const subPath = window.location.href.match(
            '\\/myaccount\\/([a-zA-Z0-9]+)'
          )
          if (subPath && subPath[1]) {
            const tabIndex = _.findIndex(navTabs, navTab => {
              return subPath[1] === navTab.id
            })
            if (tabIndex > -1) {
              newId = navTabs[tabIndex].id
            } else {
              newId = navTabs[0].id
            }
          } else {
            //TODO: Should the first tab always be the default/fallback?
            newId = navTabs[0].id
          }
        } else {
          const tabIndex = _.findIndex(navTabs, navTab => {
            return newId === navTab.id
          })
          if (tabIndex === -1) {
            newId = navTabs[0].id
          }
        }
      } else {
        newId = ''
      }
      return {
        navTabs,
        id: newId,
        prevProps: props
      }
    } else {
      return null
    }
  }

  componentDidMount() {
    if (this.state.id != undefined && this.state.id === '') {
      let subPath = window.location.href.match('\\/myaccount\\/([a-zA-Z0-9]+)')
      if (subPath != undefined && subPath[1] != undefined)
        this.setState({ id: subPath[1] })
    }
    if (this.navDiv && this.navDiv.clientHeight) {
      this.setState({ minHeight: this.navDiv.clientHeight })
    }
    if (this.props.history) {
      if (this.state.id && this.state.id !== '') {
        if (
          !this.props.location.pathname.match(
            baseUrl + '/myaccount/' + this.state.id
          )
        ) {
          this.props.history.push(baseUrl + '/myaccount/' + this.state.id)
        }
      } else {
        if (!this.props.location.pathname.match(baseUrl + '/myaccount/?$')) {
          this.props.history.push(baseUrl + '/myaccount/')
        }
      }
    }
    if (this.state.id && this.state.id !== '') {
      this.focusMainContent()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.navDiv &&
      this.navDiv.clientHeight &&
      this.navDiv.clientHeight !== this.state.minHeight
    ) {
      this.setState({ minHeight: this.navDiv.clientHeight })
    }
    if (this.props.history) {
      if (this.state.id && this.state.id !== '') {
        const subPathArr = this.props.location.pathname.match(
          '\\/myaccount\\/([a-zA-Z0-9]+)'
        )
        if (subPathArr != null) {
          const subPath = subPathArr[1]
          if (
            !this.props.location.pathname.match(
              baseUrl + '/myaccount/' + this.state.id
            ) &&
            this.state.id != subPath
          ) {
            //this.props.history.push(baseUrl + '/myaccount/' + subPath)
            this.setState({ id: subPath })
          }
        } else if (
          !this.props.location.pathname.match(
            baseUrl + '/myaccount/' + this.state.id
          )
        ) {
          this.props.history.push(baseUrl + '/myaccount/' + this.state.id)
          //this.setState({ id: this.state.id })
        }
      } else {
        if (!this.props.location.pathname.match(baseUrl + '/myaccount/?$')) {
          this.props.history.push(baseUrl + '/myaccount/')
        }
      }
    }
    if (
      this.state.id &&
      this.state.id !== '' &&
      this.state.id !== prevState.id
    ) {
      this.focusMainContent()
    }
    const selRoleName = _.get(
      this.props,
      'userAccess.selectedUserRole.role.roleName',
      ''
    )
    const selOrgId = _.get(this.props, 'userAccess.selectedOrg.orgId', '')
    const prevOrgId = _.get(prevProps, 'userAccess.selectedOrg.orgId', '')
    const selUserRoleId = _.get(
      this.props,
      'userAccess.selectedUserRole.role.roleId',
      ''
    )
    const prevRoleId = _.get(
      prevProps,
      'userAccess.selectedUserRole.role.roleId',
      ''
    )
    if (
      (selRoleName == 'BH_HR_SPEC' || selRoleName == 'BH_ADMIN_ASST') &&
      (prevOrgId !== selOrgId || prevRoleId !== selUserRoleId)
    ) {
      this.props.history.push(baseUrl + '/myaccount/eligibleApplicants')
      this.setState({ id: 'eligibleApplicants' })
    } else if (
      selRoleName == 'BH_RES_COOR' &&
      (prevOrgId !== selOrgId || prevRoleId !== selUserRoleId)
    ) {
      this.props.history.push(baseUrl + '/myaccount/referrals')
      this.setState({ id: 'referrals' })
    } else if (
      selRoleName == 'Client' &&
      (prevOrgId !== selOrgId || prevRoleId !== selUserRoleId)
    ) {
      this.props.history.push(baseUrl + '/myaccount/profile')
      this.setState({ id: 'profile' })
    } else if (
      selRoleName == 'Worker Admin' &&
      (prevOrgId !== selOrgId || prevRoleId !== selUserRoleId)
    ) {
      this.props.history.push(baseUrl + '/myaccount/reports')
      this.setState({ id: 'reports' })
    } else if (
      selRoleName !== 'Client' &&
      (prevOrgId !== selOrgId || prevRoleId !== selUserRoleId)
    ) {
      this.props.history.push(baseUrl + '/myaccount/dashboard')
      this.setState({ id: 'dashboard' })
    }
  }
  focusMainContent = () => {
    setTimeout(() => {
      const mainContent = document.getElementById('content')
      if (mainContent) {
        mainContent.focus()
      }
    }, 0)
  }

  _onNavClick = tabId => {
    this.props.history.push(baseUrl + '/myaccount/' + tabId)

    this.setState({ id: tabId })
  }

  render() {
    const {
      presentation,
      selectedEntitlements,
      locale,
      userRoleId,
      userRoles,
      history
    } = this.props
    const { id, minHeight, navTabs } = this.state

    if (_.isNil(navTabs)) {
      return <div>Loading...</div>
    }

    let subProps: any = {
      key: id + '_' + userRoleId.toString()
    }
    if (minHeight) {
      subProps = {
        ...subProps,
        minHeight: '' + minHeight + 'px',
        history
      }
    }
    const presentationProps = {
      id,
      subProps,
      navTabs,
      onNavClick: this._onNavClick,
      setNavDiv: el => {
        this.navDiv = el
      },
      selectedEntitlements,
      userRoles
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  return {
    externalAuth: state.externalAuth,
    userRoleId: _.get(state.userAccess, 'selectedUserRole.userRoleId') || '',
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    userRoles: _.get(state.userAccess, 'userRoles', []),
    userAccess: state.userAccess,
    caseTypes: _.get(state, 'caseTypes.caseTypes')
  }
}

export default connect(mapStateToProps)(MyAccountContainer)
