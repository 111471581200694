import * as React from 'react'
import { includes, omit, findIndex } from 'lodash'
import NavTabs from '@optum-wvie/dynamic-ui-framework/src/components/natives/NavTabs'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import { I18n } from 'react-redux-i18n'

const _ = { includes, omit, findIndex }

export default function MyAccountPresentation(
  props: MyAccountPresentationProps
) {
  const {
    id,
    subProps,
    navTabs,
    onNavClick,
    setNavDiv,
    selectedEntitlements,
    locale
  } = props

  let finalNavTabs = null
  if (_.includes(selectedEntitlements, ENTITLEMENTS.CLIENT_PARTICIPATION)) {
    finalNavTabs = (navTabs || []).map(tab => {
      return {
        ..._.omit(tab, 'clientTitle', 'otherTitle'),
        title: I18n.t(tab.clientTitle)
      }
    })
  } else {
    finalNavTabs = (navTabs || []).map(tab => {
      return {
        ..._.omit(tab, 'clientTitle', 'otherTitle'),
        title: I18n.t(tab.otherTitle)
      }
    })
  }

  let ActiveSubcomponent = null
  if (finalNavTabs.length === 0) {
    ActiveSubcomponent = props => <div>{I18n.t('MyAccount.noTabs')}</div>
  } else {
    const activeTabIndex = _.findIndex(navTabs, { id })
    if (activeTabIndex > -1) {
      ActiveSubcomponent = navTabs[activeTabIndex].component
    } else {
      ActiveSubcomponent = props => (
        <div>{I18n.t('MyAccount.invalidSubcomponentID')}</div>
      )
    }
  }

  return (
    <div>
      <div className="header-view">
        <p className="heading">
          <i className="myglyphicon-halflings myglyphicon-user myaccount-icon" />{' '}
          {I18n.t('MyAccount.myAccount')}
        </p>
        <div className="header-sub" />
      </div>
      <div className="below-header-bar" />
      <div className="row my-account">
        <div
          id="side-nav"
          className="tabs-section nav col-md-2 col-sm-3 no-padding"
          ref={el => setNavDiv(el)}
        >
          <NavTabs activeKey={id} onNavClick={onNavClick} tabs={finalNavTabs} />
        </div>
        <div
          id="content"
          className="form-section col-md-10 col-sm-9"
          tabIndex={-1}
        >
          <ActiveSubcomponent {...subProps} />
        </div>
      </div>
    </div>
  )
}
