import * as React from 'react'
import { Modal } from 'react-bootstrap'
import ConfirmModal from './ConfirmModal'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
//import Pagination from 'react-js-pagination'
import { Pagination } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Pagination'
import { ErrorModal } from '../src/components/natives/ErrorModal'

interface SearchPEApplicationsPresentationProps {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  loadScreen: boolean
  onFormDataChange: (formData: any) => void
  searchPEApplications: () => void
  clearForm: () => void
  formKey: string
  isLoading: boolean
  showUploadModal: boolean
  closeUploadModal: () => void
  onFormDataChangeModal: (formData: any) => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  popupoad: boolean
  applId: any
  uuid: any
  userId: any
  orgId: any
  userRoleId: any
  errorDisplay: boolean
  activeCount: number
  totalCount: number
  showOption: string
  pageCountDisplay: number
  onPaginationClick: () => void
  errorMessage: Array<any>
  onDeleteClick: () => void
  setPopupoadToFalse: any
}

export default function SearchPEApplicationsPresentation(
  props: SearchPEApplicationsPresentationProps
) {
  let confirmationModal

  const {
    forms,
    formData,
    formContext,
    loadScreen,
    onFormDataChange,
    searchPEApplications,
    errorDisplay,
    clearForm,
    formKey,
    showUploadModal,
    closeUploadModal,
    onFormDataChangeModal,
    formComponents,
    popupoad,
    applId,
    uuid,
    userRoleId,
    orgId,
    userId,
    activeCount,
    onPaginationClick,
    totalCount,
    showOption,
    pageCountDisplay,
    isLoading,
    errorMessage,
    onDeleteClick
  } = props

  confirmationModal = popupoad ? (
    <ConfirmModal
      getApplID={applId}
      getUUID={uuid}
      getUserRoleId={userRoleId}
      getuserId={userId}
      getorgId={orgId}
      setPopupoadToFalse={props.setPopupoadToFalse}
    />
  ) : null

  if (loadScreen) {
    return (
      <div className="container-fluid reports-container">
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <div>
          <ScreenPreLoader loaded={!isLoading} />
        </div>
        {errorDisplay && (
          <div className="panel panel-danger errors">
            <div className="panel-heading">
              <h3 className="panel-title">Errors</h3>
            </div>
            <ul className="list-group">
              <div>
                <li className="list-group-item text-danger">
                  End date cannot be prior to the start date.
                </li>
              </div>
            </ul>
          </div>
        )}
        <Form
          key={formKey}
          schema={forms[0]['schema']}
          uiSchema={forms[0]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
        >
          <input type="submit" style={{ display: 'none' }} />
        </Form>
        <div className="row">
          <div className="col-12 justify-content-end">
            <button
              id="EventHistory_clearSearchBtn"
              className="btn btn-default mr-3"
              onClick={clearForm}
            >
              Reset
            </button>
            <button
              id="EventHistory_searchEventsBtn"
              className="btn btn-primary pe-button-focus"
              onClick={searchPEApplications}
            >
              Search
            </button>
          </div>
        </div>
        <Form
          schema={forms[1]['schema']}
          uiSchema={forms[1]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
        >
          <button type="submit" style={{ display: 'none' }} />
        </Form>

        <Modal show={showUploadModal} onHide={closeUploadModal}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="col-lg-12">
              <Form
                schema={forms[2]['schema']}
                uiSchema={forms[2]['uiSchema']}
                formData={formData.overridePeData}
                formContext={formContext}
                onChange={onFormDataChangeModal}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                FieldTemplate={formComponents.FieldTemplate}
              >
                <button type="submit" style={{ display: 'none' }} />
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn" onClick={closeUploadModal}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        {confirmationModal}

        <div style={{ display: showOption }} className="row">
          {/* <Pagination
            activePage={activeCount}
            itemsCountPerPage={pageCountDisplay}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            onChange={onPaginationClick}
          /> */}
          <Pagination
            prev={'‹'}
            next={'›'}
            first={'«'}
            last={'»'}
            ellipsis={false}
            boundaryLinks={true}
            items={pageCountDisplay}
            maxButtons={3}
            activePage={activeCount}
            onSelect={onPaginationClick}
          />
        </div>

        <div className="row">
          {formData.Application.myApplicationSub.clients.length === 0 && (
            <div className="col-12">
              <ul className="list-group">No data available</ul>
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="container-fluid reports-container">
        <div>
          <ErrorModal
            errorMessage={errorMessage}
            onDeleteClick={onDeleteClick}
          />
          <ScreenPreLoader loaded={loadScreen} />
        </div>
      </div>
    )
  }
}
