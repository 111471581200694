import * as _ from 'lodash' // extra functionality
import { combineReducers } from 'redux'

export default combineReducers({
  mybenifitsFormData
})

function mybenifitsFormData(state = [], action) {
  switch (action.type) {
    case 'MY_BENIFITS_FORMDATA':
      return action.formData
    case 'LOGOUT_SUCCESS':
      return []
    default:
      return state
  }
}
