import * as React from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { config } from '../../../config'
import { Redirect } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import { isEqual } from 'lodash'
import * as bnfCatalogJSON from './benefitCatalog.json'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
declare var process

const baseUrl = process.env.PUBLIC_URL

const gatewayBaseUrl = config['gatewayBaseUrl']

interface BenefitsCatalogContainerProps {
  presentation: any
  myFavorites: Array<string>
  addMyFavorite: (name) => void
  removeMyFavorite: (name) => void
  roleId: any
  roleName: string
  handleSelect: (tabKey) => void
  tabKey: string
  locale: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  logoutUser: () => void
}
interface BenefitsCatalogContainerState {
  tabKey: string
  benefitCategoryJSON: {
    benfCatName: string
    benfCatLongDesc: string
  }[]
  redirect: string
  showExitModal: boolean
  showErrorModal: boolean
}

export class BenefitsCatalogContainer extends React.Component<
  BenefitsCatalogContainerProps,
  BenefitsCatalogContainerState
> {
  mainContent: React.RefObject<{}>
  constructor(props: BenefitsCatalogContainerProps) {
    super(props)
    this.mainContent = React.createRef()
    this.state = {
      benefitCategoryJSON: [],
      tabKey:
        window.history.state &&
        window.history.state.state &&
        window.history.state.state.tabKey
          ? window.history.state.state.tabKey
          : 'health-wellness',
      redirect: null,
      showExitModal: false,
      showErrorModal: false
    }
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect = (tabKey: string): void => {
    this.setState({ tabKey })
  }

  componentDidMount() {
    document.title = I18n.t('BenefitsCatalog.programsServices')
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount
          ? this.props.auth.userAccount.uuid || ''
          : '',
        tenantCode: config.tCode,
        Authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalName: config.portalName
      }
    }

    let sideNavLink = this._createSkipLink(
      '#side-nav',
      'skip-to-side-nav',
      I18n.t('General.skipToSideNav')
    )
    const navContent = document.getElementById('nav-content')
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      navContent.insertBefore(sideNavLink, mainNavContent)
    }
  }

  _showExit = () => {
    this.setState({ showExitModal: true })
  }

  _hideError = () => {
    this.setState({ showErrorModal: false })
  }

  _confirmExit = () => {
    this.setState({
      showExitModal: false,
      redirect: baseUrl + '/myaccount/dashboard'
    })
  }

  _cancelExit = () => {
    this.setState({ showExitModal: false })
  }

  componentDidUpdate(prevProps, prevState) {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    if (!isEqual(prevProps.locale, this.props.locale)) {
      document.title = I18n.t('BenefitsCatalog.title')
      if (navContent) {
        if (sideNavLink) {
          sideNavLink.textContent = I18n.t('General.skipToSideNav')
        }
      }
    }
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      if (!sideNavLink) {
        let sideNavLink = this._createSkipLink(
          '#side-nav',
          'skip-to-side-nav',
          I18n.t('General.skipToSideNav')
        )
        navContent.insertBefore(sideNavLink, mainNavContent)
      }
    }
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  componentWillUnmount() {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    if (navContent) {
      if (sideNavLink) {
        navContent.removeChild(sideNavLink)
      }
    }
  }

  _createSkipLink = (href, id, text = '') => {
    let skipLink = document.createElement('a')
    skipLink.setAttribute('class', 'skip')
    skipLink.setAttribute('href', href)
    skipLink.setAttribute('id', id)
    if (text) {
      let skipLinkText = document.createTextNode(text)
      skipLink.appendChild(skipLinkText)
    }
    return skipLink
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: this.state.redirect }} />
    }

    const {
      presentation,
      myFavorites,
      addMyFavorite,
      removeMyFavorite,
      roleId,
      roleName,
      locale,
      errorMessage
    } = this.props
    const { tabKey, benefitCategoryJSON } = this.state
    const presentationProps = {
      myFavorites,
      addMyFavorite,
      removeMyFavorite,
      handleSelect: this.handleSelect,
      benefitCategoryJSON,
      tabKey,
      locale,
      showExit: this._showExit,
      confirmExit: this._confirmExit,
      cancelExit: this._cancelExit,
      hideErrorModal: this._hideError,
      roleId,
      roleName,
      mainContent: this.mainContent,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      ...this.state
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  return {
    myFavorites: state.myFavorites,
    auth: state.auth,
    locale: state.i18n.locale,
    roleId: _.get(state.userAccess, 'selectedUserRole.role.roleId'),
    roleName: _.get(state.userAccess, 'selectedUserRole.role.roleName'),
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addMyFavorite: name => {
      dispatch(actions.addMyFavorite(name))
    },
    removeMyFavorite: name => {
      dispatch(actions.removeMyFavorite(name))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitsCatalogContainer)
