import * as React from 'react'
import * as _ from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { Popover, OverlayTrigger } from 'react-bootstrap'

interface CPSPresentationProps {
  formData: any
  formContext: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  onFormDataChange: (formData: any) => void
  formComponents
  dynamicHeading: any
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function CPSPresentation(props: CPSPresentationProps) {
  const {
    formData = {},
    formContext,
    forms = [],
    onFormDataChange,
    formComponents,
    dynamicHeading,
    onDeleteClick,
    errorMessage
  } = props

  let helpPopover = (
    <Popover id="helpPopover">
      <a href="/help" target="_blank">
        Help Center
      </a>
    </Popover>
  )

  let helpLink = (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={helpPopover}
    >
      <button className="btn btn-tiny btn-white">
        <span className="fa fa-life-ring btn-icon-sm" />
        Help
      </button>
    </OverlayTrigger>
  )
  if (!_.isEmpty(forms)) {
    return (
      <div>
        <div>
          <div className="header-view">
            <h1 className="heading" id="cp-title">
              {' '}
              {dynamicHeading}
            </h1>
          </div>
          <ErrorModal
            errorMessage={errorMessage}
            onDeleteClick={onDeleteClick}
          />
          <div className="cpsHeaderBar">
            <div className="float-right margin-left-auto">
              {helpLink}
              <button
                className="printStyle print btn btn-tiny btn-white"
                onClick={function() {
                  window.print()
                }}
              >
                <span className="fa fa-print btn-icon-sm" />
                Print
              </button>
            </div>
          </div>
          {forms && forms.length > 0 ? (
            <Form
              schema={forms[0]['schema']}
              uiSchema={forms[0]['uiSchema']}
              formData={formData}
              formContext={formContext}
              onChange={onFormDataChange}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
            >
              <input type="submit" style={{ display: 'none' }} />
              <a
                className="btn btn-info float-right back-contrast"
                href="/Home"
              >
                Back to Home
              </a>
            </Form>
          ) : (
            <h3>
              <input type="submit" style={{ display: 'none' }} />
            </h3>
          )}
        </div>
      </div>
    )
  }
  return <ScreenPreLoader loaded={false} />
}
