import * as React from 'react'
import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const HomeContainer = MultitenantLoadable(
  () => import('./HomeContainer'),
  () => import('../../' + tenantDirectoryName + '/Home/HomeContainer')
)

const HomePresentation = MultitenantLoadable(
  () => import('./HomePresentation'),
  () => import('../../' + tenantDirectoryName + '/Home/HomePresentation')
)

export default function Home(props) {
  return (
    <ErrorBoundary name="Home">
      <HomeContainer
        {...props}
        presentation={presentationProps => (
          <HomePresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseHomeContainer = MultitenantLoadable(
  () => import('./HomeContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseHomePresentation = MultitenantLoadable(
  () => import('./HomePresentation'),
  () => Promise.resolve().then(() => null)
)

export function BaseHome(props) {
  return (
    <BaseErrorBoundary name="Home">
      <BaseHomeContainer
        {...props}
        presentation={presentationProps => (
          <BaseHomePresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
