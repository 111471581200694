import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'

const CPSContainer = MultitenantLoadable(
  () => import('./CPSContainer'),
  () => import(config)
)

const CPSPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./CPSPresentation'),
    () => import(config)
  ),
  () => import('./formComponents'),
  () => import(config)
)

export default function CommunityPartnerSearch(props) {
  return (
    <CPSContainer
      {...props}
      presentation={presentationProps => (
        <CPSPresentation {...presentationProps} />
      )}
    />
  )
}
