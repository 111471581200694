import * as React from 'react'
import * as _ from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src'
const moment = require('moment-timezone')
import { Modal, Button } from 'react-bootstrap'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'

export default function MyMessagesPresentation(props: any) {
  const {
    callingApi,
    formContext,
    forms,
    onFormDataChange,
    formComponents,
    formData,
    isLoading
  } = props

  if (isLoading) {
    return <ScreenPreLoader />
  }
  return (
    <div id="content_my_messages" className="form-section">
      <h1 className="title-header">Child Support Payments</h1>

      {forms && (
        <Form
          schema={forms.app.forms[0]['schema']}
          uiSchema={forms.app.forms[0]['uiSchema']}
          formData={formData}
          onChange={onFormDataChange}
          formContext={formContext}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
        >
          <button type="submit" style={{ display: 'none' }} />
        </Form>
      )}
    </div>
  )
}