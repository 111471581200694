import * as React from 'react'
import { isNil, chunk, get } from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { I18n } from 'react-redux-i18n'

const _ = { isNil, chunk, get }

interface Entitlement {
  entitlementId: number
  entitlementName: string
  entitlementDescription: string
}

interface UserRole {
  userRoleId: number
  uuid: string
  role: {
    roleId: number
    roleName: string
    multilingualDisplayName: {
      en: string
      es: string
    }
    multilingualDescription: {
      en: string
      es: string
    }
    tenantId: number
    status: string
    startDate: string
    endDate: string
  }
  organization: {
    orgId: number
    orgNm: string
    multilingualDisplayName: {
      en: string
      es: string
    }
    tenantId: number
    startDate: string
    endDate: string
  }
  entitlements: Array<Entitlement>
}

interface SelectRolePresentationProps {
  userRoles: Array<UserRole>
  loaded: boolean
  shouldContinue: () => boolean
  handleSubmit: () => void
  roleTileClass: (userRole: UserRole) => string
  roleIconClass: (userRole: UserRole) => string
  isDefaultUserRole: boolean
  handleDefaultRoleCheckbox: (event: any) => void
  locale: string
  handleRoleClick: (event: any) => void
}

export default function SelectRolePresentation(
  props: SelectRolePresentationProps
) {
  const {
    userRoles,
    loaded,
    shouldContinue,
    handleSubmit,
    roleTileClass,
    roleIconClass,
    isDefaultUserRole,
    handleDefaultRoleCheckbox,
    locale,
    handleRoleClick
  } = props

  const userRoleChunks = _.isNil(userRoles) ? [] : _.chunk(userRoles, 3)

  const mayContinue = shouldContinue()
  return (
    <form onSubmit={handleSubmit}>
      <div className="select-role-container">
        <p className="select-role-signin text-center font-weight-bold">
          {I18n.t('SelectRole.signInAs')}
        </p>
        <div className="container">
          {userRoleChunks.map(userRoles => (
            <div className="row">
              {userRoles.map(userRole => {
                const roleDisplay =
                  _.get(userRole, 'role.multilingualDisplayName.' + locale) ||
                  ''
                const orgDisplay = _.get(
                  userRole,
                  'organization.multilingualDisplayName.' + locale
                )
                return (
                  <div
                    className="col-md-3 col-md-push-1 classWithPad"
                    id={userRole.userRoleId}
                    key={userRole.userRoleId}
                    onClick={handleRoleClick}
                  >
                    <div
                      className={roleTileClass(userRole)}
                      id={userRole.userRoleId}
                      style={{ display: 'table' }}
                    >
                      <div
                        className="vcenter"
                        id={userRole.userRoleId}
                        style={{ display: 'table-cell' }}
                      >
                        <i
                          className={roleIconClass(userRole)}
                          id={userRole.userRoleId}
                        />
                        <br />
                        <p
                          className="select-role-label"
                          id={userRole.userRoleId}
                        >
                          {roleDisplay}
                        </p>
                        {orgDisplay && (
                          <p
                            className="select-role-org-label"
                            id={userRole.userRoleId}
                          >
                            {orgDisplay}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ))}
          <div className="row top-buffer">
            <label className="col-md-offset-1 col-md-4">
              <input
                name="isDefaultUserRole"
                type="checkbox"
                checked={isDefaultUserRole}
                onChange={handleDefaultRoleCheckbox}
              />
              &nbsp;{I18n.t('SelectRole.useAsDefault')}
            </label>
            <div className="col-md-offset-1 col-md-4">
              <button
                id="SelectRole_continueBtn"
                type="button"
                className="btn btn-info float-right"
                disabled={!mayContinue}
                onClick={handleSubmit}
              >
                {I18n.t('General.continueButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ScreenPreLoader loaded={loaded} minHeight="500px" />
    </form>
  )
}
