import * as React from 'react'
import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../../base/Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const LoadableLayout = MultitenantLoadable(
  () => import('./Layout'),
  () => import('../../' + tenantDirectoryName + '/Layout/Layout')
)

const Header = MultitenantLoadable(
  () => import('./Header'),
  () => import('../../' + tenantDirectoryName + '/Layout/Header')
)

const Footer = MultitenantLoadable(
  () => import('./Footer'),
  () => import('../../' + tenantDirectoryName + '/Layout/Footer')
)

export default function Layout(props) {
  return (
    <LoadableLayout
      {...props}
      header={headerProps => (
        <ErrorBoundary name="Header">
          <Header {...headerProps} />
        </ErrorBoundary>
      )}
      footer={footerProps => (
        <ErrorBoundary name="Footer">
          <Footer {...footerProps} />
        </ErrorBoundary>
      )}
    />
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseLoadableLayout = MultitenantLoadable(
  () => import('./Layout'),
  () => Promise.resolve().then(() => null)
)

const BaseHeader = MultitenantLoadable(
  () => import('./Header'),
  () => Promise.resolve().then(() => null)
)

const BaseFooter = MultitenantLoadable(
  () => import('./Footer'),
  () => Promise.resolve().then(() => null)
)

export function BaseLayout(props) {
  return (
    <BaseLoadableLayout
      {...props}
      header={headerProps => (
        <BaseErrorBoundary name="Header">
          <BaseHeader {...headerProps} />
        </BaseErrorBoundary>
      )}
      footer={footerProps => (
        <BaseErrorBoundary name="Footer">
          <BaseFooter {...footerProps} />
        </BaseErrorBoundary>
      )}
    />
  )
}
