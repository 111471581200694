import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const AppIntakePEContainer = MultitenantLoadable(
  () => import('./AppIntakePEContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/AppIntakePE/AppIntakePEContainer')
)

const AppIntakePEPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./AppIntakePEPresentation'),
    () =>
      import(
        '../../' + tenantDirectoryName + '/AppIntakePE/AppIntakePEPresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/AppIntakePE/formComponents')
)

export default function AppIntakePE(props) {
  return (
    <ErrorBoundary name="AppIntakePE">
      <AppIntakePEContainer
        {...props}
        presentation={presentationProps => (
          <AppIntakePEPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseAppIntakePEContainer = MultitenantLoadable(
  () => import('./AppIntakePEContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseAppIntakePEPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./AppIntakePEPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseAppIntakePE(props) {
  return (
    <BaseErrorBoundary name="AppIntakePE">
      <BaseAppIntakePEContainer
        {...props}
        presentation={presentationProps => (
          <BaseAppIntakePEPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
