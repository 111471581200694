import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'

export function LinkWidget(props) {
  const { options } = props
  const { parameters } = options
  let linkAction = null
  switch (options.action) {
    case 'search':
      linkAction = () => props.formContext.search()
      break
    case 'clear':
      linkAction = () => props.formContext.clear()
      break
  }
  let linkLabel = props.value

  return (
    <button
      type="button"
      onClick={linkAction}
      id={props.id}
      className="btn btn-primary"
    >
      {options.label}
    </button>
  )
}

function PaymentsSent(props) {
  return (
    <>
      <div className="cs-payment-box" style={{ border: '2px solid black' }}>
        <h5>Filtered Amount Sent ${props.formContext.totalFilteredSent}</h5>
      </div>
    </>
  )
}

function PaymentsReceived(props) {
  return (
    <>
      <div className="cs-payment-box" style={{ border: '2px solid black' }}>
        <h5>
          Filtered Amount Received ${props.formContext.totalFilteredReceived} 
        </h5>
      </div>
    </>
  )
}

function PaymentsAllSent(props) {
  return (
    <>
      <div className="cs-payment-box" style={{ border: '2px solid black' }}>
        <h5>Total Amount Sent ${props.formContext.totalSent} </h5>
      </div>
    </>
  )
}

function PaymentsAllReceived(props) {
  return (
    <>
      <div className="cs-payment-box" style={{ border: '2px solid black' }}>
        <h5>Total Amount Received ${props.formContext.totalReceived}  </h5>
      </div>
    </>
  )
}

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  dataTable: Fields.DataTableField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  span: Widgets.SpanWidget,
  iconWidget: Widgets.ConditionalIconWidget,
  validatedText: Widgets.ValidatedTextWidget,
  'alt-date': Widgets.DatePickerWidget,
  PaymentsSent,
  PaymentsReceived,
  LinkWidget,
  PaymentsAllSent,
  PaymentsAllReceived
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate