import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import '!style-loader!css-loader!../src/customStyles.css'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { getServerTime } from '../src/components/utils'
import { Panel } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Panel'
import { Accordion } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

const gatewayWvUrl = config['gatewayWvUrl']
const formsBaseUrl = config['formsBaseUrl']

const loadEndpoint = gatewayWvUrl + config['clientAppointments']
const formsEndpoint = formsBaseUrl + config['forms_MyAppointments']

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  dataTable: Fields.DataTableField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  span: Widgets.SpanWidget,
  routeLink: Widgets.LinkWidget,
  iconWidget: Widgets.ConditionalIconWidget
}

interface iEvent {
  title: string
  programName: string
  worker: string
  address: string
  interpreterNeeded: string
  startDate: string
  endDate: string
  eventType: string
}

interface iMyCalendarState {
  selectedEvent: iEvent
  showEventDetailsPopup: boolean
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  application: any
  formContext: any
  showAppointment: boolean
}

interface iCalendar {
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  hasCCA
  locale: string
  showErrorMessage: (message: any) => void
}

const initialState = {
  showEventDetailsPopup: false,
  selectedEvent: {}
}
type State = Readonly<typeof initialState>

class MyCalendar extends React.Component<iCalendar, iMyCalendarState> {
  serverTime = null
  constructor(props: iCalendar) {
    super(props)
    this.handleEvnetSelection = this.handleEvnetSelection.bind(this)
    this.eventStyleGetter = this.eventStyleGetter.bind(this)
    this.closeEvnetPopuup = this.closeEvnetPopuup.bind(this)
    this.state = {
      showEventDetailsPopup: false,
      application: {},
      forms: null,
      formContext: {},
      formData: null,
      selectedEvent: {
        title: '',
        programName: '',
        worker: '',
        address: '',
        interpreterNeeded: '',
        startDate: '',
        endDate: '',
        eventType: ''
      },
      showAppointment: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      const request = {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          locale: (this.props.locale + '_US').toUpperCase()
        }
      }
      helper
        .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
        .then(formJson => {
          let application, forms, formContext
          application = formJson['app']
          forms = application['forms']

          this.setState(prevState => {
            return {
              application,
              forms,
              formContext: {
                ...prevState.formContext,
                refs: application['metaData']['refs'],
                forms: forms
              }
            }
          })
        })
        .catch(error => {
          console.error('MyAppointments form fetch failed due to ex', error)
        })
    }
  }

  componentDidMount() {
    this.serverTime = _moment(config, getServerTime())
    document.title = 'My Appointments - My Account'
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(formJson => {
        let application, forms, formContext
        application = formJson['app']
        forms = application['forms']

        this.setState(prevState => {
          return {
            application,
            forms,
            formContext: {
              ...prevState.formContext,
              refs: application['metaData']['refs'],
              forms: forms
            }
          }
        })
      })
      .catch(error => {
        console.error('MyAppointments form fetch failed due to ex', error)
      })

    serviceHelper
      .fetchJson(
        loadEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            programType: this.props.hasCCA ? 'CCA' : 'FA'
          }
        },
        this.props.showErrorMessage,
        0,
        60000
      )
      .then(json => {
        const formData = _.map(json, item => {
          return {
            title: item.clientName,
            startDate: new Date(
              item.scheduledBeginDt + ' ' + item.scheduledBeginTm
            ),
            endDate: new Date(item.scheduledEndDt + ' ' + item.scheduledEndTm),
            programName: '',
            scheduledBeginDt: item.scheduledBeginDt,
            scheduledBeginTm: item.scheduledBeginTm,
            scheduledEndDt: item.scheduledEndDt,
            scheduledEndTm: item.scheduledEndTm,
            worker: '',
            address: item.locationLine2Address,
            interpreterNeeded: '',
            eventType: item.eventType
          }
        })

        const formData1 = { Application: { myAppointments: json || [] } }
        this.setState({
          formData: formData1
        })
      })
  }

  handleEvnetSelection(event, e) {
    this.setState({
      showEventDetailsPopup: true,
      selectedEvent: event
    })
  }

  eventStyleGetter(event, e) {
    var today = this.serverTime
    if (event.endDate < today) {
      var css = {
        borderRadius: '5px',
        opacity: 0.8,
        backgroundColor: '#DDD',
        border: '0px',
        display: 'block'
      }
    }
    return {
      style: css
    }
  }

  closeEvnetPopuup() {
    this.setState({
      showEventDetailsPopup: false
    })
  }
  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _onSelect = () => {
    this.setState({ showAppointment: !this.state.showAppointment })
  }

  render() {
    const { formContext, formData, forms = [] } = this.state
    const { errorMessage } = this.props
    let formDataArray, filteredFormData
    let newFormData = {
      Application: {
        myAppointments: []
      }
    }

    if (formData !== null) {
      formDataArray = formData.Application.myAppointments
      if (formData && _.isArray(formDataArray)) {
        formDataArray = formDataArray.filter(
          ele => this.serverTime.diff(ele.scheduledBeginDt, 'days') < 367
        )
        filteredFormData = formDataArray
        newFormData = {
          Application: {
            myAppointments: filteredFormData
          }
        }
      }
    }

    return (
      <div>
        <ErrorModal
          errorMessage={errorMessage}
          onDeleteClick={this._onDeleteClick}
        />
        <Accordion>
          <Panel
            header={I18n.t('General.title5')}
            eventKey="2"
            onSelect={this._onSelect}
            expanded={this.state.showAppointment}
          >
            {this.state.forms &&
            this.state.forms.length > 0 &&
            filteredFormData ? (
              <div>
                <Form
                  schema={forms[0]['schema']}
                  uiSchema={forms[0]['uiSchema']}
                  formData={newFormData}
                  formContext={formContext}
                  fields={fields}
                  widgets={widgets}
                  ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
                  FieldTemplate={Templates.CustomFieldTemplate}
                >
                  <input type="submit" style={{ display: 'none' }} />
                </Form>
              </div>
            ) : (
              ''
            )}
          </Panel>
        </Accordion>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  return {
    auth: state.auth,
    userRoleId,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || [],
    locale: state.i18n.locale,
    hasCCA: _.get(state, 'hasCCA.hasCCA')
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCalendar)
