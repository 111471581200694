import * as React from 'react'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { validatePE_CPForm } from './validation'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import { config } from '../../../config' // endpoints
import * as actions from '../../../actions'
import {
  isEqual,
  includes,
  cloneDeep,
  unset,
  set,
  get,
  isEmpty,
  merge,
  omit,
  find
} from 'lodash' // extra functionality
import * as queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { imageMap } from '../../../images'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'
import { refs as frameworkRefs } from '@optum-wvie/dynamic-ui-framework/src/refs'
import { refs as cpRefs } from '~/refs'
import { I18n } from 'react-redux-i18n'

const _ = {
  isEqual,
  includes,
  cloneDeep,
  unset,
  set,
  get,
  isEmpty,
  merge,
  omit,
  find
}

declare const process
const baseUrl = process.env.PUBLIC_URL

const formsEndpoint = config['form_AppIntakePEContainer']
const submitEndpoint = config['submitCPApplication']

//Nav tab classes
const pristineIcon = ''

const editingIcon = 'glyphicon myglyphicon-pencil'
const editingBadge = 'badge bg-white-alt'

const validatedIcon = 'glyphicon myglyphicon-ok'
const validatedBadge = 'badge bg-green-alt'

const errorIcon = 'glyphicon myglyphicon-circle_exclamation_mark'
const errorBadge = 'badge bg-yellow-alt'

//This is for step-specific logic to not assume the step number.  Keep this updated.
const STEPS = {
  PERSONAL: 1,
  CITIZENSHIP_AND_RESIDENCY: 2,
  INCOME_AND_RESOURCES: 3,
  RETROACTIVE_REQUEST: 4,
  ADDITIONAL_QUESTIONS: 5,
  REVIEW_AND_SIGN: 6
}

let peApplication = {
  clientMedicalIdNm: undefined,
  applicationStartDate: undefined,
  agreement: undefined,
  clients: [
    {
      prefixCode: undefined,
      clientFstNm: undefined,
      clientMidlNm: undefined,
      clientLstNm: undefined,
      prtcpSsn: undefined,
      clientBthDt: undefined,
      suffixCode: undefined,
      genderCode: undefined,
      clientRemInStIntnFlg: undefined,
      clientHomelessFlg: undefined,
      optBenefitPE: undefined,
      address: {
        adrLn1Txt: undefined,
        adrLn2Txt: undefined,
        ctyNm: undefined,
        st: undefined,
        zip: undefined,
        county: undefined
      },
      addrSameAsPrimApplicant: undefined,
      secondaryAddress: {
        adrLn1Txt: undefined,
        adrLn2Txt: undefined,
        ctyNm: undefined,
        st: undefined,
        zip: undefined,
        county: undefined
      },
      emailAddress1: undefined,
      phones: [
        {
          telNumber1: undefined,
          phoneType: undefined
        }
      ],
      prefCntcMethod1Cd: undefined,
      prefCntcMethod2Id: undefined,
      clientPreferedWrittenLangua: undefined,
      clientPreferedSpokenLanguag: undefined,
      interpreterNeeded: undefined,
      usCtznOrNatInd: undefined,
      immgStatusCd: undefined,
      immigrationStatusGrantDate: undefined,
      flfl40QualWrkQtr: undefined,
      numberOfIndividualInTaxHousehold: undefined,
      houseHoldIncome: undefined,
      exptPeriod: undefined,
      isCovered: undefined,
      isApproved: undefined,
      isPregnantWhenApproved: undefined,
      isPregnancyEnded: undefined,
      pregEndDt: undefined,
      pregnant: undefined,
      fostercare: undefined,
      breastAndCervicalCancerPatients: undefined,
      caretakerclient: undefined
    }
  ]
}

interface PEApplicationAction {
  applId: string
  status: string
  applicationData: Object
}

interface AppIntakePEContainerProps {
  presentation: any
  location: {
    search: string
  }
  uuid: string
  activeApplicationId: string
  userRoleId: number
  applicationData: any
  step: number
  status: string
  addPEApplication: (...PEApplicationAction) => void
  removePEApplication: (applId: string) => void
  updatePEApplication: (...PEApplicationAction) => void
  setActivePEApplicationId: (applId: string) => void
  assignPEApplicationId: (applId: string) => void
  updatePEApplicationStep: (applId: string, step: number) => void
  isUnitTest: boolean
  selectedEntitlements: Array<string>
  locale: string
  authHeader: string
}

interface AppIntakePEContainerState {
  application: Object
  tabs: {
    status: string
    leftIcon: string
    rightIcon: string
    rightSpan: string
    isVisible: boolean
  }[]
  forms: {
    schema: Object
    uiSchema: Object
    metaData: Object
  }[]
  formContext: any
  formData: any
  liveValidate: boolean
  viewId: string
  redirect: string
  showNext: boolean
  formDataSubmitted: boolean
  callingApi: boolean
  showExitModal: boolean
  errors: Array<any>
}

class AppIntakePEContainer extends React.Component<
  AppIntakePEContainerProps,
  AppIntakePEContainerState
> {
  constructor(props: AppIntakePEContainerProps) {
    super(props)

    this.state = {
      application: {},
      tabs: undefined,
      forms: undefined,
      formData: undefined,
      formContext: undefined,
      liveValidate: false,
      viewId: undefined,
      redirect: undefined,
      showNext: false,
      formDataSubmitted: false,
      callingApi: false,
      showExitModal: false,
      errors: []
    }
  }

  componentDidMount() {
    let applId = this.props.activeApplicationId
    if (this.props.location && this.props.location.search) {
      try {
        const query = queryString.parse(this.props.location.search)
        if (applId !== query.applId) {
          applId = query.applId
          this.props.setActivePEApplicationId(applId)
        }
      } catch (err) {
        console.error(
          'AppIntakePE error on handling applId from URL query',
          err
        )
      }
    }

    const endpoint = formsEndpoint.replace('{version}', '1.0')
    helper
      .fetchJson(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        this._initializeForm(this.props.applicationData, formJson, applId)
      })
      .catch(error => {
        console.error('AppIntakePE form fetch failed due to ex', error)
        const code = CODES.APP_INTAKE_PE_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  componentDidUpdate(prevProps, prevState) {
    let { viewId, tabs } = this.state

    if (viewId != null) {
      //Have to do this since componentDidUpdate runs before real DOM is updated.
      try {
        setTimeout(function() {
          window.requestAnimationFrame(function() {
            const element = document.getElementById(viewId)
            const offset = helper.getOffsetTop(element)
            element.focus()
            window.scroll(0, offset - 100)
          })
        }, 0)
      } catch (e) {
        console.error('AppIntakePE viewId setTimeout failed with ex', e)
      }
      //TODO: expand accordions if they contain the element.

      this.setState({ viewId: null })
    }

    this._updateTabs()

    if (!_.isEqual(prevProps.userRoleId, this.props.userRoleId)) {
      //The user switched their role! Redirect back to home.
      //TODO: global config for target landing page by role/entitlement?
      if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
        )
      ) {
        this._setRedirect('/myaccount/dashboard')
      } else if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.COMMUNITY_PARTNERSHIP
        )
      ) {
        this._setRedirect('/myaccount/dashboard')
      } else {
        this._setRedirect('/home')
      }
    }

    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      const endpoint = formsEndpoint.replace('{version}', '1.0')
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(formJson => {
          this._initializeForm(
            this.props.applicationData,
            formJson,
            this.props.activeApplicationId
          )
        })
        .catch(error => {
          console.error('AppIntakePE form fetch failed due to ex', error)
          const code = CODES.APP_INTAKE_PE_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }

    if (
      this.state.formContext &&
      !_.isEqual(this.props.applicationData, this.state.formContext.formData)
    ) {
      const newFormData = helper.deepClone(this.props.applicationData)

      this.setState({
        formContext: {
          ...this.state.formContext,
          formData: newFormData,
          reviewFormData: newFormData
        }
      })
    }
  }

  _changeView = (val: number) => {
    this._changeStep(val + 1)
  }

  _loadDraft = (endpoint, formsJson, applId) => {
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        uuid: this.props.uuid,
        userRoleId: this.props.userRoleId.toString(),
        tenantCode: config.tenant.code,
        portalName: config.portalName,
        Authorization: this.props.authHeader
      }
    }

    const fetchEndpoint = endpoint.replace('{applId}', applId)

    this.setState({ callingApi: true })
    helper
      .fetchJson(fetchEndpoint, request)
      .then(formData => {
        this.setState({ callingApi: false })
        this._initializeForm(formData, formsJson, applId)
      })
      .catch(error => {
        this.setState({ callingApi: false })
        console.error('AppIntakePE _getFormData failed with error:', error)
        const code = CODES.APP_INTAKE_PE_FETCH_DRAFT
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _saveFormDataDraft = () => {
    let applicationData = this.props.applicationData

    if (
      typeof applicationData['Application']['agreement'] !== 'undefined' ||
      typeof applicationData['Application']['signatureReceived'] !== 'undefined'
    ) {
      applicationData = _.cloneDeep(this.props.applicationData)
      _.unset(applicationData, 'Application.agreement')
      _.unset(applicationData, 'Application.signatureReceived')
    }
  }

  _initializeForm = (formData, formsJson, applId) => {
    let tabs = [] // navigation tabs
    let forms, application, formContext
    application = formsJson['app']
    forms = application['forms']

    for (let i = 0; i < forms.length; i++) {
      //Initialize tabs using uiSchema, with status set as pristine
      if (typeof forms[i].uiSchema['externalOptions'] !== 'undefined') {
        let options = forms[i].uiSchema['externalOptions']

        if (typeof options.tab !== 'undefined') {
          tabs.push({
            title: options.tab.tabName,
            leftIcon: options.tab.iconClassName,
            rightIcon: pristineIcon,
            rightSpan: null,
            status: 'pristine',
            visibleIf: options.tab.visibleIf,
            isVisible: true
          })
        }

        if (i === STEPS.RETROACTIVE_REQUEST) {
          let columnArray = []
          columnArray.push('Source')
          columnArray.push('Income Type')
          columnArray.push(
            helper
              ._moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          columnArray.push(
            helper
              ._moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          columnArray.push(
            helper
              ._moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.incomeRetroDetails.earnedIncomeRetro.ui:options.columns',
            columnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.incomeRetroDetails.unEarnedIncomeRetro.ui:options.columns',
            columnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.incomeRetroDetails.selfEmpIncomeRetro.ui:options.columns',
            columnArray
          )
          let expenseColumnArray = []
          expenseColumnArray.push('Start Date')
          expenseColumnArray.push('Share with someone')
          expenseColumnArray.push(
            helper
              ._moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          expenseColumnArray.push(
            helper
              ._moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          expenseColumnArray.push(
            helper
              ._moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.expenseRetroDetails.expenseRetro.ui:options.columns',
            expenseColumnArray
          )
          let cashColumnArray = []
          cashColumnArray.push(
            helper
              ._moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          cashColumnArray.push(
            helper
              ._moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          cashColumnArray.push(
            helper
              ._moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.cashRetro.ui:options.columns',
            cashColumnArray
          )
          let bankAccountColumnArray = []
          bankAccountColumnArray.push('Source')
          bankAccountColumnArray.push('Account Number')
          bankAccountColumnArray.push(
            helper
              ._moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          bankAccountColumnArray.push(
            helper
              ._moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          bankAccountColumnArray.push(
            helper
              ._moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.bankAccountRetro.ui:options.columns',
            bankAccountColumnArray
          )
          let vehicleColumnArray = []
          vehicleColumnArray.push('Make')
          vehicleColumnArray.push('Model')
          vehicleColumnArray.push(
            helper
              ._moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          vehicleColumnArray.push(
            helper
              ._moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          vehicleColumnArray.push(
            helper
              ._moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.vehicleRetro.ui:options.columns',
            vehicleColumnArray
          )
          let fundsColumnArray = []
          fundsColumnArray.push('Type')
          fundsColumnArray.push('Account Number')
          fundsColumnArray.push(
            helper
              ._moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          fundsColumnArray.push(
            helper
              ._moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          fundsColumnArray.push(
            helper
              ._moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.trustFundsRetro.ui:options.columns',
            fundsColumnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.investmentRetro.ui:options.columns',
            fundsColumnArray
          )
          let realEstateColumnArray = []
          realEstateColumnArray.push('Type')
          realEstateColumnArray.push(
            helper
              ._moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          realEstateColumnArray.push(
            helper
              ._moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          realEstateColumnArray.push(
            helper
              ._moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.realEstateRetro.ui:options.columns',
            realEstateColumnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.otherRetro.ui:options.columns',
            realEstateColumnArray
          )
        }
      }
    }

    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      refs: {
        ...frameworkRefs,
        ...cpRefs,
        ...application['metaData']['refs']
      },
      forms: forms,
      reviewForms: forms,
      config
    }

    //Create a default empty application and merge it with our application data
    let newFormData
    if (!_.isEmpty(formData) && this.props.status != 'SUBMITTED') {
      newFormData = _.merge(
        helper.createObjectFromMasterSchema(null, formContext),
        formData
      )
      this.props.updatePEApplicationStep(this.props.activeApplicationId, 1)
    } else {
      newFormData = helper.createObjectFromMasterSchema(null, formContext)
    }

    //Set the application ID
    if (applId !== '0') {
      _.set(newFormData, 'Application.applId', applId)
    }

    //Set the tenant ID
    if (config.tenant.code) {
      _.set(newFormData, 'Application.tenantId', config.tenant.code)
    }

    //Clear the review signature info.
    _.unset(newFormData, 'Application.agreement')
    _.unset(newFormData, 'Application.signatureReceived')

    //Default primary to relationship to "Self"
    //TODO: update once we are aligned to MCR
    const relationshipSchema = _.get(
      application.forms[0].schema,
      'definitions.client.properties.relationship'
    )
    if (relationshipSchema) {
      _.set(
        newFormData,
        'Application.clients[0].relationship',
        relationshipSchema.enum[relationshipSchema.enumNames.indexOf('Self')]
      )
    }

    if (!_.isEmpty(this.props.applicationData)) {
      if (!_.isEqual(newFormData, formData)) {
        this.props.updatePEApplication(applId, 'DRAFT', newFormData)
      }
    } else {
      this.props.addPEApplication(applId, 'DRAFT', newFormData)
    }
    let date = helper._moment(config).format('MM/DD/YYYY')
    formContext = {
      ...formContext,
      formData: newFormData,
      reviewFormData: newFormData,
      setShowNext: this._setShowNext,
      imageMap: imageMap,
      component: this,
      currentDate: date,
      isUnitTest: this.props.isUnitTest
    }

    this.setState({ application, forms, tabs, formContext })

    this._updateTabs()
  }

  _onFormDataChange = ({ formData }) => {
    const { tabs, forms } = this.state
    const { step } = this.props

    //Clear the review signature info if we changed anything other than that page.
    if (step != forms.length) {
      _.unset(formData, 'Application.agreement')
      _.unset(formData, 'Application.signatureReceived')
    }

    this.props.updatePEApplication(
      this.props.activeApplicationId,
      'DRAFT',
      formData
    )

    if (tabs[step - 1].status !== 'editing') {
      this.setState({
        tabs: [
          ...tabs.slice(0, step - 1),
          {
            ...tabs[step - 1],
            status: 'editing',
            rightIcon: editingIcon,
            rightSpan: editingBadge
          },
          ...tabs.slice(step)
        ]
      })
    }
  }

  _onValidate = (formData: object, errors: object) => {
    const { forms, tabs, liveValidate } = this.state
    const { step } = this.props

    //Call CP-specific validation library.
    validatePE_CPForm(formData, errors, forms.length === step ? null : step)

    //Determine which tabs contain errors and update their statuses.
    let foundErrors = helper.findErrors(errors)

    if (foundErrors && foundErrors.length > 0) {
      let newTabs = tabs
      for (let i = 0; i < foundErrors.length; ++i) {
        try {
          let errorObject = JSON.parse(foundErrors[i])
          let errorStep = errorObject.step - 1
          if (newTabs[errorStep].status !== 'error') {
            newTabs[errorStep].status = 'error'
            newTabs[errorStep].rightIcon = errorIcon
            newTabs[errorStep].rightSpan = errorBadge
          }
        } catch (err) {
          console.error('Error on parsing errors', err)
        }
      }
    }

    return errors
  }

  _getApplicationData = () => {
    var applicationMap = this.props.applicationData.Application
    let myPEApplicationData = _.cloneDeep(peApplication)
    let applicationData = { Application: null, applId: null }
    myPEApplicationData.clients = [...applicationMap.clients]
    _.set(myPEApplicationData, 'clients[0].retroDetails', undefined)
    myPEApplicationData.clients = [
      { ...myPEApplicationData.clients[0], ...applicationMap.addressDetails[0] }
    ]
    let {
      adrLn1Txt,
      adrLn2Txt,
      ctyNm,
      st,
      zip,
      county
    } = applicationMap.addressDetails[0]
    _.set(myPEApplicationData, 'clients[0].address', {
      adrLn1Txt,
      adrLn2Txt,
      ctyNm,
      st,
      zip,
      county
    })
    _.set(
      myPEApplicationData,
      'clients[0].secondaryAddress',
      applicationMap.secondaryAddressDetails[0]
    )
    _.set(
      myPEApplicationData,
      'clients[0].emailAddress1',
      applicationMap.primaryEmail[0].emailAddress1
    )
    _.set(myPEApplicationData, 'clients[0].optBenefitPE', true)
    _.set(
      myPEApplicationData,
      'clients[0].phones',
      applicationMap.contactDetails[0].phones || []
    )
    myPEApplicationData.clients = [
      {
        ...myPEApplicationData.clients[0],
        ...applicationMap.preferedContactDetails[0]
      }
    ]
    myPEApplicationData.clients = [
      {
        ...myPEApplicationData.clients[0],
        ...applicationMap.preferedLanguageDetails[0]
      }
    ]
    _.set(
      myPEApplicationData,
      'clients[0].numberOfIndividualInTaxHousehold',
      applicationMap.clients[0].exptMagiIncm
    )
    _.set(
      myPEApplicationData,
      'clients[0].houseHoldIncome',
      applicationMap.clients[0].exptIncmThsYr
    )

    _.set(myPEApplicationData, 'clients[0].noIndivInTax', undefined)
    _.set(myPEApplicationData, 'clients[0].houseHoldEstIncome', undefined)
    myPEApplicationData.clients = [
      {
        ...myPEApplicationData.clients[0],
        ...applicationMap.benefitProgramInformations[0]
      }
    ]
    _.set(
      myPEApplicationData,
      'clients[0].pregnant',
      applicationMap.benefitProgramInformations[0].isPregnantNow
    )
    _.set(myPEApplicationData, 'clients[0].isPregnantNow', undefined)
    myPEApplicationData.clients = [
      { ...myPEApplicationData.clients[0], ...applicationMap.fosterclients[0] }
    ]
    myPEApplicationData.clients = [
      { ...myPEApplicationData.clients[0], ...applicationMap.cancerclients[0] }
    ]
    _.set(
      myPEApplicationData,
      'clients[0].caretakerclient',
      applicationMap.caretakerclients[0].parentorcaretaker
    )
    _.set(
      myPEApplicationData,
      'clientMedicalIdNm',
      applicationMap.clients[0].clientMedicalIdNm
    )
    _.set(
      myPEApplicationData,
      'applicationStartDate',
      applicationMap.clients[0].presumEligStartDate
    )
    _.set(myPEApplicationData, 'agreement', applicationMap.clients[0].agreement)

    _.set(myPEApplicationData, 'clients[0].clientMedicalIdNm', undefined)
    _.set(myPEApplicationData, 'clients[0].presumEligStartDate', undefined)
    _.set(myPEApplicationData, 'clients[0].agreement', undefined)
    applicationData.Application = myPEApplicationData
    applicationData.applId = null
    return applicationData
  }

  _submitFormData = endpoint => {
    let applicationData = this._getApplicationData()
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        uuid: this.props.uuid,
        userRoleId: this.props.userRoleId.toString(),
        tenantCode: config.tenant.code,
        portalName: config.portalName,
        Authorization: this.props.authHeader
      },
      body: JSON.stringify(applicationData)
    }

    this.setState({ callingApi: true })
    helper
      .fetchJson(endpoint, request)
      .then(responseJson => {
        this.setState({ callingApi: false })
        this.setState({ formDataSubmitted: true })
        this.props.updatePEApplication(
          this.props.activeApplicationId,
          'SUBMITTED',
          this.props.applicationData
        )
        this.setState({ formDataSubmitted: true, formData: {} })
        return responseJson
      })
      .catch(error => {
        this.setState({ callingApi: false })
        console.error('AppIntakePE _submitFormData failed with error:', error)
        const code = CODES.APP_INTAKE_PE_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _onSubmit = ({ formData }) => {
    // This executes after validation has passed.
    const { tabs, liveValidate, forms } = this.state
    const { step } = this.props

    //Set tab(s) to validated status
    let newTabs = helper.deepClone(this.state.tabs)

    if (step === forms.length) {
      for (let i = 0; i < forms.length; ++i) {
        newTabs[i].status = 'validated'
        newTabs[i].rightIcon = validatedIcon
        newTabs[i].rightSpan = validatedBadge
      }
      this.setState(
        {
          liveValidate: false,
          tabs: newTabs
        },
        () => this._submitFormData(submitEndpoint)
      )
    } else {
      newTabs[step - 1].status = 'validated'
      newTabs[step - 1].rightIcon = validatedIcon
      newTabs[step - 1].rightSpan = validatedBadge
      this.setState(
        {
          liveValidate: false,
          tabs: newTabs
        },
        () => this._onNext()
      )
    }
  }

  _onNext = () => {
    // increment step in forms
    const { tabs, forms } = this.state
    const { step, activeApplicationId, updatePEApplicationStep } = this.props
    let nextStep = step + 1

    while (!tabs[nextStep - 1].isVisible) {
      nextStep++
    }

    if (nextStep > forms.length) {
      return
    }

    const nextShowNext = nextStep >= 1

    updatePEApplicationStep(activeApplicationId, nextStep)
    this.setState({
      tabs: [
        ...tabs.slice(0, step - 1),
        {
          ...tabs[step - 1],
          status: 'validated',
          rightIcon: validatedIcon,
          rightSpan: validatedBadge
        },
        ...tabs.slice(step)
      ],
      showNext: nextShowNext
    })

    //Save draft copy
    this._saveFormDataDraft()

    // return to top of form
    this._toTop()
  }

  // Goes to previous step while saving formData
  _onPrevious = () => {
    const { tabs, forms } = this.state
    const { step } = this.props

    // check if step is already at the beginning
    if (step === 1) return false

    // decrease step and save formData
    let previousStep = step - 1

    while (!tabs[previousStep - 1].isVisible) {
      previousStep--
    }

    if (previousStep > forms.length) {
      return
    }

    //Set the previous tab to editing status.
    this._changeStep(previousStep)

    // return to top of form
    this._toTop()
  }

  // changing step of application, sets whether or not it should show the next button
  _changeStep = step => {
    const { activeApplicationId, updatePEApplicationStep } = this.props
    updatePEApplicationStep(activeApplicationId, step)
    this._setShowNext(step >= 1)
  }

  _setShowNext = next => {
    this.setState({ showNext: next })
  }

  _toTop = () => {
    window.location.href = '#'
  }

  _errorListTemplate = (props: any) => {
    const { errors } = props

    return (
      <div
        className="panel panel-danger errors"
        id="appintake-errors"
        tabIndex={-1}
      >
        <div className="panel-heading">
          <span className="panel-title error-title">
            {I18n.t('General.errorListTitle')}
          </span>
        </div>
        <ul className="list-group">
          {errors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.
            let errorObject = null

            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <li className="list-group-item text-danger" key={i}>
                <a
                  className="btn btn-link"
                  onClick={() => this._onErrorClick(errorObject)}
                  href="#"
                >
                  {errorObject
                    ? errorObject.displayMessage
                    : error.stack.substring(error.stack.indexOf(':') + 1)}
                  <i
                    style={{ marginLeft: '5px' }}
                    className="fa fa-eye"
                    aria-hidden="true"
                  />
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  _onErrorClick = (error: any) => {
    const { forms } = this.state
    const { step, activeApplicationId, updatePEApplicationStep } = this.props

    //Change current step to the step of the clicked error.
    if (step != error.step) {
      updatePEApplicationStep(activeApplicationId, error.step)
    }

    //Set a viewId state to be used for scrolling down after the React DOM finishes updating.
    this.setState({
      viewId: forms[error.step - 1].uiSchema['ui:rootFieldId'] + '_' + error.id
    })
  }

  _onError = (errors: object) => {
    const { liveValidate } = this.state

    //Enable live validation upon error to allow for realtime evaluation of errors in response to formData changes.
    if (!liveValidate) {
      this.setState({ liveValidate: true })
      this._toTop()
    }
  }

  _transformErrors = (errors: object) => {
    return errors
  }

  _setRedirect = url => {
    this.props.setActivePEApplicationId('0')
    this.setState({ redirect: baseUrl + url })
  }

  updateUploadedDocs(clients) {
    this.setState(
      {
        formContext: {
          ...this.state.formContext,
          formData: {
            ...this.state.formContext.formData,
            Application: {
              ...this.state.formContext.formData.Application,
              clients
            }
          }
        }
      },
      () => {
        this._onFormDataChange({
          formData: {
            Application: this.state.formContext.formData.Application,
            applId: this.props.applicationData.applId
          }
        })
      }
    )
  }

  _onDocUpload = uploadedDoc => {
    const clients = this.state.formContext.formData.Application.clients.slice()
    const client = clients[uploadedDoc.clientIndex]
    if (client.myDocuments) {
      client.myDocuments[uploadedDoc.documentClssIndex].docList.push(
        uploadedDoc.doc
      )
    }
    this.updateUploadedDocs(clients)
  }

  _onUploadDelete = deletedDoc => {
    const clients = this.state.formContext.formData.Application.clients.slice()
    for (let i = 0; i < clients.length; i++) {
      const client = clients[i]
      const clientName =
        client.clientFstNm +
        (client.clientLstNm ? ' ' + client.clientLstNm : '')
      if (deletedDoc.clientName === clientName) {
        client.myDocuments[deletedDoc.documentClssIndex].docList.splice(
          deletedDoc.docIndex,
          1
        )
        break
      }
    }

    this.updateUploadedDocs(clients)
  }

  _updateTabs = () => {
    const { tabs, forms, formContext } = this.state

    if (!_.isEmpty(tabs)) {
      let newTabs = tabs.map(element => {
        let isVisible
        if (typeof element['visibleIf'] === 'boolean') {
          isVisible = element['visibleIf']
        } else {
          isVisible = false
          if (!_.isEmpty(this.props.applicationData)) {
            try {
              var f = new Function(
                'formData',
                'return (' +
                  helper.resolveStringRefs(
                    element['visibleIf'],
                    formContext,
                    'root',
                    null,
                    null,
                    null,
                    this.props.locale
                  ) +
                  ')'
              )
              isVisible = f(this.props.applicationData)
            } catch (err) {
              console.error(
                'Error in AppIntakePE tabs visibleIf check',
                element['visibleIf'],
                err
              )
            }
          }
        }
        return {
          ...element,
          isVisible
        }
      })
      if (!_.isEqual(newTabs, tabs)) {
        this.setState({ tabs: newTabs })
      }
    }
  }

  _showExit = () => {
    this.setState({ showExitModal: true })
  }

  _confirmExit = () => {
    this.setState({
      showExitModal: false,
      redirect: baseUrl + '/myaccount/applications'
    })
  }

  _cancelExit = () => {
    this.setState({ showExitModal: false })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }

    const { presentation, step, locale } = this.props
    const { forms, formContext, errors } = this.state

    let validator = null
    if (forms && step) {
      validator = new CustomValidator(
        forms[step - 1].schema,
        forms[step - 1].uiSchema,
        formContext,
        locale
      )
    }

    const presentationProps = {
      STEPS,
      ..._.omit(this.props, 'presentation'),
      ...this.state,
      validator,
      onPrevious: this._onPrevious,
      saveFormDataDraft: this._saveFormDataDraft,
      changeView: this._changeView,
      onDocUpload: this._onDocUpload,
      onUploadDelete: this._onUploadDelete,
      onNext: this._onNext,
      onSubmit: this._onSubmit,
      onFormDataChange: this._onFormDataChange,
      onValidate: this._onValidate,
      errorListTemplate: this._errorListTemplate,
      onError: this._onError,
      transformErrors: this._transformErrors,
      setRedirect: this._setRedirect,
      submitFormData: this._submitFormData,
      showExit: this._showExit,
      confirmExit: this._confirmExit,
      cancelExit: this._cancelExit,
      hasError: errors.length > 0
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let activeApplicationId = '0'
  let applicationData = {}
  let step = 1
  let status = null
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  if (state.peApplications) {
    activeApplicationId = state.peApplications.activePEApplicationId || '0'

    if (
      state.peApplications.applicationsArray &&
      state.peApplications.applicationsArray.length > 0
    ) {
      const peApplication = _.find(
        state.peApplications.applicationsArray,
        function(element) {
          return element['applId'] === activeApplicationId
        }
      )
      if (peApplication) {
        applicationData = peApplication['applicationData'] || {}
        step = peApplication['step'] || 1
        status = peApplication['status']
      }
    }
  }

  let uuid = _.get(state.auth, 'userAccount.uuid')
  let selectedEntitlements = _.get(
    state.userAccess,
    'selectedUserRole.entitlements',
    []
  ).map(element => element.entitlementName)
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    activeApplicationId,
    applicationData,
    status,
    userRoleId,
    step,
    selectedEntitlements,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addPEApplication: (applId, status, applicationData) => {
      dispatch(actions.addPEApplication(applId, status, applicationData))
    },
    removePEApplication: applId => {
      dispatch(actions.removePEApplication(applId))
    },
    updatePEApplication: (applId, status, applicationData) => {
      dispatch(actions.updatePEApplication(applId, status, applicationData))
    },
    setActivePEApplicationId: applId => {
      dispatch(actions.setActivePEApplicationId(applId))
    },
    assignPEApplicationId: applId => {
      dispatch(actions.assignPEApplicationId(applId))
    },
    updatePEApplicationStep: (applId, step) => {
      dispatch(actions.updatePEApplicationStep(applId, step))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppIntakePEContainer)
