import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

interface MyProvidersPresentationProps {
  CRFMapReact: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  mapProps: {
    member: any
    crfProvider: any
    features: any
  }
  showMap: boolean
  showMapAddress: boolean
  uuid: string
  onFormDataChange: (formData: any) => void
  initialDataLoad: boolean
  showCRFMap: boolean
  previousMapProps: {
    member: any
    crfProvider: any
    features: any
  }
  isAddressChanged: boolean
  isSearchEnabled: boolean
  setCrfContainer: (el: React.ReactNode) => void
  saveProvider: (
    applicantName: string,
    providerName: string,
    appliedBenefit: string,
    providerId: string,
    provider: string
  ) => void
  providerMapping: Array<Object>
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  callingApi: boolean
  locale: string
}

export default function MyProvidersPresentation(
  props: MyProvidersPresentationProps
) {
  const {
    CRFMapReact,
    forms,
    formData,
    formContext,
    mapProps,
    showMap,
    showMapAddress,
    uuid,
    onFormDataChange,
    initialDataLoad,
    showCRFMap,
    previousMapProps,
    isAddressChanged,
    isSearchEnabled,
    setCrfContainer,
    saveProvider,
    providerMapping,
    formComponents,
    callingApi,
    locale
  } = props

  let output = <ScreenPreLoader loaded={false} />

  if (initialDataLoad && forms) {
    output = (
      <div>
        <h1>
          <i className="pe-7s-users" />
          {I18n.t('MyProviders.title')}
        </h1>
        <Form
          schema={forms[0]['schema']}
          uiSchema={forms[0]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <button
            type="submit"
            style={{ display: 'none' }}
            disabled={callingApi}
          />
        </Form>
        <div ref={el => setCrfContainer(el)}>
          {CRFMapReact && showMap && showMapAddress && isSearchEnabled ? (
            <CRFMapReact
              uuid={uuid}
              mapProps={
                showCRFMap && isAddressChanged ? mapProps : previousMapProps
              }
              saveProvider={saveProvider}
              providerMapping={providerMapping}
            />
          ) : null}
        </div>
      </div>
    )
  } else {
    output = (
      <div>
        <h1>
          <i className="glyphicon myglyphicon-group" />{' '}
          {I18n.t('MyProviders.title')}
        </h1>
        <p>{I18n.t('MyProviders.description')}</p>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('MyBenefits.title')}</title>
      </Helmet>
      {output}
    </>
  )
}
