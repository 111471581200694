import { combineReducers } from 'redux'
import externalAuth from './externalAuth'
import hasCCA from './hasCCA'
import auth from './auth'
import myFavorites from './myfavorites'
import myApplications from './myapplications'
import peApplications from './peapplications'
import mycocapplication from './mycocapplications'
import userAccess from './userAccess'
import myMessagesError from './myMessages'
import { i18nReducer } from 'react-redux-i18n'
import myCases from './mycases'
import mybenifitsapplication from './mybenifitsapplication'
import myBenH from './referral'
import statesCounties from './statesCounties'
import caseTypes from './caseTypes'

export default combineReducers({
  externalAuth,
  auth,
  myFavorites,
  myApplications,
  peApplications,
  mycocapplication,
  userAccess,
  myMessagesError,
  i18n: i18nReducer,
  myCases,
  mybenifitsapplication,
  myBenH,
  hasCCA,
  statesCounties,
  caseTypes
})
