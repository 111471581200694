export default function myFavorites(state = [], action) {
  switch (action.type) {
    case 'ADD_MY_FAVORITE':
      if (state.indexOf(action.key) === -1) return state.concat(action.key)
      else return state

    case 'REMOVE_MY_FAVORITE':
      return state.filter(function(element) {
        return element !== action.key
      })
    case 'LOGOUT_SUCCESS':
      return []
    default:
      return state
  }
}
