export let imageMap

/*
	Now all assets are served outside of the bundle
*/

// if (process.env.NODE_ENV !== 'production') {
//   imageMap = {
//     "WV": {
//       "CP_Logo": require('./assets/logo/wv-logo.png'),
//       "CP_Benefits_1": require('./assets/benefits/Woman_Child_HD.png'),
//       "CP_Benefits_2": require('./assets/benefits/Children_HD.png'),
//       "CP_Benefits_3": require('./assets/benefits/Veggies_HD.png'),
//       "CP_Benefits_4": require('./assets/benefits/coc_HD_1286x423.png')
//     },
//     "MN": {
//       "CP_Logo": require('./assets/logo/logo.png'),
//        "CP_Benefits_1": require('./assets/benefits/Man_Kid_HD.png'),
//       "CP_Benefits_2": require('./assets/benefits/Children-1_HD.png'),
//       "CP_Benefits_3": require('./assets/benefits/Veggies1_HD.png'),
//       "CP_Benefits_4": require('./assets/benefits/coc1_hd.png')
//     },
//     "CP_PreQ_Hello": require("./assets/prequestionnaire/hello_new.png"),
//     "CP_PreQ_Help": require("./assets/prequestionnaire/help.png"),
//     "CP_PreQ_Home": require("./assets/prequestionnaire/i_home.png"),
//     "CP_PreQ_Baby": require("./assets/prequestionnaire/baby.png"),
//     "CP_PreQ_Checkbook": require("./assets/prequestionnaire/checkbook.png"),
//     "CP_PreQ_Groceries": require("./assets/prequestionnaire/groceries.png")
//   }
// } else {
imageMap = {
  wv: {
    CP_Logo: '/assets/logo/wv-dhhr-logo.png',
    CP_Benefits_1: '/assets/benefits/Woman_Child_HD.png',
    CP_Benefits_2: '/assets/benefits/Children_HD.png',
    CP_Benefits_3: '/assets/benefits/Veggies_HD.png',
    CP_Benefits_4: '/assets/benefits/coc_HD_1286x423.png'
  },
  mn: {
    CP_Logo: '/assets/logo/logo.png',
    CP_Benefits_1: '/assets/benefits/Man_Kid_HD.png',
    CP_Benefits_2: '/assets/benefits/Children-1_HD.png',
    CP_Benefits_3: '/assets/benefits/Veggies1_HD.png',
    CP_Benefits_4: '/assets/benefits/coc1_hd.png'
  },
  CP_PreQ_Hello: '/assets/prequestionnaire/hello_new.png',
  CP_PreQ_Help: '/assets/prequestionnaire/help.png',
  CP_PreQ_Home: '/assets/prequestionnaire/i_home.png',
  CP_PreQ_Baby: '/assets/prequestionnaire/baby.png',
  CP_PreQ_Checkbook: '/assets/prequestionnaire/checkbook.png',
  CP_PreQ_Groceries: '/assets/prequestionnaire/groceries.png',
  CP_PreQ_Retro: '/assets/prequestionnaire/calendar.png'
}
//}
