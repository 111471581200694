import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

interface MyAppealPresentationProps {
  locale: string
}

function MyAppealPresentation(props: MyAppealPresentationProps) {
  const { locale } = props
  return (
    <>
      <Helmet>
        <title>{I18n.t('MyAppeal.title')}</title>
      </Helmet>
      <div className="row">
        <div className="col-xs-12">
          <h1>
            <span className="pe-7s-speaker" /> {I18n.t('MyAppeal.title')}
          </h1>
        </div>
        <div className="col-xs-12">
          <p>{I18n.t('MyAppeal.appealMessage.p1')}</p>
          <p>{I18n.t('MyAppeal.appealMessage.p2')}</p>
          <p>{I18n.t('MyAppeal.appealMessage.p3')}</p>
          <p>{I18n.t('MyAppeal.appealMessage.p4')}</p>
          <p>{I18n.t('MyAppeal.appealMessage.p5')}</p>
          <p>{I18n.t('MyAppeal.appealMessage.p6')}</p>
        </div>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale
  }
}

export default connect(mapStateToProps)(MyAppealPresentation)
