import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { config } from '../../../config'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { isEmpty, isEqual, isObject, mergeWith } from 'lodash'
import { Link } from 'react-router-dom'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { I18n } from 'react-redux-i18n'

const _ = { isEmpty, isEqual, isObject, mergeWith }

const widgets = {
  span: Widgets.SpanWidget,
  link: ({ id, classNames, value, formContext }) => {
    return (
      <span className={classNames}>
        <button
          type="button"
          onClick={() => formContext.onLinkClick(id)}
          id={id}
          className={'btn btn-link long-link'}
        >
          {value}
        </button>
      </span>
    )
  }
}

const formsEndpoint = config['helpSelfService']
const ebtCardManagementLink = 'https://www.ebtedge.com/cca/main/ebtedge'

class CommonAnswers extends React.Component<any, any> {
  answerContent: React.RefObject<{}>
  constructor(props: any) {
    super(props)
    this.answerContent = React.createRef()
  }

  back = () => {
    this.props._unmountCommonAnwers()
  }

  getAnswer = () => {
    if (!_.isEmpty(this.props.index)) {
      const index = this.props.index
      var answer = ''
      if (index == '7') {
        answer = I18n.t('Help.toCloseCase')
      }
      if (index == '8') {
        {
          ebtCardManagementLink
        }
        {
          I18n.t('Help.ebtLinkTab')
        }
        return (
          <div>
            <h6>{I18n.t('Help.ebtLinkTab')}</h6>
            <a href={ebtCardManagementLink} target="_blank">
              {ebtCardManagementLink}
            </a>
          </div>
        )
      }
      return answer
    }
  }

  getQuestion = () => {
    if (!_.isEmpty(this.props.index)) {
      const index = this.props.index
      switch (index) {
        case '1':
          return I18n.t('Help.comQues1')
        case '2':
          return I18n.t('Help.comQues2')
        case '3':
          return I18n.t('Help.comQues3')
        case '4':
          return I18n.t('Help.comQues4')
        case '5':
          return I18n.t('Help.comQues5')
        case '6':
          return I18n.t('Help.comQues6')
        case '7':
          return I18n.t('Help.comQues7')
        default:
          return ''
      }
    }
  }

  componentDidMount() {
    if (this.props.tabFocus) {
      let el = this.answerContent.current as HTMLElement
      el.focus()
    }
  }

  render() {
    const { locale } = this.props
    return (
      <div className="headerpadding margin-top-10">
        <div className="paragraph-label padding-top-5x headerpadding">
          {I18n.t('Help.title')}
        </div>
        <div className="headerpadding padding-top-5x margin-top-10">
          <a className="myglyphicon-halflings myglyphicon-chevron-left" />
          <a
            id="backToQuestionsBtn"
            href="JavaScript:void(0)"
            onClick={this.back}
          >
            {' '}
            {I18n.t('Help.backToCommonQuestionsLink')}
          </a>
        </div>
        <div className="paragraph-label headerpadding padding-top-5x margin-top-10">
          {this.getQuestion()}
        </div>
        <div
          className="headerpadding padding-top-5x margin-top-10"
          tabIndex={-1}
          ref={this.answerContent as any}
        >
          {this.getAnswer()}
        </div>
      </div>
    )
  }
}

class CommonQuestions extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    document.addEventListener('keydown', this.onFocus)
    this.state = {
      forms: null,
      formData: null,
      formContext: null,
      isLinkSelected: false,
      tabFocus: false
    }
  }

  componentDidMount() {
    const endpoint = formsEndpoint.replace('{version}', '1.0')
    helper
      .fetchJson(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        this._loadAll(formJson)
      })
      .catch(error => {
        console.error('CommonQuestions form fetch failed due to ex', error)
      })
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      const endpoint = formsEndpoint.replace('{version}', '1.0')
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(formJson => {
          this._loadAll(formJson)
        })
        .catch(error => {
          console.error('CommonQuestions form fetch failed due to ex', error)
        })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onFocus)
  }

  _loadAll = Json => {
    let forms, application, formData, formContext
    application = Json['app']
    forms = application['forms']
    formContext = { forms: forms, config, onLinkClick: this.onLinkClick }
    let formDataLoad = application['formData']
    if (_.isObject(formDataLoad) && !_.isEmpty(formDataLoad)) {
      formData = _.mergeWith(
        helper.createObjectFromMasterSchema(null, formContext),
        formDataLoad,
        function(objValue, srcValue) {
          if (srcValue == null && objValue == undefined) {
            return {}
          }
          return undefined
        }
      )
    } else {
      formData = helper.createObjectFromMasterSchema(null, formContext)
    }
    formContext = { ...formContext, formData: formData, component: this }
    let loadScreen = true
    this.setState({ application, forms, formData, formContext, loadScreen })
  }

  _refreshFormContext = () => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        formData: this.state.formData
      }
    })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({ formData }, () => this._refreshFormContext())
  }

  _unmountCommonAnwers = () => {
    this.setState({ isLinkSelected: false, tabFocus: false })
  }

  onLinkClick = id => {
    const index = id.match(
      '(?:.*)forms_help_application_CommonQuestion([0-9]+)?'
    )[1]
    this.setState({ isLinkSelected: true, index }, () =>
      this._refreshFormContext()
    )
  }

  onFocus = e => {
    if (e.which === 9 && !this.state.tabFocus) {
      this.setState({ tabFocus: true })
    } else if (e.which === 1 && this.state.tabFocus) {
      this.setState({ tabFocus: false })
    }
  }

  render() {
    let loaded = false
    if (this.state.forms) {
      loaded = true
      if (!this.state.isLinkSelected) {
        return (
          <div>
            <div className="col-md-12 border-container margin-bottom-15px no-gutter">
              <div className="container-fluid report-renewal-pg">
                <div className="col-md-12 no-gutter">
                  <Form
                    schema={this.state.forms[0]['schema']}
                    uiSchema={this.state.forms[0]['uiSchema']}
                    formData={this.state.formData}
                    onChange={this._onFormDataChange}
                    formContext={this.state.formContext}
                    widgets={widgets}
                    ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
                    FieldTemplate={Templates.CustomFieldTemplate}
                    locale={this.props.locale}
                  >
                    <span />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <CommonAnswers
            _unmountCommonAnwers={this._unmountCommonAnwers}
            index={this.state.index}
            locale={this.props.locale}
            tabFocus={this.state.tabFocus}
          />
        )
      }
    } else {
      return (
        <div className="container-fluid reports-container">
          <div>
            <ScreenPreLoader loaded={false} />
          </div>
        </div>
      )
    }
  }
}

export default CommonQuestions
