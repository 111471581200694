import * as React from 'react'
import * as _ from 'lodash'
import NavTabs from '@optum-wvie/dynamic-ui-framework/src/components/natives/NavTabs'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { Popover, Overlay, Tooltip, OverlayTrigger } from 'react-bootstrap'
import '!style-loader!css-loader!../src/customStyles.css'
import { I18n } from 'react-redux-i18n'

declare var process
const baseUrl = process.env.PUBLIC_URL

interface MyAccountTab {
  id: string
  clientTitle: {
    en: string
    es: string
  }
  otherTitle: {
    en: string
    es: string
  }
  title: string
  leftIcon: string
  component: React.ReactNode
  entitlements: Array<string>
}

interface MyAccountPresentationProps {
  id: string
  subProps: Object
  navTabs: Array<MyAccountTab>
  onNavClick: (id: string) => void
  setNavDiv: (el: React.ReactNode) => void
  selectedEntitlements: Array<string>
  userRoles: Array<any>
}

export default function MyAccountPresentation(
  props: MyAccountPresentationProps
) {
  const {
    id,
    subProps,
    navTabs,
    onNavClick,
    setNavDiv,
    selectedEntitlements,
    userRoles
  } = props

  let finalNavTabs = null

  //Prepend "My " to every tab if they are a participating client.
  if (_.includes(selectedEntitlements, ENTITLEMENTS.CLIENT_PARTICIPATION)) {
    finalNavTabs = (navTabs || []).map(tab => {
      return {
        ..._.omit(tab, 'clientTitle', 'otherTitle'),
        title: I18n.t(tab.clientTitle)
      }
    })
  } else {
    finalNavTabs = (navTabs || []).map(tab => {
      return {
        ..._.omit(tab, 'clientTitle', 'otherTitle'),
        title: I18n.t(tab.otherTitle)
      }
    })
  }

  let ActiveSubcomponent = null
  if (finalNavTabs.length === 0 && !_.isEmpty(userRoles)) {
    ActiveSubcomponent = props => (
      <div>
        WV PATH is currently undergoing an update. My Account, historical
        applications, renewals, change of circumstances, and messages are
        currently unavailable. Please direct questions related to benefits to
        the Customer Service Reporting Center at 1-877-716-1212 or your local
        county office. Thank you for being patient as WV PATH functionality is
        updated.
      </div>
    )
  } else if (_.isEmpty(userRoles) && finalNavTabs.length === 0) {
    ActiveSubcomponent = props => (
      <div>You are not authorized to access this section.</div>
    )
  } else {
    const activeTabIndex = _.findIndex(navTabs, { id })
    if (activeTabIndex > -1) {
      ActiveSubcomponent = navTabs[activeTabIndex].component
    } else {
      ActiveSubcomponent = props => <div>Invalid subcomponent ID</div>
    }
  }
  let helpPopover = (
    <Popover id="helpPopover">
      <a href="/help" target="_blank" tabIndex={-1}>
        Help Center
      </a>
    </Popover>
  )

  let helpLink = (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={helpPopover}
    >
      <button className="print btn btn-tiny btn-white">
        <span className="fa fa-life-ring btn-icon-sm" />
        {I18n.t('General.helpButtonLabel')}
      </button>
    </OverlayTrigger>
  )

  return (
    <div>
      <a href="#side-nav" className="skip-nav">
        Skip to side navigation
      </a>
      <div className="container-fluid">
        <div className="row" style={{ display: 'block' }}>
          <div className="header-view">
            <p className="heading print">
              <i className="myglyphicon-halflings myglyphicon-user myaccount-icon" />{' '}
              {I18n.t('General.myAccount')}
            </p>
            <div className="header-sub" />
          </div>
          <div className="myAccBelowHeader">
            <div className="float-right">
              {/* <button
                className="save btn btn-tiny btn-white"
                onClick={() => console.log('exit')}
              >
                <span className="fa fa-times btn-icon-sm" />
                Exit
              </button> */}
              <button
                className="print btn btn-tiny btn-white"
                onClick={() => {
                  window.open('/help', '_blank')
                }}
              >
                <span className="fa fa-life-ring btn-icon-sm" />
                {I18n.t('General.helpButtonLabel')}
              </button>
              <button
                className="print btn btn-tiny btn-white"
                onClick={window.print}
              >
                <span className="fa fa-print btn-icon-sm" />
                {I18n.t('General.printButtonLabel')}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            id="side-nav"
            className="tabs-section col-md-4 col-lg-3 col-xl-2 no-padding"
            ref={el => setNavDiv(el)}
          >
            <NavTabs
              activeKey={id}
              onNavClick={onNavClick}
              tabs={finalNavTabs}
            />
          </div>
          <div
            className="form-section col-md-8 col-lg-9 col-xl-10"
            id="content"
            tabIndex={-1}
          >
            <ActiveSubcomponent {...subProps} />
          </div>
        </div>
      </div>
    </div>
  )
}
