import * as React from 'react'
import * as _ from 'lodash'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import '!style-loader!css-loader!../src/customStyles.css'

export default function Faq(props) {
  let helpPopover = (
    <Popover id="helpPopover">
      <a href="/help" target="_blank">
        Help Center
      </a>
    </Popover>
  )

  let helpLink = (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={helpPopover}
    >
      <button className="btn btn-tiny btn-white">
        <span className="fa fa-life-ring btn-icon-sm" />
        Help
      </button>
    </OverlayTrigger>
  )
  return (
    <div>
      <div id="content" className="benefits-catalog-content col-12 no-gutter">
        <div className="header-view">
          <h1 className="heading">
            <i className="fa fa-question-circle btn-icon-md" /> FAQ
          </h1>
          <div className="header-sub" />
        </div>
        <div className="faqBelowHeader">
          <div className="float-right margin-left-auto">
            {helpLink}
            <button
              className="print btn btn-tiny btn-white"
              onClick={function() {
                window.print()
              }}
            >
              <span className="fa fa-print btn-icon-sm" />
              Print
            </button>
          </div>
        </div>
        <div role="main" className="col-12 faq-question-font">
          <div>
            <h2>Which browsers are compatible with PATH?</h2>
            <p>
              Chrome v39+, Safari v5.1.10+, Firefox v35+, Internet Explorer/Edge
              v9+.
            </p>
          </div>
          <br />
          <div>
            <h2>Do I have to create an account to submit an application?</h2>
            <p>
              Yes, since all applications are now electronically signed, you
              will have to create an account to apply for benefits. Screening
              can still be done without creating an account.
            </p>
          </div>
          <br />
          <div>
            <h2>What happened to my inROADS account?</h2>
            <p>
              We have replaced inROADS with the new PATH system to allow you to
              apply for both DoHS programs and services in one place. In order
              to submit applications, complete reviews, or view benefits online,
              you must create an account in the new system. This is the only
              account you will need to manage all applications, reviews,
              benefits, and services for the cases with which you are
              associated. Once the new account is created and you are logged in,
              you can view your existing benefits and services and complete
              reviews by navigating to the “My Account” section and entering
              your SSN (or WV DoHS PIN if you do not have an SSN).
            </p>
          </div>
          <br />
          <div>
            <h2>
              If I had an old inROADS and/or My Benefits Account (MBA), do I
              have to create a new one?
            </h2>
            <p>
              Yes, due to the switch to the new system, you will have to create
              a new account even if you previously had one. This account is the
              only account you will need to submit applications and reviews and
              view all benefits and services in all cases you are part of. To
              create an account, click the “Sign In” link on the top of the PATH
              homepage and follow the instructions.
            </p>
          </div>
          <br />
          <div>
            <h2>Who can set up a PATH Account?</h2>
            <p>
              Any individual over the age of 18 (or emancipated minor) can
              create a PATH Account to apply for benefits and services, complete
              a review, or view benefits they are receiving through the WV DoHS.
            </p>
          </div>
          <br />
          <div>
            <h2>
              If I forget my account user ID or password can I recover them?
            </h2>
            <p>
              Yes, you can provide credentials to retrieve your user ID or
              password. Click the “Sign In” link on the top of the PATH
              homepage.
            </p>
            <div>
              <ul>
                <li>
                  For your account (Optum) ID: Click “Forgot Optum ID” and
                  follow instructions.
                </li>
                <li>
                  For your account password: Click “Forgot Password” and follow
                  instructions.
                </li>
              </ul>
            </div>
          </div>
          <br />
          <div>
            <h2>
              Once I have created the account, how do I view my benefits
              information and complete reviews?
            </h2>
            <p>
              You will need to provide a little more information once you have
              created your account in order to retrieve benefits information and
              submit reviews. Navigate to the “My Profile” section by clicking
              “My Account” from the homepage. Enter your SSN (or WV DoHS PIN if
              you do not have an SSN). PATH will retrieve and display all
              benefits and services in the “My Benefits” section. You will be
              able to see your benefits and services or all benefits and
              services your household is receiving if you are the household’s
              Primary Person. If any of these benefits are due for a review, you
              will see a “Begin Review” button next to the benefit information.
              If more than one review is due, clicking the “Begin Review button”
              on any benefit will retrieve all reviews that are due.
            </p>
          </div>
          <br />
          <div>
            <h2>What information can I see in the My Account?</h2>
            <p>
              By default, the Primary Person of a case will have the ability to
              see all information associated with that case. Other adult
              household members, by default, will only be able to see their own
              information.
            </p>
          </div>
          <br />
          <div>
            <h2>How do I view the detailed benefits information?</h2>
            <p>
              On the account summary screen, you will see high level summarized
              information about the benefits in the case. Clicking the program
              icons on the benefit summary screen will direct you to pages that
              have more detailed benefits information. You can navigate between
              benefits using tabs at the top of the details screens.
            </p>
          </div>
          <br />
          <div>
            <h2>Will I still have to send in a separate signature page?</h2>
            <p>
              No. All PATH applications and reviews will now be electronically
              signed. You will still, however, need to provide any requested
              documentation to the county office.
            </p>
          </div>
          <br />
          <div>
            <h2>How do I print applications and reviews for my records?</h2>
            <p>
              After you have submitted your application or review, you will
              receive a submission confirmation. Toward the bottom of this
              message, you will find a link to navigate to your My Applications.
              From there you can print a PDF version of your application or
              review. If you choose not to print your application or review at
              that time, it will be accessible in your My Account to view and/or
              print for 15 months.
            </p>
            <br />

            <span>
              Note: You will have to download Adobe in order to view and/or
              print the application. A link to download Adobe is available on
              both the submission confirmation page as well as in the My
              Account.
            </span>
          </div>
          <br />
          <div>
            <h2>How do I find a Community Partner in my area?</h2>
            <p>
              Click the Community Partner link on the bottom of the PATH
              homepage (“Community Partners”) and select a county. This will
              return all Community Partners that serve your selected county and
              will provide information about their location, hours, and services
              provided.
            </p>
          </div>
          <br />
          <div>
            <h2>What do I do if I have trouble using the PATH website?</h2>
            <p>
              If you have trouble using the PATH website, contact the Technical
              Call Center at 1-844-451-3515 (8 AM - 5 PM Monday through Friday).
            </p>
          </div>
          <br />
        </div>
      </div>
    </div>
  )
}
