import * as React from 'react'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import * as _ from 'lodash'
import { config } from '../../../config'
import { UnauthorizedError, getServerTime } from '../src/components/utils'

import * as validator from '../src/validation'
const formsEndpoint = config['formsBaseUrl'] + config['forms_userAccess']
const endPoint = config['gatewayWvUrl'] + config['getUserResults']
const deleteEndPoint = config['gatewayWvUrl'] + config['cancelInvitation']
const invitationEndPoint = config['gatewayWvUrl'] + config['invitation']
const updateAccessEndPoint = config['gatewayWvUrl'] + config['updateAccess']
const roleEndPoint = config['getAllRoles']
const profileEndPoint = config['gatewayWvUrl'] + config['gettabs']
const workerAdminOrg = config['gatewayWvUrl'] + config['retrieveorg']
const baseUrl = process.env.PUBLIC_URL
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
let newOrgValueList: any
let serverTime: any
interface UserAccessContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  userFormData: any
  minHeight: string
  orgId: string
  roleId: number
  ieOrgId: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  showModal: boolean
  selectedEntitlements: Array<string>
  userAccess: {
    selectedOrg: {
      orgId: any
      orgName: any
      ieOrgId: any
    }
    selectedUserRole: {
      userRoleId: any
      uuid: any
      role: {
        roleId: any
        roleName: any
      }
    }
  }
  usersListCreated: {}
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  logoutUser
  history: {
    push: (url: string) => void
  }
  locale: string
}

interface UserAccessContainerState {
  formComponents: any
  form: Array<{
    schema: Object
    uiSchema: Object
    metaData: Object
  }>
  formContext: any
  formData: any
  userFormData: any
  showModal: boolean
  showModalMessage: string
  showModalError: boolean
  userId: any
  userDetailViewId: any
  startDate: any
  application: any
  redirect: any
  access: boolean
  redirectDetials: any
  userDetails: any
  pendingUsers: Array<{
    firstName: string
    lastName: string
    email: string
    trainingConfirmationDate: any
    dataAgreementCompletionDate: any
  }>
  pendingUserModal: boolean
  selectedPUser: {
    index: any
    user: {
      firstName: string
      lastName: string
      email: string
    }
  }
  userResults: any
  usersListCreated: {}
  organizations: Array<any>
  isUserUpdating: boolean
  orgData: Array<any>
  getAllRoles: any
  selectedOrg: Array<any>
  testI: Array<any>
  liveValidate: boolean
  userList?: any
  currentDate: any
  selectedOrgList: any
  showCallbackModal: boolean
}

class UserAccessContainer extends React.Component<
  UserAccessContainerProps,
  UserAccessContainerState
> {
  constructor(props: UserAccessContainerProps) {
    super(props)
    serverTime = getServerTime()
    this.state = {
      application: {},
      form: null,
      formContext: {
        formData: {}
      },
      orgData: [],
      getAllRoles: [],
      selectedOrg: [],
      showModal: false,
      showModalMessage: null,
      showModalError: false,
      formComponents: null,
      formData: null,
      userFormData: null,
      userId: null,
      userDetailViewId: null,
      startDate: _moment(config, serverTime),
      redirect: null,
      access: false,
      redirectDetials: null,
      userDetails: null,
      pendingUsers: [],
      pendingUserModal: false,
      selectedPUser: {
        index: -1,
        user: {
          firstName: '',
          lastName: '',
          email: ''
        }
      },
      userResults: {
        usersList: [],
        pendingUserList: []
      },
      usersListCreated: {},
      organizations: [],
      isUserUpdating: false,
      testI: [],
      liveValidate: false,
      currentDate: serverTime,
      selectedOrgList: [],
      showCallbackModal: false
    }
  }

  componentDidMount() {
    document.title = 'User Access - My Account'
    this._initializeForm()
    this._getUserResults()
    this._getOrgs()
    this._getRoles()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      this._initializeForm()
    }
  }
  static getDerivedStateFromProps(
    props: UserAccessContainerProps,
    state: UserAccessContainerState
  ) {
    return {
      formContext: {
        ...state.formContext,
        formData: props.formData
      }
    }
  }

  _initializeForm = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    serviceHelper
      .fetchJson(
        formsEndpoint.replace('{version}', '1.0'),
        request,
        this.props.showErrorMessage
      )
      .then(form => {
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            formContext: {
              ...prevState.formContext,
              forms: [{ ...form }],
              viewPendingUser: this._viewUser,
              removeUser: this._removeUser,
              refs: {
                ...form['app']['metaData']['refs'],
                '{name}': formContext => {
                  let statusTitleName = ''
                  switch (
                    this.props.userAccess.selectedUserRole.role.roleName
                  ) {
                    case 'CP Admin':
                      statusTitleName = 'Remove Community Partner Rights'
                      break
                    case 'PE Admin':
                      statusTitleName = 'Remove Presumptive Eligibility Rights'
                      break
                    default:
                      statusTitleName = 'Remove Eligibility Rights'
                  }
                  return statusTitleName
                },
                '{is cp}': formContext => {
                  return (
                    formContext.formContext.selectedEntitlements.find(
                      entitlement => {
                        return entitlement === 'CP Admin'
                      }
                    ) === 'CP Admin'
                  )
                },
                '{is pe trainee}': formContext => {
                  /* Pe Trinee to select role dropdown*/
                  return formContext.formContext.formData.Application.usercompleteDetails[0].roleName.includes(
                    'PE Trainee'
                  )
                }
              },
              selectedEntitlements: this.props.selectedEntitlements
            }
          }
        })
      })
      .catch(error => {
        console.error('user acces _initializeForm error', error)
      })
  }

  _getUserResults = () => {
    let params
    if (
      this.props.userAccess.selectedUserRole.role.roleName === 'Worker Admin'
    ) {
      params = null
    } else {
      params = this.props.userAccess.selectedOrg
        ? this.props.userAccess.selectedOrg.orgId
        : ''
    }
    let role = this.props.userAccess.selectedUserRole.userRoleId || ''
    serviceHelper
      .fetchJson(
        endPoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          body: JSON.stringify({
            roleId: role.toString(),
            orgId: params === null ? null : params.toString()
          })
        },
        this.props.showErrorMessage
      )
      .then(data => {
        let newUserList = data.usersList.map(user => {
          return {
            ...user
          }
        })
        if (
          this.props.userAccess.selectedUserRole.role.roleName !==
          'Worker Admin'
        ) {
          const modifiedUserList = newUserList.map(user => {
            const roleName = user.roleName
              .substring(0, user.roleName.indexOf('-'))
              .trim()
            return {
              ...user,
              roleName
            }
          })
          newUserList = modifiedUserList
        }
        this.setState({
          userResults: {
            ...data,
            usersList: newUserList
          },
          userList: newUserList,
          usersListCreated: newUserList,
          pendingUserModal: false
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('User access _getUserResults failed due to ex', error)
      })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  // Defect# 3706
  _handleMultiSelect = (data, i) => {
    // below handle the organization selected list while adding user
    let testI = i.map(x => x.value)
    this.setState({
      selectedOrg: testI,
      selectedOrgList: i
    })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({
      formData
    })
  }

  _onUserFormDataChange = ({ formData }) => {
    let status =
      formData.Application.usercompleteDetails[0].status === 'Yes'
        ? 'Inactive'
        : 'Active'
    const { userDetailViewId } = this.state
    this.setState({
      userFormData: formData,
      userDetailViewId: {
        ...userDetailViewId,
        status: status,
        selectRole: formData.Application.usercompleteDetails[0].selectRole,
        trainingConfirmationDate:
          formData.Application.usercompleteDetails[0].trainingConfirmationDate,
        dataAgreementCompletionDate:
          formData.Application.usercompleteDetails[0]
            .dataAgreementCompletionDate
      }
    })
  }

  _hideModal = () => {
    this.setState({
      showModal: false,
      showModalError: false,
      showModalMessage: null
    })
  }

  _hideCallbackModal = () => {
    this.setState({
      showCallbackModal: false,
      showModalError: false,
      showModalMessage: null
    })
  }

  _showModal = () => {
    this.setState({
      showCallbackModal: false,
      showModal: true,
      isUserUpdating: false
    })
  }

  _viewUser = props => {
    this.setState({
      userDetailViewId: props.options.conditionObj.baseObject
    })
  }

  _removeUser = props => {
    let { userResults } = this.state
    let index = userResults.pendingUserList.findIndex(
      (ele: any) => ele.email === props.options.conditionObj.baseObject.email
    )
    this._showPendingUserModal(index, userResults.pendingUserList[index])
  }

  _getRoles = () => {
    serviceHelper
      .fetchJson(
        roleEndPoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(data => {
        this.setState({
          getAllRoles: data.roleList
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.log(error)
      })
  }

  _getOrgs = () => {
    // let newOrgValueList
    let newProfileTablist
    if (
      this.props.userAccess.selectedUserRole.role.roleName === 'Worker Admin'
    ) {
      serviceHelper
        .fetchJson(
          workerAdminOrg,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              roleId: this.props.roleId,
              orgId: this.props.orgId
            }
          },
          this.props.showErrorMessage
        )
        .then(data => {
          newOrgValueList = data.agencyList.filter((obj, index) => {
            if (!data.agencyList[index].organizationName) {
              delete data.agencyList[index]
            }
            return (
              data.agencyList
                .map(obj => obj['organizationId'])
                .indexOf(obj['organizationId']) === index
            )
          })
          newProfileTablist = newOrgValueList.map(item => {
            return {
              orgName: item.organizationName,
              value: item.organizationName,
              id: item.organizationId,
              label: item.organizationName
            }
          })
          newProfileTablist.sort((a, b) =>
            a.orgName > b.orgName ? 1 : b.orgName > a.orgName ? -1 : 0
          )
          this.setState({
            orgData: newProfileTablist
          })
        })
        .catch(error => {
          if (error instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.log(error)
        })
    } else {
      serviceHelper
        .fetchJson(
          profileEndPoint,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              roleId: this.props.roleId,
              orgId: this.props.orgId
            }
          },
          this.props.showErrorMessage
        )
        .then(data => {
          newOrgValueList = data.ProfileTabList.filter((obj, index) => {
            return (
              data.ProfileTabList.map(obj => obj['orgId']).indexOf(
                obj['orgId']
              ) === index
            )
          })
          newProfileTablist = newOrgValueList.map(item => {
            return {
              orgName: item.orgName,
              value: item.orgName,
              id: item.orgId
            }
          })
          this.setState({
            orgData: newProfileTablist
          })
        })
        .catch(error => {
          if (error instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.log(error)
        })
    }
  }

  _addNewUser = formData => {
    let workerAdminSelectedOrg = formData.orgs ?? []
    let workerAdminSelectedOrgName = []
    let workerAdminSelectedOrgId = []
    let invitationOrgName = []
    let invitationOrgId = []
    let _invitationIEOrgId = []
    let invitationRole

    // Below method gets the selected Orgs by Worker Admin
    this.getWorkerAdminSelectedOrgs(
      workerAdminSelectedOrg,
      workerAdminSelectedOrgName,
      workerAdminSelectedOrgId
    )

    const card = this.props.userAccess.selectedUserRole
    let roleSelected = formData.selectedRole
    let loggedRoleName = card.role.roleName

    if (loggedRoleName === 'CP Admin') {
      invitationRole = 'Community Partner'
      invitationOrgName.push(this.props.userAccess.selectedOrg.orgName)
      invitationOrgId.push(this.props.userAccess.selectedOrg.orgId)
      _invitationIEOrgId.push(this.props.userAccess.selectedOrg.ieOrgId)
    } else if (loggedRoleName === 'PE Admin') {
      invitationOrgName.push(this.props.userAccess.selectedOrg.orgName)
      invitationOrgId.push(this.props.userAccess.selectedOrg.orgId)
      _invitationIEOrgId.push(this.props.userAccess.selectedOrg.ieOrgId)
      if (roleSelected === 'PE Worker') {
        invitationRole = 'Presumptive Eligibility Worker'
      } else if (roleSelected === 'PE Trainee') {
        invitationRole = 'PE Trainee'
      }
    } else if (loggedRoleName === 'Worker Admin') {
      invitationOrgName = workerAdminSelectedOrgName
      invitationOrgId = workerAdminSelectedOrgId
      invitationRole = formData.roles
      if (newOrgValueList) {
        newOrgValueList.filter(function(item) {
          let numtoString = item.organizationId.toString()
          if (_.includes(invitationOrgId, numtoString)) {
            _invitationIEOrgId.push(item.ieOrgId)
          }
        })
      }
    }

    const userObj = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.emailAddress,
      trainingConfirmationDate: _moment(config, formData.trainingDate).format(
        'MM/DD/YYYY'
      ),
      dataAgreementCompletionDate: _moment(
        config,
        formData.completionDate
      ).format('MM/DD/YYYY'),
      action: 'cancel',
      id: _moment(config, serverTime),
      role: formData.selectedRole,
      roleName: formData.selectedRole,
      org: formData.orgs
    }

    const allRoles = _.get(this, 'state.getAllRoles', [])
    const newRole = allRoles.find(roleObj => {
      return roleObj.roleName === invitationRole
    })

    this.invitationServiceCall$(
      invitationOrgId,
      invitationRole,
      newRole,
      formData,
      card,
      invitationOrgName,
      _invitationIEOrgId,
      userObj
    )
  }

  invitationServiceCall$ = (
    invitationOrgId,
    invitationRole,
    newRole,
    formData,
    card,
    invitationOrgName,
    _invitationIEOrgId,
    userObj
  ) => {
    let { pendingUsers, formContext, userResults } = this.state
    serviceHelper
      .fetchJson(
        invitationEndPoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId,
            ieOrgId: this.props.ieOrgId
          },
          body: JSON.stringify({
            agencyIdList: invitationOrgId,
            invitationId: '',
            invtExpiryDate: '',
            legacyUserId: '',
            roleName: invitationRole,
            roleId: newRole ? `${newRole.roleId}` : '',
            systemUserId: card.uuid,
            userId: '',
            UUID: '',
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailId: formData.emailAddress,
            tranCmplDate: _moment(config, formData.trainingDate).format(
              'MM/DD/YYYY'
            ),
            agrCmplDate: _moment(config, formData.completionDate).format(
              'MM/DD/YYYY'
            ),
            org: invitationOrgName,
            _ieOrgIdObj: _invitationIEOrgId
          })
        },
        this.props.showErrorMessage
      )
      .then(data => {
        let status = data.status
        if (status === 'ERROR') {
          this.setState({
            showModalMessage: 'Changes are not saved. Please try again.',
            showModalError: true,
            showCallbackModal: true,
            showModal: false
          })
        } else {
          if (status === 'Success') {
            pendingUsers =
              userResults && userResults.pendingUserList
                ? userResults.pendingUserList.concat(userObj)
                : {}
            formContext.formData = pendingUsers
            this.setState({
              showModalError: false,
              showModalMessage: 'Changes have been saved successfully.',
              formContext,
              pendingUsers,
              userResults: {
                usersList: userResults.usersList,
                pendingUserList: pendingUsers
              },
              showCallbackModal: true,
              showModal: false
            })
          } else if (status.length > 0) {
            this.setState({
              showModalError: false,
              showModalMessage: status,
              formContext,
              showCallbackModal: true,
              showModal: false
            })
          }
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error(
          'User access invitationServiceCall failed due to ex',
          error
        )
      })
  }

  _hidePendingUserModal = () => {
    this.setState({ pendingUserModal: false })
  }

  _showPendingUserModal = (index, user) => {
    this.setState({
      selectedPUser: {
        index: index,
        user: user
      },
      pendingUserModal: true
    })
  }

  _removePendingUser = index => {
    let { userResults } = this.state

    serviceHelper
      .fetchJson(
        deleteEndPoint,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId,
            ieOrgId: this.props.ieOrgId
          },
          body: JSON.stringify({
            invitationToken: userResults.pendingUserList[index].invitationId,
            tenantCode: config.tCode
          })
        },
        this.props.showErrorMessage
      )
      .then(data => {
        this._getUserResults()
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('User access _removePendingUser failed due to ex', error)
      })
  }

  _clearSearch = () => {
    this.setState(prevState => {
      return {
        ...prevState.formContext,
        userResults: {
          ...this.state.userResults,
          usersList: this.state.usersListCreated
        }
      }
    })
  }

  _backToUser = () => {
    this.setState({
      userDetailViewId: null,
      liveValidate: false
    })
  }

  _updateUser = (uFormContext, e) => {
    const allRoles = this.state.getAllRoles ? this.state.getAllRoles : ''
    const userOrgId = this.props.userAccess.selectedOrg
      ? this.props.userAccess.selectedOrg.orgId
      : ''
    const ieOrgID = this.state.userDetailViewId.ieOrgId
    let updateUserRoleId = this.state.userDetailViewId.roleId
    let updateUserRoleName =
      this.state.userDetailViewId &&
      this.state.userDetailViewId.roleName &&
      this.state.userDetailViewId.roleName.includes('-')
        ? this.state.userDetailViewId.roleName.split('-')[0].trim()
        : this.state.userDetailViewId.roleName
    const userRoleAgcyId = this.state.userDetailViewId.userRoleAgcyId
    const userListUuid = this.state.userDetailViewId.uuid
    let pendingUserEmail = this.state.userDetailViewId.email
    let newRemoveRights = uFormContext.status === 'Active' ? 'N' : 'Y'
    let removeRights = this.state.userFormData
      ? this.state.userFormData.Application.usercompleteDetails[0].status[0]
      : newRemoveRights
    const selectRoleDependencyObj = {
      previousRoleId: null,
      previousRoleName: null,
      tranCmplDate: this.state.userDetailViewId.trainingConfirmationDate,
      agrCmplDate: this.state.userDetailViewId.dataAgreementCompletionDate
    }

    if (
      this.props.selectedEntitlements.find(entitlement => {
        return entitlement === 'PRESUMPTIVE_ELIGIBILITY_MANAGEMENT'
      }) === 'PRESUMPTIVE_ELIGIBILITY_MANAGEMENT'
    ) {
      selectRoleDependencyObj.tranCmplDate = this.state.userDetailViewId.trainingConfirmationDate
      selectRoleDependencyObj.agrCmplDate = this.state.userDetailViewId.dataAgreementCompletionDate
      if (
        this.state.userDetailViewId.roleName == 'PE Trainee' &&
        this.state.userDetailViewId.selectRole != undefined
      ) {
        selectRoleDependencyObj.previousRoleId = this.state.userDetailViewId.roleId
        selectRoleDependencyObj.previousRoleName = this.state.userDetailViewId.roleName
        updateUserRoleName = this.state.userDetailViewId.selectRole
        const newRole = allRoles.find(roleObj => {
          return roleObj.roleName === updateUserRoleName
        })
        updateUserRoleId = newRole.roleId
      }
    }
    serviceHelper
      .fetchJson(
        updateAccessEndPoint,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId,
            ieOrgId: ieOrgID
          },
          body: JSON.stringify({
            agencyId: `${userOrgId}`,
            removeRights: removeRights,
            roleId: `${updateUserRoleId}`,
            roleName: updateUserRoleName,
            tenantId: config.tId,
            userRoleAgcyId: userRoleAgcyId,
            uuid: userListUuid,
            userEmail: pendingUserEmail,
            tranCmplDate: selectRoleDependencyObj.tranCmplDate,
            agrCmplDate: selectRoleDependencyObj.agrCmplDate,
            previousRoleId: selectRoleDependencyObj.previousRoleId,
            previousRoleName: selectRoleDependencyObj.previousRoleName
          })
        },
        this.props.showErrorMessage
      )
      .then(data => {
        this._showModal()
        if (data.status === 'ERROR') {
          this.setState({
            userDetailViewId: null,
            showModalMessage: 'Changes are not saved. Please try again.',
            showModalError: false,
            isUserUpdating: true,
            showCallbackModal: true,
            showModal: false
          })
        } else {
          this._getUserResults()
          this.setState({
            userDetailViewId: null,
            showModalError: false,
            liveValidate: false,
            showModalMessage: 'The changes have been successfully saved.',
            isUserUpdating: true,
            showCallbackModal: true,
            showModal: false
          })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        this.setState({
          isUserUpdating: true
        })
        console.error('User access _updateUser failed due to ex', error)
      })
    this.setState({ liveValidate: false })
  }

  _cancelPendingUser = () => {
    this.setState({
      pendingUserModal: false
    })
  }

  multiFilter = (arr, filters) => {
    const filterKeys = Object.keys(filters)
    return arr.filter(eachObj => {
      return filterKeys.every(eachKey => {
        if (!filters[eachKey]) {
          return true
        }
        return filters[eachKey]
          .toLowerCase()
          .includes(eachObj[eachKey].toLowerCase())
      })
    })
  }
  _search = () => {
    let { formData, userList, usersListCreated } = this.state
    let searchResults
    if (this.state.userList.length > 0) {
      if (!formData) {
        searchResults = usersListCreated
      } else if (
        !formData.Application.searchUserId &&
        !formData.Application.searchFirstName &&
        !formData.Application.searchLastName &&
        !formData.Application.searchEmail
      ) {
        searchResults = usersListCreated
      } else {
        let searchItems = {
          userID: formData.Application.searchUserId,
          firstName: formData.Application.searchFirstName,
          lastName: formData.Application.searchLastName,
          email: formData.Application.searchEmail
        }
        searchResults = this.multiFilter(
          userList.length > 0 ? userList : usersListCreated,
          searchItems
        )
      }
      this.setState(prevState => {
        return {
          userResults: {
            ...this.state.userResults,
            usersList: searchResults
          }
        }
      })
    }
  }

  _transformErrors = (errors: object) => {
    return errors
  }
  _errorListTemplate = (props: any) => {
    const { errors } = props

    return (
      <div className="panel panel-danger errors">
        <div className="panel-heading">
          <h3 className="panel-title">Errors</h3>
        </div>
        <ul className="list-group">
          {errors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.
            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  {errorObject
                    ? errorObject.displayMessage
                    : error.stack.substring(error.stack.indexOf(':') + 1)}
                  <a
                    href={'#form1_' + errorObject.id}
                    aria-describedby={'form1_' + errorObject.id}
                  >
                    <span
                      className="fa fa-eye"
                      title={errorObject.displayMessage}
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  private getWorkerAdminSelectedOrgs(
    workerAdminSelectedOrg: any,
    workerAdminSelectedOrgName: any[],
    workerAdminSelectedOrgId: any[]
  ) {
    let newOrgListArray = []
    if (workerAdminSelectedOrg.length > 0) {
      for (let i = 0; i < this.state.orgData.length; i++) {
        for (let j = 0; j < workerAdminSelectedOrg.length; j++) {
          if (this.state.orgData[i].orgName == workerAdminSelectedOrg[j]) {
            newOrgListArray.push(this.state.orgData[i])
          }
        }
      }
      for (let i = 0; i < newOrgListArray.length; i++) {
        workerAdminSelectedOrgName.push(`${newOrgListArray[i].orgName}`)
        workerAdminSelectedOrgId.push(`${newOrgListArray[i].id}`)
      }
    }
  }

  _onValidate(selectedEntitlements, formData: any, errors: object) {
    let isPe
    if (selectedEntitlements) {
      isPe =
        selectedEntitlements.find(entitlement => {
          return entitlement === 'PRESUMPTIVE_ELIGIBILITY_MANAGEMENT'
        }) === 'PRESUMPTIVE_ELIGIBILITY_MANAGEMENT'
    }
    if (
      isPe &&
      formData.Application.usercompleteDetails[0].selectRole ===
        'Presumptive Eligibility Worker'
    ) {
      validator.validateUserAccess(formData, errors)
    }
    return errors
  }

  _switchOnLiveValidate = () => {
    this.setState({ liveValidate: true })
  }

  render() {
    const {
      presentation,
      isLoading,
      minHeight,
      selectedEntitlements,
      errorMessage
    } = this.props
    const {
      application,
      form,
      formData,
      formContext,
      showModal,
      access,
      userDetails,
      userDetailViewId,
      pendingUserModal,
      selectedPUser,
      userResults,
      showModalError,
      showModalMessage,
      isUserUpdating,
      orgData,
      getAllRoles,
      liveValidate,
      showCallbackModal
    } = this.state

    const orgDataList = orgData.map(x => x.value)
    const presentationProps = {
      application,
      form,
      formContext,
      formData,
      onFormDataChange: this._onFormDataChange,
      onUserFormDataChange: this._onUserFormDataChange,
      isLoading,
      minHeight,
      hideModal: this._hideModal,
      showModalEvent: this._showModal,
      showModal,
      showModalError,
      showModalMessage,
      getAllRoles,
      access,
      clear: this._clearSearch,
      userDetails,
      userDetailViewId,
      backToUser: this._backToUser,
      updateUser: this._updateUser,
      search: this._search,
      hidePendingUserModal: this._hidePendingUserModal,
      pendingUserModal,
      selectedPUser,
      cancelPendingUser: this._cancelPendingUser,
      removePendingUser: this._removePendingUser,
      userResults,
      selectedEntitlements,
      isUserUpdating,
      transformErrors: this._transformErrors,
      errorListTemplate: this._errorListTemplate,
      liveValidate,
      onValidate: this._onValidate,
      switchOnLiveValidate: this._switchOnLiveValidate,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      orgDataList,
      currentDate: this.state.currentDate,
      addNewUser: this._addNewUser,
      showCallbackModal,
      hideCallbackModal: this._hideCallbackModal
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  let ieOrgId = _.get(state.userAccess, 'selectedOrg.ieOrgId', '')

  return {
    auth: state.auth,
    userAccess: state.userAccess,
    externalAuth: state.externalAuth,
    orgId,
    roleId,
    ieOrgId,
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    locale: state.i18n.locale,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccessContainer)
