import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import MyApplications from '../../base/MyApplications'
import SearchApplications from '../../base/SearchApplications'
import SearchPEApplications from '../../base/SearchPEApplications'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import { ENTITLEMENTS_wv as wvEnt } from '../../wv/src/entitlements_wv'

const Fragment = (React as any).Fragment

interface MyApplicationsRootProps {
  minHeight: string
  selectedEntitlements: Array<string>
}

interface MyApplicationsRootState {}

class MyApplicationsRoot extends React.Component<
  MyApplicationsRootProps,
  MyApplicationsRootState
> {
  constructor(props: MyApplicationsRootProps) {
    super(props)

    this.state = {}
  }
  render() {
    const { selectedEntitlements, minHeight } = this.props
    const presumptiveEligMgmt = _.includes(
      selectedEntitlements,
      ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
    )
    const communityPartnership = _.includes(
      selectedEntitlements,
      ENTITLEMENTS.COMMUNITY_PARTNERSHIP
    )
    const cpAdmin = _.includes(selectedEntitlements, wvEnt.CP_Admin)
    return (
      <div>
        {communityPartnership || cpAdmin ? (
          <SearchApplications minHeight={this.props.minHeight} />
        ) : (
          <Fragment>
            {presumptiveEligMgmt ? (
              <SearchPEApplications minHeight={this.props.minHeight} />
            ) : (
              <MyApplications minHeight={this.props.minHeight} />
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName)
  }
}

export default connect(mapStateToProps)(MyApplicationsRoot)
