import * as React from 'react'
import { connect } from 'react-redux'
import * as wvActions from '../../../actions'
import * as _ from 'lodash'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import MessageModal from '@optum-wvie/dynamic-ui-framework/src/components/natives/MessageModal'
import { Redirect } from 'react-router-dom'
import * as serviceHelper from '../src/components/utils'
import { config } from '../../../config'
import BenHScreenContent from './BenHScreenContent'
import ViewResults from './ViewResults'

declare var process
const baseUrl = process.env.PUBLIC_URL
const submitAnswersEndpoint =
  config['gatewayWvUrl'] + config['referralScreening']
const programList = [
  {
    id: 'LongTermCareICFIID',
    name:
      'Long Term Care: Intermediate Care Facility for Individuals with Intellectual Disabilities (ICF/IID)',
    checked: true
  },
  {
    id: 'LongTermCareCDCSP',
    name: 'Children with Disabilities Community Services Program (CDCSP)',
    checked: true
  },
  { id: 'ADW', name: 'Aged and Disabled Waiver', checked: true },
  {
    id: 'UNGIDD',
    name: 'Unmet Needs Grant for Individuals with a Developmental Disability',
    checked: true
  },
  { id: 'IDDWSG', name: 'I/DD Waiver Waitlist Support Grant', checked: true },
  { id: 'FSP', name: 'Family Support Program', checked: true },
  { id: 'BTT', name: 'Birth to Three', checked: true },
  { id: 'CSHN', name: 'Children with Special Healthcare Needs', checked: true },
  { id: 'PersonalCare', name: 'Personal Care', checked: true },
  { id: 'BCC', name: 'Breast and Cervical Cancer (BCC)', checked: true },
  { id: 'RFTS', name: 'Right from the Start (RFTS)', checked: true },
  { id: 'FPP', name: 'Family Planning Program', checked: true },
  {
    id: 'CHSP',
    name: 'Children’s Hearing Services Project (CHSP)',
    checked: true
  }
]
/**
 * function component
 */
function BenHScreenHeader() {
  return (
    <div className="row">
      <div className="headerpadding">
        <h1 className="heading print">
          <i className="myglyphicon-halflings myglyphicon-compass myaccount-icon" />{' '}
          Referral Screening
        </h1>
      </div>
    </div>
  )
}
class BenHScreen extends React.Component<any, any> {
  private readonly divContent: any
  constructor(props: any) {
    super(props)
    this.divContent = (React as any).createRef()
    this.state = {
      benefits: [],
      numBenefits: 0,
      answers: {},
      formContext: {},
      formActiveTab: 0,
      mainTab: 0,
      loaded: true,
      temp1: [],
      jsonbenefits: {},
      redirect: null,
      showExitModal: false,
      showErrorModal: false,
      eligibleBenefits: [],
      notEligibleBenefits: [],
      addEligibleAppData: null
    }
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        addEligibleAppData: this.props.location.state.addEligibleAppData
      })
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({ formContext: { goToTab: this._goToTab } })
  }

  _updateMainTab = mainTab => {
    this.setState({ mainTab })
    this.divContent.current.focus()
    if (mainTab === 1) {
      document.title = 'View Results - Referral Screening'
    }
    this.setState({ activeTab: 5 })
  }

  _goToTab = tab => {
    this.setState({ mainTab: 0, formActiveTab: tab })
  }

  _showExit = () => {
    this.setState({ showExitModal: true })
  }

  _confirmExit = () => {
    this.setState({
      showExitModal: false,
      redirect: `${baseUrl}/myaccount/eligibleApplicants`
    })
  }

  _cancelExit = () => {
    this.setState({ showExitModal: false })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _fetchEligibleBenefits = answers => {
    this.setState({ loaded: false })
    serviceHelper
      .fetchJson(
        submitAnswersEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Accept: 'application/json',
            uuid: this.props.auth.userAccount
              ? this.props.auth.userAccount.uuid || ''
              : '',
            tenantCode: config.tCode,
            Authorization: config.bearer + (this.props.auth.accessToken || ''),
            portalName: config.portalName
          },
          body: JSON.stringify(answers)
        },
        this.props.showErrorMessage
      )
      .then(response => {
        if (!response.error) {
          this._digestBenefitResults(response)
          this._updateMainTab(1)
        }
      })
      .catch(error => {
        if (error instanceof serviceHelper.UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: `${baseUrl}/home` })
        }
        console.log('Failure')
        this.setState({
          errors: true
        })
      })
  }

  _digestBenefitResults = (response): void => {
    let eligibleBenefits = []
    let notEligibleBenefits = []
    response.eligiblePrograms.forEach(str => {
      let obj = _.find(programList, function(e) {
        return e.id === str
      })
      if (obj != null) {
        eligibleBenefits.push(obj)
      }
    })
    response.notEligiblePrograms.forEach(str => {
      let obj = _.find(programList, function(e) {
        return e.id === str
      })
      if (obj != null) {
        notEligibleBenefits.push(obj)
      }
    })
    eligibleBenefits.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    )
    notEligibleBenefits.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    )
    this.setState({
      eligibleBenefits: eligibleBenefits,
      notEligibleBenefits: notEligibleBenefits,
      loaded: true
    })
  }

  _formContextUpdated = formContext => {
    this.setState({
      formContext: { ...this.state.formContext, ...formContext }
    })
  }

  _updateActiveTab = formActiveTab => {
    this.setState({ formActiveTab })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }
    const {
      benefits,
      numBenefits,
      formData,
      formContext,
      mainTab,
      loaded,
      temp1,
      jsonbenefits,
      showExitModal,
      formActiveTab,
      enableFavorites,
      notEligibleBenefits,
      eligibleBenefits,
      addEligibleAppData
    } = this.state
    let helpPopover = (
      <Popover id="helpPopover">
        <a href="/help" target="_blank">
          Help Center
        </a>
      </Popover>
    )
    let helpLink = (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={helpPopover}
      >
        <button className="print btn btn-tiny btn-white">
          <span className="fa fa-life-ring btn-icon-sm" />
          Help
        </button>
      </OverlayTrigger>
    )
    let exitButton = (
      <button className="print btn btn-tiny btn-white" onClick={this._showExit}>
        <span className="fa fa-times btn-icon-sm" />
        Exit
      </button>
    )

    return (
      <div>
        <BenHScreenHeader />
        <div id="benefits-finder-content" className="main-content">
          <div className="nav-wrapper">
            <ul className="nav nav-pills offset-lg-2">
              <li className={mainTab === 0 ? 'active' : ''}>
                <button
                  className="btnBF btn-link-bf btn-headerTab btn btn-link"
                  onClick={() => this._updateMainTab(0)}
                  aria-pressed={mainTab === 0}
                >
                  Benefits
                </button>
              </li>
              <li className={mainTab === 1 ? 'active' : ''}>
                <button
                  className="btnBF btn-link-bf btn-headerTab btn btn-link"
                  onClick={() => this._updateMainTab(1)}
                  aria-pressed={mainTab === 1}
                >
                  View Results
                </button>
              </li>
              <li className="li-btn-style">
                <div className="float-right">
                  {exitButton}
                  {helpLink}
                  <button
                    className="print btn btn-tiny btn-white"
                    onClick={() => window.print()}
                  >
                    <span className="fa fa-print fa-lg btn-icon-sm" />
                    Print
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <MessageModal
            show={showExitModal}
            confirm={this._confirmExit}
            cancel={this._cancelExit.bind(this)}
            confirmBtnText="Yes"
            closeBtnText="No"
            title={
              <span>
                <h4 className="popup-title"> Exit</h4>
              </span>
            }
            body={
              <div className="row">
                <div className="col-lg-12">
                  <h6 className="headertxt">Are you sure you want to exit?</h6>
                </div>
              </div>
            }
            bsSize={'lg'}
          />
          <div
            ref={this.divContent}
            className="tab-content clearfix accessibility-focus"
          >
            <div
              className={(mainTab === 0 ? 'active' : 'hidden') + ' tab-pane'}
            >
              <BenHScreenContent
                _fetchEligibleBenefits={this._fetchEligibleBenefits}
                benefits={benefits}
                formContext={this.state.formContext}
                numBenefits={numBenefits}
                temp1={temp1}
                _formContextUpdated={this._formContextUpdated.bind(this)}
                _updateActiveTab={this._updateActiveTab.bind(this)}
                activeTab={formActiveTab}
                tabEnabled={true}
                _updateMainTab={this._updateMainTab}
                enableFavorites={enableFavorites}
                loaded={loaded}
                mainTab={this.state.mainTab}
                onDeleteClick={this._onDeleteClick}
                {...this.props}
              />
            </div>
            <div
              className={(mainTab === 1 ? 'active' : 'hidden') + ' tab-pane'}
            >
              <ViewResults
                formContext={formContext}
                eligibleBenefits={eligibleBenefits}
                formData={formData}
                enableFavorites={enableFavorites}
                notEligibleBenefits={notEligibleBenefits}
                addEligibleAppData={addEligibleAppData}
                {...this.props}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const error = state.myMessagesError.myMessagesError.errorMessage || []
  return {
    myFavorites: state.myFavorites,
    auth: state.auth,
    roleId: _.get(state.userAccess, 'selectedUserRole.role.roleId'),
    roleName: _.get(state.userAccess, 'selectedUserRole.role.roleName'),
    errorMessage: error
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(wvActions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(wvActions.myMessagesDeleteError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BenHScreen)
