import * as React from 'react'
import { includes, get } from 'lodash'
import { connect } from 'react-redux'
import MyApplications from '../MyApplications'
import SearchApplications from '../SearchApplications'
import MyPEApplications from '../MyPEApplications'
import SearchPEApplications from '../SearchPEApplications'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'

const _ = { includes, get }

const Fragment = (React as any).Fragment

interface MyApplicationsRootProps {
  minHeight: string
  selectedEntitlements: Array<string>
}

interface MyApplicationsRootState {}

class MyApplicationsRoot extends React.Component<
  MyApplicationsRootProps,
  MyApplicationsRootState
> {
  constructor(props: MyApplicationsRootProps) {
    super(props)

    this.state = {}
  }
  render() {
    const { selectedEntitlements, minHeight } = this.props
    const presumptiveEligMgmt = _.includes(
      selectedEntitlements,
      ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
    )
    const communityPartnership = _.includes(
      selectedEntitlements,
      ENTITLEMENTS.COMMUNITY_PARTNERSHIP
    )
    return (
      <div>
        {communityPartnership ? (
          <SearchApplications minHeight={this.props.minHeight} />
        ) : (
          <Fragment>
            {presumptiveEligMgmt ? (
              <SearchPEApplications minHeight={this.props.minHeight} />
            ) : (
              <MyApplications minHeight={this.props.minHeight} />
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName)
  }
}

export default connect(mapStateToProps)(MyApplicationsRoot)
