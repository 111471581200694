import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyFormsContainer = MultitenantLoadable(
  () => import('./MyFormsContainer'),
  () => import('../../' + tenantDirectoryName + '/MyForms/MyFormsContainer')
)

const MyFormsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyFormsPresentation'),
    () =>
      import('../../' + tenantDirectoryName + '/MyForms/MyFormsPresentation')
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyForms/formComponents')
)

export default function MyForms(props) {
  return (
    <ErrorBoundary name="MyForms">
      <MyFormsContainer
        {...props}
        presentation={presentationProps => (
          <MyFormsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyFormsContainer = MultitenantLoadable(
  () => import('./MyFormsContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyFormsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyFormsPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyForms(props) {
  return (
    <BaseErrorBoundary name="MyForms">
      <BaseMyFormsContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyFormsPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
