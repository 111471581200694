import * as React from 'react'
import * as helper from '../src/components/utils'
import { fetchJson } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash' // extra functionality
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
const moment = require('moment-timezone')
import * as actions from '../../../actions'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError } from '../src/components/utils'

const gatewayWvUrl = config['gatewayWvUrl']
const gatewayWvSharedUrl = config['gatewayWvSharedUrl']
const formsEndPoint = config['ChildSupportPayments']
const paymentsSentEndPoint = gatewayWvSharedUrl + config['paymentsSent']
const paymentsReceivedEndPoint = gatewayWvSharedUrl + config['paymentsReceived']
const getCaseNumbers = gatewayWvUrl + '/WVIE/coc/v2.0/getCaseNumbers'
const baseUrl = process.env.PUBLIC_URL

class ChildSupportPaymentsContainer extends React.Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = {}
    this.onFormDataChange = this.onFormDataChange.bind(this)
    this.search = this.search.bind(this)
    this.clear = this.clear.bind(this)
  }

  _loadCaseNumbers = () => {
    this.setState({
      isLoading: true
    })
    serviceHelper
      .fetchJson(
        getCaseNumbers,
        {
          method: 'GET',
          headers: {
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            'Content-Type': 'application/json',
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage,
        0,
        60000
      )
      .then(res => {
        const caseDetails = _.filter(res.caseList, c => {
          return c.programType === 'CS'
        })
        this.setState(
          {
            caseDetails,
            caseNumbers: _.map(caseDetails, c => c.caseNumber) || [],
            isLoading: false
          },
          () => {
            this._loadForms()
          }
        )
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
      })
  }

  componentDidMount() {
    document.title = 'Child Support Payments - My Account'
    this._loadCaseNumbers()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      this._loadForms()
    }
  }

  componentWillUnmount() {
    this.props.deleteErrorMessage()
  }

  _loadForms = () => {
    this.setState({
      isLoading: true
    })
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    fetchJson(formsEndPoint.replace('{version}', '1.0'), request)
      .then(forms => {
        _.set(
          forms,
          'app.forms[0].schema.properties.searchCriteria.properties.caseNumber.enum',
          this.state.caseNumbers
        )
        _.set(
          forms,
          'app.forms[0].schema.properties.searchCriteria.properties.caseNumber.enumNames',
          this.state.caseNumbers
        )
        this.setState(
          {
            forms,
            formContext: {
              refs: {
                '{case Numbers}': () => {
                  return this.state.caseNumbers
                }
              },
              formData: {
                searchCriteria: {
                  caseNumber: this.state.caseNumbers[0],
                  payments: '1',
                  view: 180
                },
                paymentsSent: [],
                paymentsReceived: []
              },
              search: this.search,
              clear: this.clear,
              view: 'sent'
            },
            formData: {
              searchCriteria: {
                caseNumber: this.state.caseNumbers[0],
                payments: '1',
                view: 180
              },
              paymentsSent: [],
              paymentsReceived: []
            },
            isLoading: false
          },
          () => {
            this._loadPaymentsSent()
            this._loadPaymentsReceived()
          }
        )
      })
      .catch(error => {
        console.error('MyMessages form fetch failed due to ex', error)
      })
  }

  _loadPaymentsSent = () => {
    const caseNumber = this.state.formData.searchCriteria.caseNumber
    const currentCase = _.find(this.state.caseDetails, c => {
      return c.caseNumber === caseNumber
    })
    this.setState({
      isLoading: true
    })
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      },
      body: JSON.stringify({
        caseId: this.state.formData.searchCriteria.caseNumber,
        role: currentCase.role,
        caseTypeCode: currentCase.caseTypeCode,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(this.state.formData.searchCriteria.view, 'days')
          .format('YYYY-MM-DD')
      })
    }
    fetchJson(paymentsSentEndPoint, request)
      .then(response => {
        let totalFilteredSent = 0
        let totalSent = 0
        _.forEach(response.rcptList, p => {
          totalSent = totalSent + p.amount
        })
        response = _.filter(response.rcptList, p => {
          return (
            (this.state.formData.searchCriteria.amount &&
              p.amount === +this.state.formData.searchCriteria.amount) ||
            !this.state.formData.searchCriteria.amount
          )
        })

        const paymentsSent = _.map(response, p => {
          totalFilteredSent = totalFilteredSent + p.amount
          return {
            allocationDate: moment(p.allocationDate).format('MM/DD/YYYY'),
            amount: '$' + p.amount ,
            caseNumber: this.state.formData.searchCriteria.caseNumber
          }
        })
        const formData = {
          ...this.state.formData,
          paymentsSent: paymentsSent
        }
        this.setState({
          formData,
          formContext: {
            ...this.state.formContext,
            formData,
            totalSent,
            totalFilteredSent
          },
          isLoading: false
        })
      })
      .catch(error => {
        console.error('MyMessages form fetch failed due to ex', error)
      })
  }

  _loadPaymentsReceived = () => {
    const caseNumber = this.state.formData.searchCriteria.caseNumber
    const currentCase = _.find(this.state.caseDetails, c => {
      return c.caseNumber === caseNumber
    })
    this.setState({
      isLoading: true
    })
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      },
      body: JSON.stringify({
        caseId: this.state.formData.searchCriteria.caseNumber,
        role: currentCase.role,
        caseTypeCode: currentCase.caseTypeCode,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(this.state.formData.searchCriteria.view, 'days')
          .format('YYYY-MM-DD')
      })
    }
    fetchJson(paymentsReceivedEndPoint, request)
      .then(response => {
        let totalFilteredReceived = 0
        let totalReceived = 0
        _.forEach(response.disbList, p => {
          totalReceived = totalReceived + p.amount
        })
        response = _.filter(response.disbList, p => {
          return (
            (this.state.formData.searchCriteria.amount &&
              p.amount === +this.state.formData.searchCriteria.amount) ||
            !this.state.formData.searchCriteria.amount
          )
        })
        const paymentsReceived = _.map(response, p => {
          totalFilteredReceived = totalFilteredReceived + p.amount
          return {
            checkDate: moment(p.checkDate).format('MM/DD/YYYY'),
            amount: '$' + p.amount ,
            caseNumber: this.state.formData.searchCriteria.caseNumber
          }
        })
        const formData = {
          ...this.state.formData,
          paymentsReceived: paymentsReceived
        }
        this.setState({
          formData,
          formContext: {
            ...this.state.formContext,
            formData,
            totalReceived,
            totalFilteredReceived
          },
          isLoading: false
        })
      })
      .catch(error => {
        console.error('MyMessages form fetch failed due to exception', error)
      })
  }

  onFormDataChange = ({ formData }) => {
    this.setState({
      formData,
      formContext: {
        ...this.state.formContext,
        formData
      }
    })
  }

  search = () => {
    if (this.state.formData.searchCriteria.payments === '1') {
      this.setState(
        {
          formContext: {
            ...this.state.formContext,
            view: 'sent'
          }
        },
        () => {
          this._loadPaymentsSent()
        }
      )
    } else {
      this.setState(
        {
          formContext: {
            ...this.state.formContext,
            view: 'received'
          }
        },
        () => {
          this._loadPaymentsReceived()
        }
      )
    }
  }
  clear = () => {
    if (this.state.formData.searchCriteria.payments === '1') {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            searchCriteria: {
              ...this.state.formData.searchCriteria,
              amount: undefined,
              view: 180
            }
          },
          formContext: {
            ...this.state.formContext,
            view: 'sent',
            formData: {
              ...this.state.formData,
              searchCriteria: {
                ...this.state.formData.searchCriteria,
                amount: undefined,
                view: 180
              }
            }
          }
        },
        () => {
          this._loadPaymentsSent()
        }
      )
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            searchCriteria: {
              ...this.state.formData.searchCriteria,
              amount: undefined,
              view: 180
            }
          },
          formContext: {
            ...this.state.formContext,
            view: 'received',
            formData: {
              ...this.state.formData,
              searchCriteria: {
                ...this.state.formData.searchCriteria,
                amount: undefined,
                view: 180
              }
            }
          }
        },
        () => {
          this._loadPaymentsReceived()
        }
      )
    }
  }
  render() {
    const { presentation, errorMessage } = this.props
    const { formContext, forms, formData, isLoading } = this.state

    const presentationProps = {
      errorMessage,
      forms,
      formContext,
      formData,
      isLoading,
      onFormDataChange: this.onFormDataChange
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let userType =
    _.get(state, 'userAccess.userRoles[0].role.roleDescription') || ''
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []

  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''

  return {
    auth: state.auth,
    userRoleId,
    userType,
    errorMessage,
    roleId,
    orgId,
    locale: state.i18n.locale
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChildSupportPaymentsContainer)