import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { I18n } from 'react-redux-i18n'

export default class ApplicantProviderTable extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      value: props.value,
      formContext: props.formContext
    }
  }

  render() {
    const index = this.props.id
    let width = 100
    let applicantAndProviderMappings = this.props.formData
    if (applicantAndProviderMappings) {
      return (
        <div>
          {width > 0 ? (
            <div>
              {applicantAndProviderMappings &&
                applicantAndProviderMappings.map((element, index) => {
                  return (
                    <div>
                      <div className="col-xs-12 provider-mapping-headerRow">
                        {element.applicantName}{' '}
                      </div>
                      {element.providers &&
                        element.providers.map((provider, index) => {
                          helper.cleanNullValues(element.providers)
                          let serviceType = provider.serviceType
                            ? provider.serviceType
                            : provider.ADR_LN_1_TXT
                          return (
                            <div className="col-xs-12 border-1px">
                              <div className="col-xs-12 ">
                                <a
                                  href="#"
                                  onClick={e =>
                                    this.openDialog(
                                      provider,
                                      element.caseId,
                                      element.applicantId,
                                      element.benefitCd
                                    )
                                  }
                                >
                                  {provider.name}
                                </a>{' '}
                              </div>
                              <div className="col-xs-12">{serviceType}</div>
                              <div className="col-xs-12">{provider.phone} </div>
                            </div>
                          )
                        })}
                    </div>
                  )
                })}
            </div>
          ) : (
            <div className="col-xs-12">
              {I18n.t('MyProvidersCCA.applicationProviderTable.noRecords')}{' '}
            </div>
          )}
        </div>
      )
    } else {
      return <div />
    }
  }
  openDialog = (providerInfo, caseId, clientId, benefitCd) => {
    if (this.props.formContext.component.initModelWindow) {
      this.props.formContext.component.initModelWindow(
        providerInfo,
        caseId,
        clientId,
        benefitCd
      )
    }
  }
}
