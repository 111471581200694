import * as React from 'react'
import { Link } from 'react-router-dom'
import { Popover } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import RolesSubMenu from './RolesSubMenu'
import { get } from 'lodash'

const _ = { get }

const baseUrl = process.env.PUBLIC_URL

export default class AccountMenu extends React.Component<any, any> {
  private readonly accountMenuRef: React.RefObject<any>
  private readonly rolesMenuRef: React.RefObject<any>
  constructor(props) {
    super(props)
    this.state = {
      showAccountOverlay: false,
      showRolesOverlay: false
    }

    this.accountMenuRef = React.createRef()
    this.rolesMenuRef = React.createRef()
  }

  componentDidMount() {
    window.document.addEventListener('keydown', this.handleEscPress)
    window.document.addEventListener('click', this.handleFocusClick)
  }

  componentWillUnmount() {
    window.document.removeEventListener('keydown', this.handleEscPress)
    window.document.removeEventListener('click', this.handleFocusClick)
  }

  handleEscPress = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return
    }

    const key = event.key || event.keyCode
    if (key === 'Escape' || key === 'Esc' || key === 27) {
      this.closeAndSetParentFocus()
    }
  }

  handleFocusClick = (event: MouseEvent) => {
    if (event.defaultPrevented) return

    const target: any = event.target
    const parent = target.parentNode ? target.parentNode.parentNode : ''
    const headerBtn = document.getElementById('Header_account_link')

    if (
      parent !== this.rolesMenuRef.current &&
      parent !== this.accountMenuRef.current &&
      target !== headerBtn
    ) {
      this.setState({ showAccountOverlay: false, showRolesOverlay: false })
    }
  }

  clickRole = userRole => {
    this.props.setSelectedUserRole(userRole)
    this.closeAndSetParentFocus()
  }

  clickOrg = orgObj => {
    this.props.setSelectedOrg(orgObj)
    this.closeAndSetParentFocus()
  }

  closeAndSetParentFocus = () => {
    this.setState({
      showAccountOverlay: false,
      showRolesOverlay: false
    })
    window.document.getElementById(this.props.parent).focus()
  }

  toggleAccountMenu = () => {
    const { showAccountOverlay } = this.state

    this.setState({
      showAccountOverlay: !showAccountOverlay,
      showRolesOverlay: false
    })
  }

  moveFocusToRolesMenu = () => {
    this.setState({ moveFocusToRolesMenu: true })
  }

  render() {
    const { userAccount, userAccess, locale, logoutUser } = this.props
    const { showAccountOverlay, showRolesOverlay } = this.state

    const hasMultipleRoles =
      (userAccess.userRoles || []).length > 1 ||
      ((userAccess.userRoles || []).length === 1 &&
        userAccess.userRoles[0].orgList.length > 1)
    let multilingualDisplayName = _.get(
      userAccess,
      'selectedUserRole.role.roleName',
      null
    )
    if (multilingualDisplayName === 'Community Partner') {
      multilingualDisplayName = _.get(userAccess, 'selectedOrg.roleName', null)
    } else if (multilingualDisplayName === 'Presumptive Eligibility Worker') {
      multilingualDisplayName = _.get(userAccess, 'selectedOrg.roleName', null)
    }
    if (multilingualDisplayName === 'Community Partner') {
      multilingualDisplayName = 'CP'
    } else if (multilingualDisplayName === 'Presumptive Eligibility Worker') {
      multilingualDisplayName = 'PE'
    }

    const orgName = _.get(userAccess, 'selectedOrg.orgName', null)
    let roleText = ''
    if (
      multilingualDisplayName &&
      multilingualDisplayName.toLowerCase() !== 'Client' &&
      multilingualDisplayName.toLowerCase() !== 'Worker Admin' &&
      orgName
    ) {
      roleText = ' - ' + multilingualDisplayName + ' - ' + orgName
    } else if (
      multilingualDisplayName &&
      multilingualDisplayName === 'Worker Admin'
    ) {
      roleText = ' - ' + multilingualDisplayName
    }

    return (
      <>
        <div className="nav navbar-nav head-style IconAlign">
          <div>
            <button
              id="Header_account_link"
              className="account-button sign-in-font"
              onClick={() => {
                this.toggleAccountMenu()
              }}
              aria-expanded={showAccountOverlay}
            >
              <span className="sr-only">{`${I18n.t(
                'Header.role'
              )}: ${multilingualDisplayName}`}</span>
              {` ${userAccount.firstName}${roleText} `}
            </button>
            {showAccountOverlay && (
              <Popover
                id="accountPopover"
                placement="bottom"
                className="accountpopover-style accountPop"
              >
                <ul
                  className="popover-menu-list"
                  id="account-menu"
                  ref={this.accountMenuRef}
                  onBlur={e => {
                    const rolesList = document.getElementById('roles-menu-list')
                    const target: any =
                      e.relatedTarget || document.activeElement
                    const parentTarget: any = target.parentElement.parentElement

                    if (
                      !parentTarget ||
                      (parentTarget !== rolesList &&
                        parentTarget !== this.accountMenuRef.current)
                    ) {
                      this.setState({
                        showAccountOverlay: false,
                        showRolesOverlay: false
                      })
                    }
                  }}
                >
                  {hasMultipleRoles && (
                    <li>
                      <RolesSubMenu
                        userAccess={userAccess}
                        setSelectedUserRole={this.clickRole}
                        setSelectedOrg={this.clickOrg}
                        defaultUserRoleId={userAccount.defaultUserRoleId}
                        locale={locale}
                        showRolesOverlay={showRolesOverlay}
                        onShowRolesOverlay={() =>
                          this.setState({
                            showRolesOverlay: true,
                            moveFocusToRolesMenu: true
                          })
                        }
                        onHideRolesOverlay={() =>
                          this.setState({ showRolesOverlay: false })
                        }
                        onHideAccountOverlay={() =>
                          this.setState({
                            showAccountOverlay: false,
                            showRolesOverlay: false
                          })
                        }
                        moveFocusToRolesMenu={this.moveFocusToRolesMenu}
                        rolesMenuRef={this.rolesMenuRef}
                      />
                    </li>
                  )}
                  <li>
                    <Link
                      onClick={this.closeAndSetParentFocus}
                      to={`${baseUrl}/myaccount`}
                      className="rolefocus"
                    >
                      <i className="myglyphicon-user" />
                      {I18n.t('Header.myAccountLink')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${baseUrl}/home`}
                      onClick={() => {
                        logoutUser(
                          this.props.userAccount,
                          this.props.auth.accessToken
                        )
                      }}
                      className="rolefocus"
                    >
                      <i className="myglyphicon-log_out" />
                      {I18n.t('Header.signOutLink')}
                    </Link>
                  </li>
                </ul>
              </Popover>
            )}
          </div>
        </div>
      </>
    )
  }
}
