import * as React from 'react'
import { Tabs, Tab, Row, Col, Nav, NavItem } from 'react-bootstrap'
import { isEmpty, get } from 'lodash'
import { scrollToTop } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { Localize, I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

const _ = { isEmpty, get }

function BenefitCatalogHeader(props) {
  return (
    <div className="row">
      <div className="headerpadding">
        <p className="benefit-header1">
          <span className="myglyphicon-book_open glyphicon benefitfinderglyphlogo" />
          {I18n.t('BenefitsCatalog.title')}
        </p>
      </div>
    </div>
  )
}

function ToggleFavoriteButton(props) {
  const myFavorites = props.myFavorites
  if (myFavorites && myFavorites.indexOf(props.keyVal) > -1) {
    return (
      <button
        type="button"
        className="btn link my-benefit active"
        onClick={() => {
          props.removeMyFavorite(props.keyVal)
        }}
      >
        {I18n.t('MyFavorites.removeFavorite')}
      </button>
    )
  } else {
    return (
      <button
        type="button"
        className="btn link my-benefit"
        onClick={() => {
          props.addMyFavorite(props.keyVal)
        }}
      >
        {I18n.t('MyFavorites.addFavorite')}
      </button>
    )
  }
}

function BenefitsCatalogContent(props: BenefitCatalogPresentationProps) {
  const {
    myFavorites,
    addMyFavorite,
    removeMyFavorite,
    tabKey,
    handleSelect,
    locale,
    mainContent
  } = props

  return (
    <div>
      <Helmet>
        <title>{I18n.t('BenefitsCatalog.title')}</title>
      </Helmet>
      <h1>{I18n.t('BenefitsCatalog.benefits')}</h1>
      <Tab.Container
        id="benefits-catalog-tabs"
        activeKey={tabKey}
        onSelect={handleSelect}
      >
        <Row className="clearfix">
          <Col sm={3} id="side-nav">
            <Nav variant="pills" className="flex-column">
              <NavItem>
                <Nav.Link eventKey="health-wellness">
                  <span className="glyphicon myglyphicon-heart" />
                  {I18n.t('General.benefitCategories.HEWL.name')}
                </Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link eventKey="family-assistance">
                  <span className="glyphicon myglyphicon-parents" />
                  {I18n.t('General.benefitCategories.FMAS.name')}
                </Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link eventKey="food-nutrition">
                  <span className="glyphicon myglyphicon-apple" />
                  {I18n.t('General.benefitCategories.FDNT.name')}
                </Nav.Link>
              </NavItem>
            </Nav>
          </Col>
          <Col sm={9}>
            <div id="content" tabIndex={-1} ref={mainContent}>
              <Tab.Content className="benefits-catalog-content col-xs-12 no-gutter">
                <Tab.Pane eventKey="health-wellness" className="row">
                  <div className="col-xs-12">
                    <h2>{I18n.t('General.benefitCategories.HEWL.name')}</h2>
                    <p>
                      {I18n.t('General.benefitCategories.HEWL.description')}
                    </p>
                  </div>
                  <hr className="col-xs-12" />
                  <div className="col-xs-12">
                    <ToggleFavoriteButton {...props} keyVal="Medicaid" />
                    <button
                      type="button"
                      className="btn link scroll-top"
                      onClick={() => {
                        scrollToTop
                        mainContent.current.focus()
                      }}
                    >
                      {I18n.t('General.backToTop')}
                    </button>
                  </div>
                  <hr className="col-xs-12" />
                  <div className="col-xs-12">
                    <ToggleFavoriteButton {...props} keyVal="QHP" />
                    <button
                      type="button"
                      className="btn link scroll-top"
                      onClick={() => {
                        scrollToTop
                        mainContent.current.focus()
                      }}
                    >
                      {I18n.t('General.backToTop')}
                    </button>
                  </div>
                  <hr className="col-xs-12" />
                  <div className="col-xs-12">
                    <ToggleFavoriteButton {...props} keyVal="CHIP" />
                    <button
                      type="button"
                      className="btn link scroll-top"
                      onClick={() => {
                        scrollToTop
                        mainContent.current.focus()
                      }}
                    >
                      {I18n.t('General.backToTop')}
                    </button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="family-assistance" className="row">
                  <div className="col-xs-12">
                    <h2>{I18n.t('General.benefitCategories.FMAS.name')}</h2>
                    <p>
                      {I18n.t('General.benefitCategories.FMAS.description')}
                    </p>
                  </div>
                  <hr className="col-xs-12" />
                  <div className="col-xs-12">
                    <ToggleFavoriteButton {...props} keyVal="TANF" />
                    <button
                      type="button"
                      className="btn link scroll-top"
                      onClick={() => {
                        scrollToTop
                        mainContent.current.focus()
                      }}
                    >
                      {I18n.t('General.backToTop')}
                    </button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="food-nutrition" className="row">
                  <div className="col-xs-12">
                    <h2>{I18n.t('General.benefitCategories.FDNT.name')}</h2>
                    <p>
                      {I18n.t('General.benefitCategories.FDNT.description')}
                    </p>
                  </div>
                  <hr className="col-xs-12" />
                  <div className="col-xs-12">
                    <ToggleFavoriteButton {...props} keyVal="SNAP" />
                    <button
                      type="button"
                      className="btn link scroll-top"
                      onClick={() => {
                        scrollToTop
                        mainContent.current.focus()
                      }}
                    >
                      {I18n.t('General.backToTop')}
                    </button>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  )
}

export default function BenefitsCatalogPresentation(
  props: BenefitCatalogPresentationProps
) {
  return (
    <div className="benefits-catalog">
      <BenefitCatalogHeader />
      <Tabs id="benefits-catalog-nav-tabs" defaultActiveKey="0">
        <Tab eventKey="0" title={I18n.t('BenefitsCatalog.benefits')}>
          <BenefitsCatalogContent {...props} />
        </Tab>
        <Tab eventKey="1" title={I18n.t('MyFavorites.title')}>
        </Tab>
      </Tabs>
    </div>
  )
}
