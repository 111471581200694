import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { Modal } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { get } from 'lodash'

interface MyDocumentsAccordionPresentationProps {
  showModal: boolean
  formData: any
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formContext: any
  loaded: boolean
  onSubmit: (formData: any) => void
  onFormDataChange: (formData: any) => void
  appIntake: string
  open: () => void
  showDeleteModal: boolean
  closeDeleteModal: () => void
  close: () => void
  onDeleteClick: () => void
  minHeight: string
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  locale: string
  uploadedDocsData: Array<number>
  onUploadedDocsChange: () => void
}

export default function MyDocumentsAccordionPresentation(
  props: MyDocumentsAccordionPresentationProps
) {
  const {
    showModal,
    formData,
    formSchema,
    formContext,
    loaded,
    onSubmit,
    onFormDataChange,
    appIntake,
    open,
    showDeleteModal,
    closeDeleteModal,
    close,
    onDeleteClick,
    minHeight,
    formComponents,
    locale,
    uploadedDocsData,
    onUploadedDocsChange
  } = props
  if (formSchema != null && formData != null && loaded) {
    let modalBody = (
      <span
        style={{ paddingLeft: '10px;', fontSize: '16px;', lineHeight: '15px;' }}
      >
        {I18n.t('MyDocuments.noDrafts')}
      </span>
    )
    if ((get(formContext, 'modalData.docUploadIds') || []).length > 0) {
      modalBody = (
        <Form
          uiSchema={formSchema['forms'][1]['uiSchema']}
          schema={formSchema['forms'][1]['schema']}
          formContext={formContext}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          formData={uploadedDocsData}
          onChange={onUploadedDocsChange}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          onSubmit={onSubmit}
          noValidate={true}
          locale={locale}
        >
          <button type="submit" className="btn">
            {I18n.t('General.submitButton')}
          </button>
        </Form>
      )
    }
    return (
      <>
        <Form
          uiSchema={formSchema['forms'][0]['uiSchema']}
          schema={formSchema['forms'][0]['schema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          enctype="multipart/form-data"
          locale={locale}
        >
          <button type="submit" className="hidden" />
        </Form>
        {!appIntake ? (
          <button type="button" className="btn btn-default" onClick={open}>
            {I18n.t('General.reviewAndSubmitButton')}
          </button>
        ) : null}
        <Modal show={showModal} onHide={close}>
          <Modal.Header closeButton>
            <Modal.Title>{I18n.t('General.reviewAndSubmitTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="headertxt">
              {I18n.t('MyDocuments.modalBody')}
              <span style={{ color: 'red' }}>(30days)</span>.{' '}
            </span>
            {modalBody}
          </Modal.Body>
        </Modal>
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>{I18n.t('General.deleteButton')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="headertxt">
              {I18n.t('MyDocuments.areYouSureDeleteImage')}
            </span>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn" onClick={onDeleteClick}>
              {I18n.t('General.yesButton')}
            </button>
            <button type="button" className="btn" onClick={closeDeleteModal}>
              {I18n.t('General.noButton')}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
  return (
    <div>
      <ScreenPreLoader loaded={loaded} minHeight={minHeight} />
    </div>
  )
}
