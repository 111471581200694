import * as React from 'react'
import MyAppeal from '../MyAppeal'
import MyApplicationsRoot from '../MyApplicationsRoot'
import MyProfile from '../MyProfile'
import MyTasks from '../MyTasks'
import MyBenefits from '../MyBenefits'
import MyMessages from '../MyMessages'
import MyLifeEvents from '../MyLifeEvents'
import MyPermission from '../MyPermission'
import MyProviders from '../MyProviders'
import MyProvidersNew from '../MyProvidersNew'
import MyDocuments from '../MyDocuments'
import MyDashboard from '../MyDashboard'
import { ENTITLEMENTS as ent } from '@optum-wvie/dynamic-ui-framework/src/entitlements'

export const MyAccountTabs = [
  {
    id: 'profile',
    clientTitle: 'MyAccount.myProfile',
    otherTitle: 'MyAccount.profile',
    leftIcon: 'pe-7s-user',
    component: MyProfile,
    entitlements: [ent.CP_VIEW_PROFILE],
    href: '/myaccount/profile'
  },
  {
    id: 'permissions',
    clientTitle: 'MyAccount.myPermissions',
    otherTitle: 'MyAccount.permissions',
    leftIcon: 'pe-7s-key',
    component: MyPermission,
    entitlements: [ent.CP_VIEW_PERMISSIONS],
    href: '/myaccount/permissions'
  },
  {
    id: 'applications',
    clientTitle: 'MyAccount.myApplications',
    otherTitle: 'MyAccount.applications',
    leftIcon: 'pe-7s-note2',
    component: MyApplicationsRoot,
    entitlements: [ent.CP_VIEW_APPLICATIONS],
    href: '/myaccount/applications'
  },
  {
    id: 'benefits',
    clientTitle: 'MyAccount.myBenefits',
    otherTitle: 'MyAccount.benefits',
    leftIcon: 'pe-7s-gift',
    component: MyBenefits,
    entitlements: [ent.CP_VIEW_BENEFITS],
    href: '/myaccount/benefits',
    caseCheck: ['fa', 'cca']
  },
  {
    id: 'tasks',
    clientTitle: 'MyAccount.myTasks',
    otherTitle: 'MyAccount.tasks',
    leftIcon: 'pe-7s-note',
    component: MyTasks,
    entitlements: [ent.CP_VIEW_TASKS],
    href: '/myaccount/tasks'
  },
  {
    id: 'appeals',
    clientTitle: 'MyAccount.myAppeals',
    otherTitle: 'MyAccount.appeals',
    leftIcon: 'pe-7s-speaker',
    component: MyAppeal,
    entitlements: [ent.CP_VIEW_APPEALS],
    href: '/myaccount/appeals',
    caseCheck: ['cca', 'fa']
  },
  {
    id: 'messages',
    clientTitle: 'MyAccount.myMessages',
    otherTitle: 'MyAccount.messages',
    leftIcon: 'pe-7s-mail',
    component: MyMessages,
    entitlements: [ent.CP_VIEW_MESSAGES],
    href: '/myaccount/messages'
  },
  {
    id: 'providers',
    clientTitle: 'MyAccount.myProviders',
    otherTitle: 'MyAccount.providers',
    leftIcon: 'pe-7s-users',
    component: MyProviders,
    entitlements: [ent.CP_VIEW_PROVIDERS],
    href: '/myaccount/providers'
  },

  {
    id: 'providersNew',
    clientTitle: 'MyAccount.myProvidersCCA',
    otherTitle: 'MyAccount.providersCCA',
    leftIcon: 'glyphicon myglyphicon-group',
    component: MyProvidersNew,
    entitlements: [ent.CP_VIEW_PROVIDERS_CCA],
    href: '/myaccount/providersNew'
  },
  {
    id: 'documents',
    clientTitle: 'MyAccount.myDocuments',
    otherTitle: 'MyAccount.documents',
    leftIcon: 'pe-7s-copy-file',
    component: MyDocuments,
    entitlements: [ent.CP_VIEW_DOCUMENTS],
    href: '/myaccount/documents'
  },
  {
    id: 'lifeevents',
    clientTitle: 'MyAccount.myLifeEvents',
    otherTitle: 'MyAccount.lifeEvents',
    leftIcon: 'pe-7s-compass',
    component: MyLifeEvents,
    entitlements: [ent.CP_VIEW_LIFEEVENTS],
    href: '/myaccount/lifeevents',
    caseCheck: ['fa', 'cca']
  },
  {
    id: 'dashboard',
    clientTitle: 'MyAccount.myDashboard',
    otherTitle: 'MyAccount.dashboard',
    leftIcon: 'glyphicon myglyphicon-dashboard',
    component: MyDashboard,
    entitlements: [ent.CP_VIEW_DASHBOARD],
    href: '/myaccount/dashboard'
  }
]
