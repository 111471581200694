import * as React from 'react'
import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const SelectRoleContainer = MultitenantLoadable(
  () => import('./SelectRoleContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/SelectRole/SelectRoleContainer')
)

const SelectRolePresentation = MultitenantLoadable(
  () => import('./SelectRolePresentation'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/SelectRole/SelectRolePresentation'
    )
)

export default function SelectRole(props) {
  return (
    <ErrorBoundary name="SelectRole">
      <SelectRoleContainer
        {...props}
        presentation={presentationProps => (
          <SelectRolePresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseSelectRoleContainer = MultitenantLoadable(
  () => import('./SelectRoleContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseSelectRolePresentation = MultitenantLoadable(
  () => import('./SelectRolePresentation'),
  () => Promise.resolve().then(() => null)
)

export function BaseSelectRole(props) {
  return (
    <BaseErrorBoundary name="SelectRole">
      <BaseSelectRoleContainer
        {...props}
        presentation={presentationProps => (
          <BaseSelectRolePresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
