import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import { Link } from 'react-router-dom'
import * as _ from 'lodash'

declare const process
const baseUrl = process.env.PUBLIC_URL
const Fragment = (React as any).Fragment

function FootNoteWidget(props) {
  return (
    <p>
      *If you have a Qualified Health Plan, you should also report your changes
      to the Federal Market Place.
    </p>
  )
}

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  panelGroup: Fields.CollapsiblePanelGroupField,
  tabs: Fields.TabsField,
  carousel: Fields.CarouselField,
  panel: Fields.PanelField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  radio: Widgets.CustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  checkBox: Widgets.CustomCheckboxWidget,
  checkboxes: Widgets.CustomCheckboxesWidget,
  'alt-date': Widgets.DatePickerWidget,
  review: Widgets.ReviewWidget,
  dollar: Widgets.DollarWidget,
  span: Widgets.SpanWidget,
  routeLink: Widgets.LinkWidget,
  footNote: FootNoteWidget,
  linkButton: Widgets.LinkButtonWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
