import * as React from 'react'
import { connect } from 'react-redux'
import {
  forEach,
  isEmpty,
  includes,
  some,
  each,
  sortBy,
  findIndex,
  isNil,
  get,
  isEqual
} from 'lodash'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'

const _ = {
  forEach,
  isEmpty,
  includes,
  some,
  each,
  sortBy,
  findIndex,
  isNil,
  get,
  isEqual
}

declare var process
const baseUrl = process.env.PUBLIC_URL

const navigationTabs = (myAccountTabs, entitlements, externalAuth) => {
  let tabs = []

  //Filter tabs by entitlement.
  _.forEach(myAccountTabs, tab => {
    if (
      _.isEmpty(tab.entitlements) ||
      _.some(tab.entitlements, tabEntitlement => {
        return _.includes(entitlements, tabEntitlement)
      })
    ) {
      tabs.push(tab)
    }
  })

  //Filter tabs if we need them to enter a PIN.
  if (
    _.get(config.tenant, 'externalAuth.checkHasPin', false) &&
    !externalAuth.hasPin
  ) {
    tabs = tabs.filter(tab => {
      switch (tab.id) {
        case 'profile':
        case 'applications':
        case 'documents':
        case 'permissions':
          return true
        default:
          return false
      }
    })
  }

  return sortTabs(tabs, entitlements)
}

const sortTabs = (tabs, entitlements) => {
  //Not sure if this should be moved to presentation.
  let sortedTabs = [...tabs]
  _.each(entitlements, entitlement => {
    switch (entitlement) {
      case ENTITLEMENTS.CLIENT_PARTICIPATION:
        sortedTabs = sortTabsForClient(sortedTabs)
        return false
      case ENTITLEMENTS.COMMUNITY_PARTNERSHIP:
        sortedTabs = sortTabsForCommunityPartner(sortedTabs)
        return false
      case ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT:
        sortedTabs = sortTabsForPEWorker(sortedTabs)
        return false
    }
  })

  return sortedTabs
}

const sortTabsForCommunityPartner = tabs => {
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'dashboard':
          return 1
        case 'applications':
          return 2
        case 'renewal':
          return 3
        case 'documents':
          return 4
        case 'quickreports':
          return 5
        case 'profile':
          return 6
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

const sortTabsForClient = tabs => {
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'profile':
          return 1
        case 'permissions':
          return 2
        case 'applications':
          return 3
        case 'benefits':
          return 4
        case 'tasks':
          return 5
        case 'appeals':
          return 6
        case 'messages':
          return 7
        case 'providers':
          return 8
        case 'providersNew':
          return 9
        case 'documents':
          return 10
        case 'lifeevents':
          return 11
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

const sortTabsForPEWorker = tabs => {
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'dashboard':
          return 1
        case 'applications':
          return 2
        case 'documents':
          return 3
        case 'quickreports':
          return 4
        case 'profile':
          return 5
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

class MyAccountContainer extends React.Component<
  MyAccountContainerProps,
  MyAccountContainerState
> {
  private navDiv: HTMLDivElement

  constructor(props: MyAccountContainerProps) {
    super(props)
    this.navDiv = (React as any).createRef()

    this.state = {
      id: '',
      minHeight: 0,
      navTabs: null,
      prevProps: null
    }
  }

  static getDerivedStateFromProps(
    props: MyAccountContainerProps,
    state: MyAccountContainerState
  ) {
    if (
      !state.prevProps ||
      props.myAccountTabs != state.prevProps.myAccountTabs ||
      props.selectedEntitlements != state.prevProps.selectedEntitlements ||
      props.externalAuth != state.prevProps.externalAuth
    ) {
      if (!props.myAccountTabs || props.myAccountTabs.length === 0) {
        return null
      }
      const navTabs = navigationTabs(
        props.myAccountTabs,
        props.selectedEntitlements,
        props.externalAuth
      )

      let newId = state.id

      if (navTabs && navTabs.length > 0) {
        if (!newId || newId === '') {
          const subPath = window.location.href.match(
            '\\/myaccount\\/([a-zA-Z0-9]+)'
          )
          if (subPath && subPath[1]) {
            const tabIndex = _.findIndex(navTabs, navTab => {
              return subPath[1] === navTab.id
            })
            if (tabIndex > -1) {
              newId = navTabs[tabIndex].id
            } else {
              newId = navTabs[0].id
            }
          } else {
            //TODO: Should the first tab always be the default/fallback?
            newId = navTabs[0].id
          }
        } else {
          const tabIndex = _.findIndex(navTabs, navTab => {
            return newId === navTab.id
          })
          if (tabIndex === -1) {
            newId = navTabs[0].id
          }
        }
      } else {
        newId = ''
      }
      return {
        navTabs,
        id: newId,
        prevProps: props
      }
    } else {
      return null
    }
  }

  componentDidMount() {
    let sideNavLink = this._createSkipLink(
      '#side-nav',
      'skip-to-side-nav',
      I18n.t('MyAccount.skipToSideNavigation')
    )
    const navContent = document.getElementById('nav-content')
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      navContent.insertBefore(sideNavLink, mainNavContent)
    }
    if (this.navDiv && this.navDiv.clientHeight) {
      this.setState({ minHeight: this.navDiv.clientHeight })
    }
    if (this.props.history) {
      if (this.state.id && this.state.id !== '') {
        if (
          !this.props.location.pathname.match(
            baseUrl + '/myaccount/' + this.state.id
          )
        ) {
          this.props.history.push(baseUrl + '/myaccount/' + this.state.id)
        }
      }
    }
    if (this.state.id && this.state.id !== '') {
      this.focusMainContent()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      if (navContent) {
        if (sideNavLink) {
          sideNavLink.textContent = I18n.t('MyAccount.skipToSideNavigation')
        }
      }
    }
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      if (!sideNavLink) {
        let sideNavLink = this._createSkipLink(
          '#side-nav',
          'skip-to-side-nav',
          I18n.t('MyAccount.skipToSideNavigation')
        )
        navContent.insertBefore(sideNavLink, mainNavContent)
      }
    }
    if (
      this.navDiv &&
      this.navDiv.clientHeight &&
      this.navDiv.clientHeight !== this.state.minHeight
    ) {
      this.setState({ minHeight: this.navDiv.clientHeight })
    }
    if (this.props.history) {
      if (this.state.id && this.state.id !== '') {
        if (
          !this.props.location.pathname.match(
            baseUrl + '/myaccount/' + this.state.id
          )
        ) {
          this.props.history.push(baseUrl + '/myaccount/' + this.state.id)
        }
      }
    }
    if (
      this.state.id &&
      this.state.id !== '' &&
      this.state.id !== prevState.id
    ) {
      this.focusMainContent()
    }
  }

  componentWillUnmount() {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    if (navContent) {
      if (sideNavLink) {
        navContent.removeChild(sideNavLink)
      }
    }
  }

  focusMainContent = () => {
    setTimeout(() => {
      const mainContent = document.getElementById('content')
      if (mainContent) {
        mainContent.focus()
      }
    }, 0)
  }

  _onNavClick = tabId => {
    this.props.history.push(baseUrl + '/myaccount/' + tabId)

    this.setState({ id: tabId })
  }

  _createSkipLink = (href, id, text = '') => {
    let skipLink = document.createElement('a')
    skipLink.setAttribute('class', 'skip')
    skipLink.setAttribute('href', href)
    skipLink.setAttribute('id', id)
    if (text) {
      let skipLinkText = document.createTextNode(text)
      skipLink.appendChild(skipLinkText)
    }
    return skipLink
  }

  render() {
    const {
      presentation,
      selectedEntitlements,
      locale,
      userRoleId
    } = this.props
    const { id, minHeight, navTabs } = this.state

    if (_.isNil(navTabs)) {
      return <div>Loading...</div>
    }

    let subProps: any = {
      key: id + '_' + userRoleId.toString()
    }
    if (minHeight) {
      subProps = {
        ...subProps,
        minHeight: '' + minHeight + 'px'
      }
    }
    const presentationProps = {
      id,
      subProps,
      navTabs,
      onNavClick: this._onNavClick,
      setNavDiv: el => {
        this.navDiv = el
      },
      selectedEntitlements,
      locale
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  return {
    externalAuth: state.externalAuth,
    userRoleId: _.get(state.userAccess, 'selectedUserRole.userRoleId') || '',
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    locale: state.i18n.locale
  }
}

export default connect(mapStateToProps)(MyAccountContainer)
