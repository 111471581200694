import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import CommunityPartnerSearch from '../CommunityPartnerSearch'
import IncidentForm from '../IncidentForm'
import BenefitsFinder from '../BenefitsFinder/BenefitsFinder'
import { config } from '../../../config'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import MyCalendar from '../MyAppointments'
import SiteMap from '../SiteMap'
import Faq from '../Faq'
import ImmigrationStatus from '../ImmigrationStatus'
import Reports from '../Reports'
import ApplicationSearch from '../ApplicationSearch'
import UserAccess from '../userAccess'
import Organization from '../Organization'
import BenefitsCatalog from '../../base/BenefitsCatalog'
import BenHScreen from '../BenHScreen/BenHScreen'
import BenhReferralRedirect from '../BenHReferral/BenHReferralRedirect'
import BenhEligibleRedirect from '../BenHEligibleApplicant/BenHEligibleRedirect'

declare const process
const baseUrl = process.env.PUBLIC_URL
const Fragment = (React as any).Fragment

export default function Routes(props) {
  const { auth, openIdRedirect } = props
  return (
    <Fragment>
      <Route path={baseUrl + '/thing'} component={SomeComponent} />
      <Route path={baseUrl + '/siteMap'} component={SiteMap} />
      <Route
        path={baseUrl + '/ImmigrationStatus'}
        component={ImmigrationStatus}
      />
      <Route path={baseUrl + '/faq'} component={Faq} />
      <Route
        path={baseUrl + '/communityPartnerSearch'}
        component={CommunityPartnerSearch}
      />
      <Route path={baseUrl + '/IncidentForm'} component={IncidentForm} />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/myAppointmets'}
        component={MyCalendar}
        componentProps={auth}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/reports'}
        component={Reports}
        componentProps={null}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/search'}
        component={ApplicationSearch}
        componentProps={null}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/userAccess'}
        component={UserAccess}
        componentProps={null}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/organization'}
        component={Organization}
        componentProps={null}
      />
      <Route
        path={baseUrl + '/benefitsfinderWV/:value'}
        render={() => <BenefitsFinder mode="AP" config={config} />}
      />
      {/*<Route path={baseUrl + '/benefitsfinder'} component={BenefitsFinder} />*/}
      <Route
        exact
        path={baseUrl + '/benefitsfinderWV'}
        render={() => <BenefitsFinder mode="CP" config={config} />}
      />
      <Route
        path={baseUrl + '/programs&services'}
        component={BenefitsCatalog}
      />
      <Route
        path={baseUrl + '/referrals/:value'}
        component={BenhReferralRedirect}
      />
      <Route
        path={baseUrl + '/eligibles/:value'}
        component={BenhEligibleRedirect}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/benHScreen'}
        component={BenHScreen}
        componentProps={null}
      />
    </Fragment>
  )
}

const SomeComponent = props => {
  return <div>This is a POC for how the tenant may add a new page route.</div>
}

function PrivateRoute({ component: Component, auth, componentProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        helper.isLoggedIn(auth, config) ? (
          <Component {...componentProps} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: baseUrl + '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}
