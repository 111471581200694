import * as React from 'react'
import * as _ from 'lodash'
import * as actions from '../../../actions'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { config } from '../../../config'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import { ENTITLEMENTS_wv as wvEnt } from '../src/entitlements_wv'
import { isLoggedIn } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { UnauthorizedError } from '../src/components/utils'

declare var process
const baseUrl = process.env.PUBLIC_URL

const gatewayBaseUrl = config['gatewayBaseUrl']
const getStateList = config['getStateList']
const getCountyList = config['getCountyList']

const errorList =
  config['gatewayWvUrl'] + '/WVIE/errorcodes/v1.0/getErrorCodes?systemName=ui'

interface HomeContainerProps {
  presentation: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  location: {
    search: string
    pathname: string
  }
  history: {
    push: (url: string) => void
  }
  selectedEntitlements: Array<string>
  loginUser: (code: string, checkHasPin: boolean) => void
  roleId: number
  roleName: string
  userAccess: {
    selectedUserRole: {
      userRoleId: number
    }
    userRoles: Array<UserRole>
  }
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorListUpdate: (errorList: any) => void
  errorMessage: Array<any>
  isLoggedIn: boolean
  logoutUser
  addReferralId: (referralId: any) => void
  addEligibleId: (eligibleId: any) => void
  referralId: any
  eligibleId: any
  getStates: (states: any) => void
  getCounties: (counties: any) => void
}

interface HomeContainerState {
  redirect: string
  benefitCategoryData: Array<{
    benfCatName: string
    benfCatShortDesc: string
    benfLieap: string
  }>
  roleId: number
  roleName: string
  userAccess: {
    selectedUserRole: {
      userRoleId: number
    }
  }
  auth: {
    userAccount: {
      uuid: string
    }
  }
  loggedInformation: boolean
  carouselFocus: boolean
  covidModal: boolean
  scaModal: boolean
  snapModal: boolean
  referralId: any
}

class HomeContainer extends React.Component<
  HomeContainerProps,
  HomeContainerState
> {
  constructor(props: HomeContainerProps) {
    super(props)

    this.state = {
      redirect: null,
      benefitCategoryData: [],
      roleId: null,
      roleName: null,
      userAccess: {
        selectedUserRole: {
          userRoleId: null
        }
      },
      auth: {
        userAccount: {
          uuid: null
        }
      },
      loggedInformation: false,
      carouselFocus: false,
      covidModal: false,
      scaModal: false,
      snapModal: false,
      referralId: null
    }
  }

  componentDidMount() {
    document.title = 'CP - Integrated Eligibility'
    this._acceptLogin()
    this._getErrorList()
    this._getStateCounty()
    //Clean up the URL
    if (this.props.history) {
      this.props.history.push(baseUrl + '/')
    }
    this.setState(prev => {
      return {
        ...prev,
        userAccess: this.props.userAccess,
        auth: this.props.auth
      }
    })
  }

  _getStateCounty = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        tenantCode: config.tCode,
        portalName: config.portalName
      }
    }
    Promise.all([
      helper.fetchJson(getStateList.replace('{version}', '1.0'), request),
      helper.fetchJson(getCountyList.replace('{version}', '1.0'), request)
    ])
      .then(res => {
        const states = res[0].states
        const counties = res[1].counties
        this.props.getStates(states)
        this.props.getCounties(counties)
      })
      .catch(error => {
        console.error('AppIntake form fetch failed due to ex', error)
      })
  }

  componentDidUpdate(prevProps, prevState) {
    let redirectUrl = baseUrl + '/myaccount/dashboard'
    const eligAppRedirectUrl = baseUrl + '/myaccount/eligibleApplicants'
    const referralRedirectUrl = baseUrl + '/myaccount/referrals'
    if (
      !_.isEqual(
        _.get(prevProps, 'userAccess.selectedUserRole.userRoleId'),
        _.get(this.props, 'userAccess.selectedUserRole.userRoleId')
      ) ||
      !_.isEqual(
        _.get(prevProps, 'userAccess.selectedOrg.orgId'),
        _.get(this.props, 'userAccess.selectedOrg.orgId')
      )
    ) {
      //They have just logged in, or their selected role changed. Check for post-role selection functionality.
      if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
        )
      ) {
        //PE workers are redirected to My Dashboard page.
        this.setState({
          redirect: redirectUrl
        })
      } else if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.COMMUNITY_PARTNERSHIP
        )
      ) {
        //Community Partner workers are redirected to My Dashboard page.
        this.setState({
          redirect: redirectUrl
        })
      } else if (_.includes(this.props.selectedEntitlements, wvEnt.CP_Admin)) {
        this.setState({
          redirect: redirectUrl
        })
      } else if (
        _.includes(
          this.props.selectedEntitlements,
          wvEnt.BH_ELIGIBLE_APPLICANT_VIEW
        )
      ) {
        if (this.props.eligibleId && this.props.eligibleId != null) {
          this.setState({
            redirect: eligAppRedirectUrl + '/' + this.props.eligibleId
          })
        } else {
          this.setState({
            redirect: eligAppRedirectUrl
          })
        }
        // HR Resource specialist
        if (
          this.props.referralId &&
          this.props.referralId != null &&
          _.includes(this.props.selectedEntitlements, wvEnt.BH_REFERRAL)
        ) {
          this.setState({
            redirect: referralRedirectUrl + '/' + this.props.referralId
          })
        }
      } else if (
        _.includes(this.props.selectedEntitlements, wvEnt.BH_REFERRAL)
      ) {
        if (this.props.referralId && this.props.referralId != null) {
          this.setState({
            redirect: referralRedirectUrl + '/' + this.props.referralId
          })
        } else {
          this.setState({
            redirect: referralRedirectUrl
          })
        }
      }
      this.setState({
        userAccess: this.props.userAccess,
        auth: this.props.auth
      })
    }

    if (
      !isLoggedIn(prevProps.auth, config) &&
      isLoggedIn(this.props.auth, config)
    ) {
      //They have just logged in, or their selected role changed. Check for post-role selection functionality.
      if (!this.state.loggedInformation) {
        if (
          _.includes(
            this.props.selectedEntitlements,
            ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
          )
        ) {
          //PE workers are redirected to My Dashboard page.
          this.setState({
            redirect: redirectUrl
          })
        } else if (
          _.includes(
            this.props.selectedEntitlements,
            ENTITLEMENTS.COMMUNITY_PARTNERSHIP
          )
        ) {
          //Community Partner workers are redirected to My Dashboard page.
          this.setState({
            redirect: redirectUrl
          })
        } else if (
          _.includes(this.props.selectedEntitlements, wvEnt.CP_Admin)
        ) {
          this.setState({
            redirect: redirectUrl
          })
        } else if (
          _.includes(
            this.props.selectedEntitlements,
            wvEnt.BH_ELIGIBLE_APPLICANT_VIEW
          )
        ) {
          if (this.props.eligibleId && this.props.eligibleId != null) {
            this.setState({
              redirect: eligAppRedirectUrl + '/' + this.props.eligibleId
            })
          } else {
            this.setState({
              redirect: eligAppRedirectUrl
            })
          }
          // HR Resource specialist
          if (
            this.props.referralId &&
            this.props.referralId != null &&
            _.includes(this.props.selectedEntitlements, wvEnt.BH_REFERRAL)
          ) {
            this.setState({
              redirect: referralRedirectUrl + '/' + this.props.referralId
            })
          }
        } else if (
          _.includes(this.props.selectedEntitlements, wvEnt.BH_REFERRAL)
        ) {
          if (this.props.referralId && this.props.referralId != null) {
            this.setState({
              redirect: referralRedirectUrl + '/' + this.props.referralId
            })
          } else {
            this.setState({
              redirect: referralRedirectUrl
            })
          }
        }
        this.setState({
          loggedInformation: true
        })
      }
    }
  }

  _acceptLogin = () => {
    try {
      if (this.props.location && this.props.location.search) {
        const query = queryString.parse(this.props.location.search)
        if (query.code) {
          const code = query.code
          this.props.loginUser(
            code,
            _.get(config.tenant, 'externalAuth.checkHasPin', false)
          )
        }
        if (query.state) {
          const state = query.state
          const stateVar = state.split(';referralId=')
          if (stateVar.length > 1) {
            this.props.addReferralId(+stateVar[1])
          }
          const stateVar1 = state.split(';eligibleId=')
          if (stateVar1.length > 1) {
            this.props.addEligibleId(+stateVar1[1])
          }
        }
      }
    } catch (err) {
      console.error('_acceptLogin failed with error:', err)
    }
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _getErrorList = () => {
    helper
      .fetchJson(errorList, {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount
            ? this.props.auth.userAccount.uuid || ''
            : '',
          tenantCode: config.tCode,
          Authorization: config.bearer + (this.props.auth.accessToken || ''),
          portalName: config.portalName
        }
      })
      .then(response => {
        this.props.errorListUpdate(response)
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
      })
  }
  _onCarouselFocus = () => {
    this.setState({
      carouselFocus: true
    })
  }

  _onCarouselBlur = () => {
    this.setState({
      carouselFocus: false
    })
  }

  _showCovidModal = () => {
    this.setState({
      covidModal: true
    })
  }

  _hideCovidModal = () => {
    this.setState({
      covidModal: false
    })
  }

  _showScaModal = () => {
    this.setState({
      scaModal: true
    })
  }

  _hideScaModal = () => {
    this.setState({
      scaModal: false
    })
  }
  _showSnapModal = () => {
    this.setState({
      snapModal: true
    })
  }

  _hideSnapModal = () => {
    this.setState({
      snapModal: false
    })
  }

  render() {
    const { presentation, errorMessage, userAccess, isLoggedIn } = this.props
    const { redirect, benefitCategoryData } = this.state
    if (redirect) {
      return <Redirect to={redirect} />
    }
    const presentationProps = {
      isLoggedIn,
      userAccess,
      benefitCategoryData,
      roleId: this.props.roleId,
      roleName: this.props.roleName,
      errorMessage,
      covidModal: this.state.covidModal,
      onDeleteClick: this._onDeleteClick,
      carouselFocus: this.state.carouselFocus,
      onCarouselFocus: this._onCarouselFocus,
      onCarouselBlur: this._onCarouselBlur,
      showCovidModal: this._showCovidModal,
      hideCovidModal: this._hideCovidModal,
      scaModal: this.state.scaModal,
      showScaModal: this._showScaModal,
      hideScaModal: this._hideScaModal,
      snapModal: this.state.snapModal,
      showSnapModal: this._showSnapModal,
      hideSnapModal: this._hideSnapModal
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  const isLoggedIn = helper.isLoggedIn(state.auth, config)
  return {
    isLoggedIn,
    auth: state.auth,
    userAccess: state.userAccess,
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    roleId: _.get(state.userAccess, 'selectedUserRole.role.roleId'),
    roleName: _.get(state.userAccess, 'selectedUserRole.role.roleName'),
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || [],
    referralId: _.get(state.myBenH, 'referralId'),
    eligibleId: _.get(state.myBenH, 'eligibleId')
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loginUser: (code, checkHasPin) => {
      dispatch(actions.loginUser(code, checkHasPin))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    errorListUpdate: errorList => {
      dispatch(actions.errorListUpdate(errorList))
    },
    setSelectedUserRole: selectedUserRole => {
      dispatch(actions.setSelectedUserRole(selectedUserRole))
    },
    setDefaultUserRole: selectedUserRole => {
      dispatch(actions.setDefaultUserRoleId(selectedUserRole.userRoleId))
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    addReferralId: referralId => {
      dispatch(actions.addReferralId(referralId))
    },
    addEligibleId: eligibleId => {
      dispatch(actions.addEligibleId(eligibleId))
    },
    getStates: states => {
      dispatch(actions.getStates(states))
    },
    getCounties: counties => {
      dispatch(actions.getCounties(counties))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
