import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const ClientUpdateWizardContainer = MultitenantLoadable(
  () => import('./ClientUpdateWizardContainer'),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/ClientUpdateWizardChildCare/ClientUpdateWizardContainer'
    )
)

const ClientUpdateWizardPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./ClientUpdateWizardPresentation'),
    () =>
      import(
        '../../' +
          tenantDirectoryName +
          '/ClientUpdateWizardChildCare/ClientUpdateWizardPresentation'
      )
  ),
  () => import('./formComponents'),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/ClientUpdateWizardChildCare/formComponents'
    )
)

export default function ClientUpdateWizard(props) {
  return (
    <ErrorBoundary name="ClientUpdateWizard">
      <ClientUpdateWizardContainer
        {...props}
        presentation={presentationProps => (
          <ClientUpdateWizardPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseClientUpdateWizardContainer = MultitenantLoadable(
  () => import('./ClientUpdateWizardContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseClientUpdateWizardPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./ClientUpdateWizardPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseClientUpdateWizard(props) {
  return (
    <BaseErrorBoundary name="ClientUpdateWizard">
      <BaseClientUpdateWizardContainer
        {...props}
        presentation={presentationProps => (
          <BaseClientUpdateWizardPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
