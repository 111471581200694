import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const CpRenewalsContainer = MultitenantLoadable(
  () => import('./CpRenewalsContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/CpRenewals/CpRenewalsContainer')
)

const CpRenewalsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./CpRenewalsPresentation'),
    () => import(config)
  ),
  () => import('./formComponents'),
  () => import(config)
)

export default function CpRenewals(props) {
  return (
    <CpRenewalsContainer
      {...props}
      presentation={presentationProps => (
        <CpRenewalsPresentation {...presentationProps} />
      )}
    />
  )
}
