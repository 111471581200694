import { createStore, applyMiddleware } from 'redux'
import { debounce, get } from 'lodash'
import thunkMiddleware from 'redux-thunk'
import reducer from './reducers'
//import LocalStorage from '@optum-wvie/dynamic-ui-framework/src/localStorage'
import SessionStorage from '@optum-wvie/dynamic-ui-framework/src/sessionStorage'
const xssFilters = require('xss-filters')
const _ = { debounce, get }

//const stringify: Function = require('fast-json-stable-stringify')

const stringify = JSON.stringify

const configureStore = (environment, tenantCode) => {
  const stateStorageKey = 'IE-' + environment + '-CP-' + tenantCode + '-STATE'
  const persistedState = JSON.parse(
    xssFilters.inHTMLData(SessionStorage.getItem(stateStorageKey) || '{}')
  )
  //TODO: Faster parse?

  const store = createStore(
    reducer,
    persistedState,
    applyMiddleware(thunkMiddleware)
  )

  store.subscribe(
    _.debounce(() => {
      const state = store.getState()
      let safeState = state
      SessionStorage.setItem(stateStorageKey, stringify(safeState))
    }, 500)
  )

  return store
}

export default configureStore
