import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash' // extra functionality
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
import * as serviceHelper from '../src/components/utils'
import * as actions from '../../../actions'
import { UnauthorizedError } from '../src/components/utils'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import * as validator from '../src/validation'

const baseUrl = process.env.PUBLIC_URL

const formsBaseUrl = config['formsBaseUrl']

const formsEndpoint = formsBaseUrl + config['forms_IncidentForm']
const submitTicketEndpoint =
  config['gatewayWvUrl'] + config['gatewayBaseUrlSsWv']

interface IncidentFormContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  county1: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
    isAuthenticated: boolean
  }
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  roleId: any
  orgId: any
  history: {
    push: (url: string) => void
  }
  logoutUser
}

interface IncidentFormContainerState {
  application: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formContext: any
  formData: object
  required: boolean
  successModal: boolean
  failureModal: boolean
  uuid: string
  ticketNumber: string
  liveValidate: boolean
}

class IncidentFormContainer extends React.Component<
  IncidentFormContainerProps,
  IncidentFormContainerState
> {
  constructor(props: IncidentFormContainerProps) {
    super(props)

    this.state = {
      application: {},
      forms: null,
      formContext: {
        formData: {}
      },
      formData: null,
      required: false,
      successModal: false,
      failureModal: false,
      uuid: this.props.auth.userAccount
        ? this.props.auth.userAccount.uuid || ''
        : '',
      ticketNumber: null,
      liveValidate: false
    }
  }

  componentDidMount() {
    document.title = 'Report Incident'
    this._loadForms()
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(prevState => {
      return {
        ...prevState,
        formData: formData,
        formContext: {
          formData: formData
        }
      }
    })
  }

  _switchOnLiveValidate = () => {
    this.setState({ liveValidate: true })
  }

  _errorListTemplate = (props: any) => {
    const { errors } = props

    return (
      <div
        className="panel panel-danger errors"
        id="appintake-errors"
        tabIndex={-1}
      >
        <div className="panel-heading">
          <span className="panel-title">Errors</span>
        </div>
        <ul className="list-group">
          {errors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this._onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  _onErrorClick = error => {
    const { forms } = this.state
    let errorId = error.id
    try {
      setTimeout(function() {
        window.requestAnimationFrame(function() {
          const element = document.getElementById(`IncidentReport_${error.id}`)
          let offset = helper.getOffsetTop(
            document.getElementById(`IncidentReport_${error.id}`)
          )
          element.focus()
          window.scroll(0, offset - 100)
        })
      }, 0)
    } catch (e) {
      console.error('Benefit Finder viewId setTimeout failed with ex', e)
    }
  }

  _onError = (errors: object) => {}

  _transformErrors = (errors: object) => {
    return errors
  }

  _loadForms = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount
          ? this.props.auth.userAccount.uuid || ''
          : '',
        tenantCode: config.tCode,
        Authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalName: config.portalName
      }
    }
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(formJson => {
        let application, forms, formContext
        application = formJson['app']
        forms = application['forms']
        this.setState(prevState => {
          return {
            application,
            forms,
            formContext: {
              ...prevState.formContext,
              forms: forms,
              refs: {}
            }
          }
        })
      })
      .catch(error => {
        console.error('Incident form fetch failed due to ex', error)
      })

    // Below is only execute when client is logged in and raise Incident.
    if (this.props.auth && this.props.auth.isAuthenticated) {
      this._getFormData()
    }
  }

  _getFormData() {
    const endpoint = config['getUserAccountDetails']
    serviceHelper
      .fetchJson(
        endpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount
              ? this.props.auth.userAccount.uuid || ''
              : '',
            tenantCode: config.tCode,
            Authorization: config.bearer + (this.props.auth.accessToken || ''),
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        const formData = {
          clientFstNm: json.userFstNm,
          clientLstNm: json.userLstNm,
          name: json.userFstNm,
          userId: json.userId,
          emailId: json.userEmail
        }
        const formData1 = { incidentForm: formData || [] }
        this.setState({
          formData: formData1
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error(
          'MyProfile _getFormData failed due to ex',
          error,
          'endpoint: ' + endpoint
        )
      })
  }

  _reportincident = () => {
    const { formData } = this.state
    serviceHelper
      .fetchJson(
        submitTicketEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount
              ? this.props.auth.userAccount.uuid || ''
              : '',
            tenantCode: config.tCode,
            Authorization: config.bearer + (this.props.auth.accessToken || ''),
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          body: JSON.stringify(this.state.formData)
        },
        this.props.showErrorMessage
      )
      .then(res => {
        if (res.status === 'Success') {
          let ticketNumber = res && res.ticketNumber
          this.setState({ successModal: true, required: false, ticketNumber })
        } else {
          this.setState({
            successModal: false,
            required: false,
            failureModal: true
          })
        }
      })
      .catch(error => {
        console.error('Submit Ticket is failed with error:', error)
      })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _onValidate(formData: object, errors: object) {
    validator.validateIncidentForm(formData, errors)

    let foundErrors = helper.findErrors(errors)
    return errors
  }

  render() {
    const { presentation, minHeight, isLoading, errorMessage } = this.props
    const {
      application,
      formData,
      formContext,
      forms,
      required,
      successModal,
      failureModal,
      ticketNumber,
      liveValidate
    } = this.state
    let validator
    if (forms) {
      validator = new CustomValidator(
        forms[0].schema,
        forms[0].uiSchema,
        formContext,
        'en'
      )
    }

    const presentationProps = {
      application,
      formData,
      formContext,
      forms,
      isLoading,
      minHeight,
      required,
      successModal,
      failureModal,
      ticketNumber,
      liveValidate,
      validator,
      onFormDataChange: this._onFormDataChange,
      reportincident: this._reportincident,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      errorListTemplate: this._errorListTemplate,
      onError: this._onError,
      transformErrors: this._transformErrors,
      switchOnLiveValidate: this._switchOnLiveValidate,
      onValidate: this._onValidate
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''

  return {
    auth: state.auth,
    userRoleId,
    errorMessage,
    roleId,
    orgId
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentFormContainer)
