import * as React from 'react'
import { find, isEmpty } from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

const _ = { find, isEmpty }

interface MyMessagesPresentationProps {
  application: any
  formData: any
  formContext: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  isLoading: boolean
  messageDetailViewId: number
  messageId: number
  goBack: () => void
  generatePDF: (json: Object) => void
  minHeight: string
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  criteriaFormData: {
    startDate?: string
    endDate?: string
  }
  onCriteriaChange: ({ formData: any }) => void
  onCriteriaClear: () => void
  onSearchData: () => void
  locale: string
}

export default function MyMessagesPresentation(
  props: MyMessagesPresentationProps
) {
  const {
    application,
    formData,
    formContext,
    forms,
    isLoading,
    messageDetailViewId,
    messageId,
    goBack,
    generatePDF,
    minHeight,
    formComponents,
    criteriaFormData,
    onCriteriaChange,
    onCriteriaClear,
    onSearchData,
    locale
  } = props

  const header = isLoading ? (
    <div style={{ display: 'table' }}>
      <h1 style={{ display: 'table-cell' }}>
        <i className="pe-7s-mail" /> {I18n.t('MyMessages.title')}
      </h1>
      <i
        className="fa fa-spinner fa-spin float-right"
        style={{ fontSize: '24px', display: 'table-cell' }}
      />
    </div>
  ) : (
    <h1>
      <i className="pe-7s-mail" /> {I18n.t('MyMessages.title')}
    </h1>
  )

  let output = <ScreenPreLoader loaded={false} minHeight={minHeight} />

  if (messageDetailViewId) {
    const messageDetail = {
      ..._.find(
        formData.Application.myMessages,
        element => element.msgId === messageId
      ),
      readUnreadFlg: 'Y'
    }
    const detailFormData = {
      Application: messageDetail
    }
    const detailFormContext = { ...formContext, formData: detailFormData }
    output = (
      <div className="form-section">
        {header}
        <Form
          schema={forms[1]['schema']}
          uiSchema={forms[1]['uiSchema']}
          formData={detailFormData}
          formContext={detailFormContext}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <div>
            <button
              type="button"
              className="btn btn-default float-right"
              onClick={goBack}
            >
              {I18n.t('General.backButton')}
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-default float-left"
              onClick={() => generatePDF(formData)}
            >
              {I18n.t('General.viewAttachmentButton')}
            </button>
          </div>
        </Form>
      </div>
    )
  } else {
    if (!_.isEmpty(application)) {
      const criteriaFormContext = { ...formContext, formData: criteriaFormData }
      output = (
        <div className="form-section">
          {header}
          <Form
            schema={forms[2]['schema']}
            uiSchema={forms[2]['uiSchema']}
            formData={criteriaFormData}
            onChange={onCriteriaChange}
            formContext={criteriaFormContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
            onSubmit={onSearchData}
            locale={locale}
          >
            <p className="float-right">
              <button
                type="button"
                className="btn btn-default"
                onClick={onCriteriaClear}
                style={{ marginRight: '10px' }}
              >
                {I18n.t('General.clearButton')}
              </button>
              <button type="submit" className="btn btn-primary">
                {I18n.t('General.searchButton')}
              </button>
            </p>
          </Form>
          <Form
            schema={forms[0]['schema']}
            uiSchema={forms[0]['uiSchema']}
            formData={formData}
            formContext={formContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
            locale={locale}
          >
            <button type="submit" style={{ display: 'none' }} />
          </Form>
        </div>
      )
    }
  }
  return (
    <>
      <Helmet>
        <title>{I18n.t('MyMessages.title')}</title>
      </Helmet>
      {output}
    </>
  )
}
