import * as React from 'react'
import { Modal } from 'react-bootstrap'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'
import * as serviceHelper from '../src/components/utils'
const gatewayWvUrl = config['gatewayWvUrl']
const CountiesBaseUrl = gatewayWvUrl + config['countiesStateUrl']
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import * as validator from '../src/validation'

const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  carousel: Fields.CarouselField,
  panel: Fields.PanelField,
  tableRow: Fields.TableRowField,
  checkboxGrid: Fields.CheckboxGridField,
  mcr: Fields.CodeReferenceField
}

const widgets = {
  radio: Widgets.CustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  checkboxes: Widgets.CustomCheckboxesWidget,
  'alt-date': Widgets.DatePickerWidget,
  dollar: Widgets.DollarWidget,
  span: Widgets.SpanWidget,
  validatedText: Widgets.ValidatedTextWidget,
  checkbox: Widgets.CustomCheckboxWidget,
  phone: Widgets.PhoneWidget,
  ssn: Widgets.SSNWidget,
  county: Widgets.CustomCountyWidget,
  ssn1: Widgets.SSNWidgetV2
}

export default class ContributorModal extends React.Component<any, any> {
  private hasError = false
  constructor(props: any) {
    super(props)

    this.state = {
      formData: null,
      formContext: null,
      showErrors: false,
      liveValidate: false
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.show && !prevProps.show) {
      this.setState({
        formData: null,
        formContext: {
          refs: this.props.form.refs,
          config,
          CountiesBaseUrl: CountiesBaseUrl,
          onFormDataChange: this._onFormDataChange,
          fetchJson: serviceHelper.fetchJson,
          showErrorMessage: this.props.showErrorMessage
        }
      })
    }
  }

  _cancel = () => {
    this.setState({ showErrors: false, liveValidate: false })
    this.props.cancel()
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({ formData }, () => this._refreshFormContext())
  }

  _refreshFormContext = () => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        formData: {
          Application: {
            contributors: [...helper.deepClone(this.state.formData)]
          }
        }
      }
    })
  }

  _onSubmit = ({ formData }) => {
    this.setState({ formData, liveValidate: false }, () => {
      this.props.save(this.state.formData)
    })
  }

  onValidate = (formData: object, errors: object) => {
    let newFormData = helper.deepClone(formData)
    const { liveValidate, formContext } = this.state

    //Call CP-specific validation library.
    if (liveValidate) {
      validator.validateContributorForm(
        newFormData,
        errors,
        this.state.formContext
      )
    }

    //Determine which tabs contain errors and update their statuses.
    let foundErrors = helper.findErrors(errors)
    if (foundErrors && foundErrors.length > 0) {
      this.hasError = true
    } else {
      this.hasError = false
    }
    return errors
  }

  onErrorClick = (error: any) => {
    event.preventDefault()
  }

  transformErrors = (errors: object) => {
    return errors
  }

  onError = (errors: object) => {}

  errorListTemplate = (props: any) => {
    const { errors } = props

    return (
      <div
        className="panel panel-danger errors"
        id="appintake-errors"
        tabIndex={-1}
      >
        <div className="panel-heading">
          <span className="panel-title">
            {' '}
            {I18n.t('General.errButtonLabel')}
          </span>
        </div>
        <ul className="list-group">
          {errors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this.onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  render() {
    let validator
    if (!this.props.form) {
      return null
    } else {
      validator = new CustomValidator(
        this.props.form['schema'],
        this.props.form['uiSchema'],
        this.state.formContext
      )
    }
    return (
      <div className="modal-container">
        <Modal
          show={this.props.show}
          onHide={this._cancel}
          backdrop={'static'}
          aria-labelledby="contained-modal-title"
          size={'lg'}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              {I18n.t('Appintake.contributorModalTitle')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {I18n.t('Appintake.contributorModalBody')}
            <Form
              schema={this.props.form['schema']}
              uiSchema={this.props.form['uiSchema']}
              onSubmit={this._onSubmit}
              formData={this.state.formData}
              formContext={this.state.formContext}
              onChange={this._onFormDataChange}
              fields={fields}
              widgets={widgets}
              ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
              FieldTemplate={Templates.CustomFieldTemplate}
              locale={this.props.locale}
              validate={this.onValidate}
              liveValidate={this.state.liveValidate}
              showErrorList={true}
              ErrorList={this.errorListTemplate}
              onError={this.onError}
              transformErrors={this.transformErrors}
              jsonValidator={validator}
            >
              <Modal.Footer>
                <div className="col-lg-12">
                  <button
                    type="button"
                    className="btn btn-default float-left"
                    onClick={this._cancel}
                  >
                    {I18n.t('General.closeButton')}
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      this.setState({
                        liveValidate: true
                      })
                    }}
                    className="btn btn-default float-right"
                  >
                    {I18n.t('General.saveButton')}
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
