import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { config } from '../../../config'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'

import {
  ENTITLEMENTS_wv as wvEnt,
  ENTITLEMENTS_wv
} from '../src/entitlements_wv'

const widgets = {
  span: Widgets.SpanWidget,
  link: Widgets.LinkWidget,
  linkButton: Widgets.LinkButtonWidget,
  header: Widgets.HeaderWidget
}
const baseUrl = process.env.PUBLIC_URL
const formsEndpoint = config['helpSelfService']
const trainingUrl = config['trainingUrl']

class CommonAnswers extends React.Component<any, any> {
  answerContent: React.RefObject<{}>
  constructor(props: any) {
    super(props)
    this.answerContent = React.createRef()
  }

  back = () => {
    this.props._unmountCommonAnwers()
  }

  getAnswer = () => {
    let userRoleid = _.get(
      this.props,
      'userAccess.selectedUserRole.userRoleId',
      ''
    )
    const selectedUserRole = _.find(this.props.userAccess.userRoles, {
      userRoleId: _.toInteger(userRoleid)
    })
    const selectedEntitlements = _.get(
      selectedUserRole,
      'entitlements',
      []
    ).map(element => element.entitlementName)
    if (!_.isEmpty(this.props.index)) {
      const index = this.props.index
      switch (index) {
        case '1':
          return (
            <div>
              <p>
                The PATH Public Portal can be used to apply for the following
                programs and services:
              </p>
              <ul>
                <li>Child Care Services</li>
                <li>Child Support Services</li>
                <li>Food and Nutrition (SNAP)</li>
                <li>Health Care</li>
                <li>
                  Home Energy Assistance (LIEAP) –{' '}
                  <i>only available during appropriate season</i>
                </li>
                <li>Medicare Premium Assistance</li>
                <li>
                  School Clothing Allowance –{' '}
                  <i>only available during appropriate season</i>
                </li>
              </ul>
              <p>
                I don’t have an Optum GovID: <br />
                To apply for one of these programs, you need to create an Optum
                GovID. To create an Optum GovID, click{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://identity.nonprod.optumgovid.com/app/index.html#/registration"
                >
                  {' '}
                  Create Optum GovID
                </a>{' '}
                .
              </p>
              {config['preGoLiveTitlesVisibile'] &&
                _.includes(
                  selectedEntitlements,
                  ENTITLEMENTS_wv.CP_APPLY_NOW
                ) && (
                  <p>
                    I have an Optum GovID:
                    <br />
                    Begin an application by clicking{' '}
                    <Link
                      style={{ textDecoration: 'underline' }}
                      to={{
                        pathname: baseUrl + '/appdrafts'
                      }}
                    >
                      {' '}
                      Apply Now
                    </Link>
                    .
                  </p>
                )}
              <p>
                For benefits or services provided by the DoHS but not listed
                above, please contact your local DoHS office.{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://dhhr.wv.gov/Pages/default.aspx"
                  target="_blank"
                >
                  Click here
                </a>{' '}
                for contact information.
              </p>
            </div>
          )
        case '2':
          return (
            <div>
              <p>
                Each program is different, but these are some of the changes you
                may need to report:
              </p>
              <ul>
                <li>A new address</li>
                <li>A new phone number</li>
                <li>
                  An income change (new job, loss of employment, lower/higher
                  pay or hours)
                </li>
                <li>
                  A change in who lives with you (someone moves in or out, birth
                  of a child, death or incarceration)
                </li>
                <li>
                  A change in assets (opening or closing a bank account, new
                  vehicle, buying or selling a home)
                </li>
              </ul>
              <p>
                Contact your DoHS worker if you have questions about what
                changes to report.
              </p>
            </div>
          )
        case '3':
          return (
            <div>
              The PATH Public Portal can be used to report many different types
              of changes.
              <br />
              {config && config['preGoLiveTitlesVisibile'] && (
                <div>
                  Log into{' '}
                  <Link
                    style={{ textDecoration: 'underline' }}
                    to={{
                      pathname: baseUrl + '/myaccount'
                    }}
                  >
                    {' '}
                    My Account
                  </Link>{' '}
                  using your Optum GovID. Select My Changes to report changes to
                  your DoHS worker.
                </div>
              )}
            </div>
          )
        case '4':
          return (
            <div>
              <p>
                Certain benefits and services may be closed if you no longer
                need them at the request of the recipient/client. Contact your
                DoHS worker to close your case.
              </p>
              <p>Or</p>
              <p>
                Contact the Customer Service Center at 1-877-716-1212 for help
                closing your SNAP, Medicare Premium Assistance, Medicaid or CHIP
                case.{' '}
              </p>
            </div>
          )

        default:
          return ''
      }
    }
  }

  getQuestion = () => {
    if (!_.isEmpty(this.props.index)) {
      const index = this.props.index
      switch (index) {
        case '1':
          return 'How do I apply for programs or services with the West Virginia Department of Human Services (WV DoHS)?'
        case '2':
          return 'What changes do I need to report?'
        case '3':
          return 'How do I report changes?'
        case '4':
          return 'How do I close my case?'

        default:
          return ''
      }
    }
  }

  componentDidMount() {
    if (this.props.tabFocus) {
      let el = this.answerContent.current as HTMLElement
      el.focus()
    }
  }

  render() {
    return (
      <div className="headerpadding margin-top-10">
        <div className="paragraph-label padding-top-5x headerpadding">
          <h1>Common Questions:</h1>
        </div>
        <div className="headerpadding padding-top-5x margin-top-10">
          <a className="myglyphicon-halflings myglyphicon-chevron-left" />
          <Link to="#" onClick={this.back} className="backlink-focus">
            {' '}
            Back to Common Questions
          </Link>
        </div>
        <h2 className="paragraph-label headerpadding padding-top-5x margin-top-10 question-heading-style">
          {this.getQuestion()}
        </h2>
        <div
          className="headerpadding padding-top-5x margin-top-10"
          tabIndex={-1}
          ref={this.answerContent as any}
        >
          {this.getAnswer()}
        </div>
      </div>
    )
  }
}

class CommonQuestions extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    document.addEventListener('keydown', this.onFocus)
    this.state = {
      forms: null,
      formData: null,
      formContext: null,
      isLinkSelected: false,
      tabFocus: false
    }
  }

  componentDidMount() {
    const endpoint = formsEndpoint.replace('{version}', '1.0')
    helper
      .fetchJson(endpoint)
      .then(formJson => {
        this._loadAll(formJson)
      })
      .catch(error => {
        console.error('AppIntake form fetch failed due to ex', error)
      })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onFocus)
  }

  _loadAll = Json => {
    let forms, application, formData, formContext
    application = Json['app']
    forms = application['forms']
    formContext = { forms: forms, config }
    let formDataLoad = application['formData']
    if (_.isObject(formDataLoad) && !_.isEmpty(formDataLoad)) {
      formData = _.mergeWith(
        helper.createObjectFromMasterSchema(null, formContext),
        formDataLoad,
        function(objValue, srcValue) {
          if (srcValue == null && objValue == undefined) {
            return {}
          }
          return undefined
        }
      )
    } else {
      formData = helper.createObjectFromMasterSchema(null, formContext)
    }
    formContext = { ...formContext, formData: formData, component: this }
    let loadScreen = true
    this.setState({ application, forms, formData, formContext, loadScreen })
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      this.setState({ callingApi: true })
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            locale: (this.props.locale + '_us').toUpperCase()
          }
        })
        .then(formsJson => {
          this.setState({ callingApi: false })
          const application = formsJson['app']
          const forms = application['forms']
          const formContext = {
            ...this.state.formContext,
            forms
          }
          this.setState({ forms, formContext })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('AppIntake form re-fetch failed due to ex', error)
        })
    }
  }

  _refreshFormContext = () => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        formData: this.state.formData
      }
    })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({ formData }, () => this._refreshFormContext())
  }

  _unmountCommonAnwers = () => {
    this.setState({ isLinkSelected: false, tabFocus: false })
  }

  OnLinkClick = data => {
    const index = data.id.match(
      '(?:.*)forms_help_application_CommonQuestion([0-9]+)?'
    )[1]
    this.setState({ isLinkSelected: true, index }, () =>
      this._refreshFormContext()
    )
  }

  onFocus = e => {
    if (e.which === 9 && !this.state.tabFocus) {
      this.setState({ tabFocus: true })
    } else if (e.which === 1 && this.state.tabFocus) {
      this.setState({ tabFocus: false })
    }
  }

  render() {
    let loaded = false
    if (this.state.forms) {
      loaded = true
      if (!this.state.isLinkSelected) {
        return (
          <div>
            <div className="col-lg-12 border-container margin-bottom-15px no-gutters">
              <div className="container-fluid report-renewal-pg">
                <div className="col-lg-12 no-gutters">
                  <Form
                    schema={this.state.forms[0]['schema']}
                    uiSchema={this.state.forms[0]['uiSchema']}
                    formData={this.state.formData}
                    onChange={this._onFormDataChange}
                    formContext={this.state.formContext}
                    widgets={widgets}
                    ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
                    FieldTemplate={Templates.CustomFieldTemplate}
                  >
                    <a
                      href="https://learning-public.wvpath.wv.gov/story.html"
                      target="_blank"
                      className="col-md-12 no-gutter margin-top-15px"
                    >
                      <u>Click here for Training Resources.</u>
                    </a>
                    <span />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <CommonAnswers
            _unmountCommonAnwers={this._unmountCommonAnwers}
            index={this.state.index}
            tabFocus={this.state.tabFocus}
            userAccess={this.props}
          />
        )
      }
    } else {
      return (
        <div className="container-fluid reports-container">
          <div>
            <ScreenPreLoader loaded={false} />
          </div>
        </div>
      )
    }
  }
}

export default CommonQuestions
