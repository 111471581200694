import * as React from 'react'
import { render } from 'react-dom'
import configureStore from './configureStore'
import { config } from './config'
import { words, get, includes } from 'lodash'
import { setLocale, syncTranslationWithStore } from 'react-redux-i18n'
require('es6-object-assign').polyfill()
require('es6-promise').polyfill()
require('array-findindex-polyfill')
require('array.prototype.fill')

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

import './styles/scss/blue.scss'

declare const process
import 'whatwg-fetch'
Number.isNaN =
  Number.isNaN ||
  function(value) {
    return value !== value
  }
import Root from './components/base/Root'
import { getStaticTranslations } from './actions'
//TODO: rearrange the imports across the project

const _ = { words, get, includes }

const supportedLocales = config['languages'].map(e => e.locale)
const store = configureStore(config.environment, config.tenant.code)
store.dispatch({ type: 'INITIALIZE' })
syncTranslationWithStore(store)
store.dispatch(getStaticTranslations(supportedLocales) as any)

const browserLocale =
  _.get(window, 'navigator.userLanguage') || _.get(window, 'navigator.language')
let defaultLocale = browserLocale
  ? _.words(browserLocale)[0].toLowerCase()
  : 'en'
if (!_.includes(supportedLocales, defaultLocale)) {
  defaultLocale = 'en'
}
store.dispatch(setLocale(defaultLocale))

render(<Root store={store} />, document.getElementById('root'))
