import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

export default class ConfirmModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      modalOpen: true
    }

    this._closeModal = this._closeModal.bind(this)
    this._openModal = this._openModal.bind(this)
  }

  _closeModal = () => {
    this.props.modalWasClosed()
    this.setState({ modalOpen: false })
  }

  _openModal = () => {
    this.setState({ modalOpen: true })
  }

  render() {
    const { modalOpen } = this.state
    if (this.props.retro) {
      return (
        <div className="static-modal">
          <Modal show={this.props.open} onHide={() => this._closeModal()}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="glyphicon myglyphicon-check" />{' '}
                {I18n.t('MyApplications.retroactiveModalTitle')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  {I18n.t('MyApplications.retroactiveModalBody')}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
    } else {
      return (
        <div className="static-modal">
          <Modal show={this.props.open} onHide={() => this._closeModal()}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="glyphicon myglyphicon-check" />{' '}
                {I18n.t('MyApplications.withdrawModalTitle')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  {I18n.t('MyApplications.withdrawModalBody')}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
    }
  }
}
