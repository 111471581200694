import * as React from 'react'
import { get } from 'lodash'
import { I18n } from 'react-redux-i18n'

const _ = { get }

export default function MyRoleTable(props) {
  const { roleSetting, locale, handleRoleChange, defaultUserRoleId } = props

  const userRoles = _.get(roleSetting, 'userRoles', [])

  return (
    <div className="form-group field field-object  col-xs-12 padding-top-30x plus-minus-panel">
      <h2>{I18n.t('MyProfile.myRoleSetting')}</h2>
      <div>
        <p className="paragraph-label">
          {I18n.t('MyProfile.selectRoleDefault')}
        </p>
        <div className="form-group field field-array  col-xs-12 padding-top-30x plus-minus-panel">
          <div className="field field-array field-array-of-object">
            <fieldset>
              <div className="table table-striped table-bordered table-condensed table-hover">
                <div className="thead">
                  <div className="tr">
                    <div className="th">
                      <legend className="span-label">
                        {I18n.t('MyProfile.defaultRole')}
                      </legend>
                    </div>
                    <div className="th">
                      <span>{I18n.t('MyProfile.roleName')}</span>
                    </div>
                  </div>
                  <div />
                </div>
                <div className="tbody">
                  {userRoles.map((role, index) => {
                    const userRoleId = role.userRoleId
                    const displayName = _.get(
                      role,
                      `role.multilingualDisplayName[${locale}]`,
                      ''
                    )
                    return (
                      <div className="tr">
                        <div className="td">
                          <div className="field field-string pull-text-center">
                            {
                              <div className="field-radio-group">
                                <div className="radio">
                                  <label
                                    htmlFor={`root_roleSetting_userRoles_${index}_defaultRole_0`}
                                    className="sr-only"
                                  >
                                    {displayName}
                                  </label>
                                  <input
                                    id={`root_roleSetting_userRoles_${index}_defaultRole_0`}
                                    type="radio"
                                    value="true"
                                    checked={userRoleId === defaultUserRoleId}
                                    onChange={() => {
                                      handleRoleChange(userRoleId)
                                    }}
                                  />
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="td">
                          <span>{displayName}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  )
}
