import * as React from 'react'
import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

//There is only a basic presentation for now.
//TODO: refactor when there is a container.

const MyAppealPresentation = MultitenantLoadable(
  () => import('./MyAppealPresentation'),
  () =>
    import('../../' + tenantDirectoryName + '/MyAppeal/MyAppealPresentation')
)

export default function MyAppeal(props) {
  return (
    <ErrorBoundary name="MyAppeal">
      <MyAppealPresentation {...props} />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyAppealPresentation = MultitenantLoadable(
  () => import('./MyAppealPresentation'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyAppeal(props) {
  return (
    <BaseErrorBoundary name="MyAppeal">
      <BaseMyAppealPresentation {...props} />
    </BaseErrorBoundary>
  )
}
