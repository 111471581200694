import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyApplicationsContainer = MultitenantLoadable(
  () => import('./MyApplicationsContainer'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/MyApplications/MyApplicationsContainer'
    )
)

const MyApplicationsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyApplicationsPresentation'),
    () =>
      import(
        '../../' +
          tenantDirectoryName +
          '/MyApplications/MyApplicationsPresentation'
      )
  ),
  () => import('./formComponents'),
  () =>
    import('../../' + tenantDirectoryName + '/MyApplications/formComponents')
)

export default function MyApplications(props) {
  return (
    <ErrorBoundary name="MyApplications">
      <MyApplicationsContainer
        {...props}
        presentation={presentationProps => (
          <MyApplicationsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyApplicationsContainer = MultitenantLoadable(
  () => import('./MyApplicationsContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyApplicationsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyApplicationsPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyApplications(props) {
  return (
    <BaseErrorBoundary name="MyApplications">
      <BaseMyApplicationsContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyApplicationsPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
