import * as React from 'react'
import { join } from 'lodash'
// import PropTypes from "prop-types";

const SELECT_OPTION_VALIDATION_TEXT = {
  en: 'Please select one of these options.',
  es: 'Seleccione una de las opciones.'
}

function RadioWidget(props) {
  const {
    options,
    value,
    required,
    disabled,
    readonly,
    autofocus,
    onChange,
    id,
    locale,
    inputDescriptionIds,
    hasError
  } = props

  //this is done to fix if radio is used inside table to put them under same Name tag.
  //keeping under same Name tag will arrange them in a same group.
  let name = ''
  const { enumOptions, inline, isTableRadio, radioLabelClassNames } = options
  if (isTableRadio) {
    let nameArray = id.split('_')
    nameArray.pop()
    nameArray.pop()
    name = nameArray.join('_')
  } else {
    name = id
  }

  // checked={checked} has been moved above name={name}, As mentioned in #349;
  // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.
  return (
    <div className="field-radio-group">
      {enumOptions.map((option, i) => {
        const checked = option.value === value
        const disabledCls = disabled || readonly ? 'disabled' : ''
        const className =
          options.hasOwnProperty('csEnforcementServicesApplication') &&
          options.csEnforcementServicesApplication
            ? 'radio'
            : `radio${inline ? ' radio-inline' : ''} ${disabledCls}`
        return (
          <div key={i} className={className}>
            <input
              id={id + '_' + i}
              type="radio"
              checked={checked}
              name={name}
              value={option.value}
              disabled={disabled || readonly}
              autoFocus={autofocus && i === 0}
              onChange={() => {
                if (!readonly) onChange(option.value)
              }}
              onInvalid={e => {
                const target = e.target as any
                target.setCustomValidity('')
                if (!target.validity.valid) {
                  target.setCustomValidity(
                    SELECT_OPTION_VALIDATION_TEXT[locale || 'en']
                  )
                }
              }}
              onInput={e => {
                const target = e.target as any
                target.setCustomValidity('')
              }}
              aria-required={required}
              aria-invalid={hasError}
              aria-describedby={join(inputDescriptionIds || [], ' ')}
              aria-disabled={disabled || readonly ? true : false}
            />
            <label
              className={radioLabelClassNames}
              key={i}
              htmlFor={id + '_' + i}
            >
              {option.label}
            </label>
          </div>
        )
      })}
    </div>
  )
}

;(RadioWidget as any).defaultProps = {
  autofocus: false
}

// if (process.env.NODE_ENV !== "production") {
//   RadioWidget.propTypes = {
//     schema: PropTypes.object.isRequired,
//     id: PropTypes.string.isRequired,
//     options: PropTypes.shape({
//       enumOptions: PropTypes.array,
//       inline: PropTypes.bool
//     }).isRequired,
//     value: PropTypes.any,
//     required: PropTypes.bool,
//     disabled: PropTypes.bool,
//     readonly: PropTypes.bool,
//     autofocus: PropTypes.bool,
//     onChange: PropTypes.func
//   };
// }
export default RadioWidget
