import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import '!style-loader!css-loader!./CpRenewals.css'
import { Link } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorModal } from '../src/components/natives/ErrorModal'

const baseUrl = process.env.PUBLIC_URL
interface CpRenewalsPresentationProps {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  formContext: any
  formData: any
  hasKeyedAny: () => boolean
  onFormDataChange: (formData: any) => void
  minHeight: string
  formComponents
  search: () => void
  reset: () => void
  showCpRenewals: boolean
  showBackCpRenewals: () => void
  getCpRenewalsResult: any
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function CpRenewalsPresentation(
  props: CpRenewalsPresentationProps
) {
  const {
    form,
    formContext,
    onFormDataChange,
    minHeight,
    formComponents,
    search,
    reset,
    hasKeyedAny,
    showCpRenewals,
    showBackCpRenewals,
    getCpRenewalsResult,
    errorMessage,
    onDeleteClick
  } = props

  const disableButtons = !hasKeyedAny()
  let reviewIndicator = 'R'
  let prgNames
  let programCodeString
  if (getCpRenewalsResult) {
    prgNames = _.get(
      getCpRenewalsResult,
      'GetCpRenewalsResult.renewalData[0].appProgCode',
      ''
    )
    programCodeString = _.get(
      getCpRenewalsResult,
      'GetCpRenewalsResult.renewalData[0].programCode',
      ''
    )

    if (prgNames == 'Other Benefits') {
      prgNames = _.get(
        getCpRenewalsResult,
        'GetCpRenewalsResult.renewalData[0].programCode',
        ''
      )

      programCodeString = _.get(
        getCpRenewalsResult,
        'GetCpRenewalsResult.renewalData[0].programCode',
        ''
      )
    }
  }
  let applId = undefined
  let renewalStatus =
    getCpRenewalsResult &&
    getCpRenewalsResult.GetCpRenewalsResult &&
    getCpRenewalsResult.GetCpRenewalsResult.renewalData &&
    getCpRenewalsResult.GetCpRenewalsResult.renewalData.length > 0 &&
    getCpRenewalsResult.GetCpRenewalsResult.renewalData[0].hasOwnProperty(
      'status'
    )

  if (form && !showCpRenewals) {
    return (
      <div>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <div id="cp-renewals">
          <Form
            schema={form.app.forms[0].schema}
            uiSchema={form.app.forms[0].uiSchema}
            formData={formContext.formData}
            onChange={onFormDataChange}
            formContext={formContext}
            widgets={formComponents.widgets}
          />
          <div className="flex-box">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <button
                type="button"
                id="clear_cp_renewals"
                className="btn btn-default float-right reset-margin userListButton1 margin-right-20"
                onClick={reset}
              >
                Clear
              </button>
              <button
                type="submit"
                id="search_cp_renewals"
                disabled={disableButtons}
                className="btn btn-default float-right userListButton2"
                onClick={search}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (showCpRenewals) {
    let renewalText
    let renewalButton
    if (
      getCpRenewalsResult &&
      getCpRenewalsResult.GetCpRenewalsResult &&
      getCpRenewalsResult.GetCpRenewalsResult.renewalData &&
      getCpRenewalsResult.GetCpRenewalsResult.renewalData.length > 0 &&
      getCpRenewalsResult.GetCpRenewalsResult.renewalData[0].hasOwnProperty(
        'caseNum'
      ) &&
      !renewalStatus
    ) {
      renewalButton = (
        <Link
          to={
            baseUrl +
            '/renewal?caseId=' +
            getCpRenewalsResult.GetCpRenewalsResult.renewalData[0].caseNum +
            '&reviewIndicator=' +
            getCpRenewalsResult.GetCpRenewalsResult.renewalData[0]
              .reviewTypeIndicator +
            '&appId=' +
            applId +
            '&prgNames=' +
            prgNames +
            '&renewalDate=' +
            getCpRenewalsResult.GetCpRenewalsResult.renewalData[0]
              .reviewDueDate +
            '&programCode=' +
            programCodeString
          }
          className="btn btn-primary"
          id="CpRenewals_startRenewal"
        >
          Start Renewal
        </Link>
      )
      renewalText = (
        <>
          <b>Below are the current benefits which are due for renewal.</b>
        </>
      )
    } else {
      renewalButton = (
        <Link
          to={'/'}
          className="disable-link btn btn-primary"
          onClick={() => event.preventDefault()}
          id="CpRenewals_startRenewal"
        >
          Start Renewal
        </Link>
      )
      renewalText = (
        <>
          <b>
            {getCpRenewalsResult &&
            getCpRenewalsResult.GetCpRenewalsResult &&
            getCpRenewalsResult.GetCpRenewalsResult.renewalData &&
            getCpRenewalsResult.GetCpRenewalsResult.renewalData.length > 0 &&
            getCpRenewalsResult.GetCpRenewalsResult.renewalData[0].status
              ? getCpRenewalsResult.GetCpRenewalsResult.renewalData[0].status
              : 'No case was found based on the data entered'}
          </b>
        </>
      )
    }
    return (
      <div>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <div className="row">
          <p className="benefit-header1">
            <span className="pe-7s-gift" />
            My Renewals
          </p>
        </div>
        {renewalButton}
        <br />
        <div style={{ marginTop: '15px' }}>
          <div>{renewalText}</div>
          <br />
          <Form
            schema={form.app.forms[1].schema}
            uiSchema={form.app.forms[1].uiSchema}
            formData={getCpRenewalsResult.GetCpRenewalsResult}
            onChange={onFormDataChange}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            fields={formComponents.fields}
            FieldTemplate={formComponents.FieldTemplate}
          />
        </div>
        <div className="col-lg-6">
          <button
            type="button"
            className="btn btn-default float-left reset-margin userListButton1"
            style={{ marginLeft: '-1em', marginTop: '5px' }}
            onClick={showBackCpRenewals}
          >
            Previous
          </button>
        </div>
      </div>
    )
  }
  return <ScreenPreLoader minHeight={minHeight} />
}
