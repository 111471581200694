import * as React from 'react'
import * as _ from 'lodash'
import { FormCheck, Button, Modal } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import * as serviceHelper from '../src/components/utils'
import { config } from '../../../config'

declare var process
const baseUrl = process.env.PUBLIC_URL
const referralSubmit = config['gatewayWvUrl'] + config['referralSubmit']
function ScreenBenefitsModal(props) {
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.cancel()}
        backdrop="static"
        aria-labelledby="contained-modal-title"
        size={'lg'}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">
            <h4 style={{ marginTop: '0px', marginBottom: '0px' }}>
              Referral Screening Summary
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4 style={{ marginTop: '-5px' }}>
              A referral has been sent for the following program(s):
            </h4>
            <ul style={{ fontSize: '15px', fontWeight: 800 }}>
              {props.eligibleBenefits
                .filter(item => item.checked)
                .map((item: any) => (
                  <li key={item.name}>{item.name}</li>
                ))}
            </ul>
          </div>

          <div style={{ marginTop: '10px' }}>
            Please note to screen the entire household for additional programs
            you will need information about each member of the household,
            including income information, etc. Click here if you would like to{' '}
            <Link
              to={`${baseUrl}/benefitsfinderWV`}
              className="link-color pe-button-focus"
              style={{ textDecoration: 'underline' }}
            >
              screen the entire household for additional programs.
            </Link>
          </div>
          <div style={{ marginTop: '10px' }}>
            Click here to return to{' '}
            <Link
              to={`${baseUrl}/myaccount/eligibleApplicants`}
              className="link-color pe-button-focus"
              style={{ textDecoration: 'underline' }}
            >
              Eligible Applicants.
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default class ViewResults extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
      show: false,
      eligibleBenefits: props.eligibleBenefits,
      notEligibleBenefits: props.notEligibleBenefits
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.eligibleBenefits.length !== state.eligibleBenefits.length ||
      props.notEligibleBenefits.length !== state.notEligibleBenefits.length
    ) {
      return {
        eligibleBenefits: props.eligibleBenefits,
        notEligibleBenefits: props.notEligibleBenefits
      }
    }
    return null
  }

  _handleClick = item => {
    const eligibleBenefits = this.state.eligibleBenefits
    const index = _.findIndex(eligibleBenefits, function(obj) {
      return obj.id === item.id
    })
    if (item.checked) {
      eligibleBenefits[index].checked = false
    } else {
      eligibleBenefits[index].checked = true
    }
    this.setState(prev => {
      return {
        ...prev,
        eligibleBenefits: eligibleBenefits
      }
    })
  }

  cancel = () => {
    this.setState({
      show: false,
      redirect: `${baseUrl}/myaccount/eligibleApplicants`
    })
  }

  _showModal = appNum => {
    const filterMap = _.omitBy(
      this.props.eligibleBenefits,
      benefit => !benefit.checked
    )
    const map = _.map(filterMap, 'id')
    const obj = { programList: map, eligAppNum: appNum }
    this.createReferral(obj)
  }

  createReferral = eligibleBenefits => {
    serviceHelper
      .fetchJson(
        referralSubmit,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Accept: 'application/json',
            uuid: this.props.auth.userAccount
              ? this.props.auth.userAccount.uuid || ''
              : '',
            tenantCode: config.tCode,
            Authorization: config.bearer + (this.props.auth.accessToken || ''),
            portalName: config.portalName
          },
          body: JSON.stringify(eligibleBenefits)
        },
        this.props.showErrorMessage
      )
      .then(response => {
        if (!response.error) {
          this.setState({
            show: true
          })
        }
      })
      .catch(error => {
        if (error instanceof serviceHelper.UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: `${baseUrl}/home` })
        }
        console.log('Failure')
        this.setState({
          errors: true
        })
      })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }
    let tab = this.state.tab
    let show = this.state.show
    let name = ''
    let eligAppNum = ''
    if (this.props.addEligibleAppData) {
      const client = this.props.addEligibleAppData.Application.clients[0]
      name = `${client.clientFstNm}  ${client.clientLstNm}`
      eligAppNum = this.props.addEligibleAppData.Application.eligAppNum
    }
    const { eligibleBenefits, notEligibleBenefits } = this.state

    if (!_.isEmpty(this.props.formContext)) {
      if (tab == 0) {
        return (
          <div>
            <h1 className="h1-title">View Results</h1>
            <div className="col-12">
              {eligibleBenefits && eligibleBenefits.length > 0 && (
                <h2 className="h2-title">
                  {name} is possibly eligible for the following program(s). A
                  referral will be submitted for the selected program(s) below
                  upon clicking the submit button:
                </h2>
              )}
              <div className="col-12">
                {eligibleBenefits.map((item: any) => (
                  <FormCheck
                    key={item.id}
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => this._handleClick(item)}
                    label={item.name}
                    id={item.id}
                  ></FormCheck>
                ))}
              </div>
            </div>
            <div className="col-12">
              {notEligibleBenefits && notEligibleBenefits.length > 0 && (
                <h2 className="h2-title">
                  {name} appears NOT to be eligible for the program(s) below:
                </h2>
              )}
            </div>
            <div className="col-12">
              <ul
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif'
                }}
              >
                {notEligibleBenefits.map((item: any) => (
                  <li key={item.id}>{item.name}</li>
                ))}
              </ul>
            </div>
            {eligibleBenefits && eligibleBenefits.length > 0 && (
              <div className="screen-results float-right">
                <Button
                  variant="outline-dark"
                  onClick={() => this._showModal(eligAppNum)}
                  disabled={!_.find(eligibleBenefits, obj => obj.checked)}
                >
                  Submit Referrals
                </Button>
              </div>
            )}
            <ScreenBenefitsModal
              show={show}
              cancel={this.cancel}
              eligibleBenefits={this.props.eligibleBenefits}
            />
          </div>
        )
      } else {
        return (
          <div>
            <h1>View Results</h1>
            <h2>NO RESULTS DISPLAYED</h2>
          </div>
        )
      }
    } else return null
  }
}
