import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import {
  isEqual,
  isObject,
  isEmpty,
  mergeWith,
  cloneDeep,
  unset,
  noop,
  isNil,
  get
} from 'lodash'
import { config } from '../../../config'
import { v4 } from 'uuid'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'
const _ = {
  isEqual,
  isObject,
  isEmpty,
  mergeWith,
  cloneDeep,
  unset,
  noop,
  isNil,
  get
}

const baseUrl = process.env.PUBLIC_URL

const formsEndpoint = config['forms_Applications']
const getPdfEndpoint = config['getPdfApplication']
const getCommunityPartnerApplicationsInfo =
  config['getCommunityPartnerApplicationsInfo']

interface SearchApplicationsContainerProps {
  presentation: any
  uuid: string
  userRoleId: number
  locale: string
  authHeader: string
}

interface SearchApplicationsContainerState {
  formData: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formContext: any
  reviewFormContext: any
  formDataSubmitted: false
  redirect: string
  formKey: string
}

class SearchApplicationsContainer extends React.Component<
  SearchApplicationsContainerProps,
  SearchApplicationsContainerState
> {
  constructor(props: SearchApplicationsContainerProps) {
    super(props)
    this.state = {
      formData: {},
      forms: null,
      formContext: null,
      reviewFormContext: null,
      formDataSubmitted: false,
      redirect: null,
      formKey: v4()
    }
  }

  componentDidMount() {
    this._loadApp()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      helper
        .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(json => {
          const forms = json['forms']
          helper.cleanNullValues(forms)
          this.setState(prevState => {
            return {
              forms,
              formContext: {
                ...prevState.formContext,
                forms
              }
            }
          })
        })
        .catch(error => {
          console.error(
            'SearchApplications form re-fetch failed due to ex',
            error
          )
          const code = CODES.SEARCH_APPLICATIONS_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _loadApp = () => {
    this._getFormDataJSON()
  }

  _getFormDataJSON = () => {
    let jsonData = {
      applicationType: 'Application',
      source: 'Myself',
      searchApplicationsResult: []
    }

    helper
      .fetchJson(getCommunityPartnerApplicationsInfo, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          userRoleId: this.props.userRoleId.toString(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        },
        body: JSON.stringify(jsonData)
      })
      .then(jsonApplData => {
        helper
          .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              locale: (this.props.locale + '_us').toUpperCase(),
              tenantCode: config.tenant.code,
              portalName: config.portalName,
              uuid: this.props.uuid,
              Authorization: this.props.authHeader
            }
          })
          .then(json => {
            const applicationSearchSchema = json
            //const applicationSearchSchema =  searchAppJson;
            const forms = applicationSearchSchema['forms']
            helper.cleanNullValues(forms)
            helper.cleanNullValues(jsonApplData)
            //let applicationsResult = {"applicationsResult" : jsonData.searchApplicationsResult};
            this._loadForm(forms, jsonApplData)
          })
          .catch(error => {
            console.error(
              'SearchApplications form fetch failed due to ex',
              error
            )
            const code = CODES.SEARCH_APPLICATIONS_FETCH_FORM
            if (shouldThrow(code)) {
              this.setState(() => {
                if (error instanceof helper.IEServiceError) {
                  throw error
                } else {
                  throw new ClientPortalException(error, code)
                }
              })
            }
          })
      })
      .catch(error => {
        console.error(
          'SearchApplications getCommunityPartnerApplicationsInfo failed with ex',
          error
        )
        const code = CODES.SEARCH_APPLICATIONS_FETCH_PARTNER_INFO
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  /**
   * @Description: Load the EventHistory tab.
   *
   */
  _loadForm = (forms: Array<any>, formData: any) => {
    let formContext: object
    formContext = {
      forms: forms,
      setRedirect: this._setRedirect,
      viewPDFApplication: this._viewPDFApplication,
      hasKeyedAny: this._hasKeyedAny,
      config
    }

    let formDataLoad: object = formData

    if (_.isObject(formDataLoad) && !_.isEmpty(formDataLoad)) {
      //Merge the pre-filled form data with an empty default object to ensure necessary objects are available for RJSF rendering.
      formData = _.mergeWith(
        helper.createObjectFromMasterSchema(null, formContext),
        formDataLoad,
        function(objValue, srcValue) {
          if (srcValue == null && objValue == undefined) {
            return ''
          }
          return undefined
        }
      )
    } else {
      //New application, initialize to empty object - does this work?
      formData = helper.createObjectFromMasterSchema(null, formContext)
    }

    //Augment the context with formData
    formContext = {
      ...formContext,
      formData: formData
    }

    this.setState({ forms, formData, formContext })
  }

  _setRedirect = (path: string) => {
    this.setState({ redirect: path })
  }

  convertDataURIToBinary = (dataURI: string) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }

  _viewPDFApplication = (applicationId: string) => {
    const fetchEndpoint = getPdfEndpoint.replace('{applId}', applicationId)

    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(response => {
        let content = response.fileContent
        let contentType = 'application/pdf'
        var pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
        var bytearray = this.convertDataURIToBinary(pdfAsDataUri)

        var file = new Blob([bytearray], {
          type: contentType
        })
        // for IE 10/11 save doc pop up will appear
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file)
        } else {
          var fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          // auto download the file
          var a = document.createElement('a')
          a.href = fileURL
          a.target = '_blank'
          a.download = 'application.pdf'
          document.body.appendChild(a)
          a.click()
        }
      })
      .catch(error => {
        console.error(
          'SearchApplications _viewPDFApplication failed with ex',
          error
        )
        const code = CODES.SEARCH_APPLICATIONS_VIEW_PDF
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _searchApplications = () => {
    let se = this.state.formData

    if (!this._hasKeyedAny()) {
      // no valid input to search
      console.error(
        'SearchApplications _searchApplications missing required fields to perform search'
      )
    } else {
      let tempSearch = _.cloneDeep(this.state.formData)
      // delete non SearchEvent props from 'formData' before submitting form
      _.unset(tempSearch, 'sectionOneHeader')
      _.unset(tempSearch, 'searchApplicationsResult')
      this._cleanFormData(tempSearch)

      helper
        .fetchJson(getCommunityPartnerApplicationsInfo, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: this.props.uuid,
            userRoleId: this.props.userRoleId.toString(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            Authorization: this.props.authHeader
          },
          body: JSON.stringify(tempSearch)
        })
        .then(json => {
          let formData = se
          formData.searchApplicationsResult = json.searchApplicationsResult
          this._cleanFormData(formData)
          this._onFormDataChange({ formData })
        })
        .catch(error => {
          console.error(
            'SearchApplications _searchApplications failed with ex ',
            error
          )
          const code = CODES.SEARCH_APPLICATIONS_SEARCH_PARTNER_INFO
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  // Remove null values from the form data
  _cleanFormData(obj: any) {
    var k,
      has = Object.prototype.hasOwnProperty.bind(obj)
    for (k in obj)
      if (has(k)) {
        switch (typeof obj[k]) {
          case 'object':
            // clear element in the object and then delete the object
            this._cleanFormData(obj[k])
            if (
              obj[k] === null ||
              obj[k] === undefined ||
              obj[k] === '' ||
              Object.keys(obj[k]).length === 0
            ) {
              delete obj[k]
            }
            break
          default:
            if (obj[k] === null || obj[k] === undefined || obj[k] === '') {
              delete obj[k]
            }
            break
        }
      }
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(
      {
        formData,
        formContext: {
          ...this.state.formContext,
          formData: formData
        }
      },
      () => this._refreshFormContext()
    )
  }

  // refresh formcontext since it works with formdata
  _refreshFormContext = () => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        formData: helper.deepClone(this.state.formData)
      }
    })
  }

  _clearForm = () => {
    let formData = _.cloneDeep(this.state.formData)
    formData.applicationType = _.noop()
    formData.applicationId = _.noop()
    formData.source = _.noop()
    formData.clientFstName = _.noop()
    formData.clientLstName = _.noop()
    formData.dob = _.noop()
    formData.clientSSN = _.noop()
    formData.appStatus = _.noop()
    formData.fromDate = _.noop()
    formData.toDate = _.noop()
    this.setState({
      formData,
      formContext: {
        ...this.state.formContext,
        formData: formData
      },
      formKey: v4()
    })
  }

  _hasKeyedAny = (): boolean => {
    const se = this.state.formData
    let hasKeyedAny = false
    if (
      !_.isNil(se.applicationId) ||
      !_.isNil(se.applicationType) ||
      !_.isNil(se.source) ||
      !_.isNil(se.clientFstName) ||
      !_.isNil(se.clientLstName) ||
      !_.isNil(se.dob) ||
      !_.isNil(se.clientSSN) ||
      !_.isNil(se.appStatus) ||
      !_.isNil(se.fromDate) ||
      !_.isNil(se.toDate)
    ) {
      hasKeyedAny = true
    }
    return hasKeyedAny
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={baseUrl + '/application?applId=' + this.state.redirect} />
      )
    }

    const { presentation, locale } = this.props
    const { formContext, formData, formKey, forms } = this.state

    const presentationProps = {
      forms,
      formData,
      formContext,
      onFormDataChange: this._onFormDataChange,
      hasKeyedAny: this._hasKeyedAny,
      searchApplications: this._searchApplications,
      clearForm: this._clearForm,
      formKey,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    userRoleId,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

export default connect(mapStateToProps)(SearchApplicationsContainer)
