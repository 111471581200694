import * as React from 'react'
import { Modal } from 'react-bootstrap'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

interface MyDashboardPresentationProps {
  loadScreen: boolean
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  onFormDataChange: (formData: any) => void
  showUploadModal: boolean
  closeUploadModal: () => void
  onFormDataChangeModal: (formData: any) => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  locale: string
}

export default function MyDashboardPresentation(
  props: MyDashboardPresentationProps
) {
  const {
    loadScreen,
    forms,
    formData,
    formContext,
    onFormDataChange,
    showUploadModal,
    closeUploadModal,
    onFormDataChangeModal,
    formComponents,
    locale
  } = props

  let output = (
    <div className="container-fluid reports-container">
      <ScreenPreLoader loaded={loadScreen} />
    </div>
  )

  if (loadScreen) {
    output = (
      <div>
        <div>
          <Form
            schema={forms[0]['schema']}
            uiSchema={forms[0]['uiSchema']}
            formData={formData}
            formContext={formContext}
            onChange={onFormDataChange}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
            locale={locale}
          >
            <button type="submit" style={{ display: 'none' }} />
          </Form>
        </div>
        <Modal show={showUploadModal} onHide={closeUploadModal}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="col-md-12">
              <Form
                schema={forms[1]['schema']}
                uiSchema={forms[1]['uiSchema']}
                formData={formData.overridePeData}
                formContext={formContext}
                onChange={onFormDataChangeModal}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                locale={locale}
              >
                <button type="submit" style={{ display: 'none' }} />
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn" onClick={closeUploadModal}>
              {I18n.t('General.cancel')}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('MyDashboard.title')}</title>
      </Helmet>
      {output}
    </>
  )
}
