import {
  requireField,
  expectAlphabets,
  requireFieldToAnotherId
} from '@optum-wvie/dynamic-ui-framework/src/validation'
import { I18n } from 'react-redux-i18n'

export function validatePE_CPForm(formData, errors, step) {
  const validateAll = step == null
  let validationStep = null

  //testing access
  //Pre-Questionnaire validation
  if (step === 1 || validateAll) {
    validationStep = 1
    requireField(
      'Application_clients_0' + '_clientFstNm',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.firstNameRequiredError')
    )
    requireField(
      'Application_clients_0' + '_clientLstNm',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.lastNameRequiredError')
    )
    expectAlphabets(
      'Application_clients_0' + '_clientFstNm',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.firstNameInvalidError')
    )
    expectAlphabets(
      'Application_clients_0' + '_clientLstNm',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.lastNameInvalidError')
    )

    requireField(
      'Application_clients_0' + '_clientBthDt',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.birthDateRequiredError')
    )

    requireField(
      'Application_addressDetails_0' + '_clientHomelessFlg',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.homelessInformationRequiredError')
    )

    requireField(
      'Application_addressDetails_0' + '_addrSameAsPrimApplicant',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.mailingAddressPrimaryRequiredError')
    )

    if (formData.Application.addressDetails[0].clientHomelessFlg === 'N') {
      requireFieldToAnotherId(
        'Application_addressDetails_0' + '_adrLn1Txt',
        'Application_addressDetails_0_address',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.addressLine1RequiredError')
      )
      requireField(
        'Application_addressDetails_0' + '_county',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.countyRequiredError')
      )
      requireFieldToAnotherId(
        'Application_addressDetails_0' + '_ctyNm',
        'Application_addressDetails_0_address',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.cityRequiredError')
      )
      requireFieldToAnotherId(
        'Application_addressDetails_0' + '_st',
        'Application_addressDetails_0_address',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.stateRequiredError')
      )
      requireFieldToAnotherId(
        'Application_addressDetails_0' + '_zip',
        'Application_addressDetails_0_address',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.zipRequiredError')
      )
    }

    if (
      formData.Application.addressDetails[0].addrSameAsPrimApplicant === 'N'
    ) {
      requireFieldToAnotherId(
        'Application_secondaryAddressDetails_0' + '_adrLn1Txt',
        'AppIntake_Application_secondaryAddressDetails_0_address',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.secondaryAddressLine1RequiredError')
      )
      requireField(
        'Application_secondaryAddressDetails_0' + '_county',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.secondaryCountyRequiredError')
      )
      requireFieldToAnotherId(
        'Application_secondaryAddressDetails_0' + '_ctyNm',
        'AppIntake_Application_secondaryAddressDetails_0_address',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.secondaryCityRequiredError')
      )
      requireFieldToAnotherId(
        'Application_secondaryAddressDetails_0' + '_st',
        'AppIntake_Application_secondaryAddressDetails_0_address',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.secondaryStateRequiredError')
      )
      requireFieldToAnotherId(
        'Application_secondaryAddressDetails_0' + '_zip',
        'AppIntake_Application_secondaryAddressDetails_0_address',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.secondaryZipRequiredError')
      )
    }
  }

  //CitizenShip validation
  if (step === 2 || validateAll) {
    validationStep = 2
    requireField(
      'Application_clients_0' + '_usCtznOrNatInd',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.usCitizenRequiredError')
    )

    if (formData.Application.clients[0].usCtznOrNatInd === 'N') {
      requireField(
        'Application_clients_0' + '_immgStatusCd',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.immigrationStatusRequiredError')
      )
      requireField(
        'Application_clients_0' + '_immigrationStatusGrant',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.immigrationGrantDateRequiredError')
      )
      requireField(
        'Application_clients_0' + '_flfl40QualWrkQtr',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.40QualifyingWorkQuarterRequiredError')
      )
    }
  }

  //Income and Resource validation
  if (step === 3 || validateAll) {
    validationStep = 3
    for (let i = 0; i < formData.Application.clients.length; ++i) {
      requireField(
        'Application_clients_0' + '_exptMagiIncm',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.numberInTaxHouseholdRequiredError')
      )
      requireField(
        'Application_clients_0' + '_exptIncmThsYr',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.householdEstimatedIncomeRequiredError')
      )
      requireField(
        'Application_clients_0' + '_exptPeriod',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.incomePeriodRequiredError')
      )
    }
  }

  //Additional Question validation
  if (step === 5 || validateAll) {
    validationStep = 5

    requireField(
      'Application_benefitProgramInformations_0' + '_isPregnantNow',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.currentPregnancyInformationRequiredError')
    )
    requireField(
      'Application_benefitProgramInformations_0' + '_isApproved',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.peApprovalInLast12MonthsRequiredError')
    )
    if (formData.Application.benefitProgramInformations[0].isApproved === 'Y') {
      requireField(
        'Application_benefitProgramInformations_0' + '_isPregnantWhenApproved',
        errors,
        formData,
        validationStep,
        I18n.t('Validation.pregnancyInformationRequiredError')
      )

      if (
        formData.Application.benefitProgramInformations[0]
          .isPregnantWhenApproved === 'Y'
      ) {
        requireField(
          'Application_benefitProgramInformations_0' + '_isPregnancyEnded',
          errors,
          formData,
          validationStep,
          I18n.t('Validation.pregnancyEndInformationRequiredError')
        )
        if (
          formData.Application.benefitProgramInformations[0]
            .isPregnancyEnded === 'Y' &&
          !formData.Application.benefitProgramInformations[0].pregEndDate
        ) {
          requireField(
            'Application_benefitProgramInformations_0' + '_pregEndDt',
            errors,
            formData,
            validationStep,
            I18n.t('Validation.pregnancyEndInformationFormatOrFutureDateError')
          )
        }
      }
    }
    requireField(
      'Application_benefitProgramInformations_0' + '_isCovered',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.medicaidOrChipCoverageInformationRequiredError')
    )
    requireField(
      'Application_fosterclients_0' + '_fostercare',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.fosterCareInformationRequiredError')
    )

    requireField(
      'Application_cancerclients_0' + '_breastAndCervicalCancerPatients',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.breastOrCervicalCancerInformationRequiredError')
    )

    requireField(
      'Application_caretakerclients_0' + '_parentorcaretaker',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.parentCaretakerInformationRequiredError')
    )
  }

  //Review & Sign validation
  if (step === 6 || validateAll) {
    validationStep = 6
    requireField(
      'Application_clients_0' + '_presumEligStartDate',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.peStartDateRequiredError')
    )
    requireField(
      'Application_clients_0' + '_agreement',
      errors,
      formData,
      validationStep,
      I18n.t('Validation.coveredByMedicaidOrChipRequiredError')
    )
  }

  console.log('PEValidation errors', errors)

  return errors
}
