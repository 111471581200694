import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { config } from '../../../config'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import * as validator from '../src/validation'
import * as serviceHelper from '../src/components/utils'
import {
  UnauthorizedError,
  getServerTime,
  orderErrors
} from '../src/components/utils'
import * as actions from '../../../actions'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { Redirect } from 'react-router-dom'
import * as queryString from 'query-string'

const formsEndpoint = config['formsBaseUrl'] + config['forms_benH']
const countiesBaseUrl = config['gatewayWvUrl'] + config['countiesStateUrl']
const removeEligApp = config['gatewayWvUrl'] + config['removeEligApp']
const addOrUpdateEligApp = config['gatewayWvUrl'] + config['addOrUpdateEligApp']
const getEligAppData = config['gatewayWvUrl'] + config['getEligAppData']
const searchEligAppData = config['gatewayWvUrl'] + config['searchEligAppData']
const getEligAppIdDetail = config['gatewayWvUrl'] + config['getEligAppIdDetail']
declare const process
const baseUrl = process.env.PUBLIC_URL

function identifyObjRemoved(orgArr, changedArr, type) {
  if (orgArr.length === 0) return changedArr
  if (changedArr.length === 0) return orgArr
  for (let i = 0; i < orgArr[0].length; i++) {
    for (let j = 0; j < changedArr[0].length; j++) {
      if (type === 'phone') {
        if (+orgArr[0][i].phoneId === +changedArr[0][j].phoneId) {
          orgArr[0][i]['found'] = true
          break
        }
      } else if (type == 'diag') {
        if (+orgArr[0][i].diagId === +changedArr[0][j].diagId) {
          orgArr[0][i]['found'] = true
          break
        }
      }
    }
  }
  return orgArr
}

interface BenHEligibleApplicantContainerProps {
  presentation: any
  location: {
    search: string
  }
  isLoading: boolean
  showErrorMessage: (message: any) => void
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  selectedEntitlements: Array<string>
  userAccess: {
    selectedOrg: {
      orgId: any
      orgName: any
      ieOrgId: any
    }
    selectedUserRole: {
      userRoleId: any
      uuid: any
      role: {
        roleId: any
        roleName: any
      }
    }
  }
  logoutUser
  history: {
    push: (url: string) => void
  }
  removeEligibleId: () => void
  errorMessage: Array<any>
  deleteErrorMessage: () => void
}

interface BenHEligibleApplicantContainerState {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  formContext: any
  eligibleApplicantList: any
  organizations: Array<any>
  liveValidate: boolean
  eligibleApplicantSearch: any
  showAddEligibleAppView: boolean
  showModal: boolean
  addEligibleAppData: any
  currentDate: any
  isRemoveClicked: boolean
  modalMessage: string
  showExitModal: boolean
  callingApi: boolean
  redirect: any
  origEligibleAppData: any
  hasError: boolean
}
class BenHEligibileApplicantContainer extends React.Component<
  BenHEligibleApplicantContainerProps,
  BenHEligibleApplicantContainerState
> {
  private hasError: boolean = false
  private currentDate
  constructor(props: BenHEligibleApplicantContainerProps) {
    super(props)
    this.state = {
      form: null,
      formContext: {
        formData: {}
      },
      eligibleApplicantList: [],
      organizations: null,
      liveValidate: false,
      eligibleApplicantSearch: null,
      showAddEligibleAppView: false,
      showModal: false,
      addEligibleAppData: this.emptyAddObject(),
      currentDate: getServerTime(),
      isRemoveClicked: false,
      modalMessage: null,
      showExitModal: false,
      callingApi: false,
      redirect: null,
      origEligibleAppData: [],
      hasError: false
    }
  }

  componentDidMount() {
    document.title = 'Eligible Applicants - My Account'
    this.currentDate = getServerTime()
    this._initializeForm()
    const subPath = window.location.href.match(
      '\\/eligibleApplicants\\/([a-zA-Z0-9]+)'
    )
    if (subPath != null) {
      const obj = {
        eligibleApplicantNumber: subPath[1]
      }
      this.props.removeEligibleId()
      document.title = 'Eligible Applicant - My Account'
      this.getEligAppIdDetail$(obj)
    } else {
      this.getEligAppData$()
    }
  }

  _initializeForm = () => {
    let uuid = ''
    if (this.props.auth && this.props.auth.userAccount) {
      uuid = this.props.auth.userAccount.uuid
    }
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    this.setState({ callingApi: true })
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(form => {
        this.setState({ callingApi: false })
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            liveValidate: false,
            form,
            formContext: {
              ...prevState.formContext,
              forms: [{ ...form }]
            }
          }
        })
      })
      .catch(error => {
        this.setState({ callingApi: false })
        console.error('BenH _initializeForm error', error)
      })
    let formContext = {
      ...this.state.formContext,
      viewEligibleApplicant: this._viewEligibleApplicant,
      viewReferral: this._viewReferral,
      selectedRoleName: this.props.userAccess.selectedUserRole.role.roleName,
      refs: {
        '{cnty1}': 'return formContext.cnty1',
        '{is edit}': 'return formContext.isEdit',
        '{is editable}': 'return formContext.isEditable',
        '{Star Child}': ({ formContext }) => {
          if (
            formContext.formData.Application &&
            formContext.formData.Application.clients &&
            formContext.formData.Application.clients[0].clientBthDt
          ) {
            let birthdate = _moment(config, this.currentDate).diff(
              formContext.formData.Application.clients[0].clientBthDt,
              'years',
              false
            )
            if (birthdate < 18) {
              return '*'
            }
          }
          return ''
        }
      }
    }
    this.setState({ formContext })
    this._defaultStateCounties()
  }

  _setRedirect = url => {
    this.setState({ redirect: baseUrl + url })
  }

  _defaultStateCounties = () => {
    let uuid = ''
    if (this.props.auth && this.props.auth.userAccount) {
      uuid = this.props.auth.userAccount.uuid
    }
    const request = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        uuid: uuid,
        tenantcode: config.tCode,
        authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalname: config.portalName
      }
    }
    const fetchEndpoint = countiesBaseUrl.replace('{state}', 'WV')
    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      .then(counties => {
        if (counties) {
          this.setState(prevState => {
            return {
              ...prevState,
              formContext: {
                ...prevState.formContext,
                cnty1: counties.counties,
                cnty: counties.counties
              }
            }
          })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          console.log(error)
        }
      })
  }

  _onValidate(formData: any, errors: object) {
    const { showAddEligibleAppView } = this.state
    validator.validateEligAppForm(formData, errors, 0, showAddEligibleAppView)
    return errors
  }

  _onAppFormValidate(formData: any, errors: object) {
    const { showAddEligibleAppView } = this.state
    validator.validateEligAppForm(formData, errors, 1, showAddEligibleAppView)
    return errors
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({
      eligibleApplicantSearch: formData,
      formContext: {
        ...this.state.formContext,
        formData: formData
      }
    })
  }

  _onAppFormDataChange = ({ formData }) => {
    let formData1 = helper.deepClone(formData)
    if (formData && formData.Application.clients[0].clientBthDt != undefined) {
      let birthdate = _moment(config, this.state.currentDate).diff(
        formData.Application.clients[0].clientBthDt,
        'years',
        false
      )
      formData1.Application.clients[0].clientAge = birthdate
    }
    if (
      formData &&
      formData.Application.clients[0].medicalEligibilityDate != undefined
    ) {
      let recertDate = _moment(
        config,
        formData.Application.clients[0].medicalEligibilityDate
      )
        .add(1, 'years')
        .format('YYYY-MM-DD')
      formData1.Application.clients[0].recertificationDate = recertDate
    }
    if (
      formData &&
      formData.Application.remove[0].removeReason != undefined &&
      formData.Application.remove[0].removeReason.value != undefined
    ) {
      formData1.Application.remove[0].removedDate = _moment(
        config,
        this.state.currentDate
      ).format('YYYY-MM-DD')
    } else {
      formData1.Application.remove[0].removedDate = undefined
    }
    this.setState(prevState => {
      return {
        ...prevState,
        addEligibleAppData: formData1,
        formContext: {
          ...this.state.formContext,
          formData: formData1
        }
      }
    })
  }

  _transformErrors = (errors: object) => {
    return errors
  }

  _errorListTemplate = (props: any) => {
    const { errors, schema, uiSchema } = props

    const masterSchema = helper.resolveSchemaDefinitions(
      schema,
      schema.definitions
    )

    const orderedErrors = orderErrors(errors, masterSchema, uiSchema)

    return (
      <div className="panel panel-danger errors" id="benh-errors" tabIndex={-1}>
        <div className="panel-heading">
          <h2 className="panel-title">Errors</h2>
        </div>
        <ul className="list-group">
          {orderedErrors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <li className="list-group-item text-danger" key={i}>
                <a
                  href="#"
                  onClick={
                    errorObject ? () => this._onErrorClick(errorObject) : null
                  }
                >
                  {errorObject
                    ? errorObject.displayMessage
                    : error.stack.substring(error.stack.indexOf(':') + 1)}
                  <i
                    style={{ marginLeft: '5px' }}
                    className="fa fa-eye"
                    aria-hidden="true"
                  />
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
  _exitFromApplication = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showExitModal: !prevState.showExitModal,
        formContext: {
          ...this.state.formContext
        }
      }
    })
  }

  _cancelRemove = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showModal: !prevState.showModal,
        isRemoveClicked: false,
        formContext: {
          ...this.state.formContext
        }
      }
    })
  }

  _hideModalMessage = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showModal: !prevState.showModal,
        showAddEligibleAppView: false,
        liveValidate: false,
        hasError: false
      }
    })
    const subPath = window.location.href.match(
      '\\/eligibleApplicants\\/([a-zA-Z0-9]+)'
    )
    if (subPath != null) {
      this.props.history.push(baseUrl + '/myaccount/eligibleApplicants')
    }
    document.title = 'Eligible Applicants - My Account'
    this.getEligAppData$()
  }

  _hideExitModalMessage = () => {
    this.setState({
      showExitModal: false,
      showAddEligibleAppView: false,
      liveValidate: false,
      hasError: false
    })
    const subPath = window.location.href.match(
      '\\/eligibleApplicants\\/([a-zA-Z0-9]+)'
    )
    if (subPath != null) {
      this.props.history.push(baseUrl + '/myaccount/eligibleApplicants')
    }
    this.getEligAppData$()
  }

  _exitAddScreen = () => {
    const liveValidate = this.state.liveValidate
    this.setState({
      showExitModal: true,
      liveValidate: liveValidate ? true : false
    })
  }

  _onErrorClick = error => {
    const expandLinks = Array.from(
      document.getElementsByClassName('collapsed btn btn-link btn-block')
    )
    expandLinks.forEach((expandLinkId: HTMLElement) => {
      expandLinkId.click()
    })

    try {
      setTimeout(function() {
        window.requestAnimationFrame(function() {
          const element = document.getElementById(`root_${error.id}`)
          let offset = helper.getOffsetTop(
            document.getElementById(`root_${error.id}`)
          )
          element.focus()
          window.scroll(0, offset - 100)
        })
      }, 0)
    } catch (e) {
      console.error('Benefit Finder viewId setTimeout failed with ex', e)
    }
  }

  _viewEligibleApplicant = props => {
    document.title = 'Eligible Applicant - My Account'
    const obj = props.options.conditionObj.baseObject
    this.getEligAppIdDetail$(obj)
  }

  _viewAddEligibileApp = () => {
    this.setState({
      liveValidate: false,
      formContext: {
        ...this.state.formContext,
        isEdit: false,
        isEditable: false
      },
      showAddEligibleAppView: true,
      addEligibleAppData: this.emptyAddObject(),
      hasError: false
    })
  }

  _viewReferral = props => {
    this.props.history.push(baseUrl + `/myaccount/referrals/${props.value}`)
  }

  _searchEligibleApplicant = () => {
    const { eligibleApplicantSearch } = this.state
    if (eligibleApplicantSearch == null) {
      this.getEligAppData$()
    } else {
      this.searchEligAppData$(eligibleApplicantSearch)
    }
  }

  emptyEligibleSearch = () => {
    return {
      ageFrom: undefined,
      ageTo: undefined,
      county: undefined,
      includeHistory: undefined,
      individualAddDateFrom: undefined,
      individualAddDateTo: undefined,
      lastName: undefined,
      medicalEligibilityDateFrom: undefined,
      medicalEligibilityDateTo: undefined,
      referralStatus: undefined,
      ssn: undefined
    }
  }

  emptyAddObject = () => {
    return {
      Application: {
        tenantId: undefined,
        indvAddDate: undefined,
        eligAppNum: undefined,
        refSummaryStatus: undefined,
        referralTable: [[]],
        comments: [[]],
        diagnosis: [[]]
      }
    }
  }
  _clearSearch = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        eligibleApplicantSearch: null,
        liveValidate: false
      }
    })
  }

  _addEligibleApplicant = ({ formData }) => {
    this.saveEligApp$(formData)
  }

  saveEligApp$ = formData => {
    let newFormData = _.cloneDeep(formData)
    if (this.state.formContext.isEdit) {
      this.identifyRemoved(formData, newFormData)
    }
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      },
      body: JSON.stringify(newFormData.Application)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(addOrUpdateEligApp, request, this.props.showErrorMessage)
      .then(json => {
        this.setState({
          callingApi: false,
          showModal: true,
          modalMessage: 'Save Successful'
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  removeEligApp$ = obj => {
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      },
      body: JSON.stringify(obj)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(removeEligApp, request, this.props.showErrorMessage)
      .then(json => {
        this.setState({
          callingApi: false,
          showModal: false,
          liveValidate: false,
          isRemoveClicked: false,
          showAddEligibleAppView: false
        })
        this.getEligAppData$()
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  getEligAppData$ = () => {
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      }
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(getEligAppData, request, this.props.showErrorMessage)
      .then(json => {
        const objArr = _.get(json, 'eligibleApplicantList')
        objArr.forEach(element => {
          element['eligibleApplicantNumber'] = +element[
            'eligibleApplicantNumber'
          ]
        })
        this.setState(prevState => {
          return {
            ...prevState,
            eligibleApplicantList: objArr,
            liveValidate: false
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_getEligAppData failed with error:', error)
      })
  }

  searchEligAppData$ = formData => {
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      },
      body: JSON.stringify(formData.eligibleApplicantSearch)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(searchEligAppData, request, this.props.showErrorMessage)
      .then(json => {
        const objArr = _.get(json, 'eligibleApplicantList')
        objArr.forEach(element => {
          element['eligibleApplicantNumber'] = +element[
            'eligibleApplicantNumber'
          ]
        })
        this.setState(prevState => {
          return {
            ...prevState,
            eligibleApplicantList: objArr,
            formContext: {
              ...this.state.formContext,
              includeHistory: formData.eligibleApplicantSearch.includeHistory
            }
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  getEligAppIdDetail$ = obj => {
    const queryParameters = {
      eligibleAppntID: obj.eligibleApplicantNumber
    }
    const endpoint =
      getEligAppIdDetail + '?' + queryString.stringify(queryParameters)
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      }
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(endpoint, request, this.props.showErrorMessage)
      .then(json => {
        const obj = _.get(json, 'eligibleApplicantDetailList[0]')
        const removeObj = _.get(obj, 'remove', [])
        if (removeObj.length === 0 || removeObj[0].removedDate == undefined) {
          _.set(obj, 'remove', [
            { removedDate: undefined, removeReason: undefined }
          ])
        }
        const deepCloneObj = _.cloneDeep(obj)
        this.setState({
          liveValidate: false,
          hasError: false,
          formContext: {
            ...this.state.formContext,
            isEdit: true,
            isEditable: obj.referralSummaryStatus != 'Pending',
            formData: { Application: obj }
          },
          showAddEligibleAppView: true,
          addEligibleAppData: { Application: obj },
          origEligibleAppData: { Application: deepCloneObj }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  _switchOnLiveValidate = () => {
    this.setState({
      liveValidate: true
    })
  }

  _setButton = flag => {
    this.setState(prevState => {
      return {
        ...prevState,
        isRemoveClicked: flag,
        liveValidate: true
      }
    })
  }

  _showRemoveModal = () => {
    this.setState({
      callingApi: false,
      showModal: true,
      modalMessage: 'Please confirm removal',
      liveValidate: false,
      isRemoveClicked: true
    })
  }

  _confirmRemove = () => {
    const { addEligibleAppData, isRemoveClicked } = this.state
    if (isRemoveClicked) {
      const removeObj = _.get(addEligibleAppData, 'Application.remove', [])
      const appId = addEligibleAppData.Application.eligAppNum
      const obj = {
        appId: appId,
        remove: removeObj[0]
      }
      if (removeObj.length > 0 && appId != undefined) {
        this.removeEligApp$(obj)
      }
    }
    //this.getEligAppData$()
  }

  _onError = (errors: object) => {
    const { liveValidate, formContext } = this.state

    let foundErrors = helper.findErrors(errors)
    if (foundErrors && foundErrors.length > 0) {
      this.setState({
        hasError: true,
        formContext: {
          ...formContext,
          hasError: true
        }
      })
    } else {
      this.setState({
        hasError: false,
        formContext: {
          ...formContext,
          hasError: false
        }
      })
    }
    //Enable live validation upon error to allow for realtime evaluation of errors in response to formData changes.
    if (!liveValidate) {
      this.setState({
        liveValidate: true,
        formContext: {
          ...formContext,
          hasError: this.hasError
        }
      })
      window.location.href = '#'
    }
    const errorContent = document.getElementById('benh-errors') as HTMLElement
    if (errorContent) {
      window.scroll(0, errorContent.offsetTop)
      errorContent.focus()
    }
  }

  private identifyRemoved(formData: any, newFormData: any) {
    if (formData.Application.eligAppNum != null) {
      const orgPhone = this.state.origEligibleAppData.Application.phones
      const formPhone = formData.Application.phones
      const newArr = identifyObjRemoved(orgPhone, formPhone, 'phone')
      if (newArr.length > 0) {
        for (let i = 0; i < newArr[0].length; i++) {
          const phoneId = _.get(newArr[0][i], 'found', null)
          if (phoneId == null) {
            let obj = newArr[0][i]
            obj['isRemoved'] = true
            newFormData.Application.phones[0].push(obj)
          }
        }
      }

      const orgDiag = this.state.origEligibleAppData.Application.diagnosis
      const formDiag = formData.Application.diagnosis
      const newArr1 = identifyObjRemoved(orgDiag, formDiag, 'diag')
      if (newArr1.length > 0) {
        for (let i = 0; i < newArr1[0].length; i++) {
          const diag = _.get(newArr1[0][i], 'found', null)
          if (diag == null) {
            let obj = newArr1[0][i]
            obj['isRemoved'] = true
            newFormData.Application.diagnosis[0].push(obj)
          }
        }
      }
    }
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }

    const { presentation, userAccess, errorMessage } = this.props
    const {
      form,
      formContext,
      eligibleApplicantSearch,
      showAddEligibleAppView,
      liveValidate,
      showModal,
      addEligibleAppData,
      modalMessage,
      showExitModal,
      eligibleApplicantList,
      hasError,
      isRemoveClicked
    } = this.state
    let validator
    if (form) {
      validator = new CustomValidator(
        form.app.forms[2].schema,
        form.app.forms[2].uiSchema,
        formContext
      )
    }
    const presentationProps = {
      form,
      formContext,
      liveValidate,
      onFormDataChange: this._onFormDataChange,
      errorListTemplate: this._errorListTemplate,
      transformErrors: this._transformErrors,
      onValidate: this._onValidate.bind(this),
      validator,
      searchEligibleApplicant: this._searchEligibleApplicant,
      clearSearch: this._clearSearch,
      addEligibleAppData,
      eligibleApplicantSearch,
      eligibleApplicantList,
      viewAddEligibileApp: this._viewAddEligibileApp,
      showAddEligibleAppView: showAddEligibleAppView,
      addEligibleApplicant: this._addEligibleApplicant.bind(this),
      switchOnLiveValidate: this._switchOnLiveValidate,
      onAppFormDataChange: this._onAppFormDataChange,
      showModal,
      hideModalMessage: this._hideModalMessage,
      onAppFormValidate: this._onAppFormValidate.bind(this),
      setButton: this._setButton,
      modalMessage: modalMessage,
      exitAddScreen: this._exitAddScreen,
      exitFromApplication: this._exitFromApplication,
      showExitModal: showExitModal,
      hideExitModalMessage: this._hideExitModalMessage,
      onError: this._onError,
      userAccess,
      hasError: hasError,
      isRemoveClicked: isRemoveClicked,
      showRemoveModal: this._showRemoveModal,
      confirmRemove: this._confirmRemove,
      cancelRemove: this._cancelRemove,
      errorMessage,
      onDeleteClick: this._onDeleteClick
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    userAccess: state.userAccess,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    removeEligibleId: () => {
      dispatch(actions.removeEligibleId())
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenHEligibileApplicantContainer)
