import * as React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { get, find } from 'lodash'
import { config } from '~/config'
import { render } from 'react-dom'
const _ = { get, find }

export default class LocaleMenu extends React.Component<any, any> {
  private overlayTarget: any

  constructor(props) {
    super(props)
    this.state = {
      showOverlay: false
    }
  }

  render() {
    const { locale, setLocale } = this.props
    const { showOverlay } = this.state
    const popOver = (
      <Popover id="localePopover">
        <Popover.Content>
          <div className="header-popover-panels">
            {config['languages'].map(language => {
              if (language.locale === locale) {
                return (
                  <div key={'selectedLocale_' + language.locale}>
                    <button
                      aria-labelledby={language.name}
                      autoFocus
                      tabIndex={1}
                      onClick={() => {
                        this.setState({ showOverlay: !showOverlay })
                        setLocale(language.locale)
                      }}
                      className="selected btn btn-default"
                    >
                      {language.name}
                    </button>
                  </div>
                )
              } else {
                return (
                  <div
                    key={'unselectedLocale_' + language.locale}
                    onClick={() => setLocale(language.locale)}
                  >
                    <button
                      type="button"
                      className="popover-button btn btn-default"
                      tabIndex={2}
                      aria-labelledby={language.name}
                      onClick={() => {
                        this.setState({ showOverlay: !showOverlay })
                        setLocale(language.locale)
                      }}
                    >
                      {language.name}
                    </button>
                  </div>
                )
              }
            })}
          </div>
        </Popover.Content>
      </Popover>
    )
    return (
      <>
        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={popOver}
          show={showOverlay}
        >
          <button
            type="button"
            id="Header_locale_link"
            className="language-button"
            aria-label={_.find(config['languages'], { locale }).name}
            ref={target => (this.overlayTarget = target)}
            onClick={() => this.setState({ showOverlay: !showOverlay })}
          >
            {_.find(config['languages'], { locale }).name}
          </button>
        </OverlayTrigger>
      </>
    )
  }
}
