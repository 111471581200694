import * as React from 'react'
import {
  Tabs,
  Tab,
  Row,
  Col,
  Nav,
  NavItem,
  Popover,
  OverlayTrigger
} from 'react-bootstrap'
import { BenefitContent } from './BenefitsView'
import MyFavoritesView from './MyFavoritesView'
import * as _ from 'lodash'
import { scrollToTop } from '@optum-wvie/dynamic-ui-framework/src/utils'
import '!style-loader!css-loader!../src/customStyles.css'
import MessageModal from '@optum-wvie/dynamic-ui-framework/src/components/natives/MessageModal'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { I18n } from 'react-redux-i18n'

export interface BenefitCatalogPresentationProps {
  showExitModal: () => void
  redirect: string
  showErrorModal: () => void
  showExit: () => void
  confirmExit
  cancelExit
  hideErrorModal
  myFavorites: Array<string>
  roleId: number
  roleName: string
  handleSelect: (tabKey) => void
  tabKey: string
  addMyFavorite: (name) => void
  removeMyFavorite: (name) => void
  locale: string
  mainContent: any
  errorMessage: Array<any>
  onDeleteClick: () => void
}

function BenefitCatalogHeader(props) {
  return (
    <div className="row">
      <div className="headerpadding">
        <p className="heading print">
          <i className="glyphicons-halflings myglyphicon-book_open myaccount-icon" />{' '}
          {I18n.t('BenefitsCatalog.programsServices')}
        </p>
      </div>
    </div>
  )
}

function ToggleFavoriteButton({ props }) {
  const myFavorites = props.myFavorites
  if (myFavorites && myFavorites.indexOf(props.key) > -1) {
    return (
      <button
        className="btn link my-benefit active"
        onClick={() => {
          props.removeMyFavorite(props.key)
        }}
      >
        {I18n.t('BenefitsCatalog.removeFavorite')}
      </button>
    )
  } else {
    return (
      <button
        className="btn link my-benefit"
        onClick={() => {
          props.addMyFavorite(props.key)
        }}
      >
        {I18n.t('BenefitsCatalog.addFavorite')}
      </button>
    )
  }
}

function BenefitsCatalogContent(props: BenefitCatalogPresentationProps) {
  let helpPopover = (
    <Popover id="helpPopover">
      <a href="/help" target="_blank">
        {I18n.t('Header.helpLink')}
      </a>
    </Popover>
  )
  const {
    myFavorites,
    addMyFavorite,
    removeMyFavorite,
    tabKey,
    handleSelect,
    locale,
    showExitModal,
    showExit,
    confirmExit,
    cancelExit,
    hideErrorModal,
    roleId,
    roleName,
    mainContent
  } = props

  let helpLink = (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={helpPopover}
    >
      <button className="printStyle btn btn-tiny btn-white">
        <span className="fa fa-life-ring btn-icon-sm" />
        {I18n.t('General.helpButtonLabel')}
      </button>
    </OverlayTrigger>
  )
  let exitButton
  if (
    roleName == 'Presumptive Eligibility Worker' ||
    roleName == 'Community Partner'
  ) {
    exitButton = (
      <button className="printStyle btn btn-tiny btn-white" onClick={showExit}>
        <span className="fa fa-life-ring btn-icon-sm" />
        {I18n.t('General.exitButtonLabel')}
      </button>
    )
  }
  let benefitCategoryJSON = [
    {
      benfCatCd: I18n.t('BenefitsCatalog.hewl'),
      benfCatGlyph: 'myglyphicon-heart',
      benfCatHref: '#/catalog/catalogbenefit/hewl/',
      benfCatLongDesc: I18n.t('BenefitsCatalog.hewlDesc'),
      benfCatName: I18n.t('BenefitsCatalog.healthCare'),
      benfCatRoute: 'catalog.catalogbenefit.hewl',
      benfCatShortDesc: I18n.t('BenefitsCatalog.hewlDesc2')
    },
    {
      benfCatCd: I18n.t('BenefitsCatalog.FMAS'),
      benfCatGlyph: 'myglyphicon-parents',
      benfCatHref: '#/catalog/catalogbenefit/fmas/',
      benfCatLongDesc: I18n.t('BenefitsCatalog.famsDesc'),
      benfCatName: I18n.t('BenefitsCatalog.familyAssistance'),
      benfCatRoute: 'catalog.catalogbenefit.fmas',
      benfCatShortDesc: I18n.t('BenefitsCatalog.famsDesc2')
    },
    {
      benfCatCd: I18n.t('BenefitsCatalog.fdnt'),
      benfCatGlyph: 'myglyphicon-apple',
      benfCatHref: '#/catalog/catalogbenefit/fdnt/',
      benfCatLongDesc: I18n.t('BenefitsCatalog.fdntDesc'),
      benfCatName: I18n.t('BenefitsCatalog.foodNutrition'),
      benfCatRoute: 'catalog.catalogbenefit.fdnt',
      benfCatShortDesc: I18n.t('BenefitsCatalog.fdntDesc2')
    },
    {
      benfCatCd: I18n.t('BenefitsCatalog.lien'),
      benfCatGlyph: 'fa-child',
      benfCatHref: '#/catalog/catalogbenefit/lien/',
      benfCatLongDesc: I18n.t('BenefitsCatalog.lienDesc'),
      benfCatName: I18n.t('BenefitsCatalog.childSupport'),
      benfCatRoute: 'catalog.catalogbenefit.lien',
      benfCatShortDesc: I18n.t('BenefitsCatalog.lienDesc2')
    }
  ]
  if (!_.isEmpty(benefitCategoryJSON)) {
    return (
      <div id="benefit-catalog">
        <div className="header-view">
          <h1>{I18n.t('BenefitsCatalog.programsServices')}</h1>
          <div className="header-sub" />
        </div>
        <div className="benfCatBelowHeader">
          <div className="benfCatBelowHeader-item float-end">
            {exitButton}
            {helpLink}
            <button
              className="printStyle print btn btn-tiny btn-white"
              onClick={function() {
                window.print()
              }}
            >
              <span className="fa fa-print btn-icon-sm" />
              {I18n.t('General.printButtonLabel')}
            </button>
          </div>
        </div>
        <Tab.Container
          id="benefits-catalog-tabs"
          activeKey={tabKey}
          onSelect={handleSelect}
        >
          <Row className="clearfix">
            <Col sm={3} id="side-nav">
              <Nav variant="pills" className="flex-column" role="button">
                <NavItem>
                  <Nav.Link eventKey="health-wellness">
                    <span className="font-size-18 glyphicon myglyphicon-heart" />
                    {benefitCategoryJSON[0].benfCatName}
                  </Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link eventKey="family-assistance">
                    <span className="font-size-18 glyphicon myglyphicon-parents" />
                    {benefitCategoryJSON[1].benfCatName}
                  </Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link eventKey="food-nutrition">
                    <span className="font-size-18 glyphicon myglyphicon-apple" />
                    {benefitCategoryJSON[2].benfCatName}
                  </Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link eventKey="NewTab1">
                    <span className="childSupportStyle fa fa-child fa-lg" />
                    {benefitCategoryJSON[3].benfCatName}
                  </Nav.Link>
                </NavItem>
              </Nav>
            </Col>
            <Col sm={9}>
              <div id="content" tabIndex={-1} ref={mainContent}>
                <Tab.Content className="benefits-catalog-content col-12 no-gutter">
                  <Tab.Pane
                    eventKey="health-wellness"
                    className="row"
                    transition={false}
                  >
                    <div className="col-12">
                      <h2 className="titleColor bold">
                        {benefitCategoryJSON[0].benfCatName}
                      </h2>
                      <p>{benefitCategoryJSON[0].benfCatLongDesc}</p>
                    </div>
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'MEDI' }} />
                      {BenefitContent('MEDI')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'MPAP' }} />
                      {BenefitContent('MPAP')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'MLTC' }} />
                      {BenefitContent('MLTC')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'CHIP' }} />
                      {BenefitContent('CHIP')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="family-assistance"
                    className="row"
                    transition={false}
                  >
                    <div className="col-12">
                      <h2 className="titleColor bold">
                        {benefitCategoryJSON[1].benfCatName}
                      </h2>
                      <p>{benefitCategoryJSON[1].benfCatLongDesc}</p>
                    </div>
                    {/* WV WORKS */}
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'TANF' }} />
                      {BenefitContent('TANF')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>

                    {/* School Clothing Allowance */}
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'SCLA' }} />
                      {BenefitContent('SCLA')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>

                    {/*Utility Assistance/LIEAP*/}
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton
                        props={{ ...props, key: 'LIEAP' }}
                      />
                      {BenefitContent('LIEAP')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>

                    {/* 20% Discount Program*/}
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton
                        props={{ ...props, key: '20DPS' }}
                      />
                      {BenefitContent('20DPS')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                    {/*Child Welfare Services*/}
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'CWS' }} />
                      {BenefitContent('CWS')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                    {/* Adult Protective Services (APS)*/}
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'APS' }} />
                      {BenefitContent('APS')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                    {/*Child Care*/}
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'CC' }} />
                      {BenefitContent('CC')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="food-nutrition"
                    className="row"
                    transition={false}
                  >
                    <div className="col-12">
                      <h2 className="titleColor bold">
                        {benefitCategoryJSON[2].benfCatName}
                      </h2>
                      <p>{benefitCategoryJSON[2].benfCatLongDesc}</p>
                    </div>
                    <hr className="col-12" />
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'SNAP' }} />
                      {BenefitContent('SNAP')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="NewTab1"
                    className="row"
                    transition={false}
                  >
                    <div className="col-12">
                      <h2 className="titleColor bold">
                        {benefitCategoryJSON[3].benfCatName}
                      </h2>
                      <p>{benefitCategoryJSON[3].benfCatLongDesc}</p>
                    </div>
                    <div className="col-12">
                      <ToggleFavoriteButton props={{ ...props, key: 'CSS' }} />
                      {BenefitContent('CSS')}
                      <button
                        className="btn link scroll-top"
                        onClick={() => {
                          window.scrollTo(0, mainContent.current.focus())
                        }}
                      >
                        {I18n.t('General.back2Top')}
                      </button>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    )
  } else {
    return (
      <div>
        <h1>{I18n.t('BenefitsCatalog.benefits')}</h1>
        <a href="#side-nav" className="skip">
          {I18n.t('BenefitsCatalog.skiptoSideNav')}
        </a>
        <div id="content">
          <h2>{I18n.t('General.loading')}</h2>
        </div>
      </div>
    )
  }
}

export default function BenefitsCatalogPresentation(
  props: BenefitCatalogPresentationProps
) {
  const {
    showExit,
    showExitModal,
    confirmExit,
    cancelExit,
    showErrorModal,
    hideErrorModal,
    onDeleteClick,
    errorMessage
  } = props

  return (
    <div>
      <BenefitCatalogHeader />
      <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
      <Tabs id="benefits-catalog-nav-tabs" defaultActiveKey={0}>
        <Tab eventKey={'0'} title={I18n.t('BenefitsCatalog.programsServices')}>
          <BenefitsCatalogContent {...props} />
        </Tab>
        <Tab
          eventKey={'1'}
          title={I18n.t('BenefitsCatalog.titleMyFavorites')}
          tabIndex={0}
        >
          <MyFavoritesView myFavorites={props.myFavorites} />
        </Tab>
      </Tabs>

      <div>
        <MessageModal
          show={showExitModal}
          confirm={confirmExit}
          cancel={cancelExit}
          confirmBtnText="Yes"
          closeBtnText="No"
          title={
            <span>
              <h4 className="popup-title">
                {' '}
                {I18n.t('General.exitButtonLabel')}
              </h4>
            </span>
          }
          body={
            <div className="row">
              <div className="col-lg-12">
                {' '}
                <h6 className="headertxt">{I18n.t('General.areuSure')}</h6>
              </div>
            </div>
          }
          bsSize={'lg'}
        />
      </div>
    </div>
  )
}
