import { findIndex, isArray, isObject, isString } from 'lodash' // extra functionality
import { combineReducers } from 'redux'

const _ = { findIndex, isArray, isObject, isString }

export default combineReducers({
  states,
  counties
})

function states(state = [], action) {
  switch (action.type) {
    case 'GET_STATES':
      return action.states
    default:
      return state
  }
}

function counties(state = [], action) {
  switch (action.type) {
    case 'GET_COUNTIES':
      return action.counties
    default:
      return state
  }
}
