import * as React from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { I18n } from 'react-redux-i18n'

const _ = { isEmpty }

interface SearchApplicationsPresentationProps {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  onFormDataChange: (formData: any) => void
  hasKeyedAny: () => boolean
  searchApplications: () => void
  clearForm: () => void
  formKey: string
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  locale: string
}

export default function SearchApplicationsPresentation(
  props: SearchApplicationsPresentationProps
) {
  const {
    forms,
    formData,
    formContext,
    onFormDataChange,
    hasKeyedAny,
    searchApplications,
    clearForm,
    formKey,
    formComponents,
    locale
  } = props

  const disableButtons = !hasKeyedAny()
  if (!_.isEmpty(forms)) {
    return (
      <div>
        <Form
          key={formKey}
          schema={forms[0]['schema']}
          uiSchema={forms[0]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <input type="submit" style={{ display: 'none' }} />
        </Form>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <button
              type="button"
              id="EventHistory_searchEventsBtn"
              className="btn btn-primary float-right"
              disabled={disableButtons}
              onClick={searchApplications}
              style={{ margin: '10px' }}
            >
              {I18n.t('General.searchButton')}
            </button>
            <button
              type="button"
              id="EventHistory_clearSearchBtn"
              className="btn btn-default float-right"
              disabled={disableButtons}
              onClick={clearForm}
              style={{ margin: '10px' }}
            >
              {I18n.t('General.resetButton')}
            </button>
          </div>
        </div>
        <Form
          schema={forms[1]['schema']}
          uiSchema={forms[1]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <input type="submit" style={{ display: 'none' }} />
        </Form>
      </div>
    )
  }
  return <ScreenPreLoader loaded={false} />
}
