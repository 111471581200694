import * as React from 'react'
import '!style-loader!css-loader!../src/customStyles.css'
import { I18n } from 'react-redux-i18n';

export default function ImmigrationStatus() {
  return (
<div>
    <div className="wrapper">
        <h1 className="immmigration-status-h1">{I18n.t('General.titleA1')}</h1>
        <h1 className="immmigration-status-h1">{I18n.t('General.titleA2')}</h1>
        <div>
            <p>
            {I18n.t('General.titleA3')}
            </p>
            <p>
            {I18n.t('General.titleA4')}  <b>{I18n.t('General.titleA5')}</b>
            </p>
        </div>
        <div>
            <p>
            {I18n.t('General.titleA6')}
            </p>
        </div>

        <strong>{I18n.t('General.titleA7')}</strong>
        <ul className="sub">
            <li>
            {I18n.t('General.titleA8')}     
            </li>
            <li>
            {I18n.t('General.titleA9')}      
            </li>
            <li>
            {I18n.t('General.titleA10')} 
            </li>
            <li>
            {I18n.t('General.titleA11')}  
            </li>
            <li>
            {I18n.t('General.titleA12')}
            </li>
            <li>
            {I18n.t('General.titleA13')}  
            </li>
            <li>
            {I18n.t('General.titleA14')}
            </li>
            <li>
            {I18n.t('General.titleA15')}      
            </li>
            <li>
            {I18n.t('General.titleA16')}
            </li>
            <li>
            {I18n.t('General.titleA17')}   
            </li>

        </ul>
        <strong>{I18n.t('General.titleA18')}</strong>
        <ul className="sub">
            <li>
            {I18n.t('General.titleA19')}
            </li>
            <li>
            {I18n.t('General.titleA20')}
            </li>
            <li>
            {I18n.t('General.titleA21')}<i><b>{I18n.t('General.titleA22')}</b></i>
                <ul className="sub">
                    <li>
                    ● <i><b>{I18n.t('General.titleA23')}</b></i> {I18n.t('General.titleA24')}
                    </li>
                    <li>
                    ● <i><b>{I18n.t('General.titleA25')}</b></i>{I18n.t('General.titleA26')}
                        <ul className="sub">
                            <li>
                            ○ {I18n.t('General.titleA27')}
                            </li>
                            <li>
                            ○ {I18n.t('General.titleA28')}
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
            {I18n.t('General.titleA29')}
            </li>
            <li>
                15. <i><b>{I18n.t('General.title30')}</b></i>{I18n.t('General.title31')}
                <ul className="sub">
                    <li>
                    {I18n.t('General.titleA32')} 
                    </li>
                    <li>
                    {I18n.t('General.titleA33')}
                    </li>
                    <li>
                    {I18n.t('General.titleA34')}
                    </li>
                    <li>
                    {I18n.t('General.titleA35')}
                    </li>
                    <li>
                    {I18n.t('General.titleA36')}
                    </li>
                    <li>
                    {I18n.t('General.titleA37')}
                    </li>
                    <li>
                    {I18n.t('General.titleA38')}
                    </li>
                </ul>
            </li>
        </ul>
        <strong>{I18n.t('General.titleA39')}</strong>
        <ul className="sub">
            <li>
            {I18n.t('General.titleA40')}
            </li>
            <li>
            {I18n.t('General.titleA41')}
            </li>
            <li>
            {I18n.t('General.titleA42')}
            </li>
            <li>
            {I18n.t('General.titleA43')}
                <ul className="sub">
                    <li>
                    {I18n.t('General.titleA44')}
                    </li> 
                    <li>
                    {I18n.t('General.titleA45')}
                    </li> 
                    <li>
                    {I18n.t('General.titleA46')}
                    </li> 
                    <li>
                    {I18n.t('General.titleA47')}
                    </li> 
                    <li>
                    {I18n.t('General.titleA48')}
                    </li> 
                    <li>
                    {I18n.t('General.titleA49')}
                    </li> 
                    <li>
                    {I18n.t('General.titleA50')}
                    </li> 
                    <li>
                    {I18n.t('General.titleA51')}
                    </li> 
                </ul>
            </li>
            <li>
            {I18n.t('General.titleA52')}
            </li>
            <li>
            {I18n.t('General.titleA53')}
            </li>
            <li>
            {I18n.t('General.titleA54')}
            </li>
            <li>
            {I18n.t('General.titleA55')}
            </li>
        </ul>
      </div>
    </div>
  )
}