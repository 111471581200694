import * as _ from 'lodash'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { getServerTime } from '../src/components/utils'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'
const moment = require('moment-mini-ts')
const moment_timezone = require('moment-timezone')
const currentDate = getServerTime()
function isEmpty(str) {
  return str === undefined || str === null || str.length === 0
}

function validBirthDateCheck(birthdate, currentDt) {
  //let currDt = moment(getServerTime())
  let currESTDT = moment_timezone
    .tz(currentDt, 'America/New_York')
    .format('YYYY-MM-DD')
  if (birthdate < '1900-01-01' || birthdate > currESTDT) {
    return true
  }
  return false
}

// Code Changes GPRB0359223 and US2819645
function validSSN(ssn) {
  let temp = ssn || ''
  if (ssn.length > 0 && ssn.length < 9) {
    return false
  }
  if (
    temp.substring(0, 3) === '000' ||
    (temp.substring(0, 3) >= '900' && temp.substring(0, 3) <= '999') ||
    temp.substring(0, 3) === '666'
  ) {
    return false
  }
  if (temp.substring(3, 5) === '00' || temp.substring(5, 9) === '0000') {
    return false
  }
  return true
}

export function createErrorJsonString(message, step, id, displayMessage) {
  const error = {
    message,
    step,
    id,
    displayMessage
  }
  return JSON.stringify(error)
}

export function requireField(
  id,
  errors,
  formData,
  step,
  displayMessage,
  requiredFieldErrorMsg = I18n.t('Validation.requiredFieldError')
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (
      _.isObject(localFormData) &&
      _.includes(_.keys(localFormData), 'key') &&
      localFormData.key === undefined
    ) {
      localFormData = undefined
    }
    if (_.isObject(localFormData) && Object.keys(localFormData).length === 0) {
      localFormData = undefined
    }

    if (isEmpty(_.trim(localFormData)) || isEmpty(localFormData)) {
      localErrors.addError(
        createErrorJsonString(requiredFieldErrorMsg, step, id, displayMessage)
      )
    }
  } catch (err) {}
}

export function errorMessage(
  id,
  errors,
  formData,
  step,
  displayMessage,
  requiredFieldErrorMsg = I18n.t('Validation.requiredFieldError')
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    localErrors.addError(
      createErrorJsonString(requiredFieldErrorMsg, step, id, displayMessage)
    )
  } catch (err) {}
}

// Code Changes GPRB0359078 and US2554056
function validZipCodeCheck(zip) {
  if (String(zip).length > 0 && String(zip).length < 5) {
    return true
  }
  return false
}

function validZipExtCheck(zipExt) {
  if (String(zipExt).length > 0 && String(zipExt).length < 4) {
    return true
  }
  return false
}

// Code Changes GPRB0359078 and US2554056
export function notRequiredZipExt(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(
          I18n.t('Validation.invalidZipExt'),
          step,
          id,
          displayMessage
        )
      )
    }
  } catch (err) {}
}

export function notRequiredZip(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }

    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(
          I18n.t('Validation.invalidZip'),
          step,
          id,
          displayMessage
        )
      )
    }
  } catch (err) {}
}

export function requireZipCode(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
  } catch (err) {}
}

export function expectCorrectEmailId(
  id,
  errors,
  formData,
  step,
  displayMessage
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (
      !isEmpty(localFormData) &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(localFormData)
    ) {
      localErrors.addError(
        createErrorJsonString(
          I18n.t('Validation.invalidEmailMessage'),
          step,
          id,
          displayMessage
        )
      )
    }
  } catch (err) {}
}

// Code Changes GPRB0359223 and US2819645
function ssnValidation(id, errors, formData, step, displayMessage) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(
          I18n.t('Validation.inValidSSN'),
          step,
          id,
          displayMessage
        )
      )
    }
  } catch (err) {}
}

// Code changes for GPRB0359133 and US2558381
export function cocBthDtgreaterthanNinteenHundredandLessCurrDt(
  id,
  errors,
  formData,
  step,
  displayMessage
) {
  try {
    let localErrors = errors
    let localFormData = formData
    const schemaPath = id.split('_')
    for (let i = 0; i < schemaPath.length; ++i) {
      localErrors = localErrors[schemaPath[i]]
      localFormData = localFormData[schemaPath[i]]
    }
    if (localFormData) {
      localErrors.addError(
        createErrorJsonString(
          I18n.t('Validation.invalidDOBError'),
          step,
          id,
          displayMessage
        )
      )
    }
  } catch (err) {}
}

export function validate_renewal(
  formData,
  errors,
  step,
  allPrograms,
  prgNames,
  isRenewal,
  currentDate,
  errorJson,
  formContext
) {
  const prgmNameCCA = _.get(formContext, 'CCA', false)
  const prgmNameFDNT = _.get(formContext, 'FDNT', false)
  const prgmNameMDPA = _.get(formContext, 'MDPA', false)
  const prgmNameHEWL = _.get(formContext, 'HEWL', false)
  const prgmNameCHCR = _.get(formContext, 'CHCR', false)
  const prgmNameCHSP = _.get(formContext, 'CHSP', false)
  const prgmNameSCAL = _.get(formContext, 'SCAL', false)
  const totalHouseholdAssets = _.get(
    formData,
    'totalHouseholdAssets',
    undefined
  )
  let applyingFrom = _.get(
    formData,
    'application.APPLYFROM.applyingFrom.key',
    {}
  )
  if (applyingFrom === 'APAPOT94884') {
    requireField(
      'application_APPLYFROM_specifyOthers',
      errors,
      formData,
      step,
      errorJson['specifyOthers']
    )
  }
  let someoneAsstYouFlg = _.get(formData, 'application.someoneAsstYouFlg', '')
  if (someoneAsstYouFlg === 'N' || someoneAsstYouFlg === 'YA') {
    requireField(
      'application_authorizedRepresentative_assisterFstNm',
      errors,
      formData,
      step,
      errorJson['householdInfo'] + errorJson['clientFstNm']
    )
    requireField(
      'application_authorizedRepresentative_assisterLstNm',
      errors,
      formData,
      step,
      errorJson['householdInfo'] + errorJson['clientLstNm']
    )
    requireField(
      'application_authorizedRepresentative_assisterAddress_adrLn1Txt',
      errors,
      formData,
      step,
      errorJson['address'] + errorJson['adrLn1Txt']
    )
    requireField(
      'application_authorizedRepresentative_assisterAddress_ctyNm',
      errors,
      formData,
      step,
      errorJson['address'] + errorJson['cityReq']
    )
    requireField(
      'application_authorizedRepresentative_assisterAddress_st',
      errors,
      formData,
      step,
      errorJson['address'] + errorJson['selectState']
    )
    requireField(
      'application_authorizedRepresentative_assisterAddress_zip',
      errors,
      formData,
      step,
      errorJson['address'] + errorJson['zipReq']
    )
    if (
      (formData.application.someoneAsstYouFlg == 'N' &&
        _.get(
          formData,
          'application.authorizedRepresentative.assisterRoleCd.key',
          {}
        ) === 'CPASAN87219') ||
      (formData.application.someoneAsstYouFlg == 'YA' &&
        _.get(
          formData,
          'application.authorizedRepresentative.assisterRoleCdRepresentative.key',
          {}
        ) === 'CPASAN87219')
    ) {
      requireField(
        'application_authorizedRepresentative_noticeQuestionForRepresentativeOrLegalGuardian',
        errors,
        formData,
        step,
        errorJson['noticeQuestionForRepresentativeOrLegalGuardian']
      )
    }
    if (
      (formData.application.someoneAsstYouFlg == 'N' &&
        _.get(
          formData,
          'application.authorizedRepresentative.assisterRoleCd.key',
          {}
        ) === 'CPASAF65210') ||
      (formData.application.someoneAsstYouFlg == 'YA' &&
        _.get(
          formData,
          'application.authorizedRepresentative.assisterRoleCdRepresentative.key',
          {}
        ) === 'CPASAF65210')
    )
      requireField(
        'application_authorizedRepresentative_doYouLiveInHousehold',
        errors,
        formData,
        step,
        errorJson['doYouLiveInHousehold']
      )
    // Code Changes GPRB0359078 and US2554056
    // COC and Renewal - Assister Address Zip Code validation
    let assisterAddress = _.get(
      formData,
      'application.authorizedRepresentative.assisterAddress',
      {}
    )

    if (assisterAddress) {
      if (assisterAddress.zip && assisterAddress.zip !== undefined)
        if (validZipCodeCheck(assisterAddress.zip)) {
          notRequiredZip(
            'application_authorizedRepresentative_assisterAddress_zip',
            errors,
            formData,
            step,
            errorJson['address'] + errorJson['zipMust5']
          )
        }
    }
  }
  if (
    formData.application.EBT.ebtQue === undefined ||
    _.trim(formData.application.EBT.ebtQue) === ''
  ) {
    requireField(
      'application_EBT_ebtQue',
      errors,
      formData,
      step,
      errorJson['authorizedEBT']
    )
  }

  if (formData.application.EBT.ebtQue === 'Y') {
    if (
      formData.application.EBT.ebtCardHolder.ebtCardFstNm === undefined ||
      _.trim(formData.application.EBT.ebtCardHolder.ebtCardFstNm) === ''
    ) {
      requireField(
        'application_EBT_ebtCardHolder_ebtCardFstNm',
        errors,
        formData,
        step,
        errorJson['authorizedCardHolder'] + errorJson['clientFstNm']
      )
    }
    if (
      formData.application.EBT.ebtCardHolder.ebtCardLstNm === undefined ||
      _.trim(formData.application.EBT.ebtCardHolder.ebtCardLstNm) === ''
    ) {
      requireField(
        'application_EBT_ebtCardHolder_ebtCardLstNm',
        errors,
        formData,
        step,
        errorJson['authorizedCardHolder'] + errorJson['clientLstNm']
      )
    }
  }
  if (formData.application.requestClosure === 'Y') {
    // if (
    //   formData.application.closureType === undefined ||
    //   _.trim(formData.application.closureType) === ''
    // ) {
    //   requireField(
    //     'application_closureType',
    //     errors,
    //     formData,
    //     step,
    //     errorJson['closureType']
    //   )
    // }
    if (formData.application.closureType === 'close') {
      if (
        formData.application.closeCaseConfirmation === undefined ||
        _.trim(formData.application.closeCaseConfirmation) === ''
      ) {
        requireField(
          'application_closeCaseConfirmation',
          errors,
          formData,
          step,
          errorJson['closeCaseConfirmation']
        )
      }
    }
    if (formData.application.closureType === 'end') {
      formData.application.closureDetails.forEach((closure, index) => {
        if (
          _.get(closure, 'membersForClosure') === undefined ||
          _.get(closure, 'membersForClosure') === ''
        ) {
          requireField(
            `application_closureDetails_${index}_membersForClosure`,
            errors,
            formData,
            step,
            errorJson['membersForClosure']
          )
        }
        if (
          _.get(closure, 'benefitDetails') === undefined ||
          _.get(closure, 'benefitDetails', []).length === 0
        ) {
          closure.benefitDetails = undefined
          requireField(
            `application_closureDetails_${index}_benefitDetails`,
            errors,
            formData,
            step,
            errorJson['benefitDetails']
          )
        }
      })
    }
  }
  if (
    (formData.application.closeCaseConfirmation !== 'Y' && !isRenewal) ||
    isRenewal
  ) {
    if (_.get(formData, 'application.SPECIALNEEDS.specialNeeds', {}) === 'Y') {
      requireField(
        'application_SPECIALNEEDS_specialNeedsDescription',
        errors,
        formData,
        step,
        errorJson['specialNeedsDesc']
      )
    }

    if (
      ((prgmNameFDNT ||
        prgmNameMDPA ||
        prgmNameHEWL ||
        prgmNameCHSP ||
        prgmNameSCAL) &&
        isRenewal) ||
      !isRenewal
    ) {
      requireField(
        'application_householdRequestForADA_householdRequestForADA',
        errors,
        formData,
        step,
        'ADA: Please select "Does your household request an accommodation under the Americans with Disabilities Act?"'
      )
    }
    if (prgmNameCCA && totalHouseholdAssets === undefined) {
      requireField(
        'application_totalHouseholdAssets',
        errors,
        formData,
        step,
        errorJson['totalHouseholdAssets']
      )
    }

    requireField(
      'application_householdInfo_householdAddress_cntyLive',
      errors,
      formData,
      step,
      errorJson['householdAddress'] + errorJson['cntyLive']
    )
    if (formData.application.changes === 'Y') {
      requireField(
        'application_householdInfo_0_householdAddress_dtBirth',
        errors,
        formData,
        step,
        errorJson['householdAddress'] + errorJson['clientBthDt']
      )
      requireField(
        'application_householdInfo_0_householdAddress_gdrCd',
        errors,
        formData,
        step,
        errorJson['householdAddress'] + errorJson['genderReq']
      )
    }
    let householdAddress = _.get(
      formData,
      'application.householdInfo.householdAddress',
      {}
    )
    if (householdAddress) {
      if (
        householdAddress.county === undefined ||
        _.trim(householdAddress.county) === ''
      ) {
        householdAddress.county = undefined
        requireField(
          'application_householdInfo_householdAddress_county',
          errors,
          formData,
          step,
          errorJson['householdAddress'] + errorJson['countyReq']
        )
      }

      if (
        householdAddress.adrLn1Txt === undefined ||
        _.trim(householdAddress.adrLn1Txt) === ''
      ) {
        householdAddress.adrLn1Txt = undefined
        requireField(
          'application_householdInfo_householdAddress_adrLn1Txt',
          errors,
          formData,
          step,
          errorJson['householdAddress'] + errorJson['adrLn1Txt']
        )
      }
      if (
        householdAddress.ctyNm === undefined ||
        _.trim(householdAddress.ctyNm) === ''
      ) {
        householdAddress.ctyNm1 = undefined
        requireField(
          'application_householdInfo_householdAddress_ctyNm',
          errors,
          formData,
          step,
          errorJson['householdAddress'] + errorJson['cityReq']
        )
      }
      if (
        householdAddress.st === undefined ||
        _.trim(householdAddress.st) === ''
      ) {
        householdAddress.st = undefined
        requireField(
          'application_householdInfo_householdAddress_st',
          errors,
          formData,
          step,
          errorJson['householdAddress'] + errorJson['selectState']
        )
      }
      if (
        householdAddress.zip === undefined ||
        _.trim(householdAddress.zip) === ''
      ) {
        householdAddress.zip = undefined
        requireField(
          'application_householdInfo_householdAddress_zip',
          errors,
          formData,
          step,
          errorJson['householdAddress'] + errorJson['zipReq']
        )
      }
      // Code Changes GPRB0359078 and US2554056
      // COC and Renewal - Household Address Zip Code and Zip Ext validation
      if (householdAddress.zip && householdAddress.zip !== undefined) {
        if (validZipCodeCheck(householdAddress.zip)) {
          notRequiredZip(
            'application_householdInfo_householdAddress_zip',
            errors,
            formData,
            step,
            errorJson['householdAddress'] + errorJson['zipMust5']
          )
        }
      }
      if (householdAddress.zipExt && householdAddress.zipExt !== undefined) {
        if (validZipExtCheck(householdAddress.zipExt)) {
          notRequiredZipExt(
            'application_householdInfo_householdAddress_zipExt',
            errors,
            formData,
            step,
            errorJson['householdAddress'] + errorJson['extMust4']
          )
        }
      }
    }
    // Code Changes GPRB0359078 and US2554056
    // COC and Renewal - Mailing Address Zip Code validation
    let mailingAddress = _.get(
      formData,
      'application.householdInfo.mailingAddress',
      {}
    )

    if (mailingAddress) {
      if (mailingAddress.zip && mailingAddress.zip !== undefined) {
        if (validZipCodeCheck(mailingAddress.zip)) {
          notRequiredZip(
            'application_householdInfo_mailingAddress_zip',
            errors,
            formData,
            step,
            errorJson['mailingAddress'] + errorJson['zipMust5orBlank']
          )
        }
      }
    }
    for (let i = 0; i < formData.application.clients.length; i++) {
      requireField(
        'application_clients_' +
          i +
          '_clientDetails_personalData_0_clientFstNm',
        errors,
        formData,
        step,
        errorJson['LOHM'] + errorJson['clientFstNm']
      )
      requireField(
        'application_clients_' +
          i +
          '_clientDetails_personalData_0_clientLstNm',
        errors,
        formData,
        step,
        errorJson['LOHM'] + errorJson['clientLstNm']
      )

      requireField(
        'application_clients_' +
          i +
          '_clientDetails_personalData_0_clientBthDt',
        errors,
        formData,
        step,
        errorJson['LOHM'] + errorJson['clientBthDt']
      )

      // Code changes for GPRB0359133 and US2558381
      if (
        formData.application.clients[i].clientDetails.personalData &&
        formData.application.clients[i].clientDetails.personalData[0]
          .clientBthDt &&
        formData.application.clients[i].clientDetails.personalData[0]
          .clientBthDt !== undefined
      ) {
        if (
          validBirthDateCheck(
            formData.application.clients[i].clientDetails.personalData[0]
              .clientBthDt,
            currentDate
          )
        ) {
          cocBthDtgreaterthanNinteenHundredandLessCurrDt(
            'application_clients_' +
              i +
              '_clientDetails_personalData_0_clientBthDt',
            errors,
            formData,
            step,
            errorJson['LOHM'] + errorJson['clientBthDtFuture']
          )
        }
      }
      // Code Changes GPRB0359223 and US2819645
      // Household Information SSN Validation
      let userSSN = _.get(
        formData,
        `application.clients[${i}].clientDetails.personalData[0].clientSsn`
      )
      if (userSSN && userSSN !== undefined) {
        if (!validSSN(userSSN))
          ssnValidation(
            'application_clients_' +
              i +
              '_clientDetails_personalData_0_clientSsn',
            errors,
            formData,
            step,
            errorJson['LOHM'] + errorJson['invalidSSN']
          )
      }
      requireField(
        'application_clients_' + i + '_clientDetails_personalData_0_gdrCd',
        errors,
        formData,
        step,
        errorJson['LOHM'] + errorJson['gdrCd']
      )
    }
    expectCorrectEmailId(
      'application_householdInfo_contactInfo_email',
      errors,
      formData,
      step,
      errorJson['invalidEmail']
    )
    for (let i = 0; i < formData.application.clients.length; i++) {
      if (
        formData.application.clients[
          i
        ].clientDetails.personalData[0].hasOwnProperty(
          'alterativeInformation'
        ) &&
        formData.application.clients[i].clientDetails.personalData[0]
          .alterativeInformation !== undefined &&
        formData.application.clients[i].clientDetails.personalData[0]
          .alterativeInformation.isKnownByAnotherName === 'Y'
      ) {
        if (
          formData.application.clients[i].clientDetails.personalData[0]
            .alterativeInformation.alternativeFstName === undefined ||
          _.trim(
            formData.application.clients[i].clientDetails.personalData[0]
              .alterativeInformation.alternativeFstName
          ) === ''
        ) {
          formData.application.clients[
            i
          ].clientDetails.personalData[0].alterativeInformation.alternativeFstName = undefined

          requireField(
            'application_clients_' +
              i +
              '_clientDetails_personalData_0_alterativeInformation_alternativeFstName',
            errors,
            formData,
            step,
            errorJson['LOHMAFname']
          )
        }
        if (
          formData.application.clients[i].clientDetails.personalData[0]
            .alterativeInformation.alternativeLstName === undefined
        ) {
          formData.application.clients[
            i
          ].clientDetails.personalData[0].alterativeInformation.alternativeLstName = undefined
          requireField(
            'application_clients_' +
              i +
              '_clientDetails_personalData_0_alterativeInformation_alternativeLstName',
            errors,
            formData,
            step,
            errorJson['LOHMALname']
          )
        }
      }
      if (formContext.HEWL) {
        if (
          formData.application.clients[i].clientDetails.personalData[0]
            .helpWithBills === 'Y'
        ) {
          if (
            !formData.application.clients[i].clientDetails.personalData[0]
              .unpaidBillsForMonths
          ) {
            formData.application.clients[
              i
            ].clientDetails.personalData[0].unpaidBillsForMonths = undefined
          }
          requireField(
            'application_clients_' +
              i +
              '_clientDetails_personalData_0_unpaidBillsForMonths',
            errors,
            formData,
            step,
            errorJson['unpaidBillsForMonths']
          )
        }
      }
    }
    let reviewandsignDOB = _.get(formData, 'application.clientDt', '')

    if (reviewandsignDOB && reviewandsignDOB !== undefined) {
      if (validBirthDateCheck(reviewandsignDOB, currentDate)) {
        cocBthDtgreaterthanNinteenHundredandLessCurrDt(
          'application_clientDt',
          errors,
          formData,
          step,
          errorJson['clientBthDtFuture']
        )
      }
    }
  }
  return errors
}
