import * as React from 'react'
import * as _ from 'lodash'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'

export default function ReviewPublicTrackerForm(props) {
  const { formData, formContext, uiSchema } = props
  let mode = 'VIEW'
  const relationKey = uiSchema['ui:options']['relationKey']

  const isRelated = (element1, element2) => {
    for (let i = 0; i < relationKey.length; ++i) {
      if (element1[relationKey[i]] !== element2[relationKey[i]]) {
        return false
      }
    }
    return true
  }

  const indexedFormData = formData.map((element, index) => {
    return { ...element, index }
  })
  const changedOrAddedFormData = indexedFormData.filter(element => {
    return element.action === 'NEW'
  })
  const oldFormData = indexedFormData.filter(element => {
    return element.action !== 'NEW'
  })
  const changedFormDataIndexes = changedOrAddedFormData
    .filter(element => {
      return _.some(oldFormData, oldRecord => {
        return isRelated(oldRecord, element)
      })
    })
    .map(element => {
      return element.index
    })
  const addedFormDataIndexes = changedOrAddedFormData
    .filter(element => {
      return !_.some(oldFormData, oldRecord => {
        return isRelated(oldRecord, element)
      })
    })
    .map(element => {
      return element.index
    })
  const newFormContext = {
    ...formContext,
    refs: {
      ...formContext.refs,
      '{tracker mode}': ({
        formContext,
        fieldId,
        formData,
        schema,
        uiSchema
      }) => {
        const index = parseInt(fieldId.match('(?:.*)_([0-9]+)_?')[1])
        if (_.indexOf(changedFormDataIndexes, index) > -1) {
          return 'COC'
        } else if (_.indexOf(addedFormDataIndexes, index) > -1) {
          return 'ADD'
        } else {
          return 'VIEW'
        }
      }
    }
  }

  return (
    <Fields.CustomSchemaField
      {...props}
      uiSchema={{
        ..._.omit(uiSchema, 'ui:field'),
        'ui:eachVisibleIf': 'formData["action"] === "NEW"'
      }}
      formContext={newFormContext}
      registry={{ ...props.registry, formContext: newFormContext }}
    />
  )
}
