import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  radio: Widgets.CustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  span: Widgets.SpanWidget,
  infoBox: Widgets.InfoBoxWidget,
  linkButton: Widgets.LinkButtonWidget,
  organizationLink: Widgets.LinkWidget,
  'alt-date': Widgets.DatePickerWidget,
  validatedText: Widgets.ValidatedTextWidget,
  multiSelect: Widgets.MultiSelectDropdownWidgetV2,
  phone: Widgets.PhoneWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
