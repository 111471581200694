export default function caseTypes(
  state = { caseTypes: { cs: false, cca: false, fa: false } },
  action
) {
  switch (action.type) {
    case 'SET_CASE_TYPES':
      return {
        ...state,
        caseTypes: action.caseTypes
      }
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        caseTypes: { cs: false, cca: false, fa: false }
      }
    default:
      return state
  }
}
