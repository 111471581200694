import * as React from 'react'
import CheckboxesWidgetWithHelp from './checkboxesWidgetWithHelp'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { pull } from 'lodash'
import { getServerTime } from '../../../src/components/utils'
import { config } from '../../../../../config' // endpoints
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'

export default function CustomCheckboxesWidgetWithHelp(props) {
  return <CustomCheckboxesComponent {...props} />
}

class CustomCheckboxesComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  handleChange(value) {
    const options = this.props.options
    const previousValue = this.props.value
    let newValue = value

    if (options && value) {
      // none option present in value
      if (options.noneValue && value.indexOf(options.noneValue) > -1) {
        // none was there and previously there meaning another addition was made to remove the none option and add the other selected
        if (value.length > 1 && previousValue.indexOf(options.noneValue) > -1) {
          newValue = pull(value, options.noneValue)
          // none was not there previously meaning it was just selected to only return the none value
        } else {
          newValue = [options.noneValue]
        }
        // options.allValue is within value
      } else if (options.allValue && value.indexOf(options.allValue) > -1) {
        newValue = []
        // if all was select previous and a change made keeping all in but reducing array length, meaning another option was deselected
        if (previousValue.length > value.length) {
          // return the rest of the values minus all
          newValue = pull(value, options.allValue)
          // all was not in there previous now it is, return all values
        } else {
          for (let i = 0; i < options.enumOptions.length; i++) {
            newValue.push(options.enumOptions[i].value)
          }
        }
        // all other checkboxes are selected but not the all
      } else if (
        options.allValue &&
        value.indexOf(options.allValue) === -1 &&
        value.length === options.enumOptions.length - 1
      ) {
        // if all was previous there now not, return no values
        if (previousValue.indexOf(options.allValue) > -1) {
          newValue = []
          // if all was not previously there but all has now been selected, return all valuese with all aswell
        } else {
          newValue = []
          for (let i = 0; i < options.enumOptions.length; i++) {
            newValue.push(options.enumOptions[i].value)
          }
        }
      }
    }
    this.props.onChange(newValue)
  }

  render() {
    const newProps = this.props
    const options = this.props.options

    if (typeof options.enumOptions !== 'undefined') {
      newProps['options']['enumOptions'] = helper.resolveEnumRefs(
        options.enumOptions,
        this.props.formContext,
        this.props.id
      )
    }

    if (this.props.options.checkForMonth) {
      let currentDate = _moment(config, getServerTime())
      let month = currentDate.month() + 1
      if (month !== 7) {
        newProps.options.enumOptions.splice(5, 1)
      } else {
      }
    }

    return (
      <div>
        <CheckboxesWidgetWithHelp
          {...newProps}
          onChange={this.handleChange.bind(this)}
        />
      </div>
    )
  }
}
