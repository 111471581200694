import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import * as _ from 'lodash'
import { Modal, Button } from 'react-bootstrap'
import { ErrorModal } from '../src/components/natives/ErrorModal'

interface MyDocumentsAccordionPresentationProps {
  showModal: boolean
  formData: any
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formContext: any
  modalData: any
  loaded: boolean
  onSubmit: (formData: any) => void
  onFormDataChange: (formData: any) => void
  appIntake: string
  showExistingDocumentModal: boolean
  showLimitModal: boolean
  open: () => void
  showDeleteModal: boolean
  showUploadDocumentModal: boolean
  showUploadWrongDocumentModal: boolean
  closeDeleteModal: () => void
  showSelectDocumentTypeModal: boolean
  closeSelectDocumentTypeModal: () => void
  openExistingDocumentModal: () => void
  closeExistingDocumentModal: () => void
  existingDocumentType: string
  closeUploadDocumentModal: () => void
  closeLimitModal: () => void
  closeOtherDocumentTypeModal: () => void
  onDeleteClick: () => void
  minHeight: string
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  serviceErrors: Array<any>
  onDeleteModalClick: () => void
}

export default function MyDocumentsAccordionPresentation(
  props: MyDocumentsAccordionPresentationProps
) {
  const {
    showModal,
    formData,
    formSchema,
    formContext,
    modalData,
    loaded,
    onSubmit,
    onFormDataChange,
    appIntake,
    open,
    showDeleteModal,
    closeDeleteModal,
    showSelectDocumentTypeModal,
    closeSelectDocumentTypeModal,
    onDeleteClick,
    minHeight,
    formComponents,
    showExistingDocumentModal,
    openExistingDocumentModal,
    showLimitModal,
    closeExistingDocumentModal,
    existingDocumentType,
    closeOtherDocumentTypeModal,
    showUploadDocumentModal,
    closeLimitModal,
    showUploadWrongDocumentModal,
    closeUploadDocumentModal,
    serviceErrors,
    onDeleteModalClick
  } = props
  if (formSchema != null && formData != null && loaded) {
    let modalBody = (
      <h6
        style={{ paddingLeft: '10px;', fontSize: '16px;', lineHeight: '15px;' }}
      >
        No Drafts to Submit
      </h6>
    )
    if (modalData['docUploadIds'].length > 0) {
      modalBody = (
        <Form
          uiSchema={formSchema['forms'][1]['uiSchema']}
          schema={formSchema['forms'][1]['schema']}
          formContext={formContext}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          formData={modalData}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          onSubmit={onSubmit}
          noValidate={true}
        >
          <button type="submit" className="btn">
            Submit
          </button>
        </Form>
      )
    }
    return (
      <div>
        <Form
          uiSchema={formSchema['forms'][0]['uiSchema']}
          schema={formSchema['forms'][0]['schema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          enctype="multipart/form-data"
        >
          <button type="submit" className="hidden" />
        </Form>
        {!appIntake ? (
          <button type="button" className="btn btn-default" onClick={open}>
            Review & Submit
          </button>
        ) : null}
        <Modal show={showModal} onHide={close}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="popup-title">Review and Submit</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="headertxt">
              You must click the submit button for your requested documentation
              to be received by your assigned case worker. Once submitted, you
              will no longer be able to delete your document(s) but you can
              preview the items for{' '}
              <span style={{ color: 'red' }}>(30days)</span>.{' '}
            </h6>
            {modalBody}
          </Modal.Body>
        </Modal>
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="popup-title">Delete</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="headertxt">
              Are you sure you want to delete this image?
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onDeleteClick}>
              Yes
            </Button>
            <Button variant="primary" onClick={closeDeleteModal}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showSelectDocumentTypeModal}
          onHide={closeSelectDocumentTypeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="popup-title">Document Type</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="headertxt">Please select a document type</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeSelectDocumentTypeModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showExistingDocumentModal}
          onHide={closeExistingDocumentModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="popup-title">Existing Document Type</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {existingDocumentType !== 'Other' && (
              <h6 className="headertxt">
                {existingDocumentType} has already been uploaded. Do you want to
                replace the existing?
              </h6>
            )}
            {existingDocumentType === 'Other' && (
              <h6 className="headertxt">
                There are 5 "Other" documents already uploaded, If you still
                want to upload, please delete one from existing.
              </h6>
            )}
          </Modal.Body>
          <Modal.Footer>
            {existingDocumentType !== 'Other' && (
              <Button variant="primary" onClick={openExistingDocumentModal}>
                Yes
              </Button>
            )}
            {existingDocumentType !== 'Other' && (
              <Button variant="primary" onClick={closeExistingDocumentModal}>
                No
              </Button>
            )}
            {existingDocumentType === 'Other' && (
              <Button variant="primary" onClick={closeOtherDocumentTypeModal}>
                Ok
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <Modal show={showLimitModal} onHide={closeLimitModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="popup-title">Document Limit</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {existingDocumentType === 'Court Order' && (
              <h6 className="headertxt">
                There is a "Court Order" already uploaded, If you still want to
                upload, please delete the existing one.
              </h6>
            )}
            {existingDocumentType === 'Other' && (
              <h6 className="headertxt">
                There are 5 "Other" documents already uploaded, If you still
                want to upload, please delete one from existing.
              </h6>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeLimitModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showUploadDocumentModal} onHide={closeUploadDocumentModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="popup-title">No Document</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="headertxt">Please select a document</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeUploadDocumentModal}>Ok</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showUploadWrongDocumentModal}
          onHide={closeUploadDocumentModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="popup-title">File Type</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="headertxt">
              Upload only following file types - JPG, PDF, GIF, TIF and PNG
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeUploadDocumentModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <ErrorModal
          errorMessage={serviceErrors}
          onDeleteClick={onDeleteModalClick}
        />
      </div>
    )
  }
  return (
    <div>
      <ErrorModal
        errorMessage={serviceErrors}
        onDeleteClick={onDeleteModalClick}
      />
      <ScreenPreLoader loaded={loaded} minHeight={minHeight} />
    </div>
  )
}
