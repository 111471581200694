import * as React from 'react'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

interface MyTasksPresentationProps {
  form: {
    schema: Object
    uiSchema: Object
  }
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  locale: string
}

let output = <ScreenPreLoader loaded={false} />

export default function MyTasksPresentation(props: MyTasksPresentationProps) {
  const { form, formComponents, locale } = props
  output = (
    <div>
      <h1>
        <i className="pe-7s-note" /> {I18n.t('MyTasks.title')}
      </h1>
      <Form
        schema={form.schema}
        uiSchema={form.uiSchema}
        fields={formComponents.fields}
        widgets={formComponents.widgets}
        FieldTemplate={formComponents.FieldTemplate}
        ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
        locale={locale}
      >
        <input type="submit" style={{ display: 'none' }} />
      </Form>
    </div>
  )

  return (
    <>
      <Helmet>
        <title>{I18n.t('MyTasks.title')}</title>
      </Helmet>
      {output}
    </>
  )
}
