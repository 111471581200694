import * as React from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { config } from '../../../config'
import { Redirect } from 'react-router-dom'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import { ENTITLEMENTS_wv as wvEnt } from '../src/entitlements_wv'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError } from '../src/components/utils'

declare var process
const baseUrl = process.env.PUBLIC_URL
const updateDefaultUserRoleEndpoint = config['updateDefaultUserRole']
const updateDefaultOrgFlagEndpoint =
  config['gatewayWvUrl'] + config['updatedefaultorg']

interface Entitlement {
  entitlementId: number
  entitlementName: string
  entitlementDescription: string
}

interface UserRole {
  userRoleId: number
  uuid: string
  orgList: Array<Org>
  role: {
    roleId: number
    roleName: string
    multilingualDisplayName: {
      en: string
      es: string
    }
    roleDescription: string
    tenantId: number
    status: string
    startDate: string
    endDate: string
  }
  entitlements: Array<Entitlement>
}

interface Org {
  roleId: number
  roleName: string
  orgId: number
  orgName: string
  roleorgId: number
  dfltflg: string
}

interface SelectRoleContainerProps {
  presentation: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
      defaultUserRoleId: number
      isDefaultUserRole: boolean
    }
  }
  userAccess: {
    userRoles: Array<UserRole>
    selectedUserRole: UserRole
    defaultOrg: Org
    orgList: Array<Org>
  }
  setSelectedUserRole: (selectedUserRole: UserRole) => void
  setSelectedOrg: (selectedOrg: Org) => void
  setDefaultOrg: (selectedOrg: Org) => void
  updateOrgList: (orgList: Array<Org>) => void
  setDefaultUserRole: (selectedUserRole: UserRole) => void
  location: {
    search: string
  }
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  roleId: any
  orgId: any
  history: {
    push: (url: string) => void
  }
  logoutUser
}

interface SelectRoleContainerState {
  userRoles: Array<UserRole>
  loaded: boolean
  isDefaultUserRole: boolean
  redirect: string
  selectedUserRoleId: number
  selectedOrgRoleId: number
}

/**
 * @author smahan3
 */
class SelectRoleContainer extends React.Component<
  SelectRoleContainerProps,
  SelectRoleContainerState
> {
  constructor(props: SelectRoleContainerProps) {
    super(props)

    this.state = {
      userRoles: null,
      loaded: false,
      isDefaultUserRole: this.props.auth.userAccount.isDefaultUserRole || false,
      redirect: null,
      selectedUserRoleId: null,
      selectedOrgRoleId: null
    }
  }

  componentDidMount() {
    this._loadData(this.props.userAccess)
  }

  _loadData(json) {
    const userRoles = json.userRoles
    const selectedUserRole = json.selectedUserRole
    const defaultUserRoleId = this.props.auth.userAccount.defaultUserRoleId
    const isDefaultUserRole = !_.isNil(defaultUserRoleId)
    const selectedUserRoleId = _.isNil(selectedUserRole)
      ? null
      : selectedUserRole.userRoleId

    this.setState({
      userRoles,
      loaded: true,
      isDefaultUserRole,
      selectedUserRoleId
    })
  }

  _handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.id
    const value2 =
      target.type === 'checkbox' ? this.state.selectedOrgRoleId : null
    const name: any = target.name || 'selectedUserRoleId'

    this.setState({
      [name]: value,
      selectedOrgRoleId: value2
    } as any)
  }

  _handleOrgChange = event => {
    const target = event.target
    const value = target.id.split('_')
    const value1 = value[0]
    const value2 = value[1]

    this.setState({
      selectedUserRoleId: value1,
      selectedOrgRoleId: value2
    })
  }

  _handleSubmit = () => {
    this._updateUserRoleSelection()
    this._updateOrgSelection()
  }

  _updateUserRoleSelection = () => {
    let redirectUrl = baseUrl + '/myaccount/dashboard'
    let bHRedirectUrl = baseUrl + '/myaccount/eligibleApplicants'
    const isDefaultUserRole = this.state.isDefaultUserRole ? 'Y' : 'N'
    const updateEndpoint = updateDefaultUserRoleEndpoint
      .replace('{userRoleId}', this.state.selectedUserRoleId.toString())
      .replace('{isDefaultUserRole}', isDefaultUserRole)

    serviceHelper
      .fetchJson(
        updateEndpoint,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(() => {
        // 2. navigate to home page based on selected role
        const selectedUserRole = _.find(this.props.userAccess.userRoles, {
          userRoleId: _.toInteger(this.state.selectedUserRoleId)
        })
        const selectedEntitlements = _.get(
          selectedUserRole,
          'entitlements',
          []
        ).map(element => element.entitlementName)

        let redirect = baseUrl + '/home'

        if (
          _.includes(
            selectedEntitlements,
            ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
          )
        ) {
          redirect = redirectUrl
        } else if (
          _.includes(selectedEntitlements, ENTITLEMENTS.COMMUNITY_PARTNERSHIP)
        ) {
          redirect = redirectUrl
        } else if (_.includes(selectedEntitlements, wvEnt.CP_Admin)) {
          redirect = redirectUrl
        } else if (
          _.includes(selectedEntitlements, wvEnt.BH_ELIGIBLE_APPLICANT_VIEW)
        ) {
          redirect = bHRedirectUrl
        }

        this.setState({ redirect }, () => {
          this.props.setSelectedUserRole(selectedUserRole)
          this.props.setDefaultUserRole(
            this.state.isDefaultUserRole ? selectedUserRole : ''
          )
        })
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error(
          'SelectRole _updateUserRoleSelection failed due to ex',
          ex,
          'endpoint: ',
          updateEndpoint
        )
      })
  }

  _updateOrgSelection = () => {
    let selectedOrg1 = this.props.userAccess.userRoles.map(role => {
      let orgObj =
        role &&
        role.orgList &&
        _.find(role.orgList, roleObj => {
          return roleObj.roleorgId == _.toInteger(this.state.selectedOrgRoleId)
        })
      return orgObj
    })
    selectedOrg1 = _.compact(selectedOrg1)
    const selectedOrg = selectedOrg1[0]

    let currentOrgList = this.props.userAccess.orgList
    let defaultOrgObj
    let newOrgList
    const isDefaultUserRole = this.state.isDefaultUserRole ? 'Y' : 'N'
    if (isDefaultUserRole == 'Y' && selectedOrg) {
      newOrgList = currentOrgList.map(orgObj => {
        if (orgObj.roleorgId == selectedOrg.roleorgId) {
          return {
            ...orgObj,
            dfltflg: 'Y'
          }
        } else if (
          this.props.userAccess.defaultOrg != null &&
          orgObj.roleorgId == this.props.userAccess.defaultOrg.roleorgId
        ) {
          return {
            ...orgObj,
            dfltflg: 'N'
          }
        } else {
          return orgObj
        }
      })
      defaultOrgObj = newOrgList.find(Obj => {
        return Obj.dfltflg == 'Y'
      })
      if (_.isNil(defaultOrgObj)) {
        defaultOrgObj = null
      }
      if (
        this.props.userAccess.orgList &&
        this.props.userAccess.orgList.length > 0
      ) {
        let profileTabRoleOrgId = defaultOrgObj && defaultOrgObj.roleorgId
        let profileTabDfltflg = defaultOrgObj && defaultOrgObj.dfltflg
        if (profileTabRoleOrgId === null || profileTabRoleOrgId === undefined) {
          profileTabRoleOrgId = this.props.userAccess.orgList[0].roleorgId
        }
        if (profileTabDfltflg === null || profileTabDfltflg === '') {
          profileTabDfltflg = 'N'
        }
        serviceHelper
          .fetchJson(
            updateDefaultOrgFlagEndpoint,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                uuid: this.props.auth.userAccount.uuid,
                tenantCode: config.tCode,
                Authorization: config.bearer + this.props.auth.accessToken,
                portalName: config.portalName,
                roleId: this.props.roleId,
                orgId: this.props.orgId
              },
              body: JSON.stringify({
                flag: profileTabDfltflg,
                roleOrgId: profileTabRoleOrgId.toString(),
                uuid: this.props.auth.userAccount.uuid
              })
            },
            this.props.showErrorMessage
          )
          .catch(error => {
            if (error instanceof UnauthorizedError) {
              this.props.logoutUser()
              this.props.history.push(baseUrl + '/home')
            }
          })
      }
    }
    this.setState({}, () => {
      this.props.setSelectedOrg(selectedOrg)
      this.props.setDefaultOrg(defaultOrgObj)
      this.props.updateOrgList(newOrgList)
    })
  }

  _shouldContinue = () => {
    // if a role is selected, enable Continue button.
    return !_.isNil(this.state.selectedUserRoleId)
  }

  _roleIconClass = userRole => {
    const entitlements = (userRole.entitlements || []).map(
      element => element.entitlementName
    )
    if (
      _.includes(entitlements, ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT)
    ) {
      return 'pe-7s-star pe-7s-icon-90'
    } else if (_.includes(entitlements, ENTITLEMENTS.COMMUNITY_PARTNERSHIP)) {
      return 'pe-7s-home pe-7s-icon-90'
    } else if (_.includes(entitlements, ENTITLEMENTS.CLIENT_PARTICIPATION)) {
      return 'pe-7s-user pe-7s-icon-90'
    } else {
      return 'pe-7s-more pe-7s-icon-90'
    }
  }

  _roleTileClass = userRole => {
    const baseClass =
      !_.isNil(this.state.selectedUserRoleId) &&
      _.toInteger(this.state.selectedUserRoleId) === userRole.userRoleId
        ? 'select-role-tile-selected'
        : 'select-role-tile'
    return baseClass + ' text-center font-weight-bold'
  }

  _orgTileClass = userRole => {
    const baseClass =
      !_.isNil(this.state.selectedOrgRoleId) &&
      _.toInteger(this.state.selectedOrgRoleId) === userRole.roleorgId
        ? 'select-role-tile-selected'
        : 'select-role-tile'
    return baseClass + ' text-center font-weight-bold'
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    const { presentation, errorMessage } = this.props
    const { userRoles, loaded, redirect, isDefaultUserRole } = this.state

    if (!_.isNil(redirect)) {
      return (
        <Redirect
          to={{ pathname: redirect, state: { from: this.props.location } }}
        />
      )
    }

    const presentationProps = {
      userRoles,
      loaded,
      shouldContinue: this._shouldContinue,
      handleSubmit: this._handleSubmit,
      roleTileClass: this._roleTileClass,
      orgTileClass: this._orgTileClass,
      roleIconClass: this._roleIconClass,
      isDefaultUserRole,
      handleInputChange: this._handleInputChange,
      handleOrgChange: this._handleOrgChange,
      errorMessage,
      onDeleteClick: this._onDeleteClick
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''

  return {
    auth: state.auth,
    userAccess: state.userAccess,
    errorMessage,
    roleId,
    orgId
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setSelectedUserRole: selectedUserRole => {
      dispatch(actions.setSelectedUserRole(selectedUserRole))
    },
    setDefaultUserRole: selectedUserRole => {
      dispatch(actions.setDefaultUserRoleId(selectedUserRole.userRoleId))
    },
    setSelectedOrg: selectedOrg => {
      dispatch(actions.setSelectedOrg(selectedOrg))
    },
    setDefaultOrg: selectedOrg => {
      dispatch(actions.setDefaultOrg(selectedOrg))
    },
    updateOrgList: orgList => {
      dispatch(actions.updateOrgList(orgList))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectRoleContainer)
