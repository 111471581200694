import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import AuthorizedRepField from './AuthorizedRepField'
import ReviewDifference from './ReviewDifference'
import ConditionField from './ConditionField'
import ReviewPublicTrackerForm from './ReviewPublicTrackerForm'
import CriWidget from './CriWidget'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'
import '!style-loader!css-loader!../src/customStyles.css'
import * as queryString from 'query-string'
import customWidgets from '../src/components/widgets'
import validateCustomRadioWidget from '../src/components/widgets/CustomRadioWidget'
import { I18n } from 'react-redux-i18n'

const Fragment = (React as any).Fragment

function ReviewInfoWidget(props) {
  return props.formContext.isRenewal ? (
    <p>{I18n.t('Appintake.reviewInfo1')}</p>
  ) : (
    <p>{I18n.t('Appintake.reviewInfo2')}</p>
  )
}

class DHHRInsruction extends React.Component<any, any> {
  componentDidMount() {
    const reneCovInd = _.get(
      this.props.formContext,
      'formData.Application.reneCovInd'
    )
    if (reneCovInd == 0) {
      let noElement = document.getElementById('useTextYesNo_1') as any
      if (noElement) {
        noElement.checked = true
      }
    } else if (reneCovInd > 0) {
      let yesElement = document.getElementById('useTextYesNo_0') as any
      let yesElements = document.getElementsByName('useTextYears') as any
      if (yesElements) {
        for (let i = 0; i < yesElements.length; i++) {
          yesElement.checked = true
          yesElements[reneCovInd - 1].checked = true
          yesElements[i].disabled = false
        }
      }
    }
  }

  render() {
    return (
      <div>
        <p>{I18n.t('Appintake.DHHRIncomeData')}</p>
        <div className="years-padding-style">
          <input
            id="useTextYesNo_0"
            type="radio"
            name="useTextYesNo"
            value="Yes"
            onClick={() => {
              let yesElements = document.getElementsByName(
                'useTextYears'
              ) as any
              for (let i = 0; i < yesElements.length; i++) {
                yesElements[i].disabled = false
              }
              this.props.formContext.setReneCovInd(null)
            }}
          />{' '}
          <label
            className="appIntakeReview"
            htmlFor="useTextYesNo_0"
            id="useTextYesNo_0_label"
          >
            {I18n.t('Appintake.useTaxInformation')}
          </label>
          <div role="group" aria-labelledby="useTextYesNo_0_label">
            <ul style={{ listStyleType: 'none' }}>
              <li>
                <input
                  id="useTextYears_1"
                  type="radio"
                  name="useTextYears"
                  value="1Year"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(1)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_1">
                  {I18n.t('Appintake.1years')}1 year
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_2"
                  type="radio"
                  name="useTextYears"
                  value="2Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(2)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_2">
                  {I18n.t('Appintake.2years')}
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_3"
                  type="radio"
                  name="useTextYears"
                  value="3Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(3)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_3">
                  {I18n.t('Appintake.3years')}
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_4"
                  type="radio"
                  name="useTextYears"
                  value="4Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(4)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_4">
                  {I18n.t('Appintake.4years')}
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_5"
                  type="radio"
                  name="useTextYears"
                  value="5Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(5)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_5">
                  {I18n.t('Appintake.5years')}
                </label>
              </li>
            </ul>
          </div>
          <input
            id="useTextYesNo_1"
            type="radio"
            name="useTextYesNo"
            value="No"
            onClick={() => {
              let yesElements = document.getElementsByName(
                'useTextYears'
              ) as any
              for (let i = 0; i < yesElements.length; i++) {
                yesElements[i].checked = false
                yesElements[i].disabled = true
              }
              this.props.formContext.setReneCovInd(0)
            }}
          />{' '}
          <label
            className="appIntakeReview"
            htmlFor="useTextYesNo_1"
            id="useTextYesNo_1_label"
          >
            {I18n.t('Appintake.dontUseTaxInformation')}
          </label>
        </div>
      </div>
    )
  }
}

function getQueryParamFromUrl(field) {
  let url = window.location.href
  var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i')
  var string = reg.exec(url)

  return string ? decodeURIComponent(string[1]) : null
}

function IntroductionWidget(props) {
  let prgNames = getQueryParamFromUrl('prgNames')
  let prgNames1 = []
  if (prgNames) {
    prgNames1 = prgNames.split(',')
  }
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  if (prgNames1) {
    var uniqPrngNames = prgNames1.filter(onlyUnique)
    uniqPrngNames.map(function(currentObj, index, arr) {
      currentObj = '  ' + currentObj
    })
  }

  let stringNames = ''
  let prgNames3 = []

  let prgNames2 = props.formContext.prgNames
  if (prgNames2) {
    prgNames3 = prgNames2.search(',') > 0 ? prgNames2.split(',') : [prgNames2]
  }
  function onlyUnique1(value, index, self) {
    return self.indexOf(value) === index
  }

  if (prgNames3) {
    var uniqPrngNames1 = prgNames3.filter(onlyUnique1)
    uniqPrngNames1.map(function(currentObj, index, arr) {
      currentObj = '  ' + currentObj
    })
  }

  if (!prgNames) {
    stringNames = uniqPrngNames1.join(', ')
  } else if (prgNames) {
    stringNames = uniqPrngNames.join(', ')
  }
  return props.formContext.isRenewal ? (
    <form className="renewben">
      <fieldset>
        <legend style={{ background: '#b7d5e8' }}>
          {' '}
          <h3 className="welcome-benefits-style">
            {' '}
            {I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg1')}{' '}
          </h3>{' '}
        </legend>
        <p>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg2')}</p>
        <ul>
          <li>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg3')}</li>
          <li>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg4')}</li>
          <li>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg5')}</li>
          <li>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg6')}</li>
          <li>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg7')}</li>
        </ul>
        <ul style={{ listStyle: 'none' }}>
          <li style={{ paddingBottom: '7px' }}>
            {I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg8')}
          </li>
          <li style={{ paddingBottom: '7px' }}>
            {I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg9')}{' '}
            <a
              href="http://www.ascr.usda.gov/complaint_filing_cust.html"
              className="link-line"
              target="_blank"
            >
              http://www.ascr.usda.gov/complaint_filing_cust.html
            </a>{' '}
            {I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg10')}
          </li>
          <li className="display-flex">
            <div style={{ paddingRight: '3px' }}>
              {I18n.t('ClientUpdateWizard.mail')}
            </div>
            <div style={{ paddingBottom: '7px' }}>
              {I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg11')}
            </div>
          </li>
          <li style={{ paddingBottom: '7px' }}>
            {I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg12')}
          </li>
          <li style={{ paddingBottom: '7px' }}>
            {I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg13')}
          </li>
          <li style={{ paddingBottom: '7px' }}>
            {I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg14')}
          </li>
        </ul>

        <ul>
          <li style={{ paddingBottom: '7px' }}>
            {I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg15')}
          </li>
          <li style={{ marginLeft: '20px' }}>{stringNames}</li>
        </ul>
      </fieldset>
    </form>
  ) : (
    <div>
      <p>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg16')}</p>
      <ul className="intro-align">
        <li>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg17')}</li>
        <li>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg18')}</li>
        <li>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg19')}</li>
      </ul>
      <p>{I18n.t('ClientUpdateWizard.houeHoldWelcomeMsg20')}</p>
    </div>
  )
}

function RegisterWidget() {
  return (
    <Fragment>
      <a
        href="https://ovr.sos.wv.gov/Register/Landing"
        target="_blank"
        className="link-line"
      >
        {I18n.t('Appintake.clickToVote')}
      </a>
    </Fragment>
  )
}

function RnrWidget(props) {
  let prgNames = props.formContext.prgNames
  return (
    <div>
      <h2 className="aIHeader rights-font">
        {I18n.t('Appintake.rnrWidgetL1')}
      </h2>
      <div className="aIDiv print1 " id="pr">
        <h3 className="aIh5 ">
          {I18n.t('Appintake.rnrWidgetL2')}
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr')
            }}
          >
            {' '}
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </h3>
        <ul>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL4')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL5')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL6')}{' '}
            <strong> {I18n.t('Appintake.rnrWidgetL6Strong1')}</strong>
            {I18n.t('Appintake.rnrWidgetL6p1')}
          </li>
          <li>
            <strong> {I18n.t('Appintake.rnrWidgetL7Strong')}</strong>
            {I18n.t('Appintake.rnrWidgetL7')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL8')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL9')}{' '}
            {!props.formContext.hideWVWORKS && (
              <>
                <strong> {I18n.t('Appintake.rnrWidgetL11Strong1')} </strong>{' '}
                {I18n.t('Appintake.rnrWidgetL11p1')}
              </>
            )}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>
            {I18n.t('Appintake.rnrWidgetL12')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>
            {I18n.t('Appintake.rnrWidgetL13')}
          </li>
          <li>
            <strong> {I18n.t('Appintake.rnrWidgetL14Strong')} </strong>{' '}
            {I18n.t('Appintake.rnrWidgetL14')}{' '}
            <strong> {I18n.t('Appintake.rnrWidgetL14Strong1')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL14p1')}
            <ol list-style-type="A">
              <li>{I18n.t('Appintake.rnrWidgetL15')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL16')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL17')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL18')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL19')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL21')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL22')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL23')}</li>
              <li>{I18n.t('Appintake.rnrWidgetL24')}</li>
            </ol>
          </li>
          <li>
            <strong> {I18n.t('Appintake.rnrWidgetL25Strong')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL25')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL26')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL27')}{' '}
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL27p1')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL28')}{' '}
            <strong> {I18n.t('Appintake.rnrWidgetL28Strong1')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL28p1')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL29')}{' '}
            <strong> {I18n.t('Appintake.rnrWidgetL30Strong')} </strong>{' '}
            {I18n.t('Appintake.rnrWidgetL30')}{' '}
            <strong> {I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL31')}
          </li>
          <li>
            <strong> {I18n.t('Appintake.rnrWidgetL32Strong')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL32')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.rnrWidgetL32Strong')} </strong>
            {I18n.t('Appintake.rnrWidgetL33')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.rnrWidgetL34Strong')} </strong>{' '}
            {I18n.t('Appintake.rnrWidgetL34')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL35')}
            <p style={{ paddingLeft: '30px' }}>
              {I18n.t('Appintake.rnrWidgetL36')}
            </p>
          </li>
          <li>
            <strong>{I18n.t('Appintake.rnrWidgetL37Strong')} </strong>{' '}
            {I18n.t('Appintake.rnrWidgetL37')}
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL38')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL39')}{' '}
            <strong>{I18n.t('Appintake.rnrWidgetL44')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL41')}{' '}
            <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL43')}{' '}
            <strong>{I18n.t('Appintake.rnrWidgetL44')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL45')}{' '}
            <strong> {I18n.t('Appintake.rnrWidgetL46')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL47')}{' '}
            <strong> {I18n.t('Appintake.rnrWidgetL48')}</strong>{' '}
            {I18n.t('Appintake.rnrWidgetL49')}
          </li>
        </ul>
      </div>
    </div>
  )
}

function rnrInfoTANF() {
  return (
    <div>
      <h2 className="aIHeader rights-font">
        {I18n.t('Appintake.wvWorksTitle')}
      </h2>
      <div className="aIDiv print1 " id="pr1">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr1')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </p>
        <div>
          <ul>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksPayment')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksRecipientOrNonRecipient')}
            </li>
            <li>
              <div>
                <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
                {I18n.t('Appintake.wvWorksLearningDisability')}
              </div>{' '}
              <ol>
                <p> {I18n.t('Appintake.DHHRCanHelp')}</p>
                <p>{I18n.t('Appintake.DHHRCanCall')}</p>
                <p> {I18n.t('Appintake.DHHRCanTell')} </p>
              </ol>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksChildMoving')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksAttendingSchool')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksDirectDeposit')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksCashBenefit')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksTanfFunds')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.wvWorksSupportServices')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function RnrFDNTWidget() {
  return (
    <div>
      <h2 className="aIHeader rights-font">
        {' '}
        {I18n.t('Appintake.RnrFDNTWidget1')}
      </h2>
      <div className="aIDiv print1 " id="pr1">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr1')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </p>
        <div>
          <h3 className="aIh5 " id="pr">
            {I18n.t('BenefitsCatalog.snap.title')}
            {I18n.t('BenefitsCatalog.benefitSNAP1')}
          </h3>
          <div className="rnrContentBox">
            <p>{I18n.t('Appintake.RnrFDNTWidget55')}</p>
            <p>{I18n.t('Appintake.RnrFDNTWidget54')}</p>
            <p>
              {I18n.t('Appintake.RnrFDNTWidget47')},
              {I18n.t('Appintake.RnrFDNTWidget48')}
              <a
                href="https://www.usda.gov/sites/default/files/documents/ad-3027.pdf"
                className="link-line"
              >
                https://www.usda.gov/sites/default/files/documents/ad-3027.pdf
              </a>
              {I18n.t('Appintake.RnrFDNTWidget46')}
            </p>
            <p>
              <ol>
                <li>{I18n.t('Appintake.RnrFDNTWidget50')}</li>
                {I18n.t('Appintake.RnrFDNTWidget49')}
                <li>{I18n.t('Appintake.RnrFDNTWidget53')}</li>
                <a
                  href="mailto:FNSCIVILRIGHTSCOMPLAINTS@usda.gov"
                  className="link-line"
                >
                  FNSCIVILRIGHTSCOMPLAINTS@usda.gov
                </a>
              </ol>
            </p>
            <p>{I18n.t('Appintake.RnrFDNTWidget45')}</p>
          </div>

          <ul>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget8')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget9')}{' '}
              <em>{I18n.t('Appintake.RnrFDNTWidget9em')}</em>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('ClientUpdateWizard.myassistancegroupText')}{' '}
              <strong>
                <u>{I18n.t('Appintake.RnrFDNTWidget43')}</u>
              </strong>{' '}
              - {I18n.t('Appintake.RnrFDNTWidget44')};{' '}
              <strong>
                <u>{I18n.t('Appintake.RnrFDNTWidget11')}</u>
              </strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget12')}{' '}
              <strong>
                <u>{I18n.t('Appintake.RnrFDNTWidget37')}</u>
              </strong>{' '}
              - {I18n.t('Appintake.RnrFDNTWidget13')}{' '}
              {I18n.t('Appintake.iUnderstand')}{' '}
              {I18n.t('Appintake.RnrFDNTWidget14')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget15')}
              <ol type="A">
                <li>
                  {I18n.t('Appintake.RnrFDNTWidget16')}{' '}
                  <strong>
                    <u>{I18n.t('Appintake.RnrFDNTWidget17')}</u>
                  </strong>
                </li>
                <li>
                  {I18n.t('Appintake.RnrFDNTWidget18')}{' '}
                  <strong>
                    <u> {I18n.t('Appintake.RnrFDNTWidget19')}</u>
                  </strong>
                </li>
                <li>{I18n.t('Appintake.RnrFDNTWidget20')}</li>
              </ol>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget21')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget22')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget39')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget24')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget25')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget26')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget28')}{' '}
              <strong> {I18n.t('Appintake.RnrFDNTWidget31')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget29')}{' '}
              <strong> {I18n.t('Appintake.RnrFDNTWidget31')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget30')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget33')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>
              {I18n.t('Appintake.RnrFDNTWidget34')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrFDNTWidget35')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>
              &nbsp; {I18n.t('Appintake.RnrFDNTWidget36')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function RnrHEWLWidget(props) {
  let applicantName
  try {
    let thisApplicant = props.formContext.formData.Application.clients[0]
    if (thisApplicant && thisApplicant.clientFstNm) {
      let name = thisApplicant.clientFstNm
      if (thisApplicant.clientLstNm) {
        name += ' ' + thisApplicant.clientLstNm
      }
      applicantName = name
    } else {
      applicantName = 'Undefined'
    }
  } catch (e) {
    applicantName = 'Undefined'
  }

  return (
    <div>
      <h2 className="aIHeader rights-font">
        Health Care (Medicaid and WVCHIP) and Medicare Premium Assistance
      </h2>
      <div className="aIDiv print1 " id="pr1">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr1')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            {I18n.t('General.printButtonLabel')}
          </button>
        </p>
        <div>
          <div className="rnrContentBox">
            <h3 className="aIh5 " id="pr">
              {I18n.t('Appintake.HealthCoveragePrograms')}
            </h3>
            <p>
              {I18n.t('Appintake.federalLawProhbits')}{' '}
              <a
                href="https://www.hhs.gov/ocr/office/file"
                target="_blank"
                className="link-line"
              >
                <u>www.hhs.gov/ocr/office/file</u>
              </a>{' '}
              {I18n.t('Appintake.writingHHSDirector')}
            </p>
          </div>

          <ul>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.recipientofMedicaid')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>
              {I18n.t('Appintake.medicalAssistanceForMyChild')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.incomeIsAbove')}
              <strong> I further understand </strong>{' '}
              {I18n.t('Appintake.myWorkerwill')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>
              {I18n.t('Appintake.periodOfIneligibility')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.requiredtoDisclose')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.stateMustBeNamed')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.lawMandates')}{' '}
              <ul>
                <li>{I18n.t('Appintake.individualQualifies')}</li>
                <li>{I18n.t('Appintake.individualHasSurvingSpouse')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget1')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget2')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget3')}</li>
              </ul>
              <p>{I18n.t('Appintake.RnrHEWLWidget4')}</p>
              <p>{I18n.t('Appintake.RnrHEWLWidget5')}</p>
              <p>{I18n.t('Appintake.RnrHEWLWidget6')}</p>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget7')}
              <ol type="A">
                <li>{I18n.t('Appintake.RnrHEWLWidget8')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget9')}</li>
                <li>{I18n.t('Appintake.RnrHEWLWidget10')}</li>
              </ol>
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget11')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget12')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget13')}{' '}
              <strong> {I18n.t('Appintake.IAgree')} </strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget14')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget15')}{' '}
              <strong> {I18n.t('Appintake.authorize')} </strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget16')}{' '}
              {I18n.t('Appintake.RnrHEWLWidget17')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget18')}{' '}
              <strong>{I18n.t('Appintake.furtherAuthorize')} </strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget19')}
            </li>
            <li>
              {I18n.t('Appintake.iUnderstand')}{' '}
              {I18n.t('Appintake.RnrHEWLWidget20')}
            </li>
            <li>
              {I18n.t('Appintake.IConfirm')}{' '}
              {I18n.t('Appintake.RnrHEWLWidget24')}{' '}
              <strong>{applicantName}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget25')}
            </li>
            <li>
              {I18n.t('Appintake.iUnderstand')}{' '}
              {I18n.t('Appintake.RnrHEWLWidget26')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget27')}{' '}
              <strong> {I18n.t('Appintake.rnrWidgetL14Strong')}</strong>{' '}
              {I18n.t('Appintake.RnrHEWLWidget28')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function RnrHMENWidget() {
  return (
    <div>
      <h2 className="aIHeader rights-font">
        {I18n.t('Appintake.homeEnergyAssistance')}
      </h2>
      <div className="aIDiv print1 " id="pr1">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr1')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            Print
          </button>
        </p>
        <div>
          <ul>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.knowinglyProvide')}
            </li>
            <li>
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.willBeNotified')}{' '}
              <strong> {I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.paymentMustBeUsed')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.foundEligible')}{' '}
              <strong>{I18n.t('Appintake.iUnderstand')}</strong>{' '}
              {I18n.t('Appintake.regularEmergencyLieap')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

/* print  For Right & Responsiblities*/
function printElem(id) {
  let newWin = window.open('', '', 'width=1000,height=800')
  newWin.document.write(
    '<html><head><title></title><style>.content h6 {text-align: center;}.content .centered {  text-align: center;}.bordered h6 {   border: 1px solid black;    padding: 5px 15px;    font-size: 13px!important; } .text-format{list-style-type:upper-alpha;}  .Rights-responsiblities >li{ padding-top:14px;margin-right:3px;} .left-align{ margin-left:4em;}</style></head><body>'
  )
  let prele = document.getElementById(id)
  const printButtonElements = prele.querySelectorAll('.printStyle')
  _.map(printButtonElements, printButton => {
    _.set(printButton, 'hidden', true)
  })
  newWin.document.write(prele.innerHTML)
  newWin.document.close()
  newWin.focus()
  newWin.window.print()
  newWin.close()
}

function PvcyiWidget() {
  return (
    <div
      className="main "
      style={{
        overflow: 'auto',
        height: '540px',
        border: '1px solid black',
        padding: '14px'
      }}
      id="pr1"
    >
      <div className="content centered">
        <h3>{I18n.t('Appintake.noticeOfPrivacy')} </h3>
        <button
          type="button"
          className="printStyle print btn btn-tiny btn-white"
          onClick={function() {
            printElem('pr1')
          }}
        >
          {' '}
          <span className="fa fa-print btn-icon-sm" />
          Print
        </button>
        <p className="centered">
          {I18n.t('Appintake.westVirginiaDepartmentOfHealthAndHR')}
          <br />
        </p>
      </div>
      <div className="content">
        <strong>{I18n.t('Appintake.effectiveDateOfNotice')} </strong>
        <p>{I18n.t('Appintake.effectiveDateOfNoticeDescription')} </p>
      </div>
      <div className="align notice-align-p">
        <p>
          <br />
          {I18n.t('Appintake.NOTICEDESCRIBES')}
          <br />
        </p>
      </div>
      <div className="content">
        <h3>{I18n.t('Appintake.PRIVACYANDYOU')} </h3>
        <p>{I18n.t('Appintake.PRIVACYANDYOUdesc')} </p>
      </div>
      <div className="content">
        <h3> {I18n.t('Appintake.CHANGESTONOTICEOFPRIVACY')} </h3>
        <p>{I18n.t('Appintake.CHANGESTONOTICEOFPRIVACYdesc')} </p>
      </div>
      <div className="content">
        <h3>{I18n.t('Appintake.HOWWEMAYUSEANDSHARE')} </h3>
        <p>
          {I18n.t('Appintake.HOWWEMAYUSEANDSHAREdesc')}
          <ul className="Pvcy-list">
            <li>{I18n.t('Appintake.HOWWEMAYUSEANDSHAREdescL1')}</li>
            <li>{I18n.t('Appintake.HOWWEMAYUSEANDSHAREdescL2')}</li>
            <li>{I18n.t('Appintake.HOWWEMAYUSEANDSHAREdescL3')}</li>
            <li>{I18n.t('Appintake.HOWWEMAYUSEANDSHAREdescL4')}</li>
          </ul>
        </p>
      </div>
      <div className="content">
        <h3>{I18n.t('Appintake.WHYWEMAYUSEORSHARE')}</h3>
        <ol className="Pvcy-list" list-style-type="1">
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL1Strong')}</strong>{' '}
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL1')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL2Strong')}</strong>{' '}
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL2')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL3Strong')}</strong>{' '}
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL3')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL4Strong')}</strong>{' '}
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL4')}
          </li>
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL5Strong')}</strong>{' '}
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL5')}{' '}
          </li>
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL6Strong')}</strong>{' '}
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL6')}{' '}
          </li>
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL7Strong')}</strong>{' '}
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL7')}{' '}
          </li>
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL8Strong')}</strong>
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL8')}{' '}
          </li>
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL9Strong')}</strong>
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL9')}{' '}
          </li>
          <li>
            <strong>{I18n.t('Appintake.WHYWEMAYUSEORSHAREL10Strong')}</strong>{' '}
            {I18n.t('Appintake.WHYWEMAYUSEORSHAREL10')}
          </li>
        </ol>
        <p>
          <strong>{I18n.t('Appintake.iUnderstand')} </strong>{' '}
          {I18n.t('Appintake.WHYWEMAYUSEORSHAREp')}{' '}
        </p>
      </div>
      <div className="content">
        <h3>{I18n.t('Appintake.WRITTENPERMISSION')} </h3>
        <p>{I18n.t('Appintake.WRITTENPERMISSIONp1')} </p>
      </div>
      <div className="content">
        <h3>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTS')} </h3>
        <div className="sublist">
          <strong>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShave')}</strong>

          <ul className="Pvcy-list">
            <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL1')}</li>
            <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL2')}</li>
            <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL3')}</li>
            <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL4')}</li>
            <li>{I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL5')} </li>
            <li>
              <b> {I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL6')}</b>{' '}
            </li>
            <li>
              {I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL7')}
              <a
                href="https://dhhr.wv.gov/Pages/default.aspx"
                className="link-line"
              >
                {I18n.t('Appintake.WHATAREMYPRIVACYRIGHTShaveL7Link')}
              </a>{' '}
            </li>
          </ul>
        </div>
      </div>
      <div className="align border-address">
        <h3> {I18n.t('Appintake.IMPORTANT')}</h3>
        <p>
          <br />
          {I18n.t('Appintake.IMPORTANTp')}
        </p>
      </div>
      <div className="content">
        <h3> {I18n.t('Appintake.HOWDOIASKABOUTMYPRIVACYRIGHTS')} </h3>
        <p>{I18n.t('Appintake.HOWDOIASKABOUTMYPRIVACYRIGHTSp')} </p>
      </div>
      <div className="align border-address">
        <h3> {I18n.t('Appintake.clientServices')} </h3>
        <address>{I18n.t('Appintake.clientServicesAddress')}</address>
      </div>
      <div className="content">
        <h3>{I18n.t('Appintake.HOWDOICOMPLAIN')} </h3>
        <p>{I18n.t('Appintake.HOWDOICOMPLAINp')} </p>
      </div>
      <div className="align border-address">
        <h3>{I18n.t('Appintake.privacyOfficer')}</h3>
        <address>{I18n.t('Appintake.privacyOfficerAddress')}</address>
      </div>
      <div className="align border-address">
        <h3> {I18n.t('Appintake.privacyOfficer')}</h3>
        <address>{I18n.t('Appintake.privacyOfficer1Address')}</address>
      </div>
      <div className="align border-address">
        <h3>{I18n.t('ClientUpdateWizard.SecretaryUSDepartment')}</h3>
        <address>
          <br />
          {I18n.t('ClientUpdateWizard.SecretaryUSDepartmentAddr')}
        </address>
      </div>

      <div className="content introduction-p-style">
        <h3> {I18n.t('Appintake.NORETALIATION')} </h3>
        <p> {I18n.t('Appintake.NORETALIATIONp')} </p>
      </div>
      <div className="content">
        <h3> {I18n.t('Appintake.QUESTIONS')} </h3>
        <p> {I18n.t('Appintake.QUESTIONSp')} </p>
      </div>

      <p>
        {I18n.t('Appintake.localCountyOffices')}{' '}
        <a
          href="https://dhhr.wv.gov/bms/Pages/default.aspx"
          className="link-line"
        >
          {' '}
          www.wvdhhr.org/bms.
        </a>{' '}
      </p>
    </div>
  )
}

function SsninfoWidget() {
  return (
    <div>
      <p>{I18n.t('ClientUpdateWizard.SsninfoWidgetText')}</p>
      <br />
      <p>{I18n.t('ClientUpdateWizard.SsninfoWidgetText1')}</p>
    </div>
  )
}

function checkingConditionsFull() {
  return (
    <div>
      <ul>
        <li>{I18n.t('Appintake.readByMe')}</li>
        <li>{I18n.t('Appintake.acceptResponsibilities')}</li>
        <li>{I18n.t('Appintake.informationIsTrue')}</li>
        <li>{I18n.t('Appintake.electronicallySign')}</li>
      </ul>
    </div>
  )
}

export const fields = {
  conditioncoc: ConditionField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  panelGroup: Fields.CollapsiblePanelGroupField,
  tabs: Fields.TabsField,
  carousel: Fields.CarouselField,
  panel: Fields.PanelField,
  tableRow: Fields.TableRowField,
  trackerTable: Fields.TrackerTable,
  mcr: Fields.CodeReferenceField,
  publicTrackerForm: Fields.PublicTrackerForm,
  authorizedRepresentative: AuthorizedRepField,
  reviewDifference: ReviewDifference,
  reviewPublicTrackerForm: ReviewPublicTrackerForm,
  condition: Fields.ConditionalField
}

export const widgets = {
  radio: validateCustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  checkboxes: Widgets.CustomCheckboxesWidget,
  'alt-date': Widgets.DatePickerWidget,
  review: Widgets.ReviewWidget,
  dollar: customWidgets.customDollarWidget,
  span: Widgets.SpanWidget,
  routeLink: Widgets.LinkWidget,
  header: Widgets.HeaderWidget,
  validatedText: Widgets.ValidatedTextWidget,
  phone: Widgets.PhoneWidget,
  ssn: Widgets.SSNWidget,
  cocreviewInfo: Widgets.ReviewWidget,
  DHHRInsruction: DHHRInsruction,
  introduction: IntroductionWidget,
  voterLink: RegisterWidget,
  rnrInfo: RnrWidget,
  checkingConditionsFull: checkingConditionsFull,
  rnrInfoFDNT: RnrFDNTWidget,
  rnrInfoHEWL: RnrHEWLWidget,
  rnrInfoHMEN: RnrHMENWidget,
  cri: CriWidget,
  pvcyi: PvcyiWidget,
  rnrInfoTANF: rnrInfoTANF,
  ssnInfo: SsninfoWidget,
  ssn1: Widgets.SSNWidgetV2,
  textarea: Widgets.CustomTextAreaWidget,
  county: Widgets.CustomCountyWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
