import * as React from 'react'
import { InputGroup, FormControl } from 'react-bootstrap'
import { addCents } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'

export class customDollarWidget extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value ? addCents(props.value) : props.value
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
  }

  handleChange(event) {
    let candidateValue = event.target.value ? String(event.target.value) : ''
    let newValue = ''

    let dollarAmtPattern = '^(([0-9]+\\.?[0-9]{0,2})|())$'
    let dollarAmtRe = new RegExp(dollarAmtPattern)
    let isDollarAmtValue = dollarAmtRe.test(candidateValue)

    const candidateValueIntegralPortion = this._getIntegralPortion(
      candidateValue
    )
    const { maxLength } = this.props.options

    let isPermissibleLength =
      maxLength === undefined ||
      (maxLength &&
        !isNaN(maxLength) &&
        candidateValueIntegralPortion.length <= maxLength)

    if (isDollarAmtValue && isPermissibleLength) {
      newValue = candidateValue
    } else {
      newValue = this.state.value || ''
    }

    this.setState(
      {
        value: newValue
      },
      () => this.props.onChange(this.state.value)
    )
  }

  _getIntegralPortion(numberToParse) {
    let number = numberToParse ? String(numberToParse) : ''
    let decimalIndex = number.indexOf('.')
    let integralPortionEndIndex =
      decimalIndex > -1 ? decimalIndex : number.length
    let integralPortion = number
      ? number.substring(0, integralPortionEndIndex)
      : ''
    return integralPortion
  }

  handleBlur = event => {
    const { noFormatCents } = this.props.options
    if (!noFormatCents) {
      const newValue = addCents(event.target.value)
      this.setState({ value: newValue })
    } else {
      this.setState({ value: parseFloat(event.target.value) })
    }
  }

  render() {
    const isReadonly = helper.resolveOptionalPropRefs(
      'readonly',
      this.props,
      typeof this.props.readonly !== 'undefined' ? this.props.readonly : false
    )
    let value = this.state.value
    if (isReadonly) {
      value = this.state.value ? addCents(this.state.value) : this.state.value
    }
    return (
      <InputGroup>
        <InputGroup.Prepend>$</InputGroup.Prepend>
        <FormControl
          id={this.props.id}
          type="text"
          value={value}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          disabled={this.props.disabled}
          readOnly={isReadonly}
          autoComplete="off"
        />
      </InputGroup>
    )
  }
}