import * as React from 'react'
import {
  Modal,
  Table,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  FormCheck
} from 'react-bootstrap'
import { config } from '../../../config' // endpoints
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { connect } from 'react-redux'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash' // extra functionality

const baseUrl = process.env.PUBLIC_URL

const PeVerifyEndpoint = config['gatewayWvUrl'] + config['verify']
const pEGetAppDetailsEndpoint =
  config['gatewayWvUrl'] + config['pEapplicationsId'] + '?q=detail'
const pESubmitEndpoint = config['gatewayWvUrl'] + config['pESubmitApplications']
const savePEDraftEndPoint = config['gatewayWvUrl'] + config['savePEApplication']
const submitPEDeterminationEndpoint =
  config['gatewayWvUrl'] + config['submitPEDetermination']

class ConfirmModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      modalOpen: true,
      redirectURL: null,
      response: null,
      applId: null,
      textdata: '',
      orgId: null,
      userId: null,
      isLoading: false,
      showErrorModal: false,
      hideErrorModal: false,
      errorMessage: null,
      ErrorModal: null,
      currentDate: getServerTime()
    }
    this._closeModal = this._closeModal.bind(this)
    this._hideModal = this._hideModal.bind(this)
    this._openModal = this._openModal.bind(this)
    this._submitDetermination = this._submitDetermination.bind(this)
    this._setNoVisibility = this._setNoVisibility.bind(this)
    this._setYesVisibility = this._setYesVisibility.bind(this)
    this._displayOther = this._displayOther.bind(this)
    this._handleClick = this._handleClick.bind(this)
    this._handleClickOne = this._handleClickOne.bind(this)
    this._handleClickTwo = this._handleClickTwo.bind(this)
    this._handleClickThree = this._handleClickThree.bind(this)
    this._handleClickFour = this._handleClickFour.bind(this)
    this._handleChange = this._handleChange.bind(this)
    this._enableButton = this._enableButton.bind(this)
  }

  componentDidMount() {
    let applId = this.props.getApplID
    const fetchEndpoint = pEGetAppDetailsEndpoint.replace('{applId}', applId)
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.getUUID,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.getaccessToken,
        portalName: config.portalName,
        orgId: this.props.getorgId.toString(),
        roleId: this.props.getRoleId
      }
    }
    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      .then(formJson => {
        let status = formJson.result.applicationDetail[0].Application.Status
        this.setState({ disableButton: true })
        this.setState({ buttonEnable: false })
        let isdisable = false
        let isdisplay = 'block'
        if (status == 'Submitted') {
          isdisable = true
          isdisplay = 'none'
          this.setState({ display: 'none' })
        } else {
          this.setState({ display: 'block' })
        }
        formJson.result.applicationDetail[0].applId = applId
        this.setState({ datedisplay: 'block' })
        this.setState({ applicationData: formJson.result.applicationDetail[0] }) //500
        this.setState({ isDisable: isdisable })
        this.setState({ showOption: 'none' })
        this.setState({ showOther: 'none' })
        this.setState({ isHide: false })

        var date = _moment(config, this.state.currentDate)
          .date()
          .toString()
        if (date.length < 2) {
          date = '0' + date
        }
        var month = (
          _moment(config, this.state.currentDate).month() + 1
        ).toString()
        if (month.length < 2) {
          month = '0' + month
        }
        var year = _moment(config, this.state.currentDate).year()
        var dateFormat = year + '-' + month + '-' + date
        var yesdate = (
          _moment(config, this.state.currentDate).date() - 1
        ).toString()
        var yesmonth = _moment(config, this.state.currentDate).month() + 1
        if (yesdate.length < 2) {
          yesdate = '0' + yesdate
          if (yesdate == '00') {
            yesdate = '31'
            yesmonth = _moment(config, this.state.currentDate).month()
          }
        }
        var yesmonth1 = yesmonth.toString()

        if (yesmonth1.length < 2) {
          yesmonth1 = '0' + yesmonth1
        }

        var yesyear = _moment(config, this.state.currentDate).year()
        var yesterdaydateFormat = yesyear + '-' + yesmonth1 + '-' + yesdate

        this.setState({ yesterday: yesterdaydateFormat })
        this.setState({ today: dateFormat })
        this.setState({ checked: false })
        this.setState({ isPeEligibleIn12Monthschecked: false })
        this.setState({ notUsCitizenchecked: false })
        this.setState({ notWestVirginiaResidentchecked: false })
        this.setState({ incomeExceedsStandardchecked: false })
        this.setState({ notMemberOfPeGroupchecked: false })
        this.setState({ otherReasonText: '' })
        this.setState({ isPeEligibleIn12Months: 'N' })
        this.setState({ notUsCitizen: 'N' })
        this.setState({ notWestVirginiaResident: 'N' })
        this.setState({ incomeExceedsStandard: 'N' })
        this.setState({ notMemberOfPeGroup: 'N' })
        this.setState({ otherReason: 'N' })
        this.setState({
          selectdate: _moment(config, this.state.currentDate).format(
            'YYYY-MM-DD'
          )
        })
        this._verify()
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirectURL: baseUrl + '/home' })
        }
        console.error(
          'Confirm Verify Complete Modal form fetch failed due to ex',
          error
        )
      })
  }

  _closeModal = () => {
    // window.location.href = this.props.getRedirectUrl
    this.setState(
      {
        modalOpen: false
      },
      () => {
        this.props.setPopupoadToFalse()
      }
    )
  }

  _hideModal = () => {
    this.setState({ showErrorModal: false })
  }

  _openModal = () => {
    this.setState({ modalOpen: true })
  }

  _setNoVisibility = () => {
    this.setState({ showOption: 'block' })
    this.setState({ isHide: true })
    this.setState({ datedisplay: 'none' })
    this.setState({ radiochk: false })
  }

  _setYesVisibility = () => {
    this.setState({ showOption: 'none' })
    this.setState({ isHide: false })
    this.setState({ datedisplay: 'block' })
    this.setState({ radiochk: true })
  }

  _displayOther = () => {
    var chkStatus = this.state.checked
    if (chkStatus == false) {
      this.setState({ checked: true })
      this.setState({ showOther: 'block' })
      this.setState({ otherReason: 'Y' })
    } else {
      this.setState({ checked: false })
      this.setState({ showOther: 'none' })
      this.setState({ otherReason: 'N' })
      this.setState({ otherReasonText: '' })
    }
  }

  _handleClick = () => {
    var chkStatus = this.state.isPeEligibleIn12Monthschecked
    if (chkStatus == false) {
      this.setState({ isPeEligibleIn12Monthschecked: true })
      this.setState({ isPeEligibleIn12Months: 'Y' })
    } else {
      this.setState({ isPeEligibleIn12Monthschecked: false })
      this.setState({ isPeEligibleIn12Months: 'N' })
    }
  }
  _handleClickOne = () => {
    var chkStatus = this.state.notUsCitizenchecked
    if (chkStatus == false) {
      this.setState({ notUsCitizenchecked: true })
      this.setState({ notUsCitizen: 'Y' })
    } else {
      this.setState({ notUsCitizenchecked: false })
      this.setState({ notUsCitizen: 'N' })
    }
  }
  _handleClickTwo = () => {
    var chkStatus = this.state.notWestVirginiaResidentchecked
    if (chkStatus == false) {
      this.setState({ notWestVirginiaResidentchecked: true })
      this.setState({ notWestVirginiaResident: 'Y' })
    } else {
      this.setState({ notWestVirginiaResidentchecked: false })
      this.setState({ notWestVirginiaResident: 'N' })
    }
  }
  _handleClickThree = () => {
    var chkStatus = this.state.incomeExceedsStandardchecked
    if (chkStatus == false) {
      this.setState({ incomeExceedsStandardchecked: true })
      this.setState({ incomeExceedsStandard: 'Y' })
    } else {
      this.setState({ incomeExceedsStandardchecked: false })
      this.setState({ incomeExceedsStandard: 'N' })
    }
  }
  _handleClickFour = () => {
    var chkStatus = this.state.notMemberOfPeGroupchecked
    if (chkStatus == false) {
      this.setState({ notMemberOfPeGroupchecked: true })
      this.setState({ notMemberOfPeGroup: 'Y' })
    } else {
      this.setState({ notMemberOfPeGroupchecked: false })
      this.setState({ notMemberOfPeGroup: 'N' })
    }
  }
  _handleChange = event => {
    this.setState({ otherReasonText: event.target.value })
  }

  _enableButton = () => {
    var chkStatus = this.state.buttonEnable
    if (chkStatus == false) {
      this.setState({ disableButton: false })
      this.setState({ buttonEnable: true })
    } else {
      this.setState({ disableButton: true })
      this.setState({ buttonEnable: false })
    }
  }

  _submitDetermination = () => {
    let appData = this.state.applicationData
    if (this.state.showOption == 'none') {
      appData.Application.presumEligStatus = 'Approved'
      appData.Application.presumEligStartDate = this.state.selectdate
      appData.Application.pEEligibilityReason = 'Yes'
    } else {
      appData.Application.presumEligStatus = 'Denied'
      appData.Application.pEEligibilityReason = 'No'
      appData.Application.peIneligibleReason = {
        isPeEligibleIn12Months: this.state.isPeEligibleIn12Months,
        notUsCitizen: this.state.notUsCitizen,
        notWestVirginiaResident: this.state.notWestVirginiaResident,
        incomeExceedsStandard: this.state.incomeExceedsStandard,
        notMemberOfPeGroup: this.state.notMemberOfPeGroup,
        otherReason: this.state.otherReason,
        otherReasonText: this.state.otherReasonText
      }
    }
    appData.Application.pEEligibilityResults = this.state.verifyResult
    appData.Application.notes = this.state.textdata
    const clients = _.get(appData, 'Application.clients', [])
    clients.forEach(client => {
      if (
        client.hasOwnProperty('mailingAddressCheck') &&
        client.mailingAddressCheck.length == 0
      ) {
        _.set(client, 'mailingAddressCheck', null)
      }
    })
    appData.Application.Status = 'Submitted'
    var date = _moment(config, this.state.currentDate)
      .date()
      .toString()
    if (date.length < 2) {
      date = '0' + date
    }
    let today = new Date(getServerTime())
    let submissionDate = today.toUTCString()
    appData.Application.applSubmissionDate = submissionDate

    const request = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        uuid: this.props.getUUID,
        userroleid: this.props.getUserRoleId.toString(),
        tenantcode: config.tCode,
        Authorization: config.bearer + this.props.getaccessToken,
        portalname: config.portalName,
        orgId: this.props.getorgId.toString(),
        roleId: this.props.getRoleId,
        tenantId: '1',
        applType: 'APPL',
        userRoleId: this.props.getUserRoleId,
        userId: this.props.getuserId,
        peId: this.props.getApplID
      },
      body: JSON.stringify(appData)
    }

    this.setState({ callingApi: true })
    this.setState({ isLoading: true })

    serviceHelper
      .fetchJson(
        submitPEDeterminationEndpoint,
        request,
        this.props.showErrorMessage
      )
      .then(applId => {
        this.setState({ isLoading: false, modalOpen: false })
        this._closeModal()
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirectURL: baseUrl + '/home' })
        }
        this.setState({ isLoading: false })
        this.setState({ showErrorModal: true })
        this.setState({
          errorMessage:
            'System Error. Your application submission has not been finalized, please try again.'
        })
      })
  }

  handleChange = event => {
    this.setState({ textdata: event.target.value })
  }

  handleSelectChange = value => {
    this.setState({ selectdate: value })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _verify = () => {
    const request = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        uuid: this.props.getUUID,
        userroleid: this.props.getUserRoleId.toString(),
        tenantcode: config.tCode,
        authorization: config.bearer + this.props.getaccessToken,
        portalname: config.portalName,
        orgId: this.props.getorgId.toString(),
        roleId: this.props.getRoleId
      },
      body: JSON.stringify(this.state.applicationData)
    }

    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(PeVerifyEndpoint, request, this.props.showErrorMessage)
      .then(response => {
        if (response) {
          return response
        } else {
          var error = new Error(response.statusText)
          throw error
        }
      })
      .then(json => {
        this.setState({
          verifyResult: json.pEEligibilityResults,
          income: json.pEEligibilityResults.incomeLevel,
          st: json.pEEligibilityResults.residentOfWestVirginia,
          pregenancy: json.pEEligibilityResults.pregnancy,
          notApprovedPEInLast12Months:
            json.pEEligibilityResults.notApprovedForPEInLast12Months,
          ssnBenefitApprovedInLast12Months:
            json.pEEligibilityResults.ssnBenefitApprovedInLast12Months,
          citizen: json.pEEligibilityResults.citizenship,
          fostercare: json.pEEligibilityResults.westVirginiaFosterCare,
          breastAndCervicalCancerPatients:
            json.pEEligibilityResults.breastAndCervicalCancerPatient,
          caretakerclient: json.pEEligibilityResults.parentCareTakerOver65,
          adultGroup: json.pEEligibilityResults.adultGroup,
          childGroup: json.pEEligibilityResults.childGroup
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirectURL: baseUrl + '/home' })
        }
        console.error('confirmComplete Modal _verify failed due to ex', error)
      })
  }

  render() {
    const { modalOpen, isLoading } = this.state
    const { serviceErrors } = this.props
    const screenLoad = this.state.isLoading ? <ScreenPreLoader /> : null
    const handleFour = () => (
      <div>
        This individual is not a member of following groups:
        <ul>
          <li>Children Under Age 19</li>
          <li>Pregnant Women</li>
          <li>Individuals under 133% FPL Ages 19-64</li>
          <li>former Foster Care Children Under 26</li>
          <li>
            Certain Individuals Needing Treatment for Breast or Cervical Cancer
          </li>
          <li>Parent/Caretaker Relative</li>
        </ul>
      </div>
    )
    return (
      <div>
        <Modal
          show={modalOpen}
          onHide={() => this._closeModal()}
          size={'lg'}
          aria-labelledby="contained-modal-title-lg"
        >
          <Modal.Header closeButton>
            <h3>Presumptive Eligibility Criteria Summary</h3>
          </Modal.Header>
          <div>
            <ScreenPreLoader loaded={!isLoading} />
          </div>
          <Modal.Body>
            <div>
              {this.state.ssnBenefitApprovedInLast12Months === 'Y' ? (
                <thead>
                  <tr>
                    <h3
                      style={{
                        background: 'red',
                        color: 'white'
                      }}
                    >
                      Warning
                    </h3>
                  </tr>
                  <tr>
                    <p
                      style={{
                        color: 'black'
                      }}
                    >
                      An individual with this SSN has been approved for
                      Presumptive Eligibility in the last 12 months.  Please
                      validate accordingly.
                    </p>
                  </tr>
                </thead>
              ) : (
                ''
              )}
            </div>
            <Table striped bordered size="sm" hover>
              <thead>
                <tr>
                  <th>Eligibility Criteria</th>
                  <th>Results</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>NOT approved for PE in the last 12 months</td>
                  <td> {this.state.notApprovedPEInLast12Months}</td>
                </tr>
                <tr>
                  <td>Citizenship or Satisfactory Immigration Status</td>
                  <td> {this.state.citizen}</td>
                </tr>
                <tr>
                  <td>Resident of West Virginia</td>
                  <td> {this.state.st}</td>
                </tr>
                <tr>
                  <td>Income Level</td>
                  <td> {this.state.income}</td>
                </tr>
                <tr>
                  <td>Pregnant Women</td>
                  <td> {this.state.pregenancy}</td>
                </tr>
                <tr>
                  <td>Former Foster Care Children</td>
                  <td> {this.state.fostercare}</td>
                </tr>
                <tr>
                  <td>Breast and Cervical Cancer Patient</td>
                  <td> {this.state.breastAndCervicalCancerPatients}</td>
                </tr>
                <tr>
                  <td>Parent/Caretaker Relative</td>
                  <td> {this.state.caretakerclient}</td>
                </tr>
                <tr>
                  <td>Adult Group</td>
                  <td> {this.state.adultGroup}</td>
                </tr>
                <tr>
                  <td>Children Under Age 19</td>
                  <td> {this.state.childGroup}</td>
                </tr>
              </tbody>
            </Table>

            <div style={{ display: this.state.display }}>
              <div>
                <h3
                  style={{
                    background: '#1274b2',
                    padding: '8px',
                    color: '#f2f2f2'
                  }}
                >
                  Notes
                </h3>
                <p style={{ padding: '5px' }}>
                  This field is for any notes/reminders the authorized
                  organization worker may need for future reference
                  (incarcerated individuals, individuals who have passed away
                  prior to the completion of the full application, etc).This is
                  internal information only and will not be shared with any
                  outside entities{' '}
                </p>
                <FormGroup controlId="formControlsTextarea">
                  <FormLabel />
                  <FormControl
                    as="textarea"
                    rows={8}
                    placeholder="Notes"
                    onChange={this.handleChange}
                    disabled={this.state.isDisable}
                    value={this.state.textdata}
                  />
                </FormGroup>
              </div>

              <div>
                <h3
                  style={{
                    background: '#1274b2',
                    padding: '8px',
                    color: '#f2f2f2'
                  }}
                >
                  Organization Determined Presumptive Eligibility Status
                </h3>
                <p>
                  Based on your state's policies, please select if the person is
                  eligible for Presumptive Eligibility.
                </p>
                <FormGroup>
                  <FormCheck
                    type="radio"
                    checked={this.state.radiochk}
                    name="g"
                    id="confirm_eligibility_yes"
                    disabled={this.state.isDisable}
                    onClick={this._setYesVisibility}
                    label=" Yes, this person is eligible for Presumptive Eligibility "
                  ></FormCheck>
                  <FormCheck
                    type="radio"
                    name="g"
                    id="confirm_eligibility_no"
                    disabled={this.state.isDisable}
                    onClick={this._setNoVisibility}
                    label=" No, this person is not eligible for Presumptive Eligibility "
                  ></FormCheck>
                </FormGroup>
                <FormGroup style={{ display: this.state.datedisplay }}>
                  <FormLabel>Presumptive Eligibility Start Date*</FormLabel>
                  <FormControl
                    as="select"
                    onChange={event => {
                      this.handleSelectChange(event.target.value)
                    }}
                    placeholder="select"
                    disabled={true}
                  >
                    <option
                      value={_moment(config, this.state.currentDate).format(
                        'YYYY-MM-DD'
                      )}
                    >
                      {_moment(config, this.state.currentDate).format(
                        'MM/DD/YYYY'
                      )}
                    </option>
                  </FormControl>
                </FormGroup>

                <div style={{ display: this.state.showOption }}>
                  <FormCheck
                    type="checkbox"
                    checked={this.state.isPeEligibleIn12Monthschecked}
                    onClick={this._handleClick}
                    label="This individual has had a PE period previously in the past 12 months  "
                  ></FormCheck>
                  <FormCheck
                    type="checkbox"
                    label="This individual is not a United States citizen or qualified immigrant "
                  ></FormCheck>
                  <FormCheck
                    type="checkbox"
                    checked={this.state.notWestVirginiaResidentchecked}
                    onClick={this._handleClickTwo}
                    label="This individual is not a West Virginia resident "
                  ></FormCheck>
                  <FormCheck
                    type="checkbox"
                    checked={this.state.incomeExceedsStandardchecked}
                    onClick={this._handleClickThree}
                    label="This individual's income exceeds the application income standard "
                  ></FormCheck>
                  <FormCheck
                    type="checkbox"
                    checked={this.state.notMemberOfPeGroupchecked}
                    onClick={this._handleClickFour}
                    label={handleFour()}
                  ></FormCheck>
                  <FormCheck
                    type="checkbox"
                    checked={this.state.checked}
                    onClick={this._displayOther}
                    label="Others"
                  ></FormCheck>
                  <input
                    type="text"
                    id="txtother"
                    value={this.state.otherReasonText}
                    onChange={this._handleChange}
                    style={{ display: this.state.showOther }}
                  />
                </div>
                <div>
                  <FormCheck
                    type="checkbox"
                    name="g"
                    id="notCovered_verify"
                    disabled={this.state.isDisable}
                    onClick={this._enableButton}
                    label="I have verified that applicant is not covered by Medicaid or CHIP*"
                  ></FormCheck>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              id="submit_determination"
              disabled={this.state.disableButton}
              onClick={this._submitDetermination}
            >
              Submit Determination
            </Button>
          </Modal.Footer>
        </Modal>
        <ErrorModal
          errorMessage={serviceErrors}
          onDeleteClick={this._onDeleteClick}
        />
        <Modal
          show={this.state.showErrorModal}
          onHide={() => this._hideModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{this.state.errorMessage}</div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn" onClick={this._hideModal}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    serviceErrors: state.myMessagesError.myMessagesError.errorMessage || [],
    getaccessToken: state.auth.accessToken,
    getRoleId: state.userAccess.selectedUserRole.role.roleId,
    getUserRoleId: state.userAccess.selectedUserRole.userRoleId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal)
