import * as React from 'react'
import { MyMessagesDoubleFetcher } from '../../base/MyMessages'
import { MyLifeEventsDoubleFetcher } from '../../base/MyLifeEvents'
import { ENTITLEMENTS as baseEnt } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import { ENTITLEMENTS_wv as wvEnt } from '../src/entitlements_wv'
import MyCalendar from '../MyAppointments'
import Reports from '../Reports'
import ApplicationSearch from '../ApplicationSearch'
import UserAccess from '../userAccess'
import Organization from '../Organization'
import MyQuickReports from '../MyQuickReports'
import CpRenewals from '../CpRenewals'
import BenHEligibleApplicant from '../BenHEligibleApplicant'
import BenHReferral from '../BenHReferral'
import BannerMessages from '../Banner/BannerMessages'
import ChildSupportPayments from '../../base/ChildSupportPayments'

export const MyAccountTabs = [
  {
    id: 'messages',
    clientTitle: 'MyAccount.myMessages',
    otherTitle: 'MyAccount.messages',
    leftIcon: 'glyphicon myglyphicon-chat',
    component: MyMessagesDoubleFetcher,
    entitlements: [baseEnt.CP_VIEW_MESSAGES]
  },
  {
    id: 'lifeevents',
    clientTitle: 'MyAccount.myChanges',
    otherTitle: 'MyAccount.myChanges',
    title: 'Changes',
    leftIcon: 'fa fa-child',
    component: MyLifeEventsDoubleFetcher,
    entitlements: [baseEnt.CP_VIEW_LIFEEVENTS],
    caseCheck: ['cca', 'fa']
  },
  {
    id: 'appointments',
    clientTitle: 'MyAccount.myAppointments',
    otherTitle: 'MyAccount.myAppointments',
    title: 'Appointments',
    leftIcon: 'glyphicon myglyphicon-calendar',
    component: MyCalendar,
    entitlements: [baseEnt.CP_VIEW_LIFEEVENTS],
    caseCheck: ['cca', 'fa']
  },
  {
    id: 'reports',
    clientTitle: 'MyAccount.myReports',
    otherTitle: 'MyAccount.myReports',
    title: 'Reports',
    leftIcon: 'pe-7s-copy-file',
    component: Reports,
    entitlements: [wvEnt.CP_WORKER_ADMIN]
  },
  {
    id: 'search',
    clientTitle: 'MyAccount.applicationSearch',
    otherTitle: 'MyAccount.applicationSearch',
    title: 'Application Search',
    leftIcon: 'pe-7s-note2',
    component: ApplicationSearch,
    entitlements: [wvEnt.CP_WORKER_ADMIN]
  },
  {
    id: 'userAccess',
    clientTitle: 'MyAccount.userAccess',
    otherTitle: 'MyAccount.userAccess',
    title: 'User Access',
    leftIcon: 'fa fa-users',
    component: UserAccess,
    entitlements: [wvEnt.CP_USER_ACCESS]
  },
  {
    id: 'organization',
    clientTitle: 'MyAccount.organization',
    otherTitle: 'MyAccount.organization',
    title: 'Organization',
    leftIcon: 'fa fa-briefcase',
    component: Organization,
    entitlements: [wvEnt.CP_MANAGE_ORGANIZATION]
  },
  {
    id: 'quickreport',
    clientTitle: 'MyAccount.quickReport',
    otherTitle: 'MyAccount.quickReport',
    title: 'quickreport',
    leftIcon: 'glyphicon myglyphicon-file',
    component: MyQuickReports,
    entitlements: [wvEnt.CP_VIEW_QUICKREPORTS]
  },
  {
    id: 'renewal',
    clientTitle: 'MyAccount.renewals',
    otherTitle: 'MyAccount.renewals',
    title: 'Renewals',
    leftIcon: 'pe-7s-gift',
    component: CpRenewals,
    entitlements: [wvEnt.CP_VIEW_QUICKREPORTS]
  },
  {
    id: 'eligibleApplicants',
    clientTitle: 'MyAccount.eligibleApplicants',
    otherTitle: 'MyAccount.eligibleApplicants',
    title: 'Eligible Applicants',
    leftIcon: 'pe-7s-copy-file',
    component: BenHEligibleApplicant,
    entitlements: [wvEnt.BH_ELIGIBLE_APPLICANT_VIEW],
    href: '/myaccount/eligibleApplicants'
  },
  {
    id: 'referrals',
    clientTitle: 'MyAccount.referrals',
    otherTitle: 'MyAccount.referrals',
    title: 'Referrals',
    leftIcon: 'pe-7s-note2',
    component: BenHReferral,
    entitlements: [wvEnt.BH_REFERRAL],
    href: '/myaccount/referrals'
  },
  {
    id: 'banner',
    clientTitle: 'MyAccount.bannerMessages',
    otherTitle: 'MyAccount.bannerMessages',
    title: 'Banner Messages',
    leftIcon: 'fa fa-bullhorn',
    component: BannerMessages,
    entitlements: [wvEnt.CP_ADMIN_WRITE_BANNER]
  },
  {
    id: 'childsupportpayments',
    clientTitle: 'Child Support Payments',
    otherTitle: 'Child Support Payments',
    leftIcon: 'fa fa-usd',
    component: ChildSupportPayments,
    entitlements: ['CP_VIEW_MESSAGES'],
    caseCheck: ['cs']
  }
]
