import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { isEqual, get, some } from 'lodash'
import { config } from '../../../config'
import { connect } from 'react-redux'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'
import { I18n } from 'react-redux-i18n'

const _ = { isEqual, get, some }

declare const process
const baseUrl = process.env.PUBLIC_URL

const formsEndpoint = config['forms_Feedback']

interface FeedbackProps {
  presentation: any
  locale: string
  uuid: string
  authHeader: string
}

interface FeedbackState {
  application: {}
  form: boolean
  formData: {}
  forms: any
  formContext: any
}

class FeedbackContainer extends React.Component<FeedbackProps, FeedbackState> {
  constructor(props: FeedbackProps) {
    super(props)

    this.state = {
      application: {},
      form: false, // dictates if form is loaded
      formData: {},
      forms: null,
      formContext: null
    }
  }

  componentDidMount() {
    this._loadApp() // UNCOMMENT FOR INITAL APPJSON LOAD
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      helper
        .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(json => {
          this.setState(prevState => {
            return {
              application: json['app'],
              forms: json['app']['forms'],
              formContext: {
                ...prevState.formContext,
                forms: json['app']['forms']
              }
            }
          })
        })
        .catch(error => {
          console.error('Feedback form fetch failed due to ex', error)
          const code = CODES.FEEDBACK_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _loadApp = () => {
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        this._loadAll(formJson)
      })
      .catch(error => {
        console.error('Feedback form fetch failed due to ex', error)
        const code = CODES.FEEDBACK_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _loadAll = (json: object) => {
    let tabs = [] // navigation tabs
    let forms, application, formData, formContext
    application = json['app']
    forms = application['forms']

    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = { forms: forms, config }

    formData = helper.createObjectFromMasterSchema(null, formContext)

    formContext = { ...formContext, formData: formData }

    this.setState({ application, forms, formData, formContext })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData
        }
      }
    })
  }

  _onSubmit = ({ formData }) => {
    this.setState({ formData })
    alert(JSON.stringify(formData, null, 2))
  }

  _isFormDataEmpty = () => {
    const { formData } = this.state
    const app = _.get(formData, 'Application', {})
    return !_.some(app, value => {
      return value
    })
  }

  render() {
    const { presentation, locale } = this.props
    const { forms, formContext, application, formData } = this.state

    let formsOutput = []
    let applicationTitle
    let previousButton: any
    let submitButton
    let loaded = false

    const presentationProps = {
      application,
      forms,
      formData,
      formContext,
      onFormDataChange: this._onFormDataChange,
      onSubmit: this._onSubmit,
      locale,
      isFormDataEmpty: this._isFormDataEmpty
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    locale: state.i18n.locale,
    uuid,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}
export default connect(mapStateToProps)(FeedbackContainer)
