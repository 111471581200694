import * as React from 'react'
import { connect } from 'react-redux'
import { isNil, find, get, toInteger, includes, some } from 'lodash'
import { config } from '../../../config'
import { Redirect } from 'react-router-dom'
import * as actions from '../../../actions'
import {
  ENTITLEMENTS,
  CATEGORIES
} from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'

const _ = { isNil, find, get, toInteger, includes, some }

declare var process
const baseUrl = process.env.PUBLIC_URL

const updateDefaultUserRoleEndpoint = config['updateDefaultUserRole']

interface Entitlement {
  entitlementId: number
  entitlementName: string
  entitlementDescription: string
}

interface UserRole {
  userRoleId: number
  uuid: string
  role: {
    roleId: number
    roleName: string
    multilingualDisplayName: {
      en: string
      es: string
    }
    multilingualDescription: {
      en: string
      es: string
    }
    tenantId: number
    status: string
    startDate: string
    endDate: string
  }
  entitlements: Array<Entitlement>
}

interface SelectRoleContainerProps {
  presentation: any
  userAccount: {
    uuid: string
    defaultUserRoleId: number
    isDefaultUserRole: boolean
  }
  userAccess: {
    userRoles: Array<UserRole>
    selectedUserRole: UserRole
  }
  setSelectedUserRole: (selectedUserRole: UserRole, accessToken: string) => void
  setDefaultUserRoleId: (userRoleId: number) => void
  location: {
    search: string
  }
  locale: string
  authHeader: string
  accessToken: string
}

interface SelectRoleContainerState {
  userRoles: Array<UserRole>
  loaded: boolean
  isDefaultUserRole: boolean
  redirect: string
  selectedUserRoleId: number
}

/**
 * @author smahan3
 */
class SelectRoleContainer extends React.Component<
  SelectRoleContainerProps,
  SelectRoleContainerState
> {
  constructor(props: SelectRoleContainerProps) {
    super(props)

    this.state = {
      userRoles: null,
      loaded: false,
      isDefaultUserRole: this.props.userAccount.isDefaultUserRole || false,
      redirect: null,
      selectedUserRoleId: null
    }
  }

  componentDidMount() {
    this._loadData(this.props.userAccess)
  }

  _loadData(json) {
    const userRoles = json.userRoles
    const selectedUserRole = json.selectedUserRole
    const defaultUserRoleId = this.props.userAccount.defaultUserRoleId
    const isDefaultUserRole = !_.isNil(defaultUserRoleId)
    const selectedUserRoleId = _.isNil(selectedUserRole)
      ? null
      : selectedUserRole.userRoleId

    this.setState({
      userRoles,
      loaded: true,
      isDefaultUserRole,
      selectedUserRoleId
    })
  }

  handleRoleClick = event => {
    const value = _.get(event, 'target.id')
    this.setState({ selectedUserRoleId: value })
  }

  handleDefaultRoleCheckbox = event => {
    this.setState({
      isDefaultUserRole: _.get(event, 'target.checked')
    })
  }

  _handleSubmit = () => {
    this._updateUserRoleSelection()
  }

  _updateUserRoleSelection = () => {
    if (this.state.isDefaultUserRole) {
      const isDefaultUserRole = 'Y'
      const updateEndpoint = updateDefaultUserRoleEndpoint
        .replace('{userRoleId}', this.state.selectedUserRoleId.toString())
        .replace('{isDefaultUserRole}', isDefaultUserRole)

      helper
        .fetchJson(updateEndpoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.userAccount.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(response => {})
        .catch(error => {
          console.error(
            'SelectRole _updateUserRoleSelection failed due to ex',
            error,
            'endpoint: ',
            updateEndpoint
          )
          const code = CODES.SELECT_ROLE_UPDATE_DEFAULT_ROLE
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }

    // 2. navigate to home page based on selected role
    const selectedUserRole: UserRole = _.find(this.props.userAccess.userRoles, {
      userRoleId: _.toInteger(this.state.selectedUserRoleId)
    })
    const selectedEntitlements = _.get(
      selectedUserRole,
      'entitlements',
      []
    ).map(element => element.entitlementName)

    let redirect = baseUrl + '/home'

    if (
      _.includes(
        selectedEntitlements,
        ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
      )
    ) {
      redirect = baseUrl + '/myaccount/dashboard'
    } else if (
      _.includes(selectedEntitlements, ENTITLEMENTS.COMMUNITY_PARTNERSHIP)
    ) {
      redirect = baseUrl + '/myaccount/dashboard'
    }

    this.setState({ redirect }, () => {
      this.props.setSelectedUserRole(selectedUserRole, this.props.accessToken)
      this.props.setDefaultUserRoleId(
        this.state.isDefaultUserRole ? selectedUserRole.userRoleId : null
      )
    })
  }

  _shouldContinue = () => {
    // if a role is selected, enable Continue button.
    return !_.isNil(this.state.selectedUserRoleId)
  }

  _roleIconClass = userRole => {
    const entitlements = (userRole.entitlements || []).map(
      element => element.entitlementName
    )
    if (
      _.includes(entitlements, ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT)
    ) {
      return 'pe-7s-star pe-7s-icon-90'
    } else if (_.includes(entitlements, ENTITLEMENTS.COMMUNITY_PARTNERSHIP)) {
      return 'pe-7s-home pe-7s-icon-90'
    } else if (_.includes(entitlements, ENTITLEMENTS.CLIENT_PARTICIPATION)) {
      return 'pe-7s-user pe-7s-icon-90'
    } else if (
      _.some(entitlements, entitlement =>
        _.includes(CATEGORIES.ADMIN_PORTAL_ACCESS_MANAGEMENT, entitlement)
      )
    ) {
      return 'pe-7s-tools pe-7s-icon-90'
    } else {
      return 'pe-7s-more pe-7s-icon-90'
    }
  }

  _roleTileClass = userRole => {
    const baseClass =
      !_.isNil(this.state.selectedUserRoleId) &&
      _.toInteger(this.state.selectedUserRoleId) === userRole.userRoleId
        ? 'select-role-tile-selected'
        : 'select-role-tile'
    return baseClass + ' text-center font-weight-bold'
  }

  render() {
    const { presentation, locale } = this.props
    const { userRoles, loaded, redirect, isDefaultUserRole } = this.state

    if (!_.isNil(redirect)) {
      return (
        <Redirect
          to={{ pathname: redirect, state: { from: this.props.location } }}
        />
      )
    }

    const presentationProps = {
      userRoles,
      loaded,
      shouldContinue: this._shouldContinue,
      handleSubmit: this._handleSubmit,
      roleTileClass: this._roleTileClass,
      roleIconClass: this._roleIconClass,
      isDefaultUserRole,
      handleDefaultRoleCheckbox: this.handleDefaultRoleCheckbox,
      locale,
      handleRoleClick: this.handleRoleClick
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    userAccount: state.auth.userAccount,
    userAccess: state.userAccess,
    locale: state.i18n.locale,
    accessToken,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedUserRole: selectedUserRole => {
      dispatch(actions.setSelectedUserRole(selectedUserRole))
    },
    setDefaultUserRoleId: userRoleId => {
      dispatch(actions.setDefaultUserRoleId(userRoleId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectRoleContainer)
