import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import '!style-loader!css-loader!./reports.css'
import { Pagination } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Pagination'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorModal } from '../src/components/natives/ErrorModal'

import 'react-datepicker/dist/react-datepicker.css'

interface ReportsPresentationProps {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  currentPage: number
  formContext: any
  formData: any
  hasKeyedAny: () => boolean
  onFormDataChange: (formData: any) => void
  isLoading: boolean
  minHeight: string
  formComponents
  search: () => void
  showBackResultsTable: () => void
  searchResults: any
  reset: () => void
  showResultsTable: boolean
  pageChangeDetected: (pageNumber: number) => void
  errorDisplay: boolean
  errorFrom: boolean
  errorTo: boolean
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function ReportsPresentation(props: ReportsPresentationProps) {
  const {
    currentPage,
    pageChangeDetected,
    form,
    formContext,
    onFormDataChange,
    isLoading,
    minHeight,
    formComponents,
    search,
    searchResults,
    showResultsTable,
    showBackResultsTable,
    reset,
    hasKeyedAny,
    errorDisplay,
    errorFrom,
    errorTo,
    errorMessage,
    onDeleteClick
  } = props

  const disableButtons = !hasKeyedAny()
  const resultChunks = _.isNil(searchResults) ? [] : _.chunk(searchResults, 20)

  const header = isLoading ? <h2 /> : <div />
  let header1
  if (formContext.formData != undefined) {
    header1 =
      formContext.formData.quickReports.reviewApplication === 'Y'
        ? 'Number of Renewals'
        : 'Number of Applications'
  }

  if (showResultsTable && !errorDisplay && !errorFrom && !errorTo) {
    return (
      <div>
        <div>
          <ErrorModal
            errorMessage={errorMessage}
            onDeleteClick={onDeleteClick}
          />
          <div className="col-12">
            <h2>Quick Report Results</h2>
          </div>
          <div className="col-12">
            <table className="table table-striped table-bordered table-condensed table-hover">
              <thead>
                <tr>
                  <th>
                    <span>{header1}</span>
                  </th>
                  <th>
                    <span>Statistic Level</span>
                  </th>
                  <th>
                    <span>Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultChunks[currentPage - 1] ? (
                  resultChunks[currentPage - 1].map((result, i) => {
                    return (
                      <tr key={i}>
                        <td>{searchResults[i].numberOfApplications}</td>
                        <td>{searchResults[i].geographicalValue}</td>
                        <td>{searchResults[i].applicationStatus}</td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex-box">
            <div className="col-lg-6 col-12 px-0 py-0 mtb-20">
              <button
                type="button"
                className="btn btn-default float-left reset-margin userListButton1"
                onClick={showBackResultsTable}
              >
                Previous
              </button>
            </div>
            <div className="col-lg-6 col-12 px-0 py-0">
              <Pagination
                prev={'‹'}
                next={'›'}
                first={'«'}
                last={'»'}
                ellipsis={false}
                boundaryLinks={true}
                items={searchResults.length}
                maxButtons={1}
                activePage={currentPage}
                onSelect={pageChangeDetected}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (form) {
    return (
      <div>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <div>
          {header}
          {(errorDisplay || errorTo || errorFrom) && (
            <div className="panel panel-danger errors">
              <div className="panel-heading">
                <h3 className="panel-title">Errors</h3>
              </div>
            </div>
          )}
          {errorDisplay && (
            <div className="panel panel-danger errors">
              <ul className="list-group">
                <div>
                  <li className="list-group-item text-danger">
                    To Date cannot be prior to the From Date.
                  </li>
                </div>
              </ul>
            </div>
          )}
          {errorTo && (
            <div className="panel panel-danger errors">
              <ul className="list-group">
                <div>
                  <li className="list-group-item text-danger">
                    To Date should be within last 36 months from today's date.
                  </li>
                </div>
              </ul>
            </div>
          )}
          {errorFrom && (
            <div className="panel panel-danger errors">
              <ul className="list-group">
                <div>
                  <li className="list-group-item text-danger">
                    From Date should be within last 36 months from today's date.
                  </li>
                </div>
              </ul>
            </div>
          )}
          <Form
            schema={form.app.forms[0].schema}
            uiSchema={form.app.forms[0].uiSchema}
            formData={formContext.formData}
            onChange={onFormDataChange}
            formContext={formContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          />
          <div className="flex-box">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <button
                type="button"
                id="clear_quick_report"
                className="btn btn-default float-right reset-margin userListButton1"
                onClick={reset}
              >
                Clear
              </button>
              <button
                type="submit"
                id="search_quick_report"
                disabled={disableButtons}
                className="btn btn-default float-right userListButton2"
                onClick={search}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
      <ScreenPreLoader minHeight={minHeight} />
    </div>
  )
}
