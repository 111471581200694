import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form' // json schema form library
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import NavTabs from '@optum-wvie/dynamic-ui-framework/src/components/natives/NavTabs'
import * as _ from 'lodash' // extra functionality
import {
  Modal,
  Popover,
  Overlay,
  Tooltip,
  OverlayTrigger,
  Button
} from 'react-bootstrap'
import MessageModal from '@optum-wvie/dynamic-ui-framework/src/components/natives/MessageModal'
import ConfirmComplete from './ConfirmComplete'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { I18n } from 'react-redux-i18n'

declare const process
const baseUrl = process.env.PUBLIC_URL

export default function AppIntakePEPresentation(props) {
  const {
    applicationData,
    step,
    tabs,
    forms,
    formContext,
    application,
    liveValidate,
    showNext,
    callingApi,
    validator,
    onPrevious,
    changeView,
    onSubmit,
    onFormDataChange,
    onValidate,
    errorListTemplate,
    onError,
    transformErrors,
    showExit,
    showExitModal,
    confirmExit,
    cancelExit,
    formComponents,
    isDisable,
    isComplete,
    applId,
    uuid,
    userRoleId,
    redirectUrl,
    saveExitFromHeader,
    serviceErrors,
    onDeleteClick,
    titleMessage,
    btnText,
    roleId,
    accessToken,
    showErrorModal,
    hideErrorModal,
    errorMessage,
    history,
    logoutUser,
    modalCloseCallback
  } = props
  const userId = props.auth.userAccount.userId || ''
  const orgId = props.userAccess.selectedOrg.orgId

  let applicationTitle
  let previousButton: any
  let submitButton
  let saveExitButton
  let confirmationModal
  let confirmCompleteModal

  let helpPopover = (
    <Popover id="helpPopover">
      <a href="/help" target="_blank">
        {I18n.t('FAQ.helpCenter')}
      </a>
    </Popover>
  )

  let helpLink = (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={helpPopover}
    >
      <button className="print btn btn-tiny btn-white">
        <span className="fa fa-life-ring btn-icon-sm" />
        {I18n.t('FAQ.help')}
      </button>
    </OverlayTrigger>
  )

  if (!_.isEmpty(application) && !_.isEmpty(applicationData)) {
    // since application isn't empty the component is going to be rendered thus 'loaded'
    // load application title
    applicationTitle = application['properties']['title']

    // submit button change on last page to Submit instead of Next
    if (step === forms.length) {
      if (!isDisable) {
        submitButton = (
          <button
            id="AppIntake_submitBtn"
            type="submit"
            className="btn btn-default float-right sp-md"
            disabled={callingApi}
          >
            {I18n.t('General.completeButtonLabel')}
          </button>
        )
      }
    }
    // Need to make this only display if it's at the end of the carousel
    else if (showNext || step !== 0) {
      submitButton = (
        <button
          id="AppIntake_nextBtn"
          type="submit"
          className="btn btn-default float-right sp-md"
          disabled={callingApi}
        >
          {I18n.t('General.nextButton')}
        </button>
      )
    } else {
      submitButton = null
      saveExitButton = null
    }

    if (step !== 1) {
      previousButton = (
        <button
          type="button"
          id="AppIntake_prevBtn"
          className="btn btn-default float-left sp-md"
          onClick={onPrevious}
          disabled={callingApi}
        >
          {I18n.t('General.previousButtonLabel')}
        </button>
      )
    } else {
      previousButton = null
    }

    confirmCompleteModal = isComplete ? (
      <ConfirmComplete
        getApplID={applId}
        getUUID={uuid}
        getUserRoleId={userRoleId}
        getuserId={userId}
        getorgId={orgId}
        getRedirectUrl={redirectUrl}
        getRoleId={roleId}
        getaccessToken={accessToken}
        history={history}
        logoutUser={logoutUser}
        modalCloseCallback={modalCloseCallback}
      />
    ) : null
    return (
      <div>
        <div className="container-fluid">
          <ErrorModal
            errorMessage={props.serviceErrors}
            onDeleteClick={props.onDeleteClick}
          />
          <div className="row" style={{ display: 'block' }}>
            <div className="application-title">
              <h1>
                <span className="fa fa-shopping-cart icon-left title-icon" />
                {applicationTitle}
              </h1>
            </div>
            <div className="below-header-bar">
              <div className="content">
                <button
                  className="help btn btn-tiny btn-white"
                  onClick={showExit}
                >
                  <span className="fa fa-times btn-icon-sm" />
                  {I18n.t('General.exitButtonLabel')}
                </button>
                <button
                  className="save btn btn-tiny btn-white"
                  onClick={saveExitFromHeader}
                >
                  <span className="fa fa-floppy-o btn-icon-sm" />
                  {I18n.t('General.saveButtonLabel')}
                </button>
                {helpLink}
                <button
                  className="printStyle print btn btn-tiny btn-white printpage"
                  onClick={function() {
                    window.print()
                  }}
                >
                  <span className="fa fa-print btn-icon-sm" />
                  {I18n.t('General.printButtonLabel')}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="tabs-section col-md-4 col-lg-3 col-xl-2 px-0 pe-tabs-focus">
              <NavTabs
                activeKey={step - 1}
                onNavClick={changeView}
                tabs={tabs}
                disabled={callingApi}
              />
            </div>

            <div className="form-section col-md-8 col-lg-9 col-xl-10 dynamic-form px-0">
              {
                <Form
                  schema={forms[step - 1].schema}
                  uiSchema={forms[step - 1].uiSchema}
                  onSubmit={onSubmit}
                  formData={applicationData}
                  onChange={onFormDataChange}
                  formContext={formContext}
                  fields={formComponents.fields}
                  widgets={formComponents.widgets}
                  ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                  FieldTemplate={formComponents.FieldTemplate}
                  validate={onValidate}
                  liveValidate={liveValidate}
                  showErrorList={true}
                  ErrorList={errorListTemplate}
                  onError={onError}
                  transformErrors={transformErrors}
                  jsonValidator={validator}
                >
                  <div className="col-12">
                    {previousButton}
                    {saveExitButton}
                    {submitButton}
                  </div>
                </Form>
              }
              <ScreenPreLoader loaded={!callingApi} minHeight="500px" />
            </div>
          </div>
        </div>
        {confirmationModal}
        {confirmCompleteModal}
        <MessageModal
          className="modal-content lg-width-to-md"
          show={showExitModal}
          confirm={confirmExit}
          cancel={cancelExit}
          closeBtnText="Cancel"
          title={
            <span>
              <h4 className="popup-title"> {I18n.t('General.saveChanges')}</h4>
            </span>
          }
          body={
            <div className="row">
              <div className="col-lg-12">
                <h6 className="headertxt">{I18n.t('General.wantToExit')}</h6>
              </div>
            </div>
          }
          bsSize={'lg'}
        />
        {
          <Modal show={showErrorModal} onHide={hideErrorModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="popup-title">{titleMessage}</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <h6 className="headertxt">{errorMessage}</h6>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={hideErrorModal}>
                {btnText}
              </button>
            </Modal.Footer>
          </Modal>
        }
      </div>
    )
  }

  return (
    <div>
      <ScreenPreLoader loaded={false} />
    </div>
  )
}
