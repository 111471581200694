import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'

function PaymentsSent() {
  return (
    <>
      <div className="card" style={{ width: '18rem' }}>
        <div className="card-body">
          <h5 className="card-title">Total Amount</h5>
          <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
        </div>
      </div>
    </>
  )
}

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  dataTable: Fields.DataTableField,
  tableRow: Fields.TableRowField,
  PaymentsSent
}

export const widgets = {
  span: Widgets.SpanWidget,
  iconWidget: Widgets.ConditionalIconWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
