import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Select from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/widgets/SelectWidget'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config'
import '!style-loader!css-loader!../src/customStyles.css'

const CountiesBaseUrl = config['gatewayWvUrl'] + config['countiesStateUrl']

function PlainTextWidget(props) {
  return (
    <div className="cpsTitle">
      <span>&nbsp;{props.value}</span>
    </div>
  )
}

export function CpsCountyWidget(props) {
  return <CpsCountyComponent {...props} />
}

class CpsCountyComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      selectedState: undefined,
      counties: []
    }
  }

  componentWillMount() {
    this.setCpsCounties()
  }

  setCpsCounties() {
    this.setState({
      selectedState: this.props.options && this.props.options.state,
      counties: []
    })
    const endPoint = CountiesBaseUrl.replace(
      '{state}',
      this.props.options.state
    )
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.uuid,
        roleId: this.props.roleId,
        orgId: this.props.orgId
      }
    }
    helper.fetchJson(endPoint, request).then(json => {
      let sortedArray = json.counties && json.counties.sort()
      this.setState({
        counties: sortedArray ? sortedArray : []
      })
    })
  }

  render() {
    let countyOptions = []
    let newProps = this.props
    if (this.state.counties.length > 0) {
      countyOptions = this.state.counties.map(county => {
        return {
          label: county,
          value: county
        }
      })
      newProps = {
        ...this.props,
        options: {
          ...this.props.options,
          enumOptions: countyOptions
        }
      }
    } else {
      newProps = {
        ...this.props,
        options: {
          ...this.props.options,
          enumOptions: []
        }
      }
    }
    return (
      <div className={'select-wrapper'}>
        <Select {...newProps} />
      </div>
    )
  }
}

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  grid: Fields.GridField,
  accordion: Fields.AccordionField,
  dataTable: Fields.DataTableField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  span: Widgets.SpanWidget,
  routeLink: Widgets.LinkWidget,
  spanCPS: Widgets.SpanWidget,
  iconWidget: Widgets.ConditionalIconWidget,
  header: Widgets.HeaderWidget,
  info: Widgets.InfoBoxWidget,
  plain: PlainTextWidget,
  county: CpsCountyWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate
export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
