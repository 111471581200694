import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import { Link } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

declare const process
const baseUrl = process.env.PUBLIC_URL
const Fragment = (React as any).Fragment

function ReportChangeWidget(props) {
  const { formContext } = props
  const { eligiblePendingRenewals, setRedirect } = formContext
  if (eligiblePendingRenewals && Array.isArray(eligiblePendingRenewals)) {
    if (eligiblePendingRenewals.indexOf(props.value) > -1) {
      return (
        <Fragment>
          <p>{I18n.t('MyLifeEvents.programDueForRenewal')}</p>
          <p />
          <Link
            to={baseUrl + '/renewal?caseId=' + props.value}
            className="btn btn-primary"
          >
            {I18n.t('MyLifeEvents.startRenewal')}
          </Link>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Link
            to={baseUrl + '/coc?caseId=' + props.value}
            className="btn btn-primary"
          >
            {I18n.t('MyLifeEvents.reportAChange')}
          </Link>
          <p />
          <p>{I18n.t('MyLifeEvents.reportAChangeActiveCaseDisclaimer')}</p>
        </Fragment>
      )
    }
  } else {
    //Don't display anything until eligiblePendingRenewals is loaded, which will be non-nil.
    return null
  }
}

function HasBenefitCheckmarkWidget(props) {
  if (props.value === true) {
    return (
      <small>
        <span
          className="badge-sm bg-primary-alt"
          style={{ borderRadius: '50%' }}
        >
          <i className="glyphicon myglyphicon-ok" />
        </span>
      </small>
    )
  } else {
    return null
  }
}

function FootNoteWidget(props) {
  return (
    <p>{I18n.t('MyLifeEvents.reportAChangeFederalMarketPlaceDisclaimer')}</p>
  )
}

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  radio: Widgets.CustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  span: Widgets.SpanWidget,
  linkButton: Widgets.LinkButtonWidget,
  reportChange: ReportChangeWidget,
  hasBenefitCheckmark: HasBenefitCheckmarkWidget,
  footNote: FootNoteWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
