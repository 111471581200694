import * as React from 'react'
import validateFormData from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/validate'
import * as validator from '../src/validation'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import * as JSONSchema from './benHScreen.json'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import customWidgets from '../src/components/widgets/index'
import * as _ from 'lodash'

interface BenHContentProps {
  application: {}
  form: boolean
  formData: {}
  tabs: any
  forms: any
  formContext: any
  liveValidate: boolean
  activeTab: number
  temp1: any
  programFormContext: any
  tabEnabled: boolean
  mainTab: number
  errors
  errorMessage: Array<any>
  onDeleteClick: () => void
  logoutUser
}

const _BTT = 'BTT'
const _BCC = 'BCC'
const _LONGTERMCARE = 'LongTermCareICFIID'
const _CDCSP = 'LongTermCareCDCSP'
const _FPP = 'FPP'
const _RFTS = 'RFTS'

const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  tabs: Fields.TabsField,
  table: Fields.DataTableField
}

const widgets = {
  radio: Widgets.CustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  checkboxes: customWidgets.CustomCheckboxesWidgetWithHelp,
  'alt-date': Widgets.DatePickerWidget,
  review: Widgets.ReviewWidget,
  dollar: Widgets.DollarWidget,
  validatedText: Widgets.ValidatedTextWidget,
  checkboxesWithoutHelp: Widgets.CustomCheckboxesWidget,
  header: Widgets.HeaderWidget
}

export default class BenHScreenContent extends React.Component<any, any> {
  private readonly divContent: any
  private hasError = false
  fC1: {}
  stateFormContext: any
  constructor(props: BenHContentProps) {
    super(props)
    this.divContent = (React as any).createRef()
    this.state = {
      application: {},
      form: false, // dictates if form is loaded
      formData: {},
      tabs: null,
      forms: null,
      formContext: null,
      activeTab: 0, // open first tab by default
      tabEnabled: this.props.tabEnabled,
      temp1: this.props.temp1,
      dispErrors: [],
      programFormContext: {},
      liveValidate: false,
      showTabs: []
    }
  }

  UNSAFE_componentWillMount() {
    this._loadApp() // UNCOMMENT FOR INITAL APPJSON LOAD
    document.title = 'Referral Screening'
  }

  _changeTab = (val: number) => {
    //Set the clicked tab to editing status.
    this.props._updateActiveTab(val)
    this.setState({ activeTab: val })
    this.divContent.current.focus()
  }

  _loadApp = () => {
    this._loadAll(JSONSchema)
  }

  _loadAll = (json: object) => {
    let tabs = [] // navigation tabs
    let forms, application, formData, formContext
    application = json['app']
    forms = application['forms']

    for (let form of forms) {
      if (typeof form['uiSchema']['ui:options'] !== 'undefined') {
        tabs.push({
          tabOptions: form['uiSchema']['ui:options']
        })
      }
    }
    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      forms: forms,
      reviewForms: forms,
      refs: application['metaData']['refs']
    }

    //New application, initialize to empty object
    formData = helper.createObjectFromMasterSchema(null, formContext)
    //Augment the context with formData
    if (this.props.location.state) {
      const client = this.props.location.state.addEligibleAppData.Application
        .clients[0]
      formData.demographicInformation.clientFstNm = client.clientFstNm
      formData.demographicInformation.clientLstNm = client.clientLstNm
      formData.demographicInformation.gender = client.genderCode.value
      formData.demographicInformation.citizenshipStatus =
        client.citizenshipStatus.value
      formData.demographicInformation.age = `${client.clientAge}`
      formData.demographicInformation.dateOfBirth = client.clientBthDt
    }
    formData['programList'] = [
      'LongTermCareICFIID',
      'CHSP',
      'LongTermCareCDCSP',
      'FPP',
      'ADW',
      'RFTS',
      'UNGIDD',
      'BCC',
      'IDDWSG',
      'CSHN',
      'FSP',
      'PersonalCare',
      'BTT'
    ]
    formContext = { ...formContext, formData }

    this.setState({
      application,
      forms,
      tabs,
      formData,
      formContext,
      temp1: formData['programList']
    })

    this.props._formContextUpdated(formContext)
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({ tabEnabled: false, temp1: formData['programList'] })
    this.setState({ formData }, () => this._refreshFormContext())
  }

  arrayHasValue(inputArr, value) {
    if (inputArr === undefined) return false
    for (var i = 0; i < inputArr.length; i++) {
      if (inputArr[i] === value) {
        return true
      }
    }
    return false
  }

  _onSubmit = ({ formData }) => {
    // This executes after validation has passed.
    formData = { ...this.state.formData, ...formData }
    this.setState({ formData })
    this.props._fetchEligibleBenefits(formData)
  }

  _focusToTop() {
    this.divContent.current.focus()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  lieapCheck(inputArr) {
    if (inputArr === undefined) return false
    if (inputArr.length === 1 && inputArr[0] === 'LIEAP') {
      return false
    } else {
      return true
    }
  }

  validateTest() {
    if (this.state.dispErrors.length > 0) {
      return (
        <div className="panel panel-danger errors">
          <div className="panel-heading">
            <h3 className="panel-title">Errors</h3>
          </div>
          <ul className="list-group">
            {this.state.dispErrors.map((error, i) => {
              return (
                <li key={i} className="list-group-item text-danger">
                  {error}
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
    return null
  }

  validate(formData, schema) {
    const { validate, transformErrors, jsonValidator } = this.props
    return validateFormData(
      formData,
      schema,
      validate,
      transformErrors,
      jsonValidator,
      null
    )
  }

  _isEqualArrayElelments(chkArr, inputArr) {
    var flag = false
    chkArr.sort()
    inputArr.sort()
    if (chkArr.length === inputArr.length) {
      for (var i = 0; i < chkArr.length; i++) {
        if (chkArr[i] !== inputArr[i]) {
          return false
        } else {
          flag = true
        }
      }
    }
    return flag
  }

  _onNext = () => {
    const showTabs = this.findNumberOfTabs(this.state.tabs)
    this._changeTab(showTabs[this.props.activeTab + 1].tabOptions.tabIndex)
    this._toTop()
    this.divContent.current.focus()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  _onPrevious = () => {
    this._switchOnLiveValidate()
    const showTabs = this.findNumberOfTabs(this.state.tabs)
    const activeTab =
      this.state.activeTab > showTabs.length - 1 ? 0 : this.state.activeTab - 1
    this._changeTab(showTabs[activeTab].tabOptions.tabIndex)
    // return to top of form
    this._toTop()
    this.divContent.current.focus()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  _toTop = () => {
    window.location.href = '#app'
  }

  _removeStringInAArray = (array, search_term) => {
    for (var i = array.length - 1; i >= 0; i--) {
      if (array[i] === search_term) {
        array.splice(i, 1)
        break
      }
    }
    return array
  }

  _refreshFormContext = () => {
    this.setState(
      {
        formContext: {
          ...this.state.formContext,
          formData: helper.deepClone(this.state.formData),
          reviewFormData: helper.deepClone(this.state.formData)
        }
      },
      () => {
        this.props._formContextUpdated(this.state.formContext)
        let errorKeys = this.state.errorKeys
        let dispErrors = this.state.dispErrors
        if (errorKeys) {
          if (errorKeys.indexOf('programList') >= 0) {
            if (
              this.state.formContext.formData['programList'] !== undefined ||
              this.state.formContext.formData['programList'].length > 0
            ) {
              errorKeys = this._removeStringInAArray(errorKeys, 'programList')
              dispErrors = this._removeStringInAArray(
                dispErrors,
                'Please choose a value for "Please select the programs for which you would like to screen:"'
              )
            }
          }
        }
        this.setState({ dispErrors: dispErrors, errorKeys: errorKeys })
      }
    )
  }

  _disableNext = (): boolean => {
    const se = this.state.formData
    let hasProgram = false
    if (_.isNil(se.programList)) {
      hasProgram = true
    }

    return hasProgram
  }

  _errorListTemplate = (props: any) => {
    const { errors } = props
    return (
      <div
        className="panel panel-danger errors"
        id="benefitfinder-errors"
        tabIndex={-1}
      >
        <div className="panel-heading">
          <h2 className="panel-title">Errors</h2>
        </div>
        <ul className="list-group">
          {errors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this._onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  _onErrorClick = error => {
    const { forms } = this.state
    let errorId = error.id
    for (let tab = 0; tab < forms.length; tab++) {
      if (forms[tab].schema.properties.hasOwnProperty(errorId.toString())) {
        if (error.step !== forms[tab]) {
          this.props.formContext.goToTab(tab)
        }
      }
    }

    try {
      setTimeout(function() {
        window.requestAnimationFrame(function() {
          const element = document.getElementById(`root_${error.id}`)
          let offset = helper.getOffsetTop(
            document.getElementById(`root_${error.id}`)
          )
          element.focus()
          window.scroll(0, offset - 100)
        })
      }, 0)
    } catch (e) {
      console.error('Benefit Finder viewId setTimeout failed with ex', e)
    }
  }

  _transformErrors = (errors: object) => {
    return errors
  }

  _switchOnLiveValidate = () => {
    if (!this.state.liveValidate) {
      this.setState({ liveValidate: true })
    }
  }

  _switchOffLiveValidate = () => {
    if (this.state.liveValidate) {
      this.setState({ liveValidate: false })
    }
  }

  _checkForErrors(errors) {
    if (this.state.activeTab === 0) {
      if (errors.programList.__errors.length === 0) {
        return true
      }
    }
  }
  _onValidate(formData: object, errors: object) {
    if (this.state.liveValidate) {
      validator.validateReferralScreen(
        formData,
        errors,
        this.state.activeTab,
        this.state.forms
      )
    }
    let foundErrors = helper.findErrors(errors)
    if (foundErrors && foundErrors.length > 0) {
      this.hasError = true
    } else {
      this.hasError = false
    }
    return errors
  }

  _setLiveValidate() {
    this._switchOnLiveValidate()
  }

  _goToNextTab() {
    this._switchOffLiveValidate()
    const showTabs = this.findNumberOfTabs(this.state.tabs)
    if (
      showTabs.length - 1 === this.state.activeTab ||
      this.state.activeTab > showTabs.length - 1
    ) {
      this._onSubmit(this.state.formData)
    } else {
      const activeTab = this.state.activeTab + 1
      this.setState({ activeTab: activeTab })
      this._onNext()
    }
  }

  _onError = (errors: object) => {
    this._toTop()
    const errorContent = document.getElementById('benefitfinder-errors')
    if (errorContent) {
      window.scroll(0, errorContent.offsetTop)
      errorContent.focus()
    }
    this.setState({
      reRender: true
    })
  }

  isEqualArrayElelments(chkArr, inputArr) {
    var count = 0
    if (chkArr.length > 0) {
      for (var i = 0; i < inputArr.length; i++) {
        for (var j = 0; j < chkArr.length; j++) {
          if (inputArr[i] === chkArr[j]) {
            return true
          }
        }
      }
    }
    return false
  }

  findNumberOfTabs = tabs => {
    let showTabs = [tabs[0]]
    if (
      this.state.formContext.formData.programList &&
      this.state.formContext.formData.programList.length > 0
    ) {
      let incomeEnabledFlag = this.isEqualArrayElelments(
        this.state.formContext.formData.programList,
        [_LONGTERMCARE, _FPP, _CDCSP]
      )
      let familyPlanAndBirthToThreeFlag = this.isEqualArrayElelments(
        this.state.formContext.formData.programList,
        [_FPP, _BTT, _BCC]
      )
      const rightFromTheStartMale =
        this.isEqualArrayElelments(
          this.state.formContext.formData.programList,
          [_RFTS]
        ) &&
        this.state.formContext.formData.demographicInformation.gender == 'Male'

      let diff = _.differenceWith(
        this.state.formContext.formData.programList,
        [_FPP, _BTT, _BCC],
        _.isEqual
      )
      if (incomeEnabledFlag) {
        //Income & Expense Questions
        showTabs.push(tabs[1])
      }
      if (
        !familyPlanAndBirthToThreeFlag ||
        (familyPlanAndBirthToThreeFlag && diff.length > 0)
      ) {
        showTabs.push(tabs[2])
      }
      let diff1 = _.differenceWith(
        this.state.formContext.formData.programList,
        [_FPP, _BTT, _BCC, _RFTS],
        _.isEqual
      )
      if (rightFromTheStartMale && diff1.length == 0) {
        showTabs.splice(-1, 1)
      }
    }
    return showTabs
  }

  render() {
    const { tabs, formContext, liveValidate, formData } = this.state

    const activeTab = this.props.activeTab
    const tabEnabled = this.state.tabEnabled
    const forms = formContext.forms || []
    const temp1 = this.state.temp1
    let submitButton, prevButton
    const dispErrors = this.state.dispErrors
    let validatorBenefitFinder
    if (forms && activeTab) {
      validatorBenefitFinder = new CustomValidator(
        forms[activeTab].schema,
        forms[activeTab].uiSchema,
        formContext,
        'en'
      )
    }
    const bnfMargin = { margin: '22px' }
    const showTabs = this.findNumberOfTabs(this.state.tabs)
    const numberOfTabs = showTabs.length

    if (activeTab > 0) {
      prevButton = (
        <button
          type="button"
          style={bnfMargin}
          className={`btn btn-default float-left ${
            this.hasError ? 'btn-danger' : 'btn-default'
          }`}
          onClick={this._onPrevious}
        >
          <i className="fa fa-chevron-left arrow-left" />
          Previous
        </button>
      )
    }
    if (activeTab < numberOfTabs - 1) {
      submitButton = this.hasError ? (
        <button
          type="submit"
          className={`btn btn-default float-right benefits-error-margin-top next btn-danger`}
          aria-label="There are errors on this page"
          onClick={this._setLiveValidate.bind(this)}
        >
          Errors
          <i className="fa fa-chevron-right arrow-right" />
        </button>
      ) : (
        <button
          type="submit"
          style={bnfMargin}
          className="btn btn-default float-right next"
          onClick={this._setLiveValidate.bind(this)}
        >
          Next
          <i className="fa fa-chevron-right arrow-right" />
        </button>
      )
    } else {
      submitButton = this.hasError ? (
        <button
          type="submit"
          id="submit"
          style={bnfMargin}
          className={`btn btn-default float-right benefits-error-margin-top next btn-danger`}
          aria-label="There are errors on this page"
          onClick={this._setLiveValidate.bind(this)}
        >
          Submit
        </button>
      ) : (
        <button
          type="submit"
          id="submit"
          style={bnfMargin}
          className="btn btn-default float-right"
          onClick={this._setLiveValidate.bind(this)}
        >
          Submit
        </button>
      )
    }
    return (
      <div
        className="container-fluid benefits-wrapper"
        style={{ backgroundColor: '#1274b2' }}
      >
        <a href="#side-nav" className="skip-nav">
          Skip to side navigation
        </a>
        <ErrorModal
          errorMessage={this.props.errorMessage}
          onDeleteClick={this.props.onDeleteClick}
        />
        <a href="#benefits" className="skip-nav">
          Skip to benefits information
        </a>
        <div className="row">
          <div id="side-nav" className="col-lg-2 side-pane">
            <div className="row">
              <VerticalTabs
                tabs={tabs}
                hasError={this.hasError}
                _changeTab={this._changeTab}
                activeTab={activeTab}
                mainTab={this.props.mainTab}
                displayNone={tabEnabled}
                temp1={temp1}
                formData={formData}
              />
            </div>
          </div>
          <div
            id="benefit-content"
            ref={this.divContent}
            className="col-12 col-md-9 col-lg-9 col-xl-10 main-pane accessibility-focus"
          >
            {forms.length > 0 && (
              <Form
                schema={forms[activeTab]['schema']}
                onSubmit={this._goToNextTab.bind(this)}
                uiSchema={forms[activeTab]['uiSchema']}
                formData={this.state.formData}
                onChange={this._onFormDataChange}
                formContext={formContext}
                fields={fields}
                widgets={widgets}
                ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
                FieldTemplate={Templates.CustomFieldTemplate}
                validate={this._onValidate.bind(this)}
                liveValidate={liveValidate}
                ErrorList={this._errorListTemplate}
                transformErrors={this._transformErrors}
                jsonValidator={validatorBenefitFinder}
                onError={this._onError}
              >
                {prevButton}
                {submitButton}
              </Form>
            )}
          </div>
        </div>
      </div>
    )
  }
}

interface VerticalTabsProps {
  _changeTab: any
  activeTab: number
  tabs: any
  temp1: any
  mainTab: number
  hasError: boolean
  displayNone: any
  formData: any
}

class VerticalTabs extends React.Component<VerticalTabsProps, {}> {
  constructor(props: any) {
    super(props)
  }

  _changeTab = (val: number, tabName: String): void => {
    const val1 = _.findIndex(this.props.tabs, function(obj) {
      return obj.tabOptions.tabName == tabName
    })
    this.props._changeTab(val1)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  isEqualArrayElelments(chkArr, inputArr) {
    if (chkArr.length > 0) {
      for (var i = 0; i < inputArr.length; i++) {
        for (var j = 0; j < chkArr.length; j++) {
          if (inputArr[i] === chkArr[j]) {
            return true
          }
        }
      }
    }
    return false
  }

  render() {
    const tabs = this.props.tabs
    const activeTab = this.props.activeTab
    const hasError = this.props.hasError
    let tabsHTML = [] // tab info into html tagged tabs
    let li_classes = [] // li tag classes
    li_classes = _.fill(Array(tabs.length), '')
    //Not showing final form as that will be displayed in ViewResults component.
    let showTabs = [tabs[0]]
    const style = { width: '100%' }

    if (this.props.temp1.length > 0) {
      let incomeEnabledFlag = this.isEqualArrayElelments(this.props.temp1, [
        _LONGTERMCARE,
        _FPP,
        _CDCSP
      ])
      let familyPlanAndBirthToThreeFlag = this.isEqualArrayElelments(
        this.props.temp1,
        [_FPP, _BTT, _BCC]
      )

      const rightFromTheStartMale =
        this.isEqualArrayElelments(this.props.temp1, [_RFTS]) &&
        this.props.formData.demographicInformation.gender == 'Male'

      let diff = _.differenceWith(
        this.props.temp1,
        [_FPP, _BTT, _BCC],
        _.isEqual
      )
      if (incomeEnabledFlag) {
        //Income & Expense Questions
        showTabs.push(tabs[1])
      }
      if (
        !familyPlanAndBirthToThreeFlag ||
        (familyPlanAndBirthToThreeFlag && diff.length > 0)
      ) {
        showTabs.push(tabs[2])
      }
      let diff1 = _.differenceWith(
        this.props.temp1,
        [_FPP, _BTT, _BCC, _RFTS],
        _.isEqual
      )
      if (rightFromTheStartMale && diff1.length == 0) {
        showTabs.splice(-1, 1)
      }
    }

    const activeFlag =
      this.props.activeTab == 0
        ? this.props.activeTab
        : this.props.activeTab > showTabs.length - 1
        ? showTabs.length - 1
        : this.props.activeTab

    li_classes[activeFlag] = li_classes[activeTab] + ' ' + 'active'
    for (let i = 0; i < showTabs.length; i++) {
      let flag =
        showTabs[activeFlag].tabOptions.tabName ==
        showTabs[i].tabOptions.tabName
          ? true
          : false
      tabsHTML.push(
        <li key={i} className={li_classes[i]} style={style}>
          <button
            className="btnBF btn-link-bf btn btn-link"
            onClick={() => this._changeTab(i, showTabs[i].tabOptions.tabName)}
            aria-current={flag}
          >
            <i
              className={
                showTabs[i].tabOptions.iconClassName + ' icon large-bf'
              }
              aria-hidden="true"
            />
            <span className="vcenter col-lg-10 spaceText">
              {showTabs[i].tabOptions.tabName}
            </span>
          </button>
        </li>
      )
      if (i === this.props.activeTab && this.props.mainTab === 0) {
        document.title =
          (hasError ? 'Error' + ' - ' : '') +
          showTabs[i].tabOptions.tabName +
          ' - Referral Screening'
      }
    }

    return (
      <ul style={style} className="nav nav-pills nav-stacked tab-active">
        {tabsHTML}
      </ul>
    )
  }
}
