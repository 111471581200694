import * as React from 'react'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as serviceHelper from '../src/components/utils'
import * as _ from 'lodash'
import { config } from '../../../config'
import * as actions from '../../../actions'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
import * as validator from '../src/validation'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'

const formsEndpoint = config['formsBaseUrl'] + config['forms_organization']
const roleEndPoint = config['getAllRoles']
const updateAccessEndPoint = config['gatewayWvUrl'] + config['updateAccess']
const endPoint = config['gatewayWvUrl'] + config['getUserResults']
const retrieveOrgWithUserCountEndPoint =
  config['gatewayWvUrl'] + config['retrieveOrgWithUserCount']
const baseUrl = process.env.PUBLIC_URL

interface OrganizationContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
    organizationName: string
  }
  orgId: any
  orgName: any
  organizationName: any
  userAccess: {
    selectedOrg: {
      roleName: any
      orgId: any
      orgName: any
    }
    selectedUserRole: {
      userRoleId: any
      uuid: any
      role: {
        roleId: any
        roleName: any
      }
    }
  }
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  orgnizationListData: any
  userFormData: any
  showModal: boolean
  ieOrgId: any
  logoutUser
  history: {
    push: (url: string) => void
  }
  locale: string
}

interface OrganizationContainerState {
  formComponents: any
  form: Array<{
    schema: Object
    uiSchema: Object
  }>
  formContext: any
  formData: any
  organizationName: any
  orgData: any
  application: any
  orgId: number
  openDays: any
  selRole: any
  orgName: any
  displayNameOrg: any
  callingApi: any
  enableUpdate: boolean
  currentOrgData: any
  currentOpenDays: any
  saveRequest: boolean
  initialLoad: boolean
  selectedorgDetails: {}
  userCount: boolean
  workerAdminOrgList: any
  workerAdminOrgListCreated: any
  orgnizationListData: any
  userDetailViewId: any
  getAllRoles: Array<any>
  userFormData: any
  showModal: boolean
  showModalMessage: string
  showModalError: boolean
  isUserUpdating: boolean
  newPresumptiveUser: {
    firstName: string
    lastName: string
    email: string
    trainingDate: any
    agreementDate: any
    role: string
    org: string
    selectRole?: any
  }
  userResults: any
  retrieveOrgId: any
  newOrgBtnSel: boolean
  technicalDescription: string
  errorModal: boolean
  errorDisplay: boolean
  status: string
  liveValidate: boolean
}

class OrganizationContainer extends React.Component<
  OrganizationContainerProps,
  OrganizationContainerState
> {
  private hasError = false
  constructor(props: OrganizationContainerProps) {
    super(props)
    this.state = {
      application: {},
      form: null,
      formContext: {
        formData: {},
        enableUpdate: false,
        roleName: props.userAccess.selectedUserRole.role.roleName,
        organizationLink: this._organizationLink
      },
      organizationName: props.organizationName,
      formComponents: null,
      formData: null,
      orgData: {},
      selRole: props.userAccess,
      orgId: props.orgId,
      openDays: {
        Monday: {
          open: '',
          close: '',
          allDay: ''
        },
        Tuesday: {
          open: '',
          close: '',
          allDay: ''
        },
        Wednesday: {
          open: '',
          close: '',
          allDay: ''
        },
        Thursday: {
          open: '',
          close: '',
          allDay: ''
        },
        Friday: {
          open: '',
          close: '',
          allDay: ''
        },
        Saturday: {
          open: '',
          close: '',
          allDay: ''
        },
        Sunday: {
          open: '',
          close: '',
          allDay: ''
        }
      },
      orgName: null,
      displayNameOrg: null,
      callingApi: false,
      enableUpdate: false,
      currentOrgData: null,
      currentOpenDays: null,
      saveRequest: false,
      initialLoad: false,
      selectedorgDetails: {},
      userCount: false,
      workerAdminOrgList: null,
      workerAdminOrgListCreated: null,
      orgnizationListData: null,
      userDetailViewId: null,
      getAllRoles: [],
      userFormData: null,
      showModal: false,
      showModalMessage: null,
      showModalError: null,
      isUserUpdating: false,
      newPresumptiveUser: {
        firstName: '',
        lastName: '',
        email: '',
        trainingDate: _moment(config).format('MM/DD/YYYY'),
        agreementDate: _moment(config).format('MM/DD/YYYY'),
        role: '',
        org: ''
      },
      userResults: {
        usersList: []
      },
      retrieveOrgId: null,
      newOrgBtnSel: false,
      technicalDescription: null,
      errorModal: false,
      errorDisplay: false,
      status: null,
      liveValidate: false
    }
  }

  componentDidMount() {
    document.title = 'Organization - My Account'
    this._initializeForm()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userAccess.selectedOrg &&
      prevProps.userAccess.selectedOrg.orgId !==
        this.props.userAccess.selectedOrg.orgId
    ) {
      this.organizationInformation()
    }
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      const request = {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          locale: (this.props.locale + '_US').toUpperCase()
        }
      }
      helper
        .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
        .then(form => {
          helper.cleanNullValues(form)
          this.setState(prevState => {
            return {
              form,
              initialLoad: true,
              formContext: {
                ...prevState.formContext,
                forms: [{ ...form }],
                refs: {
                  ...form['app']['metaData']['refs'],
                  '{statusDisable}': formContext => {
                    if (this.state.newOrgBtnSel) {
                      return true
                    }
                  }
                },
                organizationLink: this._organizationLink,
                userCountLink: this._userCountLink,
                organizationUserID: this._organizationUserID
              }
            }
          })
        })
        .catch(error => {
          console.error('intializeForm form error', error)
        })
    }
  }

  static getDerivedStateFromProps(
    props: OrganizationContainerProps,
    state: OrganizationContainerState
  ) {
    return {
      formContext: {
        ...state.formContext,
        formData: props.formData
      },
      selectedUserRole: {
        ...props.userAccess.selectedOrg,
        orgId:
          props.userAccess.selectedOrg && props.userAccess.selectedOrg.orgId
      }
    }
  }

  _initializeForm = () => {
    this.organizationInformation()
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(form => {
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            initialLoad: true,
            formContext: {
              ...prevState.formContext,
              forms: [{ ...form }],
              refs: {
                ...form['app']['metaData']['refs'],
                '{statusDisable}': formContext => {
                  if (this.state.newOrgBtnSel) {
                    return true
                  }
                }
              },
              organizationLink: this._organizationLink,
              userCountLink: this._userCountLink,
              organizationUserID: this._organizationUserID
            }
          }
        })
        this._workerAdminOrgList()
      })
      .catch(error => {
        console.error('intializeForm form error', error)
      })
  }

  _workerAdminOrgList = () => {
    let body = {
      organizationSearch: {
        retrieveFullList: true,
        zipCode: null,
        organizationName: null,
        city: null
      }
    }
    if (
      this.state.initialLoad &&
      this.props.userAccess.selectedUserRole.role.roleName === 'Worker Admin'
    ) {
      serviceHelper
        .fetchJson(
          retrieveOrgWithUserCountEndPoint,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              userRoleId: this.props.userAccess.selectedUserRole.userRoleId.toString(),
              roleId: this.props.userAccess.selectedUserRole.role.roleId.toString(),
              orgId: this.props.orgId.toString()
            },
            body: JSON.stringify(body)
          },
          this.props.showErrorMessage
        )
        .then(json => {
          this.setState({
            ...this.state.formContext,
            formData: json,
            orgData: json,
            workerAdminOrgList: json
          })
        })
        .catch(error => {
          if (error instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.log('_workerAdminOrgList error', error)
        })
    }
  }

  _workerAdminUserList = props => {
    serviceHelper
      .fetchJson(
        endPoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.userAccess.selectedUserRole.role.roleId,
            orgId: this.props.userAccess.selectedOrg.orgId
          },
          body: JSON.stringify({
            roleId: this.props.userAccess.selectedUserRole.userRoleId.toString(),
            orgId:
              (props.options &&
                props.options.conditionObj &&
                props.options.conditionObj.baseObject &&
                props.options.conditionObj.baseObject.organizationId &&
                props.options.conditionObj.baseObject.organizationId.toString()) ||
              ''
          })
        },
        this.props.showErrorMessage
      )
      .then(json => {
        const newUserList =
          json.usersList &&
          json.usersList.map(user => {
            return {
              ...user
            }
          })
        this.setState({
          userResults: {
            ...json,
            usersList: newUserList
          },
          ...this.state.formContext,
          formData: json,
          orgData: json
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('Organization _getUserResults failed due to ex', error)
      })
  }

  _daysTable = (type, day, e) => {
    const value = e.target.value
    this.setState(prevState => {
      return {
        ...prevState,
        openDays: {
          ...prevState.openDays,
          [day]: {
            ...prevState.openDays[day],
            [type]: value
          }
        }
      }
    })
  }

  retrieveorgById = retrieveorgById => {
    let endPoint =
      config['gatewayWvUrl'] + config['retrieveorgById'] + retrieveorgById
    serviceHelper
      .fetchJson(
        endPoint,
        {
          method: 'GET',
          headers: {
            'content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.userAccess.selectedUserRole.role.roleId.toString(),
            orgId: this.props.orgId.toString()
          }
        },
        this.props.showErrorMessage
      )
      .then(data => {
        let status
        if (data && data.agencyList && data.agencyList.length > 0) {
          if (
            this.props.userAccess.selectedUserRole.role.roleName &&
            (this.props.userAccess.selectedUserRole.role.roleName ===
              'CP Admin' ||
              (this.state.status && this.state.status === 'Active'))
          ) {
            status = 'N'
          } else {
            status = 'Y'
          }
          let languages
          const openDays = data.agencyList[0]
          if (_.get(data.agencyList[0], 'phoneNumber', '').length === 1) {
            data.agencyList[0].phoneNumber = null
          }
          const arrayLanguage = data.agencyList.map(orgData => {
            if (
              orgData &&
              orgData.language &&
              orgData.language.length > 0 &&
              !_.isNull(orgData.language[0])
            ) {
              languages = orgData.language[0].split(',')
            } else {
              languages = []
            }
            return {
              ...orgData,
              language: languages
            }
          })
          if (arrayLanguage[0]) {
            arrayLanguage[0].status = status
          }
          this.setState({
            orgData: {
              ...data,
              agencyList: arrayLanguage
            },
            openDays: {
              Monday: {
                open: openDays.monstrttm,
                close: openDays.monendtm,
                allDay: openDays.monopenflg
              },
              Tuesday: {
                open: openDays.tuestrttm,
                close: openDays.tueendtm,
                allDay: openDays.tueopenflg
              },
              Wednesday: {
                open: openDays.wedstrttm,
                close: openDays.wedendtm,
                allDay: openDays.wedopenflg
              },
              Thursday: {
                open: openDays.thustrttm,
                close: openDays.thuendtm,
                allDay: openDays.thuopenflg
              },
              Friday: {
                open: openDays.fristrttm,
                close: openDays.friendtm,
                allDay: openDays.friopenflg
              },
              Saturday: {
                open: openDays.satstrttm,
                close: openDays.satendtm,
                allDay: openDays.satopenflg
              },
              Sunday: {
                open: openDays.sunstrttm,
                close: openDays.sunendtm,
                allDay: openDays.sunopenflg
              }
            }
          })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
      })
  }

  organizationInformation = () => {
    if (
      this.props.userAccess.selectedUserRole.role.roleName !== 'Worker Admin'
    ) {
      this.retrieveorgById(this.props.userAccess.selectedOrg.orgId)
    }
  }

  _onFormDataChange = ({ formData }) => {
    if (
      this.props.userAccess.selectedUserRole.role.roleName === 'Worker Admin'
    ) {
      if (
        (this.state.orgData &&
          this.state.orgData.length > 0 &&
          this.state.orgData.agencyList &&
          this.state.orgData.agencyList[0].organizationName !== undefined) ||
        (this.state.orgData &&
          this.state.orgData.agencyList &&
          this.state.orgData.agencyList.length > 0 &&
          this.state.orgData.agencyList[0].organizationName !== undefined)
      ) {
        this.setState({
          errorDisplay: false
        })
      }
    } else {
      this.setState({
        errorDisplay: false
      })
    }
    this.setState({
      orgData: formData.Application ? formData.Application : formData,
      technicalDescription: null
    })
  }

  _checkErrorWhileSave = data => {
    if (data && data.agencyList && data.agencyList.length > 0) {
      const obj = data.agencyList[0]
      if (
        (obj.phoneNumber &&
          obj.phoneNumber.length > 0 &&
          obj.phoneNumber.length < 10) ||
        (obj.emailaddr &&
          !obj.emailaddr.match(
            '^[\\w-]+(?:\\.[\\w-]+)*@(?:[\\w-]+\\.)+[a-zA-Z]{2,7}$'
          )) ||
        (obj.zipCode && obj.zipCode.length > 0 && obj.zipCode.length < 5)
      ) {
        return true
      }
    }
    return false
  }

  _orgDataSave = () => {
    let orgDataEndpoint = config['gatewayWvUrl'] + config['createupdateorg']
    const { orgData, openDays } = this.state

    if (
      (this.props.userAccess.selectedUserRole.role.roleName ===
        'Worker Admin' ||
        this.props.userAccess.selectedUserRole.role.roleName === 'CP Admin') &&
      orgData.length === 0
    ) {
      this.setState({
        errorDisplay: true,
        technicalDescription: null
      })
    } else if (
      (this.props.userAccess.selectedUserRole.role.roleName ===
        'Worker Admin' ||
        this.props.userAccess.selectedUserRole.role.roleName === 'CP Admin') &&
      orgData &&
      orgData.agencyList &&
      orgData.agencyList.length > 0 &&
      !orgData.agencyList[0].organizationName
    ) {
      this.setState({
        errorDisplay: true,
        technicalDescription: null
      })
    } else {
      this.setState({
        errorDisplay: false,
        technicalDescription: null
      })
      let displayName = null
      let organizationName, organizationId
      if (
        this.props.userAccess.selectedUserRole.role.roleName === 'Worker Admin'
      ) {
        displayName = this.state.organizationName
        organizationName = this.state.displayNameOrg
        organizationId = this.state.retrieveOrgId || ''
      } else {
        displayName = this.state.selRole.selectedUserRole.orgList[0].orgName
        organizationName = this.props.userAccess.selectedOrg.orgName
        organizationId = this.props.userAccess.selectedOrg.orgId
      }
      if (
        (orgData && orgData.agencyList && orgData.agencyList.length > 0) ||
        this.state.displayNameOrg
      ) {
        const existingOrg =
          orgData &&
          orgData.agencyList &&
          orgData.agencyList.length > 0 &&
          orgData.agencyList[0]
        helper
          .fetchJson(orgDataEndpoint, {
            method: 'POST',
            headers: {
              'content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              roleId: this.props.userAccess.selectedUserRole.role.roleId.toString(),
              orgId: this.props.orgId.toString(),
              tenantId: config.tId
            },
            body: JSON.stringify({
              organization: {
                ...existingOrg,
                organizationId,
                monstrttm: openDays.Monday.open,
                monendtm: openDays.Monday.close,
                monopenflg: openDays.Monday.allDay,
                tuestrttm: openDays.Tuesday.open,
                tueendtm: openDays.Tuesday.close,
                tueopenflg: openDays.Tuesday.allDay,
                wedstrttm: openDays.Wednesday.open,
                wedendtm: openDays.Wednesday.close,
                wedopenflg: openDays.Wednesday.allDay,
                thustrttm: openDays.Thursday.open,
                thuendtm: openDays.Thursday.close,
                thuopenflg: openDays.Thursday.allDay,
                fristrttm: openDays.Friday.open,
                friendtm: openDays.Friday.close,
                friopenflg: openDays.Friday.allDay,
                satstrttm: openDays.Saturday.open,
                satendtm: openDays.Saturday.close,
                satopenflg: openDays.Saturday.allDay,
                sunstrttm: openDays.Sunday.open,
                sunendtm: openDays.Sunday.close,
                sunopenflg: openDays.Sunday.allDay
              }
            })
          })
          .then(data => {
            if (
              data //&& data.status >= 200 && data.status <= 300
            ) {
              if (
                (this.props.userAccess.selectedUserRole.role.roleName ===
                  'Worker Admin' ||
                  this.props.userAccess.selectedUserRole.role.roleName ===
                    'CP Admin') &&
                data.hasOwnProperty('error') &&
                data.error[0].hasOwnProperty('errorDescription') &&
                data.error[0].errorDescription
              ) {
                this.setState({
                  technicalDescription: data.error[0].errorDescription,
                  errorModal: true
                })
              }
              this.setState(prevState => {
                return {
                  ...prevState,
                  enableUpdate: false,
                  formContext: {
                    ...prevState.formContext,
                    enableUpdate: false
                  },
                  saveRequest: !prevState.saveRequest
                }
              })
            }
          })
          .catch(error => {
            if (error instanceof UnauthorizedError) {
              this.props.logoutUser()
              this.props.history.push(baseUrl + '/home')
            }
            console.log(error)
          })
      }
    }
  }

  _orgNamechange = e => {
    this.setState({
      displayNameOrg: e.target.value
    })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _updateCancel = () => {
    this.setState({
      initialLoad: true,
      formData: this.state.workerAdminOrgList,
      orgData: this.state.workerAdminOrgList
    })
  }

  _noopCancel = () => {
    this.retrieveorgById(this.props.userAccess.selectedOrg.orgId)
    this.setState({
      liveValidate: false
    })
  }

  _closeSaveModal = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        saveRequest: !prevState.saveRequest
      }
    })
    this._initializeForm()
  }

  _openSaveModal = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        saveRequest: !prevState.saveRequest
      }
    })
  }

  _organizationLink = props => {
    this.setState({
      initialLoad: false,
      userCount: false,
      orgData: [],
      newOrgBtnSel: false,
      displayNameOrg: props.options.conditionObj.baseObject.organizationName,
      retrieveOrgId: props.options.conditionObj.baseObject.organizationId,
      status: props.options.conditionObj.baseObject.status
    })
    this.retrieveorgById(props.options.conditionObj.baseObject.organizationId)
  }

  _userCountLink = props => {
    this.setState({
      userCount: true,
      initialLoad: false
    })
    this._workerAdminUserList(props)
  }

  _clear = prevState => {
    let { orgData } = this.state
    if (orgData) {
      orgData.organizationCity = _.noop()
      orgData.organizationName = _.noop()
      orgData.organizationZipcode = _.noop()
    }
    this.setState({
      orgnizationListData: {
        ...prevState.formContext,
        orgnizationListData: orgData
      }
    })
  }

  _search = () => {
    let { workerAdminOrgList, workerAdminOrgListCreated, orgData } = this.state
    let searchResults

    if (!orgData) {
      searchResults = workerAdminOrgListCreated
    } else if (
      !orgData.organizationCity &&
      !orgData.organizationName &&
      !orgData.organizationZipcode
    ) {
      searchResults = workerAdminOrgListCreated
    } else {
      let searchItems = {
        organizationSearch: {
          organizationName: orgData.organizationName,
          city: orgData.organizationCity,
          zip: orgData.organizationZipcode,
          retrieveFullList: false
        }
      }
      serviceHelper
        .fetchJson(
          retrieveOrgWithUserCountEndPoint,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              userRoleId: this.props.userAccess.selectedUserRole.userRoleId.toString(),
              roleId: this.props.userAccess.selectedUserRole.role.roleId.toString(),
              orgId: this.props.orgId.toString()
            },
            body: JSON.stringify(searchItems)
          },
          this.props.showErrorMessage
        )
        .then(json => {
          this.setState({
            workerAdminOrgList: json
          })
        })
        .catch(error => {
          if (error instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.log('_workerAdminOrgList error', error)
        })
    }
  }

  _clearSearchResults = () => {
    this._workerAdminOrgList()
  }

  _organizationUserID = props => {
    this.setState({
      userDetailViewId: props.options.conditionObj.baseObject
    })
  }

  _getRoles = () => {
    fetch(roleEndPoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        this.setState({
          getAllRoles: data.data
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.log(error)
      })
  }

  _showModal = () => {
    this.setState({
      showModal: true,
      isUserUpdating: false,
      newPresumptiveUser: {
        firstName: '',
        lastName: '',
        email: '',
        trainingDate: _moment(config, getServerTime()).format('MM/DD/YYYY'),
        agreementDate: _moment(config, getServerTime).format('MM/DD/YYYY'),
        role: '',
        org: ''
      }
    })
  }

  _updateUser = (uFormContext, e) => {
    const allRoles = this.state.getAllRoles
      ? this.state.getAllRoles['roleList']
      : ''
    const userOrgId = this.props.userAccess.selectedOrg
      ? this.props.userAccess.selectedOrg.orgId
      : ''
    let updateUserRoleId = this.state.userDetailViewId.roleId
    let updateUserRoleName =
      this.state.userDetailViewId.roleName &&
      this.state.userDetailViewId.roleName.includes('-')
        ? this.state.userDetailViewId.roleName.split('-')[0].trim()
        : this.state.userDetailViewId.roleName
    const userRoleAgcyId = this.state.userDetailViewId.userRoleAgcyId
    const userListUuid = this.state.userDetailViewId.uuid
    let pendingUserEmail = this.state.userDetailViewId.email
    let removeRights = this.state.userFormData
      ? this.state.userFormData.Application.usercompleteDetails[0].status[0]
      : ''
    const selectRoleDependencyObj = {
      previousRoleId: null,
      previousRoleName: null,
      tranCmplDate: null,
      agrCmplDate: null
    }
    serviceHelper
      .fetchJson(
        updateAccessEndPoint,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.userAccess.selectedUserRole.role.roleId,
            orgId: this.props.userAccess.selectedOrg.orgId,
            ieOrgId: this.props.ieOrgId
          },
          body: JSON.stringify({
            agencyId: `${userOrgId}`,
            removeRights: removeRights,
            roleId: `${updateUserRoleId}`,
            roleName: updateUserRoleName ? updateUserRoleName : '',
            tenantId: config.tId,
            userRoleAgcyId: userRoleAgcyId,
            uuid: userListUuid,
            userEmail: pendingUserEmail,
            tranCmplDate: selectRoleDependencyObj.tranCmplDate,
            agrCmplDate: selectRoleDependencyObj.agrCmplDate,
            previousRoleId: selectRoleDependencyObj.previousRoleId,
            previousRoleName: selectRoleDependencyObj.previousRoleName
          })
        },
        this.props.showErrorMessage
      )
      .then(data => {
        this._showModal()
        this._workerAdminUserList(this.props)
        this.setState({
          userDetailViewId: null,
          showModalError: false,
          showModalMessage: 'The changes have been successfully saved.',
          isUserUpdating: true
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        this.setState({
          isUserUpdating: true
        })
        console.error('Organization _updateUser failed due to ex', error)
      })
  }

  _backToUser = () => {
    this.setState({
      userDetailViewId: null
    })
  }

  _onUserFormDataChange = ({ formData }) => {
    let status =
      formData.Application.usercompleteDetails[0].status === 'Yes'
        ? 'Inactive'
        : 'Active'
    const { userDetailViewId } = this.state
    this.setState({
      userFormData: formData,
      userDetailViewId: {
        ...userDetailViewId,
        status: status,
        selectRole: formData.Application.usercompleteDetails[0].selectRole,
        trainingConfirmationDate:
          formData.Application.usercompleteDetails[0].trainingConfirmationDate,
        dataAgreementCompletionDate:
          formData.Application.usercompleteDetails[0]
            .dataAgreementCompletionDate
      }
    })
  }

  _hideModal = () => {
    this.setState({
      showModal: false,
      showModalError: false,
      showModalMessage: null
    })
  }

  _showNewOrg = () => {
    this.setState({
      orgData: [],
      initialLoad: false,
      userCount: false,
      displayNameOrg: null,
      newOrgBtnSel: true,
      errorDisplay: false,
      technicalDescription: null,
      openDays: {
        Monday: {
          open: '',
          close: '',
          allDay: ''
        },
        Tuesday: {
          open: '',
          close: '',
          allDay: ''
        },
        Wednesday: {
          open: '',
          close: '',
          allDay: ''
        },
        Thursday: {
          open: '',
          close: '',
          allDay: ''
        },
        Friday: {
          open: '',
          close: '',
          allDay: ''
        },
        Saturday: {
          open: '',
          close: '',
          allDay: ''
        },
        Sunday: {
          open: '',
          close: '',
          allDay: ''
        }
      }
    })
  }

  //Error Implemenation
  _onError = (errors: object) => {
    const { liveValidate, formContext } = this.state

    //Enable live validation upon error to allow for realtime evaluation of errors in response to formData changes.
    if (!liveValidate) {
      this.setState({
        //   liveValidate: true,
        formContext: {
          ...formContext,
          hasError: this.hasError
        }
      })
    }
    const errorContent = document.getElementById('appintake-errors')
    if (errorContent) {
      window.scroll(0, errorContent.offsetTop)
      errorContent.focus()
    }

    document.title = (this.hasError ? 'Error' + ' - ' : '') + 'Organization'
  }

  _errorListTemplate = (props: any) => {
    const { errors } = props

    return (
      <div
        className="panel panel-danger errors"
        id="appintake-errors"
        tabIndex={-1}
      >
        <div className="panel-heading">
          <span className="panel-title">Errors</span>
        </div>
        <ul className="list-group">
          {errors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this._onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  _transformErrors = (errors: object) => {
    return errors
  }

  _onValidate = (formData: object, errors: object) => {
    let newFormData = helper.deepClone(formData)
    const { liveValidate, formContext } = this.state

    //Call CP-specific validation library.
    if (liveValidate) {
      validator.validateOrgForm(newFormData, errors, this.state.formContext)
    }

    //Determine which tabs contain errors and update their statuses.
    let foundErrors = helper.findErrors(errors)
    if (foundErrors && foundErrors.length > 0) {
      this.hasError = true
    } else {
      this.hasError = false
    }
    return errors
  }

  _onErrorClick = (error: any) => {
    event.preventDefault()
    try {
      setTimeout(function() {
        window.requestAnimationFrame(function() {
          const element = document.getElementById(
            `organizationForm_${error.id}`
          )
          let offset = helper.getOffsetTop(
            document.getElementById(`organizationForm_${error.id}`)
          )
          element.focus()
          window.scroll(0, offset - 100)
        })
      }, 0)
    } catch (e) {
      console.error('Organization setTimeout failed with ex', e)
    }
  }

  _switchOnLiveValidate = () => {
    this.setState({
      liveValidate: true
    })
  }

  _onSubmit = ({ formData }) => {
    this._orgDataSave()

    this.setState({
      liveValidate: false
    })
  }

  render() {
    const {
      presentation,
      isLoading,
      minHeight,
      userAccess,
      errorMessage
    } = this.props
    const {
      application,
      form,
      formData,
      formContext,
      orgData,
      organizationName,
      openDays,
      orgName,
      saveRequest,
      initialLoad,
      userCount,
      displayNameOrg,
      workerAdminOrgList,
      orgnizationListData,
      userDetailViewId,
      showModal,
      showModalMessage,
      technicalDescription,
      errorModal,
      errorDisplay,
      liveValidate
    } = this.state

    let validator = null

    if (form) {
      validator = new CustomValidator(
        form['app'].forms[0].schema,
        form['app'].forms[0].uiSchema,
        formContext
      )
    }

    const presentationProps = {
      application,
      form,
      formContext,
      formData,
      onFormDataChange: this._onFormDataChange,
      orgDataSave: this._orgDataSave,
      isLoading,
      minHeight,
      orgData,
      organizationName,
      openDays,
      daysTable: this._daysTable,
      orgName,
      handleInputChange: this._orgNamechange,
      updateCancel: this._updateCancel,
      saveRequest,
      closeSaveModal: this._closeSaveModal,
      openSaveModal: this._openSaveModal,
      userAccess,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      initialLoad,
      clear: this._clear,
      userCount,
      displayNameOrg,
      search: this._search,
      workerAdminOrgList,
      orgnizationListData,
      clearSearchResults: this._clearSearchResults,
      userDetailViewId,
      updateUser: this._updateUser,
      showModal,
      backToUser: this._backToUser,
      showModalMessage,
      onUserFormDataChange: this._onUserFormDataChange,
      hideModal: this._hideModal,
      showNewOrg: this._showNewOrg,
      technicalDescription,
      errorModal,
      errorDisplay,
      errorListTemplate: this._errorListTemplate,
      onError: this._onError,
      transformErrors: this._transformErrors,
      onValidate: this._onValidate,
      onSubmit: this._onSubmit,
      liveValidate,
      switchOnLiveValidate: this._switchOnLiveValidate,
      validator,
      noopCancel: this._noopCancel
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let userAccess = _.get(state, 'userAccess') || ''
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let orgName = _.get(state.userAccess, 'selectedOrg.orgName') || ''
  let organizationName = _.get(state.auth, 'organizationName') || ''
  let roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  // let ieOrgId = _.get(state.userAccess, 'userRoles[0].organization.orgId') || ''

  let ieOrgId = null
  if (userAccess) {
    userAccess.userRoles.filter(function(item) {
      if (item.userRoleId === userRoleId) {
        if (item && item.organization && item.organization.orgId) {
          ieOrgId = item.organization.orgId
        } else {
          ieOrgId = ''
        }
      }
    })
  }

  return {
    auth: state.auth,
    userRoleId,
    externalAuth: state.externalAuth,
    userAccess,
    orgId,
    roleId,
    ieOrgId,
    orgName,
    organizationName,
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    locale: state.i18n.locale,
    errorMessage
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationContainer)
