import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

export default class ConfirmModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      modalOpen: true,
      redirectURL: null
    }

    this._closeModal = this._closeModal.bind(this)
    this._openModal = this._openModal.bind(this)
  }

  _closeModal = redirectURL => {
    this.setState({ modalOpen: false, redirectURL })
  }

  _openModal = () => {
    this.setState({ modalOpen: true })
  }

  render() {
    const { redirectFunction } = this.props
    const { modalOpen, redirectURL } = this.state

    if (redirectURL) {
      redirectFunction(redirectURL)
    }

    return (
      <div className="static-modal">
        <Modal show={modalOpen} onHide={() => this._closeModal('/home')}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="glyphicon myglyphicon-check" />
              {I18n.t('AppIntakePE.confirmModalTitle')}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                {I18n.t('AppIntakePE.confirmModalBody')}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <i className="glyphicon myglyphicon-shopping-cart" />
                <p>
                  <b>{I18n.t('AppIntakePE.providerSelection')}</b>
                </p>
                <p>{I18n.t('AppIntakePE.seeEligibleBenefits')}</p>
                <a onClick={() => this._closeModal('/myaccount/providers')}>
                  {I18n.t('AppIntakePE.goToProviderSelection')}
                </a>
              </div>
              <div className="col-md-6">
                <i className="glyphicon myglyphicon-folder-open" />
                <p>
                  <b>{I18n.t('AppIntakePE.adminOffice')}</b>
                </p>
                <p>{I18n.t('AppIntakePE.findOffice')}</p>
                <a>{I18n.t('AppIntakePE.visitAdminOffice')}</a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <i className="glyphicon myglyphicon-home" />
                <p>
                  <b>{I18n.t('AppIntakePE.home')}</b>
                </p>
                <p>{I18n.t('AppIntakePE.seeMore')}</p>
                <a onClick={() => this._closeModal('/home')}>
                  {I18n.t('AppIntakePE.goHome')}
                </a>
              </div>
              <div className="col-md-6">
                <i className="glyphicon myglyphicon-flag" />
                <p>
                  <b>{I18n.t('AppIntakePE.myApplication')}</b>
                </p>
                <p>{I18n.t('AppIntakePE.seeEligibleBenefits')}</p>
                <a onClick={() => this._closeModal('/myaccount/applications')}>
                  {I18n.t('AppIntakePE.manageApplication')}
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
