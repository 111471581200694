import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as serviceHelper from '../src/components/utils'
import * as _ from 'lodash'
import { config } from '../../../config'
import { v4 } from 'uuid'
const moment = require('moment-mini-ts')
const baseUrl = process.env.PUBLIC_URL
import * as actions from '../../../actions'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'

const gatewayWvUrl = config['gatewayWvUrl']

const formsEndpoint = config['forms_Applications']
const loadDraftEndpoint = config['getDraftApplication']
const getPdfEndpoint = gatewayWvUrl + config['getWorkerApplicationPDF']
const getCommunityPartnerApplicationsInfo =
  config['getCommunityPartnerApplicationsInfo']
const getCommunityPartnerApplicationsInfoSearch =
  config['gatewayWvUrl'] + config['CPAdminWorkerApplicationSearch']

interface SearchApplicationsContainerProps {
  presentation: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  userRoleId: number
  orgId: number
  roleId: number
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  logoutUser
  history: {
    push: (url: string) => void
  }
}

interface SearchApplicationsContainerState {
  formData: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formContext: any
  reviewFormContext: any
  formDataSubmitted: false
  redirect: string
  formKey: string
  errorDisplay: boolean
  activeCount: number
  totalCount: number
  pageNumber: number
  showOption: string
  pageCountDisplay: number
  response: any
  currentDate: any
}

class SearchApplicationsContainer extends React.Component<
  SearchApplicationsContainerProps,
  SearchApplicationsContainerState
> {
  constructor(props: SearchApplicationsContainerProps) {
    super(props)
    this.state = {
      formData: {},
      forms: null,
      formContext: null,
      reviewFormContext: null,
      formDataSubmitted: false,
      redirect: null,
      formKey: v4(),
      errorDisplay: false,
      activeCount: 1,
      totalCount: 0,
      pageNumber: 1,
      showOption: 'none',
      pageCountDisplay: 10,
      response: null,
      currentDate: getServerTime()
    }
  }

  componentDidMount() {
    document.title = 'My Applications - My Account'
    this._loadApp()
  }

  _loadApp = () => {
    this._getFormDataJSON()
  }

  _getFormDataJSON = () => {
    let jsonData = {
      applicationType: 'APPL',
      source: 'Myself',
      searchApplicationsResult: []
    }

    serviceHelper
      .fetchJson(
        getCommunityPartnerApplicationsInfo,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            userRoleId: this.props.userRoleId.toString()
          },
          body: JSON.stringify(jsonData)
        },
        this.props.showErrorMessage
      )
      .then(jsonApplData => {
        const request = {
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        }
        serviceHelper
          .fetchJson(
            formsEndpoint.replace('{version}', '1.0'),
            request,
            this.props.showErrorMessage
          )
          .then(json => {
            const applicationSearchSchema = json
            const forms = applicationSearchSchema['forms']
            helper.cleanNullValues(forms)
            helper.cleanNullValues(jsonApplData)
            this._loadForm(forms, jsonApplData, applicationSearchSchema)
          })
          .catch(error => {
            if (error instanceof UnauthorizedError) {
              this.props.logoutUser()
              this.props.history.push(baseUrl + '/home')
            }
            console.error('Applications form fetch failed due to ex', error)
          })
      })
      .catch(ex => {
        console.error('Applications _getFormDataJSON failed with ex', ex)
      })
  }

  /**
   * @Description: Load the EventHistory tab.
   *
   */
  _loadForm = (forms: Array<any>, formData: any, applicationSearchSchema) => {
    let formContext: object, refs
    let currentdate = _moment(config, this.state.currentDate).format(
      'YYYY-MM-DD'
    )
    let pastDate = _moment(config, this.state.currentDate)
      .subtract('years', 1)
      .add('days', 1)
      .format('YYYY-MM-DD')
    refs = applicationSearchSchema['metaData']['refs']
    formContext = {
      ...formContext,
      forms: forms,
      setRedirect: this._setRedirect,
      hasKeyedAny: this._hasKeyedAny,
      getDraftApplication: this._viewApplicationDetails,
      config,
      currentDate: currentdate,
      pastDate: pastDate,
      refs: refs
    }

    let formDataLoad: object = formData

    if (_.isObject(formDataLoad) && !_.isEmpty(formDataLoad)) {
      //Merge the pre-filled form data with an empty default object to ensure necessary objects are available for RJSF rendering.
      formData = _.mergeWith(
        helper.createObjectFromMasterSchema(null, formContext),
        formDataLoad,
        function(objValue, srcValue) {
          if (srcValue == null && objValue == undefined) {
            return ''
          }
          return undefined
        }
      )
    } else {
      //New application, initialize to empty object - does this work?
      formData = helper.createObjectFromMasterSchema(null, formContext)
    }

    //Augment the context with formData
    formContext = {
      ...formContext,
      formData: formData
    }

    this.setState({ forms, formData, formContext })
  }

  _setRedirect = (path: string) => {
    this.setState({ redirect: path })
  }

  _viewApplicationDetails = (applId: string, appStatus: string) => {
    if (appStatus === 'Draft') {
      const fetchEndpoint = loadDraftEndpoint.replace('{applId}', applId)
      serviceHelper
        .fetchJson(
          fetchEndpoint,
          {
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              userRoleId: this.props.userRoleId
            },
            method: 'POST'
          },
          this.props.showErrorMessage
        )
        .then(jsonData => {
          helper.cleanNullValues(jsonData)
          this.setState({
            reviewFormContext: {
              ...this.state.formData,
              formData: jsonData,
              reviewFormData: jsonData
            },
            redirect: applId
          })
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.error(
            'SearchApplications _viewApplicationDetails failed with ex',
            ex
          )
        })
    } else if (appStatus === 'Submitted') {
      const fetchEndpoint = getPdfEndpoint.replace(
        '{applId}',
        applId.toString()
      )
      const request = {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          roleId: this.props.roleId,
          orgId: this.props.orgId,
          userRoleId: this.props.userRoleId
        }
      }
      serviceHelper
        .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
        .then(response => {
          let content = response.fileContent
          let contentType = 'application/pdf'
          var pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
          var bytearray = this.convertDataURIToBinary(pdfAsDataUri)

          var file = new Blob([bytearray], {
            type: contentType
          })
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file)
          } else {
            var fileURL = URL.createObjectURL(file)
            window.open(fileURL)
            // auto download the file
            var a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = 'application.pdf'
            document.body.appendChild(a)
            a.click()
          }
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.error('MyApplications _viewPDFApplication failed with ex', ex)
        })
    }
  }

  convertDataURIToBinary = (dataURI: string) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }

  // _viewPDFApplication = (applicationId: string) => {
  //   const fetchEndpoint = getPdfEndpoint.replace('{applId}', applicationId)

  //   fetch(fetchEndpoint, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //       tenantCode: config.tenant.code,
  //       portalName: config.portalName,
  //       uuid: this.props.auth.userAccount.uuid,
  //       Authorization: this.props.authHeader
  //     }
  //   })
  //     .then(res => {
  //       return res.json()
  //     })
  //     .then(response => {
  //       let content = response.fileContent
  //       let contentType = 'application/pdf'
  //       var pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
  //       var bytearray = this.convertDataURIToBinary(pdfAsDataUri)

  //       var file = new Blob([bytearray], {
  //         type: contentType
  //       })
  //       // for IE 10/11 save doc pop up will appear
  //       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //         window.navigator.msSaveOrOpenBlob(file)
  //       } else {
  //         var fileURL = URL.createObjectURL(file)
  //         window.open(fileURL)
  //         // auto download the file
  //         var a = document.createElement('a')
  //         a.href = fileURL
  //         a.target = '_blank'
  //         a.download = 'application.pdf'
  //         document.body.appendChild(a)
  //         a.click()
  //       }
  //     })
  //     .catch(ex => {
  //       console.error('Applications _viewPDFApplication failed with ex', ex)
  //     })
  // }

  _viewPDFApplication = (id: string, applicationId: number) => {
    const fetchEndpoint = getPdfEndpoint.replace('{applId}', id.toString())

    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(response => {
        let content = response.fileContent
        let contentType = 'application/pdf'
        var pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
        var bytearray = this.convertDataURIToBinary(pdfAsDataUri)

        var file = new Blob([bytearray], {
          type: contentType
        })
        // for IE 10/11 save doc pop up will appear
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file)
        } else {
          var fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          // auto download the file
          var a = document.createElement('a')
          a.href = fileURL
          a.target = '_blank'
          a.download = 'application.pdf'
          document.body.appendChild(a)
          a.click()
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('viewPDFApplication failed with ex', error)
      })
  }

  _searchApplications = () => {
    let se = this.state.formData

    if (!this._hasKeyedAny()) {
      // no valid input to search
      console.error(
        '_searchApplications missing required fields to perform search'
      )
    } else {
      let tempSearch = _.cloneDeep(this.state.formData)
      // delete non SearchEvent props from 'formData' before submitting form
      _.unset(tempSearch, 'sectionOneHeader')
      _.unset(tempSearch, 'searchApplicationsResult')
      this._cleanFormData(tempSearch)

      let currentdate = _moment(config, this.state.currentDate).format(
        'YYYY-MM-DD'
      )
      let pastDate = _moment(config, this.state.currentDate)
        .subtract('years', 1)
        .add('days', 1)
        .format('YYYY-MM-DD')

      let fromDate = tempSearch.fromDate
      let toDate = tempSearch.toDate

      if (tempSearch.fromDate === undefined) {
        tempSearch.fromDate = pastDate
      }
      if (tempSearch.toDate === undefined) {
        tempSearch.toDate = currentdate
      }
      if (fromDate !== undefined) {
        if (moment(fromDate).isAfter(toDate)) {
          this.setState({ errorDisplay: true })
        } else {
          this.setState({ errorDisplay: false })
        }
      } else {
        this.setState({ errorDisplay: false })
      }
      const searchApp = {
        searchApplications: {
          ...tempSearch
        }
      }

      if (searchApp.searchApplications.hasOwnProperty('data')) {
        delete searchApp.searchApplications['data']
      }
      if (searchApp.searchApplications.hasOwnProperty('messageElements')) {
        delete searchApp.searchApplications['messageElements']
      }
      if (searchApp.searchApplications.hasOwnProperty('tenant')) {
        delete searchApp.searchApplications['tenant']
      }
      serviceHelper
        .fetchJson(
          getCommunityPartnerApplicationsInfoSearch,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              userRoleId: this.props.userRoleId.toString(),
              roleId: this.props.roleId.toString(),
              orgId: this.props.orgId.toString()
            },
            body: JSON.stringify(searchApp)
          },
          this.props.showErrorMessage
        )
        .then(json => {
          if (json) {
            for (var i = 0; i < json.searchApplicationsResult.length; i++) {
              var CPApplStatus =
                json.searchApplicationsResult[i].applicationStatus
              if (
                CPApplStatus == 'DEACTIVATED' ||
                CPApplStatus == 'deactivated'
              ) {
                json.searchApplicationsResult[i].applicationStatus =
                  'Deactivated'
              } else if (
                CPApplStatus == 'SUBMITTED' ||
                CPApplStatus == 'submitted'
              ) {
                json.searchApplicationsResult[i].applicationStatus = 'Submitted'
              } else if (CPApplStatus == 'DRAFT' || CPApplStatus == 'draft') {
                json.searchApplicationsResult[i].applicationStatus = 'Draft'
              }
            }
            let formData = se
            formData.searchApplicationsResult = json.searchApplicationsResult
            this._cleanFormData(formData)
            this._onFormDataChange({ formData })
          }
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.error(' _searchApplications failed with ex ', ex)
        })
    }
  }

  // Remove null values from the form data
  _cleanFormData(obj: any) {
    var k,
      has = Object.prototype.hasOwnProperty.bind(obj)
    for (k in obj)
      if (has(k)) {
        switch (typeof obj[k]) {
          case 'object':
            // clear element in the object and then delete the object
            this._cleanFormData(obj[k])
            if (
              obj[k] === null ||
              obj[k] === undefined ||
              obj[k] === '' ||
              Object.keys(obj[k]).length === 0
            ) {
              delete obj[k]
            }
            break
          default:
            if (obj[k] === null || obj[k] === undefined || obj[k] === '') {
              delete obj[k]
            }
            break
        }
      }
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(
      {
        formData,
        formContext: {
          ...this.state.formContext,
          formData: formData
        }
      },
      () => this._refreshFormContext()
    )
  }

  // refresh formcontext since it works with formdata
  _refreshFormContext = () => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        formData: helper.deepClone(this.state.formData)
      }
    })
  }

  _clearForm = () => {
    let formData = _.cloneDeep(this.state.formData)
    formData.applicationType = _.noop()
    formData.applicationId = _.noop()
    formData.source = _.noop()
    formData.clientFstName = _.noop()
    formData.clientLstName = _.noop()
    formData.dob = _.noop()
    formData.clientSSN = _.noop()
    formData.appStatus = _.noop()
    formData.fromDate = _.noop()
    formData.toDate = _.noop()
    this.setState({
      formData,
      formContext: {
        ...this.state.formContext,
        formData: formData
      },
      formKey: v4(),
      errorDisplay: false
    })
  }

  _hasKeyedAny = (): boolean => {
    const se = this.state.formData
    let hasKeyedAny = false
    if (
      !_.isNil(se.applicationId) ||
      !_.isNil(se.applicationType) ||
      !_.isNil(se.source) ||
      !_.isNil(se.clientFstName) ||
      !_.isNil(se.clientLstName) ||
      !_.isNil(se.dob) ||
      !_.isNil(se.clientSSN) ||
      !_.isNil(se.appStatus) ||
      !_.isNil(se.fromDate) ||
      !_.isNil(se.toDate)
    ) {
      hasKeyedAny = true
    }
    return hasKeyedAny
  }

  render() {
    if (
      this.state.redirect &&
      this.state.formData.applicationType !== 'RENEWAL'
    ) {
      return (
        <Redirect to={baseUrl + '/application?applId=' + this.state.redirect} />
      )
    } else if (
      this.state.formData.applicationType === 'RENEWAL' &&
      this.state.redirect
    ) {
      return (
        <Redirect to={baseUrl + '/renewal?applId=' + this.state.redirect} />
      )
    }

    const { presentation, errorMessage } = this.props
    const { formContext, formData, formKey, forms } = this.state

    const presentationProps = {
      forms,
      formData,
      formContext,
      onFormDataChange: this._onFormDataChange,
      hasKeyedAny: this._hasKeyedAny,
      searchApplications: this._searchApplications,
      clearForm: this._clearForm,
      formKey,
      errorDisplay: this.state.errorDisplay,
      errorMessage,
      onDeleteClick: this._onDeleteClick
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }
  let roleName = _.get(state.userAccess, 'selectedUserRole.role.roleName') || ''
  let ieOrgId, userRoleId
  let userRoleIdOrgIdList = _.get(state, 'userAccess.userRoleIdOrgIdList') || []
  let currentuserRoleIdOrgIdRecord = _.find(userRoleIdOrgIdList, current => {
    return (
      _.get(current, 'orgId') ==
        _.get(state.userAccess, 'selectedOrg.ieOrgId') &&
      _.get(current, 'rolename') == roleName
    )
  })
  ieOrgId = _.get(currentuserRoleIdOrgIdRecord, 'orgId', '')
  userRoleId = _.get(currentuserRoleIdOrgIdRecord, 'userRoleId', '')
  if (!userRoleId) {
    userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  }
  return {
    auth: state.auth,
    userRoleId,
    roleId,
    orgId,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchApplicationsContainer)
