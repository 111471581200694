import * as React from 'react'
import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as actions from '../../../actions'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { v4 } from 'uuid'

declare const process
const baseUrl = process.env.PUBLIC_URL

const Fragment = (React as any).Fragment
const tenantDirectoryName = config.tenant.code.toLowerCase()

const withRoutes = (WrappedComponent, basePromiseThunk, tenantPromiseThunk) => {
  return connect(
    null,
    mapDispatchToProps
  )(
    class extends React.Component<any, any> {
      constructor(props) {
        super(props)
        this.state = {
          TenantRoutes: undefined,
          BaseRoutes: undefined
        }
      }

      componentDidMount() {
        Promise.all([tenantPromiseThunk(), basePromiseThunk()])
          .then(result => {
            const TenantRoutes = result[0].default
            const BaseRoutes = result[1].default
            this.setState({
              TenantRoutes,
              BaseRoutes
            })
          })
          .catch(() => {
            basePromiseThunk().then(baseTabs => {
              this.setState({
                BaseRoutes: baseTabs.MyAccountTabs
              })
            })
          })
      }
      _openIdRedirect = () => {
        let isDefaultCase = config.isDefaultBaseCase && config.isDefaultWVCase
        let isProductionNodeEnv = process.env.NODE_ENV === 'production'
        let isLocalConfigEnv = config.environment === 'LOCAL'

        if (isDefaultCase || (isProductionNodeEnv && isLocalConfigEnv)) {
          let messageList = [
            {
              errorCode: 'IE-APPP-BEN100',
              errorDescription:
                'This functionality is currently not available. Please try again later or contact the Technical Call Center at 1-844-451-3515 for immediate assistance.'
            }
          ]
          this.props.showErrorMessage(messageList)
          return <Redirect to={baseUrl + '/home'} />
        } else {
          const loginUrl = config.openId_loginUrl.replace('{state}', v4());
          window.location.href = loginUrl;
          return null
        }
      }

      render() {
        const { TenantRoutes, BaseRoutes } = this.state
        return (
          <WrappedComponent
            routes={auth => (
              <Fragment>
                {BaseRoutes && (
                  <BaseRoutes
                    auth={auth}
                    openIdRedirect={this._openIdRedirect}
                  />
                )}
                {TenantRoutes && (
                  <TenantRoutes
                    auth={auth}
                    openIdRedirect={this._openIdRedirect}
                  />
                )}
              </Fragment>
            )}
            {...this.props}
          />
        )
      }
    }
  )
}

const LoadableRoot = withRoutes(
  MultitenantLoadable(
    () => import('./Root'),
    () => import('../../' + tenantDirectoryName + '/Root/Root')
  ),
  () => import('./routes'),
  () => import('../../' + tenantDirectoryName + '/Root/routes')
)

export default function Root(props) {
  return <LoadableRoot {...props} />
}

const BaseLoadableRoot = withRoutes(
  MultitenantLoadable(
    () => import('./Root'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./routes'),
  () => Promise.resolve().then(() => null)
)

export function BaseRoot(props) {
  return <BaseLoadableRoot {...props} />
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    }
  }
}
