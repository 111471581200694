import * as React from 'react'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { isEmpty, get } from 'lodash'
import { Modal } from 'react-bootstrap'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'
import MyRoleTable from './MyRoleTable'
import { Helmet } from 'react-helmet'

document.title = I18n.t('MyProfile.title')

const _ = { isEmpty, get }

interface MyProfilePresentationProps {
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formData: any
  edit: boolean
  formContext: any
  onSubmit: (formData: any) => void
  onFormDataChange: (formData: any) => void
  closePinErrorModal: () => void
  showPinErrorModal: boolean
  minHeight: string
  updateDefaultUserRoleId: () => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  locale: string
  defaultUserRoleId: number
  handleRoleChange: () => void
}

export default function MyProfilePresentation(
  props: MyProfilePresentationProps
) {
  const {
    formSchema,
    formData,
    formContext,
    onFormDataChange,
    closePinErrorModal,
    showPinErrorModal,
    minHeight,
    updateDefaultUserRoleId,
    formComponents,
    locale,
    defaultUserRoleId,
    handleRoleChange
  } = props

  const roleSetting = _.get(formData, 'roleSetting', {})

  let output = <ScreenPreLoader loaded={false} />

  if (formSchema && formData && formContext && !_.isEmpty(formContext)) {
    const newFormContext = {
      ...formContext,
      locale
    }

    output = (
      <div className="col-xs-12">
        <div className="row">
          <h1>
            <i className="pe-7s-user" /> {I18n.t('MyProfile.title')}
          </h1>
          <h5 className="headertxt">
            {I18n.t('MyProfile.profileMessage', { name: config.tenant.name })}
            <br />
          </h5>
        </div>
        <div className="row">
          <Form
            schema={formSchema[0]['schema']}
            uiSchema={formSchema[0]['uiSchema']}
            formData={formData}
            onChange={onFormDataChange}
            widgets={formComponents.widgets}
            fields={formComponents.fields}
            formContext={newFormContext}
            FieldTemplate={formComponents.FieldTemplate}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            locale={locale}
          >
            <button type="submit" className="hidden" />
          </Form>
          <MyRoleTable
            roleSetting={roleSetting}
            defaultUserRoleId={defaultUserRoleId}
            locale={locale}
            handleRoleChange={handleRoleChange}
          />
          <button
            type="button"
            id="saveDefaultRole"
            className="btn btn-primary float-right"
            onClick={updateDefaultUserRoleId}
            style={{ margin: '10px' }}
          >
            {I18n.t('General.saveButton')}
          </button>
        </div>
        <Modal show={showPinErrorModal} onHide={closePinErrorModal}>
          <Modal.Header closeButton>
            <Modal.Title>{I18n.t('MyProfile.pinErrorModalTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div> {I18n.t('MyProfile.pinErrorModalBody')}</div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn" onClick={closePinErrorModal}>
              {I18n.t('General.okButton')}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('MyProfile.title')}</title>
      </Helmet>
      {output}
    </>
  )
}
