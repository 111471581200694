import * as React from 'react'
import * as helper from '../src/components/utils'
import { fetchJson } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash' // extra functionality
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
const moment = require('moment-timezone')
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'

const gatewayWvUrl = config['gatewayWvUrl']

const loadEndpoint = gatewayWvUrl + config['getClientNoticeList']
const documentEndpont = gatewayWvUrl + config['getClientNoticeDetails']
const updateReadInd = gatewayWvUrl + config['updateReadInd']
const downloadDocEndpoint = config['downloadDocument']
const formsEndpoint = config['forms_MyMessages']
const baseUrl = process.env.PUBLIC_URL

interface MyMessagesContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  nextProps: object
  userRoleId: string
  userType: string
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  roleId: any
  orgId: any
  logoutUser
  hasCCA
  locale
  history: {
    push: (url: string) => void
  }
  programTypes
}

interface MyMessagesContainerState {
  application: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formContext: any
  messageDetailViewId: number
  messageId: number
  eligibleMessages: {
    Application: {
      myMessages: Array<{
        agSeqNumber: string
        benefitMonth: string
        caseNumber: string
        messageType: string
        msgDt: string
        msgSbj: string
        msgTo: string
        noticeCd: string
        noticeType: string
        programCode: string
        readUnreadFlag: boolean
        requestTimeStamp: string
        subProgramCode: string
      }>
    }
  }
  sortedMessages: Array<{}>
  startDate: string
  endDate: string
  sortedMessageArray: any
  currentDate: any
}

class MyMessagesContainer extends React.Component<
  MyMessagesContainerProps,
  MyMessagesContainerState
> {
  constructor(props: MyMessagesContainerProps) {
    super(props)

    this.state = {
      application: {},
      forms: null,
      formContext: {},
      messageDetailViewId: null,
      messageId: null,
      eligibleMessages: {
        Application: {
          myMessages: []
        }
      },
      sortedMessages: [],
      startDate: null,
      endDate: null,
      sortedMessageArray: {},
      currentDate: getServerTime()
    }
  }
  componentDidMount() {
    document.title = 'My Messages - My Account'
    let messages = null
    let startDt = _moment(config, this.state.currentDate).format('MM/DD/YYYY')
    let endDt = _moment(config, this.state.currentDate)
      .subtract(6, 'months')
      .format('MM/DD/YYYY')
    this.fetchMessages(startDt, endDt)
    this._loadForms()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      this._loadForms()
    }
  }

  private fetchMessages(startDt, endDt) {
    let ldPT = loadEndpoint + '?fromDate=' + endDt + '&toDate=' + startDt
    helper
      .fetchJson(
        ldPT,
        {
          method: 'GET',
          headers: {
            uuid: this.props.auth.userAccount.uuid,
            userRoleId: this.props.userRoleId,
            userType: this.props.userType,
            'Content-Type': 'application/json',
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId,
            programType: this.props.programTypes
          }
        },
        this.props.showErrorMessage,
        0,
        60000
      )
      .then(messages => {
        messages = _.map(messages, m => {
          return {
            ...m,
            programName:
              m.programType === 'CCA'
                ? 'Child Care'
                : m.programType === 'FA'
                  ? 'Family Assistance'
                  : 'Child Support'
          }
        })
        return this.setState(prevState => {
          return {
            ...prevState,
            eligibleMessages: {
              Application: {
                myMessages: messages
              }
            }
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('Messages failed to fetch because of', error)
      })
  }

  componentWillUnmount() {
    this.props.deleteErrorMessage()
  }

  static getDerivedStateFromProps(
    props: MyMessagesContainerProps,
    state: MyMessagesContainerState
  ) {
    let newEligibleMessages, newMessageArray
    if (state.sortedMessages.length > 0) {
      newMessageArray = {
        Application: {
          ...state.formContext.formData.Application.myMessages.Application,
          myMessages: state.sortedMessages
        }
      }
      newEligibleMessages = newMessageArray
    } else {
      newEligibleMessages = state.eligibleMessages
    }
    return {
      formContext: {
        ...state.formContext,
        formData: newEligibleMessages || {}
      },
      eligibleMessages: newEligibleMessages,
      sortedMessageArray: newMessageArray
    }
  }

  _postApplicationJSON = () => { }

  _generatePDF = (json: Object) => {
    let message = json['Application']

    const fetchEndpoint =
      message.programType === 'FA'
        ? documentEndpont
          .replace('{programCode}', message.programCode)
          .replace('{reqTimeStamp}', message.requestTimeStamp)
          .replace('{agSeqNumber}', message.agSeqNumber)
          .replace('{subProgramCode}', message.subProgramCode)
          .replace('{readUnreadFlag}', message.readUnreadFlag)
          .replace('{caseNum}', message.caseNumber)
        : downloadDocEndpoint.replace('{docID}', message.docId)
    helper
      .fetchJson(
        fetchEndpoint,
        {
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          method: 'GET'
        },
        this.props.showErrorMessage,
        0,
        60000
      )
      .then(resp => {
        var content
        var contentType
        var pdfAsDataUri
        if (message.messageType == 'Document') {
          content =
            message.programType === 'FA'
              ? resp.document.fileContent
              : resp.fileContent
          contentType = resp.document.contentType
          pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
        } else {
          content =
            message.programType === 'FA'
              ? resp.document.fileContent
              : resp.fileContent
          contentType = 'application/pdf'
          pdfAsDataUri = 'data:application/pdf;base64,' + content // shortened
        }

        var bytearray = this.convertDataURIToBinary(pdfAsDataUri)

        var file = new Blob([bytearray], {
          type: contentType
        })
        //trick to download store a file having its URL
        var fileURL = ''
        // for IE 10/11 save doc pop up will appear
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file)
        } else {
          fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          // auto download the file
          var a = document.createElement('a')
          a.href = fileURL
          a.target = '_blank'
          a.download = 'Notification.pdf'
          document.body.appendChild(a)
          a.click()
        }
        if (message.programType === 'CS') {
          helper
            .fetchJson(
              updateReadInd,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  uuid: this.props.auth.userAccount.uuid,
                  tenantCode: config.tCode,
                  Authorization: config.bearer + this.props.auth.accessToken,
                  portalName: config.portalName,
                  roleId: this.props.roleId,
                  orgId: this.props.orgId
                },
                body: JSON.stringify({
                  publicPortalList: [
                    {
                      caseId: message.caseNumber,
                      pin: message.pin,
                      documentId: message.docId,
                      readInd: 'Y'
                    }
                  ]
                })
              },
              this.props.showErrorMessage
            )
            .then(resp => { })
            .catch(ex => {
              if (ex instanceof UnauthorizedError) {
                this.props.logoutUser()
                this.props.history.push(baseUrl + '/home')
              }
              console.error('MyMessages un failed with ex', ex)
            })
        }
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('MyMessages _generatePDF failed with ex', ex)
      })
  }

  _goBack = () => {
    this.setState({
      messageDetailViewId: null
    })
    let startDt = _moment(config, this.state.currentDate).format('MM/DD/YYYY')
    let endDt = _moment(config, this.state.currentDate)
      .subtract(6, 'months')
      .format('MM/DD/YYYY')
    this.fetchMessages(startDt, endDt);
  }

  _filterMessages = () => {
    const filteredMessages = this.state.eligibleMessages.Application.myMessages
    if (filteredMessages.length > 0) {
      var resultArr = filteredMessages.filter(this.callback)
      this.setState(prevState => {
        return {
          ...prevState,
          sortedMessages: resultArr
        }
      })
    }
  }

  callback = message => {
    let stDate = moment(this.state.startDate).format('MM/DD/YYYY')
    let endDate = moment(this.state.endDate).format('MM/DD/YYYY')
    let newARR = moment(message.msgDt).isBetween(stDate, endDate, null, '[]')
    return newARR
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _loadForms = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(formJson => {
        let application, forms, formContext
        application = formJson['app']
        forms = application['forms']

        this.setState(prevState => {
          return {
            application,
            forms,
            formContext: {
              ...prevState.formContext,
              refs: application['metaData']['refs'],
              locale: this.props.locale,
              forms: forms,
              viewMessageDetails: (id, messageId) => {
                this.setState({ messageDetailViewId: id, messageId })
              }
            }
          }
        })
      })
      .catch(error => {
        console.error('MyMessages form fetch failed due to ex', error)
      })
  }

  convertDataURIToBinary = (dataURI: string) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }
  _onFormDataChange = ({ formData }) => {
    console.error(
      'MyMessages _onFormDataChange: This should not happen as messages are read-only'
    )
  }

  _onChangeDates = (value, date) => {
    if (date === 'startDate') {
      this.setState(prevState => {
        return {
          ...prevState,
          startDate: value
        }
      })
    }
    if (date === 'endDate') {
      this.setState(prevState => {
        return {
          ...prevState,
          endDate: value
        }
      })
    }
  }

  _clearSearch = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        sortedMessages: [],
        startDate: '',
        endDate: ''
      }
    }, () => {
      let startDt = _moment(config, this.state.currentDate).format('MM/DD/YYYY')
      let endDt = _moment(config, this.state.currentDate)
        .subtract(6, 'months')
        .format('MM/DD/YYYY')
      this.fetchMessages(startDt, endDt)
    })
  }

  _searchMessages = () => {
    let startDate = this.state.startDate
    let endDate = this.state.endDate
    let trigger = true
    if (
      (endDate === '' || endDate === null) &&
      (startDate === '' || startDate === null)
    ) {
      trigger = false
    }
    if (trigger) {
      let endDt = null
      let strtDt = null
      if (endDate === '' || endDate === null) {
        endDt = _moment(config, this.state.currentDate).format('MM/DD/YYYY')
      } else {
        endDt = _moment(config, endDate).format('MM/DD/YYYY')
      }
      if (startDate === '' || startDate === null) {
        strtDt = _moment(config, endDate)
          .subtract(36, 'months')
          .format('MM/DD/YYYY')
      } else {
        strtDt = _moment(config, startDate).format('MM/DD/YYYY')
        if (endDate === '' || endDate === null) {
          if (
            _moment(config, this.state.currentDate).diff(
              _moment(config, startDate),
              'years'
            ) >= 3
          ) {
            strtDt = _moment(config, this.state.currentDate)
              .subtract(36, 'months')
              .format('MM/DD/YYYY')
          }
        }
      }

      if (
        (endDate != '' || endDate != null) &&
        (startDate != '' || startDate != null)
      ) {
        if (
          _moment(config, endDate).diff(_moment(config, startDate), 'years') >=
          3
        ) {
          strtDt = _moment(config, endDate)
            .subtract(36, 'months')
            .format('MM/DD/YYYY')
        }
      }
      this.fetchMessages(endDt, strtDt)
    }
  }

  render() {
    const {
      presentation,
      formData,
      minHeight,
      isLoading,
      errorMessage
    } = this.props
    const {
      application,
      formContext,
      forms,
      messageDetailViewId,
      messageId,
      startDate,
      endDate,
      eligibleMessages,
      sortedMessageArray,
      currentDate
    } = this.state

    const presentationProps = {
      application,
      eligibleMessages,
      formContext,
      forms,
      isLoading,
      messageDetailViewId,
      messageId,
      startDate,
      endDate,
      onFormDataChange: this._onFormDataChange,
      goBack: this._goBack,
      generatePDF: this._generatePDF,
      filterMessages: this._filterMessages,
      onChangeDates: this._onChangeDates,
      clearSearch: this._clearSearch,
      minHeight,
      sortedMessageArray,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      currentDate,
      searchMessages: this._searchMessages
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let userType = _.get(state.userAccess.userRoles[0].role.roleDescription) || ''
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []

  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let caseTypes = state.caseTypes.caseTypes
  let programs = []
  if (caseTypes.cs) {
    console.log('CS', caseTypes.cs)
    programs.push('CS')
  }
  if (caseTypes.cca) {
    programs.push('CCA')
  }
  if (caseTypes.fa) {
    programs.push('FA')
  }
  return {
    auth: state.auth,
    userRoleId,
    userType,
    errorMessage,
    roleId,
    orgId,
    locale: state.i18n.locale,
    hasCCA: _.get(state, 'hasCCA.hasCCA'),
    programTypes: programs
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMessagesContainer)
export const MyMessagesDoubleFetcherContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyMessagesContainer)
