import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '~/config' // endpoints
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import '!style-loader!css-loader!../src/customStyles.css'
import { connect } from 'react-redux'
import * as actions from '../../../actions'
import { UnauthorizedError } from '../src/components/utils'
import { Redirect } from 'react-router-dom'
import * as serviceHelper from '../src/components/utils'
import { I18n } from 'react-redux-i18n'

const baseUrl = process.env.PUBLIC_URL

const formsBaseUrl = config['formsBaseUrl']
const IncomeEndpoint = config['gatewayWvUrl'] + config['getIncomeLimits']
const formsEndpoint = formsBaseUrl + config['forms_MyIncome']

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  dataTable: Fields.DataTableField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  span: Widgets.SpanWidget,
  routeLink: Widgets.LinkWidget,
  iconWidget: Widgets.ConditionalIconWidget
}

interface incomeState {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  tableData: any
  tableData2: any
  application: any
  formContext: any
  redirect: any
}

export class CriWidget extends React.Component<any, incomeState> {
  constructor(props) {
    super(props)

    this.state = {
      application: {},
      forms: null,
      formContext: props.formContext,
      tableData: null,
      tableData2: null,
      redirect: null
    }
  }

  componentDidMount() {
    this.loadForms()
    this._getIncome()
  }

  loadForms() {
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tenantCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName
        }
      })
      .then(formJson => {
        let application, forms, formContext
        application = formJson['app']
        forms = application['forms']

        this.setState(prevState => {
          return {
            ...prevState,
            application: application,
            forms: forms,
            formContext: {
              ...prevState.formContext,
              refs: application['metaData']['refs'],
              forms: forms
            }
          }
        })
      })
      .catch(error => {
        console.error(I18n.t('ClientUpdateWizard.CriWidgetConsoleError'), error)
      })
  }

  _getIncome = () => {
    const houserHoldSize = this.state.formContext.formData.application.clients
      .length
    const fetchEndpoint = IncomeEndpoint
    let body = {
      houseHoldSize: 11,
      programName: 'CoCAll'
    }

    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tenantCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          method: 'POST',
          body: JSON.stringify(body)
        },
        this.props.showErrorMessage
      )
      .then(json => {
        let arr = json
        arr.map(function(currentObj, index, arr) {
          currentObj.incomeLimit = '$' + currentObj.incomeLimit
        })
        const size = arr.filter(household => {
          return (
            household.houserHoldSize ===
            this.state.formContext.formData.application.clients.length
          )
        })
        const formData2 = { Application: { myIncomes: size || [] } }
        const formData1 = { Application: { myIncomes: arr || [] } }
        this.setState(prevState => {
          return {
            ...prevState,
            tableData: formData1,
            tableData2: formData2
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
      })
  }

  _table() {
    let tableId1 = document.getElementById('table1').className
    let tableId2 = document.getElementById('table2').className
    if (tableId1 == 'tableStyleShow') {
      document.getElementById('table1').className = 'tableShowNone'
      document.getElementById('table2').className = 'tableStyleShow'
    } else {
      document.getElementById('table1').className = 'tableStyleShow'
      document.getElementById('table2').className = 'tableShowNone'
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state
    }
  }

  render() {
    const { formContext, tableData, tableData2, forms, redirect } = this.state

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }

    return (
      <div>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          {I18n.t('ClientUpdateWizard.CriWidget1')}
        </h3>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          {I18n.t('ClientUpdateWizard.CriWidgetCC1')}
        </h3>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC2')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC3')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC4')}</p>

        <p className="cri-p-space">
          {I18n.t('ClientUpdateWizard.CriWidgetCC5')}
        </p>
        <p className="cri-p-space">
          {I18n.t('ClientUpdateWizard.CriWidgetCC6')}
        </p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC7')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC8')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC9')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC10')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC11')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC12')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC13')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC14')}</p>
        <p>{I18n.t('ClientUpdateWizard.CriWidgetCC15')}</p>
        <p className="cri-p-space">
          {I18n.t('ClientUpdateWizard.CriWidgetCC16')}
        </p>
        <p className="cri-p-space">
          {I18n.t('ClientUpdateWizard.CriWidgetCC17')}
        </p>
        <p className="cri-p-space">
          {I18n.t('ClientUpdateWizard.CriWidgetCC18')}
        </p>
        <br />
      </div>
    )
  }
}
function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CriWidget)
