import * as React from 'react'
import {
  IEServiceError,
  NetworkConnectionError,
  InternalServerError
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { get, toString } from 'lodash'
import { config } from '../../../config'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'

const Fragment = (React as any).Fragment

const PREFIX = 'IE'
const MODULE = 'CP'

export const CATEGORIES = {
  //Project wide categories:
  FUNCTIONAL_LEVEL: '00A', //TODO: What functionality?
  INVALID_INPUT_PARAMETERS: '00G',
  INTERNAL_SERVER_ERROR: '00H',
  NETWORK_CONNECTION_ERROR: '00I',
  OTHER: '00Z',

  //All known exception types in JavaScript:
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Error_types
  JS_EVAL_ERROR: 'JS1',
  JS_INTERNAL_ERROR: 'JS2',
  JS_RANGE_ERROR: 'JS3',
  JS_REFERENCE_ERROR: 'JS4',
  JS_SYNTAX_ERROR: 'JS5',
  JS_TYPE_ERROR: 'JS6',
  JS_URI_ERROR: 'JS7'
}

function getCategoryByErrorType(error) {
  if (error instanceof NetworkConnectionError) {
    return CATEGORIES.NETWORK_CONNECTION_ERROR
  } else if (error instanceof InternalServerError) {
    return CATEGORIES.INTERNAL_SERVER_ERROR
  } else if (error instanceof EvalError) {
    return CATEGORIES.JS_EVAL_ERROR
  } else if (error instanceof RangeError) {
    return CATEGORIES.JS_RANGE_ERROR
  } else if (error instanceof ReferenceError) {
    return CATEGORIES.JS_REFERENCE_ERROR
  } else if (error instanceof SyntaxError) {
    return CATEGORIES.JS_SYNTAX_ERROR
  } else if (error instanceof TypeError) {
    return CATEGORIES.JS_TYPE_ERROR
  } else if (error instanceof URIError) {
    return CATEGORIES.JS_URI_ERROR
  } else {
    return CATEGORIES.OTHER
  }
}

export const CODES = {
  APP_INTAKE_UNKNOWN: 'AI0',
  APP_INTAKE_FETCH_FORM: 'AI1',
  APP_INTAKE_REFETCH_FORM: 'AI2',
  APP_INTAKE_FETCH_DRAFT: 'AI3',
  APP_INTAKE_SAVE_DRAFT: 'AI4',
  APP_INTAKE_SUBMIT: 'AI5',
  APP_INTAKE_RIDP_SUBMIT: 'AI6',

  APP_INTAKE_PE_UNKNOWN: 'PE0',
  APP_INTAKE_PE_FETCH_FORM: 'PE1',
  APP_INTAKE_PE_REFETCH_FORM: 'PE2',
  APP_INTAKE_PE_FETCH_DRAFT: 'PE3',
  APP_INTAKE_PE_SUBMIT: 'PE4',

  BENEFITS_CATALOG_UNKNOWN: 'BC0',

  CLIENT_UPDATE_WIZARD_UNKNOWN: 'CU0',
  CLIENT_UPDATE_WIZARD_FETCH_FORM: 'CU1',
  CLIENT_UPDATE_WIZARD_REFETCH_FORM: 'CU2',
  CLIENT_UPDATE_WIZARD_FETCH_DATA: 'CU3',
  CLIENT_UPDATE_WIZARD_FETCH_RENEWAL_INFO: 'CU4',
  CLIENT_UPDATE_WIZARD_SUBMIT: 'CU5',

  FEEDBACK_UNKNOWN: 'FB0',
  FEEDBACK_FETCH_FORM: 'FB1',
  FEEDBACK_REFETCH_FORM: 'FB2',

  HELP_UNKNOWN: 'HP0',

  HOME_UNKNOWN: 'HO0',
  HOME_ACCEPT_LOGIN: 'HO1',

  HEADER_UNKNOWN: 'HD0',

  FOOTER_UNKNOWN: 'FT0',

  MY_ACCOUNT_UNKNOWN: 'AC0',

  MY_APP_DRAFTS_UNKNOWN: 'AD0',
  MY_APP_DRAFTS_FETCH_FORM: 'AD1',
  MY_APP_DRAFTS_REFETCH_FORM: 'AD2',
  MY_APP_DRAFTS_FETCH_DATA: 'AD3',

  MY_APPEAL_UNKNOWN: 'AL0',

  MY_APPLICATIONS_UNKNOWN: 'AP0',
  MY_APPLICATIONS_FETCH_FORM: 'AP1',
  MY_APPLICATIONS_REFETCH_FORM: 'AP2',
  MY_APPLICATIONS_FETCH_REVIEW_FORM: 'AP3',
  MY_APPLICATIONS_REFETCH_REVIEW_FORM: 'AP4',
  MY_APPLICATIONS_FETCH_DATA: 'AP5',
  MY_APPLICATIONS_COPY_N_SAVE: 'AP6',
  MY_APPLICATIONS_DELETE_DRAFT: 'AP7',
  MY_APPLICATIONS_FETCH_DETAILS: 'AP8',
  MY_APPLICATIONS_VIEW_PDF: 'AP9',

  MY_APPLICATIONS_ROOT_UNKNOWN: 'AR0',

  MY_BENEFITS_UNKNOWN: 'BE0',
  MY_BENEFITS_FETCH_FORM: 'BE1',
  MY_BENEFITS_REFETCH_FORM: 'BE2',
  MY_BENEFITS_FETCH_DATA: 'BE3',

  MY_DASHBOARD_UNKNOWN: 'DB0',
  MY_DASHBOARD_FETCH_FORM: 'DB1',
  MY_DASHBOARD_REFETCH_FORM: 'DB2',
  MY_DASHBOARD_DELETE_DRAFT: 'DB3',
  MY_DASHBOARD_FETCH_PE_DATA: 'DB4',
  MY_DASHBOARD_SUBMIT_OVERRIDE: 'DB5',
  MY_DASHBOARD_AGREE_AUTO_ACCEPT: 'DB6',
  MY_DASHBOARD_VIEW_PDF: 'DB7',

  MY_DOCUMENTS_UNKNOWN: 'DO0',
  MY_DOCUMENTS_FETCH_FORM: 'DO1',

  MY_DOCUMENTS_ACCORDION_UNKNOWN: 'DA0',
  MY_DOCUMENTS_ACCORDION_FETCH_FORM: 'DA1',
  MY_DOCUMENTS_ACCORDION_FETCH_DATA: 'DA2',
  MY_DOCUMENTS_ACCORDION_FETCH_DETAILS: 'DA3',
  MY_DOCUMENTS_ACCORDION_DELETE_DOC: 'DA4',
  MY_DOCUMENTS_ACCORDION_UPLOAD_DOC: 'DA5',
  MY_DOCUMENTS_ACCORDION_SUBMIT: 'DA6',
  MY_DOCUMENTS_FETCH_APPLICATION_DATA: 'DA7',

  MY_LIFE_EVENTS_UNKNOWN: 'LE0',
  MY_LIFE_EVENTS_FETCH_FORM: 'LE1',
  MY_LIFE_EVENTS_REFETCH_FORM: 'LE2',
  MY_LIFE_EVENTS_FETCH_RENEWAL_INFO: 'LE3',

  MY_MESSAGES_UNKNOWN: 'ME0',
  MY_MESSAGES_FETCH_FORM: 'ME1',
  MY_MESSAGES_REFETCH_FORM: 'ME2',
  MY_MESSAGES_VIEW_PDF: 'ME3',
  MY_MESSAGES_UPDATE_READ_FLAG: 'ME4',

  MY_PE_APPLICATIONS_UNKNOWN: 'PA0',
  MY_PE_APPLICATIONS_FETCH_FORM: 'PA1',
  MY_PE_APPLICATIONS_REFETCH_FORM: 'PA2',
  MY_PE_APPLICATIONS_DELETE_DRAFT: 'PA3',
  MY_PE_APPLICATIONS_FETCH_PE_DATA: 'PA4',
  MY_PE_APPLICATIONS_SUBMIT_OVERRIDE: 'PA5',
  MY_PE_APPLICATIONS_PE_OVERRIDE: 'PA6',
  MY_PE_APPLICATIONS_AGREE_AUTO_ACCEPT: 'PA7',
  MY_PE_APPLICATIONS_VIEW_PDF: 'PA8',

  MY_PERMISSION_UNKNOWN: 'PM0',
  MY_PERMISSION_FETCH_FORM: 'PM1',
  MY_PERMISSION_REFETCH_FORM: 'PM2',
  MY_PERMISSION_FETCH_REVIEW_FORM: 'PM3',
  MY_PERMISSION_REFETCH_REVIEW_FORM: 'PM4',
  MY_PERMISSION_FETCH_DATA: 'PM5',
  MY_PERMISSION_FETCH_DETAILS: 'PM6',

  MY_PROFILE_UNKNOWN: 'PR0',
  MY_PROFILE_FETCH_FORM: 'PR1',
  MY_PROFILE_REFETCH_FORM: 'PR2',
  MY_PROFILE_FETCH_DATA: 'PR3',
  MY_PROFILE_VALIDATE_PIN: 'PR4',
  MY_PROFILE_UPDATE_DEFAULT_ROLE: 'PR5',

  MY_PROVIDERS_UNKNOWN: 'PV0',
  MY_PROVIDERS_FETCH_FORM: 'PV1',
  MY_PROVIDERS_REFETCH_FORM: 'PV2',
  MY_PROVIDERS_INITIAL_DATA: 'PV3',
  MY_PROVIDERS_SELECTED_DATA: 'PV4',
  MY_PROVIDERS_SAMEASPROVIDER: 'PV5',
  MY_PROVIDERS_DELETE_DATA: 'PV6',
  MY_PROVIDERS_OPEN_ENROLLMENT: 'PV7',
  MY_PROVIDERS_SUBMIT: 'PV8',

  MY_PROVIDERS_NEW_UNKNOWN: 'PN0',
  MY_PROVIDERS_NEW_FETCH_FORM: 'PN1',
  MY_PROVIDERS_NEW_REFETCH_FORM: 'PN2',
  MY_PROVIDERS_NEW_INITIAL_DATA: 'PN3',
  MY_PROVIDERS_NEW_SELECTED_DATA: 'PN4',
  MY_PROVIDERS_NEW_OPEN_ENROLLMENT: 'PN5',
  MY_PROVIDERS_NEW_SUBMIT: 'PN6',
  MY_PROVIDERS_NEW_PROVIDER_PROFILE: 'PN7',

  MY_TASKS_UNKNOWN: 'TA0',

  ROOT_UNKNOWN: 'RO0',

  SEARCH_APPLICATIONS_UNKNOWN: 'SA0',
  SEARCH_APPLICATIONS_FETCH_FORM: 'SA1',
  SEARCH_APPLICATIONS_REFETCH_FORM: 'SA2',
  SEARCH_APPLICATIONS_FETCH_PARTNER_INFO: 'SA3',
  SEARCH_APPLICATIONS_SEARCH_PARTNER_INFO: 'SA3',
  SEARCH_APPLICATIONS_VIEW_PDF: 'SA4',

  SEARCH_PE_APPLICATIONS_UNKNOWN: 'SP0',
  SEARCH_PE_APPLICATIONS_FETCH_FORM: 'SP1',
  SEARCH_PE_APPLICATIONS_REFETCH_FORM: 'SP2',
  SEARCH_PE_APPLICATIONS_SEARCH_PE_INFO: 'SP3',
  SEARCH_PE_APPLICATIONS_VIEW_PDF: 'SP4',
  SEARCH_PE_APPLICATIONS_DELETE_DRAFT: 'SP5',
  SEARCH_PE_APPLICATIONS_SUBMIT_OVERRIDE: 'SP6',
  SEARCH_PE_APPLICATIONS_PE_OVERRIDE: 'SP7',
  SEARCH_PE_APPLICATIONS_AGREE_AUTO_ACCEPT: 'SP8',

  SELECT_ROLE_UNKNOWN: 'SR0',
  SELECT_ROLE_UPDATE_DEFAULT_ROLE: 'SR1',

  //TODO: Define error codes in Shared repo.
  BENEFITS_FINDER_UNKNOWN: 'BF0',

  //TODO: To define error codes for My Forms.
  FORMS_LOAD_DATA: 'FM0'
}

function getDefaultCodeByPageName(name) {
  switch (name) {
    case 'AppIntake':
      return CODES.APP_INTAKE_UNKNOWN
    case 'BenefitsCatalog':
      return CODES.BENEFITS_CATALOG_UNKNOWN
    case 'ClientUpdateWizard':
      return CODES.CLIENT_UPDATE_WIZARD_UNKNOWN
    case 'Feedback':
      return CODES.FEEDBACK_UNKNOWN
    case 'Help':
      return CODES.HELP_UNKNOWN
    case 'Home':
      return CODES.HOME_UNKNOWN
    case 'Header':
      return CODES.HEADER_UNKNOWN
    case 'Footer':
      return CODES.FOOTER_UNKNOWN
    case 'MyAccount':
      return CODES.MY_ACCOUNT_UNKNOWN
    case 'MyAppDrafts':
      return CODES.MY_APP_DRAFTS_UNKNOWN
    case 'MyAppeal':
      return CODES.MY_APPEAL_UNKNOWN
    case 'MyApplications':
      return CODES.MY_APPLICATIONS_UNKNOWN
    case 'MyApplicationsRoot':
      return CODES.MY_APPLICATIONS_ROOT_UNKNOWN
    case 'MyBenefits':
      return CODES.MY_BENEFITS_UNKNOWN
    case 'MyDashboard':
      return CODES.MY_DASHBOARD_UNKNOWN
    case 'MyDocuments':
      return CODES.MY_DOCUMENTS_UNKNOWN
    case 'MyDocumentsAccordion':
      return CODES.MY_DOCUMENTS_ACCORDION_UNKNOWN
    case 'MyLifeEvents':
      return CODES.MY_LIFE_EVENTS_UNKNOWN
    case 'MyMessages':
      return CODES.MY_MESSAGES_UNKNOWN
    case 'MyPEApplications':
      return CODES.MY_PE_APPLICATIONS_UNKNOWN
    case 'MyPermission':
      return CODES.MY_PERMISSION_UNKNOWN
    case 'MyProfile':
      return CODES.MY_PROFILE_UNKNOWN
    case 'MyProviders':
      return CODES.MY_PROVIDERS_UNKNOWN
    case 'MyProvidersNew':
      return CODES.MY_PROVIDERS_NEW_UNKNOWN
    case 'MyTasks':
      return CODES.MY_TASKS_UNKNOWN
    case 'Root':
      return CODES.ROOT_UNKNOWN
    case 'SearchApplications':
      return CODES.SEARCH_APPLICATIONS_UNKNOWN
    case 'SearchPEApplications':
      return CODES.SEARCH_PE_APPLICATIONS_UNKNOWN
    case 'SelectRole':
      return CODES.SELECT_ROLE_UNKNOWN
    case 'BenefitsFinder':
      return CODES.BENEFITS_FINDER_UNKNOWN
    default:
      return '000'
  }
}

export function shouldThrow(code) {
  //This will act as our "fusebox" for now. It will return whether an error is critical
  //enough that it should be thrown to trigger the ErrorBoundary component, based on the code.
  switch (code) {
    //TODO: Get direction from the business on the decisions for which errors are critical.
    case CODES.APP_INTAKE_FETCH_DRAFT:
    case CODES.APP_INTAKE_SAVE_DRAFT:
    case CODES.APP_INTAKE_PE_FETCH_DRAFT:
    case CODES.MY_APP_DRAFTS_FETCH_DATA:
    case CODES.CLIENT_UPDATE_WIZARD_SUBMIT:
    case CODES.MY_PROVIDERS_INITIAL_DATA:
    case CODES.MY_PROVIDERS_NEW_INITIAL_DATA:
      return false

    case CODES.APP_INTAKE_UNKNOWN:
    case CODES.APP_INTAKE_FETCH_FORM:
    case CODES.APP_INTAKE_REFETCH_FORM:
    case CODES.APP_INTAKE_SUBMIT:
    case CODES.APP_INTAKE_RIDP_SUBMIT:
    case CODES.APP_INTAKE_PE_UNKNOWN:
    case CODES.APP_INTAKE_PE_FETCH_FORM:
    case CODES.APP_INTAKE_PE_REFETCH_FORM:
    case CODES.APP_INTAKE_PE_SUBMIT:
    case CODES.BENEFITS_CATALOG_UNKNOWN:
    case CODES.CLIENT_UPDATE_WIZARD_UNKNOWN:
    case CODES.CLIENT_UPDATE_WIZARD_FETCH_FORM:
    case CODES.CLIENT_UPDATE_WIZARD_REFETCH_FORM:
    case CODES.CLIENT_UPDATE_WIZARD_FETCH_DATA:
    case CODES.CLIENT_UPDATE_WIZARD_FETCH_RENEWAL_INFO:
    case CODES.FEEDBACK_UNKNOWN:
    case CODES.FEEDBACK_FETCH_FORM:
    case CODES.FEEDBACK_REFETCH_FORM:
    case CODES.HELP_UNKNOWN:
    case CODES.HOME_UNKNOWN:
    case CODES.HOME_ACCEPT_LOGIN:
    case CODES.HEADER_UNKNOWN:
    case CODES.FOOTER_UNKNOWN:
    case CODES.MY_ACCOUNT_UNKNOWN:
    case CODES.MY_APP_DRAFTS_UNKNOWN:
    case CODES.MY_APP_DRAFTS_FETCH_FORM:
    case CODES.MY_APP_DRAFTS_REFETCH_FORM:
    case CODES.MY_APPEAL_UNKNOWN:
    case CODES.MY_APPLICATIONS_UNKNOWN:
    case CODES.MY_APPLICATIONS_FETCH_FORM:
    case CODES.MY_APPLICATIONS_REFETCH_FORM:
    case CODES.MY_APPLICATIONS_FETCH_REVIEW_FORM:
    case CODES.MY_APPLICATIONS_REFETCH_REVIEW_FORM:
    case CODES.MY_APPLICATIONS_FETCH_DATA:
    case CODES.MY_APPLICATIONS_COPY_N_SAVE:
    case CODES.MY_APPLICATIONS_DELETE_DRAFT:
    case CODES.MY_APPLICATIONS_FETCH_DETAILS:
    case CODES.MY_APPLICATIONS_VIEW_PDF:
    case CODES.MY_APPLICATIONS_ROOT_UNKNOWN:
    case CODES.MY_BENEFITS_UNKNOWN:
    case CODES.MY_BENEFITS_FETCH_FORM:
    case CODES.MY_BENEFITS_REFETCH_FORM:
    case CODES.MY_BENEFITS_FETCH_DATA:
    case CODES.MY_DASHBOARD_UNKNOWN:
    case CODES.MY_DASHBOARD_FETCH_FORM:
    case CODES.MY_DASHBOARD_REFETCH_FORM:
    case CODES.MY_DASHBOARD_DELETE_DRAFT:
    case CODES.MY_DASHBOARD_FETCH_PE_DATA:
    case CODES.MY_DASHBOARD_SUBMIT_OVERRIDE:
    case CODES.MY_DASHBOARD_AGREE_AUTO_ACCEPT:
    case CODES.MY_DASHBOARD_VIEW_PDF:
    case CODES.MY_DOCUMENTS_UNKNOWN:
    case CODES.MY_DOCUMENTS_FETCH_FORM:
    case CODES.MY_DOCUMENTS_ACCORDION_UNKNOWN:
    case CODES.MY_DOCUMENTS_ACCORDION_FETCH_FORM:
    case CODES.MY_DOCUMENTS_ACCORDION_FETCH_DATA:
    case CODES.MY_DOCUMENTS_ACCORDION_FETCH_DETAILS:
    case CODES.MY_DOCUMENTS_ACCORDION_DELETE_DOC:
    case CODES.MY_DOCUMENTS_ACCORDION_UPLOAD_DOC:
    case CODES.MY_DOCUMENTS_ACCORDION_SUBMIT:
    case CODES.MY_LIFE_EVENTS_UNKNOWN:
    case CODES.MY_LIFE_EVENTS_FETCH_FORM:
    case CODES.MY_LIFE_EVENTS_REFETCH_FORM:
    case CODES.MY_LIFE_EVENTS_FETCH_RENEWAL_INFO:
    case CODES.MY_MESSAGES_UNKNOWN:
    case CODES.MY_MESSAGES_FETCH_FORM:
    case CODES.MY_MESSAGES_REFETCH_FORM:
    case CODES.MY_MESSAGES_VIEW_PDF:
    case CODES.MY_MESSAGES_UPDATE_READ_FLAG:
    case CODES.MY_PE_APPLICATIONS_UNKNOWN:
    case CODES.MY_PE_APPLICATIONS_FETCH_FORM:
    case CODES.MY_PE_APPLICATIONS_REFETCH_FORM:
    case CODES.MY_PE_APPLICATIONS_DELETE_DRAFT:
    case CODES.MY_PE_APPLICATIONS_FETCH_PE_DATA:
    case CODES.MY_PE_APPLICATIONS_SUBMIT_OVERRIDE:
    case CODES.MY_PE_APPLICATIONS_PE_OVERRIDE:
    case CODES.MY_PE_APPLICATIONS_AGREE_AUTO_ACCEPT:
    case CODES.MY_PE_APPLICATIONS_VIEW_PDF:
    case CODES.MY_PERMISSION_UNKNOWN:
    case CODES.MY_PERMISSION_FETCH_FORM:
    case CODES.MY_PERMISSION_REFETCH_FORM:
    case CODES.MY_PERMISSION_FETCH_REVIEW_FORM:
    case CODES.MY_PERMISSION_REFETCH_REVIEW_FORM:
    case CODES.MY_PERMISSION_FETCH_DATA:
    case CODES.MY_PERMISSION_FETCH_DETAILS:
    case CODES.MY_PROFILE_UNKNOWN:
    case CODES.MY_PROFILE_FETCH_FORM:
    case CODES.MY_PROFILE_REFETCH_FORM:
    case CODES.MY_PROFILE_FETCH_DATA:
    case CODES.MY_PROFILE_VALIDATE_PIN:
    case CODES.MY_PROFILE_UPDATE_DEFAULT_ROLE:
    case CODES.MY_PROVIDERS_UNKNOWN:
    case CODES.MY_PROVIDERS_FETCH_FORM:
    case CODES.MY_PROVIDERS_REFETCH_FORM:
    case CODES.MY_PROVIDERS_SELECTED_DATA:
    case CODES.MY_PROVIDERS_SAMEASPROVIDER:
    case CODES.MY_PROVIDERS_DELETE_DATA:
    case CODES.MY_PROVIDERS_OPEN_ENROLLMENT:
    case CODES.MY_PROVIDERS_SUBMIT:
    case CODES.MY_PROVIDERS_NEW_UNKNOWN:
    case CODES.MY_PROVIDERS_NEW_FETCH_FORM:
    case CODES.MY_PROVIDERS_NEW_REFETCH_FORM:
    case CODES.MY_PROVIDERS_NEW_SELECTED_DATA:
    case CODES.MY_PROVIDERS_NEW_OPEN_ENROLLMENT:
    case CODES.MY_PROVIDERS_NEW_SUBMIT:
    case CODES.MY_PROVIDERS_NEW_PROVIDER_PROFILE:
    case CODES.MY_TASKS_UNKNOWN:
    case CODES.SEARCH_APPLICATIONS_UNKNOWN:
    case CODES.SEARCH_APPLICATIONS_FETCH_FORM:
    case CODES.SEARCH_APPLICATIONS_REFETCH_FORM:
    case CODES.SEARCH_APPLICATIONS_FETCH_PARTNER_INFO:
    case CODES.SEARCH_APPLICATIONS_SEARCH_PARTNER_INFO:
    case CODES.SEARCH_APPLICATIONS_VIEW_PDF:
    case CODES.SEARCH_PE_APPLICATIONS_UNKNOWN:
    case CODES.SEARCH_PE_APPLICATIONS_FETCH_FORM:
    case CODES.SEARCH_PE_APPLICATIONS_REFETCH_FORM:
    case CODES.SEARCH_PE_APPLICATIONS_SEARCH_PE_INFO:
    case CODES.SEARCH_PE_APPLICATIONS_VIEW_PDF:
    case CODES.SEARCH_PE_APPLICATIONS_DELETE_DRAFT:
    case CODES.SEARCH_PE_APPLICATIONS_SUBMIT_OVERRIDE:
    case CODES.SEARCH_PE_APPLICATIONS_PE_OVERRIDE:
    case CODES.SEARCH_PE_APPLICATIONS_AGREE_AUTO_ACCEPT:
    case CODES.SELECT_ROLE_UNKNOWN:
    case CODES.SELECT_ROLE_UPDATE_DEFAULT_ROLE:
    case CODES.BENEFITS_FINDER_UNKNOWN:
    default:
      return true
  }
}

export function ClientPortalException(error, code, category = null) {
  this.message = error.message
  this.error = error
  this.code = code
  if (category) {
    this.category = category
  } else {
    this.category = getCategoryByErrorType(error)
  }
  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(this)
  } else {
    const newError = new (Error as any)()
    this.stack = newError.stack
  }
}
ClientPortalException.prototype = Object.create(Error.prototype)
ClientPortalException.prototype.name = 'ClientPortalException'
ClientPortalException.prototype.constructor = ClientPortalException

class ErrorBoundaryComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorObject: undefined,
      errorCode: undefined,
      correlationId: null
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(errorObject: any, errorInfo: any) {
    let errorCode = ''
    let correlationId = null
    if (errorObject instanceof IEServiceError) {
      //The error was received from a backend service API response.
      const { errorList, businessCorrelationId } = errorObject as any
      //TODO: Can there be multiple errors in errorList? Do we display all?
      errorCode = get(errorList, '[0].errorCode') || ''
      correlationId = businessCorrelationId
    } else if (errorObject instanceof ClientPortalException) {
      //The error originated from a known point in the UI.
      const { category, code } = errorObject as any
      errorCode = `${PREFIX}-${MODULE}-${category}${code}`
    } else {
      //It is unclear where the error came from. Check for JS exceptions and specify the affected component.
      errorCode = `${PREFIX}-${MODULE}-`
      errorCode += getCategoryByErrorType(errorObject)
      errorCode += getDefaultCodeByPageName(this.props.name)
    }
    console.error(
      'ErrorBoundary caught exception! errorCode:',
      errorCode,
      'errorObject',
      errorObject,
      'errorInfo',
      errorInfo
    )
    //TODO: Call front end service logging API?
    this.setState({
      hasError: true,
      errorCode,
      errorObject,
      errorInfo,
      correlationId
    })
  }

  render() {
    const { children, name, locale } = this.props
    const {
      hasError,
      errorCode,
      errorObject,
      errorInfo,
      correlationId
    } = this.state
    if (hasError) {
      return (
        <div>
          <span>
            <i className="fa fa-frown-o" />
            {' ' + I18n.t('ErrorBoundary.title')}
          </span>
          <p>
            <span style={{ fontWeight: 'bold' }}>
              {I18n.t('ErrorBoundary.page') + ': '}
            </span>
            {name}
            <br />
            <span style={{ fontWeight: 'bold' }}>
              {I18n.t('ErrorBoundary.errorCode') + ': '}
            </span>
            {errorCode}
            {!config.isProd && errorObject && (
              <Fragment>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  {I18n.t('ErrorBoundary.errorMessage') + ': '}
                </span>
                {toString(errorObject)}
              </Fragment>
            )}
            {correlationId && (
              <Fragment>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  {I18n.t('ErrorBoundary.correlationId') + ': '}
                </span>
                {correlationId}
              </Fragment>
            )}
          </p>
          {I18n.t('ErrorBoundary.apology')}
          <br />
          {I18n.t('ErrorBoundary.contact', {
            supportEmail: 'wvtcc@optum.com',
            supportPhone: '1-844-451-3515'
          })}
        </div>
      )
    }

    return children
  }
}

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale
  }
}

export const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryComponent)
