import * as React from 'react'
import { Panel } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Panel'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import * as actions from '@optum-wvie/dynamic-ui-framework/src/actions'
import { scrollToTop } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { Link } from 'react-router-dom'
const baseUrl = process.env.PUBLIC_URL

export function BenefitContent(key: string): JSX.Element {
  switch (key) {
    case 'MEDI':
      return (
        <div className="benefit benefit-medicaid">
          <h2 className="bold">Medicaid</h2>
          <p>
            Medicaid is health insurance for families and individuals with low
            income and limited resources. Medicaid provides free or low-cost
            health coverage to some low-income people, families and children,
            pregnant women, the elderly, and people with disabilities. Medicaid
            also covers medical care in the home or in an institutional setting,
            such as a nursing home, for eligible individuals who otherwise may
            not be able to afford the care.
          </p>
          <p>Some services covered by Medicaid include:</p>
          <ul>
            <li>Doctor visits</li>
            <li>Check ups</li>
            <li>Hospital visits</li>
            <li>Prescriptions</li>
            <li>Tests and X-rays</li>
            <li>Emergency Care</li>
            <li>Transportation</li>
            <li>And more</li>
          </ul>
          <h4 className="bold">
            You may be eligible for Medicaid, if you are:
          </h4>
          <ul>
            <li>Live in West Virginia</li>
            <li>Are a U.S. citizen or qualified immigrant</li>
            <li>Receive Supplemental Security Income (SSI)</li>
            <li>Are a child under age 19</li>
            <li>Are an adult age 19 to 65</li>
            <li>Are a pregnant woman</li>
            <li>Are a family with dependent children</li>
            <li>Are over age 65</li>
            <li>Are disabled and/or blind</li>
            <li>Have low income</li>
          </ul>
          <p>
            You qualify for Medicaid based on your household size, income, and
            other factors, like age and disability. When you fill out your
            application, you'll find out if you and your family qualify.
            Eligibility is determined by West Virginia Department of Human 
            Services (DoHS) workers in county offices.
          </p>
          <h4 className="bold">Additional information is available at:</h4>
          <ul>
            <li>
              <a
                href="https://dhhr.wv.gov/bms/Pages/default.aspx"
                target="_blank"
                className="link-line"
              >
                West Virginia Bureau for Medical Services{' '}
              </a>
            </li>
            <li>
              <a
                href="https://dhhr.wv.gov/bms/BMSPUB/Documents/Guide%20to%20Medicaid%202023.pdf"
                target="_blank"
                className="link-line"
              >
                Your Guide To Medicaid{' '}
              </a>
            </li>
          </ul>
        </div>
      )
    case 'SNAP':
      return (
        <div className="benefit benefit-snap">
          <h2 className="bold">Supplemental Nutrition Assistance Program</h2>
          <p>
            Supplemental Nutrition Assistance Program (SNAP) provides monthly
            benefits to help eligible households buy food or seeds. Eligibility
            to receive benefits is based on household size, income, assets and
            some household expenses as determined by the federal government.
          </p>

          <h4 className="bold">
            Who is eligible for Supplemental Nutrition Assistance Program?
          </h4>
          <p>
            Supplemental Nutrition Assistance Program is approved if the
            household meets or can be presumed to have met:
          </p>
          <ul>
            <li>
              The gross income limit for a household’s size and composition.
            </li>
            <li>
              The net income limit for a household’s size and composition.
            </li>
            <li>An asset limit.</li>
            <li>Citizenship or immigration status requirements.</li>
            <li>Residency requirements.</li>
            <li>Other SNAP-specific requirements.</li>
          </ul>
          <h4 className="bold">
            How much can your households receive from Supplemental Nutrition
            Assistance Program?
          </h4>
          <p>
            How much money your household can receive depends on how many people
            there are in your household.
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>People in Household</th>
                <th>Maximum Monthly Allotment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>$250</td>
              </tr>
              <tr>
                <td>2</td>
                <td>$459</td>
              </tr>
              <tr>
                <td>3</td>
                <td>$658</td>
              </tr>
              <tr>
                <td>4</td>
                <td>$835</td>
              </tr>
              <tr>
                <td>5</td>
                <td>$992</td>
              </tr>
              <tr>
                <td>6</td>
                <td>$1,190</td>
              </tr>
              <tr>
                <td>7</td>
                <td>$1,316</td>
              </tr>
              <tr>
                <td>8</td>
                <td>$1,504</td>
              </tr>
              <tr>
                <td>Each additional person</td>
                <td>$188</td>
              </tr>
            </tbody>
          </table>
          <p>
            The amount of benefits or money your household gets is called an
            allotment. The net monthly income of the household is multiplied by
            0.3, and the result is subtracted from the maximum allotment for the
            household size to find the household's allotment. This is because
            Supplemental Nutrition Assistance Program households are expected to
            spend about 30 percent of their resources on food.
          </p>

          <h4 className="bold">Example:</h4>
          <p>
            James and Mary have two, young daughters. Mary's mother also lives
            with them and shares their food. Their total monthly income is
            $2,500. The net monthly income is $2,300, and the household has
            $1,100 worth of assets. The Maximum monthly allotment the household
            can receive is $992. The household's Monthly Net Income is: $2,300
            Maximum Monthly Allotment for 5 people is: $992
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>People in Household</th>
                <th>Maximum Monthly Allotment</th>
                <th>Household's Monthly Net Income</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>5</td>
                <td>$992</td>
                <td>$2,300</td>
              </tr>
            </tbody>
          </table>
          <p>
            James and Mary's household is expected to provide 30% of the money
            they need for food.
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>Monthly Net Income</th>
                <th style={{ minWidth: '50px' }}>30%</th>
                <th>Expected Household Contribution</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>$2,300</td>
                <td>X 0.3</td>
                <td>=$690</td>
              </tr>
            </tbody>
          </table>
          <p>
            James and Mary's household can receive $302 when they enroll in
            Supplemental Nutrition Assistance Program (SNAP).
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>Maximum Monthly Allotment</th>
                <th>Expected Household Contribution</th>
                <th>SNAP Benefit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>$992</td>
                <td>-$690</td>
                <td>=$302</td>
              </tr>
            </tbody>
          </table>

          <h4 className="bold">
            Special Features of Supplemental Nutrition Assistance Program:
          </h4>
          <p>
            There are special rules for households that contain an elderly or
            disabled member.
          </p>
          <ul>
            <li>
              Who is elderly?
              <ul>
                <li>
                  A person is elderly if he or she is 60 years of age or older
                </li>
              </ul>
            </li>
            <li>
              Who is disabled?
              <ul>
                <li>
                  Generally, a person is considered to be disabled for
                  Supplemental Nutrition Assistance Program purposes if he or
                  she:
                  <ul>
                    <li>
                      Receives Federal disability or blindness payments under
                      the Social Security Act, including Supplemental Security
                      Income (SSI) or Social Security disability or blindness
                      payments; or
                    </li>
                    <li>
                      Receives State disability or blindness payments based on
                      Supplemental Security Income rules; or
                    </li>
                    <li>
                      Receives a disability retirement benefit from a
                      governmental agency because of a disability considered
                      permanent under the Social Security Act; or
                    </li>
                    <li>
                      Receives an annuity under the Railroad Retirement Act and
                      is eligible for Medicare or is considered to be disabled
                      based on the Supplemental Security Income rules; or
                    </li>
                    <li>
                      Is a veteran who is totally disabled, permanently
                      housebound, or in need of regular aid and attendance; or
                    </li>
                    <li>
                      Is a surviving spouse or child of a veteran who is
                      receiving Veterans Affairs (VA) benefits and is considered
                      to be permanently disabled
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <p>
            By federal law, SNAP eligibility is determined from the date the
            application is filed. To set this filing date, individuals have the
            right to submit an “incomplete application” for SNAP. This is an
            application that contains only the applicant’s name, address and a
            valid signature. You will still need to complete a full application
            and interview before a decision is made that you will receive
            benefits.{' '}
            <Link to={baseUrl + '/application?incomplete=true'}>
              Click here to begin the “incomplete application” for SNAP.
            </Link>
          </p>
        </div>
      )
    case 'TANF':
      return (
        <div className="benefit benefit-tanf">
          <h2 className="bold">WV WORKS</h2>
          <p>
            WV WORKS provides monthly cash assistance to eligible families. Most
            people who receive a monthly WV WORKS check also receive SNAP and
            Medicaid, but these are not guaranteed based just on receipt of a
            check.
          </p>

          <h3>There are two steps to the eligibility process:</h3>

          <h3>Step 1-Gross Income Test</h3>
          <p>
            The family's gross income must be below an amount that varies by
            family size. If this income test is passed, certain other deductions
            are applied to the income to determine the amount of the benefit.
            These are: 40% of earned income; child care expenses; and a portion
            of redirected child support. There is 1 incentive payment paid
            monthly based on case circumstances, a child support
            incentive/pass-through payment when child support is redirected to
            the Bureau for Child Support Enforcement.
          </p>

          <h3>Step 2-WV WORKS requirements</h3>
          <p>
            In addition to financial eligibility, the client is required to
            complete an orientation to WV WORKS that may be done in a group or
            individually. Applicants are required to attend orientation to learn
            about available activities and services and for an explanation of
            the 60-month lifetime limit for receipt of benefits.
          </p>
          <p>
            In addition, each adult or emancipated minor is required to
            negotiate a personal responsibility contract. This contract is an
            agreement between the applicant and DoHS which identifies goals,
            objectives and actions that must be taken in order to become
            self-sufficient. The personal responsibility contract may be
            renegotiated at any time; it is an ever-changing document. Within
            the first 30 days a home visit will be completed by the WV WORKS
            Case Manager.{' '}
          </p>

          <h3>Educational Opportunities</h3>
          <p>
            Most people who receive a monthly WV WORKS check also receive SNAP
            and Medicaid, but these are not guaranteed based just on receipt of
            a check.
          </p>
          <p>
            Eligible participants may receive educational programs including
            assessment, vocational training, college, job skills related to
            employment and education related to employment.
          </p>

          <hr className="col-xs-12" />

          <h2 className="bold">School Clothing Allowance</h2>
          <p>
            The School Clothing Allowance (SCA) program provides vouchers for
            clothing appropriate for school such as pants, shirts, skirts,
            dresses, shoes, coats, underwear and other basic clothing. Piece
            goods may be purchased to make clothing, if the family desires.
            Purchases made with the vouchers are exempt from sales tax and some
            stores offer discounts to customers who use the vouchers in their
            stores. Applications will be accepted from July 1 through July 31 or
            as designated by WV Department of Human Services, WV DoHS.
            Vouchers must be redeemed by October 31 of the current program year.
            Clothing cannot be returned for cash (only exchanged).
          </p>
          <hr className="col-xs-12" />

          <h2 className="bold">Utility Assistance/LIEAP</h2>
          <p>
            The Low Income Energy Assistance Program (LIEAP) assists eligible
            households with the cost of home heating through direct cash
            payments or payments to utility companies on their behalf. A crisis
            component is available for households without resources facing the
            loss of a heating source. Eligibility for both program components is
            based on a combination of factors, including income, type of heating
            payment, and total heating costs.{' '}
          </p>
          <p>
            The Division of Family Assistance (DFA) operates LIEAP in each of
            the DoHS field offices for a short time each winter, usually
            beginning with a mail-out to targeted households. In addition, DFA
            contracts with the Community Action network and with regional Agency
            on Aging offices to perform outreach and accept LIEAP applications
            at their local sites.
          </p>
          <hr className="col-xs-12" />

          <h2 className="bold">20% Discount Program </h2>
          <p>
            The Special Reduced Rate Service Program (20% Utility Discount
            Program) was established by West Virginia state government to assist
            eligible participants in receiving a 20 percent discount from their
            gas, electric and water companies. If you are a recipient of SNAP
            and over age 60, SSI and over age 18, or WV WORKS, you may be
            eligible.
          </p>
          <hr className="col-xs-12" />

          <h2 className="bold">Child Welfare Services</h2>
          <h3 className="bold">Responsibilities of Child Welfare Services:</h3>
          <ul>
            <li>investigate reports of abuse and neglect of children</li>
            <li>provide services to children and families in their home</li>
            <li>
              place children in foster care if they are unsafe in their own
              homes or the community is not safe for them
            </li>
            <li>
              finding permanent homes for children who cannot return to their
              own homes
            </li>
            <li>
              provide services to children transitioning into adulthood while in
              foster care placement
            </li>
          </ul>

          <h3 className="bold">
            Those who can receive Child Welfare Services:
          </h3>
          <p>Any child ages 0-18 years old who: </p>
          <ul>
            <li>has been suspected of being abused or neglected </li>
            <li>
              is subjected to conditions that are likely to result in abuse or
              neglect
            </li>
            <li>is a youth who committed status offenses</li>
            <li>is a youth who has been adjudicated delinquent</li>
            <li>
              is a youth who has been referred to the Department of Human Services for services or placement
            </li>
          </ul>
          <hr className="col-xs-12" />

          <h2 className="bold">Adult Protective Services (APS)</h2>
          <h3 className="bold">Adult Services </h3>
          <ul>
            <li>Adult Protective Services (APS)</li>
            <li>Preventative Adult Protective Services (PAPS)</li>
            <li>Adult Guardianship</li>
            <li>Health Care Surrogate</li>
            <li>Adult Residential Services</li>
            <li>Homeless Services</li>
          </ul>

          <h3 className="bold">You may be eligible for APS, if you are:</h3>
          <ul>
            <li>under age 18 and are legally emancipated</li>
            <li>
              adults age 18 and older
              <ul>
                <li>
                  Who are functionally disabled due to a mental or physical
                  disability
                </li>
                <li>
                  Living in their own home or a residential facility, such as a
                  nursing home, personal care home, residential board and care
                  home or adult family care home
                </li>
              </ul>
            </li>
          </ul>
          <hr className="col-xs-12" />

          <h2 className="bold">Child Care</h2>
          <p>
            All child care applicants must visit their local Resource and
            Referral agency office in person to complete an application and
            receive child care assistance.{' '}
          </p>
          <p>
            West Virginia’s Child Care program is designed to help low income
            families afford safe and quality child care. Child Care and
            Development Fund (CCDF) is the federal funding source for the
            program. Financial assistance covers some child care costs when
            parents are involved in eligible activities such as work or school.
            Resource and Referral agencies also assist in educating parents on
            how to select child care providers, and refer families to other
            needed services.
          </p>

          <h3 className="bold">
            You may be eligible for Child Care, if your family:
          </h3>
          <ul>
            <li>Resides in West Virginia</li>
            <li>
              Meet income requirements:
              <ul>
                <li>
                  Monthly gross Income, by family size, must fall within the
                  eligibility guidelines{' '}
                </li>
              </ul>
            </li>
            <li>
              Meets full time qualifying activity requirements:
              <ul>
                <li>Must be working, or going to school, or WV Works, etc.</li>
              </ul>
            </li>
          </ul>
          <h3 className="bold">
            In addition to the above family requirements, your child(ren) must:
          </h3>
          <ul>
            <li>Reside with family applying for Child Care</li>
            <li>Be US Citizen or Qualified Alien</li>
            <li>Be under the age of 13 years old, or</li>
            <li>
              Be between 13 to 18 years old – if:
              <ul>
                <li>Under court supervision, or</li>
                <li>Meets the definition of special needs</li>
              </ul>
            </li>
          </ul>
        </div>
      )
    case 'MPAP':
      return (
        <div className="benefit benefit-qhp">
          <h2 className="bold">Medicare Premium Assistance Programs</h2>
          <h4 className="bold">Qualified Medicare Beneficiary (QMB)</h4>
          <p>
            This is Medicaid coverage which pays Medicare Part A and Part B
            premiums and Medicare co-pays and deductibles, including those
            related to nursing facility services. There is no prescription drug
            coverage.{' '}
          </p>

          <h4 className="bold">You may be eligible for QMB, if you have:</h4>
          <ul>
            <li>Income Limit 100% of the Federal Poverty Level</li>
            <li>
              Asset Limit
              <ul>
                <li>$8,400 Individual</li>
                <li>$12,600 Couple</li>
              </ul>
            </li>
          </ul>

          <h3 className="bold">
            Specified Low-Income Medicare Beneficiary (SLIMB)
          </h3>
          <p>
            Medicaid coverage is limited to payment of the Medicare Part B
            premium.
          </p>

          <h4 className="bold">You may be eligible for SLIMB, if you have:</h4>
          <ul>
            <li>Income Limit 120% of the Federal Poverty Level</li>
            <li>
              Asset Limit
              <ul>
                <li>$8,400 Individual</li>
                <li>$12,600 Couple</li>
              </ul>
            </li>
          </ul>

          <h3 className="bold">Qualified Individual (QI)</h3>
          <p>
            Medicaid coverage is limited to payment of the Medicare Part B
            premium. You cannot receive both QI and full Medicaid coverage at
            the same time.
          </p>

          <h4 className="bold">You may be eligible for QI, if you have:</h4>
          <ul>
            <li>Income Limit 135% Federal Poverty Level</li>
            <li>
              Asset Limit
              <ul>
                <li>$8,400 Individual</li>
                <li>$12,600 Couple</li>
              </ul>
            </li>
          </ul>
        </div>
      )
    case 'MLTC':
      return (
        <div className="benefit benefit-qhp">
          <h2 className="bold">Medicaid for Long Term Care</h2>
          <p>
            This is Medicaid coverage which pays for care for persons in a
            nursing home facility, or in Intermediate Care Facilities for
            Individuals with Intellectual Disabilities. Coverage also includes
            other medical expenses not covered by Medicare or other private
            health insurance. The individual must meet certain medical criteria
            in addition to financial requirements.{' '}
          </p>
          <p>
            Eligible individuals with income must contribute to the cost of
            care. Some allowance is permitted for the expenses of a spouse who
            remains in the community. Some assets of the couple are protected
            for the community spouse and are not counted to determine
            eligibility for the individual in the nursing home.{' '}
          </p>

          <ul>
            <li>Income Limit varies based on category of eligibility </li>
            <li>
              Asset Limit
              <ul>
                <li>$2,000 Individual</li>
                <li>$3,000 Couple</li>
              </ul>
            </li>
          </ul>

          <h3 className="bold">
            Medicaid for Home and Community-Based (HCB) Care{' '}
          </h3>

          <h4 className="bold">Aged and Disabled Waiver </h4>
          <p>
            This Medicaid coverage pays for in home care for aged, blind or
            disabled persons who require the same level of care provided in a
            nursing home, but who chose to receive the care at home. The care
            may be directed by a case management agency or the individual
            himself. The individual must meet certain medical criteria in
            addition to the financial requirements listed below.{' '}
          </p>

          <h4 className="bold">Traumatic Brain Injury Waiver </h4>
          <p>
            This Medicaid coverage pays for in-home care for those with a
            traumatic brain injury who require the same level of care provided
            in a nursing home, but who chose to receive the care at home. The
            individual must meet certain medical criteria in addition to the
            financial requirements listed below.{' '}
          </p>

          <h4 className="bold">
            Intellectual and Developmental Disabilities Waiver{' '}
          </h4>
          <p>
            This Medicaid coverage pays for in-home care for those with
            intellectual or a developmental disability which could be provided
            in a medical or psychiatric hospital or nursing home. The individual
            must meet certain medical criteria in addition to the financial
            requirements listed below.{' '}
          </p>

          <h4 className="bold">
            You may be eligible for an HCB waiver program if you have:
          </h4>
          <ul>
            <li>
              Income Limit 300% of the Maximum SSI Payment for One Person{' '}
            </li>
            <li>
              Asset Limit
              <ul>
                <li>$2,000 Individual</li>
              </ul>
            </li>
          </ul>

          <h5>Additional information is available at:</h5>
          <ul>
            <li>
              <a
                href="https://dhhr.wv.gov/bms/Pages/default.aspx"
                target="_blank"
                className="link-line"
              >
                West Virginia Bureau for Medical Services{' '}
              </a>
            </li>
          </ul>
        </div>
      )
    case 'CHIP':
      return (
        <div className="benefit benefit-chip">
          <h2 className="bold">Children's Health Insurance Program</h2>
          <p>
            West Virginia Children’s Health Insurance Program, WVCHIP, was
            created to help working families who do not have other health
            insurance for their children.
          </p>
          <p>
            You want your kids to be healthy. One good way to keep them healthy
            is making sure they get medical care. Children who get good medical
            care have a better chance of being healthy and doing well in school.
          </p>

          <h4 className="bold">Services covered by WVCHIP:</h4>
          <ul>
            <li>Doctor visits</li>
            <li>Check-ups</li>
            <li>Hospital visits</li>
            <li>Immunizations (Shots)</li>
            <li>Prescriptions</li>
            <li>Tests and X-rays</li>
            <li>Dental care</li>
            <li>Vision care</li>
            <li>Emergency care</li>
            <li>Mental Health</li>
            <li>Diabetic supplies</li>
            <li>Urgent Care or After Hour Clinic Visits</li>
            <li>Case Management for Special Needs</li>
            <li>And more…</li>
          </ul>
          <h4 className="bold">Your children may be eligible if they:</h4>
          <ul>
            <li>Reside in West Virginia</li>
            <li>Are under age 19</li>
            <li>Are not eligible for West Virginia Medicaid</li>
            <li>
              Are United States citizens or qualified immigrants (Children who
              are not U.S. citizens must provide verification of their
              immigration status.)
            </li>
            <li>
              Children who are members of federally recognized American Indian
              or Native Alaskan tribes are not required to pay co-payments or
              premiums once enrolled in the West Virginia Children’s Health
              Insurance Program.
            </li>
            <li>
              WVCHIP income guidelines are based on family size. Family size
              means the number of individuals you have claimed on your federal
              tax return.
            </li>
          </ul>
          <h5>Additional information is available at:</h5>
          <ul>
            <li>
              <a
                href="https://chip.wv.gov/Pages/default.aspx"
                target="_blank"
                className="link-line"
              >
                West Virginia Children’s Health Insurance Program
              </a>
            </li>
          </ul>
        </div>
      )

    case 'CSS':
      return (
        <div className="benefit benefit-chip">
          <p>Child Support services include but are not limited to:</p>
          <ul>
            <li>locating the parents(s)</li>
            <li>voluntary paternity acknowledgments </li>
            <li>
              establishing court orders for paternity, child support and medical
              support
            </li>
            <li>electronic payment collection and distribution </li>
            <li>enforcement remedies as defined in West Virginia Code</li>
            <li>interstate actions if a parent lives out of state</li>
          </ul>

          <p>
            An online application is available to anyone who wishes to apply for
            services for a child(ren) in their care or for a parent who may pay
            support. There is no fee for Child Support services. Paper
            applications are also available for printing and at your local Child
            Support office.
          </p>
          <p>
            Should you have questions about Child Support services you may
            contact the BCSE Customer Service Unit at 1-800-249-3778.
          </p>
        </div>
      )
  }
}

class BenefitBlock extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      open: this.props.open
    }
  }

  _getPreInformation = (key: string) => {
    let title: string, summary: string, icon: JSX.Element

    switch (key) {
      case 'MEDI':
        title = 'Medicaid'
        icon = <span className="glyphicon myglyphicon-heart" />
        summary =
          'Medicaid provides payment for health care services on behalf of eligible low-income persons and individuals with limited income and high medical expenses.'
        break
      case 'SNAP':
        title = 'Supplemental Nutrition Assistance Program'
        icon = <span className="glyphicon myglyphicon-apple" />
        summary =
          'The Supplemental Nutrition Assistance Program (SNAP) provides benefits to low-income individuals and families and provides economic benefits to communities.'
        break
      case 'TANF':
        title = 'Temporary Assistance for Needy Families'
        icon = (
          <span
            className="glyphicon myglyphicon-parents"
            style={{ marginLeft: '10px' }}
          />
        )
        summary =
          'Temporary Assistance for Needy Families (TANF) is designed to help obtain and maintain jobs. This program provides assistance to families with children and requires adults to work.'
        break
      case 'QHP':
        title = 'Qualified Health Plan'
        icon = <span className="glyphicon myglyphicon-heart" />
        summary =
          'If you qualify, you can enroll in a Marketplace health plan outside the annual open enrollment period.'
        break
      case 'CHIP':
        title = "Children's Health Insurance Program"
        icon = <span className="glyphicon myglyphicon-heart" />
        summary =
          "The state sponsored program offers Child Health Plus as a health insurance plan for kids. Depending on your family's income, your child may be eligible for benefits."
        break
      default:
        title = 'No MyFavorites Found'
        icon = <span />
        summary = 'No MyFavorites Found'
    }

    return { title, icon, summary }
  }

  _favoriteToggleBtn = key => {
    const myFavorites = this.props.myFavorites ? this.props.myFavorites : []

    if (myFavorites.indexOf(key) > -1) {
      return (
        <button
          className="btn link my-benefit active"
          onClick={() => {
            this.props.removeMyFavorite(key)
          }}
        >
          Remove Favorite
        </button>
      )
    } else {
      return (
        <button
          className="btn link my-benefit"
          onClick={() => {
            this.props.addMyFavorite(key)
          }}
        >
          Add Favorite
        </button>
      )
    }
  }

  _expandButton = () => {
    if (!this.state.open)
      return (
        <button
          className="btn link read-more"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          Read More
        </button>
      )
    else
      return (
        <button
          className="btn link read-more active"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          Show Less
        </button>
      )
  }

  render() {
    const benefitKey = this.props.benefitKey

    const { title, icon, summary } = this._getPreInformation(benefitKey)

    let favoritesToggle
    if (this.props.enableFavorites)
      favoritesToggle = this._favoriteToggleBtn(benefitKey)
    else favoritesToggle = <div />

    return (
      <div className="my-benefits-block">
        <h3 className="title">
          {title}
          {icon}
        </h3>
        {favoritesToggle}
        <hr />
        <p>{summary}</p>
        {this._expandButton()}
        <Panel collapsible expanded={this.state.open}>
          {BenefitContent(benefitKey)}
          <button className="btn link scroll-top" onClick={scrollToTop}>
            Back To Top
          </button>
        </Panel>
      </div>
    )
  }
}

class BenefitsView extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    let content = []
    if (this.props.benefits.length >= 1) {
      for (let i = 0; i < this.props.benefits.length; i++) {
        content.push(
          <BenefitBlock
            key={v4()}
            benefitKey={this.props.benefits[i]}
            open={this.props.expanded}
            myFavorites={this.props.myFavorites}
            addMyFavorite={this.props.addMyFavorite}
            removeMyFavorite={this.props.removeMyFavorite}
            enableFavorites={this.props.enableFavorites}
          />
        )
      }
    } else {
      content.push(<h3 key={v4()}>No Benefits Available</h3>)
    }

    return <div>{content}</div>
  }
}

function mapStateToProps(state, ownProps) {
  return {
    myFavorites: state.myFavorites
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addMyFavorite: key => {
      dispatch(actions.addMyFavorite(key))
    },
    removeMyFavorite: key => {
      dispatch(actions.removeMyFavorite(key))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsView)
