import * as React from 'react'
import { isEqual, get, includes } from 'lodash'
import * as actions from '../../../actions'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { config } from '../../../config'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { I18n } from 'react-redux-i18n'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'

const _ = { isEqual, get, includes }

declare var process
const baseUrl = process.env.PUBLIC_URL

interface HomeContainerProps {
  presentation: any
  isLoggedIn: boolean
  location: {
    search: string
    pathname: string
  }
  history: {
    push: (url: string) => void
  }
  selectedEntitlements: Array<string>
  loginUser: (code: string, checkHasPin: boolean) => void
  locale: string
}

interface HomeContainerState {
  redirect: string
  carouselFocus: boolean
}

class HomeContainer extends React.Component<
  HomeContainerProps,
  HomeContainerState
> {
  constructor(props: HomeContainerProps) {
    super(props)

    this.state = {
      redirect: null,
      carouselFocus: false
    }
  }

  componentDidMount() {
    this._acceptLogin()
    //Clean up the URL
    if (this.props.history) {
      this.props.history.push(baseUrl + '/')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.isLoggedIn && this.props.isLoggedIn) ||
      !_.isEqual(
        _.get(prevProps, 'userAccess.selectedUserRole.userRoleId'),
        _.get(this.props, 'userAccess.selectedUserRole.userRoleId')
      )
    ) {
      //They have just logged in, or their selected role changed. Check for post-role selection functionality.
      if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
        )
      ) {
        //PE workers are redirected to My Dashboard page.
        this.setState({
          redirect: baseUrl + '/myaccount/dashboard'
        })
      } else if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.COMMUNITY_PARTNERSHIP
        )
      ) {
        //Community Partner workers are redirected to My Dashboard page.
        this.setState({
          redirect: baseUrl + '/myaccount/dashboard'
        })
      }
    }
  }

  _acceptLogin = () => {
    try {
      if (this.props.location && this.props.location.search) {
        const query = queryString.parse(this.props.location.search)
        if (query.code) {
          const code = query.code
          this.props.loginUser(
            code,
            _.get(config.tenant, 'externalAuth.checkHasPin', false)
          )
        }
      }
    } catch (error) {
      console.error('Home _acceptLogin failed with error:', error)
      const code = CODES.HOME_ACCEPT_LOGIN
      if (shouldThrow(code)) {
        this.setState(() => {
          if (error instanceof helper.IEServiceError) {
            throw error
          } else {
            throw new ClientPortalException(error, code)
          }
        })
      }
    }
  }

  _onCarouselFocus = () => {
    this.setState({
      carouselFocus: true
    })
  }

  _onCarouselBlur = () => {
    this.setState({
      carouselFocus: false
    })
  }

  render() {
    const { presentation, isLoggedIn, locale } = this.props
    const { redirect, carouselFocus } = this.state
    if (redirect) {
      return <Redirect to={redirect} />
    }
    const presentationProps = {
      isLoggedIn,
      locale,
      carouselFocus,
      onCarouselFocus: this._onCarouselFocus,
      onCarouselBlur: this._onCarouselBlur
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  const isLoggedIn = helper.isLoggedIn(state.auth, config)
  return {
    isLoggedIn,
    userAccess: state.userAccess,
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    locale: state.i18n.locale
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (code, checkHasPin) => {
      dispatch(actions.loginUser(code, checkHasPin))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
