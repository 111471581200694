import * as React from 'react'
import Radio from './RadioWidget'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { includes } from 'lodash'
import ReactHtmlParser from 'react-html-parser'

const _ = {
  includes
}

export default function validateCustomRadioWidgett(props) {
  const newProps = props
  if (typeof props.options.enumOptions !== 'undefined') {
    newProps['options']['enumOptions'] = helper.resolveEnumRefs(
      props.options.enumOptions,
      props.formContext,
      props.id
    )
    if (
      props &&
      props.id &&
      props.options.enumOptions.length > 1 &&
      _.includes(props.id, 'csEnforcementServicesApplication') &&
      _.includes(props.options.enumOptions[1].label, '&') &&
      _.includes(props.options.enumOptions[2].label, '&')
    ) {
      newProps.options.enumOptions[1].label = ReactHtmlParser(
        ReactHtmlParser(props.options.enumOptions[1].label)
      )
      newProps.options.enumOptions[2].label = ReactHtmlParser(
        ReactHtmlParser(props.options.enumOptions[2].label)
      )
    }
  }

  return <Radio {...newProps} />
}
