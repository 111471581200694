import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import AuthorizedRepField from './AuthorizedRepField'
import ReviewDifference from './ReviewDifference'
import ConditionField from './ConditionField'
import ReviewPublicTrackerForm from './ReviewPublicTrackerForm'
import CriWidget from './CriWidget'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'
import '!style-loader!css-loader!../src/customStyles.css'
import * as queryString from 'query-string'
import customWidgets from '../src/components/widgets'
import validateCustomRadioWidget from '../src/components/widgets/CustomRadioWidget'

const Fragment = (React as any).Fragment

function prc2IntroScreen() {
  let stringNames = 'SNAP'
  return (
    <form className="renewben">
      <fieldset>
        <legend style={{ background: '#b7d5e8' }}>
          {' '}
          <h3 className="welcome-benefits-style">
            {' '}
            Welcome to Renew My Benefits{' '}
          </h3>{' '}
        </legend>
        <p>
          There are a few things that you should know before you get started:
        </p>
        <ul>
          <li>
            You can stop and save your application at any point during the
            review process.
          </li>
          <li>
            In this form, we will ask you questions about the people in your
            home, your money and your bills. To answer these questions, it's a
            good idea to have your pay stubs or benefit check stubs with you, as
            well as the bills you pay each month for housing, utilities and
            child care.
          </li>
          <li>All reviews will be electronically signed.</li>
          <li>
            You are completing the SNAP interim contact form. USDA requires this
            form’s completion mid-way through the household’s SNAP
            certification.
          </li>
          <li>
            At any point during this process, you can stop and save. Please
            note, this form must be completed and submitted by the last day of
            the reporting month, or your benefits will stop.{' '}
            <u>
              After this date a new SNAP application and interview will be
              required for you to continue receiving SNAP
            </u>
            . Before stopping your benefits DoHS will send a notification about
            your right to file a fair hearing.
          </li>
          <li>
            On this form, we will ask you questions about the people in your
            home, your money and your bills. To answer these questions, it’s a
            good idea to have your pay stubs or benefit check stubs with you, as
            well as the bills you pay each month for housing, utilities and
            childcare.
          </li>
          <li>
            You are required to verify any changes reported on this form, such
            as if you or a household member’s income has increased or decreased
            by more than $125.
          </li>
          <li>
            If you intentionally give false information or withhold information,
            you will have to pay back your SNAP benefits and may be disqualified
            for SNAP for 12 months, 24 months or permanently. In addition, you
            may be found guilty of fraud. Punishment upon conviction may be a
            fine up to $10,000 and/or jail sentence of 10 years in a state
            correctional facility.
          </li>
          <li>
            I understand that if I fail to report or verify any household
            expense(s) that may entitle my household to an income deduction, I
            will not receive that deduction. This means I may not receive the
            full amount of SNAP benefits for which my household may be eligible.
            I understand that once I report and verify the expense(s) as
            required, I have to right to receive any calculated deduction
            beginning the following month.
          </li>

          {/* END LIST 1 */}
        </ul>
        <ul style={{ listStyle: 'none' }}>
          <li style={{ paddingBottom: '7px' }}>
            In accordance with federal civil rights law and U.S. Department of
            Agriculture (USDA) civil rights regulations and policies, this
            institution is prohibited from discriminating on the basis of race,
            color, national origin, sex (including gender identity and sexual
            orientation), religious creed, disability, age, political beliefs,
            or reprisal or retaliation for prior civil rights activity.
          </li>
          <li style={{ paddingBottom: '7px' }}>
            Program information may be made available in languages other than
            English. Persons with disabilities who require alternative means of
            communication to obtain program information (e.g., Braille, large
            print, audiotape, American Sign Language), should contact the agency
            (state or local) where they applied for benefits. Individuals who
            are deaf, hard of hearing or have speech disabilities may contact
            USDA through the Federal Relay Service at (800) 877-8339.{' '}
          </li>
          <li style={{ paddingBottom: '7px' }}>
            To file a program discrimination complaint, a Complainant should
            complete a Form AD-3027,USDA Program Discrimination Complaint Form
            which can be obtained online at{' '}
            <a
              href="https://www.usda.gov/sites/default/files/documents/ad-3027.pdf"
              className="link-line"
              target="_blank"
            >
              https://www.usda.gov/sites/default/files/documents/ad-3027.pdf
            </a>{' '}
            from any USDA office, by calling (833) 620-1071, or by writing a
            letter addressed to USDA. The letter must contain the complainant's
            name, address, telephone number, and a written description of the
            alleged discriminatory action in sufficient detail to inform the
            Assistant Secretary for Civil Rights (ASCR) about the nature and
            date of an alleged civil rights violation. The completed AD-3027
            form or letter must be submitted to:
          </li>
          <li className="display-flex">
            <div style={{ paddingRight: '3px' }}>1. mail:</div>
            <div style={{ paddingBottom: '7px' }}>
              <br />
              Food and Nutrition Service, USDA
              <br />
              1320 Braddock Place, Room 334
              <br />
              Alexandria, VA 22314; or
              <br />
            </div>
          </li>
          <li className="display-flex">
            <div style={{ paddingRight: '3px' }}>2. fax:</div>
            <div style={{ paddingBottom: '7px' }}>
              <br />
              (833) 256-1665 or (202) 690-7442; or
              <br />
            </div>
          </li>
          <li className="display-flex">
            <div style={{ paddingRight: '3px' }}>3. email:</div>
            <div style={{ paddingBottom: '7px' }}>
              <br />
              <a
                href="mailto:FNSCIVILRIGHTSCOMPLAINTS@usda.gov"
                className="link-line"
                target="_blank"
              >
                FNSCIVILRIGHTSCOMPLAINTS@usda.gov
              </a>{' '}
              <br />
            </div>
          </li>
          <li style={{ paddingBottom: '7px' }}>
            This institution is an equal opportunity provider.
          </li>
        </ul>

        <ul>
          <li style={{ paddingBottom: '7px' }}>
            You are filing a review for the following benefits:
          </li>
          <li style={{ marginLeft: '20px' }}>{stringNames}</li>
        </ul>
      </fieldset>
    </form>
  )
}
class exemptions extends React.Component<any, any> {
  render() {
    return (
      <div>
        <b>
          Please see exemptions below and continue with the following
          questions...
        </b>
        <ul>
          <li>Age 53 or older</li>
          <li>Pregnant</li>

          <li>
            Regular participant in a drug addiction or alcoholic treatment and
            rehabilitation program
          </li>
          <li>Responsible for the care of an incapacitated individual</li>
          <li>Receiving Unemployment Compensation</li>
          <li>
            Hired at least 30 hours per week or for work paying the equivalent
            of at least 30 hours times the minimum wage per week
          </li>
          <li>Medically certified as unfit for work</li>
          <li>
            Enrolled at least half time in a recognized school, training program
            or an institution of higher education
          </li>
          <li>Experiencing homelessness</li>
          <li>Aged out of foster care and not yet 25 years old</li>
          <li>Is a veteran of any United States military branch</li>
        </ul>
      </div>
    )
  }
}

function ReviewInfoWidget(props) {
  return props.formContext.isRenewal ? (
    <p>
      You can review the information provided on this renewal and modify your
      answers before submission. You have obtained sufficient information from
      all individuals for whom you are submitting this renewal and that you are
      providing information about (or if applicable, from their parents or
      legally authorized representatives) to act responsibility or provide
      accurate information in completing the renewal and other related
      eligibility documents and forms; You have informed, or will inform as soon
      as possible, all adults in your household and the parent or legal guardian
      of any minor who is not your child about their rights and responsibilities
      as set forth in this renewal; and You are either; Over eighteen years of
      age; or younger than eighteen years of age and renewing on behalf of
      yourself and/or your minor child.
    </p>
  ) : (
    <p>
      Please review the life events you provided for benefit application below.
      Click on the edit link for each section to change your information
    </p>
  )
}

class DHHRInsruction extends React.Component<any, any> {
  componentDidMount() {
    const reneCovInd = _.get(
      this.props.formContext,
      'formData.Application.reneCovInd'
    )
    if (reneCovInd == 0) {
      let noElement = document.getElementById('useTextYesNo_1') as any
      if (noElement) {
        noElement.checked = true
      }
    } else if (reneCovInd > 0) {
      let yesElement = document.getElementById('useTextYesNo_0') as any
      let yesElements = document.getElementsByName('useTextYears') as any
      if (yesElements) {
        for (let i = 0; i < yesElements.length; i++) {
          yesElement.checked = true
          yesElements[reneCovInd - 1].checked = true
          yesElements[i].disabled = false
        }
      }
    }
  }

  render() {
    return (
      <div>
        <p>
          Below you must choose if you want to allow DoHS to use income data
          from electronic sources to automatically renew your Medicaid or WVCHIP
          coverage. <br />
          If you choose “YES”, DoHS will use income data from electronic sources
          to verify that the income you reported is still the same in future
          years. You can opt out at any time by notifying your local DoHS
          office.
          <br />
          If you choose “NO”, DoHS will send you a review form each year which
          you must fill out and return so we can determine if you are still
          eligible for benefits.
        </p>
        <div className="years-padding-style">
          <input
            id="useTextYesNo_0"
            type="radio"
            name="useTextYesNo"
            value="Yes"
            onClick={() => {
              let yesElements = document.getElementsByName(
                'useTextYears'
              ) as any
              for (let i = 0; i < yesElements.length; i++) {
                yesElements[i].disabled = false
              }
              this.props.formContext.setReneCovInd(null)
            }}
          />{' '}
          <label
            className="appIntakeReview"
            htmlFor="useTextYesNo_0"
            id="useTextYesNo_0_label"
          >
            Yes, use tax information from electronic data sources to renew my
            Medicaid and/or WVCHIP automatically for the next:
          </label>
          <div role="group" aria-labelledby="useTextYesNo_0_label">
            <ul style={{ listStyleType: 'none' }}>
              <li>
                <input
                  id="useTextYears_1"
                  type="radio"
                  name="useTextYears"
                  value="1Year"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(1)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_1">
                  1 year
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_2"
                  type="radio"
                  name="useTextYears"
                  value="2Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(2)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_2">
                  2 years
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_3"
                  type="radio"
                  name="useTextYears"
                  value="3Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(3)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_3">
                  3 years
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_4"
                  type="radio"
                  name="useTextYears"
                  value="4Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(4)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_4">
                  4 years
                </label>
              </li>
              <li>
                <input
                  id="useTextYears_5"
                  type="radio"
                  name="useTextYears"
                  value="5Years"
                  disabled={true}
                  onChange={() => {
                    this.props.formContext.setReneCovInd(5)
                  }}
                />{' '}
                <label className="appIntakeReview" htmlFor="useTextYears_5">
                  5 years
                </label>
              </li>
            </ul>
          </div>
          <input
            id="useTextYesNo_1"
            type="radio"
            name="useTextYesNo"
            value="No"
            onClick={() => {
              let yesElements = document.getElementsByName(
                'useTextYears'
              ) as any
              for (let i = 0; i < yesElements.length; i++) {
                yesElements[i].checked = false
                yesElements[i].disabled = true
              }
              this.props.formContext.setReneCovInd(0)
            }}
          />{' '}
          <label
            className="appIntakeReview"
            htmlFor="useTextYesNo_1"
            id="useTextYesNo_1_label"
          >
            No, do not use tax information from electronic data sources to renew
            my Medicaid and/or WVCHIP automatically.
          </label>
        </div>
      </div>
    )
  }
}

function getQueryParamFromUrl(field) {
  let url = window.location.href
  var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i')
  var string = reg.exec(url)

  return string ? decodeURIComponent(string[1]) : null
}

function IntroductionWidget(props) {
  let prgNames = getQueryParamFromUrl('prgNames')
  let prgNames1 = []
  if (prgNames) {
    prgNames1 = prgNames.split(',')
  }
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  if (prgNames1) {
    var uniqPrngNames = prgNames1.filter(onlyUnique)
    uniqPrngNames.map(function(currentObj, index, arr) {
      currentObj = '  ' + currentObj
    })
  }

  let stringNames = ''
  let prgNames3 = []

  let prgNames2 = props.formContext.prgNames
  if (prgNames2) {
    prgNames3 = prgNames2.search(',') > 0 ? prgNames2.split(',') : [prgNames2]
  }
  function onlyUnique1(value, index, self) {
    return self.indexOf(value) === index
  }

  if (prgNames3) {
    var uniqPrngNames1 = prgNames3.filter(onlyUnique1)
    uniqPrngNames1.map(function(currentObj, index, arr) {
      currentObj = '  ' + currentObj
    })
  }

  if (!prgNames) {
    stringNames = uniqPrngNames1.join(', ')
  } else if (prgNames) {
    stringNames = uniqPrngNames.join(', ')
  }
  return props.formContext.isRenewal ? (
    <form className="renewben">
      <fieldset>
        <legend style={{ background: '#b7d5e8' }}>
          {' '}
          <h3 className="welcome-benefits-style">
            {' '}
            Welcome to Renew My Benefits{' '}
          </h3>{' '}
        </legend>
        <p>
          There are a few things that you should know before you get started:
        </p>
        <ul>
          <li>
            You can stop and save your application at any point during the
            review process.
          </li>
          <li>
            In this form, we will ask you questions about the people in your
            home, your money and your bills. To answer these questions, it's a
            good idea to have your pay stubs or benefit check stubs with you, as
            well as the bills you pay each month for housing, utilities and
            child care.
          </li>
          <li>All reviews will be electronically signed.</li>
          <li>
            You are completing the SNAP interim contact form. USDA requires this
            form’s completion mid-way through the household’s SNAP
            certification.
          </li>
          <li>
            At any point during this process, you can stop and save. Please
            note, this form must be completed and submitted by the last day of
            the reporting month, or your benefits will stop.{' '}
            <u>
              After this date a new SNAP application and interview will be
              required for you to continue receiving SNAP
            </u>
            . Before stopping your benefits DoHS will send a notification about
            your right to file a fair hearing.
          </li>
          <li>
            On this form, we will ask you questions about the people in your
            home, your money and your bills. To answer these questions, it’s a
            good idea to have your pay stubs or benefit check stubs with you, as
            well as the bills you pay each month for housing, utilities and
            childcare.
          </li>
          <li>
            You are required to verify any changes reported on this form, such
            as if you or a household member’s income has increased or decreased
            by more than $125.
          </li>
          <li>
            If you intentionally give false information or withhold information,
            you will have to pay back your SNAP benefits and may be disqualified
            for SNAP for 12 months, 24 months or permanently. In addition, you
            may be found guilty of fraud. Punishment upon conviction may be a
            fine up to $10,000 and/or jail sentence of 10 years in a state
            correctional facility.
          </li>
          <li>
            I understand that if I fail to report or verify any household
            expense(s) that may entitle my household to an income deduction, I
            will not receive that deduction. This means I may not receive the
            full amount of SNAP benefits for which my household may be eligible.
            I understand that once I report and verify the expense(s) as
            required, I have to right to receive any calculated deduction
            beginning the following month.
          </li>

          {/* END LIST 1 */}
        </ul>
        <ul style={{ listStyle: 'none' }}>
          <li style={{ paddingBottom: '7px' }}>
            In accordance with federal civil rights law and U.S. Department of
            Agriculture (USDA) civil rights regulations and policies, this
            institution is prohibited from discriminating on the basis of race,
            color, national origin, sex (including gender identity and sexual
            orientation), religious creed, disability, age, political beliefs,
            or reprisal or retaliation for prior civil rights activity.
          </li>
          <li style={{ paddingBottom: '7px' }}>
            Program information may be made available in languages other than
            English. Persons with disabilities who require alternative means of
            communication to obtain program information (e.g., Braille, large
            print, audiotape, American Sign Language), should contact the agency
            (state or local) where they applied for benefits. Individuals who
            are deaf, hard of hearing or have speech disabilities may contact
            USDA through the Federal Relay Service at (800) 877-8339.{' '}
          </li>
          <li style={{ paddingBottom: '7px' }}>
            To file a program discrimination complaint, a Complainant should
            complete a Form AD-3027,USDA Program Discrimination Complaint Form
            which can be obtained online at{' '}
            <a
              href="https://www.usda.gov/sites/default/files/documents/ad-3027.pdf"
              className="link-line"
              target="_blank"
            >
              https://www.usda.gov/sites/default/files/documents/ad-3027.pdf
            </a>{' '}
            from any USDA office, by calling (833) 620-1071, or by writing a
            letter addressed to USDA. The letter must contain the complainant's
            name, address, telephone number, and a written description of the
            alleged discriminatory action in sufficient detail to inform the
            Assistant Secretary for Civil Rights (ASCR) about the nature and
            date of an alleged civil rights violation. The completed AD-3027
            form or letter must be submitted to:
          </li>
          <li className="display-flex">
            <div style={{ paddingRight: '3px' }}>1. mail:</div>
            <div style={{ paddingBottom: '7px' }}>
              <br />
              Food and Nutrition Service, USDA
              <br />
              1320 Braddock Place, Room 334
              <br />
              Alexandria, VA 22314; or
              <br />
            </div>
          </li>
          <li className="display-flex">
            <div style={{ paddingRight: '3px' }}>2. fax:</div>
            <div style={{ paddingBottom: '7px' }}>
              <br />
              (833) 256-1665 or (202) 690-7442; or
              <br />
            </div>
          </li>
          <li className="display-flex">
            <div style={{ paddingRight: '3px' }}>3. email:</div>
            <div style={{ paddingBottom: '7px' }}>
              <br />
              <a
                href="mailto:FNSCIVILRIGHTSCOMPLAINTS@usda.gov"
                className="link-line"
                target="_blank"
              >
                FNSCIVILRIGHTSCOMPLAINTS@usda.gov
              </a>{' '}
              <br />
            </div>
          </li>
          <li style={{ paddingBottom: '7px' }}>
            This institution is an equal opportunity provider.
          </li>
        </ul>

        <ul>
          <li style={{ paddingBottom: '7px' }}>
            You are filing a review for the following benefits:
          </li>
          <li style={{ marginLeft: '20px' }}>{stringNames}</li>
        </ul>
      </fieldset>
    </form>
  ) : (
    <div>
      <p>
        The information shown is the current information we have about the
        household members on your case. You can report a change in life events
        for yourself and anyone else in your household.
      </p>
      <ul className="intro-align">
        <li>
          If you need to edit the information, update the previously provided
          information.
        </li>
        <li>
          If you need to add Information, click the Add button of that section.
        </li>
        <li>
          If you need to remove information, click on Remove button of that
          section.
        </li>
      </ul>
      <p>
        Review to confirm that all information is correct and it must be
        submitted. Your worker will review the life events reported and contact
        you if additional information is needed.
      </p>
    </div>
  )
}

function RegisterWidget() {
  return (
    <Fragment>
      <a
        href="https://ovr.sos.wv.gov/Register/Landing"
        target="_blank"
        className="link-line"
      >
        Click here to register to vote
      </a>
    </Fragment>
  )
}

function RnrWidget(props) {
  let prgNames = props.formContext.prgNames
  return (
    <div>
      <h2 className="aIHeader rights-font">Rights & Responsibilities</h2>
      <div className="aIDiv print1 " id="pr">
        <h3 className="aIh5 ">
          For All Programs
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr')
            }}
          >
            {' '}
            <span className="fa fa-print btn-icon-sm" />
            Print
          </button>
        </h3>
        <ul>
          <li>
            <strong>I understand</strong> that any information given is subject
            to verification by an authorized representative of DoHS.
          </li>
          <li>
            <strong>I understand</strong> that providing my Social Security
            Number (SSN) to DoHS is mandatory and is required by federal law.
            The only use of the SSN is in the administration of the Medicaid, WV
            WORKS and/or SNAP Programs, with no disclosure or use of the SSN for
            any other purpose. I further understand that an SSN is required only
            for those people who apply for and/or receive benefits and not for
            any other person.
          </li>
          <li>
            <strong>I understand</strong> for all programs that all persons
            included in the benefit must provide a Social Security Number (SSN).
            The SSN will be used to check the identity of household members,
            prevent duplicate participation and to make mass changes. It will
            also be used in computer matching and program reviews or audits to
            make sure my household is eligible for the benefits received. Any
            fraudulent acts discovered may result in criminal or civil action or
            administrative claims against any person found to have committed
            such acts. <strong>SNAP only:</strong> This information may be
            disclosed to other federal and state agencies for official
            examination and to law enforcement officials for the purpose of
            apprehending persons fleeing to avoid the law.
          </li>
          <li>
            <strong>I hereby consent</strong> to be referred to the Social
            Security Administration to be issued a Social Security Number (SSN)
            and to have my SSN released only for the purposes described above.
          </li>
          <li>
            <strong>I understand</strong> that DoHS may obtain income and
            eligibility information from the Social Security Administration,
            Internal Revenue Service, Department of Homeland Security, a
            consumer reporting agency, the Division of Motor Vehicles, Veteran's
            Administration, Workers' Compensation carriers, Bureau of Employment
            Programs, DoHS's Bureau for Child Support Enforcement, DoHS's Bureau
            for Public Health - Division of Vital Statistics and Office of
            Maternal, Child and Family Health, DoHS's Office of Inspector
            General, DoHS's Bureau for Medical Services, Division of
            Rehabilitation Services and Immigration and Naturalization Service
            about each member of my group. This information will be obtained by
            the use of the Social Security Number of each recipient.
          </li>
          <li>
            <strong>I understand</strong> it is an eligibility requirement to
            cooperate with the Quality Control Reviewer in any review of my
            benefits. This may require a home visit by the Reviewer and include
            additional verification of my situation, but I also understand that
            I am not required to permit the Quality Control Reviewer to enter my
            home. <strong> For WV WORKS Benefit Only:</strong> this requirement
            does not apply. Other benefits received in addition to WV WORKS may
            be reviewed and I must cooperate with the Quality Control Reviewer
            on these.
          </li>
          <li>
            <strong>I understand</strong> that I may receive information and a
            referral to receive Family Planning Services upon request.
          </li>
          <li>
            <strong>I understand</strong> that I may receive information and a
            referral for Domestic Violence services upon request.
          </li>
          <li>
            <strong>I agree</strong> to notify DoHS of the following changes
            within <strong>10 days</strong> if:
            <ol list-style-type="A">
              <li>
                We move and/or change our address, name, or telephone number;
              </li>
              <li>
                There are changes in my shelter costs because I have moved;
              </li>
              <li>Anyone obtains/loses employment;</li>
              <li>
                There are changes in my household’s amount or source of unearned
                income;
              </li>
              <li>
                There are changes in my household’s amount or source of earned
                income or number of hours worked;
              </li>
              <li>Anyone moves into/out of my household;</li>
              <li>
                Any individual in my home starts, finishes or drops out of
                school or job training;
              </li>
              <li>
                There are changes in my household’s assets, including receiving,
                selling, purchasing, or losing a vehicle, including recreational
                vehicles and equipment; or
              </li>
              <li>
                Anyone in my household receives a lump sum payment because this
                may affect our eligibility for continuing benefits, and I may be
                expected to live on this income for a specific period of time.
              </li>
            </ol>
          </li>
          <li>
            <strong>For SNAP Benefits Only:</strong> these requirements do not
            apply. My reporting requirements were explained in the SNAP program
            section.
          </li>
          <li>
            <strong>I understand</strong> that failure to provide this
            information may result in a penalty or sanction.
          </li>
          <li>
            <strong>I understand</strong> if I am not satisfied with any action
            taken on my case or I feel I have been treated unfairly because of
            my race, color, national origin, sex, religious creed, age,
            disability, political beliefs, or retaliation, I can ask for a Fair
            Hearing orally or in writing. <strong>I understand</strong> that
            anyone may attend the Fair Hearing but, if I choose to have a lawyer
            attend, the Department will not pay the lawyer's fee. I also may
            complete a civil rights complaint form, IG-CR-3, at my local DoHS
            office.
          </li>
          <li>
            <strong>I understand</strong> that appointments/meetings with my
            Worker may include scheduled/ unscheduled home visits, but{' '}
            <strong> I also understand</strong> that I am not required to permit
            the DoHS Worker to enter my home.
          </li>
          <li>
            <strong>I understand</strong> that I may be qualified to apply for
            low-priced telephone services called America and
            Tel-Assistance/Lifeline that the telephone company in my area
            offers. <strong>I give permission</strong> to DoHS to release
            information to the telephone company concerning my eligibility for
            this service. If my eligibility for DoHS programs is stopped,{' '}
            <strong> I understand</strong> DoHS will notify the telephone
            company.
          </li>
          <li>
            <strong>I give my permission</strong> to DoHS to refer my family to
            any agency for needed services.
          </li>
          <li>
            <strong>I give my permission </strong>specifically to the West
            Virginia State Tax and Revenue Department and the Internal Revenue
            Service to release to DoHS any and all information from my personal
            and/or business income tax returns for any and all tax years that
            would have to do with my receiving benefits and which is required by
            federal regulations and/or DoHS policy. This includes filing status,
            dependents, address, income, deductions, and any other pertinent
            information requested by DoHS.
          </li>
          <li>
            <strong>I give my permission </strong> to DoHS to provide
            information contained in my confidential case record, regarding me
            or any member of my family or assistance group, to Immigration and
            Naturalization Services, Social Security Administration, DoHS's
            Bureau for Child Support Enforcement, DoHS's Bureau for Medical
            Services, DoHS's Bureau for Public Health, Division of
            Rehabilitation Services, or any other state or federal
            department/agency/organization primarily for the purpose of
            providing me with access to the services and benefits offered by
            these departments/agencies/organizations in an efficient manner that
            allows for coordination rather that duplication of service(s).
          </li>
          <li>
            <strong>I understand</strong> DoHS does not discriminate on the
            basis of disability in admission to or access to its programs or in
            its operations, services or activities. This notice is available in
            large print, on audio tape, or in Braille from any DoHS office. This
            notice is provided as required by Title II of the Americans with
            Disabilities Act (ADA) of 1990. If I have questions or complaints or
            if I want to talk about whether I have a disability, I may contact
            the Bureau for Family Assistance ADA Coordinator:
            <p style={{ paddingLeft: '30px' }}>
              Bureau for Family Assistance
              <br />
              State BFA ADA Coordinator
              <br />
              350 Capitol Street, Room 730
              <br />
              Charleston, WV 25301
              <br />
              (304) 558-0628
              <br />
              Monday through Friday, 9:00 a.m. to 5:00 p.m.
            </p>
          </li>
          <li>
            <strong>I give my permission </strong>for any of the following
            entities to release any information to DoHS when this information is
            related to my receipt of assistance, including LIEAP.
            <strong>I understand</strong> that only information which is
            required by federal regulations and/or DoHS policy will be requested
            and that it will be used only in determining or redetermining my
            eligibility for assistance or the level of assistance received. The
            entities that may release my information include any financial
            institution; government agency or department; landlords, both
            private and public housing authorities; physicians, including
            psychiatrists, psychologist or other counselor; drug testing
            facilities; hospitals, including psychiatric hospitals; business
            concern/employers; HIV/AIDS testing services or other person(s) with
            related information. This release authorizes schools to provide
            information including, but not limited to, enrollment, attendance,
            address, custodian, and all information related to the receipt of
            public assistance for my child(ren) under my care and custody.
          </li>
          <li>
            <strong>I understand</strong> that my assistance group may be
            required to repay any benefits paid to or on behalf of it for which
            I was not eligible because of unintentional errors made by me or by
            DoHS. <strong>I also understand</strong> that if I give incorrect or
            false information or if I fail to report changes that I am required
            to report, my assistance group may be required to repay any benefits
            I receive and I may also be prosecuted for fraud. Additionally,{' '}
            <strong>I understand</strong> that all adult members of my
            assistance group are equally and separately responsible for an
            overpayment of assistance. <strong>I also understand</strong> that
            any person who obtains or attempts to obtain benefits from DoHS by
            means of a willfully false statement or misrepresentation or by
            impersonation or any other fraudulent device can be charged with
            fraud. Punishment upon a conviction may be a fine up to $10,000
            and/or a jail sentence of 10 years in state correctional facility.{' '}
            <strong>For the SNAP Program Only</strong> - federal penalties may
            include a maximum fine of $250,000 and a jail sentence of up to 20
            years. <strong>For the LIEAP Program Only</strong> - failure to
            repay such benefits may result in loss of future LIEAP benefits.
          </li>
        </ul>
      </div>
    </div>
  )
}

function RnrFDNTWidget() {
  return (
    <div>
      <h2 className="aIHeader rights-font">Food & Nutrition (SNAP)</h2>
      <div className="aIDiv print1 " id="pr1">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr1')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            Print
          </button>
        </p>
        <div>
          <h3 className="aIh5 " id="pr">
            SUPPLEMENTAL NUTRITION ASSISTANCE PROGRAM
          </h3>
          <div className="rnrContentBox">
            <p>
              In accordance with federal civil rights law and U.S. Department of
              Agriculture (USDA) civil rights regulations and policies, this
              institution is prohibited from discriminating on the basis of
              race, color, national origin, sex (including gender identity and
              sexual orientation), religious creed, disability, age, political
              beliefs, or reprisal or retaliation for prior civil rights
              activity.
            </p>
            <p>
              Program information may be made available in languages other than
              English. Persons with disabilities who require alternative means
              of communication to obtain program information (e.g., Braille,
              large print, audiotape, American Sign Language), should contact
              the agency (state or local) where they applied for benefits.
              Individuals who are deaf, hard of hearing or have speech
              disabilities may contact USDA through the Federal Relay Service at
              (800) 877-8339.
            </p>
            <p>
              To file a program discrimination complaint, a Complainant should
              complete a Form AD-3027,
              <i>USDA Program Discrimination Complaint Form</i> which can be
              obtained online at
              <a
                href="https://www.usda.gov/sites/default/files/documents/ad-3027.pdf"
                className="link-line"
              >
                https://www.usda.gov/sites/default/files/documents/ad-3027.pdf
              </a>
              from any USDA office, by calling (833) 620-1071, or by writing a
              letter addressed to USDA. The letter must contain the
              complainant's name, address, telephone number, and a written
              description of the alleged discriminatory action in sufficient
              detail to inform the Assistant Secretary for Civil Rights (ASCR)
              about the nature and date of an alleged civil rights violation.
              The completed AD-3027 form or letter must be submitted to:
            </p>
            <p>
              <ol>
                <li>mail:</li>
                Food and Nutrition Service, USDA
                <br /> 1320 Braddock Place, Room 334
                <br /> Alexandria, VA 22314; or
                <li>fax:</li>
                (833) 256-1665 or (202) 690-7442; or
                <li>email:</li>
                <a
                  href="mailto:FNSCIVILRIGHTSCOMPLAINTS@usda.gov"
                  className="link-line"
                >
                  FNSCIVILRIGHTSCOMPLAINTS@usda.gov
                </a>
              </ol>
            </p>
            <p>This institution is an equal opportunity provider.</p>
          </div>

          <ul>
            <li>
              <strong>I understand</strong> that SNAP benefits are to be used by
              or on behalf of my assistance group and me to purchase food or
              seeds. I cannot sell my SNAP benefits or use someone else's
              benefits for myself. The SNAP benefits will not be used for any
              other purpose. <strong>I understand</strong> that I may not use my
              EBT SNAP benefits to purchase food on credit. This means I cannot
              pay for food already purchased or food to be received in the
              future.
            </li>
            <li>
              <strong>I understand</strong> that I cannot do, or attempt to do
              the following either in public, in private, or online: buy, sell,
              trade, steal or otherwise use SNAP benefits for monetary gain or
              other considerations; purchase food in containers with deposits
              and discard the product to receive cash refund deposits; and
              purchase or sell food originally purchased with SNAP benefits for
              monetary gain or other considerations.{' '}
              <em>These actions are considered SNAP trafficking.</em>
            </li>
            <li>
              <strong>I understand</strong> if any member of my assistance group
              is found (by court action or an administrative disqualification
              hearing) to have committed an act of intentional program violation
              including trafficking, the individual will not receive SNAP
              benefits as follows:{' '}
              <strong>
                <u>First Offense</u>
              </strong>{' '}
              - one year;{' '}
              <strong>
                <u>Second Offense</u>
              </strong>{' '}
              - two years;{' '}
              <strong>
                <u>Third Offense</u>
              </strong>{' '}
              - permanently. In addition, I understand my assistance group will
              have to repay any benefits received for which it was not eligible.
            </li>
            <li>
              <strong>I understand</strong> if I or any individual:
              <ol type="A">
                <li>
                  is found guilty in a federal, state or local court of trading
                  SNAP benefits for firearms, ammunition, explosives or
                  controlled substances; is a convicted felon for possession,
                  use, or distribution of a controlled substance(s); or is found
                  guilty of trafficking $500 or more in SNAP benefits, the
                  guilty party will be{' '}
                  <strong>
                    <u>
                      permanently disqualified from participating in the SNAP
                      Program.
                    </u>
                  </strong>
                </li>
                <li>
                  makes a false statement or misrepresentation of identity
                  and/or residence to receive duplicate benefits at the same
                  time, the responsible party will be{' '}
                  <strong>
                    <u>disqualified from the SNAP Program for 10 years.</u>
                  </strong>
                </li>
                <li>
                  is found guilty of using or receiving benefits in a
                  transaction involving the sale of a controlled substance, the
                  guilty party will not be eligible for benefits for two (2)
                  years for the first offense and permanently for the second
                  offense.
                </li>
              </ol>
            </li>
            <li>
              <strong>I understand</strong> that my SNAP benefits will be
              deposited in an Electronic Benefits Transfer (EBT) account and can
              only be replaced due to food loss or theft due to card
              skimming/cloning/phishing. If I choose an authorized cardholder
              who has access to my EBT account, benefits used by the authorized
              cardholder can only be replaced due to food loss or theft, such as
              card skimming/cloning/phishing.
            </li>
            <li>
              <strong>I understand</strong> that if I do not use the entire SNAP
              benefits deposited in an EBT account for any given month, for a
              period of 274 days from the date of issuance, then that benefit
              only will be removed from my account. I may voluntarily request
              that benefits in my account be used to repay claims established
              against my SNAP account at any time.
            </li>
            <li>
              <strong>I understand</strong> SNAP benefits in an EBT account will
              be removed immediately when it has been determined and verified
              that all certified members of the household are deceased.
            </li>
            <li>
              <strong>I understand</strong> that if I fail to report or verify
              any household expense(s) that may entitle my household to an
              income deduction, I will not receive that deduction. This means I
              may not receive the full amount of SNAP benefits for which my
              household may be eligible. <strong>I understand</strong> that once
              I report and verify the expense(s) as required I have the right to
              receive any calculated deduction beginning the following month.
            </li>
            <li>
              <strong>I understand</strong> that as an Able-Bodied Adult Without
              Dependents (ABAWD) from the age of 18 until the month I turn 53
              who does not live with a child under 18, I may receive SNAP
              benefits for not more than 3 months out of each 36-month period,
              if otherwise eligible, if I do not work at least 20 hours a week
              (averaged monthly), or do not participate in a work program for at
              least 20 hours per week. If I lose eligibility because of this
              issuance limit, I can become eligible again after I work or
              participate in a work program for at least 80 hours in a 30-day
              period. I understand this issuance-limited policy does apply in
              all counties in West Virginia.
            </li>
            <li>
              <strong>I understand</strong> that if I receive SNAP benefits I
              have to report when my total household income exceeds the SNAP
              gross income limit. <strong>I also understand</strong> that I will
              be notified what this amount is and that I must report this to
              DoHS by the 10th of the month after the increase happens.{' '}
              <strong>I also understand</strong> that if my household lives in
              an issuance-limited county and contains an ABAWD, I must report
              when that person's work hours are reduced to less than 20 hours a
              week, averaged monthly. I further understand that I must report
              when any individual within my household wins an amount of money
              greater than or equal to the SNAP maximum allowable asset limit
              for assistance groups containing an older or disabled member
              through a single bet, game of chance, or lottery.
            </li>
            <li>
              <strong>I understand</strong> that unless I am exempt, I must
              comply with work requirements: registering with WorkForce West
              Virginia, providing information about employment status, job
              availability, and training programs.
            </li>
            <li>
              <strong>I understand</strong> that if I refuse or quit employment
              or reduce my work hours to below 30 hours per week without good
              cause I may be penalized.
            </li>
            <li>
              <strong>I understand</strong> that I am authorized to receive
              information and referral services about TANF-funded programs as
              well as other programs offered by the WV Department of Human Services 
              (DoHS) and other organizations in West Virginia.{' '}
              <strong>I understand</strong>
              &nbsp;that this information will be included in every SNAP
              notification letter sent to me.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function RnrHEWLWidget(props) {
  let applicantName
  try {
    let thisApplicant = props.formContext.formData.Application.clients[0]
    if (thisApplicant && thisApplicant.clientFstNm) {
      let name = thisApplicant.clientFstNm
      if (thisApplicant.clientLstNm) {
        name += ' ' + thisApplicant.clientLstNm
      }
      applicantName = name
    } else {
      applicantName = 'Undefined'
    }
  } catch (e) {
    applicantName = 'Undefined'
  }

  return (
    <div>
      <h2 className="aIHeader rights-font">
        Health Care (Medicaid and WVCHIP) and Medicare Premium Assistance
      </h2>
      <div className="aIDiv print1 " id="pr1">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr1')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            Print
          </button>
        </p>
        <div>
          <div className="rnrContentBox">
            <h3 className="aIh5 " id="pr">
              HEALTH COVERAGE PROGRAMS
            </h3>
            <p>
              Federal law prohibits discrimination on the basis of race, color,
              national origin, sex, age, sexual orientation, gender identity or
              disability. A complaint of discrimination may be filed by visiting{' '}
              <a
                href="https://www.hhs.gov/ocr/office/file"
                target="_blank"
                className="link-line"
              >
                <u>www.hhs.gov/ocr/office/file</u>
              </a>{' '}
              or by writing HHS Director, Office of Civil Rights, Room 506-F,
              200 Independence Avenue, S.W., Washington, DC 20201, or call
              202-619-0403 (voice) or 202-619-3257 (TDD). HHS is an equal
              opportunity provider and employer.
            </p>
          </div>

          <ul>
            <li>
              <strong>I understand</strong> that as a recipient of Medicaid, I
              may volunteer for DoHS's Bureau for Child Support Enforcement
              (BCSE) services, including obtaining medical support. These
              services are provided by BCSE at no charge to me.
            </li>
            <li>
              <strong>I understand</strong> I may receive medical assistance for
              my child(ren), including Early Periodic Screening, Diagnosis and
              Treatment (EPSDT).
            </li>
            <li>
              <strong>I understand</strong> that if my income is above the
              Medicaid limits, I may be eligible to receive a medical card if I
              have excess medical bills.<strong> I further understand </strong>{' '}
              that my Worker will advise me of the amount of medical bills I
              have to show and that I have 30 days from the date I apply to
              provide the bills. The bills can be paid or unpaid and can be
              bills for me, my husband/wife, or dependent minor children who
              live with me. My Worker will explain which bills cannot be used
              and why.
            </li>
            <li>
              <strong>I understand</strong> that a period of ineligibility for
              Medicaid long-term care may result if resources were transferred
              within the sixty (60) month period prior to the date of
              application by the applicant or applicant's spouse. This includes
              transfers into certain trusts.
            </li>
            <li>
              <strong>I understand</strong> that I am required to disclose to
              the State any interest my spouse or I have in an annuity.{' '}
              <strong>I understand</strong> the State must be named as the
              remainder beneficiary or as the second remainder beneficiary after
              a spouse or a minor or disabled child, for an amount at least
              equal to the amount of Medicaid benefits provided. Failure to
              comply with these requirements may be considered a transfer of
              resources for less than fair market value and result in
              ineligibility for Medicaid long-term care services.
            </li>
            <li>
              <strong>I understand</strong> that federal and West Virginia law
              mandates the recovery of Medicaid payments made after June 9,
              1995, for nursing care or home and community-based waiver services
              and related hospital and prescription drug services on behalf of
              individuals age 55 or older at the time the payment is made. These
              laws also mandate the recovery of Medicaid paid for nursing care,
              care in an intermediate care facility for an intellectual or
              developmental disability or other medical institutions when an
              individual is determined permanently institutionalized.
              <br />
              The State will not impose a lien or will defer recovery from the
              estate when:
              <ul>
                <li>
                  The individual qualifies for Medicaid under the adult
                  expansion provisions of the Affordable Care Act; or
                </li>
                <li>
                  The individual has a surviving spouse living in the home; or
                </li>
                <li>
                  The individual has a surviving child who is under age 21
                  living in the home; or
                </li>
                <li>
                  The individual has a child living in the home who meets the
                  Social Security Act's definition of blindness or permanent and
                  total disability; or,
                </li>
                <li>
                  The individual's sibling has an equity interest in the home
                  and was residing in the home for a period of at least one year
                  immediately before the date of the individual's admission to a
                  medical institution.
                </li>
              </ul>
              <p>
                The amount of the recovery is the amount Medicaid pays for these
                medical services for the individual.
              </p>
              <p>
                After a proof of claim is filed against the estate, heirs
                affected by Estate Recovery may file a hardship waiver.
              </p>
              <p>
                Estate Recovery is not an eligibility requirement to receive
                Medicaid or payment for the services.
              </p>
            </li>
            <li>
              <strong>I understand</strong> if I am in a nursing home, I must
              notify the local DoHS office within 10 days if:
              <ol type="A">
                <li>
                  I am discharged from a nursing or intermediate care facility
                  to go to another facility or return home.
                </li>
                <li>
                  There are changes in my gross unearned or earned income or the
                  income of my spouse and any dependent children who live with
                  my spouse.
                </li>
                <li>
                  There are changes in my assets or those of my spouse,
                  including receiving, selling, purchasing or giving away
                  assets.
                </li>
              </ol>
            </li>
            <li>
              <strong>I understand</strong> that failure to provide this
              information may result in a penalty or case closure.
            </li>
            <li>
              <strong>I understand</strong> that certain adult Medicaid
              recipients (identified on this application as having a chronic
              substance use disorder; serious and complex medical condition; or
              a physical, behavioral, intellectual, or developmental disorder
              for which assistance is needed) will have the option to choose the
              benefit that best fits their health needs. West Virginia Medicaid
              will provide additional information about selecting a benefit
              package with their eligibility notice by calling 1-877-716-1212.
            </li>
            <li>
              <strong>I understand</strong> it is an eligibility requirement
              that I must cooperate with DoHS and with any provider of medical
              services in pursuing any resource available to meet the medical
              expenses of any Medicaid recipient. <strong>I agree</strong> to
              assign to the DoHS benefits available to any Medicaid recipient
              from any third-party source as a result of injury, accident, or
              illness. <strong>I understand</strong> that the amount payable to
              DoHS will never exceed the amount of the Medicaid liability.{' '}
              <strong> I authorize payment </strong> of any such third-party
              resources directly to DoHS. If the liable third-party makes
              payment directly to me, I agree to refund to DoHS an amount up to,
              but not exceeding, the amount of Medicaid liability.{' '}
              <strong>I understand</strong> that this repayment must be made
              even if my eligibility for Medicaid has stopped prior to my
              receiving such monies. <strong>I further authorize </strong> the
              release of any medical information or any information regarding
              medical insurance to DoHS and also authorize the release of any
              medical insurance information to medical provider(s) for billing
              purposes and the release of medical payment information to
              attorneys and/or insurance companies for the resolution of
              third-party claims.
            </li>
            <li>
              I understand I am signing this application under penalty of
              perjury which means I have provided true answers to all the
              questions on this form to the best of my knowledge. I know that I
              may be subject to penalties under federal law if I provide false
              and or untrue information.
            </li>
            <li>
              I confirm that no one applying for health insurance on this
              application is incarcerated (detained or jailed) or I confirm that{' '}
              <strong>{applicantName}</strong> is incarcerated.
            </li>
            <li>
              I understand by accepting Medicaid under any category, I agree to
              give back to the State any and all money that is received by
              anyone listed on this application from an insurance company for
              repayment of medical and/or hospital bills for which the Medicaid
              Program has or will make payment. In addition, I agree that all
              medical payments or medical support paid or owed due to a court
              order for me or anyone listed on this application must be sent to
              the State to repay past or current medical expenses paid by the
              State. This includes insurance settlements resulting from an
              accident. I further agree to notify the DoHS office if I or anyone
              listed on this application is involved in any accident. I
              understand that this assignment of funds continues as long as I or
              anyone listed on this application received Medicaid.
            </li>
            <li>
              <strong>I understand</strong> to make it easier to determine my
              eligibility for help paying for health coverage in future years,{' '}
              <strong>I agree</strong> to allow the local DoHS office to use
              income data, including information from tax returns. The local
              office will send me a notice, let me make any changes, and I can
              opt out at any time.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function RnrHMENWidget() {
  return (
    <div>
      <h2 className="aIHeader rights-font">Home Energy Assistance (LIEAP)</h2>
      <div className="aIDiv print1 " id="pr1">
        <p className="aIh5 ">
          <button
            type="button"
            className="printStyle print btn btn-tiny btn-white"
            onClick={function() {
              printElem('pr1')
            }}
          >
            <span className="fa fa-print btn-icon-sm" />
            Print
          </button>
        </p>
        <div>
          <ul>
            <li>
              <strong>I understand</strong> that if I knowingly provide false or
              fraudulent information that is used in connection with the
              eligibility determination for LIEAP benefits I may be subject,
              upon conviction, to fines or imprisonment or both.
            </li>
            <li>
              <strong>I understand</strong> that I will be notified, in writing,
              within 30 days from the date of application regarding the decision
              made on my application and that I may request a hearing if I have
              not been notified within 30 days. If I receive a direct payment,{' '}
              <strong> I understand</strong> the payment must be used to pay for
              the cost of primary home heating and that a receipt which verifies
              my payment for the cost of primary home heating must be submitted
              with my application for Emergency LIEAP.{' '}
              <strong> I understand</strong> that if I am found eligible, I am
              entitled to only one regular LIEAP payment and one Emergency LIEAP
              payment during the LIEAP Program year.{' '}
              <strong> I understand</strong> intake for Regular or Emergency
              LIEAP will close without notice.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

/* print  For Right & Responsiblities*/
function printElem(id) {
  let newWin = window.open('', '', 'width=1000,height=800')
  newWin.document.write(
    '<html><head><title></title><style>.content h6 {text-align: center;}.content .centered {  text-align: center;}.bordered h6 {   border: 1px solid black;    padding: 5px 15px;    font-size: 13px!important; } .text-format{list-style-type:upper-alpha;}  .Rights-responsiblities >li{ padding-top:14px;margin-right:3px;} .left-align{ margin-left:4em;}</style></head><body>'
  )
  let prele = document.getElementById(id)
  const printButtonElements = prele.querySelectorAll('.printStyle')
  _.map(printButtonElements, printButton => {
    _.set(printButton, 'hidden', true)
  })
  newWin.document.write(prele.innerHTML)
  newWin.document.close()
  newWin.focus()
  newWin.window.print()
  newWin.close()
}

function PvcyiWidget() {
  return (
    <div
      className="main "
      style={{
        overflow: 'auto',
        height: '540px',
        border: '1px solid black',
        padding: '14px'
      }}
      id="pr1"
    >
      <div className="content centered">
        <h3>Notice of Privacy Practices </h3>
        <button
          type="button"
          className="printStyle print btn btn-tiny btn-white"
          onClick={function() {
            printElem('pr1')
          }}
        >
          {' '}
          <span className="fa fa-print btn-icon-sm" />
          Print
        </button>
        <p className="centered">
          West Virginia Department of Human Services
          <br />
          Bureau For Medical Services
          <br />
          350 Capitol Street, Room 251
          <br />
          Charleston, West Virginia 25301-3709
          <br />
          (304) 558-1700
          <br />
        </p>
      </div>
      <div className="content">
        <strong>Effective date of this notice: 04 / 14 / 2003 </strong>
        <p>
          If you have questions about this notice, please contact Client
          Services at 1-800-642-8589 or the Privacy Officer at the above address
          or phone.{' '}
        </p>
      </div>
      <div className="align notice-align-p">
        <p>
          <br />
          THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
          AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
          REVIEW IT CAREFULLY. <br />
        </p>
      </div>
      <div className="content">
        <h3>PRIVACY AND YOU </h3>
        <p>
          Your health information is personal and private. The Medicaid Program
          must keep your health information private. Your doctors, dentists,
          clinics, labs, and hospitals send information to us when they ask us
          to approve and pay for your health care. We must give you this Notice
          of the law of how we keep your health information private.{' '}
        </p>
      </div>
      <div className="content">
        <h3>CHANGES TO NOTICE OF PRIVACY PRACTICES </h3>
        <p>
          All Medicaid employees, staff, students, volunteers and other
          personnel whose work is under direct control of Medicaid must obey the
          rules in this notice. We have the right to change our privacy
          practices. If we do make changes, we will send a new Notice right away
          to all people covered by Medicaid. We are required to provide this
          notice of our privacy practices and legal duties regarding health
          information to anyone who asks for it.{' '}
        </p>
      </div>
      <div className="content">
        <h3>HOW WE MAY USE AND SHARE YOUR INFORMATION </h3>
        <p>
          The Medicaid program must obey laws on how we use and share your
          information, such as your name, address, personal facts, the medical
          care you had and your medical records. Any information shared must be
          for a reason related to the administration of the Medicaid program.
          Such reasons include:
          <ul className="Pvcy-list">
            <li>To approve eligibility for medical and dental benefits</li>
            <li>To establish ways to pay for health care</li>
            <li>To approve, provide, and pay for Medicaid health care</li>
            <li>To investigate or prosecute Medicaid cases (like fraud)</li>
          </ul>
        </p>
      </div>
      <div className="content">
        <h3>WHY WE MAY USE OR SHARE YOUR HEALTH INFORMATION: </h3>
        <ol className="Pvcy-list" list-style-type="1">
          <li>
            <strong>For treatment:</strong> Medicaid may need to approve care
            before you see a doctor, dentist, clinic or other health care
            provider. We will share information with necessary providers to make
            sure you get the care you need. For instance, we may use your health
            records to identify if you need special information about a health
            problem like diabetes.
          </li>
          <li>
            <strong>For Payment:</strong> When Medicaid pays your health care
            bills, we share information with your health care provider and
            others who bill us for your health care. We may send some bills to
            other health plans or groups who pay bills. For instance, if you are
            taken to an emergency room they may call to see if you are covered.
          </li>
          <li>
            <strong>For health care operations:</strong> We may use your health
            records to check the quality of the health care you get. We may also
            use them in audits, fraud and abuse programs, planning, and managing
            the Medicaid program. For instance, your prescriptions are reviewed
            to be sure the medicines can be used together without harm to you.
          </li>
          <li>
            <strong>For health notices:</strong> We may use your health records
            to provide you with additional information. This may include sending
            appointment reminders to your address, giving you information about
            treatment options, alternative settings for care, or other
            health-related services.
          </li>
          <li>
            <strong>For legal reasons:</strong> We may give your information to
            a court, investigator, or lawyer in cases about Medicaid. This may
            be about fraud or abuse, to get back money from others that should
            pay your Medicaid bills, or other issues related to the Medicaid
            program. If a court orders us to give out your information, we will
            do so.{' '}
          </li>
          <li>
            <strong>To report abuse:</strong> We may disclose your health
            information when the information relates to a victim of abuse,
            neglect, or domestic violence. We will make this report only in
            accordance with laws that require or allow such reporting, or with
            your permission.{' '}
          </li>
          <li>
            <strong>Public Health Activities:</strong> We will disclose your
            health information when required to do so for public health
            purposes. This includes reporting certain diseases, births, deaths,
            and reactions to certain medications. It may also include notifying
            people who have been exposed to a disease.{' '}
          </li>
          <li>
            <strong>Research:</strong> We may disclose your health information
            in connection with medical research projects. Federal rules govern
            any disclosure of your health information for research purposes
            without your permission.{' '}
          </li>
          <li>
            <strong>For appeals:</strong> You or your health care provider may
            appeal Medicaid decisions made about your health care services. Your
            health information may be used to decide these appeals.{' '}
          </li>
          <li>
            <strong>For Eligibility:</strong> We may share your information with
            federal, state, and local agencies when you apply for Medicaid to
            verify eligibility, and for other purposes related to the
            administration of the Medicaid program
          </li>
        </ol>
        <p>
          <strong>I understand </strong> that an electronic signature has the
          same legal effect and can be enforced in the same way as a written
          signature.{' '}
        </p>
      </div>
      <div className="content">
        <h3>WRITTEN PERMISSION </h3>
        <p>
          Medicaid may use or share your information in limited ways. If we want
          to use your health information in a way not listed above, we must get
          your permission in writing. If you give permission, you may take it
          back in writing at any time{' '}
        </p>
      </div>
      <div className="content">
        <h3>WHAT ARE MY PRIVACY RIGHTS? </h3>
        <div className="sublist">
          <strong>You have the right to:</strong>

          <ul className="Pvcy-list">
            <li>
              Ask us to restrict how we use or disclose your health information.
              The request must be in writing. We may not be able to comply with
              your request if we have already used your authorization, if the
              information is needed to pay for your care or if we are required
              by law to disclose it
            </li>
            <li>
              Ask us to communicate with you at a special address or by a
              special means.
            </li>
            <li>
              Look at or get a copy of your Medicaid information. A personal
              representative who has the legal right to act for you may look at
              and get it for you. We have information about your Medicaid
              eligibility, your health care bills, and some medical records. To
              get a copy of your records, ask us to send you a form to fill out.
              We may charge a fee to copy and mail the records. We may keep you
              from seeing parts of your records when allowed by law.
            </li>
            <li>
              Ask to change information in your records if it is not correct or
              complete. We may refuse to change the information if Medicaid did
              not create or keep it, or if it is already correct and complete.
              You may request a review of the denial or send a letter to
              disagree with the denial. This letter will be kept with your
              Medicaid records.
            </li>
            <li>
              Ask us for a report of information shared about you for reasons
              other than treatment, payment, or Medicaid operations. You may ask
              for a list of those with whom we shared your information, when,
              why, and what information was shared. The list will start on April
              14, 2003.{' '}
            </li>
            <li>
              <b>
                {' '}
                Ask us to send your information somewhere. You will be asked to
                sign an authorization form to tell us what information to send
                and where it is to go. The authorization can be used for up to
                one year, but you may tell us a specific time. You may write to
                stop the authorization at any time.
              </b>{' '}
            </li>
            <li>
              Ask for a paper copy of this Notice of Privacy Practices. You can
              also find this Notice on our website at:
              <a
                href="https://dhhr.wv.gov/Pages/default.aspx"
                className="link-line"
              >
                www.wvdhhr.org.
              </a>{' '}
            </li>
          </ul>
        </div>
      </div>
      <div className="align border-address">
        <h3>**** IMPORTANT *****</h3>
        <p>
          <br />
          MEDICAID DOES NOT HAVE FULL COPIES OF YOUR MEDICAL RECORDS. IF YOU
          WANT TO LOOK AT, GET A COPY OF, OR CHANGE YOUR MEDICAL RECORD, PLEASE
          CONTACT YOUR DOCTOR, DENTIST, CLINIC, OR HEALTH PLAN. IF YOU ARE IN A
          MANAGED CARE PLAN, THAT PLAN MAY HAVE INFORMATION ABOUT BILLS PAID FOR
          YOU AFTER YOU JOINED THE PLAN. PLEASE CONTACT THE MANAGED CARE PLAN TO
          LOOK AT OR GET A COPY OF THESE BILLS
        </p>
      </div>
      <div className="content">
        <h3>HOW DO I ASK ABOUT MY PRIVACY RIGHTS? </h3>
        <p>
          If you want to use any of the privacy rights explained in this Notice,
          please call or write us at:{' '}
        </p>
      </div>
      <div className="align border-address">
        <h3>Client Services</h3>
        <address>
          <br />
          West Virginia Department of Human Services
          <br />
          350 Capitol Street
          <br />
          Charleston, West Virginia 25301-3711
          <br />
          Phone (304) 558-2400 or (800) 642-8589 or Fax (304) 558-4501
          <br />
        </address>
      </div>
      <div className="content">
        <h3>HOW DO I COMPLAIN? </h3>
        <p>
          If you think your privacy rights have been violated and wish to
          complain, you may contact :{' '}
        </p>
      </div>
      <div className="align border-address">
        <h3>Privacy Officer</h3>
        <address>
          <br />
          Bureau for Medical Services
          <br />
          350 Capitol Street, Room 251
          <br />
          Charleston, West Virginia 25301-3709
          <br />
          Phone (304) 558-1700 or Fax (304) 5584397 <br />
        </address>
      </div>
      <div className="align border-address">
        <h3> Privacy Officer</h3>
        <address>
          <br />
          West Virginia Department of Human Services
          <br />
          Building 3, Room 206
          <br />
          1900 Kanawha Blvd. East
          <br />
          Charleston, West Virginia 25305
          <br />
          Phone (304) 558-7293 or Fax (304) 558-1130 <br />
        </address>
      </div>
      <div className="align border-address">
        <h3>Secretary of The U. S. Department of Health And Human Services</h3>
        <address>
          <br />
          Office for Civil Rights
          <br />
          Attention Regional Manager
          <br />
          150 So. Independence Mall West, Suite 372
          <br />
          Philadelphia, PA 19106-3499 <br />
        </address>
      </div>

      <div className="content introduction-p-style">
        <h3> NO RETALIATION </h3>
        <p>
          {' '}
          Medicaid cannot take away your health care benefits or retaliate in
          any way if you file a complaint or use any of the privacy rights in
          this Notice.{' '}
        </p>
      </div>
      <div className="content">
        <h3> QUESTIONS </h3>
        <p>
          {' '}
          If you have questions about this notice and want more information,
          please contact the Privacy Official, WVDoHS-BMS, by phone (304)
          558-1700 or by fax at (304) 558-4397.{' '}
        </p>
      </div>

      <p>
        Copies of this notice are also available at local county offices of the
        West Virginia Department of Human Services. This notice is
        also available by e-mail. Contact the Bureau for Medical Services at the
        above location. This notice is also available on our Web site:
        <a
          href="https://dhhr.wv.gov/bms/Pages/default.aspx"
          className="link-line"
        >
          {' '}
          www.wvdhhr.org/bms.
        </a>{' '}
      </p>
    </div>
  )
}

function SsninfoWidget() {
  return (
    <div>
      <p>
        Failure to provide a Social Security Number could result in denial of
        benefits. Social Security Numbers can be provided now or at a later date
        before your benefits are approved.
      </p>
      <br />
      <p>Note : Social Security Numbers are not required for non-applicants.</p>
    </div>
  )
}
function checkingConditionsFull() {
  return (
    <div>
      <ul>
        <li>
          All statements on this form have been read by me or to me and that I
          understand these statements.
        </li>
        <li>I accept the responsibilities stated above.</li>
        <li>
          All information I have provided is true and accurate to the best of my
          knowledge.
        </li>
        <li>I am electronically signing this application.</li>
      </ul>
    </div>
  )
}

function socialSecurityInformationPenaltyWarning() {
  let stringNames = 'SNAP'
  return (
    <form className="renewben">
      <fieldset>
        <legend style={{ background: '#b7d5e8' }}>
          {' '}
          <h3 className="welcome-benefits-style">
            {' '}
            Signature, Social Security Information and Penalty Warning:{' '}
          </h3>{' '}
        </legend>
        <ul style={{ listStyle: 'none' }}>
          <li style={{ paddingBottom: '7px' }}>
            I understand that providing my Social Security Number (SSN) to DoHS
            is mandatory and is required by federal law. The only use of the SSN
            is in the administration of Medicaid, WV WORKS and/or SNAP, with no
            disclosure or use of the SSN for any other purpose. I further
            understand that a SSN is required only for those people who apply
            for and/or receive benefits and not for any other person. I
            understand for all programs that all persons included in the benefit
            must provide a SSN. The SSN will be used to check the identity of
            household members, prevent duplicate participation and to make mass
            changes. It will also be used in computer matching and program
            reviews or audits to make sure my household is eligible for the
            benefits received. Any fraudulent acts discovered may result in
            criminal or civil action or administrative claims against any person
            found to have committed such acts. This information may be disclosed
            to other federal and state agencies for official examination and to
            law enforcement officials for the purpose of apprehending persons
            fleeing to avoid the law. Failure to provide Social Security Numbers
            may result in the closure of my benefit case or a decrease in the
            amount of SNAP benefits. DoHS policy concerning the requirement to
            provide Social Security Numbers can be found in the Income
            Maintenance Manual at Chapter 4.2. FNS regulations regarding the
            requirement and use of Social Security Numbers can be found at 7
            CFR, §273.6.
          </li>
        </ul>
      </fieldset>
    </form>
  )
}

function DHHRDiscriminate() {
  return (
    <form className="renewben">
      <fieldset>
        <ul style={{ listStyle: 'none' }}>
          <li style={{ paddingBottom: '7px' }}>
            DoHS will not discriminate against any individual or group because
            of race, religion, age, national origin, color, height, weight,
            marital status, sex, sexual orientation, gender identity or
            expression, political beliefs or disability. If you need help with
            reading, writing, hearing, etc., under the Americans with
            Disabilities Act, you are invited to make your needs known to a DoHS
            office in your area.
          </li>
        </ul>
      </fieldset>
    </form>
  )
}
export const fields = {
  conditioncoc: ConditionField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  panelGroup: Fields.CollapsiblePanelGroupField,
  tabs: Fields.TabsField,
  carousel: Fields.CarouselField,
  panel: Fields.PanelField,
  tableRow: Fields.TableRowField,
  trackerTable: Fields.TrackerTable,
  mcr: Fields.CodeReferenceField,
  publicTrackerForm: Fields.PublicTrackerForm,
  authorizedRepresentative: AuthorizedRepField,
  reviewDifference: ReviewDifference,
  reviewPublicTrackerForm: ReviewPublicTrackerForm,
  condition: Fields.ConditionalField
}

export const widgets = {
  radio: validateCustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  checkboxes: Widgets.CustomCheckboxesWidget,
  'alt-date': Widgets.DatePickerWidget,
  review: Widgets.ReviewWidget,
  dollar: customWidgets.customDollarWidget,
  span: Widgets.SpanWidget,
  routeLink: Widgets.LinkWidget,
  header: Widgets.HeaderWidget,
  validatedText: Widgets.ValidatedTextWidget,
  phone: Widgets.PhoneWidget,
  ssn: Widgets.SSNWidget,
  cocreviewInfo: Widgets.ReviewWidget,
  DHHRInsruction: DHHRInsruction,
  introduction: IntroductionWidget,
  voterLink: RegisterWidget,
  rnrInfo: RnrWidget,
  checkingConditionsFull: checkingConditionsFull,
  rnrInfoFDNT: RnrFDNTWidget,
  rnrInfoHEWL: RnrHEWLWidget,
  rnrInfoHMEN: RnrHMENWidget,
  cri: CriWidget,
  pvcyi: PvcyiWidget,
  ssnInfo: SsninfoWidget,
  ssn1: Widgets.SSNWidgetV2,
  prc2Introduction: prc2IntroScreen,
  exemptions,
  socialSecurityInformationPenaltyWarning,
  DHHRDiscriminate
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
