import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'

const JSONSchema = {
  title: '',
  description: I18n.t('MyTasks.description'),
  type: 'object',
  properties: {
    verifications: {
      type: 'object',
      title: I18n.t('MyTasks.properties.verifications.title'),
      properties: {}
    },
    bookAppt: {
      type: 'object',
      title: I18n.t('MyTasks.properties.bookAppt.title'),
      properties: {}
    },
    applyForOtherBenefits: {
      type: 'object',
      title: I18n.t('MyTasks.properties.applyForOtherBenefits.title'),
      properties: {}
    },
    setReminder: {
      type: 'object',
      title: I18n.t('MyTasks.properties.setReminder.title'),
      properties: {}
    }
  }
}

const uiSchema = {
  'ui:field': 'accordion',
  verifications: {
    'ui:readonly': true
  },
  bookAppt: {
    'ui:readonly': true
  },
  applyForOtherBenefits: {
    'ui:readonly': true
  },
  setReminder: {
    'ui:readonly': true
  }
}

interface MyTasksContainerProps {
  presentation: any
  locale: string
}

interface MyTasksContainerState {
  schema: any
}

class MyTasksContainer extends React.Component<
  MyTasksContainerProps,
  MyTasksContainerState
> {
  constructor(props: MyTasksContainerProps) {
    super(props)

    this.state = {
      schema: this.applyTranslations()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.locale, this.props.locale)) {
      this.setState({ schema: this.applyTranslations() })
    }
  }

  applyTranslations = () => {
    return {
      title: '',
      description: I18n.t('MyTasks.description'),
      type: 'object',
      properties: {
        verifications: {
          type: 'object',
          title: I18n.t('MyTasks.properties.verifications.title'),
          properties: {}
        },
        bookAppt: {
          type: 'object',
          title: I18n.t('MyTasks.properties.bookAppt.title'),
          properties: {}
        },
        applyForOtherBenefits: {
          type: 'object',
          title: I18n.t('MyTasks.properties.applyForOtherBenefits.title'),
          properties: {}
        },
        setReminder: {
          type: 'object',
          title: I18n.t('MyTasks.properties.setReminder.title'),
          properties: {}
        }
      }
    }
  }

  render() {
    const { presentation, locale } = this.props
    const { schema } = this.state

    const form = {
      schema,
      uiSchema
    }

    const presentationProps = {
      form,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale
  }
}

export default connect(mapStateToProps)(MyTasksContainer)
