import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyTasksContainer = MultitenantLoadable(
  () => import('./MyTasksContainer'),
  () => import('../../' + tenantDirectoryName + '/MyTasks/MyTasksContainer')
)

const MyTasksPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyTasksPresentation'),
    () =>
      import('../../' + tenantDirectoryName + '/MyTasks/MyTasksPresentation')
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyTasks/formComponents')
)

export default function MyTasks(props) {
  return (
    <ErrorBoundary name="MyTasks">
      <MyTasksContainer
        {...props}
        presentation={presentationProps => (
          <MyTasksPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyTasksContainer = MultitenantLoadable(
  () => import('./MyTasksContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyTasksPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyTasksPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyTasks(props) {
  return (
    <BaseErrorBoundary name="MyTasks">
      <BaseMyTasksContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyTasksPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
