import * as _ from 'lodash' // extra functionality
import { combineReducers } from 'redux'
import { includes } from 'lodash'

export default combineReducers({
  myMessagesError
})

function myMessagesError(state = { errorMessage: [], errorList: [] }, action) {
  switch (action.type) {
    case 'UPDATE_MYMESSAGES_Error':
      let newErrorMessage = state.errorMessage || []
      const errorList = state.errorList || []
      let serviceCalling
      if (_.isArray(action.message)) {
        newErrorMessage = action.message
        serviceCalling = false
      } else {
        if (action.message === 'Service Calling') {
          serviceCalling = true
        } else {
          serviceCalling = false
          const failedToFetch = includes(action.message, 'Failed to fetch')
          const errorObject = _.find(errorList, error => {
            if (failedToFetch) {
              return (
                includes(action.message, error.serviceName) &&
                error.ExceptionType === 'SERVICENOTAVAILABLE'
              )
            } else {
              return (
                includes(action.message, error.serviceName) &&
                includes(action.message, error.ExceptionType)
              )
            }
          })
          if (errorObject) {
            newErrorMessage.push(errorObject)
          }
        }
      }
      return {
        ...state,
        errorMessage: newErrorMessage,
        serviceCalling: serviceCalling
      }
    case 'DELETE_MYMESSAGES_Error':
      return {
        ...state,
        errorMessage: []
      }
    case 'UPDATE_ERROR_LIST':
      const newErrorList = action.errorList.errorInfoList || []
      return {
        ...state,
        errorList: newErrorList
      }
    case 'LOGOUT_SUCCESS':
      return { errorMessage: [], errorList: [] }
    default:
      return state
  }
}
