import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { Modal, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { I18n } from 'react-redux-i18n'

const baseUrl = process.env.PUBLIC_URL
interface MyLifeEventsPresentationProps {
  form: {
    schema: Object
    uiSchema: Object
  }
  formContext: any
  formData: any
  onFormDataChange: (formData: any) => void
  isLoading: boolean
  minHeight: string
  reportModal: boolean
  closeModal: () => void
  showReportChange: (formData: any) => void
  startDraft: (formData: any) => void
  deleteDraft: (formData: any) => void
  draftChecked: () => void
  deleteChecked: () => void
  draftButton: boolean
  deleteButton: boolean
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  errorListTemplate: (props: any) => void
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function MyLifeEventsPresentation(
  props: MyLifeEventsPresentationProps
) {
  const {
    form,
    formContext,
    formData,
    onFormDataChange,
    isLoading,
    minHeight,
    formComponents,
    reportModal,
    showReportChange,
    startDraft,
    deleteDraft,
    closeModal,
    draftChecked,
    deleteChecked,
    draftButton,
    deleteButton,
    errorListTemplate,
    errorMessage,
    onDeleteClick
  } = props

  const header = isLoading ? (
    <h3>
      <div id="content" style={{ display: 'table' }} tabIndex={0}>
        <h1 className="title-header auto-table">
          <i className="pe-7s-compass" /> {I18n.t('MyChanges.pageTitle')}
        </h1>
        <i className="fa fa-spinner fa-spin float-right auto-table" />
      </div>
    </h3>
  ) : (
    <h1 className="title-header">
      <i className="pe-7s-compass" /> {I18n.t('MyChanges.pageTitle')}
    </h1>
  )

  if (form && !_.isEmpty(form) && formData) {
    return (
      <div id="content_my_changes" className="form-section" tabIndex={-1}>
        <ErrorModal
          errorMessage={props.errorMessage}
          onDeleteClick={props.onDeleteClick}
        />
        {header}
        {formData.length > 0 ? (
          <Form
            schema={form['schema']}
            uiSchema={form['uiSchema']}
            formData={formData}
            onChange={onFormDataChange}
            formContext={formContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            jsonValidator={errorListTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          >
            <button type="submit" style={{ display: 'none' }} />
          </Form>
        ) : (
          <p> {I18n.t('MyChanges.noActivePrograms')}</p>
        )}

        {formContext.formData != undefined ? (
          <Modal show={reportModal} onHide={closeModal}>
            <Modal.Header>
              <Modal.Title>{I18n.t('MyChanges.reportInProgress')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <fieldset>
                <div>
                  {deleteButton ? (
                    <Alert variant="danger">
                      <i className="fa fa-warning" />
                      {I18n.t('MyChanges.warningMsg')}
                    </Alert>
                  ) : (
                    ''
                  )}
                </div>
                <legend className="change-report-legend-style">
                  <p>{I18n.t('MyChanges.changeInProgress')}</p>
                </legend>
                <div className="abandon-modal-radio-style">
                  <input
                    id="abandon_modal_radio_1"
                    type="radio"
                    value="draft"
                    name="continue"
                    onClick={draftChecked}
                  />{' '}
                  <label
                    className="change-report-label-style"
                    htmlFor="abandon_modal_radio_1"
                  >
                    {I18n.t('MyChanges.continueWorking')}
                  </label>
                </div>
                <div className="abandon-modal-radio-style">
                  <input
                    id="abandon_modal_radio_2"
                    type="radio"
                    value="delete"
                    name="continue"
                    onClick={deleteChecked}
                  />{' '}
                  <label
                    className="change-report-label-style"
                    htmlFor="abandon_modal_radio_2"
                  >
                    {I18n.t('MyChanges.abandonYourExistingChange')}
                  </label>
                </div>
              </fieldset>
            </Modal.Body>
            <Modal.Footer className="co-button">
              {draftButton ? (
                <button
                  type="submit"
                  onClick={() => startDraft(formContext.formData)}
                  className="btn btn-primary margin-left-auto"
                >
                  {I18n.t('MyChanges.continue')}
                </button>
              ) : (
                ''
              )}
              {deleteButton ? (
                <button
                  type="submit"
                  onClick={() => deleteDraft(formContext.formData)}
                  className="btn btn-primary margin-left-auto"
                >
                  {I18n.t('MyChanges.continue')}
                </button>
              ) : (
                ''
              )}
              <button
                type="submit"
                onClick={closeModal}
                className="btn btn-primary"
              >
                {I18n.t('MyChanges.cancel')}
              </button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
      </div>
    )
  }
  return (
    <div>
      <ErrorModal
        errorMessage={props.errorMessage}
        onDeleteClick={props.onDeleteClick}
      />
      <ScreenPreLoader minHeight={minHeight} />
    </div>
  )
}
