import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import '!style-loader!css-loader!./userAccess.css'
import { Modal, Button } from 'react-bootstrap'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import 'react-datepicker/dist/react-datepicker.css'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { config } from '../../../config'
import NewUserModal from './NewUserModal'

interface UserAccessPresentationProps {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  formContext: any
  formData: any
  onFormDataChange: (formData: any) => void
  onUserFormDataChange: (formData: any) => void
  isLoading: boolean
  minHeight: string
  formComponents
  showModal: boolean
  showModalMessage: string
  showModalError: boolean
  hideModal: () => void
  search: (formData: any) => void
  showModalEvent: () => void
  someElement: any
  clear: () => void
  userDetailViewId
  backToUser: () => void
  updateUser: (data: any, event: any) => void
  pendingUserModal: boolean
  hidePendingUserModal: () => void
  selectedPUser: {
    index: any
    user: {
      firstName: string
      lastName: string
      email: string
    }
  }
  removePendingUser: (index: any) => void
  cancelPendingUser: () => void
  userResults: any
  selectedEntitlements: Array<string>
  isUserUpdating: boolean
  transformErrors: () => void
  errorListTemplate: () => void
  liveValidate: boolean
  onValidate: (selectedEntitlements: any) => void
  switchOnLiveValidate: () => void
  onDeleteClick: () => void
  errorMessage: Array<any>
  orgDataList
  currentDate
  addNewUser: (formData: any) => void
  showCallbackModal
  hideCallbackModal: () => void
}

export default function UserAccessPresentation(
  props: UserAccessPresentationProps
) {
  const {
    form,
    formContext,
    formData,
    onFormDataChange,
    onUserFormDataChange,
    isLoading,
    minHeight,
    formComponents,
    showModal,
    hideModal,
    showModalEvent,
    clear,
    userDetailViewId,
    backToUser,
    updateUser,
    pendingUserModal,
    hidePendingUserModal,
    selectedPUser,
    cancelPendingUser,
    removePendingUser,
    userResults,
    selectedEntitlements,
    search,
    showModalMessage,
    showModalError,
    isUserUpdating,
    liveValidate,
    transformErrors,
    errorListTemplate,
    onValidate,
    switchOnLiveValidate,
    orgDataList,
    currentDate,
    addNewUser,
    showCallbackModal,
    hideCallbackModal
  } = props

  const pendingUserss = userResults.pendingUserList || []
  const isCPAdmin = selectedEntitlements.find(entitlement => {
    return entitlement === 'CP Admin'
  })
  const isPEAdmin = selectedEntitlements.find(entitlement => {
    return entitlement === 'PRESUMPTIVE_ELIGIBILITY_MANAGEMENT'
  })
  const isWorkerAdmin = selectedEntitlements.find(entitlement => {
    return entitlement === 'CP_WORKER_ADMIN'
  })

  let userButtonTitle = '',
    headerTitle = '',
    workerAdminContent = null

  if (isCPAdmin) {
    userButtonTitle = 'New CP User'
    headerTitle = 'New Community Partner'
  } else if (isPEAdmin) {
    userButtonTitle = 'New PE User'
    headerTitle = 'New PE User'
  } else if (isWorkerAdmin) {
    userButtonTitle = 'New User'
    headerTitle = isUserUpdating ? '' : 'New User'
  }

  const header = isLoading ? (
    <h2 />
  ) : (
    <div className="userListButtonDiv">
      <span id="userTableTitle">User List</span>
      <button
        className="userListButton btn pe-button-focus"
        type="button"
        onClick={showModalEvent}
      >
        {userButtonTitle}
      </button>
    </div>
  )

  const newModelSchema = {
    title: '',
    description: '',
    type: 'object',
    properties: {
      firstName: {
        type: 'string',
        title: 'First Name'
      },
      lastName: {
        type: 'string',
        title: 'Last Name'
      },
      emailAddress: {
        type: 'string',
        title: 'Email'
      },
      selectedRole: {
        type: 'string',
        title: 'Select Role',
        enum: ['PE Trainee', 'PE Worker'],
        enumNames: ['PE Trainee', 'PE Worker']
      },
      trainingDate: {
        type: 'string',
        title: 'Training Confirmation Date'
      },
      completionDate: {
        type: 'string',
        title: 'Data Agreements Completion Date'
      },
      roles: {
        type: 'string',
        title: 'Role(s)',
        enum: [
          'CP Admin',
          'PE Admin',
          'BH_ADMIN_ASST',
          'BH_HR_SPEC',
          'BH_RES_COOR'
        ],
        enumNames: [
          'Community Partner Admin',
          'Presumptive Eligibility Admin',
          'BH Administrative Assistant',
          'BH Human Resource Specialist',
          'BH Resource Coordinator'
        ]
      },
      orgs: {
        type: 'array',
        title: 'Org(s)',
        items: {
          enum: [...orgDataList]
        },
        uniqueItems: true
      }
    }
  }
  const newModelUiSchema = {
    'ui:field': 'condition',
    fieldClassNames: 'condition-flex',
    firstName: {
      classNames: 'col-lg-6',
      'ui:emptyValue': '',
      'ui:placeholder': 'First Name',
      'ui:options': {
        label: true,
        maxLength: 20,
        allowMatchFormatOnly: true,
        exactPattern: "[^a-zA-Z'.-]",
        nameField: true
      },
      'ui:widget': 'validatedText'
    },
    lastName: {
      classNames: 'col-lg-6',
      'ui:emptyValue': '',
      'ui:placeholder': 'Last Name',
      'ui:options': {
        label: true,
        maxLength: 20,
        allowMatchFormatOnly: true,
        exactPattern: "[^a-zA-Z'.-]",
        nameField: true
      },
      'ui:widget': 'validatedText'
    },
    emailAddress: {
      classNames: 'col-lg-12',
      'ui:placeholder': 'test@mystateservices.com',
      'ui:widget': 'validatedText',
      'ui:options': {
        matchFormat: '^[\\w-]+(?:\\.[\\w-]+)*@(?:[\\w-]+\\.)+[a-zA-Z-]{2,24}$',
        matchError: 'Invalid Email Address',
        maxLength: 67
      }
    },
    selectedRole: {
      classNames: 'col-lg-12',
      'ui:widget': 'select',
      'ui:visibleIf': 'formContext.isPEAdmin'
      //'ui:disableIf': ''
    },
    trainingDate: {
      classNames: 'col-lg-6',
      'ui:widget': 'alt-date',
      'ui:visibleIf':
        'formContext.isCPAdmin || formContext.isWorkerAdmin || (formContext.isPEAdmin && formData.selectedRole === "PE Worker")',
      'ui:disableIf':
        'formData.roles === "BH_ADMIN_ASST" || formData.roles === "BH_HR_SPEC" || formData.roles === "BH_RES_COOR"'
    },
    completionDate: {
      classNames: 'col-lg-6',
      'ui:widget': 'alt-date',
      'ui:visibleIf':
        'formContext.isCPAdmin || formContext.isWorkerAdmin || (formContext.isPEAdmin && formData.selectedRole === "PE Worker")',
      'ui:disableIf':
        'formData.roles === "BH_ADMIN_ASST" || formData.roles === "BH_HR_SPEC" || formData.roles === "BH_RES_COOR"'
    },
    roles: {
      classNames: 'col-lg-6',
      'ui:widget': 'select',
      'ui:options': {
        inline: true
      },
      'ui:visibleIf': 'formContext.isWorkerAdmin'
    },
    orgs: {
      classNames: 'col-lg-6',
      'ui:widget': 'multiSelect',
      'ui:options': {
        inline: true
      },
      'ui:visibleIf': 'formContext.isWorkerAdmin',
      'ui:disableIf':
        'formData.roles === "BH_ADMIN_ASST" || formData.roles === "BH_HR_SPEC" || formData.roles === "BH_RES_COOR"'
    }
  }

  const newModalProps = {
    form: {
      schema: newModelSchema,
      uiSchema: newModelUiSchema
    }
  }

  if (form) {
    if (userDetailViewId) {
      let newRoleName =
        userDetailViewId &&
        userDetailViewId.roleName &&
        userDetailViewId.roleName.includes('-')
          ? userDetailViewId.roleName.split('-')[0].trim()
          : userDetailViewId.roleName
      const userD = [
        {
          userID: userDetailViewId.userID || 1234,
          email: userDetailViewId.email,
          firstName: userDetailViewId.firstName,
          lastName: userDetailViewId.lastName,
          dataAgreementCompletionDate: _moment(
            config,
            userDetailViewId.dataAgreementCompletionDate
          ).format('YYYY-MM-DD'),
          trainingConfirmationDate: _moment(
            config,
            userDetailViewId.trainingConfirmationDate
          ).format('YYYY-MM-DD'),
          status: userDetailViewId.status === 'Active' ? 'No' : 'Yes',
          lastloginDate: '08/29/2018',
          selectRole: userDetailViewId.selectRole,
          currentRole: newRoleName,
          roleName: newRoleName,
          roleId: userDetailViewId.roleId,
          agencyName: userDetailViewId.agencyName
            ? userDetailViewId.agencyName
            : ''
        }
      ]
      const uFormData = {
        Application: {
          usercompleteDetails: userD
        }
      }

      const uFormContext = {
        ...formContext,
        formData: uFormData
      }
      let validatorUserAccess
      if (form.app.forms[3]) {
        validatorUserAccess = new CustomValidator(
          form.app.forms[3].schema,
          form.app.forms[3].uiSchema,
          uFormContext,
          'en'
        )
      }
      return (
        <div className="userDetailsUserTab">
          <ErrorModal
            errorMessage={props.errorMessage}
            onDeleteClick={props.onDeleteClick}
          />
          <div>
            <div className="row">
              <h1>{userDetailViewId.userID || 1234}</h1>
            </div>
            <hr className="lineSeparator" />
          </div>
          <div style={{ marginTop: '70px' }}>
            <Form
              schema={form.app.forms[3].schema}
              uiSchema={form.app.forms[3].uiSchema}
              formData={uFormData}
              onChange={onUserFormDataChange}
              formContext={uFormContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              jsonValidator={validatorUserAccess}
              transformErrors={transformErrors}
              ErrorList={errorListTemplate}
              liveValidate={liveValidate}
              validate={onValidate.bind(this, selectedEntitlements)}
              onSubmit={updateUser.bind(null, userDetailViewId)}
            >
              <div className="row backUserClass">
                <button
                  type="button"
                  className="userListButton1 btn"
                  onClick={backToUser}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="userListButton2 float-right"
                  onClick={switchOnLiveValidate}
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      )
    } else if (form) {
      const pendingUserssData = {
        Application: {
          PendingUserUserAccessTable: pendingUserss
        }
      }
      const pendingUserssFormContext = {
        ...formContext,
        formData: pendingUserssData
      }
      const userSearchResultData = {
        Application: {
          userAccessTable: userResults.usersList
        }
      }
      const userSearchResultFormContext = {
        ...formContext,
        formData: userSearchResultData
      }
      let modalHeader, pendingUserModalText
      if (
        showModalMessage &&
        !showModalError &&
        props.showModalMessage.includes(
          'The changes have been successfully saved.'
        )
      ) {
        modalHeader = (
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
        )
      } else if (showModalMessage && !showModalError) {
        modalHeader = (
          <Modal.Header closeButton>
            <Modal.Title>{headerTitle}</Modal.Title>
          </Modal.Header>
        )
      }

      if (showModalError) {
        pendingUserModalText = (
          <Modal.Footer>
            <div className="float-left errorColor">{showModalMessage}</div>
          </Modal.Footer>
        )
      } else {
        pendingUserModalText = ''
      }

      return (
        <div>
          <ErrorModal
            errorMessage={props.errorMessage}
            onDeleteClick={props.onDeleteClick}
          />
          <div className="pendingUserDataTable">
            <Form
              schema={form.app.forms[1].schema}
              uiSchema={form.app.forms[1].uiSchema}
              formData={pendingUserssData}
              formContext={pendingUserssFormContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
            />
          </div>

          <div className="userSearchDiv" style={{ marginTop: '10rem' }}>
            <Form
              schema={form.app.forms[2].schema}
              uiSchema={form.app.forms[2].uiSchema}
              formData={formData}
              onChange={onFormDataChange}
              formContext={formContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
            >
              <div className="userSearchButtonDiv">
                <button
                  className="userListButton1 btn"
                  type="button"
                  onClick={clear}
                >
                  Clear
                </button>
                <button
                  className="userListButton2 btn"
                  type="button"
                  onClick={() => search(formData)}
                >
                  Search
                </button>
              </div>
            </Form>
          </div>

          <div className="userSearchResultTable">
            {header}
            <Form
              schema={form.app.forms[0].schema}
              uiSchema={form.app.forms[0].uiSchema}
              formData={{ ...userSearchResultData }}
              onChange={onFormDataChange}
              formContext={userSearchResultFormContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
            />
          </div>
          <NewUserModal
            heading={headerTitle}
            {...newModalProps}
            show={showModal}
            cancel={hideModal}
            save={addNewUser}
            isPEAdmin={isPEAdmin === undefined ? false : true}
            isCPAdmin={isCPAdmin === undefined ? false : true}
            isWorkerAdmin={isWorkerAdmin === undefined ? false : true}
            currentDate={currentDate}
          />

          <Modal show={showCallbackModal} onHide={hideCallbackModal}>
            {modalHeader}
            {showModalMessage && !showModalError ? (
              <Modal.Body>
                <form>
                  <div className="row">
                    <div className="col-12">{showModalMessage}</div>
                  </div>
                </form>
              </Modal.Body>
            ) : (
              ''
            )}
            {pendingUserModalText}
          </Modal>

          <div className="pendingUserModal">
            <Modal show={pendingUserModal} onHide={hidePendingUserModal}>
              <Modal.Header closeButton>
                <Modal.Title>Remove Pending User</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          className="form-control"
                          value={selectedPUser.user.firstName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          className="form-control"
                          value={selectedPUser.user.lastName}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          className="form-control"
                          value={selectedPUser.user.email}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={() => cancelPendingUser()}>Cancel</Button>
                <Button onClick={() => removePendingUser(selectedPUser.index)}>
                  Remove
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )
    }
  }
  return <ScreenPreLoader minHeight={minHeight} />
}
