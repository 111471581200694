import * as React from 'react'
import * as _ from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { Modal, Alert } from 'react-bootstrap'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { Validator } from 'jsonschema'

interface IncidentFormPresentationProps {
  formData: any
  formContext: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  onFormDataChange: (formData: any) => void
  reportincident: () => void
  required: boolean
  formComponents
  successModal: boolean
  failureModal: boolean
  ticketNumber: string
  errorMessage: Array<any>
  onDeleteClick: () => void
  onValidate: (formData: object, errors: object) => void
  errorListTemplate: (props: any) => void
  onError: (errors: object) => void
  transformErrors: (errors: object) => void
  validator: Validator
  liveValidate: boolean
  switchOnLiveValidate: () => void
}

export default function IncidentFormPresentation(
  props: IncidentFormPresentationProps
) {
  const {
    formData = {},
    formContext,
    forms = [],
    onFormDataChange,
    reportincident,
    formComponents,
    successModal,
    failureModal,
    ticketNumber,
    errorMessage,
    onDeleteClick,
    onValidate,
    errorListTemplate,
    onError,
    transformErrors,
    liveValidate,
    validator,
    switchOnLiveValidate
  } = props

  const header = (
    <div>
      <h1>
        <i className="fa fa-warning" /> Report Incident{' '}
      </h1>
    </div>
  )
  if (!_.isEmpty(forms)) {
    return (
      <div>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <div>
          {header}
          {forms && forms.length > 0 ? (
            <Form
              schema={forms[0]['schema']}
              uiSchema={forms[0]['uiSchema']}
              formData={formData}
              formContext={formContext}
              onChange={onFormDataChange}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              onSubmit={reportincident}
              validate={onValidate}
              liveValidate={liveValidate}
              showErrorList={true}
              ErrorList={errorListTemplate}
              onError={onError}
              transformErrors={transformErrors}
              jsonValidator={validator}
            >
              <button
                type="submit"
                className="btn btn-primary float-right"
                style={{ margin: '10px' }}
                onClick={switchOnLiveValidate}
              >
                Submit Incident
              </button>
              <a
                className="btn btn-primary float-right"
                href="/Home"
                style={{ margin: '10px' }}
              >
                Back to Home
              </a>
            </Form>
          ) : (
            <h3>
              <input type="submit" style={{ display: 'none' }} />
            </h3>
          )}
          <Modal show={successModal}>
            <Modal.Body>
              <div>
                <p>
                  Thank you! Your incident ticket number is:{ticketNumber}. For
                  additional assistance, or to check status of this ticket,
                  please contact the West Virginia Technical Call Center at
                  1-844-451-3515
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <a className="btn btn-info float-right" href="/Home">
                  Close
                </a>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal show={failureModal}>
            <Modal.Body>
              <div>
                <p>
                  The online issue submission is currently unavailable. Please
                  contact the Technical Call Center at 1-844-451-3515 to report
                  your issue.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <a className="btn btn-info float-right" href="/Home">
                  Close
                </a>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
  return <ScreenPreLoader loaded={false} />
}
