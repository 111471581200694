import * as React from 'react'
import * as _ from 'lodash'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { getServerTime } from '../src/components/utils'
const moment = require('moment-mini-ts')
const moment_timezone = require('moment-timezone')
import { config } from '../../../config'

// Code changes for GPRB0359133 and US2558381
function validBirthDateCheck(birthdate, currentDate) {
  let currESTDT = moment_timezone
    .tz(currentDate, 'America/New_York')
    .format('YYYY-MM-DD')
  if (birthdate < '1900-01-01' || birthdate > currESTDT) {
    return true
  }
  return false
}

// Code Changes GPRB0359078 and US2554056
function validZipCodeCheck(zip) {
  if (String(zip).length > 0 && String(zip).length < 5) {
    return true
  }
}

// Code Changes GPRB0359223 and US2819645
function validSSN(ssn) {
  let temp = ssn
  if (ssn.length > 0 && ssn.length < 9) {
    return false
  }
  if (
    temp.substring(0, 3) === '000' ||
    (temp.substring(0, 3) >= '900' && temp.substring(0, 3) <= '999') ||
    temp.substring(0, 3) === '666'
  ) {
    return false
  }
  if (temp.substring(3, 5) === '00' || temp.substring(5, 9) === '0000') {
    return false
  }
  return true
}

export const customValidationErrors = (
  formContext,
  errors,
  formData,
  id,
  trackerName
) => {
  const step = _.get(formContext, 'STEP')
  const clientsArray = formContext.formData.application.clients.map(
    (client, index) => {
      const name =
        client.clientDetails.personalData[0].clientFstNm &&
        client.clientDetails.personalData[0].clientLstNm
          ? client.clientDetails.personalData[0].clientFstNm +
            ' ' +
            client.clientDetails.personalData[0].clientLstNm +
            '-'
          : 'Person ' + (index + 1) + '-'
      return name
    }
  )
  const applicantName = formContext.formData.application.clients.map(
    (client, index) => {
      const name =
        client.clientDetails.personalData[0].clientFstNm &&
        client.clientDetails.personalData[0].clientLstNm
          ? client.clientDetails.personalData[0].clientFstNm +
            ' ' +
            client.clientDetails.personalData[0].clientLstNm
          : ' '
      return name
    }
  )

  const errorId =
    id.match('(?:.*)clients_([0-9]+)_?') &&
    id.match('(?:.*)clients_([0-9]+)_?')[1]

  if (id.match('clientDetails_personalData')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['clientFstNm'] === undefined ||
          _.trim(row['clientFstNm']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientFstNm'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientFstNm',
          message: 'This field is required',
          stack: clientsArray[errorId] + 'First Name is required'
        })
      }
      if (
        (row['clientLstNm'] === undefined ||
          _.trim(row['clientLstNm']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientLstNm'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientLstNm',
          message: 'This field is required',
          stack: clientsArray[errorId] + 'Last Name is required'
        })
      }
      //US1801346 Story
      if (
        row['gdrCd'] === undefined &&
        document.getElementById(id + '_' + trackerName + '_' + index + '_gdrCd')
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].gdrCd',
          message: 'This field is required',
          stack: clientsArray[errorId] + 'Please select "Gender"'
        })
      }
      if (
        (row['clientBthDt'] === undefined ||
          _.trim(row['clientBthDt']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientBthDt'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].clientBthDt',
          message: 'This field is required',
          stack: clientsArray[errorId] + 'Date of Birth is required'
        })
      }

      // Code changes for GPRB0359133 and US2558381
      if (
        row['clientBthDt'] &&
        row['clientBthDt'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientBthDt'
        )
      ) {
        if (
          validBirthDateCheck(
            _.trim(row['clientBthDt']),
            formContext.currentDate
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].clientBthDt',
            message: 'Invalid Date of Birth',
            stack:
              clientsArray[errorId] +
              'Date of Birth cannot be prior to 01/01/1900 and cannot be a future date.'
          })
        }
      }

      // Code changes GPRB0359223 and US2819645
      // Personal Personal Data SSN Validation
      if (
        row['clientSsn'] &&
        row['clientSsn'] !== undefined &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_clientSsn'
        )
      ) {
        if (!validSSN(_.trim(row['clientSsn']))) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].clientSsn',
            message: 'Invalid SSN',
            stack:
              clientsArray[errorId] + 'Invalid Social Security Number (SSN)'
          })
        }
      }

      if (
        (row['buyFixEatFood'] === undefined ||
          _.trim(row['buyFixEatFood']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_buyFixEatFood'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].buyFixEatFood',
          message: 'This field is required',
          stack:
            clientsArray[errorId] +
            'Does this person buy, fix or eat food with the Household is required'
        })
      }

      if (
        (row['dateOfChange'] === undefined ||
          _.trim(row['dateOfChange']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_dateOfChange'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].dateOfChange',
          message: 'This field is required',
          stack: clientsArray[errorId] + 'Date of Change is required'
        })
      }
      //End
    })
  }
  if (id.match('clientIncomes_earnedIncomes')) {
    if (
      _.get(formContext, 'formData.application.householdIncomeChange') ===
        'Y' ||
      _.get(formContext, 'formData.application.individualIncomeChange') === 'Y'
    ) {
      _.forEach(formData, (row, index) => {
        if (
          (row['frequency'] === undefined || _.trim(row['frequency']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_frequency'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].frequency',
            message: 'This field is required',
            stack:
              clientsArray[errorId] + 'Please select "How often are you paid?"'
          })
        }
        if (
          (row['startDate'] === undefined || _.trim(row['startDate']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_startDate'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].startDate',
            message: 'This field is required',
            stack: clientsArray[errorId] + 'Please answer "Date Started"'
          })
        }
        if (
          (row['amount'] === undefined || _.trim(row['amount']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_amount'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].amount',
            message: 'This field is required',
            stack: clientsArray[errorId] + 'Please answer  "Amount Paid"'
          })
        }
      })
    }
  }
  if (id.match('clientIncomes_selfEmploymentIncomes')) {
    if (
      _.get(formContext, 'formData.application.householdIncomeChange') ===
        'Y' ||
      _.get(formContext, 'formData.application.individualIncomeChange') === 'Y'
    ) {
      _.forEach(formData, (row, index) => {
        if (
          (row['incomeType'] === undefined ||
            _.trim(row['incomeType']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_incomeType'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].incomeType',
            message: 'This field is required',
            stack:
              clientsArray[errorId] + 'Please select "Type of Self-Employment"'
          })
        }
      })
    }
  }
  if (id.match('clientIncomes_unearnedIncomes')) {
    if (
      _.get(
        formContext,
        'formData.application.householdUnearnedIncomeChange'
      ) === 'Y'
    ) {
      _.forEach(formData, (row, index) => {
        if (
          (row['amount'] === undefined || _.trim(row['amount']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_amount'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].amount',
            message: 'This field is required',
            stack:
              clientsArray[errorId] +
              'Amount of unearned income received is required'
          })
        }
      })
    }
  }

  if (id.match('clientExpenses_childSupport')) {
    if (
      _.get(
        formContext,
        'formData.application.changeInLegalObligationtoPay'
      ) === 'Y'
    ) {
      _.forEach(formData, (row, index) => {
        if (
          (row['obligationAmount'] === undefined ||
            _.trim(row['obligationAmount']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_obligationAmount'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].obligationAmount',
            message: 'This field is required',
            stack: clientsArray[errorId] + 'Please select "Obligation Amount"'
          })
        }
        if (
          (row['paymentAmount'] === undefined ||
            _.trim(row['paymentAmount']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_paymentAmount'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].paymentAmount',
            message: 'This field is required',
            stack: clientsArray[errorId] + 'Payment Amount is required'
          })
        }
        if (
          (row['paymentOrdered'] === undefined ||
            _.trim(row['paymentOrdered']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_paymentOrdered'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].paymentOrdered',
            message: 'This field is required',
            stack:
              clientsArray[errorId] +
              'Please select "Is this payment court ordered?"'
          })
        }
        if (
          (row['paymentType'] === undefined ||
            _.trim(row['paymentType']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_paymentType'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].paymentType',
            message: 'This field is required',
            stack: clientsArray[errorId] + 'Please select "Payment Type"'
          })
        }
      })
    }
  }

  if (id.match('_clientDetails_relationShip')) {
    _.forEach(formData, (row, index) => {
      if (
        (row['relClientName'] === undefined ||
          _.trim(row['relClientName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_relClientName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].relClientName',
          message: 'This field is required',
          stack:
            clientsArray[errorId] +
            'Please provide a relationship for each household member.'
        })
      }
      if (
        (row['relationName'] === undefined ||
          _.trim(row['relationName']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_relationName'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].relationName',
          message: 'This field is required',
          stack:
            clientsArray[errorId] +
            'Please provide a relationship for each household member.'
        })
      }
      if (
        (row['foodMeals'] === undefined || _.trim(row['foodMeals']) === '') &&
        document.getElementById(
          id + '_' + trackerName + '_' + index + '_foodMeals'
        )
      ) {
        errors.push({
          name: 'dateOverlap',
          property: '.' + trackerName + '[' + index + '].foodMeals',
          message: 'This field is required',
          stack:
            clientsArray[errorId] +
            'Please select "Do you buy food/eat meals with him/her?"'
        })
      }
    })
  }

  if (id.match('clientIncomes_lotteryGamblingWinnings')) {
    if (_.get(formContext, 'formData.application.wonMoreThan4250') === 'Y') {
      _.forEach(formData, (row, index) => {
        if (
          (row['dateofWinning'] === undefined ||
            _.trim(row['dateofWinning']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_dateofWinning'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].dateofWinning',
            message: 'This field is required',
            stack:
              clientsArray[errorId] + 'Please select "When did you win this"'
          })
        }
        if (
          (row['amount'] === undefined || _.trim(row['amount']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_amount'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].amount',
            message: 'This field is required',
            stack: clientsArray[errorId] + 'Please select "Amount"'
          })
        }
      })
    }
  }

  if (id.match('clientExpenses_utilityExpenses')) {
    if (
      _.get(formContext, 'formData.application.changeReason') === '2' ||
      _.get(formContext, 'formData.application.changeReason') === '4'
    ) {
      _.forEach(formData, (row, index) => {
        if (
          (row['utilityCost'] === undefined ||
            _.trim(row['utilityCost']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_utilityCost'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].utilityCost',
            message: 'This field is required',
            stack:
              clientsArray[errorId] +
              'Please answer "How much does it cost each month?"'
          })
        }

        if (
          step == 8 &&
          (row['utilityType'] === undefined ||
            _.trim(row['utilityType']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_utilityType'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].utilityType',
            message: 'This field is required',
            stack: clientsArray[errorId] + 'Please select "Expense type"'
          })
        }
      })
    }
  }

  if (id.match('clientExpenses_shelterExpenses')) {
    if (
      _.get(formContext, 'formData.application.changeReason') === '2' ||
      _.get(formContext, 'formData.application.changeReason') === '4'
    ) {
      _.forEach(formData, (row, index) => {
        if (
          step == 8 &&
          (row['shelterType'] === undefined ||
            _.trim(row['shelterType']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_shelterType'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].shelterType',
            message: 'This field is required',
            stack: clientsArray[errorId] + 'Please select "Expense type"'
          })
        }
        if (
          (row['shelterCost'] === undefined ||
            _.trim(row['shelterCost']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_shelterType'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].shelterCost',
            message: 'This field is required',
            stack:
              clientsArray[errorId] +
              'Please answer "How much is the expense each month?"'
          })
        }
      })
    }
  }

  if (id.match('application_listIndividuals')) {
    //RenewalWizardABAWD_application_listIndividuals_trackerChange_1_name
    const listIndividuals = formData !== undefined ? formData : undefined

    if (listIndividuals !== undefined && step == 9) {
      _.forEach(listIndividuals, (listIndividual, index) => {
        if (
          (listIndividual['name'] === undefined ||
            _.trim(listIndividual['name']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_name'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].name',
            message: 'This field is required',
            stack: 'Please select "Name"'
          })
        }
        if (
          (listIndividual['dob'] === undefined ||
            _.trim(listIndividual['dob']) === '') &&
          document.getElementById(id + '_' + trackerName + '_' + index + '_dob')
        ) {
          errors.push({
            name: 'dateOverlap',
            property: '.' + trackerName + '[' + index + '].dob',
            message: 'This field is required',
            stack: 'Please select "Date of Birth"'
          })
        }
        if (
          (listIndividual['dateDecreasedBelow20'] === undefined ||
            _.trim(listIndividual['dateDecreasedBelow20']) === '') &&
          document.getElementById(
            id + '_' + trackerName + '_' + index + '_dateDecreasedBelow20'
          )
        ) {
          errors.push({
            name: 'dateOverlap',
            property:
              '.' + trackerName + '[' + index + '].dateDecreasedBelow20',
            message: 'This field is required',
            stack: 'Please select "Date Hours Decreased to Fewer Than 20"'
          })
        }
      })
    }
  }
}
