import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  tabs: Fields.TabsField,
  carousel: Fields.CarouselField,
  panel: Fields.PanelField,
  tableRow: Fields.TableRowField,
  mcr: Fields.CodeReferenceField
}

export const widgets = {
  radio: Widgets.CustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  checkboxes: Widgets.CustomCheckboxesWidget,
  'alt-date': Widgets.DatePickerWidget,
  review: Widgets.ReviewWidget,
  dollar: Widgets.DollarWidget,
  span: Widgets.SpanWidget,
  link: Widgets.LinkWidget,
  linkButton: Widgets.LinkButtonWidget,
  header: Widgets.HeaderWidget,
  validatedText: Widgets.ValidatedTextWidget,
  ssn: Widgets.SSNWidget,
  ssn1: Widgets.SSNWidgetV2
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
