import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const OrganizationContainer = MultitenantLoadable(
  () => import('./OrganizationContainer'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/Organization/OrganizationContainer'
    )
)

const OrganizationPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./OrganizationPresentation'),
    () => import(config)
  ),
  () => import('./formComponents'),
  () => import(config)
)

export default function Organization(props) {
  return (
    <OrganizationContainer
      {...props}
      presentation={presentationProps => (
        <OrganizationPresentation {...presentationProps} />
      )}
    />
  )
}
