import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyProvidersNewContainer = MultitenantLoadable(
  () => import('./MyProvidersNewContainer'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/MyProvidersNew/MyProvidersNewContainer'
    )
)

const MyProvidersNewPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyProvidersNewPresentation'),
    () =>
      import(
        '../../' +
          tenantDirectoryName +
          '/MyProvidersNew/MyProvidersNewPresentation'
      )
  ),
  () => import('./formComponents'),
  () =>
    import('../../' + tenantDirectoryName + '/MyProvidersNew/formComponents')
)

export default function MyProvidersNew(props) {
  return (
    <ErrorBoundary name="MyProvidersNew">
      <MyProvidersNewContainer
        {...props}
        presentation={presentationProps => (
          <MyProvidersNewPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyProvidersNewContainer = MultitenantLoadable(
  () => import('./MyProvidersNewContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyProvidersNewPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyProvidersNewPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyProvidersNew(props) {
  return (
    <BaseErrorBoundary name="MyProvidersNew">
      <BaseMyProvidersNewContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyProvidersNewPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
