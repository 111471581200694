import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config' // endpoints
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import '!style-loader!css-loader!../src/customStyles.css'
import { connect } from 'react-redux'
import * as actions from '../../../actions'
import { UnauthorizedError } from '../src/components/utils'
import { Redirect } from 'react-router-dom'
import * as serviceHelper from '../src/components/utils'
const baseUrl = process.env.PUBLIC_URL

const formsBaseUrl = config['formsBaseUrl']

const IncomeEndpoint = config['gatewayWvUrl'] + config['getIncomeLimits']
const formsEndpoint = formsBaseUrl + config['forms_MyIncome']

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  dataTable: Fields.DataTableField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  span: Widgets.SpanWidget,
  routeLink: Widgets.LinkWidget,
  iconWidget: Widgets.ConditionalIconWidget
}

interface incomeState {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  tableData: any
  tableData2: any
  application: any
  formContext: any
  redirect: any
}

export class CriWidget extends React.Component<any, incomeState> {
  constructor(props) {
    super(props)

    this.state = {
      application: {},
      forms: null,
      formContext: props.formContext,
      tableData: null,
      tableData2: null,
      redirect: null
    }
  }

  componentDidMount() {
    this.loadForms()
    this._getIncome()
  }

  loadForms() {
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName
        }
      })
      .then(formJson => {
        let application, forms, formContext
        application = formJson['app']
        forms = application['forms']

        this.setState(prevState => {
          return {
            ...prevState,
            application: application,
            forms: forms,
            formContext: {
              ...prevState.formContext,
              refs: application['metaData']['refs'],
              forms: forms
            }
          }
        })
      })
      .catch(error => {
        console.error('MyIncomes form fetch failed due to ex', error)
      })
  }

  _getIncome = () => {
    const houserHoldSize = this.state.formContext.formData.application.clients
      .length
    const fetchEndpoint = IncomeEndpoint
    let body = {
      houseHoldSize: 11,
      programName: 'CoCAll'
    }

    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          method: 'POST',
          body: JSON.stringify(body)
        },
        this.props.showErrorMessage
      )
      .then(json => {
        let arr = json
        arr.map(function(currentObj, index, arr) {
          currentObj.incomeLimit = '$' + currentObj.incomeLimit
        })
        const size = arr.filter(household => {
          return (
            household.houserHoldSize ===
            this.state.formContext.formData.application.clients.length
          )
        })
        const formData2 = { Application: { myIncomes: size || [] } }
        const formData1 = { Application: { myIncomes: arr || [] } }
        this.setState(prevState => {
          return {
            ...prevState,
            tableData: formData1,
            tableData2: formData2
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
      })
  }

  _table() {
    let tableId1 = document.getElementById('table1').className
    let tableId2 = document.getElementById('table2').className
    if (tableId1 == 'tableStyleShow') {
      document.getElementById('table1').className = 'tableShowNone'
      document.getElementById('table2').className = 'tableStyleShow'
    } else {
      document.getElementById('table1').className = 'tableStyleShow'
      document.getElementById('table2').className = 'tableShowNone'
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state
    }
  }

  render() {
    const { formContext, tableData, tableData2, forms, redirect } = this.state

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }

    return (
      <div>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          Reporting Requirements by Program – What do you need to report?
        </h3>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          SNAP (Food Stamps) Reporting Requirements
        </h3>
        <ul>
          <li>
            You must report when the total gross earned and unearned income of
            all household members exceeds 130% of the gross limit for your
            household. Please review the attached chart for guidance on the
            income limits.
          </li>
          <li>
            Households in which all adult members are elderly or disabled with
            no earned income or only excluded earned income must report when the
            total income exceeds 200% of the gross limit for the household.
          </li>
          <li>
            Households that contain an Able Bodied Adult Without Dependents
            (ABAWD) must report when that person’s work hours are reduced to
            less than 20 hours a week, averaged monthly.
          </li>
          <li>
            You must report when a member of your SNAP assistance group (you and
            all the individuals who receive SNAP with you) or anyone who
            purchases and prepares their food with you collects lottery or
            gambling winnings of $4,250 or more during any one game.
          </li>
        </ul>
        <p className="cri-p-space">
          Note: Unless exempt, you must comply with work requirements by
          registering with WorkForce West Virginia and providing information
          about employment status and job availability. If you refuse or quit
          employment or reduce work hours to below 30 hours per week without
          good cause, you may be penalized.
        </p>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          Medicaid and WVCHIP Reporting Requirements
        </h3>
        <p>You must report the following within ten days of the change:</p>
        <ul>
          <li>
            Anyone moves and/or changes address, name, or telephone number
          </li>
          <li>Anyone moves into/out of your household</li>
          <li>Anyone obtains/loses employment</li>
          <li>
            If anyone gets new health coverage through a job, Medicare, or some
            other source
          </li>
          <li>
            There are changes in your household’s amount or source of unearned
            income
          </li>
          <li>
            There are changes in your household’s amount or source of earned
            income or number of hours worked
          </li>
          <li>
            If the tax filing status for anyone in the household changes
            (dependent status and single/joint filing)
          </li>
          <li>
            There are changes in your household’s assets, including receiving,
            selling, purchasing, or losing a vehicle, including recreational
            vehicles and equipment
          </li>
          <li>
            Anyone in your household receives a lump sum payment because this
            may affect your eligibility for continuing benefits and you may be
            expected to live on this income for a specific period of time
          </li>
          <li>
            If you are in a nursing home or intermediate care facility, you must
            report when you are discharged to go to another facility or return
            home
          </li>
        </ul>
        <h3 style={{ fontWeight: 'bold' }} className="coc-content">
          West Virginia Works (Cash Assistance) Reporting Requirements
        </h3>
        <ul>
          <li>
            You must contact your local DoHS office and report all changes
            within ten days of the change.
          </li>
        </ul>
        <p>
          If you give incorrect or false information or fail to report changes
          that are required to be reported, then your assistance group may be
          required to repay any benefits received and you may be prosecuted for
          fraud.
        </p>
        <br />

        <div>
          <p className="cri-p-space">
            The table below represents the Income Limit for your household size.
          </p>
          <button
            style={{ backgroundColor: 'none' }}
            className="coc-link-style btn btn-default"
            onClick={this._table}
            type="button"
          >
            Click here for the complete Income Limit table for larger or smaller
            households.
          </button>
          {forms && forms.length > 0 ? (
            <div id="table1" className="tableStyleShow">
              <Form
                schema={forms[0]['schema']}
                uiSchema={forms[0]['uiSchema']}
                formData={tableData2}
                formContext={formContext}
                fields={fields}
                widgets={widgets}
                ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
                FieldTemplate={Templates.CustomFieldTemplate}
              >
                <input type="submit" style={{ display: 'none' }} />
              </Form>
            </div>
          ) : (
            ''
          )}
          {forms && forms.length > 0 ? (
            <div id="table2" className="tableShowNone">
              <Form
                schema={forms[1]['schema']}
                uiSchema={forms[1]['uiSchema']}
                formData={tableData}
                formContext={formContext}
                fields={fields}
                widgets={widgets}
                ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
                FieldTemplate={Templates.CustomFieldTemplate}
              >
                <input type="submit" style={{ display: 'none' }} />
              </Form>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}
function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CriWidget)
