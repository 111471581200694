import * as React from 'react'
import { Redirect } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'
import { get, isEqual, isEmpty, mergeWith } from 'lodash'

const _ = { get, isEqual, isEmpty, mergeWith }

const moment = require('moment-timezone')

declare const process
const baseUrl = process.env.PUBLIC_URL

const initialLoadEndpoint = config['getMyPermissionDetails']
const viewApplicationEndpoint = config['viewApplication']
const formsEndpoint = config['forms_MyPermission']
const appIntakeEndpoint = config['forms_AppIntake']

interface MyPermissionContainerProps {
  presentation: any
  uuid: string
  minHeight: string
  locale: string
  authHeader: string
}

interface MyPermissionContainerState {
  application: any
  formData: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formContext: any
  reviewFormContext: any
  redirect: string
  callingApi: boolean
}

class MyPermissionContainer extends React.Component<
  MyPermissionContainerProps,
  MyPermissionContainerState
> {
  constructor(props: MyPermissionContainerProps) {
    super(props)

    this.state = {
      application: {},
      formData: {},
      forms: null,
      formContext: null,
      reviewFormContext: null,
      redirect: null,
      callingApi: false
    }
  }

  componentDidMount() {
    this._loadApp()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      this.setState({ callingApi: true })
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(json => {
          this.setState(prevState => {
            return {
              callingApi: false,
              formContext: {
                ...prevState.formContext,
                forms: json['app']['forms']
              },
              forms: json['app']['forms']
            }
          })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('MyPermission form re-fetch failed due to ex', error)
          const code = CODES.MY_PERMISSION_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })

      helper
        .fetchJson(appIntakeEndpoint.replace('{version}', '1.0'), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(appIntakeJson => {
          this.setState(prevState => {
            return {
              reviewFormContext: {
                ...prevState.reviewFormContext,
                reviewForms: appIntakeJson['app'].forms,
                refs: appIntakeJson['app'].metaData.refs
              }
            }
          })
        })
        .catch(error => {
          console.error(
            'MyPermission review form fetch failed due to ex',
            error
          )
          const code = CODES.MY_PERMISSION_REFETCH_REVIEW_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        }) // end inner-fetch (AppIntake.json)
    }
  }

  _loadApp = () => {
    this._getApplicationJSON()
  }

  // Current myPermissions enpoint deprecated. New implementation to come in the future.
  _getApplicationJSON = () => {
    helper
      .fetchJson(initialLoadEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        },
        method: 'POST'
      })
      .then(json => {
        // load json into form
        this._loadAll(json)
      })
      .catch(error => {
        console.error(
          'MyPermission _getApplicationJSON failed at endpoint',
          initialLoadEndpoint,
          'with ex',
          error
        )
        const code = CODES.MY_PERMISSION_FETCH_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _viewApplicationDetails = (id: string, applicationId: string) => {
    const fetchEndpoint = viewApplicationEndpoint.replace(
      '{applId}',
      applicationId
    )

    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(jsonData => {
        // load json into form
        //delete jsonData['applId']
        helper.cleanNullValues(jsonData)
        this.setState({
          reviewFormContext: {
            ...this.state.reviewFormContext,
            formData: jsonData,
            reviewFormData: jsonData
          }
        })
      })
      .catch(error => {
        console.error(
          'MyPermission _viewApplicationDetails failed with ex',
          error
        )
        const code = CODES.MY_PERMISSION_FETCH_DETAILS
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _goBack = () => {
    this.setState({
      reviewFormContext: {
        ...this.state.reviewFormContext,
        formData: null,
        reviewFormData: null
      }
    })
  }

  _setRedirect = (path: string) => {
    this.setState({ redirect: path })
  }

  _loadAll = (jsonData: object) => {
    let forms, application, formData, formContext
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        let json = helper.deepClone(formJson)
        application = json['app']
        forms = application['forms']

        //Initialize formContext, which will be used for schema references and context-sensitive widgets.
        formContext = {
          refs: application['metaData']['refs'],
          forms: forms,
          config
        }

        //load the pre-filled form data
        let myPermissions = []
        let jsonDataArray = JSON.parse(JSON.stringify(jsonData))
        for (var k = 0; k < jsonDataArray.length; k++) {
          let statusValue = jsonDataArray[k].status
          if (statusValue !== null) {
            statusValue = statusValue.toLowerCase()
            statusValue =
              statusValue.charAt(0).toUpperCase() + statusValue.slice(1)
          } else {
            statusValue = ' '
          }
          let myPermission = {
            accordionTitleValue:
              jsonDataArray[k].prtcpFirstName +
              ' ' +
              jsonDataArray[k].prtcpLastName +
              ' - App # ' +
              jsonDataArray[k].appId +
              ' - ' +
              moment.utc(jsonDataArray[k].startDate).format('MM/DD/YYYY'),
            primaryApplicant:
              jsonDataArray[k].prtcpFirstName +
              ' ' +
              jsonDataArray[k].prtcpLastName,
            applicationNumber: jsonDataArray[k].appId,
            emailAddress:
              jsonDataArray[k].applicantEmail !== null
                ? jsonDataArray[k].applicantEmail
                : ' ',
            applicationStartDate: jsonDataArray[k].startDate,
            status: statusValue,
            submittedDate: jsonDataArray[k].submissionDate
          }
          myPermissions.push(myPermission)
        }
        let Applications = { myPermissions }
        application = {
          Application: Applications
        }
        let formDataLoad = application

        if (!_.isEmpty(formDataLoad)) {
          //Merge the pre-filled form data with an empty default object to ensure necessary objects are available for RJSF rendering.
          formData = _.mergeWith(
            helper.createObjectFromMasterSchema(null, formContext),
            formDataLoad,
            function(objValue, srcValue) {
              if (srcValue == null && objValue == undefined) {
                return {}
              }
              return undefined
            }
          )
        } else {
          //New application, initialize to empty object
          formData = helper.createObjectFromMasterSchema(null, formContext)
        }

        //Augment the context with formData
        formContext = {
          ...formContext,
          formData: formData,
          viewApplicationDetails: this._viewApplicationDetails,
          setRedirect: this._setRedirect
        }

        let reviewFormContext
        helper
          .fetchJson(appIntakeEndpoint.replace('{version}', '1.0'), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              locale: (this.props.locale + '_us').toUpperCase(),
              tenantCode: config.tenant.code,
              portalName: config.portalName,
              uuid: this.props.uuid,
              Authorization: this.props.authHeader
            }
          })
          .then(appIntakeJson => {
            reviewFormContext = {
              reviewForms: appIntakeJson['app'].forms,
              refs: appIntakeJson['app'].metaData.refs
            }

            formContext = {
              ...formContext,
              formData: formData,
              reviewFormData: formData
            }

            this.setState({
              application,
              forms,
              formData,
              formContext,
              reviewFormContext
            })
          })
          .catch(error => {
            console.error(
              'MyPermission review form fetch failed due to ex',
              error
            )
            const code = CODES.MY_PERMISSION_FETCH_REVIEW_FORM
            if (shouldThrow(code)) {
              this.setState(() => {
                if (error instanceof helper.IEServiceError) {
                  throw error
                } else {
                  throw new ClientPortalException(error, code)
                }
              })
            }
          }) // end inner-fetch (AppIntake.json)
      })
      .catch(error => {
        console.error('MyPermission form fetch failed due to ex', error)
        const code = CODES.MY_PERMISSION_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      }) // end outer-fetch (MyPermission.json)
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData
        }
      }
    })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={baseUrl + '/application?applId=' + this.state.redirect} />
      )
    }
    const { presentation, minHeight, locale } = this.props
    const {
      application,
      forms,
      formData,
      formContext,
      reviewFormContext
    } = this.state

    const presentationProps = {
      application,
      forms,
      formData,
      formContext,
      reviewFormContext,
      minHeight,
      onFormDataChange: this._onFormDataChange,
      goBack: this._goBack,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

export default connect(mapStateToProps)(MyPermissionContainer)
