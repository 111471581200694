import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import '!style-loader!css-loader!../src/customStyles.css'

function physicalAddressWidget() {
  return (
    <p>
      Technical Call Center
      <br />
      700 Washington Street, East Suite 210
      <br />
      Charleston, West Virginia 25301
    </p>
  )
}

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  span: Widgets.SpanWidget,
  routeLink: Widgets.LinkWidget,
  validatedText: Widgets.ValidatedTextWidget,
  phone: Widgets.PhoneWidget,
  ssn: Widgets.SSNWidget,
  textarea: Widgets.CustomTextAreaWidget,
  link: Widgets.LinkWidget,
  physicalAddress: physicalAddressWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate
export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
