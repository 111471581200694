import * as React from 'react'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash'
import { config } from '../../../config'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as serviceHelper from '../src/components/utils'
import * as actions from '../../../actions'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
const baseUrl = process.env.PUBLIC_URL

const formsEndpoint = config['formsBaseUrl'] + config['forms_CpRenewals']
const getCpRenewalsEndPoint = config['gatewayWvUrl'] + config['getCpRenewals']

interface CpRenewalsContainerProps {
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  userRoleId: number
  roleId: number
  orgId: number
  showCpRenewals: boolean
  getCpRenewalsResult: any
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  history: {
    push: (url: string) => void
  }
  logoutUser
}

interface CpRenewalsContainerState {
  form: {
    schema: Object
    uiSchema: Object
  }
  formContext: any
  formData: any
  showCpRenewals: boolean
  getCpRenewalsResult: any
}

class CpRenewalsContainer extends React.Component<
  CpRenewalsContainerProps,
  CpRenewalsContainerState
> {
  constructor(props: CpRenewalsContainerProps) {
    super(props)

    this.state = {
      formData: {},
      form: null,
      formContext: {},
      showCpRenewals: false,
      getCpRenewalsResult: null
    }
  }

  componentDidMount() {
    document.title = 'My Renewals - My Account'
    this._initializeForm()
  }

  _resetForm = () => {
    let { formContext } = this.state
    let formData = _.cloneDeep(formContext.formData)
    formData.casenumber = _.noop()
    formData.dateofbirth = _.noop()
    formData.county = _.noop()
    formData.accessdate = _.noop()

    formContext.formData = formData
    this.setState({
      formContext
    })
  }

  static getDerivedStateFromProps(
    props: CpRenewalsContainerProps,
    state: CpRenewalsContainerState
  ) {
    return {
      formContext: {
        ...state.formContext,
        formData: props.formData || state.formContext.formData
      }
    }
  }

  _initializeForm = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(form => {
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            formContext: {
              ...prevState.formContext,
              forms: [{ ...form }],
              refs: {
                ...form.app.metaData.refs,
                '{future date}': () => {
                  var currentDate = _moment(config, getServerTime()).format(
                    'YYYY-MM-DD'
                  )
                  return currentDate
                }
              }
            }
          }
        })
      })
      .catch(error => {
        console.error('CpRenewals form error', error)
      })
  }

  _onFormDataChange = ({ formData }) => {
    let { formContext } = this.state
    formContext.formData = formData
    this.setState({
      formContext
    })
  }

  _search = () => {
    let se = this.state.formContext.formData
    let searchData = {
      caseNumber: se.casenumber.toString(),
      dateOfBirth: se.dateofbirth,
      county: se.county,
      reviewDueDate: se.accessdate
    }

    serviceHelper
      .fetchJson(
        getCpRenewalsEndPoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            userRoleId: this.props.userRoleId.toString(),
            roleId: this.props.roleId.toString(),
            orgId: this.props.orgId.toString()
          },
          body: JSON.stringify(searchData)
        },
        this.props.showErrorMessage
      )
      .then(json => {
        let newFormContext = {
          ...this.state.formContext,
          formData: json
        }
        this.setState({
          showCpRenewals: true,
          getCpRenewalsResult: json,
          formContext: newFormContext
        })
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('CpRenewals _search failed with ex ', ex)
      })
  }

  _hasKeyedAny = (): boolean => {
    const se = this.state.formContext.formData
    if (this.state.formContext && se) {
      let hasKeyedAny = false
      if (
        !_.isNil(se.casenumber) &&
        !_.isNil(se.dateofbirth) &&
        !_.isNil(se.county) &&
        !_.isNil(se.accessdate)
      ) {
        hasKeyedAny = true
      }
      return hasKeyedAny
    }
  }

  _showBackCpRenewals = () => {
    this.setState({
      showCpRenewals: false
    })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    const { presentation, minHeight, errorMessage } = this.props
    const {
      form,
      formContext,
      showCpRenewals,
      getCpRenewalsResult
    } = this.state

    const newFormContext = {
      ...formContext,
      hasKeyedAny: this._hasKeyedAny
    }
    const presentationProps = {
      form,
      formContext: newFormContext,
      onFormDataChange: this._onFormDataChange.bind(this),
      minHeight,
      reset: this._resetForm.bind(this),
      search: this._search.bind(this),
      hasKeyedAny: this._hasKeyedAny,
      showCpRenewals,
      showBackCpRenewals: this._showBackCpRenewals.bind(this),
      getCpRenewalsResult,
      onDeleteClick: this._onDeleteClick,
      errorMessage
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  return {
    auth: state.auth,
    userRoleId,
    roleId,
    orgId,
    errorMessage
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CpRenewalsContainer)
