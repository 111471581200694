import * as React from 'react'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as serviceHelper from '../src/components/utils'
import * as validator from '../src/validation'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import { config } from '../../../config' // endpoints
import * as actions from '../../../actions'
import * as _ from 'lodash' // extra functionality
import * as queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import {
  _moment,
  jsonPathToId,
  resolveSchemaDefinitions
} from '@optum-wvie/dynamic-ui-framework/src/utils'
const validationErrors = config['forms_AppIntake_Errors']
import { imageMap } from '../../../images'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import '!style-loader!css-loader!../src/customStyles.css'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
const CountiesBaseUrl = config['gatewayWvUrl'] + config['countiesStateUrl']

declare const process
const baseUrl = process.env.PUBLIC_URL

const gatewayWvUrl = config['gatewayWvUrl']

const formsEndpoint = config['form_AppIntakePEContainer']
const submitEndpoint = gatewayWvUrl + config['pEapplications']
let immigrationURL = baseUrl + '/ImmigrationStatus'

//Nav tab classes
const pristineIcon = ''

const editingIcon = 'glyphicon myglyphicon-pencil'
const editingBadge = 'badge bg-white-alt'

const validatedIcon = 'glyphicon myglyphicon-ok'
const validatedBadge = 'badge bg-green-alt'

const errorIcon = 'glyphicon myglyphicon-circle_exclamation_mark'
const errorBadge = 'badge bg-yellow-alt'

//This is for step-specific logic to not assume the step number.  Keep this updated.
const STEPS = {
  PERSONAL: 1,
  CITIZENSHIP_AND_RESIDENCY: 2,
  INCOME_AND_RESOURCES: 3,
  RETROACTIVE_REQUEST: 4,
  ADDITIONAL_QUESTIONS: 5
}

let peApplication = {
  clientMedicalIdNm: null,
  applicationStartDate: null,
  agreement: null,
  clients: [
    {
      prefixCode: null,
      clientFstNm: null,
      clientMidlNm: null,
      clientLstNm: null,
      prtcpSsn: null,
      clientBthDt: null,
      suffixCode: null,
      genderCode: null,
      clientRemInStIntnFlg: null,
      clientHomelessFlg: null,
      optBenefitPE: null,
      address: {
        adrLn1Txt: null,
        adrLn2Txt: null,
        ctyNm: null,
        st: null,
        zip: null,
        county: null
      },
      addrSameAsPrimApplicant: null,
      secondaryAddress: {
        adrLn1Txt: null,
        adrLn2Txt: null,
        ctyNm: null,
        st: null,
        zip: null,
        county: null
      },
      emailAddress1: null,
      phones: [
        {
          telNumber1: null,
          phoneType: null
        }
      ],
      prefCntcMethod1Cd: null,
      prefCntcMethod2Id: null,
      clientPreferedWrittenLangua: null,
      clientPreferedSpokenLanguag: null,
      interpreterNeeded: null,
      usCtznOrNatInd: null,
      immgStatusCd: null,
      immigrationStatusGrantDate: null,
      flfl40QualWrkQtr: null,
      numberOfIndividualInTaxHousehold: null,
      houseHoldIncome: null,
      exptPeriod: null,
      isCoveAppIntakePEContainerPropsred: null,
      isApproved: null,
      isPregnantWhenApproved: null,
      isPregnancyEnded: null,
      pregEndDt: null,
      pregnant: null,
      fostercare: null,
      fostercareNonWV: null,
      breastAndCervicalCancerPatients: null,
      caretakerclient: null
    }
  ]
}

interface PEApplicationAction {
  applId: string
  status: string
  applicationData: Object
}

interface AppIntakePEContainerProps {
  presentation: any
  location: {
    search: string
  }
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
      username: string
    }
  }
  isPE: string
  orgId: string
  userId: string
  activeApplicationId: string
  userRoleId: number
  roleId: number
  applicationData: any
  step: number
  status: string
  addPEApplication: (...PEApplicationAction) => void
  removePEApplication: (applId: string) => void
  updatePEApplication: (...PEApplicationAction) => void
  setActivePEApplicationId: (applId: string) => void
  assignPEApplicationId: (applId: string) => void
  updatePEApplicationStep: (applId: string, step: number) => void
  isUnitTest: boolean
  selectedEntitlements: Array<string>
  data: any
  userAccess: {
    selectedOrg: {
      orgId: number
    }
  }
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  serviceErrors: Array<any>
  history: any
  logoutUser
  locale
}

interface AppIntakePEContainerState {
  application: Object
  tabs: {
    status: string
    leftIcon: string
    rightIcon: string
    rightSpan: string
    isVisible: boolean
  }[]
  forms: {
    schema: Object
    uiSchema: Object
    metaData: Object
  }[]
  formContext: any
  formData: any
  liveValidate: boolean
  viewId: string
  redirect: string
  isPE: string
  showNext: boolean
  formDataSubmitted: boolean
  callingApi: boolean
  showExitModal: boolean
  isDisable: boolean
  isExit: boolean
  immigrationClick: boolean
  showErrorModal: boolean
  errorMessage: string
  isComplete: boolean
  applId: string
  userRoleId: number
  uuid: string
  orgId: number
  userId: string
  redirectUrl: string
  titleMessage: string
  btnText: string
  wvCounties?: any
  currentDate: any
}

class AppIntakePEContainer extends React.Component<
  AppIntakePEContainerProps,
  AppIntakePEContainerState
> {
  constructor(props: AppIntakePEContainerProps) {
    super(props)

    this.state = {
      application: {},
      tabs: null,
      forms: null,
      formData: null,
      formContext: null,
      liveValidate: false,
      viewId: null,
      redirect: null,
      isPE: null,
      showNext: false,
      formDataSubmitted: false,
      callingApi: false,
      showExitModal: false,
      isDisable: false,
      isExit: false,
      immigrationClick: false,
      showErrorModal: false,
      errorMessage: null,
      isComplete: false,
      applId: null,
      uuid: null,
      orgId: null,
      userId: null,
      userRoleId: null,
      redirectUrl: null,
      titleMessage: null,
      btnText: null,
      currentDate: getServerTime()
    }
  }

  componentDidMount() {
    let applId = this.props.activeApplicationId
    switch (this.props.step) {
      case 1:
        document.title = 'Personal - Presumptive Eligibility'
        break
      case 2:
        document.title = 'Citizenship and Residency - Presumptive Eligibility'
        break
      case 3:
        document.title = 'Household and Income - Presumptive Eligibility'
        break
      case 4:
        document.title = 'Additional Questions - Presumptive Eligibility'
        break
    }
    if (this.props.location && this.props.location.search) {
      try {
        const query = queryString.parse(this.props.location.search)
        if (applId !== query.applId) {
          applId = query.applId
          this.props.setActivePEApplicationId(applId)
        }
      } catch (err) {
        console.error('Error on handling applId from URL query', err)
      }
    }
    if (applId != '0') {
      serviceHelper
        .fetchJson(
          submitEndpoint + '/' + applId + '?q=detail',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization:
                config.bearer + (this.props.auth.accessToken || ''),
              portalName: config.portalName,
              roleId: this.props.roleId,
              orgId: this.props.orgId
            }
          },
          this.props.showErrorMessage
        )
        .then(json => {
          if (json) {
            let status = json.result.applicationDetail[0].Application.Status
            if (status == 'Submitted') {
              this.setState({ isDisable: true })
            }
            const validationErrorsEndPoint = validationErrors.replace(
              '{version}',
              '1.0'
            )
            const endpoint = formsEndpoint.replace('{version}', '1.0')
            const request = {
              headers: {
                'Content-Type': 'application/json',
                uuid: this.props.auth.userAccount
                  ? this.props.auth.userAccount.uuid
                  : '',
                tenantCode: config.tCode,
                Authorization: config.bearer + this.props.auth.accessToken,
                portalName: config.portalName,
                locale: (this.props.locale + '_US').toUpperCase()
              }
            }
            Promise.all([
              serviceHelper.fetchJson(
                endpoint,
                request,
                this.props.showErrorMessage
              ),
              serviceHelper.fetchJson(
                validationErrorsEndPoint,
                request,
                this.props.showErrorMessage
              )
            ])
              .then(formJson => {
                if (formJson[0]) {
                  var appData = json.result.applicationDetail[0]
                  appData.Application.clients[0].clientMedicalIdNm =
                    appData.Application.clientMedicalIdNm
                  this._initializeForm(
                    appData,
                    formJson[0],
                    applId,
                    formJson[1]
                  )
                }
              })
              .catch(error => {
                this.setState({ callingApi: false })
              })
          }
        })
        .catch(error => {
          if (error instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          this.setState({ callingApi: false })
        })
    } else {
      const endpoint = formsEndpoint.replace('{version}', '1.0')
      const validationErrorsEndPoint = validationErrors.replace(
        '{version}',
        '1.0'
      )
      const request = {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount
            ? this.props.auth.userAccount.uuid
            : '',
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          locale: (this.props.locale + '_US').toUpperCase()
        }
      }
      Promise.all([
        serviceHelper.fetchJson(endpoint, request, this.props.showErrorMessage),
        serviceHelper.fetchJson(
          validationErrorsEndPoint,
          request,
          this.props.showErrorMessage
        )
      ])
        .then(formJson => {
          if (formJson[0]) {
            this._initializeForm(null, formJson[0], applId, formJson[1])
          }
        })
        .catch(error => {
          this.setState({ callingApi: false })
        })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { viewId, tabs } = this.state

    if (viewId != null) {
      //Have to do this since componentDidUpdate runs before real DOM is updated.
      try {
        setTimeout(function() {
          window.requestAnimationFrame(function() {
            let offset = helper.getOffsetTop(document.getElementById(viewId))
            const element = document.getElementById(viewId)
            element.focus()
            window.scroll(0, offset - 100)
          })
        }, 0)
      } catch (e) {
        console.error(
          'AppIntakePEContainer viewId setTimeout failed with ex',
          e
        )
      }
      //TODO: expand accordions if they contain the element.
      this.setState({ viewId: null })
    }

    this._updateTabs()

    if (!_.isEqual(prevProps.userRoleId, this.props.userRoleId)) {
      //The user switched their role! Redirect back to home.
      //TODO: global config for target landing page by role/entitlement?
      if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
        )
      ) {
        this._setRedirect('/myaccount/dashboard')
      } else if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.COMMUNITY_PARTNERSHIP
        )
      ) {
        this._setRedirect('/myaccount/dashboard')
      } else {
        this._setRedirect('/home')
      }
    }

    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      const endpoint = formsEndpoint.replace('{version}', '1.0')
      const validationErrorsEndPoint = validationErrors.replace(
        '{version}',
        '1.0'
      )
      const request = {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount
            ? this.props.auth.userAccount.uuid
            : '',
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          locale: (this.props.locale + '_US').toUpperCase()
        }
      }
      Promise.all([
        serviceHelper.fetchJson(endpoint, request, this.props.showErrorMessage),
        serviceHelper.fetchJson(
          validationErrorsEndPoint,
          request,
          this.props.showErrorMessage
        )
      ])
        .then(formJson => {
          this._updateForms(formJson[0], formJson[1])
        })
        .catch(error => {
          this.setState({ callingApi: false })
        })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formContext &&
      !_.isEqual(nextProps.applicationData, this.state.formContext.formData)
    ) {
      this.setState({
        formContext: {
          ...this.state.formContext,
          formData: helper.deepClone(nextProps.applicationData),
          reviewFormData: helper.deepClone(nextProps.applicationData)
        }
      })
    }
  }

  _immigrationUpdates = () => {
    var win = window.open(immigrationURL, '_blank')
    win.focus()
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _changeView = (val: number) => {
    this._changeStep(val + 1)
  }

  _loadDraft = (endpoint, formsJson, applId, errorMessages = {}) => {
    const request = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        userroleid: this.props.roleId.toString(),
        tenantcode: config.tCode,
        authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalname: config.portalName
      }
    }

    const fetchEndpoint = endpoint.replace('{applId}', applId)
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      .then(formData => {
        this.setState({ callingApi: false })
        this._initializeForm(formData, formsJson, applId, errorMessages)
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_getFormData failed with error:', error)
      })
  }

  _saveFormDataDraft = () => {
    let applicationData = this.props.applicationData

    if (
      typeof applicationData['Application']['agreement'] !== 'undefined' ||
      typeof applicationData['Application']['signatureReceived'] !== 'undefined'
    ) {
      applicationData = _.cloneDeep(this.props.applicationData)
      _.unset(applicationData, 'Application.agreement')
      _.unset(applicationData, 'Application.signatureReceived')
    }
  }

  _updateForms = (formsJson, errorMessages) => {
    let forms, application, formContext
    application = formsJson['app']
    forms = application['forms']

    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      ...this.state.formContext,
      errorMessages,
      forms: forms
    }
    this.setState({ forms, formContext })
  }

  _initializeForm = (formData, formsJson, applId, errorMessages) => {
    let tabs = [] // navigation tabs
    let forms, application, formContext
    application = formsJson['app']
    forms = application['forms']

    for (let i = 0; i < forms.length; i++) {
      //Initialize tabs using uiSchema, with status set as pristine
      if (typeof forms[i].uiSchema['externalOptions'] !== 'undefined') {
        let options = forms[i].uiSchema['externalOptions']

        if (typeof options.tab !== 'undefined') {
          tabs.push({
            title: options.tab.tabName,
            leftIcon: options.tab.iconClassName,
            rightIcon: pristineIcon,
            rightSpan: null,
            status: 'pristine',
            visibleIf: options.tab.visibleIf,
            isVisible: true
          })
        }
        if (i === STEPS.RETROACTIVE_REQUEST) {
          let columnArray = []
          columnArray.push('Source')
          columnArray.push('Income Type')
          columnArray.push(
            _moment(config, this.state.currentDate)
              .subtract(1, 'months')
              .format('MMMM')
          )
          columnArray.push(
            _moment(config, this.state.currentDate)
              .subtract(2, 'months')
              .format('MMMM')
          )
          columnArray.push(
            _moment(config, this.state.currentDate)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.incomeRetroDetails.earnedIncomeRetro.ui:options.columns',
            columnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.incomeRetroDetails.unEarnedIncomeRetro.ui:options.columns',
            columnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.incomeRetroDetails.selfEmpIncomeRetro.ui:options.columns',
            columnArray
          )
          let expenseColumnArray = []
          expenseColumnArray.push('Start Date')
          expenseColumnArray.push('Share with someone')
          expenseColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          expenseColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          expenseColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.expenseRetroDetails.expenseRetro.ui:options.columns',
            expenseColumnArray
          )
          let cashColumnArray = []
          cashColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          cashColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          cashColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.cashRetro.ui:options.columns',
            cashColumnArray
          )
          let bankAccountColumnArray = []
          bankAccountColumnArray.push('Source')
          bankAccountColumnArray.push('Account Number')
          bankAccountColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          bankAccountColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          bankAccountColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.bankAccountRetro.ui:options.columns',
            bankAccountColumnArray
          )
          let vehicleColumnArray = []
          vehicleColumnArray.push('Make')
          vehicleColumnArray.push('Model')
          vehicleColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          vehicleColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          vehicleColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.vehicleRetro.ui:options.columns',
            vehicleColumnArray
          )
          let fundsColumnArray = []
          fundsColumnArray.push('Type')
          fundsColumnArray.push('Account Number')
          fundsColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          fundsColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          fundsColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.trustFundsRetro.ui:options.columns',
            fundsColumnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.investmentRetro.ui:options.columns',
            fundsColumnArray
          )
          let realEstateColumnArray = []
          realEstateColumnArray.push('Type')
          realEstateColumnArray.push(
            _moment(config)
              .subtract(1, 'months')
              .format('MMMM')
          )
          realEstateColumnArray.push(
            _moment(config)
              .subtract(2, 'months')
              .format('MMMM')
          )
          realEstateColumnArray.push(
            _moment(config)
              .subtract(3, 'months')
              .format('MMMM')
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.realEstateRetro.ui:options.columns',
            realEstateColumnArray
          )
          _.set(
            forms,
            i +
              '.uiSchema.Application.clients.items.retroDetails.resourceRetroDetails.otherRetro.ui:options.columns',
            realEstateColumnArray
          )
        }
      }
    }

    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      ...this.state.formContext,
      errorMessages,
      refs: {
        ...application['metaData']['refs'],
        '{cnty1}': 'return formContext.cnty1;',
        '{cnty}': 'return formContext.cnty;'
      },

      forms: forms,
      reviewForms: forms,
      immigrationUpdates: () => {
        this._immigrationUpdates()
      }
    }

    //Create a default empty application and merge it with our application data
    let newFormData
    if (!_.isEmpty(formData) && this.props.status != 'SUBMITTED') {
      newFormData = _.merge(
        helper.createObjectFromMasterSchema(null, formContext),
        formData
      )
      newFormData.Application.clients[0].address[0].adrLn1Txt =
        newFormData.Application.clients[0].adrLn1Txt
      newFormData.Application.clients[0].address[0].adrLn2Txt =
        newFormData.Application.clients[0].adrLn2Txt
      newFormData.Application.clients[0].address[0].adrLn3Txt =
        newFormData.Application.clients[0].adrLn3Txt
      newFormData.Application.clients[0].address[0].adrLn4Txt =
        newFormData.Application.clients[0].adrLn4Txt
      newFormData.Application.clients[0].address[0].aptNumber =
        newFormData.Application.clients[0].aptNumber
      newFormData.Application.clients[0].address[0].ctyNm =
        newFormData.Application.clients[0].ctyNm
      newFormData.Application.clients[0].address[0].ctyNm1 =
        newFormData.Application.clients[0].ctyNm1
      newFormData.Application.clients[0].address[0].st =
        newFormData.Application.clients[0].st
      newFormData.Application.clients[0].address[0].zip =
        newFormData.Application.clients[0].zip
      newFormData.Application.clients[0].address[0].zipExt =
        newFormData.Application.clients[0].zipExt
      newFormData.Application.clients[0].address[0].county =
        newFormData.Application.clients[0].county
      newFormData.Application.clients[0].isCovered =
        newFormData.Application.clients[0].isCovered
      newFormData.Application.fosterclients[0].fostercare =
        newFormData.Application.clients[0].fostercare
      newFormData.Application.fosterclients[0].fostercareNonWV =
        newFormData.Application.clients[0].fostercareNonWV
      newFormData.Application.benefitProgramInformations[0].isApproved =
        newFormData.Application.clients[0].isApproved
      newFormData.Application.benefitProgramInformations[0].isPregnantWhenApproved =
        newFormData.Application.clients[0].isPregnantWhenApproved
      newFormData.Application.benefitProgramInformations[0].pregEndDt =
        newFormData.Application.clients[0].pregEndDt
      newFormData.Application.benefitProgramInformations[0].isPregnancyEnded =
        newFormData.Application.clients[0].isPregnancyEnded
      newFormData.Application.benefitProgramInformations[0].isPregnantNow =
        newFormData.Application.clients[0].pregnant
      newFormData.Application.cancerclients[0].breastAndCervicalCancerPatients =
        newFormData.Application.clients[0].breastAndCervicalCancerPatients
      newFormData.Application.caretakerclients[0].parentorcaretaker =
        newFormData.Application.clients[0].caretakerclient

      this.props.updatePEApplicationStep(this.props.activeApplicationId, 1)
    } else {
      newFormData = helper.createObjectFromMasterSchema(null, formContext)
    }
    //Set the application ID
    if (applId !== '0') {
      _.set(newFormData, 'Application.applId', applId)
    }

    //Set the tenant ID
    if (config.tenant.code) {
      _.set(newFormData, 'Application.tenantId', config.tenant.code)
    }

    //Clear the review signature info.
    _.unset(newFormData, 'Application.agreement')
    _.unset(newFormData, 'Application.signatureReceived')

    //Default primary to relationship to "Self"
    //TODO: update once we are aligned to MCR
    const relationshipSchema = _.get(
      application.forms[0].schema,
      'definitions.client.properties.relationship'
    )
    if (relationshipSchema) {
      _.set(
        newFormData,
        'Application.clients[0].relationship',
        relationshipSchema.enum[relationshipSchema.enumNames.indexOf('Self')]
      )
    }

    if (!_.isEmpty(this.props.applicationData)) {
      if (!_.isEqual(newFormData, formData)) {
        this.props.updatePEApplication(applId, 'DRAFT', newFormData)
      }
    } else {
      this.props.addPEApplication(applId, 'DRAFT', newFormData)
    }
    let date = _moment(config, this.state.currentDate).format('MM/DD/YYYY')
    formContext = {
      ...formContext,
      formData: newFormData,
      reviewFormData: newFormData,
      setShowNext: this._setShowNext,
      imageMap: imageMap,
      component: this,
      currentDate: date,
      deleteOnHide: true, //This will cause form data to be removed when it becomes invisible via ui:visibleIf.
      deleteArrayOnHide: true, //This will cause form data to be removed when it becomes invisible via ui:eachVisibleIf.
      isUnitTest: this.props.isUnitTest
    }

    this.setState({ application, forms, tabs, formContext })
    this._defaultStateCounties()
    this._updateTabs()
  }

  _onFormDataChange = helper.safeDebounce(
    ({ formData }) => {
      const { tabs, forms } = this.state
      const { step } = this.props

      //Clear the review signature info if we changed anything other than that page.
      if (step != forms.length) {
        _.unset(formData, 'Application.agreement')
        _.unset(formData, 'Application.signatureReceived')
      }

      this.props.updatePEApplication(
        this.props.activeApplicationId,
        'DRAFT',
        formData
      )

      this._hasStateSelectionChanged(formData)

      if (tabs[step - 1].status !== 'editing') {
        this.setState({
          tabs: [
            ...tabs.slice(0, step - 1),
            {
              ...tabs[step - 1],
              status: 'editing',
              rightIcon: editingIcon,
              rightSpan: editingBadge
            },
            ...tabs.slice(step)
          ]
        })
      }
    },
    500,
    this.props.isUnitTest
  )

  _onValidate = (formData: object, errors: object) => {
    const { forms, tabs, liveValidate } = this.state
    const { step } = this.props
    //Call CP-specific validation library.
    validator.validatePE_CPForm(
      formData,
      errors,
      forms.length === step ? null : step,
      this.state.formContext.errorMessages
    )

    //Determine which tabs contain errors and update their statuses.
    let foundErrors = helper.findErrors(errors)

    if (foundErrors && foundErrors.length > 0) {
      let newTabs = tabs
      for (let i = 0; i < foundErrors.length; ++i) {
        try {
          let errorObject = JSON.parse(foundErrors[i])
          let errorStep = errorObject.step - 1
          if (newTabs[errorStep].status !== 'error') {
            newTabs[errorStep].status = 'error'
            newTabs[errorStep].rightIcon = errorIcon
            newTabs[errorStep].rightSpan = errorBadge
          }
        } catch (err) {
          console.error('Error on parsing errors', err)
        }
      }
    }

    return errors
  }

  _getApplicationData = () => {
    var applicationMap = this.props.applicationData.Application
    let myPEApplicationData = _.cloneDeep(peApplication)
    let applicationData = { Application: null, applId: null }
    myPEApplicationData.clients = [...applicationMap.clients]
    _.set(myPEApplicationData, 'clients[0].retroDetails', undefined)
    myPEApplicationData.clients = [
      {
        ...myPEApplicationData.clients[0],
        ...applicationMap.clients[0].address[0]
      }
    ]
    let {
      adrLn1Txt,
      adrLn2Txt,
      ctyNm,
      st,
      zip,
      county
    } = applicationMap.clients[0].address[0]
    _.set(myPEApplicationData, 'clients[0].address', {
      adrLn1Txt,
      adrLn2Txt,
      ctyNm,
      st,
      zip,
      county
    })
    _.set(
      myPEApplicationData,
      'clients[0].emailAddress1',
      applicationMap.clients[0].emailAddress1
    )
    _.set(myPEApplicationData, 'clients[0].optBenefitPE', true)
    _.set(
      myPEApplicationData,
      'clients[0].phones',
      applicationMap.clients[0].phones || []
    )
    _.set(
      myPEApplicationData,
      'clients[0].prefCntcMethod1Cd',
      applicationMap.clients[0].prefCntcMethod1Cd
    )
    _.set(
      myPEApplicationData,
      'clients[0].clientPreferedSpokenLanguag',
      applicationMap.clients[0].clientPreferedSpokenLanguag
    )
    _.set(
      myPEApplicationData,
      'clients[0].numberOfIndividualInTaxHousehold',
      applicationMap.clients[0].exptMagiIncm
    )
    _.set(
      myPEApplicationData,
      'clients[0].houseHoldIncome',
      applicationMap.clients[0].exptIncmThsYr
    )

    _.set(myPEApplicationData, 'clients[0].noIndivInTax', undefined)
    _.set(myPEApplicationData, 'clients[0].houseHoldEstIncome', undefined)
    myPEApplicationData.clients = [
      {
        ...myPEApplicationData.clients[0],
        ...applicationMap.benefitProgramInformations[0]
      }
    ]
    _.set(
      myPEApplicationData,
      'clients[0].pregnant',
      applicationMap.benefitProgramInformations[0].isPregnantNow
    )
    _.set(myPEApplicationData, 'clients[0].isPregnantNow', undefined)
    myPEApplicationData.clients = [
      { ...myPEApplicationData.clients[0], ...applicationMap.fosterclients[0] }
    ]
    myPEApplicationData.clients = [
      { ...myPEApplicationData.clients[0], ...applicationMap.cancerclients[0] }
    ]
    _.set(
      myPEApplicationData,
      'clients[0].caretakerclient',
      applicationMap.caretakerclients[0].parentorcaretaker
    )
    _.set(
      myPEApplicationData,
      'clientMedicalIdNm',
      applicationMap.clients[0].clientMedicalIdNm
    )
    _.set(
      myPEApplicationData,
      'applicationStartDate',
      applicationMap.clients[0].presumEligStartDate
    )
    _.set(myPEApplicationData, 'agreement', applicationMap.clients[0].agreement)

    _.set(myPEApplicationData, 'clients[0].clientMedicalIdNm', undefined)
    _.set(myPEApplicationData, 'clients[0].presumEligStartDate', undefined)
    _.set(myPEApplicationData, 'clients[0].agreement', undefined)
    applicationData.Application = myPEApplicationData
    applicationData.applId = null
    return applicationData
  }

  _saveAndExitHeader = ({ formData }) => {
    var submitUrl = submitEndpoint
    const { tabs, liveValidate, forms } = this.state
    const { step } = this.props
    let newTabs = helper.deepClone(this.state.tabs)
    this.setState({
      liveValidate: false,
      tabs: newTabs
    })
    let applicationData = this._getApplicationData()
    var applId = this.props.activeApplicationId
    if (applId != '0') {
      applicationData.applId = applId
    }
    applicationData.Application.Status = 'Draft'
    if (this.props.userAccess) {
      if (this.props.userAccess.selectedOrg) {
        applicationData.Application.organizationId = this.props.userAccess.selectedOrg.orgId
      }
    }
    var methodType = 'POST'
    if (applId != '0') {
      methodType = 'PUT'
      submitUrl = submitEndpoint + '/' + applId
    }
    const request = {
      method: methodType,
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        userroleid: this.props.roleId.toString(),
        tenantCode: config.tCode,
        Authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalName: config.portalName,
        roleId: this.props.roleId,
        orgId: this.props.orgId
      },
      body: JSON.stringify(applicationData)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(submitUrl, request, this.props.showErrorMessage)
      .then(data => {
        this.setState({ callingApi: false })
        if (data) {
          this.props.updatePEApplication(
            this.props.activeApplicationId,
            'DRAFT',
            this.props.applicationData
          )
          this.props.addPEApplication(
            data.result.Application.applId,
            data.resultType,
            data.result.Application
          )
          this.setState({
            errorMessage: 'You have successfully saved your changes.'
          })
          this.setState({ titleMessage: 'Your changes are being saved.' })
          this.setState({ btnText: 'OK' })
          this.setState({ showErrorModal: true })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
      })
  }

  _saveAndExit = ({ formData }) => {
    var submitUrl = submitEndpoint
    const { tabs, liveValidate, forms } = this.state
    const { step } = this.props
    let newTabs = helper.deepClone(this.state.tabs)
    newTabs[step - 1].status = 'validated'
    newTabs[step - 1].rightIcon = validatedIcon
    newTabs[step - 1].rightSpan = validatedBadge
    this.setState({
      liveValidate: false,
      tabs: newTabs,
      isExit: true
    })
    let applicationData = this._getApplicationData()
    var applId = this.props.activeApplicationId
    if (applId != '0') {
      applicationData.applId = applId
    }
    applicationData.Application.Status = 'Draft'
    var methodType = 'POST'
    if (applId != '0') {
      methodType = 'PUT'
      submitUrl = submitEndpoint + '/' + applId
    }
    const request = {
      method: methodType,
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        userroleid: this.props.roleId.toString(),
        tenantCode: config.tCode,
        Authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalName: config.portalName,
        roleId: this.props.roleId,
        orgId: this.props.orgId
      },
      body: JSON.stringify(applicationData)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(submitUrl, request, this.props.showErrorMessage)
      .then(data => {
        this.setState({ callingApi: false })
        if (data) {
          this.props.updatePEApplication(
            this.props.activeApplicationId,
            'DRAFT',
            this.props.applicationData
          )
          this.props.addPEApplication(
            data.result.Application.applId,
            data.resultType,
            data.result.Application
          )
          this.setState({ errorMessage: 'Thank you message' })
          this.setState({ titleMessage: 'Message' })
          this.setState({ btnText: 'Close' })
          this.setState({ showErrorModal: true })

          this.setState({ redirect: baseUrl + '/myaccount/applications' })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
      })
  }

  /* county code */

  _hasStateSelectionChanged = formData => {
    let primaryStateField = _.get(
      this,
      [
        'state',
        'formContext',
        'formData',
        'Application',
        'clients',
        0,
        'address',
        0,
        'st'
      ],
      ''
    )
    let updatedPrimaryStateField = _.get(
      formData,
      ['Application', 'clients', 0, 'address', 0, 'st'],
      ''
    )
    let secondaryStateField = _.get(
      this,
      [
        'state',
        'formContext',
        'formData',
        'Application',
        'clients',
        0,
        'secondaryAddress',
        'st'
      ],
      ''
    )
    let updatedSecondaryStateField = _.get(
      formData,
      ['Application', 'clients', 0, 'secondaryAddress', 'st'],
      ''
    )

    if (
      _.isEqual(primaryStateField, updatedPrimaryStateField) &&
      !_.isEmpty(primaryStateField && updatedPrimaryStateField) &&
      !_.isEqual(updatedPrimaryStateField, 'WV')
    ) {
      this._primaryStateCounty(updatedPrimaryStateField)
    } else if (_.isEqual(updatedPrimaryStateField, 'WV')) {
      this.setState({
        formContext: {
          ...this.state.formContext,
          cnty1: this.state.wvCounties.counties
        }
      })
    }
    if (
      _.isEqual(secondaryStateField, updatedSecondaryStateField) &&
      !_.isEmpty(secondaryStateField, updatedSecondaryStateField) &&
      !_.isEqual(updatedSecondaryStateField, 'WV')
    ) {
      this._secondaryStateCounty(updatedSecondaryStateField)
    } else if (_.isEqual(updatedSecondaryStateField, 'WV')) {
      this.setState({
        formContext: {
          ...this.state.formContext,
          cnty: this.state.wvCounties.counties
        }
      })
    }
  }

  _secondaryStateCounty = state => {
    const request = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantcode: config.tCode,
        authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalname: config.portalName,
        roleId: this.props.roleId,
        orgId: this.props.orgId
      }
    }
    const fetchEndpoint = CountiesBaseUrl.replace('{state}', state)
    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      .then(counties => {
        this.setState({ callingApi: false })
        if (counties) {
          this.setState(prevState => {
            return {
              ...prevState,
              formContext: {
                ...prevState.formContext,
                cnty: counties.counties
              }
            }
          })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
      })
  }

  _defaultStateCounties = () => {
    const request = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantcode: config.tCode,
        authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalname: config.portalName
      }
    }
    const fetchEndpoint = CountiesBaseUrl.replace('{state}', 'WV')
    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      .then(counties => {
        this.setState({ callingApi: false, wvCounties: counties })
        if (counties) {
          this.setState(prevState => {
            return {
              ...prevState,
              formContext: {
                ...prevState.formContext,
                cnty1: counties.counties,
                cnty: counties.counties
              }
            }
          })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
      })
  }

  _primaryStateCounty = state => {
    const request = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantcode: config.tCode,
        authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalname: config.portalName
      }
    }
    const fetchEndpoint = CountiesBaseUrl.replace('{state}', state)
    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      .then(counties => {
        this.setState({ callingApi: false })
        if (counties) {
          this.setState(prevState => {
            return {
              ...prevState,
              formContext: {
                ...prevState.formContext,
                cnty1: counties.counties
              }
            }
          })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
      })
  }

  _submitFormData = endpoint => {
    var submitUrl = submitEndpoint
    let applicationData = this._getApplicationData()
    var applId = this.props.activeApplicationId
    if (applId != '0') {
      applicationData.applId = applId
    }
    if (this.props.userAccess) {
      if (this.props.userAccess.selectedOrg) {
        applicationData.Application.organizationId = this.props.userAccess.selectedOrg.orgId
      }
    }
    applicationData.Application.Status = 'Complete'

    var methodType = 'POST'
    if (applId != '0') {
      methodType = 'PUT'
      submitUrl = submitEndpoint + '/' + applId
    }
    const request = {
      method: methodType,
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        userroleid: this.props.roleId.toString(),
        tenantCode: config.tCode,
        Authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalName: config.portalName,
        roleId: this.props.roleId,
        orgId: this.props.orgId
      },
      body: JSON.stringify(applicationData)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(submitUrl, request, this.props.showErrorMessage)
      .then(data => {
        this.setState({ callingApi: false })
        if (data) {
          this.setState({ formDataSubmitted: true })
          this.props.updatePEApplication(
            this.props.activeApplicationId,
            'DRAFT',
            this.props.applicationData
          )
          this.setState({ applId: data.result.Application.applId })
          this.setState({ uuid: this.props.auth.userAccount.uuid })
          this.setState({ userRoleId: this.props.roleId })
          this.setState({ redirectUrl: baseUrl + '/myaccount/dashboard' })
          this.props.addPEApplication(
            data.result.Application.applId,
            data.resultType,
            data.result.Application
          )
          this.setState({ isComplete: true })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
      })
  }

  _modalCloseCallback = () => {
    this.setState({ isComplete: false })
  }

  _onSubmit = ({ formData }) => {
    // This executes after validation has passed.
    const { tabs, liveValidate, forms, isExit } = this.state
    const { step } = this.props

    //Set tab(s) to validated status
    let newTabs = helper.deepClone(this.state.tabs)

    if (step === forms.length) {
      for (let i = 0; i < forms.length; ++i) {
        newTabs[i].status = 'validated'
        newTabs[i].rightIcon = validatedIcon
        newTabs[i].rightSpan = validatedBadge
      }
      if (this.state.isExit == false) {
        this.setState(
          {
            liveValidate: false,
            tabs: newTabs
          },
          () => this._submitFormData(submitEndpoint)
        )
      }
    } else {
      newTabs[step - 1].status = 'validated'
      newTabs[step - 1].rightIcon = validatedIcon
      newTabs[step - 1].rightSpan = validatedBadge
      this.setState(
        {
          liveValidate: false,
          tabs: newTabs
        },
        () => this._onNext()
      )
    }
  }

  _onNext = () => {
    // increment step in forms
    const { tabs, forms } = this.state
    const { step, activeApplicationId, updatePEApplicationStep } = this.props
    let nextStep = step + 1
    switch (nextStep) {
      case 1:
        document.title = 'Personal - Presumptive Eligibility'
        break
      case 2:
        document.title = 'Citizenship and Residency - Presumptive Eligibility'
        break
      case 3:
        document.title = 'Household and Income - Presumptive Eligibility'
        break
      case 4:
        document.title = 'Additional Questions - Presumptive Eligibility'
        break
    }

    while (!tabs[nextStep - 1].isVisible) {
      nextStep++
    }

    if (nextStep > forms.length) {
      return
    }

    const nextShowNext = nextStep >= 1

    updatePEApplicationStep(activeApplicationId, nextStep)
    this.setState({
      tabs: [
        ...tabs.slice(0, step - 1),
        {
          ...tabs[step - 1],
          status: 'validated',
          rightIcon: validatedIcon,
          rightSpan: validatedBadge
        },
        ...tabs.slice(step)
      ],
      showNext: nextShowNext
    })

    //Save draft copy
    this._saveFormDataDraft()

    // return to top of form
    this._toTop()
  }

  // Goes to previous step while saving formData
  _onPrevious = () => {
    const { tabs, forms } = this.state
    const { step } = this.props

    // check if step is already at the beginning
    if (step === 1) return false

    // decrease step and save formData
    let previousStep = step - 1

    while (!tabs[previousStep - 1].isVisible) {
      previousStep--
    }

    if (previousStep > forms.length) {
      return
    }

    //Set the previous tab to editing status.
    this._changeStep(previousStep)

    // return to top of form
    this._toTop()
  }

  // changing step of application, sets whether or not it should show the next button
  _changeStep = step => {
    switch (step) {
      case 1:
        document.title = 'Personal - Presumptive Eligibility'
        break
      case 2:
        document.title = 'Citizenship and Residency - Presumptive Eligibility'
        break
      case 3:
        document.title = 'Household and Income - Presumptive Eligibility'
        break
      case 4:
        document.title = 'Retroactive Request - Presumptive Eligibility'
        break
      case 5:
        document.title = 'Additional Questions - Presumptive Eligibility'
        break
    }
    const { activeApplicationId, updatePEApplicationStep } = this.props
    updatePEApplicationStep(activeApplicationId, step)
    this._setShowNext(step >= 1)
  }

  _setShowNext = next => {
    this.setState({ showNext: next })
  }

  _toTop = () => {
    window.location.href = '#'
  }

  _errorListTemplate = (props: any) => {
    const { errors, schema, uiSchema } = props

    const masterSchema = helper.resolveSchemaDefinitions(
      schema,
      schema.definitions
    )

    const orderedErrors = this.orderErrors(errors, masterSchema, uiSchema)

    return (
      <div className="panel panel-danger errors">
        <div className="panel-heading">
          <h3 className="panel-title">Errors</h3>
        </div>
        <ul className="list-group">
          {orderedErrors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.
            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this._onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  orderErrors = (errors, masterSchema, uiSchema): any[] => {
    const orderArray = _.uniq(this.buildOrderArray(masterSchema))

    const indexRegex = new RegExp(/_([0-9]+)_/)
    const errorKeyMsgRegex = new RegExp(/^([a-zA-Z0-9]+): (.*)/)

    const newErrorStackQueue = []
    errors.map(error => {
      const errorResult = error.stack.match(errorKeyMsgRegex)
      const errorKey = errorResult[1]
      let errorContentsObj
      try {
        errorContentsObj = JSON.parse(errorResult[2])
      } catch (err) {
        errorContentsObj = {}
      }

      const index = _.get(
        _.get(errorContentsObj, 'id', '').match(indexRegex),
        '1',
        '0'
      )

      if (newErrorStackQueue[index] === undefined) {
        for (let i = 0; i <= index; i++) {
          if (newErrorStackQueue[i] === undefined) {
            newErrorStackQueue.push([])
          }
        }
      }

      newErrorStackQueue[index].push({
        index: _.indexOf(orderArray, errorKey),
        value: errorKey,
        stack: error.stack
      })
    })

    let tempErrorObjList = []
    for (let i = 0; i < newErrorStackQueue.length; i++) {
      tempErrorObjList = _.concat(
        tempErrorObjList,
        _.sortBy(newErrorStackQueue[i], ['index'])
      )
    }

    const orderedErrors = _.forEach(tempErrorObjList, obj => obj.stack)

    return orderedErrors
  }

  buildOrderArray = (localSchema, orderArray = []) => {
    switch (localSchema['type']) {
      case 'object':
        this.buildOrderArray(localSchema['properties'], orderArray)
        break
      case 'array':
        this.buildOrderArray(localSchema['items'], orderArray)
        break
      case undefined:
        if (_.isObject(localSchema)) {
          _.forEach(localSchema, (v, k) => {
            switch (v['type']) {
              case 'object':
                if (
                  _.isEmpty(
                    _.xor(_.keys(v['properties']), [
                      'category',
                      'subCategory',
                      'languageCode',
                      'key',
                      'value',
                      'rulesEngineCode',
                      'sortOrder'
                    ])
                  )
                ) {
                  orderArray.push(k)
                  break
                }
                this.buildOrderArray(v['properties'], orderArray)
                break
              case 'array':
                if (
                  _.isEmpty(
                    _.xor(_.keys(_.get(v, 'items.properties')), [
                      'category',
                      'subCategory',
                      'languageCode',
                      'key',
                      'value',
                      'rulesEngineCode',
                      'sortOrder'
                    ])
                  ) ||
                  _.get(v, 'items.enumNames', []).length > 0
                ) {
                  orderArray.push(k)
                  break
                }
                this.buildOrderArray(v['items'], orderArray)
                break
              default:
                orderArray.push(k)
            }
          })
        } else {
          console.log('buildOrderArray localSchema not object', localSchema)
        }
    }

    return orderArray
  }

  _onErrorClick = (error: any) => {
    const { forms } = this.state
    const { step, activeApplicationId, updatePEApplicationStep } = this.props

    //Change current step to the step of the clicked error.
    if (step != error.step) {
      updatePEApplicationStep(activeApplicationId, error.step)
    }

    //Set a viewId state to be used for scrolling down after the React DOM finishes updating.
    this.setState(
      {
        viewId:
          forms[error.step - 1].uiSchema['ui:rootFieldId'] + '_' + error.id
      },
      () => {
        document.getElementById(this.state.viewId).focus()
      }
    )
  }

  _onError = (errors: object) => {
    const { liveValidate } = this.state

    //Enable live validation upon error to allow for realtime evaluation of errors in response to formData changes.
    if (!liveValidate) {
      this.setState({ liveValidate: true })
      this._toTop()
    }
  }

  _transformErrors = (errors: object) => {
    return errors
  }

  _setRedirect = url => {
    this.props.setActivePEApplicationId('0')
    this.setState({ redirect: baseUrl + url })
  }

  updateUploadedDocs(clients) {
    this.setState(
      {
        formContext: {
          ...this.state.formContext,
          formData: {
            ...this.state.formContext.formData,
            Application: {
              ...this.state.formContext.formData.Application,
              clients
            }
          }
        }
      },
      () => {
        this._onFormDataChange({
          formData: {
            Application: this.state.formContext.formData.Application,
            applId: this.props.applicationData.applId
          }
        })
      }
    )
  }

  _onDocUpload = uploadedDoc => {
    const clients = this.state.formContext.formData.Application.clients.slice()
    const client = clients[uploadedDoc.clientIndex]
    if (client.myDocuments) {
      client.myDocuments[uploadedDoc.documentClssIndex].docList.push(
        uploadedDoc.doc
      )
    }
    this.updateUploadedDocs(clients)
  }

  _onUploadDelete = deletedDoc => {
    const clients = this.state.formContext.formData.Application.clients.slice()
    for (let i = 0; i < clients.length; i++) {
      const client = clients[i]
      const clientName =
        client.clientFstNm +
        (client.clientLstNm ? ' ' + client.clientLstNm : '')
      if (deletedDoc.clientName === clientName) {
        client.myDocuments[deletedDoc.documentClssIndex].docList.splice(
          deletedDoc.docIndex,
          1
        )
        break
      }
    }

    this.updateUploadedDocs(clients)
  }

  _updateTabs = () => {
    const { tabs, forms, formContext } = this.state

    if (!_.isEmpty(tabs)) {
      let newTabs = tabs.map(element => {
        let isVisible
        if (typeof element['visibleIf'] === 'boolean') {
          isVisible = element['visibleIf']
        } else {
          isVisible = false
          if (!_.isEmpty(this.props.applicationData)) {
            try {
              var f = new Function(
                'formData',
                'return (' +
                  helper.resolveStringRefs(
                    element['visibleIf'],
                    formContext,
                    'root',
                    null,
                    null,
                    null
                  ) +
                  ')'
              )
              isVisible = f(this.props.applicationData)
            } catch (err) {
              console.error(
                'Error in AppIntake tabs visibleIf check',
                element['visibleIf'],
                err
              )
            }
          }
        }
        return {
          ...element,
          isVisible
        }
      })
      if (!_.isEqual(newTabs, tabs)) {
        this.setState({ tabs: newTabs })
      }
    }
  }

  _showExit = () => {
    this.setState({ showExitModal: true })
  }

  _hideError = () => {
    this.setState({ showErrorModal: false })
  }

  _confirmExit = () => {
    this.setState({
      showExitModal: false,
      //changed to dashboard from applications: defect# 3090
      redirect: baseUrl + '/myaccount/dashboard'
    })
  }

  _cancelExit = () => {
    this.setState({ showExitModal: false })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { from: this.props.location }
          }}
        />
      )
    }

    const { presentation, step, serviceErrors } = this.props
    const { forms, formContext } = this.state
    let validator = null
    if (forms && step) {
      validator = new CustomValidator(
        forms[step - 1].schema,
        forms[step - 1].uiSchema,
        formContext
      )
    }

    const presentationProps = {
      STEPS,
      ..._.omit(this.props, 'presentation'),
      ...this.state,
      validator,
      onPrevious: this._onPrevious,
      saveFormDataDraft: this._saveFormDataDraft,
      changeView: this._changeView,
      onDocUpload: this._onDocUpload,
      onUploadDelete: this._onUploadDelete,
      onNext: this._onNext,
      onSubmit: this._onSubmit,
      onFormDataChange: this._onFormDataChange,
      onValidate: this._onValidate,
      errorListTemplate: this._errorListTemplate,
      onError: this._onError,
      transformErrors: this._transformErrors,
      setRedirect: this._setRedirect,
      submitFormData: this._submitFormData,
      showExit: this._showExit,
      confirmExit: this._confirmExit,
      cancelExit: this._cancelExit,
      saveExit: this._saveAndExit,
      isDisable: this.state.isDisable,
      hideErrorModal: this._hideError,
      saveExitFromHeader: this._saveAndExitHeader,
      roleId: this.props.roleId,
      serviceErrors,
      onDeleteClick: this._onDeleteClick,
      userRoleId: this.props.userRoleId,
      accessToken: this.props.auth.accessToken,
      history: this.props.history,
      logoutUser: this.props.logoutUser,
      modalCloseCallback: this._modalCloseCallback
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let activeApplicationId = '0'
  var actionType = ownProps.history.action
  let applicationData = {}
  let step = 1
  let status = null
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  if (state.peApplications) {
    if (actionType == 'PUSH') {
      activeApplicationId = '0'
    } else if (actionType != 'PUSH') {
      activeApplicationId = state.peApplications.activeApplicationId || '0'
    }

    if (
      state.peApplications.applicationsArray &&
      state.peApplications.applicationsArray.length > 0
    ) {
      const peApplication = _.find(
        state.peApplications.applicationsArray,
        function(element) {
          return element['applId'] === activeApplicationId
        }
      )
      if (peApplication) {
        applicationData = peApplication['applicationData'] || {}
        step = peApplication['step'] || 1
        status = peApplication['status']
      }
    }
  }
  let userId = _.get(state.auth, 'userAccount.userId') || ''
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId')
  let roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  let selectedEntitlements = _.get(
    state.userAccess,
    'selectedUserRole.entitlements',
    []
  ).map(element => element.entitlementName)
  return {
    auth: state.auth,
    activeApplicationId,
    applicationData,
    status,
    userRoleId,
    step,
    userId,
    roleId,
    userAccess: state.userAccess,
    orgId,
    selectedEntitlements,
    serviceErrors: state.myMessagesError.myMessagesError.errorMessage || [],
    onDeleteClick: this._onDeleteClick,
    locale: state.i18n.locale
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addPEApplication: (applId, status, applicationData) => {
      dispatch(actions.addPEApplication(applId, status, applicationData))
    },
    removePEApplication: applId => {
      dispatch(actions.removePEApplication(applId))
    },
    updatePEApplication: (applId, status, applicationData) => {
      dispatch(actions.updatePEApplication(applId, status, applicationData))
    },
    viewPEApplications: () => {
      dispatch(actions.viewPEApplications())
    },
    setActivePEApplicationId: applId => {
      dispatch(actions.setActivePEApplicationId(applId))
    },
    assignPEApplicationId: applId => {
      dispatch(actions.assignPEApplicationId(applId))
    },
    updatePEApplicationStep: (applId, step) => {
      dispatch(actions.updatePEApplicationStep(applId, step))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppIntakePEContainer)
