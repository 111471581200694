import * as React from 'react'
import { connect } from 'react-redux'
import { sortBy, findIndex, isNil, get, isEqual } from 'lodash'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'

const _ = { sortBy, findIndex, isNil, get, isEqual }

declare var process
const baseUrl = process.env.PUBLIC_URL

interface HelpTab {
  id: string
  title: string
  component: React.ReactNode
}

interface HelpContainerProps {
  presentation: any
  location: {
    search: string
    pathname: string
  }
  history: {
    push: (url: string) => void
  }
  HelpTabs: Array<HelpTab>
  locale: string
  uuid: string
  authHeader: string
}

interface HelpContainerState {
  id: string
  minHeight: number
  navTabs: Array<HelpTab>
  prevProps: any
}

const navigationTabs = helpTabs => {
  let sortedTabs = [...helpTabs]
  sortedTabs = sortTabsForClient(sortedTabs)
  return sortedTabs
}

const sortTabsForClient = tabs => {
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'commonQuestions':
          return 1
        case 'applicationprocess':
          return 2
        case 'myAccount':
          return 3
        case 'lifeChanges':
          return 4
        case 'morehelp':
          return 5
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

class HelpContainer extends React.Component<
  HelpContainerProps,
  HelpContainerState
> {
  private navDiv: HTMLDivElement

  constructor(props: HelpContainerProps) {
    super(props)
    this.navDiv = (React as any).createRef()
    this.state = {
      id: '',
      minHeight: 0,
      navTabs: null,
      prevProps: null
    }
  }

  static getDerivedStateFromProps(
    props: HelpContainerProps,
    state: HelpContainerState
  ) {
    if (!state.prevProps || props.HelpTabs != state.prevProps.helpTabs) {
      if (!props.HelpTabs || props.HelpTabs.length === 0) {
        return null
      }
      const navTabs = navigationTabs(props.HelpTabs)
      let newId = state.id
      if (navTabs && navTabs.length > 0) {
        if (!newId || newId === '') {
          const subPath = window.location.href.match('\\/help\\/([a-zA-Z0-9]+)')
          if (subPath && subPath[1]) {
            const tabIndex = _.findIndex(navTabs, navTab => {
              return subPath[1] === navTab.id
            })
            if (tabIndex > -1) {
              newId = navTabs[tabIndex].id
            } else {
              newId = navTabs[0].id
            }
          } else {
            newId = navTabs[0].id
          }
        } else {
          const tabIndex = _.findIndex(navTabs, navTab => {
            return newId === navTab.id
          })
          if (tabIndex === -1) {
            newId = navTabs[0].id
          }
        }
      } else {
        newId = ''
      }
      return {
        navTabs,
        id: newId,
        prevProps: props
      }
    } else {
      return null
    }
  }

  componentDidMount() {
    let sideNavLink = this._createSkipLink(
      '#side-nav',
      'skip-to-side-nav',
      I18n.t('Help.skipNav')
    )
    const navContent = document.getElementById('nav-content')
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      navContent.insertBefore(sideNavLink, mainNavContent)
    }
    if (this.navDiv && this.navDiv.clientHeight) {
      this.setState({ minHeight: this.navDiv.clientHeight })
    }
    if (this.props.history) {
      if (
        (this.state.id && this.state.id !== '') ||
        !this.props.location.pathname.match(baseUrl + '/help')
      ) {
        if (!this.props.location.pathname.match(baseUrl + '/help')) {
          this.props.history.push(baseUrl + '/help')
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      if (navContent) {
        if (sideNavLink) {
          sideNavLink.textContent = I18n.t('Help.skipNav')
        }
      }
    }
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      if (!sideNavLink) {
        let sideNavLink = this._createSkipLink(
          '#side-nav',
          'skip-to-side-nav',
          I18n.t('Help.skipNav')
        )
        navContent.insertBefore(sideNavLink, mainNavContent)
      }
    }
    if (
      this.navDiv &&
      this.navDiv.clientHeight &&
      this.navDiv.clientHeight !== this.state.minHeight
    ) {
      this.setState({ minHeight: this.navDiv.clientHeight })
    }
    if (this.state.id && this.state.id !== '') {
      if (!this.props.location.pathname.match(baseUrl + '/help')) {
        this.props.history.push(baseUrl + '/help')
      }
    }
  }

  componentWillUnmount() {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    if (navContent) {
      if (sideNavLink) {
        navContent.removeChild(sideNavLink)
      }
    }
  }

  _onNavClick = tabId => {
    this.props.history.push(baseUrl + '/help')
    this.setState({ id: tabId })
    document.getElementById('content').focus()
  }

  _createSkipLink = (href, id, text = '') => {
    let skipLink = document.createElement('a')
    skipLink.setAttribute('class', 'skip')
    skipLink.setAttribute('href', href)
    skipLink.setAttribute('id', id)
    if (text) {
      let skipLinkText = document.createTextNode(text)
      skipLink.appendChild(skipLinkText)
    }
    return skipLink
  }

  render() {
    const { presentation, locale, uuid, authHeader } = this.props
    const { id, minHeight, navTabs } = this.state
    if (_.isNil(navTabs)) {
      return <div>{I18n.t('General.loading')}</div>
    }
    let subProps = {
      locale,
      minHeight: minHeight ? minHeight + 'px' : undefined,
      uuid,
      authHeader
    }
    const presentationProps = {
      id,
      subProps,
      navTabs,
      onNavClick: this._onNavClick,
      setNavDiv: el => {
        this.navDiv = el
      },
      locale
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    locale: state.i18n.locale,
    uuid,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

export default connect(mapStateToProps)(HelpContainer)
