import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { I18n } from 'react-redux-i18n'

interface MyPermissionPresentationProps {
  application: any
  formData: any
  formContext: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  isLoading: boolean
  onFormDataChange: (formData: any) => void
  updatePermissions: () => void
  minHeight: string
  formComponents
  onDeleteClick: () => void
  errorMessage: Array<any>
}

export default function MyPermissionPresentation(
  props: MyPermissionPresentationProps
) {
  const {
    application,
    formData,
    formContext,
    forms = [],
    isLoading,
    onFormDataChange,
    updatePermissions,
    minHeight,
    formComponents,
    errorMessage,
    onDeleteClick
  } = props

  const header = (
    <div>
      <h3>
        <div style={{ display: 'table' }}>
          <h1 className="title-header auto-table">
            <i className="pe-7s-key" />
            {I18n.t('MyPermission.myPermissions')}
          </h1>
        </div>
      </h3>
    </div>
  )
  return (
    <div>
      {header}
      <ErrorModal
        errorMessage={props.errorMessage}
        onDeleteClick={props.onDeleteClick}
      />
      <div>
        {forms && formData && formData.length > 0 ? (
          <Form
            className="body-content container-fluid"
            style={{ height: 1519.2, width: 900.2 }}
            schema={forms[0]['schema']}
            uiSchema={forms[0]['uiSchema']}
            formData={formData}
            formContext={formContext}
            onChange={onFormDataChange}
            fields={formComponents.fields}
            onSubmit={updatePermissions}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          >
            <button
              type="submit"
              className="btn btn-primary float-right update-focus"
              style={{ margin: '10px' }}
            >
              {I18n.t('MyPermission.update')}
            </button>
          </Form>
        ) : (
          <p> {I18n.t('MyPermission.noCaseToView')}</p>
        )}
      </div>
    </div>
  )
}
