import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import {
  isEqual,
  set,
  get,
  isObject,
  isEmpty,
  mergeWith,
  cloneDeep,
  findIndex,
  trim,
  trimStart
} from 'lodash'
import { connect } from 'react-redux'
import { config } from '../../../config' // endpoints
import { I18n } from 'react-redux-i18n'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'
//import * as providersFormsJson from './__mockForms__/myProviders.json'

const _ = {
  isEqual,
  set,
  get,
  isObject,
  isEmpty,
  mergeWith,
  cloneDeep,
  findIndex,
  trim,
  trimStart
}

let $injector

const loadData = true

const initialLoadEndpoint = config['myProviderInitialLoad']
const selectedLoadEndpoint = config['myProviderSelectedLoad']
const submitInfoEndpoint = config['myProviderInfoSubmit']
const submitEnrollmentEndpoint = config['submitEnrollments']
const deleteInfoEndpoint = config['myProviderInfoDelete']
const samesaSProvider = config['myProviderSamesaSProvider']
const formsEndpoint = config['forms_MyProviders']
const openEnrollmentEndPoing = config['openenrollment']

//request forms
let MyEnrollment = {
  caseId: null,
  clientId: null,
  providerType: null,
  providerName: null,
  providerId: null,
  typeCode: null,
  providerPhone: {
    providerTelId: null,
    provId: null,
    telId: null,
    phone: {
      telephoneId: null,
      telTypCd: null,
      telNumber1: null,
      cntryCd: null,
      telExt: null
    }
  },
  providerAddress: {
    providerAddressId: null,
    adrId: null,
    provId: null,
    address: {
      addressId: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: '',
      addressLine4: '',
      city: null,
      state: null,
      zipcode: null,
      county: null
    }
  },
  mangHealthCareOrg: null,
  enrollmentSourceCd: null,
  programName: null,

  recordEndDate: null,

  referralDate: null,
  tenantId: null
}

interface MyProvidersContainerProps {
  presentation: any
  uuid: string
  minHeight: string
  locale: string
  authHeader: string
}

interface MyProvidersContainerState {
  CRFMapReact: React.ReactNode
  forms: Array<{
    schema: any
    uiSchema: any
  }>
  formData: any
  formContext: any
  mapProps: {
    member: any
    crfProvider: any
    features: any
  }
  noSelectedBenefit: boolean
  showMap: boolean
  showMapAddress: boolean
  providerMapping: any
  initialDataLoad: boolean
  benfProgCd: string
  isChange: boolean
  oldForms: Array<{
    schema: any
    uiSchema: any
  }>
  showAddrFlag: boolean
  showCRFMap: boolean
  isSearchEnabled: boolean
  isAddressChanged: boolean
  previousMapProps: {
    member: any
    crfProvider: any
    features: any
  }
  callingApi: boolean
}

class MyProvidersContainer extends React.Component<
  MyProvidersContainerProps,
  MyProvidersContainerState
> {
  private crfContainer: HTMLDivElement

  constructor(props: MyProvidersContainerProps) {
    super(props)
    //this.crfContainer = (React as any).createRef();
    this.state = {
      CRFMapReact: null,
      forms: null,
      formData: null,
      formContext: {},
      mapProps: null,
      noSelectedBenefit: false,
      showMap: false,
      showMapAddress: false,
      providerMapping: null,
      initialDataLoad: false,
      benfProgCd: null,
      isChange: true,
      oldForms: null,
      showAddrFlag: false,
      showCRFMap: false,
      isSearchEnabled: false,
      isAddressChanged: false,
      previousMapProps: null,
      callingApi: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      this.setState({ callingApi: true })
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(formsJson => {
          this.setState({ callingApi: false })
          const application = formsJson['app']
          const forms = application['forms']
          const formContext = {
            ...this.state.formContext,
            forms
          }
          this.setState({ forms, formContext })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('MyProviders form re-fetch failed due to ex', error)
          const code = CODES.MY_PROVIDERS_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _getInitialLoadDataJSON = (tempJson: Object) => {
    helper
      .fetchJson(initialLoadEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        }
      })
      .then(results => {
        // load json into form
        let newJson = helper.deepClone(tempJson)
        let enumValue = []
        let enumNamesValue = []
        let enumNameCd = []
        let jsonArray = JSON.parse(JSON.stringify(results))

        for (var k = 0; k < jsonArray.length; k++) {
          enumValue.push(jsonArray[k].appId + k)
          enumNamesValue.push(jsonArray[k].benefitLongName)
          enumNameCd.push(jsonArray[k].benefitCd)
        }

        //Modify newJson
        _.set(
          newJson,
          'app.forms[0].schema.definitions.myProviders.properties.selectedBenefit.enum',
          enumValue
        )
        _.set(
          newJson,
          'app.forms[0].schema.definitions.myProviders.properties.selectedBenefit.enumNames',
          enumNamesValue
        )
        _.set(
          newJson,
          'app.forms[0].schema.definitions.myProviders.properties.selectedBenefit.enumNamesCd',
          enumNameCd
        )

        this._loadForm(newJson)
      })
      .catch(error => {
        console.error(
          'MyProviders _getInitialLoadDataJSON failed with ex',
          error
        )
        //TODO: fix the CORS error.  Also we are just using pre-fiilled data anyway?
        const code = CODES.MY_PROVIDERS_INITIAL_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        } else {
          this._loadForm(tempJson)
        }
      })
  }

  _loadForm = (json: Object) => {
    let forms, application, formData, formContext, initialDataLoad, enumArray
    application = json['app']
    forms = application['forms']
    enumArray = _.get(
      forms[0].schema,
      'definitions.myProviders.properties.selectedBenefit.enum'
    )

    if (enumArray && enumArray[0] != null) {
      initialDataLoad = true
    }

    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      refs: application['metaData']['refs'],
      forms: forms,
      component: { OnLinkClick: this._saveEnrollment },
      setCRFEnable: this._setCRFEnable,
      config
    }

    //load the pre-filled form data
    let formDataLoad = application['formData']

    if (loadData && _.isObject(formDataLoad) && !_.isEmpty(formDataLoad)) {
      //Merge the pre-filled form data with an empty default object to ensure necessary objects are available for RJSF rendering.
      formData = _.mergeWith(
        helper.createObjectFromMasterSchema(null, formContext),
        formDataLoad,
        function(objValue, srcValue) {
          if (srcValue == null && objValue == undefined) {
            return {}
          }
          return undefined
        }
      )
    } else {
      //New application, initialize to empty object
      formData = helper.createObjectFromMasterSchema(null, formContext)
    }

    helper.cleanNullValues(formData)

    formContext = {
      ...formContext,
      formData: formData,
      isChange: this.state.isChange,
      showAddrFlag: this.state.showAddrFlag
    }

    formContext = { ...formContext, selectProvider: this._selectProvider }

    formContext = { ...formContext, sameasProvider: this._sameasProvider }

    formContext = { ...formContext, deleteProvider: this._deleteProvider }

    formContext = { ...formContext, showMap: this._showMap }

    //		formContext = ({ ...formContext, 'showAddrFlag': this.state.showMap });

    formContext = { ...formContext, toggleShowText: this._toggleShowText }

    if (initialDataLoad != undefined) {
      this.setState({ forms, formData, formContext, initialDataLoad })
    } else {
      this.setState({ forms, formData, formContext })
    }
  }

  _selectProvider = (providerMapping: any) => {
    const { showCRFMap, isAddressChanged } = this.state
    const mapProps = {
      member: providerMapping.memberData,
      crfProvider: null,
      features: {
        intialview: 'map',
        travelRadius: true,
        addressChange: true,
        countyServed: false,
        flyoutmenu: false,
        tenant: 'IE-CP',
        selectProvider: true
      }
    }

    this.setState(prevState => {
      return { mapProps, previousMapProps: this.state.mapProps }
    })
  }

  _sameasProvider = (selectedIndex: number, index: number) => {
    let newFormData = helper.deepClone(this.state.formData)
    let newForms = helper.deepClone(this.state.forms)

    let applId =
      newFormData.Application.myProviders.providerMapping[selectedIndex]
        .appliedBenefit
    let clientId =
      newFormData.Application.myProviders.providerMapping[selectedIndex]
        .memberData.needs[0].clientId

    let providerId =
      newFormData.Application.myProviders.providerMapping[index].memberData
        .needs[0].details[0].providerId
    let providerName =
      newFormData.Application.myProviders.providerMapping[index].providerName

    let copiedAddressLine1 =
      newFormData.Application.myProviders.providerMapping[index].memberData
        .needs[0].addresses[0].addressLine1
    let copiedAddressLine2 =
      newFormData.Application.myProviders.providerMapping[index].memberData
        .needs[0].addresses[0].addressLine2
    let copiedPhone =
      newFormData.Application.myProviders.providerMapping[index].memberData
        .needs[0].addresses[0].phone
    let copiedWebsite =
      newFormData.Application.myProviders.providerMapping[index].memberData
        .needs[0].addresses[0].website
    let copiedHours =
      newFormData.Application.myProviders.providerMapping[index].memberData
        .needs[0].addresses[0].hours

    const fetchEndpoint = samesaSProvider
      .replace('{applId}', applId)
      .replace('{clientId}', clientId)
      .replace('{providerId}', providerId)
      .replace('{benfProgCd}', this.state.benfProgCd)
    helper
      .fetchJson(fetchEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        },
        method: 'POST'
      })
      .then(res => {
        let enumName = []
        enumName.push('{new provider}')
        let enumNameValue = []
        enumNameValue.push('New Provider')
        _.set(
          newFormData,
          'Application.myProviders.providerMapping[' +
            selectedIndex +
            '].providerName',
          providerName
        )
        _.set(
          newFormData,
          'Application.myProviders.providerMapping[' +
            selectedIndex +
            '].memberData.needs[0].addresses[0].addressLine1',
          copiedAddressLine1 ? copiedAddressLine1 : ' '
        )
        _.set(
          newFormData,
          'Application.myProviders.providerMapping[' +
            selectedIndex +
            '].memberData.needs[0].addresses[0].addressLine2',
          copiedAddressLine2 ? copiedAddressLine2 : ' '
        )
        _.set(
          newFormData,
          'Application.myProviders.providerMapping[' +
            selectedIndex +
            '].memberData.needs[0].addresses[0].phone',
          copiedPhone ? copiedPhone : ' '
        )
        _.set(
          newFormData,
          'Application.myProviders.providerMapping[' +
            selectedIndex +
            '].memberData.needs[0].addresses[0].website',
          copiedWebsite ? copiedWebsite : ' '
        )
        _.set(
          newFormData,
          'Application.myProviders.providerMapping[' +
            selectedIndex +
            '].memberData.needs[0].addresses[0].hours',
          copiedHours ? copiedHours : ' '
        )
        _.set(
          newFormData,
          'Application.myProviders.providerMapping[' +
            selectedIndex +
            '].change',
          true
        )
        _.set(newFormData, 'isChange', true)

        for (
          var j = 0;
          j < newFormData.Application.myProviders.providerMapping.length;
          j++
        ) {
          let currentProvider =
            newFormData.Application.myProviders.providerMapping[j].providerName
          if (
            (typeof currentProvider === 'string' ||
              currentProvider instanceof String) &&
            currentProvider !== null
          ) {
            if (enumNameValue.indexOf(currentProvider) === -1) {
              enumName.push(currentProvider)
              enumNameValue.push(currentProvider)
            }
          }
        }

        _.set(
          newForms,
          '0.schema.definitions.myProviders.properties.providerMapping.items.properties.providerName.enum',
          enumName
        )
        _.set(
          newForms,
          '0.schema.definitions.myProviders.properties.providerMapping.items.properties.providerName.enumNames',
          enumNameValue
        )
        this.setState(prevState => {
          return {
            forms: newForms,
            formData: newFormData,
            formContext: {
              ...prevState.formContext,
              formData: newFormData
            }
          }
        })
        //TODO: This then block does not get fired, because this is not how promises work. Do we need this?
        //}).then(results => {
        //	if (results) {
        //		let jsonDataArray = JSON.parse(JSON.stringify(results));

        //		_.set(newFormData, 'Application.myProviders.providerMapping[' + selectedIndex + '].providerName', jsonDataArray[selectedIndex].provider.name);
        //		_.set(newFormData, 'Application.myProviders.providerMapping[' + selectedIndex + '].memberData.needs[0].details[0].providerId', jsonDataArray[selectedIndex].provider.id);

        //		this.setState((prevState) => {
        //			return {
        //				formData: newFormData,
        //				formContext: {
        //					...prevState.formContext,
        //					formData: newFormData
        //				}
        //			}
        //		});
        //	}
      })
      .catch(error => {
        console.error('MyProviders _sameasProvider failed with ex', error)
        const code = CODES.MY_PROVIDERS_SAMEASPROVIDER
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _setCRFEnable = () => {
    this.setState({
      showCRFMap: true,
      isSearchEnabled: true
    })
  }

  _saveEnrollment = () => {
    let formDataValue = _.cloneDeep(this.state.formData)
    let newForms = helper.deepClone(this.state.forms)

    let myEnrollments = []

    //find the enrollment
    var providermap =
      formDataValue['Application']['myProviders']['providerMapping']
    let providerMapflag = true
    for (var j = 0; j < providermap.length; j++) {
      let providerMemberData = providermap[j].memberData
      let providerMemberDataNeed =
        providerMemberData && providermap[j].memberData.needs
      let providerMemberDataAddress =
        providerMemberDataNeed && providermap[j].memberData.needs[0].addresses
      //provider mapping
      if (!providermap[j].providerName) {
        providerMapflag = false
      }

      if (
        providerMemberData &&
        providerMemberDataNeed &&
        providerMemberDataNeed[0] &&
        providerMemberDataAddress &&
        providerMemberDataAddress[0] &&
        providermap[j].change
      ) {
        let persondata = providermap[j].memberData.needs[0]
        let address = providermap[j].memberData.needs[0].addresses[0]
        let myenrollment = _.cloneDeep(MyEnrollment)
        myenrollment.caseId = persondata.caseId
        myenrollment.clientId = persondata.clientId
        //set source provider Id
        myenrollment.srcProviderId = persondata.details[0].providerId
        myenrollment.providerName = providermap[j].providerName
        providermap[j].change = false
        //Address
        myenrollment.providerAddress.address.addressLine1 = address.ADR_LN_1_TXT
        myenrollment.providerAddress.address.addressLine2 = address.ADR_LN_2_TXT
        myenrollment.providerAddress.address.city = address.CTY_NM
        myenrollment.providerAddress.address.state = address.ST
        myenrollment.providerAddress.address.zipcode = address.ZIP
        myenrollment.providerAddress.address.county = address.CNTY
        //Phone
        myenrollment.providerPhone.phone.telNumber1 = address.phone
        myenrollment.tenantId = config.tenant.id
        //mangHealthCareOrg
        myenrollment.mangHealthCareOrg = providermap[j].mangHealthCareOrg
        myenrollment.programName = this.state.benfProgCd
        myEnrollments.push(myenrollment)
      }
    }
    //if changes found call save enrollment
    if (!_.isEmpty(myEnrollments)) {
      const fetchEndpoint = submitEnrollmentEndpoint.replace('{source}', 'CP')
      helper
        .fetchJson(fetchEndpoint, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: this.props.uuid,
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            Authorization: this.props.authHeader
          },
          method: 'POST',
          body: JSON.stringify(myEnrollments)
        })
        .then(res => {
          formDataValue.isChange = false
          //message should display openenrollment active and all client are enrolled and (Medicaid or CHIP)
          if (
            formDataValue.Application.myProviders.medicadeChipActive &&
            providerMapflag &&
            formDataValue.Application.myProviders.isOpenEnrollment
          ) {
            formDataValue.Application.myProviders.isOpenEnrollmentMsg = true
          }
          this.setState(prevState => {
            return {
              formData: formDataValue,
              formContext: {
                ...prevState.formContext,
                formData: formDataValue
              }
            }
          })
        })
        .catch(error => {
          console.error('MyProviders _saveEnrollment failed due to ex', error)
          const code = CODES.MY_PROVIDERS_SUBMIT
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _deleteProvider = (fieldId: string, removeIndex: number) => {
    //change formData

    let targetPath = ''
    const idPath = fieldId.split('_')
    for (let i = 0; i < idPath.length; ++i) {
      if (i == 0) continue
      if (isNaN(parseInt(idPath[i], 10))) {
        if (i > 1) targetPath += '.'
        targetPath += idPath[i]
      } else {
        targetPath += '[' + idPath[i] + ']'
      }
    }
    let newFormData = helper.deepClone(this.state.formData)
    _.set(newFormData, targetPath, null)
    this.setState(
      {
        formData: newFormData
      },
      () => this._refreshEnumValues(removeIndex)
    )
  }

  _toggleShowText = (index: number) => {
    let newFormData = helper.deepClone(this.state.formData)
    let value =
      newFormData.Application.myProviders.providerMapping[index].readMoreFlag
    value = value ? false : true
    newFormData.Application.myProviders.providerMapping[
      index
    ].readMoreFlag = value
    this.setState(prevState => {
      return {
        formData: newFormData,
        formContext: {
          ...prevState.formContext,
          formData: newFormData
        }
      }
    })
  }

  _refreshEnumValues = (removeIndex: number) => {
    this._updateEnumValues(removeIndex, true)
    this._deleteProviderDataJSON(removeIndex)
  }

  _deleteProviderDataJSON = (index: number) => {
    var prtcpId, applId, providerId
    prtcpId = this.state.formData.Application.myProviders.providerMapping[index]
      .memberData.needs[0].clientId
    applId = this.state.formData.Application.myProviders.providerMapping[index]
      .appliedBenefit
    providerId = this.state.formData.Application.myProviders.providerMapping[
      index
    ].memberData.needs[0].details[0].providerId

    const fetchEndpoint = deleteInfoEndpoint
      .replace('{prtcpId}', prtcpId)
      .replace('{applId}', applId)
      .replace('{providerId}', providerId)
      .replace('{benfProgCd}', this.state.benfProgCd)
    helper
      .fetchJson(fetchEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        },
        method: 'POST'
      })
      .catch(error => {
        console.error(
          'MyProviders _deleteProviderDataJSON failed with ex',
          error
        )
        const code = CODES.MY_PROVIDERS_DELETE_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _showMap = (providerMap: any) => {
    this.setState({ showMap: true, showAddrFlag: true })
    this.setState(prevState => {
      return {
        formContext: {
          ...prevState.formContext,
          showAddrFlag: true
        }
      }
    })

    this.setState({ providerMapping: providerMap })
  }

  _saveProvider = (
    applicantName: string,
    providerName: string,
    appliedBenefit: string,
    providerId: string,
    provider: string
  ) => {
    //Clone a copy of the current formData state for us to modify.
    let newFormData = helper.deepClone(this.state.formData)

    const index = _.findIndex(
      newFormData.Application.myProviders.providerMapping,
      function(element) {
        return (
          element['applicantName'] === applicantName &&
          element['appliedBenefit'] === appliedBenefit
        )
      }
    )
    this.setState({
      showMap: false,
      showAddrFlag: false,
      showMapAddress: false
    })
    this.setState(prevState => {
      return {
        formContext: {
          ...prevState.formContext,
          showAddrFlag: false
        }
      }
    })
    this._submitProviderDataJSON(provider, index, newFormData, providerName)
  }

  _submitProviderDataJSON = (
    provider: any,
    index: number,
    newFormData: any,
    providerName: string
  ) => {
    var prtcpId, applId
    prtcpId = this.state.formData.Application.myProviders.providerMapping[index]
      .memberData.needs[0].clientId
    applId = this.state.formData.Application.myProviders.providerMapping[index]
      .appliedBenefit

    let address = {
      addressId: provider.id,
      name: 'Home',
      address: provider.address,
      ADR_LN_1_TXT: provider.ADR_LN_1_TXT,
      ADR_LN_2_TXT: provider.ADR_LN_2_TXT,
      CTY_NM: provider.CTY_NM,
      ST: provider.ST,
      ZIP: provider.ZIP,
      ZIP_EXT: null,
      addressLine1: provider.ADR_LN_1_TXT + ' ' + provider.ADR_LN_2_TXT,
      addressLine2: provider.CTY_NM + ',' + provider.ST + ',' + provider.ZIP,
      website: provider.website ? provider.website : ' ',
      phone: provider.phone ? provider.phone : ' ',
      hours: provider.hours ? provider.hours : ' '
    }

    _.set(
      newFormData,
      'Application.myProviders.providerMapping[' + index + '].providerName',
      providerName
    )
    _.set(
      newFormData,
      'Application.myProviders.providerMapping[' +
        index +
        '].memberData.needs[0].details[0].providerId',
      provider.id
    )
    _.set(
      newFormData,
      'Application.myProviders.providerMapping[' +
        index +
        '].memberData.needs[0].addresses[0]',
      address
    )
    _.set(
      newFormData,
      'Application.myProviders.providerMapping[' + index + '].change',
      true
    )
    _.set(newFormData, 'isChange', true)
    this.setState(
      {
        formData: newFormData,
        formContext: {
          ...this.state.formContext,
          formData: newFormData
        }
      },
      () => this._updateEnumValues(null, false)
    )
  }

  _updateEnumValues = (removeIndex: number, isRemove: boolean) => {
    let formDataValue = this.state.formData
    let newForms = helper.deepClone(this.state.forms)
    let enumName = []
    enumName.push('{new provider}')
    let enumNameValue = []
    enumNameValue.push('New Provider')

    for (
      var j = 0;
      j < formDataValue.Application.myProviders.providerMapping.length;
      j++
    ) {
      let currentProvider =
        formDataValue.Application.myProviders.providerMapping[j].providerName
      let removedProvider = false
      if (
        (typeof removeIndex !== 'undefined' || removeIndex !== null) &&
        isRemove === true &&
        removeIndex == j
      ) {
        removedProvider = true
      }
      if (
        (typeof currentProvider === 'string' ||
          currentProvider instanceof String) &&
        currentProvider !== null
      ) {
        if (enumNameValue.indexOf(currentProvider) === -1 && !removedProvider) {
          enumName.push(currentProvider)
          enumNameValue.push(currentProvider)
        }
      }
    }

    _.set(
      newForms,
      '0.schema.definitions.myProviders.properties.providerMapping.items.properties.providerName.enum',
      enumName
    )
    _.set(
      newForms,
      '0.schema.definitions.myProviders.properties.providerMapping.items.properties.providerName.enumNames',
      enumNameValue
    )

    this.setState({
      forms: newForms
    })
  }

  componentDidMount() {
    const endpoint = formsEndpoint.replace('{version}', '1.0')
    helper
      .fetchJson(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        this._getInitialLoadDataJSON(formJson)
      })
      .catch(error => {
        console.error('MyProviders form fetch failed due to ex', error)
        const code = CODES.MY_PROVIDERS_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })

    if (this.state.showMap) {
      this.setState(prevState => {
        return {
          formContext: {
            ...prevState.formContext,
            showAddrFlag: false
          }
        }
      })
    }
  }

  _onFormDataChange = ({ formData }) => {
    let newForms = helper.deepClone(this.state.forms)
    let oldForms
    var firstFlag = false
    if (
      this.state.formData.Application.myProviders.selectedBenefit !==
      formData.Application.myProviders.selectedBenefit
    ) {
      if (!this.state.formData.Application.myProviders.selectedBenefit) {
        oldForms = helper.deepClone(this.state.forms)
        this.setState({ oldForms })
      }
      this.state.formData.Application.myProviders.selectedBenefit =
        formData.Application.myProviders.selectedBenefit
      var benfProgCd = this._getBenfProgCd()
      var thirdColumnSchema = {
        type: 'string'
      }
      var thirdColumnUiSchema = {
        classNames: 'col-md-12 bold'
      }
      if (benfProgCd === 'Medicaid') {
        _.set(
          newForms,
          '0.schema.definitions.myProviders.properties.providerMapping.items.properties.mangHealthCareOrg',
          thirdColumnSchema
        )
        _.set(
          newForms,
          '0.uiSchema.Application.myProviders.providerMapping.ui:options.columns[2]',
          I18n.t('MyProviders.managedHealthCareOrg')
        )
        _.set(
          newForms,
          '0.uiSchema.Application.myProviders.providerMapping.items.mangHealthCareOrg',
          thirdColumnUiSchema
        )
      } else {
        newForms = helper.deepClone(oldForms ? oldForms : this.state.oldForms)
      }
      this.setState({ forms: newForms })

      //Update the medicade
      if (this.isButtonActive(benfProgCd)) {
        this._updateMessage(benfProgCd)
        formData.Application.myProviders.medicadeChipActive = true
      } else {
        //is open enrollment active
        formData.Application.myProviders.isOpenEnrollment = true
        //for change provider for medicade or chip
        formData.Application.myProviders.medicadeChipActive = false
        //open enrollment message
        formData.Application.myProviders.isOpenEnrollmentMsg = false
      }
      this.setState({ benfProgCd }, () => this._fetchDataLoad())
    }

    if (formData.Application.myProviders.address) {
      let address = formData.Application.myProviders.address
      this._updateProviderMapping(address)
      this.setState(prevState => {
        return {
          formData,
          formContext: {
            ...prevState.formContext,
            formData
          }
        }
      })
      this._selectProvider(this.state.providerMapping)
      this.setState({ showMapAddress: true, showCRFMap: false })
    } else {
      this.setState(prevState => {
        return {
          formData,
          formContext: {
            ...prevState.formContext,
            formData
          }
        }
      })
    }
  }

  isButtonActive = (programCode: string) => {
    return programCode == 'CHIP' || programCode == 'Medicaid'
  }

  _updateProviderMapping = (address: string) => {
    let providerMap = this.state.providerMapping.memberData.needs[0]
    let programName = 'Medical'
    let map = {
      firstName: providerMap.firstName,
      lastName: providerMap.lastName,
      clientId: providerMap.clientId,
      address: '',
      ADR_LN_1_TXT: '',
      ADR_LN_2_TXT: '',
      CTY_NM: '',
      ST: '',
      ZIP: '',
      providerType: programName || 'Medical'
    }
    let memberData = getProviderData(map)
    map.address = address
    if (
      address &&
      address.lastIndexOf(',') > -1 &&
      address.split(',').length > 2
    ) {
      let addressArray = address.split(',')
      map.ADR_LN_1_TXT = _.trim(addressArray[0])
      map.CTY_NM = _.trim(addressArray[1])
      let stateZip = _.trimStart(addressArray[2])
      map.ST = _.trim(stateZip.split(' ')[0])
      map.ZIP = _.trim(stateZip.split(' ')[1])
    } else {
      map.ADR_LN_1_TXT = address
    }
    memberData = getProviderData(map)

    this.setState(prevState => {
      let showCRFMap = this.state.showCRFMap
      let isAddressChanged = false
      if (
        !_.isEqual(prevState.formData.Application.myProviders.address, address)
      ) {
        showCRFMap = false
        isAddressChanged = true
      }
      return {
        providerMapping: { ...prevState.providerMapping, memberData },
        showCRFMap,
        isAddressChanged
      }
    })
  }
  _getBenfProgCd = () => {
    var enumArray = this.state.forms[0].schema.definitions.myProviders
      .properties.selectedBenefit.enum
    var benfProgCd = null
    for (var k = 0; k < enumArray.length; k++) {
      if (
        this.state.formData.Application.myProviders.selectedBenefit ==
        enumArray[k]
      ) {
        benfProgCd = this.state.forms[0].schema.definitions.myProviders
          .properties.selectedBenefit.enumNamesCd[k]
      }
    }
    return benfProgCd
  }

  _getSelectedDataJSON = (providerMapping: any) => {
    let newForms = helper.deepClone(this.state.forms)
    let newFormData = helper.deepClone(this.state.formData)
    let enumName = []
    enumName.push('{new provider}')
    let enumNameValue = []
    enumNameValue.push('New Provider')
    for (var j = 0; j < providerMapping.length; j++) {
      if (
        (typeof providerMapping[j].providerName === 'string' ||
          providerMapping[j].providerName instanceof String) &&
        providerMapping[j].providerName !== null
      ) {
        if (enumNameValue.indexOf(providerMapping[j].providerName) === -1) {
          enumName.push(providerMapping[j].providerName)
          enumNameValue.push(providerMapping[j].providerName)
        }
      }
    }

    _.set(
      newForms,
      '0.schema.definitions.myProviders.properties.providerMapping.items.properties.providerName.enum',
      enumName
    )
    _.set(
      newForms,
      '0.schema.definitions.myProviders.properties.providerMapping.items.properties.providerName.enumNames',
      enumNameValue
    )

    _.set(
      newFormData,
      'Application.myProviders.providerMapping',
      providerMapping
    )
    _.set(
      newFormData,
      'Application.myProviders.selectedBenefit',
      this.state.formData.Application.myProviders.selectedBenefit
    )

    helper.cleanNullValues(newFormData)

    this.setState(prevState => {
      return {
        forms: newForms,
        formData: newFormData,
        showMap: false,
        formContext: {
          ...prevState.formContext,
          formData: newFormData
        }
      }
    })
  }

  _openEnrollment = (benfProgCd: string) => {
    const fetchEndpoint = openEnrollmentEndPoing.replace(
      '{benfProgCd}',
      benfProgCd.toUpperCase()
    )
    return helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .catch(error => {
        const code = CODES.MY_PROVIDERS_OPEN_ENROLLMENT
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _updateMessage = (benfProgCd: string) => {
    this._openEnrollment(benfProgCd).then(resp => {
      let formData = _.cloneDeep(this.state.formData)
      if (!_.isEmpty(resp.data)) {
        formData.Application.myProviders.isOpenEnrollment = false
      } else {
        formData.Application.myProviders.isOpenEnrollment = true
      }
      this.setState(prevState => {
        return {
          formData,
          formContext: {
            ...prevState.formContext,
            formData
          }
        }
      })
    })
  }

  _fetchDataLoad = () => {
    var applId = this.state.formData.Application.myProviders.selectedBenefit
    applId = applId.slice(0, -1)

    const fetchEndpoint = selectedLoadEndpoint
      .replace('{applId}', applId)
      .replace('{benfProgCd}', this.state.benfProgCd)

    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(results => {
        if (results) {
          let jsonDataArray = JSON.parse(JSON.stringify(results))
          let providerMapping = []
          let add = null
          let openEnrollementFlag = this.state.formData.Application.myProviders
            .isOpenEnrollment
          let medicadeChipActive = this.state.formData.Application.myProviders
            .medicadeChipActive
          let count = 0
          for (var k = 0; k < jsonDataArray.length; k++) {
            if (jsonDataArray[k].adrLn1Txt) {
              add =
                jsonDataArray[k].adrLn1Txt +
                ' ' +
                jsonDataArray[k].adrLn2Txt +
                ',' +
                jsonDataArray[k].ctyNm +
                ',' +
                jsonDataArray[k].st +
                ',' +
                jsonDataArray[k].zip
            }
            let addresses = []
            let details = []
            let needs = []
            let detail = {
              detailId: '000009',
              program: 'Medical',
              filter: 'Medical',
              subsidized: '100%',
              providerId: jsonDataArray[k].providerExists
                ? jsonDataArray[k].provider.id
                : null,
              allowUpdate: true
            }
            details.push(detail)
            let address = {
              addressId: k,
              name: 'Home',
              address: add ? add : '',
              ADR_LN_1_TXT: jsonDataArray[k].adrLn1Txt
                ? jsonDataArray[k].adrLn1Txt
                : '',
              ADR_LN_2_TXT: jsonDataArray[k].adrLn2Txt
                ? jsonDataArray[k].adrLn2Txt
                : '',
              CTY_NM: jsonDataArray[k].ctyNm ? jsonDataArray[k].ctyNm : '',
              ST: jsonDataArray[k].st ? jsonDataArray[k].st : '',
              ZIP: jsonDataArray[k].zip ? jsonDataArray[k].zip : '',
              ZIP_EXT: null,
              addressLine1: null,
              addressLine2: null,
              website: null,
              phone: null,
              hours: null
            }

            if (jsonDataArray[k].provider != null) {
              ;(address.addressLine1 = jsonDataArray[k].provider.adr_LN_1_TXT
                ? jsonDataArray[k].provider.adr_LN_1_TXT
                : ' ' + ' ' + jsonDataArray[k].provider.adr_LN_2_TXT
                ? jsonDataArray[k].provider.adr_LN_2_TXT
                : ' '),
                (address.addressLine2 = jsonDataArray[k].provider.cty_NM
                  ? jsonDataArray[k].provider.cty_NM
                  : '' + ',' + jsonDataArray[k].provider.st
                  ? jsonDataArray[k].provider.st
                  : '' + ',' + jsonDataArray[k].provider.zip
                  ? jsonDataArray[k].provider.zip
                  : ''),
                (address.website = jsonDataArray[k].provider.website
                  ? jsonDataArray[k].provider.website
                  : ' '),
                (address.phone = jsonDataArray[k].provider.phone
                  ? jsonDataArray[k].provider.phone
                  : ' '),
                (address.hours = jsonDataArray[k].provider.hours
                  ? jsonDataArray[k].provider.hours
                  : ' ')
            }

            addresses.push(address)
            let need = {
              clientId: jsonDataArray[k].prtcpId,
              firstName: jsonDataArray[k].prtcpFirstName,
              lastName: jsonDataArray[k].prtcpLastName,
              addresses: addresses,
              details: details,
              caseId: jsonDataArray[k].caseId || '3410'
            }
            needs.push(need)
            let memberData = {
              transactionId: applId,
              needs: needs
            }
            let providermap = {
              applicantName:
                jsonDataArray[k].prtcpFirstName +
                ' ' +
                jsonDataArray[k].prtcpLastName,
              providerName: jsonDataArray[k].providerExists
                ? jsonDataArray[k].provider.name
                : null,
              appliedBenefit: applId,
              memberData: memberData,
              readMoreFlag: 'true',
              change: false
            }
            //openenrollment not active and medicade or chip and atleast client is not submitted enrollment
            if (
              jsonDataArray[k].providerExists &&
              openEnrollementFlag &&
              medicadeChipActive
            ) {
              count = count + 1
            }
            providerMapping.push(providermap)
            //all clients enrolled then display message.
            if (openEnrollementFlag && medicadeChipActive) {
              this.state.formData.Application.myProviders.isOpenEnrollmentMsg =
                count == jsonDataArray.length
            }
          }
          this._getSelectedDataJSON(providerMapping)
        }
      })
      .catch(error => {
        console.error('MyProviders _fetchDataLoad failed with ex', error)
        const code = CODES.MY_PROVIDERS_SELECTED_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  render() {
    const { presentation, uuid, locale } = this.props
    const {
      CRFMapReact,
      forms,
      formData,
      formContext,
      mapProps,
      showMap,
      showMapAddress,
      initialDataLoad,
      showCRFMap,
      previousMapProps,
      isAddressChanged,
      isSearchEnabled,
      providerMapping
    } = this.state

    const presentationProps = {
      CRFMapReact,
      forms,
      formData,
      formContext,
      mapProps,
      showMap,
      showMapAddress,
      uuid,
      onFormDataChange: this._onFormDataChange,
      initialDataLoad,
      showCRFMap,
      previousMapProps,
      isAddressChanged,
      isSearchEnabled,
      setCrfContainer: el => {
        this.crfContainer = el
      },
      saveProvider: this._saveProvider,
      providerMapping,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

function getProviderData(map) {
  // Default CRF Map Provider Details configured.
  return {
    transactionId: 999,
    needs: [
      {
        clientId: map.clientId || 98765,
        firstName: map.firstName || 'Michael',
        lastName: map.lastName || 'Doe',
        addresses: [
          {
            addressId: 1,
            name: 'Home',
            address: map.address || '',
            ADR_LN_1_TXT: map.ADR_LN_1_TXT || '',
            ADR_LN_2_TXT: null,
            CTY_NM: map.CTY_NM || '',
            ST: map.ST || '',
            ZIP: map.ZIP || '',
            ZIP_EXT: null
          },
          {
            addressId: 2,
            name: 'Work',
            address: '13625 Technology Dr, Eden Prairie, MN 55346',
            ADR_LN_1_TXT: '13625 Technology Dr',
            ADR_LN_2_TXT: null,
            CTY_NM: 'Eden Prairie',
            ST: 'MN',
            ZIP: '55346',
            ZIP_EXT: null
          }
        ],
        details: [
          {
            detailId: '000009',
            program: map.providerType,
            filter: map.providerType,
            subsidized: '100%',
            providerId: 217, //(optional - Added For View Map Purpose.)
            allowUpdate: true //(Added  for updating provider details)
          }
        ]
      }
    ]
  }
}

export default connect(mapStateToProps)(MyProvidersContainer)
