import * as React from 'react'
import * as _ from 'lodash'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import validateCustomRadioWidget from '../src/components/widgets/CustomRadioWidget'
import { Link } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'

declare const process
const baseUrl = process.env.PUBLIC_URL
const Fragment = (React as any).Fragment

function ReportAChangeWidget(props) {
  const { formContext } = props
  if (props.value.caseNumber) {
    if (props.value.status === 'PARTIAL SUBMIT') {
      return (
        <Fragment>
          <Link
            to={
              baseUrl +
              '/coc?caseId=' +
              props.value.caseNumber +
              '&applId=' +
              undefined +
              '&status=' +
              props.value.status
            }
            className="btn btn-primary"
          >
            {I18n.t('MyChanges.reportChange')}
          </Link>
          <p />
          <p>{I18n.t('MyChanges.alreadySubmitted')}</p>
        </Fragment>
      )
    } else if (props.value.status === 'DRAFT') {
      return (
        <Fragment>
          <button
            onClick={() => showDraftModal(props)}
            className="btn btn-primary"
          >
            {I18n.t('MyChanges.reportChange')}
          </button>
          <p />
          <p>{I18n.t('MyChanges.reportChangesToPrograms')}</p>
        </Fragment>
      )
    } else if (props.value.status === 'INPROGRESS') {
      return (
        <Fragment>
          <button className="btn btn-secondary">
            {' '}
            {I18n.t('MyChanges.reportProgress')}
          </button>
          <p />
          <p>{I18n.t('MyChanges.alreadyBeenStarted')}</p>
        </Fragment>
      )
    } else if (props.value.status === 'OPEN') {
      return (
        <Fragment>
          <Link
            to={
              baseUrl +
              `/${
                props.value.programType === 'CCA' ? 'coc-child-care' : 'coc'
              }?caseId=` +
              props.value.caseNumber +
              '&applId=' +
              'undefined' +
              '&status=' +
              props.value.status
            }
            className="btn btn-primary"
          >
            {I18n.t('MyChanges.reportChange')}
          </Link>
          <p />
          <p>{I18n.t('MyChanges.reportChangesToPrograms')}</p>
        </Fragment>
      )
    }
  } else {
    //Don't display anything until eligiblePendingRenewals is loaded, which will be non-nil.
    return null
  }
}

function showDraftModal(props) {
  Object.assign(props.value, { cocReady: 'ready' })
  return props.onChange(props.value)
}

function HasBenefitCheckmarkWidget(props) {
  if (props.value === true) {
    return (
      <small>
        <span
          className="badge-sm bg-primary-alt"
          style={{ borderRadius: '50%' }}
        >
          <i className="glyphicon myglyphicon-ok" />
        </span>
      </small>
    )
  } else {
    return null
  }
}

function FootNoteWidget(props) {
  const index = props.id.match('^LifeEvents_([0-9]+)_?')[1]
  const CCA =
    _.get(props, `formContext.formData[${index}].caseId.programType`) === 'CCA'
  return (
    <Fragment>
      <div>{!CCA && <p>{I18n.t('MyChanges.ifQualifiedHealthPlan')}</p>}</div>
    </Fragment>
  )
}

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  radio: validateCustomRadioWidget,
  select: Widgets.CustomSelectWidget,
  span: Widgets.SpanWidget,
  infoBox: Widgets.InfoBoxWidget,
  linkButton: Widgets.LinkButtonWidget,
  reportAChange: ReportAChangeWidget,
  hasBenefitCheckmark: HasBenefitCheckmarkWidget,
  footNote: FootNoteWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
