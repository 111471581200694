import * as React from 'react'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import * as validator from '../src/validation'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import { Button, Modal } from 'react-bootstrap'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config'
import * as _ from 'lodash'

const fields = {
  SchemaField: Fields.CustomSchemaField,
  condition: Fields.ConditionalField
}

const widgets = {
  select: Widgets.CustomSelectWidget,
  validatedText: Widgets.ValidatedTextWidget,
  multiSelect: Widgets.MultiSelectDropdownWidgetV2,
  'alt-date': Widgets.DatePickerWidget
}

// TO_DO - below have to change after org created.
const orgValueForBenH = 'WV Benjamin H'

export default class NewUserModal extends React.Component<any, any> {
  private hasError = false
  private defaultFormData = {
    completionDate: null,
    emailAddress: null,
    firstName: null,
    lastName: null,
    orgs: null,
    roles: null,
    selectedRole: null,
    trainingDate: null
  }
  constructor(props: any) {
    super(props)

    this.defaultFormData['trainingDate'] = _moment(
      config,
      props.currentDate
    ).format('YYYY-MM-DD')
    this.defaultFormData['completionDate'] = _moment(
      config,
      props.currentDate
    ).format('YYYY-MM-DD')
    this.state = {
      formData: this.defaultFormData,
      formContext: null,
      liveValidate: false,
      showErrors: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show && !prevProps.show) {
      this.setState({
        formData: this.defaultFormData,
        formContext: {
          isPEAdmin: this.props.isPEAdmin,
          isWorkerAdmin: this.props.isWorkerAdmin,
          isCPAdmin: this.props.isCPAdmin
        }
      })
    }
  }

  _cancel = () => {
    this.setState({ liveValidate: false, showErrors: false })
    this.props.cancel()
  }

  _onFormDataChange = ({ formData }) => {
    if (formData.roles) {
      if (
        formData.roles === 'BH_ADMIN_ASST' ||
        formData.roles === 'BH_HR_SPEC' ||
        formData.roles === 'BH_RES_COOR'
      ) {
        formData.orgs = []
        formData.orgs.push(orgValueForBenH)
      } else {
        if (formData.orgs && formData.orgs[0] === orgValueForBenH) {
          formData.orgs = []
        }
      }
    }
    this.setState(prevState => {
      return {
        formData: formData,
        formContext: {
          ...prevState.formContext,
          formData: formData
        }
      }
    })
  }

  _onSubmit = ({ formData }) => {
    this.setState({ formData, liveValidate: false, showErrors: false }, () => {
      this.props.save(this.state.formData)
    })
  }

  onValidate = (formData: object, errors: object) => {
    const { liveValidate, formContext } = this.state

    //Call CP-specific validation library.
    if (liveValidate) {
      validator.validateNewUserForm(formData, errors, formContext)
    }

    //Determine which tabs contain errors and update their statuses.
    let foundErrors = helper.findErrors(errors)
    if (foundErrors && foundErrors.length > 0) {
      this.hasError = true
    } else {
      this.hasError = false
    }
    return errors
  }

  onErrorClick = (error: any) => {
    event.preventDefault()
    try {
      setTimeout(function() {
        window.requestAnimationFrame(function() {
          const element = document.getElementById(`root_${error.id}`)
          let offset = helper.getOffsetTop(
            document.getElementById(`root_${error.id}`)
          )
          element.focus()
          window.scroll(0, offset - 100)
        })
      }, 0)
    } catch (e) {
      console.error('New User Modal setTimeout failed with ex', e)
    }
  }

  transformErrors = (errors: object) => {
    return errors
  }

  onError = (errors: object) => {}

  errorListTemplate = (props: any) => {
    const { errors } = props
    return (
      <div
        className="panel panel-danger errors"
        id="appintake-errors"
        tabIndex={-1}
      >
        <div className="panel-heading">
          <span className="panel-title">Errors</span>
        </div>
        <ul className="list-group">
          {errors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this.onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  render() {
    let validator
    if (!this.props.form) {
      return null
    } else {
      validator = new CustomValidator(
        this.props.form['schema'],
        this.props.form['uiSchema'],
        this.state.formContext
      )
    }
    return (
      <div id="userAccessModal">
        <Modal
          show={this.props.show}
          onHide={this._cancel}
          backdrop="static"
          aria-labelledby="contained-modal-title"
          size={'lg'}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              {this.props.heading}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              schema={this.props.form['schema']}
              uiSchema={this.props.form['uiSchema']}
              onSubmit={this._onSubmit}
              formData={this.state.formData}
              formContext={this.state.formContext}
              onChange={this._onFormDataChange}
              fields={fields}
              widgets={widgets}
              ArrayFieldTemplate={Templates.CustomArrayFieldTemplate}
              FieldTemplate={Templates.CustomFieldTemplate}
              validate={this.onValidate}
              liveValidate={this.state.liveValidate}
              ErrorList={this.errorListTemplate}
              onError={this.onError}
              transformErrors={this.transformErrors}
              jsonValidator={validator}
              showErrorList={this.state.showErrors}
            >
              <Modal.Footer>
                <div className="col-lg-12">
                  <Button
                    type="button"
                    className="btn btn-default float-left"
                    onClick={this._cancel}
                    variant="outline-dark"
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    variant="outline-dark"
                    onClick={() => {
                      this.setState({
                        liveValidate: true,
                        showErrors: true
                      })
                    }}
                    className="btn btn-default float-right"
                  >
                    Save
                  </Button>
                </div>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
