import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const SearchPEApplicationsContainer = MultitenantLoadable(
  () => import('./SearchPEApplicationsContainer'),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/SearchPEApplications/SearchPEApplicationsContainer'
    )
)

const SearchPEApplicationsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./SearchPEApplicationsPresentation'),
    () =>
      import(
        '../../' +
          tenantDirectoryName +
          '/SearchPEApplications/SearchPEApplicationsPresentation'
      )
  ),
  () => import('./formComponents'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/SearchPEApplications/formComponents'
    )
)

export default function SearchPEApplications(props) {
  return (
    <ErrorBoundary name="SearchPEApplications">
      <SearchPEApplicationsContainer
        {...props}
        presentation={presentationProps => (
          <SearchPEApplicationsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseSearchPEApplicationsContainer = MultitenantLoadable(
  () => import('./SearchPEApplicationsContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseSearchPEApplicationsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./SearchPEApplicationsPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseSearchPEApplications(props) {
  return (
    <BaseErrorBoundary name="SearchPEApplications">
      <BaseSearchPEApplicationsContainer
        {...props}
        presentation={presentationProps => (
          <BaseSearchPEApplicationsPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
