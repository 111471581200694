import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { Validator } from 'jsonschema'
import ModalContainer from '../Modal/ModalContainer'

interface BenHReferralPresentationProps {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  formContext: any
  formComponents: any
  showReferralView: boolean
  saveReferral: (formData: any) => void
  onValidate: (formData: object, errors: object) => void
  onFormDataChange: (formData: any) => void
  formData: any
  transformErrors: () => void
  errorListTemplate: () => void
  liveValidate: boolean
  switchOnLiveValidate: () => void
  validator: Validator
  referralSearchResultData: any
  onError: (errors: object) => void
  cancelExit: () => void
  showExitModal: boolean
  hideExitModalMessage: () => void
  searchReferral: () => void
  referralSearchData: any
  exitScreen: () => void
  hideModalMessage: () => void
  showModal: boolean
  modalMessage: any
  clearSearch: () => void
  referralDetailData: any
  onFormDataChangeEdit: (formData: any) => void
}

export default function BenHPresentation(props: BenHReferralPresentationProps) {
  const {
    form,
    formComponents,
    showReferralView,
    formContext,
    saveReferral,
    onValidate,
    onFormDataChange,
    transformErrors,
    errorListTemplate,
    liveValidate,
    validator,
    switchOnLiveValidate,
    referralSearchResultData,
    onError,
    cancelExit,
    showExitModal,
    hideExitModalMessage,
    searchReferral,
    referralSearchData,
    exitScreen,
    showModal,
    modalMessage,
    hideModalMessage,
    clearSearch,
    referralDetailData,
    onFormDataChangeEdit
  } = props

  const referralSearchResult = {
    Application: {
      referralTable: referralSearchResultData
    }
  }

  const referralFormContext = {
    ...formContext,
    formData: referralSearchResultData
  }

  let modalProps = {
    showModal: false
  }
  if (showExitModal) {
    const modalExitProps = {
      showModal: showExitModal,
      message:
        'Are you sure you want to exit? Changes on this screen will not be saved.',
      hideModal: hideExitModalMessage,
      size: "'lg'",
      ques: true,
      stay: cancelExit
    }
    modalProps = modalExitProps
  } else if (showModal) {
    const modalSaveProps = {
      showModal: showModal,
      message: modalMessage,
      hideModal: hideModalMessage,
      size: "'lg'",
      ques: false
    }
    modalProps = modalSaveProps
  }

  if (showReferralView) {
    return (
      <div id="benHReferral" className="row ml-1 mr-0 pr-0">
        <h1 className="ml-3">Referral</h1>
        <div className="col-12 col-lg-12 mr-0 ml-0 pl-0 pr-0">
          {form && form.app && form.app.forms.length > 0 ? (
            <Form
              schema={form.app.forms[2].schema}
              uiSchema={form.app.forms[2].uiSchema}
              formData={referralDetailData}
              onChange={onFormDataChangeEdit}
              formContext={formContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              jsonValidator={validator}
              transformErrors={transformErrors}
              ErrorList={errorListTemplate}
              liveValidate={liveValidate}
              validate={onValidate}
              onSubmit={saveReferral}
              onError={onError}
            >
              <div className="col-lg-12 col-12">
                <button
                  className="userListButton2 btn pull-right"
                  type="submit"
                  onClick={switchOnLiveValidate}
                  aria-label="Save"
                >
                  Save
                </button>
                <button
                  className="userListButton1 btn pull-right"
                  type="button"
                  onClick={exitScreen}
                  aria-label="Exit"
                >
                  Exit
                </button>
              </div>
            </Form>
          ) : null}
          <ModalContainer {...modalProps} />
        </div>
      </div>
    )
  } else {
    return (
      <div id="benHReferral" className="row ml-1 mr-0 pr-0">
        <h1 className="ml-3">Referrals</h1>
        <div className="col-12 col-lg-12 mr-0 ml-0 pl-0 pr-0">
          {form && form.app && form.app.forms.length > 0 ? (
            <Form
              schema={form.app.forms[0].schema}
              uiSchema={form.app.forms[0].uiSchema}
              formData={referralSearchData}
              onChange={onFormDataChange}
              formContext={formContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              validate={onValidate}
              ErrorList={errorListTemplate}
              transformErrors={transformErrors}
              onSubmit={searchReferral}
              liveValidate={liveValidate}
              onError={onError}
              jsonValidator={validator}
            >
              <div className="col-lg-12 col-12">
                <button
                  className="userListButton2 btn pull-right"
                  type="submit"
                  onClick={switchOnLiveValidate}
                >
                  Search
                </button>
                <button
                  className="userListButton1 btn pull-right"
                  type="button"
                  onClick={clearSearch}
                >
                  Clear
                </button>
              </div>
            </Form>
          ) : null}
        </div>
        <div style={{ marginTop: '0rem', marginBottom: '2rem', width: '100%' }}>
          {form && form.app && form.app.forms.length > 0 ? (
            <div className="referralTable ml-3 mr-3">
              <div className="searchListButtonDiv">
                <h2 id="searchTableTitle">Referrals List</h2>
              </div>
              <Form
                schema={form.app.forms[1].schema}
                uiSchema={form.app.forms[1].uiSchema}
                formData={{ ...referralSearchResult }}
                formContext={referralFormContext}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                FieldTemplate={formComponents.FieldTemplate}
              >
                <button className="sr-only" type="submit">
                  Search
                </button>
              </Form>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
