import { config } from '~/config'
import * as _ from 'lodash'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'

import {
  _moment,
  getSiblingFormData
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { number } from 'prop-types'

export let clientUpdateWizardRefs = {
  '{countries enums}': ({ fieldId, formContext }) => {
    return formContext.countryEnums
  },
  '{org enums}': ({ fieldId, formContext }) => {
    return formContext.orgNameEnums
  },
  '{org enumNames}': ({ fieldId, formContext }) => {
    return formContext.orgNameEnumsNames
  },
  '{display other person name field}': ({ formContext, fieldId }) => {
    let innerIndex
    if (fieldId.includes('trackerAdd')) {
      innerIndex = +fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      innerIndex = +fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
    }
    let nameOfThePerson = _.get(
      formContext,
      `localFormData[${innerIndex}].nameOfThePerson`,
      []
    )
    return _.includes(nameOfThePerson, 'Other')
  },
  '{show electricAccount}': ({ fieldId, formContext }) => {
    return (
      _.get(
        formContext,
        'formData.application.clients[0].clientExpenses.heatingExpenses[0].primaryHeatingSource.key'
      ) !== 'HEHEEL50809'
    )
  },
  '{required For CCA}': ({ formContext }) => {
    return '*'
  },
  '{is American Indian or Alaskan Native}': ({ fieldId, formContext }) => {
    const index = +fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    const raceCd = _.filter(
      _.get(
        formContext,
        `formData.application.clients[${index}].clientDetails.ethnicityRace[0].raceCd`,
        []
      ),
      e => {
        return e.key == 'RARAAM15220'
      }
    )
    return raceCd.length > 0
  },
  '{Other Tribe}': ({ fieldId, formContext }) => {
    const index = +fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

    return (
      _.get(
        formContext,
        `formData.application.clients[${index}].clientDetails.ethnicityRace[0].nameOfTheTribe`,
        {}
      ).key == 'TRTROT39120'
    )
  },
  '{countries enumNames}': ({ fieldId, formContext }) => {
    return formContext.countryEnumNames
  },
  '{authorized YA}': ({ fieldId, formContext }) => {
    return formContext.formData.application.someoneAsstYouFlg == 'YA'
  },
  '{authorized N}': ({ fieldId, formContext }) => {
    return formContext.formData.application.someoneAsstYouFlg == 'N'
  },
  '{Help With Bills}': ({ formContext, fieldId }) => {
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    if (
      formContext.formData.application.clients[index].clientDetails
        .personalData[0].apply === 'Y'
    ) {
      return false
    } else {
      return true
    }
  },
  '{Unpaid Bills For Months}': ({ formContext, fieldId }) => {
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    if (
      formContext.formData.application.clients[index].clientDetails
        .personalData[0].helpWithBills === 'Y'
    ) {
      return false
    } else {
      return true
    }
  },
  '{show ssnDt Info}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    try {
      let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

      if (index === '0') {
        return false
      } else return true
    } catch (err) {
      return false
    }
  },
  '{is Medicare}': ({ formContext, fieldId }) => {
    let innerIndex
    if (fieldId.includes('trackerAdd')) {
      innerIndex = +fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      innerIndex = +fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
    }
    let coverageType = _.get(
      formContext,
      `localFormData[${innerIndex}].coverageType.key`
    )
    return coverageType && coverageType == 'HLHEME14762'
  },
  '{healthCare noop}': ({ formContext, fieldId }) => {
    let innerIndex
    if (fieldId.includes('trackerAdd')) {
      innerIndex = +fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      innerIndex = +fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
    }
    let nameOfThePerson = _.get(
      formContext,
      `localFormData[${innerIndex}].nameOfThePerson`,
      []
    )
    return _.includes(nameOfThePerson, 'Other')
  },
  '{other than Medicare}': ({ formContext, fieldId }) => {
    let innerIndex
    if (fieldId.includes('trackerAdd')) {
      innerIndex = +fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      innerIndex = +fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
    }
    let coverageType = _.get(
      formContext,
      `localFormData[${innerIndex}].coverageType.key`
    )
    return coverageType && coverageType != 'HLHEME14762'
  },
  '{this medicareB}': ({ formContext, fieldId }) => {
    let innerIndex
    if (fieldId.includes('trackerAdd')) {
      innerIndex = +fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      innerIndex = +fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
    }
    let receiving = _.filter(
      _.get(formContext, `localFormData[${innerIndex}].receiving`, []),
      e => {
        return e.key == 'MEMEME14962'
      }
    )
    return receiving.length > 0
  },
  '{is MedicarepartA}': ({ formContext, fieldId }) => {
    let innerIndex
    if (fieldId.includes('trackerAdd')) {
      innerIndex = +fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      innerIndex = +fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
    }
    let receiving = _.filter(
      _.get(formContext, `localFormData[${innerIndex}].receiving`, []),
      e => {
        return e.key == 'MEMEME54388'
      }
    )
    return receiving.length > 0
  },
  '{is Enrolled}': ({ formContext, fieldId }) => {
    let clientIndex = +fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let educationDetails = _.get(
      formContext.formData.application,
      `clients[${clientIndex}].clientAdditionalInformation`
    )
    if (
      _.get(educationDetails, 'education[0].enrolledRightNow.key') !=
        'ENSTNO72634' &&
      _.get(educationDetails, 'education[0].enrolledRightNow.key') !=
        undefined &&
      _.get(educationDetails, 'education[0].enrolledRightNow.key') !== null
    ) {
      return true
    }
    return false
  },
  '{show wvWorkScheduleChildCare}': ({ formContext, fieldId }) => {
    try {
      if (fieldId.includes('reviewDifference')) {
        let clientIndex = +fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
        let innerIndex = +fieldId.match('(?:.*)ChildCareDetails_([0-9]+)_?')[1]
        let childCareReason = _.get(
          formContext.formData.application,
          `clients[${clientIndex}].clientAdditionalInformation.ChildCareDetails[${innerIndex}].childCareReason`,
          []
        )
        return _.find(childCareReason, reason => {
          return reason.key == 'CHCHWO18080'
        })
      } else {
        let innerIndex
        if (fieldId.includes('trackerAdd')) {
          innerIndex = +fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
        } else {
          innerIndex = +fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
        }

        let childCareReason = _.get(
          formContext,
          `localFormData[${innerIndex}].childCareReason`,
          []
        )
        return _.find(childCareReason, reason => {
          return reason.key == 'CHCHWO18080'
        })
      }
    } catch (e) {
      console.log(e)
      return false
    }
  },
  '{show showSchoolSchedule}': ({ formContext, fieldId }) => {
    try {
      if (fieldId.includes('reviewDifference')) {
        let clientIndex = +fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
        let innerIndex = +fieldId.match('(?:.*)ChildCareDetails_([0-9]+)_?')[1]
        let childCareReason = _.get(
          formContext.formData.application,
          `clients[${clientIndex}].clientAdditionalInformation.ChildCareDetails[${innerIndex}].childCareReason`,
          []
        )
        return (
          _.find(childCareReason, reason => {
            return reason.key == 'CHCHSC20814'
          }) &&
          _.get(
            formContext,
            `formData.application.clients[${clientIndex}].clientDetails.personalData[0].roleInChildCareHouseHold.key`
          ) === 'CPCPPA73632'
        )
      } else {
        let clientIndex = +fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
        let innerIndex
        if (fieldId.includes('trackerAdd')) {
          innerIndex = +fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
        } else {
          innerIndex = +fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
        }

        let childCareReason = _.get(
          formContext,
          `localFormData[${innerIndex}].childCareReason`,
          []
        )

        return (
          _.find(childCareReason, reason => {
            return reason.key == 'CHCHSC20814'
          }) &&
          _.get(
            formContext,
            `formData.application.clients[${clientIndex}].clientDetails.personalData[0].roleInChildCareHouseHold.key`
          ) === 'CPCPPA73632'
        )
      }
    } catch (e) {
      console.log(e)
      return false
    }
  },
  '{is parentCareTaker}': ({ formContext, fieldId }) => {
    let clientIndex
    try {
      clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    } catch (e) {
      return
    }
    return (
      _.get(
        formContext,
        `formData.application.clients[${clientIndex}].clientDetails.personalData[0].roleInChildCareHouseHold.key`
      ) === 'CPCPPA73632'
    )
  },
  '{is childReceivingChildCare}': ({ formContext, fieldId }) => {
    let clientIndex
    try {
      clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    } catch (e) {
      return
    }
    return (
      _.get(
        formContext,
        `formData.application.clients[${clientIndex}].clientDetails.personalData[0].roleInChildCareHouseHold.key`
      ) === 'CPCPCH36325'
    )
  },
  '{member closure enums}': ({ fieldId, formContext }) => {
    let index = +fieldId.match('(?:.*)closureDetails_([0-9]+)_?')[1]
    const member = _.get(
      formContext,
      `formData.application.closureDetails[${index}].membersForClosure`
    )
    let benefits = _.cloneDeep(formContext.benefitsArray)
    const members = _.get(
      formContext,
      `formData.application.closureDetails`
    ).map(m => m.membersForClosure)

    _.remove(benefits, b => {
      return _.includes(members, b.clientId) && b.clientId != member
    })

    return benefits.map(b => b.clientId)
  },
  '{member closure enumNames}': ({ fieldId, formContext }) => {
    let index = +fieldId.match('(?:.*)closureDetails_([0-9]+)_?')[1]
    const member = _.get(
      formContext,
      `formData.application.closureDetails[${index}].membersForClosure`
    )
    let benefits = _.cloneDeep(formContext.benefitsArray)
    const members = _.get(
      formContext,
      `formData.application.closureDetails`
    ).map(m => m.membersForClosure)

    _.remove(benefits, b => {
      return _.includes(members, b.clientId) && b.clientId != member
    })

    return benefits.map(b => b.name)
  },
  '{member closure benefits}': ({ fieldId, formContext }) => {
    try {
      let index = +fieldId.match('(?:.*)closureDetails_([0-9]+)_?')[1]
      const member = _.get(
        formContext,
        `formData.application.closureDetails[${index}].membersForClosure`
      )
      const benefits = _.find(formContext.benefitsArray, b => {
        return b.clientId == member
      })
      return _.get(benefits, 'program', [])
    } catch (ex) {
      return []
    }
  },
  '{old client}': (formContext, fieldId, formData, schema, uiSchema) => {
    let index = formContext.fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    if (+index + 1 <= formContext.formContext.oldClients) {
      return true
    }
    return false
  },
  '{coc or incomeSourceNameBenefits}': ({ formContext, fieldId }) => {
    if (formContext.isRenewal) {
      if (formContext.SNAP || formContext.MDPA || formContext.HEWL) {
        return '*'
      } else {
        return ''
      }
    } else {
      return '*'
    }
  },
  '{coc or snap}': ({ formContext }) => {
    if (!formContext.isRenewal) {
      return '*'
    } else {
      if (formContext.SNAP) {
        return '*'
      } else {
        return ''
      }
    }
  },
  '{counties for st}': ({ fieldId, formContext }) => {
    const state = getSiblingFormData(fieldId, 'st', formContext)
    return (formContext.countyJson && formContext.countyJson[state]) || []
  },
  '{counties for stwv}': ({ fieldId, formContext }) => {
    const state = 'WV'
    return (formContext.countyJson && formContext.countyJson[state]) || []
  },
  '{over 18}': ({ formContext, fieldId }) => {
    let clientIndex
    try {
      clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    } catch (e) {
      return
    }
    let snpFlnyOvrEighteenFlag = false
    let thisApplicant = _.get(
      formContext,
      `formData.application.clients[${clientIndex}].clientDetails.personalData[0].clientBthDt`,
      {}
    )
    let birthdate = _moment(config, thisApplicant)
    let cur = _moment(config, formContext.formContextCurrentDate)
    if (cur.diff(birthdate, 'years') >= 18) {
      snpFlnyOvrEighteenFlag = true
      return snpFlnyOvrEighteenFlag
    } else {
      snpFlnyOvrEighteenFlag = false
    }
    return snpFlnyOvrEighteenFlag
  },
  '{selected deductions}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    try {
      let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
      let enumsData =
        formContext.reviewForms[formContext.STEPS.EXPENSES - 1].schema
          .properties.application.properties.clients.items.properties
          .clientExpenses.properties.deduction.items.properties.deductionType
      let deductionsSelected = _.get(
        formContext,
        `formData.application.clients[${index}].clientExpenses.deduction[0]`,
        {}
      )
      if (deductionsSelected) {
        if (deductionsSelected.deductionType === '12') {
          return 'Business'
        } else if (deductionsSelected.deductionType === '19') {
          return 'Moving'
        } else {
          if (
            enumsData.enumNames[
              enumsData.enum.indexOf(deductionsSelected.deductionType)
            ]
          ) {
            return enumsData.enumNames[
              enumsData.enum.indexOf(deductionsSelected.deductionType)
            ]
          }
        }
      }
      return '"X"'
    } catch (err) {
      return '"X"'
    }
  },
  '{FDNT}': ({ formContext }) => {
    return false
  },
  '{FDNT star}': ({ formContext }) => {
    if (formContext.FDNT) {
      return '*'
    }
    return ''
  },
  '{FDNT and LIEAP star}': ({ formContext }) => {
    if (formContext.FDNT || formContext.LIEAP) {
      return '*'
    }
    return ''
  },
  '{maritalStatus star}': ({ formContext }) => {
    if (formContext.FDNT || formContext.MDPA) {
      return '*'
    }
    return ''
  },
  '{HMEN}': ({ formContext }) => {
    if (formContext.LIEAP) {
      return true
    }
    return false
  },
  '{HEWL}': ({ formContext }) => {
    if (formContext.HEWL) {
      return true
    }
    return false
  },
  '{AsesstsRenewal}': ({ formContext }) => {
    if (formContext.SNAP || formContext.MPA) {
      return true
    }
    return false
  },
  '{CHCR}': () => {
    return true
  },
  '{CHSP}': () => {
    return false
  },
  '{SCAL}': () => {
    return false
  },
  '{MDPA}': ({ formContext }) => {
    return false
  },
  '{female over 10}': ({ formContext, fieldId }) => {
    let clientIndex
    try {
      clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    } catch (e) {
      return
    }
    let clients = _.get(formContext, 'formData.application.clients', {})
    let thisApplicant = clients[clientIndex].clientDetails.personalData[0]
    if (_.get(thisApplicant, 'gdrCd.key') == 'GDGEFE31388') {
      let birthdate = _moment(config, thisApplicant.clientBthDt)
      let cur = _moment(config, formContext.formContextCurrentDate)
      if (cur.diff(birthdate, 'years') >= 10) {
        return true
      } else {
        return false
      }
    }
  },
  '{male applicant}': ({ formContext, fieldId }) => {
    let clientIndex
    try {
      clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    } catch (e) {
      return
    }
    let clients = _.get(formContext, 'formData.application.clients', {})
    let maleApplicant = false
    let thisApplicant = clients[clientIndex].clientDetails.personalData[0]
    maleApplicant =
      _.get(thisApplicant, 'gdrCd.key') == 'GDGEMA83644' ? true : false
    return maleApplicant
  },
  '{star householdRequestForADA}': ({ formContext, fieldId }) => {
    return ''
  },
  //Below are refs for labels specific to ClientUpdateWizard that change depending on the mode our component is in.
  '{disability start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did your health insurance coverage start?'
      : 'Health Insurance Begin Date'
  },
  '{disable householdInfo}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    // if (
    //   formContext.formData.application.hasOwnProperty('changes')
    // ) {
    //   return formContext.formData.application.changes === 'N'
    //     ? true
    //     : false
    // } else {
    //   return true
    // }
    return false
  },
  '{health insurance start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did your health insurance coverage start?'
      : 'Health Insurance Begin Date'
  },
  '{expense start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did this expense start?'
      : 'Start Date'
  },
  '{earned income start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did your earned income start?'
      : 'Income Start Date'
  },
  '{self employment start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did your self-employment income start?'
      : 'Income Start Date'
  },
  '{hide add label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    let clients = _.get(formContext, 'formData.application.clients', {})
    return clients.length <= 1 ? true : false
  },
  '{unearned income start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did your unearned income start?'
      : 'Income Start Date'
  },
  '{banking account start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date was this account opened?'
      : 'Account Start Date'
  },
  '{other asset start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did this begin?'
      : 'Asset Start Date'
  },
  '{cash start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did this balance start?'
      : 'Balance Start Date'
  },
  '{trust start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did your trust account start?'
      : 'Trust Start Date'
  },
  '{insurance policy start date label}': ({
    formContext,
    fieldId,
    formData,
    schema,
    uiSchema
  }) => {
    return helper.resolveStringRefs(
      '{tracker mode}',
      formContext,
      fieldId,
      formData,
      schema,
      uiSchema
    ) === 'ADD'
      ? 'What date did this insurance policy begin?'
      : 'Policy Start Date'
  },
  '{is a citizen}': ({ formData, fieldId }) => {
    try {
      let usCtznOrNatInd = ''
      let naturalizedCtzn = ''
      const currentCitizenship = formData.filter(element => {
        return (
          element.action !== 'INVALIDATED' &&
          typeof element.effectiveEndDate !== 'string'
        )
      })
      if (currentCitizenship[0]) {
        usCtznOrNatInd = currentCitizenship[0].usCtznOrNatInd || ''
        naturalizedCtzn = currentCitizenship[0].naturalizedCtzn || ''
      }
      return usCtznOrNatInd === 'Y' || naturalizedCtzn === 'Y'
    } catch (err) {
      return undefined
    }
  },
  '{other applicants id releationship}': ({ formContext, fieldId }) => {
    let clients = _.get(formContext, 'formData.application.clients', [])
    let keyValuePairs = clients.map(client => {
      return {
        enumNames: client.clientDetails.personalData[0].clientFstNm,
        value: client.identifier
      }
    })
    let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let releationIndex
    if (fieldId.includes('trackerAdd')) {
      releationIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      return keyValuePairs.map(key => {
        return key.value
      })
    }
    const relationShips = _.get(
      formContext,
      `formData.application.clients[${mainIndex}].clientDetails.relationShip`,
      []
    ).map(relationShip => {
      return relationShip.relClientName
    })
    const currentRelationShip = _.get(
      formContext,
      `localFormData[${releationIndex}]`,
      {}
    ).relClientName
    const currentIndex = _.get(
      formContext,
      `formData.application.clients[${mainIndex}].identifier`,
      ''
    )
    _.remove(relationShips, x => {
      return x == currentRelationShip
    })

    keyValuePairs = keyValuePairs.filter(key => {
      return !_.includes(relationShips, key.value) && key.value != currentIndex
    })
    return keyValuePairs.map(key => {
      return key.value
    })
  },
  '{other applicants or contributors releationship}': ({
    formContext,
    fieldId
  }) => {
    let clients = _.get(formContext, 'formData.application.clients', [])
    let keyValuePairs = clients.map(client => {
      return {
        enumNames: client.clientDetails.personalData[0].clientFstNm,
        value: client.identifier
      }
    })
    let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let releationIndex
    if (fieldId.includes('trackerAdd')) {
      releationIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      return keyValuePairs.map(key => {
        return key.enumNames
      })
    }
    const relationShips = _.get(
      formContext,
      `formData.application.clients[${mainIndex}].clientDetails.relationShip`,
      []
    ).map(relationShip => {
      return relationShip.relClientName
    })
    const currentRelationShip = _.get(
      formContext,
      `localFormData[${releationIndex}]`,
      {}
    ).relClientName
    const currentIndex = _.get(
      formContext,
      `formData.application.clients[${mainIndex}].identifier`,
      ''
    )
    _.remove(relationShips, x => {
      return x == currentRelationShip
    })
    keyValuePairs = keyValuePairs.filter(key => {
      return !_.includes(relationShips, key.value) && key.value != currentIndex
    })
    return keyValuePairs.map(key => {
      return key.enumNames
    })
  },
  '{other applicants id tax}': ({ formContext, fieldId }) => {
    let clients = _.get(formContext, 'formData.application.clients', [])
    let keyValuePairs = clients.map(client => {
      return {
        enumNames: client.clientDetails.personalData[0].clientFstNm,
        value: client.identifier
      }
    })
    let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let taxIndex
    if (fieldId.includes('trackerAdd')) {
      taxIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      return keyValuePairs.map(key => {
        return key.value
      })
    }
    const taxRecords = _.get(
      formContext,
      `formData.application.clients[${mainIndex}].clientDetails.taxInformation`,
      []
    ).map(taxRecord => {
      return taxRecord.relClientName
    })
    const currentTaxIndex = _.get(formContext, `localFormData[${taxIndex}]`, {})
      .relClientName
    const currentIndex = _.get(
      formContext,
      `formData.application.clients[${mainIndex}].identifier`,
      ''
    )
    _.remove(taxRecords, x => {
      return x == currentTaxIndex
    })

    keyValuePairs = keyValuePairs.filter(key => {
      return !_.includes(taxRecords, key.value) && key.value != currentIndex
    })
    return keyValuePairs.map(key => {
      return key.value
    })
  },
  '{other applicants or contributors tax}': ({ formContext, fieldId }) => {
    let clients = _.get(formContext, 'formData.application.clients', [])
    let keyValuePairs = clients.map(client => {
      return {
        enumNames: client.clientDetails.personalData[0].clientFstNm,
        value: client.identifier
      }
    })
    let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let taxIndex
    if (fieldId.includes('trackerAdd')) {
      taxIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
    } else {
      return keyValuePairs.map(key => {
        return key.enumNames
      })
    }
    const taxRecords = _.get(
      formContext,
      `formData.application.clients[${mainIndex}].clientDetails.taxInformation`,
      []
    ).map(taxRecord => {
      return taxRecord.relClientName
    })
    const currentTaxIndex = _.get(formContext, `localFormData[${taxIndex}]`, {})
      .relClientName
    const currentIndex = _.get(
      formContext,
      `formData.application.clients[${mainIndex}].identifier`,
      ''
    )
    _.remove(taxRecords, x => {
      return x == currentTaxIndex
    })

    keyValuePairs = keyValuePairs.filter(key => {
      return !_.includes(taxRecords, key.value) && key.value != currentIndex
    })
    return keyValuePairs.map(key => {
      return key.enumNames
    })
  },
  '{disable relClientName}': ({ formContext, fieldId }) => {
    if (fieldId.includes('trackerAdd')) {
      return false
    } else {
      return true
    }
  },
  '{enableChildCare}': 'return false;',
  '{other applicants id}': ({ formContext, fieldId }) => {
    let otherPeople = []
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    for (let i = 0; i < formContext.formData.application.clients.length; ++i) {
      if (i != index) {
        otherPeople.push(i.toString())
      }
    }
    return otherPeople
  },
  '{other applicants or contributors}': ({ formContext, fieldId }) => {
    let otherPeople = []
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    for (let i = 0; i < formContext.formData.application.clients.length; ++i) {
      if (i != index) {
        otherPeople.push(
          formContext.formData.application.clients[
            i
          ].clientDetails.personalData[0].clientFstNm.toString()
        )
      }
    }
    return otherPeople
  },
  '{all clients}': ({ formContext, fieldId }) => {
    let otherPeople = []
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    for (let i = 0; i < formContext.formData.application.clients.length; ++i) {
      otherPeople.push(i.toString())
    }
    return otherPeople
  },
  '{all clients enumNames}': ({ formContext, fieldId }) => {
    let otherPeople = []
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    for (let i = 0; i < formContext.formData.application.clients.length; ++i) {
      otherPeople.push(
        formContext.formData.application.clients[
          i
        ].clientDetails.personalData[0].clientFstNm.toString()
      )
    }
    return otherPeople
  },
  '{oneClient}':
    'return formContext.formData.application.clients.length > 1 ? true : false',
  '{is primary applicant}':
    "let index = fieldId.match('(?:.*)clients_([0-9]+)_?') && fieldId.match('(?:.*)clients_([0-9]+)_?')[1]; return (index === '0');",
  '{isClientSelectedForCHCR}': 'return false',
  '{this applicant birthDate}': ({ formContext, fieldId }) => {
    let clientIndex
    try {
      clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    } catch (e) {
      return
    }
    let personalData = _.cloneDeep(
      _.get(
        formContext,
        'formData.application.clients[' +
          clientIndex +
          '].clientDetails.personalData',
        {}
      )
    )
    if (!personalData || personalData.length == 0) {
      return
    }
    const activePersonalData = _.maxBy(
      personalData.filter(e => e.clientBthDt !== ''),
      'clientBthDt'
    )
    if (activePersonalData != undefined) {
      return _moment(
        config,
        activePersonalData.clientBthDt,
        'YYYY-MM-DD'
      ).format('MM/DD/YYYY')
    } else {
      return ''
    }
    return activePersonalData != undefined
      ? activePersonalData.clientBthDt
      : ' '
  },
  '{Tax Info greaterthan zero}': ({ formContext, fieldId }) => {
    let clientId = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let clients = _.get(formContext, 'formData.application.clients', {})
    if (clients && clients.length == 1) {
      return false
    }
    return true
  },
  '{smo taxInformation}': ({ formContext, fieldId }) => {
    let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let taxInfoIndex
    if (!fieldId.includes('trackerAdd') && !fieldId.includes('trackerChange')) {
      taxInfoIndex = fieldId.match('(?:.*)taxInformation_([0-9]+)_?')[1]
    }
    if (taxInfoIndex) {
      return (
        _.get(
          formContext,
          `formData.application.clients[${mainIndex}].clientDetails.taxInformation[${taxInfoIndex}].relClientName`
        ) === 'Other'
      )
    }
  },
  '{smo  having income taxInformation}': ({ formContext, fieldId }) => {
    let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let taxInfoIndex
    if (!fieldId.includes('trackerAdd') && !fieldId.includes('trackerChange')) {
      taxInfoIndex = fieldId.match('(?:.*)taxInformation_([0-9]+)_?')[1]
    }
    if (taxInfoIndex) {
      return (
        _.get(
          formContext,
          `formData.application.clients[${mainIndex}].clientDetails.taxInformation[${taxInfoIndex}].relClientName`
        ) === 'Other' &&
        _.get(
          formContext,
          `formData.application.clients[${mainIndex}].clientDetails.taxInformation[${taxInfoIndex}].otherPersonHaveIncome`
        ) === 'Y'
      )
    }
  },

  '{smo  having Deductions taxInformation}': ({ formContext, fieldId }) => {
    let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let taxInfoIndex
    if (!fieldId.includes('trackerAdd') && !fieldId.includes('trackerChange')) {
      taxInfoIndex = fieldId.match('(?:.*)taxInformation_([0-9]+)_?')[1]
    }
    if (taxInfoIndex) {
      return (
        _.get(
          formContext,
          `formData.application.clients[${mainIndex}].clientDetails.taxInformation[${taxInfoIndex}].relClientName`
        ) === 'Other' &&
        _.get(
          formContext,
          `formData.application.clients[${mainIndex}].clientDetails.taxInformation[${taxInfoIndex}].otherPersonHaveIncomeDeductions`
        ) === 'Y'
      )
    }
  },
  '{school enrollment status}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let educationDetails = _.get(
      formContext,
      `formData.application.clients[${clientIndex}]
      .clientAdditionalInformation`
    )
    if (
      educationDetails.education[0].enrolledRightNow != '4' ||
      educationDetails.education[0].enrolledRightNow == undefined ||
      educationDetails.education[0].enrolledRightNow == null
    ) {
      return true
    }
    return false
  },

  '{taxInfoRef}': ({ formContext, fieldId }) => {
    const prgNames = _.get(formContext, 'prgNames', '')
    if (prgNames.includes('Health Care Benefits')) {
      return true
    }
    return false
  },
  '{primary applicant name array}':
    " let esign=[];  esign.push(formContext.clientFstNm.concat(' ').concat(formContext.clientLstNm)); return esign;",
  '{current date}': 'return formContext.currentDate;',
  '{client number}': ({ fieldId }) => {
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    return (parseInt(index) + 1).toString()
  },
  '{get gender code}': ({ fieldId, formContext }) => {
    let index =
      fieldId.match('(?:.*)clients_([0-9]+)_?') &&
      fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

    let genderCode = _.get(
      formContext,
      `formData.application.clients[${index}].clientDetails.personalData[0].gdrCd.key`,
      'all'
    )
    return genderCode
  },
  '{disable relationName}': ({ fieldId, formContext }) => {
    let index =
      fieldId.match('(?:.*)clients_([0-9]+)_?') &&
      fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

    let genderCode = _.get(
      formContext,
      `formData.application.clients[${+index}].clientDetails.personalData[0].gdrCd.key`
    )
    return genderCode == undefined
  },
  '{notes for existing disability}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let disabilityData = _.get(
      formContext,
      'formData.application.clients[' +
        clientIndex +
        '].clientAdditionalInformation.disability'
    )
    if (
      disabilityData &&
      disabilityData.length > 0 &&
      (disabilityData[0].isNew === false ||
        disabilityData[0].isNew === undefined)
    ) {
      return 'Please contact your DoHS Agency Worker to make changes to existing disability information.'
    }
    return ''
  },
  '{disable for existing disability data}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let disabilityData = _.get(
      formContext,
      `formData.application.clients['${clientIndex}'].clientAdditionalInformation.disability`
    )
    if (
      disabilityData &&
      disabilityData.length > 0 &&
      (disabilityData[0].isNew === false ||
        disabilityData[0].isNew === undefined)
    ) {
      return true
    }
    return false
  },

  '{Not mandatory if not accessible}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let disabilityData = _.get(
      formContext,
      `formData.application.clients['${clientIndex}'].clientAdditionalInformation.disability`
    )
    if (
      disabilityData &&
      disabilityData.length > 0 &&
      (disabilityData[0].isNew === false ||
        disabilityData[0].isNew === undefined)
    ) {
      return ''
    }
    return '*'
  },

  '{householdLength}': ({ formContext, fieldId }) => {
    return formContext.formData.application.clients.length - 1
  },
  '{usCtznOrNational}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    if (
      _.get(
        formContext,
        `formData.application.clients[${clientIndex}].clientDetails.citizenshipImmigration[0].usCtznOrNatInd`
      ) === 'N'
    ) {
      return true
    }
    return false
  },
  '{disable Reference Individual field}': ({ formContext, fieldId }) => {
    let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let taxInfoIndex
    if (fieldId.includes('trackerChange')) {
      taxInfoIndex = fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
    }
    if (taxInfoIndex) {
      return true
    }
    return false
  },
  '{TANF}': () => {
    return false
  },
  '{CCA}': () => {
    return true
  },
  '{CCAageCheck}': ({ formContext, fieldId }) => {
    if (true) {
      const index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
      const getAge = _.get(
        formContext,
        `formData.application.clients[${index}].clientDetails.personalData[0].clientBthDt`,
        undefined
      )
      if (getAge !== undefined) {
        const birthdate = _moment(config, getAge)
        const cur = _moment(config, formContext.formContextCurrentDate)
        if (
          cur.diff(birthdate, 'years') >= 5 &&
          cur.diff(birthdate, 'years') <= 17
        ) {
          return false
        } else {
          return true
        }
      }
    }

    return false
  },
  '{other person name}': ({ fieldId, formContext }) => {
    let index =
      fieldId.match('(?:.*)clients_([0-9]+)_?') &&
      fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let newlyAddedTaxinfo = []
    function getFieldIdKindTaxInfo() {
      if (
        formContext?.formData?.application?.clients[index]?.clientDetails
          ?.taxInformation.length
      ) {
        newlyAddedTaxinfo = formContext?.formData?.application?.clients[
          index
        ]?.clientDetails?.taxInformation.filter(item => item?.isNew == true)
      }
    }
    let innerIndex, otherPerson
    if (fieldId.includes('trackerAdd')) {
      innerIndex = +fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
      getFieldIdKindTaxInfo()
    } else if (
      fieldId.includes('Review') ||
      fieldId.includes('reviewDifference')
    ) {
      innerIndex = +fieldId.match('(?:.*)taxInformation_([0-9]+)_?')[1]
    } else {
      innerIndex = +fieldId.match('(?:.*)trackerChange_([0-9]+)_?')[1]
    }

    if (index && !fieldId.includes('trackerAdd')) {
      otherPerson = _.get(
        formContext,
        `formData.application.clients[${index}].clientDetails.taxInformation[${innerIndex}]`,
        []
      )
    } else {
      if (newlyAddedTaxinfo.length && typeof innerIndex == 'number')
        otherPerson = newlyAddedTaxinfo[innerIndex]
      else otherPerson = undefined
    }

    if (otherPerson) {
      return (
        otherPerson.otherPersonFstNm +
        (otherPerson.otherPersonLstNm ? ' ' + otherPerson.otherPersonLstNm : '')
      )
    } else {
      return '{other person name}'
    }
  },
  '{state enums}': ({ formContext, fieldId }) => {
    return formContext.stateEnum
  },
  '{state enum names}': ({ formContext, fieldId }) => {
    return formContext.stateEnumNames
  },
  '{banking accounts other person}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let bankingAccountsIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]

    var nameOfThePersonData = _.get(
      formContext,
      `formData.application.clients['${clientIndex}'].clientAssets.bankingAccounts['${bankingAccountsIndex}'].nameOfThePerson`
    )

    if (
      nameOfThePersonData &&
      nameOfThePersonData.length > 0 &&
      nameOfThePersonData[nameOfThePersonData.length - 1] == 'Other'
    ) {
      return true
    }
    return false
  },
  '{cash other person}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let cashIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]

    var nameOfThePersonData = _.get(
      formContext,
      `formData.application.clients['${clientIndex}'].clientAssets.cash['${cashIndex}'].nameOfThePerson`
    )

    if (
      nameOfThePersonData &&
      nameOfThePersonData.length > 0 &&
      nameOfThePersonData[nameOfThePersonData.length - 1] == 'Other'
    ) {
      return true
    }
    return false
  },
  '{trusts other person}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let trustsIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]

    var nameOfThePersonData = _.get(
      formContext,
      `formData.application.clients['${clientIndex}'].clientAssets.trusts['${trustsIndex}'].nameOfThePerson`
    )

    if (
      nameOfThePersonData &&
      nameOfThePersonData.length > 0 &&
      nameOfThePersonData[nameOfThePersonData.length - 1] == 'Other'
    ) {
      return true
    }
    return false
  },
  '{liquid assets other person}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let liquidAssetsIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]

    var nameOfThePersonData = _.get(
      formContext,
      `formData.application.clients['${clientIndex}'].clientAssets.investmentAccounts['${liquidAssetsIndex}'].nameOfThePerson`
    )

    if (
      nameOfThePersonData &&
      nameOfThePersonData.length > 0 &&
      nameOfThePersonData[nameOfThePersonData.length - 1] == 'Other'
    ) {
      return true
    }
    return false
  },
  '{vehicles other person}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let vehiclesIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]

    var nameOfThePersonData = _.get(
      formContext,
      `formData.application.clients['${clientIndex}'].clientAssets.vehicles['${vehiclesIndex}'].nameOfThePerson`
    )

    if (
      nameOfThePersonData &&
      nameOfThePersonData.length > 0 &&
      nameOfThePersonData[nameOfThePersonData.length - 1] == 'Other'
    ) {
      return true
    }
    return false
  },
  '{other assets other person}': ({ formContext, fieldId }) => {
    let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let otherAssetsIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]

    var nameOfThePersonData = _.get(
      formContext,
      `formData.application.clients['${clientIndex}'].clientAssets.otherAssets['${otherAssetsIndex}'].nameOfThePerson`
    )

    if (
      nameOfThePersonData &&
      nameOfThePersonData.length > 0 &&
      nameOfThePersonData[nameOfThePersonData.length - 1] == 'Other'
    ) {
      return true
    }
    return false
  }
}
