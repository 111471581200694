import * as React from 'react'
import { Modal, Button } from 'react-bootstrap'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { Accordion, Pagination, Collapse } from 'react-bootstrap'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import * as _ from 'lodash'
import { I18n } from 'react-redux-i18n'
import ConfirmModal from './ConfirmModal'
import { Link } from 'react-router-dom'
import '!style-loader!css-loader!../src/customStyles.css'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { ENTITLEMENTS_wv as wvEnt } from '../src/entitlements_wv'
import { Panel } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Panel'

const baseUrl = process.env.PUBLIC_URL

interface MyDashboardPresentationProps {
  loadScreen: boolean
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  onFormDataChange: (formData: any) => void
  showUploadModal: boolean
  closeUploadModal: () => void
  onFormDataChangeModal: (formData: any) => void
  onaccordionclickset: () => void
  sendToApplication: (appId: string) => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  popupoad: boolean
  applId: any
  uuid: any
  userId: any
  orgId: any
  userRoleId: any
  roleId: number
  roleName: string
  errorMessage: Array<any>
  onDeleteClick: () => void
  userAccess: {
    selectedUserRole: any
    userRoles: Array<UserRole>
  }
  history: any
  handleAccordionClick: any
  helpExpanded: boolean
}

export default function MyDashboardPresentation(
  props: MyDashboardPresentationProps
) {
  let confirmationModal

  const {
    loadScreen,
    forms,
    formData,
    formContext,
    sendToApplication,
    onFormDataChange,
    showUploadModal,
    roleId,
    roleName,
    closeUploadModal,
    onFormDataChangeModal,
    onaccordionclickset,
    formComponents,
    popupoad,
    applId,
    uuid,
    userRoleId,
    orgId,
    userId,
    onDeleteClick,
    errorMessage,
    userAccess,
    helpExpanded
  } = props

  const selectedUserRole = _.find(props.userAccess.userRoles, {
    userRoleId:
      props.userAccess.selectedUserRole &&
      _.toInteger(props.userAccess.selectedUserRole.userRoleId)
  })
  const selectedEntitlements = _.get(selectedUserRole, 'entitlements', []).map(
    element => element.entitlementName
  )

  const CP_PE_START_APP = _.includes(
    selectedEntitlements,
    wvEnt.CP_PE_START_APP
  )
    ? true
    : false

  let helpLink, cpHelpLink, peHelpLink
  peHelpLink = (
    <div className="helpLinkWrapper col-lg-3 col-12">
      {CP_PE_START_APP && (
        <Button
          className="margin-regular btn btn-primary startButton pe-button-focus"
          id="startPreElig"
          onClick={() => sendToApplication(null)}
        >
          {I18n.t('MyDashboard.startPresumptiveEligibility')}
        </Button>
      )}
      <Link
        to={baseUrl + '/benefitsfinderWV'}
        className="margin-regular btn btn-primary Screen pe-button-focus"
      >
        {I18n.t('MyDashboard.screenForAssistance')}
      </Link>
      <Link
        to={baseUrl + '/benefitscatalog'}
        className="margin-regular btn btn-primary Programs pe-button-focus"
      >
        {I18n.t('MyDashboard.programsServices')}
      </Link>
      <Panel
        expanded={helpExpanded}
        onSelect={onaccordionclickset}
        header={I18n.t('MyDashboard.otherHelpfulLinks')}
      >
        <ul className="listText">
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="http://www.wvdhhr.org/bccsp/"
              target="_blank"
            >
              West Virginia Breast and Cervical Screening Program (BCC)
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://dhhr.wv.gov/bms/Pages/default.aspx"
              target="_blank"
            >
              BMS Website
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.wvmmis.com/default.aspx"
              target="_blank"
            >
              Molina
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://dhhr.wv.gov/bms/BMSPUB/Documents/Guide%20to%20Medicaid%202023.pdf"
              target="_blank"
            >
              Your Guide to Medicaid
            </a>
          </li>
        </ul>
      </Panel>
    </div>
  )

  const CP_CPN_START_FULL_APP = _.includes(
    selectedEntitlements,
    wvEnt.CP_CPN_START_FULL_APP
  )
    ? true
    : false
  cpHelpLink = (
    <div className="helpLinkWrapper col-lg-3 col-12">
      {CP_CPN_START_FULL_APP && (
        <Link
          to={baseUrl + '/application'}
          className="margin-regular btn btn-primary startButton pe-button-focus"
        >
          Start Full Application
        </Link>
      )}
      <Link
        to={baseUrl + '/benefitsfinderWV'}
        className="margin-regular btn btn-primary Screen pe-button-focus"
      >
        Screen for Assistance
      </Link>
      <Link
        to={baseUrl + '/benefitscatalog'}
        className="margin-regular btn btn-primary Programs pe-button-focus"
      >
        Programs & Services
      </Link>

      <Panel
        expanded={helpExpanded}
        onSelect={onaccordionclickset}
        header={'Other Helpful Links'}
      >
        <ul className="listText">
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://chip.wv.gov/Pages/default.aspx"
              target="_blank"
            >
              WV CHIP
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://dhhr.wv.gov/bcf/Services/familyassistance/Pages/Medicaid.aspx"
              target="_blank"
            >
              Medicaid
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.fns.usda.gov/"
              target="_blank"
            >
              Food and Nutritional Services (SNAP, WIC, and other nutritional
              programs)
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://dhhr.wv.gov/bcf/Services/familyassistance/Pages/Supplemental-Nutritional-Assistance-Program-(Former-Food-Stamp-Program).aspx"
              target="_blank"
            >
              Supplemental Nutritional Assistance Program
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href=" https://dhhr.wv.gov/bcf/Services/familyassistance/Pages/Medicare-Premium-Assistance-Programs.aspx"
              target="_blank"
            >
              Medicare Premium Assistance Program
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://dhhr.wv.gov/bcf/Services/familyassistance/Pages/School-Clothing-Allowance.aspx"
              target="_blank"
            >
              School Clothing Allowance
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://dhhr.wv.gov/bcf/Services/familyassistance/Pages/Utility-Assistance.aspx"
              target="_blank"
            >
              Low Income Energy Assistance Program
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: 'underline' }}
              href="https://dhhr.wv.gov/bcf/Childcare/Pages/default.aspx"
              target="_blank"
            >
              Child Care
            </a>
          </li>
        </ul>
      </Panel>
    </div>
  )

  if (
    roleName === 'Presumptive Eligibility Worker' ||
    roleName === 'PE Admin'
  ) {
    helpLink = peHelpLink
  } else if (roleName === 'Community Partner' || roleName === 'CP Admin') {
    helpLink = cpHelpLink
  }
  confirmationModal = popupoad ? (
    <ConfirmModal
      getApplID={applId}
      getUUID={uuid}
      getuserId={userId}
      getorgId={orgId}
      history={props.history}
      handleAccordionClick={props.handleAccordionClick}
    />
  ) : null

  if (loadScreen) {
    return (
      <div className="row" style={{ margin: 0 }}>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <div className="mainContentWrapper col-lg-9 col-12">
          <Form
            schema={forms[0]['schema']}
            uiSchema={forms[0]['uiSchema']}
            formData={formData}
            formContext={formContext}
            onChange={onFormDataChange}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          >
            <button type="submit" className="buttonWrapper" />
          </Form>
        </div>
        {helpLink}
        <Modal show={showUploadModal} onHide={closeUploadModal}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="col-lg-12">
              <Form
                schema={forms[1]['schema']}
                uiSchema={forms[1]['uiSchema']}
                formData={formData.overridePeData}
                formContext={formContext}
                onChange={onFormDataChangeModal}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              >
                <button type="submit" className="buttonWrapper" />
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {
              <button className="btn" onClick={closeUploadModal}>
                {I18n.t('General.cancel')}
              </button>
            }
          </Modal.Footer>
        </Modal>
        {confirmationModal}
      </div>
    )
  } else {
    return (
      <div className="container-fluid reports-container">
        <div>
          <ErrorModal
            errorMessage={errorMessage}
            onDeleteClick={onDeleteClick}
          />
          <ScreenPreLoader loaded={loadScreen} />
        </div>
      </div>
    )
  }
}
