import * as React from 'react'
import * as _ from 'lodash'
import {
  TabContainer,
  TabPane,
  TabContent,
  Modal,
  Button
} from 'react-bootstrap'
import MyDocumentsAccordion from '../../base/MyDocumentsAccordion'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import NavTabs from '@optum-wvie/dynamic-ui-framework/src/components/natives/NavTabs'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src'
import MessageModal from '@optum-wvie/dynamic-ui-framework/src/components/natives/MessageModal'
import { Validator } from 'jsonschema'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import OnNextButton from './OnNextButton'
import Instructions from './Instructions'
// const TabPane = require('@optum-wvie/dynamic-ui-framework/src/components/natives/CustomTabPane')

interface ClientUpdateWizardPresentationProps {
  isRenewal: boolean
  STEPS: {
    GENERAL_INFORMATION: number
    PERSONAL: number
    ADDITIONAL_INFORMATION: number
    CHILD_CARE: number
    INCOME: number
    EXPENSES: number
    ASSETS: number
    DOCUMENTS: number
    REVIEW_AND_SIGN: number
  }
  step: number
  tabs: {
    status: string
    leftIcon: string
    rightIcon: string
    rightSpan: string
    isVisible: boolean
  }[]
  forms: {
    schema: Object
    uiSchema: Object
    metaData: Object
  }[]
  formData: any
  formContext: any
  callingApi: boolean
  showDraftModal: boolean
  liveValidate: boolean
  showNonDraftModal: boolean
  caseId: number
  modalProps: {
    show: boolean
    cancel: () => {}
    confirm: () => void
    title: React.ReactNode
    body: React.ReactNode
    bsSize: string
  }
  showNext: boolean

  onPrevious: () => void
  onExitClick: () => void
  saveFormDataDraft: () => void
  changeView: (val: number) => void
  onDocUpload: (uploadedDoc: any) => void
  onUploadDelete: (deletedDoc: any) => void
  onSubmit: () => void
  onNormalChange: ({ formData: any }) => void
  onValidate: (formData: object, errors: object) => void
  onErrorClearingChange: ({ formData: any }) => void
  errorListTemplate: (props: any) => void
  onError: (errors: object) => void
  onConfirmMessage: () => void
  onNonConfirmMessage: () => void

  validator: Validator

  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  errorMessage: Array<any>
  onDeleteClick: () => void
  mainContent: any
  hasError: boolean
}

export default function ClientUpdateWizardPresentation(
  props: ClientUpdateWizardPresentationProps
) {
  const {
    isRenewal,
    STEPS,
    step,
    tabs,
    forms,
    formData,
    formContext,
    callingApi,
    liveValidate,
    caseId,
    modalProps,
    onPrevious,
    onExitClick,
    saveFormDataDraft,
    changeView,
    onDocUpload,
    onUploadDelete,
    onSubmit,
    onNormalChange,
    onValidate,
    showDraftModal,
    onConfirmMessage,
    onErrorClearingChange,
    errorListTemplate,
    onError,
    validator,
    formComponents,
    showNext,
    showNonDraftModal,
    onNonConfirmMessage,
    errorMessage,
    onDeleteClick,
    hasError,
    mainContent
  } = props

  if (!_.isEmpty(forms) && !_.isEmpty(formData)) {
    let title
    if (isRenewal) {
      title = 'Benefits Application Renewal'
    } else {
      title = 'Life Event Update'
    }
    let previousButton
    let submitButton
    if (step === forms.length) {
      submitButton = (
        <button
          id="ClientUpdateWizard_submitBtn"
          type="submit"
          className="btn btn-default float-right sp-md"
          disabled={callingApi}
        >
          Submit
        </button>
      )
    } else if (step !== 1 || (showNext && step == 1)) {
      if (showNext && step == 1) {
        submitButton = hasError ? (
          <button
            id="ClientUpdateWizard_nextBtn"
            type="submit"
            className="btn btn-default float-right sp-md nextButton btn-danger"
            disabled={callingApi}
            aria-label="There are errors on this page"
          >
            Errors
          </button>
        ) : (
          <button
            id="ClientUpdateWizard_nextBtn"
            type="submit"
            className="btn btn-default float-right sp-md nextButton"
            disabled={callingApi}
          >
            Next
          </button>
        )
      } else {
        submitButton = hasError ? (
          <button
            id="ClientUpdateWizard_nextBtn"
            type="submit"
            className="btn btn-default float-right sp-md btn-danger"
            disabled={callingApi}
            aria-label="There are errors on this page"
          >
            Errors
          </button>
        ) : (
          <button
            id="ClientUpdateWizard_nextBtn"
            type="submit"
            className="btn btn-default float-right sp-md"
            disabled={callingApi}
          >
            Next
          </button>
        )
      }
    } else {
      submitButton = null
    }

    if (step !== 1) {
      previousButton = (
        <button
          type="button"
          className={`btn btn-default float-left sp-md ${
            hasError ? 'btn-danger' : 'btn-default'
          }`}
          onClick={onPrevious}
          disabled={callingApi}
        >
          Previous
        </button>
      )
    } else {
      previousButton = null
    }
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="application-title col-lg-12">
              <h1 className="heading">
                <span className="fa fa-shopping-cart icon-left title-icon" />
                {title}
              </h1>
            </div>
            <div className="below-header-bar">
              <div className="content">
                <button
                  className="help btn btn-tiny btn-white"
                  onClick={onExitClick}
                  disabled={callingApi}
                >
                  <span className="fa fa-times btn-icon-sm" />
                  Exit
                </button>
                <button
                  className="save btn btn-tiny btn-white"
                  onClick={saveFormDataDraft}
                  disabled={callingApi}
                >
                  <span className="fa fa-floppy-o btn-icon-sm" />
                  Save
                </button>
                <button className="help btn btn-tiny btn-white">
                  <span className="fa fa-life-ring btn-icon-sm" />
                  Help
                </button>
                <button
                  className="printStyle print btn btn-tiny btn-white printpage"
                  onClick={function() {
                    window.print()
                  }}
                >
                  <span className="fa fa-print btn-icon-sm" />
                  Print
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              id="side-nav"
              className="tabs-section col-md-4 col-lg-3 col-xl-2 no-padding"
            >
              {isRenewal ? (
                <NavTabs
                  activeKey={step - 1}
                  onNavClick={() => {}}
                  tabs={tabs}
                  disabled={callingApi}
                  formContext={formContext}
                  labelledByKey={'client-update-wizard-tab-container-tab'}
                  labelledByIndexStart={1}
                  classNameRenewals={'renewals-screen-cursor disabled'}
                  renewalsDisable={true}
                />
              ) : (
                <NavTabs
                  activeKey={step - 1}
                  onNavClick={changeView}
                  tabs={tabs}
                  disabled={callingApi}
                  formContext={formContext}
                  labelledByKey={'client-update-wizard-tab-container-tab'}
                  labelledByIndexStart={1}
                />
              )}
            </div>
            <div
              id="content"
              className="form-section col-md-8 col-lg-9 col-xl-10 no-padding dynamic-form px-0"
              tabIndex={-1}
              ref={mainContent}
            >
              <div>
                {forms[step - 1].uiSchema['externalOptions'] &&
                forms[step - 1].uiSchema['externalOptions']['instructions'] ? (
                  <Instructions
                    options={
                      forms[step - 1]['uiSchema']['externalOptions'][
                        'instructions'
                      ]
                    }
                  />
                ) : null}
              </div>
              <div
                className="form-section col-lg-12 col-xl-12"
                id="client-update-wizard-container"
              >
                <TabContainer
                  activeKey={step}
                  onSelect={() => {}}
                  id="client-update-wizard-tab-container"
                >
                  <TabContent>
                    {forms.map((form, index) => {
                      return (
                        <TabPane
                          mountOnEnter={true}
                          transition={null}
                          eventKey={index + 1}
                          key={index}
                        >
                          {index + 1 === STEPS.DOCUMENTS ? (
                            <MyDocumentsAccordion
                              appIntake="true"
                              appId={caseId}
                              applicants={formData.application.clients}
                              onDocUpload={onDocUpload}
                              onUploadDelete={onUploadDelete}
                            />
                          ) : (
                            <Form
                              schema={form.schema}
                              uiSchema={form.uiSchema}
                              onSubmit={onSubmit}
                              formData={formData}
                              onChange={onNormalChange}
                              formContext={formContext}
                              fields={formComponents.fields}
                              widgets={formComponents.widgets}
                              ArrayFieldTemplate={
                                formComponents.ArrayFieldTemplate
                              }
                              FieldTemplate={formComponents.FieldTemplate}
                              validate={onValidate}
                              liveValidate={liveValidate}
                              ErrorList={errorListTemplate}
                              onError={onError}
                              jsonValidator={validator}
                              onErrorClearingChange={onErrorClearingChange}
                            >
                              <div className="col-12">
                                {previousButton}
                                <OnNextButton {...props} index={index} />
                              </div>
                            </Form>
                          )}
                        </TabPane>
                      )
                    })}
                  </TabContent>
                </TabContainer>
              </div>
            </div>
          </div>
        </div>
        <MessageModal {...modalProps} />
        <MessageModal
          show={showDraftModal}
          confirm={onConfirmMessage}
          title={
            <span>
              <h4 className="popup-title"> Your changes are being saved</h4>
            </span>
          }
          body={
            <div className="row">
              <div className="col-lg-12">
                <h6 className="headertxt">
                  You have successfully saved your changes.
                </h6>
              </div>
            </div>
          }
          bsSize={'lg'}
          className={'lg-width-to-md'}
        />
        <MessageModal
          show={showNonDraftModal}
          confirm={onNonConfirmMessage}
          title={
            <span>
              <h4 className="popup-title">Warning</h4>
            </span>
          }
          body={
            <div className="row">
              <div className="col-lg-12">
                <h6 className="headertxt">
                  Your changes cannot be saved, rather they should be submitted.
                </h6>
              </div>
            </div>
          }
          bsSize={'lg'}
          className={'lg-width-to-md'}
        />
        <ErrorModal
          errorMessage={props.errorMessage}
          onDeleteClick={props.onDeleteClick}
        />
      </div>
    )
  } else if (
    !_.isEmpty(forms) &&
    _.isEmpty(formData) &&
    props.errorMessage.length > 0
  ) {
    return (
      <div>
        <ErrorModal
          errorMessage={props.errorMessage}
          onDeleteClick={props.onDeleteClick}
        />
      </div>
    )
  }
  return (
    <div>
      <ScreenPreLoader />
    </div>
  )
}
