export default function hasCCA(state = { hasCCA: null }, action) {
  switch (action.type) {
    case 'SET_HAS_CCA':
      return {
        ...state,
        hasCCA: action.hasCCA
      }
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        hasCCA: false
      }
    default:
      return state
  }
}
