import { forEach, merge, includes } from 'lodash'
import * as queryString from 'query-string'
import LocalStorage from '@optum-wvie/dynamic-ui-framework/src/localStorage'

const _ = { forEach, merge, includes }

//////////////////////////////////////////////////////////////////////////////////////
//START TENANT CONFIG SECTION
//Update this section for every new tenant. The functions must be synchronous.
import {
  isImplemented as isImpl_WV,
  getConfig as getConfig_WV,
  determineEnvironment as getEnv_WV,
  determineTenant as getTen_WV
} from './config_WV'

import {
  isImplemented as isImpl_MN,
  getConfig as getConfig_MN,
  determineEnvironment as getEnv_MN
} from './config_MN'

const TENANTS = {
  WV: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
    getTen: getTen_WV
  },
  WVDE: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
    getTen: getTen_WV
  },
  WVTE: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
    getTen: getTen_WV
  },
  WVSE: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
    getTen: getTen_WV
  },
  WVUT: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
    getTen: getTen_WV
  },
  WVST: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
    getTen: getTen_WV
  },
  WVPT: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
    getTen: getTen_WV
  },
  WVMO: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
    getTen: getTen_WV
  },
  WVTR: {
    isImpl: isImpl_WV,
    getEnv: getEnv_WV,
    getConfig: getConfig_WV,
    getTen: getTen_WV
  },
  mn: {
    isImpl: isImpl_MN,
    getEnv: getEnv_MN,
    getConfig: getConfig_MN
  }
}

//END TENANT CONFIG SECTION
//////////////////////////////////////////////////////////////////////////////////////

let environment: string = null
let tenantCode: string = null
let buildType: string = 'Core'

_.forEach(TENANTS, (value, key) => {
  if (value.isImpl()) {
    //We are in an implementation build.
    environment = value.getEnv()
    tenantCode = value.getTen()
    buildType = 'Product'
    return false
  }
})

if (!environment) {
  environment = determineBaseEnvironment()
}

if (!tenantCode) {
  tenantCode = determineBaseTenant(environment)
  LocalStorage.setItem(`IE-${environment}-CP-TENANT`, tenantCode)
}

const getTenantConfig = TENANTS[tenantCode].getConfig

export let config = initConfig()

function initConfig() {
  let baseConfig: any = {
    portalName: 'cp',
    defaultTenantId: '1',
    environment,
    buildType,
    languages: [
      {
        locale: 'en', //TODO: dialect?
        name: 'English'
      },
      {
        locale: 'es',
        name: 'Español'
      }
    ],
    defaultExpiryMs: 15 * 60000,
    countdownTimerMs: 5 * 60000
  }

  //Retrieve environment-level config.
  switch (environment) {
    case 'LOCAL':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        //Edit this variable to target localhost fetches.
        gatewayBaseUrl: 'https://ie-apigw-tst.optum.com',
        formsBaseUrl:
          // 'http://localhost:7070/api/ie/forms/{tenantCode}/{version}',
          'https://ie-apigw-tst.optum.com/api/ie/forms/{tenantCode}/{version}',
        processingBaseUrl:
          'https://ie-apigw-tst.optum.com/api/application/processing/program/v1.0',
        localhostUrl: 'http://localhost:8080',
        openId_localhostAccount: {
          username: 'Pavani',
          uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
          firstName: 'Pavani',
          lastName: 'Dudala',
          email: 'pavanidqa@gmail.com',
          defaultUserRoleId: 825
        }
      }
      break

    case 'SBX':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        //Edit this variable to target localhost fetches.
        gatewayBaseUrl: 'https://ie-apigw-tst.optum.com',
        // "formsBaseUrl" : "http://localhost:7070/api/ie/forms/{tenantCode}/{version}",
        formsBaseUrl:
          'https://ie-api-gw-dev.optum.com/api/ie/forms/{tenantCode}/{version}',
        processingBaseUrl: 'http:///api/application/processing/program/v1.0',
        localhostUrl: 'http://ie-clientportal-ie-sbx.ose-ctc-dmz.optum.com',
        openId_localhostAccount: {
          username: 'Pavani',
          uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
          firstName: 'Pavani',
          lastName: 'Dudala',
          email: 'pavanidqa@gmail.com',
          defaultUserRoleId: 825
        }
      }
      break

    // OpenShift Environments
    case 'OS-DEV':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        gatewayBaseUrl: 'https://ie-apigw-dev.optum.com',
        formsBaseUrl:
          'https://ie-apigw-dev.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-clt-dev.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break

    case 'OS-TST':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        gatewayBaseUrl: 'https://ie-apigw-tst.optum.com',
        formsBaseUrl:
          'https://ie-apigw-tst.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-clt-tst.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break

    case 'OS-STG':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        gatewayBaseUrl: 'https://ie-apigw-stg.optum.com',
        formsBaseUrl:
          'https://ie-apigw-stg.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-clt-stg.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break

    case 'OS-DEMO':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        gatewayBaseUrl: 'https://ie-apigw-demo.optum.com',
        formsBaseUrl:
          'https://ie-apigw-demo.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-clt-demo.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break

    // OFE ENVIRONMENT
    case 'OFEDEV':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        gatewayBaseUrl: 'https://ie-api-gw-int1.optum.com',
        formsBaseUrl:
          'https://ie-api-gw-int1.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-client-dev.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break

    case 'OFEUAT':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        gatewayBaseUrl: 'https://ie-api-gw-uat.optum.com',
        formsBaseUrl:
          'https://ie-api-gw-uat.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-client-uat.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break

    case 'OFETST':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        gatewayBaseUrl: 'https://ie-api-gw-test.optum.com',
        formsBaseUrl:
          'https://ie-api-gw-test.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-client-test.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break

    case 'OFESTG':
      baseConfig = {
        ...baseConfig,
        isProd: true,
        gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
        formsBaseUrl:
          'https://ie-api-gw-stage.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-client-stage.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break

    case 'OFEPROD':
      baseConfig = {
        ...baseConfig,
        isProd: true,
        gatewayBaseUrl: 'https://ie-api-gw.optum.com',
        formsBaseUrl:
          'https://ie-api-gw.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-client.optum.com&scope=openid+profile+email&state=IE&nonce='
      }
      break

    case 'DEV':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        gatewayBaseUrl: 'https://ie-apigw-dev.optum.com',
        formsBaseUrl:
          'https://ie-apigw-dev.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-cp-dev.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break
    case 'TEST':
      baseConfig = {
        ...baseConfig,
        isProd: false,
        gatewayBaseUrl: 'https://ie-apigw-tst.optum.com',
        formsBaseUrl:
          'https://ie-apigw-tst.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-cp-tst.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break
    case 'STAGING':
      baseConfig = {
        ...baseConfig,
        isProd: true,
        gatewayBaseUrl: 'https://ie-apigw-stg.optum.com',
        formsBaseUrl:
          'https://ie-apigw-stg.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-cp-stg.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break
    case 'DEMO':
      baseConfig = {
        ...baseConfig,
        isProd: true,
        gatewayBaseUrl: 'https://ie-apigw-demo.optum.com',
        formsBaseUrl:
          'https://ie-apigw-demo.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-cp-demo.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break
    case 'PRF':
      baseConfig = {
        ...baseConfig,
        isProd: true,
        gatewayBaseUrl: 'https://ie-apigw-prf.optum.com',
        formsBaseUrl:
          'https://ie-apigw-prf.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-mn-clt-prf.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
      break
    default:
      baseConfig = {
        ...baseConfig,
        isDefaultBaseCase: true,
        isProd: false,
        gatewayBaseUrl: 'https://ie-apigw-tst.optum.com',
        formsBaseUrl:
          'https://ie-apigw-tst.optum.com/api/ie/forms/{tenantCode}/{version}',
        openId_loginUrl:
          'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IECP&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-cp-dev.optum.com%2Fie-portal-webapp%2Freact%2Fhome&scope=openid+profile+email&state=IE&nonce='
      }
  }

  // prettier-ignore
  baseConfig = {
    ...baseConfig,
    domainV1Path: '/api/ie/domain/v2/{tenantCode}',
    enrollmentPath: '/api/ie/enrollment/v2/{tenantId}',
    appProcessingPath: '/api/ie/applicationProcessing/v2',
    eligibilityPath: '/api/ie/eligibility/v2',
    userManagementPath: '/api/ie/usrmgmt/v1',
    authorizationPath: '/api/ie/usrmgmt/v1',
    noticesPath: '/api/ie/noticesgate/v2',
    providerPath: '/api/ie/domain/v1/{tenantId}',
    getDraftApplication:
      '{gatewayBaseUrl}{appProcessingPath}/secure/getDraftApplication/{tenantCode}?appId={applId}',
    getUserAccountDetails:
      '{gatewayBaseUrl}{appProcessingPath}/secure/getUserAccountDetails/{tenantCode}',
    getCommunityPartnerApplicationsInfo:
      '{gatewayBaseUrl}{appProcessingPath}/secure/getCommunityPartnerApplicationsInfo/{tenantCode}',
    prgmDateRange: "{gatewayBaseUrl}{providerPath}/program/dates/{progCd}",
    getCaseBenefitDetailsCP: '{gatewayBaseUrl}/api/ie/cp/clientdetails/v2/{tenantId}/getBenefitDetails',
    getOrgByServiceCP: '{gatewayBaseUrl}/api/ie/workloadmgmt/v1/{tenantId}/{type}/getOrgByService',
    getCountriesCP: '{gatewayBaseUrl}/api/ie/referencedata/v2/{tenantCode}/countries',
    getCOCEndpointCP: '{gatewayBaseUrl}/api/ie/cp/application/v2/{tenantId}/getCocData',
    getRenewalEndpointCP: '{gatewayBaseUrl}/api/ie/cp/application/v2/{tenantId}/getCaseReviewData',
    submitCocRenewalEndpointCP: '{gatewayBaseUrl}/api/ie/cp/application/v2/{tenantId}/submitBenefitApplication',
    saveDraftAppCP: '{gatewayBaseUrl}/api/ie/cp/application/v2/saveDraftApplication',
    getDraftAppCP: '{gatewayBaseUrl}/api/ie/cp/application/v2/{tenantId}/getApplication/{applId}/{applType}',
    downloadDocument: '{gatewayBaseUrl}/api/ie/cp/wrapper/v1/{tenantId}/getDocument?base64Response=false&docID={docID}&sourceSystem=wvcs',
    authorizationTokenEndpoint:
      '{gatewayBaseUrl}{authorizationPath}/authorize/{tenantCode}/token?code={authorizationCode}',
    authorizationUserinfoEndpoint:
      '{gatewayBaseUrl}{authorizationPath}/authorize/{tenantCode}/userinfo',
    forms_AppIntake:
      '{formsBaseUrl}/cp/AppIntakeCS',
    forms_AppIntakeFACCA:
      '{formsBaseUrl}/cp/AppIntakeFACCA',
    ChildSupportPayments: '{formsBaseUrl}/cp/ChildSupportPayments',
    forms_AppIntake_Errors:
      '{formsBaseUrl}/cp/AppIntakeErrorMessages',
    form_AppIntakePE:
      '{formsBaseUrl}/cp/AppIntakePE',
    forms_MyAppDrafts:
      '{formsBaseUrl}/cp/myDraftSchema',
    forms_MyApplications:
      '{formsBaseUrl}/cp/myApplications',
    forms_Applications:
      '{formsBaseUrl}/cp/SearchApplications',
    forms_MyDashboard:
      '{formsBaseUrl}/cp/myDashboard',
    forms_MyPEApplications:
      '{formsBaseUrl}/cp/myPEApplication',
    forms_MyMessages:
      '{formsBaseUrl}/cp/myMessages',
    forms_MyLifeEvents:
      '{formsBaseUrl}/cp/myLifeEvents',
    forms_MyPermission:
      '{formsBaseUrl}/cp/myPermission',
    forms_MyProfile:
      '{formsBaseUrl}/cp/myProfileSchema',
    forms_MyProfileCS:
      '{formsBaseUrl}/cp/myProfileSchemaCS',
    forms_MyDocuments:
      '{formsBaseUrl}/cp/myDocumentSchema',
    forms_MyDocumentsAccordion:
      '{formsBaseUrl}/cp/myDocumentAccordionSchema',
    forms_Feedback:
      '{formsBaseUrl}/cp/Feedback',
    forms_MyBenefits:
      '{formsBaseUrl}/cp/myBenefits',
    forms_MyProviders:
      '{formsBaseUrl}/cp/myProviders',
    forms_MyProvidersNew:
      '{formsBaseUrl}/cp/MyProvidersNew',
    forms_RenewalWizard:
      '{formsBaseUrl}/cp/RenewalWizard',
      forms_AppIntakeSchemaPRC2: '/cp/AppIntakeSchemaPRC2',
      forms_RenewalWizardPRC2:
      '{formsBaseUrl}/cp/RenewalWizardPRC2',
    forms_RenewalWizardCP:
      '{formsBaseUrl}/cp/RenewalWizardCP',
    getStateList: '{formsBaseUrl}/cp/states',
    getCountyList: '{formsBaseUrl}/cp/counties',
    form_AppIntakePEContainer:
      '{formsBaseUrl}/cp/AppIntakePE',
    forms_AppIntakeSchema:
      '{formsBaseUrl}/ap/AppIntakeSchema',
    // Retrieve and View DocuSigned Pdf
    forms_LifeEventsWizard:
      '{formsBaseUrl}/cp/LifeEventsWizard',
    forms_LifeEventsWizardCP:
      '{formsBaseUrl}/cp/LifeEventsWizardCP',
    validationErrorMsgs_AppIntakeSchema: '{formsBaseUrl}/cp/AppIntakeSchemaErrorsCP',
    validationErrorMsgs_AppIntakeSchemaFA: '{formsBaseUrl}/cp/AppIntakeSchemaErrors',
    forms_AppIntakeSchemaCP: '{formsBaseUrl}/cp/AppIntakeSchemaCP',
    getUserRoles:
      '{gatewayBaseUrl}{userManagementPath}/{tenantId}/users/roles',
    updateDefaultUserRole:
      '{gatewayBaseUrl}{userManagementPath}/{tenantId}/users/defaultUserRole/{userRoleId}/{isDefaultUserRole}',
    getAllRoles:
      '{gatewayBaseUrl}{userManagementPath}/{tenantId}/roles',
    forms_MyPESearchApplication:
      '{formsBaseUrl}/cp/myPESearchApplication',
    helpSelfService:
      '{formsBaseUrl}/cp/CommonQuestions',
    CPStaticTranslation:
      '{formsBaseUrl}/cp/translations',
    SharedStaticTranslation:
      '{formsBaseUrl}/shared/translations',
    forms_benefitsFinder:
      '{formsBaseUrl}/shared/benefitsFinder',
    refreshTokenEndpoint:
      '{gatewayBaseUrl}{authorizationPath}/authorize/{tenantCode}/token/refresh',
    myFormsSchema:
      '{formsBaseUrl}/cp/myFormsSchema'
  }

  let finalConfig = null
  if (getTenantConfig) {
    const tenantConfig = getTenantConfig(baseConfig)
    //Merge the tenant configuration after base, so it will take priority.
    finalConfig = _.merge({}, baseConfig, tenantConfig)
  } else {
    finalConfig = baseConfig
  }

  // apply tenant.id and tenant.code to endpoints
  Object.keys(finalConfig).forEach(key => {
    if (typeof finalConfig[key] === 'string')
      finalConfig[key] = finalConfig[key]
        .replace('{gatewayBaseUrl}', finalConfig['gatewayBaseUrl'])
        .replace('{formsBaseUrl}', finalConfig['formsBaseUrl'])
        .replace('{domainV1Path}', finalConfig['domainV1Path'])
        .replace('{enrollmentPath}', finalConfig['enrollmentPath'])
        .replace('{appProcessingPath}', finalConfig['appProcessingPath'])
        .replace('{eligibilityPath}', finalConfig['eligibilityPath'])
        .replace('{userManagementPath}', finalConfig['userManagementPath'])
        .replace('{authorizationPath}', finalConfig['authorizationPath'])
        .replace('{noticesPath}', finalConfig['noticesPath'])
        .replace('{providerPath}', finalConfig['providerPath'])
        .replace('{tenantId}', finalConfig.tenant.id)
        .replace('{tenantCode}', tenantCode)
  })
  finalConfig.tenantCode = tenantCode
  return finalConfig
}

function determineBaseEnvironment() {
  //This is for the core product. Implementation repos should export their own "determineEnvironment"
  const url = window.location.href.toLowerCase()
  if (_.includes(url, 'localhost')) {
    return 'LOCAL'
  }

  // OpenShift Environments
  if (_.includes(url, '-clt-dev')) return 'OS-DEV'
  if (_.includes(url, '-clt-tst')) return 'OS-TST'
  if (_.includes(url, '-clt-stg')) return 'OS-STG'
  if (_.includes(url, '-clt-demo')) return 'OS-DEMO'

  // OFE Environments
  if (_.includes(url, 'ie-client-dev')) return 'OFEDEV'
  if (_.includes(url, 'ie-client-test')) return 'OFETST'
  if (_.includes(url, 'ie-client-stage')) return 'OFESTG'
  if (_.includes(url, 'ie-client-uat')) return 'OFEUAT'
  if (_.includes(url, 'ie-client')) return 'OFEPROD'

  // UCI Environments
  if (_.includes(url, '-sbx')) return 'SBX'

  if (_.includes(url, '-dev')) {
    return 'DEV'
  }
  if (_.includes(url, '-tst')) {
    return 'TEST'
  }
  if (_.includes(url, '-stg')) {
    return 'STAGING'
  }
  if (_.includes(url, '-demo')) {
    return 'DEMO'
  }
  if (_.includes(url, '-prf')) {
    return 'PRF'
  }
  if (_.includes(url, 'ie-testing')) {
    return 'DEV'
  }
  return 'LOCAL'
}

function determineBaseTenant(environment) {
  //We simulating tenant in the core product. Try to determine tenant in the following order, taking the first resolved.
  //0. Check if we're in OFE, if so, we only have MN
  //1. URL prefix
  //2. URL "tenant" query parameter
  //3. Last known tenant for this environment, saved to local storage.

  const { url, query } = queryString.parseUrl(window.location.href)

  // In OFE we no longer care about the implementation details of the WV portals; we only have 1 tenant and it's MN
  const baseEnv = determineBaseEnvironment().toLocaleUpperCase()
  if (
    baseEnv == 'OFEDEV' ||
    baseEnv == 'OFETST' ||
    baseEnv == 'OFESTG' ||
    baseEnv.substr(0, 3) == 'OFE'
  )
    return 'mn'

  //Determine the tenant based on the URL prefix
  const prefixMatch = url
    .toLowerCase()
    .match('^(?:(?:http|https)://)ie-([a-zA-Z][a-zA-Z])-')
  if (prefixMatch && prefixMatch[1]) {
    return prefixMatch[1].toLowerCase()
  }

  //Determine the tenant based on the tenant URL parameter
  if (query && query.tenant) {
    return query.tenant.toLowerCase()
  }

  //Determine the tenant based on what was last saved to local storage.
  let localStorageTenant = LocalStorage.getItem(`IE-${environment}-CP-TENANT`)
  if (localStorageTenant && localStorageTenant.length > 0) {
    return localStorageTenant.toLowerCase()
  }

  //Default to WV if somehow we have not determined the tenant.
  return 'WVSE'
}
