import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import '!style-loader!css-loader!./organization.css'
import { Modal, Button, FormControl, Table } from 'react-bootstrap'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { Validator } from 'jsonschema'

interface OrganizationPresentationProps {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  formContext: any
  formData: any
  onFormDataChange: (formData: any) => void
  isLoading: boolean
  minHeight: string
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  orgDataSave: () => void
  someElement: any
  orgData: any
  openDays: any
  daysTable: (e: any, type: string, day: string) => void
  displayNameOrg: any
  handleInputChange: (e) => void
  updateCancel: () => void
  saveRequest: boolean
  closeSaveModal: () => void
  userAccess: {
    selectedOrg: {
      roleName: any
      orgName: any
    }
    selectedUserRole: {
      userRoleId: any
      uuid: any
      role: {
        roleId: any
        roleName: any
      }
    }
  }
  initialLoad: boolean
  clear: any
  search: (formData: any, event: any) => void
  userCount: boolean
  clearSearchResults: any
  workerAdminOrgList: any
  orgnizationListData: any
  userDetailViewId
  switchOnLiveValidate: () => void
  backToUser: () => void
  updateUser: (data: any, event: any) => void
  liveValidate: boolean
  // errorListTemplate: () => void
  // transformErrors: () => void
  onUserFormDataChange: (formData: any) => void
  showModalError: boolean
  showModalMessage: string
  showModal: boolean
  hideModal: () => void
  showNewOrg: () => void
  technicalDescription: string
  errorModal: boolean
  errorDisplay: boolean
  errorMessage: Array<any>
  onDeleteClick: () => void

  // Error implementation
  onValidate: (formData: object, errors: object) => void
  errorListTemplate: (props: any) => void
  onError: (errors: object) => void
  transformErrors: (errors: object) => void
  validator: Validator
  onSubmit: ({ formData: any }) => void
  noopCancel: () => void
}

export default function OrganizationPresentation(
  props: OrganizationPresentationProps
) {
  const {
    form,
    formContext,
    formData,
    onFormDataChange,
    isLoading,
    minHeight,
    formComponents,
    orgData,
    orgDataSave,
    openDays,
    daysTable,
    displayNameOrg,
    handleInputChange,
    updateCancel,
    saveRequest,
    closeSaveModal,
    userAccess,
    initialLoad,
    clear,
    search,
    userCount,
    workerAdminOrgList,
    orgnizationListData,
    clearSearchResults,
    userDetailViewId,
    switchOnLiveValidate,
    backToUser,
    updateUser,
    liveValidate,
    errorListTemplate,
    transformErrors,
    onUserFormDataChange,
    showModalError,
    showModalMessage,
    showModal,
    hideModal,
    showNewOrg,
    technicalDescription,
    errorModal,
    errorDisplay,
    errorMessage,
    onDeleteClick,
    onValidate,
    onError,
    validator,
    onSubmit,
    noopCancel
  } = props

  let headerDisplay
  let footerDisplay

  const header = isLoading ? (
    <h2 />
  ) : (
    <div className="userListButtonDiv">
      <span id="userTableTitle">Organization</span>
    </div>
  )

  let saveRequestModal = { errorModal } ? (
    <Modal show={saveRequest} onHide={closeSaveModal}>
      <Modal.Header closeButton>
        <Modal.Title>Save Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="headertxt">
          {technicalDescription ? technicalDescription : 'Save Successful.'}
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => closeSaveModal()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal show={saveRequest} onHide={closeSaveModal}>
      <Modal.Header closeButton>
        <Modal.Title>Save Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="headertxt">Save Successful.</h6>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => closeSaveModal()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
  if (form) {
    if (userDetailViewId) {
      const userD = [
        {
          userID: userDetailViewId.userID || 1234,
          email: userDetailViewId.email,
          firstName: userDetailViewId.firstName,
          lastName: userDetailViewId.lastName,
          dataAgreementCompletionDate: _moment(
            config,
            userDetailViewId.dataAgreementCompletionDate
          ).format('YYYY-MM-DD'),
          trainingConfirmationDate: _moment(
            config,
            userDetailViewId.trainingConfirmationDate
          ).format('YYYY-MM-DD'),
          status: userDetailViewId.status === 'Active' ? 'No' : 'Yes',
          lastloginDate: '08/29/2018',
          currentRole: userDetailViewId.roleName,
          roleName: userDetailViewId.roleName,
          roleId: userDetailViewId.roleId
        }
      ]
      const uFormData = {
        Application: {
          usercompleteDetails: userD
        }
      }

      const uFormContext = {
        ...formContext,
        formData: uFormData
      }
      return (
        <div className="userDetailsUserTab">
          <ErrorModal
            errorMessage={props.errorMessage}
            onDeleteClick={props.onDeleteClick}
          />
          <div>
            <div className="row">
              <h1>{userDetailViewId.userID || 1234}</h1>
            </div>
            <hr className="lineSeparator" />
          </div>
          <div style={{ marginTop: '70px' }}>
            <Form
              schema={form.app.forms[4].schema}
              uiSchema={form.app.forms[4].uiSchema}
              formData={uFormData}
              onChange={onUserFormDataChange}
              formContext={uFormContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              transformErrors={transformErrors}
              ErrorList={errorListTemplate}
              liveValidate={liveValidate}
              onSubmit={updateUser.bind(null, userDetailViewId)}
            >
              <div className="row backUserClass">
                <button
                  type="button"
                  className="userListButton1 btn"
                  onClick={backToUser}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="userListButton2 float-right"
                  onClick={switchOnLiveValidate}
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      )
    }
    const orgFormData = {
      Application: orgData
    }

    const orgFormContext = {
      ...formContext,
      formData: orgFormData
    }

    const workerAdminFormData = {
      Application: workerAdminOrgList
    }

    const workerAdminFormContext = {
      ...formContext,
      formData: workerAdminFormData
    }

    const timeArray = [
      { value: 0, key: '12:00 am' },
      { value: 1, key: '1:00 am' },
      { value: 2, key: '2:00 am' },
      { value: 3, key: '3:00 am' },
      { value: 4, key: '4:00 am' },
      { value: 5, key: '5:00 am' },
      { value: 6, key: '6:00 am' },
      { value: 7, key: '7:00 am' },
      { value: 8, key: '8:00 am' },
      { value: 9, key: '9:00 am' },
      { value: 10, key: '10:00 am' },
      { value: 11, key: '11:00 am' },
      { value: 12, key: '12:00 pm' },
      { value: 13, key: '1:00 pm' },
      { value: 14, key: '2:00 pm' },
      { value: 15, key: '3:00 pm' },
      { value: 16, key: '4:00 pm' },
      { value: 17, key: '5:00 pm' },
      { value: 18, key: '6:00 pm' },
      { value: 19, key: '7:00 pm' },
      { value: 20, key: '8:00 pm' },
      { value: 21, key: '9:00 pm' },
      { value: 22, key: '10:00 pm' },
      { value: 23, key: '11:00 pm' }
    ]

    if (props.userAccess.selectedUserRole.role.roleName === 'CP Admin') {
      headerDisplay = (
        <div>
          <div className="row">
            <h1 className="col-lg-8">{props.userAccess.selectedOrg.orgName}</h1>
          </div>
          <hr className="lineSeparator" />
        </div>
      )
      footerDisplay = (
        <div className="header">
          <button
            id="org_cancelButton"
            className="btn cancel-style"
            type="button"
            onClick={noopCancel}
          >
            Cancel
          </button>
          <button
            className="saveButton btn saveButton_hover pe-button-focus"
            id="org_saveButton"
            type="submit"
            onClick={switchOnLiveValidate}
          >
            Save
          </button>
        </div>
      )
    } else if (
      props.userAccess.selectedUserRole.role.roleName === 'Worker Admin'
    ) {
      headerDisplay = <div></div>
      footerDisplay = (
        <div>
          <div className="header">
            <button
              id="org_cancelButton"
              className="btn cancel-style"
              onClick={updateCancel}
            >
              Cancel
            </button>
            <button
              className="saveButton btn saveButton_hover pe-button-focus"
              id="org_saveButton"
              type="submit"
              onClick={switchOnLiveValidate}
            >
              Save
            </button>
          </div>
        </div>
      )
    } else {
      headerDisplay = (
        <div>
          <div className="row">
            <h1 className="col-lg-8">{props.userAccess.selectedOrg.orgName}</h1>
          </div>
          <hr className="lineSeparator" />
        </div>
      )
      footerDisplay = <div></div>
    }
    if (
      initialLoad &&
      props.userAccess.selectedUserRole.role.roleName === 'Worker Admin'
    ) {
      let organizationformData = {
        organizationTable: workerAdminFormData.Application
          ? workerAdminFormData.Application
          : []
      }
      let organizationformContext = {
        ...formContext,
        formData: organizationformData
      }
      let organizationListData = {
        ...formData,
        orgnizationList: {
          organizationCity: orgData.organizationCity,
          organizationName: orgData.organizationName,
          organizationZipcode: orgData.organizationZipcode
        }
      }
      let organizationFormContext = {
        ...formContext,
        formData: organizationListData
      }

      return (
        <div style={{ display: 'grid' }}>
          <ErrorModal
            errorMessage={props.errorMessage}
            onDeleteClick={props.onDeleteClick}
          />
          <div>
            <Form
              schema={form.app.forms[1].schema}
              uiSchema={form.app.forms[1].uiSchema}
              formData={organizationListData.orgnizationList}
              onChange={onFormDataChange}
              formContext={organizationFormContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
            >
              <div className="userSearchButtonDiv">
                <button
                  id="cancelButton_workerAdmin"
                  className="userListButton1 btn"
                  type="button"
                  onClick={clear}
                >
                  Clear
                </button>
                <button
                  id="searchButton_workerAdmin"
                  className="userListButton2 btn"
                  type="button"
                  onClick={event => search(formData, event)}
                >
                  Search
                </button>
              </div>
            </Form>
          </div>
          <div>
            <button
              className="addNewOrgBtn btn"
              type="button"
              onClick={showNewOrg}
              id="newOrganization_btn"
            >
              Add New Organization
            </button>
            <button
              id="clearSearchResults_btn"
              className="userListButton1 clrSearchResults btn"
              type="button"
              onClick={clearSearchResults}
            >
              Clear Search Results
            </button>

            <Form
              schema={form.app.forms[2].schema}
              uiSchema={form.app.forms[2].uiSchema}
              formData={organizationformData}
              onChange={onFormDataChange}
              formContext={organizationformContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
            />
          </div>
        </div>
      )
    } else if (
      userCount &&
      (props.userAccess.selectedUserRole.role.roleName === 'Worker Admin' ||
        props.userAccess.selectedUserRole.role.roleName === 'CP Admin')
    ) {
      let userCountformData = {
        usersList:
          orgFormData &&
          orgFormData.Application &&
          orgFormData.Application.usersList
      }
      let userCountformcontext = {
        ...formContext,
        formData: userCountformData
      }
      let modalHeader
      if (
        showModalMessage &&
        !showModalError &&
        props.showModalMessage.includes(
          'The changes have been successfully saved.'
        )
      ) {
        modalHeader = (
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
        )
      } else {
        modalHeader = (
          <Modal.Header closeButton>
            <Modal.Title />
          </Modal.Header>
        )
      }
      return (
        <div>
          <ErrorModal
            errorMessage={errorMessage}
            onDeleteClick={onDeleteClick}
          />
          <Form
            schema={form.app.forms[3].schema}
            uiSchema={form.app.forms[3].uiSchema}
            formData={userCountformData}
            onChange={onFormDataChange}
            formContext={userCountformcontext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          />
          <button
            type="button"
            className="userListButton1 btn"
            onClick={updateCancel}
          >
            Cancel
          </button>
          <Modal show={showModal} onHide={hideModal}>
            {modalHeader}
            <Modal.Body>
              <form>
                <div className="row">
                  <div className="col-12">{showModalMessage}</div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      )
    } else {
      return (
        <div>
          {saveRequestModal}
          {headerDisplay}
          <div className="pendingUserDataTable">
            <Form
              schema={form.app.forms[0].schema}
              uiSchema={form.app.forms[0].uiSchema}
              formData={
                orgFormData &&
                orgFormData.Application &&
                orgFormData.Application.agencyList &&
                orgFormData.Application.agencyList.length > 0
                  ? orgFormData
                  : {}
              }
              onChange={onFormDataChange}
              formContext={orgFormContext ? orgFormContext : {}}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              validate={onValidate}
              liveValidate={liveValidate}
              ErrorList={errorListTemplate}
              onError={onError}
              transformErrors={transformErrors}
              jsonValidator={validator}
              onSubmit={onSubmit}
            >
              <Table striped bordered hover>
                <caption className="days-open-style">
                  <b>Days Open</b>
                </caption>
                <thead>
                  <tr>
                    <th />
                    <th scope="col" id="ColOpen">
                      Open
                    </th>
                    <th scope="col" id="ColClose">
                      Close
                    </th>
                    <th scope="col" id="ColAllDay">
                      All Day
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="RowMonday">
                      Monday
                    </th>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Monday.open}
                          onChange={daysTable.bind(this, 'open', 'Monday')}
                          placeholder="select"
                          aria-labelledby="ColOpen RowMonday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Monday.open
                              ? timeArray.find(
                                  x => x.value == openDays.Monday.open
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColOpen RowMonday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Monday.close}
                          onChange={daysTable.bind(this, 'close', 'Monday')}
                          placeholder="select"
                          aria-labelledby="ColClose RowMonday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Monday.close
                              ? timeArray.find(
                                  x => x.value == openDays.Monday.close
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColClose RowMonday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Monday.allDay}
                          onChange={daysTable.bind(this, 'allDay', 'Monday')}
                          placeholder="select"
                          aria-labelledby="ColAllDay RowMonday"
                        >
                          <option value="select" />
                          <option value="Y">Open (24hrs)</option>
                          <option value="N">Closed</option>
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Monday.allDay
                              ? openDays.Monday.allDay == 'Y'
                                ? 'Open (24hrs)'
                                : 'Closed'
                              : ' '
                          }
                          aria-labelledby="ColAllDay RowMonday"
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" id="RowTuesday">
                      Tuesday
                    </th>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Tuesday.open}
                          onChange={daysTable.bind(this, 'open', 'Tuesday')}
                          placeholder="select"
                          aria-labelledby="ColOpen RowTuesday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Tuesday.open
                              ? timeArray.find(
                                  x => x.value == openDays.Tuesday.open
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColOpen RowTuesday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Tuesday.close}
                          onChange={daysTable.bind(this, 'close', 'Tuesday')}
                          placeholder="select"
                          aria-labelledby="ColClose RowTuesday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Tuesday.close
                              ? timeArray.find(
                                  x => x.value == openDays.Tuesday.close
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColClose RowTuesday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Tuesday.allDay}
                          onChange={daysTable.bind(this, 'allDay', 'Tuesday')}
                          placeholder="select"
                          aria-labelledby="ColAllDay RowTuesday"
                        >
                          <option value="select" />
                          <option value="Y">Open (24hrs)</option>
                          <option value="N">Closed</option>
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Tuesday.allDay
                              ? openDays.Tuesday.allDay == 'Y'
                                ? 'Open (24hrs)'
                                : 'Closed'
                              : ' '
                          }
                          aria-labelledby="ColAllDay RowTuesday"
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" id="RowWednesday">
                      Wednesday
                    </th>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Wednesday.open}
                          onChange={daysTable.bind(this, 'open', 'Wednesday')}
                          placeholder="select"
                          aria-labelledby="ColOpen RowWednesday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Wednesday.open
                              ? timeArray.find(
                                  x => x.value == openDays.Wednesday.open
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColOpen RowWednesday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Wednesday.close}
                          onChange={daysTable.bind(this, 'close', 'Wednesday')}
                          placeholder="select"
                          aria-labelledby="ColClose RowWednesday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Wednesday.close
                              ? timeArray.find(
                                  x => x.value == openDays.Wednesday.close
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColClose RowWednesday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Wednesday.allDay}
                          onChange={daysTable.bind(this, 'allDay', 'Wednesday')}
                          placeholder="select"
                          aria-labelledby="ColAllDay RowWednesday"
                        >
                          <option value="select" />
                          <option value="Y">Open (24hrs)</option>
                          <option value="N">Closed</option>
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Wednesday.allDay
                              ? openDays.Wednesday.allDay == 'Y'
                                ? 'Open (24hrs)'
                                : 'Closed'
                              : ' '
                          }
                          aria-labelledby="ColAllDay RowWednesday"
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" id="RowThursday">
                      Thursday
                    </th>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Thursday.open}
                          onChange={daysTable.bind(this, 'open', 'Thursday')}
                          placeholder="select"
                          aria-labelledby="ColOpen RowThursday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Thursday.open
                              ? timeArray.find(
                                  x => x.value == openDays.Thursday.open
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColOpen RowThursday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Thursday.close}
                          onChange={daysTable.bind(this, 'close', 'Thursday')}
                          placeholder="select"
                          aria-labelledby="ColClose RowThursday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Thursday.close
                              ? timeArray.find(
                                  x => x.value == openDays.Thursday.close
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColClose RowThursday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Thursday.allDay}
                          onChange={daysTable.bind(this, 'allDay', 'Thursday')}
                          placeholder="select"
                          aria-labelledby="ColAllDay RowThursday"
                        >
                          <option value="select" />
                          <option value="Y">Open (24hrs)</option>
                          <option value="N">Closed</option>
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Thursday.allDay
                              ? openDays.Thursday.allDay == 'Y'
                                ? 'Open (24hrs)'
                                : 'Closed'
                              : ' '
                          }
                          aria-labelledby="ColAllDay RowThursday"
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" id="RowFriday">
                      Friday
                    </th>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Friday.open}
                          onChange={daysTable.bind(this, 'open', 'Friday')}
                          placeholder="select"
                          aria-labelledby="ColOpen RowFriday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Friday.open
                              ? timeArray.find(
                                  x => x.value == openDays.Friday.open
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColOpen RowFriday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Friday.close}
                          onChange={daysTable.bind(this, 'close', 'Friday')}
                          placeholder="select"
                          aria-labelledby="ColClose RowFriday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Friday.close
                              ? timeArray.find(
                                  x => x.value == openDays.Friday.close
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColClose RowFriday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Friday.allDay}
                          onChange={daysTable.bind(this, 'allDay', 'Friday')}
                          placeholder="select"
                          aria-labelledby="ColAllDay RowFriday"
                        >
                          <option value="select" />
                          <option value="Y">Open (24hrs)</option>
                          <option value="N">Closed</option>
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Friday.allDay
                              ? openDays.Friday.allDay == 'Y'
                                ? 'Open (24hrs)'
                                : 'Closed'
                              : ' '
                          }
                          aria-labelledby="ColAllDay RowFriday"
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" id="RowSaturday">
                      Saturday
                    </th>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Saturday.open}
                          onChange={daysTable.bind(this, 'open', 'Saturday')}
                          placeholder="select"
                          aria-labelledby="ColOpen RowSaturday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Saturday.open
                              ? timeArray.find(
                                  x => x.value == openDays.Saturday.open
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColOpen RowSaturday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Saturday.close}
                          onChange={daysTable.bind(this, 'close', 'Saturday')}
                          placeholder="select"
                          aria-labelledby="ColClose RowSaturday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Saturday.close
                              ? timeArray.find(
                                  x => x.value == openDays.Saturday.close
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColClose RowSaturday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Saturday.allDay}
                          onChange={daysTable.bind(this, 'allDay', 'Saturday')}
                          placeholder="select"
                          aria-labelledby="ColAllDay RowSaturday"
                        >
                          <option value="select" />
                          <option value="Y">Open (24hrs)</option>
                          <option value="N">Closed</option>
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Saturday.allDay
                              ? openDays.Saturday.allDay == 'Y'
                                ? 'Open (24hrs)'
                                : 'Closed'
                              : ' '
                          }
                          aria-labelledby="ColAllDay RowSaturday"
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" id="RowSunday">
                      Sunday
                    </th>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Sunday.open}
                          onChange={daysTable.bind(this, 'open', 'Sunday')}
                          placeholder="select"
                          aria-labelledby="ColOpen RowSunday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Sunday.open
                              ? timeArray.find(
                                  x => x.value == openDays.Sunday.open
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColOpen RowSunday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Sunday.close}
                          onChange={daysTable.bind(this, 'close', 'Sunday')}
                          placeholder="select"
                          aria-labelledby="ColClose RowSunday"
                        >
                          <option value="select" />{' '}
                          {timeArray.map(function(currVal, index, arr) {
                            return (
                              <option
                                key={index}
                                value={currVal.value}
                                className="display:block"
                              >
                                {currVal.key}
                              </option>
                            )
                          })}
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Sunday.close
                              ? timeArray.find(
                                  x => x.value == openDays.Sunday.close
                                ).key
                              : ' '
                          }
                          aria-labelledby="ColClose RowSunday"
                        />
                      )}
                    </td>
                    <td>
                      {formContext.roleName != 'PE Admin' ? (
                        <FormControl
                          as="select"
                          value={openDays.Sunday.allDay}
                          onChange={daysTable.bind(this, 'allDay', 'Sunday')}
                          placeholder="select"
                          aria-labelledby="ColAllDay RowSunday"
                        >
                          <option value="select" />
                          <option value="Y">Open (24hrs)</option>
                          <option value="N">Closed</option>
                        </FormControl>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value={
                            openDays.Sunday.allDay
                              ? openDays.Sunday.allDay == 'Y'
                                ? 'Open (24hrs)'
                                : 'Closed'
                              : ' '
                          }
                          aria-labelledby="ColAllDay RowSunday"
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {footerDisplay}
            </Form>
          </div>
        </div>
      )
    }
  }
  return <ScreenPreLoader minHeight={minHeight} />
}
