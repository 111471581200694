import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { Modal, Button } from 'react-bootstrap'
import { Validator } from 'jsonschema'
import ModalContainer from '../Modal/ModalContainer'
import * as _ from 'lodash'
import { ENTITLEMENTS_wv as wvEnt } from '../src/entitlements_wv'
import { Link } from 'react-router-dom'
import { ErrorModal } from '../src/components/natives/ErrorModal'

const baseUrl = process.env.PUBLIC_URL
interface BenHEligibleApplicantPresentationProps {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  formContext: any
  eligibleApplicantList: any
  eligibleApplicantSearch: any
  formComponents: any
  transformErrors: () => void
  errorListTemplate: () => void
  liveValidate: boolean
  switchOnLiveValidate: () => void
  validator: Validator
  searchEligibleApplicant: () => void
  onValidate: (formData: object, errors: object) => void
  onAppFormValidate: (formData: object, errors: object) => void
  onFormDataChange: (formData: any) => void
  clearSearch: () => void
  showAddEligibleAppView: boolean
  viewAddEligibileApp: () => void
  addEligibleAppData: any
  addEligibleApplicant: (formData: any) => void
  onAppFormDataChange: (formData: any) => void
  modalMessage: string
  hideModalMessage: () => void
  showModal: boolean
  setButton: (flag: boolean) => void
  exitAddScreen: () => void
  exitFromApplication: () => void
  showExitModal: boolean
  hideExitModalMessage: () => void
  onError: (errors: object) => void
  userAccess: {
    selectedUserRole: any
    userRoles: Array<UserRole>
  }
  hasError: boolean
  isRemoveClicked: boolean
  showRemoveModal: () => void
  confirmRemove: () => void
  cancelRemove: () => void
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function BenHPresentation(
  props: BenHEligibleApplicantPresentationProps
) {
  const {
    form,
    formContext,
    eligibleApplicantList,
    eligibleApplicantSearch,
    formComponents,
    onFormDataChange,
    liveValidate,
    transformErrors,
    errorListTemplate,
    onValidate,
    validator,
    searchEligibleApplicant,
    clearSearch,
    showAddEligibleAppView,
    viewAddEligibileApp,
    addEligibleAppData,
    addEligibleApplicant,
    onAppFormDataChange,
    hideModalMessage,
    onAppFormValidate,
    showModal,
    setButton,
    modalMessage,
    exitAddScreen,
    showExitModal,
    exitFromApplication,
    hideExitModalMessage,
    onError,
    userAccess,
    hasError,
    isRemoveClicked,
    showRemoveModal,
    confirmRemove,
    cancelRemove,
    onDeleteClick,
    errorMessage
  } = props

  const selectedUserRole = _.find(userAccess.userRoles, {
    userRoleId:
      userAccess.selectedUserRole &&
      _.toInteger(userAccess.selectedUserRole.userRoleId)
  })
  const selectedEntitlements = _.get(selectedUserRole, 'entitlements', []).map(
    element => element.entitlementName
  )

  const BH_APP_SCREENING = _.includes(
    selectedEntitlements,
    wvEnt.BH_ELIGIBLE_APPLICANT_SCREENING
  )
    ? true
    : false

  const BH_APP_ADD = _.includes(
    selectedEntitlements,
    wvEnt.BH_ELIGIBLE_APPLICANT_ADD
  )
    ? true
    : false

  const header = (
    <div className="searchListButtonDiv">
      <h2 id="searchTableTitle">Eligible Applicants</h2>
      {BH_APP_ADD && (
        <button
          className="userListButton btn pe-button-focus"
          type="button"
          onClick={viewAddEligibileApp}
        >
          Add Eligible Applicant
        </button>
      )}
    </div>
  )

  const appSearchResultData = {
    Application: {
      eligibleApplicantTable: eligibleApplicantList
    }
  }

  const eligibleApplicantFormContext = {
    ...formContext,
    formData: appSearchResultData
  }

  let modalProps = {
    showModal: false
  }
  if (showExitModal) {
    const modalExitProps = {
      showModal: showExitModal,
      message:
        'Are you sure you want to exit? Changes on this screen will not be saved.',
      hideModal: hideExitModalMessage,
      size: "'lg'",
      ques: true,
      stay: exitFromApplication,
      yesNo: true
    }
    modalProps = modalExitProps
  } else if (showModal) {
    let modalSaveProps = {
      showModal: showModal,
      message: modalMessage,
      hideModal: hideModalMessage,
      size: "'lg'",
      ques: false
    }
    if (isRemoveClicked) {
      modalSaveProps.ques = true
      modalSaveProps['stay'] = cancelRemove
      modalSaveProps.hideModal = confirmRemove
      modalSaveProps['yesNo'] = false
    }
    modalProps = modalSaveProps
  }

  const saveButton = hasError ? (
    <button
      id="EligibleApp_saveBtn"
      type="submit"
      className="userListButton2 benh-danger btn pull-right"
      aria-label="There are errors on this page"
    >
      Errors
    </button>
  ) : (
    <button
      className="userListButton2 btn pull-right"
      type="submit"
      disabled={addEligibleAppData.Application.isRemoved}
      onClick={() => {
        setButton(false)
      }}
    >
      Save
    </button>
  )

  if (showAddEligibleAppView) {
    return (
      <div id="benHEligibleApplicant" className="row ml-1 mr-0 pr-0">
        <h2 className="ml-3">Eligible Applicant</h2>
        <div className="col-12 col-lg-12 mr-0 ml-0 pl-0 pr-0">
          {form && form.app && form.app.forms.length > 0 ? (
            <>
              <ErrorModal
                errorMessage={errorMessage}
                onDeleteClick={onDeleteClick}
              />
              <Form
                schema={form.app.forms[3].schema}
                uiSchema={form.app.forms[3].uiSchema}
                formData={addEligibleAppData}
                onChange={onAppFormDataChange}
                formContext={formContext}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                FieldTemplate={formComponents.FieldTemplate}
                jsonValidator={validator}
                transformErrors={transformErrors}
                ErrorList={errorListTemplate}
                liveValidate={liveValidate}
                validate={onAppFormValidate}
                onSubmit={addEligibleApplicant}
                onError={onError}
              >
                <div className="col-lg-12 col-12">
                  {saveButton}
                  <button
                    className="userListButton1 btn pull-right"
                    type="button"
                    disabled={
                      !(
                        formContext.isEdit &&
                        addEligibleAppData.Application.remove != undefined &&
                        addEligibleAppData.Application.remove[0].removeReason !=
                          undefined
                      ) ||
                      (formContext.isEdit &&
                        addEligibleAppData.Application.isRemoved)
                    }
                    onClick={() => {
                      showRemoveModal()
                    }}
                  >
                    Remove
                  </button>
                  {BH_APP_SCREENING &&
                    (addEligibleAppData.Application.isRemoved ? (
                      <button
                        className="userListButton1 btn pull-right"
                        disabled
                      >
                        Screen
                      </button>
                    ) : (
                      <Link
                        to={{
                          pathname: baseUrl + '/benHScreen',
                          state: {
                            addEligibleAppData: addEligibleAppData
                          }
                        }}
                        className="userListButton1 btn pull-right"
                        disabled={addEligibleAppData.Application.isRemoved}
                      >
                        Screen
                      </Link>
                    ))}
                  <button
                    className="userListButton1 btn pull-right"
                    type="button"
                    onClick={exitAddScreen}
                  >
                    Exit
                  </button>
                </div>
              </Form>
            </>
          ) : null}
          <ModalContainer {...modalProps} />
        </div>
      </div>
    )
  } else {
    return (
      <div id="benHEligibleApplicant" className="row ml-1 mr-0 pr-0">
        <h1 className="ml-3">IDD (Title XIX) Waiver Eligible Applicants</h1>
        <div className="col-12 col-lg-12 mr-0 ml-0 pl-0 pr-0">
          {form && form.app && form.app.forms.length > 0 ? (
            <Form
              schema={form.app.forms[0].schema}
              uiSchema={form.app.forms[0].uiSchema}
              formData={eligibleApplicantSearch}
              onChange={onFormDataChange}
              formContext={formContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              validate={onValidate}
              ErrorList={errorListTemplate}
              transformErrors={transformErrors}
              onSubmit={searchEligibleApplicant}
              liveValidate={liveValidate}
              onError={onError}
              jsonValidator={validator}
            >
              <div className="col-lg-12 col-12">
                <button
                  className="userListButton2 btn pull-right"
                  type="submit"
                  onClick={props.switchOnLiveValidate}
                >
                  Search
                </button>
                <button
                  className="userListButton1 btn pull-right"
                  type="button"
                  onClick={clearSearch}
                >
                  Clear
                </button>
              </div>
            </Form>
          ) : null}
        </div>
        <div style={{ marginTop: '0rem', marginBottom: '2rem', width: '100%' }}>
          {form && form.app && form.app.forms.length > 0 ? (
            formContext.includeHistory == 'Y' ? (
              <div className="eligibleApplicantTable ml-3 mr-3">
                <ErrorModal
                  errorMessage={errorMessage}
                  onDeleteClick={onDeleteClick}
                />
                {header}
                <Form
                  schema={form.app.forms[2].schema}
                  uiSchema={form.app.forms[2].uiSchema}
                  formData={{ ...appSearchResultData }}
                  formContext={eligibleApplicantFormContext}
                  fields={formComponents.fields}
                  widgets={formComponents.widgets}
                  ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                  FieldTemplate={formComponents.FieldTemplate}
                >
                  <button className="sr-only" type="submit">
                    Search
                  </button>
                </Form>
              </div>
            ) : (
              <div className="eligibleApplicantTable ml-3 mr-3">
                <ErrorModal
                  errorMessage={errorMessage}
                  onDeleteClick={onDeleteClick}
                />
                {header}
                <Form
                  schema={form.app.forms[1].schema}
                  uiSchema={form.app.forms[1].uiSchema}
                  formData={{ ...appSearchResultData }}
                  formContext={eligibleApplicantFormContext}
                  fields={formComponents.fields}
                  widgets={formComponents.widgets}
                  ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                  FieldTemplate={formComponents.FieldTemplate}
                >
                  <button className="sr-only" type="submit">
                    Search
                  </button>
                </Form>
              </div>
            )
          ) : null}
        </div>
      </div>
    )
  }
}
