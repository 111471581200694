import * as React from 'react'
import { isEqual, get, find } from 'lodash'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { config } from '../../../config'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ClientPortalException, CODES, shouldThrow } from '../../base/Errors'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError } from '../src/components/utils'

const _ = { isEqual, get, find }

const loadEndpoint =
  config['gatewayBaseUrl'] + config['getBenefitApplicationsDetails']
const formsEndpoint = config['forms_MyAppDrafts']
const loadDraftEndpoint = config['getDraftApplication']
const _pagination = { recordsPerPage: 20, pageIndex: 1 }

declare var process

const baseUrl = process.env.PUBLIC_URL

interface MyAppDraftsContainerProps {
  presentation: any
  uuid: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  userRoleId: number
  removeMyApplication: (applId: string) => void
  setActiveApplicationId: (applId: string) => void
  locale: string
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  logoutUser
}

interface MyAppDraftsContainerState {
  formData: any
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formContext: any
  redirect: string
  callingApi: boolean
  applicationData: any
}

class MyAppDraftsContainer extends React.Component<
  MyAppDraftsContainerProps,
  MyAppDraftsContainerState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      formData: null,
      formSchema: null,
      formContext: null,
      redirect: null,
      callingApi: false,
      applicationData: null
    }
  }

  componentDidMount() {
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName
        }
      })
      .then(formJson => {
        this.setState({ formSchema: formJson }, this._getDraftData)
      })
      .catch(error => {
        console.error('MyAppDrafts form fetch failed due to ex', error)
        const code = CODES.MY_APP_DRAFTS_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      this.setState({ callingApi: true })
      helper
        .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        })
        .then(json => {
          this.setState({ callingApi: false })
          this.setState({ formSchema: json })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('MyAppDrafts form re-fetch failed due to ex', error)
          const code = CODES.MY_APP_DRAFTS_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _getDraftData = () => {
    serviceHelper
      .fetchJson(
        loadEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            locale: (this.props.locale + '_us').toUpperCase(),
            userRoleId: this.props.userRoleId.toString()
          },
          body: JSON.stringify({
            pagination: _pagination
          })
        },
        this.props.showErrorMessage
      )
      .then(json => {
        let draftJson = new Array()
        let redirect = null
        for (let i = 0; i < json.applicationSummaries.length; i++) {
          if (json.applicationSummaries[i].status.toUpperCase() === 'DRAFT') {
            draftJson.push(json.applicationSummaries[i])
          }
        }
        let { formSchema } = this.state
        let formContext = {
          formData: draftJson,
          edit: this._sendToApplication,
          formSchema: formSchema,
          config,
          locale: this.props.locale
        }
        if (draftJson.length == 0) {
          redirect = baseUrl + '/application'
        }

        this.setState({ formData: draftJson, formContext, redirect })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error('MyAppDrafts _getDraftData failed with error:', error)
        const code = CODES.MY_APP_DRAFTS_FETCH_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        } else {
          this.setState({ redirect: baseUrl + '/application' })
        }
      })
  }

  _sendToApplication = (appFieldId: string) => {
    if (appFieldId) {
      let applicationIndex = appFieldId.split('_')[1]
      const fetchEndpoint = loadDraftEndpoint.replace(
        '{applId}',
        this.state.formData[applicationIndex].appId
      )

      let applicationId = this.state.formData[applicationIndex].appId
      serviceHelper
        .fetchJson(
          fetchEndpoint,
          {
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              userRoleId: this.props.userRoleId
            },
            method: 'POST'
          },
          this.props.showErrorMessage
        )
        .then(json => {
          if (json) {
            const zerothProgramCode = json.Application && json.Application.programCode && json.Application.programCode[0]
            const isPrc2 = zerothProgramCode === 'FS P'
            switch (json.applicationType) {
              case 'COC':
                this.setState({
                  redirect: baseUrl + '/coc?applId=' + applicationId
                })
                break
              case 'RENEWAL':
                if (isPrc2) {
                  this.setState({
                    redirect: baseUrl + '/renewal-prc2?applId=' + applicationId
                  })
                } else {
                  this.setState({
                    redirect: baseUrl + '/renewal?applId=' + applicationId
                  })
                }
                break
              default:
                this.setState({
                  redirect: baseUrl + '/application?applId=' + applicationId
                })
            }
          } else {
            throw new Error('_sendToApplication json null')
          }
        })
        .catch(error => {
          if (error instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
        })
    } else {
      this.props.removeMyApplication('0')
      this.props.setActiveApplicationId('0')
      this.setState({ redirect: baseUrl + '/application' })
    }
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    const { formSchema, formData, formContext, redirect } = this.state
    const { presentation, locale, errorMessage } = this.props
    if (redirect) {
      return <Redirect to={redirect} />
    }
    const presentationProps = {
      formSchema,
      formData,
      formContext,
      sendToApplication: this._sendToApplication,
      locale,
      errorMessage,
      onDeleteClick: this._onDeleteClick
    }
    return presentation(presentationProps)
  }
}
function mapStateToProps(state, ownProps) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  let userRoleIdOrgIdList = _.get(state, 'userAccess.userRoleIdOrgIdList') || []
  let roleName = _.get(state.userAccess, 'selectedUserRole.role.roleName') || ''
  let ieOrgId, userRoleId
  let currentuserRoleIdOrgIdRecord = _.find(userRoleIdOrgIdList, current => {
    return (
      _.get(current, 'orgId') ==
        _.get(state.userAccess, 'selectedOrg.ieOrgId') &&
      _.get(current, 'rolename') == roleName
    )
  })
  ieOrgId = _.get(currentuserRoleIdOrgIdRecord, 'orgId', '')
  userRoleId = _.get(currentuserRoleIdOrgIdRecord, 'userRoleId', '')
  if (!userRoleId) {
    userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  }
  return {
    uuid,
    userRoleId,
    locale: state.i18n.locale,
    auth: state.auth,
    errorMessage
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    removeMyApplication: applId => {
      dispatch(actions.removeMyApplication(applId))
    },
    setActiveApplicationId: applId => {
      dispatch(actions.setActiveApplicationId(applId))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAppDraftsContainer)
