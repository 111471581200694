import * as React from 'react'
import { Modal } from 'react-bootstrap'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import * as _ from 'lodash'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { I18n } from 'react-redux-i18n'

const identityProofForm = {
  schema: {
    type: 'object',
    properties: {
      question_1: {
        type: 'string',
        title: '{QUESTION_1}'
      },
      question_2: {
        type: 'string',
        title: '{QUESTION_2}'
      },
      question_3: {
        type: 'string',
        title: '{QUESTION_3}'
      }
    }
  },
  uiSchema: {
    question_1: {
      'ui:widget': 'radio'
    },
    question_2: {
      'ui:widget': 'radio'
    },
    question_3: {
      'ui:widget': 'radio'
    }
  }
}

interface FormProps {
  schema: any
  uiSchema?: any
  formData?: any
  onChange?: Function
}

export default class SubmissionModal extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      form: { schema: {}, uiSchema: {} },
      formData: {},
      status: 'NO_RIDP',
      showModal: props.showModal,
      childCare: this.isCHCREnabled(props),
      callingApi: props.callingApi
    }
  }

  componentDidMount() {
    if (this.props.RIDPQuestions) {
      this.setState({
        status: 'INITIAL',
        form: this.mergeFormAndQuestions(
          identityProofForm,
          this.props.RIDPQuestions
        )
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let flag = this.isCHCREnabled(this.props)
    if (this.state.childCare !== flag) {
      this.setState({ childCare: flag })
    }

    if (
      this.state.status === 'NO_RIDP' &&
      _.isEmpty(prevProps.RIDPQuestions) &&
      !_.isEmpty(this.props.RIDPQuestions)
    ) {
      this.setState({
        status: 'INITIAL',
        form: this.mergeFormAndQuestions(
          identityProofForm,
          this.props.RIDPQuestions
        )
      })
    }
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {}
    if (props.showModal !== state.showModal) {
      state['showModal'] = props.showModal
    }

    if (!_.isEmpty(state)) {
      return newState
    }

    return null
  }

  closeModal = (redirectURL?: string): void => {
    if (redirectURL) {
      this.props.setRedirect(redirectURL)
    }

    this.props.closeModal()
  }

  isCHCREnabled = props => {
    const formData = props.formData
    return (
      _.get(formData, 'Application.benefitCategorieslist', []).indexOf('CHCR') >
        -1 &&
      _.some(_.get(formData, 'Application.clients', []), (element, key) => {
        return element.optBenefitCHCR
      })
    )
  }

  mergeFormAndQuestions = (form: FormProps, questions: any): FormProps => {
    let newForm: FormProps = helper.deepClone(form)

    for (let i = 0; i < questions.length; i++) {
      _.set(
        newForm.schema.properties[`question_${i + 1}`],
        'title',
        questions[i].question
      )
      _.set(
        newForm.schema.properties[`question_${i + 1}`],
        'enum',
        questions[i].options
      )
    }

    return newForm
  }

  onChange = ({ formData }: any): void => {
    this.setState({
      formData
    })
  }

  submitIdentityProof = (): void => {
    try {
      const promise = this.props.submitIdentityProof(this.state.formData)
      this.setState({ status: 'SUCCESS' })
    } catch (ex) {
      console.error('try catch submitIdentityProof ex', ex)
    }
  }

  submitDraft = (): void => {
    this.props.saveFormDataDraft(false)
  }

  setRedirect = url => {
    this.props.setRedirect(url)
  }

  render() {
    const { confirmation, callingApi, showModal, RIDPQuestions } = this.props

    const { form, formData, status } = this.state

    let redirectTo

    switch (status) {
      case 'INITIAL':
        redirectTo = null
        break
      case 'SUCCESS':
      case 'NO_RIDP':
        redirectTo = '/myaccount/applications'
        break
      case 'FAILURE':
        redirectTo = '/myaccount/documents?applId=' + this.props.applId
        break
      default:
        redirectTo = null
    }

    const Fragment = (React as any).Fragment

    return (
      <Modal
        show={showModal}
        onHide={() => this.closeModal(redirectTo)}
        keyboard={status !== 'INITIAL'}
        backdrop="static"
        size={'lg'}
      >
        <Modal.Header closeButton={status !== 'INITIAL'}>
          <Modal.Title>
            {status === 'INITIAL' && 'Please answer the questions below'}
            {status === 'SUCCESS' && 'Verification Successful'}
            {status === 'FAILURE' && 'Verification Failed'}
            {status === 'NO_RIDP' && I18n.t('Appintake.title1')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="col-12">
          <div className="col-12">
            {status === 'INITIAL' && (
              <Fragment>
                <p>
                  {I18n.t('Appintake.submissionModal.initial.instructions')}
                </p>
                <Form
                  schema={form.schema}
                  uiSchema={form.uiSchema}
                  formData={formData}
                  onChange={this.onChange}
                >
                  <button type="submit" className="hidden" />
                </Form>
              </Fragment>
            )}
            {status === 'SUCCESS' && (
              <Fragment>
                <div className="large-verification-icon text-center">
                  <i className="fa fa-check-circle green" />
                </div>
                <p>
                  {I18n.t('Appintake.submissionModal.success.instructions')}
                </p>
              </Fragment>
            )}
            {status === 'FAILURE' && (
              <Fragment>
                <div className="large-verification-icon text-center">
                  <i className="fa fa-exclamation-triangle red" />
                </div>
                <p>
                  {I18n.t('Appintake.submissionModal.failure.instructions')[0]}

                  <b>
                    {
                      I18n.t(
                        'Appintake.submissionModal.failure.instructions'
                      )[1]
                    }
                  </b>
                </p>
                <ul>
                  <li>
                    {
                      I18n.t(
                        'Appintake.submissionModal.failure.acceptableDocuments'
                      )[0]
                    }
                  </li>
                  <li>
                    {' '}
                    {
                      I18n.t(
                        'Appintake.submissionModal.failure.acceptableDocuments'
                      )[1]
                    }
                  </li>
                  <li>
                    {' '}
                    {
                      I18n.t(
                        'Appintake.submissionModal.failure.acceptableDocuments'
                      )[2]
                    }
                  </li>
                  <li>
                    {
                      I18n.t(
                        'Appintake.submissionModal.failure.acceptableDocuments'
                      )[3]
                    }
                  </li>
                  <li>
                    {
                      I18n.t(
                        'Appintake.submissionModal.failure.acceptableDocuments'
                      )[4]
                    }
                  </li>
                  <li>
                    {
                      I18n.t(
                        'Appintake.submissionModal.failure.acceptableDocuments'
                      )[5]
                    }
                  </li>
                  <li>
                    {
                      I18n.t(
                        'Appintake.submissionModal.failure.acceptableDocuments'
                      )[6]
                    }
                  </li>
                </ul>
                <p>
                  {I18n.t('Appintake.submissionModal.failure.instructions')[2]}
                </p>
              </Fragment>
            )}
            {callingApi && <h2>Please wait...</h2>}
          </div>
          {confirmation && status !== 'INITIAL' && (
            <Fragment>
              <div className="col-12">
                <p>
                  {this.state.childCare
                    ? I18n.t(
                        'Appintake.submissionModal.confirmation.childCareMessage'
                      )
                    : 'You have successfully completed an application for benefits or services. You will be notified through My Account\\MyMessages of the status for each of the applicants listed in your application.'}
                </p>
                {this.state.childCare && (
                  <div>
                    <h2>{I18n.t('Appintake.AttentionCCCApplicants')}</h2>
                    <p>{I18n.t('Appintake.AttentionCCCApplicantsDesc')}</p>
                    <Fragment>
                      <a
                        href="https://dhhr.wv.gov/bcf/ece/Documents/R%26R%20Map%20April%202023.pdf"
                        target="_blank"
                      >
                        {I18n.t('Appintake.ChildCareoffices')}
                      </a>
                    </Fragment>
                  </div>
                )}
              </div>
              <div className="col-12">
                <div className="col-lg-6">
                  <i className="glyphicon myglyphicon-folder-open" />
                  <p>
                    <b>{I18n.t('Appintake.WVDHHROffice')}</b>
                  </p>
                  <p>{I18n.t('Appintake.FindVisitDHHRoffice')}</p>
                  <a
                    href="https://dhhr.wv.gov/Pages/default.aspx"
                    target="_blank"
                    className="btn btn-link hyper-link-color"
                  >
                    {' '}
                    {I18n.t('Appintake.ContactYourLocalDHHROffice')}
                  </a>
                </div>
              </div>
              <div className="col-12">
                <div className="col-lg-6">
                  <p />
                  <i className="glyphicon myglyphicon-flag" />
                  <p>
                    <b> {I18n.t('submissionModal.label')}</b>
                  </p>
                  <p>{I18n.t('Appintake.Reviewandprintyourapplication')}</p>
                  <button
                    className="btn btn-link hyper-link-color"
                    onClick={() => this.setRedirect('/myaccount/applications')}
                  >
                    {I18n.t('submissionModal.button')}
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          {status === 'INITIAL' && (
            <Fragment>
              <button
                onClick={this.submitIdentityProof}
                className="btn btn-primary"
              >
                {I18n.t('Appintake.submissionModal.initial.button')}
              </button>
            </Fragment>
          )}
          {status === 'SUCCESS' && (
            <Fragment>
              <button
                className="btn btn-primary"
                onClick={() => this.setRedirect(redirectTo)}
              >
                {I18n.t('Appintake.submissionModal.success.button')}
              </button>
            </Fragment>
          )}
          {status === 'FAILURE' && (
            <Fragment>
              <button
                className="btn btn-primary"
                onClick={() => this.setRedirect(redirectTo)}
              >
                {I18n.t('Appintake.GotoMyDocuments')}
              </button>
            </Fragment>
          )}
        </Modal.Footer>
        <ScreenPreLoader style={{ minHeight: '500px' }} loaded={!callingApi} />
      </Modal>
    )
  }
}
