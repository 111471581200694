import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import { I18n } from 'react-redux-i18n'

declare const process
const baseUrl = process.env.PUBLIC_URL

interface SearchPEApplicationsPresentationProps {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  loadScreen: boolean
  onFormDataChange: (formData: any) => void
  searchPEApplications: () => void
  clearForm: () => void
  showUploadModal: boolean
  closeUploadModal: () => void
  onFormDataChangeModal: (formData: any) => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  locale: string
}

export default function SearchPEApplicationsPresentation(
  props: SearchPEApplicationsPresentationProps
) {
  const {
    forms,
    formData,
    formContext,
    loadScreen,
    onFormDataChange,
    searchPEApplications,
    clearForm,
    showUploadModal,
    closeUploadModal,
    onFormDataChangeModal,
    formComponents,
    locale
  } = props

  if (loadScreen) {
    return (
      <div>
        <Link
          to={baseUrl + '/peApplication'}
          style={{ width: '200px' }}
          className="margin-regular btn btn-primary"
        >
          {I18n.t('SearchPEApplication.startPEApplication')}
        </Link>
        <Link
          to={baseUrl + '/application'}
          style={{ width: '200px' }}
          className="margin-regular btn btn-default"
        >
          {I18n.t('SearchPEApplication.startFullApplication')}
        </Link>
        <Form
          schema={forms[0]['schema']}
          uiSchema={forms[0]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <input type="submit" style={{ display: 'none' }} />
        </Form>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <button
              type="button"
              id="EventHistory_searchEventsBtn"
              className="btn btn-primary float-right"
              onClick={searchPEApplications}
              style={{ margin: '10px' }}
            >
              {I18n.t('General.searchButton')}
            </button>
            <button
              type="button"
              id="EventHistory_clearSearchBtn"
              className="btn btn-default float-right"
              onClick={clearForm}
              style={{ margin: '10px' }}
            >
              {I18n.t('General.resetButton')}
            </button>
          </div>
        </div>
        <Form
          schema={forms[1]['schema']}
          uiSchema={forms[1]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <button type="submit" style={{ display: 'none' }} />
        </Form>

        <Modal show={showUploadModal} onHide={closeUploadModal}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="col-md-12">
              <Form
                schema={forms[2]['schema']}
                uiSchema={forms[2]['uiSchema']}
                formData={formData.overridePeData}
                formContext={formContext}
                onChange={onFormDataChangeModal}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                FieldTemplate={formComponents.FieldTemplate}
                locale={locale}
              >
                <button type="submit" style={{ display: 'none' }} />
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn" onClick={closeUploadModal}>
              {I18n.t('General.cancelButton')}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  } else {
    return (
      <div className="container-fluid reports-container">
        <div>
          <ScreenPreLoader loaded={loadScreen} />
        </div>
      </div>
    )
  }
}
