import * as React from 'react'
import * as _ from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
const moment = require('moment-timezone')
import { Modal, Button } from 'react-bootstrap'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'

interface MyMessagesPresentationProps {
  application: any
  formContext: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  onDeleteClick: () => void
  isLoading: boolean
  messageDetailViewId: number
  messageId: number
  startDate: string
  endDate: string
  errorMessage: Array<any>
  eligibleMessages: {
    Application: {
      myMessages: Array<{
        agSeqNumber: string
        benefitMonth: string
        caseNumber: string
        messageType: string
        msgDt: string
        msgSbj: string
        msgTo: string
        noticeCd: string
        noticeType: string
        programCode: string
        readUnreadFlag: boolean
        requestTimeStamp: string
        subProgramCode: string
      }>
    }
  }
  errorScreen: boolean
  onFormDataChange: (formData: any) => void
  goBack: () => void
  generatePDF: (json: Object) => void
  filterMessages: () => void
  clearSearch: () => void
  onChangeDates: (e: Object, date: string) => void
  minHeight: string
  formComponents
  sortedMessageArray: object
  currentDate: any
  searchMessages: () => void
}

// function checkForYear(item, currentDate) {
//   let benefitMonth = moment(item.msgDt)
//   let currentDt = _moment(config, currentDate)
//   let resultArr = currentDt.diff(benefitMonth, 'days')
//   return resultArr <= 366
// }

export default function MyMessagesPresentation(
  props: MyMessagesPresentationProps
) {
  const {
    application,
    eligibleMessages,
    sortedMessageArray,
    formContext,
    forms,
    isLoading,
    messageDetailViewId,
    messageId,
    startDate,
    endDate,
    onFormDataChange,
    goBack,
    generatePDF,
    onChangeDates,
    minHeight,
    formComponents,
    filterMessages,
    clearSearch,
    errorMessage,
    onDeleteClick,
    currentDate,
    searchMessages
  } = props
  const maxDate = _moment(config, props.currentDate).format('YYYY-MM-DD')
  const minDate = _moment(config, props.currentDate)
    .day(-1098)
    .format('YYYY-MM-DD')

  const header = isLoading ? (
    <h3>
      <div style={{ display: 'table' }}>
        <h1 style={{ display: 'table-cell' }}>
          <i className="pe-7s-mail" /> {I18n.t('MyMessages.myMessages')}
        </h1>
        <i
          className="fa fa-spinner fa-spin float-right"
          style={{ fontSize: '24px', display: 'table-cell' }}
        />
      </div>
    </h3>
  ) : (
    <h1 className="title-header">
      <i className="pe-7s-mail" /> {I18n.t('MyMessages.myMessages')}
    </h1>
  )

  const searchField = (
    <div>
      <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
      <label>{I18n.t('MyMessages.notAllAvailable')}</label>
      <p>
        {I18n.t('MyMessages.searchMsgStartdate')}
        <input
          type="date"
          id="startDate"
          value={props.startDate || ''}
          min={minDate}
          max={maxDate}
          onChange={e => onChangeDates(e.target.value, 'startDate')}
        />
        {I18n.t('MyMessages.endDate')}
        <input
          type="date"
          id="endDate"
          value={props.endDate || ''}
          min={minDate}
          max={maxDate}
          onChange={e => onChangeDates(e.target.value, 'endDate')}
        />
        <button
          type="submit"
          className="btn btn-default"
          onClick={searchMessages}
        >
          {I18n.t('MyMessages.search')}
        </button>
        <button type="submit" className="btn btn-default" onClick={clearSearch}>
          {I18n.t('MyMessages.clear')}
        </button>
      </p>
    </div>
  )
  if (messageDetailViewId) {
    const messageDetail = {
      ..._.find(eligibleMessages.Application.myMessages, element =>
        element.programType == 'CCA' || element.programType == 'CS'
          ? element.docId === messageId
          : element.requestTimeStamp === messageId
      ),
      readUnreadFlg: 'Y'
    }
    const detailFormData = {
      Application: messageDetail
    }
    const detailFormContext = {
      ...formContext,
      eligibleMessages: detailFormData
    }
    return (
      <div className="form-section">
        {header}
        {searchField}
        <Form
          schema={forms[1]['schema']}
          uiSchema={forms[1]['uiSchema']}
          formData={detailFormData}
          formContext={detailFormContext}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
        >
          <div>
            <button
              type="button"
              className="btn btn-default float-right"
              onClick={goBack}
            >
              {I18n.t('MyMessages.back')}
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-default float-left"
              onClick={() => generatePDF(detailFormData)}
            >
              {I18n.t('MyMessages.viewAttachment')}
            </button>
          </div>
        </Form>
      </div>
    )
  } else if (sortedMessageArray) {
    if (!_.isEmpty(application)) {
      return (
        <div className="form-section">
          {header}
          {searchField}
          <Form
            schema={forms[0]['schema']}
            uiSchema={forms[0]['uiSchema']}
            formData={sortedMessageArray}
            onChange={onFormDataChange}
            formContext={formContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          >
            <button type="submit" style={{ display: 'none' }} />
          </Form>
        </div>
      )
    }
  } else {
    if (!_.isEmpty(application)) {
      let newMessages = {
        Application: {
          myMessages: []
        }
      }
      let allMessages =
        eligibleMessages &&
        eligibleMessages.Application &&
        eligibleMessages.Application.myMessages

      if (allMessages && allMessages.length > 0) {
        if (_.isArray(allMessages)) {
          newMessages = {
            Application: {
              myMessages: eligibleMessages.Application.myMessages
            }
          }
        }
      }

      // Commented filter messages as per defect # 4171
      // let messagesFor12Months =
      //     eligibleMessages &&
      //     eligibleMessages.Application &&
      //     eligibleMessages.Application.myMessages,
      //     filteredMessages
      //   if (messagesFor12Months && messagesFor12Months.length > 0) {
      //   if (_.isArray(messagesFor12Months)) {
      //     filteredMessages = messagesFor12Months.filter(function(item) {
      //       let benefitMonth = moment(item.msgDt)
      //       let currentDt = _moment(config, props.currentDate)
      //       let resultArr = currentDt.diff(benefitMonth, 'days')
      //       return resultArr <= 366
      //     })
      //     newMessages = {
      //       Application: {
      //         myMessages: filteredMessages
      //       }
      //     }
      //   }
      // }
      return (
        <div id="content_my_messages" className="form-section">
          {header}
          {searchField}
          <Form
            schema={forms[0]['schema']}
            uiSchema={forms[0]['uiSchema']}
            formData={newMessages}
            onChange={onFormDataChange}
            formContext={formContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          >
            <button type="submit" style={{ display: 'none' }} />
          </Form>
        </div>
      )
    }
  }
  return (
    <div>
      <ScreenPreLoader loaded={false} minHeight={minHeight} />
    </div>
  )
}
