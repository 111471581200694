import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const SearchApplicationsContainer = MultitenantLoadable(
  () => import('./SearchApplicationsContainer'),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/SearchApplications/SearchApplicationsContainer'
    )
)

const SearchApplicationsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./SearchApplicationsPresentation'),
    () =>
      import(
        '../../' +
          tenantDirectoryName +
          '/SearchApplications/SearchApplicationsPresentation'
      )
  ),
  () => import('./formComponents'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/SearchApplications/formComponents'
    )
)

export default function SearchApplications(props) {
  return (
    <ErrorBoundary name="SearchApplications">
      <SearchApplicationsContainer
        {...props}
        presentation={presentationProps => (
          <SearchApplicationsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseSearchApplicationsContainer = MultitenantLoadable(
  () => import('./SearchApplicationsContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseSearchApplicationsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./SearchApplicationsPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseSearchApplications(props) {
  return (
    <BaseErrorBoundary name="SearchApplications">
      <BaseSearchApplicationsContainer
        {...props}
        presentation={presentationProps => (
          <BaseSearchApplicationsPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
