import * as React from 'react'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'

export const fields = {
  tableRow: Fields.TableRowField,
  condition: Fields.ConditionalField
}

export const widgets = {
  routeLink: Widgets.LinkWidget,
  span: Widgets.SpanWidget,
  ssn1: Widgets.SSNWidgetV2
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
