import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const ReportsContainer = MultitenantLoadable(
  () => import('./ReportsContainer'),
  () => import('../../' + tenantDirectoryName + '/Reports/ReportsContainer')
)

const ReportsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./ReportsPresentation'),
    () => import(config)
  ),
  () => import('./FormComponents'),
  () => import(config)
)

export default function Reports(props) {
  return (
    <ReportsContainer
      {...props}
      presentation={presentationProps => (
        <ReportsPresentation {...presentationProps} />
      )}
    />
  )
}
