import { includes } from 'lodash'
import * as queryString from 'query-string'

const assetBase = process.env.ASSET_BASE

const _ = { includes }

export function isImplemented() {
  ///TODO: In Minnesota's implementation fork, return true here.
  return false
}

export function getConfig(baseConfig: any) {
  let config: any = {
    tenant: {
      id: 2,
      code: 'mn',
      state: 'MN',
      name: 'Minnesota',
      styles: {
        theme: assetBase + '/css/blue-theme.css'
      },
      fetcher: {
        retries: 3,
        timeoutMs: 5000
      },
      externalAuth: {
        checkHasPin: true // Enabling the value as per requirement(US1839815)
      },
      timeZone: 'America/Chicago',
      useDocusign: true
    }
  }

  //Retrieve environment-level config.
  switch (baseConfig.environment) {
    case 'LOCAL':
      config = {
        ...config
        //"gatewayBaseUrl": "https://mn-ie-api-gw-tst.optum.com" //TODO
      }
      break
    case 'DEV':
      config = {
        ...config
        //"gatewayBaseUrl": "https://mn-ie-api-gw-dev.optum.com" //TODO
      }
      break
    case 'TEST':
      config = {
        ...config
        //"gatewayBaseUrl": "https://mn-ie-api-gw-tst.optum.com" //TODO
      }
      break
    case 'STAGING':
      config = {
        ...config
        //"gatewayBaseUrl": "https://mn-ie-api-gw-stg.optum.com" //TODO
      }
      break
    case 'DEMO':
      config = {
        ...config
        //"gatewayBaseUrl": "https://mn-ie-api-gw-demo.optum.com" //TODO
      }
      break
    default:
      config = {
        ...config
        //"gatewayBaseUrl": "https://mn-ie-api-gw-dev.optum.com" //TODO
      }
  }

  return config
}

export function determineEnvironment() {
  const url = window.location.href.toLowerCase()
  if (_.includes(url, 'localhost')) {
    return 'LOCAL'
  }
  if (_.includes(url, '-dev')) {
    return 'DEV'
  }
  if (_.includes(url, '-tst')) {
    return 'TEST'
  }
  if (_.includes(url, '-stg')) {
    return 'STAGING'
  }
  if (_.includes(url, '-demo')) {
    return 'DEMO'
  }
  if (_.includes(url, 'ie-testing')) {
    return 'DEV'
  }
  return null
}
