import * as React from 'react'
import CommonQuestions from './CommonQuestions'

export const HelpTabs = [
  {
    id: 'commonQuestions',
    title: 'Common Questions',
    component: CommonQuestions
  },
  {
    id: 'applicationprocess',
    title: 'Application Process'
  },
  {
    id: 'myAccount',
    title: 'My Account'
  },
  {
    id: 'lifeChanges',
    title: 'Life Changes'
  },
  {
    id: 'morehelp',
    title: 'Need More Help'
  },
  {
    id: 'peTraining',
    title: 'Training'
  }
]
