import * as React from 'react'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash'
import { config } from '../../../config'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { UnauthorizedError, getServerTime } from '../src/components/utils'

const formsEndpoint = config['formsBaseUrl'] + config['forms_Reports']
const screeningReportsEndPoint =
  config['gatewayWvUrl'] + config['screenigReportSearch']
const applicationReportsEndPoint =
  config['gatewayWvUrl'] + config['applicationReportSearch']
const baseUrl = process.env.PUBLIC_URL

interface ReportsContainerProps {
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  userRoleId: number
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  roleId: any
  orgId: any
  logoutUser
  history: {
    push: (url: string) => void
  }
}

interface ReportsContainerState {
  currentPage: number
  form: {
    schema: Object
    uiSchema: Object
  }
  application: any
  formContext: any
  mainContainer: boolean
  reportType: string
  searchResults: any
  showResultsTable: boolean
  showScreeningTable: boolean
  screeningSearchResults: any
  uuid: string
  role: number
  currentDate: any
}

class ReportsContainer extends React.Component<
  ReportsContainerProps,
  ReportsContainerState
> {
  constructor(props: ReportsContainerProps) {
    super(props)

    this.state = {
      currentPage: 1,
      form: null,
      application: {},
      formContext: {},
      mainContainer: true,
      reportType: null,
      searchResults: {},
      showResultsTable: false,
      showScreeningTable: false,
      screeningSearchResults: {},
      uuid: props.auth.userAccount.uuid,
      role: props.userRoleId,
      currentDate: getServerTime()
    }
  }

  componentDidMount() {
    this._initializeForm()
    document.title = 'My Reports - My Account'
  }

  _pageChangeDetected = currentPage => {
    this.setState({
      currentPage
    })
  }

  _navigateApplicationReports = e => {
    this.setState({
      mainContainer: !this.state.mainContainer,
      reportType: e.target.innerHTML
    })
    document.title =
      e.target.innerHTML == 'Previous'
        ? 'My Reports - My Account'
        : e.target.innerHTML + ' - My Reports'
  }

  _resetForm = () => {
    let { formContext } = this.state
    let formData = _.cloneDeep(formContext.formData)
    if (formData) {
      formData.applicationReportCriteria.applicationFromDate = _.noop()
      formData.applicationReportCriteria.applicationStatus = _.noop()
      formData.applicationReportCriteria.applicationToDate = _.noop()
      formData.applicationReportCriteria.applicationType = _.noop()
      formData.applicationReportCriteria.incompleteApplication = _.noop()
      formData.applicationReportCriteria.programRequested = _.noop()
      formData.applicationReportCriteria.stateOrCountyOrRegion = _.noop()
      formData.applicationReportCriteria.submitFromDate = _.noop()
      formData.applicationReportCriteria.submitToDate = _.noop()
      formData.applicationReportCriteria.geographical = _.noop()
    }
    formContext.formData = formData
    this.setState({ formContext })
  }
  _screenResetForm = () => {
    let { formContext } = this.state
    let formData = _.cloneDeep(formContext.formData)
    if (formData) {
      formData.screeningReports.screeningAppDateFrom = _.noop()
      formData.screeningReports.screeningAppDateTo = _.noop()
      formData.screeningReports.benefitsScreenedFor = _.noop()
      formData.screeningReports.county = _.noop()
    }
    formContext.formData = formData
    this.setState({ formContext })
  }

  static getDerivedStateFromProps(
    props: ReportsContainerProps,
    state: ReportsContainerState
  ) {
    return {
      formContext: {
        ...state.formContext,
        formData: props.formData || state.formContext.formData
      }
    }
  }

  _initializeForm = () => {
    let date = _moment(config, this.state.currentDate).format('MM/DD/YYYY')

    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(form => {
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            formContext: {
              ...prevState.formContext,
              forms: [{ ...form }],
              refs: {
                ...form['app']['metaData']['refs']
              },
              currentDate: date,
              pastDate: '01/01/2016'
            }
          }
        })
      })
      .then(() => {})
      .catch(error => {
        console.error('user acces form error', error)
      })
  }

  _onFormDataChange = ({ formData }) => {
    let { formContext } = this.state
    formContext.formData = formData
    this.setState({
      formContext
    })
  }

  _screeningSearch = () => {
    let params = this.state.formContext.formData
      ? { screeningReports: this.state.formContext.formData.screeningReports }
      : { screeningReports: {} }
    serviceHelper
      .fetchJson(
        screeningReportsEndPoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          body: JSON.stringify(params)
        },
        this.props.showErrorMessage
      )
      .then(data => {
        this.setState({
          showScreeningTable: true,
          searchResults: data
        })
        if (this.state.showScreeningTable) {
          document.title = 'Screening Report Results - My Reports'
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.log(error)
      })
  }

  _search = () => {
    try {
      let applicationReports = this.state.formContext.formData
        ? this.state.formContext.formData.applicationReportCriteria
        : {}
      serviceHelper
        .fetchJson(
          applicationReportsEndPoint,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              roleId: this.props.roleId,
              orgId: this.props.orgId
            },
            body: JSON.stringify({
              applicationReports
            })
          },
          this.props.showErrorMessage
        )
        .then(data => {
          this.setState({
            showResultsTable: true,
            searchResults: data
          })
          if (this.state.showResultsTable) {
            document.title = 'Application Report Results - My Reports'
          }
        })
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        this.props.logoutUser()
        this.props.history.push(baseUrl + '/home')
      }

      console.log('Application-Report Search error', e)
    }
  }

  _showBackResultsTable = () => {
    this.setState({
      showResultsTable: false,
      showScreeningTable: false
    })
    if (this.state.showScreeningTable) {
      document.title = 'Screening Reports - My Reports'
    } else if (this.state.showResultsTable) {
      document.title = 'Application Reports - My Reports'
    }
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    const { presentation, isLoading, minHeight, errorMessage } = this.props
    const {
      form,
      application,
      formContext,
      mainContainer,
      showResultsTable,
      showScreeningTable,
      searchResults,
      reportType,
      currentPage
    } = this.state

    const presentationProps = {
      form,
      formContext,
      onFormDataChange: this._onFormDataChange.bind(this),
      isLoading,
      minHeight,
      mainContainer,
      navigateApplicationReports: this._navigateApplicationReports.bind(this),
      searchResults,
      showBackResultsTable: this._showBackResultsTable.bind(this),
      reset: this._resetForm.bind(this),
      screeningReset: this._screenResetForm.bind(this),
      reportType,
      search: this._search.bind(this),
      screeningSearch: this._screeningSearch.bind(this),
      showResultsTable,
      showScreeningTable,
      pageChangeDetected: this._pageChangeDetected,
      currentPage,
      errorMessage,
      onDeleteClick: this._onDeleteClick
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''

  return {
    auth: state.auth,
    userRoleId,
    errorMessage,
    roleId,
    orgId
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer)
