import * as React from 'react'
import { Component } from 'react'
import * as _ from 'lodash'
import BenefitsView from './BenefitsView'
import { connect } from 'react-redux'

interface MyFavoritesViewState {
  expanded: boolean
}

/* Favorites View Components */
class MyFavoritesView extends Component<any, any> {
  constructor(props) {
    super(props)

    /* Initial State is not expanded */
    this.state = {
      expanded: false
    }
  }

  render() {
    // store and state for myfavorites

    const myFavorites = this.props.myFavorites

    // no favorites exist
    if (_.isEmpty(myFavorites)) {
      return (
        <div>
          <h1>My Favorites</h1>
          <h2>No Favorites Selected</h2>
        </div>
      )
      // favorites exist
    } else {
      // render outer layer with content
      return (
        <div>
          <div className="margin-regular bottom-margin-regular">
            <h1>My Favorites</h1>
            <p>
              Below are the benefits that you have selected as "My Favorites".
              To remove benefits from this list, click the "Remove Favorite"
              button below each benefit.
            </p>
            <p>
              PLEASE NOTE: These results will not be stored on the site unless
              you are a registered user. To avoid losing your results, please
              email this list to yourself or someone else for future reference.
            </p>
          </div>
          <div className="my-favorites-content bottom-margin-regular">
            <BenefitsView benefits={myFavorites} expanded={false} />
          </div>
        </div>
      )
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    myFavorites: state.myFavorites
  }
}

export default connect(mapStateToProps)(MyFavoritesView)
