import * as React from 'react'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'

export default class ContributorQuestionWidget extends React.Component<
  any,
  any
> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  _handleStringChange = value => {
    const { id, formContext, onChange } = this.props
    if (formContext.openAddContributor && value === '{add contributor}') {
      formContext.openAddContributor(id, 'string')
    } else {
      onChange(value)
    }
  }

  _handleArrayChange = value => {
    const { id, formContext, onChange } = this.props
    if (
      formContext.openAddContributor &&
      value.indexOf('{add contributor}') > -1
    ) {
      formContext.openAddContributor(id, 'array')
    } else {
      onChange(value)
    }
  }

  render() {
    const { options } = this.props
    if (!options || !options.subwidget) return null
    switch (options.subwidget) {
      case 'checkboxes':
        return (
          <Widgets.CustomCheckboxesWidget
            {...this.props}
            onChange={this._handleArrayChange}
          />
        )
      case 'radio':
        return (
          <Widgets.CustomRadioWidget
            {...this.props}
            onChange={this._handleStringChange}
          />
        )
      case 'select':
        return (
          <Widgets.CustomSelectWidget
            {...this.props}
            onChange={this._handleStringChange}
          />
        )
      default:
        return null
    }
  }
}
