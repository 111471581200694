import * as React from 'react'
import * as _ from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { ErrorModal } from '../src/components/natives/ErrorModal'

interface Entitlement {
  entitlementId: number
  entitlementName: string
  entitlementDescription: string
}

interface UserRole {
  userRoleId: number
  uuid: string
  role: {
    roleId: number
    roleName: string
    multilingualDisplayName: {
      en: string
      es: string
    }
    roleDescription: string
    tenantId: number
    status: string
    startDate: string
    endDate: string
  }
  entitlements: Array<Entitlement>
}

interface Org {
  roleId: number
  roleName: string
  orgId: number
  orgName: string
  roleorgId: number
  dfltflg: string
}

interface SelectRolePresentationProps {
  userRoles: Array<UserRole>
  loaded: boolean
  shouldContinue: () => boolean
  handleSubmit: () => void
  roleTileClass: (userRole: UserRole) => string
  orgTileClass: (org: Org) => string
  roleIconClass: (userRole: UserRole) => string
  isDefaultUserRole: boolean
  handleInputChange: (event: any) => void
  handleOrgChange: (event: any) => void
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function SelectRolePresentation(
  props: SelectRolePresentationProps
) {
  const {
    userRoles,
    loaded,
    shouldContinue,
    handleSubmit,
    roleTileClass,
    orgTileClass,
    roleIconClass,
    isDefaultUserRole,
    handleInputChange,
    handleOrgChange,
    errorMessage,
    onDeleteClick
  } = props

  const userRoleChunks = _.isNil(userRoles) ? [] : _.chunk(userRoles, 3)

  const mayContinue = shouldContinue()
  return (
    <div>
      <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
      <form onSubmit={handleSubmit}>
        <div className="select-role-container">
          <p className="select-role-signin text-center font-weight-bold">
            Sign In As
          </p>
          <div className="container">
            {userRoleChunks.map((userRoles, index, arr) => (
              <div className="row" key={index}>
                {userRoles.map(userRole => {
                  if (userRole.orgList && userRole.orgList.length >= 1) {
                    return userRole.orgList.map(org => (
                      <div
                        className="col-lg-3 col-lg-push-1 classWithPad"
                        id={userRole.userRoleId + '_' + org.roleorgId}
                        key={org.roleorgId}
                        onClick={handleOrgChange}
                      >
                        <div
                          className={orgTileClass(org)}
                          id={userRole.userRoleId + '_' + org.roleorgId}
                          style={{ display: 'table' }}
                        >
                          <div
                            className="vcenter"
                            id={userRole.userRoleId + '_' + org.roleorgId}
                            style={{ display: 'table-cell' }}
                          >
                            <i
                              className={roleIconClass(userRole)}
                              id={userRole.userRoleId + '_' + org.roleorgId}
                            />
                            <br />
                            <p
                              className="select-role-label"
                              id={userRole.userRoleId + '_' + org.roleId}
                            >
                              {userRole.role.multilingualDisplayName.en}&nbsp;
                              {org.orgName}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  } else {
                    return (
                      <div
                        className="col-lg-3 col-lg-push-1 classWithPad"
                        id={userRole.userRoleId}
                        key={userRole.userRoleId}
                        onClick={handleInputChange}
                      >
                        <div
                          className={roleTileClass(userRole)}
                          id={userRole.userRoleId}
                          style={{ display: 'table' }}
                        >
                          <div
                            className="vcenter"
                            id={userRole.userRoleId}
                            style={{ display: 'table-cell' }}
                          >
                            <i
                              className={roleIconClass(userRole)}
                              id={userRole.userRoleId}
                            />
                            <br />
                            <p
                              className="select-role-label"
                              id={userRole.userRoleId}
                            >
                              {userRole.role.multilingualDisplayName.en}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            ))}
            <div className="row top-buffer">
              <label className="offset-1 col-lg-4">
                <input
                  name="isDefaultUserRole"
                  type="checkbox"
                  checked={isDefaultUserRole}
                  onChange={handleInputChange}
                />
                &nbsp;Use the selected role as my default role
              </label>
              <div className="offset-1 col-lg-4">
                <button
                  id="SelectRole_continueBtn"
                  type="button"
                  className="btn btn-info float-right"
                  disabled={!mayContinue}
                  onClick={handleSubmit}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
        <ScreenPreLoader loaded={loaded} minHeight="500px" />
      </form>
    </div>
  )
}
