import * as React from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import * as actions from '../../../actions'
import { config } from '../../../config'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import {
  UnauthorizedError,
  getServerTime,
  orderErrors
} from '../src/components/utils'
import * as serviceHelper from '../src/components/utils'
import * as validator from '../src/validation'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import * as queryString from 'query-string'

const formsEndpoint = config['formsBaseUrl'] + config['forms_benHRef']
const countiesBaseUrl = config['gatewayWvUrl'] + config['countiesStateUrl']
const saveReferral = config['gatewayWvUrl'] + config['saveReferral']
const getReferralData = config['gatewayWvUrl'] + config['getReferralData']
const searchReferralData = config['gatewayWvUrl'] + config['searchReferralData']
const getReferralIdDetail =
  config['gatewayWvUrl'] + config['getReferralIdDetail']
declare const process
const baseUrl = process.env.PUBLIC_URL

interface BenHReferralContainerProps {
  presentation: any
  location: {
    search: string
  }
  isLoading: boolean
  showErrorMessage: (message: any) => void
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  selectedEntitlements: Array<string>
  userAccess: {
    selectedOrg: {
      orgId: any
      orgName: any
      ieOrgId: any
    }
    selectedUserRole: {
      userRoleId: any
      uuid: any
      role: {
        roleId: any
        roleName: any
      }
    }
  }
  logoutUser
  history: {
    push: (url: string) => void
  }
  removeReferralId: () => void
}

interface BenHReferralContainerState {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  formContext: any
  liveValidate: boolean
  currentDate: any
  modalMessage: string
  callingApi: boolean
  redirect: any
  showReferralView: boolean
  showExitModal: boolean
  referralList: any
  showModal: boolean
  referralSearchData: any
  referralDetailData: any
}

class BenHReferralContainer extends React.Component<
  BenHReferralContainerProps,
  BenHReferralContainerState
> {
  constructor(props: BenHReferralContainerProps) {
    super(props)
    this.state = {
      form: null,
      formContext: {
        formData: {}
      },
      liveValidate: false,
      currentDate: getServerTime(),
      modalMessage: null,
      callingApi: false,
      redirect: null,
      showReferralView: false,
      showExitModal: false,
      referralList: [],
      showModal: false,
      referralSearchData: null,
      referralDetailData: null
    }
  }

  componentDidMount() {
    document.title = 'Referrals - My Account'
    this._initializeForm()
    const subPath = window.location.href.match('\\/referrals\\/([a-zA-Z0-9]+)')
    if (subPath != null) {
      this.props.removeReferralId()
      this.getReferralIdDetail$({ referralNumber: subPath[1] })
    } else {
      this.getReferralData$()
    }
  }

  getReferralData$ = () => {
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      }
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(getReferralData, request, this.props.showErrorMessage)
      .then(json => {
        const objArr = _.get(json, 'referralListResult')
        objArr.forEach(element => {
          element['referralNumber'] = +element['referralNumber']
        })
        this.setState(prevState => {
          return {
            ...prevState,
            referralList: objArr,
            liveValidate: false
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  _initializeForm = () => {
    let uuid = ''
    if (this.props.auth && this.props.auth.userAccount) {
      uuid = this.props.auth.userAccount.uuid
    }
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    this.setState({ callingApi: true })
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(form => {
        this.setState({ callingApi: false })
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            formContext: {
              ...prevState.formContext,
              forms: [{ ...form }]
            }
          }
        })
      })
      .catch(error => {
        this.setState({ callingApi: false })
        console.error('BenH _initializeForm error', error)
      })
    let formContext = {
      ...this.state.formContext,
      viewReferral: this._viewReferral,
      refs: {
        '{cnty1}': 'return formContext.cnty1',
        '{is referral completed}': ({ formContext }) => {
          if (
            formContext.referralStatus &&
            (formContext.referralStatus == 'Complete' ||
              formContext.referralStatus == 'Not Needed')
          ) {
            return true
          }
          return false
        }
      }
    }
    this.setState({ formContext })
    this._defaultStateCounties()
  }

  _defaultStateCounties = () => {
    let uuid = ''
    if (this.props.auth && this.props.auth.userAccount) {
      uuid = this.props.auth.userAccount.uuid
    }
    const request = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        uuid: uuid,
        tenantcode: config.tCode,
        authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalname: config.portalName
      }
    }
    const fetchEndpoint = countiesBaseUrl.replace('{state}', 'WV')
    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      .then(counties => {
        if (counties) {
          this.setState(prevState => {
            return {
              ...prevState,
              formContext: {
                ...prevState.formContext,
                cnty1: counties.counties,
                cnty: counties.counties
              }
            }
          })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          console.log(error)
        }
      })
  }

  _viewReferral = props => {
    document.title = 'Referral - My Account'
    const obj = props.options.conditionObj.baseObject
    this.getReferralIdDetail$(obj)
  }

  getReferralIdDetail$ = obj => {
    const referralStatus = obj.referralStatus
    const queryParameters = {
      refId: obj.referralNumber
    }
    const endpoint =
      getReferralIdDetail + '?' + queryString.stringify(queryParameters)
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      }
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(endpoint, request, this.props.showErrorMessage)
      .then(json => {
        let detailObj = _.get(json, 'referralDetailList[0]')
        const outcome = _.get(detailObj, 'eligibilityOutcome', {})
        detailObj['eligibilityOutcome'] = {
          outcome: outcome
        }
        // below will address when referral clicked from Eligible Applicant
        const referralStatus1 =
          referralStatus == undefined
            ? detailObj.referral.referralStatus
            : referralStatus
        this.setState({
          liveValidate: false,
          formContext: {
            ...this.state.formContext,
            isEdit: true,
            referralStatus: referralStatus1
          },
          showReferralView: true,
          referralDetailData: { Application: detailObj }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  _switchOnLiveValidate = () => {
    this.setState({
      liveValidate: true
    })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({
      referralSearchData: formData,
      referralDetailData: formData,
      formContext: {
        ...this.state.formContext
      }
    })
  }

  _onFormDataChangeEdit = ({ formData }) => {
    this.setState({
      referralDetailData: formData,
      formContext: {
        ...this.state.formContext
      }
    })
  }

  _onValidate(formData: any, errors: object) {
    validator.validateReferralForm(formData, errors)
    return errors
  }

  _transformErrors = (errors: object) => {
    return errors
  }

  _errorListTemplate = (props: any) => {
    const { errors, schema, uiSchema } = props

    const masterSchema = helper.resolveSchemaDefinitions(
      schema,
      schema.definitions
    )

    const orderedErrors = orderErrors(errors, masterSchema, uiSchema)
    return (
      <div
        className="panel panel-danger errors"
        id="benhreferral-errors"
        tabIndex={-1}
        style={{ marginBottom: '20px' }}
      >
        <div className="panel-heading">
          <h2 className="panel-title">Errors</h2>
        </div>
        <ul className="list-group">
          {orderedErrors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this._onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }
  _onErrorClick = error => {
    try {
      setTimeout(function() {
        window.requestAnimationFrame(function() {
          const element = document.getElementById(`root_${error.id}`)
          let offset = helper.getOffsetTop(
            document.getElementById(`root_${error.id}`)
          )
          element.focus()
          window.scroll(0, offset - 100)
        })
      }, 0)
    } catch (e) {
      console.error('Benefit Finder viewId setTimeout failed with ex', e)
    }
  }

  _onError = (errors: object) => {
    const { liveValidate, formContext } = this.state

    let foundErrors = helper.findErrors(errors)
    if (foundErrors && foundErrors.length > 0) {
      this.setState({
        formContext: {
          ...formContext,
          hasError: true
        }
      })
    } else {
      this.setState({
        formContext: {
          ...formContext,
          hasError: false
        }
      })
    }
    //Enable live validation upon error to allow for realtime evaluation of errors in response to formData changes.
    if (!liveValidate) {
      this.setState({
        liveValidate: true,
        formContext: {
          ...formContext
        }
      })
      window.location.href = '#'
    }
    const errorContent = document.getElementById(
      'benhreferral-errors'
    ) as HTMLElement
    if (errorContent) {
      window.scroll(0, errorContent.offsetTop)
      errorContent.focus()
    }
  }

  _cancelExit = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showExitModal: !prevState.showExitModal,
        formContext: {
          ...this.state.formContext,
          isEdit: false,
          isEditable: false
        }
      }
    })
  }

  _hideExitModalMessage = () => {
    this.setState({
      showExitModal: false,
      showReferralView: false,
      liveValidate: false
    })
    const subPath = window.location.href.match('\\/referrals\\/([a-zA-Z0-9]+)')
    if (subPath != null) {
      this.props.history.push(baseUrl + '/myaccount/referrals')
    }
    document.title = 'Referrals - My Account'
    this.getReferralData$()
  }

  _hideModalMessage = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showModal: !prevState.showModal,
        showReferralView: false,
        liveValidate: true
      }
    })
    const subPath = window.location.href.match('\\/referrals\\/([a-zA-Z0-9]+)')
    if (subPath != null) {
      this.props.history.push(baseUrl + '/myaccount/referrals')
    }
    this.getReferralData$()
  }

  _searchReferral = () => {
    const { referralSearchData } = this.state
    if (referralSearchData == null) {
      this.getReferralData$()
    } else {
      this.searchReferralData$(referralSearchData)
    }
  }

  searchReferralData$ = formData => {
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      },
      body: JSON.stringify(formData.referralSearch)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(searchReferralData, request, this.props.showErrorMessage)
      .then(json => {
        const objArr = _.get(json, 'referralListResult')
        objArr.forEach(element => {
          element['referralNumber'] = +element['referralNumber']
        })
        this.setState(prevState => {
          return {
            ...prevState,
            referralList: objArr,
            liveValidate: false
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  _clearSearch = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        referralSearchData: null,
        liveValidate: !prevState.liveValidate
      }
    })
  }

  _saveReferral = formData => {
    let obj = {
      referralNumber: formData.formData.Application.referral.referralNumber,
      eligibilityOutcome: formData.formData.Application.eligibilityOutcome,
      newReferralComment: formData.formData.Application.newReferralComment
    }

    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      },
      body: JSON.stringify(obj)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(saveReferral, request, this.props.showErrorMessage)
      .then(json => {
        this.setState({
          callingApi: false,
          showModal: true,
          modalMessage: 'Save Successful'
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  _exitScreen = () => {
    const liveValidate = this.state.liveValidate
    this.setState({
      showExitModal: true,
      liveValidate: liveValidate ? true : false
    })
  }

  render() {
    const { presentation } = this.props
    const {
      form,
      formContext,
      showReferralView,
      showExitModal,
      referralList,
      referralSearchData,
      liveValidate,
      showModal,
      modalMessage,
      referralDetailData
    } = this.state

    let validator
    if (form) {
      validator = new CustomValidator(
        form.app.forms[2].schema,
        form.app.forms[2].uiSchema,
        formContext
      )
    }
    const presentationProps = {
      form,
      formContext,
      showReferralView: showReferralView,
      onFormDataChange: this._onFormDataChange,
      onFormDataChangeEdit: this._onFormDataChangeEdit,
      onValidate: this._onValidate,
      errorListTemplate: this._errorListTemplate,
      transformErrors: this._transformErrors,
      onError: this._onError,
      cancelExit: this._cancelExit,
      showExitModal: showExitModal,
      hideExitModalMessage: this._hideExitModalMessage,
      saveReferral: this._saveReferral,
      switchOnLiveValidate: this._switchOnLiveValidate,
      liveValidate: liveValidate,
      validator,
      referralSearchResultData: referralList,
      referralDetailData,
      referralSearchData: referralSearchData,
      searchReferral: this._searchReferral,
      exitScreen: this._exitScreen,
      hideModalMessage: this._hideModalMessage,
      showModal: showModal,
      modalMessage: modalMessage,
      clearSearch: this._clearSearch
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    userAccess: state.userAccess
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    removeReferralId: () => {
      dispatch(actions.removeReferralId())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenHReferralContainer)
