import * as React from 'react'
import { Link } from 'react-router-dom'
import { imageMap } from '../../../images'
import { Carousel } from 'react-bootstrap'
import { isEmpty, isObject, forEach } from 'lodash'
import PlayPauseCarousel from '@optum-wvie/dynamic-ui-framework/src/components/natives/PlayPauseCarousel'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

const _ = { isEmpty, isObject, forEach }

declare const process

const baseUrl = process.env.PUBLIC_URL

const Fragment = (React as any).Fragment

interface HomePresentationProps {
  isLoggedIn: boolean
  locale: string
  carouselFocus: boolean
  onCarouselFocus: () => void
  onCarouselBlur: () => void
}

export default function HomePresentation(props: HomePresentationProps) {
  const {
    isLoggedIn,
    locale,
    carouselFocus,
    onCarouselFocus,
    onCarouselBlur
  } = props
  return (
    <div>
      <Helmet>
        <title>{I18n.t('Home.title')}</title>
      </Helmet>
      <div>
        <h1 className="hidden">{I18n.t('Home.title')}</h1>
      </div>
      <div id="content" tabIndex={-1}>
        <HomeCarousel
          locale={locale}
          carouselFocus={carouselFocus}
          onCarouselFocus={onCarouselFocus}
          onCarouselBlur={onCarouselBlur}
        />
        <MainNavigation isLoggedIn={isLoggedIn} locale={locale} />
      </div>
    </div>
  )
}

function CarouselButtons(props) {
  return (
    <div className="carousel-caption-benefits">
      <hr />
      <h3 className="homelead">{I18n.t('Home.interestedBenefitsLabel')}</h3>
      <ul className="list-inline">
        <li className="listClass">
          <i
            style={{ color: '#85c0e8' }}
            className="glyphicon  myglyphicon-heart"
          />
          <Link
            to={{
              pathname: baseUrl + '/benefitscatalog',
              state: { tabKey: 'health-wellness' }
            }}
            onFocus={() => props.onCarouselFocus()}
            onBlur={() => props.onCarouselBlur()}
          >
            {I18n.t('Home.interestedBenefits.0')}
          </Link>
        </li>
        <li className="listClass">
          <i
            style={{ color: '#85c0e8' }}
            className="glyphicon  myglyphicon-parents"
          />
          <Link
            to={{
              pathname: baseUrl + '/benefitscatalog',
              state: { tabKey: 'family-assistance' }
            }}
            onFocus={() => props.onCarouselFocus()}
            onBlur={() => props.onCarouselBlur()}
          >
            {I18n.t('Home.interestedBenefits.1')}
          </Link>
        </li>
        <li className="listClass">
          <i
            style={{ color: '#85c0e8' }}
            className="glyphicon  myglyphicon-apple"
          />
          <Link
            to={{
              pathname: baseUrl + '/benefitscatalog',
              state: { tabKey: 'food-nutrition' }
            }}
            onFocus={() => props.onCarouselFocus()}
            onBlur={() => props.onCarouselBlur()}
          >
            {I18n.t('Home.interestedBenefits.2')}
          </Link>
        </li>
      </ul>
    </div>
  )
}

function HomeCarousel(props) {
  const { locale } = props

  const currentItemSRElement = (
    <span className="sr-only">{I18n.t('General.carouselCurrentItem')}</span>
  )

  return (
    <PlayPauseCarousel
      className="home-carousel"
      carouselFocus={props.carouselFocus}
      interval={15000}
      locale={locale}
      numItems={4}
      renderItems={activeIndex => [
        <Carousel.Item>
          {activeIndex === 0 && currentItemSRElement}
          <img
            className="carousel-bg-img"
            src={imageMap[config.tenant.code].CP_Benefits_1}
            alt=""
          />
          <Carousel.Caption>
            <div
              style={{ height: '100%', width: '100%' }}
              className="carousel-caption caption-inner"
            >
              <h2>{I18n.t('General.benefitCategories.HEWL.name')}</h2>
              <p>{I18n.t('General.benefitCategories.HEWL.description')}</p>
              <CarouselButtons {...props} />
            </div>
          </Carousel.Caption>
        </Carousel.Item>,
        <Carousel.Item>
          {activeIndex === 1 && currentItemSRElement}
          <img
            className="carousel-bg-img"
            src={imageMap[config.tenant.code].CP_Benefits_2}
            alt=""
          />
          <Carousel.Caption>
            <div
              style={{ height: '100%', width: '100%' }}
              className="carousel-caption caption-inner"
            >
              <h2>{I18n.t('General.benefitCategories.FMAS.name')}</h2>
              <p>{I18n.t('General.benefitCategories.FMAS.description')}</p>
              <CarouselButtons {...props} />
            </div>
          </Carousel.Caption>
        </Carousel.Item>,
        <Carousel.Item>
          {activeIndex === 2 && currentItemSRElement}
          <img
            className="carousel-bg-img xs-height-500"
            src={imageMap[config.tenant.code].CP_Benefits_3}
            alt=""
          />
          <Carousel.Caption>
            <div
              style={{ height: '100%', width: '100%' }}
              className="carousel-caption caption-inner"
            >
              <h2>{I18n.t('General.benefitCategories.FDNT.name')}</h2>
              <p>{I18n.t('General.benefitCategories.FDNT.description')}</p>
              <CarouselButtons {...props} />
            </div>
          </Carousel.Caption>
        </Carousel.Item>,
        <Carousel.Item>
          {activeIndex === 3 && currentItemSRElement}
          <img
            className="carousel-bg-img"
            src={imageMap[config.tenant.code].CP_Benefits_4}
          />
          <Carousel.Caption>
            <div
              style={{ height: '100%', width: '100%' }}
              className="carousel-caption caption-inner"
            >
              <h2>{I18n.t('General.benefitCategories.LIEN.name')}</h2>
              <p>{I18n.t('General.benefitCategories.LIEN.description')}</p>
              <br />
              <Link
                to={baseUrl + '/myaccount/lifeevents'}
                className="btn btn-lg btn-primary"
                onFocus={() => props.onCarouselFocus()}
                onBlur={() => props.onCarouselBlur()}
              >
                {I18n.t('Home.learnMore')}
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ]}
    />
  )
}

function MainNavItem(props) {
  const {
    title,
    bullets,
    button,
    liClass,
    hrClass,
    glyphiconClass,
    url
  } = props

  return (
    <div className={liClass} style={{}}>
      <hr className={hrClass} />
      <div id="side-nav" className="application-grid-margin">
        <div>
          <span className={glyphiconClass} />
        </div>
        <div className="application-grid-column">
          <h2 className="home">{title}</h2>
          <ul className=" ordered-style">
            {bullets && bullets.map(bullet => <li>{bullet}</li>)}
          </ul>
        </div>
        <div>
          <Link
            to={baseUrl + url}
            className="btn  btn-lg btn-primary"
            onFocus={() => props.onCarouselFocus()}
            onBlur={() => props.onCarouselBlur()}
          >
            {button}
          </Link>
        </div>
      </div>
    </div>
  )
}

function MainNavigation(props) {
  const { isLoggedIn, locale } = props
  const navProps = {
    account: {
      title: I18n.t(
        `Home.nav.${isLoggedIn ? 'myAccount' : 'createAccount'}.title`
      ),
      bullets: [
        I18n.t(
          `Home.nav.${isLoggedIn ? 'myAccount' : 'createAccount'}.bullets.0`
        ),
        I18n.t(
          `Home.nav.${isLoggedIn ? 'myAccount' : 'createAccount'}.bullets.1`
        )
      ],
      button: I18n.t(
        `Home.nav.${isLoggedIn ? 'myAccount' : 'createAccount'}.button`
      ),
      liClass: 'account col-md-3 col-sm-3 col-xs-12',
      hrClass: 'accountbar',
      glyphiconClass: 'glyphicon myglyphicon-user glyphiconuser',
      url: isLoggedIn ? '/myaccount' : '/login'
    },
    benefitsFinder: {
      title: I18n.t('Home.nav.benefitsFinder.title'),
      bullets: [
        I18n.t('Home.nav.benefitsFinder.bullets.0'),
        I18n.t('Home.nav.benefitsFinder.bullets.1')
      ],
      button: I18n.t('Home.nav.benefitsFinder.button'),
      liClass: 'finder col-md-3 col-sm-3 col-xs-12',
      hrClass: 'finderbar',
      glyphiconClass: 'myglyphicon-halflings myglyphicon-compass glyphiconuser',
      url: '/benefitsfinder'
    },
    benefitsCatalog: {
      title: I18n.t('Home.nav.benefitsCatalog.title'),
      bullets: [
        I18n.t('Home.nav.benefitsCatalog.bullets.0'),
        I18n.t('Home.nav.benefitsCatalog.bullets.1')
      ],
      button: I18n.t('Home.nav.benefitsCatalog.button'),
      liClass: 'catalog col-md-3 col-sm-3 col-xs-12',
      hrClass: 'catalogbar',
      glyphiconClass:
        'myglyphicon-halflings myglyphicon-book_open glyphiconuser',
      url: '/benefitscatalog'
    },
    applyNow: {
      title: I18n.t('Home.nav.applyNow.title'),
      bullets: [I18n.t('Home.nav.applyNow.bullets.0')],
      button: I18n.t('Home.nav.applyNow.button'),
      liClass: 'apply col-md-3 col-sm-3 col-xs-12',
      hrClass: 'applybar',
      glyphiconClass: 'glyphicon myglyphicon-check glyphiconuser',
      url: isLoggedIn ? '/appdrafts' : '/login'
    }
  }

  return (
    <div className="container-fluid fluid-bg ng-scope">
      <div
        className="row text-center features application-features-grid "
        style={{}}
      >
        <MainNavItem {...navProps.account} />
        <MainNavItem {...navProps.benefitsFinder} />
        <MainNavItem {...navProps.benefitsCatalog} />
        <MainNavItem {...navProps.applyNow} />
      </div>
    </div>
  )
}
