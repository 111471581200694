import * as React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { isEqual } from 'lodash'
import * as actions from '~/actions'
import { get } from 'lodash'

export class BenefitsCatalogContainer extends React.Component<
  BenefitsCatalogContainerProps,
  BenefitsCatalogContainerState
> {
  mainContent: React.RefObject<{}>
  constructor(props: BenefitsCatalogContainerProps) {
    super(props)
    this.mainContent = React.createRef()
    this.state = {
      tabKey: get(window, 'history.state.state.tabKey', 'health-wellness')
    }
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect = (tabKey: string): void => {
    this.setState({ tabKey })
  }

  componentDidMount() {
    let sideNavLink = this._createSkipLink(
      '#side-nav',
      'skip-to-side-nav',
      I18n.t('General.skipToSideNav')
    )
    const navContent = document.getElementById('nav-content')
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      navContent.insertBefore(sideNavLink, mainNavContent)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    if (!isEqual(prevProps.locale, this.props.locale)) {
      if (navContent) {
        if (sideNavLink) {
          sideNavLink.textContent = I18n.t('General.skipToSideNav')
        }
      }
    }
    if (navContent) {
      let mainNavContent = navContent.lastElementChild
      if (!sideNavLink) {
        let sideNavLink = this._createSkipLink(
          '#side-nav',
          'skip-to-side-nav',
          I18n.t('General.skipToSideNav')
        )
        navContent.insertBefore(sideNavLink, mainNavContent)
      }
    }
  }

  componentWillUnmount() {
    const navContent = document.getElementById('nav-content')
    let sideNavLink = document.getElementById('skip-to-side-nav')
    if (navContent) {
      if (sideNavLink) {
        navContent.removeChild(sideNavLink)
      }
    }
  }

  _createSkipLink = (href, id, text = '') => {
    let skipLink = document.createElement('a')
    skipLink.setAttribute('class', 'skip')
    skipLink.setAttribute('href', href)
    skipLink.setAttribute('id', id)
    if (text) {
      let skipLinkText = document.createTextNode(text)
      skipLink.appendChild(skipLinkText)
    }
    return skipLink
  }

  render() {
    const {
      presentation,
      myFavorites,
      addMyFavorite,
      removeMyFavorite,
      locale
    } = this.props
    const { tabKey } = this.state
    const presentationProps = {
      myFavorites,
      addMyFavorite,
      removeMyFavorite,
      handleSelect: this.handleSelect,
      tabKey,
      locale,
      mainContent: this.mainContent
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  return {
    myFavorites: state.myFavorites,
    locale: state.i18n.locale
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addMyFavorite: name => {
      dispatch(actions.addMyFavorite(name))
    },
    removeMyFavorite: name => {
      dispatch(actions.removeMyFavorite(name))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitsCatalogContainer)
