import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { isEmpty } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

const _ = { isEmpty }

declare const process
const baseUrl = process.env.PUBLIC_URL

export default function FeedbackPresentation(props) {
  const {
    application,
    forms,
    formData,
    formContext,
    onFormDataChange,
    onSubmit,
    formComponents,
    locale,
    isFormDataEmpty
  } = props

  let formsOutput = []
  let applicationTitle
  let previousButton: any
  let submitButton
  let loaded = false

  if (!_.isEmpty(application)) {
    loaded = true
    submitButton = (
      <button type="submit" className="btn btn-default float-right">
        {I18n.t('General.sendButton')}
      </button>
    )
    return (
      <div id="content" className="container-fluid" tabIndex={-1}>
        <Helmet>
          <title>{I18n.t('FeedBack.title')}</title>
        </Helmet>
        <div className="row">
          <div className="headerpadding">
            <h1 className="benefit-header1">
              <span className="glyphicon myglyphicon-send benefitfinderglyphlogo" />
              {I18n.t('FeedBack.title')}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="form-section">
            <span className="sr-only">Please fill out at least one field</span>
            <Form
              schema={forms[0]['schema']}
              uiSchema={forms[0]['uiSchema']}
              onSubmit={onSubmit}
              formData={formData}
              onChange={onFormDataChange}
              formContext={formContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              locale={locale}
            >
              <div>{!isFormDataEmpty() ? submitButton : null}</div>
            </Form>
          </div>
        </div>
        <ScreenPreLoader loaded={loaded} />
      </div>
    )
  }

  return (
    <div>
      <ScreenPreLoader loaded={loaded} />
    </div>
  )
}
