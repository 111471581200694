import { findIndex } from 'lodash' // extra functionality
import { combineReducers } from 'redux'

const _ = { findIndex }

export default combineReducers({
  activeApplicationId,
  applicationsArray,
  saveMAID
})

//Every application has an initial applId of "0", of which there may only be one, until the draft is saved for the first time.

function activeApplicationId(state = '0', action) {
  switch (action.type) {
    case 'SET_ACTIVE_PE_APPLICATION_ID':
      return action.applId
    case 'LOGOUT_SUCCESS':
      return '0'
    default:
      return state
  }
}

function applicationsArray(state = [], action) {
  switch (action.type) {
    case 'ADD_PE_APPLICATION':
      const addIndex = _.findIndex(state, element => {
        return element.applId === action.applId
      })
      if (addIndex === -1) {
        return [...state, application(null, action)]
      } else {
        return state
      }
    case 'UPDATE_PE_APPLICATION':
    case 'UPDATE_PE_APPLICATION_STEP':
    case 'UPDATE_PE_APPLICATION_TAB_STATUS':
      const updateIndex = _.findIndex(state, element => {
        return element.applId === action.applId
      })
      if (updateIndex > -1) {
        return [
          ...state.slice(0, updateIndex),
          application(state[updateIndex], action),
          ...state.slice(updateIndex + 1)
        ]
      } else {
        return state
      }
    case 'ASSIGN_PE_APPLICATION_ID':
      const assignIndex = _.findIndex(state, element => {
        return element.applId === '0'
      })
      if (assignIndex > -1) {
        return [
          ...state.slice(0, assignIndex),
          application(state[assignIndex], action),
          ...state.slice(assignIndex + 1)
        ]
      } else {
        return state
      }
    case 'REMOVE_PE_APPLICATION':
      return state.filter(element => {
        return element.applId !== action.applId
      })
    case 'LOGOUT_SUCCESS':
      return []
    default:
      return state
  }
}

function application(state = { tabStatus: [], step: 1 }, action) {
  switch (action.type) {
    case 'ADD_PE_APPLICATION':
      return {
        ...state,
        applId: action.applId || '0',
        status: action.status,
        applicationData: action.applicationData || {},
        step: action.step || 1,
        tabStatus: action.tabStatus || []
      }
    case 'UPDATE_PE_APPLICATION':
      return {
        ...state,
        status: action.status,
        applicationData: action.applicationData || {}
      }
    case 'ASSIGN_PE_APPLICATION_ID':
      return {
        ...state,
        applId: action.applId
      }
    case 'UPDATE_PE_APPLICATION_STEP':
      return {
        ...state,
        step: action.step
      }
    /*
		case "UPDATE_PE_APPLICATION_TAB_STATUS":
			if (_.isArray(action.tabStatus)) {
				return {
					...state,
					tabStatus: action.tabStatus
				};
			} else if (_.isObject(action.tabStatus)){
				const tabIndex = _.findIndex(state.tabStatus, (element) => {
					return element.name === action.tabStatus.name;
				});
				if (tabIndex === -1) {
					return state;
				} else {
					return {
						...state,
						tabStatus: [
							...state.tabStatus.slice(0, tabIndex),
							{
								...state.tabStatus[tabIndex],
								...action.tabStatus
							},
							...state.tabStatus.slice(tabIndex+1)
						]
					};
				}
			} else if (_.isString(action.tabStatus) && state.step) {
				return {
					...state,
					tabStatus: [
						...state.tabStatus.slice(0, state.step-1),
						{
							...state.tabStatus[state.step-1],
							status: action.tabStatus
						},
						...state.tabStatus.slice(state.step)
					]
				};
			} else {
				return state;
			}
    */
    case 'LOGOUT_SUCCESS':
      return { tabStatus: [], step: 1 }
    default:
      return state
  }
}

function saveMAID(state = {}, action) {
  switch (action.type) {
    case 'SET_MAID_NUMBER':
      return action.maid
    case 'LOGOUT_SUCCESS':
      return {}
    default:
      return state
  }
}
