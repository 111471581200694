import * as React from 'react'
import { Link } from 'react-router-dom'
import '!style-loader!css-loader!../src/customStyles.css'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'

declare var process
const baseUrl = process.env.PUBLIC_URL

interface FooterProps {}

export default function Footer(props: FooterProps) {
  return (
    <footer>
      <div className="container">
        <div className="row myRow">
          <div className="col-md-2 footer-link">
            <h2>{I18n.t('fotter.title1')}</h2>
            <ul className="footerListNew">
              <li>
                <a href="/siteMap" target="_top">
                  {I18n.t('fotter.title2')}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 footer-link">
            <h2>{I18n.t('fotter.title3')}</h2>
            <ul className="footerListNew">
              <li>
                <a
                  href="https://www.wv.gov/policies/Pages/Privacy.aspx"
                  target="_blank"
                >
                  {I18n.t('fotter.title4')}
                </a>
              </li>
              <li>
                <a href="/assets/pdf-files/Terms_of_Use.pdf" target="_blank">
                  {I18n.t('fotter.title5')}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 footer-link">
            <h2>{I18n.t('fotter.title6')}</h2>
            <ul className="footerListNew">
              <li>
                <a
                  href="https://dhhr.wv.gov/Pages/default.aspx"
                  target="_blank"
                >
                  {I18n.t('fotter.title7')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.wv.gov/agencies/Pages/default.aspx"
                  target="_blank"
                >
                  {I18n.t('fotter.title8')}
                </a>
              </li>
              <li>
                <a
                  href="https://dhhr.wv.gov/bcf/ece/Documents/R%26R%20Map%20April%202023.pdf"
                  target="_blank"
                >
                  {I18n.t('fotter.title9')}
                </a>
              </li>
              <li>
                <Link to={baseUrl + '/communityPartnerSearch'}>
                  {I18n.t('fotter.title10')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 footer-link">
            <h2>{I18n.t('fotter.title11')}</h2>
            <ul className="footerListNew">
              <li>
                <a href="/faq" target="_blank">
                  {I18n.t('fotter.title12')}
                </a>
              </li>
              <li>
                <a href="tel:+18444513515">{I18n.t('fotter.title13')}</a>
              </li>
              <li>
                <Link to={baseUrl + '/IncidentForm'}>
                  {I18n.t('fotter.title14')}
                </Link>
              </li>
              <li>
                <a href="mailto:wvtcc@optum.com?subject=WV IES Support Email">
                  {I18n.t('fotter.title15')}
                </a>
              </li>
              <li>
                <a
                  href="/assets/pdf-files/LimtedEnglishProficiency_LEP.pdf"
                  target="_blank"
                >
                  {I18n.t('fotter.title16')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="layoutFooterDiv">
        <span className="layoutFooterText">
          &copy; {I18n.t('fotter.title17')}
        </span>
      </div>
    </footer>
  )
}
