import * as React from 'react'
import { config } from '../../../config'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import { connect } from 'react-redux'
import * as actions from '../../../actions'
import * as _ from 'lodash'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import * as serviceHelper from '../src/components/utils'
import * as queryString from 'query-string'
import * as validator from '../src/validation'
import ModalContainer from '../Modal/ModalContainer'

const formsEndpoint = config['formsBaseUrl'] + config['forms_banner']
declare const process
const baseUrl = process.env.PUBLIC_URL
const host = config['gatewayWvUrl']
const saveBannerMessage = host + config['saveBannerMessage']
const getAllBannerMessages = host + config['getAllBannerMessages']
const getBannerMessageDetail = host + config['getBannerMessageDetail']

export const widgets = {
  header: Widgets.HeaderWidget,
  'alt-date': Widgets.DatePickerWidget,
  select: Widgets.CustomSelectWidget,
  validatedText: Widgets.ValidatedTextWidget,
  span: Widgets.SpanWidget,
  multiSelect: Widgets.MultiSelectDropdownWidgetV2,
  ssn: Widgets.SSNWidget,
  routeLink: Widgets.LinkWidget,
  phone: Widgets.PhoneWidget,
  textarea: Widgets.CustomTextAreaWidget,
  'alt-time': Widgets.TimePickerWidgetV2
}
export const fields = {
  tableRow: Fields.TableRowField,
  mcr: Fields.CodeReferenceField,
  addressSuggestion: Fields.AddressSuggestionField,
  publicTrackerForm: Fields.PublicTrackerForm,
  panelGroup: Fields.CollapsiblePanelGroupField,
  accordion: Fields.AccordionField,
  condition: Fields.ConditionalField
}

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate

export const FieldTemplate = Templates.CustomFieldTemplate

class BannerMessages extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      form: null,
      formContext: null,
      formData: null,
      liveValidate: false,
      messageList: []
    }
  }

  componentDidMount() {
    document.title = 'Banner Messages - My Account'
    this._initializeForm()
    this.getAllBannerMessages$()
  }

  _viewBannerMessage = props => {
    this.getMessageIdDetail$(props.options.conditionObj.baseObject)
  }

  getMessageIdDetail$ = obj => {
    const queryParameters = {
      bannerMessageId: obj.bannerNumber
    }
    const endpoint = `${getBannerMessageDetail}?${queryString.stringify(
      queryParameters
    )} `
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      }
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(endpoint, request, this.props.showErrorMessage)
      .then(json => {
        const detailObj = _.get(json, 'bannerMessageDetailList')
        this.convertToHtml(detailObj)
        let offset = helper.getOffsetTop(
          document.getElementById('bannerMessage')
        )
        window.scroll(0, offset - 100)
        this.setState({
          liveValidate: false,
          formContext: {
            ...this.state.formContext,
            isEdit: true,
            bannerMessageId: detailObj[0].bannerMessageId
          },
          formData: { Banner: detailObj[0] }
        })
      })
      .catch(error => {
        if (error instanceof serviceHelper.UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  getAllBannerMessages$ = () => {
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      }
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(getAllBannerMessages, request, this.props.showErrorMessage)
      .then(json => {
        let objArr = _.get(json, 'bannerMessageSearchResultList')
        this.convertToHtml(objArr)
        this.setState(prevState => {
          return {
            ...prevState,
            formContext: {
              ...this.state.formContext,
              isEdit: false
            },
            messageList: objArr,
            liveValidate: false
          }
        })
      })
      .catch(error => {
        if (error instanceof serviceHelper.UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_getAllBannerMessages failed with error:', error)
      })
  }

  convertToHtml = (objArr: any) => {
    objArr.forEach(element => {
      let mes = element.message.split('&lt;').join('<')
      let header =
        element.modalHeader != null
          ? element.modalHeader.split('&lt;').join('<')
          : ''
      let body =
        element.modalBody != null
          ? element.modalBody.split('&lt;').join('<')
          : ''
      let footer =
        element.modalFooter != null
          ? element.modalFooter.split('&lt;').join('<')
          : ''
      element.message = mes
      element.modalHeader = header
      element.modalBody = body
      element.modalFooter = footer
    })
  }

  _initializeForm = () => {
    let uuid = ''
    if (this.props.auth && this.props.auth.userAccount) {
      uuid = this.props.auth.userAccount.uuid
    }
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    this.setState({ callingApi: true })
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(form => {
        this.setState({ callingApi: false })
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            formContext: {
              ...prevState.formContext,
              viewBannerMessage: this._viewBannerMessage,
              forms: [{ ...form }]
            }
          }
        })
      })
      .catch(error => {
        this.setState({ callingApi: false })
        console.error('BenH _initializeForm error', error)
      })
  }

  onFormDataChange = ({ formData }) => {
    this.setState({
      formData: formData,
      formContext: {
        ...this.state.formContext,
        formData: formData
      }
    })
  }

  clearScreen = () => {
    this.setState({
      formData: null,
      formContext: {
        ...this.state.formContext,
        formData: null,
        isEdit: false
      }
    })
  }

  switchOnLiveValidate = () => {
    this.setState({
      liveValidate: true
    })
  }

  transformErrors = (errors: object) => {
    return errors
  }

  errorListTemplate = (props: any) => {
    const { errors } = props
    return (
      <div
        className="panel panel-danger errors"
        id="banner-errors"
        tabIndex={-1}
      >
        <div className="panel-heading">
          <span className="panel-title">Errors</span>
        </div>
        <ul className="list-group">
          {errors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.

            let errorObject = null
            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <div key={i}>
                <li className="list-group-item text-danger" key={i}>
                  <a
                    href="#"
                    onClick={
                      errorObject ? () => this._onErrorClick(errorObject) : null
                    }
                  >
                    {errorObject
                      ? errorObject.displayMessage
                      : error.stack.substring(error.stack.indexOf(':') + 1)}
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  _onErrorClick = error => {
    try {
      setTimeout(function() {
        window.requestAnimationFrame(function() {
          const element = document.getElementById(`root_${error.id}`)
          let offset = helper.getOffsetTop(
            document.getElementById(`root_${error.id}`)
          )
          element.focus()
          window.scroll(0, offset - 100)
        })
      }, 0)
    } catch (e) {
      console.error('Benefit Finder viewId setTimeout failed with ex', e)
    }
  }

  onError = (errors: object) => {
    const errorContent = document.getElementById('banner-errors') as HTMLElement
    if (errorContent) {
      window.scroll(0, errorContent.offsetTop)
      errorContent.focus()
    }
  }

  submitMessage = formData => {
    let obj = formData.formData.Banner
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        roleId: this.props.userAccess.selectedUserRole.role.roleId,
        orgId: this.props.userAccess.selectedOrg.orgId,
        portalName: config.portalName
      },
      body: JSON.stringify(obj)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(saveBannerMessage, request, this.props.showErrorMessage)
      .then(json => {
        this.setState({
          callingApi: false,
          showModal: true,
          modalMessage: 'Save Successful',
          liveValidate: false
        })
      })
      .catch(error => {
        if (error instanceof serviceHelper.UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_submitFormData failed with error:', error)
      })
  }

  onValidate(formData: any, errors: object) {
    validator.validateMessage(formData, errors)
    return errors
  }

  hideModalMessage = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showModal: !prevState.showModal,
        isEdit: false,
        liveValidate: false,
        hasError: false,
        formData: null,
        formContext: {
          ...this.state.formContext,
          formData: null,
          isEdit: false
        }
      }
    })
    this.getAllBannerMessages$()
  }

  render() {
    const {
      form,
      formContext,
      formData,
      liveValidate,
      messageList,
      showModal,
      modalMessage
    } = this.state
    const messageListResult = {
      Banner: {
        messageTable: messageList
      }
    }

    const messageFormContext = {
      ...formContext,
      formData: messageList
    }
    let validator
    if (form) {
      validator = new CustomValidator(
        form.app.forms[0].schema,
        form.app.forms[0].uiSchema,
        formContext
      )
    }
    let modalProps = {
      showModal: false
    }
    if (showModal) {
      const modalSaveProps = {
        showModal: showModal,
        message: modalMessage,
        hideModal: this.hideModalMessage,
        size: "'lg'",
        ques: false
      }
      modalProps = modalSaveProps
    }

    return (
      <div id="bannerMessage">
        <h1 id="root__title">Banner Messages</h1>
        {formContext && formContext.isEdit && (
          <h2 className="banner-subHeading">
            Edit Message# {formContext.bannerMessageId}
          </h2>
        )}
        {formContext && !formContext.isEdit && (
          <h2 className="banner-subHeading">Add Banner Message</h2>
        )}
        {form && form.app && form.app.forms.length > 0 ? (
          <Form
            schema={form.app.forms[0].schema}
            uiSchema={form.app.forms[0].uiSchema}
            formData={formData}
            onChange={this.onFormDataChange.bind(this)}
            formContext={formContext}
            fields={fields}
            widgets={widgets}
            ArrayFieldTemplate={ArrayFieldTemplate}
            FieldTemplate={FieldTemplate}
            liveValidate={liveValidate}
            jsonValidator={validator}
            ErrorList={this.errorListTemplate}
            transformErrors={this.transformErrors}
            onError={this.onError}
            onSubmit={this.submitMessage}
            validate={this.onValidate}
          >
            <div className="col-lg-12 col-12">
              <button
                className="userListButton2 btn pull-right"
                type="submit"
                onClick={this.switchOnLiveValidate}
                disabled={formData === null || formData.length === 0}
              >
                Save
              </button>
              <button
                className="userListButton1 btn pull-right"
                type="button"
                onClick={this.clearScreen}
                disabled={formData === null || formData.length === 0}
              >
                Cancel
              </button>
            </div>
          </Form>
        ) : null}
        <ModalContainer {...modalProps} />
        <div style={{ marginTop: '4rem', marginBottom: '2rem', width: '100%' }}>
          {form && form.app && form.app.forms.length > 0 ? (
            <div className="referralTable ml-3 mr-3">
              <div className="searchListButtonDiv">
                <span id="searchTableTitle">Messages List</span>
              </div>
              <Form
                schema={form.app.forms[1].schema}
                uiSchema={form.app.forms[1].uiSchema}
                formData={{ ...messageListResult }}
                formContext={messageFormContext}
                fields={fields}
                widgets={widgets}
                ArrayFieldTemplate={ArrayFieldTemplate}
                FieldTemplate={FieldTemplate}
              >
                <button className="sr-only" type="submit">
                  Search
                </button>
              </Form>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    userAccess: state.userAccess
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerMessages)
