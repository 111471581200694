import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyMessagesContainer = MultitenantLoadable(
  () => import('./MyMessagesContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/MyMessages/MyMessagesContainer')
)

const MyMessagesPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyMessagesPresentation'),
    () =>
      import(
        '../../' + tenantDirectoryName + '/MyMessages/MyMessagesPresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyMessages/formComponents')
)

export default function MyMessages(props) {
  return (
    <ErrorBoundary name="MyMessages">
      <MyMessagesContainer
        {...props}
        presentation={presentationProps => (
          <MyMessagesPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

const MyMessagesDoubleFetcherContainer = MultitenantLoadable(
  () => import('./MyMessagesContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/MyMessages/MyMessagesContainer'),
  'MyMessagesDoubleFetcherContainer'
)

export function MyMessagesDoubleFetcher(props) {
  return (
    <ErrorBoundary name="MyMessagesDoubleFetcher">
      <MyMessagesDoubleFetcherContainer
        {...props}
        presentation={presentationProps => (
          <MyMessagesPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyMessagesContainer = MultitenantLoadable(
  () => import('./MyMessagesContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyMessagesPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyMessagesPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyMessages(props) {
  return (
    <BaseErrorBoundary name="MyMessages">
      <BaseMyMessagesContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyMessagesPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}

const BaseMyMessagesDoubleFetcherContainer = MultitenantLoadable(
  () => import('./MyMessagesContainer'),
  () => Promise.resolve().then(() => null),
  'MyMessagesDoubleFetcherContainer'
)

export function BaseMyMessagesDoubleFetcher(props) {
  return (
    <BaseErrorBoundary name="MyMessagesDoubleFetcher">
      <BaseMyMessagesDoubleFetcherContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyMessagesPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
