import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import { Link } from 'react-router-dom'
import { ENTITLEMENTS_wv as wvEnt } from '../../entitlements_wv'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../../../config'
import ReactHtmlParser from 'react-html-parser'
import { I18n } from 'react-redux-i18n'

const baseUrl = process.env.PUBLIC_URL

class BenefitBlock extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      open: this.props.open
    }
  }

  _getPreInformation = key => {
    let title: string,
      summary: JSX.Element,
      icon: JSX.Element,
      status: JSX.Element
    title = key.programName

    if (key.eligibilitySwitch) {
      status = (
        <span style={{ color: '#006400', fontWeight: 'bold' }}>
          <i className="glyphicon myglyphicon-ok" aria-hidden="true" />
        </span>
      )
    }
    if (key.programName == 'Food & Nutrition') {
      var text = key.description
      summary = ReactHtmlParser(text)
    } else {
      summary = key.description
    }

    return { title, icon, summary, status }
  }

  _boldString(str) {
    const expr = /[$](\d*)(\.)[0-9]+/
    var getString = str
    var stringArray = []
    var match = expr.exec(getString)
    while (match != null) {
      stringArray.push({
        dispStr: getString.substring(0, match.index),
        boldStr: match[0]
      })
      getString = getString.substring(
        match.index + match[0].length,
        getString.length
      )
      match = expr.exec(getString)
    }
    let resultString: JSX.Element
    if (stringArray.length > 0) {
      resultString = (
        <span>
          {stringArray[0].dispStr} <b>{stringArray[0].boldStr} </b>
          {stringArray[1].dispStr} <b>{stringArray[1].boldStr} </b> {getString}
        </span>
      )
    } else {
      resultString = <span>{str}</span>
    }

    return resultString
  }

  _showMapsPdf = () => {
    const pdfPath =
      'https://dhhr.wv.gov/bcf/ece/Documents/R%26R%20Map%20April%202023.pdf'
    window.open(pdfPath)
  }

  render() {
    const propDetails = this.props.propDetails
    const benefitKey = this.props.benefitKey

    const { title, icon, summary, status } = this._getPreInformation(benefitKey)

    return (
      <div className="my-benefits-block">
        <h2 className="title">{title}</h2>
        <hr />
        <p>
          {status} {summary}
        </p>
        {title == 'Child Care' && (
          <div>
            <p>{I18n.t('General.title26')}</p>
            <br />
            <p>{I18n.t('General.title27')}</p>
            <br />
            <p> {I18n.t('General.title28')}</p>
            <ul>
              <li> {I18n.t('General.title29')}</li>
              <li>
                {I18n.t('General.title30')}

                <ul>
                  <li>{I18n.t('General.title31')}</li>
                </ul>
              </li>
              <li>
                {I18n.t('General.title32')}

                <ul>
                  <li>{I18n.t('General.title33')}</li>
                </ul>
              </li>
            </ul>
            <br />
            <p>{I18n.t('General.title34')}</p>
            <ul>
              <li> {I18n.t('General.title35')}</li>
              <li> {I18n.t('General.title36')}</li>
              <li> {I18n.t('General.title37')}</li>
              <li>
                {I18n.t('General.title38')}
                <ul>
                  <li> {I18n.t('General.title39')}</li>
                  <li> {I18n.t('General.title40')}</li>
                </ul>
              </li>
            </ul>
            <div>
              <a id="area_map" href="#" onClick={this._showMapsPdf}>
                {I18n.t('General.title41')}
              </a>
            </div>
          </div>
        )}
      </div>
    )
  }
}

class BenefitsView extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    let content = []
    let link
    const selectedUserRole = _.find(this.props.userAccess.userRoles, {
      userRoleId:
        this.props.userAccess.selectedUserRole &&
        _.toInteger(this.props.userAccess.selectedUserRole.userRoleId)
    })
    const selectedEntitlements = _.get(
      selectedUserRole,
      'entitlements',
      []
    ).map(element => element.entitlementName)
    const CP_APPLY_NOW = _.includes(selectedEntitlements, wvEnt.CP_APPLY_NOW)
      ? true
      : false
    if (this.props.benefits !== undefined) {
      if (this.props.benefits.length >= 1) {
        for (let i = 0; i < this.props.benefits.length; i++) {
          content.push(
            <BenefitBlock
              key={v4()}
              benefitKey={this.props.benefits[i]}
              roleId={this.props.roleId}
              roleName={this.props.roleName}
              userAccess={this.props.userAccess}
              isLoggedIn={this.props.isLoggedIn}
            />
          )
        }
      } else {
        content.push(<h3 key={v4()}>{I18n.t('General.title42')}</h3>)
      }
    }

    if (
      this.props.roleName === 'Presumptive Eligibility Worker' ||
      this.props.roleName === 'PE Admin'
    ) {
      link = (
        <Link
          to={baseUrl + '/myaccount/dashboard'}
          className="margin-regular btn btn-primary"
        >
          {I18n.t('General.title43')}
        </Link>
      )
    } else if (
      this.props.roleName &&
      (this.props.roleName === 'BH_ADMIN_ASST' ||
        this.props.roleName === 'BH_HR_SPEC' ||
        this.props.roleName === 'BH_RES_COOR')
    ) {
      link = (
        <Link
          to={baseUrl + '/myaccount/eligibleApplicants'}
          className="margin-regular btn btn-primary"
        >
          {I18n.t('General.title44')}
        </Link>
      )
    } else if (CP_APPLY_NOW) {
      link = (
        <Link
          to={baseUrl + '/appdrafts'}
          className="margin-regular btn btn-primary goto-focus"
        >
          {I18n.t('BenefitsFinder.goToApplication')}
        </Link>
      )
    } else if (!this.props.isLoggedIn && config['preGoLiveTitlesVisibile']) {
      link = (
        <Link
          to={baseUrl + '/appdrafts'}
          className="margin-regular btn btn-primary goto-focus"
        >
          {I18n.t('BenefitsFinder.goToApplication')}
        </Link>
      )
    }

    return (
      <div>
        <div>
          {content}
          {link}
        </div>
        <p>{I18n.t('General.title45')}</p>
        <br />
        <p>
          <b>{I18n.t('General.title46')}</b>
        </p>
        <br />
        {config['preGoLiveTitlesVisibile'] && (
          <p>{I18n.t('General.title47')}</p>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const isLoggedIn = helper.isLoggedIn(state.auth, config)
  return {
    roleId: _.get(state.userAccess, 'selectedUserRole.role.roleId'),
    roleName: _.get(state.userAccess, 'selectedUserRole.role.roleName'),
    userAccess: state.userAccess,
    isLoggedIn
  }
}

export default connect(mapStateToProps)(BenefitsView)
