import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { isEmpty } from 'lodash' // extra functionality
import { I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

document.title = I18n.t('MyPermission.title')

const _ = { isEmpty }

interface MyPermissionPresentationProps {
  application: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  reviewFormContext: any
  minHeight: string
  onFormDataChange: (formData: any) => void
  goBack: () => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  locale: string
}

export default function MyPermissionPresentation(
  props: MyPermissionPresentationProps
) {
  const {
    application,
    forms,
    formData,
    formContext,
    reviewFormContext,
    minHeight,
    onFormDataChange,
    goBack,
    formComponents,
    locale
  } = props

  let output = <ScreenPreLoader loaded={false} minHeight={minHeight} />

  if (reviewFormContext && reviewFormContext.reviewFormData) {
    output = (
      <div className="form-section">
        <h1>
          <i className="pe-7s-key" /> {I18n.t('MyPermission.title')}
        </h1>
        <Form
          schema={forms[1]['schema']}
          uiSchema={forms[1]['uiSchema']}
          formData={{}}
          formContext={reviewFormContext}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <button type="submit" style={{ display: 'none' }} />
          <div>
            <button
              type="button"
              className="btn btn-default float-right"
              onClick={goBack}
            >
              {I18n.t('General.backButton')}
            </button>
          </div>
        </Form>
      </div>
    )
  } else {
    if (!_.isEmpty(application)) {
      if (
        formData.Application.myPermissions[0].applicationNumber !== undefined &&
        formData.Application.myPermissions[0].applicationNumber !== null
      ) {
        output = (
          <div className="form-section">
            <h1>
              <i className="pe-7s-key" /> {I18n.t('MyPermission.title')}
            </h1>
            <Form
              schema={forms[0]['schema']}
              uiSchema={forms[0]['uiSchema']}
              //onSubmit={this._onSubmit}
              formData={formData}
              onChange={onFormDataChange}
              formContext={formContext}
              fields={formComponents.fields}
              widgets={formComponents.widgets}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              FieldTemplate={formComponents.FieldTemplate}
              locale={locale}
            >
              <button type="submit" style={{ display: 'none' }} />
            </Form>
          </div>
        )
      } else {
        output = (
          <div>
            <h1 className="mypermissions-header">
              <i className="pe-7s-key" /> {I18n.t('MyPermission.title')}
            </h1>
            <p>{I18n.t('MyPermission.noApplicationsMessage')}</p>
          </div>
        )
      }
    }

    return (
      <>
        <Helmet>
          <title>{I18n.t('MyPermission.title')}</title>
        </Helmet>
        {output}
      </>
    )
  }
}
