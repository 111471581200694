import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
import * as _ from 'lodash'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError } from '../src/components/utils'
import { I18n } from 'react-redux-i18n'

const baseUrl = process.env.PUBLIC_URL

const gatewayBaseUrl = config['gatewayBaseUrl']
const gatewayWvUrl = config['gatewayWvUrl']

const loadEndpoint = config['getUserAccountDetails']
const loadAuthEndpoint = gatewayWvUrl + config['authentication']
const loadNoticeEndpoint = gatewayWvUrl + '/WVIE/coc/v2.0/getCaseNumbers'
const formsEndpoint = config['forms_MyProfile']
const formsEndpointCS = config['forms_MyProfileCS']
const pinEndpoint = gatewayWvUrl + config['validateUser']
const updateDefaultUserRoleEndpoint = config['updateDefaultUserRole']
const updateNoticePreferencesEndpoint =
  gatewayWvUrl + '/WVIE/enrollement/v2.0/enrollment'
const updateDefaultOrgFlagEndpoint =
  config['gatewayWvUrl'] + config['updatedefaultorg']

interface Entitlement {
  entitlementId: number
  entitlementName: string
  entitlementDescription: string
}

interface Org {
  roleId: number
  roleName: string
  orgId: number
  orgName: string
  roleorgId: number
  dfltflg: string
}

interface UserRole {
  userRoleId: number
  userBthDt: string
  uuid: string
  orgList: Array<Org>
  role: {
    roleId: number
    roleName: string
    roleDescription: string
    tenantId: number
    status: string
    startDate: string
    endDate: string
  }
  entitlements: Array<Entitlement>
}

interface MyProfileContainerProps {
  presentation: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
      userBthDt: string
      defaultUserRoleId: number
      isDefaultUserRole: boolean
    }
  }
  locale: string
  minHeight: string
  userAccess: {
    userRoles: Array<UserRole>
    orgList: Array<Org>
    selectedUserRole: UserRole
    defaultOrg: Org
  }
  externalAuth?: {
    hasPin: boolean
  }
  roleId: number
  orgId: any
  roleName: string
  errorMessage: any
  setHasPin: (hasPin: boolean) => void
  setDefaultUserRole: (selectedUserRole: UserRole) => void
  setDefaultOrg: (selectedOrg: Org) => void
  updateOrgList: (orgList: Array<Org>) => void
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  serviceErrors: Array<any>
  updateCase
  logoutUser
  setHASCCA: (hasCCA: boolean) => void
  setCaseTypes: any
  updatecsCases
}

interface MyProfileContainerState {
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formData: any
  errorMessage: any
  showPinErrorModal: boolean
  showPreferenceModal: boolean
  showUserRolesModal: boolean
  confirm: () => void
  redirect: string
  edit: boolean
  formContext: any
  userRoles: Array<UserRole>
  defaultUserRoleId: number
  defaultOrgId: number
  roleId: number
  roleName: string
  updatedOrgList: any
}

class MyProfileContainer extends React.Component<
  MyProfileContainerProps,
  MyProfileContainerState
> {
  constructor(props: MyProfileContainerProps) {
    super(props)
    let redirect = null
    this.state = {
      formSchema: null,
      formData: null,
      confirm: null,
      errorMessage: null,
      redirect,
      showPinErrorModal: false,
      showPreferenceModal: false,
      showUserRolesModal: false,
      edit: false,
      formContext: {},
      userRoles: null,
      defaultUserRoleId: null,
      defaultOrgId: null,
      roleId: null,
      roleName: null,
      updatedOrgList: null
    }
  }

  componentDidMount() {
    document.title = I18n.t('myProfile.docTitle')
    console.log('test', process.env)
    const endPoint = config.childSupportIntegrated
      ? formsEndpointCS
      : formsEndpoint
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    serviceHelper
      .fetchJson(
        endPoint.replace('{version}', '1.0'),
        request,
        this.props.showErrorMessage
      )
      .then(formJson => {
        this._loadFormSchema(formJson)
        this._getFormData(loadEndpoint)
        this._getAuthData(loadAuthEndpoint)
      })
      .catch(error => {
        console.error('MyProfile componentDidMount failed due to ex', error)
      })

    this.setState(prevState => {
      if (_.get(config.tenant, 'externalAuth.checkHasPin', false)) {
        return {
          formContext: {
            ...prevState.formContext,
            addPin: this._pinValidation,
            checkHasPin: true,
            locale: this.props.locale
          }
        }
      } else {
        return {
          formContext: {
            ...prevState.formContext,
            checkHasPin: false
          }
        }
      }
    })
    this._loadUserRoles(this.props.userAccess)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      const request = {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tenantCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          locale: (this.props.locale + '_US').toUpperCase()
        }
      }
      serviceHelper
        .fetchJson(
          formsEndpoint.replace('{version}', '1.0'),
          request,
          this.props.showErrorMessage
        )
        .then(formJson => {
          //  formJson = mockForms
          this._loadFormSchema(formJson)
        })
        .catch(error => {
          console.error('MyProfile componentDidMount failed due to ex', error)
        })
    }
  }

  _loadUserRoles(json) {
    let userRoles = json.userRoles
    let defaultUserRoleId = !_.isNil(
      this.props.auth.userAccount.defaultUserRoleId
    )
      ? this.props.auth.userAccount.defaultUserRoleId
      : 0
    let defaultOrgId = !_.isNil(
      this.props.userAccess.defaultOrg &&
        this.props.userAccess.defaultOrg.roleorgId
    )
      ? this.props.userAccess.defaultOrg.roleorgId
      : 0
    this.setState({
      userRoles: userRoles,
      defaultUserRoleId: defaultUserRoleId,
      defaultOrgId: defaultOrgId
    })
  }

  static getDerivedStateFromProps(
    props: MyProfileContainerProps,
    state: MyProfileContainerState
  ) {
    if (_.get(config.tenant, 'externalAuth.checkHasPin', false)) {
      return {
        formContext: {
          ...state.formContext,
          hasPin: props.externalAuth.hasPin
        }
      }
    } else {
      return null
    }
  }

  _onFormDataChange = ({ formData }) => {
    let defaultRoleId = this.state.defaultUserRoleId
    let defaultOrgId = this.state.defaultOrgId
    if(formData.userPin){
      if (formData.userPin.selection !== '1') {
        formData.userPin.ssnEntry = undefined;
        formData.userPin.ssnReEntry = undefined;
      } 
      if (formData.userPin.selection !== '2') {
        formData.userPin.pin = undefined;
        formData.userPin.pinReEntry = undefined;
      } 
      if (formData.userPin.selection !== '3') {
        formData.userPin.ccaPinNumber = undefined;
        formData.userPin.ccaPinNumberReEntry = undefined;
      }
    }
    for (let userRole of formData.roleSetting.userRoles) {
      if (userRole.defaultRole === 'true') {
        if (this.state.defaultUserRoleId != userRole.userRoleId) {
          defaultRoleId = userRole.userRoleId
        }
        if (userRole.org && this.state.defaultOrgId != userRole.org.roleorgId) {
          defaultOrgId = userRole.org.roleorgId
        } else if (userRole.org == undefined) {
          defaultOrgId = -1
        }
      }
    }
    this.setState(prevState => {
      return {
        formData:_.cloneDeep(formData),
        formContext: {
          ...prevState.formContext,
          formData:_.cloneDeep(formData),
        },
        defaultUserRoleId: defaultRoleId,
        defaultOrgId: defaultOrgId
      }
    })
  }

  _getFormData = endpoint => {
    serviceHelper
      .fetchJson(
        endpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        if (json['userAccountAddresses'].length == 0) {
          let userAccountAddress = new Object()
          userAccountAddress['adrLn1Txt'] = ''
          userAccountAddress['adrLn2Txt'] = ''
          userAccountAddress['ctyNm'] = ''
          userAccountAddress['cnty'] = ''
          userAccountAddress['st'] = ''
          userAccountAddress['zip'] = ''
          json['userAccountAddresses'][0] = userAccountAddress
        }
        json['userAccountAddresses'][0]['adrLn1Txt'] =
          json['userAccountAddresses'][0]['adrLn1Txt'] == null
            ? ''
            : json['userAccountAddresses'][0]['adrLn1Txt']
        json['userAccountAddresses'][0]['adrLn2Txt'] =
          json['userAccountAddresses'][0]['adrLn2Txt'] == null
            ? ''
            : json['userAccountAddresses'][0]['adrLn2Txt']
        json['userAccountAddresses'][0]['ctyNm'] =
          json['userAccountAddresses'][0]['ctyNm'] == null
            ? ''
            : json['userAccountAddresses'][0]['ctyNm']
        json['userAccountAddresses'][0]['cnty'] =
          json['userAccountAddresses'][0]['cnty'] == null
            ? ''
            : json['userAccountAddresses'][0]['cnty']
        json['userAccountAddresses'][0]['st'] =
          json['userAccountAddresses'][0]['st'] == null
            ? ''
            : json['userAccountAddresses'][0]['st']
        json['userAccountAddresses'][0]['zip'] =
          json['userAccountAddresses'][0]['zip'] == null
            ? ''
            : json['userAccountAddresses'][0]['zip']

        // JSON for Role Settings table
        let roleSetting = { userRoles: this.state.userRoles }
        let updatedOrgList
        json['roleSetting'] = roleSetting
        for (let userRole of roleSetting['userRoles']) {
          userRole['defaultRole'] =
            userRole.userRoleId === this.state.defaultUserRoleId
              ? 'true'
              : 'false'
          if (userRole.orgList && userRole.orgList.length > 0) {
            let newUserRoles = []
            for (let Org of userRole['orgList']) {
              userRole['defaultRole'] =
                Org.roleorgId === this.state.defaultOrgId ? 'true' : 'false'
              let newUserRole = _.clone(userRole)
              newUserRole['org'] = Org
              newUserRoles.push(newUserRole)
              updatedOrgList = userRole['orgList']
              this.setState({
                updatedOrgList: updatedOrgList
              })
            }
            roleSetting.userRoles = roleSetting.userRoles.filter(roleObj => {
              return roleObj.userRoleId != userRole.userRoleId
            })
            roleSetting.userRoles = roleSetting.userRoles.concat(newUserRoles)
          }
        }
        this._loadFormData(json)
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'MyProfile _getFormData failed due to ex',
          error,
          'endpoint: ' + endpoint
        )
      })
  }

  _getAuthData = endpoint => {
    helper
      .fetchJson(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName
        }
      })
      .then(json => {
        if (this.props.userAccess.selectedUserRole.role.roleName === 'Client') {
          this.props.setHasPin(json['fa'] || json['cca'] || json['cs'])
          this.props.setCaseTypes({
            cca: json['cca'],
            fa: json['fa'],
            cs: json['cs']
          })
          if (json['fa'] || json['cca'] || json['cs']) {
            this.setState({
              formContext: {
                ...this.state.formContext,
                fa: json['fa'],
                cca: json['cca'],
                cs: json['cs']
              }
            })
            this._loadCaseNumbers()
          }
        } else {
          this.props.setHasPin(true)
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'MyProfile _getFormData failed due to ex',
          error,
          'endpoint: ' + endpoint
        )
      })
  }

  _loadCaseNumbers = () => {
    serviceHelper
      .fetchJson(
        loadNoticeEndpoint,
        {
          method: 'GET',
          headers: {
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            'Content-Type': 'application/json',
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage,
        0,
        60000
      )
      .then(res => {
        helper.cleanNullValues(res);
        const noticesN = _.uniqBy(res.caseList, 'caseNumber')
        const hasCCA = _.filter(res.caseList, c => {
          return c.programType === 'CCA'
        })
        const csCases = _.filter(res.caseList, c => {
          return c.programType === 'CS'
        })
        const notices = _.map(noticesN, caseNumber => {
          let newNotice = {
            additionalInfo: undefined,
            caseNumber: caseNumber.caseNumber,
            goPaperless: caseNumber.goPaperless,
            extCaseNumber: caseNumber.extCaseNumber,
            programType: caseNumber.programType,
            programName:
              caseNumber.programType === 'CCA'
                ? 'Child Care'
                : caseNumber.programType === 'FA'
                ? 'Family Assistance'
                : 'Child Support'
          }
          return newNotice
        })
        if (notices[0]) {
          this.props.updateCase(notices[0].caseNumber)
        }
        if (hasCCA.length > 0) {
          this.props.setHASCCA(true)
        }
        this.props.updatecsCases(csCases)
        const paperlessNotices = { notices }
        this._loadPaperlessNotices(paperlessNotices)
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
      })
  }

  _pinValidation = (userPin: any) => {
    if (userPin.selection === '1' && userPin.ssnEntry !== userPin.ssnReEntry) {
      alert('Social Security Numbers do not match. Please correct them')
      return
    }
    const params = {
      user: {
        uuid: this.props.auth.userAccount.uuid,
        userBthDt: this.state.formData.userBthDt,
        ssn: userPin.selection === '1' ? userPin.ssnEntry || null : null,
        pinNumber: userPin.selection === '2' ? userPin.pin || null : null,
        ccaPinNumber:
          userPin.selection === '3' ? userPin.ccaPinNumber || null : null
      }
    }

    serviceHelper
      .fetchJson(
        pinEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          body: JSON.stringify(params)
        },
        this.props.showErrorMessage
      )
      .then(json => {
        const hasPin = (_.get(json, "user.fa") || _.get(json, "user.cca") || _.get(json, "user.cs")) || (this.state.formContext.cca || this.state.formContext.fa || this.state.formContext.cs)
        let errorMessage = json && json['user']['errorMessage']
        this.props.setCaseTypes({
          cca: this.state.formContext.cca || json['user']['cca'],
          fa: this.state.formContext.fa || json['user']['fa'],
          cs: this.state.formContext.cs || json['user']['cs']
        })
        if (json['user']['fa'] || json['user']['cca'] || json['user']['cs']) {
          this.setState({
            formContext: {
              ...this.state.formContext,
              fa: this.state.formContext.fa || json['user']['fa'],
              cca: this.state.formContext.cca || json['user']['cca'],
              cs: this.state.formContext.cs || json['user']['cs']
            }
          })
          this._loadCaseNumbers()
        }
        this.setState(prevState => {
          return {
            errorMessage
          }
        })

        if (errorMessage) {
          this._openPinErrorModal()
        }
        this.props.setHasPin(hasPin)
        this._loadCaseNumbers()
      })

      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error('MyProfile _pinValidation failed due to ex', error)
      })
  }

  _onSubmit = ({ formData }) => {
    let formSchema = helper.deepClone(this.state.formSchema)
    let userAccountAddress =
      formSchema['uiSchema']['userAccountAddresses']['items']

    if (this.state.edit) {
      userAccountAddress['adrLn1Txt']['ui:widget'] = 'span'
      userAccountAddress['adrLn2Txt']['ui:widget'] = 'span'
      userAccountAddress['ctyNm']['ui:widget'] = 'span'
      userAccountAddress['cnty']['ui:widget'] = 'span'
      userAccountAddress['st']['ui:widget'] = 'span'
      userAccountAddress['zip']['ui:widget'] = 'span'
      this.setState({ formSchema: formSchema, formData: null, edit: false })
    } else {
      userAccountAddress['adrLn1Txt']['ui:widget'] = 'text'
      userAccountAddress['adrLn2Txt']['ui:widget'] = 'text'
      userAccountAddress['ctyNm']['ui:widget'] = 'text'
      userAccountAddress['cnty']['ui:widget'] = 'text'
      userAccountAddress['st']['ui:widget'] = 'text'
      userAccountAddress['zip']['ui:widget'] = 'text'
      this.setState({ formSchema: formSchema, formData: formData, edit: true })
    }
  }

  _updateDefaultUserRole = () => {
    let defaultUserRoleId = this.state.defaultUserRoleId
    if (defaultUserRoleId <= 0) {
      return
    }

    let userRoles = this.state.userRoles
    let selectedUserRole = null
    for (let userRole of userRoles) {
      if (userRole.userRoleId === defaultUserRoleId) {
        selectedUserRole = userRole
      }
    }
    if (!_.isNil(selectedUserRole)) {
      const updateEndpoint = updateDefaultUserRoleEndpoint
        .replace('{userRoleId}', defaultUserRoleId.toString())
        .replace('{isDefaultUserRole}', 'Y')
      serviceHelper
        .fetchJson(
          updateEndpoint,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName
            }
          },
          this.props.showErrorMessage
        )
        .then(() => {
          this.setState({ showUserRolesModal: true }, () => {
            this.props.setDefaultUserRole(selectedUserRole)
          })
          //this._loadCaseNumbers()
          // Code fix for US3566129
          // call getCaseNumber service only for Client User
          if (
            this.props.userAccess.selectedUserRole.role.roleName === 'Client'
          ) {
            this._loadCaseNumbers()
          }
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          console.error(
            'SelectRole _updateUserRoleSelection failed due to ex',
            ex,
            'endpoint: ',
            updateEndpoint
          )
          this.setState({
            showPinErrorModal: true,
            errorMessage: 'Failed to update user role selections.'
          })
        })
      this._getFormData(loadEndpoint)
    }
  }

  _updateDefaultOrg = () => {
    let defaultOrgId = this.state.defaultOrgId
    if (defaultOrgId <= 0) {
      this.setState({}, () => {
        this.props.setDefaultOrg(null)
      })
    }
    let dOrgId = _.get(this.state, 'defaultOrgId', null)
    let dUserRoleId = _.get(this.state, 'defaultUserRoleId', null)
    let uRoles = _.get(this.state, 'userRoles', [])

    //getting the roleorgId and setting the dfltflg to y
    if (dUserRoleId != null) {
      if (uRoles.length > 0) {
        uRoles = uRoles.filter(ele => ele.userRoleId == dUserRoleId)
        if (uRoles[0].role.roleName !== 'Client') {
          uRoles = uRoles[0].orgList.filter(
            orgList => orgList.roleorgId === dOrgId
          )
        }
        uRoles = uRoles.map(uRoles => {
          return {
            ...uRoles,
            dfltflg: 'Y'
          }
        })
        uRoles = uRoles[0]
      }
    }

    let currentOrgList = this.state.updatedOrgList
    let newOrgList =
      currentOrgList &&
      currentOrgList.length > 0 &&
      currentOrgList.map(orgObj => {
        if (orgObj.roleorgId == defaultOrgId) {
          return {
            ...orgObj,
            dfltflg: 'Y'
          }
        } else if (
          this.props.userAccess.defaultOrg != null &&
          orgObj.roleorgId != defaultOrgId
        ) {
          return {
            ...orgObj,
            dfltflg: 'Y'
          }
        } else {
          return orgObj
        }
      })
    let defaultOrgObj
    if (newOrgList && newOrgList.length > 0) {
      defaultOrgObj = newOrgList.find(Obj => {
        return Obj.dfltflg == 'Y'
      })
    }
    if (_.isNil(defaultOrgObj)) {
      defaultOrgObj = null
    }

    if (this.state.updatedOrgList && this.state.updatedOrgList.length > 0) {
      serviceHelper
        .fetchJson(
          updateDefaultOrgFlagEndpoint,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              roleId: this.props.roleId,
              orgId: this.props.orgId
            },
            body: JSON.stringify({
              flag: uRoles.dfltflg,
              roleOrgId: uRoles.roleorgId ? uRoles.roleorgId.toString() : '',
              uuid: this.props.auth.userAccount.uuid
            })
          },
          this.props.showErrorMessage
        )
        .then(() => {
          this.setState({}, () => {
            this.props.setDefaultOrg(uRoles)
            this.props.updateOrgList(newOrgList)
          })
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          console.error(
            'SelectRole _updateUserRoleSelection failed due to ex',
            ex,
            'endpoint: ',
            updateDefaultOrgFlagEndpoint
          )
        })
    }
  }

  _updateNoticePreferences = () => {
    const params = {
      paperLessNotices: {
        userEmail: this.state.formData.userEmail,
        firstName: this.state.formData.userFstNm,
        lastName: this.state.formData.userLstNm,
        notices: this.state.formData.paperlessNotices.notices
      }
    }
    serviceHelper
      .fetchJson(
        updateNoticePreferencesEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          body: JSON.stringify(params)
        },
        this.props.showErrorMessage
      )
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
      })

    this._openPreferenceModal()
  }

  _loadFormData = (json: Object) => {
    this.setState({ formData: json })
  }

  _loadPaperlessNotices = (json: Object) => {

    this.setState({
      formData: { ...this.state.formData, paperlessNotices: json }
    })
  }

  _loadFormSchema = (json: any) => {
    this.setState({ formSchema: json['myProfile']['forms'] })
  }

  _closePinErrorModal = () => {
    this.setState({ showPinErrorModal: false })
  }
  _closeUserRolesModal = () => {
    this.setState({ showUserRolesModal: false })
  }

  _openPinErrorModal = () => {
    this.setState({ showPinErrorModal: true })
  }
  _closePreferenceModal = () => {
    this.setState({ showPreferenceModal: false })
  }

  _openPreferenceModal = () => {
    this.setState({ showPreferenceModal: true })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    const { presentation, minHeight, serviceErrors } = this.props
    const {
      formSchema,
      formData,
      edit,
      formContext,
      showPinErrorModal,
      errorMessage,
      showPreferenceModal,
      showUserRolesModal,
      userRoles,
      defaultUserRoleId
    } = this.state

    const presentationProps = {
      formSchema,
      formData,
      edit,
      formContext,
      onSubmit: this._onSubmit,
      onFormDataChange: this._onFormDataChange,
      closePinErrorModal: this._closePinErrorModal,
      closePreferenceModal: this._closePreferenceModal,
      showPinErrorModal,
      errorMessage,
      showPreferenceModal,
      minHeight,
      userRoles,
      defaultUserRoleId,
      updateDefaultUserRole: this._updateDefaultUserRole,
      updateDefaultOrg: this._updateDefaultOrg,
      updateNoticePreferences: this._updateNoticePreferences,
      showUserRolesModal,
      closeUserRolesModal: this._closeUserRolesModal,
      serviceErrors,
      onDeleteClick: this._onDeleteClick
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let roleName = _.get(state.userAccess, 'selectedUserRole.role.roleName') || ''
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  return {
    externalAuth: state.externalAuth,
    auth: state.auth,
    userAccess: state.userAccess,
    roleId: _.get(state.userAccess, 'selectedUserRole.role.roleId'),
    serviceErrors: state.myMessagesError.myMessagesError.errorMessage || [],
    orgId: orgId,
    roleName,
    locale: state.i18n.locale
  }
}

const responseMapper = json => {
  return { Application: { verifications: json || [] } }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setHasPin: hasPin => {
      dispatch(actions.setHasPin(hasPin))
    },
    setDefaultUserRole: selectedUserRole => {
      dispatch(actions.setDefaultUserRoleId(selectedUserRole.userRoleId))
    },
    setDefaultOrg: selectedOrg => {
      dispatch(actions.setDefaultOrg(selectedOrg))
    },
    updateOrgList: orgList => {
      dispatch(actions.updateOrgList(orgList))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    updateCase: caseNumber => {
      dispatch(actions.updateCase(caseNumber))
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    setHASCCA: hasCCA => {
      dispatch(actions.setHASCCA(hasCCA))
    },
    setCaseTypes: setCaseTypes => {
      dispatch(actions.setCaseTypes(setCaseTypes))
    },
    updatecsCases: csCases => {
      dispatch(actions.updatecsCases(csCases))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileContainer)
