import * as React from 'react'
import { isEqual, get, cloneDeep, forEach } from 'lodash'
import { connect } from 'react-redux'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'

const _ = { isEqual, get, cloneDeep, forEach }

const loadEndpoint = config['getMyBenefits']
const myBenefitsSchemaEndpoint = config['forms_MyBenefits']

const TRANSLATIONS = {
  en: {
    active: 'Active',
    pending: 'Pending',
    healthcare: 'Healthcare'
  },
  es: {
    active: 'Activo',
    pending: 'Pendiente',
    healthcare: 'Cuidado de la salud'
  }
}

interface MyBenefitsContainerProps {
  presentation: any
  uuid: string
  userRoleId: number
  locale: string
  authHeader: string
}

interface MyBenefitsContainerState {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  callingApi: boolean
}

class MyBenefitsContainer extends React.Component<
  MyBenefitsContainerProps,
  MyBenefitsContainerState
> {
  constructor(props: MyBenefitsContainerProps) {
    super(props)

    this.state = {
      forms: null,
      formData: null,
      formContext: null,
      callingApi: false
    }
  }

  componentDidMount() {
    this._initializeForms()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      const endpoint = myBenefitsSchemaEndpoint.replace('{version}', '1.0')

      this.setState({
        callingApi: true,
        formData: this.updateFormDataByLocale(
          prevProps.locale,
          this.props.locale,
          this.state.formData
        )
      })
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(json => {
          this.setState(prevState => {
            return {
              callingApi: false,
              formContext: {
                ...prevState.formContext,
                forms: json['forms']
              },
              forms: json['forms']
            }
          })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('MyBenefits form re-fetch failed due to ex', error)
          const code = CODES.MY_BENEFITS_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  // TODO : Remove Translation Band-Aid
  updateFormDataByLocale = (oldLocale, newLocale, formData) => {
    const newFormData = _.cloneDeep(formData)

    _.forEach(newFormData, ele => {
      switch (ele.currentStatus) {
        case TRANSLATIONS[oldLocale].active:
          ele.currentStatus = TRANSLATIONS[newLocale].active
          break
        case TRANSLATIONS[oldLocale].pending:
          ele.currentStatus = TRANSLATIONS[newLocale].pending
          break
      }

      if (ele.benefitProgramCode === TRANSLATIONS[oldLocale].healthcare) {
        ele.benefitProgramCode = TRANSLATIONS[newLocale].healthcare
      }
    })

    return newFormData
  }

  _initializeForms = () => {
    const endpoint = myBenefitsSchemaEndpoint.replace('{version}', '1.0')
    helper
      .fetchJson(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(json => {
        const myBenefitsSchema = json
        const forms = myBenefitsSchema['forms']

        helper.cleanNullValues(forms)

        this.setState(
          {
            forms,
            formContext: {
              forms,
              formData: null,
              refs: myBenefitsSchema['metaData']['refs'],
              config
            }
          },
          () => {
            this._getMyBenefitsJSON()
          }
        )
      })
      .catch(error => {
        console.error('MyBenefits _initializeForms failed due to ex', error)
        const code = CODES.MY_BENEFITS_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _getMyBenefitsJSON = () => {
    helper
      .fetchJson(loadEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          userRoleId: this.props.userRoleId.toString(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        }
      })
      .then(json => {
        let formData = json
        helper.cleanNullValues(formData)

        // update react state with fetched data
        this._onFormDataChange({
          formData: this.updateFormDataByLocale(
            'en',
            this.props.locale,
            formData
          )
        })
      })
      .catch(error => {
        console.error('MyBenefits _getMyBenefitsJSON failed due to ex', error)
        const code = CODES.MY_BENEFITS_FETCH_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData
        }
      }
    })
  }

  render() {
    const { presentation, locale } = this.props
    const { formData, formContext, forms } = this.state

    const presentationProps = {
      formData,
      formContext,
      forms,
      onFormDataChange: this._onFormDataChange,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    userRoleId,
    locale: state.i18n.locale,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

export default connect(mapStateToProps)(MyBenefitsContainer)
