import { CustomSchemaField } from '@optum-wvie/dynamic-ui-framework/src/components/fields/CustomSchemaField'
import * as _ from 'lodash'
import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'

export default class ConditionalField extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      visibility: null
    }
  }

  static getDerivedStateFromProps(props, state) {
    const visibility = helper.determineVisibility(props, state)
    if (!helper.shallowEqual(visibility, state.visibility)) {
      return {
        visibility
      }
    } else {
      return null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.formContext.deleteOnHide &&
      this.state.visibility &&
      prevState.visibility
    ) {
      //Use with caution! Not all forms should delete data just because it becomes hidden.
      helper.clearHiddenData(
        this.props,
        this.state.visibility,
        prevState.visibility
      )
    }
  }

  _handleChange = name => {
    return fieldData => {
      this.props.onChange({
        ...this.props.formData,
        [name]: fieldData
      })
    }
  }

  render() {
    const {
      formData,
      errorSchema,
      formContext,
      uiSchema,
      idSchema,
      registry,
      required,
      schema,
      locale
    } = this.props
    const { visibility } = this.state
    const { title, description } = schema

    let newTitle = helper.resolveStringRefs(
      title,
      formContext,
      idSchema.$id,
      formData,
      schema,
      uiSchema,
      locale
    )
    let newDescription = helper.resolveStringRefs(
      description,
      formContext,
      idSchema.$id,
      formData,
      schema,
      uiSchema,
      locale
    )

    let properties = Object.keys(schema.properties || {})
    if (uiSchema['ui:order']) {
      properties = uiSchema['ui:order']
      // Used for Wildcard injection into the ordering
      let diff = Object.keys(schema.properties || {}).filter(
        x => uiSchema['ui:order'].indexOf(x) < 0
      )
      for (let i = 0; i < uiSchema['ui:order'].length; i++) {
        if (uiSchema['ui:order'][i] == '*') {
          properties.splice(i, 1, ...diff)
          break
        }
      }
    }

    let masterIsDisabled = false
    if (uiSchema['ui:disableIf']) {
      var f = new Function('formContext', 'return ' + uiSchema['ui:disableIf'])
      masterIsDisabled = f(formContext)
    }

    let masterIsDisabledString
    if (uiSchema['ui:readonly'] || formContext.isReadOnly) {
      masterIsDisabledString = helper.resolveStringRefs(
        uiSchema['ui:readonly'],
        formContext,
        idSchema.$id,
        formData,
        schema,
        uiSchema,
        locale
      )
    }

    return (
      <div
        className={uiSchema.fieldClassNames ? uiSchema.fieldClassNames : null}
      >
        {newTitle && newTitle.length > 0 ? (
          newDescription ? (
            <h1>{newTitle}</h1>
          ) : (
            <legend className="legend-replacement">{newTitle}</legend>
          )
        ) : null}
        {newDescription && newDescription.length > 0 ? (
          <p className="paragraph-label">{newDescription}</p>
        ) : null}
        {properties.map(name => {
          let isVisible =
            visibility && !_.isEmpty(visibility) ? visibility[name] : true
          let isDisabled = false
          let newUiSchema = { ...uiSchema[name] }

          if (
            isVisible &&
            uiSchema &&
            uiSchema[name] &&
            uiSchema[name]['ui:disableIf']
          ) {
            try {
              var f = new Function(
                'formData',
                'formContext',
                'return (' +
                  helper.resolveStringRefs(
                    uiSchema[name]['ui:disableIf'],
                    formContext,
                    idSchema[name].$id,
                    formData,
                    schema,
                    uiSchema,
                    locale
                  ) +
                  ')'
              )
              isDisabled = f(formData, formContext)
            } catch (err) {
              console.error(
                'Error in ConditionalField disableIf check',
                uiSchema[name]['ui:disableIf'],
                err
              )
            }
          }

          if (isDisabled || masterIsDisabledString === 'true') {
            newUiSchema['ui:readonly'] = true
          }

          if (masterIsDisabledString === 'false') {
            newUiSchema['ui:readonly'] = false
          }

          return !isVisible ? null : (
            <CustomSchemaField
              formContext={formContext}
              errorSchema={errorSchema[name]}
              onChange={this._handleChange(name)}
              uiSchema={newUiSchema}
              idSchema={idSchema[name]}
              formData={formData[name] ? formData[name] : ''}
              registry={registry}
              schema={schema.properties[name]}
              name={name}
              key={name}
              locale={locale}
            />
          )
        })}
      </div>
    )
  }
}
