import * as React from 'react'
import { Panel } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Panel'
import { I18n } from 'react-redux-i18n'
export default class Instructions extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      open: true
    }
  }

  onSelect = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { options } = this.props

    const { open } = this.state

    return (
      <>
        <Panel className="instruction-panel" expanded={open}>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 ">
              <div className="col-lg-12 col-md-12 col-12 ">
                <p className="">{options.title}</p>
              </div>
            </div>
            <div
              className="col-lg-12 col-md-12 col-12 float-right"
              style={{ paddingRight: '15px' }}
            >
              <button
                className="btn float-right btn-primary glance-btn"
                onClick={() => {
                  this.setState({ open: !open })
                }}
              >
                {I18n.t('General.getStartedBtnLabel')}
              </button>
            </div>
          </div>
        </Panel>
        <div className="row">
          <button
            className="application-collapse-panel"
            onClick={() => {
              this.setState({ open: !open })
            }}
            aria-expanded={this.state.open ? 'true' : 'false'}
          >
            <span className="sr-only">
              {this.state.open
                ? 'Collapse Instructions Box'
                : 'Expand Instructions Box'}
            </span>
            <span className="glyphicons myglyphicon-show_lines colored" />
          </button>
        </div>
      </>
    )
  }
}
