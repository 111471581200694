import * as React from 'react'
import * as _ from 'lodash'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import * as rjsfUtils from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/utils'

export default class ReviewDifference extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      form: null
    }
  }

  componentDidMount() {
    this._initializeForm()
  }

  _initializeForm = () => {
    const { formContext } = this.props
    const { STEPS } = formContext

    let masterSchema = {}
    let masterUiSchema = {}
    for (let i = 0; i < formContext.forms.length; i++) {
      switch (i + 1) {
        case STEPS.PERSONAL:
        case STEPS.ADDITIONAL_INFORMATION:
        case STEPS.CHILD_CARE:
        case STEPS.INCOME:
        case STEPS.EXPENSES:
        case STEPS.ASSETS:
          _.merge(masterSchema, formContext.forms[i]['schema'])
          _.merge(masterUiSchema, formContext.forms[i]['uiSchema'])
          break
      }
    }

    _.unset(masterSchema, 'properties.application.properties.reviewDifference')
    _.unset(masterUiSchema, 'application.reviewDifference')
    _.unset(masterUiSchema, 'externalOptions')
    _.unset(masterUiSchema, 'ui:rootFieldId')
    _.unset(masterUiSchema, 'ui:title')
    _.set(
      masterUiSchema,
      'application.clients.ui:eachVisibleIf',
      '{has differences}'
    )
    _.set(masterUiSchema, 'application.clients.items.ui:field', 'panelGroup')
    _.set(
      masterUiSchema,
      'application.clients.items.ui:options.defaultExpanded',
      true
    )
    _.set(masterUiSchema, 'application.clients.items.ui:options.editLink', true)
    _.set(
      masterUiSchema,
      'application.clients.items.classNames',
      'plus-minus-panel'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.clientDetails.ui:title',
      'Personal'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.clientDetails.ui:visibleIf',
      '{has differences}'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.clientAdditionalInformation.ui:title',
      'Additional Information'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.clientAdditionalInformation.ui:visibleIf',
      '{has differences}'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.childCare.ui:title',
      'Child Care'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.childCare.ui:visibleIf',
      '{has differences}'
    )

    _.set(
      masterUiSchema,
      'application.clients.items.clientIncomes.ui:title',
      'Income'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.clientIncomes.ui:visibleIf',
      '{has differences}'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.clientExpenses.ui:title',
      'Expenses'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.clientExpenses.ui:visibleIf',
      '{has differences}'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.clientAssets.ui:title',
      'Assets'
    )
    _.set(
      masterUiSchema,
      'application.clients.items.clientAssets.ui:visibleIf',
      '{has differences}'
    )
    _.forEach(
      _.get(masterUiSchema, 'application.clients.items'),
      (value, key) => {
        if (_.isObject(value) && !_.isArray(value) && !key.startsWith('ui:')) {
          _.set(
            masterUiSchema,
            'application.clients.items.' + key + '.ui:visibleIf',
            '{has differences}'
          )
          _.set(
            masterUiSchema,
            'application.clients.items.' + key + '.ui:field',
            'condition'
          )
          _.forEach(
            _.get(masterUiSchema, 'application.clients.items.' + key),
            (value2, key2) => {
              if (
                _.isObject(value2) &&
                !_.isArray(value2) &&
                !key2.startsWith('ui:')
              ) {
                _.set(
                  masterUiSchema,
                  'application.clients.items.' +
                    key +
                    '.' +
                    key2 +
                    '.ui:visibleIf',
                  '{has differences}'
                )
                _.set(
                  masterUiSchema,
                  'application.clients.items.' + key + '.' + key2 + '.ui:field',
                  'reviewPublicTrackerForm'
                )
              }
            }
          )
        }
      }
    )
    masterUiSchema = helper.setAllReadOnly(masterUiSchema)
    this.setState({
      form: {
        schema: masterSchema,
        uiSchema: masterUiSchema
      }
    })
  }

  _hasAnyDifferences = formData => {
    let found = false
    if (_.isObject(formData)) {
      _.forEach(formData, (value, key) => {
        if (key === 'action' && value === 'NEW') {
          found = true
          return false
        } else if (this._hasAnyDifferences(value)) {
          found = true
          return false
        }
      })
    }
    return found
  }

  render() {
    const { formContext } = this.props
    const { form } = this.state
    if (form && form.schema && form.uiSchema) {
      const newFormContext = {
        ...formContext,
        refs: {
          ...formContext.refs,
          '{has differences}': ({ formData }) => {
            return this._hasAnyDifferences(formData) ? 'true' : 'false'
          }
        }
      }
      const newIdSchema = rjsfUtils.toIdSchema(
        form.schema,
        'reviewDifference',
        form.schema.definitions,
        formContext.formData
      )
      return (
        <Fields.CustomSchemaField
          {...this.props}
          idSchema={newIdSchema}
          schema={form.schema}
          uiSchema={form.uiSchema}
          formData={formContext.formData}
          formContext={newFormContext}
          registry={{
            ...this.props.registry,
            formContext: newFormContext
          }}
        />
      )
    } else {
      return <span>"Loading..."</span>
    }
  }
}
