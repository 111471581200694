import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const MyAppDraftsContainer = MultitenantLoadable(
  () => import('./MyAppDraftsContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/MyAppDrafts/MyAppDraftsContainer')
)

const MyAppDraftsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyAppDraftsPresentation'),
    () =>
      import(
        '../../' + tenantDirectoryName + '/MyAppDrafts/MyAppDraftsPresentation'
      )
  ),
  () => import('./formComponents'),
  () => import('../../' + tenantDirectoryName + '/MyAppDrafts/formComponents')
)

export default function MyAppDrafts(props: any) {
  return (
    <ErrorBoundary name="MyAppDrafts">
      <MyAppDraftsContainer
        {...props}
        presentation={presentationProps => (
          <MyAppDraftsPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMyAppDraftsContainer = MultitenantLoadable(
  () => import('./MyAppDraftsContainer'),
  () => Promise.resolve().then(() => null)
)

const BaseMyAppDraftsPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./MyAppDraftsPresentation'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./formComponents'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyAppDrafts(props: any) {
  return (
    <BaseErrorBoundary name="MyAppDrafts">
      <BaseMyAppDraftsContainer
        {...props}
        presentation={presentationProps => (
          <BaseMyAppDraftsPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
