import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as actions from '../../../actions'
import { config } from '../../../config'
import { setLocale } from 'react-redux-i18n'
import { I18n } from 'react-redux-i18n'
import Banner from './Banner'

declare var process
const baseUrl = process.env.PUBLIC_URL

class Layout extends React.Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      children,
      header,
      footer,
      isLoggedIn,
      userAccount,
      userAccess,
      logoutUser,
      fakeLogin,
      setSelectedUserRole,
      setSelectedOrg,
      i18n,
      setLocale,
      auth
    } = this.props
    const { locale } = i18n
    const headerProps = {
      isLoggedIn,
      userAccount,
      userAccess,
      logoutUser,
      fakeLogin,
      setSelectedUserRole,
      setSelectedOrg,
      locale,
      setLocale,
      auth
    }
    const footerProps = {
      locale
    }
    return (
      <div id="app">
        <Banner {...headerProps} />
        {header(headerProps)}
        <section
          className="body-content container-fluid"
          style={{ padding: 0 }}
        >
          {children}
        </section>
        {footer(footerProps)}
        <Link to={baseUrl + '/feedback'} className="feedback-link">
          {I18n.t('General.feedback')}
        </Link>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: helper.isLoggedIn(state.auth, config),
    userAccount: state.auth.userAccount,
    userAccess: state.userAccess,
    i18n: state.i18n,
    auth: state.auth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: (userAccount, accessToken) => {
      dispatch(actions.logoutUser(userAccount, accessToken))
    },
    fakeLogin: () => {
      dispatch(actions.fakeLogin())
      dispatch(
        actions.fetchUserRoles(config['openId_localhostAccount'], undefined)
      )
    },
    setSelectedUserRole: selectedUserRole => {
      dispatch(actions.setSelectedUserRole(selectedUserRole))
    },
    setSelectedOrg: selectedOrg => {
      dispatch(actions.setSelectedOrg(selectedOrg))
    },
    setLocale: locale => dispatch(setLocale(locale))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
