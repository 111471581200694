import * as React from 'react'
import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const LoadableMyApplicationsRoot = MultitenantLoadable(
  () => import('./MyApplicationsRoot'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/MyApplicationsRoot/MyApplicationsRoot'
    )
)

export default function MyApplicationsRoot(props) {
  return (
    <ErrorBoundary name="MyApplicationsRoot">
      <LoadableMyApplicationsRoot {...props} />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseLoadableMyApplicationsRoot = MultitenantLoadable(
  () => import('./MyApplicationsRoot'),
  () => Promise.resolve().then(() => null)
)

export function BaseMyApplicationsRoot(props) {
  return (
    <BaseErrorBoundary name="MyApplicationsRoot">
      <BaseLoadableMyApplicationsRoot {...props} />
    </BaseErrorBoundary>
  )
}
