import * as React from 'react'
import * as rjsfUtils from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/utils'
import {
  isNil,
  includes,
  intersection,
  keys,
  each,
  merge,
  isObject,
  mergeWith,
  forEach,
  isArray,
  startsWith,
  concat,
  isNull,
  isEqual,
  isEmpty,
  trim,
  omit,
  clone,
  debounce,
  get,
  noop,
  filter,
  mapValues,
  cloneDeep,
  uniq,
  indexOf,
  sortBy,
  xor
} from 'lodash'
import * as Intl from 'intl'
import 'intl/locale-data/jsonp/en-US'
const Loadable = require('react-loadable')
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
const moment = require('moment-timezone')
const xssFilters = require('xss-filters')
const _ = {
  isNil,
  includes,
  intersection,
  keys,
  each,
  merge,
  isObject,
  mergeWith,
  forEach,
  isArray,
  startsWith,
  concat,
  isNull,
  isEqual,
  isEmpty,
  trim,
  omit,
  clone,
  debounce,
  get,
  noop,
  filter,
  mapValues,
  cloneDeep
}
import { config } from './../../../../config'
export function getLogger(debugMode) {
  return debugMode ? console.log : function() {}
}

export function getServerTime() {
  try {
    let xmlHttp = new XMLHttpRequest()
    xmlHttp.open('HEAD', window.location.href.toString(), false)
    xmlHttp.setRequestHeader('Content-Type', 'text/html')
    xmlHttp.send('')
    return xmlHttp.getResponseHeader('Date')
  } catch (err) {
    return new Date()
  }
}

// TODO: Move to shared
export function timeout(ms, promise, errorMessage) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error(`TIMEOUT after ${ms} ms`))
    }, ms)
    promise.then(resolve, reject)
  })
}

export function UnauthorizedError(message) {
  this.message = message
  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(this)
  } else {
    const newError = new (Error as any)()
    this.stack = newError.stack
  }
}

export function NetworkConnectionError(message) {
  this.message = message
  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(this)
  } else {
    const newError = new (Error as any)()
    this.stack = newError.stack
  }
}
NetworkConnectionError.prototype = Object.create(Error.prototype)
NetworkConnectionError.prototype.name = 'NetworkConnectionError'
NetworkConnectionError.prototype.constructor = NetworkConnectionError

export function PathApplicationError(message) {
  this.message = message
  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(this)
  } else {
    const newError = new (Error as any)()
    this.stack = newError.stack
  }
}

interface FetcherHOC {
  (
    WrappedComponent: any,
    isDouble: boolean,
    initialData: any,
    createEndpoint: (props: any) => string,
    createRequest: (
      props: any
    ) => {
      method: string
      headers: object
      body?: string
    },
    responseMapper: (json: any, updateFunction?: any) => any,
    tenant: {
      id: number
      code: string
      name: string
      styles: {
        theme: string
      }
      fetcher: {
        retries: number
        timeoutMs: number
      }
    },
    fetchOnMount: boolean,
    shouldThrow?: boolean
  ): any
}
export let withFetcher: FetcherHOC
withFetcher = (
  WrappedComponent,
  isDouble,
  initialData,
  createEndpoint,
  createRequest,
  responseMapper,
  tenant,
  fetchOnMount,
  shouldThrow = true
) => {
  //Fetch statuses
  const INITIAL = 'INITIAL'
  const PENDING = 'PENDING'
  const PARTIAL = 'PARTIAL'
  const SUCCESS = 'SUCCESS'
  const FAILURE = 'FAILURE'

  return class extends React.Component<any, any> {
    constructor(props) {
      super(props)
      this.state = {
        data: initialData,
        status: INITIAL
      }
    }

    componentDidMount() {
      if (fetchOnMount) {
        this._loadData({ endpoint: null, request: null })
      }
    }

    _loadData = ({ endpoint, request }) => {
      this.setState({ status: PENDING })

      const fetchEndpoint = endpoint ? endpoint : createEndpoint(this.props)
      const fetchRequest = request ? request : createRequest(this.props)

      const retries = _.get(tenant, 'fetcher.retries') || _.noop()
      const timeoutMs = _.get(tenant, 'fetcher.timeoutMs') || _.noop()
      fetchJson(
        fetchEndpoint,
        fetchRequest,
        this.props.showErrorMessage,
        retries,
        timeoutMs
      )
        .then(json => {
          const data = responseMapper(json, this.props.updateMyOpenCases)
          if (!isDouble) {
            this.setState({ status: SUCCESS, data })
          } else {
            this.setState({ status: PARTIAL, data })
            setTimeout(() => {
              fetchJson(
                fetchEndpoint,
                fetchRequest,
                this.props.showErrorMessage,
                retries,
                timeoutMs
              )
                .then(json => {
                  const completeData = responseMapper(json)
                  this.setState({ status: SUCCESS, data: completeData })
                })
                .catch(ex => {
                  console.error(
                    WrappedComponent.name +
                      ' withFetcher failed on the 2nd call with ex',
                    ex
                  )
                  this.setState({ status: FAILURE })
                  if (shouldThrow) {
                    throw ex
                  }
                })
            }, 5000)
          }
        })
        .catch(ex => {
          this.props.logoutUser()
          console.error(
            WrappedComponent.name + ' withFetcher failed with ex',
            ex
          )
          this.setState({ status: FAILURE })
          if (shouldThrow) {
            throw ex
          }
        })
    }

    render() {
      const { data, status } = this.state
      const isLoading =
        (fetchOnMount && status === INITIAL) ||
        status === PENDING ||
        status === PARTIAL
      return (
        <WrappedComponent
          formData={data}
          isLoading={isLoading}
          fetchStatus={status}
          loadData={this._loadData}
          {...this.props}
        />
      )
    }
  }
}

PathApplicationError.prototype = Object.create(Error.prototype)
PathApplicationError.prototype.name = 'PathApplicationError'
PathApplicationError.prototype.constructor = PathApplicationError

export function InternalServerError(message) {
  this.message = message
  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(this)
  } else {
    const newError = new (Error as any)()
    this.stack = newError.stack
  }
}
InternalServerError.prototype = Object.create(Error.prototype)
InternalServerError.prototype.name = 'InternalServerError'
InternalServerError.prototype.constructor = InternalServerError

export function IEServiceError(message, errorList, businessCorrelationId) {
  this.message = message
  this.errorList = errorList
  this.businessCorrelationId = businessCorrelationId
  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(this)
  } else {
    const newError = new (Error as any)()
    this.stack = newError.stack
  }
}
IEServiceError.prototype = Object.create(Error.prototype)
IEServiceError.prototype.name = 'IEServiceError'
IEServiceError.prototype.constructor = IEServiceError

/**
 * Fetches from an endpoint and returns a Promise for the JSON data from the end point.
 * If maxRetries is not set, retries up to 3 times before throwing the error back to the caller.
 * If request isn't set, a simple GET request will be used.
 *
 * @param endpoint The URL of the endpoint to get data from.
 * @param request Request object for endpoint if needed for headers, http method, etc.  Optional. Defaults to a 'GET'.
 * @param retries The number of times to retry a failed fetch before throwing an error. Optional. Defaults to 3.
 * @param timeoutMs The timeout duration on the fetch call in milliseconds. Optional. Defaults to 10000.
 *
 * @throws Error on empty or undefined endpoint. Also throws the last error response from the endpoint when the retry limit is exceeded without success.
 */
interface FetchFunction {
  (
    endpoint: string,
    request?: {
      method?: any
      headers?: any
      body?: any
      mode?: any
    },
    errorMessage?: (message: string) => void,
    retries?: number,
    timeoutMs?: number
  ): Promise<any>
}

export function readStandardizedResponse(responseJson, errorMessage) {
  if (responseJson && responseJson.messageElements) {
    /*
      Structure of messageElements:

      "messageElements": {
        "description": "Base level header that contains other elements regarding the message structure",
        "properties": {
          "messageStatus": {
            "description": "Values: SUCCESS or FAILURE",
            "type": "string"
          },
          "businessCorrelationId": {
            "type": "string"
          },
          "errorList": {
            "type": "array",
            "items": {
              "type": "object",
              "properties": {
                "errorCode": {
                  "type": "string"
                },
                "errorDescription": {
                  "type": "string"
                }
              },
              "additionalProperties": false
            }
          }
        },
        "additionalProperties": false
      }
    */
    const { messageElements, tenant, data } = responseJson
    const { messageStatus, businessCorrelationId, errorList } = messageElements
    if (messageStatus !== 'SUCCESS') {
      errorMessage(
        'fetchJson received FAILURE response! ErrorList: ' +
          JSON.stringify(messageElements.errorList, null, 2),
        errorList,
        businessCorrelationId
      )
      throw new PathApplicationError(
        'fetchJson received FAILURE response! Error Code:' +
          messageElements.errorCode +
          'ErrorList: ' +
          JSON.stringify(messageElements.errorList, null, 2)
      )
    }

    if (data) {
      if (typeof data === 'string') {
        try {
          return JSON.parse(data)
        } catch (err) {
          return data
        }
      } else {
        return data
      }
    } else {
      return _.omit(responseJson, ['messageElements', 'tenant'])
    }
  } else if (responseJson && responseJson.error) {
    errorMessage(responseJson.error)
    return responseJson
  } else {
    return responseJson
  }
}

// TODO: Move to shared
export let fetchJson: FetchFunction
fetchJson = (
  endpoint,
  request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  },
  errorMessage,
  retries = 0,
  timeoutMs = 60000
) => {
  const toSafeJson = object => {
    switch (typeof object) {
      case 'string':
        let _data = null
        try {
          return JSON.parse(xssFilters.inHTMLData(object))
        } catch (error) {
          _data = xssFilters.inHTMLData(object)
        }
        return _data
      case 'object':
        return JSON.parse(xssFilters.inHTMLData(JSON.stringify(object)))
      default:
        return null
    }
  }
  let newRequest
  let responseCaught = false
  if (request && request.body && typeof request.body === 'string') {
    newRequest = {
      ...request,
      body: xssFilters.inHTMLData(request.body)
    }
  } else {
    newRequest = request
  }
  errorMessage('Service Calling')
  return timeout(timeoutMs, fetch(endpoint, newRequest), errorMessage)
    .then((response: Response) => {
      if (response.ok) {
        responseCaught = true
        errorMessage('Service Success')
        return response
          .json()
          .then(response => toSafeJson(response))
          .then(responseJson =>
            readStandardizedResponse(responseJson, errorMessage)
          )
      } else {
        responseCaught = true
        const doThrow = error => {
          if (response.status === 401) {
            throw new UnauthorizedError(`User is unauthorized (401 error).`)
          } else if (error && error instanceof IEServiceError) {
            errorMessage(
              'The following ' +
                endpoint +
                ' failed with status' +
                response.status
            )
            throw error
          } else if (response.status >= 400 && response.status < 500) {
            throw new NetworkConnectionError(
              `Failed with status: ${response.status}. statusText: ${response.statusText}.`
            )
          } else if (response.status === 500) {
            responseCaught = false
            if (retries < 1) {
              responseCaught = true
            }
            throw new InternalServerError(
              `Failed with status: ${response.status}. statusText: ${response.statusText}.`
            )
          } else if (response.status > 500 && response.status < 600) {
            throw new InternalServerError(
              `Failed with status: ${response.status}. statusText: ${response.statusText}.`
            )
          } else {
            if (response.status > 600) {
              throw new PathApplicationError(
                `Failed with status: ${response.status}. statusText: ${response.statusText}.`
              )
            } else {
              errorMessage(
                `Failed with status: ${response.status}. statusText: ${response.statusText}.`
              )
              throw new Error(
                `Failed with status: ${response.status}. statusText: ${response.statusText}.`
              )
            }
          }
        }
        return response
          .json()
          .then(response => toSafeJson(response))
          .then(responseJson =>
            readStandardizedResponse(responseJson, errorMessage)
          )
          .then(() => doThrow(null))
          .catch(error => doThrow(error))
      }
    })
    .catch(error => {
      if (retries < 1 || newRequest.method === 'POST') {
        //Do not retry POST calls as it can create duplicate data.
        errorMessage(
          'fetchJson failed at endpoint ' +
            endpoint +
            ' with request:' +
            newRequest +
            ' due to error: ' +
            error.message
        )
        console.error(
          'fetchJson failed at endpoint ' + endpoint + ' with request:',
          newRequest,
          ' due to error:',
          error
        )
        throw error
      } else {
        console.warn(
          'fetchJson failed at endpoint ' +
            endpoint +
            ' (' +
            retries +
            ' tries left) with request:',
          newRequest,
          ' due to error:',
          error
        )
      }
      if (!responseCaught) {
        return fetchJson(endpoint, newRequest, errorMessage, retries - 1)
      }
    })
}
// TODO: Move to shared

export function orderErrors(errors, masterSchema, uiSchema): any[] {
  const orderArray = uniq(buildOrderArray(masterSchema))

  const indexRegex = new RegExp(/_([0-9]+)_/)
  const errorKeyMsgRegex = new RegExp(/^([a-zA-Z0-9]+): (.*)/)

  const newErrorStackQueue = []
  errors.map(error => {
    const errorResult = error.stack.match(errorKeyMsgRegex)
    const errorKey = errorResult[1]
    let errorContentsObj
    try {
      errorContentsObj = JSON.parse(errorResult[2])
    } catch (err) {
      errorContentsObj = {}
    }
    const step = errorContentsObj.step
    const index = _.get(_.get(errorContentsObj, 'step', ''), '1', '0')

    if (newErrorStackQueue[index] === undefined) {
      for (let i = 0; i <= index; i++) {
        if (newErrorStackQueue[i] === undefined) {
          newErrorStackQueue.push([])
        }
      }
    }

    newErrorStackQueue[index].push({
      index: indexOf(orderArray, errorKey),
      value: errorKey,
      stack: error.stack,
      step: step
    })
  })

  let tempErrorObjList = []
  for (let i = 0; i < newErrorStackQueue.length; i++) {
    tempErrorObjList = _.concat(
      tempErrorObjList,
      sortBy(newErrorStackQueue[i], ['step'])
    )
  }

  const orderedErrors = _.forEach(tempErrorObjList, obj => obj.stack)

  return orderedErrors
}

function buildOrderArray(localSchema, orderArray = []) {
  switch (localSchema['type']) {
    case 'object':
      buildOrderArray(localSchema['properties'], orderArray)
      break
    case 'array':
      buildOrderArray(localSchema['items'], orderArray)
      break
    case undefined:
      if (_.isObject(localSchema)) {
        _.forEach(localSchema, (v, k) => {
          switch (v['type']) {
            case 'object':
              if (
                _.isEmpty(
                  xor(_.keys(v['properties']), [
                    'category',
                    'subCategory',
                    'languageCode',
                    'key',
                    'value',
                    'rulesEngineCode',
                    'sortOrder'
                  ])
                )
              ) {
                orderArray.push(k)
                break
              }
              buildOrderArray(v['properties'], orderArray)
              break
            case 'array':
              if (
                _.isEmpty(
                  xor(_.keys(_.get(v, 'items.properties')), [
                    'category',
                    'subCategory',
                    'languageCode',
                    'key',
                    'value',
                    'rulesEngineCode',
                    'sortOrder'
                  ])
                ) ||
                _.get(v, 'items.enumNames', []).length > 0
              ) {
                orderArray.push(k)
                break
              }
              buildOrderArray(v['items'], orderArray)
              break
            default:
              orderArray.push(k)
          }
        })
      } else {
        console.log('buildOrderArray localSchema not object', localSchema)
      }
  }
  return orderArray
}
