import * as React from 'react'
import { Modal } from 'react-bootstrap'

export default class ConfirmModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      modalOpen: true
    }

    this._closeModal = this._closeModal.bind(this)
    this._openModal = this._openModal.bind(this)
  }

  _closeModal = () => {
    this.props.modalWasClosed()
    this.setState({ modalOpen: false })
  }

  _openModal = () => {
    this.setState({ modalOpen: true })
  }

  render() {
    const { modalOpen } = this.state
    if (this.props.retro) {
      return (
        <div className="static-modal">
          <Modal show={this.props.open} onHide={() => this._closeModal()}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="glyphicon myglyphicon-check" /> Retroactive
                request not submitted
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="row">
                <div className="col-lg-12">
                  Clients may request retroactive healthcare coverage for
                  medical bills up to 90 days prior to application date. If you
                  wish to request for retroactive healthcare coverage, please
                  contact your agency worker as soon as possible.
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
    } else {
      return (
        <div className="static-modal">
          <Modal show={this.props.open} onHide={() => this._closeModal()}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="glyphicon myglyphicon-check" /> Application Not
                Withdrawn
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="row">
                <div className="col-lg-12">
                  If you wish to withdraw from a program or programs, please
                  contact your agent as soon as possible.
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
    }
  }
}
