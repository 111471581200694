import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash'
import { config } from '../../../config'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
// import moment from 'moment/src/moment'
import { UnauthorizedError, getServerTime } from '../src/components/utils'

const formsEndpoint = config['formsBaseUrl'] + config['forms_QuickReports']
const applicationReportsEndPoint =
  config['gatewayWvUrl'] + config['myQuickReportSearch']

const baseUrl = process.env.PUBLIC_URL

interface Org {
  roleId: number
  roleName: string
  orgId: number
  orgName: string
  roleorgId: number
  dfltflg: string
}

interface ReportsContainerProps {
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  userAccess: {
    selectedOrg: Org
  }
  userRoleId: number
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  logoutUser
  history: {
    push: (url: string) => void
  }
}

interface ReportsContainerState {
  currentPage: number
  form: {
    schema: Object
    uiSchema: Object
  }
  application: any
  formContext: any
  mainContainer: boolean
  reportType: string
  searchResults: any
  showResultsTable: boolean
  uuid: string
  role: number
  errorDisplay: boolean
  errorFrom: boolean
  errorTo: boolean
  reviewApplication: boolean
  currentDate: any
}

class MyQuickReportsContainer extends React.Component<
  ReportsContainerProps,
  ReportsContainerState
> {
  constructor(props: ReportsContainerProps) {
    super(props)

    this.state = {
      currentPage: 1,
      form: null,
      application: {},
      formContext: {},
      mainContainer: true,
      reportType: null,
      searchResults: {},
      showResultsTable: false,
      uuid: props.auth.userAccount.uuid,
      role: props.userRoleId,
      errorDisplay: false,
      errorFrom: false,
      errorTo: false,
      reviewApplication: false,
      currentDate: getServerTime()
    }
  }

  componentDidMount() {
    this._initializeForm()
  }

  _pageChangeDetected = currentPage => {
    this.setState({
      currentPage
    })
  }

  _navigateApplicationReports = e => {
    this.setState({
      mainContainer: !this.state.mainContainer,
      reportType: e.target.innerHTML
    })
  }

  _resetForm = () => {
    let { formContext } = this.state
    let formData = _.cloneDeep(formContext.formData)
    formData.quickReports.applicationStatus = _.noop()
    formData.quickReports.geographical = _.noop()
    formData.quickReports.applicationFromDate = _.noop()
    formData.quickReports.applicationToDate = _.noop()

    formContext.formData = formData
    this.setState({
      formContext,
      errorDisplay: false,
      showResultsTable: false,
      errorFrom: false,
      errorTo: false
    })
  }

  static getDerivedStateFromProps(
    props: ReportsContainerProps,
    state: ReportsContainerState
  ) {
    return {
      formContext: {
        ...state.formContext,
        formData: props.formData || state.formContext.formData
      }
    }
  }

  _initializeForm = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(form => {
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            formContext: {
              ...prevState.formContext,
              forms: [{ ...form }],
              refs: form.app.metaData.refs
            }
          }
        })
      })
      .catch(error => {
        console.error('user acces form error', error)
      })
  }

  _onFormDataChange = ({ formData }) => {
    let { formContext } = this.state
    formContext.formData = formData
    this.setState({
      formContext
    })
  }

  _search = () => {
    try {
      if (this.state.formContext.formData) {
        let applicationReports = _.cloneDeep(
          this.state.formContext.formData.quickReports
        )
        let currentdate = _moment(config, this.state.currentDate).format(
          'YYYY-MM-DD'
        )
        let pastDate = _moment(config, this.state.currentDate)
          .subtract('years', 3)
          .format('YYYY-MM-DD')
        if (applicationReports.applicationFromDate === undefined) {
          applicationReports.applicationFromDate = pastDate
        }
        if (applicationReports.applicationToDate === undefined) {
          applicationReports.applicationToDate = currentdate
        }
        if (applicationReports.applicationToDate !== undefined) {
          if (
            applicationReports.applicationToDate < pastDate ||
            applicationReports.applicationToDate > currentdate
          ) {
            this.setState({ errorTo: true })
          } else {
            this.setState({ errorTo: false })
          }
        } else {
          this.setState({ errorTo: false })
        }
        if (applicationReports.applicationFromDate !== undefined) {
          if (
            applicationReports.applicationFromDate < pastDate ||
            applicationReports.applicationFromDate > currentdate
          ) {
            this.setState({ errorFrom: true })
          } else if (
            applicationReports.applicationFromDate >
            applicationReports.applicationToDate
          ) {
            this.setState({ errorDisplay: true })
          } else {
            this.setState({ errorFrom: false })
            this.setState({ errorDisplay: false })
          }
        } else {
          this.setState({ errorFrom: false })
          this.setState({ errorDisplay: false })
        }

        if (applicationReports.reviewApplication === 'Y')
          this.setState({
            reviewApplication: true
          })
        else
          this.setState({
            reviewApplication: false
          })

        serviceHelper
          .fetchJson(
            applicationReportsEndPoint,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                uuid: this.props.auth.userAccount.uuid,
                tenantCode: config.tCode,
                Authorization: config.bearer + this.props.auth.accessToken,
                portalName: config.portalName,
                orgId: this.props.userAccess.selectedOrg.orgId.toString(),
                roleId: this.props.userAccess.selectedOrg.roleId.toString()
              },
              body: JSON.stringify({
                applicationReports
              })
            },
            this.props.showErrorMessage
          )
          .then(data => {
            this.setState({
              showResultsTable: true,
              searchResults: data
            })
          })
      }
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        this.props.logoutUser()
        this.props.history.push(baseUrl + '/home')
      }
      console.log('Application-Report Search error', e)
    }
  }

  _showBackResultsTable = () =>
    this.setState({
      showResultsTable: false
    })
  _hasKeyedAny = (): boolean => {
    if (
      this.state.formContext &&
      this.state.formContext.formData &&
      this.state.formContext.formData.quickReports
    ) {
      const se = this.state.formContext.formData.quickReports
      let hasKeyedAny = false
      if (
        !_.isNil(se.geographical) ||
        !_.isNil(se.applicationStatus) ||
        !_.isNil(se.applicationFromDate) ||
        !_.isNil(se.applicationToDate)
      ) {
        hasKeyedAny = true
      }
      return hasKeyedAny
    }
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    const { presentation, isLoading, minHeight, errorMessage } = this.props
    const {
      form,
      formContext,
      mainContainer,
      showResultsTable,
      searchResults,
      reportType,
      currentPage
    } = this.state
    let currentdate = _moment(config, this.state.currentDate).format(
      'YYYY-MM-DD'
    )
    let pastdate = _moment(config, this.state.currentDate)
      .subtract('years', 3)
      .format('YYYY-MM-DD')

    const newFormContext = {
      ...formContext,
      currentDate: currentdate,
      pastDate: pastdate,
      hasKeyedAny: this._hasKeyedAny
    }
    const presentationProps = {
      form,
      formContext: newFormContext,
      onFormDataChange: this._onFormDataChange.bind(this),
      isLoading,
      minHeight,
      mainContainer,
      navigateApplicationReports: this._navigateApplicationReports.bind(this),
      searchResults,
      showBackResultsTable: this._showBackResultsTable.bind(this),
      reset: this._resetForm.bind(this),
      reportType,
      search: this._search.bind(this),
      showResultsTable,
      pageChangeDetected: this._pageChangeDetected,
      currentPage,
      hasKeyedAny: this._hasKeyedAny,
      errorDisplay: this.state.errorDisplay,
      errorFrom: this.state.errorFrom,
      errorTo: this.state.errorTo,
      onDeleteClick: this._onDeleteClick,
      errorMessage,
      reviewApplication: this.state.reviewApplication
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  return {
    auth: state.auth,
    userRoleId,
    userAccess: state.userAccess,
    errorMessage
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyQuickReportsContainer)
