import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import * as queryString from 'query-string'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import {
  _moment,
  getSiblingFormData
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import { Redirect } from 'react-router-dom'
import { config } from '~/config'
import { refs } from '@optum-wvie/dynamic-ui-framework/src/refs'
import * as validator from '@optum-wvie/dynamic-ui-framework/src/validation'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import * as actions from '../../../actions'
import { customValidationErrors } from './ClientUpdateWizardValidation'
import * as validation from '../src/validationChildCare'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
import { I18n } from 'react-redux-i18n'
const getCaseBenefitDetails = config['getCaseBenefitDetailsCP']
const getOrgByService = config['getOrgByServiceCP']
import { clientUpdateWizardRefs } from './refs'
// import * as renewalWizardForms from './__mockForms__/RenewalWizard.json'
// import * as lifeEventsForms from './__mockForms__/LifeEventsWizard.json'
// import * as appIntakeSchemaa from './__mockForms__/AppIntakeSchema.json'
import { v4 } from 'uuid'
import { Modal } from 'react-bootstrap'

declare const process
const baseUrl = process.env.PUBLIC_URL
const gatewayWvUrl = config['gatewayWvUrl']
const formsBaseUrl = config['formsBaseUrl']
const getCountries = config['getCountriesCP']
const loadUserEndpoint = config['getUserAccountDetails']
const getCocData = config['getCOCEndpointCP']
const getRenewalData = config['getRenewalEndpointCP']
const submitCocRenewalEndpoint = config['submitCocRenewalEndpointCP']
const appIntakeSchemaEndpoint = config['forms_AppIntakeSchemaCP']
const saveDraftEndpoint = gatewayWvUrl + config['saveCCAApplication']
const loadDraftEndpoint = config['getDraftAppCP']

//Endpoints needed for Renewal mode
const renewalWizardFormsEndpoint = config['forms_RenewalWizardCP']

//Endpoints needed for Life Events (COC) mode
const lifeEventsFormsEndpoint = config['forms_LifeEventsWizardCP']
const validationMsgsEndpoint = config['validationErrorMsgs_AppIntakeSchema']
const pristineIcon = ''

const editingIcon = 'glyphicon myglyphicon-pencil'
const editingBadge = 'badge bg-white-alt'

const validatedIcon = 'glyphicon myglyphicon-ok'
const validatedBadge = 'badge bg-green-alt'

const errorIcon = 'glyphicon myglyphicon-circle_exclamation_mark'
const errorBadge = 'badge bg-yellow-alt'
let addedNewApplicant: boolean = false
let newApplicantIndex: any
let initClientsLength: any
//This is for step-specific logic to not assume the step number.  Keep this updated.
const STEPS = {
  GENERAL_INFORMATION: 1,
  PERSONAL: 2,
  ADDITIONAL_INFORMATION: 3,
  INCOME: 4,
  EXPENSES: 5,
  ASSETS: 6,
  DOCUMENT_UPLOAD: 7,
  REVIEW_AND_SIGN: 8
}

const HELPSTEPS = {
  1: 'Household_Information.htm',
  2: 'Personal.htm',
  3: 'Additional_Information.htm',
  4: 'Income.htm',
  5: 'Expenses.htm',
  6: 'Assets.htm',
  7: 'DOCUMENT_UPLOAD.htm',
  8: 'Review_%26_Sign.htm'
}

const HELPSTEPSRENEWAL = {
  1: 'Renewal_Information.htm',
  2: 'Personal.htm',
  3: 'Additional_Information.htm',
  4: 'Income.htm',
  5: 'Expenses.htm',
  6: 'Assets.htm',
  8: 'Review_%26_Sign.htm'
}

const unearnedIncomeTypes = [
  'INUNAD39594',
  'INUNAN14347',
  'INUNBL78991',
  'INUNCH55757',
  'INUNCH37672',
  'INUNDI62908',
  'INUNDI10223',
  'INUNFA90001',
  'INUNFO41422',
  'INUNGA43821',
  'INUNHO54991',
  'INUNIN55991',
  'INUNMI65820',
  'INUNMO33191',
  'INUNNO11105',
  'INUNPA30286',
  'INUNRA44970',
  'INUNNO99830',
  'INUMRO19327',
  'INUNSO83280',
  'INUNSU61671',
  'INUNAS82467',
  'INUNTR59643',
  'INUNTR84327',
  'INUNUN71331',
  'INUNUM23099',
  'INUNVE13247',
  'INUNWO40709',
  'INUNOT31718',
  'INUNOT28650',
  'INUNMA90489',
  'INUNCH94663',
  'INUNCO53582',
  'INUNDE11879',
  'INUNDI10223',
  'INUNFA52679',
  'INUNHO54991',
  'INUNSI70604',
  'INUNSI52737',
  'INUNOT67517',
  'INUNOT23099',
  'INUNUM91451',
  'INUMRE18897',
  null,
  undefined
]

const lumpSumPaymentTypes = [
  'ASLUCH84340',
  'ASLUEX24090',
  'ASLUCH96436',
  'ASLUDI09449',
  'ASLUGA28963',
  'ASLUHO34815',
  'ASLUHU23901',
  'ASLUIN45009',
  'ASLUIN97795',
  'ASLUIN64363',
  'ASLULA16384',
  'ASLUPE08764',
  'ASLUSS57648',
  'ASLUTR64751',
  'ASLUUN88758',
  'ASLUUN63506',
  'ASLUVA12904',
  'ASLUVE07456',
  'ASLUWO03270',
  'ASLUOT40105',
  null,
  undefined
]

export function getStepBySection(name) {
  switch (name) {
    case 'clientDetails':
      return STEPS.PERSONAL
    case 'clientAdditionalInformation':
      return STEPS.ADDITIONAL_INFORMATION
    case 'clientIncomes':
      return STEPS.INCOME
    case 'clientExpenses':
      return STEPS.EXPENSES
    case 'clientAssets':
      return STEPS.ASSETS
  }
  return null
}

interface MyApplicationAction {
  applId: string
  status: string
  applicationData: Object
}

interface ProgramRenewal {
  renRecvDt: string
  renSrc: string
  renewalSignedandDated: string
  allQuestionsAnswered: string
  interviewCompleted: string
}

interface ClientUpdateWizardContainerProps {
  isRenewal: boolean
  presentation: any
  location: {
    search: string
    pathname: string
  }
  caseId?: number
  reviewIndicator: any
  renewalStatus: any
  status: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  orgId: string
  userRoleId: string
  roleId: any
  roleName: string
  isUnitTest: boolean
  selectedEntitlements: Array<string>
  step: number
  renewalDate: any
  activeApplicationId: string
  myApplicationData: any
  addMyApplication: (...MyApplicationAction) => void
  removeMyApplication: (applId: string) => void
  updateMyApplication: (...MyApplicationAction) => void
  setActiveApplicationId: (applId: string) => void
  assignApplicationId: (applId: string) => void
  updateMyCocApplicationStep: (step: number) => void
  updateMyApplicationTabStatus: (applId: string, tabStatus: any) => void
  errorMessage: Array<any>
  deleteErrorMessage: () => void
  showErrorMessage: (message) => void
  updatedFormData: (formContext: Array<any>) => void
  updatedCOCFormData: any
  ieOrgId: any
  logoutUser
  locale: string
  states: any
  counties: any
  myOpencases
}

interface ClientUpdateWizardContainerState {
  alertModalFlag: boolean
  programCode: any
  applId: string
  caseId: number
  reviewIndicator: string
  status: string
  renewalStatus: string
  benefitProgramName: any
  benefitProgramCode: any
  programClientMapping: any
  allPrograms: any
  allProgramCodes: any
  prgNames: any
  step: number
  count: number
  viewId: string
  showDraftModal: boolean
  showNonDraftModal: boolean
  hasSpecialNeeds?: boolean
  tabs: {
    status: string
    leftIcon: string
    rightIcon: string
    rightSpan: string
    isVisible: boolean
    title: string
  }[]
  forms: {
    schema: Object
    uiSchema: any
    metaData: Object
    myDocsSchema: any
  }[]
  masterUiSchema: Object
  resolvedSchema: Object
  formData: any
  oldFormData: any
  formContext: any
  callingApi: boolean
  liveValidate: boolean
  trackerForms: Object
  redirect: string
  modalProps: {
    show: boolean
    cancel: () => void
    confirm: () => void
    title: React.ReactNode
    body: React.ReactNode
    bsSize?: string
    confirmBtnText: string
    closeBtnText: string
    className?: string
  }
  programRenewals: Array<ProgramRenewal>
  caseInfo: any
  showNext: boolean
  errorTitle: any
  slides: any
  slideIndex: any
  tabFocus: boolean
  renewalDate: any
  rnwlDate: string
  reneCovInd: number
  currentdate: any
  errorJson: Array<string>
  showDomesticViolenceModal: boolean
  showRenewalMessage
}
class ClientUpdateWizardContainer extends React.Component<
  ClientUpdateWizardContainerProps,
  ClientUpdateWizardContainerState
> {
  private hasError = false

  constructor(props: any) {
    super(props)
    document.addEventListener('keydown', this.onFocus)
    const caseId = this._determineCaseId()[0]
    const reviewIndicator = this._determineCaseId()[1]
    const status = this._determineCaseId()[2]
    const renewalStatus = this._determineCaseId()[3]
    const prgNames = this._determineCaseId()[5]
    const renewalDate = this.getQueryParamFromUrl('renewalDate')
    let redirect = null
    const renewByDate = this.getQueryParamFromUrl('renewByDate')
    const programNames = this.getQueryParamFromUrl('prgNames')
    const programCode = this.getQueryParamFromUrl('programCode')
    const programNamesDecoded = decodeURI(programNames)
    const programCodeDecoded = decodeURI(programCode)
    const allPrograms = programNamesDecoded.split(',')
    const allProgramCodes = programCodeDecoded.split(',')
    this.state = {
      alertModalFlag: false,
      applId: null,
      caseId,
      reviewIndicator,
      status,
      renewalStatus,
      benefitProgramName: this.getQueryParamFromUrl('benefitProgramName'),
      benefitProgramCode: this.getQueryParamFromUrl('programCode'),
      showRenewalMessage: this.getQueryParamFromUrl('showRenewalMessage'),
      programClientMapping: null,
      allPrograms: allPrograms,
      allProgramCodes: allProgramCodes,
      prgNames: '',
      step: 1,
      count: 0,
      viewId: null,
      showDraftModal: false,
      showNonDraftModal: false,
      tabs: null,
      forms: null,
      masterUiSchema: null,
      resolvedSchema: null,
      formData: null,
      oldFormData: null,
      formContext: {
        states: this.props.states,
        counties: this.props.counties,
        renewByDate
      },
      callingApi: false,
      liveValidate: false,
      trackerForms: {},
      redirect,
      reneCovInd: null,
      modalProps: {
        show: false,
        cancel: null,
        confirm: null,
        title: null,
        body: null,
        bsSize: 'lg',
        className: 'lg-width-to-md',
        closeBtnText: null,
        confirmBtnText: null
      },
      programRenewals: null,
      caseInfo: null,
      showNext: false,
      slideIndex: 0,
      slides: [],
      errorTitle: [],
      programCode: [],
      tabFocus: false,
      renewalDate,
      rnwlDate: null,
      currentdate: getServerTime(),
      errorJson: [],
      showDomesticViolenceModal: false
    }
    this._getStep = this._getStep.bind(this)
    this._exitDomesticViolenceModal = this._exitDomesticViolenceModal.bind(this)
  }

  getQueryParamFromUrl(field) {
    let url = window.location.href
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i')
    var string = reg.exec(url)

    return string ? decodeURIComponent(string[1]) : null
  }

  _setReneCovInd(value) {
    this.setState({
      reneCovInd: value
    })
  }

  componentDidMount() {
    let applId = this.props.activeApplicationId
    if (this.props.location && this.props.location.search) {
      try {
        const query = queryString.parse(this.props.location.search)
        if (applId !== query.applId) {
          applId = query.applId
          this.props.setActiveApplicationId(applId)
          this.setState({
            callingApi: false,
            applId: applId
          })
          this.setState(prevState => {
            return {
              formContext: {
                ...prevState.formContext,
                checkHasPin: true,
                currentDate: this.state.currentdate
              }
            }
          })
        }
      } catch (err) {
        console.error('Error on handling applId from URL query', err)
      }
    }

    this._initializeForms()
    this._getFormData()
    this._determineCaseId()
  }

  componentDidUpdate(prevProps, prevState) {
    const { viewId, count, step, tabFocus } = this.state
    const appTitle = this.props.isRenewal
      ? I18n.t('ClientUpdateWizard.benefitsApplicationRenewal')
      : I18n.t('ClientUpdateWizard.lifeEventUpdate')
    switch (this.state.step) {
      case 1:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          I18n.t('ClientUpdateWizard.tabHouseholdInfo') +
          appTitle
        break
      case 2:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          I18n.t('ClientUpdateWizard.tabPersonal') +
          appTitle
        break
      case 3:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          I18n.t('ClientUpdateWizard.tabAdditionalInfo') +
          appTitle
        break
      case 4:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          I18n.t('ClientUpdateWizard.tabIncome') +
          appTitle
        break
      case 5:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          I18n.t('ClientUpdateWizard.tabExpenses') +
          appTitle
        break
      case 6:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          I18n.t('ClientUpdateWizard.tabAssets') +
          appTitle
        break
      case 8:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          I18n.t('ClientUpdateWizard.tabReviewSign') +
          appTitle
        break
    }
    if (viewId != null) {
      //Have to do this since componentDidUpdate runs before real DOM is updated.
      try {
        setTimeout(function() {
          window.requestAnimationFrame(function() {
            let offset = helper.getOffsetTop(document.getElementById(viewId))
            const element = document.getElementById(viewId)
            element.focus()
            window.scroll(0, offset - 100)
          })
        }, 1000)
      } catch (e) {
        console.error('ClientUpdateWizard viewId setTimeout failed with ex', e)
      }
      //TODO: expand accordions if they contain the element.

      this.setState({ viewId: null })
    }

    if (!_.isEqual(prevProps.userRoleId, this.props.userRoleId)) {
      //The user switched their role! Redirect back to home.
      //TODO: global config for target landing page by role/entitlement?
      if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
        )
      ) {
        this.setState({ redirect: baseUrl + '/myaccount/dashboard' })
      } else if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.COMMUNITY_PARTNERSHIP
        )
      ) {
        this.setState({ redirect: baseUrl + '/myaccount/dashboard' })
      } else {
        this.setState({ redirect: baseUrl + '/home' })
      }
    }

    if (count < 1) {
      this.updateStep(prevProps)
      this.setState({ count: count + 1 })
    }

    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      this._initializeForms()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onFocus)
  }

  updateStep(prevProps) {
    this.setState({ step: prevProps.step })
  }

  _determineCaseId = () => {
    let caseId = 0
    let reviewIndicator
    let status
    let renewalStatus
    let prgNames
    if (this.props.location && this.props.location.search) {
      try {
        const query = queryString.parse(this.props.location.search)
        reviewIndicator = query.reviewIndicator
        status = query.status
        switch (query.renewalStatus) {
          case 'Not Started': {
            renewalStatus = 'Renewal Pending'
            break
          }
          case 'Pending': {
            renewalStatus = 'Renewal in Progress'
            break
          }
          case 'Submitted': {
            renewalStatus = 'Submitted'
            break
          }
        }
        prgNames = query.prgNames
        this.setState(prevState => {
          return {
            ...prevState,
            reviewIndicator: reviewIndicator,
            status: status,
            renewalStatus: renewalStatus,
            prgNames: prgNames,
            formContext: {
              ...prevState.formContext,
              benefitProgramName: prevState.benefitProgramName,
              benefitProgramCode: prevState.benefitProgramCode,
              prgNames: prgNames
            }
          }
        })
        if (query && query.caseId) {
          caseId = parseInt(query.caseId, 10)
        } else if (this.props.caseId) {
          caseId = this.props.caseId
        }
      } catch (err) {
        console.error('Error on parsing caseId from URL query', err)
      }
    } else if (this.props.caseId) {
      caseId = this.props.caseId
    }
    return [caseId, reviewIndicator, status, renewalStatus, prgNames]
  }

  _getFormData() {
    const endpoint = loadUserEndpoint
    serviceHelper
      .fetchJson(
        endpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tenantCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            userRoleId: this.props.userRoleId
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        this.setState(prevState => {
          return {
            formContext: {
              ...prevState.formContext,
              clientBthDt: json.userBthDt,
              clientFstNm: json.userFstNm,
              clientLstNm: json.userLstNm,
              clientUserId: json.userId
            }
          }
        })
      })
      .catch(this.onUnAuthorizedError)
  }

  onUnAuthorizedError = error => {
    if (error instanceof UnauthorizedError) {
      this.props.logoutUser()
      this.setState({ redirect: baseUrl + '/home' })
    }
    this.setState({ callingApi: false })
    console.error('Failed with Error', error)
  }

  _getBenefitsArray(benefitDetails) {
    let benefitsArray = []
    benefitDetails.forEach(benefit => {
      benefit.receivingBenefits.forEach(receivingBenefit => {
        let found = _.find(benefitsArray, b => {
          return b.clientId == receivingBenefit.clientId
        })
        if (found) {
          found.program.push(benefit.benefitProgramName)
        } else {
          let current = {
            ...receivingBenefit,
            program: [benefit.benefitProgramName]
          }
          benefitsArray.push(current)
        }
      })
    })
    return benefitsArray
  }
  _initializeForms = () => {
    const getOrgByServiceEndpoint = getOrgByService.replace(
      '{type}',
      'electric'
    )
    const formsEndpoint = this.props.isRenewal
      ? renewalWizardFormsEndpoint
      : lifeEventsFormsEndpoint
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tenantCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    const requestGetOrgByService = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount
          ? this.props.auth.userAccount.uuid
          : '',
        tenantCode: config.tenantCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase(),
        orgType: 'ORORUT12278'
      }
    }

    Promise.all([
      helper.fetchJson(formsEndpoint.replace('{version}', '1.0'), request),
      helper.fetchJson(
        appIntakeSchemaEndpoint.replace('{version}', '1.0'),
        request
      ),
      helper.fetchJson(
        validationMsgsEndpoint.replace('{version}', '1.0'),
        request
      ),
      helper.fetchJson(getCountries, request),
      helper.fetchJson(getCaseBenefitDetails + '?history=N', request),
      helper.fetchJson(getOrgByServiceEndpoint, requestGetOrgByService)
    ])
      .then(responses => {
        const clientUpdateForms = responses[0]
        const appIntakeSchema = responses[1]
        const errorJson = responses[2]
        const countries = responses[3]
        const countryEnums = countries.map(country => country.countryCode)
        const countryEnumNames = countries.map(country => country.countryName)
        let orgNames = responses[5]
        orgNames.push({
          orgId: 'Other',
          orgNm: 'Other'
        })
        const orgNameEnums = orgNames.map(org => org.orgId)
        const orgNameEnumsNames = orgNames.map(org => org.orgNm)

        const benefitDetails = _.filter(
          _.flattenDeep(responses[4]),
          benefit => {
            return benefit.caseNumber == this.state.caseId
          }
        )
        let benefitsArray = this._getBenefitsArray(benefitDetails)
        if (!this.props.isRenewal) {
          _.set(
            clientUpdateForms,
            'app.forms[0].schema.properties.application.properties.closureDetails.maxItems',
            benefitsArray.length
          )
        }

        let resolvedSchema = helper.mergeAllOfSchemas(
          appIntakeSchema,
          appIntakeSchema['definitions']
        )
        resolvedSchema = helper.resolveSchemaDefinitions(
          resolvedSchema,
          resolvedSchema['definitions']
        )
        delete resolvedSchema['definitions']

        const forms = []
        let masterUiSchema = {}
        _.forEach(clientUpdateForms['app']['forms'], (value, index) => {
          if (_.isEmpty(value['schema'])) {
            forms.push({
              schema: helper.schemaIntersect(resolvedSchema, value['uiSchema']),
              uiSchema: value['uiSchema']
            })
          } else {
            forms.push({
              schema: value['schema'],
              uiSchema: value['uiSchema']
            })
          }
          masterUiSchema = _.merge(masterUiSchema, value['uiSchema'])
        })

        let date = _moment(config, this.state.currentdate).format('MM/DD/YYYY')
        let clientBthDt = this.state.formContext.clientBthDt
        let clientFstNm = this.state.formContext.clientFstNm
        let clientLstNm = this.state.formContext.clientLstNm
        let clientUserId = this.state.formContext.clientUserId
        let tenantId = this.state.formContext.tenantId
        let benefitProgramName =
          this.state.formContext.benefitProgramName != undefined
            ? this.state.formContext.benefitProgramName
            : this.state.benefitProgramName
        let benefitProgramCode =
          this.state.formContext.benefitProgramCode != undefined
            ? this.state.formContext.benefitProgramCode
            : this.state.benefitProgramCode
        let prgNames = this.state.formContext.prgNames

        helper.cleanNullValues(forms)

        const countyList = this.props.counties

        const countyJson = {}
        _.forEach(countyList, (county, idx) => {
          if (!countyJson[county.stateCode]) {
            countyJson[county.stateCode] = []
          }
          countyJson[county.stateCode].push(county.countyName)
        })
        let states = _.get(this.props, 'states', [])
        let stateEnum = []
        let stateEnumNames = []
        for (let i = 0; i < states.length; i++) {
          stateEnum.push(states[i].stateCode)
          stateEnumNames.push(states[i].stateName)
        }

        this.setState(
          {
            forms,
            errorJson,
            masterUiSchema,
            resolvedSchema,
            formContext: {
              ...this.state.formContext,
              stateEnum,
              stateEnumNames,
              countryEnums,
              countryEnumNames,
              orgNameEnums,
              orgNameEnumsNames,
              benefitsArray,
              STEPS,
              HELPSTEPS,
              HELPSTEPSRENEWAL,
              forms,
              reviewForms: _.cloneDeep(forms),
              formData: null,
              clientBthDt,
              clientFstNm,
              clientLstNm,
              clientUserId,
              tenantId,
              benefitProgramName,
              benefitProgramCode,
              prgNames,
              subscribeTrackerMode: this._subscribeTrackerForm,
              unsubscribeTrackerMode: this._unsubscribeTrackerForm,
              panelEditLink: this._panelEditLink,
              isRenewal: this.props.isRenewal,
              checkChildCareTabEnable: this._checkChildCareTabEnable,
              isClientEligibleForCHCR: this._isClientEligibleForCHCR,
              setShowNext: this._setShowNext,
              newTaxInformation: {},
              changeActiveAccordionKey: this._changeActiveAccordionKey,
              setVictimOfAFamOrDomestViol: this._setVictimOfAFamOrDomestViol.bind(
                this
              ),
              setReneCovInd: this._setReneCovInd.bind(this),
              LIEAP: false,
              FDNT: false,
              HEWL: false,
              SCA: false,
              CHCR: true,
              CHSP: false,
              MDPA: false,
              TANF: false,
              CCA: true,
              countyJson,
              refs: {
                ...refs,
                ...clientUpdateWizardRefs,
                '{applicant status}': ({ formContext, fieldId }) => {
                  let index =
                    fieldId.match('(?:.*)clients_([0-9]+)_?') &&
                    fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  if (index) {
                    let status =
                      index === '0' ? 'Primary Applicant' : 'Applicant'
                    return status || ''
                  }
                },
                '{this docClssNm}': ({ formContext, fieldId }) => {
                  let index1 = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let index2 = fieldId.match('(?:.*)myDocuments_([0-9]+)_?')[1]
                  return formContext.formData.application.clients[index1]
                    .myDocuments[index2].docClssNm
                },
                '{this applicant name}': ({ formContext, fieldId }) => {
                  let clientIndex =
                    fieldId.match('(?:.*)clients_([0-9]+)_?') &&
                    fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let personalData =
                    formContext.formData.application.clients[clientIndex]
                      .clientDetails.personalData

                  if (
                    personalData[0].clientFstNm == undefined ||
                    personalData[0].clientLstNm == undefined
                  ) {
                    personalData = this.props.updatedCOCFormData.formData
                      .application.clients[clientIndex].clientDetails
                      .personalData
                  }
                  if (!personalData || personalData.length == 0) {
                    return
                  }
                  const activePersonalData = _.maxBy(
                    personalData.filter(e => e.clientFstNm !== ''),
                    'clientFstNm'
                  )

                  return (
                    activePersonalData.clientFstNm +
                    (activePersonalData.clientLstNm
                      ? ' ' + activePersonalData.clientLstNm
                      : '')
                  )
                },
                '{CPDisable}': ({ formContext }) => {
                  if (
                    this.props.roleName === 'Community Partner' ||
                    this.props.roleName === 'CP Admin'
                  ) {
                    formContext.formData.application.someoneAsstYouFlg = 'N'
                    return true
                  } else return false
                },
                '{someoneAsstFlg Enum}': ({ formContext }) => {
                  let arr = []
                  if (
                    this.props.roleName === 'Community Partner' ||
                    this.props.roleName === 'CP Admin'
                  ) {
                    arr.push('N')
                  } else {
                    arr.push('Y')
                    arr.push('YA')
                    arr.push('N')
                  }
                  return arr
                },
                '{someAsstFlgEnumNames}': ({ formContext }) => {
                  let arr = []
                  if (
                    this.props.roleName === 'Community Partner' ||
                    this.props.roleName === 'CP Admin'
                  ) {
                    arr.push(
                      I18n.t('ClientUpdateWizard.RenewalSomeoneAsstYouFlgN')
                    )
                  } else {
                    arr.push(
                      I18n.t('ClientUpdateWizard.RenewalSomeoneAsstYouFlgY')
                    )
                    arr.push(
                      I18n.t('ClientUpdateWizard.RenewalSomeoneAsstYouFlgYA')
                    )
                    arr.push(
                      I18n.t('ClientUpdateWizard.RenewalSomeoneAsstYouFlgN')
                    )
                  }
                  return arr
                },
                '{notes for old applicants}': ({ formContext, fieldId }) => {
                  let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  if (+clientIndex <= +initClientsLength - 1) {
                    return I18n.t('ClientUpdateWizard.notesForOldApplicants')
                  }
                  return ''
                },
                '{disable for existing household member}': ({
                  formContext,
                  fieldId
                }) => {
                  let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  return +clientIndex <= +initClientsLength - 1
                }
              },
              currentDate: date,
              formContextCurrentDate: getServerTime(),
              onUpdateCarouselSlide: this._onUpdateCarouselSlides,
              deleteOnHide: true, //This will cause form data to be removed when it becomes invisible via ui:visibleIf.
              deleteArrayOnHide: true, //This will cause form data to be removed when it becomes invisible via ui:eachVisibleIf.
              locale: this.props.locale
            }
          },
          () => {
            if (this.props.isRenewal) {
              this._initializeTabs()
              this._loadRenewalAndInitializeData()
            } else this._loadAndInitializeData()
            this._initializeTabs()
          }
        )
      })
      .catch(error => {
        console.error(
          'ClientUpdateWizard _initializeForms failed due to ex',
          error
        )
      })

    if (config.tenant.code) {
      this.setState(prevState => {
        return {
          formContext: {
            ...prevState.formContext,
            tenantId: config.tenant.id
          }
        }
      })
    }
  }

  _initializeTabs = () => {
    const tabs = []
    _.forEach(this.state.forms, (value, index) => {
      tabs.push({
        title: value['uiSchema']['externalOptions']['tab']['tabName'],
        leftIcon: value['uiSchema']['externalOptions']['tab']['iconClassName'],
        rightIcon: pristineIcon,
        rightSpan: null,
        status: 'pristine',
        visibleIf:
          value['uiSchema']['externalOptions']['tab']['visibleIf'] || true,
        isVisible:
          value['uiSchema']['externalOptions']['tab']['visibleIf'] || true
      })
    })
    this.setState({ tabs })
  }

  _loadRenewalAndInitializeData = () => {
    const {
      caseId,
      status,
      applId,
      reviewIndicator,
      renewalStatus
    } = this.state
    let programCodes = this.getQueryParamFromUrl('programCodes')
    let programRequestType =
      this.getQueryParamFromUrl('programRequestType') || ''
    if (programCodes) {
      programCodes = programCodes.split(',') as any
    }

    let freshStart =
      (applId != undefined && renewalStatus === 'Renewal in Progress') ||
      (applId != undefined && renewalStatus === undefined)
        ? false
        : true

    const fetchEndpoint = !freshStart
      ? loadDraftEndpoint
          .replace('{applId}', applId)
          .replace('{applType}', 'RENEWAL')
      : getRenewalData

    let body = {
      applId: applId !== 'undefined' ? applId : 0,
      applicationType: 'RENEWAL',
      caseNumber: caseId ? caseId : 0,
      orgId: this.props.orgId !== '' ? this.props.orgId : 0,
      programCodes: ['CCA'],
      userRoleId: this.props.userRoleId !== '' ? this.props.userRoleId : 0,
      roleId: this.props.roleId !== '' ? this.props.roleId : 0,
      status: renewalStatus ? renewalStatus : 'OPEN',
      tenantId: config.tenant.id,
      uuid: this.props.auth.userAccount.uuid
    }

    let getCall = {
      headers: {
        'Content-Type': 'application/json',
        reviewTypeIndicator: reviewIndicator,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        roleId: this.props.roleId !== '' ? this.props.roleId : 0,
        orgId: this.props.orgId !== '' ? this.props.orgId : 0,
        tenantId: config.tenant.id
      },
      method: 'GET'
    }

    let postCall = {
      headers: {
        'Content-Type': 'application/json',
        reviewTypeIndicator: reviewIndicator,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        roleId: this.props.roleId !== '' ? this.props.roleId : 0,
        orgId: this.props.orgId !== '' ? this.props.orgId : 0,
        tenantId: config.tenant.id
      },
      method: 'POST',
      body: JSON.stringify(body)
    }

    let getPostHeader: any
    if (
      (this.state.applId != undefined &&
        renewalStatus === 'Renewal in Progress') ||
      (this.state.applId != undefined && renewalStatus === undefined)
    ) {
      getPostHeader = getCall
    } else {
      getPostHeader = postCall
    }

    serviceHelper
      .fetchJson(fetchEndpoint, getPostHeader, this.props.showErrorMessage)
      .then(json => {
        if (freshStart) {
          let clientsData = []
          _.forEach(json.Application.clients, (client, index) => {
            _.set(
              clientsData,
              `[${index}].clientDetails.personalData`,
              _.get(client, `clientDetails.personalData`)
            )
          })
          json = {
            Application: {
              householdInfo: json.Application.householdInfo,
              clients: clientsData,
              EBT: {},
              programCodes: ['CCA'],
              appPrograms: ['CCA']
            }
          }
        }
        if (json.hasOwnProperty('tabStatus')) {
          let tabStatus = _tabStatus(this.state.tabs, json)
          let step =
            json.hasOwnProperty('step') && json.step != 1 ? json.step : 1
          this.setState({
            tabs: tabStatus,
            step
          })
        }
        if (
          !json ||
          !json.Application['clients'] ||
          !_.isArray(json.Application['clients'])
        ) {
          throw new Error('Expected a clients response property')
        }
        if (
          this.state.applId != undefined &&
          this.state.renewalStatus === undefined
        ) {
          // if (this.state.allPrograms[0] == 'null') {
          //   this.setState({
          //     allPrograms:
          //       json.Application.prgNames.search(',') > 0
          //         ? json.Application.prgNames.split(',')
          //         : [json.Application.prgNames]
          //   })
          // }
          if (!this.state.benefitProgramName) {
            this.setState({
              benefitProgramName: json.Application.benefitProgramName
            })
          }
          if (!this.state.benefitProgramCode) {
            this.setState({
              benefitProgramCode: json.Application.benefitProgramCode
            })
          }
        }
        if (json.Application.programCode) {
          this.setState({
            programCode: json.Application.programCode
          })
        }
        if (json.Application.programClientMapping) {
          this.setState({
            programClientMapping: json.Application.programClientMapping
          })
        }

        let prgNames = this.state.prgNames || json.Application.prgNames
        let allPrograms
        // if (this.state.allPrograms[0] == 'null') {
        //   allPrograms =
        //     json.Application.prgNames.search(',') > 0
        //       ? json.Application.prgNames.split(',')
        //       : [json.Application.prgNames]
        // } else {
        //   allPrograms = this.state.allPrograms
        // }
        if (!programCodes) {
          programCodes = json.Application.programCodes
          programRequestType = json.Application.programRequestType
        }
        let LIEAP = false,
          FDNT = false,
          SNAP = false,
          HEWL = false,
          SCA = false,
          CHCR = true,
          CHSP = false,
          MDPA = false,
          TANF = false,
          CCA = true
        let hasSpecialNeeds = false
        if (this.props.isRenewal) {
          if (FDNT || MDPA || HEWL || CHSP || CHCR || TANF) {
            hasSpecialNeeds = true
          }
        } else {
          hasSpecialNeeds = true
        }
        let formsClone = _.cloneDeep(this.state.forms)
        if (TANF) {
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability.items.disabilityBenefits[
            'ui:disableIf'
          ] = false
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability.items.disabilityStartDate[
            'ui:disableIf'
          ] = false
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability.items.disabilityType[
            'ui:disableIf'
          ] = false

          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability[
            'ui:options'
          ].removable = true
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability[
            'ui:options'
          ].removableAdd = true
        } else {
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability.items.disabilityBenefits[
            'ui:disableIf'
          ] = true
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability.items.disabilityStartDate[
            'ui:disableIf'
          ] = true
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability.items.disabilityType[
            'ui:disableIf'
          ] = true
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability[
            'ui:options'
          ].removable = false
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability[
            'ui:options'
          ].removableAdd = false
          formsClone[2].uiSchema.application.clients.items.clientAdditionalInformation.disability[
            'ui:options'
          ].maxItems = 1
        }
        this.setState({
          hasSpecialNeeds,
          forms: formsClone,
          formContext: {
            ...this.state.formContext,
            isRenewal: true,
            forms: formsClone,
            programRequestType,
            programCodes,
            LIEAP,
            FDNT,
            HEWL,
            SCA,
            CHCR,
            CHSP,
            MDPA,
            TANF,
            CCA,
            SNAP
          }
        })
        let clientRelations
        let caseInfo = null
        helper.cleanNullValues[json]
        if (
          json.Application['caseInfo'] &&
          Array.isArray(json.Application['caseInfo']) &&
          json.Application['caseInfo'].length > 0
        ) {
          caseInfo = json.Application['caseInfo'][0]
          clientRelations = caseInfo.clientRelations || []
        }
        const fetchedFormData = _.merge(
          helper.createObjectFromMasterSchema(null, this.state.formContext),
          {
            application: {
              clients: json.Application['clients'],
              clientRelations: clientRelations
            }
          }
        )

        initClientsLength =
          json &&
          json.Application &&
          json.Application['clients'] &&
          json.Application['clients'].length

        if (json.Application['CONTRIBUTOR']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            authorizedRepresentative: json.Application['CONTRIBUTOR']
          }
        }

        if (json.Application['APPLYFROM']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            APPLYFROM: json.Application['APPLYFROM']
          }
        }

        if (json.Application['EBT']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            EBT: json.Application['EBT']
          }
        }

        if (json.Application['SPECIALNEEDS']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            SPECIALNEEDS: json.Application['SPECIALNEEDS']
          }
        }

        if (json.Application['householdRequestForADA']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            householdRequestForADA: json.Application['householdRequestForADA']
          }
        }

        if (json.Application['householdInfo']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            householdInfo: json.Application['householdInfo']
          }
        }
        if (json.Application['prgNames']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            prgNames: json.Application['benefitProgramName']
          }
        }

        fetchedFormData.application.someoneAsstYouFlg = 'Y'

        let caseNumber = json.caseNumber
        let renewalDate = json.renewalDate
        let newFormData = helper.clearActions(fetchedFormData)
        helper.cleanNullValues(newFormData)
        for (let i = 0; i < newFormData.application.clients.length; i++) {
          newFormData.application.clients[i].nbrRegInd =
            json.Application?.clients[i]?.nbrRegInd
          newFormData.application.clients[i].clientFstNm =
            json.Application?.clients[i]?.clientFstNm
          newFormData.application.clients[i].clientLstNm =
            json.Application?.clients[i]?.clientLstNm
        }

        let clients = newFormData.application.clients
        clients.forEach(client => {
          if (!client.hasOwnProperty('identifier')) {
            client.identifier = v4()
          }
          if (!_.get(client, 'clientDetails.personalData[0].helpWithBills')) {
            client.clientDetails.personalData[0].helpWithBills = 'N'
          }
        })
        clients.forEach(client => {
          let disability = _.get(
            client,
            'clientAdditionalInformation.disability',
            []
          )
          let relationShip = _.get(client, 'clientDetails.relationShip', [])
          let taxInformation = _.get(client, 'clientDetails.taxInformation', [])
          if (
            disability &&
            disability[0] &&
            (disability[0].disabilityType == null ||
              disability[0].disabilityType == undefined) &&
            (disability[0].disabilityBenefits == null ||
              disability[0].disabilityBenefits == undefined) &&
            (disability[0].disabilityStartDate == null ||
              disability[0].disabilityStartDate == undefined)
          ) {
            client.clientAdditionalInformation.disability = []
          }
          let earnedIncome = _.get(client, 'clientIncomes.earnedIncomes', [])
          earnedIncome.forEach(income => {
            if (
              _.get(income, 'incomeSource.address.zip') === '0' ||
              _.get(income, 'incomeSource.address.zip') === null
            ) {
              _.set(income, 'incomeSource.address.zip', undefined)
            }
            // Code Changes GPRB0359078 and US2554056
            // Renewal - Earned Income Zip Code
            // Add Leading zero to Zip Code
            if (
              String(_.get(income, 'incomeSource.address.zip')).length > 0 &&
              String(_.get(income, 'incomeSource.address.zip')).length < 5
            ) {
              let zip = _.get(income, 'incomeSource.address.zip', undefined)
              let updatedZip = zip && zip.padStart(5, '0')
              _.set(income, 'incomeSource.address.zip', updatedZip)
            }
          })
          if (
            _.get(
              newFormData,
              'application.householdInfo.householdAddress.zipExt'
            ) == '0'
          ) {
            _.set(
              newFormData,
              'application.householdInfo.householdAddress.zipExt',
              undefined
            )
          }
          // Code Changes GPRB0359078 and US2554056
          // Renewal - Household Address Zip Code and Zip Ext
          // Add Leading zero to Zip Code and Zip Ext
          if (
            String(
              _.get(
                newFormData,
                'application.householdInfo.householdAddress.zipExt'
              )
            ).length > 0 &&
            String(
              _.get(
                newFormData,
                'application.householdInfo.householdAddress.zipExt'
              )
            ).length < 4
          ) {
            let householdAddressZipExt = _.get(
              newFormData,
              'application.householdInfo.householdAddress.zipExt',
              ''
            )
            let updatedHouseholdAddressZipExt =
              householdAddressZipExt && householdAddressZipExt.padStart(4, '0')
            _.set(
              newFormData,
              'application.householdInfo.householdAddress.zipExt',
              updatedHouseholdAddressZipExt
            )
          }
          if (
            String(
              _.get(
                newFormData,
                'application.householdInfo.householdAddress.zip'
              )
            ).length > 0 &&
            String(
              _.get(
                newFormData,
                'application.householdInfo.householdAddress.zip'
              )
            ).length < 5
          ) {
            let householdAddressZip = _.get(
              newFormData,
              'application.householdInfo.householdAddress.zip',
              ''
            )
            let updatedHouseholdAddressZip =
              householdAddressZip && householdAddressZip.padStart(5, '0')
            _.set(
              newFormData,
              'application.householdInfo.householdAddress.zip',
              updatedHouseholdAddressZip
            )
          }
          // Code Changes GPRB0359078 and US2554056
          // Renewal - Assister Address Zip Code
          // Add Leading zero to Zip Code
          if (
            String(
              _.get(
                newFormData,
                'application.authorizedRepresentative.assisterAddress.zip'
              )
            ).length > 0 &&
            String(
              _.get(
                newFormData,
                'application.authorizedRepresentative.assisterAddress.zip'
              )
            ).length < 5
          ) {
            let assisterAddressZip = _.get(
              newFormData,
              'application.authorizedRepresentative.assisterAddress.zip',
              ''
            )
            let updatedAssisterAddressZip =
              assisterAddressZip && assisterAddressZip.padStart(5, '0')
            _.set(
              newFormData,
              'application.authorizedRepresentative.assisterAddress.zip',
              updatedAssisterAddressZip
            )
          }
          // Code Changes GPRB0359078 and US2554056
          // Renewal - Mailing Address Zip Code
          // Add Leading zero to Zip Code
          if (
            String(
              _.get(newFormData, 'application.householdInfo.mailingAddress.zip')
            ).length > 0 &&
            String(
              _.get(newFormData, 'application.householdInfo.mailingAddress.zip')
            ).length < 5
          ) {
            let mailingAddressZip = _.get(
              newFormData,
              'application.householdInfo.mailingAddress.zip',
              ''
            )
            let updatedMailingAddressZip =
              mailingAddressZip && mailingAddressZip.padStart(5, '0')
            _.set(
              newFormData,
              'application.householdInfo.mailingAddress.zip',
              updatedMailingAddressZip
            )
          }

          relationShip.forEach(relation => {
            if (relation.relClientName != undefined) {
              relation.relClientName =
                clients[relation.relClientName].identifier
            }
          })
          taxInformation.forEach(taxInfo => {
            if (
              taxInfo.relClientName != undefined &&
              taxInfo.relClientName != 'Other'
            ) {
              taxInfo.relClientName = clients[taxInfo.relClientName].identifier
            }
          })
        })

        clients.forEach(client => {
          for (let clientKey of Object.keys(client)) {
            if (_.isObject(client[clientKey])) {
              for (let keys of Object.keys(client[clientKey])) {
                let currentAccordionData = _.get(
                  client,
                  '[' + clientKey + '][' + keys + ']',
                  []
                )
                _.isArray(currentAccordionData) &&
                  currentAccordionData.forEach(data => {
                    data.isNew = false
                  })
              }
            }
          }
        })

        clients.forEach(client => {
          client.isOld = true
        })
        if (caseNumber > 0) {
          this.setState({
            callingApi: false,
            caseId: caseNumber,
            rnwlDate: renewalDate
          })
        }
        let jsonStep = 1
        if (json.step != undefined) jsonStep = json.step
        else jsonStep = this.state.step

        if (
          this.props.roleName === 'Community Partner' ||
          this.props.roleName === 'CP Admin'
        ) {
          _.set(
            newFormData,
            'application.authorizedRepresentative.assisterRoleCd',
            '10'
          )
        }
        if (!freshStart) {
          newFormData.application.clients.forEach(client => {
            client.clientAssets.lumpsumPayment = _.filter(
              client.clientAssets.lumpsumPayment,
              lumpSum => {
                return _.includes(
                  lumpSumPaymentTypes,
                  _.get(lumpSum, 'accountType.key')
                )
              }
            )
            client.clientAssets = {
              bankingAccounts: client.clientAssets.bankingAccounts,
              cash: client.clientAssets.cash,
              trusts: client.clientAssets.trusts,
              investmentAccounts: client.clientAssets.investmentAccounts,
              lumpsumPayment: client.clientAssets.lumpsumPayment,
              vehicles: client.clientAssets.vehicles,
              soldtransferAsset: client.clientAssets.soldtransferAsset,
              insurancePolicies: client.clientAssets.insurancePolicies,
              otherAssets: client.clientAssets.otherAssets,
              realEstates: client.clientAssets.realEstates
            }
            client.clientIncomes.otherIncomes = _.filter(
              client.clientIncomes.otherIncomes,
              income => {
                return _.includes(
                  unearnedIncomeTypes,
                  _.get(income, 'incomeType.key')
                )
              }
            )
            client.clientIncomes = {
              earnedIncomes: client.clientIncomes.earnedIncomes,
              selfEmploymentIncomes: client.clientIncomes.selfEmploymentIncomes,
              otherIncomes: client.clientIncomes.otherIncomes,
              roomAndBoardExpenses: client.clientIncomes.roomAndBoardExpenses
            }
            client.clientExpenses = {
              childSupport: client.clientExpenses.childSupport,
              dependentCareExpenses:
                client.clientExpenses.dependentCareExpenses,
              heatingExpenses: client.clientExpenses.heatingExpenses,
              coolingExpenses: client.clientExpenses.coolingExpenses,
              utilityExpenses: client.clientExpenses.utilityExpenses,
              shelterExpenses: client.clientExpenses.shelterExpenses,
              deduction: client.clientExpenses.deduction,
              educationExpenses: client.clientExpenses.educationExpenses,
              electricExpenses: client.clientExpenses.electricExpenses,
              gasExpenses: client.clientExpenses.gasExpenses,
              medicalExpenses: client.clientExpenses.medicalExpenses,
              movingExpenses: client.clientExpenses.movingExpenses,
              otherExpenses: client.clientExpenses.otherExpenses,
              selfEmploymentExpenses:
                client.clientExpenses.selfEmploymentExpenses,
              sewerExpenses: client.clientExpenses.sewerExpenses,
              waterExpenses: client.clientExpenses.waterExpenses,
              workExpenses: client.clientExpenses.workExpenses
            }
          })
        }
        this.setState(prevState => {
          return {
            formData: newFormData,
            step: jsonStep,
            oldFormData: _.cloneDeep(newFormData),
            formContext: {
              ...prevState.formContext,
              states: this.props.states,
              originalFormData: newFormData,
              formData: newFormData,
              oldClients: newFormData.application.clients.length,
              reviewFormData: _.cloneDeep(newFormData)
            },
            caseInfo
          }
        })
      })
      .catch(this.onUnAuthorizedError)
  }

  _loadAndInitializeData = () => {
    const { caseId } = this.state
    const hasOtherOpenCases =
      _.filter(this.props.myOpencases, c => c.caseNumber !== caseId).length > 0

    const applId =
      this.state.status === 'DELETE' || this.state.status === 'PARTIAL SUBMIT'
        ? 0
        : this.state.applId

    const fetchEndpoint =
      applId &&
      applId !== 'undefined' &&
      (this.state.status === 'INDRAFT' || this.state.status === undefined)
        ? loadDraftEndpoint
            .replace('{applId}', applId)
            .replace('{applType}', 'COC')
        : getCocData.replace('{caseId}', caseId)

    let requestBody = {
      caseNumber: this.state.caseId,
      userRoleId: this.props.userRoleId !== '' ? this.props.userRoleId : 0,
      orgId: this.props.orgId !== '' ? this.props.orgId : 0,
      roleId: this.props.roleId !== '' ? this.props.roleId : 0,
      status: this.state.status,
      applId: this.state.applId !== 'undefined' ? this.state.applId : 0,
      applicationType: 'COC',
      programCodes: [],
      uuid: this.props.auth.userAccount.uuid,
      tenantId: config.tenant.id
    }

    let getCall = {
      headers: {
        uuid: this.props.auth.userAccount.uuid,
        'Content-Type': 'application/json',
        tenantCode: config.tenantCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        orgId: this.props.orgId !== '' ? this.props.orgId : 0,
        roleId: this.props.roleId !== '' ? this.props.roleId : 0,
        tenantId: config.tenant.id
      },
      method: 'GET'
    }

    let postCall = {
      headers: {
        uuid: this.props.auth.userAccount.uuid,
        'Content-Type': 'application/json',
        tenantCode: config.tenantCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        orgId: this.props.orgId !== '' ? this.props.orgId : 0,
        roleId: this.props.roleId !== '' ? this.props.roleId : 0,
        tenantId: config.tenant.id
      },
      method: 'POST',
      body: JSON.stringify(requestBody)
    }

    let getPostHeader: any
    if (
      applId &&
      applId !== 'undefined' &&
      (this.state.status === 'INDRAFT' || this.state.status === undefined)
    ) {
      getPostHeader = getCall
    } else {
      getPostHeader = postCall
    }

    serviceHelper
      .fetchJson(fetchEndpoint, getPostHeader, this.props.showErrorMessage)
      .then(json => {
        helper.cleanNullValues(json)
        if (json.hasOwnProperty('error')) {
          this.setState({ redirect: baseUrl + 'myaccount/lifeevents' })
        }
        if (json.hasOwnProperty('tabStatus')) {
          let tabStatus = _tabStatus(this.state.tabs, json)
          let step =
            json.hasOwnProperty('step') && json.step != 1 ? json.step : 1
          this.setState({
            tabs: tabStatus,
            step
          })
        }
        if (
          !json ||
          !json.Application['clients'] ||
          !_.isArray(json.Application['clients'])
        ) {
          throw new Error('Expected a clients response property')
        }
        let clientRelations
        let caseInfo = null
        helper.cleanNullValues[json]
        initClientsLength =
          json &&
          json.Application &&
          json.Application['clients'] &&
          json.Application['clients'].length

        json.Application.clients.forEach(client => {
          client.isOld = true
        })
        if (
          json.Application['caseInfo'] &&
          Array.isArray(json.Application['caseInfo']) &&
          json.Application['caseInfo'].length > 0
        ) {
          caseInfo = json.Application['caseInfo'][0]
          clientRelations = caseInfo.clientRelations || []
        }
        let fetchedFormData = _.merge(
          helper.createObjectFromMasterSchema(null, this.state.formContext),
          {
            application: {
              clients: json.Application['clients'],
              clientRelations: clientRelations
            }
          }
        )
        if (json.Application['CONTRIBUTOR']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            authorizedRepresentative: json.Application['CONTRIBUTOR']
          }
        }

        if (json.Application['APPLYFROM']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            APPLYFROM: json.Application['APPLYFROM']
          }
        }

        if (json.Application['EBT']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            EBT: json.Application['EBT']
          }
        }

        if (json.Application['SPECIALNEEDS']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            SPECIALNEEDS: json.Application['SPECIALNEEDS']
          }
        }

        if (json.Application['householdRequestForADA']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            householdRequestForADA: json.Application['householdRequestForADA']
          }
        }

        if (json.Application['householdInfo']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            householdInfo: json.Application['householdInfo']
          }
        }

        if (json.Application.programCode) {
          this.setState({
            programCode: json.Application.programCode
          })
        }

        let caseNumber = json.caseNumber
        let clients = fetchedFormData.application.clients
        clients.forEach(client => {
          if (!client.hasOwnProperty('identifier')) {
            client.identifier = v4()
          }
        })
        clients.forEach(client => {
          // client.myDocuments.forEach(doc => {
          //   doc.docList.forEach(d => {
          //     d.docType = d.typeRefCode.value
          //   })
          // })
          let disability = client.clientAdditionalInformation.disability
          let relationShip = _.get(client, 'clientDetails.relationShip', [])
          let taxInformation = _.get(client, 'clientDetails.taxInformation', [])
          if (
            disability &&
            disability[0] &&
            (disability[0].disabilityType == null ||
              disability[0].disabilityType == undefined) &&
            (disability[0].disabilityBenefits == null ||
              disability[0].disabilityBenefits == undefined) &&
            (disability[0].disabilityStartDate == null ||
              disability[0].disabilityStartDate == undefined)
          ) {
            client.clientAdditionalInformation.disability = []
          }
          let earnedIncome = _.get(client, 'clientIncomes.earnedIncomes', [])
          earnedIncome.forEach(income => {
            if (_.get(income, 'incomeSource.address.zip') == '0') {
              _.set(income, 'incomeSource.address.zip', undefined)
            }
            // Code Changes GPRB0359078 and US2554056
            // COC - Earned Income Zip Code
            // Add Leading zero to Zip Code
            if (
              String(_.get(income, 'incomeSource.address.zip')).length > 0 &&
              String(_.get(income, 'incomeSource.address.zip')).length < 5
            ) {
              let zip = _.get(income, 'incomeSource.address.zip', undefined)
              let updatedZip = zip && zip.padStart(5, '0')
              _.set(income, 'incomeSource.address.zip', updatedZip)
            }
          })
          if (
            _.get(
              fetchedFormData,
              'application.householdInfo.householdAddress.zipExt'
            ) == '0'
          ) {
            _.set(
              fetchedFormData,
              'application.householdInfo.householdAddress.zipExt',
              undefined
            )
          }
          // Code Changes GPRB0359078 and US2554056
          // COC - Household Address Zip Code and Zip Ext
          // Add Leading zero to Zip Code and Zip Ext
          if (
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.householdAddress.zipExt'
              )
            ).length > 0 &&
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.householdAddress.zipExt'
              )
            ).length < 4
          ) {
            let householdAddressZipExt = _.get(
              fetchedFormData,
              'application.householdInfo.householdAddress.zipExt',
              ''
            )
            let updatedHouseholdAddressZipExt =
              householdAddressZipExt && householdAddressZipExt.padStart(4, '0')
            _.set(
              fetchedFormData,
              'application.householdInfo.householdAddress.zipExt',
              updatedHouseholdAddressZipExt
            )
          }
          if (
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.householdAddress.zip'
              )
            ).length > 0 &&
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.householdAddress.zip'
              )
            ).length < 5
          ) {
            let householdAddressZip = _.get(
              fetchedFormData,
              'application.householdInfo.householdAddress.zip',
              ''
            )
            let updatedHouseholdAddressZip =
              householdAddressZip && householdAddressZip.padStart(5, '0')
            _.set(
              fetchedFormData,
              'application.householdInfo.householdAddress.zip',
              updatedHouseholdAddressZip
            )
          }
          // Code Changes GPRB0359078 and US2554056
          // COC - Mailing Address Zip Code
          // Add Leading zero to Zip Code
          if (
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.mailingAddress.zip'
              )
            ).length > 0 &&
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.mailingAddress.zip'
              )
            ).length < 5
          ) {
            let mailingAddressZip = _.get(
              fetchedFormData,
              'application.householdInfo.mailingAddress.zip',
              ''
            )
            let updatedMailingAddressZip =
              mailingAddressZip && mailingAddressZip.padStart(5, '0')
            _.set(
              fetchedFormData,
              'application.householdInfo.mailingAddress.zip',
              updatedMailingAddressZip
            )
          }

          //old code
          relationShip.forEach(relation => {
            if (relation.relClientName != undefined) {
              relation.relClientName =
                clients[relation.relClientName].identifier
            }
          })
          taxInformation.forEach(taxInfo => {
            if (
              taxInfo.relClientName != undefined &&
              taxInfo.relClientName != 'Other'
            ) {
              taxInfo.relClientName = clients[taxInfo.relClientName].identifier
            }
          })
        })

        clients.forEach(client => {
          for (let clientKey of Object.keys(client)) {
            if (_.isObject(client[clientKey])) {
              for (let keys of Object.keys(client[clientKey])) {
                let currentAccordionData = _.get(
                  client,
                  '[' + clientKey + '][' + keys + ']',
                  []
                )
                _.isArray(currentAccordionData) &&
                  currentAccordionData.forEach(data => {
                    data.isNew = false
                  })
              }
            }
          }
        })

        let newFormData = helper.clearActions(fetchedFormData)
        helper.cleanNullValues(newFormData)
        for (let i = 0; i < newFormData.application.clients.length; i++) {
          newFormData.application.clients[i].nbrRegInd =
            json.Application.clients[i].nbrRegInd
          newFormData.application.clients[i].clientFstNm =
            json.Application.clients[i].clientFstNm
          newFormData.application.clients[i].clientLstNm =
            json.Application.clients[i].clientLstNm
        }
        _.set(
          newFormData.application,
          'someoneAsstYouFlg',
          json?.Application?.someoneAsstYouFlg
        )

        if (caseNumber > 0) {
          this.setState({
            callingApi: false,
            caseId: caseNumber
          })
        }

        newFormData.application.clients.forEach(client => {
          client.clientAssets.lumpsumPayment = _.filter(
            client.clientAssets.lumpsumPayment,
            lumpSum => {
              return _.includes(
                lumpSumPaymentTypes,
                _.get(lumpSum, 'accountType.key')
              )
            }
          )
          client.clientAssets = {
            bankingAccounts: client.clientAssets.bankingAccounts,
            cash: client.clientAssets.cash,
            trusts: client.clientAssets.trusts,
            investmentAccounts: client.clientAssets.investmentAccounts,
            lumpsumPayment: client.clientAssets.lumpsumPayment,
            vehicles: client.clientAssets.vehicles,
            soldtransferAsset: client.clientAssets.soldtransferAsset,
            insurancePolicies: client.clientAssets.insurancePolicies,
            otherAssets: client.clientAssets.otherAssets,
            realEstates: client.clientAssets.realEstates
          }
          client.clientIncomes.otherIncomes = _.filter(
            client.clientIncomes.otherIncomes,
            income => {
              return _.includes(
                unearnedIncomeTypes,
                _.get(income, 'incomeType.key')
              )
            }
          )
          client.clientIncomes = {
            earnedIncomes: client.clientIncomes.earnedIncomes,
            selfEmploymentIncomes: client.clientIncomes.selfEmploymentIncomes,
            otherIncomes: client.clientIncomes.otherIncomes,
            roomAndBoardExpenses: client.clientIncomes.roomAndBoardExpenses
          }
          client.clientExpenses = {
            childSupport: client.clientExpenses.childSupport,
            dependentCareExpenses: client.clientExpenses.dependentCareExpenses,
            heatingExpenses: client.clientExpenses.heatingExpenses,
            coolingExpenses: client.clientExpenses.coolingExpenses,
            utilityExpenses: client.clientExpenses.utilityExpenses,
            shelterExpenses: client.clientExpenses.shelterExpenses,
            deduction: client.clientExpenses.deduction,
            educationExpenses: client.clientExpenses.educationExpenses,
            electricExpenses: client.clientExpenses.electricExpenses,
            gasExpenses: client.clientExpenses.gasExpenses,
            medicalExpenses: client.clientExpenses.medicalExpenses,
            movingExpenses: client.clientExpenses.movingExpenses,
            otherExpenses: client.clientExpenses.otherExpenses,
            selfEmploymentExpenses:
              client.clientExpenses.selfEmploymentExpenses,
            sewerExpenses: client.clientExpenses.sewerExpenses,
            waterExpenses: client.clientExpenses.waterExpenses,
            workExpenses: client.clientExpenses.workExpenses
          }
        })
        this.setState(prevState => {
          return {
            formData: newFormData,
            oldFormData: _.cloneDeep(newFormData),
            formContext: {
              ...prevState.formContext,
              states: this.props.states,
              hasOtherOpenCases,
              formData: newFormData,
              oldClients: newFormData.application.clients.length,
              reviewFormData: _.cloneDeep(newFormData),
              isRenewal: false
            },
            caseInfo,
            hasSpecialNeeds: true
          }
        })
      })
      .catch(this.onUnAuthorizedError)
  }

  _onNormalChange = helper.safeDebounce(
    ({ formData }) => {
      this._onFormDataChange(formData, false)
    },
    200,
    this.props.isUnitTest
  )

  _onErrorClearingChange = ({ formData }) => {
    this._onFormDataChange(formData, true)
  }

  _onFormDataChange = (formData: any, errorCleared: boolean) => {
    const { step, tabs } = this.state

    const newFormDataClients = _.get(formData, 'application.clients', [])

    let oldClients = 0
    newFormDataClients.forEach(client => {
      if (client.isOld) {
        oldClients++
      }
    })
    initClientsLength = oldClients

    newFormDataClients.forEach((client, index) => {
      if (!client.hasOwnProperty('identifier')) {
        client.identifier = v4()
      }
      if (!client.clientId) {
        client.clientId = index
      }
    })
    newFormDataClients.forEach(client => {
      let releationships = _.get(client, 'clientDetails.relationShip', [])
      let education = _.get(client, 'clientAdditionalInformation.education', [])
      let earnedIncomes = _.get(client, 'clientIncomes.earnedIncomes', [])
      let selfEmploymentIncomes = _.get(
        client,
        'clientIncomes.selfEmploymentIncomes',
        []
      )
      let ChildCareDetails = _.get(
        client,
        'clientAdditionalInformation.ChildCareDetails',
        []
      )

      releationships = releationships.filter(releationship => {
        return releationship.action !== 'INVALIDATED'
      })
      client.clientDetails.relationShip = releationships
      let birthDate = _moment(
        config,
        client.clientDetails.personalData[0].clientBthDt
      )
      let date = _moment(config, this.state.currentdate)
      if (
        date.diff(birthDate, 'years') >= 18 &&
        _.get(
          client,
          'clientDetails.personalData[0].roleInChildCareHouseHold.key'
        ) == 'CPCPCH36325'
      ) {
        _.set(
          client,
          'clientDetails.personalData[0].roleInChildCareHouseHold',
          {}
        )
      }
      education.forEach(e => {
        if (!e.schoolSchedule) {
          e.schoolSchedule = [{}]
        }
      })
      ChildCareDetails.forEach(e => {
        if (e.militaryEndDate === undefined) {
          e.militaryActiveStatus = 'Y'
        } else {
          e.militaryActiveStatus = 'N'
        }
        if (!e.wvWorkSchedule) {
          e.wvWorkSchedule = [{}]
        }
        if (!e.schoolSchedule) {
          e.schoolSchedule = [{}]
        }
      })
      earnedIncomes.forEach(e => {
        if (!e.workSchedule) {
          e.workSchedule = [{}]
        }
      })
      selfEmploymentIncomes.forEach(e => {
        if (!e.workSchedule) {
          e.workSchedule = [{}]
        }
      })
    })
    if (formData.application.closeCaseConfirmation === 'N') {
      formData.application.requestClosure = 'N'
    }
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          reviewFormData: _.cloneDeep(formData),
          formData,
          hasError: !errorCleared ? false : true
        },
        liveValidate: errorCleared ? false : prevState.liveValidate
      }
    })

    if (tabs[step - 1].status !== 'editing') {
      this.setState({
        tabs: [
          ...tabs.slice(0, step - 1),
          {
            ...tabs[step - 1],
            status: 'editing',
            rightIcon: editingIcon,
            rightSpan: editingBadge
          },
          ...tabs.slice(step)
        ]
      })
    }
  }

  _setVictimOfAFamOrDomestViol(value) {
    let { formData } = this.state
    _.set(formData.application, 'victimOfAFamOrDomestViol', value)
    this.setState({
      formData
    })
  }

  _showDomesticViolenceModal() {
    const { formData } = this.state
    const { isRenewal } = this.props
    let today = new Date(getServerTime())
    let submissionDate = today.toUTCString()

    if (
      _.get(formData, 'application.agreement', false) === true &&
      _.get(formData, 'application.signatureReceived', '').length > 0 &&
      _.get(formData, 'application.clientDt', '').length > 0
    ) {
      this.setState({
        showDomesticViolenceModal: true
      })
    } else {
      this.setState({ alertModalFlag: true })
      return
    }
  }

  _exitDomesticViolenceModal() {
    this.setState(
      {
        showDomesticViolenceModal: false
      },
      () => this._submitFormData()
    )
  }

  _focusMainContent = () => {
    const mainContent = document.getElementById('content')
    if (mainContent) {
      mainContent.focus()
    }
  }

  _changeView = (val: number) => {
    let { tabFocus } = this.state
    if (tabFocus) {
      this._focusMainContent()
    }
    let cStep = val + 1
    const { updateMyCocApplicationStep, step } = this.props
    updateMyCocApplicationStep(cStep)
    window.location.href = '#'
    this.setState({ step: val + 1 })
    this.setState(prevState => {
      return {
        ...prevState,
        step: val + 1
      }
    })
    if (this.state.slideIndex < this.state.slides.length - 1) {
      this.setState({
        showNext: false
      })
    }
  }

  onFocus = e => {
    if (e.which === 9 && !this.state.tabFocus) {
      this.setState({ tabFocus: true })
    } else if (e.which === 1 && this.state.tabFocus) {
      this.setState({ tabFocus: false })
    }
  }

  _saveFormDataDraft = (
    showModal: boolean,
    step: number,
    tabStatus: any,
    nonModal: boolean
  ) => {
    const {
      formData,
      oldFormData,
      resolvedSchema,
      programRenewals,
      caseId
    } = this.state
    const { isRenewal } = this.props
    let renewalBody

    let newFormData = _.cloneDeep(formData)

    let updatedTabStatus = this.state.tabs.map(e => {
      return {
        name: e.title,
        status: e.status
      }
    })

    let appltype
    if (isRenewal) {
      appltype = 'RENEWAL'
      renewalBody = {
        programCode:
          this.state.benefitProgramCode &&
          this.state.benefitProgramCode.search(',') > 0
            ? this.state.benefitProgramCode.split(',')
            : this.state.benefitProgramCode
            ? [this.state.benefitProgramCode]
            : this.state.programCode
      }
    } else {
      appltype = 'COC'
    }

    let saveStatusPoint = ''
    if (this.state.status != 'PARTIAL SUBMIT') {
      saveStatusPoint = saveDraftEndpoint
    } else if (nonModal != undefined) {
      this.setState({ showNonDraftModal: nonModal })
    } else {
      this.setState({ showNonDraftModal: true })
    }

    const applId =
      this.state.applId != 'undefined' && this.state.status != 'DELETE'
        ? this.state.applId
        : null
    let benefitPrgmNm =
      this.state.formContext.benefitProgramName != undefined
        ? this.state.formContext.benefitProgramName
        : this.state.benefitProgramName

    let programNms =
      this.state.formContext.prgNames != undefined
        ? this.state.formContext.prgNames
        : this.state.prgNames

    let clients = _.cloneDeep(_.get(newFormData, 'application.clients', []))
    let newClients = []
    let clientIdentifiers = []
    clients.forEach(client => {
      clientIdentifiers.push(client.identifier)
    })
    clients.forEach(tempClient => {
      let client = _.cloneDeep(tempClient)
      const relationShipDetails = _.get(
        client,
        'clientDetails.relationShip',
        []
      )
      const taxInfoDetails = _.get(client, 'clientDetails.taxInformation', [])
      relationShipDetails.forEach(relationShip => {
        for (let x = 0; x < clients.length; x++) {
          if (relationShip.relClientName == clients[x].identifier) {
            relationShip.relClientName = clients[x].id || clients[x].clientId
            relationShip.clientId = clients[x].clientId
          }
        }
      })
      taxInfoDetails.forEach(taxInfo => {
        if (taxInfo.relClientName && taxInfo.relClientName != 'Other') {
          for (let x = 0; x < clients.length; x++) {
            if (taxInfo.relClientName == clients[x].identifier) {
              taxInfo.relClientName = clients[x].id
            }
          }
        }
      })
      newClients.push(client)
    })

    _.set(newFormData, 'application.clients', newClients)

    let body: any = {
      caseNumber: this.state.caseId,
      renewalDate: this.state.renewalDate
        ? this.state.renewalDate
        : this.state.rnwlDate,
      step: this.state.step,
      applicationType: appltype,
      applId: applId,
      Application: {
        applId: applId,
        someoneAsstYouFlg: _.get(newFormData, 'application.someoneAsstYouFlg'),
        CONTRIBUTOR: _.get(
          newFormData,
          'application.authorizedRepresentative',
          {}
        ),
        clients: _.get(newFormData, 'application.clients', []),
        APPLYFROM: _.get(newFormData, 'application.APPLYFROM'),
        EBT: _.get(newFormData, 'application.EBT'),
        SPECIALNEEDS: _.get(newFormData, 'application.SPECIALNEEDS'),
        householdRequestForADA: _.get(
          newFormData,
          'application.householdRequestForADA'
        ),
        prgNames: programNms,
        benefitProgramName: benefitPrgmNm ? benefitPrgmNm : programNms,
        programClientMapping: this.state.programClientMapping,
        householdInfo: _.get(newFormData, 'application.householdInfo'),
        programCodes: this.state.formContext.programCodes,
        programRequestType: this.state.formContext.programRequestType,
        signatureReceived: _.get(newFormData, 'application.signatureReceived'),
        voterRegistration: _.get(newFormData, 'application.voterRegistration'),
        requestClosure: _.get(newFormData, 'application.requestClosure'),
        closeCaseConfirmation: _.get(
          newFormData,
          'application.closeCaseConfirmation'
        ),
        closureDetails: _.get(newFormData, 'application.closureDetails'),
        closureType: _.get(newFormData, 'application.closureType'),
        totalHouseholdAssets: _.get(
          newFormData,
          'application.totalHouseholdAssets'
        ),
        evaluatedForAutomaticIssuance: _.get(
          newFormData,
          'application.evaluatedForAutomaticIssuance'
        )
      },
      tabStatus: updatedTabStatus,
      type: 'CCA'
    }
    if (isRenewal) {
      if (this.state.formContext.HEWL) {
        body.Application.reneCovInd = this.state.reneCovInd
      }
    }

    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        orgId: this.props.orgId !== '' ? this.props.orgId : 0,
        userRoleId: this.props.userRoleId !== '' ? this.props.userRoleId : 0,
        roleId: this.props.roleId !== '' ? this.props.roleId : 0,
        applicationType: appltype,
        userId: this.state.formContext.clientUserId,
        tenantId: config.tenant.id,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        ieOrgId: this.props.ieOrgId,
        applId: applId !== 'undefined' ? applId : 0,
        caseNumber: caseId ? caseId : 0,
        status: 'DRAFT',
        source: 'CP',
        tenantCode: 'WV'
      },
      body: JSON.stringify(body)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(saveStatusPoint, request, this.props.showErrorMessage)
      .then(response => {
        let applId = response.applId
        this.setState({
          callingApi: false,
          showDraftModal: showModal,
          applId: applId,
          status: 'DRAFT'
        })
        if (applId && this.props.activeApplicationId === '0') {
          let newFormData = {
            Application: {
              applId: applId
            }
          }
          this.props.assignApplicationId(applId)
          this.props.setActiveApplicationId(applId)
          this.props.updateMyApplication(applId, 'DRAFT', newFormData)
        }
      })
      .catch(this.onUnAuthorizedError)
  }

  _onNext = () => {
    window.location.href = '#'
    let nextStep
    let progNames = this.state.prgNames
    const { updateMyCocApplicationStep, isRenewal } = this.props
    const { step, forms } = this.state
    nextStep = this._getStep(step, 1, forms)

    let newApplicantlength =
      this.state &&
      this.state.formData &&
      this.state.formData.application &&
      this.state.formData.application.clients &&
      this.state.formData.application.clients.length
    let oldApplicantLength =
      this.state &&
      this.state.oldFormData &&
      this.state.oldFormData.application &&
      this.state.oldFormData.application.clients &&
      this.state.oldFormData.application.clients.length

    if (newApplicantlength > oldApplicantLength) {
      addedNewApplicant = true
      newApplicantIndex = oldApplicantLength
      let containerId = document.getElementById(
        'LifeEventsWizardPersonal_application_clients_' +
          newApplicantIndex +
          '_clientDetails_personalData_notes'
      )
      if (containerId) {
        containerId.innerHTML = ''
      }
    } else {
      addedNewApplicant = false
    }

    const nextShowNext = nextStep > 1
    updateMyCocApplicationStep(nextStep)
    this.setState({
      step: nextStep,
      showNext: nextShowNext,
      formContext: {
        ...this.state.formContext,
        isRenewal: isRenewal
      }
    })
    if (this.state.status != 'PARTIAL SUBMIT') {
      this.props.updatedFormData(this.state.formContext)
      this._saveFormDataDraft(false, config, config, false)
    }
    const content = document.getElementById('content')
    if (content) {
      window.scroll(0, content.offsetTop)
      content.focus()
    }
  }

  _getStep(step, stepDelta, forms) {
    if (forms[step + stepDelta - 1]) {
      const stepTabId = forms[
        step + stepDelta - 1
      ].uiSchema.externalOptions.tab.tabName
        .split(' ')
        .join('')

      if (document.getElementById(stepTabId)) {
        return step + stepDelta
      } else {
        return this._getStep(step + stepDelta, stepDelta, forms)
      }
    }
  }

  _setShowNext = next => {
    this.setState({ showNext: next })
  }

  _onNextToDestination = (destinationIndex: number) => {
    window.location.href = '#'
    this.setState({ step: destinationIndex })
  }

  _onPrevious = () => {
    window.location.href = '#'
    let prevStep
    let progNames = this.state.prgNames
    const { step, forms } = this.state
    const { updateMyCocApplicationStep } = this.props
    prevStep = this._getStep(step, -1, forms)

    updateMyCocApplicationStep(prevStep)
    // check if step is already at the beginning
    if (step === 2) {
      this.setState(prevState => {
        return {
          ...prevState
        }
      })
    }
    if (
      this.state.step == STEPS.INCOME &&
      !this._checkChildCareTabEnable('root')
    ) {
      this.setState({ step: prevStep })
    } else {
      this.setState({ step: prevStep })
    }
    const content = document.getElementById('content')
    if (content) {
      window.scroll(0, content.offsetTop)
      content.focus()
    }
    if (this.state.slideIndex < this.state.slides.length - 1) {
      this.setState({
        showNext: false
      })
    }
  }

  _onSubmit = () => {
    const { step, tabs, forms, liveValidate } = this.state
    let newTabs = helper.deepClone(this.state.tabs)
    if (step === forms.length) {
      for (let i = 0; i < forms.length; ++i) {
        newTabs[i].status = 'validated'
        newTabs[i].rightIcon = validatedIcon
        newTabs[i].rightSpan = validatedBadge
      }
      this.setState(
        {
          liveValidate: false,
          tabs: newTabs
        },
        () => {
          if (this.state.formContext.FDNT || this.state.formContext.TANF) {
            this._showDomesticViolenceModal()
          } else {
            this._submitFormData()
          }
        }
      )
    } else {
      newTabs[step - 1].status = 'validated'
      newTabs[step - 1].rightIcon = validatedIcon
      newTabs[step - 1].rightSpan = validatedBadge

      this.setState(
        {
          liveValidate: false,
          tabs: newTabs
        },
        () => {
          this._onNext()
        }
      )
    }
  }

  _submitFormData = () => {
    const {
      formData,
      oldFormData,
      resolvedSchema,
      programRenewals,
      caseId,
      renewalDate,
      formContext,
      programCode
    } = this.state
    const { isRenewal, updateMyCocApplicationStep } = this.props
    const { reviewIndicator } = this.state
    let today = new Date(getServerTime())
    let submissionDate = today.toUTCString()

    if (
      _.get(formData, 'application.agreement', false) === true &&
      _.get(formData, 'application.signatureReceived', '').length > 0 &&
      _.get(formData, 'application.clientDt', '').length > 0
    ) {
      if (isRenewal) {
      } else {
        //TODO: What review & sign data to send on a COC?
        let clients = formData.application.clients
        for (let i = 0; i < clients.length; i++) {
          if (formData.application.clients[i].childCare) {
            formData.application.clients[
              i
            ].clientAdditionalInformation.childCare = _.cloneDeep(
              formData.application.clients[i].childCare
            )
          }

          if (formData.application.clients[i].childCare) {
            formData.application.clients[i].childCare = _.noop()
          }
        }
      }
    } else {
      this.setState({ alertModalFlag: true })
      return
    }
    const newFormData = _.cloneDeep(formData)

    let appltype
    if (isRenewal) {
      appltype = 'RENEWAL'
    } else {
      appltype = 'COC'
    }
    helper.stampUserInfo(
      oldFormData,
      newFormData,
      appltype,
      null,
      resolvedSchema,
      config
    )
    let clients = _.get(newFormData, 'application.clients', [])
    _.set(
      newFormData,
      'application.householdInfo.previousBenefitInfo.movedSt',
      undefined
    )
    if (isRenewal) {
      let HEWL = false,
        LIEAP = false,
        FDNT = false,
        SCA = false,
        CHCR = true,
        CHSP = false,
        MDPA = false
      clients.forEach(client => {
        if (!HEWL) {
          _.set(client, 'clientDetails.taxInformation', [])
        }
        if (!(SCA || HEWL || FDNT)) {
          _.set(client, 'clientAdditionalInformation.education', [])
        }
        if (!(FDNT || LIEAP)) {
          _.set(client, 'clientExpenses.shelterExpenses', [])
        }
        if (!(FDNT || CHCR || HEWL)) {
          _.set(client, 'clientAdditionalInformation.disability', [])
        }
        let residenceInformation = _.get(
          client,
          'clientDetails.residenceInformation',
          []
        )
        residenceInformation.forEach(resident => {
          if (!(FDNT || SCA || HEWL || MDPA))
            resident.clientRemInStIntnFlg = undefined
        })
      })
    }
    let programNms =
      this.state.formContext.prgNames != undefined
        ? this.state.formContext.prgNames
        : this.state.prgNames

    let newClients = []
    let clientIdentifiers = []
    clients.forEach(client => {
      clientIdentifiers.push(client.identifier)
    })
    clients.forEach(tempClient => {
      let client = _.cloneDeep(tempClient)
      const relationShipDetails = _.get(
        client,
        'clientDetails.relationShip',
        []
      )
      const taxInfoDetails = _.get(client, 'clientDetails.taxInformation', [])
      relationShipDetails.forEach(relationShip => {
        for (let x = 0; x < clients.length; x++) {
          if (relationShip.relClientName == clients[x].identifier) {
            relationShip.relClientName = clients[x].id
            relationShip.clientId = clients[x].clientId
          }
        }
      })
      taxInfoDetails.forEach(taxInfo => {
        if (taxInfo.relClientName && taxInfo.relClientName != 'Other') {
          for (let x = 0; x < clients.length; x++) {
            if (taxInfo.relClientName == clients[x].identifier) {
              taxInfo.relClientName = clients[x].id
            }
          }
        }
      })
      newClients.push(client)
    })

    _.set(newFormData, 'application.clients', newClients)

    let body: any = {
      submissionDate: submissionDate,
      reviewIndicator: reviewIndicator,
      benefitProgramName:
        programNms && programNms.search(',') > 0
          ? programNms.split(',')
          : [programNms],
      caseNumber: this.state.caseId,
      renewalDate: this.state.renewalDate
        ? this.state.renewalDate
        : this.state.rnwlDate,
      applId: this.state.applId,
      uuid: this.props.auth.userAccount.uuid,
      role_id: this.props.roleId,
      user_role_id: this.props.userRoleId,
      org_id: this.props.orgId,
      userId: formContext.clientUserId,
      tenantId: formContext.tenantId,
      applicationType: appltype,
      STATUS: 'SUBMITTED',
      Application: {
        CONTRIBUTOR: _.get(
          newFormData,
          'application.authorizedRepresentative',
          {}
        ),
        someoneAsstYouFlg: _.get(newFormData, 'application.someoneAsstYouFlg'),
        clients: _.get(newFormData, 'application.clients', []),
        APPLYFROM: _.get(newFormData, 'application.APPLYFROM'),
        EBT: _.get(newFormData, 'application.EBT'),
        SPECIALNEEDS: _.get(newFormData, 'application.SPECIALNEEDS'),
        householdRequestForADA: _.get(
          newFormData,
          'application.householdRequestForADA'
        ),
        householdInfo: _.get(newFormData, 'application.householdInfo'),
        signatureReceived: _.get(newFormData, 'application.signatureReceived'),
        voterRegistration: _.get(newFormData, 'application.voterRegistration'),
        programCodes: this.state.formContext.programCodes,
        programClientMapping: this.state.programClientMapping,
        victimOfAFamOrDomestViol: _.get(
          newFormData,
          'application.victimOfAFamOrDomestViol'
        ),
        requestClosure: _.get(newFormData, 'application.requestClosure'),
        closeCaseConfirmation: _.get(
          newFormData,
          'application.closeCaseConfirmation'
        ),
        closureDetails: _.get(newFormData, 'application.closureDetails'),
        closureType: _.get(newFormData, 'application.closureType'),
        totalHouseholdAssets: _.get(
          newFormData,
          'application.totalHouseholdAssets'
        ),
        evaluatedForAutomaticIssuance: _.get(
          newFormData,
          'application.evaluatedForAutomaticIssuance'
        )
      }
    }
    if (isRenewal)
      if (this.state.formContext.HEWL) {
        body.Application.reneCovInd = this.state.reneCovInd
      }
    if (isRenewal) {
      body.Application.programCode = this.state.formContext.programCodes
    }
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,

        applicationType: appltype,
        tenantCode: config.tCode,
        ieOrgId: this.props.ieOrgId !== '' ? this.props.ieOrgId : 0,
        orgId: this.props.orgId !== '' ? this.props.orgId : 0,
        roleId: this.props.roleId !== '' ? this.props.roleId : 0,
        tenantId: formContext.tenantId ? formContext.tenantId : 1,
        userId: this.state.formContext.clientUserId,
        userRoleId: this.props.userRoleId !== '' ? this.props.userRoleId : 0,
        uuid: this.props.auth.userAccount.uuid,
        status: 'SUBMITTED',
        source: 'CP'
      },
      body: JSON.stringify(body)
    }
    const fetchEndpoint = submitCocRenewalEndpoint

    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      //Your changes were submitted. Contact your agency worker with questions. Thank you
      .then(response => {
        updateMyCocApplicationStep(1)
        if (response && response.error) {
          this.setState({ callingApi: false })
        } else {
          this.setState({
            callingApi: false,
            modalProps: {
              closeBtnText: null,
              confirmBtnText: null,
              show: true,
              bsSize: 'lg',
              className: 'lg-width-to-md',
              cancel: null,
              confirm: () => {
                this.setState({
                  redirect:
                    baseUrl +
                    (isRenewal
                      ? '/myaccount/benefits'
                      : '/myaccount/lifeevents')
                })
              },
              title: isRenewal ? (
                <h4 className="popup-title">Confirmation</h4>
              ) : (
                <h4 className="popup-title">
                  Your Change Report is being processed
                </h4>
              ),
              body: isRenewal ? (
                <h6 className="headertxt">
                  Your renewal has been submitted. Your worker will follow-up if
                  additional information is necessary. Thank you.
                  {this.state.showRenewalMessage === 'true' &&
                    `Do you also want to complete a redetermination for other benefits ? By clicking on OK, you will be redirected to Benefit Renewal Screen where you can submit another Renewal.`}
                </h6>
              ) : (
                <>
                  <h6 className="headertxt">
                    You have successfully completed your Change Report. If you
                    have selected to receive electronic notifications, you will
                    be notified through My Messages of the status of your Change
                    Report.
                    <br />
                    <br />
                    {this.state.formContext.hasOtherOpenCases &&
                      `Note: Do you also want to report changes for your other case(s)? By clicking on OK, you will be redirected to My Changes to select another case where you would like to report changes.`}
                  </h6>
                </>
              )
            }
          })
        }
      })
      .catch(this.onUnAuthorizedError)
  }

  _getTrackerFormsfromstep = step => {
    switch (step) {
      case 1:
        return this.props.isRenewal
          ? 'RenewalWizardInfo'
          : 'LifeEventsWizardInfo'
      case 2:
        return this.props.isRenewal
          ? 'RenewalWizardPersonal'
          : 'LifeEventsWizardPersonal'
      case 3:
        return this.props.isRenewal
          ? 'RenewalWizardAdditionalInfo'
          : 'LifeEventsWizardAdditionalInfo'
      case 4:
        return this.props.isRenewal
          ? 'RenewalWizardIncome'
          : 'LifeEventsWizardIncome'
      case 5:
        return this.props.isRenewal
          ? 'RenewalWizardExpenses'
          : 'LifeEventsWizardExpenses'
      case 6:
        return this.props.isRenewal
          ? 'RenewalWizardAssets'
          : 'LifeEventsWizardAssets'
    }
  }

  _onValidate = (formData: any, errors: object) => {
    const { tabs, errorJson } = this.state
    let trackerForms = this.state.trackerForms
    if (this.state.step != 8) {
      const trackkeys = Object.keys(trackerForms)
      const rootid = this._getTrackerFormsfromstep(this.state.step)
      const rootIdKey = trackkeys.filter(key => {
        return key.includes(rootid)
      })
      trackerForms = _.pick(trackerForms, rootIdKey)
    }
    const newErrors = _.cloneDeep(errors)

    if (this.state.step === 1 || this.state.step === 8) {
      validation.validate_renewal(
        formData,
        newErrors,
        1,
        this.state.allPrograms,
        this.state.prgNames,
        this.props.isRenewal,
        this.state.currentdate,
        errorJson,
        this.state.formContext
      )
    }
    if (this.state.step !== 1) {
      //Call the PublicTrackerForm components' local validation since the top level does not have the appropriate context.
      if (
        (formData.application.closeCaseConfirmation !== 'Y' &&
          !this.props.isRenewal) ||
        this.props.isRenewal
      ) {
        _.forEach(trackerForms, (trackerForm, fieldId) => {
          const trackerFormErrors = [
            ...trackerForm.onValidateChanged(
              [customValidationErrors],
              errorJson
            ),
            ...trackerForm.onValidateAdded([customValidationErrors], errorJson)
          ]
          _.forEach(trackerFormErrors, formError => {
            const path = helper.idSchemaToPath(fieldId) + formError.property
            const arrayPath = path
              .split(new RegExp('\\.|\\[|\\]', 'g'))
              .filter(e => {
                return !_.isEmpty(e)
              })
            const step =
              getStepBySection(arrayPath[arrayPath.length - 5]) ||
              this.state.step

            let accordianName =
              _.startCase(arrayPath[arrayPath.length - 4]) + ': '
            if (!isNaN(+accordianName.split(':')[0])) {
              accordianName =
                _.startCase(arrayPath[arrayPath.length - 6]) + ': '
            }
            const formErrorStack = formError.stack.split(/-(.+)/)
            let errorMessages =
              formErrorStack[0] + ' - ' + accordianName + formErrorStack[1]

            let errorString = validator.createErrorJsonString(
              formError.message,
              step,
              _.join(arrayPath, '_'),
              errorMessages
            )

            if (_.includes(errorString, 'Relation Ship')) {
              errorString = _.replace(
                errorString,
                'Relation Ship',
                'Relationships'
              )
            }

            if (_.includes(errorString, 'Citizenship Immigration')) {
              errorString = _.replace(
                errorString,
                'Citizenship Immigration',
                'Citizenship/Immigration'
              )
            }

            if (_.includes(errorString, 'Shelter Expenses')) {
              errorString = _.replace(
                errorString,
                'Shelter Expenses',
                'Shelter Cost'
              )
            }

            if (_.includes(errorString, 'Utility Expenses')) {
              errorString = _.replace(
                errorString,
                'Utility Expenses',
                'Utility Cost'
              )
            }

            if (_.includes(errorString, 'Earned Incomes')) {
              errorString = _.replace(
                errorString,
                'Earned Incomes',
                'Earned Income'
              )
            }
            // Code Changes GPRB0359078 and US2554056
            if (_.includes(errorString, '- 0: Employer Address:')) {
              errorString = _.replace(
                errorString,
                '- 0: Employer Address:',
                '- Employer Address: '
              )
            }

            if (_.includes(errorString, '- 1: Employer Address:')) {
              errorString = _.replace(
                errorString,
                '- 1: Employer Address:',
                '- Employer Address: '
              )
            }

            if (_.includes(errorString, 'Self Employment Incomes')) {
              errorString = _.replace(
                errorString,
                'Self Employment Incomes',
                'Self-Employment Income'
              )
            }

            if (_.includes(errorString, 'Unearned Incomes')) {
              errorString = _.replace(
                errorString,
                'Unearned Incomes',
                'Unearned Income'
              )
            }

            if (_.includes(errorString, 'Soldtransfer Asset')) {
              errorString = _.replace(
                errorString,
                'Soldtransfer Asset',
                'Sold or Transferred Asset'
              )
            }

            if (_.includes(errorString, 'Health Insurance')) {
              errorString = _.replace(
                errorString,
                'Health Insurance',
                'Health Insurance Policy'
              )
            }

            if (_.includes(errorString, 'Heating Expenses')) {
              errorString = _.replace(
                errorString,
                'Heating Expenses',
                'Heating Information'
              )
            }

            if (_.includes(errorString, 'Cooling Expenses')) {
              errorString = _.replace(
                errorString,
                'Cooling Expenses',
                'Cooling Information'
              )
            }

            if (_.includes(errorString, 'Trusts')) {
              errorString = _.replace(errorString, 'Trusts', 'Trust')
            }

            if (_.includes(errorString, 'Investment Accounts')) {
              errorString = _.replace(
                errorString,
                'Investment Accounts',
                'Liquid Assets'
              )
            }

            if (_.includes(errorString, 'Lumpsum Payment')) {
              errorString = _.replace(
                errorString,
                'Lumpsum Payment',
                'Lump Sum Payment'
              )
            }

            if (_.includes(path, 'earnedIncomes')) {
              errorString = _.replace(
                errorString,
                'Tracker Change',
                'Heating Information'
              )
            }
            if (_.includes(path, 'heatingExpenses')) {
              errorString = _.replace(
                errorString,
                'Tracker Change',
                'Heating Information'
              )
            }

            if (_.includes(path, 'heatingExpenses')) {
              errorString = _.replace(
                errorString,
                'Tracker Add',
                'Heating Information'
              )
            }

            // Code Changes GPRB0359078 and US2554056
            if (_.includes(path, 'utilityExpenses')) {
              errorString = _.replace(
                errorString,
                'Tracker Change',
                'Utility Cost'
              )
            }

            if (_.includes(path, 'shelterExpenses')) {
              errorString = _.replace(
                errorString,
                'Tracker Change',
                'Shelter Cost'
              )
            }

            if (_.includes(path, 'healthInsurance')) {
              errorString = _.replace(
                errorString,
                'Tracker Change',
                'Health Insurance Policy Address'
              )
            }

            if (_.includes(path, 'dependentCareExpenses')) {
              errorString = _.replace(
                errorString,
                'Tracker Change',
                'Dependent Care'
              )
            }

            if (_.includes(errorString, 'Other Assets')) {
              errorString = _.replace(errorString, 'Other Assets', 'Other')
            }

            if (_.includes(errorString, 'Other Incomes')) {
              errorString = _.replace(
                errorString,
                'Other Incomes',
                'Other Income'
              )
            }

            const existingErrors = _.get(
              newErrors,
              _.join(arrayPath, '.') + '.__errors',
              []
            )

            _.set(newErrors, _.join(arrayPath, '.') + '.__errors', [
              ...existingErrors,
              errorString
            ])
          })
        })
      }
    }
    //Determine which tabs contain errors and update their statuses.
    let foundErrors = helper.findErrors(newErrors)
    let updatedTabs = false
    if (foundErrors && foundErrors.length > 0) {
      this.hasError = true
      let newTabs = [...tabs]
      for (let i = 0; i < foundErrors.length; ++i) {
        try {
          let errorObject = JSON.parse(foundErrors[i])
          let errorStep = errorObject.step - 1
          if (newTabs[errorStep].status !== 'error') {
            newTabs[errorStep].status = 'error'
            newTabs[errorStep].rightIcon = errorIcon
            newTabs[errorStep].rightSpan = errorBadge
            updatedTabs = true
          }
        } catch (err) {
          console.error('Error on parsing errors', err)
        }
      }
    } else {
      this.hasError = false
    }

    return newErrors
  }

  orderErrors = (errors, masterSchema, uiSchema): any[] => {
    const orderArray = _.uniq(this.buildOrderArray(masterSchema))

    const indexRegex = new RegExp(/_([0-9]+)_/)
    const errorKeyMsgRegex = new RegExp(/^([a-zA-Z0-9]+): (.*)/)

    const newErrorStackQueue = []
    errors.map(error => {
      const errorResult = error.stack.match(errorKeyMsgRegex)
      const errorKey = errorResult[1]
      let errorContentsObj
      try {
        errorContentsObj = JSON.parse(errorResult[2])
      } catch (err) {
        errorContentsObj = {}
      }

      const index = _.get(
        _.get(errorContentsObj, 'id', '').match(indexRegex),
        '1',
        '0'
      )

      if (newErrorStackQueue[index] === undefined) {
        for (let i = 0; i <= index; i++) {
          if (newErrorStackQueue[i] === undefined) {
            newErrorStackQueue.push([])
          }
        }
      }

      newErrorStackQueue[index].push({
        index: _.indexOf(orderArray, errorKey),
        value: errorKey,
        stack: error.stack
      })
    })

    let tempErrorObjList = []
    for (let i = 0; i < newErrorStackQueue.length; i++) {
      tempErrorObjList = _.concat(
        tempErrorObjList,
        _.sortBy(newErrorStackQueue[i], ['index'])
      )
    }

    const orderedErrors = _.forEach(tempErrorObjList, obj => obj.stack)

    return orderedErrors
  }

  buildOrderArray = (localSchema, orderArray = []) => {
    if (localSchema) {
      switch (localSchema['type']) {
        case 'object':
          this.buildOrderArray(localSchema['properties'], orderArray)
          break
        case 'array':
          this.buildOrderArray(localSchema['items'], orderArray)
          break
        case undefined:
          if (_.isObject(localSchema)) {
            _.forEach(localSchema, (v, k) => {
              switch (v['type']) {
                case 'object':
                  if (
                    _.isEmpty(
                      _.xor(_.keys(v['properties']), [
                        'category',
                        'subCategory',
                        'languageCode',
                        'key',
                        'value',
                        'rulesEngineCode',
                        'sortOrder'
                      ])
                    )
                  ) {
                    orderArray.push(k)
                    break
                  }
                  this.buildOrderArray(v['properties'], orderArray)
                  break
                case 'array':
                  if (
                    _.isEmpty(
                      _.xor(_.keys(_.get(v, 'items.properties')), [
                        'category',
                        'subCategory',
                        'languageCode',
                        'key',
                        'value',
                        'rulesEngineCode',
                        'sortOrder'
                      ])
                    ) ||
                    _.get(v, 'items.enumNames', []).length > 0
                  ) {
                    orderArray.push(k)
                    break
                  }
                  this.buildOrderArray(v['items'], orderArray)
                  break
                default:
                  orderArray.push(k)
              }
            })
          } else {
            console.log('buildOrderArray localSchema not object', localSchema)
          }
      }
    }
    return orderArray
  }

  _errorListTemplate = (props: any) => {
    const { errors, schema, uiSchema } = props

    const masterSchema = helper.resolveSchemaDefinitions(
      schema,
      schema.definitions
    )
    let orderedErrors
    if (this.state.step == 1 || this.state.step == 3) {
      orderedErrors = this.orderErrors(
        _.cloneDeep(errors),
        masterSchema,
        uiSchema
      )
    } else {
      orderedErrors = _.cloneDeep(errors)
    }

    return (
      <div className="panel panel-danger errors lifevent-errors" tabIndex={-1}>
        <div className="panel-heading">
          <span className="panel-title">{I18n.t('General.labelErrors')}</span>
        </div>
        <ul className="list-group">
          {orderedErrors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.
            let errorObject = null

            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'earnedIncomes')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Earned Income'
                )
              }
              if (
                _.includes(errorObject.displayMessage, 'Other: ') &&
                _.includes(errorObject.id, 'otherAssets')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Other: ',
                  'Other Assets: '
                )
              }
              // Code Changes GPRB0359078 and US2554056
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'dependentCareExpenses')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Dependent Care'
                )
              }
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'utilityExpenses')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Utility Cost'
                )
              }
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'shelterExpenses')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Shelter Cost'
                )
              }
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'healthInsurance')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Health Insurance Policy Address'
                )
              }
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'dependent_')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Dependent Care'
                )
              }
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <li className="list-group-item text-danger" key={i}>
                <a
                  href="#"
                  onClick={
                    errorObject ? () => this._onErrorClick(errorObject) : null
                  }
                >
                  {errorObject
                    ? errorObject.displayMessage
                    : error.stack.substring(error.stack.indexOf(':') + 1)}
                  <i
                    style={{ marginLeft: '5px' }}
                    className="fa fa-eye"
                    aria-hidden="true"
                  />
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  _onUpdateCarouselSlides = (slideIndex, slides) => {
    let oldSlidesLength = this.state.slides.length
    let newSlidesLength = slides.length
    let showNext = this.state.showNext
    let oldSlideIndex = this.state.slideIndex
    const { formContext } = this.state
    const { formData } = formContext
    if (
      newSlidesLength > 0 &&
      oldSlidesLength !== newSlidesLength &&
      this.props.step === 1 &&
      Object.keys(formData).length > 0
    ) {
      this.setState({ slideIndex, slides: _.cloneDeep(slides) })
    }
    if (
      oldSlideIndex !== slideIndex &&
      this.props.step === 1 &&
      Object.keys(formData).length > 0
    ) {
      this.setState({ slideIndex })
    }
    if (this.state.formContext.slideIndex) {
      this.setState(prevState => {
        return {
          ...prevState,
          formContext: {
            ...prevState.formContext,
            slideIndex: null
          }
        }
      })
    }
  }

  _onErrorClick = (error: any) => {
    const { forms, step } = this.state
    let clientIndex = error.id.match('application_clients_([0-9]+)')
    if (clientIndex) {
      clientIndex = clientIndex[1]
      let section = 'LifeEventsWizard'
      if (this.props.isRenewal) {
        section = 'RenewalWizard'
      }
      const expandLinks = [
        `${section}Personal_application_clients_${+clientIndex}_clientDetails_expandLink`,
        `${section}AdditionalInfo_application_clients_${+clientIndex}_clientAdditionalInformation_expandLink`,
        `${section}Income_application_clients_${+clientIndex}_clientIncomes_expandLink`,
        `${section}Expenses_application_clients_${+clientIndex}_clientExpenses_expandLink`,
        `${section}Assets_application_clients_${+clientIndex}_clientAssets_expandLink`
      ]
      expandLinks.forEach(expandLinkId => {
        if (document.getElementById(expandLinkId) != null) {
          document.getElementById(expandLinkId).click()
        }
      })
    } else {
      clientIndex = ''
    }

    this.setState(
      {
        formContext: {
          ...this.state.formContext,
          accordionActiveId:
            forms[error.step - 1].uiSchema['ui:rootFieldId'] +
            '_application_clients',
          accordionActiveKey: +clientIndex
        }
      },
      () => {
        setTimeout(() => {
          //Change current step to the step of the clicked error.
          if (step !== error.step) {
            this.setState({ step: error.step })
          }

          //Set a viewId state to be used for scrolling down after the React DOM finishes updating.
          if (step === 1) {
            const searchKey = error.id.split('_')[1]
            let slideInd = forms[0].uiSchema.application[
              'ui:options'
            ].slides.findIndex(slide =>
              slide.localUiSchema.hasOwnProperty(searchKey)
            )
            if (!this.state.hasSpecialNeeds && slideInd > 3) {
              slideInd--
            }

            this.setState(prevState => {
              return {
                ...prevState,
                viewId:
                  forms[error.step - 1].uiSchema['ui:rootFieldId'] +
                  '_' +
                  error.id,
                formContext: {
                  ...prevState.formContext,
                  slideIndex: slideInd || null
                }
              }
            })

            if (
              slideInd !=
              forms[0].uiSchema.application['ui:options'].slides.length - 1
            ) {
              this.setState({ showNext: false })
            } else {
              this.setState({ showNext: true })
            }
          } else {
            this.setState({
              viewId:
                forms[error.step - 1].uiSchema['ui:rootFieldId'] +
                '_' +
                error.id
            })
          }

          if (this.state.slideIndex < this.state.slides.length - 1) {
            this.setState({
              showNext: false
            })
          }
        }, 1000)
      }
    )
  }

  _onError = (errors: object) => {
    const { liveValidate, formContext, step } = this.state

    //Enable live validation upon error to allow for realtime evaluation of errors in response to formData changes.
    if (!liveValidate) {
      this.setState({
        liveValidate: true,
        formContext: {
          ...formContext,
          hasError: this.hasError
        }
      })
      window.location.href = '#'
    }
    const errorContent = document.getElementsByClassName(
      'lifevent-errors'
    ) as HTMLCollectionOf<HTMLElement>
    const errorContentDiv =
      errorContent.length == 1
        ? errorContent[0]
        : step === errorContent.length
        ? errorContent[errorContent.length - 1]
        : errorContent[step - 1]
    if (errorContentDiv) {
      window.scroll(0, errorContentDiv.offsetTop)
      errorContentDiv.focus()
    }
  }

  _subscribeTrackerForm = (
    fieldId: string,
    onValidateChanged: Function,
    onValidateAdded: Function
  ) => {
    this.setState(prevState => {
      return {
        trackerForms: {
          ...prevState.trackerForms,
          [fieldId]: {
            onValidateChanged,
            onValidateAdded
          }
        }
      }
    })
  }

  _unsubscribeTrackerForm = (fieldId: string) => {
    this.setState(prevState => {
      return {
        trackerForms: _.omit(prevState.trackerForms, [fieldId])
      }
    })
  }

  _onDocUpload = (uploadedDoc: any) => {
    if (uploadedDoc.doc.ptype === 'myDocuments') {
      const clients = this.state.formData.application.clients.slice()
      const client = clients[uploadedDoc.clientIndex]
      const docList =
        client.myDocuments &&
        client.myDocuments[uploadedDoc.documentClssIndex].docList
      if (!client.myDocuments) {
        client.myDocuments = JSON.parse(
          JSON.stringify([
            {
              docClssNm: 'Uploaded Documents',
              docList: [],
              firstName: ''
            }
          ])
        )
      }
      if (client.myDocuments) {
        if (
          uploadedDoc.doc.typeRefCode === '2' ||
          uploadedDoc.doc.typeRefCode === '18'
        ) {
          const existingDocument =
            docList &&
            docList.find(document => {
              return document.typeRefCode === uploadedDoc.doc.typeRefCode
            })
          if (existingDocument) {
            const removalIndex = _.indexOf(docList, existingDocument)
            client.myDocuments[uploadedDoc.documentClssIndex].docList.splice(
              removalIndex,
              1
            )
            client.myDocuments[uploadedDoc.documentClssIndex].docList.push(
              uploadedDoc.doc
            )
          } else {
            client.myDocuments &&
              client.myDocuments[uploadedDoc.documentClssIndex].docList.push(
                uploadedDoc.doc
              )
          }
        } else {
          client.myDocuments &&
            client.myDocuments[uploadedDoc.documentClssIndex].docList.push(
              uploadedDoc.doc
            )
        }
      }

      this.updateUploadedDocs(clients)
    } else {
      const clients = this.state.formData.application.clients.slice()
      const client = clients[uploadedDoc.clientIndex]
      const docList =
        client.myDocumentsChildCare &&
        client.myDocumentsChildCare[uploadedDoc.documentClssIndex].docList
      if (!client.myDocumentsChildCare) {
        client.myDocumentsChildCare = JSON.parse(
          JSON.stringify([
            {
              docClssNm: 'Uploaded Documents',
              docList: [],
              firstName: ''
            }
          ])
        )
      }
      if (client.myDocumentsChildCare) {
        client.myDocumentsChildCare &&
          client.myDocumentsChildCare[
            uploadedDoc.documentClssIndex
          ].docList.push(uploadedDoc.doc)
      }
      this.updateUploadedDocs(clients)
    }
  }

  updateUploadedDocs(clients) {
    // clients.forEach(client => {
    //   client.myDocuments.forEach(doc => {
    //     doc.docList.forEach(d => {
    //       d.docType = d.typeRefCode.value
    //     })
    //   })
    // })
    this.setState(
      {
        formContext: {
          ...this.state.formContext,
          formData: {
            ...this.state.formContext.formData,
            application: {
              ...this.state.formContext.formData.application,
              clients
            }
          }
        }
      },
      () => {
        this._onFormDataChange(this.state.formContext.formData, false)
      }
    )
  }

  _onUploadDelete = (deletedDoc: any) => {
    if (deletedDoc.pType === 'myDocuments') {
      const clients = this.state.formData.application.clients.slice()
      for (let i = 0; i < clients.length; i++) {
        const client = clients[i]
        const clientName =
          client.clientFstNm +
          (client.clientLstNm ? ' ' + client.clientLstNm : '')
        if (deletedDoc.clientName === clientName) {
          client.myDocuments[deletedDoc.documentClssIndex].docList.splice(
            deletedDoc.docIndex,
            1
          )
          break
        }
      }

      this.updateUploadedDocs(clients)
    } else {
      const clients = this.state.formData.application.clients.slice()
      for (let i = 0; i < clients.length; i++) {
        const client = clients[i]
        const clientName =
          client?.clientDetails?.personalData[0]?.clientFstNm +
          (client?.clientDetails?.personalData[0]?.clientLstNm
            ? ' ' + client.clientDetails.personalData[0].clientLstNm
            : '')
        if (deletedDoc.clientName === clientName) {
          client.myDocumentsChildCare[0].docList.splice(deletedDoc.docIndex, 1)
          break
        }
      }

      this.updateUploadedDocs(clients)
    }
  }

  _onExitClick = () => {
    const { updateMyCocApplicationStep } = this.props
    updateMyCocApplicationStep(1)
    this.setState({
      modalProps: {
        show: true,
        bsSize: 'lg',
        className: 'lg-width-to-md',
        cancel: () => {
          this.setState({
            modalProps: {
              show: false,
              cancel: null,
              confirm: null,
              title: null,
              body: null,
              confirmBtnText: null,
              closeBtnText: null
            }
          })
        },
        confirm: () => {
          this.setState({
            redirect:
              baseUrl +
              (this.props.isRenewal
                ? '/myaccount/benefits'
                : '/myaccount/lifeevents')
          })
        },
        confirmBtnText: 'Yes',
        closeBtnText: 'No',
        title: <h4 className="popup-title"> Exit</h4>,
        body: (
          <h6 className="headertxt">
            Are you sure you want to exit? Changes on this screen will not be
            saved.
          </h6>
        )
      }
    })
  }

  _panelEditLink = fieldId => {
    const tab = fieldId.match('_clients_[0-9]+_([a-zA-Z]+)_?')[1]
    const step = getStepBySection(tab) || this.state.step
    this.setState(
      {
        step
      },
      () => {
        window.location.href = '#'
      }
    )
  }

  _checkChildCareTabEnable = fieldId => {
    let { caseInfo } = this.state

    return (
      caseInfo &&
      _.some(caseInfo['clientBenfGrps'], element => {
        return element.benfProgCd === 'CCA'
      })
    )
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _changeActiveAccordionKey = activeKey => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        accordionActiveKey: activeKey
      }
    })
  }

  _isClientEligibleForCHCR = clientIndex => {
    let { formData, caseInfo } = this.state

    return (
      caseInfo &&
      _.some(caseInfo['clientBenfGrps'], element => {
        return (
          element.benfProgCd == 'CCA' &&
          element.clientId == formData.application.clients[clientIndex].id
        )
      })
    )
  }

  _onConfirmMessage = () => {
    this.setState({ showDraftModal: false })
  }

  _onNonConfirmMessage = () => {
    this.setState({ showNonDraftModal: false })
  }

  closeAlertModal = () => {
    this.setState({ alertModalFlag: false })
  }

  render() {
    const { step, forms, formContext, redirect } = this.state
    const { presentation, errorMessage } = this.props
    if (redirect) {
      return (
        <Redirect
          to={{ pathname: redirect, state: { from: this.props.location } }}
        />
      )
    }

    let validator = null
    if (forms && step && formContext) {
      validator = new CustomValidator(
        forms[step - 1].schema,
        forms[step - 1].uiSchema,
        {
          ...formContext,
          refs: {
            ...formContext.refs,
            '{tracker mode}': 'return "VIEW";'
          }
        }
      )
    }
    const presentationProps = {
      STEPS,
      HELPSTEPS,
      HELPSTEPSRENEWAL,
      ..._.omit(this.props, 'presentation'),
      ...this.state,
      onPrevious: this._onPrevious,
      onExitClick: this._onExitClick,
      saveFormDataDraft: this._saveFormDataDraft,
      changeView: this._changeView,
      onDocUpload: this._onDocUpload,
      onUploadDelete: this._onUploadDelete,
      onSubmit: this._onSubmit,
      onConfirmMessage: this._onConfirmMessage,
      onNonConfirmMessage: this._onNonConfirmMessage,
      errorListTemplate: this._errorListTemplate,
      onNormalChange: this._onNormalChange,
      onValidate: this._onValidate,
      onErrorClearingChange: this._onErrorClearingChange,
      onError: this._onError,
      validator,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      hasError: this.hasError,
      exitDomesticViolenceModal: this._exitDomesticViolenceModal,
      closeAlertModal: this.closeAlertModal,
      alertModalFlag: this.state.alertModalFlag
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps, step) {
  if (state.mycocapplication.mycocapplication) {
    step = state.mycocapplication.mycocapplication.step || 1
  }
  let orgId =
    _.get(state.userAccess, 'selectedUserRole.organization.orgId') || ''
  let roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  let roleName = _.get(state.userAccess, 'selectedUserRole.role.roleName') || ''
  let selectedEntitlements = _.get(
    state.userAccess,
    'selectedUserRole.entitlements',
    []
  ).map(element => element.entitlementName)
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  let updatedCOCFormData = state.mycocapplication.mycocapplication || []

  // let ieOrgId = _.get(state.userAccess, 'userRoles[0].organization.orgId') || ''
  let userAccess = _.get(state, 'userAccess') || ''
  let userRoleIdOrgIdList = _.get(state, 'userAccess.userRoleIdOrgIdList') || []
  let ieOrgId, userRoleId
  let currentuserRoleIdOrgIdRecord = _.find(userRoleIdOrgIdList, current => {
    return (
      _.get(current, 'orgId') ==
        _.get(state.userAccess, 'selectedOrg.ieOrgId') &&
      _.get(current, 'rolename') == roleName
    )
  })
  ieOrgId = _.get(currentuserRoleIdOrgIdRecord, 'orgId', '')
  userRoleId = _.get(currentuserRoleIdOrgIdRecord, 'userRoleId', '')
  if (!userRoleId) {
    userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  }

  let states = _.get(state, 'statesCounties.states', [])
  let counties = _.get(state, 'statesCounties.counties', [])

  return {
    auth: state.auth,
    orgId,
    userRoleId,
    roleId,
    roleName,
    selectedEntitlements,
    step,
    errorMessage,
    updatedCOCFormData,
    ieOrgId,
    locale: state.i18n.locale,
    states,
    counties,
    myOpencases: _.get(state, 'myCases.myOpenCasesInformation')
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setActiveApplicationId: applId => {
      dispatch(actions.setActiveApplicationId(applId))
    },
    updateMyCocApplicationStep: step => {
      dispatch(actions.updateMyCocApplicationStep(step))
    },
    assignApplicationId: applId => {
      dispatch(actions.assignApplicationId(applId))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    updatedFormData: formData => {
      dispatch(actions.updatedFormData(formData))
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientUpdateWizardContainer)

function _tabStatus(tabs, json) {
  let tabStatus = tabs
  if (json.hasOwnProperty('tabStatus')) {
    for (let i = 0; i < tabStatus.length; i++) {
      for (let j = 0; j < json.tabStatus.length; j++) {
        if (tabStatus[i].title == json.tabStatus[j].name) {
          tabStatus[i].status = json.tabStatus[j].status
        }
      }
    }
  }

  for (let i = 0; i < tabStatus.length; i++) {
    switch (tabStatus[i].status) {
      case 'validated':
        tabStatus[i].rightIcon = validatedIcon
        tabStatus[i].rightSpan = validatedBadge
        break
      case 'editing':
        tabStatus[i].rightIcon = editingIcon
        tabStatus[i].rightSpan = editingBadge
        break
      case 'error':
        tabStatus[i].rightIcon = errorIcon
        tabStatus[i].rightSpan = errorBadge
        break
      case 'pristine':
      default:
        tabStatus[i].rightIcon = pristineIcon
        tabStatus[i].rightSpan = null
    }
  }

  return tabStatus
}
