import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const ApplicationSearchContainer = MultitenantLoadable(
  () => import('./ApplicationSearchContainer'),
  () =>
    import(
      '../../' +
        tenantDirectoryName +
        '/ApplicationSearch/ApplicationSearchContainer'
    )
)

const ApplicationSearchPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./ApplicationSearchPresentation'),
    () => import(config)
  ),
  () => import('./formComponents'),
  () => import(config)
)

export default function ApplicationSearch(props) {
  return (
    <ApplicationSearchContainer
      {...props}
      presentation={presentationProps => (
        <ApplicationSearchPresentation {...presentationProps} />
      )}
    />
  )
}
