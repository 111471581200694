import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash' // extra functionality
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
import * as actions from '../../../actions'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError } from '../src/components/utils'
import { I18n } from 'react-redux-i18n'
const baseUrl = process.env.PUBLIC_URL

const formsBaseUrl = config['formsBaseUrl']
const gatewayWvUrl = config['gatewayWvUrl']

const loadEndpoint = gatewayWvUrl + config['accessControl']
const submitEndpoint = gatewayWvUrl + config['UpdatedPermissionList']
const formsEndpoint = formsBaseUrl + config['forms_MyNewPermission']

interface MyPermissionContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  roleId: any
  orgId: any
  logoutUser
  history: {
    push: (url: string) => void
  }
  locale: string
  hasCCA
}

interface MyPermissionContainerState {
  application: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formContext: any
  reqCounty: string
  formData: object
  completeJson: any
}

class MyPermissionContainer extends React.Component<
  MyPermissionContainerProps,
  MyPermissionContainerState
> {
  constructor(props: MyPermissionContainerProps) {
    super(props)

    this.state = {
      application: {},
      forms: null,
      formContext: {
        formData: {}
      },
      reqCounty: null,
      formData: null,
      completeJson: null
    }
  }

  componentDidMount() {
    document.title = I18n.t('MyPermission.docTitle')
    this._loadForms()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      document.title = I18n.t('MyPermission.docTitle')
      this._loadForms()
    }
  }

  _postApplicationJSON = () => {}
  _onFormDataChange = ({ formData }) => {
    this.setState({ formData })
  }

  _updatePermissions = ({ formData }) => {
    const params = {
      accessControl: formData
    }
    serviceHelper
      .fetchJson(
        submitEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId,
            programType: this.props.hasCCA ? 'CCA' : 'FA'
          },
          body: JSON.stringify(params)
        },
        this.props.showErrorMessage
      )
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
      })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _loadForms = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        locale: (this.props.locale + '_US').toUpperCase()
      }
    }
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(formJson => {
        let application, forms, formContext
        application = formJson['app']
        forms = application['forms']
        this.setState(prevState => {
          return {
            application,
            forms,
            formContext: {
              ...prevState.formContext,
              forms: forms,
              refs: {
                '{case summary}': ({ fieldId, formContext }) => {
                  try {
                    const index = fieldId.match(/\d+/g)
                    let gender = this.state.completeJson.accessControl[
                      parseInt(index[0], 10)
                    ].adultPerson[parseInt(index[1], 10)].gender
                    if (gender == 'Male') {
                      gender = I18n.t('MyPermission.male')
                    } else if (gender == 'Female') {
                      gender = I18n.t('MyPermission.female')
                    }
                    return (
                      this.state.completeJson.accessControl[
                        parseInt(index[0], 10)
                      ].adultPerson[parseInt(index[1], 10)].flName +
                      ' ' +
                      this.state.completeJson.accessControl[
                        parseInt(index[0], 10)
                      ].adultPerson[parseInt(index[1], 10)].age +
                      ' ' +
                      gender
                    )
                  } catch (err) {
                    return I18n.t('MyPermission.caseIDUNKNOWN')
                  }
                },
                '{primaryPermissions}': ({ fieldId, formContext }) => {
                  let index =
                    fieldId.match('(?:.*)myPermissions_([0-9]+)_?') &&
                    fieldId.match('(?:.*)myPermissions_([0-9]+)_?')[1]
                  let index2 =
                    fieldId.match('(?:.*)adultPerson_([0-9]+)_?') &&
                    fieldId.match('(?:.*)adultPerson_([0-9]+)_?')[1]
                  let index3 =
                    fieldId.match('(?:.*)details_([0-9]+)_?') &&
                    fieldId.match('(?:.*)details_([0-9]+)_?')[1]
                  try {
                    let condition_1 =
                      formContext.formData[index].adultPerson[index2].details[
                        index3
                      ].identifier ==
                      formContext.formData[index].adultPerson[index2].identifier
                    let condition_2 =
                      formContext.formData[index].primaryCaseDetails
                        .identifier ==
                      formContext.formData[index].adultPerson[index2].identifier
                    if (condition_1 || condition_2) {
                      return true
                    }
                  } catch (err) {
                    return true
                  }
                },
                '{case details}': ({ fieldId, formContext }) => {
                  try {
                    const index = fieldId.match('^myPermissions_([0-9]+)_?')[1]
                    let gender = this.state.completeJson.accessControl[
                      parseInt(index[0], 10)
                    ].primaryCaseDetails.gender
                    if (gender == 'Male') {
                      gender = I18n.t('MyPermission.male')
                    } else if (gender == 'Female') {
                      gender = I18n.t('MyPermission.female')
                    }

                    const caseDetails = this.state.completeJson.accessControl[
                      parseInt(index, 10)
                    ].primaryCaseDetails
                    const benefitType =
                      this.state.completeJson.accessControl[parseInt(index, 10)]
                        .programType === 'CCA'
                        ? I18n.t('General.title3')
                        : I18n.t('General.title4')

                    return (
                      caseDetails.extCaseNumber +
                      ' ' +
                      benefitType +
                      ' ' +
                      caseDetails.fullName +
                      ' ' +
                      gender
                    )
                  } catch (err) {
                    return I18n.t('MyPermission.caseIDUNKNOWN')
                  }
                }
              },
              locale: this.props.locale
            }
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.error('CPS form fetch failed due to ex', error)
      })

    serviceHelper
      .fetchJson(
        loadEndpoint,
        {
          method: 'GET',
          headers: {
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            'Content-Type': 'application/json',
            roleId: this.props.roleId,
            orgId: this.props.orgId,
            programType: this.props.hasCCA ? 'CCA' : 'FA',
            locale: (this.props.locale + '_US').toUpperCase()
          }
        },
        this.props.showErrorMessage,
        0,
        60000
      )
      .then(json => {
        json.accessControl.forEach(access => {
          _.get(access, 'adultPerson', []).forEach(person => {
            _.get(person, 'details', []).forEach(detail => {
              if (detail.gender == 'Male') {
                detail.gender = I18n.t('MyPermission.male')
              } else if (detail.gender == 'Female') {
                detail.gender = I18n.t('MyPermission.female')
              }
            })
          })
        })
        if (json) {
          return this.setState(prevState => {
            return {
              ...prevState,
              completeJson: json,
              formContext: {
                ...prevState.formContext,
                formData: json.accessControl
              },
              formData: json.accessControl
            }
          })
        }
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        return ex
      })
  }
  render() {
    const { presentation, minHeight, isLoading, errorMessage } = this.props
    const { application, formData, formContext, forms, reqCounty } = this.state

    const presentationProps = {
      application,
      formData,
      formContext,
      forms,
      reqCounty,
      isLoading,
      minHeight,
      onFormDataChange: this._onFormDataChange,
      updatePermissions: this._updatePermissions,
      errorMessage,
      onDeleteClick: this._onDeleteClick
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''

  return {
    auth: state.auth,
    userRoleId,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || [],
    roleId,
    orgId,
    locale: state.i18n.locale,
    hasCCA: _.get(state, 'hasCCA.hasCCA')
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyPermissionContainer)
