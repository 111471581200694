import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { isEqual, get, isNil, cloneDeep, forEach } from 'lodash'
import { config } from '~/config'
import { I18n } from 'react-redux-i18n'
import { refs } from '~/refs'
import { ClientPortalException, CODES, shouldThrow } from '../Errors'

const _ = { isEqual, get, isNil, cloneDeep, forEach }

declare const process
const baseUrl = process.env.PUBLIC_URL

const loadAppEndpoint =
  config['gatewayBaseUrl'] + config['getBenefitApplicationsDetails']
const viewApplicationEndpoint = config['viewApplication']
const copyNSaveEndpoint = config['copyNSaveApplication']
const formsEndpoint = config['forms_MyApplications']
const appIntakeEndpoint = config['forms_AppIntake']
const deleteDraftEndPoint = config['deleteDraftEndPoint']
const docuSignedPdf = config['retrieveDocuSignPdf']
const _pagination = { recordsPerPage: 20, pageIndex: 1 }

const ASSISTANCE_TYPES = {
  en: {
    temporaryAssistanceForNeedyFamilies:
      'Temporary Assistance for Needy Families',
    healthcare: 'Health Care',
    foodAndNutrition: 'Food & Nutrition (SNAP)',
    homeEnergyAssistance: 'Home Energy Assistance (LIEAP)',
    childCareServices: 'Child Care Services',
    childSupportServices: 'Child Support Services',
    medicarePremiumAssistance: 'Medicare Premium Assistance'
  },
  es: {
    temporaryAssistanceForNeedyFamilies:
      'Asistencia Temporal para Familias Necesitadas (Temporary Assistance for Needy Families, TANF)',
    healthcare: 'Cuidado de la salud',
    foodAndNutrition: 'Alimentos y Nutrición (SNAP)',
    homeEnergyAssistance: 'Asistencia de Energía para el Hogar (LIEAP)',
    childCareServices: 'Servicios de cuidado de niños',
    childSupportServices: 'Servicios de manutención infantil',
    medicarePremiumAssistance: 'Asistencia para las primas de Medicare'
  }
}

interface MyApplicationsContainerProps {
  presentation: any
  selectedEntitlements: Array<string>
  uuid: string
  userRoleId: number
  locale: string
  orgId: string
  authHeader: string
}

interface MyApplicationsContainerState {
  application: any
  formData: any
  forms: Array<{
    schema: any
    uiSchema: any
  }>
  formContext: any
  reviewFormContext: any
  redirect: string
  retroActiveRequest: boolean
  applicationWithdrawn: boolean
  callingApi: boolean
  pagination: any
}

class MyApplicationsContainer extends React.Component<
  MyApplicationsContainerProps,
  MyApplicationsContainerState
> {
  constructor(props: MyApplicationsContainerProps) {
    super(props)

    this.state = {
      application: {},
      formData: {},
      forms: null,
      formContext: null,
      reviewFormContext: null,
      redirect: null,
      retroActiveRequest: null,
      applicationWithdrawn: null,
      callingApi: false,
      pagination: null
    }
  }

  componentDidMount() {
    this._loadApp()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      this.setState({ callingApi: true })
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(json => {
          this.setState({ callingApi: false })
          const forms = json['app']['forms']
          for (let i = 0; i < forms.length; ++i) {
            forms[i]['schema'] = helper.schemaIntersect(
              forms[i]['schema'],
              forms[i]['uiSchema']
            )
          }
          this.setState(prevState => {
            return {
              forms,
              formContext: {
                ...prevState.formContext,
                forms
              }
            }
          })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('MyApplications form re-fetch failed due to ex', error)
          const code = CODES.MY_APPLICATIONS_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })

      helper
        .fetchJson(appIntakeEndpoint.replace('{version}', '1.0'), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            tenantCode: config.tenant.code,
            portalName: config.portalName,
            uuid: this.props.uuid,
            Authorization: this.props.authHeader
          }
        })
        .then(json => {
          this.setState(prevState => {
            return {
              reviewFormContext: {
                ...prevState.reviewFormContext,
                reviewForms: json['app'].forms
              }
            }
          })
        })
        .catch(error => {
          console.error(
            'MyApplications review form fetch failed due to ex',
            error
          )
          const code = CODES.MY_APPLICATIONS_REFETCH_REVIEW_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })

      this.setState({
        formData: this._updateFormDataByLocale(
          prevProps.locale,
          this.props.locale,
          this.state.formData
        )
      })
    }
  }

  _loadApp = () => {
    this._getFormDataJSON()
  }

  _setRedirect = (path: string) => {
    this.setState({ redirect: path })
  }
  _getFormDataJSON = () => {
    helper
      .fetchJson(loadAppEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          userRoleId: this.props.userRoleId.toString(),
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        },
        body: JSON.stringify({
          pagination: _pagination
        })
      })
      .then(jsonData => {
        helper
          .fetchJson(formsEndpoint.replace('{version}', '1.0'), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              locale: (this.props.locale + '_us').toUpperCase(),
              tenantCode: config.tenant.code,
              portalName: config.portalName,
              uuid: this.props.uuid,
              Authorization: this.props.authHeader
            }
          })
          .then(formJson => {
            this._loadAll(formJson, jsonData)
          })
          .catch(error => {
            console.error('MyApplications form fetch failed due to ex', error)
            const code = CODES.MY_APPLICATIONS_FETCH_FORM
            if (shouldThrow(code)) {
              this.setState(() => {
                if (error instanceof helper.IEServiceError) {
                  throw error
                } else {
                  throw new ClientPortalException(error, code)
                }
              })
            }
          })
      })
      .catch(error => {
        console.error('MyApplications _getFormDataJSON failed with ex', error)
        const code = CODES.MY_APPLICATIONS_FETCH_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _copyNredirect = (applicationId: number) => {
    const fetchEndpoint = copyNSaveEndpoint.replace(
      '{applId}',
      applicationId.toString()
    )
    helper
      .fetchJson(fetchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          userRoleId: this.props.userRoleId.toString(),
          orgId: this.props.orgId,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        }
      })
      .then(jsonData => {
        // load json into form
        this._setRedirect(jsonData)
      })
      .catch(error => {
        console.error('MyApplications _copyNredirect failed with ex', error)
        const code = CODES.MY_APPLICATIONS_COPY_N_SAVE
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _deleteDraft = (applicationId: number) => {
    const fetchEndpoint = deleteDraftEndPoint.replace(
      '{applId}',
      applicationId.toString()
    )
    helper
      .fetchJson(fetchEndpoint, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(jsonData => {
        // load json into form
        this._loadApp()
      })
      .catch(error => {
        console.error('MyApplications _deleteDraft failed with ex', error)
        const code = CODES.MY_APPLICATIONS_DELETE_DRAFT
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _viewApplicationDetails = (id: string, applicationId: number) => {
    const fetchEndpoint = viewApplicationEndpoint.replace(
      '{applId}',
      applicationId.toString()
    )

    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(jsonData => {
        helper.cleanNullValues(jsonData)
        this.setState(prevState => {
          return {
            reviewFormContext: {
              ...prevState.reviewFormContext,
              formData: jsonData,
              reviewFormData: jsonData
            }
          }
        })
      })
      .catch(error => {
        console.error(
          'MyApplications _viewApplicationDetails failed with ex',
          error
        )
        const code = CODES.MY_APPLICATIONS_FETCH_DETAILS
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _withdrawApplication = () => {
    this.setState({ applicationWithdrawn: true })
  }
  _retroActiveRequest = () => {
    this.setState({ retroActiveRequest: true })
  }

  _goBack = () => {
    this.setState(prevState => {
      return {
        reviewFormContext: {
          ...prevState.reviewFormContext,
          formData: null,
          reviewFormData: null
        }
      }
    })
  }

  _changePage = (eventKey: number) => {
    let _pagination = { ...this.state.pagination, pageIndex: eventKey }
    this.setState({ pagination: _pagination })
    this._reloadAll(_pagination)
  }

  _viewPDFApplication = (id: string, applicationId: number) => {
    const fetchEndpoint = docuSignedPdf

    helper
      .fetchJson(fetchEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          applId: applicationId,
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(response => {
        let fileName = response.document.fileName
        let fileURL = response.document.url
        if (!_.isNil(fileURL)) {
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.open(fileURL)
          } else {
            // auto download the file
            var a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = fileName
            document.body.appendChild(a)
            a.click()
          }
        } else {
          const code = CODES.MY_APPLICATIONS_VIEW_PDF
          const error = {
            message: 'There is no content associated to the Document requested'
          }
          throw new ClientPortalException(error, code)
        }
      })
      .catch(error => {
        console.error(
          'MyApplications _viewPDFApplication failed with ex',
          error
        )
        const code = CODES.MY_APPLICATIONS_VIEW_PDF
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _loadAll = (jsonForms: any, jsonData: any) => {
    let forms, application, formContext, pagination
    application = jsonForms['app']
    forms = application['forms']
    for (let i = 0; i < forms.length; ++i) {
      forms[i]['schema'] = helper.schemaIntersect(
        forms[i]['schema'],
        forms[i]['uiSchema']
      )
    }

    formContext = {
      refs: application['metaData']['refs'],
      forms: forms,
      viewApplicationDetails: this._viewApplicationDetails,
      setRedirect: this._setRedirect,
      copyNredirect: this._copyNredirect,
      withdrawApplication: this._withdrawApplication,
      deleteDraft: this._deleteDraft,
      retroActiveRequest: this._retroActiveRequest,
      viewPDFApplication: this._viewPDFApplication,
      config
    }

    let reviewFormContext
    helper
      .fetchJson(appIntakeEndpoint.replace('{version}', '1.0'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          uuid: this.props.uuid,
          Authorization: this.props.authHeader
        }
      })
      .then(formJson => {
        reviewFormContext = {
          reviewForms: formJson['app'].forms,
          refs: {
            ...formJson['app'].metaData.refs,
            ...refs
          }
        }

        this.setState({ reviewFormContext })
      })
      .catch(error => {
        console.error(
          'MyApplications review form fetch failed due to ex',
          error
        )
        const code = CODES.MY_APPLICATIONS_FETCH_REVIEW_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
    let formData = []
    if (jsonData) {
      pagination = { ...jsonData.pagination }
      formData = helper.deepClone({
        applicationSummaries: jsonData.applicationSummaries
      })
    }
    const viewTabs = []
    formContext = {
      ...formContext,
      formData: formData
    }

    this.setState({
      application,
      forms,
      formContext,
      formData: this._updateFormDataByLocale('en', this.props.locale, formData),
      pagination
    })
  }

  _reloadAll = pagination => {
    helper
      .fetchJson(loadAppEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          uuid: this.props.uuid,
          userRoleId: this.props.userRoleId.toString(),
          locale: (this.props.locale + '_us').toUpperCase(),
          tenantCode: config.tenant.code,
          portalName: config.portalName,
          Authorization: this.props.authHeader
        },
        body: JSON.stringify({ pagination: pagination })
      })
      .then(jsonData => {
        let _formData = helper.deepClone({
          applicationSummaries: jsonData.applicationSummaries
        })
        const formData = { formData: _formData }
        console.log('pag clicked', formData)
        this._onFormDataChange(formData)
      })
      .catch(error => {
        console.error('MyApplications form fetch failed due to ex', error)
        const code = CODES.MY_APPLICATIONS_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData
        }
      }
    })
  }

  _modalWasClosed = () => {
    this.setState({ retroActiveRequest: false, applicationWithdrawn: false })
  }

  _updateFormDataByLocale = (oldLocale, newLocale, formData) => {
    let newFormData = _.cloneDeep(formData)
    let applicationSummaries = _.get(newFormData, 'applicationSummaries', [])
    _.forEach(applicationSummaries, application => {
      let applicants = application.applicants
      _.forEach(applicants, applicant => {
        let assistanceTypes = applicant.assistanceTypes.split(', ')
        _.forEach(assistanceTypes, (assistance, index) => {
          switch (assistance) {
            case ASSISTANCE_TYPES[oldLocale]
              .temporaryAssistanceForNeedyFamilies:
              assistanceTypes[index] =
                ASSISTANCE_TYPES[newLocale].temporaryAssistanceForNeedyFamilies
              break
            case ASSISTANCE_TYPES[oldLocale].healthcare:
              assistanceTypes[index] = ASSISTANCE_TYPES[newLocale].healthcare
              break
            case ASSISTANCE_TYPES[oldLocale].foodAndNutrition:
              assistanceTypes[index] =
                ASSISTANCE_TYPES[newLocale].foodAndNutrition
              break
            case ASSISTANCE_TYPES[oldLocale].homeEnergyAssistance:
              assistanceTypes[index] =
                ASSISTANCE_TYPES[newLocale].homeEnergyAssistance
              break
            case ASSISTANCE_TYPES[oldLocale].childCareServices:
              assistanceTypes[index] =
                ASSISTANCE_TYPES[newLocale].childCareServices
              break
            case ASSISTANCE_TYPES[oldLocale].childSupportServices:
              assistanceTypes[index] =
                ASSISTANCE_TYPES[newLocale].childSupportServices
              break
            case ASSISTANCE_TYPES[oldLocale].medicarePremiumAssistance:
              assistanceTypes[index] =
                ASSISTANCE_TYPES[newLocale].medicarePremiumAssistance
              break
          }
        })
        applicant.assistanceTypes = assistanceTypes.join(', ')
      })
    })
    return newFormData
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={baseUrl + '/application?applId=' + this.state.redirect} />
      )
    }
    const { presentation, locale } = this.props
    const {
      application,
      forms,
      formData,
      formContext,
      applicationWithdrawn,
      retroActiveRequest,
      reviewFormContext,
      pagination
    } = this.state

    const presentationProps = {
      application,
      forms,
      formData,
      formContext,
      onFormDataChange: this._onFormDataChange,
      applicationWithdrawn,
      retroActiveRequest,
      modalWasClosed: this._modalWasClosed,
      reviewFormContext,
      goBack: this._goBack,
      changePage: this._changePage,
      pagination,
      locale
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  let orgId =
    _.get(state.userAccess, 'selectedUserRole.organization.orgId') || ''
  let accessToken = _.get(state.auth, 'accessToken')
  return {
    uuid,
    userRoleId,
    locale: state.i18n.locale,
    orgId,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}

export default connect(mapStateToProps)(MyApplicationsContainer)
