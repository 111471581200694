import * as React from 'react'
import * as _ from 'lodash'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import Pagination from 'react-js-pagination'
import { Modal, Button } from 'react-bootstrap'
import { ErrorModal } from '../src/components/natives/ErrorModal'

interface SearchApplicationsPresentationProps {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  onFormDataChange: (formData: any) => void
  hasKeyedAny: () => boolean
  searchApplications: () => void
  clearForm: () => void
  formKey: string
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  errorDisplay: boolean
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function SearchApplicationsPresentation(
  props: SearchApplicationsPresentationProps
) {
  const {
    forms,
    formData,
    formContext,
    onFormDataChange,
    hasKeyedAny,
    searchApplications,
    clearForm,
    formKey,
    formComponents,
    errorDisplay,
    errorMessage,
    onDeleteClick
  } = props

  const disableButtons = !hasKeyedAny()
  if (!_.isEmpty(forms)) {
    return (
      <div>
        {errorDisplay && (
          <div className="panel panel-danger errors">
            <div className="panel-heading">
              <h3 className="panel-title">Errors</h3>
            </div>
            <ul className="list-group">
              <div>
                <li className="list-group-item text-danger">
                  End date cannot be prior to the start date.
                </li>
              </div>
            </ul>
          </div>
        )}
        <ErrorModal
          errorMessage={props.errorMessage}
          onDeleteClick={props.onDeleteClick}
        />
        <Form
          key={formKey}
          schema={forms[0]['schema']}
          uiSchema={forms[0]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
        >
          <input type="submit" style={{ display: 'none' }} />
        </Form>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <button
              id="EventHistory_searchEventsBtn"
              className="btn btn-primary float-right"
              disabled={disableButtons}
              onClick={searchApplications}
              style={{ margin: '10px' }}
            >
              Search
            </button>
            <button
              id="EventHistory_clearSearchBtn"
              className="btn btn-default float-right"
              onClick={clearForm}
              style={{ margin: '10px' }}
            >
              Reset
            </button>
          </div>
        </div>
        <Form
          schema={forms[1]['schema']}
          uiSchema={forms[1]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
        >
          <input type="submit" style={{ display: 'none' }} />
        </Form>
      </div>
    )
  }
  return (
    <div>
      <ScreenPreLoader loaded={false} />
      <ErrorModal
        errorMessage={props.errorMessage}
        onDeleteClick={props.onDeleteClick}
      />
    </div>
  )
}
