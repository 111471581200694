import * as _ from 'lodash' // extra functionality
import { combineReducers } from 'redux'

export default combineReducers({
  applicationsArray,
  mycocapplication
})

function applicationsArray(state = [], action) {
  switch (action.type) {
    case 'UPDATE_MY_COC_APPLICATION-STEP':
      return state
    case 'LOGOUT_SUCCESS':
      return []
    default:
      return state
  }
}

function mycocapplication(state = { step: 1 }, action) {
  switch (action.type) {
    case 'UPDATE_MY_COC_APPLICATION-STEP':
      return {
        ...state,
        step: action.step
      }

    case 'UPDATE_COC_FORMDATA':
      return action.formData
    case 'LOGOUT_SUCCESS':
      return { step: 1 }
    default:
      return state
  }
}
