import * as React from 'react'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form' // json schema form library
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import NavTabs from '@optum-wvie/dynamic-ui-framework/src/components/natives/NavTabs'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { config } from '../../../config' // endpoints
import { isEmpty } from 'lodash' // extra functionality
import { Modal } from 'react-bootstrap'
import MessageModal from '@optum-wvie/dynamic-ui-framework/src/components/natives/MessageModal'
import ConfirmModal from './ConfirmModal'
import { I18n } from 'react-redux-i18n'
import { Helmet } from 'react-helmet'

declare const process
const baseUrl = process.env.PUBLIC_URL

export default function AppIntakePEPresentation(props) {
  const _ = { isEmpty }

  const {
    applicationData,
    step,
    tabs,
    tabStatus,
    forms,
    formContext,
    application,
    liveValidate,
    showNext,
    formDataSubmitted,
    callingApi,
    validator,
    onPrevious,
    saveFormDataDraft,
    changeView,
    onSubmit,
    onFormDataChange,
    onValidate,
    errorListTemplate,
    onError,
    transformErrors,
    setRedirect,
    showExit,
    showExitModal,
    confirmExit,
    cancelExit,
    formComponents,
    locale,
    hasError
  } = props

  let formsOutput = []
  let applicationTitle
  let previousButton: any
  let submitButton
  let confirmationModal

  if (!_.isEmpty(application) && !_.isEmpty(applicationData)) {
    // since application isn't empty the component is going to be rendered thus 'loaded'

    // load application title

    applicationTitle = application['properties']['title']
    // submit button change on last page to Submit instead of Next
    if (step === forms.length) {
      submitButton = (
        <button
          id="AppIntake_submitBtn"
          type="submit"
          className={`btn btn-default float-right sp-md ${
            hasError ? 'btn-danger' : 'btn-default'
          }`}
          disabled={callingApi}
        >
          {(hasError ? I18n.t('General.error') + ' - ' : '') +
            I18n.t('General.submitButton')}
        </button>
      )
    }
    // Need to make this only display if it's at the end of the carousel
    else if (showNext || step !== 0) {
      submitButton = (
        <button
          id="AppIntake_nextBtn"
          type="submit"
          className={`btn btn-default float-right sp-md ${
            hasError ? 'btn-danger' : 'btn-default'
          }`}
          disabled={callingApi}
        >
          {hasError ? I18n.t('General.error') + ' - ' : ''}
          {I18n.t('General.nextButton')}
        </button>
      )
    } else {
      submitButton = null
    }

    if (step !== 1) {
      /* previousButton = <ReactButton type="button" className="btn btn-default float-left" text="Previous" onClick={onPrevious}></ReactButton>; */
      previousButton = (
        <button
          type="button"
          className={`btn btn-default float-left sp-md ${
            hasError ? 'btn-danger' : 'btn-default'
          }`}
          onClick={onPrevious}
          disabled={callingApi}
        >
          {hasError ? I18n.t('General.error') + ' - ' : ''}
          {I18n.t('General.previousButton')}
        </button>
      )
    } else {
      previousButton = null
    }

    confirmationModal = formDataSubmitted ? (
      <ConfirmModal redirectFunction={setRedirect} />
    ) : null

    return (
      <div>
        <Helmet>
          <title>
            {(hasError ? I18n.t('General.error') + ' - ' : '') +
              applicationTitle}
          </title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="application-title">
              <h1>
                <span className="fa fa-shopping-cart icon-left title-icon" />
                {applicationTitle}
              </h1>
            </div>
            <div className="below-header-bar">
              <div className="content">
                {/* <button className="save btn btn-tiny btn-white" onClick={saveFormDataDraft} disabled={callingApi}>
									<span className="fa fa-floppy-o btn-icon-sm"></span>Save
								</button> */}
                <button
                  type="button"
                  className="help btn btn-tiny btn-white"
                  onClick={showExit}
                >
                  <span className="fa fa-times btn-icon-sm" />
                  {I18n.t('General.exitButton')}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="tabs-section col-sm-4 col-md-3 col-lg-2 px-0">
              <NavTabs
                activeKey={step - 1}
                onNavClick={changeView}
                tabs={tabs}
                disabled={callingApi}
              />
            </div>

            <div className="form-section col-sm-8 col-md-9 col-lg-10 dynamic-form px-0">
              {
                <Form
                  schema={forms[step - 1].schema}
                  uiSchema={forms[step - 1].uiSchema}
                  onSubmit={onSubmit}
                  formData={applicationData}
                  onChange={onFormDataChange}
                  formContext={formContext}
                  fields={formComponents.fields}
                  widgets={formComponents.widgets}
                  ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                  FieldTemplate={formComponents.FieldTemplate}
                  validate={onValidate}
                  liveValidate={liveValidate}
                  showErrorList={true}
                  ErrorList={errorListTemplate}
                  onError={onError}
                  transformErrors={transformErrors}
                  jsonValidator={validator}
                  locale={locale}
                >
                  <div className="col-xs-12">
                    {previousButton}
                    {submitButton}
                  </div>
                </Form>
              }
              <ScreenPreLoader loaded={!callingApi} minHeight="500px" />
            </div>
          </div>
        </div>
        {confirmationModal}
        <MessageModal
          show={showExitModal}
          confirm={confirmExit}
          cancel={cancelExit}
          closeBtnText="Cancel"
          title={
            <span>
              <i className="glyphicon myglyphicon-warning-sign" />
              {I18n.t('AppIntakePE.changesNotSaved')}
            </span>
          }
          body={
            <div className="row">
              <div className="col-md-12">
                {I18n.t('AppIntakePE.exitConfirmation')}
              </div>
            </div>
          }
          bsSize={'lg'}
        />
      </div>
    )
  }

  return (
    <div>
      <ScreenPreLoader loaded={false} />
    </div>
  )
}
