import CustomCheckboxesWidgetWithHelp from './customCheckboxesWidgetWithHelp'
import validateCustomRadioWidget from './CustomRadioWidget'
import { customDollarWidget } from './CustomDollarWidget'
import CheckboxesWidgetWithHelp from './checkboxesWidgetWithHelp'

const customWidgets = {
  CustomCheckboxesWidgetWithHelp,
  validateCustomRadioWidget,
  customDollarWidget,
  CheckboxesWidgetWithHelp
}

export default customWidgets
