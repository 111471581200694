import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'

export const widgets = {
  header: Widgets.HeaderWidget,
  'alt-date': Widgets.DatePickerWidget,
  select: Widgets.CustomSelectWidget,
  validatedText: Widgets.ValidatedTextWidget,
  span: Widgets.SpanWidget,
  multiSelect: Widgets.MultiSelectDropdownWidgetV2,
  ssn: Widgets.SSNWidget,
  routeLink: Widgets.LinkWidget,
  phone: Widgets.PhoneWidget,
  textarea: Widgets.CustomTextAreaWidget,
  ssn1: Widgets.SSNWidgetV2
}
export const fields = {
  tableRow: Fields.TableRowField,
  mcr: Fields.CodeReferenceField,
  addressSuggestion: Fields.AddressSuggestionField,
  publicTrackerForm: Fields.PublicTrackerForm,
  panelGroup: Fields.CollapsiblePanelGroupField,
  accordion: Fields.AccordionField,
  condition: Fields.ConditionalField
}

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate

export const FieldTemplate = Templates.CustomFieldTemplate
