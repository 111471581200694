import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ClientPortalException, CATEGORIES, CODES, shouldThrow } from './Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const ErrorBoundary = MultitenantLoadable(
  () => import('./Errors'),
  () => import('../../' + tenantDirectoryName + '/Errors'),
  'ErrorBoundary'
)

//Export component with no tenancy, for use in base unit tests.

const BaseErrorBoundary = MultitenantLoadable(
  () => import('./Errors'),
  () => Promise.resolve().then(() => null),
  'ErrorBoundary'
)

export {
  ErrorBoundary,
  ClientPortalException,
  CATEGORIES,
  CODES,
  shouldThrow,
  BaseErrorBoundary
}
