import * as React from 'react'
import * as _ from 'lodash'
import { Validator } from 'jsonschema'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form' // json schema form library
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import NavTabs from '@optum-wvie/dynamic-ui-framework/src/components/natives/NavTabs'
import Instructions from './Instructions'
import ContributorModal from './ContributorModal'
import MyDocumentsAccordion from '../../base/MyDocumentsAccordion'
import SubmissionModal from './SubmissionModal'
import MessageModal from '@optum-wvie/dynamic-ui-framework/src/components/natives/MessageModal'
import { Modal, Popover, OverlayTrigger, Button } from 'react-bootstrap'
import '!style-loader!css-loader!../src/customStyles.css'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'react-redux-i18n'

export interface AppIntakePresentationProps {
  //constants
  STEPS: {
    START: number
    PROGRAM_SERVICE_SELECTION: number
    PERSONAL: number
    CITIZENSHIP_AND_RESIDENCY: number
    HEALTH: number
    FAMILY_AND_HOUSEHOLD: number
    CHILD_CARE: number
    INCOME_AND_RESOURCES: number
    RETROACTIVE_REQUEST: number
    ADDITIONAL_QUESTIONS: number
    DOCUMENT_UPLOAD: number
    REVIEW_AND_SIGN: number
  }

  //container props
  myApplicationData: any
  activeApplicationId: string
  step: number
  tabStatus: {
    name: string
    status: string
    accordionActiveKey: number
  }[]

  //container state
  forms: {
    schema: Object
    uiSchema: Object
    metaData: Object
  }[]
  formContext: any
  application: Object
  tabs: {
    status: string
    leftIcon: string
    rightIcon: string
    rightSpan: string
    isVisible: boolean
  }[]
  liveValidate: boolean
  contributorModalProps: {
    show: boolean
    fieldType: string
    form: {
      schema: Object
      uiSchema: Object
      refs: Object
    }
    targetFieldId: string
  }
  formDataSubmitted: boolean
  callingApi: boolean
  showDraftModal: boolean
  showSubmissionModal: boolean
  RIDPQuestions: object[]
  slideIndex: number
  slides: object[]
  errorMessage: object

  //container callbacks
  onPrevious: () => void
  saveFormDataDraft: (showModal: boolean, step: number, tabStatus: any) => void
  exitFromApplication: () => void
  changeView: (val: number) => void
  onDocUpload: (uploadedDoc: any) => void
  onUploadDelete: (deletedDoc: any) => void
  onNext: () => void
  onSubmit: ({ formData: any }) => void
  onFormDataChange: ({ formData: any }) => void
  onValidate: (formData: object, errors: object) => void
  errorListTemplate: (props: any) => void
  onError: (errors: object) => void
  transformErrors: (errors: object) => void
  saveContributor: (contributorFormData: any) => void
  cancelContributor: () => void
  onConfirmMessage: () => void
  setRedirect: (url: string) => void
  submitFormData: () => void
  submitIdentityProof: (data: any) => void
  closeSubmissionModal: () => void
  showExitModal: boolean
  programService: () => void

  //misc
  validator: Validator

  //HOC props
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  mainContent: any
  serviceErrors: Array<any>
  onDeleteClick: () => void
  hasError: boolean
  showErrorMessage: any
  switchOnLiveValidate: any
  submitClicked: any
  showDomesticViolenceModal
  exitDomesticViolenceModal
}

export default function AppIntakePresentation(
  props: AppIntakePresentationProps
) {
  const {
    STEPS,
    myApplicationData,
    activeApplicationId,
    step,
    tabs,
    tabStatus,
    forms,
    formContext,
    application,
    liveValidate,
    contributorModalProps,
    formDataSubmitted,
    callingApi,
    showDraftModal,
    showSubmissionModal,
    RIDPQuestions,
    validator,
    onPrevious,
    saveFormDataDraft,
    exitFromApplication,
    changeView,
    onDocUpload,
    onUploadDelete,
    onNext,
    onSubmit,
    onFormDataChange,
    onValidate,
    errorListTemplate,
    onError,
    transformErrors,
    saveContributor,
    cancelContributor,
    onConfirmMessage,
    setRedirect,
    submitFormData,
    submitIdentityProof,
    closeSubmissionModal,
    formComponents,
    showExitModal,
    errorMessage,
    slideIndex,
    slides,
    programService,
    mainContent,
    serviceErrors,
    onDeleteClick,
    hasError,
    showErrorMessage,
    showDomesticViolenceModal,
    exitDomesticViolenceModal
  } = props
  const docUploadbenefits = {
    CCA:
      _.get(myApplicationData, 'Application.benefitCategorieslist', []).indexOf(
        'CHCR'
      ) > -1,
    CHSP:
      _.get(myApplicationData, 'Application.benefitCategorieslist', []).indexOf(
        'CHSP'
      ) > -1,
    FA: _.get(myApplicationData, 'Application.benefitCategorieslist', []).indexOf(
      'FDNT'
    ) > -1 || 
    _.get(myApplicationData, 'Application.benefitCategorieslist', []).indexOf(
      'HEWL'
    ) > -1 ||
    _.get(myApplicationData, 'Application.benefitCategorieslist', []).indexOf(
      'HMEN'
    ) > -1 || 
    _.get(myApplicationData, 'Application.benefitCategorieslist', []).indexOf(
      'MDPA'
    ) > -1 ||
    _.get(myApplicationData, 'Application.benefitCategorieslist', []).indexOf(
      'SCAL'
    ) > -1 || 
    _.get(myApplicationData, 'Application.benefitCategorieslist', []).indexOf(
      'WVWORKS'
    ) > -1
  }
  if (_.isEmpty(application) || _.isEmpty(formComponents)) {
    return (
      <div>
        <ScreenPreLoader loaded={false} />
      </div>
    )
  }

  let isHEWLSeleted = false
  if (formContext.formData && formContext.formData.Application) {
    for (let i = 0; i < formContext.formData.Application.clients.length; ++i) {
      if (
        formContext.formData.Application.clients[i].optBenefitHEWL ||
        formContext.formData.Application.clients[i].optBenefitMDPA
      ) {
        isHEWLSeleted = true
      }
    }
  }

  let applicationTitle = `${I18n.t('Appintake.benefitsapplication')}`
  let previousButton
  let submitButton
  // submit button change on last page to Submit instead of Next
  if (step === forms.length) {
    submitButton = (
      <button
        id="AppIntake_submitBtn"
        type="submit"
        className="btn btn-default float-right sp-md"
        disabled={callingApi}
        onClick={props.submitClicked}
      >
        {I18n.t('General.submitButton')}
      </button>
    )
  }
  // Need to make this only display if it's at the end of the carousel
  else if (step != 1) {
    submitButton = hasError ? (
      <button
        id="AppIntake_nextBtn"
        type="submit"
        className={`btn btn-default float-right sp-md btn-danger`}
        disabled={callingApi}
        aria-label={I18n.t('General.errorsOnPage')}
        onClick={props.switchOnLiveValidate}
      >
        {I18n.t('General.errButtonLabel')}
      </button>
    ) : (
      <button
        id="AppIntake_nextBtn"
        type="submit"
        className="btn btn-default float-right sp-md"
        disabled={callingApi}
        onClick={props.switchOnLiveValidate}
      >
        {I18n.t('General.nextButtonLabel')}
      </button>
    )
  } else if (step === 1 && slideIndex === slides.length - 1) {
    submitButton = hasError ? (
      <button
        id="AppIntake_nextBtn"
        type="submit"
        className={`btn btn-default float-right sp-md nextButton btn-danger`}
        disabled={callingApi}
        aria-label="There are errors on this page"
        onClick={props.switchOnLiveValidate}
      >
        {I18n.t('General.errButtonLabel')}
      </button>
    ) : (
      <button
        id="AppIntake_nextBtn"
        type="submit"
        className="btn btn-default float-right sp-md nextButton"
        disabled={callingApi}
        onClick={props.switchOnLiveValidate}
      >
        {I18n.t('General.nextButtonLabel')}
      </button>
    )
  } else {
    submitButton = null
  }

  if (step !== 1) {
    previousButton = (
      <button
        type="button"
        className={`btn btn-default float-left sp-md ${
          hasError ? 'btn-danger' : 'btn-default'
        }`}
        onClick={onPrevious}
        disabled={callingApi}
      >
        {I18n.t('General.previousButtonLabel')}
      </button>
    )
  } else {
    previousButton = null
  }
  let helpPopover = (
    <Popover id="helpPopover">
      <a href="/help" target="_blank">
        {I18n.t('General.helpCenterButtonLabel')}
      </a>
    </Popover>
  )

  let helpLink = (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={helpPopover}
    >
      <button className="print btn btn-tiny btn-white">
        <span className="fa fa-life-ring btn-icon-sm" />
        {I18n.t('General.helpButtonLabel')}
      </button>
    </OverlayTrigger>
  )

  return (
    <div>
      <a href="#side-nav" className="skip-nav">
        {I18n.t('General.skipToSideNav')}
      </a>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <div className="application-title">
              <h1>
                <span className="fa fa-shopping-cart icon-left title-icon" />
                {applicationTitle}
              </h1>
            </div>
            <div className="below-header-bar">
              <div className="content">
                <button
                  className="save btn btn-tiny btn-white"
                  onClick={() => exitFromApplication()}
                >
                  <span className="fa fa-life-ring btn-icon-sm" />
                  {I18n.t('General.exitButtonLabel')}
                </button>
                <button
                  className="save btn btn-tiny btn-white"
                  onClick={() => saveFormDataDraft(true, step, tabStatus)}
                  disabled={callingApi || props.formContext.isIncomplete}
                >
                  <span className="fa fa-floppy-o btn-icon-sm" />
                  {I18n.t('General.saveButtonLabel')}
                </button>
                {helpLink}

                <button
                  className="printStyle print btn btn-tiny btn-white printpage"
                  onClick={function() {
                    window.print()
                  }}
                >
                  <span className="fa fa-print btn-icon-sm" />
                  {I18n.t('General.printButtonLabel')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            id="side-nav"
            className="tabs-section col-md-4 col-lg-3 col-xl-2 px-0"
          >
            <NavTabs
              activeKey={step - 1}
              onNavClick={changeView}
              tabs={tabs}
              disabled={callingApi}
              className="appintake-navtabs"
            />
          </div>
          <div
            id="content"
            className="form-section col-md-8 col-lg-9 col-xl-10 dynamic-form px-0"
            tabIndex={-1}
            ref={mainContent}
          >
            {forms[step - 1].uiSchema['externalOptions'] &&
            forms[step - 1].uiSchema['externalOptions']['instructions'] ? (
              <Instructions
                options={
                  forms[step - 1]['uiSchema']['externalOptions']['instructions']
                }
              />
            ) : null}
            {step === STEPS.DOCUMENT_UPLOAD ? (
              <div>
                <MyDocumentsAccordion
                  appIntake="true"
                  appId={myApplicationData.applId}
                  applicants={myApplicationData.Application.clients}
                  onDocUpload={onDocUpload}
                  onUploadDelete={onUploadDelete}
                  errorMessage={errorMessage}
                  activeApplicationId={activeApplicationId}
                  benefits={docUploadbenefits}
                />
                <div className="col-12">
                  {previousButton}
                  <button
                    id="AppIntake_nextBtn"
                    type="submit"
                    onClick={onNext}
                    className="btn btn-default float-right sp-md"
                  >
                    {I18n.t('General.nextButton')}
                  </button>
                </div>
              </div>
            ) : step === STEPS.PROGRAM_SERVICE_SELECTION ? (
              programService()
            ) : (
              <Form
                schema={forms[step - 1].schema}
                uiSchema={forms[step - 1].uiSchema}
                onSubmit={onSubmit}
                formData={myApplicationData}
                onChange={onFormDataChange}
                formContext={formContext}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                FieldTemplate={formComponents.FieldTemplate}
                validate={onValidate}
                liveValidate={liveValidate}
                showErrorList={true}
                ErrorList={errorListTemplate}
                onError={onError}
                transformErrors={transformErrors}
                jsonValidator={validator}
                id="child-care"
              >
                <div className="col-lg-12 col-xl-12 col-md-12 col-12 margin-top-minus-4">
                  {previousButton}
                  {submitButton}
                </div>
              </Form>
            )}
            <ScreenPreLoader loaded={!callingApi} minHeight="500px" />
          </div>
        </div>
      </div>
      <ContributorModal
        {...contributorModalProps}
        save={saveContributor}
        cancel={cancelContributor}
        showErrorMessage={showErrorMessage}
      />
      <MessageModal
        show={showDraftModal}
        confirm={onConfirmMessage}
        title={
          <span>
            <h4 className="popup-title"> {I18n.t('General.changesSaved')}</h4>
          </span>
        }
        body={
          <div className="row">
            <div className="col-lg-12">
              <h6 className="headertxt">
                {I18n.t('General.changesSavedSuccess')}
              </h6>
            </div>
          </div>
        }
        bsSize={'lg'}
        className={'lg-width-to-md'}
      />
      <Modal
        show={showDomesticViolenceModal}
        onHide={exitDomesticViolenceModal}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <label id="victimOfAFamOrDomestViol">
              {I18n.t('Appintake.victimOfAFamOrDomestViol')}
            </label>
            <div className={`radio`}>
              <input
                id="victimOfAFamOrDomestViol_yes"
                type="radio"
                name="victimOfAFamOrDomestViol"
                value="Y"
                onChange={() => {
                  props.formContext.setVictimOfAFamOrDomestViol('Y')
                }}
              />{' '}
              <label htmlFor="victimOfAFamOrDomestViol_yes">Yes</label>
              <input
                id="victimOfAFamOrDomestViol_no"
                type="radio"
                name="victimOfAFamOrDomestViol"
                value="N"
                onChange={() => {
                  props.formContext.setVictimOfAFamOrDomestViol('N')
                }}
              />{' '}
              <label htmlFor="victimOfAFamOrDomestViol_no">No</label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {myApplicationData.Application &&
            !myApplicationData.Application.victimOfAFamOrDomestViol && (
              <Button
                variant="primary"
                aria-label={'Skip'}
                onClick={() => exitDomesticViolenceModal()}
              >
                {I18n.t('General.skip')}
              </Button>
            )}
          {myApplicationData.Application &&
            myApplicationData.Application.victimOfAFamOrDomestViol && (
              <Button
                variant="primary"
                aria-label={'Ok'}
                onClick={() => exitDomesticViolenceModal()}
              >
                {I18n.t('General.ok')}
              </Button>
            )}
        </Modal.Footer>
      </Modal>
      <Modal show={showExitModal} onHide={exitFromApplication}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="popup-title">Exit</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="headertxt">{I18n.t('General.confirmExit')}</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setRedirect('/myaccount/profile')}
          >
            {I18n.t('General.yes')}
          </Button>
          <Button variant="primary" onClick={() => exitFromApplication()}>
            {I18n.t('General.no')}
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorModal
        errorMessage={props.serviceErrors}
        onDeleteClick={props.onDeleteClick}
      />
      <SubmissionModal
        applId={activeApplicationId}
        confirmation={formDataSubmitted}
        formData={myApplicationData}
        callingApi={callingApi}
        setRedirect={setRedirect}
        saveFormDataDraft={saveFormDataDraft}
        showModal={showSubmissionModal}
        submitFormData={submitFormData}
        submitIdentityProof={submitIdentityProof}
        closeModal={closeSubmissionModal}
        RIDPQuestions={RIDPQuestions}
      />
    </div>
  )
}
