import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import { isEmpty } from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form' // json schema form library
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import ConfirmModal from './ConfirmModal'
import { I18n } from 'react-redux-i18n'
import { Pagination } from '@optum-wvie/dynamic-ui-framework/src/components/natives/Pagination'
const _ = { isEmpty }
const moment = require('moment-mini-ts')
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { config } from '../../../config'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'

interface MyApplicationsPresentationProps {
  application: any
  forms: Array<{
    schema: any
    uiSchema: any
  }>
  formData: any
  formContext: any
  onFormDataChange: (formData: any) => void
  applicationWithdrawn: boolean
  retroActiveRequest: boolean
  modalWasClosed: () => void
  reviewFormContext: any
  goBack: () => void
  changePage: (eventKey: number) => void
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  minHeight: string
  locale: string
  pagination: any
  deleteRequest: boolean
  deleteAppId: any
  closeDeleteModal: () => void
  deleteApplication: (applicationId: number) => void
  errorMessage: Array<any>
  onDeleteClick: () => void
  currentDate: any
}

// function checkForYear(item) {
//   let submitDate = moment(item.submissionDate)
//   let currentDt = _moment(config, this.props.currentDate)
//   console.log(' MyApplicationPresentation -- currentDt -- ' + currentDt)
//   let resultArr = currentDt.diff(submitDate, 'days')
//   return resultArr <= 459
// }

function formatStatus(status: string) {
  switch ((status || '').toLowerCase()) {
    case 'draft':
      return I18n.t('General.draft')
    case 'submitted':
      return I18n.t('General.submitted')
    default:
      return status
  }
}

export default function MyApplicationsPresentation(
  props: MyApplicationsPresentationProps
) {
  const {
    application,
    forms,
    formData,
    formContext,
    onFormDataChange,
    applicationWithdrawn,
    retroActiveRequest,
    modalWasClosed,
    reviewFormContext,
    goBack,
    formComponents,
    locale,
    changePage,
    pagination,
    deleteRequest,
    deleteAppId,
    closeDeleteModal,
    deleteApplication,
    onDeleteClick,
    errorMessage,
    currentDate
  } = props

  let filterFormData = {
    applicationSummaries: []
  }

  let withdrawnModal = applicationWithdrawn ? (
    <ConfirmModal
      open={applicationWithdrawn}
      modalWasClosed={modalWasClosed}
      retro={false}
    />
  ) : null
  let retroActiveRequestModal = retroActiveRequest ? (
    <ConfirmModal
      open={retroActiveRequest}
      modalWasClosed={modalWasClosed}
      retro={true}
    />
  ) : null

  let deleteRequestModal = (
    // let deleteRequestModal = deleteRequest ? (
    <Modal show={deleteRequest} onHide={closeDeleteModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Draft Application</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="headertxt">
          Are you sure you want to delete your draft application?
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => deleteApplication(deleteAppId)}
        >
          Yes
        </Button>
        <Button variant="primary" onClick={() => closeDeleteModal()}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
    // ) : null
  )

  if (
    formData.applicationSummaries &&
    formData.applicationSummaries.length > 0
  ) {
    filterFormData = {
      applicationSummaries: formData.applicationSummaries.filter(function(
        item
      ) {
        let submitDate = moment(item.submissionDate)
        let currentDt = _moment(config, props.currentDate) // props.currentDate
        let resultArr = currentDt.diff(submitDate, 'days')
        return resultArr <= 459
      })
    }
  }

  if (reviewFormContext && reviewFormContext.reviewFormData) {
    return (
      <div>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <Form
          schema={forms[1]['schema']}
          uiSchema={forms[1]['uiSchema']}
          formData={{}}
          formContext={reviewFormContext}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <button type="submit" style={{ display: 'none' }} />
          <div>
            <button
              type="button"
              className="btn btn-default float-right"
              onClick={goBack}
            >
              {I18n.t('General.backButton')}
            </button>
          </div>
        </Form>
      </div>
    )
  } else {
    if (!_.isEmpty(application)) {
      const newFormData = {
        applicationSummaries: ((formData || {}).applicationSummaries || []).map(
          record => {
            return {
              ...record,
              status: formatStatus(record.status)
            }
          }
        )
      }
      const newFormContext = {
        ...formContext,
        formData: newFormData
      }
      const pageCount = Math.ceil(
        pagination.recordCount / pagination.recordsPerPage
      )
      const activePage = pagination.pageIndex
      const start =
        pagination.recordsPerPage * activePage - pagination.recordsPerPage + 1
      const end =
        activePage == pageCount
          ? pagination.recordCount
          : pagination.recordsPerPage * activePage

      return (
        <div id="my-applications">
          <ErrorModal
            errorMessage={errorMessage}
            onDeleteClick={onDeleteClick}
          />
          {withdrawnModal}
          {retroActiveRequestModal}
          {deleteRequestModal}
          <div className="form-section">
            <h1>
              <i className="pe-7s-note2" /> {I18n.t('MyApplications.title')}
            </h1>
            {filterFormData && filterFormData.applicationSummaries.length > 0 && (
              <Form
                schema={forms[0]['schema']}
                uiSchema={forms[0]['uiSchema']}
                formData={newFormData}
                onChange={onFormDataChange}
                formContext={newFormContext}
                fields={formComponents.fields}
                widgets={formComponents.widgets}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
                FieldTemplate={formComponents.FieldTemplate}
                locale={locale}
              >
                <button type="submit" style={{ display: 'none' }} />
              </Form>
            )}
            <div className="float-left messageRegularLine">
              <span>
                Displaying {start} - {end}
              </span>
            </div>
            <Pagination
              prev={'‹'}
              next={'›'}
              first={'«'}
              last={'»'}
              ellipsis={false}
              boundaryLinks={true}
              items={pageCount}
              maxButtons={3}
              activePage={pagination.pageIndex}
              onSelect={changePage}
            />
          </div>
          <script>
            ($('.panel-heading:even').css("backgroundColor", "#4190C1"));
          </script>
        </div>
      )
    }
  }
  return (
    <div>
      <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
      <ScreenPreLoader loaded={false} minHeight={props.minHeight} />
    </div>
  )
}
