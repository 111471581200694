import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'

export const ENTITLEMENTS_wv = {
  ...ENTITLEMENTS,
  CP_VIEW_APPOINTMENTS: 'CP_VIEW_APPOINTMENTS',
  CP_USER_ACCESS: 'CP_USER_ACCESS',
  CP_MANAGE_ORGANIZATION: 'CP_MANAGE_ORGANIZATION',
  CP_APPLICATION_SEARCH: 'CP_APPLICATION_SEARCH',
  CP_REPORTS: 'CP_REPORTS',
  CP_Admin: 'CP Admin',
  CP_WORKER_ADMIN: 'CP_WORKER_ADMIN',
  CP_APPLY_NOW: 'CP_APPLY_NOW',
  CP_PE_START_APP: 'CP_PE_START_APP',
  CP_CPN_START_FULL_APP: 'CP_CPN_START_FULL_APP',
  BH_ELIGIBLE_APPLICANT_VIEW: 'BH_ELIGIBLE_APPLICANT_VIEW',
  BH_ELIGIBLE_APPLICANT_UPDATE: 'BH_ELIGIBLE_APPLICANT_UPDATE',
  BH_ELIGIBLE_APPLICANT_ADD: 'BH_ELIGIBLE_APPLICANT_ADD',
  BH_ELIGIBLE_APPLICANT_SCREENING: 'BH_ELIGIBLE_APPLICANT_SCREENING',
  BH_REFERRAL: 'BH_REFERRAL',
  CP_ADMIN_WRITE_BANNER: 'CP_ADMIN_WRITE_BANNER'
}
