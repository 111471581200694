import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { isEqual, get, find } from 'lodash'
import { config } from '../../../config'
import { I18n } from 'react-redux-i18n'
import { refs } from '../../../refs'
import { ClientPortalException, CODES, shouldThrow } from '../../base/Errors'
import * as serviceHelper from '../src/components/utils'
import * as actions from '../../../actions'
import { UnauthorizedError, getServerTime } from '../src/components/utils'

const _ = { isEqual, get, find }

declare const process
const baseUrl = process.env.PUBLIC_URL

const gatewayWvUrl = config['gatewayWvUrl']

const loadAppEndpoint =
  config['gatewayBaseUrl'] + config['getBenefitApplicationsDetails']
const copyNSaveEndpoint = config['copyNSaveApplication']
const formsEndpoint = config['forms_MyApplications']
const appIntakeEndpoint = config['forms_AppIntake']
const loadDraftEndpoint = config['getDraftApplication']
const deleteDraftEndPoint = gatewayWvUrl + config['deleteDrftEndPoint']
const getPdfEndpoint =
  gatewayWvUrl + '/WVIE/application/pdf/v2.1/getApplicationPDF?appID={applId}'
const getPdfCSEndpoint = gatewayWvUrl + config['getPdfCSEndpoint']
const _pagination = { recordsPerPage: 20, pageIndex: 1 }

interface MyApplicationsContainerProps {
  presentation: any
  selectedEntitlements: Array<string>
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  userRoleId: number
  locale: string
  orgId: string
  roleId: number
  authHeader: string
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  logoutUser
}

interface MyApplicationsContainerState {
  application: any
  formData: any
  forms: Array<{
    schema: any
    uiSchema: any
  }>
  formContext: any
  reviewFormContext: any
  redirect: string
  retroActiveRequest: boolean
  applicationWithdrawn: boolean
  callingApi: boolean
  pagination: any
  deleteRequest: boolean
  deleteAppId: any
  currentDate: any
}

class MyApplicationsContainer extends React.Component<
  MyApplicationsContainerProps,
  MyApplicationsContainerState
> {
  constructor(props: MyApplicationsContainerProps) {
    super(props)

    this.state = {
      application: {},
      formData: {},
      forms: null,
      formContext: null,
      reviewFormContext: null,
      redirect: null,
      retroActiveRequest: null,
      applicationWithdrawn: null,
      callingApi: false,
      pagination: null,
      deleteRequest: false,
      deleteAppId: null,
      currentDate: getServerTime()
    }
  }

  componentDidMount() {
    document.title = 'My Applications - My Account'
    this._loadApp()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      //The user switched their locale. Need to re-fetch the form.
      document.title = I18n.t('MyApplications.title')
      const endpoint = formsEndpoint.replace('{version}', '1.0')

      this.setState({ callingApi: true })
      serviceHelper
        .fetchJson(
          endpoint,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              locale: (this.props.locale + '_us').toUpperCase(),
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName
            }
          },
          this.props.showErrorMessage
        )
        .then(json => {
          this.setState({ callingApi: false })
          const forms = json['app']['forms']
          for (let i = 0; i < forms.length; ++i) {
            forms[i]['schema'] = helper.schemaIntersect(
              forms[i]['schema'],
              forms[i]['uiSchema']
            )
          }
          this.setState(prevState => {
            return {
              forms,
              formContext: {
                ...prevState.formContext,
                forms
              }
            }
          })
        })
        .catch(error => {
          this.setState({ callingApi: false })
          console.error('MyApplications form re-fetch failed due to ex', error)
          const code = CODES.MY_APPLICATIONS_REFETCH_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })

      serviceHelper
        .fetchJson(
          appIntakeEndpoint.replace('{version}', '1.0'),
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              locale: (this.props.locale + '_us').toUpperCase(),
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName
            }
          },
          this.props.showErrorMessage
        )
        .then(json => {
          this.setState(prevState => {
            return {
              reviewFormContext: {
                ...prevState.reviewFormContext,
                reviewForms: json['app'].forms
              }
            }
          })
        })
        .catch(error => {
          console.error(
            'MyApplications review form fetch failed due to ex',
            error
          )
          const code = CODES.MY_APPLICATIONS_REFETCH_REVIEW_FORM
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof helper.IEServiceError) {
                throw error
              } else {
                throw new ClientPortalException(error, code)
              }
            })
          }
        })
    }
  }

  _loadApp = () => {
    this._getFormDataJSON()
  }

  _setRedirect = (path: string, id: string, applicationId: number) => {
    const fetchEndpoint = loadDraftEndpoint.replace('{applId}', path)

    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            userRoleId: this.props.userRoleId
          },
          method: 'POST'
        },
        this.props.showErrorMessage
      )
      .then(json => {
        helper.cleanNullValues(json)
        this.setState(prevState => {
          return {
            reviewFormContext: {
              ...prevState.reviewFormContext,
              formData: json
            },
            redirect: path
          }
        })
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'MyApplications _viewApplicationDetails failed with ex',
          ex
        )
      })
    //this.setState({ redirect: path });
  }

  _getFormDataJSON = () => {
    serviceHelper
      .fetchJson(
        loadAppEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            userRoleId: this.props.userRoleId.toString(),
            locale: (this.props.locale + '_us').toUpperCase(),
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          },
          body: JSON.stringify({
            pagination: _pagination
          })
        },
        this.props.showErrorMessage
      )
      .then(jsonData => {
        serviceHelper
          .fetchJson(
            formsEndpoint.replace('{version}', '1.0'),
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                locale: (this.props.locale + '_us').toUpperCase(),
                uuid: this.props.auth.userAccount.uuid,
                tenantCode: config.tCode,
                Authorization: config.bearer + this.props.auth.accessToken,
                portalName: config.portalName
              }
            },
            this.props.showErrorMessage
          )
          .then(formJson => {
            this._loadAll(formJson, jsonData)
          })
          .catch(error => {
            console.error('MyApplications form fetch failed due to ex', error)
            const code = CODES.MY_APPLICATIONS_FETCH_FORM
            if (shouldThrow(code)) {
              this.setState(() => {
                if (error instanceof helper.IEServiceError) {
                  throw error
                } else {
                  throw new ClientPortalException(error, code)
                }
              })
            }
          })
      })
      .catch(error => {
        console.error('MyApplications _getFormDataJSON failed with ex', error)
        const code = CODES.MY_APPLICATIONS_FETCH_DATA
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _copyNredirect = (applicationId: number) => {
    const fetchEndpoint = copyNSaveEndpoint.replace(
      '{applId}',
      applicationId.toString()
    )
    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            userRoleId: this.props.userRoleId.toString(),
            orgId: this.props.orgId,
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId
          }
        },
        this.props.showErrorMessage
      )
      .then(jsonData => {
        // load json into form
        this._setRedirect(jsonData, config, config)
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error('MyApplications _copyNredirect failed with ex', error)
        const code = CODES.MY_APPLICATIONS_COPY_N_SAVE
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _deleteDraft = (applicationId: number) => {
    const fetchEndpoint = deleteDraftEndPoint
    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            applId: applicationId.toString(),
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage
      )
      .then(jsonData => {
        // load json into form
        this._loadApp()
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error('MyApplications _deleteDraft failed with ex', ex)
      })
  }

  _viewApplicationDetails = (id: string, applicationId: number) => {
    const fetchEndpoint = loadDraftEndpoint.replace(
      '{applId}',
      applicationId.toString()
    )

    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(jsonData => {
        helper.cleanNullValues(jsonData)
        this.setState(prevState => {
          return {
            reviewFormContext: {
              ...prevState.reviewFormContext,
              formData: jsonData,
              reviewFormData: jsonData
            }
          }
        })
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'MyApplications _viewApplicationDetails failed with ex',
          ex
        )
      })
  }

  _withdrawApplication = () => {
    this.setState({ applicationWithdrawn: true })
  }
  _retroActiveRequest = () => {
    this.setState({ retroActiveRequest: true })
  }

  _goBack = () => {
    this.setState(prevState => {
      return {
        reviewFormContext: {
          ...prevState.reviewFormContext,
          formData: null,
          reviewFormData: null
        }
      }
    })
  }

  _changePage = (eventKey: number) => {
    let _pagination = { ...this.state.pagination, pageIndex: eventKey }
    this.setState({ pagination: _pagination })
    this._reloadAll(_pagination)
  }

  _viewPDFApplication = (id: string, applicationId: number) => {
    const fetchEndpoint = getPdfEndpoint.replace(
      '{applId}',
      applicationId.toString()
    )

    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage
      )
      .then(response => {
        let content = response.fileContent
        let contentType = 'application/pdf'
        var pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
        var bytearray = this.convertDataURIToBinary(pdfAsDataUri)

        var file = new Blob([bytearray], {
          type: contentType
        })
        // for IE 10/11 save doc pop up will appear
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var filename = 'application.pdf'
          window.navigator.msSaveOrOpenBlob(file, filename)
        } else {
          var fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          // auto download the file
          var a = document.createElement('a')
          a.href = fileURL
          a.target = '_blank'
          a.download = 'application.pdf'
          document.body.appendChild(a)
          a.click()
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'MyApplications _viewPDFApplication failed with ex',
          error
        )
        const code = CODES.MY_APPLICATIONS_VIEW_PDF
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  convertDataURIToBinary = (dataURI: string) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }

  _loadAll = (jsonForms: any, jsonData: any) => {
    let forms, application, formContext, pagination
    application = jsonForms['app']
    forms = application['forms']
    for (let i = 0; i < forms.length; ++i) {
      forms[i]['schema'] = helper.schemaIntersect(
        forms[i]['schema'],
        forms[i]['uiSchema']
      )
    }

    formContext = {
      refs: application['metaData']['refs'],
      forms: forms,
      viewApplicationDetails: this._viewApplicationDetails,
      setRedirect: this._setRedirect,
      copyNredirect: this._copyNredirect,
      withdrawApplication: this._withdrawApplication,
      deleteDraft: this._deleteDraft,
      retroActiveRequest: this._retroActiveRequest,
      viewPDFApplication: this._viewPDFApplication,
      config,
      viewPDFCSApplication: this._viewPDFCSApplication,
      deleteApplicationModal: this._deleteApplicationModal
    }

    let reviewFormContext
    serviceHelper
      .fetchJson(
        appIntakeEndpoint.replace('{version}', '1.0'),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            locale: (this.props.locale + '_us').toUpperCase(),
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
        },
        this.props.showErrorMessage
      )
      .then(formJson => {
        reviewFormContext = {
          reviewForms: formJson['app'].forms,
          refs: {
            ...formJson['app'].metaData.refs,
            ...refs
          }
        }

        this.setState({ reviewFormContext })
      })
      .catch(error => {
        console.error(
          'MyApplications review form fetch failed due to ex',
          error
        )
        const code = CODES.MY_APPLICATIONS_FETCH_REVIEW_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
    let formData = []
    if (jsonData) {
      pagination = { ...jsonData.pagination }
      formData = helper.deepClone({
        applicationSummaries: jsonData.applicationSummaries
      })
    }
    const viewTabs = []
    formContext = {
      ...formContext,
      formData: formData
    }

    this.setState({
      application,
      forms,
      formContext,
      formData: formData,
      pagination
    })
  }

  _reloadAll = pagination => {
    serviceHelper
      .fetchJson(
        loadAppEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            userRoleId: this.props.userRoleId.toString(),
            locale: (this.props.locale + '_us').toUpperCase(),
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          },
          body: JSON.stringify({ pagination: pagination })
        },
        this.props.showErrorMessage
      )
      .then(jsonData => {
        let _formData = helper.deepClone({
          applicationSummaries: jsonData.applicationSummaries
        })
        const formData = { formData: _formData }
        this._onFormDataChange(formData)
      })
      .catch(error => {
        console.error('MyApplications form fetch failed due to ex', error)
        const code = CODES.MY_APPLICATIONS_FETCH_FORM
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  _onFormDataChange = ({ formData }) => {
    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          formData
        }
      }
    })
  }

  _modalWasClosed = () => {
    this.setState({ retroActiveRequest: false, applicationWithdrawn: false })
  }

  _deleteApplicationModal = (deleteAppId: number) => {
    this.setState(prevState => {
      return {
        ...prevState,
        deleteRequest: !prevState.deleteRequest,
        deleteAppId: deleteAppId
      }
    })
  }

  _closeDeleteModal = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        deleteRequest: !prevState.deleteRequest,
        deleteAppId: null
      }
    })
  }

  _deleteApplication = (deleteAppId: number) => {
    this._deleteDraft(deleteAppId)
    this._closeDeleteModal()
  }

  _viewPDFCSApplication = (id: string, applicationId: number) => {
    const fetchEndpoint = getPdfCSEndpoint.replace(
      '{applId}',
      applicationId.toString()
    )

    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage
      )
      .then(response => {
        let content = response.fileContent
        let contentType = 'application/pdf'
        var pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
        var bytearray = this.convertDataURIToBinary(pdfAsDataUri)

        var file = new Blob([bytearray], {
          type: contentType
        })
        // for IE 10/11 save doc pop up will appear
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file)
        } else {
          var fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          // auto download the file
          var a = document.createElement('a')
          a.href = fileURL
          a.target = '_blank'
          a.download = 'application.pdf'
          document.body.appendChild(a)
          a.click()
        }
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error('MyApplications _viewPDFApplication failed with ex', ex)
      })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    const type = _.get(this.state.reviewFormContext, 'formData.type')
    const prc2 =
      _.get(
        this.state.reviewFormContext,
        'formData.Application.programCode[0]'
      ) === 'FS P'
    if (
      this.state.reviewFormContext &&
      this.state.reviewFormContext.formData &&
      this.state.reviewFormContext.formData.applicationType === 'COC'
    ) {
      return (
        <Redirect
          to={
            baseUrl +
            `${type === 'CCA' ? '/coc-child-care' : '/coc'}?applId=` +
            this.state.redirect
          }
        />
      )
    } else if (
      this.state.reviewFormContext &&
      this.state.reviewFormContext.formData &&
      (this.state.reviewFormContext.formData.applicationType === 'RENEWAL' ||
        this.state.reviewFormContext.formData.applicationType === 'Renewal')
    ) {
      return (
        <Redirect
          to={
            baseUrl +
            `${
              type === 'CCA'
                ? '/renewal-child-care'
                : prc2
                ? '/renewal-prc2'
                : '/renewal'
            }?applId=` +
            this.state.redirect
          }
        />
      )
    } else if (
      this.state.reviewFormContext &&
      this.state.reviewFormContext.formData &&
      !this.state.reviewFormContext.formData.caseNumber
    ) {
      return (
        <Redirect to={baseUrl + '/application?applId=' + this.state.redirect} />
      )
    }
    const { presentation, locale, errorMessage } = this.props
    const {
      application,
      forms,
      formData,
      formContext,
      applicationWithdrawn,
      retroActiveRequest,
      reviewFormContext,
      pagination,
      deleteRequest,
      deleteAppId,
      currentDate
    } = this.state

    const presentationProps = {
      application,
      forms,
      formData,
      formContext,
      onFormDataChange: this._onFormDataChange,
      applicationWithdrawn,
      retroActiveRequest,
      modalWasClosed: this._modalWasClosed,
      reviewFormContext,
      goBack: this._goBack,
      changePage: this._changePage,
      pagination,
      locale,
      deleteRequest,
      deleteAppId,
      closeDeleteModal: this._closeDeleteModal,
      deleteApplication: this._deleteApplication,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      currentDate
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state) {
  let uuid = _.get(state.auth, 'userAccount.uuid')
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  let accessToken = _.get(state.auth, 'userAccount.accessToken')
  let roleName = _.get(state.userAccess, 'selectedUserRole.role.roleName') || ''
  let ieOrgId, userRoleId
  let userRoleIdOrgIdList = _.get(state, 'userAccess.userRoleIdOrgIdList') || []
  let currentuserRoleIdOrgIdRecord = _.find(userRoleIdOrgIdList, current => {
    return (
      _.get(current, 'orgId') ==
        _.get(state.userAccess, 'selectedOrg.ieOrgId') &&
      _.get(current, 'rolename') == roleName
    )
  })
  ieOrgId = _.get(currentuserRoleIdOrgIdRecord, 'orgId', '')
  userRoleId = _.get(currentuserRoleIdOrgIdRecord, 'userRoleId', '')
  if (!userRoleId) {
    userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  }
  return {
    auth: state.auth,
    userRoleId,
    locale: state.i18n.locale,
    orgId,
    roleId,
    authHeader: accessToken ? 'Bearer ' + accessToken : undefined,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyApplicationsContainer)
