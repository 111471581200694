import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ProviderInformation from './ProviderInformation'
import { I18n } from 'react-redux-i18n'
import { Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

interface MyProvidersNewPresentationProps {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  formContext: any
  mapProps: {
    member: any
    crfProvider: any
    features: any
  }
  showMap: boolean
  showMapAddress: boolean
  initialDataLoad: boolean
  showCRFMap: boolean
  cancel: () => void
  isModalOpen: boolean
  onFormDataChange: (formData: any) => void
  S: {
    id: number
    code: string
    name: string
    styles: { any }
  }
  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  callingApi: boolean
  locale: string
  providerError: any
  isProviderExistsError: boolean
  closeCRFError: any
}

export default function MyProvidersNewPresentation(
  props: MyProvidersNewPresentationProps
) {
  const {
    forms,
    formData,
    formContext,
    mapProps,
    showMap,
    showMapAddress,
    initialDataLoad,
    showCRFMap,
    cancel,
    isModalOpen,
    onFormDataChange,
    formComponents,
    callingApi,
    locale,
    providerError,
    isProviderExistsError,
    closeCRFError
  } = props

  let output = <ScreenPreLoader loaded={false} />

  if (initialDataLoad && forms) {
    const crfmapError = isProviderExistsError ? (
      <CRFMapError
        isOpen={isProviderExistsError}
        errorMsg={providerError}
        closeCRFModel={closeCRFError}
      />
    ) : null
    output = (
      <div>
        <h1>
          <i className="glyphicon myglyphicon-group" />
          {I18n.t('MyProvidersCCA.title')}
        </h1>
        <Form
          schema={forms[0]['schema']}
          uiSchema={forms[0]['uiSchema']}
          formData={formData}
          formContext={formContext}
          onChange={onFormDataChange}
          fields={formComponents.fields}
          widgets={formComponents.widgets}
          ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
          FieldTemplate={formComponents.FieldTemplate}
          locale={locale}
        >
          <button
            type="submit"
            style={{ display: 'none' }}
            disabled={callingApi}
          />
        </Form>
        <ProviderInformation
          form={forms}
          formData={formData}
          formContext={formContext}
          cancel={cancel}
          isModalOpen={isModalOpen}
          formComponents={formComponents}
          locale={locale}
        />
        {crfmapError}
      </div>
    )
  } else {
    output = (
      <div>
        <h1>
          <i className="glyphicon myglyphicon-group" />{' '}
          {I18n.t('MyProvidersCCA.title')}
        </h1>
        <p>{I18n.t('MyProvidersCCA.description')}</p>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('MyBenefits.title')}</title>
      </Helmet>
      {output}
    </>
  )
}

export class CRFMapError extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      modalOpen: props.isOpen,
      error: props.errorMsg
    }
  }

  _closeModal = () => {
    this.setState({ modalOpen: false }, () => {
      this.props.closeCRFModel()
    })
  }

  render() {
    const { modalOpen, error } = this.state
    return (
      <div className="static-modal">
        <Modal show={modalOpen} onHide={() => this._closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="glyphicon myglyphicon-check btn-icon-sm" />
              <span className="">{'Provider Exists'}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                {error.errorCode + ': ' + error.errorDescription}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-default float-right"
                  onClick={() => this._closeModal()}
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
