import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash'
import { config } from '../../../config'
const moment = require('moment-mini-ts')
import * as serviceHelper from '../src/components/utils'
import * as actions from '../../../actions'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { I18n } from 'react-redux-i18n'

declare const process
const baseUrl = process.env.PUBLIC_URL
declare var Promise: any
const Fragment = (React as any).Fragment
const gatewayWvUrl = config['gatewayWvUrl']
const loadEndpoint = gatewayWvUrl + config['getCocCaseNumbers']
const renewalInfoEndpoint = config['renewalInfoEndpoint']
const formsEndpoint = config['forms_MyLifeEvents']
const deleteDraftEndPoint = gatewayWvUrl + config['deleteDrftEndPoint']

interface MyLifeEventsContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  errorMessage: Array<any>
  deleteErrorMessage: () => void
  showErrorMessage: (message) => void
  logoutUser
  history: {
    push: (url: string) => void
  }
  updateMyOpenCases
  hasCCA
  locale
}

interface MyLifeEventsContainerState {
  form: {
    schema: Object
    uiSchema: Object
  }
  reportModal: boolean
  draftButton: boolean
  deleteButton: boolean
  formContext: any
}

class MyLifeEventsContainer extends React.Component<
  MyLifeEventsContainerProps,
  MyLifeEventsContainerState
> {
  constructor(props: MyLifeEventsContainerProps) {
    super(props)

    this.state = {
      form: null,
      formContext: {},
      reportModal: false,
      draftButton: false,
      deleteButton: false
    }
  }

  componentDidMount() {
    document.title = 'My Changes - My Account'
    this._initializeForm()
  }

  componentDidUpdate(
    prevProps: MyLifeEventsContainerProps,
    prevState: MyLifeEventsContainerState
  ) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      this._initializeForm()
    }
  }

  static getDerivedStateFromProps(
    props: MyLifeEventsContainerProps,
    state: MyLifeEventsContainerState
  ) {
    const newFormData = state.formContext.formData
      ? state.formContext.formData
      : props.formData
    return {
      formData: newFormData,
      formContext: {
        ...state.formContext,
        formData: newFormData
      }
    }
  }

  _initializeForm = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(form => {
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            formContext: {
              ...prevState.formContext,
              formData: this.props.formData,
              forms: [{ ...form }],
              refs: {
                '{case summary}': ({ fieldId, formContext }) => {
                  try {
                    const index = fieldId.match('^LifeEvents_([0-9]+)_?')[1]
                    const caseDetails = formContext.formData[index].caseId
                    const benefitType =
                      caseDetails.programType === 'CCA'
                        ? I18n.t('General.title3')
                        : I18n.t('General.title4')
                    return (
                      I18n.t('General.title1') +
                      caseDetails.extCaseNumber +
                      I18n.t('General.title2') +
                      benefitType
                    )
                  } catch (err) {
                    return 'Case ID UNKNOWN'
                  }
                },
                '{female over 10}': formContext => {
                  let femaleOverTenFlag = false
                  for (
                    let i = 0;
                    i <
                    formContext.formContext.formData.application.clients.length;
                    i++
                  ) {
                    let thisApplicant =
                      formContext.formContext.formData.application.clients[i]
                        .clientDetails.personalData[0]
                    if (
                      thisApplicant.gdrCd === '2' ||
                      thisApplicant.gdrCd === '3'
                    ) {
                      let birthdate = _moment(config, thisApplicant.clientBthDt)
                      let cur = _moment(config, getServerTime())
                      if (cur.diff(birthdate, 'years') >= 10) {
                        femaleOverTenFlag = true
                        return femaleOverTenFlag
                      } else {
                        femaleOverTenFlag = false
                      }
                    }
                  }
                  return femaleOverTenFlag
                },
                '{has only qhp}': ({ fieldId, formContext }) => {
                  try {
                    const index = fieldId.match('^LifeEvents_([0-9]+)_?')[1]
                    const clients = _.get(
                      formContext,
                      'formData.' + index + '.clients'
                    )
                    return (
                      _.some(clients, value => {
                        return value['hasBenefitQHP'] === true
                      }) &&
                      !_.some(clients, value => {
                        return (
                          value['hasBenefitHEAP'] === true ||
                          value['hasBenefitMEDICAID'] === true ||
                          value['hasBenefitSNAP'] === true ||
                          value['hasBenefitTANF'] === true ||
                          value['hasBenefitCCA'] === true
                        )
                      })
                    )
                  } catch (err) {
                    return false
                  }
                }
              }
            }
          }
        })
      })
      .catch(error => {
        console.error('MyLifeEvents _initializeForm failed due to ex', error)
      })
  }

  _onFormDataChange = ({ formData }) => {
    const readyApplication = formData.filter(coc => {
      return coc.caseId.cocReady === 'ready'
    })
    this._showReportChange(readyApplication)
  }

  _showReportChange = formData => {
    this.setState(prevState => {
      return {
        ...prevState,
        reportModal: !prevState.reportModal,
        formData,
        formContext: {
          ...prevState.formContext,
          formData: formData
        }
      }
    })
  }

  _closeModal = () => {
    this.setState({
      reportModal: false,
      draftButton: false,
      deleteButton: false
    })
  }

  _deleteChecked = () => {
    this.setState({ draftButton: false, deleteButton: true })
  }

  _draftChecked = () => {
    this.setState({ deleteButton: false, draftButton: true })
  }

  _deleteDraft = formData => {
    const applId = formData[0].caseId.applId
    const fetchEndpoint = deleteDraftEndPoint

    helper
      .fetchJson(fetchEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          applId: applId
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
      })
    this.setState(prevState => {
      return {
        ...prevState,
        formData,
        formContext: {
          ...prevState.formContext,
          formData: [
            {
              ...prevState.formContext.formData[0],
              caseId: {
                ...prevState.formContext.formData[0].caseId,
                status: 'DELETE'
              }
            }
          ]
        }
      }
    })
  }

  _startDraft = formData => {
    this.setState(prevState => {
      return {
        ...prevState,
        formData,
        formContext: {
          ...prevState.formContext,
          formData: [
            {
              ...prevState.formContext.formData[0],
              caseId: {
                ...prevState.formContext.formData[0].caseId,
                status: 'INDRAFT'
              }
            }
          ]
        }
      }
    })
  }

  _errorListTemplate = (props: any) => {
    const { errors } = props
    return null
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    let programCode = this.props.hasCCA
    // const programCode = this.state.formContext.formData[0].programC
    if (programCode) {
      programCode = 'CCA'
    } else {
      programCode = 'FA'
    }

    if (
      this.state.formContext.formData &&
      this.state.formContext.formData[0] &&
      this.state.formContext.formData[0].caseId.status === 'DELETE'
    ) {
      return (
        <Redirect
          to={
            baseUrl +
            `/${
              this.state.formContext.formData[0].caseId.programType === 'CCA'
                ? 'coc-child-care'
                : 'coc'
            }?caseId=` +
            this.state.formContext.formData[0].caseId.caseNumber +
            '&applId=' +
            this.state.formContext.formData[0].caseId.applId +
            '&status=' +
            this.state.formContext.formData[0].caseId.status
          }
        />
      )
    } else if (
      this.state.formContext.formData &&
      this.state.formContext.formData[0] &&
      this.state.formContext.formData[0].caseId.status === 'INDRAFT'
    ) {
      return (
        <Redirect
          to={
            baseUrl +
            `/${
              this.state.formContext.formData[0].caseId.programType === 'CCA'
                ? 'coc-child-care'
                : 'coc'
            }?caseId=` +
            this.state.formContext.formData[0].caseId.caseNumber +
            '&applId=' +
            this.state.formContext.formData[0].caseId.applId +
            '&status=' +
            this.state.formContext.formData[0].caseId.status
          }
        />
      )
    }

    const {
      presentation,
      formData,
      isLoading,
      minHeight,
      errorMessage
    } = this.props
    const {
      form,
      formContext,
      reportModal,
      draftButton,
      deleteButton
    } = this.state

    const presentationProps = {
      form,
      formContext,
      formData,
      onFormDataChange: this._onFormDataChange,
      isLoading,
      minHeight,
      showReportChange: this._showReportChange,
      reportModal,
      draftButton,
      deleteButton,
      deleteDraft: this._deleteDraft,
      startDraft: this._startDraft,
      closeModal: this._closeModal,
      draftChecked: this._draftChecked,
      deleteChecked: this._deleteChecked,
      errorListTemplate: this._errorListTemplate,
      errorMessage,
      onDeleteClick: this._onDeleteClick
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  return {
    auth: state.auth,
    errorMessage,
    hasCCA: state.hasCCA.hasCCA,
    locale: state.i18n.locale
  }
}

const initialData = null
const createEndpoint = props => {
  return loadEndpoint
}
const createRequest = props => {
  return {
    method: 'GET',
    headers: {
      uuid: props.auth.userAccount.uuid,
      tenantCode: config.tCode,
      Authorization: config.bearer + props.auth.accessToken,
      portalName: config.portalName,
      'Content-Type': 'application/json',
      programType: props.hasCCA ? 'CCA' : 'FA'
    }
  }
}
const responseMapper = (json, updateMyOpenCases) => {
  const cL = _.uniqBy(json.caseList, 'caseNumber')
  const openCases = _.filter(cL, c => {
    return c.status === 'OPEN' || c.status === 'INPROGRESS'
  })
  updateMyOpenCases(openCases)
  if (Array.isArray(cL) && cL.length > 0) {
    let combinedJson = []
    _.forEach(cL, (value, index) => {
      const caseIndex = _.findIndex(combinedJson, element => {
        return element.caseId === value.caseNumber
      })
      if (caseIndex >= -1) {
        combinedJson.push(value)
      } else {
        _.merge(combinedJson[caseIndex], value)
      }
    })

    let newFormData = combinedJson.map(element => {
      let clients = []
      _.forEach(element.programClientMap, (value, program) => {
        if (program === 'Healthcare') {
          _.forEach(value, healthCareValue => {
            const clientIndex = _.findIndex(clients, client => {
              return client.clientName === healthCareValue.clientName
            })
            const activeQHP =
              healthCareValue.benefitProgramCode === 'QHP' &&
              healthCareValue.clientProgramStatusValue === 'Active'
            if (clientIndex === -1) {
              clients.push({
                clientName: healthCareValue.clientName,
                hasBenefitHEAP: false,
                hasBenefitMEDICAID: false,
                hasBenefitQHP: activeQHP,
                hasBenefitSNAP: false,
                hasBenefitTANF: false,
                hasBenefitCCA: false
              })
            } else if (activeQHP) {
              clients[clientIndex].hasBenefitQHP = true
            }
          })
        } else {
          _.forEach(value, clientName => {
            const clientIndex = _.findIndex(clients, client => {
              return client.clientName === clientName
            })

            if (clientIndex === -1) {
              clients.push({
                clientName,
                hasBenefitHEAP: program === 'HEAP',
                hasBenefitMEDICAID: program === 'MEDICAID',
                hasBenefitQHP: program === 'QHP',
                hasBenefitSNAP: program === 'SNAP',
                hasBenefitTANF: program === 'TANF',
                hasBenefitCCA: program === 'CCA'
              })
            } else {
              switch (program) {
                case 'HEAP':
                  clients[clientIndex].hasBenefitHEAP = true
                  break
                case 'MEDICAID':
                  clients[clientIndex].hasBenefitMEDICAID = true
                  break
                case 'QHP':
                  clients[clientIndex].hasBenefitQHP = true
                  break
                case 'SNAP':
                  clients[clientIndex].hasBenefitSNAP = true
                  break
                case 'TANF':
                  clients[clientIndex].hasBenefitTANF = true
                  break
                case 'CCA': {
                  clients[clientIndex].hasBenefitCCA = true
                  break
                }
              }
            }
          })
        }
      })
      return {
        caseId: element
      }
    })
    helper.cleanNullValues(newFormData)
    return newFormData
  } else {
    return []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    },
    updateMyOpenCases: openCases => {
      dispatch(actions.updateMyOpenCases(openCases))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  serviceHelper.withFetcher(
    MyLifeEventsContainer,
    false,
    initialData,
    createEndpoint,
    createRequest,
    responseMapper,
    config,
    config
  )
)

export const MyLifeEventsDoubleFetcherContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  serviceHelper.withFetcher(
    MyLifeEventsContainer,
    true,
    initialData,
    createEndpoint,
    createRequest,
    responseMapper,
    {
      ...config,
      fetcher: {
        retries: 0,
        timeoutMs: 60000
      }
    },
    config
  )
)
