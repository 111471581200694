import * as React from 'react'
import * as _ from 'lodash'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'

const Fragment = (React as any).Fragment

export default class AuthorizedRepField extends React.Component<any, any> {
  constructor(props) {
    super(props)
    const formData = _.cloneDeep(props.formData)
    this.state = {
      oldFormData: _.cloneDeep(formData),
      formData,
      changeMode: false
    }
  }

  render() {
    const { uiSchema, idSchema } = this.props
    const { formData, oldFormData, changeMode } = this.state
    const newUiSchema = {
      ...uiSchema,
      'ui:field': null,
      'ui:disabled': !changeMode,
      'ui:readonly': !changeMode,
      'ui:options': {
        ...uiSchema['ui:options'],
        addable: !changeMode ? false : uiSchema['ui:options']['addable'],
        orderable: !changeMode ? false : uiSchema['ui:options']['orderable'],
        removable: !changeMode ? false : uiSchema['ui:options']['removable']
      }
    }
    delete newUiSchema['ui:field']

    const id = idSchema.$id
    return (
      <div id={id}>
        <span>Has your assister representative information changed?</span>
        <div className="field-radio-group">
          <div className="radio-inline">
            <label>
              <span>
                <input
                  id={id + '_haveChange'}
                  type="radio"
                  checked={!!changeMode === true}
                  value={'true'}
                  name={id + '_haveChange'}
                  onChange={event => {
                    this.setState({ changeMode: true })
                  }}
                />
                <span>Yes</span>
              </span>
            </label>
          </div>
          <div className="radio-inline">
            <label>
              <span>
                <input
                  id={id + '_haveChange'}
                  type="radio"
                  checked={!!changeMode === false}
                  value={'false'}
                  name={id + '_haveChange'}
                  onChange={event => {
                    this.setState(
                      {
                        changeMode: false,
                        formData: _.cloneDeep(oldFormData)
                      },
                      () => this.props.onChange(this.state.formData)
                    )
                  }}
                />
                <span>No</span>
              </span>
            </label>
          </div>
        </div>
        {(changeMode || (formData && formData.length > 0)) && (
          <Fragment>
            <br />
            <div className="well-add-section padding-top-5x">
              <Fields.CustomSchemaField
                {...this.props}
                uiSchema={newUiSchema}
                formData={formData}
                onChange={formData => {
                  this.setState(
                    {
                      formData
                    },
                    () => this.props.onChange(this.state.formData)
                  )
                }}
              />
            </div>
          </Fragment>
        )}
      </div>
    )
  }
}
