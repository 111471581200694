import * as React from 'react'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as actions from '../../../actions'
import * as _ from 'lodash' // extra functionality
import { config } from '../../../config' // endpoints
import { connect } from 'react-redux'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError } from '../src/components/utils'

const formsBaseUrl = config['formsBaseUrl']
const gatewayWvUrl = config['gatewayWvUrl']
const baseUrl = process.env.PUBLIC_URL

const loadEndpoint = gatewayWvUrl + config['communityPartnerSearch']
const formsEndpoint = formsBaseUrl + config['forms_CommunityPartnerSearch']

interface CPSContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  county1: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  roleId: any
  orgId: any
  logoutUser
  history
}

interface CPSContainerState {
  application: any
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formContext: any
  reqCounty: string
  formData: object
  currentCounty: string
  dynamicHeading: any
}

class CPSContainer extends React.Component<
  CPSContainerProps,
  CPSContainerState
> {
  constructor(props: CPSContainerProps) {
    super(props)

    this.state = {
      application: {},
      forms: null,
      formContext: {
        formData: {}
      },
      reqCounty: null,
      formData: null,
      currentCounty: '',
      dynamicHeading: 'Community Partner Search'
    }
  }

  componentDidMount() {
    document.title = 'Community Partner Search'
    this._loadForms()
  }

  _postApplicationJSON = () => {}
  _onFormDataChange = ({ formData }) => {
    let county = formData.countySearch[0].county
    if (county) {
      this.setState({
        dynamicHeading: county + ' Community Partner Search'
      })
      document.title = county + ' ' + '-' + ' ' + 'Community Partner Search'
    } else {
      this.setState({
        dynamicHeading: 'Community Partner Search'
      })
      document.title = 'Community Partner Search'
    }
    if (county !== undefined) {
      this.setState(prevState => {
        return {
          ...prevState,
          formData: formData,
          formContext: {
            ...prevState.formContext,
            formData: formData
          }
        }
      })
      this._fetchCommunityPartners(county)
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          formData: null
        }
      })
    }
  }

  _fetchCommunityPartners = county => {
    const endpoint = loadEndpoint.replace('{cntyName}', county)
    if (county !== undefined && county !== null) {
      helper
        .fetchJson(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            pragma: 'no-cache',
            'cache-control': 'no-cache no-store',
            uuid: this.props.auth.userAccount
              ? this.props.auth.userAccount.uuid || ''
              : '',
            tenantCode: config.tCode,
            Authorization: config.bearer + (this.props.auth.accessToken || ''),
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        })
        .then(json => {
          const allCommunityPartners = json
          return this.setState(prevState => {
            return {
              ...prevState,
              formContext: {
                ...prevState.formContext,
                formData: {
                  ...prevState.formData,
                  allCommunityPartners
                }
              },
              formData: {
                ...prevState.formData,
                allCommunityPartners
              }
            }
          })
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          return ex
        })
    } else {
      console.error('CPS Container, county is undefined')
    }
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _loadForms = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount
          ? this.props.auth.userAccount.uuid || ''
          : '',
        tenantCode: config.tCode,
        Authorization: config.bearer + (this.props.auth.accessToken || ''),
        portalName: config.portalName
      }
    }

    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(formJson => {
        let application, forms, formContext
        application = formJson['app']
        forms = application['forms']
        this.setState(prevState => {
          return {
            application,
            forms,
            formContext: {
              ...prevState.formContext,
              forms: forms,
              refs: {
                '{this partner name}': ({ fieldId, formContext }) => {
                  try {
                    const index = fieldId.match(
                      '^root_allCommunityPartners_([0-9]+)_?'
                    )[1]
                    return formContext.formData.allCommunityPartners[
                      parseInt(index, 10)
                    ].communityPartner
                  } catch (err) {
                    return 'Case ID UNKNOWN'
                  }
                }
              }
            }
          }
        })
      })
      .catch(error => {
        console.error('CPS form fetch failed due to ex', error)
      })
  }
  render() {
    const { presentation, minHeight, isLoading, errorMessage } = this.props
    const {
      application,
      formData,
      formContext,
      forms,
      reqCounty,
      currentCounty,
      dynamicHeading
    } = this.state

    const presentationProps = {
      application,
      formData,
      formContext,
      forms,
      reqCounty,
      isLoading,
      minHeight,
      currentCounty,
      onFormDataChange: this._onFormDataChange,
      dynamicHeading,
      errorMessage,
      onDeleteClick: this._onDeleteClick
    }

    return presentation(presentationProps)
  }
}
function mapStateToProps(state, ownProps) {
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''

  return {
    auth: state.auth,
    roleId,
    orgId,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CPSContainer)
