import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Home from '../Home'
import Help from '../Help'
import AppIntake from '../AppIntake'
import AppIntakePE from '../AppIntakePE'
import MyAccount from '../MyAccount'
import BenefitsCatalog from '../BenefitsCatalog'
import Feedback from '../Feedback'
import MyAppDrafts from '../MyAppDrafts'
import ClientUpdateWizard from '../ClientUpdateWizard'
import ClientUpdateWizardChildCare from '../ClientUpdateWizardChildCare'
import ClientUpdateWizardSNAPPRC2 from '../ClientUpdateWizardSNAPPRC2'
import SelectRole from '../SelectRole'
import { config } from '../../../config'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary } from '../Errors'
import MyForms from '../MyForms'

declare const process
const baseUrl = process.env.PUBLIC_URL
const Fragment = (React as any).Fragment

export default function Routes(props) {
  const { auth, openIdRedirect } = props
  return (
    <Fragment>
      <Route exact path={baseUrl + '/'} component={Home} />
      <Route path={baseUrl + '/index.html'} component={Home} />
      <Route path={baseUrl + '/home'} component={Home} />
      <Route path={baseUrl + '/ie-portal-webapp/react/home'} component={Home} />
      <Route
        auth={auth}
        path={baseUrl + '/application'}
        component={AppIntake}
        componentProps={null}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/peApplication'}
        component={AppIntakePE}
        componentProps={null}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/myaccount'}
        component={MyAccount}
        componentProps={null}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/appdrafts'}
        component={MyAppDrafts}
        componentProps={null}
      />
      <Route path={baseUrl + '/benefitscatalog'} component={BenefitsCatalog} />
      <Route
        path={baseUrl + '/benefitsfinder/:value'}
        render={() => (
          <ErrorBoundary name="BenefitsFinder">
          
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path={baseUrl + '/benefitsfinder'}
        render={() => (
          <ErrorBoundary name="BenefitsFinder">
          
          </ErrorBoundary>
        )}
      />
      <Route path={baseUrl + '/feedback'} component={Feedback} />
      {process.env.NODE_ENV === 'production' ? (
        <Route path={baseUrl + '/login'} component={openIdRedirect} />
      ) : (
        <Route path={baseUrl + '/login'} component={Home} />
      )}
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/renewal-prc2'}
        component={ClientUpdateWizardSNAPPRC2}
        componentProps={{ isRenewal: true }}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/renewal'}
        component={ClientUpdateWizard}
        componentProps={{ isRenewal: true }}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/renewal-child-care'}
        component={ClientUpdateWizardChildCare}
        componentProps={{ isRenewal: true }}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/coc'}
        component={ClientUpdateWizard}
        componentProps={{ isRenewal: false }}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/coc-child-care'}
        component={ClientUpdateWizardChildCare}
        componentProps={{ isRenewal: false }}
      />
      <PrivateRoute
        auth={auth}
        path={baseUrl + '/selectRole'}
        component={SelectRole}
        componentProps={null}
      />
      <Route path={baseUrl + '/help'} component={Help} componentProps={null} />
      <Route
        path={baseUrl + '/myForms'}
        component={MyForms}
        componentProps={null}
      />
    </Fragment>
  )
}

function PrivateRoute({ component: Component, auth, componentProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        helper.isLoggedIn(auth, config) ? (
          <Component {...componentProps} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: baseUrl + '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}
