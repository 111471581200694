import * as React from 'react'
import * as _ from 'lodash'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import '!style-loader!css-loader!./applicationSearch.css'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorModal } from '../src/components/natives/ErrorModal'
//import PropTypes from 'prop-types'

interface ApplicationSearchPresentationProps {
  form: {
    app: {
      forms: Array<{
        schema: object
        uiSchema: object
      }>
      metaData: object
    }
  }
  application: any
  formContext: any
  formData: any
  onFormDataChange: (formData: any) => void
  isLoading: boolean
  minHeight: string
  formComponents
  mainContainer: boolean
  navigateApplicationApplicationSearch: () => void
  searchResults: any
  searchFormData: any
  resetForm: () => void
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export default function ApplicationSearchPresentation(
  props: ApplicationSearchPresentationProps
) {
  const {
    form,
    formContext,
    onFormDataChange,
    navigateApplicationApplicationSearch,
    isLoading,
    minHeight,
    formComponents,
    mainContainer,
    searchResults,
    searchFormData,
    resetForm,
    errorMessage,
    onDeleteClick
  } = props

  const header = isLoading ? <h2 /> : <div />

  if (!mainContainer) {
    const resultsFormData = {
      applicationSearchResults: searchResults
    }

    const resultsFormContext = {
      ...formContext,
      formData: resultsFormData
    }

    let card
    let schema
    let uiSchema
    if (searchFormData.applicationSearchCriteria !== undefined) {
      card = searchFormData.applicationSearchCriteria.hbpeTempMedicalCard
      schema =
        card === 'Yes' ? form.app.forms[2].schema : form.app.forms[1].schema
      uiSchema =
        card === 'Yes' ? form.app.forms[2].uiSchema : form.app.forms[1].uiSchema
    } else {
      schema = form.app.forms[2].schema
      uiSchema = form.app.forms[2].uiSchema
    }

    return (
      <div>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <div>
          <Form
            schema={schema}
            uiSchema={uiSchema}
            formData={resultsFormData}
            onChange={onFormDataChange}
            formContext={resultsFormContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          />
          <div className="col-lg-12">
            <div className="col-lg-6">
              <button
                type="button"
                className="btn btn-default float-left reset-margin userListButton1"
                onClick={navigateApplicationApplicationSearch}
              >
                Previous
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (form) {
    const searchDataFormContext = {
      ...formContext,
      formData: searchFormData
    }
    return (
      <div>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
        <div>
          {header}
          <Form
            schema={form.app.forms[0].schema}
            uiSchema={form.app.forms[0].uiSchema}
            formData={searchDataFormContext.formData}
            onChange={onFormDataChange}
            formContext={searchDataFormContext}
            fields={formComponents.fields}
            widgets={formComponents.widgets}
            ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            FieldTemplate={formComponents.FieldTemplate}
          />
          <div className="col-lg-12">
            <div className="col-lg-6" />
            <div className="col-lg-6 app-btn-placement">
              <button
                type="submit"
                id="ApplicationSearch_search"
                className="btn btn-default userListButton2 goto-focus"
                onClick={navigateApplicationApplicationSearch}
              >
                Search
              </button>
              <button
                type="button"
                className="btn btn-default reset-margin userListButton1 goto-focus"
                onClick={resetForm}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <ScreenPreLoader minHeight={minHeight} />
}
