import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash'
import { config } from '../../../config'
import * as serviceHelper from '../src/components/utils'
import * as actions from '../../../actions'
import { UnauthorizedError } from '../src/components/utils'

declare const process
const baseUrl = process.env.PUBLIC_URL

const formsEndpoint = config['formsBaseUrl'] + config['forms_ApplicationSearch']
const endPoint = config['gatewayWvUrl'] + config['dhhrWorkerApplicationSearch']
const getPdfEndpoint =
  config['gatewayWvUrl'] + config['getWorkerApplicationPDF']
const roleEndPoint = config['getAllRoles']

interface ApplicationSearchContainerProps {
  presentation: any
  isLoading: boolean
  formData: any
  minHeight: string
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  showModal: boolean
  errorMessage: Array<any>
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  userRoleId
  orgId: any
  roleId: any
  ieOrgId: any
  logoutUser
  history
}

interface ApplicationSearchContainerState {
  form: {
    schema: Object
    uiSchema: Object
  }
  application: any
  formContext: any
  mainContainer: boolean
  searchResults: any
  showResultsTable: boolean
  searchFormData: any
  redirect: number
  reviewFormContext: any
  getAllRoles: Array<any>
}

class ApplicationSearchContainer extends React.Component<
  ApplicationSearchContainerProps,
  ApplicationSearchContainerState
> {
  constructor(props: ApplicationSearchContainerProps) {
    super(props)

    this.state = {
      form: null,
      application: {},
      formContext: {},
      mainContainer: true,
      searchResults: {},
      showResultsTable: false,
      searchFormData: {},
      redirect: null,
      reviewFormContext: null,
      getAllRoles: []
    }
  }

  componentDidMount() {
    this._initializeForm()
    document.title = 'Application Search - My Account'
  }

  _navigateApplicationApplicationSearch = () => {
    let searchData = this.state.searchFormData.applicationSearchCriteria
    const allRoles = this.state.getAllRoles
      ? this.state.getAllRoles['roleList']
      : ''
    let CProleIdList,
      PEroleIdList,
      cpRoleId = [],
      peRoleId = []

    if (
      searchData &&
      searchData.communityPartner === 'Yes' &&
      allRoles.length > 0
    ) {
      CProleIdList = _.filter(allRoles, function(role) {
        return role.roleName === 'Community Partner'
      })
      for (let i = 0; i < CProleIdList.length; i++) {
        cpRoleId.push(CProleIdList[i].roleId)
      }
    }
    if (searchData && searchData.HBPEWorker === 'Yes' && allRoles.length > 0) {
      PEroleIdList = _.filter(allRoles, function(role) {
        return role.roleName === 'Presumptive Eligibility Worker'
      })
      for (let i = 0; i < PEroleIdList.length; i++) {
        peRoleId.push(PEroleIdList[i].roleId)
      }
    }

    if (searchData == undefined) {
      searchData = {
        hbpeTempMedicalCard: 'Yes'
      }
    }

    try {
      serviceHelper
        .fetchJson(
          endPoint,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              roleId: this.props.roleId,
              orgId: this.props.orgId
            },
            body: JSON.stringify({
              dhhrWorkerApplicationSearch: {
                ...searchData,
                cpRoleIdList: cpRoleId.length > 0 ? cpRoleId.toString() : null,
                peRoleIdList: peRoleId.length > 0 ? peRoleId.toString() : null
              }
            })
          },
          this.props.showErrorMessage
        )
        .then(data => {
          if (this.state.mainContainer) {
            document.title = 'Application Search Results - My Account'
          } else {
            document.title = 'Application Search - My Account'
          }

          data = data.map(e => {
            let updatedName = e.primaryPerson.split(' ')
            for (let i = 0; i < updatedName.length; i++) {
              if (updatedName[i] == 'null') {
                updatedName[i] = ' '
              }
            }

            updatedName = updatedName.join(' ')

            return {
              ...e,
              primaryPerson: updatedName
            }
          })
          this.setState({
            mainContainer: !this.state.mainContainer,
            searchResults: data
          })
        })
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        this.props.logoutUser()
        this.props.history.push(baseUrl + '/home')
      }
      console.error('_navigateApplicationApplicationSearch() error', e)
    }
  }

  _resetForm = () => {
    this.setState({ searchFormData: undefined })
  }

  static getDerivedStateFromProps(
    props: ApplicationSearchContainerProps,
    state: ApplicationSearchContainerState
  ) {
    return {
      formContext: {
        ...state.formContext,
        formData: props.formData || state.formContext.formData
      }
    }
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({
      searchFormData: formData
    })
  }

  _initializeForm = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }

    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(form => {
        helper.cleanNullValues(form)
        this.setState(prevState => {
          return {
            form,
            formContext: {
              ...prevState.formContext,
              forms: [{ ...form }],
              viewWorkerPDFApplication: this._viewPDFApplication,
              viewPDFApplication: this._viewPDFApplication
            }
          }
        })
        this._getRoles()
      })
      .catch(error => {
        console.error('user acces form error', error)
      })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }
  _viewPDFApplication = (applId: any, applStatus: any) => {
    if (applStatus === 'DRAFT') {
      const loadDraftEndpoint = config['getDraftApplication']
      const fetchEndpoint = loadDraftEndpoint.replace('{applId}', applId)
      serviceHelper
        .fetchJson(
          fetchEndpoint,
          {
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              userRoleId: this.props.userRoleId,
              orgId: this.props.ieOrgId
            },
            method: 'POST'
          },
          this.props.showErrorMessage
        )
        .then(jsonData => {
          helper.cleanNullValues(jsonData)
          this.setState({
            reviewFormContext: {
              ...this.state.searchFormData,
              formData: jsonData,
              reviewFormData: jsonData
            },
            redirect: applId
          })
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.error(
            'ApplicationSearch _viewPDFApplication failed with ex',
            ex
          )
        })
    } else if (applStatus === 'SUBMITTED') {
      const fetchEndpoint = getPdfEndpoint.replace(
        '{applId}',
        applId.toString()
      )
      const request = {
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName,
          roleId: this.props.roleId,
          orgId: this.props.orgId
        }
      }

      serviceHelper
        .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
        .then(response => {
          let content = response.fileContent
          let contentType = 'application/pdf'
          var pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
          var bytearray = this.convertDataURIToBinary(pdfAsDataUri)

          var file = new Blob([bytearray], {
            type: contentType
          })
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file)
          } else {
            var fileURL = URL.createObjectURL(file)
            window.open(fileURL)
            // auto download the file
            var a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = 'application.pdf'
            document.body.appendChild(a)
            a.click()
          }
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.props.history.push(baseUrl + '/home')
          }
          console.error('MyApplications _viewPDFApplication failed with ex', ex)
        })
    }
  }

  convertDataURIToBinary = (dataURI: string) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }

  _getRoles = () => {
    helper
      .fetchJson(roleEndPoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          uuid: this.props.auth.userAccount.uuid,
          tenantCode: config.tCode,
          Authorization: config.bearer + this.props.auth.accessToken,
          portalName: config.portalName
        }
      })
      .then(data => {
        this.setState({
          getAllRoles: data
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
        console.log(error)
      })
  }

  render() {
    if (this.state.reviewFormContext && this.state.reviewFormContext.formData) {
      return (
        <Redirect to={baseUrl + '/application?applId=' + this.state.redirect} />
      )
    }
    const { presentation, isLoading, minHeight, errorMessage } = this.props
    const {
      form,
      application,
      formContext,
      mainContainer,
      showResultsTable,
      searchResults,
      searchFormData
    } = this.state

    const presentationProps = {
      form,
      formContext,
      onFormDataChange: this._onFormDataChange,
      isLoading,
      minHeight,
      mainContainer,
      navigateApplicationApplicationSearch: this._navigateApplicationApplicationSearch.bind(
        this
      ),
      showResultsTable,
      searchResults,
      searchFormData,
      resetForm: this._resetForm,
      errorMessage: errorMessage,
      onDeleteClick: this._onDeleteClick
    }

    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let userType = _.get(state.userAccess.userRoles[0].role.roleDescription) || ''
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []

  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let userAccess = _.get(state, 'userAccess') || ''
  let userRoleIdOrgIdList = _.get(state, 'userAccess.userRoleIdOrgIdList') || []
  let ieOrgId, userRoleId, roleName
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleName = _.get(state.userAccess, 'selectedOrg.roleName') || ''
  } else {
    roleName = _.get(state.userAccess, 'selectedUserRole.role.roleName') || ''
  }
  let currentuserRoleIdOrgIdRecord = _.find(userRoleIdOrgIdList, current => {
    return (
      _.get(current, 'orgId') ==
        _.get(state.userAccess, 'selectedOrg.ieOrgId') &&
      _.get(current, 'rolename') == roleName
    )
  })
  ieOrgId = _.get(currentuserRoleIdOrgIdRecord, 'orgId', '')
  userRoleId = _.get(currentuserRoleIdOrgIdRecord, 'userRoleId', '')
  if (!userRoleId) {
    userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  }
  return {
    errorMessage,
    auth: state.auth,
    userRoleId,
    roleId,
    orgId,
    ieOrgId
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationSearchContainer)
