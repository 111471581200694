import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import * as queryString from 'query-string'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { Redirect } from 'react-router-dom'
import { config } from '../../../config'
import { refs } from '@optum-wvie/dynamic-ui-framework/src/refs'
import * as validator from '@optum-wvie/dynamic-ui-framework/src/validation'
import CustomValidator from '@optum-wvie/dynamic-ui-framework/src/CustomValidator'
import { ENTITLEMENTS } from '@optum-wvie/dynamic-ui-framework/src/entitlements'
import * as actions from '../../../actions'
import { customValidationErrors } from './ClientUpdateWizardValidation'
import * as validation from '../src/validation'
import * as serviceHelper from '../src/components/utils'
import { UnauthorizedError, getServerTime } from '../src/components/utils'

// import * as renewalWizardForms from './__mockForms__/RenewalWizard.json'
// import * as lifeEventsForms from './__mockForms__/LifeEventsWizard.json'
// import * as appIntakeSchemaa from './__mockForms__/AppIntakeSchema.json'
import { v4 } from 'uuid'
import { determineEnvironment } from '~/config_WV'

declare const process
const baseUrl = process.env.PUBLIC_URL

const gatewayWvUrl = config['gatewayWvUrl']
const formsBaseUrl = config['formsBaseUrl']
const loadUserEndpoint = config['getUserAccountDetails']
const loadEndpoint = gatewayWvUrl + config['getClientDetails']
const loadRenewalpoint = gatewayWvUrl + config['review']
const submitCocEndpoint =
  config['gatewayWvUrl'] + '/WVIE/case/v1.0/saveClientDetails'
const appIntakeSchemaEndpoint =
  formsBaseUrl + config['forms_AppIntakeSchemaPRC2']
const submitRenewalEndpoint =
  gatewayWvUrl + '/WVIE/client-review/v2.0/saveClientReview'
const saveDraftEndpoint = gatewayWvUrl + config['saveCOCApplication']
const loadDraftEndpoint = config['getDraftApplication']

//Endpoints needed for Renewal mode
const renewalWizardFormsEndpoint = config['forms_RenewalWizardPRC2']
const renewalInfoEndpoint = config['renewalInfoEndpoint']

//Endpoints needed for Life Events (COC) mode
const lifeEventsFormsEndpoint = config['forms_LifeEventsWizard']

const pristineIcon = ''

const editingIcon = 'glyphicon myglyphicon-pencil'
const editingBadge = 'badge bg-white-alt'

const validatedIcon = 'glyphicon myglyphicon-ok'
const validatedBadge = 'badge bg-green-alt'

const errorIcon = 'glyphicon myglyphicon-circle_exclamation_mark'
const errorBadge = 'badge bg-yellow-alt'
let addedNewApplicant: boolean = false
let newApplicantIndex: any
let initClientsLength: any
//This is for step-specific logic to not assume the step number.  Keep this updated.
const STEPS = {
  RENEWAL_INFO: 1,
  CHANGE_IN_ADDRESS: 2,
  INFO_PPL_HH: 3,
  LOTTERY_GAMBLING: 4,
  EARNED_INCOME: 5,
  UNEARNED_INCOME: 6,
  CHILD_SUPPORT: 7,
  SHELTER_UTILITIES: 8,
  ABAWD: 9,
  REVIEW_AND_SIGN: 10
}

function getStepBySection(name) {
  if (name.includes('slides')) {
    return STEPS.RENEWAL_INFO
  } else if (name.includes('householdInfo')) {
    return STEPS.CHANGE_IN_ADDRESS
  } else if (name.includes('clientDetails')) {
    return STEPS.INFO_PPL_HH
  } else if (name.includes('lotteryGamblingWinnings')) {
    return STEPS.LOTTERY_GAMBLING
  } else if (
    (name.includes('earnedIncomes') ||
      name.includes('selfEmploymentIncomes')) &&
    !name.includes('unearnedIncomes')
  ) {
    return STEPS.EARNED_INCOME
  } else if (name.includes('unearnedIncomes')) {
    return STEPS.UNEARNED_INCOME
  } else if (name.includes('childSupport')) {
    return STEPS.CHILD_SUPPORT
  } else if (
    name.includes('utilityExpenses') ||
    name.includes('shelterExpenses')
  ) {
    return STEPS.SHELTER_UTILITIES
  } else if (name.includes('underAgeEighteen')) {
    return STEPS.ABAWD
  }

  return null
}

interface MyApplicationAction {
  applId: string
  status: string
  applicationData: Object
}

interface ProgramRenewal {
  renRecvDt: string
  renSrc: string
  renewalSignedandDated: string
  allQuestionsAnswered: string
  interviewCompleted: string
}

interface ClientUpdateWizardContainerProps {
  isRenewal: boolean
  presentation: any
  location: {
    search: string
    pathname: string
  }
  caseId?: number
  reviewIndicator: any
  renewalStatus: any
  status: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  orgId: string
  userRoleId: string
  roleId: any
  roleName: string
  isUnitTest: boolean
  selectedEntitlements: Array<string>
  step: number
  renewalDate: any
  activeApplicationId: string
  myApplicationData: any
  addMyApplication: (...MyApplicationAction) => void
  removeMyApplication: (applId: string) => void
  updateMyApplication: (...MyApplicationAction) => void
  setActiveApplicationId: (applId: string) => void
  assignApplicationId: (applId: string) => void
  updateMyCocApplicationStep: (step: number) => void
  updateMyApplicationTabStatus: (applId: string, tabStatus: any) => void
  errorMessage: Array<any>
  deleteErrorMessage: () => void
  showErrorMessage: (message) => void
  updatedFormData: (formContext: Array<any>) => void
  updatedCOCFormData: any
  ieOrgId: any
  logoutUser
  locale
}

interface ClientUpdateWizardContainerState {
  programCode: any
  applId: string
  caseId: number
  reviewIndicator: string
  status: string
  renewalStatus: string
  benefitProgramName: any
  benefitProgramCode: any
  allPrograms: any
  allProgramCodes: any
  prgNames: any
  step: number
  count: number
  viewId: string
  showDraftModal: boolean
  showNonDraftModal: boolean
  hasSpecialNeeds?: boolean
  tabs: {
    status: string
    leftIcon: string
    rightIcon: string
    rightSpan: string
    isVisible: boolean
    title: string
  }[]
  forms: {
    schema: Object
    uiSchema: any
    metaData: Object
  }[]
  masterUiSchema: Object
  resolvedSchema: Object
  formData: any
  oldFormData: any
  formContext: any
  callingApi: boolean
  liveValidate: boolean
  trackerForms: Object
  redirect: string
  modalProps: {
    show: boolean
    cancel: () => void
    confirm: () => void
    title: React.ReactNode
    body: React.ReactNode
    bsSize?: string
    confirmBtnText: string
    closeBtnText: string
    className?: string
  }
  programRenewals: Array<ProgramRenewal>
  caseInfo: any
  showNext: boolean
  errorTitle: any
  slides: any
  slideIndex: any
  tabFocus: boolean
  renewalDate: any
  rnwlDate: string
  reneCovInd: number
  currentdate: any
}
class ClientUpdateWizardContainer extends React.Component<
  ClientUpdateWizardContainerProps,
  ClientUpdateWizardContainerState
> {
  private hasError = false

  constructor(props: any) {
    super(props)
    document.addEventListener('keydown', this.onFocus)
    const caseId = this._determineCaseId()[0]
    const reviewIndicator = this._determineCaseId()[1]
    const status = this._determineCaseId()[2]
    const renewalStatus = this._determineCaseId()[3]
    const prgNames = this._determineCaseId()[5]
    const renewalDate = this.getQueryParamFromUrl('renewalDate')
    let redirect = null
    const programNames = this.getQueryParamFromUrl('prgNames')
    const programCode = this.getQueryParamFromUrl('programCode')
    const programNamesDecoded = decodeURI(programNames)
    const programCodeDecoded = decodeURI(programCode)
    const allPrograms = programNamesDecoded.split(',')
    const allProgramCodes = programCodeDecoded.split(',')
    this.state = {
      applId: null,
      caseId,
      reviewIndicator,
      status,
      renewalStatus,
      benefitProgramName: this.getQueryParamFromUrl('benefitProgramName'),
      benefitProgramCode: this.getQueryParamFromUrl('programCode'),
      allPrograms: allPrograms,
      allProgramCodes: allProgramCodes,
      prgNames: '',
      step: 1,
      count: 0,
      viewId: null,
      showDraftModal: false,
      showNonDraftModal: false,
      tabs: null,
      forms: null,
      masterUiSchema: null,
      resolvedSchema: null,
      formData: null,
      oldFormData: null,
      formContext: null,
      callingApi: false,
      liveValidate: false,
      trackerForms: {},
      redirect,
      reneCovInd: null,
      modalProps: {
        show: false,
        cancel: null,
        confirm: null,
        title: null,
        body: null,
        bsSize: 'lg',
        className: 'lg-width-to-md',
        closeBtnText: null,
        confirmBtnText: null
      },
      programRenewals: null,
      caseInfo: null,
      showNext: false,
      slideIndex: 0,
      slides: [],
      errorTitle: [],
      programCode: [],
      tabFocus: false,
      renewalDate,
      rnwlDate: null,
      currentdate: getServerTime()
    }
    this._getStep = this._getStep.bind(this)
  }

  getQueryParamFromUrl(field) {
    let url = window.location.href
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i')
    var string = reg.exec(url)

    return string ? decodeURIComponent(string[1]) : null
  }

  _setReneCovInd(value) {
    this.setState({
      reneCovInd: value
    })
  }

  componentDidMount() {
    let applId = this.props.activeApplicationId
    if (this.props.location && this.props.location.search) {
      try {
        const query = queryString.parse(this.props.location.search)
        if (applId !== query.applId) {
          applId = query.applId
          this.props.setActiveApplicationId(applId)
          this.setState({
            callingApi: false,
            applId: applId
          })
          this.setState(prevState => {
            return {
              formContext: {
                ...prevState.formContext,
                checkHasPin: true,
                currentDate: this.state.currentdate
              }
            }
          })
        }
      } catch (err) {
        console.error('Error on handling applId from URL query', err)
      }
    }

    this._initializeForms()
    this._getFormData()
    this._determineCaseId()
  }

  componentDidUpdate(prevProps, prevState) {
    const { viewId, count, step, tabFocus } = this.state
    const appTitle = this.props.isRenewal
      ? 'Benefits Application Renewal'
      : 'Life Event Update'
    switch (this.state.step) {
      case 1:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Household Information - ' +
          appTitle
        break
      case 2:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') + 'Personal - ' + appTitle
        break
      case 3:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Additional Information - ' +
          appTitle
        break
      case 4:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') + 'Income - ' + appTitle
        break
      case 5:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') + 'Expenses - ' + appTitle
        break
      case 6:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') + 'Assets - ' + appTitle
        break
      case 7:
        document.title =
          (this.hasError ? 'Error' + ' - ' : '') +
          'Review and Sign - ' +
          appTitle
        break
    }
    if (viewId != null) {
      //Have to do this since componentDidUpdate runs before real DOM is updated.
      try {
        setTimeout(function() {
          window.requestAnimationFrame(function() {
            let offset = helper.getOffsetTop(document.getElementById(viewId))
            const element = document.getElementById(viewId)
            element.focus()
            window.scroll(0, offset - 100)
          })
        }, 1000)
      } catch (e) {
        console.error('ClientUpdateWizard viewId setTimeout failed with ex', e)
      }
      //TODO: expand accordions if they contain the element.

      this.setState({ viewId: null })
    }

    if (!_.isEqual(prevProps.userRoleId, this.props.userRoleId)) {
      //The user switched their role! Redirect back to home.
      //TODO: global config for target landing page by role/entitlement?
      if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.PRESUMPTIVE_ELIGIBILITY_MANAGEMENT
        )
      ) {
        this.setState({ redirect: baseUrl + '/myaccount/dashboard' })
      } else if (
        _.includes(
          this.props.selectedEntitlements,
          ENTITLEMENTS.COMMUNITY_PARTNERSHIP
        )
      ) {
        this.setState({ redirect: baseUrl + '/myaccount/dashboard' })
      } else {
        this.setState({ redirect: baseUrl + '/home' })
      }
    }

    if (count < 1) {
      this.updateStep(prevProps)
      this.setState({ count: count + 1 })
    }
    this._alignADANextButton()
  }

  _alignADANextButton() {
    try {
      let previousButton = document.getElementById(
        'RenewalWizardInfo_application_prevSlideBtn'
      )
      let nextButton = document.getElementById('ClientUpdateWizard_nextBtn_1')

      if (previousButton === null || nextButton === null) {
        return null
      }

      nextButton.classList.remove('sp-md')

      let previousButtonBottom = parseFloat(
        previousButton.getBoundingClientRect().bottom.toString()
      )
      let nextButtonBottom = parseFloat(
        nextButton.getBoundingClientRect().bottom.toString()
      )

      let bottomDelta = previousButtonBottom - nextButtonBottom

      let alignmentAttempts = 0
      while (Math.abs(bottomDelta) > 1 && alignmentAttempts < 10) {
        nextButton.style.margin = bottomDelta.toString() + 'px' + ' ' + '0'
        previousButtonBottom = parseFloat(
          previousButton.getBoundingClientRect().bottom.toString()
        )
        nextButtonBottom = parseFloat(
          nextButton.getBoundingClientRect().bottom.toString()
        )
        bottomDelta = previousButtonBottom - nextButtonBottom

        alignmentAttempts += 1
      }
    } catch (e) {
      console.error('ClientUpdateWizard failed to align ADA Next button', e)
    }
  }

  // _adjustContentHeight() {
  //   try {
  //     console.log('HEIGHT FIX')
  //     document.getElementById('content').style.height = ''
  //     let sideNavBottom = parseFloat(
  //       document
  //         .getElementById('Review&Sign')
  //         .getBoundingClientRect()
  //         .bottom.toString()
  //     )
  //     let contentBottom = parseFloat(
  //       document
  //         .getElementById('content')
  //         .getBoundingClientRect()
  //         .bottom.toString()
  //     )

  //     let heightDelta = sideNavBottom - contentBottom
  //     console.log('HEIGHT FIX', heightDelta)
  //     if (heightDelta > 0) {
  //       let currHeight =
  //         document.getElementById('content').style.height.toString() === ''
  //           ? 0
  //           : parseFloat(
  //               document.getElementById('content').style.height.toString()
  //             )
  //       let newHeight = sideNavBottom + heightDelta
  //       document.getElementById('content').style.height =
  //         newHeight.toString() + 'px'
  //       console.log('START height')
  //       console.log(sideNavBottom)
  //       console.log(contentBottom)
  //       console.log(heightDelta)
  //       console.log(currHeight)
  //       console.log(newHeight)
  //     }
  //   } catch (e) {
  //     console.error(
  //       'ClientUpdateWizard failed to auto adjust content height',
  //       e
  //     )
  //   }
  // }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onFocus)
  }

  updateStep(prevProps) {
    this.setState({ step: prevProps.step })
  }

  _determineCaseId = () => {
    let caseId = 0
    let reviewIndicator
    let status
    let renewalStatus
    let prgNames
    if (this.props.location && this.props.location.search) {
      try {
        const query = queryString.parse(this.props.location.search)
        reviewIndicator = query.reviewIndicator
        status = query.status
        switch (query.renewalStatus) {
          case 'Not Started': {
            renewalStatus = 'Renewal Pending'
            break
          }
          case 'Pending': {
            renewalStatus = 'Renewal in Progress'
            break
          }
          case 'Submitted': {
            renewalStatus = 'Submitted'
            break
          }
        }
        prgNames = query.prgNames
        this.setState(prevState => {
          return {
            ...prevState,
            reviewIndicator: reviewIndicator,
            status: status,
            renewalStatus: renewalStatus,
            prgNames: prgNames,
            formContext: {
              ...prevState.formContext,
              benefitProgramName: prevState.benefitProgramName,
              benefitProgramCode: prevState.benefitProgramCode,
              prgNames: prgNames
            }
          }
        })
        if (query && query.caseId) {
          caseId = parseInt(query.caseId, 10)
        } else if (this.props.caseId) {
          caseId = this.props.caseId
        }
      } catch (err) {
        console.error('Error on parsing caseId from URL query', err)
      }
    } else if (this.props.caseId) {
      caseId = this.props.caseId
    }
    return [caseId, reviewIndicator, status, renewalStatus, prgNames]
  }

  _getFormData() {
    const endpoint = loadUserEndpoint
    serviceHelper
      .fetchJson(
        endpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            userRoleId: this.props.userRoleId
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        this.setState(prevState => {
          return {
            formContext: {
              ...prevState.formContext,
              clientBthDt: json.userBthDt,
              clientFstNm: json.userFstNm,
              clientLstNm: json.userLstNm,
              clientUserId: json.userId
            }
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'MyProfile _getFormData failed due to ex',
          error,
          'endpoint: ' + endpoint
        )
      })
  }
  _initializeForms = () => {
    const formsEndpoint = this.props.isRenewal
      ? renewalWizardFormsEndpoint
      : lifeEventsFormsEndpoint
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }

    Promise.all([
      helper.fetchJson(formsEndpoint.replace('{version}', '1.0'), request),
      helper.fetchJson(
        appIntakeSchemaEndpoint.replace('{version}', '1.0'),
        request
      )
    ])
      .then(responses => {
        const clientUpdateForms = responses[0]
        const appIntakeSchema = responses[1]

        // let clientUpdateForms = renewalWizardForms
        // const appIntakeSchema = appIntakeSchemaa

        let resolvedSchema = helper.mergeAllOfSchemas(
          appIntakeSchema,
          appIntakeSchema['definitions']
        )
        resolvedSchema = helper.resolveSchemaDefinitions(
          resolvedSchema,
          resolvedSchema['definitions']
        )
        delete resolvedSchema['definitions']

        const forms = []
        let masterUiSchema = {}
        _.forEach(clientUpdateForms['app']['forms'], (value, index) => {
          if (_.isEmpty(value['schema'])) {
            forms.push({
              schema: helper.schemaIntersect(resolvedSchema, value['uiSchema']),
              uiSchema: value['uiSchema']
            })
          } else {
            forms.push({
              schema: value['schema'],
              uiSchema: value['uiSchema']
            })
          }
          masterUiSchema = _.merge(masterUiSchema, value['uiSchema'])
        })

        let date = _moment(config, this.state.currentdate).format('MM/DD/YYYY')
        let clientBthDt = this.state.formContext.clientBthDt
        let clientFstNm = this.state.formContext.clientFstNm
        let clientLstNm = this.state.formContext.clientLstNm
        let clientUserId = this.state.formContext.clientUserId
        let tenantId = this.state.formContext.tenantId
        let benefitProgramName =
          this.state.formContext.benefitProgramName != undefined
            ? this.state.formContext.benefitProgramName
            : this.state.benefitProgramName
        let benefitProgramCode =
          this.state.formContext.benefitProgramCode != undefined
            ? this.state.formContext.benefitProgramCode
            : this.state.benefitProgramCode
        let prgNames = this.state.formContext.prgNames

        helper.cleanNullValues(forms)
        this.setState(
          {
            forms,
            masterUiSchema,
            resolvedSchema,
            formContext: {
              STEPS,
              forms,
              reviewForms: _.cloneDeep(forms),
              formData: null,
              clientBthDt,
              clientFstNm,
              clientLstNm,
              clientUserId,
              tenantId,
              benefitProgramName,
              benefitProgramCode,
              prgNames,
              subscribeTrackerMode: this._subscribeTrackerForm,
              unsubscribeTrackerMode: this._unsubscribeTrackerForm,
              panelEditLink: this._panelEditLink,
              isRenewal: this.props.isRenewal,
              checkChildCareTabEnable: this._checkChildCareTabEnable,
              isClientEligibleForCHCR: this._isClientEligibleForCHCR,
              setShowNext: this._setShowNext,
              newTaxInformation: {},
              changeActiveAccordionKey: this._changeActiveAccordionKey,
              setReneCovInd: this._setReneCovInd.bind(this),
              LIEAP: false,
              FDNT: false,
              HEWL: false,
              SCA: false,
              CHCR: false,
              CHSP: false,
              MDPA: false,
              refs: {
                ...refs,
                '{show listIndividuals}': ({ formContext, fieldId }) => {
                  const droppedBelowTwentyHours = _.get(
                    formContext,
                    `formData.application.droppedBelowTwentyHours`,
                    undefined
                  )
                  if (droppedBelowTwentyHours == 'Y') {
                    return true
                  }
                  return false
                },
                '{enableAdd Personal}': ({ formContext }) => {
                  const liveWithYou = _.get(
                    formContext,
                    `formData.application.liveWithYou`,
                    []
                  )
                  if (liveWithYou === 'N') {
                    return true
                  } else {
                    return false
                  }
                },
                '{enableRemove Personal}': ({ formContext }) => {
                  const liveWithYou = _.get(
                    formContext,
                    `formData.application.liveWithYou`,
                    []
                  )
                  const clients = _.get(
                    formContext,
                    `formData.application.clients`,
                    []
                  )
                  if (liveWithYou === 'N' && clients.length > 1) {
                    return true
                  } else {
                    return false
                  }
                },
                '{show utilityExpenses}': ({ formContext, fieldId }) => {
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  const changeReason = _.get(
                    formContext,
                    `formData.application.changeReason`,
                    []
                  )
                  if (
                    changeReason === '2' ||
                    changeReason === '4' ||
                    _.get(
                      formContext,
                      `formData.application.clients[${index}].clientExpenses.utilityExpenses`,
                      []
                    ).length !== 0
                  ) {
                    return true
                  } else {
                    return false
                  }
                },
                '{show shelterExpenses}': ({ formContext, fieldId }) => {
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  const changeReason = _.get(
                    formContext,
                    `formData.application.changeReason`,
                    []
                  )
                  if (
                    changeReason === '2' ||
                    changeReason === '4' ||
                    _.get(
                      formContext,
                      `formData.application.clients[${index}].clientExpenses.shelterExpenses`,
                      []
                    ).length !== 0
                  ) {
                    return true
                  } else {
                    return false
                  }
                },
                '{showAddRemove shelterUtilityExpenses}': ({
                  formContext,
                  fieldId
                }) => {
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  const changeReason = _.get(
                    formContext,
                    `formData.application.changeReason`,
                    []
                  )
                  if (changeReason === '2' || changeReason === '4') {
                    return true
                  } else {
                    return false
                  }
                },
                '{showAddRemove householdEarned}': ({
                  formContext,
                  fieldId
                }) => {
                  const householdIncomeChange = _.get(
                    formContext,
                    `formData.application.householdIncomeChange`
                  )
                  const individualIncomeChange = _.get(
                    formContext,
                    `formData.application.individualIncomeChange`
                  )
                  if (
                    householdIncomeChange === undefined ||
                    individualIncomeChange === undefined
                  ) {
                    return false
                  } else if (
                    householdIncomeChange === 'Y' ||
                    individualIncomeChange === 'Y'
                  ) {
                    return true
                  } else {
                    return false
                  }
                },
                '{showAddRemove householdUnearned}': ({
                  formContext,
                  fieldId
                }) => {
                  const householdUnearnedIncomeChange = _.get(
                    formContext,
                    `formData.application.householdUnearnedIncomeChange`
                  )
                  if (
                    householdUnearnedIncomeChange === 'N' ||
                    householdUnearnedIncomeChange === undefined
                  ) {
                    return false
                  } else {
                    return true
                  }
                },
                '{showAddRemove childSupport}': ({ formContext, fieldId }) => {
                  const changeInLegalObligationtoPay = _.get(
                    formContext,
                    `formData.application.changeInLegalObligationtoPay`
                  )
                  if (
                    changeInLegalObligationtoPay === 'N' ||
                    changeInLegalObligationtoPay === undefined
                  ) {
                    return false
                  } else {
                    return true
                  }
                },
                '{changeReason TypeCheckLength}': ({ formContext }) => {
                  const changeReason = _.get(
                    formContext,
                    `formData.application.changeReason`,
                    []
                  )
                  if (changeReason === '2' || changeReason === '4') {
                    return 100
                  } else {
                    return 1
                  }
                },
                '{changeReason TypeCheckDisabilty}': ({ formContext }) => {
                  const changeReason = _.get(
                    formContext,
                    `formData.application.changeReason`,
                    []
                  )
                  if (changeReason === '2' || changeReason === '4') {
                    return false
                  } else {
                    return true
                  }
                },
                '{show lotteryGamblingWinnings}': ({ formContext }) => {
                  const wonMoreThan4250 = _.get(
                    formContext,
                    `formData.application.wonMoreThan4250`
                  )
                  if (wonMoreThan4250 === 'Y') {
                    return true
                  } else {
                    return false
                  }
                },
                '{unearnedIncome TypeCheckDisabilty}': ({ formContext }) => {
                  const householdIncomeChange = _.get(
                    formContext,
                    `formData.application.householdIncomeChange`
                  )
                  const individualIncomeChange = _.get(
                    formContext,
                    `formData.application.individualIncomeChange`,
                    []
                  )
                  if (
                    householdIncomeChange === 'Y' ||
                    individualIncomeChange === 'Y'
                  ) {
                    return false
                  } else {
                    return true
                  }
                },
                '{show earnedIncomes}': ({ formContext, fieldId }) => {
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

                  const householdIncomeChange = _.get(
                    formContext,
                    `formData.application.householdIncomeChange`
                  )
                  const individualIncomeChange = _.get(
                    formContext,
                    `formData.application.individualIncomeChange`,
                    []
                  )

                  if (
                    householdIncomeChange === 'Y' ||
                    individualIncomeChange === 'Y' ||
                    _.get(
                      formContext,
                      `formData.application.clients[${index}].clientIncomes.earnedIncomes`,
                      []
                    ).length !== 0
                  ) {
                    return true
                  } else {
                    return false
                  }
                },
                '{show selfEmploymentIncomes}': ({ formContext, fieldId }) => {
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

                  const householdIncomeChange = _.get(
                    formContext,
                    `formData.application.householdIncomeChange`
                  )
                  const individualIncomeChange = _.get(
                    formContext,
                    `formData.application.individualIncomeChange`,
                    []
                  )

                  if (
                    householdIncomeChange === 'Y' ||
                    individualIncomeChange === 'Y' ||
                    _.get(
                      formContext,
                      `formData.application.clients[${index}].clientIncomes.selfEmploymentIncomes`,
                      []
                    ).length !== 0
                  ) {
                    return true
                  } else {
                    return false
                  }
                },
                '{show unearnedIncomes}': ({ formContext, fieldId }) => {
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

                  const householdUnearnedIncomeChange = _.get(
                    formContext,
                    `formData.application.householdUnearnedIncomeChange`
                  )
                  if (
                    householdUnearnedIncomeChange === 'Y' ||
                    _.get(
                      formContext,
                      `formData.application.clients[${index}].clientIncomes.unearnedIncomes`,
                      []
                    ).length !== 0
                  ) {
                    return true
                  } else {
                    return false
                  }
                },
                '{unearnedIncome CheckDisabilty}': ({ formContext }) => {
                  const householdUnearnedIncomeChange = _.get(
                    formContext,
                    `formData.application.householdUnearnedIncomeChange`
                  )
                  if (householdUnearnedIncomeChange === 'Y') {
                    return false
                  } else {
                    return true
                  }
                },
                '{childSupport CheckDisabilty}': ({ formContext }) => {
                  const householdUnearnedIncomeChange = _.get(
                    formContext,
                    `formData.application.changeInLegalObligationtoPay`
                  )
                  if (householdUnearnedIncomeChange === 'Y') {
                    return false
                  } else {
                    return true
                  }
                },
                '{show childSupport}': ({ formContext, fieldId }) => {
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

                  const changeInLegalObligationtoPay = _.get(
                    formContext,
                    `formData.application.changeInLegalObligationtoPay`
                  )
                  if (
                    changeInLegalObligationtoPay === 'Y' ||
                    _.get(
                      formContext,
                      `formData.application.clients[${index}].clientExpenses.childSupport`,
                      []
                    ).length !== 0
                  ) {
                    return true
                  } else {
                    return false
                  }
                },
                '{show ssnDt Info}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  try {
                    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]

                    if (index === '0') {
                      return false
                    } else return true
                  } catch (err) {
                    return false
                  }
                },
                '{old client}': (
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                ) => {
                  let index = formContext.fieldId.match(
                    '(?:.*)clients_([0-9]+)_?'
                  )[1]
                  if (
                    formContext.formContext &&
                    formContext.formContext.formData &&
                    formContext.formContext.formData.application &&
                    formContext.formContext.formData.application.clients &&
                    formContext.formContext.formData.application.clients[
                      index
                    ] &&
                    formContext.formContext.formData.application.clients[index]
                      .isOld === true
                  ) {
                    return true
                  }
                  return false
                },
                '{coc or incomeSourceNameBenefits}': ({
                  formContext,
                  fieldId
                }) => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (currentProgram && currentProgram != 'null') {
                      if (
                        currentProgram == 'food & nutrition (snap)' ||
                        currentProgram == 'SNAP' ||
                        currentProgram == 'FDNT' ||
                        this.state.prgNames.search('SNAP') > 0 ||
                        currentProgram == 'Medicare Premium Assistance' ||
                        currentProgram == 'SLMB' ||
                        currentProgram == 'QMB' ||
                        (this.state.prgNames &&
                          (this.state.prgNames.search('QMB') > 0 ||
                            this.state.prgNames.search(
                              'Medicare Premium Assistance'
                            ) > 0)) ||
                        currentProgram === 'Health Care Benefits' ||
                        this.state.prgNames.search('Health Care Benefits') > 0
                      ) {
                        return '*'
                      } else {
                        return ''
                      }
                    } else {
                      return '*'
                    }
                  }
                },
                '{coc or snap}': ({}) => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (currentProgram && currentProgram != 'null') {
                      if (
                        currentProgram == 'food & nutrition (snap)' ||
                        currentProgram == 'SNAP' ||
                        currentProgram == 'FDNT' ||
                        this.state.prgNames.search('SNAP') > 0
                      ) {
                        return '*'
                      } else {
                        return ''
                      }
                    } else {
                      return '*'
                    }
                  }
                },
                '{over 18}': ({ formContext, fieldId }) => {
                  let clientIndex
                  try {
                    clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  } catch (e) {
                    return
                  }
                  let snpFlnyOvrEighteenFlag = false
                  let thisApplicant = _.get(
                    formContext,
                    `formData.application.clients[${clientIndex}].clientDetails.personalData[0].clientBthDt`,
                    {}
                  )
                  let birthdate = _moment(config, thisApplicant)
                  let cur = _moment(config, this.state.currentdate)
                  if (cur.diff(birthdate, 'years') >= 18) {
                    snpFlnyOvrEighteenFlag = true
                    return snpFlnyOvrEighteenFlag
                  } else {
                    snpFlnyOvrEighteenFlag = false
                  }
                  return snpFlnyOvrEighteenFlag
                },
                '{selected deductions}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  try {
                    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                    let enumsData =
                      formContext.reviewForms[
                        formContext.STEPS.SHELTER_UTILITIES - 1
                      ].schema.properties.application.properties.clients.items
                        .properties.clientExpenses.properties.deduction.items
                        .properties.deductionType
                    let deductionsSelected = _.get(
                      formContext,
                      `formData.application.clients[${index}].clientExpenses.deduction[0]`,
                      {}
                    )
                    if (deductionsSelected) {
                      if (deductionsSelected.deductionType === '12') {
                        return 'Business'
                      } else if (deductionsSelected.deductionType === '19') {
                        return 'Moving'
                      } else {
                        if (
                          enumsData.enumNames[
                            enumsData.enum.indexOf(
                              deductionsSelected.deductionType
                            )
                          ]
                        ) {
                          return enumsData.enumNames[
                            enumsData.enum.indexOf(
                              deductionsSelected.deductionType
                            )
                          ]
                        }
                      }
                    }
                    return '"X"'
                  } catch (err) {
                    return '"X"'
                  }
                },

                '{FDNT}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram == 'food & nutrition (snap)' ||
                      currentProgram == 'SNAP' ||
                      currentProgram == 'FDNT' ||
                      this.state.prgNames.search('SNAP') > 0
                    ) {
                      return true
                    }
                  }
                  return false
                },
                '{FDNT star}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram == 'food & nutrition (snap)' ||
                      currentProgram == 'SNAP' ||
                      currentProgram == 'FDNT' ||
                      this.state.prgNames.search('SNAP') > 0
                    ) {
                      return '*'
                    }
                  }
                  return ''
                },
                '{FDNT and LIEAP star}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram == 'food & nutrition (snap)' ||
                      currentProgram == 'SNAP' ||
                      currentProgram == 'FDNT' ||
                      this.state.prgNames.search('SNAP') > 0 ||
                      currentProgram == 'SLER' ||
                      currentProgram == 'LIEAP' ||
                      this.state.prgNames.search('LIEAP') > 0 ||
                      this.state.prgNames.search('SLER') > 0 ||
                      this.state.prgNames.search('Other-LIEAP') > 0
                    ) {
                      return '*'
                    }
                  }
                  return ''
                },
                '{maritalStatus star}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram == 'food & nutrition (snap)' ||
                      currentProgram == 'SNAP' ||
                      currentProgram == 'FDNT' ||
                      this.state.prgNames.search('SNAP') > 0 ||
                      currentProgram == 'Medicare Premium Assistance' ||
                      currentProgram == 'SLMB' ||
                      currentProgram == 'QMB' ||
                      this.state.prgNames.search('QMB') > 0 ||
                      this.state.prgNames.search(
                        'Medicare Premium Assistance'
                      ) > 0
                    ) {
                      return '*'
                    }
                  }
                  return ''
                },
                '{HMEN}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram == 'SLER' ||
                      currentProgram == 'LIEAP' ||
                      this.state.prgNames.search('LIEAP') > 0 ||
                      this.state.prgNames.search('SLER') > 0 ||
                      this.state.prgNames.search('Other-LIEAP') > 0
                    ) {
                      return true
                    }
                  }
                  return false
                },
                '{HEWL}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram === 'Health Care Benefits' ||
                      this.state.prgNames.search('Health Care Benefits') > 0
                    ) {
                      return true
                    }
                  }
                  return false
                },
                '{AsesstsRenewal}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram === 'medicare premium assistance' ||
                      currentProgram === 'SLMB' ||
                      currentProgram === 'QMB' ||
                      currentProgram === 'SNAP' ||
                      currentProgram === 'food & nutrition (snap)' ||
                      currentProgram === 'FDNT' ||
                      this.state.prgNames.search('SNAP') > 0 ||
                      this.state.prgNames.search('food & nutrition (snap)') >
                        0 ||
                      this.state.prgNames.search('FDNT') > 0 ||
                      this.state.prgNames.search(
                        'medicare premium assistance'
                      ) > 0 ||
                      this.state.prgNames.search('SLMB') > 0 ||
                      this.state.prgNames.search('QMB') > 0
                    ) {
                      return true
                    }
                  }
                  return false
                },
                '{CHCR}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram == 'child care services' ||
                      currentProgram == 'CHCR' ||
                      this.state.prgNames.search('CHCR') > 0 ||
                      this.state.prgNames.search('child care services') > 0
                    ) {
                      return true
                    }
                  }
                  return false
                },
                '{CHSP}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram == 'child support services' ||
                      currentProgram == 'CHSP' ||
                      this.state.prgNames.search('CHSP') > 0 ||
                      this.state.prgNames.search('child support services') > 0
                    ) {
                      return true
                    }
                  }
                  return false
                },
                '{SCAL}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram == 'SCA' ||
                      this.state.prgNames.search('SCA') > 0 ||
                      this.state.prgNames.search('SCHOOL CLOTHING ALLOWANCE') >
                        0 ||
                      this.state.prgNames.search('Other-SCA') > 0
                    ) {
                      return true
                    }
                  }
                  return false
                },
                '{MDPA}': formContext => {
                  for (let i = 0; i < this.state.allPrograms.length; i++) {
                    let currentProgram = this.state.allPrograms[i]
                    if (
                      currentProgram == 'Medicare Premium Assistance' ||
                      currentProgram == 'SLMB' ||
                      currentProgram == 'QMB' ||
                      this.state.prgNames.search('QMB') > 0 ||
                      this.state.prgNames.search(
                        'Medicare Premium Assistance'
                      ) > 0
                    ) {
                      return true
                    }
                  }
                  return false
                },
                '{female over 10}': ({ formContext, fieldId }) => {
                  let clientIndex
                  try {
                    clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  } catch (e) {
                    return
                  }
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    {}
                  )
                  let thisApplicant =
                    clients[clientIndex].clientDetails.personalData[0]
                  if (
                    thisApplicant.gdrCd === '2' ||
                    thisApplicant.gdrCd === '3'
                  ) {
                    let birthdate = _moment(config, thisApplicant.clientBthDt)
                    let cur = _moment(config, this.state.currentdate)
                    if (cur.diff(birthdate, 'years') >= 10) {
                      return true
                    } else {
                      return false
                    }
                  }
                },
                '{male applicant}': ({ formContext, fieldId }) => {
                  let clientIndex
                  try {
                    clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  } catch (e) {
                    return
                  }
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    {}
                  )
                  let maleApplicant = false
                  let thisApplicant =
                    clients[clientIndex].clientDetails.personalData[0]
                  maleApplicant = thisApplicant.gdrCd == '1' ? true : false
                  return maleApplicant
                },
                //Below are refs for labels specific to ClientUpdateWizard that change depending on the mode our component is in.
                '{disability start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did your health insurance coverage start?'
                    : 'Health Insurance Begin Date'
                },
                '{disable householdInfo}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  // if (
                  //   formContext.formData.application.hasOwnProperty('changes')
                  // ) {
                  //   return formContext.formData.application.changes === 'N'
                  //     ? true
                  //     : false
                  // } else {
                  //   return true
                  // }
                  return false
                },
                '{health insurance start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did your health insurance coverage start?'
                    : 'Health Insurance Begin Date'
                },
                '{expense start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did this expense start?'
                    : 'Start Date'
                },
                '{earned income start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did your earned income start?'
                    : 'Income Start Date'
                },
                '{self employment start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did your self-employment income start?'
                    : 'Income Start Date'
                },
                '{hide add label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    {}
                  )
                  return clients.length <= 1 ? true : false
                },
                '{unearned income start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did your unearned income start?'
                    : 'Income Start Date'
                },
                '{banking account start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date was this account opened?'
                    : 'Account Start Date'
                },
                '{other asset start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did this begin?'
                    : 'Asset Start Date'
                },
                '{cash start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did this balance start?'
                    : 'Balance Start Date'
                },
                '{trust start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did your trust account start?'
                    : 'Trust Start Date'
                },
                '{insurance policy start date label}': ({
                  formContext,
                  fieldId,
                  formData,
                  schema,
                  uiSchema
                }) => {
                  return helper.resolveStringRefs(
                    '{tracker mode}',
                    formContext,
                    fieldId,
                    formData,
                    schema,
                    uiSchema
                  ) === 'ADD'
                    ? 'What date did this insurance policy begin?'
                    : 'Policy Start Date'
                },
                '{is a citizen}': ({ formData, fieldId }) => {
                  try {
                    let usCtznOrNatInd = ''
                    let naturalizedCtzn = ''
                    const currentCitizenship = formData.filter(element => {
                      return (
                        element.action !== 'INVALIDATED' &&
                        typeof element.effectiveEndDate !== 'string'
                      )
                    })
                    if (currentCitizenship[0]) {
                      usCtznOrNatInd =
                        currentCitizenship[0].usCtznOrNatInd || ''
                      naturalizedCtzn =
                        currentCitizenship[0].naturalizedCtzn || ''
                    }
                    return usCtznOrNatInd === 'Y' || naturalizedCtzn === 'Y'
                  } catch (err) {
                    return undefined
                  }
                },
                '{other applicants id releationship}': ({
                  formContext,
                  fieldId
                }) => {
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    []
                  )
                  let keyValuePairs = clients.map(client => {
                    return {
                      enumNames:
                        client.clientDetails.personalData[0].clientFstNm,
                      value: client.identifier
                    }
                  })
                  let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let releationIndex
                  if (fieldId.includes('trackerAdd')) {
                    releationIndex = fieldId.match(
                      '(?:.*)trackerAdd_([0-9]+)_?'
                    )[1]
                  } else {
                    return keyValuePairs.map(key => {
                      return key.value
                    })
                  }
                  const relationShips = _.get(
                    formContext,
                    `formData.application.clients[${mainIndex}].clientDetails.relationShip`,
                    []
                  ).map(relationShip => {
                    return relationShip.relClientName
                  })
                  const currentRelationShip = _.get(
                    formContext,
                    `localFormData[${releationIndex}]`,
                    {}
                  ).relClientName
                  const currentIndex = _.get(
                    formContext,
                    `formData.application.clients[${mainIndex}].identifier`,
                    ''
                  )
                  _.remove(relationShips, x => {
                    return x == currentRelationShip
                  })

                  keyValuePairs = keyValuePairs.filter(key => {
                    return (
                      !_.includes(relationShips, key.value) &&
                      key.value != currentIndex
                    )
                  })
                  return keyValuePairs.map(key => {
                    return key.value
                  })
                },
                '{other applicants or contributors releationship}': ({
                  formContext,
                  fieldId
                }) => {
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    []
                  )
                  let keyValuePairs = clients.map(client => {
                    return {
                      enumNames:
                        client.clientDetails.personalData[0].clientFstNm,
                      value: client.identifier
                    }
                  })
                  let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let releationIndex
                  if (fieldId.includes('trackerAdd')) {
                    releationIndex = fieldId.match(
                      '(?:.*)trackerAdd_([0-9]+)_?'
                    )[1]
                  } else {
                    return keyValuePairs.map(key => {
                      return key.enumNames
                    })
                  }
                  const relationShips = _.get(
                    formContext,
                    `formData.application.clients[${mainIndex}].clientDetails.relationShip`,
                    []
                  ).map(relationShip => {
                    return relationShip.relClientName
                  })
                  const currentRelationShip = _.get(
                    formContext,
                    `localFormData[${releationIndex}]`,
                    {}
                  ).relClientName
                  const currentIndex = _.get(
                    formContext,
                    `formData.application.clients[${mainIndex}].identifier`,
                    ''
                  )
                  _.remove(relationShips, x => {
                    return x == currentRelationShip
                  })
                  keyValuePairs = keyValuePairs.filter(key => {
                    return (
                      !_.includes(relationShips, key.value) &&
                      key.value != currentIndex
                    )
                  })
                  return keyValuePairs.map(key => {
                    return key.enumNames
                  })
                },
                '{other applicants id tax}': ({ formContext, fieldId }) => {
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    []
                  )
                  let keyValuePairs = clients.map(client => {
                    return {
                      enumNames:
                        client.clientDetails.personalData[0].clientFstNm,
                      value: client.identifier
                    }
                  })
                  let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let taxIndex
                  if (fieldId.includes('trackerAdd')) {
                    taxIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
                  } else {
                    return keyValuePairs.map(key => {
                      return key.value
                    })
                  }
                  const taxRecords = _.get(
                    formContext,
                    `formData.application.clients[${mainIndex}].clientDetails.taxInformation`,
                    []
                  ).map(taxRecord => {
                    return taxRecord.relClientName
                  })
                  const currentTaxIndex = _.get(
                    formContext,
                    `localFormData[${taxIndex}]`,
                    {}
                  ).relClientName
                  const currentIndex = _.get(
                    formContext,
                    `formData.application.clients[${mainIndex}].identifier`,
                    ''
                  )
                  _.remove(taxRecords, x => {
                    return x == currentTaxIndex
                  })

                  keyValuePairs = keyValuePairs.filter(key => {
                    return (
                      !_.includes(taxRecords, key.value) &&
                      key.value != currentIndex
                    )
                  })
                  return keyValuePairs.map(key => {
                    return key.value
                  })
                },
                '{other applicants or contributors tax}': ({
                  formContext,
                  fieldId
                }) => {
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    []
                  )
                  let keyValuePairs = clients.map(client => {
                    return {
                      enumNames:
                        client.clientDetails.personalData[0].clientFstNm,
                      value: client.identifier
                    }
                  })
                  let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let taxIndex
                  if (fieldId.includes('trackerAdd')) {
                    taxIndex = fieldId.match('(?:.*)trackerAdd_([0-9]+)_?')[1]
                  } else {
                    return keyValuePairs.map(key => {
                      return key.enumNames
                    })
                  }
                  const taxRecords = _.get(
                    formContext,
                    `formData.application.clients[${mainIndex}].clientDetails.taxInformation`,
                    []
                  ).map(taxRecord => {
                    return taxRecord.relClientName
                  })
                  const currentTaxIndex = _.get(
                    formContext,
                    `localFormData[${taxIndex}]`,
                    {}
                  ).relClientName
                  const currentIndex = _.get(
                    formContext,
                    `formData.application.clients[${mainIndex}].identifier`,
                    ''
                  )
                  _.remove(taxRecords, x => {
                    return x == currentTaxIndex
                  })

                  keyValuePairs = keyValuePairs.filter(key => {
                    return (
                      !_.includes(taxRecords, key.value) &&
                      key.value != currentIndex
                    )
                  })
                  return keyValuePairs.map(key => {
                    return key.enumNames
                  })
                },
                '{disable relClientName}': ({ formContext, fieldId }) => {
                  if (fieldId.includes('trackerAdd')) {
                    return false
                  }
                  //don't disable if any client is new
                  else {
                    let shouldDisable = true
                    let clients = _.get(
                      formContext,
                      'formData.application.clients'
                    )
                    clients.forEach(tempClient => {
                      if (tempClient.isOld === false) {
                        shouldDisable = false
                      }
                    })
                    return shouldDisable
                  }
                },
                '{enableChildCare}': 'return false;',
                '{other applicants id}': ({ formContext, fieldId }) => {
                  let otherPeople = []
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  for (
                    let i = 0;
                    i < formContext.formData.application.clients.length;
                    ++i
                  ) {
                    if (i != index) {
                      otherPeople.push(i.toString())
                    }
                  }
                  return otherPeople
                },
                '{other applicants or contributors}': ({
                  formContext,
                  fieldId
                }) => {
                  let otherPeople = []
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  for (
                    let i = 0;
                    i < formContext.formData.application.clients.length;
                    ++i
                  ) {
                    if (i != index) {
                      otherPeople.push(
                        formContext.formData.application.clients[
                          i
                        ].clientDetails.personalData[0].clientFstNm.toString()
                      )
                    }
                  }
                  return otherPeople
                },
                '{oneClient}':
                  'return formContext.formData.application.clients.length > 1 ? true : false',
                '{is primary applicant}':
                  "let index = fieldId.match('(?:.*)clients_([0-9]+)_?') && fieldId.match('(?:.*)clients_([0-9]+)_?')[1]; return (index === '0');",
                '{isClientSelectedForCHCR}': 'return false',
                '{this applicant name}': ({ formContext, fieldId }) => {
                  let clientIndex =
                    fieldId.match('(?:.*)clients_([0-9]+)_?') &&
                    fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let personalData =
                    formContext.formData.application.clients[clientIndex]
                      .clientDetails.personalData

                  //disabled the following code block to prevent a removed client's name
                  //from persisting in the accordion of a newly addded client
                  //CODE BLOCK BEGIN
                  // if (
                  //   personalData[0].clientFstNm == undefined ||
                  //   personalData[0].clientLstNm == undefined
                  // ) {
                  //   personalData = this.props.updatedCOCFormData.formData
                  //     .application.clients[clientIndex].clientDetails
                  //     .personalData
                  // }
                  //CODE BLOCK END
                  if (!personalData || personalData.length == 0) {
                    return
                  }
                  const activePersonalData = _.maxBy(
                    personalData.filter(e => e.clientFstNm !== ''),
                    'clientFstNm'
                  )

                  return (
                    activePersonalData.clientFstNm +
                    (activePersonalData.clientLstNm
                      ? ' ' + activePersonalData.clientLstNm
                      : '')
                  )
                },
                '{this applicant birthDate}': ({ formContext, fieldId }) => {
                  let clientIndex
                  try {
                    clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  } catch (e) {
                    return
                  }
                  let personalData = _.cloneDeep(
                    _.get(
                      formContext,
                      'formData.application.clients[' +
                        clientIndex +
                        '].clientDetails.personalData',
                      {}
                    )
                  )
                  if (!personalData || personalData.length == 0) {
                    return
                  }
                  const activePersonalData = _.maxBy(
                    personalData.filter(e => e.clientBthDt !== ''),
                    'clientBthDt'
                  )
                  if (activePersonalData != undefined) {
                    return _moment(
                      config,
                      activePersonalData.clientBthDt,
                      'YYYY-MM-DD'
                    ).format('MM/DD/YYYY')
                  } else {
                    return ''
                  }
                  return activePersonalData != undefined
                    ? activePersonalData.clientBthDt
                    : ' '
                },
                '{Tax Info greaterthan zero}': ({ formContext, fieldId }) => {
                  let clientId = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    {}
                  )
                  if (clients && clients.length == 1) {
                    return false
                  }
                  return true
                },
                '{smo taxInformation}': ({ formContext, fieldId }) => {
                  let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let taxInfoIndex
                  if (
                    !fieldId.includes('trackerAdd') &&
                    !fieldId.includes('trackerChange')
                  ) {
                    taxInfoIndex = fieldId.match(
                      '(?:.*)taxInformation_([0-9]+)_?'
                    )[1]
                  }
                  if (taxInfoIndex) {
                    return (
                      _.get(
                        formContext,
                        `formData.application.clients[${mainIndex}].clientDetails.taxInformation[${taxInfoIndex}].relClientName`
                      ) === 'smo'
                    )
                  }
                },
                '{school enrollment status}': ({ formContext, fieldId }) => {
                  let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let educationDetails = _.get(
                    formContext,
                    `formData.application.clients[${clientIndex}]
                    .clientAdditionalInformation`
                  )
                  if (
                    educationDetails.education[0].enrolledRightNow != '4' ||
                    educationDetails.education[0].enrolledRightNow ==
                      undefined ||
                    educationDetails.education[0].enrolledRightNow == null
                  ) {
                    return true
                  }
                  return false
                },

                '{taxInfoRef}': ({ formContext, fieldId }) => {
                  const prgNames = _.get(formContext, 'prgNames', '')
                  if (prgNames.includes('Health Care Benefits')) {
                    return true
                  }
                  return false
                },
                '{primary applicant name array}':
                  " let esign=[];  esign.push(formContext.clientFstNm.concat(' ').concat(formContext.clientLstNm)); return esign;",
                '{current date}': 'return formContext.currentDate;',
                '{client number}': ({ fieldId }) => {
                  let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  return (parseInt(index) + 1).toString()
                },
                '{CPDisable}': ({ formContext }) => {
                  if (
                    this.props.roleName === 'Community Partner' ||
                    this.props.roleName === 'CP Admin'
                  ) {
                    formContext.formData.application.someoneAsstYouFlg = 'N'
                    return true
                  } else return false
                },
                '{someoneAsstFlg Enum}': ({ formContext }) => {
                  let arr = []
                  if (
                    this.props.roleName === 'Community Partner' ||
                    this.props.roleName === 'CP Admin'
                  ) {
                    arr.push('N')
                  } else {
                    arr.push('Y')
                    arr.push('N')
                  }
                  return arr
                },
                '{someAsstFlgEnumNames}': ({ formContext }) => {
                  let arr = []
                  if (
                    this.props.roleName === 'Community Partner' ||
                    this.props.roleName === 'CP Admin'
                  ) {
                    arr.push(
                      "I am completing this application on someone's behalf"
                    )
                  } else {
                    arr.push('I am completing this application myself')
                    arr.push(
                      "I am completing this application on someone's behalf"
                    )
                  }
                  return arr
                },
                '{notes for old applicants}': ({ formContext, fieldId }) => {
                  let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  if (+clientIndex <= +initClientsLength - 1) {
                    return 'Please contact your DoHS Agency Worker to make changes to your name, date of birth, gender and/or Social Security Number.'
                  }
                  return ''
                },
                '{notes for existing disability}': ({
                  formContext,
                  fieldId
                }) => {
                  let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let disabilityData = _.get(
                    formContext,
                    'formData.application.clients[' +
                      clientIndex +
                      '].clientAdditionalInformation.disability'
                  )
                  if (
                    disabilityData &&
                    disabilityData.length > 0 &&
                    (disabilityData[0].isNew === false ||
                      disabilityData[0].isNew === undefined)
                  ) {
                    return 'Please contact your DoHS Agency Worker to make changes to existing disability information.'
                  }
                  return ''
                },
                '{disable for existing disability data}': ({
                  formContext,
                  fieldId
                }) => {
                  let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let disabilityData = _.get(
                    formContext,
                    `formData.application.clients['${clientIndex}'].clientAdditionalInformation.disability`
                  )
                  if (
                    disabilityData &&
                    disabilityData.length > 0 &&
                    (disabilityData[0].isNew === false ||
                      disabilityData[0].isNew === undefined)
                  ) {
                    return true
                  }
                  return false
                },

                '{Not mandatory if not accessible}': ({
                  formContext,
                  fieldId
                }) => {
                  let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let disabilityData = _.get(
                    formContext,
                    `formData.application.clients['${clientIndex}'].clientAdditionalInformation.disability`
                  )
                  if (
                    disabilityData &&
                    disabilityData.length > 0 &&
                    (disabilityData[0].isNew === false ||
                      disabilityData[0].isNew === undefined)
                  ) {
                    return ''
                  }
                  return '*'
                },

                '{householdLength}': ({ formContext, fieldId }) => {
                  return formContext.formData.application.clients.length - 1
                },
                '{disable for existing household member}': ({
                  formContext,
                  fieldId
                }) => {
                  let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  return +clientIndex <= +initClientsLength - 1
                },
                '{usCtznOrNational}': ({ formContext, fieldId }) => {
                  let clientIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  if (
                    _.get(
                      formContext,
                      `formData.application.clients[${clientIndex}].clientDetails.citizenshipImmigration[0].usCtznOrNatInd`
                    ) === 'N'
                  ) {
                    return true
                  }
                  return false
                },
                '{disable Reference Individual field}': ({
                  formContext,
                  fieldId
                }) => {
                  let mainIndex = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
                  let taxInfoIndex
                  if (fieldId.includes('trackerChange')) {
                    taxInfoIndex = fieldId.match(
                      '(?:.*)trackerChange_([0-9]+)_?'
                    )[1]
                  }
                  if (taxInfoIndex) {
                    return true
                  }
                  return false
                },
                '{listIndividuals Enum}': ({ formContext, fieldId }) => {
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    []
                  )
                  let enumValue = []
                  clients.forEach(client => {
                    enumValue.push(client.identifier)
                  })

                  return enumValue

                  // const localFormData = _.get(
                  //   formContext,
                  //   'formData.application.listIndividuals',
                  //   []
                  // )
                  // let fieldIdMatchRegex = '(?:.*)trackerAdd_([0-9]+)_?'
                  // if (fieldId.includes('Change')) {
                  //   fieldIdMatchRegex = '(?:.*)trackerChange_([0-9]+)_?'
                  // }

                  // let index = fieldId.match(fieldIdMatchRegex)[1]
                  // let enumValue = getEnumNameorValue(
                  //   _.get(formContext, 'formData.application.clients', []),
                  //   'identifier',
                  //   localFormData,
                  //   index
                  // )
                  // console.log('{listIndividuals Enum}', enumValue)
                  // return enumValue
                },
                '{listIndividuals EnumNames}': ({ formContext, fieldId }) => {
                  //formData.application.listIndividuals
                  let clients = _.get(
                    formContext,
                    'formData.application.clients',
                    []
                  )
                  let enumNames = []
                  clients.forEach(client => {
                    if (
                      client &&
                      client.clientDetails &&
                      client.clientDetails.personalData &&
                      client.clientDetails.personalData[0]
                    ) {
                      enumNames.push(
                        client.clientDetails.personalData[0].clientFstNm +
                          ' ' +
                          client.clientDetails.personalData[0].clientLstNm
                      )
                    } else {
                      enumNames.push('')
                    }
                  })

                  return enumNames

                  // const localFormData = _.get(
                  //   formContext,
                  //   'formData.application.listIndividuals',
                  //   []
                  // )

                  // let fieldIdMatchRegex = '(?:.*)trackerAdd_([0-9]+)_?'
                  // if (fieldId.includes('Change')) {
                  //   fieldIdMatchRegex = '(?:.*)trackerChange_([0-9]+)_?'
                  // }
                  // let index = fieldId.match(fieldIdMatchRegex)[1]

                  // let enumNames = getEnumNameorValue(
                  //   _.get(formContext, 'formData.application.clients', []),
                  //   'fullName',
                  //   localFormData,
                  //   index
                  // )
                  // console.log('{listIndividuals EnumNames}', enumNames)
                  // return enumNames
                },
                '{listIndividualsLength}': ({ formContext, fieldId }) => {
                  return formContext.formData.application.clients.length
                }
              },
              currentDate: date,
              onUpdateCarouselSlide: this._onUpdateCarouselSlides,
              deleteOnHide: true, //This will cause form data to be removed when it becomes invisible via ui:visibleIf.
              deleteArrayOnHide: true, //This will cause form data to be removed when it becomes invisible via ui:eachVisibleIf.
              locale: this.props.locale
            }
          },
          () => {
            if (true) {
              this._initializeTabs()
              this._loadRenewalAndInitializeData()
            } else this._loadAndInitializeData()
            this._initializeTabs()
          }
        )
      })
      .catch(error => {
        console.error(
          'ClientUpdateWizard _initializeForms failed due to ex',
          error
        )
      })

    if (config.tenant.code) {
      this.setState(prevState => {
        return {
          formContext: {
            ...prevState.formContext,
            tenantId: config.tenant.id
          }
        }
      })
    }

    function getEnumNameorValue(
      clients: [],
      value: string,
      listIndividuals?: [],
      index?: string
    ): string[] {
      let returnValue = []
      let selectedValue = []

      if (
        listIndividuals[`${index}`] == undefined ||
        (listIndividuals[`${index}`].hasOwnProperty('name') &&
          listIndividuals[`${index}`]['name'] == undefined)
      ) {
        if (listIndividuals.length > 0) {
          for (let i = 0; i < listIndividuals.length; i++) {
            if (listIndividuals[i]['name'] != undefined) {
              selectedValue.push(listIndividuals[i]['name'])
            }
          }
        }
      }
      //if(listIndividuals[`${index}`])
      //formData.application.listIndividuals

      if (clients.length > 0) {
        for (let i = 0; i < clients.length; i++) {
          if (selectedValue.length > 0) {
            for (let j = 0; j < selectedValue.length; j++) {
              if (clients[i]['identifier'] != selectedValue[j]) {
                getFullName(value, returnValue, clients[i])
              }
            }
          } else {
            getFullName(value, returnValue, clients[i])
          }
        }
      }

      function getFullName(
        value: string,
        returnValue: string[],
        clients
      ): string[] {
        if (value == 'fullName') {
          let firstName =
            clients &&
            clients.clientDetails &&
            clients.clientDetails.personalData &&
            clients.clientDetails.personalData[0]
              ? clients.clientDetails.personalData[0].clientFstNm
              : ''

          let lastName =
            clients &&
            clients.clientDetails &&
            clients.clientDetails.personalData &&
            clients.clientDetails.personalData[0]
              ? clients.clientDetails.personalData[0].clientLstNm
              : ''
          let fullName = firstName + ' ' + lastName
          returnValue.push(fullName)
        }
        if (value == 'identifier') {
          returnValue.push(clients['identifier'])
        }

        return returnValue
      }

      return returnValue
    }

    function removeSelectedValues(
      clients: [],
      selectedValue: [],
      value: string
    ): string[] {
      let returnValue = []
      for (let i = 0; i < clients.length; i++) {}

      return returnValue
    }
  }

  _initializeTabs = () => {
    const tabs = []
    _.forEach(this.state.forms, (value, index) => {
      tabs.push({
        title: value['uiSchema']['externalOptions']['tab']['tabName'],
        leftIcon: value['uiSchema']['externalOptions']['tab']['iconClassName'],
        rightIcon: pristineIcon,
        rightSpan: null,
        status: 'pristine',
        visibleIf:
          value['uiSchema']['externalOptions']['tab']['visibleIf'] || true,
        isVisible:
          value['uiSchema']['externalOptions']['tab']['visibleIf'] || true
      })
    })
    this.setState({ tabs })
  }

  _loadRenewalAndInitializeData = () => {
    const { caseId } = this.state
    const { reviewIndicator } = this.state
    const applId = this.state.applId
    const renewalStatus = this.state.renewalStatus

    const fetchEndpoint =
      (this.state.applId != undefined &&
        this.state.renewalStatus === 'Renewal in Progress') ||
      (this.state.applId != undefined && this.state.renewalStatus === undefined)
        ? loadDraftEndpoint.replace('{applId}', applId)
        : loadRenewalpoint

    let resumingApplication = fetchEndpoint === loadRenewalpoint ? false : true
    let body: any = {
      programCode: ['FS P']
    }
    if (determineEnvironment() == 'LOCAL') {
      let json: any = {
        caseNumber: 1090942915,
        renewalDate: null,
        step: 9,
        applicationType: 'RENEWAL',
        applId: 4002547,
        Application: {
          applId: '4002547',
          tenantId: 'WV',
          someoneAsstYouFlg: 'Y',
          CONTRIBUTOR: {
            assisterRoleCd: '3',
            assisterPhones: {
              phoneType: '6'
            },
            assisterEmails: {},
            assisterAddress: {
              st: 'WV'
            }
          },
          clients: [
            {
              clientDetails: {
                personalData: [
                  {
                    clientFstNm: 'ANDY-local',
                    clientLstNm: 'ADAMS-local',
                    gdrCd: '1',
                    clientBthDt: '1990-01-01',
                    clientSsn: '487874616',
                    alterativeInformation: {},
                    clientId: 0,
                    mrtlStatusCd: '5',
                    isNew: false
                  }
                ],
                relationShip: [
                  {
                    relClientName: 1,
                    relationName: '39',
                    foodMeals: 'Y',
                    care: 'N',
                    isNew: false
                  }
                ],
                language: [
                  {
                    spokenLanguage: '1',
                    isNew: false
                  }
                ],
                ethnicityRace: [
                  {
                    ethnicOrigin: '1',
                    isNew: false
                  }
                ],
                citizenshipImmigration: [
                  {
                    usCtznOrNatInd: 'Y',
                    isNew: false
                  }
                ],
                residenceInformation: [
                  {
                    clientRemInStIntnFlg: 'Y',
                    addressSpecificationCode: '10',
                    isNew: false
                  }
                ],
                livingArrangement: [
                  {
                    clientRemInStIntnFlg: 'Y',
                    isNew: false
                  }
                ],
                email: [
                  {
                    isNew: false
                  },
                  {
                    isNew: false
                  }
                ]
              },
              clientIncomes: {
                earnedIncomes: [
                  {
                    action: 'PRISTINE',
                    startDate: '2019-01-01',
                    hoursWorked: '3',
                    amount: '75.00',
                    incomeSource: {
                      incomeSourceName: 'WV STATE',
                      address: {},
                      strike: 'N'
                    },
                    workHoursReduction: 'N',
                    isNew: false
                  }
                ],
                selfEmploymentIncomes: [
                  {
                    action: 'PRISTINE',
                    expense: '13.50',
                    amount: '82.00',
                    isNew: false
                  }
                ],
                unearnedIncomes: [
                  {
                    action: 'PRISTINE',
                    startDate: '2019-01-01',
                    amount: '13.00',
                    isNew: false,
                    incomeSource: {
                      incomeSourceName: 'Somewhere'
                    }
                  }
                ],
                lotteryGamblingWinnings: [
                  {
                    isNew: false
                  }
                ]
              },
              clientExpenses: {
                utilityExpenses: [
                  {
                    billPaidTo: 'AEP',
                    utilityCost: '74.00',
                    isNew: false
                  }
                ]
              },
              clientAdditionalInformation: {
                disability: [],
                education: [
                  {
                    levelOfEducation: '1',
                    isNew: false
                  }
                ]
              },
              clientAssets: {},
              nbrRegInd: '6092528541',
              clientFstNm: 'ANDY',
              clientLstNm: 'ADAMS',
              identifier: 'cb8b5ff8-5ec7-4f0e-9fb9-7e8fbb54e500',
              isOld: true
            },
            {
              clientDetails: {
                personalData: [
                  {
                    clientId: 1,
                    clientBthDt: '2010-04-01',
                    clientFstNm: 'BEN',
                    clientLstNm: 'ADAMS',
                    clientSsn: '341460481',
                    gdrCd: '1',
                    mrtlStatusCd: '1',
                    isNew: false
                  }
                ],
                language: [
                  {
                    spokenLanguage: '1',
                    isNew: false
                  }
                ],
                ethnicityRace: [
                  {
                    ethnicOrigin: '1',
                    isNew: false
                  }
                ],
                citizenshipImmigration: [
                  {
                    usCtznOrNatInd: 'Y',
                    isNew: false
                  }
                ],
                livingArrangement: [
                  {
                    clientRemInStIntnFlg: 'Y',
                    isNew: false
                  }
                ],
                email: [
                  {
                    isNew: false
                  },
                  {
                    isNew: false
                  }
                ],
                residenceInformation: [
                  {
                    clientRemInStIntnFlg: 'Y',
                    addressSpecificationCode: '10',
                    isNew: false
                  }
                ],
                relationShip: [
                  {
                    relClientName: 0,
                    relationName: '59',
                    foodMeals: 'Y',
                    care: 'N',
                    isNew: false
                  }
                ]
              },
              clientAdditionalInformation: {
                disability: [],
                education: [
                  {
                    levelOfEducation: '4',
                    isNew: false
                  }
                ]
              },
              clientIncomes: {
                unearnedIncomes: [
                  {
                    action: 'PRISTINE',
                    startDate: '2019-01-01',
                    amount: '13.00',
                    isNew: false
                  }
                ],
                lotteryGamblingWinnings: [
                  {
                    isNew: false
                  }
                ]
              },
              clientExpenses: {
                shelterExpenses: [
                  {
                    billPaidTo: 'WVHDF',
                    shelterCost: '22.00',
                    shelterType: '5',
                    isNew: false
                  }
                ]
              },
              clientAssets: {},
              nbrRegInd: '6092528550',
              clientFstNm: 'BEN',
              clientLstNm: 'ADAMS',
              identifier: '6391dda5-3af5-4d9c-8df6-569f1781b46f',
              isOld: true
            }
          ],
          APPLYFROM: {},
          EBT: {
            ebtQue: 'N',
            ebtCardHolder: {}
          },
          SPECIALNEEDS: {},
          householdRequestForADA: {
            householdRequestForADA: 'N'
          },
          prgNames: 'SNAP PRC2',
          benefitProgramName: 'undefined',
          householdInfo: {
            changeInAddress: 'N',
            householdAddress: {
              cntyLive: 'Greenbrier',
              gdrCd: '1',
              dtBirth: '1990-01-01',
              street: '700',
              poBox: 'WASHINGTON',
              ctyNm1: 'CHARLESTON',
              st: 'WV',
              zip1: '25301',
              ctyNm: 'CHARLESTON',
              zip: '25301'
            },
            mailingAddress: {
              street: '555',
              stDirection: '8',
              poBox: 'MAIN',
              stType: '122',
              apt: '4',
              ctyNm: 'DUNBAR',
              st: 'WV',
              zip: '25064',
              cntyLive: 'Greenbrier',
              gdrCd: '1',
              dtBirth: '1990-01-01',
              zipExt: '0'
            },
            contactInfo: {},
            housingAssistance: {},
            previousBenefitInfo: {
              movedSt: 'N'
            }
          },
          programCode: ['FS P'],
          lotterywon: 'N',
          householdIncomeChange: 'N',
          individualIncomeChange: 'N',
          householdUnearnedIncomeChange: 'N',
          changeInLegalObligationtoPay: 'N',
          changeReason: '1',
          adultWithoutDependents: {
            personUnder18: 'N',
            meetingExemption: 'N',
            workHoursBelow20: undefined,
            exemptionIndividualDetails: []
          },
          totalAmount: '123456'
        }
      }

      if (json.hasOwnProperty('tabStatus')) {
        let tabStatus = _tabStatus(this.state.tabs, json)
        let step = json.hasOwnProperty('step') && json.step != 1 ? json.step : 1
        this.setState({
          tabs: tabStatus,
          step
        })
      }
      if (
        !json ||
        !json.Application['clients'] ||
        !_.isArray(json.Application['clients'])
      ) {
        throw new Error('Expected a clients response property')
      }
      if (
        this.state.applId != undefined &&
        this.state.renewalStatus === undefined
      ) {
        if (!this.state.prgNames) {
          this.setState({
            prgNames: json.Application.prgNames,

            formContext: {
              ...this.state.formContext,
              prgNames: json.Application.prgNames
            }
          })
        }
        if (this.state.allPrograms[0] == 'null') {
          this.setState({
            allPrograms:
              json.Application.prgNames.search(',') > 0
                ? json.Application.prgNames.split(',')
                : [json.Application.prgNames]
          })
        }
        if (!this.state.benefitProgramName) {
          this.setState({
            benefitProgramName: json.Application.benefitProgramName
          })
        }
        if (!this.state.benefitProgramCode) {
          this.setState({
            benefitProgramCode: json.Application.benefitProgramCode
          })
        }
      }
      if (json.Application.programCode) {
        this.setState({
          programCode: json.Application.programCode
        })
      }
      let prgNames = this.state.prgNames || json.Application.prgNames
      let allPrograms
      if (this.state.allPrograms[0] == 'null') {
        allPrograms =
          json.Application.prgNames.search(',') > 0
            ? json.Application.prgNames.split(',')
            : [json.Application.prgNames]
      } else {
        allPrograms = this.state.allPrograms
      }
      let hasSpecialNeeds = false
      if (this.props.isRenewal) {
        for (let i = 0; i < allPrograms.length; i++) {
          let currentProgram = allPrograms[i]
          if (
            prgNames &&
            (currentProgram == 'food & nutrition (snap)' ||
              currentProgram == 'SNAP' ||
              currentProgram == 'FDNT' ||
              prgNames.search('SNAP') > 0 ||
              currentProgram == 'Medicare Premium Assistance' ||
              currentProgram == 'SLMB' ||
              currentProgram == 'QMB' ||
              prgNames.search('QMB') > 0 ||
              prgNames.search('Medicare Premium Assistance') > 0 ||
              currentProgram == 'Health Care Benefits' ||
              prgNames.search('Health Care Benefits') > 0 ||
              currentProgram == 'child support services' ||
              currentProgram == 'CHSP' ||
              prgNames.search('CHSP') > 0 ||
              prgNames.search('child support services') > 0 ||
              currentProgram == 'child care services' ||
              currentProgram == 'CHCR' ||
              prgNames.search('CHCR') > 0 ||
              prgNames.search('child care services') > 0)
          ) {
            hasSpecialNeeds = true
          }
        }
      } else {
        hasSpecialNeeds = true
      }
      let LIEAP = false,
        FDNT = false,
        HEWL = false,
        SCA = false,
        CHCR = false,
        CHSP = false,
        MDPA = false
      if (this.props.isRenewal) {
        for (let i = 0; i < allPrograms.length; i++) {
          let currentProgram = allPrograms[i]
          if (prgNames) {
            if (
              currentProgram == 'food & nutrition (snap)' ||
              currentProgram == 'SNAP' ||
              currentProgram == 'FDNT' ||
              prgNames.search('SNAP') > 0
            ) {
              FDNT = true
            }
            if (
              currentProgram == 'SLER' ||
              currentProgram == 'LIEAP' ||
              prgNames.search('LIEAP') > 0 ||
              prgNames.search('SLER') > 0 ||
              prgNames.search('Other-LIEAP') > 0
            ) {
              LIEAP = true
            }
            if (
              currentProgram == 'Health Care Benefits' ||
              prgNames.search('Health Care Benefits') > 0
            ) {
              HEWL = true
            }
            if (
              currentProgram == 'SCA' ||
              prgNames.search('SCA') > 0 ||
              prgNames.search('SCHOOL CLOTHING ALLOWANCE') > 0 ||
              prgNames.search('Other-SCA') > 0
            ) {
              SCA = true
            }
            if (
              currentProgram == 'child care services' ||
              currentProgram == 'CHCR' ||
              prgNames.search('CHCR') > 0 ||
              prgNames.search('child care services') > 0
            ) {
              CHCR = true
            }
            if (
              currentProgram == 'child support services' ||
              currentProgram == 'CHSP' ||
              prgNames.search('CHSP') > 0 ||
              prgNames.search('child support services') > 0
            ) {
              CHSP = true
            }
            if (
              currentProgram == 'Medicare Premium Assistance' ||
              currentProgram == 'SLMB' ||
              currentProgram == 'QMB' ||
              this.state.prgNames.search('QMB') > 0 ||
              this.state.prgNames.search('Medicare Premium Assistance') > 0
            ) {
              MDPA = true
            }
          }
        }
      }
      this.setState({
        hasSpecialNeeds,
        formContext: {
          ...this.state.formContext,
          LIEAP,
          FDNT,
          HEWL,
          SCA,
          CHCR,
          CHSP,
          MDPA
        }
      })
      let clientRelations
      let caseInfo = null
      if (
        json.Application['caseInfo'] &&
        Array.isArray(json.Application['caseInfo']) &&
        json.Application['caseInfo'].length > 0
      ) {
        caseInfo = json.Application['caseInfo'][0]
        clientRelations = caseInfo.clientRelations || []
      }
      const fetchedFormData = _.merge(
        helper.createObjectFromMasterSchema(null, this.state.formContext),
        {
          application: {
            clients: json.Application['clients'],
            clientRelations: clientRelations
          }
        }
      )

      initClientsLength =
        json &&
        json.Application &&
        json.Application['clients'] &&
        json.Application['clients'].length

      if (json.Application['CONTRIBUTOR']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          authorizedRepresentative: json.Application['CONTRIBUTOR']
        }
      }

      if (json.Application['APPLYFROM']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          APPLYFROM: json.Application['APPLYFROM']
        }
      }

      if (json.Application['EBT']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          EBT: json.Application['EBT']
        }
      }

      if (json.Application['SPECIALNEEDS']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          SPECIALNEEDS: json.Application['SPECIALNEEDS']
        }
      }
      if (json.Application['householdIncomeUsed']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          householdIncomeUsed: json.Application['householdIncomeUsed']
        }
      }
      if (json.Application.householdUnearedIncomeUsed) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          householdUnearedIncomeUsed:
            json.Application['householdUnearedIncomeUsed']
        }
      }

      if (json.Application.totalAmount) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          totalAmount: json.Application['totalAmount']
        }
      }

      if (json.Application['householdRequestForADA']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          householdRequestForADA: json.Application['householdRequestForADA']
        }
      }

      if (json.Application['householdInfo']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          householdInfo: json.Application['householdInfo']
        }
      }
      if (json.Application['prgNames']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          prgNames: json.Application['benefitProgramName']
        }
      }

      if (json.Application['lotterywon']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          wonMoreThan4250: json.Application['lotterywon']
        }
      }

      if (json.Application['householdIncomeChange']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          householdIncomeChange: json.Application['householdIncomeChange']
        }
      }

      if (json.Application['individualIncomeChange']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          individualIncomeChange: json.Application['individualIncomeChange']
        }
      }

      if (json.Application['householdUnearnedIncomeChange']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          householdUnearnedIncomeChange:
            json.Application['householdUnearnedIncomeChange']
        }
      }

      if (json.Application['changeInLegalObligationtoPay']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          changeInLegalObligationtoPay:
            json.Application['changeInLegalObligationtoPay']
        }
      }

      if (json.Application['changeReason']) {
        fetchedFormData.application = {
          ...fetchedFormData.application,
          changeReason: json.Application['changeReason']
        }
      }

      if (json.Application['adultWithoutDependents']) {
        if (json.Application['adultWithoutDependents']['personUnder18']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            underAgeEighteen:
              json.Application['adultWithoutDependents']['personUnder18']
          }
        }

        if (json.Application['adultWithoutDependents']['meetingExemption']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            allAdultsMeetExemptions:
              json.Application['adultWithoutDependents']['meetingExemption']
          }
        }

        if (json.Application['adultWithoutDependents']['workHoursBelow20']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            droppedBelowTwentyHours:
              json.Application['adultWithoutDependents']['workHoursBelow20']
          }
        }

        if (
          json.Application['adultWithoutDependents'][
            'exemptionIndividualDetails'
          ]
        ) {
          let exemptionIndividualDetails = _.cloneDeep(
            json.Application['adultWithoutDependents'][
              'exemptionIndividualDetails'
            ]
          )
          let newExemptionIndividualDetails = []

          exemptionIndividualDetails.forEach(tempExemptionIndividual => {
            let newExemptIndividual = {
              name: tempExemptionIndividual.name,
              dob: tempExemptionIndividual.dob,
              dateDecreasedBelow20: tempExemptionIndividual.dateDecreasedBelow20
            }
            newExemptionIndividualDetails.push(newExemptIndividual)
          })

          fetchedFormData.application = {
            ...fetchedFormData.application,
            listIndividuals: newExemptionIndividualDetails
          }
        }
      }

      let caseNumber = json.caseNumber
      let renewalDate = json.renewalDate
      let newFormData = helper.clearActions(fetchedFormData)
      helper.cleanNullValues(newFormData)
      for (let i = 0; i < newFormData.application.clients.length; i++) {
        newFormData.application.clients[i].nbrRegInd =
          json.Application.clients[i].nbrRegInd
        newFormData.application.clients[i].clientFstNm =
          json.Application.clients[i].clientFstNm
        newFormData.application.clients[i].clientLstNm =
          json.Application.clients[i].clientLstNm
      }

      let clients = newFormData.application.clients
      clients.forEach(client => {
        if (!client.hasOwnProperty('identifier')) {
          client.identifier = v4()
        }
      })
      clients.forEach(client => {
        let disability = client.clientAdditionalInformation
          ? client.clientAdditionalInformation.disability
          : undefined
        let relationShip = _.get(client, 'clientDetails.relationShip', [])
        let taxInformation = _.get(client, 'clientDetails.taxInformation', [])
        if (
          disability &&
          disability[0] &&
          (disability[0].disabilityType == null ||
            disability[0].disabilityType == undefined) &&
          (disability[0].disabilityBenefits == null ||
            disability[0].disabilityBenefits == undefined) &&
          (disability[0].disabilityStartDate == null ||
            disability[0].disabilityStartDate == undefined)
        ) {
          client.clientAdditionalInformation.disability = []
        }
        let earnedIncome = _.get(client, 'clientIncomes.earnedIncomes', [])
        earnedIncome.forEach(income => {
          if (_.get(income, 'incomeSource.address.zip') == '0') {
            _.set(income, 'incomeSource.address.zip', undefined)
          }
          // Code Changes GPRB0359078 and US2554056
          // Renewal - Earned Income Zip Code
          // Add Leading zero to Zip Code
          if (
            String(_.get(income, 'incomeSource.address.zip')).length > 0 &&
            String(_.get(income, 'incomeSource.address.zip')).length < 5
          ) {
            let zip = _.get(income, 'incomeSource.address.zip')
            let updatedZip = zip.padStart(5, '0')
            _.set(income, 'incomeSource.address.zip', updatedZip)
          }
        })
        if (
          _.get(
            newFormData,
            'application.householdInfo.householdAddress.zipExt'
          ) == '0'
        ) {
          _.set(
            newFormData,
            'application.householdInfo.householdAddress.zipExt',
            undefined
          )
        }
        // Code Changes GPRB0359078 and US2554056
        // Renewal - Household Address Zip Code and Zip Ext
        // Add Leading zero to Zip Code and Zip Ext
        if (
          String(
            _.get(
              newFormData,
              'application.householdInfo.householdAddress.zipExt'
            )
          ).length > 0 &&
          String(
            _.get(
              newFormData,
              'application.householdInfo.householdAddress.zipExt'
            )
          ).length < 4
        ) {
          let householdAddressZipExt = _.get(
            newFormData,
            'application.householdInfo.householdAddress.zipExt'
          )
          let updatedHouseholdAddressZipExt = householdAddressZipExt.padStart(
            4,
            '0'
          )
          _.set(
            newFormData,
            'application.householdInfo.householdAddress.zipExt',
            updatedHouseholdAddressZipExt
          )
        }
        if (
          String(
            _.get(newFormData, 'application.householdInfo.householdAddress.zip')
          ).length > 0 &&
          String(
            _.get(newFormData, 'application.householdInfo.householdAddress.zip')
          ).length < 5
        ) {
          let householdAddressZip = _.get(
            newFormData,
            'application.householdInfo.householdAddress.zip'
          )
          let updatedHouseholdAddressZip = householdAddressZip.padStart(5, '0')
          _.set(
            newFormData,
            'application.householdInfo.householdAddress.zip',
            updatedHouseholdAddressZip
          )
        }
        // Code Changes GPRB0359078 and US2554056
        // Renewal - Assister Address Zip Code
        // Add Leading zero to Zip Code
        if (
          String(
            _.get(
              newFormData,
              'application.authorizedRepresentative.assisterAddress.zip'
            )
          ).length > 0 &&
          String(
            _.get(
              newFormData,
              'application.authorizedRepresentative.assisterAddress.zip'
            )
          ).length < 5
        ) {
          let assisterAddressZip = _.get(
            newFormData,
            'application.authorizedRepresentative.assisterAddress.zip'
          )
          let updatedAssisterAddressZip = assisterAddressZip.padStart(5, '0')
          _.set(
            newFormData,
            'application.authorizedRepresentative.assisterAddress.zip',
            updatedAssisterAddressZip
          )
        }
        // Code Changes GPRB0359078 and US2554056
        // Renewal - Mailing Address Zip Code
        // Add Leading zero to Zip Code
        if (
          String(
            _.get(newFormData, 'application.householdInfo.mailingAddress.zip')
          ).length > 0 &&
          String(
            _.get(newFormData, 'application.householdInfo.mailingAddress.zip')
          ).length < 5
        ) {
          let mailingAddressZip = _.get(
            newFormData,
            'application.householdInfo.mailingAddress.zip'
          )
          let updatedMailingAddressZip = mailingAddressZip.padStart(5, '0')
          _.set(
            newFormData,
            'application.householdInfo.mailingAddress.zip',
            updatedMailingAddressZip
          )
        }
        relationShip.forEach(relation => {
          if (relation.relClientName != undefined) {
            relation.relClientName = clients[relation.relClientName].identifier
          }
        })
        taxInformation.forEach(taxInfo => {
          if (
            taxInfo.relClientName != undefined &&
            taxInfo.relClientName != 'smo'
          ) {
            taxInfo.relClientName = clients[taxInfo.relClientName].identifier
          }
        })
      })

      clients.forEach(client => {
        for (let clientKey of Object.keys(client)) {
          if (_.isObject(client[clientKey])) {
            for (let keys of Object.keys(client[clientKey])) {
              let currentAccordionData = _.get(
                client,
                '[' + clientKey + '][' + keys + ']',
                []
              )
              _.isArray(currentAccordionData) &&
                currentAccordionData.forEach(data => {
                  data.isNew = false
                })
            }
          }
        }
      })

      clients.forEach(client => {
        if (client.isOld === undefined) {
          client.isOld = true
        }
      })
      if (caseNumber > 0) {
        this.setState({
          callingApi: false,
          caseId: caseNumber,
          rnwlDate: renewalDate
        })
      }
      let jsonStep = 1
      if (json.step != undefined) jsonStep = json.step
      else jsonStep = this.state.step

      if (
        this.props.roleName === 'Community Partner' ||
        this.props.roleName === 'CP Admin'
      ) {
        _.set(
          newFormData,
          'application.authorizedRepresentative.assisterRoleCd',
          '10'
        )
      }

      //map earnedIncomes[i].incomeSource.incomeSourceName to earnedIncomes[i].sourceOfIncome
      newFormData.application.clients.forEach(client => {
        if (
          client.clientIncomes &&
          client.clientIncomes.earnedIncomes &&
          client.clientIncomes.earnedIncomes.length > 0
        ) {
          client.clientIncomes.earnedIncomes.forEach(earnedIncome => {
            if (
              earnedIncome &&
              earnedIncome.incomeSource &&
              earnedIncome.incomeSource.incomeSourceName
            ) {
              earnedIncome['sourceOfIncome'] = _.cloneDeep(
                earnedIncome.incomeSource.incomeSourceName
              )
              delete earnedIncome.incomeSource
            }
          })
        }

        // if (
        //   client.clientIncomes &&
        //   client.clientIncomes.unearnedIncomes &&
        //   client.clientIncomes.unearnedIncomes.length > 0
        // ) {
        //   client.clientIncomes.unearnedIncomes.forEach(unearnedIncome => {
        //     if (
        //       unearnedIncome &&
        //       unearnedIncome.incomeSource &&
        //       unearnedIncome.incomeSource.incomeSourceName
        //     ) {
        //       unearnedIncome['sourceOfIncome'] = _.cloneDeep(
        //         unearnedIncome.incomeSource.incomeSourceName
        //       )
        //       delete unearnedIncome.incomeSource
        //     }
        //   })
        // }
      })

      newFormData.application.clients.forEach(client => {
        if (client.clientAssets === undefined) {
          client.clientAssets = []
        }
        client.clientAssets = {
          bankingAccounts: client.clientAssets.bankingAccounts,
          cash: client.clientAssets.cash,
          trusts: client.clientAssets.trusts,
          investmentAccounts: client.clientAssets.investmentAccounts,
          lumpsumPayment: client.clientAssets.lumpsumPayment,
          vehicles: client.clientAssets.vehicles,
          soldtransferAsset: client.clientAssets.soldtransferAsset,
          insurancePolicies: client.clientAssets.insurancePolicies,
          otherAssets: client.clientAssets.otherAssets,
          realEstates: client.clientAssets.realEstates
        }
        client.clientIncomes = {
          earnedIncomes: client.clientIncomes.earnedIncomes,
          selfEmploymentIncomes: client.clientIncomes.selfEmploymentIncomes,
          unearnedIncomes: client.clientIncomes.unearnedIncomes,
          lotteryGamblingWinnings: client.clientIncomes.lotteryGamblingWinnings
        }
        client.clientExpenses = {
          childSupport: client.clientExpenses.childSupport,
          dependentCareExpenses: client.clientExpenses.dependentCareExpenses,
          heatingExpenses: client.clientExpenses.heatingExpenses,
          coolingExpenses: client.clientExpenses.coolingExpenses,
          utilityExpenses: client.clientExpenses.utilityExpenses,
          shelterExpenses: client.clientExpenses.shelterExpenses,
          deduction: client.clientExpenses.deduction,
          educationExpenses: client.clientExpenses.educationExpenses,
          electricExpenses: client.clientExpenses.electricExpenses,
          gasExpenses: client.clientExpenses.gasExpenses,
          medicalExpenses: client.clientExpenses.medicalExpenses,
          movingExpenses: client.clientExpenses.movingExpenses,
          otherExpenses: client.clientExpenses.otherExpenses,
          selfEmploymentExpenses: client.clientExpenses.selfEmploymentExpenses,
          sewerExpenses: client.clientExpenses.sewerExpenses,
          waterExpenses: client.clientExpenses.waterExpenses,
          workExpenses: client.clientExpenses.workExpenses
        }
      })

      const householdAddressCtyNm = _.get(
        newFormData,
        'application.householdInfo.householdAddress.ctyNm'
      )
      const householdAddresszip = _.get(
        newFormData,
        'application.householdInfo.householdAddress.zip'
      )

      _.set(
        newFormData,
        'application.householdInfo.householdAddress.ctyNm1',
        householdAddressCtyNm
      )

      _.set(
        newFormData,
        'application.householdInfo.householdAddress.zip1',
        householdAddresszip
      )

      if (!resumingApplication) {
        if (
          newFormData.application.householdInfo.mailingAddress === undefined
        ) {
          newFormData.application.householdInfo.mailingAddress = { st: 'WV' }
        } else if (
          newFormData.application.householdInfo.mailingAddress.st ===
            undefined ||
          newFormData.application.householdInfo.mailingAddress.st === ''
        ) {
          newFormData.application.householdInfo.mailingAddress.st = 'WV'
        }

        const originalApplicationFormData = _.cloneDeep(newFormData.application)
        _.set(newFormData, 'originalApplication', originalApplicationFormData)
      } else {
        const originalApplicationFormData = _.cloneDeep(
          json.OriginalApplication
        )
        _.set(newFormData, 'originalApplication', originalApplicationFormData)
      }

      this.setState(prevState => {
        return {
          formData: newFormData,
          step: jsonStep,
          oldFormData: _.cloneDeep(newFormData),
          formContext: {
            ...prevState.formContext,
            originalFormData: newFormData,
            formData: newFormData,
            oldClients: newFormData.application.clients.length,
            reviewFormData: _.cloneDeep(newFormData)
          },
          caseInfo
        }
      })
    } else {
      serviceHelper
        .fetchJson(
          fetchEndpoint,
          {
            headers: {
              'Content-Type': 'application/json',
              reviewTypeIndicator: reviewIndicator,
              applId: applId,
              uuid: this.props.auth.userAccount.uuid,
              caseNumber: caseId,
              org_id: this.props.orgId,
              role_id: this.props.roleId,
              status: 'OPEN',
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              userRoleId: this.props.userRoleId,
              roleId: this.props.roleId,
              orgId: this.props.orgId,
              Origin: 'https://wvie-client-test.optum.com'
            },
            method: 'POST',
            body: JSON.stringify(body)
          },
          this.props.showErrorMessage
        )
        .then(json => {
          if (json.hasOwnProperty('tabStatus')) {
            let tabStatus = _tabStatus(this.state.tabs, json)
            let step =
              json.hasOwnProperty('step') && json.step != 1 ? json.step : 1
            this.setState({
              tabs: tabStatus,
              step
            })
          }
          if (
            !json ||
            !json.Application['clients'] ||
            !_.isArray(json.Application['clients'])
          ) {
            throw new Error('Expected a clients response property')
          }
          if (
            this.state.applId != undefined &&
            this.state.renewalStatus === undefined
          ) {
            if (!this.state.prgNames) {
              this.setState({
                prgNames: json.Application.prgNames,

                formContext: {
                  ...this.state.formContext,
                  prgNames: json.Application.prgNames
                }
              })
            }
            if (this.state.allPrograms[0] == 'null') {
              this.setState({
                allPrograms:
                  json.Application.prgNames.search(',') > 0
                    ? json.Application.prgNames.split(',')
                    : [json.Application.prgNames]
              })
            }
            if (!this.state.benefitProgramName) {
              this.setState({
                benefitProgramName: json.Application.benefitProgramName
              })
            }
            if (!this.state.benefitProgramCode) {
              this.setState({
                benefitProgramCode: json.Application.benefitProgramCode
              })
            }
          }
          if (json.Application.programCode) {
            this.setState({
              programCode: json.Application.programCode
            })
          }
          let prgNames = this.state.prgNames || json.Application.prgNames
          let allPrograms
          if (this.state.allPrograms[0] == 'null') {
            allPrograms =
              json.Application.prgNames.search(',') > 0
                ? json.Application.prgNames.split(',')
                : [json.Application.prgNames]
          } else {
            allPrograms = this.state.allPrograms
          }
          let hasSpecialNeeds = false
          if (this.props.isRenewal) {
            for (let i = 0; i < allPrograms.length; i++) {
              let currentProgram = allPrograms[i]
              if (
                prgNames &&
                (currentProgram == 'food & nutrition (snap)' ||
                  currentProgram == 'SNAP' ||
                  currentProgram == 'FDNT' ||
                  prgNames.search('SNAP') > 0 ||
                  currentProgram == 'Medicare Premium Assistance' ||
                  currentProgram == 'SLMB' ||
                  currentProgram == 'QMB' ||
                  prgNames.search('QMB') > 0 ||
                  prgNames.search('Medicare Premium Assistance') > 0 ||
                  currentProgram == 'Health Care Benefits' ||
                  prgNames.search('Health Care Benefits') > 0 ||
                  currentProgram == 'child support services' ||
                  currentProgram == 'CHSP' ||
                  prgNames.search('CHSP') > 0 ||
                  prgNames.search('child support services') > 0 ||
                  currentProgram == 'child care services' ||
                  currentProgram == 'CHCR' ||
                  prgNames.search('CHCR') > 0 ||
                  prgNames.search('child care services') > 0)
              ) {
                hasSpecialNeeds = true
              }
            }
          } else {
            hasSpecialNeeds = true
          }
          let LIEAP = false,
            FDNT = false,
            HEWL = false,
            SCA = false,
            CHCR = false,
            CHSP = false,
            MDPA = false
          if (this.props.isRenewal) {
            for (let i = 0; i < allPrograms.length; i++) {
              let currentProgram = allPrograms[i]
              if (prgNames) {
                if (
                  currentProgram == 'food & nutrition (snap)' ||
                  currentProgram == 'SNAP' ||
                  currentProgram == 'FDNT' ||
                  prgNames.search('SNAP') > 0
                ) {
                  FDNT = true
                }
                if (
                  currentProgram == 'SLER' ||
                  currentProgram == 'LIEAP' ||
                  prgNames.search('LIEAP') > 0 ||
                  prgNames.search('SLER') > 0 ||
                  prgNames.search('Other-LIEAP') > 0
                ) {
                  LIEAP = true
                }
                if (
                  currentProgram == 'Health Care Benefits' ||
                  prgNames.search('Health Care Benefits') > 0
                ) {
                  HEWL = true
                }
                if (
                  currentProgram == 'SCA' ||
                  prgNames.search('SCA') > 0 ||
                  prgNames.search('SCHOOL CLOTHING ALLOWANCE') > 0 ||
                  prgNames.search('Other-SCA') > 0
                ) {
                  SCA = true
                }
                if (
                  currentProgram == 'child care services' ||
                  currentProgram == 'CHCR' ||
                  prgNames.search('CHCR') > 0 ||
                  prgNames.search('child care services') > 0
                ) {
                  CHCR = true
                }
                if (
                  currentProgram == 'child support services' ||
                  currentProgram == 'CHSP' ||
                  prgNames.search('CHSP') > 0 ||
                  prgNames.search('child support services') > 0
                ) {
                  CHSP = true
                }
                if (
                  currentProgram == 'Medicare Premium Assistance' ||
                  currentProgram == 'SLMB' ||
                  currentProgram == 'QMB' ||
                  this.state.prgNames.search('QMB') > 0 ||
                  this.state.prgNames.search('Medicare Premium Assistance') > 0
                ) {
                  MDPA = true
                }
              }
            }
          }
          this.setState({
            hasSpecialNeeds,
            formContext: {
              ...this.state.formContext,
              LIEAP,
              FDNT,
              HEWL,
              SCA,
              CHCR,
              CHSP,
              MDPA
            }
          })
          let clientRelations
          let caseInfo = null
          if (
            json.Application['caseInfo'] &&
            Array.isArray(json.Application['caseInfo']) &&
            json.Application['caseInfo'].length > 0
          ) {
            caseInfo = json.Application['caseInfo'][0]
            clientRelations = caseInfo.clientRelations || []
          }
          const fetchedFormData = _.merge(
            helper.createObjectFromMasterSchema(null, this.state.formContext),
            {
              application: {
                clients: json.Application['clients'],
                clientRelations: clientRelations
              }
            }
          )

          initClientsLength =
            json &&
            json.Application &&
            json.Application['clients'] &&
            json.Application['clients'].length

          if (json.Application['CONTRIBUTOR']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              authorizedRepresentative: json.Application['CONTRIBUTOR']
            }
          }

          if (json.Application['APPLYFROM']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              APPLYFROM: json.Application['APPLYFROM']
            }
          }

          if (json.Application['EBT']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              EBT: json.Application['EBT']
            }
          }

          if (json.Application['SPECIALNEEDS']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              SPECIALNEEDS: json.Application['SPECIALNEEDS']
            }
          }
          if (json.Application['householdIncomeUsed']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              householdIncomeUsed: json.Application['householdIncomeUsed']
            }
          }
          if (json.Application.householdUnearedIncomeUsed) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              householdUnearedIncomeUsed:
                json.Application['householdUnearedIncomeUsed']
            }
          }

          if (json.Application.totalAmount) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              totalAmount: json.Application['totalAmount']
            }
          }

          if (json.Application['householdRequestForADA']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              householdRequestForADA: json.Application['householdRequestForADA']
            }
          }

          if (json.Application['householdInfo']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              householdInfo: json.Application['householdInfo']
            }
          }
          if (json.Application['prgNames']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              prgNames: json.Application['benefitProgramName']
            }
          }

          if (json.Application['lotterywon']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              wonMoreThan4250: json.Application['lotterywon']
            }
          }

          if (json.Application['householdIncomeChange']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              householdIncomeChange: json.Application['householdIncomeChange']
            }
          }

          if (json.Application['individualIncomeChange']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              individualIncomeChange: json.Application['individualIncomeChange']
            }
          }

          if (json.Application['householdUnearnedIncomeChange']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              householdUnearnedIncomeChange:
                json.Application['householdUnearnedIncomeChange']
            }
          }

          if (json.Application['changeInLegalObligationtoPay']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              changeInLegalObligationtoPay:
                json.Application['changeInLegalObligationtoPay']
            }
          }

          if (json.Application['changeReason']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              changeReason: json.Application['changeReason']
            }
          }

          if (json.Application['liveWithYou']) {
            fetchedFormData.application = {
              ...fetchedFormData.application,
              liveWithYou: json.Application['liveWithYou']
            }
          }

          if (json.Application['adultWithoutDependents']) {
            if (json.Application['adultWithoutDependents']['personUnder18']) {
              fetchedFormData.application = {
                ...fetchedFormData.application,
                underAgeEighteen:
                  json.Application['adultWithoutDependents']['personUnder18']
              }
            }

            if (
              json.Application['adultWithoutDependents']['meetingExemption']
            ) {
              fetchedFormData.application = {
                ...fetchedFormData.application,
                allAdultsMeetExemptions:
                  json.Application['adultWithoutDependents']['meetingExemption']
              }
            }

            if (
              json.Application['adultWithoutDependents']['workHoursBelow20']
            ) {
              fetchedFormData.application = {
                ...fetchedFormData.application,
                droppedBelowTwentyHours:
                  json.Application['adultWithoutDependents']['workHoursBelow20']
              }
            }

            if (
              json.Application['adultWithoutDependents'][
                'exemptionIndividualDetails'
              ]
            ) {
              let exemptionIndividualDetails = _.cloneDeep(
                json.Application['adultWithoutDependents'][
                  'exemptionIndividualDetails'
                ]
              )
              let newExemptionIndividualDetails = []

              exemptionIndividualDetails.forEach(tempExemptionIndividual => {
                let newExemptIndividual = {
                  name: tempExemptionIndividual.name,
                  dob: tempExemptionIndividual.dob,
                  dateDecreasedBelow20:
                    tempExemptionIndividual.dateDecreasedBelow20
                }
                newExemptionIndividualDetails.push(newExemptIndividual)
              })

              fetchedFormData.application = {
                ...fetchedFormData.application,
                listIndividuals: newExemptionIndividualDetails
              }
            }
          }

          let caseNumber = json.caseNumber
          let renewalDate = json.renewalDate
          let newFormData = helper.clearActions(fetchedFormData)
          helper.cleanNullValues(newFormData)
          for (let i = 0; i < newFormData.application.clients.length; i++) {
            newFormData.application.clients[i].nbrRegInd =
              json.Application.clients[i].nbrRegInd
            newFormData.application.clients[i].clientFstNm =
              json.Application.clients[i].clientFstNm
            newFormData.application.clients[i].clientLstNm =
              json.Application.clients[i].clientLstNm
          }

          let clients = newFormData.application.clients
          clients.forEach(client => {
            if (!client.hasOwnProperty('identifier')) {
              client.identifier = v4()
            }
          })
          clients.forEach(client => {
            let disability = client.clientAdditionalInformation
              ? client.clientAdditionalInformation.disability
              : undefined
            let relationShip = _.get(client, 'clientDetails.relationShip', [])
            let taxInformation = _.get(
              client,
              'clientDetails.taxInformation',
              []
            )
            if (
              disability &&
              disability[0] &&
              (disability[0].disabilityType == null ||
                disability[0].disabilityType == undefined) &&
              (disability[0].disabilityBenefits == null ||
                disability[0].disabilityBenefits == undefined) &&
              (disability[0].disabilityStartDate == null ||
                disability[0].disabilityStartDate == undefined)
            ) {
              client.clientAdditionalInformation.disability = []
            }
            let earnedIncome = _.get(client, 'clientIncomes.earnedIncomes', [])
            earnedIncome.forEach(income => {
              if (_.get(income, 'incomeSource.address.zip') == '0') {
                _.set(income, 'incomeSource.address.zip', undefined)
              }
              // Code Changes GPRB0359078 and US2554056
              // Renewal - Earned Income Zip Code
              // Add Leading zero to Zip Code
              if (
                String(_.get(income, 'incomeSource.address.zip')).length > 0 &&
                String(_.get(income, 'incomeSource.address.zip')).length < 5
              ) {
                let zip = _.get(income, 'incomeSource.address.zip')
                let updatedZip = zip.padStart(5, '0')
                _.set(income, 'incomeSource.address.zip', updatedZip)
              }
            })
            if (
              _.get(
                newFormData,
                'application.householdInfo.householdAddress.zipExt'
              ) == '0'
            ) {
              _.set(
                newFormData,
                'application.householdInfo.householdAddress.zipExt',
                undefined
              )
            }
            // Code Changes GPRB0359078 and US2554056
            // Renewal - Household Address Zip Code and Zip Ext
            // Add Leading zero to Zip Code and Zip Ext
            if (
              String(
                _.get(
                  newFormData,
                  'application.householdInfo.householdAddress.zipExt'
                )
              ).length > 0 &&
              String(
                _.get(
                  newFormData,
                  'application.householdInfo.householdAddress.zipExt'
                )
              ).length < 4
            ) {
              let householdAddressZipExt = _.get(
                newFormData,
                'application.householdInfo.householdAddress.zipExt'
              )
              let updatedHouseholdAddressZipExt = householdAddressZipExt.padStart(
                4,
                '0'
              )
              _.set(
                newFormData,
                'application.householdInfo.householdAddress.zipExt',
                updatedHouseholdAddressZipExt
              )
            }
            if (
              String(
                _.get(
                  newFormData,
                  'application.householdInfo.householdAddress.zip'
                )
              ).length > 0 &&
              String(
                _.get(
                  newFormData,
                  'application.householdInfo.householdAddress.zip'
                )
              ).length < 5
            ) {
              let householdAddressZip = _.get(
                newFormData,
                'application.householdInfo.householdAddress.zip'
              )
              let updatedHouseholdAddressZip = householdAddressZip.padStart(
                5,
                '0'
              )
              _.set(
                newFormData,
                'application.householdInfo.householdAddress.zip',
                updatedHouseholdAddressZip
              )
            }
            // Code Changes GPRB0359078 and US2554056
            // Renewal - Assister Address Zip Code
            // Add Leading zero to Zip Code
            if (
              String(
                _.get(
                  newFormData,
                  'application.authorizedRepresentative.assisterAddress.zip'
                )
              ).length > 0 &&
              String(
                _.get(
                  newFormData,
                  'application.authorizedRepresentative.assisterAddress.zip'
                )
              ).length < 5
            ) {
              let assisterAddressZip = _.get(
                newFormData,
                'application.authorizedRepresentative.assisterAddress.zip'
              )
              let updatedAssisterAddressZip = assisterAddressZip.padStart(
                5,
                '0'
              )
              _.set(
                newFormData,
                'application.authorizedRepresentative.assisterAddress.zip',
                updatedAssisterAddressZip
              )
            }
            // Code Changes GPRB0359078 and US2554056
            // Renewal - Mailing Address Zip Code
            // Add Leading zero to Zip Code
            if (
              String(
                _.get(
                  newFormData,
                  'application.householdInfo.mailingAddress.zip'
                )
              ).length > 0 &&
              String(
                _.get(
                  newFormData,
                  'application.householdInfo.mailingAddress.zip'
                )
              ).length < 5
            ) {
              let mailingAddressZip = _.get(
                newFormData,
                'application.householdInfo.mailingAddress.zip'
              )
              let updatedMailingAddressZip = mailingAddressZip.padStart(5, '0')
              _.set(
                newFormData,
                'application.householdInfo.mailingAddress.zip',
                updatedMailingAddressZip
              )
            }
            relationShip.forEach(relation => {
              if (relation.relClientName != undefined) {
                relation.relClientName =
                  clients[relation.relClientName].identifier
              }
            })
            taxInformation.forEach(taxInfo => {
              if (
                taxInfo.relClientName != undefined &&
                taxInfo.relClientName != 'smo'
              ) {
                taxInfo.relClientName =
                  clients[taxInfo.relClientName].identifier
              }
            })
          })

          clients.forEach(client => {
            for (let clientKey of Object.keys(client)) {
              if (_.isObject(client[clientKey])) {
                for (let keys of Object.keys(client[clientKey])) {
                  let currentAccordionData = _.get(
                    client,
                    '[' + clientKey + '][' + keys + ']',
                    []
                  )
                  _.isArray(currentAccordionData) &&
                    currentAccordionData.forEach(data => {
                      data.isNew = false
                    })
                }
              }
            }
          })

          clients.forEach(client => {
            if (client.isOld === undefined) {
              client.isOld = true
            }
          })
          if (caseNumber > 0) {
            this.setState({
              callingApi: false,
              caseId: caseNumber,
              rnwlDate: renewalDate
            })
          }
          let jsonStep = 1
          if (json.step != undefined) jsonStep = json.step
          else jsonStep = this.state.step

          if (
            this.props.roleName === 'Community Partner' ||
            this.props.roleName === 'CP Admin'
          ) {
            _.set(
              newFormData,
              'application.authorizedRepresentative.assisterRoleCd',
              '10'
            )
          }

          //map earnedIncomes[i].incomeSource.incomeSourceName to earnedIncomes[i].sourceOfIncome
          newFormData.application.clients.forEach(client => {
            if (
              client.clientIncomes &&
              client.clientIncomes.earnedIncomes &&
              client.clientIncomes.earnedIncomes.length > 0
            ) {
              client.clientIncomes.earnedIncomes.forEach(earnedIncome => {
                if (
                  earnedIncome &&
                  earnedIncome.incomeSource &&
                  earnedIncome.incomeSource.incomeSourceName
                ) {
                  earnedIncome['sourceOfIncome'] = _.cloneDeep(
                    earnedIncome.incomeSource.incomeSourceName
                  )
                  delete earnedIncome.incomeSource
                }
              })
            }

            // if (
            //   client.clientIncomes &&
            //   client.clientIncomes.unearnedIncomes &&
            //   client.clientIncomes.unearnedIncomes.length > 0
            // ) {
            //   client.clientIncomes.unearnedIncomes.forEach(unearnedIncome => {
            //     if (
            //       unearnedIncome &&
            //       unearnedIncome.incomeSource &&
            //       unearnedIncome.incomeSource.incomeSourceName
            //     ) {
            //       unearnedIncome['sourceOfIncome'] = _.cloneDeep(
            //         unearnedIncome.incomeSource.incomeSourceName
            //       )
            //       delete unearnedIncome.incomeSource
            //     }
            //   })
            // }
          })

          newFormData.application.clients.forEach(client => {
            if (client.clientAssets === undefined) {
              client.clientAssets = []
            }
            client.clientAssets = {
              bankingAccounts: client.clientAssets.bankingAccounts,
              cash: client.clientAssets.cash,
              trusts: client.clientAssets.trusts,
              investmentAccounts: client.clientAssets.investmentAccounts,
              lumpsumPayment: client.clientAssets.lumpsumPayment,
              vehicles: client.clientAssets.vehicles,
              soldtransferAsset: client.clientAssets.soldtransferAsset,
              insurancePolicies: client.clientAssets.insurancePolicies,
              otherAssets: client.clientAssets.otherAssets,
              realEstates: client.clientAssets.realEstates
            }
            client.clientIncomes = {
              earnedIncomes: client.clientIncomes.earnedIncomes,
              selfEmploymentIncomes: client.clientIncomes.selfEmploymentIncomes,
              unearnedIncomes: client.clientIncomes.unearnedIncomes,
              lotteryGamblingWinnings:
                client.clientIncomes.lotteryGamblingWinnings
            }
            client.clientExpenses = {
              childSupport: client.clientExpenses.childSupport,
              dependentCareExpenses:
                client.clientExpenses.dependentCareExpenses,
              heatingExpenses: client.clientExpenses.heatingExpenses,
              coolingExpenses: client.clientExpenses.coolingExpenses,
              utilityExpenses: client.clientExpenses.utilityExpenses,
              shelterExpenses: client.clientExpenses.shelterExpenses,
              deduction: client.clientExpenses.deduction,
              educationExpenses: client.clientExpenses.educationExpenses,
              electricExpenses: client.clientExpenses.electricExpenses,
              gasExpenses: client.clientExpenses.gasExpenses,
              medicalExpenses: client.clientExpenses.medicalExpenses,
              movingExpenses: client.clientExpenses.movingExpenses,
              otherExpenses: client.clientExpenses.otherExpenses,
              selfEmploymentExpenses:
                client.clientExpenses.selfEmploymentExpenses,
              sewerExpenses: client.clientExpenses.sewerExpenses,
              waterExpenses: client.clientExpenses.waterExpenses,
              workExpenses: client.clientExpenses.workExpenses
            }
          })

          const householdAddressCtyNm = _.get(
            newFormData,
            'application.householdInfo.householdAddress.ctyNm'
          )
          const householdAddresszip = _.get(
            newFormData,
            'application.householdInfo.householdAddress.zip'
          )

          _.set(
            newFormData,
            'application.householdInfo.householdAddress.ctyNm1',
            householdAddressCtyNm
          )

          _.set(
            newFormData,
            'application.householdInfo.householdAddress.zip1',
            householdAddresszip
          )

          if (!resumingApplication) {
            if (
              newFormData.application.householdInfo.mailingAddress === undefined
            ) {
              newFormData.application.householdInfo.mailingAddress = {
                st: 'WV'
              }
            } else if (
              newFormData.application.householdInfo.mailingAddress.st ===
                undefined ||
              newFormData.application.householdInfo.mailingAddress.st === ''
            ) {
              newFormData.application.householdInfo.mailingAddress.st = 'WV'
            }
            const originalApplicationFormData = _.cloneDeep(
              newFormData.application
            )
            _.set(
              newFormData,
              'originalApplication',
              originalApplicationFormData
            )
          } else {
            const originalApplicationFormData = _.cloneDeep(
              json.OriginalApplication
            )
            _.set(
              newFormData,
              'originalApplication',
              originalApplicationFormData
            )
          }

          this.setState(prevState => {
            return {
              formData: newFormData,
              step: jsonStep,
              oldFormData: _.cloneDeep(newFormData),
              formContext: {
                ...prevState.formContext,
                originalFormData: newFormData,
                formData: newFormData,
                oldClients: newFormData.application.clients.length,
                reviewFormData: _.cloneDeep(newFormData)
              },
              caseInfo
            }
          })
        })
        .catch(error => {
          if (error instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          console.error(
            'ClientUpdateWizard _loadAndInitializeData failed with error:',
            error
          )
        })
    }
  }

  _loadAndInitializeData = () => {
    const { caseId } = this.state
    const applId =
      this.state.status === 'DELETE' || this.state.status === 'PARTIAL SUBMIT'
        ? null
        : this.state.applId

    const fetchEndpoint =
      applId &&
      applId !== 'undefined' &&
      (this.state.status === 'INDRAFT' || this.state.status === undefined)
        ? loadDraftEndpoint.replace('{applId}', applId)
        : loadEndpoint.replace('{caseId}', caseId.toString())

    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          headers: {
            uuid: this.props.auth.userAccount.uuid,
            'Content-Type': 'application/json',
            caseNumber: this.state.caseId,
            status: this.state.status,
            applId: this.state.applId,
            org_id: null,
            role_id: this.props.roleId.toString(),
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            userRoleId: this.props.userRoleId,
            orgId: this.props.orgId,
            roleId: this.props.roleId
          },
          method: 'POST'
        },
        this.props.showErrorMessage
      )
      .then(json => {
        if (json.hasOwnProperty('error')) {
          this.setState({ redirect: baseUrl + 'myaccount/lifeevents' })
        }
        if (json.hasOwnProperty('tabStatus')) {
          let tabStatus = _tabStatus(this.state.tabs, json)
          let step =
            json.hasOwnProperty('step') && json.step != 1 ? json.step : 1
          this.setState({
            tabs: tabStatus,
            step
          })
        }

        if (
          !json ||
          !json.Application['clients'] ||
          !_.isArray(json.Application['clients'])
        ) {
          throw new Error('Expected a clients response property')
        }
        let clientRelations
        let caseInfo = null
        initClientsLength =
          json &&
          json.Application &&
          json.Application['clients'] &&
          json.Application['clients'].length

        json.Application.clients.forEach(client => {
          if (client.isOld === undefined) {
            client.isOld = true
          }
        })
        if (
          json.Application['caseInfo'] &&
          Array.isArray(json.Application['caseInfo']) &&
          json.Application['caseInfo'].length > 0
        ) {
          caseInfo = json.Application['caseInfo'][0]
          clientRelations = caseInfo.clientRelations || []
        }
        let fetchedFormData = _.merge(
          helper.createObjectFromMasterSchema(null, this.state.formContext),
          {
            application: {
              clients: json.Application['clients'],
              clientRelations: clientRelations
            }
          }
        )

        if (json.Application['APPLYFROM']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            APPLYFROM: json.Application['APPLYFROM']
          }
        }

        if (json.Application['EBT']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            EBT: json.Application['EBT']
          }
        }

        if (json.Application['SPECIALNEEDS']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            SPECIALNEEDS: json.Application['SPECIALNEEDS']
          }
        }

        if (json.Application['householdRequestForADA']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            householdRequestForADA: json.Application['householdRequestForADA']
          }
        }

        if (json.Application['householdInfo']) {
          fetchedFormData.application = {
            ...fetchedFormData.application,
            householdInfo: json.Application['householdInfo']
          }
        }

        if (json.Application.programCode) {
          this.setState({
            programCode: json.Application.programCode
          })
        }

        let caseNumber = json.caseNumber
        let clients = fetchedFormData.application.clients
        clients.forEach(client => {
          if (!client.hasOwnProperty('identifier')) {
            client.identifier = v4()
          }
        })
        clients.forEach(client => {
          let disability = client.clientAdditionalInformation
            ? client.clientAdditionalInformation.disability
            : undefined
          let relationShip = _.get(client, 'clientDetails.relationShip', [])
          let taxInformation = _.get(client, 'clientDetails.taxInformation', [])
          if (
            disability &&
            disability[0] &&
            (disability[0].disabilityType == null ||
              disability[0].disabilityType == undefined) &&
            (disability[0].disabilityBenefits == null ||
              disability[0].disabilityBenefits == undefined) &&
            (disability[0].disabilityStartDate == null ||
              disability[0].disabilityStartDate == undefined)
          ) {
            client.clientAdditionalInformation.disability = []
          }
          let earnedIncome = _.get(client, 'clientIncomes.earnedIncomes', [])
          earnedIncome.forEach(income => {
            if (_.get(income, 'incomeSource.address.zip') == '0') {
              _.set(income, 'incomeSource.address.zip', undefined)
            }
            // Code Changes GPRB0359078 and US2554056
            // COC - Earned Income Zip Code
            // Add Leading zero to Zip Code
            if (
              String(_.get(income, 'incomeSource.address.zip')).length > 0 &&
              String(_.get(income, 'incomeSource.address.zip')).length < 5
            ) {
              let zip = _.get(income, 'incomeSource.address.zip')
              let updatedZip = zip.padStart(5, '0')
              _.set(income, 'incomeSource.address.zip', updatedZip)
            }
          })
          if (
            _.get(
              fetchedFormData,
              'application.householdInfo.householdAddress.zipExt'
            ) == '0'
          ) {
            _.set(
              fetchedFormData,
              'application.householdInfo.householdAddress.zipExt',
              undefined
            )
          }
          // Code Changes GPRB0359078 and US2554056
          // COC - Household Address Zip Code and Zip Ext
          // Add Leading zero to Zip Code and Zip Ext
          if (
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.householdAddress.zipExt'
              )
            ).length > 0 &&
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.householdAddress.zipExt'
              )
            ).length < 4
          ) {
            let householdAddressZipExt = _.get(
              fetchedFormData,
              'application.householdInfo.householdAddress.zipExt'
            )
            let updatedHouseholdAddressZipExt = householdAddressZipExt.padStart(
              4,
              '0'
            )
            _.set(
              fetchedFormData,
              'application.householdInfo.householdAddress.zipExt',
              updatedHouseholdAddressZipExt
            )
          }
          if (
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.householdAddress.zip'
              )
            ).length > 0 &&
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.householdAddress.zip'
              )
            ).length < 5
          ) {
            let householdAddressZip = _.get(
              fetchedFormData,
              'application.householdInfo.householdAddress.zip'
            )
            let updatedHouseholdAddressZip = householdAddressZip.padStart(
              5,
              '0'
            )
            _.set(
              fetchedFormData,
              'application.householdInfo.householdAddress.zip',
              updatedHouseholdAddressZip
            )
          }
          // Code Changes GPRB0359078 and US2554056
          // COC - Mailing Address Zip Code
          // Add Leading zero to Zip Code
          if (
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.mailingAddress.zip'
              )
            ).length > 0 &&
            String(
              _.get(
                fetchedFormData,
                'application.householdInfo.mailingAddress.zip'
              )
            ).length < 5
          ) {
            let mailingAddressZip = _.get(
              fetchedFormData,
              'application.householdInfo.mailingAddress.zip'
            )
            let updatedMailingAddressZip = mailingAddressZip.padStart(5, '0')
            _.set(
              fetchedFormData,
              'application.householdInfo.mailingAddress.zip',
              updatedMailingAddressZip
            )
          }

          relationShip.forEach(relation => {
            if (relation.relClientName != undefined) {
              relation.relClientName =
                clients[relation.relClientName].identifier
            }
          })
          taxInformation.forEach(taxInfo => {
            if (
              taxInfo.relClientName != undefined &&
              taxInfo.relClientName != 'smo'
            ) {
              taxInfo.relClientName = clients[taxInfo.relClientName].identifier
            }
          })
        })

        clients.forEach(client => {
          for (let clientKey of Object.keys(client)) {
            if (_.isObject(client[clientKey])) {
              for (let keys of Object.keys(client[clientKey])) {
                let currentAccordionData = _.get(
                  client,
                  '[' + clientKey + '][' + keys + ']',
                  []
                )
                _.isArray(currentAccordionData) &&
                  currentAccordionData.forEach(data => {
                    data.isNew = false
                  })
              }
            }
          }
        })

        let newFormData = helper.clearActions(fetchedFormData)
        helper.cleanNullValues(newFormData)
        for (let i = 0; i < newFormData.application.clients.length; i++) {
          newFormData.application.clients[i].nbrRegInd =
            json.Application.clients[i].nbrRegInd
          newFormData.application.clients[i].clientFstNm =
            json.Application.clients[i].clientFstNm
          newFormData.application.clients[i].clientLstNm =
            json.Application.clients[i].clientLstNm
        }
        if (caseNumber > 0) {
          this.setState({
            callingApi: false,
            caseId: caseNumber
          })
        }

        newFormData.application.clients.forEach(client => {
          if (client.clientAssets === undefined) {
            client.clientAssets = []
          }
          client.clientAssets = {
            bankingAccounts: client.clientAssets.bankingAccounts,
            cash: client.clientAssets.cash,
            trusts: client.clientAssets.trusts,
            investmentAccounts: client.clientAssets.investmentAccounts,
            lumpsumPayment: client.clientAssets.lumpsumPayment,
            vehicles: client.clientAssets.vehicles,
            soldtransferAsset: client.clientAssets.soldtransferAsset,
            insurancePolicies: client.clientAssets.insurancePolicies,
            otherAssets: client.clientAssets.otherAssets,
            realEstates: client.clientAssets.realEstates
          }
          client.clientIncomes = {
            earnedIncomes: client.clientIncomes.earnedIncomes,
            selfEmploymentIncomes: client.clientIncomes.selfEmploymentIncomes,
            unearnedIncomes: client.clientIncomes.unearnedIncomes
          }
          client.clientExpenses = {
            childSupport: client.clientExpenses.childSupport,
            dependentCareExpenses: client.clientExpenses.dependentCareExpenses,
            heatingExpenses: client.clientExpenses.heatingExpenses,
            coolingExpenses: client.clientExpenses.coolingExpenses,
            utilityExpenses: client.clientExpenses.utilityExpenses,
            shelterExpenses: client.clientExpenses.shelterExpenses,
            deduction: client.clientExpenses.deduction,
            educationExpenses: client.clientExpenses.educationExpenses,
            electricExpenses: client.clientExpenses.electricExpenses,
            gasExpenses: client.clientExpenses.gasExpenses,
            medicalExpenses: client.clientExpenses.medicalExpenses,
            movingExpenses: client.clientExpenses.movingExpenses,
            otherExpenses: client.clientExpenses.otherExpenses,
            selfEmploymentExpenses:
              client.clientExpenses.selfEmploymentExpenses,
            sewerExpenses: client.clientExpenses.sewerExpenses,
            waterExpenses: client.clientExpenses.waterExpenses,
            workExpenses: client.clientExpenses.workExpenses
          }
        })
        const householdAddressCtyNm = _.get(
          newFormData,
          'application.householdInfo.householdAddress.ctyNm'
        )
        const householdAddresszip = _.get(
          newFormData,
          'application.householdInfo.householdAddress.zip'
        )

        _.set(
          newFormData,
          'application.householdInfo.householdAddress.ctyNm1',
          householdAddressCtyNm
        )

        _.set(
          newFormData,
          'application.householdInfo.householdAddress.zip1',
          householdAddresszip
        )
        this.setState(prevState => {
          return {
            formData: newFormData,
            oldFormData: _.cloneDeep(newFormData),
            formContext: {
              ...prevState.formContext,
              formData: newFormData,
              oldClients: newFormData.application.clients.length,
              reviewFormData: _.cloneDeep(newFormData)
            },
            caseInfo,
            hasSpecialNeeds: true
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'ClientUpdateWizard _loadAndInitializeData failed with error:',
          error
        )
      })
  }

  _retrieveRenewalInfo = () => {
    const { caseId } = this.state
    const body = {
      caseNumber: caseId,
      offset: 1,
      limit: 10,
      programCodes: ['SNAP', 'TANF', 'QHP', 'MEDI', 'CHIP', 'CCA'],
      statuses: ['Not Received', 'Incomplete']
    }
    const fetchEndpoint = renewalInfoEndpoint.replace(
      '{caseId}',
      caseId.toString()
    )

    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      },
      method: 'POST',
      body: JSON.stringify(body)
    }

    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      .then(json => {
        if (json && json['programRenewals']) {
          this.setState({
            programRenewals: json['programRenewals']
          })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'ClientUpdateWizard _retrieveRenewalInfo failed at endpoint ' +
            fetchEndpoint +
            ' with error:',
          error
        )
      })
  }

  _onNormalChange = helper.safeDebounce(
    ({ formData }) => {
      this._onFormDataChange(formData, false)
    },
    200,
    this.props.isUnitTest
  )

  _onErrorClearingChange = ({ formData }) => {
    this._onFormDataChange(formData, true)
  }

  _onFormDataChange = (formData: any, errorCleared: boolean) => {
    const { step, tabs } = this.state
    const householdAddressCtyNm = _.get(
      formData,
      'application.householdInfo.householdAddress.ctyNm1'
    )
    const householdAddresszip = _.get(
      formData,
      'application.householdInfo.householdAddress.zip1'
    )

    _.set(
      formData,
      'application.householdInfo.householdAddress.ctyNm',
      householdAddressCtyNm
    )

    _.set(
      formData,
      'application.householdInfo.householdAddress.zip',
      householdAddresszip
    )
    let newFormDataClients = _.cloneDeep(
      _.get(formData, 'application.clients', [])
    )

    //if client hasn't been explicitly marked as old, it is new
    newFormDataClients.forEach(client => {
      if (client.isOld === undefined) {
        client.isOld = false
      }
    })

    let oldClients = 0
    newFormDataClients.forEach(client => {
      if (client.isOld) {
        oldClients++
      }
    })
    initClientsLength = oldClients

    newFormDataClients.forEach(client => {
      if (!client.hasOwnProperty('identifier')) {
        client.identifier = v4()
      }
    })
    newFormDataClients.forEach(client => {
      let releationships = _.get(client, 'clientDetails.relationShip', [])
      releationships = releationships.filter(releationship => {
        return releationship.action !== 'INVALIDATED'
      })
      client.clientDetails.relationShip = releationships
    })

    // //RAPIDS RESTORE LOGIC BEGIN
    //conditionally restore original formData for Change in Address @application_householdInfo_changeInAddress
    //PASS 2
    if (_.get(formData, 'application.householdInfo.changeInAddress') === 'N') {
      _.set(
        formData,
        'application.householdInfo.householdAddress',
        formData.originalApplication.householdInfo.householdAddress
      )
      _.set(
        formData,
        'application.householdInfo.mailingAddress',
        formData.originalApplication.householdInfo.mailingAddress
      )
    }

    //conditionally restore original formData for Information about the people in your household
    //PASS 2
    if (_.get(formData, 'application.liveWithYou') === 'Y') {
      //retain only the old (i.e., original) clients
      const updatedClients = []
      newFormDataClients.forEach(client => {
        if (client.isOld) {
          formData.originalApplication.clients.forEach(originalClient => {
            if (originalClient.identifier === client.identifier) {
              client.clientDetails.relationShip =
                originalClient.clientDetails.relationShip
            }
          })
          updatedClients.push(_.cloneDeep(client))
        }
      })

      //add in original clients that were removed
      formData.originalApplication.clients.forEach(
        (originalClient, originalClientIndex) => {
          if (
            (updatedClients[originalClientIndex] !== undefined &&
              originalClient.identifier !==
                updatedClients[originalClientIndex].identifier) ||
            originalClientIndex === updatedClients.length
          ) {
            updatedClients.splice(
              originalClientIndex,
              0,
              _.cloneDeep(originalClient)
            )
          }
        }
      )

      //force restore personalData[0] of all clients
      updatedClients.forEach((client, index) => {
        client.clientDetails.personalData[0].clientFstNm =
          formData.originalApplication.clients[
            index
          ].clientDetails.personalData[0].clientFstNm
        client.clientDetails.personalData[0].clientMidlNm =
          formData.originalApplication.clients[
            index
          ].clientDetails.personalData[0].clientMidlNm
        client.clientDetails.personalData[0].clientLstNm =
          formData.originalApplication.clients[
            index
          ].clientDetails.personalData[0].clientLstNm
        client.clientDetails.personalData[0].clientBthDt =
          formData.originalApplication.clients[
            index
          ].clientDetails.personalData[0].clientBthDt
        client.clientDetails.personalData[0].clientSsn =
          formData.originalApplication.clients[
            index
          ].clientDetails.personalData[0].clientSsn
        client.clientDetails.personalData[0].gdrCd =
          formData.originalApplication.clients[
            index
          ].clientDetails.personalData[0].gdrCd
        client.clientDetails.personalData[0].mrtlStatusCd =
          formData.originalApplication.clients[
            index
          ].clientDetails.personalData[0].mrtlStatusCd
        client.clientDetails.personalData[0].isNew =
          formData.originalApplication.clients[
            index
          ].clientDetails.personalData[0].isNew
        client.clientDetails.personalData[0].clientId =
          formData.originalApplication.clients[
            index
          ].clientDetails.personalData[0].clientId
      })

      newFormDataClients = _.cloneDeep(updatedClients)
    }

    //conditionally restore original formData for Lottery or Gambling Winnings
    //PASS 2
    if (_.get(formData, 'application.wonMoreThan4250') === 'N') {
      newFormDataClients.forEach(client => {
        client.clientIncomes.lotteryGamblingWinnings = undefined
      })
    }

    //conditionally restore original formData for Household Earned Income
    //PASS 2
    if (
      _.get(formData, 'application.householdIncomeChange') === 'N' &&
      _.get(formData, 'application.individualIncomeChange') === 'N'
    ) {
      newFormDataClients.forEach(client => {
        //clientIncomes.earnedIncomes
        //clientIncomes.selfEmploymentIncomes
        if (!client.isOld) {
          client.clientIncomes.earnedIncomes = undefined
          client.clientIncomes.selfEmploymentIncomes = undefined
        } else {
          formData.originalApplication.clients.forEach(originalClient => {
            if (client.identifier === originalClient.identifier) {
              client.clientIncomes.earnedIncomes =
                originalClient.clientIncomes.earnedIncomes
              client.clientIncomes.selfEmploymentIncomes =
                originalClient.clientIncomes.selfEmploymentIncomes
            }
          })
        }
      })
    }

    //conditionally restore original formData for Household Unearned Income
    //PASS 2
    if (_.get(formData, 'application.householdUnearnedIncomeChange') === 'N') {
      newFormDataClients.forEach(client => {
        //clientIncomes.unearnedIncomes
        if (!client.isOld) {
          client.clientIncomes.unearnedIncomes = undefined
        } else {
          formData.originalApplication.clients.forEach(originalClient => {
            if (client.identifier === originalClient.identifier) {
              client.clientIncomes.unearnedIncomes =
                originalClient.clientIncomes.unearnedIncomes
            }
          })
        }
      })
    }

    //conditionally restore original formData for Child Support Payments
    //PASS 2
    if (_.get(formData, 'application.changeInLegalObligationtoPay') === 'N') {
      newFormDataClients.forEach(client => {
        //clientExpenses.childSupport
        if (!client.isOld) {
          client.clientExpenses.childSupport = undefined
        } else {
          formData.originalApplication.clients.forEach(originalClient => {
            if (client.identifier === originalClient.identifier) {
              client.clientExpenses.childSupport =
                originalClient.clientExpenses.childSupport
            }
          })
        }
      })
    }

    //conditionally restore original formData for Shelter and Utility Cost Changes
    //PASS 2
    if (
      _.get(formData, 'application.changeReason') === '1' ||
      _.get(formData, 'application.changeReason') === '3'
    ) {
      newFormDataClients.forEach(client => {
        //clientExpenses.shelterExpenses
        //clientExpenses.utilityExpenses
        if (!client.isOld) {
          client.clientExpenses.shelterExpenses = undefined
          client.clientExpenses.utilityExpenses = undefined
        } else {
          formData.originalApplication.clients.forEach(originalClient => {
            if (client.identifier === originalClient.identifier) {
              client.clientExpenses.shelterExpenses =
                originalClient.clientExpenses.shelterExpenses

              client.clientExpenses.utilityExpenses =
                originalClient.clientExpenses.utilityExpenses
            }
          })
        }
      })
    }

    _.set(formData, 'application.clients', newFormDataClients)

    // //RAPIDS RESTORE LOGIC END

    this.setState(prevState => {
      return {
        formData,
        formContext: {
          ...prevState.formContext,
          reviewFormData: _.cloneDeep(formData),
          formData,
          hasError: !errorCleared ? false : true,
          STEP: _.cloneDeep(this.state.step),
          oldClients
        },
        liveValidate: errorCleared ? false : prevState.liveValidate
      }
    })

    if (tabs[step - 1].status !== 'editing') {
      this.setState({
        tabs: [
          ...tabs.slice(0, step - 1),
          {
            ...tabs[step - 1],
            status: 'editing',
            rightIcon: editingIcon,
            rightSpan: editingBadge
          },
          ...tabs.slice(step)
        ]
      })
    }
  }

  _focusMainContent = () => {
    const mainContent = document.getElementById('content')
    if (mainContent) {
      mainContent.focus()
    }
  }

  _changeView = (val: number) => {
    let { tabFocus } = this.state
    if (tabFocus) {
      this._focusMainContent()
    }
    let cStep = val + 1
    const { updateMyCocApplicationStep, step } = this.props
    updateMyCocApplicationStep(cStep)
    window.location.href = '#'
    this.setState({ step: val + 1 })
    this.setState(prevState => {
      return {
        ...prevState,
        step: val + 1
      }
    })
    if (this.state.slideIndex < this.state.slides.length - 1) {
      this.setState({
        showNext: false
      })
    }
  }

  onFocus = e => {
    if (e.which === 9 && !this.state.tabFocus) {
      this.setState({ tabFocus: true })
    } else if (e.which === 1 && this.state.tabFocus) {
      this.setState({ tabFocus: false })
    }
  }

  _saveFormDataDraft = (
    showModal: boolean,
    step: number,
    tabStatus: any,
    nonModal: boolean
  ) => {
    const {
      formData,
      oldFormData,
      resolvedSchema,
      programRenewals,
      caseId
    } = this.state
    const { isRenewal } = this.props
    let renewalBody

    let newFormData = _.cloneDeep(formData)

    let updatedTabStatus = this.state.tabs.map(e => {
      return {
        name: e.title,
        status: e.status
      }
    })

    let userName
    if (isRenewal) {
      userName = 'RENEWAL'
      renewalBody = {
        programCode: ['FS P']
      }
    } else {
      userName = 'COC'
    }

    let saveStatusPoint = ''
    if (this.state.status != 'PARTIAL SUBMIT') {
      saveStatusPoint = saveDraftEndpoint
    } else if (nonModal != undefined) {
      this.setState({ showNonDraftModal: nonModal })
    } else {
      this.setState({ showNonDraftModal: true })
    }

    const applId =
      this.state.applId != 'undefined' && this.state.status != 'DELETE'
        ? this.state.applId
        : null
    let benefitPrgmNm =
      this.state.formContext.benefitProgramName != undefined
        ? this.state.formContext.benefitProgramName
        : this.state.benefitProgramName

    let benefitPrgmCode =
      this.state.formContext.benefitProgramCode != undefined
        ? this.state.formContext.benefitProgramCode
        : this.state.formContext.benefitProgramCode

    let programNms =
      this.state.formContext.prgNames != undefined
        ? this.state.formContext.prgNames
        : this.state.prgNames

    let clients = _.cloneDeep(_.get(newFormData, 'application.clients', []))
    let newClients = []
    let clientIdentifiers = []
    clients.forEach(client => {
      clientIdentifiers.push(client.identifier)
    })
    clients.forEach(tempClient => {
      let client = _.cloneDeep(tempClient)
      const relationShipDetails = _.get(
        client,
        'clientDetails.relationShip',
        []
      )
      const taxInfoDetails = _.get(client, 'clientDetails.taxInformation', [])
      relationShipDetails.forEach(relationShip => {
        if (relationShip.relClientName) {
          relationShip.relClientName = _.findIndex(clientIdentifiers, function(
            identifier
          ) {
            return relationShip.relClientName == identifier
          })
        }
      })
      taxInfoDetails.forEach(taxInfo => {
        if (taxInfo.relClientName && taxInfo.relClientName != 'smo') {
          taxInfo.relClientName = _.findIndex(clientIdentifiers, function(
            identifier
          ) {
            return taxInfo.relClientName == identifier
          })
        }
      })
      newClients.push(client)
    })

    _.set(newFormData, 'application.clients', newClients)

    let exemptionIndividualDetails = _.cloneDeep(
      _.get(newFormData, 'application.listIndividuals', [])
    )
    let newExemptionIndividualDetails = []

    exemptionIndividualDetails.forEach(tempExemptionIndividual => {
      let newExemptIndividual = {
        name: tempExemptionIndividual.name,
        dob: tempExemptionIndividual.dob,
        dateDecreasedBelow20: tempExemptionIndividual.dateDecreasedBelow20
      }
      newExemptionIndividualDetails.push(newExemptIndividual)
    })

    _.set(
      newFormData,
      'application.listIndividuals',
      newExemptionIndividualDetails
    )

    //map earnedIncomes[i].sourceOfIncome to earnedIncomes[i].incomeSource.incomeSourceName
    if (newFormData.application.clients.length > 0) {
      newFormData.application.clients.forEach(client => {
        if (
          client.clientIncomes &&
          client.clientIncomes.earnedIncomes &&
          client.clientIncomes.earnedIncomes.length > 0
        ) {
          client.clientIncomes.earnedIncomes.forEach(earnedIncome => {
            if (earnedIncome && earnedIncome.sourceOfIncome) {
              earnedIncome['incomeSource'] = {}
              earnedIncome['incomeSource']['incomeSourceName'] = _.cloneDeep(
                earnedIncome.sourceOfIncome
              )
              delete earnedIncome.sourceOfIncome
            }
          })
        }

        // if (
        //   client.clientIncomes &&
        //   client.clientIncomes.unearnedIncomes &&
        //   client.clientIncomes.unearnedIncomes.length > 0
        // ) {
        //   client.clientIncomes.unearnedIncomes.forEach(unearnedIncome => {
        //     if (unearnedIncome && unearnedIncome.sourceOfIncome) {
        //       unearnedIncome['incomeSource'] = {}
        //       unearnedIncome['incomeSource']['incomeSourceName'] = _.cloneDeep(
        //         unearnedIncome.sourceOfIncome
        //       )
        //       delete unearnedIncome.sourceOfIncome
        //     }
        //   })
        // }
      })
    }

    let body: any = {
      caseNumber: this.state.caseId,
      renewalDate: this.state.renewalDate
        ? this.state.renewalDate
        : this.state.rnwlDate,
      step: this.state.step,
      applicationType: userName,
      applId: applId,
      Application: {
        applId: applId,
        tenantId: 'WV',
        someoneAsstYouFlg: _.get(newFormData, 'application.someoneAsstYouFlg'),
        CONTRIBUTOR: _.get(
          newFormData,
          'application.authorizedRepresentative',
          {}
        ),
        clients: _.get(newFormData, 'application.clients', []),
        APPLYFROM: _.get(newFormData, 'application.APPLYFROM'),
        EBT: _.get(newFormData, 'application.EBT'),
        SPECIALNEEDS: _.get(newFormData, 'application.SPECIALNEEDS'),
        householdRequestForADA: _.get(
          newFormData,
          'application.householdRequestForADA'
        ),
        prgNames: 'SNAP PRC2',
        benefitProgramName: benefitPrgmNm ? benefitPrgmNm : programNms,
        householdInfo: _.get(newFormData, 'application.householdInfo'),
        programCode: ['FS P'],
        lotterywon: _.get(newFormData, 'application.wonMoreThan4250'),
        householdIncomeChange: _.get(
          newFormData,
          'application.householdIncomeChange'
        ),
        individualIncomeChange: _.get(
          newFormData,
          'application.individualIncomeChange'
        ),
        householdUnearnedIncomeChange: _.get(
          newFormData,
          'application.householdUnearnedIncomeChange'
        ),
        changeInLegalObligationtoPay: _.get(
          newFormData,
          'application.changeInLegalObligationtoPay'
        ),
        totalAmount: _.get(newFormData, 'application.totalAmount'),
        householdUnearedIncomeUsed: _.get(
          newFormData,
          'application.householdUnearedIncomeUsed'
        ),
        householdIncomeUsed: _.get(
          newFormData,
          'application.householdIncomeUsed'
        ),
        changeReason: _.get(newFormData, 'application.changeReason'),
        liveWithYou: _.get(newFormData, 'application.liveWithYou'),
        adultWithoutDependents: {
          personUnder18: _.get(newFormData, 'application.underAgeEighteen'),
          meetingExemption: _.get(
            newFormData,
            'application.allAdultsMeetExemptions'
          ),
          workHoursBelow20: _.get(
            newFormData,
            'application.droppedBelowTwentyHours'
          ),
          exemptionIndividualDetails: _.get(
            newFormData,
            'application.listIndividuals',
            []
          )
        }
      },
      OriginalApplication: _.get(newFormData, 'originalApplication'),
      tabStatus: updatedTabStatus
    }
    if (
      isRenewal &&
      this.state.allPrograms &&
      _.isArray(this.state.allPrograms)
    ) {
      for (let i = 0; i < this.state.allPrograms.length; i++) {
        let currentProgram = this.state.allPrograms[i]
        if (
          currentProgram === 'Health Care Benefits' ||
          this.state.prgNames.search('Health Care Benefits') > 0
        ) {
          body.Application.reneCovInd = this.state.reneCovInd
        }
      }
    }
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        orgId: this.props.orgId,
        userRoleId: this.props.userRoleId,
        roleId: this.props.roleId,
        applType: userName,
        userId: this.state.formContext.clientUserId,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        ieOrgId: this.props.ieOrgId
      },
      body: JSON.stringify(body)
    }
    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(saveStatusPoint, request, this.props.showErrorMessage)
      .then(response => {
        let applId = response.applId
        this.setState({
          callingApi: false,
          showDraftModal: showModal,
          applId: applId,
          status: 'DRAFT'
        })
        if (isRenewal && this.state.applId != undefined) {
          const { reviewIndicator } = this.state
          const fetchEndpoint = loadRenewalpoint

          serviceHelper.fetchJson(
            fetchEndpoint,
            {
              headers: {
                'Content-Type': 'application/json',
                applId: applId,
                status: 'DRAFT',
                uuid: this.props.auth.userAccount.uuid,
                caseNumber: caseId,
                org_Id: this.props.orgId,
                role_id: this.props.roleId,
                tenantCode: config.tCode,
                Authorization: config.bearer + this.props.auth.accessToken,
                portalName: config.portalName,
                userRoleId: this.props.userRoleId,
                roleId: this.props.roleId,
                orgId: this.props.roleId
              },
              method: 'POST',
              body: JSON.stringify(renewalBody)
            },
            this.props.showErrorMessage
          )
        } else {
          if (
            this.state.status != 'PARTIAL SUBMIT' &&
            this.state.applId != undefined
          ) {
            const fetchEndpoint = loadEndpoint.replace(
              '{caseId}',
              caseId.toString()
            )
            serviceHelper.fetchJson(
              fetchEndpoint,
              {
                headers: {
                  uuid: this.props.auth.userAccount.uuid,
                  'Content-Type': 'application/json',
                  caseNumber: this.state.caseId,
                  status: 'DRAFT',
                  applId: this.state.applId,
                  org_id: null,
                  role_id: this.props.userRoleId,
                  tenantCode: config.tCode,
                  Authorization: config.bearer + this.props.auth.accessToken,
                  portalName: config.portalName,
                  userRoleId: this.props.userRoleId
                },
                method: 'POST'
              },
              this.props.showErrorMessage
            )
          }
        }
        if (applId && this.props.activeApplicationId === '0') {
          let newFormData = {
            Application: {
              applId: applId
            }
          }
          this.props.assignApplicationId(applId)
          this.props.setActiveApplicationId(applId)
          this.props.updateMyApplication(applId, 'DRAFT', newFormData)
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
        console.error('_saveFormDataDraft failed with error:', error)
      })
  }

  _onNext = () => {
    window.location.href = '#'
    let nextStep
    let progNames = this.state.prgNames
    const { updateMyCocApplicationStep, isRenewal } = this.props
    const { step, forms } = this.state
    nextStep = this._getStep(step, 1, forms)

    let newApplicantlength =
      this.state &&
      this.state.formData &&
      this.state.formData.application &&
      this.state.formData.application.clients &&
      this.state.formData.application.clients.length
    let oldApplicantLength =
      this.state &&
      this.state.oldFormData &&
      this.state.oldFormData.application &&
      this.state.oldFormData.application.clients &&
      this.state.oldFormData.application.clients.length

    if (newApplicantlength > oldApplicantLength) {
      addedNewApplicant = true
      newApplicantIndex = oldApplicantLength
      let containerId = document.getElementById(
        'LifeEventsWizardPersonal_application_clients_' +
          newApplicantIndex +
          '_clientDetails_personalData_notes'
      )
      if (containerId) {
        containerId.innerHTML = ''
      }
    } else {
      addedNewApplicant = false
    }

    const nextShowNext = nextStep > 1
    updateMyCocApplicationStep(nextStep)
    this.setState({
      step: nextStep,
      showNext: nextShowNext,
      formContext: {
        ...this.state.formContext,
        isRenewal: isRenewal
      }
    })
    if (this.state.status != 'PARTIAL SUBMIT') {
      this.props.updatedFormData(this.state.formContext)
      this._saveFormDataDraft(false, config, config, false)
    }
    const content = document.getElementById('content')
    if (content) {
      window.scroll(0, content.offsetTop)
      content.focus()
    }
  }

  _getStep(step, stepDelta, forms) {
    if (forms[step + stepDelta - 1]) {
      const stepTabId = forms[
        step + stepDelta - 1
      ].uiSchema.externalOptions.tab.tabName
        .split(' ')
        .join('')

      if (document.getElementById(stepTabId)) {
        return step + stepDelta
      } else {
        return this._getStep(step + stepDelta, stepDelta, forms)
      }
    }
  }

  _setShowNext = next => {
    this.setState({ showNext: next })
  }

  _onNextToDestination = (destinationIndex: number) => {
    window.location.href = '#'
    this.setState({ step: destinationIndex })
  }

  _onPrevious = () => {
    window.location.href = '#'
    let prevStep
    let progNames = this.state.prgNames
    const { step, forms } = this.state
    const { updateMyCocApplicationStep } = this.props
    prevStep = this._getStep(step, -1, forms)

    updateMyCocApplicationStep(prevStep)
    // check if step is already at the beginning
    if (step === 2) {
      this.setState(prevState => {
        return {
          ...prevState
        }
      })
    }
    this.setState({ step: prevStep })

    const content = document.getElementById('content')
    if (content) {
      window.scroll(0, content.offsetTop)
      content.focus()
    }
    if (this.state.slideIndex < this.state.slides.length - 1) {
      this.setState({
        showNext: false
      })
    }
  }

  _onSubmit = () => {
    const { step, tabs, forms, liveValidate } = this.state
    let newTabs = helper.deepClone(this.state.tabs)
    if (step === forms.length) {
      for (let i = 0; i < forms.length; ++i) {
        newTabs[i].status = 'validated'
        newTabs[i].rightIcon = validatedIcon
        newTabs[i].rightSpan = validatedBadge
      }
      this.setState(
        {
          liveValidate: false,
          tabs: newTabs
        },
        () => this._submitFormData()
      )
    } else {
      newTabs[step - 1].status = 'validated'
      newTabs[step - 1].rightIcon = validatedIcon
      newTabs[step - 1].rightSpan = validatedBadge

      this.setState(
        {
          liveValidate: false,
          tabs: newTabs
        },
        () => {
          this._onNext()
        }
      )
    }
  }

  _submitFormData = () => {
    const {
      formData,
      oldFormData,
      resolvedSchema,
      programRenewals,
      caseId,
      renewalDate,
      formContext,
      programCode
    } = this.state
    const { isRenewal, updateMyCocApplicationStep } = this.props
    const { reviewIndicator } = this.state
    let today = new Date(getServerTime())
    let submissionDate = today.toUTCString()

    if (
      _.get(formData, 'application.agreement', false) === true &&
      _.get(formData, 'application.authorizedRepresentativeSignature', '')
        .length > 0 &&
      _.get(formData, 'application.dobOfThePersonEsign', '').length > 0
    ) {
      if (isRenewal) {
      } else {
        //TODO: What review & sign data to send on a COC?
        let clients = formData.application.clients
        for (let i = 0; i < clients.length; i++) {
          if (
            formData.application.clients[i].clientAdditionalInformation ===
            undefined
          ) {
            formData.application.clients[i].clientAdditionalInformation = {}
          }
          if (formData.application.clients[i].childCare) {
            formData.application.clients[
              i
            ].clientAdditionalInformation.childCare = _.cloneDeep(
              formData.application.clients[i].childCare
            )
          }

          if (formData.application.clients[i].childCare) {
            formData.application.clients[i].childCare = _.noop()
          }
        }
      }
    } else {
      alert('Please fill out the Review & Sign section!')
      return
    }
    const newFormData = _.cloneDeep(formData)

    let userName
    if (isRenewal) {
      userName = 'RENEWAL'
    } else {
      userName = 'COC'
    }
    helper.stampUserInfo(
      oldFormData,
      newFormData,
      userName,
      null,
      resolvedSchema,
      config
    )
    let clients = _.get(newFormData, 'application.clients', [])
    _.set(
      newFormData,
      'application.householdInfo.previousBenefitInfo.movedSt',
      undefined
    )
    if (isRenewal) {
      let HEWL = _.get(this.state, 'formContext.HEWL'),
        LIEAP = _.get(this.state, 'formContext.LIEAP'),
        FDNT = _.get(this.state, 'formContext.FDNT'),
        SCA = _.get(this.state, 'formContext.SCA'),
        CHCR = _.get(this.state, 'formContext.CHCR'),
        CHSP = _.get(this.state, 'formContext.CHSP'),
        MDPA = _.get(this.state, 'formContext.MDPA')
      clients.forEach(client => {
        if (!HEWL) {
          _.set(client, 'clientDetails.taxInformation', [])
        }
        if (!(SCA || HEWL || FDNT)) {
          _.set(client, 'clientAdditionalInformation.education', [])
        }
        if (!(FDNT || CHCR || HEWL)) {
          _.set(client, 'clientAdditionalInformation.disability', [])
        }
        let residenceInformation = _.get(
          client,
          'clientDetails.residenceInformation',
          []
        )
        residenceInformation.forEach(resident => {
          if (!(FDNT || SCA || HEWL || MDPA))
            resident.clientRemInStIntnFlg = undefined
        })
      })
    }
    let programNms =
      this.state.formContext.prgNames != undefined
        ? this.state.formContext.prgNames
        : this.state.prgNames

    let newClients = []
    let clientIdentifiers = []
    clients.forEach(client => {
      clientIdentifiers.push(client.identifier)
    })
    clients.forEach(tempClient => {
      let client = _.cloneDeep(tempClient)
      const relationShipDetails = _.get(
        client,
        'clientDetails.relationShip',
        []
      )
      const taxInfoDetails = _.get(client, 'clientDetails.taxInformation', [])
      relationShipDetails.forEach(relationShip => {
        if (relationShip.relClientName) {
          relationShip.relClientName = _.findIndex(clientIdentifiers, function(
            identifier
          ) {
            return relationShip.relClientName == identifier
          })
        }
      })
      taxInfoDetails.forEach(taxInfo => {
        if (taxInfo.relClientName && taxInfo.relClientName != 'smo') {
          taxInfo.relClientName = _.findIndex(clientIdentifiers, function(
            identifier
          ) {
            return taxInfo.relClientName == identifier
          })
        }
      })
      newClients.push(client)
    })

    _.set(newFormData, 'application.clients', newClients)

    //map earnedIncomes[i].sourceOfIncome to earnedIncomes[i].incomeSource.incomeSourceName
    //set Application.clients[n].clientDetails.personalData[0].prog[0] to "FS P"
    newFormData.application.clients.forEach(client => {
      if (
        client.clientIncomes &&
        client.clientIncomes.earnedIncomes &&
        client.clientIncomes.earnedIncomes.length > 0
      ) {
        client.clientIncomes.earnedIncomes.forEach(earnedIncome => {
          if (earnedIncome && earnedIncome.sourceOfIncome) {
            earnedIncome['incomeSource'] = {}
            earnedIncome['incomeSource']['incomeSourceName'] = _.cloneDeep(
              earnedIncome.sourceOfIncome
            )
            delete earnedIncome.sourceOfIncome
          }
        })
      }

      if (
        client.clientDetails &&
        client.clientDetails.personalData &&
        client.clientDetails.personalData[0]
      ) {
        client.clientDetails.personalData[0].prog = ['FS P']
      }

      // if (
      //   client.clientIncomes &&
      //   client.clientIncomes.unearnedIncomes &&
      //   client.clientIncomes.unearnedIncomes.length > 0
      // ) {
      //   client.clientIncomes.unearnedIncomes.forEach(unearnedIncome => {
      //     if (unearnedIncome && unearnedIncome.sourceOfIncome) {
      //       unearnedIncome['incomeSource'] = {}
      //       unearnedIncome['incomeSource']['incomeSourceName'] = _.cloneDeep(
      //         unearnedIncome.sourceOfIncome
      //       )
      //       delete unearnedIncome.sourceOfIncome
      //     }
      //   })
      // }
    })

    if (
      newFormData.application.listIndividuals &&
      newFormData.application.listIndividuals.length > 0
    ) {
      newFormData.application.listIndividuals.forEach(listIndividual => {
        if (listIndividual.name) {
          newFormData.application.clients.forEach(client => {
            if (client.identifier === listIndividual.name) {
              listIndividual.name =
                _.cloneDeep(client.clientDetails.personalData[0].clientFstNm) +
                ' ' +
                _.cloneDeep(client.clientDetails.personalData[0].clientLstNm)
            }
          })
        }
      })
    }

    let body: any = {
      submissionDate: submissionDate,
      reviewIndicator: reviewIndicator,
      benefitProgramName: ['SNAP PRC2'],
      caseNumber: this.state.caseId,
      renewalDate: this.state.renewalDate
        ? this.state.renewalDate
        : this.state.rnwlDate,
      applId: this.state.applId,
      uuid: this.props.auth.userAccount.uuid,
      role_id: this.props.roleId,
      user_role_id: this.props.userRoleId,
      org_id: this.props.orgId,
      userId: formContext.clientUserId,
      tenantId: formContext.tenantId,
      applType: userName,
      STATUS: 'SUBMITTED',
      Application: {
        CONTRIBUTOR: _.get(
          newFormData,
          'application.authorizedRepresentative',
          {}
        ),
        someoneAsstYouFlg: _.get(newFormData, 'application.someoneAsstYouFlg'),
        clients: _.get(newFormData, 'application.clients', []),
        APPLYFROM: _.get(newFormData, 'application.APPLYFROM'),
        EBT: _.get(newFormData, 'application.EBT'),
        SPECIALNEEDS: _.get(newFormData, 'application.SPECIALNEEDS'),
        householdRequestForADA: _.get(
          newFormData,
          'application.householdRequestForADA'
        ),
        householdInfo: _.get(newFormData, 'application.householdInfo'),
        signatureReceived: _.get(
          newFormData,
          'application.authorizedRepresentativeSignature'
        ),
        signatureEmail: _.get(newFormData, 'application.email'),
        signatureTelephone: _.get(newFormData, 'application.telephone'),
        liveWithYou: _.get(newFormData, 'application.liveWithYou'),
        programCode: ['FS P'],
        lotterywon: _.get(newFormData, 'application.wonMoreThan4250'),
        householdIncomeChange: _.get(
          newFormData,
          'application.householdIncomeChange'
        ),
        individualIncomeChange: _.get(
          newFormData,
          'application.individualIncomeChange'
        ),
        householdUnearnedIncomeChange: _.get(
          newFormData,
          'application.householdUnearnedIncomeChange'
        ),
        totalAmount: _.get(newFormData, 'application.totalAmount'),
        householdUnearedIncomeUsed: _.get(
          newFormData,
          'application.householdUnearedIncomeUsed'
        ),
        householdIncomeUsed: _.get(
          newFormData,
          'application.householdIncomeUsed'
        ),
        changeInLegalObligationtoPay: _.get(
          newFormData,
          'application.changeInLegalObligationtoPay'
        ),
        changeReason: _.get(newFormData, 'application.changeReason'),
        adultWithoutDependents: {
          personUnder18: _.get(newFormData, 'application.underAgeEighteen'),
          meetingExemption: _.get(
            newFormData,
            'application.allAdultsMeetExemptions'
          ),
          workHoursBelow20: _.get(
            newFormData,
            'application.droppedBelowTwentyHours'
          ),
          exemptionIndividualDetails: _.get(
            newFormData,
            'application.listIndividuals',
            []
          )
        }
      }
    }
    if (
      isRenewal &&
      this.state.allPrograms &&
      _.isArray(this.state.allPrograms)
    ) {
      for (let i = 0; i < this.state.allPrograms.length; i++) {
        let currentProgram = this.state.allPrograms[i]
        if (
          currentProgram === 'Health Care Benefits' ||
          this.state.prgNames.search('Health Care Benefits') > 0
        ) {
          body.Application.reneCovInd = this.state.reneCovInd
        }
      }
    }

    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        ieOrgId: this.props.ieOrgId
      },
      body: JSON.stringify(body)
    }
    const fetchEndpoint = isRenewal ? submitRenewalEndpoint : submitCocEndpoint

    this.setState({ callingApi: true })
    serviceHelper
      .fetchJson(fetchEndpoint, request, this.props.showErrorMessage)
      //Your changes were submitted. Contact your agency worker with questions. Thank you
      .then(response => {
        updateMyCocApplicationStep(1)
        if (response && response.error) {
          this.setState({ callingApi: false })
        } else {
          this.setState({
            callingApi: false,
            modalProps: {
              closeBtnText: null,
              confirmBtnText: null,
              show: true,
              bsSize: 'lg',
              className: 'lg-width-to-md',
              cancel: null,
              confirm: () => {
                this.setState({
                  redirect:
                    baseUrl +
                    (isRenewal
                      ? '/myaccount/benefits'
                      : '/myaccount/lifeevents')
                })
              },
              title: isRenewal ? (
                <h4 className="popup-title">Confirmation</h4>
              ) : (
                <h4 className="popup-title">
                  Your Change Report is being processed
                </h4>
              ),
              body: isRenewal ? (
                <h6 className="headertxt">
                  Your renewal has been submitted. Your worker will follow-up if
                  additional information is necessary. Thank you.
                </h6>
              ) : (
                <h6 className="headertxt">
                  You have successfully completed your Change Report. If you
                  have selected to receive electronic notifications, you will be
                  notified through My Messages of the status of your Change
                  Report.
                </h6>
              )
            }
          })
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ callingApi: false })
      })
  }

  _getTrackerFormsfromstep = step => {
    switch (step) {
      case 1:
        return 'RenewalWizardInfo'
      case 2:
        return 'RenewalWizardAddress'
      case 3:
        return 'RenewalWizardPersonal'
      case 4:
        return 'RenewalWizardLotteryGambling'
      case 5:
        return 'RenewalWizardEarnedIncome'
      case 6:
        return 'RenewalWizardUnearnedIncome'
      case 7:
        return 'RenewalWizardChildSupportPayments'
      case 8:
        return 'RenewalWizardShelterUtility'
      case 9:
        return 'RenewalWizardABAWD'
      case 10:
        return 'RenewalWizardReview'
    }
  }

  _onValidate = (formData: object, errors: object) => {
    const { tabs } = this.state
    let trackerForms = this.state.trackerForms
    if (this.state.step != 7) {
      const trackkeys = Object.keys(trackerForms)
      const rootid = this._getTrackerFormsfromstep(this.state.step)
      const rootIdKey = trackkeys.filter(key => {
        return key.includes(rootid)
      })
      trackerForms = _.pick(trackerForms, rootIdKey)
    }
    const newErrors = _.cloneDeep(errors)

    if (
      this.state.step === 1 ||
      this.state.step === 2 ||
      this.state.step === 3 ||
      this.state.step === 4 ||
      this.state.step === 5 ||
      this.state.step === 6 ||
      this.state.step === 7 ||
      this.state.step === 8 ||
      this.state.step === 9
    ) {
      validation.validate_renewalPRC2(formData, newErrors, this.state.step)
    }
    if (this.state.step !== 1) {
      //Call the PublicTrackerForm components' local validation since the top level does not have the appropriate context.
      _.forEach(trackerForms, (trackerForm, fieldId) => {
        const trackerFormErrors = [
          ...trackerForm.onValidateChanged([customValidationErrors]),
          ...trackerForm.onValidateAdded([customValidationErrors])
        ]
        _.forEach(trackerFormErrors, formError => {
          const path = helper.idSchemaToPath(fieldId) + formError.property
          const arrayPath = path
            .split(new RegExp('\\.|\\[|\\]', 'g'))
            .filter(e => {
              return !_.isEmpty(e)
            })

          const step = getStepBySection(path) || this.state.step

          const accordianName =
            _.startCase(arrayPath[arrayPath.length - 4]) + ': '
          const formErrorStack = formError.stack.split(/-(.+)/)
          let errorMessages =
            formErrorStack[0] + ' - ' + accordianName + formErrorStack[1]

          if (formErrorStack[1] === undefined) {
            errorMessages =
              formErrorStack[0] + ' - ' + accordianName.replace(': ', '')
          }

          let errorString = validator.createErrorJsonString(
            formError.message,
            step,
            _.join(arrayPath, '_'),
            errorMessages
          )

          if (_.includes(errorString, 'Relation Ship')) {
            errorString = _.replace(
              errorString,
              'Relation Ship',
              'Relationships'
            )
          }

          if (_.includes(errorString, 'Citizenship Immigration')) {
            errorString = _.replace(
              errorString,
              'Citizenship Immigration',
              'Citizenship/Immigration'
            )
          }

          if (_.includes(errorString, 'Shelter Expenses')) {
            errorString = _.replace(
              errorString,
              'Shelter Expenses',
              'Shelter Cost'
            )
          }

          if (_.includes(errorString, 'Utility Expenses')) {
            errorString = _.replace(
              errorString,
              'Utility Expenses',
              'Utility Cost'
            )
          }

          if (_.includes(errorString, 'Earned Incomes')) {
            errorString = _.replace(
              errorString,
              'Earned Incomes',
              'Earned Income'
            )
          }
          // Code Changes GPRB0359078 and US2554056
          if (_.includes(errorString, '- 0: Employer Address:')) {
            errorString = _.replace(
              errorString,
              '- 0: Employer Address:',
              '- Employer Address: '
            )
          }

          if (_.includes(errorString, '- 1: Employer Address:')) {
            errorString = _.replace(
              errorString,
              '- 1: Employer Address:',
              '- Employer Address: '
            )
          }

          if (_.includes(errorString, 'Self Employment Incomes')) {
            errorString = _.replace(
              errorString,
              'Self Employment Incomes',
              'Self-Employment Income'
            )
          }

          if (_.includes(errorString, 'Unearned Incomes')) {
            errorString = _.replace(
              errorString,
              'Unearned Incomes',
              'Unearned Income'
            )
          }

          if (_.includes(errorString, 'Soldtransfer Asset')) {
            errorString = _.replace(
              errorString,
              'Soldtransfer Asset',
              'Sold or Transferred Asset'
            )
          }

          if (_.includes(errorString, 'Health Insurance')) {
            errorString = _.replace(
              errorString,
              'Health Insurance',
              'Health Insurance Policy'
            )
          }

          if (_.includes(errorString, 'Heating Expenses')) {
            errorString = _.replace(
              errorString,
              'Heating Expenses',
              'Heating Information'
            )
          }

          if (_.includes(errorString, 'Cooling Expenses')) {
            errorString = _.replace(
              errorString,
              'Cooling Expenses',
              'Cooling Information'
            )
          }

          if (_.includes(errorString, 'Trusts')) {
            errorString = _.replace(errorString, 'Trusts', 'Trust')
          }

          if (_.includes(errorString, 'Investment Accounts')) {
            errorString = _.replace(
              errorString,
              'Investment Accounts',
              'Liquid Assets'
            )
          }

          if (_.includes(errorString, 'Lumpsum Payment')) {
            errorString = _.replace(
              errorString,
              'Lumpsum Payment',
              'Lump Sum Payment'
            )
          }

          if (_.includes(path, 'earnedIncomes')) {
            errorString = _.replace(
              errorString,
              'Tracker Change',
              'Earned Income'
            )
          }

          // Code Changes GPRB0359078 and US2554056
          if (_.includes(path, 'utilityExpenses')) {
            errorString = _.replace(
              errorString,
              'Tracker Change',
              'Utility Cost'
            )
          }

          if (_.includes(path, 'shelterExpenses')) {
            errorString = _.replace(
              errorString,
              'Tracker Change',
              'Shelter Cost'
            )
          }

          if (_.includes(path, 'healthInsurance')) {
            errorString = _.replace(
              errorString,
              'Tracker Change',
              'Health Insurance Policy Address'
            )
          }

          if (_.includes(path, 'dependentCareExpenses')) {
            errorString = _.replace(
              errorString,
              'Tracker Change',
              'Dependent Care'
            )
          }

          if (_.includes(errorString, 'Other Assets')) {
            errorString = _.replace(errorString, 'Other Assets', 'Other')
          }

          const existingErrors = _.get(
            newErrors,
            _.join(arrayPath, '.') + '.__errors',
            []
          )

          _.set(newErrors, _.join(arrayPath, '.') + '.__errors', [
            ...existingErrors,
            errorString
          ])
        })
      })
    }
    //Determine which tabs contain errors and update their statuses.
    let foundErrors = helper.findErrors(newErrors)
    let updatedTabs = false
    if (foundErrors && foundErrors.length > 0) {
      this.hasError = true
      let newTabs = [...tabs]
      for (let i = 0; i < foundErrors.length; ++i) {
        try {
          let errorObject = JSON.parse(foundErrors[i])
          let errorStep = errorObject.step - 1
          if (newTabs[errorStep].status !== 'error') {
            newTabs[errorStep].status = 'error'
            newTabs[errorStep].rightIcon = errorIcon
            newTabs[errorStep].rightSpan = errorBadge
            updatedTabs = true
          }
        } catch (err) {
          console.error('Error on parsing errors', err)
        }
      }
    } else {
      this.hasError = false
    }

    return newErrors
  }

  orderErrors = (errors, masterSchema, uiSchema): any[] => {
    const orderArray = _.uniq(this.buildOrderArray(masterSchema))

    const indexRegex = new RegExp(/_([0-9]+)_/)
    const errorKeyMsgRegex = new RegExp(/^([a-zA-Z0-9]+): (.*)/)

    const newErrorStackQueue = []
    errors.map(error => {
      const errorResult = error.stack.match(errorKeyMsgRegex)
      const errorKey = errorResult[1]
      let errorContentsObj
      try {
        errorContentsObj = JSON.parse(errorResult[2])
      } catch (err) {
        errorContentsObj = {}
      }

      const index = _.get(
        _.get(errorContentsObj, 'id', '').match(indexRegex),
        '1',
        '0'
      )

      if (newErrorStackQueue[index] === undefined) {
        for (let i = 0; i <= index; i++) {
          if (newErrorStackQueue[i] === undefined) {
            newErrorStackQueue.push([])
          }
        }
      }

      newErrorStackQueue[index].push({
        index: _.indexOf(orderArray, errorKey),
        value: errorKey,
        stack: error.stack
      })
    })

    let tempErrorObjList = []
    for (let i = 0; i < newErrorStackQueue.length; i++) {
      tempErrorObjList = _.concat(
        tempErrorObjList,
        _.sortBy(newErrorStackQueue[i], ['index'])
      )
    }

    const orderedErrors = _.forEach(tempErrorObjList, obj => obj.stack)

    return orderedErrors
  }

  buildOrderArray = (localSchema, orderArray = []) => {
    if (localSchema) {
      switch (localSchema['type']) {
        case 'object':
          this.buildOrderArray(localSchema['properties'], orderArray)
          break
        case 'array':
          this.buildOrderArray(localSchema['items'], orderArray)
          break
        case undefined:
          if (_.isObject(localSchema)) {
            _.forEach(localSchema, (v, k) => {
              switch (v['type']) {
                case 'object':
                  if (
                    _.isEmpty(
                      _.xor(_.keys(v['properties']), [
                        'category',
                        'subCategory',
                        'languageCode',
                        'key',
                        'value',
                        'rulesEngineCode',
                        'sortOrder'
                      ])
                    )
                  ) {
                    orderArray.push(k)
                    break
                  }
                  this.buildOrderArray(v['properties'], orderArray)
                  break
                case 'array':
                  if (
                    _.isEmpty(
                      _.xor(_.keys(_.get(v, 'items.properties')), [
                        'category',
                        'subCategory',
                        'languageCode',
                        'key',
                        'value',
                        'rulesEngineCode',
                        'sortOrder'
                      ])
                    ) ||
                    _.get(v, 'items.enumNames', []).length > 0
                  ) {
                    orderArray.push(k)
                    break
                  }
                  this.buildOrderArray(v['items'], orderArray)
                  break
                default:
                  orderArray.push(k)
              }
            })
          } else {
            console.log('buildOrderArray localSchema not object', localSchema)
          }
      }
    }
    return orderArray
  }

  _errorListTemplate = (props: any) => {
    const { errors, schema, uiSchema } = props

    const masterSchema = helper.resolveSchemaDefinitions(
      schema,
      schema.definitions
    )
    let orderedErrors
    if (this.state.step == 1 || this.state.step == 3) {
      orderedErrors = this.orderErrors(
        _.cloneDeep(errors),
        masterSchema,
        uiSchema
      )
    } else {
      orderedErrors = _.cloneDeep(errors)
    }

    return (
      <div className="panel panel-danger errors lifevent-errors" tabIndex={-1}>
        <div className="panel-heading">
          <span className="panel-title">Errors</span>
        </div>
        <ul className="list-group">
          {orderedErrors.map((error, i) => {
            //The errors are represented as JSON in string format, need to substring out and parse the JSON.
            let errorObject = null

            try {
              errorObject = JSON.parse(
                error.stack.substring(error.stack.indexOf(':') + 1)
              )
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'earnedIncomes')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Earned Income'
                )
              }
              // Code Changes GPRB0359078 and US2554056
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'dependentCareExpenses')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Dependent Care'
                )
              }
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'utilityExpenses')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Utility Cost'
                )
              }
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'shelterExpenses')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Shelter Cost'
                )
              }
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'healthInsurance')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Health Insurance Policy Address'
                )
              }
              if (
                _.includes(errorObject.displayMessage, 'Tracker Add') &&
                _.includes(errorObject.id, 'dependent_')
              ) {
                errorObject.displayMessage = _.replace(
                  errorObject.displayMessage,
                  'Tracker Add',
                  'Dependent Care'
                )
              }
            } catch (e) {
              console.error('Error on parsing errors', e, error.stack)
            }

            //Display the list of errors with a click event that handles the clicked object.
            return (
              <li className="list-group-item text-danger" key={i}>
                <a
                  href="#"
                  onClick={
                    errorObject ? () => this._onErrorClick(errorObject) : null
                  }
                >
                  {errorObject
                    ? errorObject.displayMessage
                    : error.stack.substring(error.stack.indexOf(':') + 1)}
                  <i
                    style={{ marginLeft: '5px' }}
                    className="fa fa-eye"
                    aria-hidden="true"
                  />
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  _onUpdateCarouselSlides = (slideIndex, slides) => {
    let oldSlidesLength = this.state.slides.length
    let newSlidesLength = slides.length
    let showNext = this.state.showNext
    let oldSlideIndex = this.state.slideIndex
    const { formContext } = this.state
    const { formData } = formContext
    if (
      newSlidesLength > 0 &&
      oldSlidesLength !== newSlidesLength &&
      this.props.step === 1 &&
      Object.keys(formData).length > 0
    ) {
      this.setState({ slideIndex, slides: _.cloneDeep(slides) })
    }
    if (
      oldSlideIndex !== slideIndex &&
      this.props.step === 1 &&
      Object.keys(formData).length > 0
    ) {
      this.setState({ slideIndex })
    }
    if (this.state.formContext.slideIndex) {
      this.setState(prevState => {
        return {
          ...prevState,
          formContext: {
            ...prevState.formContext,
            slideIndex: null
          }
        }
      })
    }
  }

  _onErrorClick = (error: any) => {
    const { forms, step } = this.state
    let clientIndex = error.id.match('application_clients_([0-9]+)')
    if (clientIndex) {
      clientIndex = clientIndex[1]
      let section = 'LifeEventsWizard'
      if (this.props.isRenewal) {
        section = 'RenewalWizard'
      }
      const expandLinks = [
        `${section}Personal_application_clients_${+clientIndex}_clientDetails_expandLink`,
        `${section}AdditionalInfo_application_clients_${+clientIndex}_clientAdditionalInformation_expandLink`,
        `${section}Income_application_clients_${+clientIndex}_clientIncomes_expandLink`,
        `${section}Expenses_application_clients_${+clientIndex}_clientExpenses_expandLink`,
        `${section}Assets_application_clients_${+clientIndex}_clientAssets_expandLink`
      ]
      expandLinks.forEach(expandLinkId => {
        if (document.getElementById(expandLinkId) != null) {
          document.getElementById(expandLinkId).click()
        }
      })
    } else {
      clientIndex = ''
    }

    this.setState(
      {
        formContext: {
          ...this.state.formContext,
          accordionActiveId:
            forms[error.step - 1].uiSchema['ui:rootFieldId'] +
            '_application_clients',
          accordionActiveKey: +clientIndex
        }
      },
      () => {
        setTimeout(() => {
          //Change current step to the step of the clicked error.
          if (step !== error.step) {
            this.setState({ step: error.step })
          }

          //Set a viewId state to be used for scrolling down after the React DOM finishes updating.
          if (step === 1) {
            const searchKey = error.id.split('_')[1]
            let slideInd = forms[0].uiSchema.application[
              'ui:options'
            ].slides.findIndex(slide =>
              slide.localUiSchema.hasOwnProperty(searchKey)
            )
            if (!this.state.hasSpecialNeeds && slideInd > 3) {
              slideInd--
            }

            this.setState(prevState => {
              return {
                ...prevState,
                viewId:
                  forms[error.step - 1].uiSchema['ui:rootFieldId'] +
                  '_' +
                  error.id,
                formContext: {
                  ...prevState.formContext,
                  slideIndex: slideInd || null
                }
              }
            })

            if (
              slideInd !=
              forms[0].uiSchema.application['ui:options'].slides.length - 1
            ) {
              this.setState({ showNext: false })
            } else {
              this.setState({ showNext: true })
            }
          } else {
            this.setState({
              viewId:
                forms[error.step - 1].uiSchema['ui:rootFieldId'] +
                '_' +
                error.id
            })
          }

          if (this.state.slideIndex < this.state.slides.length - 1) {
            this.setState({
              showNext: false
            })
          }
        }, 1000)
      }
    )
  }

  _onError = (errors: object) => {
    const { liveValidate, formContext, step } = this.state

    //Enable live validation upon error to allow for realtime evaluation of errors in response to formData changes.
    if (!liveValidate) {
      this.setState({
        liveValidate: true,
        formContext: {
          ...formContext,
          hasError: this.hasError
        }
      })
      window.location.href = '#'
    }
    const errorContent = document.getElementsByClassName(
      'lifevent-errors'
    ) as HTMLCollectionOf<HTMLElement>
    const errorContentDiv =
      errorContent.length == 1
        ? errorContent[0]
        : step === errorContent.length
        ? errorContent[errorContent.length - 1]
        : errorContent[step - 1]
    if (errorContentDiv) {
      window.scroll(0, errorContentDiv.offsetTop)
      errorContentDiv.focus()
    }
  }

  _subscribeTrackerForm = (
    fieldId: string,
    onValidateChanged: Function,
    onValidateAdded: Function
  ) => {
    this.setState(prevState => {
      return {
        trackerForms: {
          ...prevState.trackerForms,
          [fieldId]: {
            onValidateChanged,
            onValidateAdded
          }
        }
      }
    })
  }

  _unsubscribeTrackerForm = (fieldId: string) => {
    this.setState(prevState => {
      return {
        trackerForms: _.omit(prevState.trackerForms, [fieldId])
      }
    })
  }

  _onDocUpload = (uploadedDoc: any) => {}

  _onUploadDelete = (deletedDoc: any) => {}

  _onExitClick = () => {
    const { updateMyCocApplicationStep } = this.props
    updateMyCocApplicationStep(1)
    this.setState({
      modalProps: {
        show: true,
        bsSize: 'lg',
        className: 'lg-width-to-md',
        cancel: () => {
          this.setState({
            modalProps: {
              show: false,
              cancel: null,
              confirm: null,
              title: null,
              body: null,
              confirmBtnText: null,
              closeBtnText: null
            }
          })
        },
        confirm: () => {
          this.setState({
            redirect:
              baseUrl +
              (this.props.isRenewal
                ? '/myaccount/benefits'
                : '/myaccount/lifeevents')
          })
        },
        confirmBtnText: 'Yes',
        closeBtnText: 'No',
        title: <h4 className="popup-title"> Exit</h4>,
        body: (
          <h6 className="headertxt">
            Are you sure you want to exit? Changes on this screen will not be
            saved.
          </h6>
        )
      }
    })
  }

  _panelEditLink = fieldId => {
    const tab = fieldId.match('_clients_[0-9]+_([a-zA-Z]+)_?')[1]
    const step = getStepBySection(tab) || this.state.step
    this.setState(
      {
        step
      },
      () => {
        window.location.href = '#'
      }
    )
  }

  _checkChildCareTabEnable = fieldId => {
    let { caseInfo } = this.state

    return (
      caseInfo &&
      _.some(caseInfo['clientBenfGrps'], element => {
        return element.benfProgCd === 'CCA'
      })
    )
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  _changeActiveAccordionKey = activeKey => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        accordionActiveKey: activeKey
      }
    })
  }

  _isClientEligibleForCHCR = clientIndex => {
    let { formData, caseInfo } = this.state

    return (
      caseInfo &&
      _.some(caseInfo['clientBenfGrps'], element => {
        return (
          element.benfProgCd == 'CCA' &&
          element.clientId == formData.application.clients[clientIndex].id
        )
      })
    )
  }

  _onConfirmMessage = () => {
    this.setState({ showDraftModal: false })
  }

  _onNonConfirmMessage = () => {
    this.setState({ showNonDraftModal: false })
  }

  render() {
    const { step, forms, formContext, redirect } = this.state
    const { presentation, errorMessage } = this.props
    if (redirect) {
      return (
        <Redirect
          to={{ pathname: redirect, state: { from: this.props.location } }}
        />
      )
    }

    let validator = null
    if (forms && step && formContext) {
      validator = new CustomValidator(
        forms[step - 1].schema,
        forms[step - 1].uiSchema,
        {
          ...formContext,
          refs: {
            ...formContext.refs,
            '{tracker mode}': 'return "VIEW";'
          }
        }
      )
    }
    const presentationProps = {
      STEPS,
      ..._.omit(this.props, 'presentation'),
      ...this.state,
      onPrevious: this._onPrevious,
      onExitClick: this._onExitClick,
      saveFormDataDraft: this._saveFormDataDraft,
      changeView: this._changeView,
      onDocUpload: this._onDocUpload,
      onUploadDelete: this._onUploadDelete,
      onSubmit: this._onSubmit,
      onConfirmMessage: this._onConfirmMessage,
      onNonConfirmMessage: this._onNonConfirmMessage,
      errorListTemplate: this._errorListTemplate,
      onNormalChange: this._onNormalChange,
      onValidate: this._onValidate,
      onErrorClearingChange: this._onErrorClearingChange,
      onError: this._onError,
      validator,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      hasError: this.hasError
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps, step) {
  if (state.mycocapplication.mycocapplication) {
    step = state.mycocapplication.mycocapplication.step || 1
  }
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  let roleName = _.get(state.userAccess, 'selectedUserRole.role.roleName') || ''
  let selectedEntitlements = _.get(
    state.userAccess,
    'selectedUserRole.entitlements',
    []
  ).map(element => element.entitlementName)
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  let updatedCOCFormData = state.mycocapplication.mycocapplication || []

  // let ieOrgId = _.get(state.userAccess, 'userRoles[0].organization.orgId') || ''
  let userAccess = _.get(state, 'userAccess') || ''
  let userRoleIdOrgIdList = _.get(state, 'userAccess.userRoleIdOrgIdList') || []
  let ieOrgId, userRoleId
  let currentuserRoleIdOrgIdRecord = _.find(userRoleIdOrgIdList, current => {
    return (
      _.get(current, 'orgId') ==
        _.get(state.userAccess, 'selectedOrg.ieOrgId') &&
      _.get(current, 'rolename') == roleName
    )
  })
  ieOrgId = _.get(currentuserRoleIdOrgIdRecord, 'orgId', '')
  userRoleId = _.get(currentuserRoleIdOrgIdRecord, 'userRoleId', '')
  if (!userRoleId) {
    userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  }
  return {
    auth: state.auth,
    orgId,
    userRoleId,
    roleId,
    roleName,
    selectedEntitlements,
    step,
    errorMessage,
    updatedCOCFormData,
    ieOrgId,
    locale: state.i18n.locale
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setActiveApplicationId: applId => {
      dispatch(actions.setActiveApplicationId(applId))
    },
    updateMyCocApplicationStep: step => {
      dispatch(actions.updateMyCocApplicationStep(step))
    },
    assignApplicationId: applId => {
      dispatch(actions.assignApplicationId(applId))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    updatedFormData: formData => {
      dispatch(actions.updatedFormData(formData))
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientUpdateWizardContainer)

function _tabStatus(tabs, json) {
  let tabStatus = tabs
  if (json.hasOwnProperty('tabStatus')) {
    for (let i = 0; i < tabStatus.length; i++) {
      for (let j = 0; j < json.tabStatus.length; j++) {
        if (tabStatus[i].title == json.tabStatus[j].name) {
          tabStatus[i].status = json.tabStatus[j].status
        }
      }
    }
  }

  for (let i = 0; i < tabStatus.length; i++) {
    switch (tabStatus[i].status) {
      case 'validated':
        tabStatus[i].rightIcon = validatedIcon
        tabStatus[i].rightSpan = validatedBadge
        break
      case 'editing':
        tabStatus[i].rightIcon = editingIcon
        tabStatus[i].rightSpan = editingBadge
        break
      case 'error':
        tabStatus[i].rightIcon = errorIcon
        tabStatus[i].rightSpan = errorBadge
        break
      case 'pristine':
      default:
        tabStatus[i].rightIcon = pristineIcon
        tabStatus[i].rightSpan = null
    }
  }

  return tabStatus
}
