import { includes, get, some } from 'lodash'
import { config } from './config'
import {
  _moment,
  getSiblingFormData,
  getCounties
} from '@optum-wvie/dynamic-ui-framework/src/utils'

const _ = { includes, get, some }

const PRIMARY_APPLICANT_TEXT = {
  en: 'Primary Applicant',
  es: 'Solicitante principal'
}

const APPLICANT_TEXT = {
  en: 'Applicant',
  es: 'Solicitante'
}

const CONTRIBUTOR_TEXT = {
  en: 'Contributor',
  es: 'Contribuyente'
}

export let refs = {
  '{enableHealthCare}': ({ formContext, fieldId }) => {
    try {
      const formData = _.get(formContext, 'reviewFormData')
      return (
        _.includes(
          _.get(formData, 'Application.benefitCategorieslist', []),
          'HEWL'
        ) &&
        _.some(_.get(formData, 'Application.clients', []), (element, key) => {
          return element.optBenefitHEWL
        })
      )
    } catch (err) {
      return false
    }
  },
  '{applicant status}': ({ fieldId, locale }) => {
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    let status =
      index === '0'
        ? PRIMARY_APPLICANT_TEXT[locale || 'en']
        : APPLICANT_TEXT[locale || 'en']
    return status
  },
  '{contributor text}': ({ locale }) => {
    return CONTRIBUTOR_TEXT[locale || 'en']
  },
  '{client number}': ({ fieldId }) => {
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    return (parseInt(index) + 1).toString()
  },
  '{1 month ago}': ({ locale }) => {
    return _moment(config)
      .subtract(1, 'months')
      .locale(locale || 'en')
      .format('MMMM')
  },
  '{2 months ago}': ({ locale }) => {
    return _moment(config)
      .subtract(2, 'months')
      .locale(locale || 'en')
      .format('MMMM')
  },
  '{3 months ago}': ({ locale }) => {
    return _moment(config)
      .subtract(3, 'months')
      .locale(locale || 'en')
      .format('MMMM')
  },
  '{isClientSelectedForHEWL}': ({ formContext, fieldId }) => {
    let index = fieldId.match('(?:.*)clients_([0-9]+)_?')[1]
    // applies to AppIntake formContext.formData and MyApplications>Review formContext.reviewFormData

    try {
      const formData = _.get(
        formContext,
        'formData',
        _.get(formContext, 'reviewFormData')
      )

      return (
        _.includes(
          _.get(formData, 'Application.benefitCategorieslist', []),
          'HEWL'
        ) &&
        _.get(
          formData,
          'Application.clients[' + index + '].optBenefitHEWL',
          false
        )
      )
    } catch (err) {
      return false
    }
  },
  '{counties for st}': ({ fieldId, formContext }) => {
    const state = getSiblingFormData(fieldId, 'st', formContext)
    return getCounties(state)
  },
  '{counties for state}': ({ fieldId, formContext }) => {
    const state = getSiblingFormData(fieldId, 'state', formContext)
    return getCounties(state)
  },
  '{counties for employerState}': ({ fieldId, formContext }) => {
    const state = getSiblingFormData(fieldId, 'employerState', formContext)
    return getCounties(state)
  }
}
