import * as React from 'react'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-framework/src/components/natives/ScreenPreLoader'
import Form from '@optum-wvie/dynamic-ui-framework/src/react-jsonschema-form/src/components/Form'
import * as _ from 'lodash'
import { Modal, Button } from 'react-bootstrap'
import { config } from '../../../config'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { I18n } from 'react-redux-i18n'

interface MyProfilePresentationProps {
  formSchema: {
    schema: Object
    uiSchema: Object
  }
  formData: any
  edit: boolean
  formContext: any
  onSubmit: (formData: any) => void
  onFormDataChange: (formData: any) => void
  closePinErrorModal: () => void
  showPinErrorModal: boolean
  minHeight: string
  errorMessage: string
  updateDefaultUserRole: () => void
  updateDefaultOrg: () => void
  updateNoticePreferences: () => void
  closePreferenceModal: () => void
  showPreferenceModal: boolean
  showUserRolesModal: boolean
  closeUserRolesModal: () => void

  formComponents: {
    fields: Object
    widgets: Object
    FieldTemplate: any
    ArrayFieldTemplate: any
  }
  serviceErrors: Array<any>
  onDeleteClick: () => void
}

export default function MyProfilePresentation(
  props: MyProfilePresentationProps
) {
  const {
    formSchema,
    formData,
    edit,
    formContext,
    onSubmit,
    onFormDataChange,
    closePinErrorModal,
    showPinErrorModal,
    minHeight,
    updateDefaultUserRole,
    updateDefaultOrg,
    formComponents,
    updateNoticePreferences,
    closePreferenceModal,
    showPreferenceModal,
    showUserRolesModal,
    closeUserRolesModal,
    errorMessage,
    serviceErrors,
    onDeleteClick
  } = props

  if (formSchema && formData && formContext && !_.isEmpty(formContext)) {
    let submitBtn
    if (edit) {
      submitBtn = (
        <button type="submit" className="btn btn-default float-right">
          {I18n.t('General.submitButton')}
        </button>
      )
    }

    return (
      <div className="row">
        <div className="col-12">
          <h1 className="title-header">
            <i className="pe-7s-user" /> {I18n.t('myProfile.title')}
          </h1>
          <h2 className="headertxt mt-0 mb-2 px-3">
            {`${I18n.t('myProfile.welcomeTo')} ${config.tenant.name}.`}
            <br />
          </h2>
        </div>
        <div className="col-12">
          <div className="px-3">
            <Form
              schema={formSchema[0]['schema']}
              uiSchema={formSchema[0]['uiSchema']}
              onSubmit={onSubmit}
              formData={formData}
              onChange={onFormDataChange}
              widgets={formComponents.widgets}
              fields={formComponents.fields}
              formContext={formContext}
              FieldTemplate={formComponents.FieldTemplate}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            >
              <div>{submitBtn}</div>
            </Form>
          </div>
          <div className="px-3 pt-3 mt-5 margin-bottom-50">
            <Form
              schema={formSchema[1]['schema']}
              uiSchema={formSchema[1]['uiSchema']}
              formData={formData}
              onChange={onFormDataChange}
              widgets={formComponents.widgets}
              fields={formComponents.fields}
              formContext={formContext}
              FieldTemplate={formComponents.FieldTemplate}
              ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
            >
              <button type="submit" className="hidden" />
            </Form>
            <button
              id="saveDefaultRole"
              className="btn btn-primary float-right"
              onClick={() => {
                updateDefaultUserRole()
                updateDefaultOrg()
              }}
              style={{ margin: '10px' }}
            >
              {I18n.t('General.saveButton')}
            </button>
          </div>
          <div className="px-3">
            {formData &&
            formData.paperlessNotices &&
            formData.paperlessNotices.notices &&
            formData.paperlessNotices.notices.length > 0 ? (
              <Form
                schema={formSchema[2]['schema']}
                uiSchema={formSchema[2]['uiSchema']}
                formData={formData}
                onChange={onFormDataChange}
                widgets={formComponents.widgets}
                fields={formComponents.fields}
                onSubmit={updateNoticePreferences}
                formContext={formContext}
                FieldTemplate={formComponents.FieldTemplate}
                ArrayFieldTemplate={formComponents.ArrayFieldTemplate}
              >
                <button
                  type="submit"
                  id="saveGoPaperlessOption"
                  className="btn btn-primary float-right"
                  style={{ margin: '10px' }}
                >
                  {I18n.t('General.update')}
                </button>
              </Form>
            ) : (
              ''
            )}
          </div>
        </div>
        {!(serviceErrors.length > 0) && (
          <Modal
            show={showPinErrorModal}
            onHide={closePinErrorModal}
            aria-labelledby="error-modal-title"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="popup-title"> {I18n.t('General.error')}</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body id="error-modal-title">
              {' '}
              <h5 className="headertxt">{errorMessage}</h5>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn"
                variant="primary"
                onClick={closePinErrorModal}
              >
                {I18n.t('General.ok')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Modal
          show={showPreferenceModal}
          onHide={closePreferenceModal}
          aria-labelledby="saved-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {' '}
              <h4 className="popup-title"> {I18n.t('General.success')}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body id="saved-modal-title">
            <h5 className="headertxt">
              {I18n.t('myProfile.updateNoticePreferences')}
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn"
              variant="primary"
              onClick={closePreferenceModal}
            >
              {I18n.t('General.closeButton')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showUserRolesModal}
          onHide={closeUserRolesModal}
          aria-labelledby="updated-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="updated-modal-title">
              {' '}
              <h4 className="popup-title">{I18n.t('General.success')}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="headertxt">
              {I18n.t('myProfile.updateDefaultRole')}
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn"
              variant="primary"
              onClick={closeUserRolesModal}
            >
              {I18n.t('General.closeButton')}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* {formData &&
        formData.paperlessNotices &&
        formData.paperlessNotices.notices &&
        formData.paperlessNotices.notices.length <= 0 ? (
          <div className="telassistanceforms">
            <h3>Manage Paperless Notices</h3>
            <p>
              Currently you don't have notices to register for opting Paperless
              Notices.
            </p>
          </div>
        ) : (
          ''
        )} */}
        <div className="col-12 telassistanceforms">
          <div className="px-3">
            <h2>{I18n.t('myProfile.teleForms')}</h2>
            <a
              href="/assets/pdf-files/DFA_TA_2_Tel-AssistanceLifelineProgramApplication.pdf"
              className="link-line"
            >
              {I18n.t('myProfile.teleForms1')}
            </a>
            <br />
            <a
              href="/assets/pdf-files/DFA_TA_3_Tel-AssistanceLifelineServiceFactSheet.pdf"
              className="link-line"
            >
              {I18n.t('myProfile.teleForms2')}
            </a>
          </div>
        </div>
        <ErrorModal
          errorMessage={serviceErrors}
          onDeleteClick={onDeleteClick}
        />
      </div>
    )
  }
  return (
    <div>
      <ErrorModal errorMessage={serviceErrors} onDeleteClick={onDeleteClick} />
      <ScreenPreLoader loaded={false} minHeight={minHeight} />
    </div>
  )
}
