import * as React from 'react'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import * as _ from 'lodash'
import { config } from '../../../config'
import { UnauthorizedError, getServerTime } from '../src/components/utils'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import { Modal, Button, Tooltip } from 'react-bootstrap'

const getBannerList = config['gatewayWvUrl'] + config['getBannerList']

function Message(props) {
  return (
    <div className="banner" id={props.message.id}>
      <div className="container-fluid header-content">
        <div className="banner-flexbox">
          <div className="banner-message">
            {ReactHtmlParser(props.message.message)}
            {props.message.modal && (
              <button
                onClick={() => props.openModal(props)}
                className="banner-button"
              >
                {ReactHtmlParser(props.message.modalLink)}
              </button>
            )}
            {props.message.message1 && ReactHtmlParser(props.message.message1)}
          </div>
          {props.message.dismissable ? (
            <div className="banner-close">
              <button
                onClick={() => props.closeBanner(props)}
                className="banner-close-button"
                title="close"
                aria-label="close"
              >
                <i className="fa fa-times fa-lg" aria-hidden="true">
                  <span className="sr-only">X</span>
                </i>
              </button>
            </div>
          ) : null}
          <ModalContainer {...props} />
        </div>
      </div>
    </div>
  )
}

function ModalContainer(props) {
  return (
    <Modal
      show={props.message.showModal}
      onHide={() => props.hideModal(props)}
      size={'xl'}
      aria-labelledby="contained-modal-message"
    >
      <Modal.Header closeButton>
        {props.message.modalHeader && (
          <Modal.Title id="contained-modal-message">
            {ReactHtmlParser(props.message.modalHeader)}
          </Modal.Title>
        )}
      </Modal.Header>
      {props.message.modalBody && (
        <Modal.Body>{ReactHtmlParser(props.message.modalBody)}</Modal.Body>
      )}
      {props.message.modalFooter && (
        <Modal.Footer>
          {ReactHtmlParser(props.message.modalFooter)}
        </Modal.Footer>
      )}
    </Modal>
  )
}

class Banner extends React.Component<LayoutProps, BannerState> {
  constructor(props: LayoutProps) {
    super(props)
    this.state = {
      notifications: []
    }
  }

  componentDidMount() {
    this.getAllBannerList$()
  }

  getAllBannerList$ = () => {
    const request = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        tenantCode: config.tCode
      }
    }
    helper
      .fetchJson(getBannerList, request)
      .then(json => {
        let objArr = _.get(json, 'bannerMessageDetailList')
        objArr.forEach(element => {
          let mes = element.message.split('&lt;').join('<')
          let header =
            element.modalHeader != null
              ? element.modalHeader.split('&lt;').join('<')
              : ''
          let body =
            element.modalBody != null
              ? element.modalBody.split('&lt;').join('<')
              : ''
          let footer =
            element.modalFooter != null
              ? element.modalFooter.split('&lt;').join('<')
              : ''
          let mes1 =
            element.message1 != null
              ? element.message1.split('&lt;').join('<')
              : ''
          let modalLink =
            element.modalLink != null
              ? element.modalLink.split('&lt;').join('<')
              : ''
          element.message = mes
          element.modalHeader = header
          element.modalBody = body
          element.modalFooter = footer
          element.message1 = mes1
          element.modalLink = modalLink
        })
        this.setState(prevState => {
          return {
            ...prevState,
            notifications: objArr
          }
        })
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
        }
        console.error('_getAllBannerMessages failed with error:', error)
      })
  }

  closeBanner = props => {
    let { notifications } = this.state
    const id = _.findIndex(notifications, x => {
      return x.id == props.message.id
    })
    notifications[id].show = false
    this.setState(prevState => {
      return {
        ...prevState,
        notifications: notifications
      }
    })
  }

  openModal = props => {
    let { notifications } = this.state
    const id = _.findIndex(notifications, x => {
      return x.id == props.message.id
    })
    notifications[id].showModal = true
    this.setState(prevState => {
      return {
        ...prevState,
        notifications: notifications
      }
    })
  }

  hideModal = props => {
    let { notifications } = this.state
    const id = _.findIndex(notifications, x => {
      return x.id == props.message.id
    })
    notifications[id].showModal = false
    this.setState(prevState => {
      return {
        ...prevState,
        notifications: notifications
      }
    })
  }

  render() {
    const { notifications } = this.state
    return (
      <div role="banner">
        {notifications.map(message => {
          return message.show ? (
            <Message
              key={message.id}
              message={message}
              closeBanner={this.closeBanner}
              hideModal={this.hideModal}
              openModal={this.openModal}
            />
          ) : null
        })}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    selectedEntitlements: _.get(
      state.userAccess,
      'selectedUserRole.entitlements',
      []
    ).map(element => element.entitlementName),
    userAccess: state.userAccess
  }
}

export default connect(mapStateToProps)(Banner)
