import { findIndex, isArray, isObject, isString } from 'lodash' // extra functionality
import { combineReducers } from 'redux'

const _ = { findIndex, isArray, isObject, isString }

export default combineReducers({
  activeApplicationId,
  applicationsArray
})

//Every application has an initial applId of "0", of which there may only be one, until the draft is saved for the first time.

function activeApplicationId(state = '0', action) {
  switch (action.type) {
    case 'CLEAR_APPLICATIONS_ID':
      return ''
    case 'SET_ACTIVE_APPLICATION_ID':
      return action.applId
    case 'LOGOUT_SUCCESS':
      return '0'
    default:
      return state
  }
}

function applicationsArray(state = [], action) {
  switch (action.type) {
    case 'CLEAR_APPLICATIONS':
      return []
    case 'ADD_MY_APPLICATION':
      const addIndex = _.findIndex(state, element => {
        return element.applId === action.applId
      })
      if (addIndex === -1) {
        return [...state, myapplication(null, action)]
      } else {
        return state
      }
    case 'UPDATE_MY_APPLICATION':
    case 'UPDATE_MY_APPLICATION_STEP':
    case 'UPDATE_MY_APPLICATION_TAB_STATUS':
      const updateIndex = _.findIndex(state, element => {
        return element.applId === action.applId
      })
      if (updateIndex > -1) {
        return [
          ...state.slice(0, updateIndex),
          myapplication(state[updateIndex], action),
          ...state.slice(updateIndex + 1)
        ]
      } else {
        return state
      }
    case 'ASSIGN_APPLICATION_ID':
      const assignIndex = _.findIndex(state, element => {
        return element.applId === '0'
      })
      if (assignIndex > -1) {
        return [
          ...state.slice(0, assignIndex),
          myapplication(state[assignIndex], action),
          ...state.slice(assignIndex + 1)
        ]
      } else {
        return state
      }
    case 'REMOVE_MY_APPLICATION':
      return state.filter(element => {
        return element.applId !== action.applId
      })
    case 'LOGOUT_SUCCESS':
      return []
    default:
      return state
  }
}

function myapplication(state = { tabStatus: [], step: 1 }, action) {
  switch (action.type) {
    case 'ADD_MY_APPLICATION':
      return {
        ...state,
        applId: action.applId || '0',
        status: action.status,
        applicationData: action.applicationData || {},
        step: action.step || 1,
        tabStatus: action.tabStatus || []
      }
    case 'UPDATE_MY_APPLICATION':
      return {
        ...state,
        status: action.status,
        applicationData: action.applicationData || {}
      }
    case 'ASSIGN_APPLICATION_ID':
      return {
        ...state,
        applId: action.applId
      }
    case 'UPDATE_MY_APPLICATION_STEP':
      return {
        ...state,
        step: action.step
      }
    case 'UPDATE_MY_APPLICATION_TAB_STATUS':
      if (_.isArray(action.tabStatus)) {
        return {
          ...state,
          tabStatus: action.tabStatus
        }
      } else if (_.isObject(action.tabStatus)) {
        const tabIndex = _.findIndex(state.tabStatus, element => {
          return element.name === action.tabStatus.name
        })
        if (tabIndex === -1) {
          return state
        } else {
          return {
            ...state,
            tabStatus: [
              ...state.tabStatus.slice(0, tabIndex),
              {
                ...state.tabStatus[tabIndex],
                ...action.tabStatus
              },
              ...state.tabStatus.slice(tabIndex + 1)
            ]
          }
        }
      } else if (_.isString(action.tabStatus) && state.step) {
        return {
          ...state,
          tabStatus: [
            ...state.tabStatus.slice(0, state.step - 1),
            {
              ...state.tabStatus[state.step - 1],
              status: action.tabStatus
            },
            ...state.tabStatus.slice(state.step)
          ]
        }
      } else {
        return state
      }
    case 'LOGOUT_SUCCESS':
      return { tabStatus: [], step: 1 }
    default:
      return state
  }
}
