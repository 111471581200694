import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'
import { get, trim } from 'lodash'
const _ = { get, trim }

export function LinkWidget(props) {
  const { options } = props
  const { parameters } = options
  let linkAction = null
  switch (options.action) {
    case 'viewMessageDetails':
      const index = props.id.match('(?:.*)myMessages_([0-9]+)_?')[1]
      const message = _.get(
        props.formContext,
        `formData.Application.myMessages[${index}]`
      )
      const detailId =
        message.programType === 'FA' ? message.requestTimeStamp : message.docId
      linkAction = () =>
        props.formContext.viewMessageDetails(parameters.viewId, detailId)

      break
  }
  let linkLabel =
    props.value && _.trim(props.value) != ''
      ? props.value
      : 'Notice Description Unavailable'

  return (
    <span className={props.classNames}>
      <button
        type="button"
        onClick={linkAction}
        id={props.id}
        className="btn btn-link "
      >
        {linkLabel}
      </button>
    </span>
  )
}

export const fields = {
  condition: Fields.ConditionalField,
  TitleField: Fields.CustomTitleField,
  DescriptionField: Fields.CustomDescriptionField,
  SchemaField: Fields.CustomSchemaField,
  accordion: Fields.AccordionField,
  dataTable: Fields.DataTableField,
  tableRow: Fields.TableRowField
}

export const widgets = {
  span: Widgets.SpanWidget,
  routeLink: LinkWidget,
  iconWidget: Widgets.ConditionalIconWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
