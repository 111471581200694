import * as React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { get } from 'lodash'
const _ = { get }

export default function RolesSubMenu(props) {
  const {
    userAccess,
    setSelectedUserRole,
    setSelectedOrg,
    defaultUserRoleId,
    showRolesOverlay,
    onShowRolesOverlay,
    onHideRolesOverlay,
    onHideAccountOverlay,
    rolesMenuRef
  } = props

  const selectedUserRoleId = _.get(userAccess, 'selectedUserRole.userRoleId')
  const selectedOrgRoleId = _.get(userAccess, 'selectedOrg.roleorgId')

  const rolesMenuClick = showRolesOverlay => {
    if (showRolesOverlay) {
      onHideRolesOverlay()
    } else {
      onShowRolesOverlay()
    }
  }

  const roleButton2 = (userRole, orgObj) => {
    const { userRoleId } = userRole
    const roleorgId = orgObj && orgObj.roleorgId
    const selected = roleorgId
      ? selectedOrgRoleId === roleorgId
      : selectedUserRoleId === userRoleId
    const defaultOrgId = _.get(userAccess, 'defaultOrg.roleorgId')
    return (
      <button
        className={
          'popover-button subrolefocus ' + (selected ? 'selected' : '')
        }
        aria-pressed={selected}
        onClick={() => {
          setSelectedUserRole(userRole)
          setSelectedOrg(orgObj)
        }}
      >
        {selected && <i className="fa fa-check" />}

        {orgObj
          ? userRole.role.multilingualDisplayName.en +
            ' ' +
            '-' +
            ' ' +
            orgObj.orgName +
            (defaultOrgId === orgObj.roleorgId ? '(default)' : '')
          : userRole.role.multilingualDisplayName.en +
            (defaultUserRoleId === userRole.userRoleId ? '(default)' : '')}
      </button>
    )
  }

  const popoverTSX = (
    <Popover id="rolesPopover">
      <ul
        id="roles-menu-list"
        className="popover-menu-list"
        ref={rolesMenuRef}
        onBlur={e => {
          const accountLink = document.getElementById('account-menu')
          const rolesLink = document.getElementById('roles-menu-list')
          const target: any = e.relatedTarget || document.activeElement
          const parentTarget = target.parentElement.parentElement

          if (!parentTarget) {
            onHideAccountOverlay()
          } else if (parentTarget === accountLink) {
            onHideRolesOverlay()
          } else if (parentTarget !== rolesLink) {
            onHideAccountOverlay()
          }
        }}
      >
        {userAccess.userRoles.map((userRole: UserRole) => {
          if (userRole.orgList && userRole.orgList.length >= 1) {
            return userRole.orgList.map(orgObj => {
              return (
                <li tabIndex={-1} key={orgObj.roleorgId}>
                  {roleButton2(userRole, orgObj)}
                </li>
              )
            })
          } else {
            return (
              <>
                <li tabIndex={-1} key={userRole.userRoleId}>
                  {roleButton2(userRole, null)}
                </li>
              </>
            )
          }
        })}
      </ul>
    </Popover>
  )

  return (
    <OverlayTrigger
      overlay={popoverTSX}
      placement="right-start"
      trigger="click"
      show={showRolesOverlay}
    >
      <button
        className="popover-button rolefocus"
        id="Header_roles_link"
        onClick={() => rolesMenuClick(showRolesOverlay)}
        aria-expanded={showRolesOverlay}
      >
        <i className="glyphicon myglyphicon-transfer" aria-hidden="true" />
        {I18n.t('Header.switchRoleLink')}
      </button>
    </OverlayTrigger>
  )
}
