import * as React from 'react'
import { Link } from 'react-router-dom'
import { imageMap } from '../../../images'
import { Carousel } from 'react-bootstrap'
import * as _ from 'lodash'
import PlayPauseCarousel from '@optum-wvie/dynamic-ui-framework/src/components/natives/PlayPauseCarousel'
import { config } from '../../../config'
import '!style-loader!css-loader!../src/customStyles.css'
import { Modal, Button } from 'react-bootstrap'
import { ErrorModal } from '../src/components/natives/ErrorModal'
import { ENTITLEMENTS_wv as wvEnt } from '../src/entitlements_wv'
import { I18n } from 'react-redux-i18n'

const finderStyle = {}

declare const process

const baseUrl = process.env.PUBLIC_URL
interface HomePresentationProps {
  benefitCategoryData: Array<{
    benfCatName: string
    benfCatShortDesc: string
  }>
  roleId: number
  roleName: string
  errorMessage: Array<any>
  onDeleteClick: () => void
  carouselFocus: boolean
  onCarouselFocus: () => void
  onCarouselBlur: () => void
  userAccess: {
    userRoles: Array<UserRole>
  }
  isLoggedIn: boolean
  showCovidModal: () => void
  hideCovidModal: () => void
  covidModal: boolean
  showScaModal: () => void
  hideScaModal: () => void
  scaModal: boolean
}

export default function HomePresentation(props: HomePresentationProps) {
  const {
    benefitCategoryData,
    roleId,
    roleName,
    onDeleteClick,
    errorMessage,
    carouselFocus,
    onCarouselFocus,
    onCarouselBlur,
    userAccess,
    isLoggedIn,
    showCovidModal,
    hideCovidModal,
    covidModal,
    showScaModal,
    hideScaModal,
    scaModal
  } = props
  return (
    <>
      <div>
        <h1 className="sr-only">{I18n.t('Home.title')}</h1>
        <ErrorModal errorMessage={errorMessage} onDeleteClick={onDeleteClick} />
      </div>
      <div id="content" tabIndex={-1}>
        <HomeCarousel
          benefitCategoryData={benefitCategoryData}
          carouselFocus={carouselFocus}
          onCarouselFocus={onCarouselFocus}
          onCarouselBlur={onCarouselBlur}
          showCovidModal={showCovidModal}
          hideCovidModal={hideCovidModal}
          covidModal={covidModal}
          showScaModal={showScaModal}
          hideScaModal={hideScaModal}
          scaModal={scaModal}
        />
        <MainNavigation
          roleId={roleId}
          roleName={roleName}
          userAccess={userAccess}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </>
  )
}

function CarouselButtons(props) {
  return (
    <div className="carousel-caption-benefits">
      <hr />
      <h3 className="homelead">{I18n.t('Home.carouselMoreInfo')}</h3>
      <ul className="list-inline">
        <li className="listClass">
          <i style={{ color: '#85c0e8' }} className="myglyphicon-heart" />
          <Link
            to={{
              pathname: baseUrl + '/programs&services',
              state: { tabKey: 'health-wellness' }
            }}
            onFocus={() => props.onCarouselFocus()}
            onBlur={() => props.onCarouselBlur()}
          >
            {' '}
            {I18n.t('Home.healthCare')}
          </Link>
        </li>
        <li className="listClass">
          <i style={{ color: '#85c0e8' }} className="myglyphicon-parents" />
          <Link
            to={{
              pathname: baseUrl + '/programs&services',
              state: { tabKey: 'family-assistance' }
            }}
            onFocus={() => props.onCarouselFocus()}
            onBlur={() => props.onCarouselBlur()}
          >
            {' '}
            {I18n.t('Home.familyAssistance')}
          </Link>
        </li>
        <li className="listClass">
          <i style={{ color: '#85c0e8' }} className="myglyphicon-apple" />
          <Link
            to={{
              pathname: baseUrl + '/programs&services',
              state: { tabKey: 'food-nutrition' }
            }}
            onFocus={() => props.onCarouselFocus()}
            onBlur={() => props.onCarouselBlur()}
          >
            {' '}
            {I18n.t('Home.foodNutrition')}
          </Link>
        </li>
      </ul>
    </div>
  )
}

function CarouselButtonsCSP(props) {
  return (
    <div className="carousel-caption-benefits">
      <hr />
      <h3 className="homelead">{I18n.t('Home.carouselMoreInfo')}</h3>
      <ul className="list-inline">
        <li className="listClass">
          <i style={{ color: '#85c0e8' }} className="fa fa-child fa-lg" />
          <Link
            to={{
              pathname: baseUrl + '/programs&services',
              state: { tabKey: 'NewTab1' }
            }}
            onFocus={() => props.onCarouselFocus()}
            onBlur={() => props.onCarouselBlur()}
          >
            {' '}
            {I18n.t('Home.childSupportServices')}
          </Link>
        </li>
      </ul>
    </div>
  )
}

function HomeCarousel(props) {
  let benefitCategoryData = [
    {
      benfCatName: I18n.t('Home.healthCare'),
      benfCatShortDesc: I18n.t('Home.healthCareDesc')
    },
    {
      benfCatName: I18n.t('Home.familyAssistance'),
      benfCatShortDesc: I18n.t('Home.familyAssistanceDesc')
    },
    {
      benfCatName: I18n.t('Home.foodNutrition'),
      benfCatShortDesc: I18n.t('Home.foodNutritionDesc')
    },
    {
      benfCatName: I18n.t('Home.childSupportServices'),
      benfCatShortDesc: I18n.t('Home.childSupportServicesDesc')
    }
  ]
  if (!_.isEmpty(benefitCategoryData)) {
    let carouselItems = []

    for (let i = 0; i < benefitCategoryData.length; i++) {
      carouselItems.push(
        <Carousel.Item key={i} id={benefitCategoryData[i].benfCatName}>
          <img
            className="carousel-bg-img xs-height-500"
            src={imageMap[config.tenant.code][`CP_Benefits_${i + 1}`]}
            alt=""
          />
          <Carousel.Caption>
            <div
              style={{ height: '100%', width: '100%' }}
              className="carousel-caption caption-inner"
            >
              <h2>{benefitCategoryData[i].benfCatName}</h2>
              <p>{benefitCategoryData[i].benfCatShortDesc}</p>
              {benefitCategoryData[i].benfCatName ==
                'Child Support Services' && <CarouselButtonsCSP {...props} />}
              {benefitCategoryData[i].benfCatName !=
                'Child Support Services' && <CarouselButtons {...props} />}
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      )
    }

    return (
      <>
        <PlayPauseCarousel
          className="home-carousel"
          interval={15000}
          carouselFocus={props.carouselFocus}
        >
          {carouselItems}
        </PlayPauseCarousel>
      </>
    )
  }
  return <div>Loading...</div>
}

function MainNavigation(props) {
  const roleId = props.roleId
  const roleName = props.roleName
  const userAccess = props.userAcess
  const isLoggedIn = props.isLoggedIn
  const buttonHTML = []
  const liClasses = [
    'account_wv col-12 col-lg-2 col-md-4 col',
    'finder_wv col-12 col-lg-2 col-md-4 col',
    'catalog_wv col-12 col-lg-2 col-md-4 col',
    'bcse_wv col-12 col-lg-2 col-md-4 col',
    'bcf_wv col-12 col-lg-2 col-md-4 col',
    'apply_wv col-12 col-lg-2 col-md-4 col'
  ]
  const hrClassNames = [
    'accountbar_wv',
    'finderbar_wv',
    'catalogbar_wv',
    'bcsebar_wv',
    'bcfbar_wv',
    'applybar_wv'
  ]
  const glyphiconClasses = [
    'glyphicons myglyphicon-user glyphiconuser',
    'glyphicons myglyphicon-compass glyphiconuser',
    'glyphicons myglyphicon-book_open glyphiconuser',
    'glyphicons myglyphicon-group glyphiconuser',
    'glyphicons myglyphicon-home glyphiconuser',
    'glyphicons myglyphicon-check glyphiconuser'
  ]
  const h2Labels = [
    I18n.t('Home.myAccount'),
    I18n.t('Home.screenAssistance'),
    I18n.t('Home.programsServices'),
    I18n.t('Home.childSupport'),
    I18n.t('Home.reportAbuseNeglect'),
    I18n.t('Home.applyNow')
  ]
  const bullets1 = [
    I18n.t('Home.reviewStatusMessages'),
    I18n.t('Home.noSignInReq'),
    I18n.t('Home.whatAvailable'),
    I18n.t('Home.whatAvailable1'),
    I18n.t('Home.whatAvailable2'),
    I18n.t('Home.applyProgramsServices')
  ]
  const URLList = [
    '/myaccount',
    '/benefitsfinderWV',
    '/programs&services',
    'https://dhhr.wv.gov/bcse/makeapayment/Pages/default.aspx',
    'https://dhhr.wv.gov/bss/services/Pages/Centralized-Intake-for-Abuse-and-Neglect.aspx',
    '/appdrafts'
  ]
  const buttonLabels = [
    I18n.t('Home.home1'),
    I18n.t('Home.home2'),
    I18n.t('Home.home3'),
    I18n.t('Home.home4'),
    I18n.t('Home.home5'),
    I18n.t('Home.home6')
  ]
  //class names for grids if the role is PE or CP
  const roleLiClasses = [
    'account_wv_role col-12 col-lg-width-19 col-md-4 col',
    'finder_wv_role col-12 col-lg-width-19 col-md-4 col',
    'catalog_wv_role col-12 col-lg-width-19 col-md-4 col',
    'bcse_wv_role col-12 col-lg-width-19 col-md-4 col',
    'bcf_wv_role col-12 col-lg-width-19 col-md-4 col',
    'apply_wv_role col-12 col-lg-width-19 col-md-4 col'
  ]
  const roleHrClassNames = [
    'accountbar_wv_role',
    'finderbar_wv_role',
    'catalogbar_wv_role',
    'bcsebar_wv_role',
    'bcfbar_wv_role',
    'applybar_wv_role'
  ]

  const accessibleName = [
    'to My Account',
    'Assistance',
    'Programs and Services',
    'for Child Support',
    'Abuse/Neglect',
    'for Programs and Services'
  ]

  const panelID = [
    'home-nav-buttons_1',
    'home-nav-buttons_2',
    'home-nav-buttons_3',
    'home-nav-buttons_4',
    'home-nav-buttons_5',
    'home-nav-buttons_6'
  ]

  const selectedUserRole = _.find(props.userAccess.userRoles, {
    userRoleId:
      props.userAccess.selectedUserRole &&
      _.toInteger(props.userAccess.selectedUserRole.userRoleId)
  })
  const selectedEntitlements = _.get(selectedUserRole, 'entitlements', []).map(
    element => element.entitlementName
  )

  const CP_APPLY_NOW = _.includes(selectedEntitlements, wvEnt.CP_APPLY_NOW)
    ? true
    : false

  if (CP_APPLY_NOW) {
    for (let i = 0; i < hrClassNames.length; i++) {
      if (i == 3 || i == 4) {
        buttonHTML.push(
          <li
            key={i}
            className={liClasses[i]}
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
          >
            <hr className={hrClassNames[i]} />
            <div id={panelID[i]} className="application-grid-margin col">
              <div className="row">
                <span className={glyphiconClasses[i]} />
              </div>
              <div className="application-grid-column_wv">
                <h2 className="home_wv ng-binding">{h2Labels[i]}</h2>
                <ul
                  className=" ordered-style_wv"
                  style={{ listStyleType: 'none' }}
                >
                  <li>&nbsp;&nbsp;</li>
                </ul>
              </div>
              <div className="row">
                <a
                  href={URLList[i]}
                  target="_blank"
                  className="btnPadding_wv btn btn-lg btn-primary btn-font"
                >
                  {buttonLabels[i]}
                  <span className="sr-only"> {accessibleName[i]}</span>
                </a>
              </div>
            </div>
          </li>
        )
      } else if (i == 0) {
        buttonHTML.push(
          <li
            key={i}
            className={liClasses[i]}
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
          >
            <hr className={hrClassNames[i]} />
            <div id={panelID[i]} className="application-grid-margin col">
              <div className="row">
                <span className={glyphiconClasses[i]} />
              </div>
              <div className="application-grid-column_wv">
                <h2 className="home_wv ng-binding">{h2Labels[i]}</h2>
                <ul className=" ordered-style_wv">
                  <li>{bullets1[i]}</li>
                </ul>
              </div>
              <div className="row">
                <Link
                  to={baseUrl + URLList[i]}
                  className="btnPadding_wv btn btn-lg btn-primary btn-font"
                >
                  {buttonLabels[i]}
                  <span className="sr-only"> {accessibleName[i]}</span>
                </Link>
              </div>
            </div>
          </li>
        )
      } else if (i != 0) {
        buttonHTML.push(
          <li
            key={i}
            className={liClasses[i]}
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
          >
            <hr className={hrClassNames[i]} />
            <div id={panelID[i]} className="application-grid-margin col">
              <div className="row">
                <span className={glyphiconClasses[i]} />
              </div>
              <div className="application-grid-column_wv">
                <h2 className="home_wv ng-binding">{h2Labels[i]}</h2>
                <ul className=" ordered-style_wv">
                  <li>{bullets1[i]}</li>
                </ul>
              </div>
              <div className="row">
                <Link
                  to={baseUrl + URLList[i]}
                  className="btnPadding_wv btn btn-lg btn-primary btn-font"
                >
                  {buttonLabels[i]}
                  <span className="sr-only"> {accessibleName[i]}</span>
                </Link>
              </div>
            </div>
          </li>
        )
      }
    }
  } else if (!isLoggedIn) {
    for (let i = 0; i < hrClassNames.length; i++) {
      if (i == 3 || i == 4) {
        buttonHTML.push(
          <li
            key={i}
            className={liClasses[i]}
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
          >
            <hr className={hrClassNames[i]} />
            <div id={panelID[i]} className="application-grid-margin col">
              <div className="row">
                <span className={glyphiconClasses[i]} />
              </div>
              <div className="application-grid-column_wv">
                <h2 className="home_wv ng-binding">{h2Labels[i]}</h2>
                <ul
                  className=" ordered-style_wv"
                  style={{ listStyleType: 'none' }}
                >
                  <li>&nbsp;&nbsp;</li>
                </ul>
              </div>
              <div className="row">
                <a
                  href={URLList[i]}
                  target="_blank"
                  className="btnPadding_wv btn btn-lg btn-primary btn-font"
                >
                  {buttonLabels[i]}
                  <span className="sr-only"> {accessibleName[i]}</span>
                </a>
              </div>
            </div>
          </li>
        )
      } else if (i == 5 && config['preGoLiveTitlesVisibile']) {
        buttonHTML.push(
          <li
            key={i}
            className={liClasses[i]}
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
          >
            <hr className={hrClassNames[i]} />
            <div id={panelID[i]} className="application-grid-margin col">
              <div className="row">
                <span className={glyphiconClasses[i]} />
              </div>
              <div className="application-grid-column_wv">
                <h2 className="home_wv ng-binding">{h2Labels[i]}</h2>
                <ul className=" ordered-style_wv">
                  <li>{bullets1[i]}</li>
                </ul>
              </div>
              <div className="row">
                <Link
                  to={baseUrl + URLList[i]}
                  className="btnPadding_wv btn btn-lg btn-primary btn-font"
                >
                  {buttonLabels[i]}
                  <span className="sr-only"> {accessibleName[i]}</span>
                </Link>
              </div>
            </div>
          </li>
        )
      } else if (i != 5) {
        buttonHTML.push(
          <li
            key={i}
            className={liClasses[i]}
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
          >
            <hr className={hrClassNames[i]} />
            <div id={panelID[i]} className="application-grid-margin col">
              <div className="row">
                <span className={glyphiconClasses[i]} />
              </div>
              <div className="application-grid-column_wv">
                <h2 className="home_wv ng-binding">{h2Labels[i]}</h2>
                <ul className=" ordered-style_wv">
                  <li>{bullets1[i]}</li>
                </ul>
              </div>
              <div className="row">
                <Link
                  to={baseUrl + URLList[i]}
                  className="btnPadding_wv btn btn-lg btn-primary btn-font"
                >
                  {buttonLabels[i]}
                  <span className="sr-only"> {accessibleName[i]}</span>
                </Link>
              </div>
            </div>
          </li>
        )
      }
    }
  } else {
    for (let i = 0; i < hrClassNames.length - 1; i++) {
      if (i == 3 || i == 4) {
        buttonHTML.push(
          <li
            key={i}
            className={roleLiClasses[i]}
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
          >
            <hr className={roleHrClassNames[i]} />
            <div id={panelID[i]} className="application-grid-margin col">
              <div className="row">
                <span className={glyphiconClasses[i]} />
              </div>
              <div className="application-grid-column_wv">
                <h2 className="home_wv ng-binding">{h2Labels[i]}</h2>
                <ul
                  className=" ordered-style_wv"
                  style={{ listStyleType: 'none' }}
                >
                  <li>&nbsp;&nbsp;</li>
                </ul>
              </div>
              <div className="row">
                <a
                  href={URLList[i]}
                  target="_blank"
                  className="btnPadding_wv btn btn-lg btn-primary btn-font"
                >
                  {buttonLabels[i]}
                  <span className="sr-only"> {accessibleName[i]}</span>
                </a>
              </div>
            </div>
          </li>
        )
      } else if (i == 0) {
        buttonHTML.push(
          <li
            key={i}
            className={roleLiClasses[i]}
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
          >
            <hr className={hrClassNames[i]} />
            <div id={panelID[i]} className="application-grid-margin col">
              <div className="row">
                <span className={glyphiconClasses[i]} />
              </div>
              <div className="application-grid-column_wv">
                <h2 className="home_wv ng-binding">{h2Labels[i]}</h2>
                <ul className=" ordered-style_wv">
                  <li>{bullets1[i]}</li>
                </ul>
              </div>
              <div className="row">
                <Link
                  to={baseUrl + URLList[i]}
                  className="btnPadding_wv btn btn-lg btn-primary btn-font"
                >
                  {buttonLabels[i]}
                  <span className="sr-only"> {accessibleName[i]}</span>
                </Link>
              </div>
            </div>
          </li>
        )
      } else if (i != 0) {
        buttonHTML.push(
          <li
            key={i}
            className={roleLiClasses[i]}
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
          >
            <hr className={roleHrClassNames[i]} />
            <div id={panelID[i]} className="application-grid-margin col">
              <div className="row">
                <span className={glyphiconClasses[i]} />
              </div>
              <div className="application-grid-column_wv">
                <h2 className="home_wv ng-binding">{h2Labels[i]}</h2>
                <ul className=" ordered-style_wv">
                  <li>{bullets1[i]}</li>
                </ul>
              </div>
              <div className="row">
                <Link
                  to={baseUrl + URLList[i]}
                  className="btnPadding_wv btn btn-lg btn-primary btn-font"
                >
                  {buttonLabels[i]}
                  <span className="sr-only"> {accessibleName[i]}</span>
                </Link>
              </div>
            </div>
          </li>
        )
      }
    }
  }

  if (
    roleName === 'Presumptive Eligibility Worker' ||
    roleName === 'Community Partner' ||
    roleName === 'PE Trainee'
  ) {
    return (
      <div className="container-fluid fluid-bg ng-scope">
        <div className="row equal text-center">
          <ul className="list-unstyled home-cards">{buttonHTML}</ul>
        </div>
      </div>
    )
  } else {
    return (
      <div className="container-fluid fluid-bg ng-scope">
        <div className="row equal text-center">
          <ul className="list-unstyled home-cards">{buttonHTML}</ul>
        </div>
      </div>
    )
  }
}
