import * as React from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import * as serviceHelper from '../src/components/utils'
import * as actions from '../../../actions'
import { config } from '../../../config'
import { fetchJson } from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as environment from '../../../config_WV'
import { UnauthorizedError } from '../src/components/utils'
declare var process
const baseUrl = process.env.PUBLIC_URL

const helper = {
  fetchJson
}

interface HelpTab {
  id: string
  title: string
  component: React.ReactNode
}

interface Org {
  roleId: number
  roleName: string
  orgId: number
  orgName: string
  roleorgId: number
  dfltflg: string
}

interface HelpContainerProps {
  presentation: any
  location: {
    search: string
    pathname: string
  }
  history: {
    push: (url: string) => void
  }
  HelpTabs: Array<HelpTab>
  externalAuth?: {
    hasPin: boolean
  }
  locale: string
  selectedRole: any
  selectedOrg: any
  userAccount: any
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  selectedUserRole: any
  auth: any
  roleId: any
  orgId: any
  userAccess: {
    userRoles: Array<UserRole>
    selectedUserRole: UserRole
    defaultOrg: Org
    orgList: Array<Org>
  }
  logoutUser
}

interface HelpContainerState {
  id: string
  minHeight: number
  navTabs: Array<HelpTab>
  prevProps: any
  uatUrl?: boolean
  openToken?: string
}

const navigationTabs = helpTabs => {
  let sortedTabs = [...helpTabs]
  sortedTabs = sortTabsForClient(sortedTabs)
  return sortedTabs
}

const sortTabsForClient = tabs => {
  tabs = _.sortBy(tabs, [
    tab => {
      switch (tab.id) {
        case 'commonQuestions':
          return 1
        case 'applicationprocess':
          return 2
        case 'myAccount':
          return 3
        case 'lifeChanges':
          return 4
        case 'morehelp':
          return 5
        default:
          return 999 //Put unknown tabs at the end.
      }
    }
  ])
  return tabs
}

class HelpContainer extends React.Component<
  HelpContainerProps,
  HelpContainerState
> {
  private navDiv: HTMLDivElement

  constructor(props: HelpContainerProps) {
    super(props)
    this.navDiv = (React as any).createRef()
    this.state = {
      id: '',
      minHeight: 0,
      navTabs: null,
      prevProps: null
    }
  }

  static getDerivedStateFromProps(
    props: HelpContainerProps,
    state: HelpContainerState
  ) {
    if (
      !state.prevProps ||
      props.HelpTabs != state.prevProps.helpTabs ||
      props.externalAuth != state.prevProps.externalAuth
    ) {
      if (!props.HelpTabs || props.HelpTabs.length === 0) {
        return null
      }
      const navTabs = navigationTabs(props.HelpTabs)
      let newId = state.id
      if (navTabs && navTabs.length > 0) {
        if (!newId || newId === '') {
          const subPath = window.location.href.match('\\/help\\/([a-zA-Z0-9]+)')
          if (subPath && subPath[1]) {
            const tabIndex = _.findIndex(navTabs, navTab => {
              return subPath[1] === navTab.id
            })
            if (tabIndex > -1) {
              newId = navTabs[tabIndex].id
            } else {
              newId = navTabs[0].id
            }
          } else {
            newId = navTabs[0].id
          }
        } else {
          const tabIndex = _.findIndex(navTabs, navTab => {
            return newId === navTab.id
          })
          if (tabIndex === -1) {
            newId = navTabs[0].id
          }
        }
      } else {
        newId = ''
      }
      return {
        navTabs,
        id: newId,
        prevProps: props
      }
    } else {
      return null
    }
  }
  componentDidMount() {
    document.title = 'Help - IE Client portal'
    if (this.navDiv && this.navDiv.clientHeight) {
      this.setState({ minHeight: this.navDiv.clientHeight })
    }
    if (this.props.history) {
      if (
        (this.state.id && this.state.id !== '') ||
        !this.props.location.pathname.match(baseUrl + '/help')
      ) {
        if (!this.props.location.pathname.match(baseUrl + '/help')) {
          this.props.history.push(baseUrl + '/help')
        }
      }
    }
    let envType = environment.determineEnvironment()
    if (
      (this.state.id == 'peTraining' || this.state.id == 'morehelp') &&
      this.props.auth.userAccount &&
      this.props.selectedUserRole.role.roleName != 'Client'
    ) {
      this.setState({
        uatUrl: true
      })
    } else {
      this.setState({
        uatUrl: false
      })
    }
  }

  componentDidUpdate() {
    if (
      this.navDiv &&
      this.navDiv.clientHeight &&
      this.navDiv.clientHeight !== this.state.minHeight
    ) {
      this.setState({ minHeight: this.navDiv.clientHeight })
    }
    if (this.state.id && this.state.id !== '') {
      if (!this.props.location.pathname.match(baseUrl + '/help')) {
        this.props.history.push(baseUrl + '/help')
      }
    }
  }

  _redirectToLms = async () => {
    let token
    const fetchEndPoint = config['gatewayWvUrl'] + config['lmsAuthTokenUrl']
    const userAccount = this.props.userAccount
    const selectedOrg = this.props.selectedOrg
    const selectedUserRole = this.props.selectedUserRole
    const body = {
      uuid: userAccount.uuid,
      emailAddress: userAccount.email,
      userName: userAccount.userId ? userAccount.userId : userAccount.username,
      firstName: userAccount.firstName,
      lastName: userAccount.lastName,
      roleName: selectedUserRole.role.roleName,
      agencyName: selectedOrg ? selectedOrg.orgName : ''
    }
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        input: '',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName,
        roleId: this.props.roleId,
        orgId: this.props.orgId
      },
      body: JSON.stringify(body)
    }
    await serviceHelper
      .fetchJson(fetchEndPoint, request, this.props.showErrorMessage)
      .then(response => {
        token = response.opentoken
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.props.history.push(baseUrl + '/home')
        }
      })

    return token
  }

  _onNavClick = async tabId => {
    let openToken
    let uatUrl = false
    this.props.history.push(baseUrl + '/help')
    if (
      (tabId == 'peTraining' || tabId == 'morehelp') &&
      this.props.auth.userAccount &&
      this.props.selectedUserRole.role.roleName != 'Client'
    ) {
      openToken = await this._redirectToLms()
      uatUrl = true
    }
    this.setState({
      id: tabId,
      uatUrl,
      openToken
    })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  render() {
    const {
      presentation,
      selectedRole,
      selectedOrg,
      errorMessage,
      userAccess
    } = this.props
    const userAccount = this.props.auth.userAccount
    const { id, minHeight, navTabs, uatUrl, openToken } = this.state
    if (_.isNil(navTabs)) {
      return <div>Loading...</div>
    }
    let subProps = {}
    if (minHeight) {
      subProps = {
        ...subProps,
        minHeight: '' + minHeight + 'px'
      }
    }
    const presentationProps = {
      id,
      subProps,
      navTabs,
      onNavClick: this._onNavClick,
      setNavDiv: el => {
        this.navDiv = el
      },
      selectedRole,

      uatUrl,
      errorMessage,
      userAccount,
      openToken,
      onDeleteClick: this._onDeleteClick,
      userAccess
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  const errorMessage = state.myMessagesError.myMessagesError.errorMessage || []
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }

  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''

  return {
    auth: state.auth,
    externalAuth: state.externalAuth,
    selectedRole: _.get(state.userAccess, 'selectedUserRole.role', []),
    selectedOrg: _.get(state.userAccess, 'selectedOrg'),
    userAccount: _.get(state.auth, 'userAccount'),
    selectedUserRole: _.get(state.userAccess, 'selectedUserRole'),
    errorMessage,
    roleId,
    orgId,
    userAccess: state.userAccess
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpContainer)
