import * as React from 'react'
import { uniqBy } from 'lodash'
import { config } from '../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../Errors'

const _ = { uniqBy }

const tenantDirectoryName = config.tenant.code.toLowerCase()

const withHelpTabs = (
  WrappedComponent,
  basePromiseThunk,
  tenantPromiseThunk
) => {
  return class extends React.Component<any, any> {
    constructor(props) {
      super(props)
      this.state = {
        helpTabs: undefined
      }
    }
    componentDidMount() {
      Promise.all([tenantPromiseThunk(), basePromiseThunk()])
        .then(result => {
          const tenantTabs = result[0].HelpTabs
          const baseTabs = result[1].HelpTabs
          this.setState({
            HelpTabs: _.uniqBy(
              [
                ...tenantTabs,
                ...baseTabs
                //Applying tenantTabs before baseTabs so that it will take precedence in the uniqBy duplicate removal.
              ],
              'id'
            )
          })
        })
        .catch(() => {
          basePromiseThunk().then(baseTabs => {
            this.setState({
              HelpTabs: baseTabs.HelpTabs
            })
          })
        })
    }

    render() {
      return <WrappedComponent HelpTabs={this.state.HelpTabs} {...this.props} />
    }
  }
}

const HelpContainer = withHelpTabs(
  MultitenantLoadable(
    () => import('./HelpContainer'),
    () => import('../../' + tenantDirectoryName + '/Help/HelpContainer')
  ),
  () => import('./tabs'),
  () => import('../../' + tenantDirectoryName + '/Help/tabs')
)

const HelpPresentation = MultitenantLoadable(
  () => import('./HelpPresentation'),
  () => import('../../' + tenantDirectoryName + '/Help/HelpPresentation')
)

export default function Help(props) {
  return (
    <ErrorBoundary name="Help">
      <HelpContainer
        {...props}
        presentation={presentationProps => (
          <HelpPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseHelpContainer = withHelpTabs(
  MultitenantLoadable(
    () => import('./HelpContainer'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./tabs'),
  () => Promise.resolve().then(() => null)
)

const BaseHelpPresentation = MultitenantLoadable(
  () => import('./HelpPresentation'),
  () => Promise.resolve().then(() => null)
)

export function BaseHelp(props) {
  return (
    <BaseErrorBoundary name="Help">
      <BaseHelpContainer
        {...props}
        presentation={presentationProps => (
          <BaseHelpPresentation {...presentationProps} />
        )}
      />
    </BaseErrorBoundary>
  )
}
