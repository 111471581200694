import * as React from 'react'
import { get, includes } from 'lodash'

const _ = { get }

export default function LinkButtonWidget(props) {
  const { options, className } = props
  const { parameters } = options
  let extraClassName = className || ''

  let linkAction = null
  switch (options.action) {
    case 'addPin':
      const userPin = _.get(props.formContext, 'formData.userPin')
      if (
        props.formContext.addPin &&
        userPin &&
        (userPin.ssnEntry || userPin.pin || userPin.ccaPinNumber)
      ) {
        if (
          userPin.ssnEntry === userPin.ssnReEntry ||
          userPin.pin === userPin.pinReEntry ||
          userPin.ccaPinNumber === userPin.ccaPinNumberReEntry
        ) {
          //TODO add validation to compare entry with re-entry
          linkAction = () => props.formContext.addPin(userPin)
        } else {
          extraClassName = 'disabled'
        }
      } else {
        extraClassName = 'disabled'
      }
      break

    default:
      linkAction = () => {
        return null
      }
  }

  return (
    <button
      className={
        'btn link-btn btn-default' + (extraClassName ? extraClassName : '')
      }
      ref={elem => {
        loadBtnRef(elem, options, props)
      }}
      onClick={linkAction}
      type="button"
      value=""
    >
      {props.value || props.schema.title || props.schema.default}
    </button>
  )
}

function loadBtnRef(elem, options, props) {
  if (options.loadRef) {
    props.formContext.component.loadBtnRef(elem, props.schema.title)
  }
}
