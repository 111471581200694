import * as React from 'react'
import { config } from '../../../config'
import {
  MultitenantLoadable,
  withFormComponents
} from '@optum-wvie/dynamic-ui-framework/src/utils'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const UserAccessContainer = MultitenantLoadable(
  () => import('./UserAccessContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/UserAccess/UserAccessContainer')
)

const UserAccessPresentation = withFormComponents(
  MultitenantLoadable(
    () => import('./UserAccessPresentation'),
    () => import(config)
  ),
  () => import('./formComponents'),
  () => import(config)
)

export default function UserAccess(props) {
  return (
    <UserAccessContainer
      {...props}
      presentation={presentationProps => (
        <UserAccessPresentation {...presentationProps} />
      )}
    />
  )
}
