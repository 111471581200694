import * as React from 'react'
import { Modal, Button } from 'react-bootstrap'

interface ErrorModalProps {
  errorMessage: Array<any>
  onDeleteClick: () => void
}

export function ErrorModal(props: ErrorModalProps) {
  const { errorMessage, onDeleteClick } = props
  return (
    <div className="error-modal">
      <Modal
        show={errorMessage && errorMessage.length > 0}
        onHide={onDeleteClick}
        aria-labelledby="error-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="error-modal-title">
            <h4 className="popup-title">Error Messages</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage.map(error => {
            return (
              <div>
                <h4>{error.errorCode}</h4>
                {error.errorCode && (
                  <div>
                    <p>{error.errorDescription}</p>
                  </div>
                )}
                {error.exceptionType && (
                  <div>
                    <p>{error.errorDesc}</p>
                  </div>
                )}
              </div>
            )
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onDeleteClick}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
