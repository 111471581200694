import * as React from 'react'
import { Redirect } from 'react-router-dom'
import * as helper from '@optum-wvie/dynamic-ui-framework/src/utils'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { config } from '../../../config'
import { v4 } from 'uuid'
import * as actions from '../../../actions'
import { ClientPortalException, CODES, shouldThrow } from '../../base/Errors'
import * as serviceHelper from '../src/components/utils'
const moment = require('moment-mini-ts')
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { UnauthorizedError, getServerTime } from '../src/components/utils'

declare const process
const baseUrl = process.env.PUBLIC_URL

const formsEndpoint = config['forms_MyPESearchApplication']
const getApplicationsForPEUser = config['getApplicationsForPEUser']
const noticeGateEndpoint = config['gatewayWvUrl'] + config['pECreatePdf']
const noticeGateReadEndpoint =
  config['gatewayWvUrl'] + config['pEgetAttachments']
const submitEndpoint = config['gatewayWvUrl'] + config['pEapplications']
const peOverrideEndpoint = config['peOverrideEndpoint']
const agreeAutoAcceptEndPoint = config['agreeAutoAcceptEndPoint']
const deleteDraftEndPoint = config['deleteDraftEndPoint']
const submitOverrideUrl = config['submitOverride']
const PEWorkerSearchEndpoint =
  config['gatewayWvUrl'] + config['PEAdminWorkerApplicationSearch']
const PEMedicaidSearchEndpoint =
  config['gatewayWvUrl'] + config['PEAdminWorkerMedicaidApplicationSearch']
const getPdfEndpoint =
  config['gatewayWvUrl'] +
  '/WVIE/application/pdf/v2.1/getApplicationPDF?appID={applId}'

interface Org {
  roleId: number
  roleName: string
  orgId: number
  orgName: string
  roleorgId: number
  dfltflg: string
}

interface SearchPEApplicationsContainerProps {
  presentation: any
  auth: {
    accessToken: string
    userAccount: {
      uuid: string
    }
  }
  userAccess: {
    selectedOrg: Org
    selectedUserRole: any
  }
  userRoleId: number
  roleId: number
  orgId: string
  peApplications: object
  isPE: string
  userId: string
  authHeader: string
  addPEApplication: (...PEApplicationAction) => void
  removePEApplication: (applId: string) => void
  updatePEApplication: (...PEApplicationAction) => void
  setActivePEApplicationId: (applId: string) => void
  assignPEApplicationId: (applId: string) => void
  updatePEApplicationStep: (applId: string, step: number) => void
  showErrorMessage: (message: any) => void
  deleteErrorMessage: () => void
  errorMessage: Array<any>
  logoutUser
}

interface SearchPEApplicationsContainerState {
  forms: Array<{
    schema: Object
    uiSchema: Object
  }>
  formData: any
  showUploadModal: boolean
  showSuccessPopup: boolean
  formContext: any
  loadScreen: boolean
  redirect: string
  formKey: string
  popupoad: boolean
  applId: string
  uuid: string
  userRoleId: string
  roleId: number
  orgId: string
  errorDisplay: boolean
  isLoading: boolean
  activeCount: number
  totalCount: number
  pageNumber: number
  showOption: string
  pageCountDisplay: number
  response: any
  userId: string
  currentDate: any
}

/**
 * PEApplications provides:
 *  1) Application full (CP APPINTAKE) Navigation
 *  2) PE application  (CP PEAPPINTAKE) Navigation
 *  3) view Submitted Application details
 *
 * @class MyPESearchApplication
 * @extends {React.Component<SearchPEApplicationsContainerProps, SearchPEApplicationsContainerState>}
 */
class SearchPEApplicationsContainer extends React.Component<
  SearchPEApplicationsContainerProps,
  SearchPEApplicationsContainerState
> {
  constructor(props: SearchPEApplicationsContainerProps) {
    super(props)
    this.state = {
      forms: null,
      formData: {},
      showUploadModal: false,
      showSuccessPopup: false,
      formContext: { elibilityFormEdit: false },
      loadScreen: null,
      redirect: null,
      formKey: v4(),
      popupoad: null,
      applId: null,
      uuid: null,
      userRoleId: null,
      orgId: null,
      errorDisplay: false,
      isLoading: false,
      activeCount: 1,
      totalCount: 0,
      pageNumber: 1,
      showOption: 'none',
      pageCountDisplay: 10,
      roleId: null,
      response: null,
      userId: null,
      currentDate: getServerTime()
    }
  }

  getAppId = (props: any) => {
    const myApplicationsDraftIndex = props.id.match(
      '(?:.*)myApplicationsDraft_clients_([0-9]+)_?'
    )
    const myAgencyApplicationDraftIndex = props.id.match(
      '(?:.*)myAgencyApplicationDraft_clients_([0-9]+)_?'
    )
    let applicationId
    if (
      myApplicationsDraftIndex &&
      props.formContext.formData.Application.myApplicationsDraft.clients.length
    ) {
      applicationId =
        props.formContext.formData.Application.myApplicationsDraft.clients[
          myApplicationsDraftIndex[1]
        ].fullApplications[0].fullApplication[0].appId
    }
    if (
      myAgencyApplicationDraftIndex &&
      props.formContext.formData.Application.myAgencyApplicationDraft.clients
        .length
    ) {
      applicationId =
        props.formContext.formData.Application.myAgencyApplicationDraft.clients[
          myAgencyApplicationDraftIndex[1]
        ].fullApplications[0].fullApplication[0].appId
    }
    return applicationId
  }

  isFullApplicationRadioSelected = (props: any) => {
    let fieldId = props.id
    let flag = false
    if (fieldId.includes('myApplicationSub')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationSub_clients_([0-9]+)_?'
      )[1]
      if (
        this.state.formData.Application.myApplicationSub.clients[clientIndex]
          .fullApplications[0].fullApplication
      ) {
        this.state.formData.Application.myApplicationSub.clients[
          clientIndex
        ].fullApplications[0].fullApplication.forEach(fullApp => {
          if (fullApp.select) {
            flag = true
          }
        })
      }
    } else if (fieldId.includes('myAgencyApplicationSubmitted')) {
      let clientIndex = fieldId.match(
        '(?:.*)myAgencyApplicationSubmitted_clients_([0-9]+)_?'
      )[1]
      this.state.formData.Application.myAgencyApplicationSubmitted.clients[
        clientIndex
      ].fullApplications[0].fullApplication.forEach(fullApp => {
        if (fullApp.select) {
          flag = true
        }
      })
    }
    return flag
  }

  isPEApplicationRadioSelected = (props: any) => {
    let fieldId = props.id
    let flag = false

    if (fieldId.includes('myApplicationSub')) {
      let clientIndex = fieldId.match(
        '(?:.*)myApplicationSub_clients_([0-9]+)_?'
      )[1]

      if (
        this.state.formData.Application.myApplicationSub.clients[clientIndex]
          .peApplications[0].peApplication
      ) {
        this.state.formData.Application.myApplicationSub.clients[
          clientIndex
        ].peApplications[0].peApplication.forEach(peApp => {
          if (peApp.select) {
            flag = true
            this.props.setActivePEApplicationId(peApp.applId)
          }
        })
      }
    }

    return flag
  }

  _editDraftApplication = (props: any) => {
    let applicationId = this.getAppId(props)
    this._setRedirect(applicationId)
  }

  _viewPDFApplication = (id: string, applicationId: number) => {
    const fetchEndpoint = getPdfEndpoint.replace(
      '{applId}',
      applicationId.toString()
    )
    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            tenantCode: config.tCode,
            portalName: config.portalName,
            uuid: this.props.auth.userAccount.uuid,
            Authorization: config.bearer + (this.props.auth.accessToken || ''),
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage
      )
      .then(response => {
        let content = response.fileContent
        let contentType = 'application/pdf'
        var pdfAsDataUri = 'data:' + contentType + ';base64,' + content // shortened
        var bytearray = this.convertDataURIToBinary(pdfAsDataUri)
        var file = new Blob([bytearray], {
          type: contentType
        })
        // for IE 10/11 save doc pop up will appear
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file)
        } else {
          var fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          // auto download the file
          var a = document.createElement('a')
          a.href = fileURL
          a.target = '_blank'
          a.download = 'application.pdf'
          document.body.appendChild(a)
          a.click()
        }
      })
      .catch(error => {
        if (error instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        console.error(
          'SearchPEApplications _viewPDFApplication failed with ex',
          error
        )
        const code = CODES.MY_APPLICATIONS_VIEW_PDF
        if (shouldThrow(code)) {
          this.setState(() => {
            if (error instanceof helper.IEServiceError) {
              throw error
            } else {
              throw new ClientPortalException(error, code)
            }
          })
        }
      })
  }

  componentDidMount() {
    document.title = 'My Applications - My Account'
    this._loadApp()
  }

  getIndividualResult = (index: string) => {
    let appMgmtParams = {}
    appMgmtParams['searchApplications'] = {
      ...this.state.formData.searchApplications
    }

    var getApplicationSearchData = PEWorkerSearchEndpoint

    if (appMgmtParams['searchApplications'].applicationType == undefined) {
      appMgmtParams['searchApplications'].applicationType = 'PEApplication'
    }
    if (appMgmtParams['searchApplications'].source == undefined) {
      appMgmtParams['searchApplications'].source = 'AllAgencies'
    }
    if (appMgmtParams['searchApplications'].source == 'MyAgency') {
      appMgmtParams[
        'searchApplications'
      ].organizationId = this.props.userAccess.selectedOrg.orgId.toString()
    }
    if (appMgmtParams['searchApplications'].applicationType == 'Appl') {
      this.setState({ isLoading: true })
      return serviceHelper
        .fetchJson(
          PEMedicaidSearchEndpoint,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              orgId: this.props.userAccess.selectedOrg.orgId.toString(),
              RoleId: this.props.userAccess.selectedOrg.roleId.toString()
            },
            body: JSON.stringify(appMgmtParams)
          },
          this.props.showErrorMessage
        )
        .then(json => {
          this.setState({ isLoading: false })
          for (var i = 0; i < json.clientInfos.length; i++) {
            var FullApplStatus =
              json.clientInfos[i].fullApplication[0].appStatusCd
            if (
              FullApplStatus == 'DEACTIVATED' ||
              FullApplStatus == 'deactivated'
            ) {
              json.clientInfos[i].fullApplication[0].appStatusCd = 'Deactivated'
            } else if (
              FullApplStatus == 'SUBMITTED' ||
              FullApplStatus == 'submitted'
            ) {
              json.clientInfos[i].fullApplication[0].appStatusCd = 'Submitted'
            } else if (FullApplStatus == 'DRAFT' || FullApplStatus == 'draft') {
              json.clientInfos[i].fullApplication[0].appStatusCd = 'Draft'
            } else if (
              FullApplStatus == 'COMPLETED' ||
              FullApplStatus == 'completed'
            ) {
              json.clientInfos[i].fullApplication[0].appStatusCd = 'Completed'
            }
          }
          return json
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          this.setState({ isLoading: false })
          return ex
        })
    } else {
      this.setState({ isLoading: true })
      return serviceHelper
        .fetchJson(
          getApplicationSearchData,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              orgId: this.props.orgId,
              roleId: this.props.roleId
            },
            body: JSON.stringify(appMgmtParams)
          },
          this.props.showErrorMessage
        )
        .then(json => {
          this.setState({ isLoading: false })
          for (var i = 0; i < json.clientInfos.length; i++) {
            var PEApplStatus = json.clientInfos[i].peApplication[0].applStatus
            if (
              PEApplStatus == 'DEACTIVATED' ||
              PEApplStatus == 'deactivated'
            ) {
              json.clientInfos[i].peApplication[0].applStatus = 'Deactivated'
            } else if (
              PEApplStatus == 'SUBMITTED' ||
              PEApplStatus == 'submitted'
            ) {
              json.clientInfos[i].peApplication[0].applStatus = 'Submitted'
            } else if (PEApplStatus == 'DRAFT' || PEApplStatus == 'draft') {
              json.clientInfos[i].peApplication[0].applStatus = 'Draft'
            } else if (
              PEApplStatus == 'COMPLETED' ||
              PEApplStatus == 'completed'
            ) {
              json.clientInfos[i].peApplication[0].applStatus = 'Completed'
            }
            if (json.clientInfos[i].fullApplication.length != 0) {
              var FullPEApplStatus =
                json.clientInfos[i].fullApplication[0].appStatusCd
              if (
                FullPEApplStatus == 'DEACTIVATED' ||
                FullPEApplStatus == 'deactivated'
              ) {
                json.clientInfos[i].fullApplication[0].appStatusCd =
                  'Deactivated'
              } else if (
                FullPEApplStatus == 'SUBMITTED' ||
                FullPEApplStatus == 'submitted'
              ) {
                json.clientInfos[i].fullApplication[0].appStatusCd = 'Submitted'
              } else if (
                FullPEApplStatus == 'DRAFT' ||
                FullPEApplStatus == 'draft'
              ) {
                json.clientInfos[i].fullApplication[0].appStatusCd = 'Draft'
              } else if (
                FullPEApplStatus == 'COMPLETED' ||
                FullPEApplStatus == 'completed'
              ) {
                json.clientInfos[i].fullApplication[0].appStatusCd = 'Completed'
              }
            }
          }
          return json
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          this.setState({ isLoading: false })
          return ex
        })
    }
  }

  _loadApp = () => {
    const request = {
      headers: {
        'Content-Type': 'application/json',
        uuid: this.props.auth.userAccount.uuid,
        tenantCode: config.tCode,
        Authorization: config.bearer + this.props.auth.accessToken,
        portalName: config.portalName
      }
    }
    helper
      .fetchJson(formsEndpoint.replace('{version}', '1.0'), request)
      .then(formJson => {
        this._initializeForm(formJson)
      })
      .catch(error => {
        console.error('MyProviders form fetch failed due to ex', error)
      })
  }

  _initializeForm = (formsJson: any) => {
    let forms, application, formData, formContext, refs
    application = formsJson['app']
    forms = application['forms']
    refs = application['metaData']['refs']
    //load the pre-filled form data
    formData = application['formData']

    let loadScreen = true
    //Date check in applications search
    let currentdate = _moment(config, this.state.currentDate).format(
      'YYYY-MM-DD'
    )
    let pastDate = _moment(config, this.state.currentDate)
      .subtract('years', 1)
      .add('days', 1)
      .format('YYYY-MM-DD')

    //Initialize formContext, which will be used for schema references and context-sensitive widgets.
    formContext = {
      ...formContext,
      refs: refs,
      forms: forms,
      formData: formData,
      component: this,
      deactivateApp: (appId: string, peApp: any) =>
        this._deactivateApp(appId, peApp),
      viewPENotice: (appId: string, docId: string) =>
        this._viewEligibilityNotice(appId, docId),
      confirmEligibility: (appId: string) => this._confirmEligibility(appId),
      notAgreeEligibilty: this._notAgreeEligibilty,
      elibilityFormEdit: false,
      agreeEligibilty: this._agreeEligibilty,
      setRedirect: this._setRedirect,
      overrideSubmit: formData => this._overrideSubmit(),
      currentDate: currentdate,
      pastDate: pastDate,
      viewPDFApplication: this._viewPDFApplication,
      orgId: this.props.userAccess.selectedOrg.orgId.toString()
    }
    this.setState({ forms, formContext, formData, loadScreen })
  }

  _overrideSubmit = () => {
    let formData = helper.deepClone(this.state.formData)

    let disposition = []
    let requestData
    if (formData && formData.overridePeData.PEGroupTest1) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'PEGroupTest',
        possibleDisposition: formData.overridePeData.PEGroupTest1,
        ruleID: ''
      })
    }

    if (formData && formData.overridePeData.StateResidency1) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'stateResidencyTest',
        possibleDisposition: formData.overridePeData.StateResidency1,
        ruleID: ''
      })
    }

    if (formData && formData.overridePeData.CitizenshipImmigration1) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'CitizenshipOrImmigrationTest',
        possibleDisposition: formData.overridePeData.CitizenshipImmigration1,
        ruleID: ''
      })
    }

    if (formData && formData.overridePeData.householdIncomeTest1) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'FPLTest',
        possibleDisposition: formData.overridePeData.householdIncomeTest1,
        ruleID: ''
      })
    }

    if (formData && formData.overridePeData.PELast) {
      disposition.push({
        currentDisposition: '',
        decisionType: 'PEInLastTwelveMonthTest',
        possibleDisposition: formData.overridePeData.PELast,
        ruleID: ''
      })
    }

    requestData = {
      disposition,
      eventId: 0,
      appId: formData.overridePeData.appId,
      eligReqId: formData.overridePeData.eligReqId
    }

    this.setState({ isLoading: true })
    serviceHelper
      .fetchJson(
        submitOverrideUrl,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          },
          mode: 'cors',
          body: JSON.stringify(requestData)
        },
        this.props.showErrorMessage
      )
      .then(res => {
        this.setState({ isLoading: false })
        return res.json()
      })
      .then(results => {
        this._closeUploadModal()
        this._loadApp()
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ isLoading: false })
        console.error(' getApps failed with ex', ex)
      })
  }

  _openUploadModal = () => {
    this.setState({ showUploadModal: true, showSuccessPopup: false })
  }

  _getData = pageNumber => {
    this.setState({ activeCount: pageNumber })
    var currentCount = pageNumber * this.state.pageCountDisplay
    var lastCount = currentCount - this.state.pageCountDisplay
    var updatedResponses = this.state.response.slice(lastCount, currentCount)
    let newFormData = helper.deepClone(this.state.formData)
    newFormData.Application.myApplicationSub.clients = updatedResponses
    this.setState({ formData: newFormData }, () => this._refreshFormContext())
  }

  _onFormDataChangeModal = ({ formData }) => {
    this.state.formData.overridePeData = formData
    this._refreshFormContext()
  }
  _agreeEligibilty = () => {
    let formData = helper.deepClone(this.state.formData)

    const fetchEndpoint = agreeAutoAcceptEndPoint.replace(
      '{applicationId}',
      formData.overridePeData.appId
    )
    this.setState({ isLoading: true })
    serviceHelper
      .fetchJson(
        fetchEndpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName
          }
          //mode : 'cors',
          //body: JSON.stringify(params)
        },
        this.props.showErrorMessage
      )
      .then(results => {
        this.setState({ isLoading: false })
        this._closeUploadModal()
        this._loadApp()
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        this.setState({ isLoading: false })
        console.error(' getApps failed with ex', ex)
      })
  }
  _closeUploadModal = () => {
    this.setState({ showUploadModal: false, showSuccessPopup: false })
  }

  _deactivateApp = (appId: string, peApp: any) => {
    serviceHelper
      .fetchJson(
        submitEndpoint + '/' + appId + '?q=detail',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            tenantCode: config.tCode,
            Authorization: config.bearer + this.props.auth.accessToken,
            portalName: config.portalName,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          }
        },
        this.props.showErrorMessage
      )
      .then(json => {
        json.result.applicationDetail[0].Application.Status = 'Deactivated'
        json.result.applicationDetail[0].applId = appId
        var submitUrl = submitEndpoint + '/' + appId
        const request = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            uuid: this.props.auth.userAccount.uuid,
            roleId: this.props.roleId,
            orgId: this.props.orgId
          },
          body: JSON.stringify(json.result.applicationDetail[0])
        }

        serviceHelper
          .fetchJson(submitUrl, request, this.props.showErrorMessage)
          .then(responseJson => {
            window.location.reload()
          })
          .catch(error => {
            if (error instanceof UnauthorizedError) {
              this.props.logoutUser()
              this.setState({ redirect: baseUrl + '/home' })
            }
          })
      })
      .catch(ex => {
        if (ex instanceof UnauthorizedError) {
          this.props.logoutUser()
          this.setState({ redirect: baseUrl + '/home' })
        }
        return ex
      })
  }

  _confirmEligibility = (appId: string) => {
    let applId = appId
    let popupoad = true
    this.setState({ popupoad })
    this.setState({ applId })
    let userRoleId = this.props.userRoleId.toString()
    let uuid = this.props.auth.userAccount.uuid
    this.setState({ uuid })
    this.setState({ userRoleId })
  }

  _notAgreeEligibilty = () => {
    let formContext = this.state.formContext
    formContext.elibilityFormEdit = true
    this.setState({ formContext }, () => this._refreshFormContext())
  }

  _setRedirect = (path: string) => {
    this.setState({ redirect: path })
  }

  convertDataURIToBinary = (dataURI: string) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array
  }

  _viewEligibilityNotice = (applicationId: string, docId: string) => {
    if (docId == null || docId.trim().length == 0) {
      //Endpoint for create doc
      var fetchEndpoint = noticeGateEndpoint.replace('{applId}', applicationId)

      serviceHelper
        .fetchJson(
          fetchEndpoint,
          {
            method: 'GET',
            headers: {
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              userRoleId: this.props.userAccess.selectedUserRole.userRoleId,
              Accept: 'application/json',
              'Content-Type': 'application/json',
              roleId: this.props.roleId,
              orgId: this.props.orgId
            },
            mode: 'cors'
          },
          this.props.showErrorMessage
        )
        .then(res => {
          var content = res.result.contentData
          var contentType = 'application/pdf'

          var bytearray = this.convertDataURIToBinary(
            'data:application/pdf;base64,' + content
          )

          var file = new Blob([bytearray], {
            type: contentType
          })

          return file
        })
        .then(file => {
          //trick to download store a file having its URL
          var fileURL = ''
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file)
          } else {
            fileURL = URL.createObjectURL(file)
            window.open(fileURL)
            // auto download the file
            var a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = 'Notification.pdf'
            document.body.appendChild(a)
            a.click()
          }
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          console.error(' view pdf', ex)
        })
    } else {
      //Endpoint for read doc
      fetchEndpoint = noticeGateReadEndpoint.replace('{docId}', docId)

      serviceHelper
        .fetchJson(
          fetchEndpoint,
          {
            method: 'GET',
            headers: {
              uuid: this.props.auth.userAccount.uuid,
              tenantCode: config.tCode,
              Authorization: config.bearer + this.props.auth.accessToken,
              portalName: config.portalName,
              roleId: this.props.roleId,
              orgId: this.props.orgId,
              Accept: '/*/',
              'Content-Type': 'application/pdf'
            },
            mode: 'cors'
          },
          this.props.showErrorMessage
        )
        .then(res => {
          return res.arrayBuffer()
        })
        .then(res => {
          var data = new Uint8Array(res, 0, res.byteLength)
          var contentType = 'application/pdf'

          var file = new Blob([data], {
            type: contentType
          })

          return file
        })
        .then(file => {
          //trick to download store a file having its URL
          var fileURL = ''
          // for IE 10/11 save doc pop up will appear
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file)
          } else {
            fileURL = URL.createObjectURL(file)
            window.open(fileURL)
            // auto download the file
            var a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = 'Notification.pdf'
            document.body.appendChild(a)
            a.click()
          }
        })
        .catch(ex => {
          if (ex instanceof UnauthorizedError) {
            this.props.logoutUser()
            this.setState({ redirect: baseUrl + '/home' })
          }
          console.error(' view pdf', ex)
        })
    }
  }

  _onFormDataChange = ({ formData }) => {
    this.setState({ formData }, () => this._refreshFormContext())
  }

  // refresh formcontext since it works with formdata
  _refreshFormContext = () => {
    this.setState({
      formContext: {
        ...this.state.formContext,
        formData: helper.deepClone(this.state.formData)
      }
    })
  }

  _clearForm = () => {
    this._loadApp()
  }

  _searchPEApplications = (index: string) => {
    let accordionIndex = index

    this.getIndividualResult(accordionIndex).then(response => {
      let formData = _.cloneDeep(this.state.formData)
      let applType = 'PEApplication'
      if (formData.searchApplications != undefined) {
        applType = formData.searchApplications.applicationType
      }
      if (response.clientInfos) {
        response = response.clientInfos
        response.map(value => {
          this.setState({ orgId: value.organizationId })
          value.fullApplications = []
          value.peApplications = []
          value.accordionTitle =
            'Name : ' +
            (value.firstName ? value.firstName : '') +
            ' ' +
            (value.lastName ? value.lastName : '') +
            (value.dob && value.dob !== 'null'
              ? '  DOB: ' + moment.utc(value.dob).format('MM/DD/YYYY')
              : '')
          value.fullApplications.push({
            fullApplication: value.fullApplication
          })
          delete value.fullApplication

          value.peApplications.push({
            peApplication: value.peApplication
          })

          delete value.peApplication
        })
      } else if (applType == 'Appl') {
      } else {
        response.map(value => {
          this.setState({ orgId: value.organizationId })
          value.fullApplications = []
          value.peApplications = []
          value.accordionTitle =
            'Name : ' +
            (value.clientFName ? value.clientFName : '') +
            ' ' +
            (value.clientLName ? value.clientLName : '') +
            (accordionIndex == '1'
              ? value.clientBirthDate
                ? '  DOB: ' +
                  moment.utc(value.clientBirthDate).format('MM/DD/YYYY')
                : ''
              : '')
          value.fullApplications.push({
            fullApplication: value.fullApplication
          })
          delete value.fullApplication
          const peApplicationValue = []
          peApplicationValue.push(value)
          value.peApplications.push({ peApplication: peApplicationValue })
          delete value.peApplication
        })
      }
      if (response.length > 0) {
        this.setState({ showOption: 'block' })
      }
      this.setState({ totalCount: response.length })
      this.setState({ response: response })

      var currentCount = this.state.pageNumber * this.state.pageCountDisplay
      var lastCount = currentCount - this.state.pageCountDisplay
      let newFormData = helper.deepClone(this.state.formData)
      if (response.length != undefined) {
        var updatedResponses = response.slice(lastCount, currentCount)
        newFormData.Application.myApplicationSub.clients = updatedResponses
      } else {
        newFormData.Application.myApplicationSub.clients = []
      }
      this.setState({ formData: newFormData }, () => this._refreshFormContext())

      var i
      for (i = 0; i < 100; i++) {
        var maindiv = document.getElementById(
          'form1_Application_myApplicationSub_clients_' +
            i +
            '_peApplications_0'
        )
        if (maindiv != null) {
          let _status
          let applStatus = document.getElementById(
            'form1_Application_myApplicationSub_clients_' +
              i +
              '_peApplications_0_peApplication_0_applStatus'
          )
          if (applStatus) {
            _status = applStatus.innerText
          }
          var tRow = maindiv.getElementsByTagName('td')
          var tHeader = maindiv.getElementsByTagName('th')
          tHeader[3].removeAttribute('style')
          tRow[3] ? tRow[3].removeAttribute('style') : ''
          tHeader[4].removeAttribute('style')
          tRow[4] ? tRow[4].removeAttribute('style') : ''

          tHeader[5].removeAttribute('style')
          tRow[5] ? tRow[5].removeAttribute('style') : ''
          tHeader[6].removeAttribute('style')
          tRow[6] ? tRow[6].removeAttribute('style') : ''

          tHeader[8].removeAttribute('style')
          tRow[8] ? tRow[8].removeAttribute('style') : ''
          tHeader[9].removeAttribute('style')
          tRow[9] ? tRow[9].removeAttribute('style') : ''
          if (_status == 'Submitted') {
            tHeader[7].style.display = 'none'
            tRow[7].style.display = 'none'
            tHeader[8].style.display = 'none'
            tRow[8].style.display = 'none'
            tHeader[11].innerText = 'Submitted By'
          } else if (_status == 'DEACTIVATED') {
            tHeader[3].style.display = 'none'
            tRow[3].style.display = 'none'
            tHeader[4].style.display = 'none'
            tRow[4].style.display = 'none'
            tHeader[5].style.display = 'none'
            tRow[5].style.display = 'none'
            tHeader[6].style.display = 'none'
            tRow[6].style.display = 'none'
            tHeader[9].style.display = 'none'
            tRow[9].style.display = 'none'
            tHeader[11].innerText = 'Last Modified By'
          } else {
            tHeader[3].style.display = 'none'
            tRow[3] ? (tRow[3].style.display = 'none') : ''
            tHeader[4].style.display = 'none'
            tRow[4] ? (tRow[4].style.display = 'none') : ''
            tHeader[5].style.display = 'none'
            tRow[5] ? (tRow[5].style.display = 'none') : ''
            tHeader[6].style.display = 'none'
            tRow[6] ? (tRow[6].style.display = 'none') : ''
            tHeader[9].style.display = 'none'
            tRow[9] ? (tRow[9].style.display = 'none') : ''
            tHeader[11].innerText = 'Last Modified By'
          }
        }
      }
      for (var j = 0; j < 100; j++) {
        let fullMainDiv = document.getElementById(
          'form1_Application_myApplicationSub_clients_' +
            j +
            '_fullApplications_0'
        )
        if (fullMainDiv != null) {
          let _fullStatus
          let fullAppStatus = document.getElementById(
            'form1_Application_myApplicationSub_clients_' +
              j +
              '_fullApplications_0_fullApplication_0_appStatusCd'
          )
          if (fullAppStatus) {
            _fullStatus = fullAppStatus.innerText
          }

          let tRowFull = fullMainDiv.getElementsByTagName('td')
          let tHeaderFull = fullMainDiv.getElementsByTagName('th')

          tHeaderFull[3].removeAttribute('style')
          tRowFull[3] ? tRowFull[3].removeAttribute('style') : ''
          tHeaderFull[4].removeAttribute('style')
          tRowFull[4] ? tRowFull[4].removeAttribute('style') : ''
          tHeaderFull[5].removeAttribute('style')
          tRowFull[5] ? tRowFull[5].removeAttribute('style') : ''
          tHeaderFull[6].removeAttribute('style')
          tRowFull[6] ? tRowFull[6].removeAttribute('style') : ''
          tHeaderFull[8].removeAttribute('style')
          tRowFull[8] ? tRowFull[8].removeAttribute('style') : ''

          if (_fullStatus == 'DRAFT') {
            tHeaderFull[4].style.display = 'none'
            tRowFull[4].style.display = 'none'
            tHeaderFull[6].style.display = 'none'
            tRowFull[6].style.display = 'none'
            tHeaderFull[8].innerText = 'Last Modified By'
          } else if (_fullStatus == 'SUBMITTED') {
            tHeaderFull[8].innerText = 'Submitted By'
            tHeaderFull[3].style.display = 'none'
            tRowFull[3].style.display = 'none'
            tHeaderFull[5].style.display = 'none'
            tRowFull[5].style.display = 'none'
          }
        }
      }
    })
  }

  _onDeleteClick = () => {
    this.props.deleteErrorMessage()
  }

  setPopupoadToFalse = () => {
    this.setState({ popupoad: false })
  }

  render() {
    let redirectUrl = this.state.redirect
    if (this.state.redirect) {
      return <Redirect to={baseUrl + redirectUrl} />
    }
    const { presentation, errorMessage } = this.props
    const { peApplications = [] } = this.props
    const {
      forms,
      formData,
      formContext,
      loadScreen,
      showUploadModal,
      popupoad,
      applId,
      uuid,
      userRoleId,
      orgId,
      activeCount,
      totalCount,
      pageNumber,
      showOption,
      formKey,
      pageCountDisplay
    } = this.state

    const presentationProps = {
      forms,
      peApplications,
      formData,
      formContext,
      loadScreen,
      onFormDataChange: this._onFormDataChange,
      searchPEApplications: this._searchPEApplications,
      clearForm: this._clearForm,
      formKey: formKey,
      showUploadModal,
      closeUploadModal: this._closeUploadModal,
      onFormDataChangeModal: this._onFormDataChangeModal,
      popupoad,
      applId,
      uuid,
      userRoleId,
      errorDisplay: this.state.errorDisplay,
      isLoading: this.state.isLoading,
      activeCount,
      totalCount,
      pageNumber,
      showOption,
      pageCountDisplay,
      onPaginationClick: this._getData,
      userId: this.props.userId,
      orgId: this.props.userAccess.selectedOrg
        ? this.props.userAccess.selectedOrg.orgId
        : '',
      roleId: this.props.roleId,
      errorMessage,
      onDeleteClick: this._onDeleteClick,
      setPopupoadToFalse: this.setPopupoadToFalse
    }
    return presentation(presentationProps)
  }
}

function mapStateToProps(state, ownProps) {
  let org = state.userAccess.selectedUserRole.organization
  let orgId = _.get(state.userAccess, 'selectedOrg.orgId') || ''
  let roleId
  if (state.userAccess && state.userAccess.selectedOrg) {
    roleId = _.get(state.userAccess, 'selectedOrg.roleId') || ''
  } else {
    roleId = _.get(state.userAccess, 'selectedUserRole.role.roleId') || ''
  }
  let userRoleId = _.get(state.userAccess, 'selectedUserRole.userRoleId') || ''
  const peApplications = state.peApplications.applicationsArray || []
  return {
    auth: state.auth,
    userAccess: state.userAccess,
    orgId,
    roleId,
    userRoleId,
    peApplications,
    errorMessage: state.myMessagesError.myMessagesError.errorMessage || []
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addPEApplication: (applId, status, applicationData) => {
      dispatch(actions.addPEApplication(applId, status, applicationData))
    },
    removePEApplication: applId => {
      dispatch(actions.removePEApplication(applId))
    },
    updatePEApplication: (applId, status, applicationData) => {
      dispatch(actions.updatePEApplication(applId, status, applicationData))
    },
    viewPEApplications: () => {
      dispatch(actions.viewPEApplications())
    },
    setActivePEApplicationId: applId => {
      dispatch(actions.setActivePEApplicationId(applId))
    },
    assignPEApplicationId: applId => {
      dispatch(actions.assignPEApplicationId(applId))
    },
    updatePEApplicationStep: (applId, step) => {
      dispatch(actions.updatePEApplicationStep(applId, step))
    },
    showErrorMessage: message => {
      dispatch(actions.myMessagesError(message))
    },
    deleteErrorMessage: () => {
      dispatch(actions.myMessagesDeleteError())
    },
    logoutUser: (uuid, accessToken) => {
      dispatch(actions.logoutUser(uuid, accessToken))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPEApplicationsContainer)
