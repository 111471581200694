import * as React from 'react'
import Templates from '@optum-wvie/dynamic-ui-framework/src/components/templates'
import Fields from '@optum-wvie/dynamic-ui-framework/src/components/fields'
import Widgets from '@optum-wvie/dynamic-ui-framework/src/components/widgets'

export const fields = {
  accordion: Fields.AccordionField,
  tableRow: Fields.TableRowField,
  mcr: Fields.CodeReferenceField
}

export const widgets = {
  span: Widgets.SpanWidget,
  tableBtn: Widgets.LinkButtonWidget,
  docSelect: Widgets.CustomSelectWidget,
  link: Widgets.LinkWidget,
  checkboxes: Widgets.CustomCheckboxesWidget,
  select: Widgets.CustomSelectWidget
}

export const FieldTemplate = Templates.CustomFieldTemplate

export const ArrayFieldTemplate = Templates.CustomArrayFieldTemplate
