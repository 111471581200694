export default function userAccess(
  state = {
    isFetching: false,
    userRoles: null,
    selectedUserRole: null
  },
  action
) {
  switch (action.type) {
    case 'INITIALIZE':
      return Object.assign({}, state, {
        isFetching: false
      })
    case 'LOGIN_REQUEST':
      return Object.assign({}, state, {
        userRoles: null,
        selectedUserRole: false
      })
    case 'UPDATE_ORG_LIST':
      return Object.assign({}, state, {
        isFetching: false,
        orgList: action.orgList
      })
    case 'SET_DEFAULT_ORG':
      return Object.assign({}, state, {
        isFetching: false,
        defaultOrg: action.selectedOrg
      })
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        isFetching: true
      })
    case 'UPDATE_SELECTED_ORG':
      return Object.assign({}, state, {
        isFetching: false,
        selectedOrg: action.selectedOrg
      })
    case 'LOGOUT_SUCCESS':
      return Object.assign({}, state, {
        isFetching: false,
        userRoles: null,
        selectedUserRole: false
      })
    case 'UPDATE_ACCESS_ROLES':
      return Object.assign({}, state, {
        isFetching: false,
        userRoles: action.userRoles
      })
    case 'UPDATE_SELECTED_ROLE':
      return Object.assign({}, state, {
        selectedUserRole: action.selectedUserRole
      })
    case 'UPDATE_USERROLEID_ORGID_LIST':
      return Object.assign({}, state, {
        userRoleIdOrgIdList: action.userRoleIdOrgIdList
      })

    case 'LOGOUT_SUCCESS':
      return {
        isFetching: false,
        userRoles: null,
        selectedUserRole: null
      }
    default:
      return state
  }
}
